import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { FilesDropZoneDirective } from './files-drop-zone.directive';

@NgModule({
  declarations: [FilesDropZoneDirective],
  imports: [CommonModule],
  exports: [FilesDropZoneDirective],
})
export class TiimeFileModule {}
