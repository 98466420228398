import {
  ChangeDetectionStrategy,
  Component,
  HostBinding,
  Input,
} from '@angular/core';
import { Mapper } from '@manakincubber/tiime-utils';

import { OperationType, TransferDirection } from '@enums';

const defaultHeight = 20;

@Component({
  selector: 'app-operation-type-logo',
  templateUrl: './operation-type-logo.component.html',
  styleUrls: ['./operation-type-logo.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OperationTypeLogoComponent {
  @Input() operationType: OperationType;
  @Input() operationDirection: TransferDirection;
  @Input() oneColor: boolean;
  @Input() tooltipEnabled: boolean;
  @Input() tooltipContent: string;
  @Input() isSalaryOperation: boolean;

  @Input() set size(size: number) {
    this._size = size;
    this.height = size;
    this.lineHeight = size;
  }

  get size(): number {
    return this._size;
  }

  @HostBinding('style.height.px') height = this.size;
  @HostBinding('style.width.px') width = this.size;
  @HostBinding('style.line-height.px') lineHeight = this.size;

  private _size: number = defaultHeight;

  readonly mapToOperationTypeLogo: Mapper<OperationType, string> = (
    operationType: OperationType,
  ) => {
    switch (operationType) {
      case OperationType.cash:
        return 'ic_twocolors_cash';
      case OperationType.check:
        return 'ic_twocolors_chb';
      case OperationType.card:
        return 'ic_twocolors_cb';
      case OperationType.transfer:
        return 'ic_twocolors_transfer';
      case OperationType.levy:
      case OperationType.undefined:
        return 'ic_twocolors_bank';
    }
  };

  readonly mapToOperationTypeOneColorLogo: Mapper<OperationType, string> = (
    operationType: OperationType,
  ) => {
    switch (operationType) {
      case OperationType.cash:
        return 'icon-methode-cash';
      case OperationType.check:
        return 'icon-methode-chb';
      case OperationType.card:
        return 'icon-methode-cb';
      case OperationType.transfer:
        return this.operationDirection === TransferDirection.in
          ? 'icon-bottom-right-1c-teint'
          : 'icon-top-left-1c-teint';
      case OperationType.levy:
      case OperationType.undefined:
        return 'icon-methode-bank';
    }
  };

  readonly mapToOperationTypeSalaryLogo: Mapper<OperationType, string> = (
    operationType: OperationType,
  ) => {
    switch (operationType) {
      case OperationType.cash:
        return 'salary-cash';
      case OperationType.card:
        return 'salary-card';
      default:
        return undefined;
    }
  };

  readonly mapToOneColorIconStyle: Mapper<
    OperationType,
    { [klass: string]: string }
  > = () => {
    return {
      'font-size': `${this.size}px`,
      'line-height': `${this.size}px`,
      height: `${this.size}px`,
      width: `${this.size}px`,
    };
  };
}
