import { FormControl, FormGroup, Validators } from '@angular/forms';
import { FormUtils } from '@manakincubber/tiime-utils';
import Big from 'big.js';
import { merge } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';

import { InvoicingCategoriesEnum } from '@enums';

import { AbstractLineForm } from './abstract-line-form';
import { DiscountForm } from './discount-form';

type LineFormModel = {
  quantity: FormControl<number>;
  totalExcludingTaxes: FormControl<number>;
  unitAmount: FormControl<number>;
  vatType: FormGroup<{ code: FormControl<string> }>;
  invoicingUnit: FormGroup<{ id: FormControl<number | null> }>;
  articleId: FormControl<number | null>;
  discount: DiscountForm;
  invoicingCategoryType: FormControl<InvoicingCategoriesEnum>;
};

export class LineForm extends AbstractLineForm<LineFormModel> {
  private static readonly FIVE_DECIMALS_VALIDATOR = Validators.pattern(
    /^[-+]?\d+([.,]\d{1,5})?$/,
  );
  private static readonly TWO_DECIMALS_VALIDATOR = Validators.pattern(
    /^[-+]?\d+([.,]\d{1,2})?$/,
  );

  displayDiscount = false;
  readonly isTextLine = false;
  readonly computationChanges$ = merge(
    this.vatTypeCode.valueChanges.pipe(distinctUntilChanged()),
    merge(
      this.totalExcludingTaxes.valueChanges.pipe(distinctUntilChanged()),
      this.discount.amount.valueChanges.pipe(distinctUntilChanged()),
      this.discount.percentage.valueChanges.pipe(distinctUntilChanged()),
    ).pipe(debounceTime(150)),
  );

  get descriptionErrorMessage(): string {
    return (this.description.touched ||
      this.quantity.touched ||
      this.unitAmount.touched) &&
      this.description.hasError('required')
      ? `Désignation requise`
      : '';
  }

  get quantity(): LineFormModel['quantity'] {
    return this.controls.quantity;
  }

  get quantityErrorMessage(): string {
    if (
      (this.description.touched ||
        this.quantity.touched ||
        this.unitAmount.touched) &&
      this.quantity.hasError('required')
    ) {
      return `Quantité requise`;
    } else {
      return this.quantity.hasError('pattern')
        ? 'Format de la quantité limité à 5 décimales'
        : '';
    }
  }

  get totalExcludingTaxes(): LineFormModel['totalExcludingTaxes'] {
    return this.controls.totalExcludingTaxes;
  }

  get totalExcludingTaxesErrorMessage(): string {
    if (
      this.description.touched &&
      this.totalExcludingTaxes.hasError('required')
    ) {
      return `Montant requis`;
    } else {
      return this.totalExcludingTaxes.hasError('pattern')
        ? 'Format du montant limité à 2 décimales'
        : '';
    }
  }

  get unitAmount(): LineFormModel['unitAmount'] {
    return this.controls.unitAmount;
  }

  get unitAmountErrorMessage(): string {
    if (
      (this.description.touched ||
        this.quantity.touched ||
        this.unitAmount.touched) &&
      this.unitAmount.hasError('required')
    ) {
      return `Prix unitaire requis`;
    } else {
      return this.unitAmount.hasError('pattern')
        ? 'Format du prix unitaire limité à 5 décimales'
        : '';
    }
  }

  get vatTypeCode(): FormControl<string> {
    return this.controls.vatType.controls.code;
  }

  get invoicingUnit(): LineFormModel['invoicingUnit'] {
    return this.controls.invoicingUnit;
  }

  get discount(): LineFormModel['discount'] {
    return this.controls.discount;
  }

  get invoicingCategoryType(): LineFormModel['invoicingCategoryType'] {
    return this.controls.invoicingCategoryType;
  }

  constructor() {
    super({
      quantity: new FormControl(1, LineForm.FIVE_DECIMALS_VALIDATOR),
      totalExcludingTaxes: new FormControl<number>(null, {
        validators: LineForm.TWO_DECIMALS_VALIDATOR,
        nonNullable: true,
      }),
      unitAmount: new FormControl<number>(null, {
        validators: LineForm.FIVE_DECIMALS_VALIDATOR,
        nonNullable: true,
      }),
      vatType: new FormGroup({
        code: new FormControl<string>(null, { nonNullable: true }),
      }),
      invoicingUnit: new FormGroup({
        id: new FormControl<number | null>(null),
      }),
      articleId: new FormControl<number | null>(null),
      discount: new DiscountForm(/*enableValidation:*/ true),
      invoicingCategoryType: new FormControl<InvoicingCategoriesEnum | null>(
        null,
      ),
    });
  }

  override setInvoicingSavedStatusValidators(): void {
    this.description.setValidators(Validators.required);
    this.description.updateValueAndValidity(FormUtils.shouldNotEmitEvent);
    this.unitAmount.setValidators([
      Validators.required,
      LineForm.FIVE_DECIMALS_VALIDATOR,
    ]);
    this.unitAmount.updateValueAndValidity(FormUtils.shouldNotEmitEvent);
    this.quantity.setValidators([
      Validators.required,
      LineForm.FIVE_DECIMALS_VALIDATOR,
    ]);
    this.quantity.updateValueAndValidity(FormUtils.shouldNotEmitEvent);
    this.updateValueAndValidity(FormUtils.shouldNotEmitEvent);
    this.totalExcludingTaxes.setValidators([
      Validators.required,
      LineForm.TWO_DECIMALS_VALIDATOR,
    ]);
    this.totalExcludingTaxes.updateValueAndValidity({
      onlySelf: false,
      emitEvent: false,
    });
  }

  setTotalExcludingTaxes(): void {
    this.totalExcludingTaxes.setValue(
      this.unitAmount.value && this.quantity.value
        ? Number(
            Big(this.unitAmount.value).mul(Big(this.quantity.value)).toFixed(2),
          )
        : 0,
    );
  }

  setUnitAmount(): void {
    this.unitAmount.setValue(
      this.totalExcludingTaxes.value ?? 0,
      // This function is called only for standard template for
      // which the unit amount value is not visible on screen
      // so there is no point to emit event
      FormUtils.shouldNotEmitEvent,
    );
  }
}
