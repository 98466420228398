import {
  AfterViewInit,
  Directive,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { MatMenu, MatMenuTrigger } from '@angular/material/menu';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { tap } from 'rxjs/operators';

import { TableColumnFilterForm } from './table-column-filter.form';

@UntilDestroy()
@Directive()
export abstract class TableColumnFilterBaseDirective<
  T extends TableColumnFilterForm<V>,
  V,
> implements OnInit, AfterViewInit
{
  @Input() filterKey!: string;

  @Input() set filterValue(value: string) {
    const currentFilterValue = this._filterValue;
    this._filterValue = value;
    if (value) {
      this.form?.fromParam(value);
    } else if (currentFilterValue) {
      this.form?.resetFilters();
    }
  }
  get filterValue(): string {
    return this._filterValue;
  }
  private _filterValue: string;

  @Output() readonly valueChange = new EventEmitter<V>();

  @ViewChild(MatMenu, { static: true }) menu: MatMenu;

  form: T;

  constructor(protected readonly menuTrigger: MatMenuTrigger) {}

  ngOnInit(): void {
    this.initForm();
  }

  ngAfterViewInit(): void {
    this.resetDirtyFormOnClose();
  }

  apply(): void {
    this.valueChange.emit(this.form.toOutput());
    this.form.markAsPristine();
    this.menuTrigger.closeMenu();
  }

  protected resetToLastPristineState(): void {
    if (this.filterValue) {
      this.form.fromParam(this.filterValue);
    } else {
      this.form.resetFilters();
    }
    this.form.markAsPristine();
  }

  private resetDirtyFormOnClose(): void {
    this.menu.closed
      .pipe(
        tap(() => {
          if (!this.form.pristine) {
            this.resetToLastPristineState();
          }
        }),
        untilDestroyed(this),
      )
      .subscribe();
  }

  protected abstract initForm(): void;
  abstract reset(): void;
}
