import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, catchError, map, Observable, take, tap } from 'rxjs';
import {
  GetOptions,
  PaginationData,
  RequiredGetOptions,
} from 'tiime-components';

import { ApiAlertError } from '@decorators/api-alert-error';
import { UserPermissionNameEnum } from '@enums/users';
import { HttpHelper } from '@helpers';
import {
  PermissionAttribute,
  PermissionAttributeApiContract,
  RoleBasedPermission,
  RoleBasedPermissionApiContract,
  User,
  UserWithPermissions,
  UserWithPermissionsApiContract,
} from '@models/user';

@Injectable({
  providedIn: 'root',
})
export class AclService {
  private readonly currentUserPermissions$ = new BehaviorSubject<
    UserWithPermissions | undefined | null
  >(undefined);

  constructor(private readonly httpClient: HttpClient) {}

  getCurrentUserPermissions(): Observable<UserWithPermissions> {
    return this.currentUserPermissions$;
  }

  userHasPermission(
    permissionNeeded: UserPermissionNameEnum[],
  ): Observable<boolean> {
    return this.currentUserPermissions$.pipe(
      take(1),
      map(user => {
        return user.permissionAttributes
          .filter(permission => permission.permit === true)
          .some(permission => permissionNeeded.includes(permission.fullName));
      }),
    );
  }

  getUserPermissions(): Observable<UserWithPermissions> {
    return this.httpClient
      .get<UserWithPermissionsApiContract>(
        'api/v1/companies/{companyId}/users/me/permissions',
      )
      .pipe(
        map(json => UserWithPermissions.fromJson(json)),
        tap(user => this.currentUserPermissions$.next(user)),
        catchError(error => {
          this.currentUserPermissions$.next(null);
          throw error;
        }),
      );
  }

  @ApiAlertError()
  getPermissionsForUser(userId: number): Observable<UserWithPermissions> {
    const url = `api/v1/companies/{companyId}/users/${userId}/permissions`;
    return this.httpClient
      .get<UserWithPermissionsApiContract>(url)
      .pipe(map(json => UserWithPermissions.fromJson(json)));
  }

  @ApiAlertError()
  getUsersPermissions(
    getOptions: RequiredGetOptions<'range'>,
  ): Observable<PaginationData<UserWithPermissions>> {
    const url = 'api/v1/companies/{companyId}/users_permissions';

    const partialOptions = new GetOptions(getOptions).toHttpGetOptions();
    const options = {
      params: new HttpParams({
        fromObject: partialOptions.params,
      }),
      headers: {
        ...partialOptions.headers,
      },
    };

    return this.httpClient
      .get(url, { ...options, observe: 'response' })
      .pipe(
        HttpHelper.mapToPaginationData(
          getOptions.range,
          (userJson: UserWithPermissionsApiContract) =>
            UserWithPermissions.fromJson(userJson),
        ),
      );
  }

  @ApiAlertError()
  changeUserAccess(userId: number, access: boolean): Observable<void> {
    const url = `api/v1/companies/{companyId}/users/${userId}/access`;
    return this.httpClient.patch<void>(url, { app_access: access });
  }

  @ApiAlertError()
  updateUserPermissions(
    userId: number,
    permissionAttributes: PermissionAttribute[],
  ): Observable<PermissionAttribute[]> {
    const url = `api/v1/companies/{companyId}/users/${userId}/permissions`;
    return this.httpClient
      .put<PermissionAttributeApiContract[]>(
        url,
        permissionAttributes.map(attr => PermissionAttribute.toJson(attr)),
      )
      .pipe(map(json => json.map(attr => PermissionAttribute.fromJson(attr))));
  }

  @ApiAlertError()
  sendInvitation(userId: number): Observable<void> {
    const url = `api/v1/companies/{companyId}/users/${userId}/invitations`;
    return this.httpClient.post<void>(url, {
      features: ['apps_invitation_generique'],
      channel: 'email',
      with_formation: false,
    });
  }

  @ApiAlertError()
  getRoleBasedPermissions(): Observable<RoleBasedPermission[]> {
    return this.httpClient
      .get<RoleBasedPermissionApiContract[]>(
        'api/v1/companies/{companyId}/role_based_permissions',
      )
      .pipe(map(json => json.map(attr => RoleBasedPermission.fromJson(attr))));
  }

  @ApiAlertError()
  setSuperAdmin(user: User): Observable<UserWithPermissions> {
    const url = `api/v1/companies/{companyId}/users/${user.id}/set_super_admin`;
    return this.httpClient
      .put<UserWithPermissionsApiContract>(url, User.toJson(user))
      .pipe(map(json => UserWithPermissions.fromJson(json)));
  }

  resetUser(): void {
    this.currentUserPermissions$.next(undefined);
  }
}
