import { OperationType, StandardDocumentCategory } from '@enums';
import { Category } from '@models';

export enum TimelineItemTypeEnum {
  BankTransaction = 'bank_transaction',
  Receipt = 'receipt',
  AdvancedExpense = 'advanced_expense',
}

export interface LabelTimelineAdditionalData {
  type: 'operation_type' | 'document_category' | 'bank_name';
  value: OperationType | StandardDocumentCategory | 'string';
}

export enum TimelineItemDirection {
  Debit = 'debit',
  Credit = 'credit',
}

export interface LabelTimelineLinkedEntity {
  type: TimelineItemTypeEnum;
  value: { id: number };
}

export interface LabelTimelineItemApiContract {
  id: number;
  type: TimelineItemTypeEnum;
  date: string;
  wording: string;
  document_category: string;
  amount: {
    value: number;
    currency: string;
  };
  linked_entities: LabelTimelineLinkedEntity[];
  operation_type: OperationType;
  additional_data: LabelTimelineAdditionalData[];
  direction: TimelineItemDirection;
}

export class LabelTimelineItem {
  constructor(
    public id?: number,
    public type?: TimelineItemTypeEnum,
    public date?: string,
    public wording?: string,
    public amount?: { value: number; currency: string },
    public linkedEntities?: LabelTimelineLinkedEntity[],
    public additionalData?: LabelTimelineAdditionalData[],
    public direction?: TimelineItemDirection,
  ) {}

  get documentCategory(): Category {
    const categoryName = this.additionalData.find(
      data => data.type === 'document_category',
    )?.value;

    return categoryName ? new Category(null, categoryName) : null;
  }

  get operationType(): OperationType {
    return this.additionalData.find(data => data.type === 'operation_type')
      ?.value as OperationType;
  }

  get bankName(): string {
    return this.additionalData.find(data => data.type === 'bank_name')
      ?.value as string;
  }

  public static fromJson(
    json: LabelTimelineItemApiContract,
  ): LabelTimelineItem {
    return new LabelTimelineItem(
      json.id,
      json.type,
      json.date,
      json.wording,
      json.amount,
      json.linked_entities,
      json.additional_data,
      json.direction,
    );
  }
}
