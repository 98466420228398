import { BankTransactionStatusCode } from '@enums';

export const DEFAULT_BANK_TRANSACTION_FAILED_MESSAGE =
  'Vérifiez vos paramètres de CB.';

export const BANK_TRANSACTION_FAILED_MESSAGE_BY_STATUS_CODE = new Map<
  BankTransactionStatusCode,
  string
>([
  [
    BankTransactionStatusCode.allowable_number_of_pin_tries_exceeded,
    'Votre CB est bloquée. Changez de PIN dans l’application mobile pour la débloquer.',
  ],
  [
    BankTransactionStatusCode.expired_card,
    'Votre CB est périmée. Commandez en une nouvelle !',
  ],
  [
    BankTransactionStatusCode.insufficient_funds,
    'Votre solde est insuffisant.',
  ],
  [BankTransactionStatusCode.lost_card, 'Votre CB est en opposition'],
  [BankTransactionStatusCode.stolen_card, 'Votre CB est en opposition'],
  [
    BankTransactionStatusCode.card_not_yet_activated,
    `Votre CB n'était pas activée`,
  ],
  [
    BankTransactionStatusCode.capture_card_capture,
    'Votre CB était expirée ou bloquée définitivement',
  ],
  [BankTransactionStatusCode.do_not_honor, 'Une erreur est survenue.'],
  [
    BankTransactionStatusCode.invalid_pin,
    'Vous avez tapé un mauvais code PIN. Attention, 3 codes faux successifs bloqueront votre CB.',
  ],
  [
    BankTransactionStatusCode.transaction_not_permitted_to_cardholder,
    `Ce type de transaction n'était pas activé`,
  ],
  [
    BankTransactionStatusCode.transaction_not_permitted_to_acquirer_terminal,
    'Erreur due à votre contrepartie. Contactez-la pour comprendre pourquoi.',
  ],
  [
    BankTransactionStatusCode.exceeds_withdrawal_amout_limit,
    'Vous avez atteint votre plafond de retrait',
  ],
  [
    BankTransactionStatusCode.card_destroyed,
    'Votre CB était expirée ou bloquée définitivement',
  ],
]);
