import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  inject,
  Input,
  OnInit,
} from '@angular/core';
import { Mapper } from '@manakincubber/tiime-utils';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import moment, { MomentInput } from 'moment';
import { BehaviorSubject, combineLatest } from 'rxjs';
import { filter, startWith, tap } from 'rxjs/operators';

import { BankTransferRecurrency } from '@enums';
import {
  bankTransferFrequencyOptions,
  BankTransferParametersForm,
} from '@forms';

@UntilDestroy()
@Component({
  selector: 'app-bank-transfer-parameters',
  templateUrl: './bank-transfer-parameters.component.html',
  styleUrls: ['./bank-transfer-parameters.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BankTransferParametersComponent implements OnInit {
  // eslint-disable-next-line @angular-eslint/no-input-rename
  @Input('form') set setForm(form: BankTransferParametersForm) {
    this.form = form;
  }

  private readonly cdr = inject(ChangeDetectorRef);

  form: BankTransferParametersForm;

  readonly minDate = new Date();
  readonly untilDateMin$ = new BehaviorSubject<Date | null>(null);
  readonly frequencyOptions = bankTransferFrequencyOptions;

  readonly mapToShouldDisplayFrequencyParameters: Mapper<
    string | null,
    boolean
  > = frequencyValue =>
    frequencyValue && frequencyValue !== BankTransferRecurrency.Once;

  ngOnInit(): void {
    this.handleNullDateFromDocument();
    this.handleIndefinitelyChange();
    this.handleFrequencyChange();
    this.handleUntilDateMinNeedUpdate();
  }

  trackByOptionValue(
    _: number,
    option: { value: string; label: string },
  ): string {
    return option.value;
  }

  private handleNullDateFromDocument(): void {
    if (this.form.date.value === null) {
      this.form.date.enable();
      this.form.date.setValue(new Date());
    }
  }

  private handleFrequencyChange(): void {
    this.form.frequency.valueChanges
      .pipe(
        startWith(this.form.frequency.value),
        tap(frequency => {
          if (!frequency) {
            this.form.frequency.setValue(BankTransferRecurrency.Once);
          }
          if (frequency === BankTransferRecurrency.Once) {
            this.form.untilDate.disable();
            this.form.indefinitely.disable();
          } else {
            if (!this.form.indefinitely.value) {
              this.form.untilDate.enable();
            }
            this.form.indefinitely.enable();
          }
          this.cdr.markForCheck();
        }),
        untilDestroyed(this),
      )
      .subscribe();
  }

  private handleIndefinitelyChange(): void {
    this.form.indefinitely.valueChanges
      .pipe(
        startWith(this.form.indefinitely.value),
        tap(indefinitely => {
          if (indefinitely) {
            this.form.untilDate.disable();
          } else if (
            this.form.frequency.value !== BankTransferRecurrency.Once
          ) {
            this.form.untilDate.enable();
          }
        }),
        untilDestroyed(this),
      )
      .subscribe();
  }

  private handleUntilDateMinNeedUpdate(): void {
    combineLatest([
      this.form.date.valueChanges.pipe(startWith(this.form.date.value)),
      this.form.frequency.valueChanges.pipe(
        startWith(this.form.frequency.value),
      ),
      this.form.indefinitely.valueChanges.pipe(
        startWith(this.form.indefinitely.value),
      ),
    ])
      .pipe(
        filter(
          ([, frequency, indefinitely]: [
            Date,
            BankTransferRecurrency,
            boolean,
          ]) =>
            frequency !== BankTransferRecurrency.Once && indefinitely === false,
        ),
        tap(([date, frequency]) => {
          const newMinDate = this.computeUntilDateMin(date, frequency);
          this.untilDateMin$.next(newMinDate);
          this.updateUntilDateValue(newMinDate);
        }),
        untilDestroyed(this),
      )
      .subscribe();
  }

  private computeUntilDateMin(
    date: Date,
    frequency: BankTransferRecurrency,
  ): Date {
    return moment(date)
      .add(1, frequency === BankTransferRecurrency.Weekly ? 'week' : 'month')
      .toDate();
  }

  private updateUntilDateValue(newMin: Date): void {
    if (
      !this.form.untilDate.value ||
      moment(newMin).isAfter(this.form.untilDate.value as MomentInput)
    ) {
      this.form.untilDate.setValue(newMin);
    }
  }
}
