import { RuntimeEnvironment } from './runtime-environment';

/**
 * Values for this environment are loaded dynamically at runtime
 */
export const environment: RuntimeEnvironment = {
  production: false,
  dynamic: true,
  ENV_NAME: '',
  AUTH0_DOMAIN: '',
  AUTH0_CLIENT_ID: '',
  AUTH0_REALM: '',
  AUTH0_AUDIENCE: '',
  AUTH0_WHITE_DOMAINS: [],
  API_URL: '',
  GOOGLE_API_KEY: '',
  SENTRY_DNS: '',
  TRACKING: { ENABLED: false },
  CARE_FORM_URL: '',
  BI_WEBVIEW_URL_BASE: '',
  BI_API_BASE: '',
  BI_CLIENT_ID: '',
  QONTO_CONNECT_REDIRECT_URI: '',
  QONTO_CONNECT_WEBVIEW_URL: '',
  QONTO_CONNECT_CLIENT_ID: '',
  ONBOARDING_URL: '',
  GO_CARDLESS: { CLIENT_ID: '', OAUTH_BASE_URL: '', MANAGE_URL: '' },
};
