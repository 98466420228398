<div class="year-container">
  <app-year-item
    [year]="years[0]"
    [months]="months | slice: 0:12"
    [boundaries]="boundaries"
    (changeYear)="decrementYears()"
    (monthClicked)="onClick($event)"
  ></app-year-item>
</div>
<div class="year-container">
  <app-year-item
    [year]="years[1]"
    [months]="months | slice: 12:24"
    [isRightPanel]="true"
    [boundaries]="boundaries"
    (changeYear)="incrementYears()"
    (monthClicked)="onClick($event)"
  ></app-year-item>
</div>
<div class="action">
  <span class="action__hint">
    Veuillez sélectionner une période maximum de 12 mois
  </span>
  <button
    class="action__button"
    tiime-button
    accent
    small
    [disabled]="!boundaries[0] || !boundaries[1]"
    (click)="save()"
  >
    Enregistrer
  </button>
</div>
