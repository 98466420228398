import { inject, Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Observable, switchMap } from 'rxjs';

import { DocumentsService } from '@core';
import { StandardDocumentCategoryIdentifier } from '@core/enum';
import { Document, DocumentPaymentStatusEnum } from '@core/models';

import {
  DocumentBulkMoveDialogComponent,
  DocumentBulkMoveDialogData,
  DocumentBulkMoveDialogResponse,
} from './document-bulk-move-dialog.component';

interface DocumentBulkMoveDialogSortedDocuments {
  otherDocumentIds: number[];
  expenseReportsDocumentIds: number[];
  scheduledPaymentsDocumentIds: number[];
  matchedDocumentIds: number[];
}

@Injectable({
  providedIn: 'root',
})
export class DocumentBulkMoveDialogService {
  private readonly dialog = inject(MatDialog);
  private readonly documentsService = inject(DocumentsService);

  openDialog(
    selectedDocuments: Document[],
  ): Observable<DocumentBulkMoveDialogResponse> {
    const sortedDocuments = this.sortDocuments(selectedDocuments);

    return this.documentsService
      .getLockedAndUnlockedDocumentIds([
        ...sortedDocuments.matchedDocumentIds,
        ...sortedDocuments.otherDocumentIds,
      ])
      .pipe(
        switchMap(({ documentLockedIds, documentsUnlockedIds }) =>
          this.dialog
            .open<
              DocumentBulkMoveDialogComponent,
              DocumentBulkMoveDialogData,
              DocumentBulkMoveDialogResponse
            >(DocumentBulkMoveDialogComponent, {
              data: {
                ...sortedDocuments,
                unlockedDocumentIds: documentsUnlockedIds,
                lockedDocumentIds: documentLockedIds,
                disabledCategories: this.getDisabledCategories(
                  sortedDocuments,
                  documentLockedIds,
                ),
              },
            })
            .afterClosed(),
        ),
      );
  }

  private sortDocuments(
    selectedDocuments: Document[],
  ): DocumentBulkMoveDialogSortedDocuments {
    const otherDocumentIds: number[] = [];
    const expenseReportsDocumentIds: number[] = [];
    const scheduledPaymentsDocumentIds: number[] = [];
    const matchedDocumentIds: number[] = [];
    selectedDocuments.forEach(doc => {
      if (
        doc.category.identifier ===
        StandardDocumentCategoryIdentifier.EXPENSE_REPORT
      ) {
        expenseReportsDocumentIds.push(doc.id);
        return;
      }
      if (doc.paymentStatus === DocumentPaymentStatusEnum.SCHEDULED) {
        scheduledPaymentsDocumentIds.push(doc.id);
        return;
      }
      if (doc.hasBankTransactions) {
        matchedDocumentIds.push(doc.id);
        return;
      }
      otherDocumentIds.push(doc.id);
    });

    return {
      otherDocumentIds,
      expenseReportsDocumentIds,
      scheduledPaymentsDocumentIds,
      matchedDocumentIds,
    };
  }

  private getDisabledCategories(
    sortedDocuments: DocumentBulkMoveDialogSortedDocuments,
    documentLockedIds: number[],
  ): Map<StandardDocumentCategoryIdentifier, string> {
    const disabledCategories = new Map<
      StandardDocumentCategoryIdentifier,
      string
    >();

    const numberOfDocuments = [
      ...sortedDocuments.scheduledPaymentsDocumentIds,
      ...sortedDocuments.matchedDocumentIds,
      ...sortedDocuments.otherDocumentIds,
    ].length;

    if (numberOfDocuments === documentLockedIds.length) {
      disabledCategories.set(
        StandardDocumentCategoryIdentifier.EXTERNAL_INVOICE,
        'Il est impossible de déplacer ces documents dans cet espace<br>car ils sont verrouillés par votre comptable',
      );
    }

    if (numberOfDocuments === sortedDocuments.matchedDocumentIds.length) {
      disabledCategories.set(
        StandardDocumentCategoryIdentifier.ADVANCED_EXPENSE,
        'Il est impossible de déplacer un document dans cet espace<br>quand il est déjà matché',
      );
    }

    if (
      numberOfDocuments === sortedDocuments.scheduledPaymentsDocumentIds.length
    ) {
      const reason =
        'Il est impossible de déplacer un document dans cet espace<br>quand un paiement est planifié';
      disabledCategories.set(
        StandardDocumentCategoryIdentifier.ADVANCED_EXPENSE,
        reason,
      );
      disabledCategories.set(
        StandardDocumentCategoryIdentifier.EXTERNAL_INVOICE,
        reason,
      );
    }

    return disabledCategories;
  }
}
