import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { map, Observable, of } from 'rxjs';
import { switchMap } from 'rxjs/operators';

import { MatchingType } from '@enums';
import { filterNotNullOrUndefined } from '@helpers';
import { MatchableDocument } from '@matching/types/matchable-document';
import { Document, DocumentTypeEnum, ScheduledPayout } from '@models';
import {
  BankTransaction,
  BankTransactionImputation,
} from '@models/bank-transaction';
import { Label } from '@models/labels';
import {
  LinkedEntityBankTransaction,
  LinkedEntityValueImputation,
} from '@models/linked-entities';

import { SelectingLabelDialogService } from '../select-label-dialog/selecting-label-dialog.service';
import { MatchedItem } from '../types/matched-item';
import { MatchingSource } from '../types/matching-source';
import {
  MatchingDialogComponent,
  MatchingDialogData,
  MatchingDialogResponse,
} from './matching-dialog.component';

@Injectable({
  providedIn: 'root',
})
export class MatchingDialogService {
  constructor(
    private readonly matDialog: MatDialog,
    private readonly selectingLabelDialogService: SelectingLabelDialogService,
  ) {}

  openMatchingDialog(
    dialogData: MatchingDialogData,
  ): Observable<MatchingDialogResponse> {
    return this.matDialog
      .open<
        MatchingDialogComponent,
        MatchingDialogData,
        MatchingDialogResponse
      >(MatchingDialogComponent, {
        data: {
          ...dialogData,
          matchingTypes: dialogData.matchingTypes || [
            MatchingType.bankTransactions,
          ],
          allowMultipleSelection: dialogData.allowMultipleSelection !== false,
        },
        width: '1200px',
        height: 'calc(100% - 44px)',
      })
      .afterClosed()
      .pipe(
        filterNotNullOrUndefined(),
        switchMap(matchingDialogResponse => {
          const matchingSource = matchingDialogResponse.matchingSource;
          const matchedItems = matchingDialogResponse.matchedItems;

          if (
            !matchingSource ||
            !this.labelOverloadCheckNeeded(matchingSource, matchedItems)
          ) {
            // TODO(TA-28392): remove this check when TA-28392 will be ready
            return of(matchingDialogResponse);
          }

          /**
           * Whether we are matching transactions (to documents) or documents (to transactions)
           */
          const matchingTransactions = matchingSource instanceof Document;

          return this.selectingLabelDialogService
            .openLabelOverloadDialogIfAvailable({
              transaction: matchingTransactions
                ? (matchedItems[0] as BankTransaction)
                : (matchingSource as BankTransaction),
              document: matchingTransactions
                ? (matchingSource as MatchableDocument)
                : (matchedItems[0] as MatchableDocument),
            })
            .pipe(
              map(labelOverloadDialogResponse => ({
                ...matchingDialogResponse,
                ...labelOverloadDialogResponse,
              })),
            );
        }),
      );
  }

  /**
   * Checks whether the label overload availability should be fetched from the api
   * @param matchingSource The object to which items are to be matched.
   * If it is anything other than a bank transaction or a document, no check should be done.
   * @param matchedItems Bank transactions or documents to be matched.
   * @private
   */
  private labelOverloadCheckNeeded(
    matchingSource: MatchingSource,
    matchedItems: MatchedItem[],
  ): boolean {
    if (
      matchingSource instanceof BankTransactionImputation ||
      matchingSource instanceof ScheduledPayout
    ) {
      return false;
    }

    if (
      // Matching too many items for label overload, or unmatching items
      matchedItems.length !== 1 ||
      // Matching transactions to an expense report
      (matchingSource instanceof Document &&
        matchingSource.type === DocumentTypeEnum.ExpenseReport) ||
      // Matching at least 1 expense report to a bank transaction
      matchedItems.filter(
        item =>
          item instanceof Document &&
          item.type === DocumentTypeEnum.ExpenseReport,
      ).length > 0
    ) {
      return false;
    }

    // Getting transaction either from the source or from the matchedItems
    let transaction: BankTransaction | LinkedEntityBankTransaction['value'];
    if (matchingSource instanceof BankTransaction) {
      transaction = matchingSource;
    } else if (matchedItems[0] instanceof BankTransaction) {
      transaction = matchedItems[0];
    } else if (matchedItems[0] instanceof LinkedEntityBankTransaction) {
      transaction = matchedItems[0].value;
    }

    // Getting document either from the source or from the matchedItems
    let document: Document;
    if (matchingSource instanceof Document) {
      document = matchingSource;
    } else if (matchedItems[0] instanceof Document) {
      document = matchedItems[0];
    }

    if (!transaction || !document) {
      // No transaction or doc, no check needed
      return false;
    }

    return this.checkBetweenBankTransactionImputationsAndDocument(
      transaction.imputations,
      document,
    );
  }

  /**
   * Returns whether it is needed to check overload availability between doc and bank transaction
   * @param imputations Imputations of the transaction the user is trying to match to or from
   * @param document Document the user is trying to match to or from
   * @private
   */
  private checkBetweenBankTransactionImputationsAndDocument(
    imputations: (BankTransactionImputation | LinkedEntityValueImputation)[],
    document: Document,
  ): boolean {
    return (
      imputations.length === 1 &&
      this.labelsAreDifferent(imputations[0].label, document.label)
    );
  }

  /**
   * Returns whether both label are defined and different
   * @param imputationLabel Label of a bank transaction's imputation
   * @param documentLabel Label of a document
   * @private
   */
  private labelsAreDifferent(
    imputationLabel?: Label | LinkedEntityValueImputation['label'],
    documentLabel?: Label,
  ): boolean {
    return (
      !!imputationLabel &&
      !!documentLabel &&
      imputationLabel.id !== documentLabel.id
    );
  }
}
