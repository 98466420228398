import { FormControl, FormGroup, Validators } from '@angular/forms';
import moment, { Moment } from 'moment';

import { SddPayinRecurrencyEnum } from '@models/wallet/payins';

export const SDD_PAYIN_EXCLUDED_DAYS = [
  '0000-01-01',
  '0000-05-01',
  '0000-12-25',
  '0000-12-26',
];

const MIN_DAYS_BEFORE_PAYIN = 3;

export function getSDDPayinMinDate(
  startDate: moment.Moment | string | undefined = moment(),
  minDaysToAddAfterStartDate = MIN_DAYS_BEFORE_PAYIN,
): moment.Moment {
  const plannedSDDPayinDay = moment(startDate).startOf('day');

  while (minDaysToAddAfterStartDate > 0) {
    plannedSDDPayinDay.add(1, 'days');

    const isWeekend = plannedSDDPayinDay.isoWeekday() >= 6;
    const isExcludedDate = SDD_PAYIN_EXCLUDED_DAYS.some(excludedDate =>
      moment(excludedDate).isSame(plannedSDDPayinDay, 'day'),
    );

    if (!isWeekend && !isExcludedDate) {
      minDaysToAddAfterStartDate--;
    }
  }

  return plannedSDDPayinDay;
}

export function getSDDPayinMinDateForReccurency(
  startDate: moment.Moment | string | undefined = moment(),
  minDaysToAddAfterStartDate = MIN_DAYS_BEFORE_PAYIN,
): moment.Moment {
  const plannedSDDPayinDay = moment(startDate)
    .startOf('day')
    .add(minDaysToAddAfterStartDate, 'days');

  // skip weekends and excluded dates
  while (
    plannedSDDPayinDay.isoWeekday() > 5 ||
    SDD_PAYIN_EXCLUDED_DAYS.some(excludedDate => {
      const momentExcludedDate = moment(excludedDate);

      return (
        momentExcludedDate.date() === plannedSDDPayinDay.date() &&
        momentExcludedDate.month() === plannedSDDPayinDay.month()
      );
    })
  ) {
    plannedSDDPayinDay.add(1, 'day');
  }

  return plannedSDDPayinDay;
}

export class CreateSddPayinSequenceForm extends FormGroup {
  get date(): FormControl<Moment> {
    return this.get('date') as FormControl<Moment>;
  }

  get endDate(): FormControl<Moment> {
    return this.get('endDate') as FormControl<Moment>;
  }

  get recurrency(): FormControl<SddPayinRecurrencyEnum> {
    return this.get('recurrency') as FormControl<SddPayinRecurrencyEnum>;
  }

  get indefinitely(): FormControl<boolean> {
    return this.get('indefinitely') as FormControl<boolean>;
  }

  get label(): FormControl<string> {
    return this.get('label') as FormControl<string>;
  }

  constructor() {
    const minDate = getSDDPayinMinDate();

    super({
      date: new FormControl<Moment>(minDate, Validators.required),
      endDate: new FormControl<Moment>(minDate),
      recurrency: new FormControl<SddPayinRecurrencyEnum>(
        { disabled: true, value: SddPayinRecurrencyEnum.ONCE },
        Validators.required,
      ),
      indefinitely: new FormControl<boolean>(false),
      label: new FormControl<string>(''),
    });
  }
}
