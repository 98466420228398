<button
  tiime-button
  neutral
  inverted
  icon
  [disabled]="disabled"
  [mat-dialog-close]="closeValue"
>
  <mat-icon svgIcon="tc_icon-close"></mat-icon>
</button>
