import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TiimePipesModule } from '@manakincubber/tiime-utils';
import { TiimeIconModule } from 'tiime-components';

import { BankTransferPipesModule } from '../../pipes/bank-transfer-pipes.module';
import { BankTransferFrequencyRecapComponent } from './payout-frequency-recap.component';

@NgModule({
  declarations: [BankTransferFrequencyRecapComponent],
  imports: [
    CommonModule,
    BankTransferPipesModule,
    TiimePipesModule,
    TiimeIconModule,
  ],
  exports: [BankTransferFrequencyRecapComponent],
})
export class PayoutFrequencyRecapModule {}
