import { ScrollingModule } from '@angular/cdk/scrolling';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { RouterModule } from '@angular/router';
import { TiimeButtonModule, TiimeLetModule } from 'tiime-components';

import { ContentNavigationLayoutComponent } from './content-navigation-layout.component';

@NgModule({
  declarations: [ContentNavigationLayoutComponent],
  imports: [
    CommonModule,
    ScrollingModule,
    TiimeLetModule,
    TiimeButtonModule,
    MatIconModule,
    RouterModule,
  ],
  exports: [ContentNavigationLayoutComponent],
})
export class ContentNavigationLayoutModule {}
