<tiime-dialog-close-button></tiime-dialog-close-button>

<div mat-dialog-content>
  <img
    class="illu"
    alt="illu delete documents"
    width="192"
    height="192"
    ngSrc="assets/illus/documents/illu_bulk_delete_documents.svg"
  />
  <p class="title">
    Êtes-vous sûr de vouloir
    <br />
    supprimer
    <ng-container [ngPlural]="data.documentIds.length">
      <ng-template ngPluralCase="=0">0 document ?</ng-template>
      <ng-template ngPluralCase="=1">1 document ?</ng-template>
      <ng-template ngPluralCase="other">
        ces {{ data.documentIds.length }} documents ?
      </ng-template>
    </ng-container>
  </p>
  <app-warning-banner
    *ngIf="data.numberOfLockedDocuments"
    [title]="lockedDocumentsBannerText.title"
    [subtitle]="lockedDocumentsBannerText.subtitle"
  ></app-warning-banner>
  <app-warning-banner
    *ngIf="data.numberOfMatchedDocuments"
    [title]="matchedDocumentsBannerText.title"
    [subtitle]="matchedDocumentsBannerText.subtitle"
  ></app-warning-banner>
  <app-warning-banner
    *ngIf="data.numberOfExpenseReports"
    [title]="expenseReportsBannerText.title"
    [subtitle]="expenseReportsBannerText.subtitle"
  ></app-warning-banner>
  <mat-dialog-actions>
    <button tiime-button neutral inverted [mat-dialog-close]="null">
      Annuler
    </button>
    <button
      tiime-button
      accent
      warn
      [disabled]="!data.documentIds.length"
      (click)="validate()"
    >
      Supprimer
    </button>
  </mat-dialog-actions>
</div>
