import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import {
  RequiredGetOptions,
  PaginationData,
  GetOptions,
} from 'tiime-components';

import { HttpHelper } from '@core/helpers';
import {
  CompanyWalletUserRequest,
  CompanyWalletUserRequestApiContract,
  EmployeeContact,
} from '@core/models';
import { TemporaryEncoder } from '@core/temporary-encoder';

@Injectable({
  providedIn: 'root',
})
export class CompanyCreditCardService {
  private readonly walletCompanyResource =
    'api/v1/wallet/companies/{companyId}';
  private readonly companyUserResource = 'api/v1/companies/{companyId}/users';

  constructor(private readonly http: HttpClient) {}

  getCompanyWalletUsers(
    getOptions: RequiredGetOptions<'range'>,
  ): Observable<PaginationData<CompanyWalletUserRequest>> {
    const partialOptions = new GetOptions(getOptions).toHttpGetOptions();
    const options = {
      params: new HttpParams({
        fromObject: partialOptions.params,
        encoder: new TemporaryEncoder(),
      }),
      headers: partialOptions.headers,
    };
    return this.http
      .get<CompanyWalletUserRequestApiContract[]>(
        `${this.companyUserResource}`,
        {
          ...options,
          observe: 'response',
        },
      )
      .pipe(
        HttpHelper.mapToPaginationData(
          getOptions.range,
          (cardRequest: CompanyWalletUserRequestApiContract) =>
            CompanyWalletUserRequest.fromJson(cardRequest),
        ),
      );
  }

  assignCompanyCompanyWalletUser(
    EmployeeContact: EmployeeContact,
  ): Observable<EmployeeContact> {
    return this.http.post<EmployeeContact>(
      `${this.walletCompanyResource}/employees`,
      EmployeeContact,
    );
  }

  allowOrderCard(userId: number, canOrderCard: boolean): Observable<unknown> {
    return this.http.patch(`${this.walletCompanyResource}/users/${userId}`, {
      can_order_card: canOrderCard,
    });
  }
}
