import { TrackingEvent } from '@manakincubber/tiime-tracking';

import { Quote } from '@models';

export const QUOTE_EDITOR_OPENED = 'Quote Editor Opened';

export class QuoteEditorOpened implements TrackingEvent {
  readonly name = QUOTE_EDITOR_OPENED;

  constructor(readonly quote?: Quote) {}
}
