import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { TiimeTooltipModule } from 'tiime-components';

import { LabelDisabledByEnum } from '@enums';

import { IconsModule } from '../../../icons/icons.module';
import { LabelArchivedTextComponent } from '../label-archived-text/label-archived-text.component';

@Component({
  standalone: true,
  imports: [
    CommonModule,
    IconsModule,
    TiimeTooltipModule,
    LabelArchivedTextComponent,
  ],
  selector: 'app-label-archived-icon',
  templateUrl: './label-archived-icon.component.html',
  styleUrls: ['./label-archived-icon.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LabelArchivedIconComponent {
  readonly LabelDisabledByEnum = LabelDisabledByEnum;

  @Input() labelDisabledBy: LabelDisabledByEnum;
  @Input() disableTooltip: boolean;
}
