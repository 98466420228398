import { HttpClient } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { Observable, map } from 'rxjs';

import {
  WalletOnboardingCompany,
  WalletOnboardingCompanyApiContract,
} from '@core/models';

@Injectable({
  providedIn: 'root',
})
export class WalletOnboardingService {
  readonly resource = `api/v1/business_onboarding/companies/{companyId}`;
  readonly http = inject(HttpClient);

  getOnboardedCompany(): Observable<WalletOnboardingCompany> {
    return this.http
      .get<WalletOnboardingCompanyApiContract>(this.resource)
      .pipe(
        map((company: WalletOnboardingCompanyApiContract) => {
          return WalletOnboardingCompany.fromJson(company);
        }),
      );
  }
}
