import { Pipe, PipeTransform } from '@angular/core';

import { AmountMetadataValue, Metadata } from '@models/documents';

@Pipe({
  name: 'documentMetadataCurrency',
})
export class DocumentMetadataCurrencyPipe implements PipeTransform {
  transform(metadata: Metadata[], key: string): string {
    const amountData = metadata?.find(meta => meta.key === key);
    return amountData?.value
      ? (amountData?.value as AmountMetadataValue).currency
      : 'EUR';
  }
}
