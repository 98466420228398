import { StandardDocumentCategoryIdentifier } from '@core/enum';

import { AmpliDocumentCategory, COMPANY_GROUP_NAME } from './constants';
import { prefixCompanyId } from './id-prefix';
import { ExtraWithGroups } from './plugins';
import { DetailDocumentAccedeProperties } from './wrapper';

export function optionsWithCompanyGroupExtra(
  companyId: number,
): ExtraWithGroups {
  return {
    extra: { groups: { [COMPANY_GROUP_NAME]: prefixCompanyId(companyId) } },
  };
}

export function mapDocumentCategoryIdentifier(
  documentCategoryIdentifier: StandardDocumentCategoryIdentifier,
): DetailDocumentAccedeProperties['type_document'] {
  if (AmpliDocumentCategory.has(documentCategoryIdentifier)) {
    return AmpliDocumentCategory.get(documentCategoryIdentifier);
  } else {
    return 'autres';
  }
}
