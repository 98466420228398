import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  Input,
} from '@angular/core';

@Component({
  selector: 'tiime-table-anchor',
  templateUrl: './table-anchor.component.html',
  styleUrls: ['./table-anchor.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TableAnchorComponent {
  @Input() behavior = 'auto';
  @Input() block = 'start';

  constructor(private element: ElementRef) {}

  scrollTo(): void {
    this.element.nativeElement.scrollIntoView({
      behavior: this.behavior,
      block: this.block,
    });
  }
}
