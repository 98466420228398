import { Pipe, PipeTransform } from '@angular/core';

import { AmountMetadata } from '@core/models/documents';

@Pipe({
  name: 'metadataTotalAmount',
})
export class MetadataTotalAmountPipe implements PipeTransform {
  transform(metadata: AmountMetadata[]): AmountMetadata {
    return metadata.find(m => m.key === 'total');
  }
}
