import { Injectable } from '@angular/core';
import { ActivatedRoute, Router, UrlTree } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { Observable, of } from 'rxjs';
import {
  filter,
  map,
  skipWhile,
  switchMap,
  take,
  tap,
  withLatestFrom,
} from 'rxjs/operators';

import { REDIRECTION_TARGET_APP } from '@constants';
import {
  loadWalletAccount,
  walletAccountSelector,
} from '@store/wallet-account';
import { selectedCompanySelector } from '@user-store';

@Injectable({
  providedIn: 'root',
})
export class TiimeBusinessOnboardedGuard {
  constructor(
    private readonly route: ActivatedRoute,
    private readonly router: Router,
    private readonly store: Store,
  ) {}

  canActivateChild(): Observable<boolean | UrlTree> {
    return this.isTiimeBusinessOnboarded();
  }

  canLoadChild(): Observable<boolean | UrlTree> {
    return this.isTiimeBusinessOnboarded();
  }

  private isTiimeBusinessOnboarded(): Observable<boolean | UrlTree> {
    return this.store.pipe(
      select(selectedCompanySelector),
      // Because the guards run asynchronously, the selected company may not be have been set already
      // (the companies data is dispatched by UserGuard).
      // To be sure the needed data is ready, we need to wait for the store sending a defined company.
      skipWhile(selectedCompany => !selectedCompany),
      take(1),
      switchMap(selectedCompany =>
        selectedCompany.walletAccess
          ? this.areFatcaPending(selectedCompany.id)
          : of(false),
      ),
      withLatestFrom(this.route.queryParamMap),
      map(([fatcaPending, queryParamsMap]) => {
        if (fatcaPending) {
          return this.router.createUrlTree(['tiime-business-onboarding'], {
            queryParams: {
              [REDIRECTION_TARGET_APP.paramName]: queryParamsMap.get(
                REDIRECTION_TARGET_APP.paramName,
              ),
            },
          });
        }
        return true;
      }),
    );
  }

  private areFatcaPending(companyId: number): Observable<boolean> {
    this.loadWalletAccountIfNeeded();
    return this.store.select(walletAccountSelector).pipe(
      skipWhile(
        walletAccount =>
          !walletAccount || walletAccount.companyId !== companyId,
      ),
      map(({ fatcaPending }) => fatcaPending),
    );
  }

  private loadWalletAccountIfNeeded(): void {
    this.store
      .pipe(
        select(walletAccountSelector),
        take(1),
        filter(walletAccount => !walletAccount),
        tap(() => this.store.dispatch(loadWalletAccount())),
      )
      .subscribe();
  }
}
