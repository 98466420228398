import { Directive } from '@angular/core';
import { UntilDestroy } from '@ngneat/until-destroy';

import { TableColumnMultipleFilterBaseDirective } from './table-column-multiple-filter-base.directive';
import {
  TableColumnMultipleFilter,
  TableColumnMultipleFilterForm,
} from './table-column-multiple-filter.form';

@UntilDestroy()
@Directive()
export abstract class TableColumnMultipleFilterDirective extends TableColumnMultipleFilterBaseDirective<
  TableColumnMultipleFilterForm,
  TableColumnMultipleFilter
> {
  reset(): void {
    this.form.resetFilters();
    this.valueChange.emit(this.form.toOutput());
    this.menuTrigger.closeMenu();
  }
}
