import { FormArray, FormControl } from '@angular/forms';
import { MailForm } from 'tiime-components';

import { InvoicingDocumentMail } from '@models';

export class InvoicingDocumentMailForm extends MailForm {
  public extraAttachments = new FormArray([]);
  public filename = new FormControl();
  public documentId = new FormControl();
  public dueDate = new FormControl();
  public totalIncludingTaxes = new FormControl();

  constructor() {
    super();
    this.addControl('filename', this.filename);
    this.addControl('documentId', this.documentId);
    this.addControl('dueDate', this.dueDate);
    this.addControl('totalIncludingTaxes', this.totalIncludingTaxes);
    this.addControl('extraAttachments', this.extraAttachments);
  }

  toMail(): InvoicingDocumentMail {
    const rawValue = this.getRawValue() as InvoicingDocumentMail;
    return {
      ...rawValue,
      extraAttachments: rawValue.extraAttachments.map(extraAttachment => ({
        name: extraAttachment.name,
        content: extraAttachment.content.replace(
          /^(?:data:[A-Za-z0-9\-./]*;base64,)/,
          '',
        ),
      })),
    };
  }
}
