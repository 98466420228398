import { CommonModule, NgOptimizedImage } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  Inject,
  OnInit,
} from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Mapper, TiimePipesModule } from '@manakincubber/tiime-utils';
import { UntilDestroy } from '@ngneat/until-destroy';
import { Store } from '@ngrx/store';
import {
  Observable,
  filter,
  finalize,
  iif,
  map,
  switchMap,
  takeWhile,
  tap,
} from 'rxjs';
import {
  TiimeButtonModule,
  TiimeDialogModule,
  TiimeLetModule,
} from 'tiime-components';

import { StandardDocumentCategoryIdentifier } from '@core/enum';
import { filterNotNullOrUndefined } from '@core/helpers';
import { Category, Document } from '@core/models';
import {
  BannerContent,
  BannerContentService,
  CategoriesService,
  DocumentsService,
} from '@core/services';
import {
  addCategory,
  categoriesSelector,
  loadCategories,
  removeDocumentFromStore,
} from '@core/store';
import { IconsModule, InfoBannerComponent } from '@shared';

import { DisabledCategoryWithReason } from '../category-selector/category-select.component';
import { CategorySelectModule } from '../category-selector/category-select.module';
import { LockedDocumentsWarningComponent } from '../locked-documents-warning/locked-documents-warning.component';

export interface DocumentBulkMoveDialogData {
  unlockedDocumentIds: number[];
  lockedDocumentIds: number[];
  otherDocumentIds: number[];
  expenseReportsDocumentIds: number[];
  scheduledPaymentsDocumentIds: number[];
  matchedDocumentIds: number[];

  disabledCategories: Map<StandardDocumentCategoryIdentifier, string>;
}

export interface DocumentBulkMoveDialogResponse {
  numberOfUpdatedDocuments: number;
}

@UntilDestroy()
@Component({
  standalone: true,
  imports: [
    CommonModule,
    LockedDocumentsWarningComponent,
    TiimeButtonModule,
    NgOptimizedImage,
    TiimeDialogModule,
    IconsModule,
    CategorySelectModule,
    TiimeLetModule,
    InfoBannerComponent,
    TiimePipesModule,
  ],
  selector: 'app-document-bulk-move-dialog',
  templateUrl: './document-bulk-move-dialog.component.html',
  styleUrls: ['./document-bulk-move-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DocumentBulkMoveDialogComponent implements OnInit {
  readonly StandardDocumentCategoryIdentifier =
    StandardDocumentCategoryIdentifier;

  readonly categories$ = this.store.select(categoriesSelector).pipe(
    filterNotNullOrUndefined(),
    map(categories =>
      categories.filter(
        category =>
          category.identifier !==
          StandardDocumentCategoryIdentifier.EXPENSE_REPORT,
      ),
    ),
    tap(categories => (this.selectedCategory = categories[0] ?? null)),
  );

  readonly disabledCategories$: Observable<DisabledCategoryWithReason[]> =
    this.categories$.pipe(
      filterNotNullOrUndefined(),
      filter(() => !!this.data.disabledCategories),
      map(categories =>
        categories
          .filter(category =>
            Array.from(this.data.disabledCategories.keys()).includes(
              category.identifier,
            ),
          )
          .map(category => ({
            category: category,
            reason: this.data.disabledCategories.get(category.identifier),
          })),
      ),
    );

  selectedDocuments: Document[];
  selectedCategory: Category;
  actionsDisabled = false;
  expenseReportsLength: number;

  expenseReportsBannerText: BannerContent | null;
  matchedDocumentsBannerText: BannerContent | null;
  scheduledPaymentBannerText: BannerContent | null;

  readonly mapToNumberOfDocuments: Mapper<Category, number> = (
    selectedCategory: Category,
  ) => this.getDocumentsIdToUpdate(selectedCategory).length;

  readonly mapToScheduledBannerVisible: Mapper<Category, boolean> = (
    category?: Category,
  ) =>
    this.data.scheduledPaymentsDocumentIds.length &&
    [
      StandardDocumentCategoryIdentifier.ADVANCED_EXPENSE,
      StandardDocumentCategoryIdentifier.EXTERNAL_INVOICE,
    ].includes(category?.identifier);

  readonly mapToMatchedBannerVisible: Mapper<Category, boolean> = (
    category?: Category,
  ) =>
    this.data.matchedDocumentIds.length &&
    category?.identifier ===
      StandardDocumentCategoryIdentifier.ADVANCED_EXPENSE;

  readonly mapToLockedBannerVisible: Mapper<Category, boolean> = (
    category?: Category,
  ) =>
    this.data.lockedDocumentIds.length &&
    category?.identifier ===
      StandardDocumentCategoryIdentifier.EXTERNAL_INVOICE;

  constructor(
    @Inject(MAT_DIALOG_DATA) readonly data: DocumentBulkMoveDialogData,
    private readonly dialogRef: MatDialogRef<
      DocumentBulkMoveDialogComponent,
      DocumentBulkMoveDialogResponse
    >,
    private readonly store: Store<Category>,
    private readonly documentsService: DocumentsService,
    private readonly categoriesService: CategoriesService,
    private readonly bannerContentService: BannerContentService,
  ) {}

  ngOnInit(): void {
    this.store.dispatch(loadCategories());
    this.initBanners();
  }

  private initBanners(): void {
    this.expenseReportsBannerText =
      this.bannerContentService.getExpenseReportsBannerText(
        this.data.expenseReportsDocumentIds.length,
      );
    this.matchedDocumentsBannerText =
      this.bannerContentService.getMatchedDocumentsBannerText(
        this.data.matchedDocumentIds.length,
        false,
      );
    this.scheduledPaymentBannerText =
      this.bannerContentService.getScheduledPaymentsBannerText(
        this.data.scheduledPaymentsDocumentIds.length,
      );
  }

  apply(): void {
    this.actionsDisabled = true;

    iif(
      () => !!this.selectedCategory.id,
      this.moveDocumentsToCategory(this.selectedCategory),
      this.categoriesService
        .create(this.selectedCategory.name)
        .pipe(switchMap(category => this.moveDocumentsToCategory(category))),
    ).subscribe();
  }

  moveDocumentsToCategory(category: Category): Observable<unknown> {
    const documentIds = this.getDocumentsIdToUpdate(category);
    return this.documentsService
      .bulkUpdateCategory(documentIds, category.id)
      .pipe(
        tap(() => {
          documentIds.forEach(id =>
            this.store.dispatch(removeDocumentFromStore({ documentId: id })),
          );
          this.dialogRef.close({
            numberOfUpdatedDocuments: documentIds.length,
          });
        }),
        finalize(() => (this.actionsDisabled = false)),
      );
  }

  createCategory(category: Category): void {
    this.store.dispatch(addCategory({ name: category.name }));

    this.categories$
      .pipe(
        tap(categories => {
          this.selectedCategory = categories.find(
            c => c.name === category.name,
          );
        }),
        takeWhile(
          categories => !categories.some(c => c.name === category.name),
          true,
        ),
      )
      .subscribe();
  }

  getDocumentsIdToUpdate(category: Category): number[] {
    switch (category?.identifier) {
      case StandardDocumentCategoryIdentifier.EXTERNAL_INVOICE:
        return this.data.unlockedDocumentIds;
      case StandardDocumentCategoryIdentifier.ADVANCED_EXPENSE:
        return this.data.otherDocumentIds;
      default:
        return [
          ...this.data.scheduledPaymentsDocumentIds,
          ...this.data.matchedDocumentIds,
          ...this.data.otherDocumentIds,
        ];
    }
  }
}
