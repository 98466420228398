const maximumSize = 25;
export const INVOICING_DOCUMENT_EXTRA_ATTACHMENT_CONFIG = {
  acceptedTypes: [
    // pictures
    '.bmp',
    '.gif',
    '.jpg',
    '.jpeg',
    '.png',
    // docs
    '.doc',
    '.docx',
    '.odt',
    '.pdf',
    '.ppt',
    '.pptx',
    '.rtf',
    '.txt',
    '.xls',
    '.xlsx',
    // misc
    '.csv',
  ],
  maximumSize,
  errorMessage: `La taille totale des fichiers ne doit pas dépasser ${maximumSize}Mo.`,
};
