import { A11yModule } from '@angular/cdk/a11y';
import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import { MatDividerModule } from '@angular/material/divider';
import { Mapper, TiimePipesModule } from '@manakincubber/tiime-utils';
import { take, tap } from 'rxjs/operators';
import { TiimeIconModule, TiimeLetModule } from 'tiime-components';

import { BankAccount, Beneficiary } from '@models';

import {
  FilterOverlayBase,
  FilterOverlayDataBase,
  FilterOverlayReturnBase,
} from '../../../bases';
import { AccountItemComponent } from '../account-item/account-item.component';

export interface AccountSelectorOverlayData
  extends FilterOverlayDataBase<BankAccount | Beneficiary> {
  canAddAccount?: boolean;
  addAccountPosition?: 'top' | 'bottom';
  isBeneficiarySelector?: boolean;
  personalWalletBeneficiary?: Beneficiary;
}

@Component({
  standalone: true,
  templateUrl: './account-selector-overlay.component.html',
  styleUrls: [
    '../../../bases/filter-overlay.base.scss',
    './account-selector-overlay.component.scss',
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    A11yModule,
    CommonModule,
    TiimePipesModule,
    TiimeLetModule,
    MatDividerModule,
    AccountItemComponent,
    TiimeIconModule,
  ],
})
export class AccountSelectorOverlayComponent extends FilterOverlayBase<
  BankAccount | Beneficiary,
  AccountSelectorOverlayData,
  FilterOverlayReturnBase<BankAccount | Beneficiary, string>,
  string
> {
  readonly mapToIsDisabled: Mapper<BankAccount | Beneficiary, boolean> =
    account => (this.isBankAccount(account) ? !account.enabled : false);

  selectItem(selectedAccount: BankAccount | Beneficiary): void {
    this.overlayRef.close({ itemSelected: selectedAccount });
  }

  createItem(): void {
    if (this.data.searchFilterValue$) {
      this.data.searchFilterValue$
        .pipe(
          take(1),
          tap(addAccountName =>
            this.overlayRef.close({ createItem: addAccountName }),
          ),
        )
        .subscribe();
    } else {
      this.overlayRef.close({
        createItem: null,
      });
    }
  }

  isBankAccount(account: BankAccount | Beneficiary): account is BankAccount {
    return (
      (account as BankAccount).enabled !== null &&
      (account as BankAccount).enabled !== undefined
    );
  }
}
