import {
  ChangeDetectionStrategy,
  Component,
  Input,
  booleanAttribute,
} from '@angular/core';
import { AnimationOptions, LottieComponent } from 'ngx-lottie';

@Component({
  standalone: true,
  selector: 'app-tiime-spinner',
  templateUrl: './tiime-spinner.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [LottieComponent],
})
export class TiimeSpinnerComponent {
  @Input({ transform: booleanAttribute }) white = false;
  @Input() height? = '100px';

  readonly loaderOptions: AnimationOptions = {
    path: '/assets/tiime-loader.json',
  };

  readonly loaderWhiteOptions: AnimationOptions = {
    path: '/assets/tiime-loader-white.json',
  };
}
