import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { TiimeIconModule } from 'tiime-components';

import { PinCodeFormComponent } from './pin-code-form.component';
import { PinCodeInputComponent } from './pin-code-input/pin-code-input.component';
import { IsCodeInputInvalidPipe } from './pipes/is-code-input-invalid.pipe';
import { IsConfirmCodeInvalidPipe } from './pipes/is-confirm-code-invalid.pipe';

@NgModule({
  declarations: [
    PinCodeInputComponent,
    PinCodeFormComponent,
    IsCodeInputInvalidPipe,
    IsConfirmCodeInvalidPipe,
  ],
  imports: [CommonModule, ReactiveFormsModule, TiimeIconModule],
  exports: [PinCodeFormComponent, PinCodeInputComponent],
})
export class PinCodeFormModule {}
