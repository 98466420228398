import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import {
  GetOptions,
  PaginationData,
  RequiredGetOptions,
} from 'tiime-components';

import { TemporaryEncoder } from '@core/temporary-encoder';
import { ApiAlertError } from '@decorators/api-alert-error';
import { HttpHelper } from '@helpers';
import {
  Check,
  CheckApiContract,
  CheckDeliveryAddress,
  CheckDeliveryAddressApiContract,
  CheckListItem,
} from '@models';

@Injectable({
  providedIn: 'root',
})
export class CheckDepositService {
  readonly resource = 'api/v1/wallet/companies/{companyId}/checks';

  constructor(private readonly http: HttpClient) {}

  @ApiAlertError()
  getAll(
    getOptions: RequiredGetOptions<'range'>,
  ): Observable<PaginationData<CheckListItem>> {
    const partialOptions = new GetOptions(getOptions).toHttpGetOptions();
    const options = {
      params: new HttpParams({
        fromObject: partialOptions.params,
        encoder: new TemporaryEncoder(),
      }),
      headers: partialOptions.headers,
    };
    return this.http
      .get<CheckApiContract[]>(`${this.resource}`, {
        ...options,
        observe: 'response',
      })
      .pipe(
        HttpHelper.mapToPaginationData(
          getOptions.range,
          (check: CheckApiContract) => CheckListItem.fromJson(check),
        ),
      );
  }

  @ApiAlertError()
  getAddress(): Observable<CheckDeliveryAddress> {
    const url = `${this.resource}/address`;
    return this.http
      .get(url)
      .pipe(
        map((addressJson: CheckDeliveryAddressApiContract) =>
          CheckDeliveryAddress.fromJson(addressJson),
        ),
      );
  }

  @ApiAlertError()
  depositCheck(check: Check): Observable<Check> {
    const url = `${this.resource}`;
    return this.http
      .post(url, Check.toJson(check))
      .pipe(map((checkJson: CheckApiContract) => Check.fromJson(checkJson)));
  }
}
