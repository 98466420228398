import { FormGroup, FormControl, Validators } from '@angular/forms';

export class SignInForm extends FormGroup {
  get email(): FormControl<string> {
    return this.get('email') as FormControl;
  }
  get emailErrorMessage(): string {
    return this.email.touched && this.email.hasError('required')
      ? 'Mail requis'
      : null;
  }
  get password(): FormControl<string> {
    return this.get('password') as FormControl;
  }
  get passwordErrorMessage(): string {
    return this.password.touched && this.password.hasError('required')
      ? 'Mot de passe requis'
      : null;
  }

  constructor(email: string) {
    super({
      email: new FormControl(email, Validators.required),
      password: new FormControl('', Validators.required),
    });
  }
}
