export class TrackCardStep {
  constructor(
    public date?: string,
    public description?: string,
    public isChecked?: boolean,
    public name?: string,
  ) {}

  static fromJson(json: any): TrackCardStep {
    return new TrackCardStep(
      json.date,
      json.description,
      json.is_checked,
      json.name,
    );
  }
}
