import { Injectable } from '@angular/core';
import { BehaviorSubject, tap } from 'rxjs';

import { TileKey } from '@core/enum';

import { TilesService } from './tiles.service';

@Injectable({
  providedIn: 'root',
})
export class MessagingService {
  private readonly unreadThreads$ = new BehaviorSubject<number>(0);

  constructor(private readonly tilesService: TilesService) {}

  updateUnreadThreadsCount(): void {
    this.tilesService
      .getTiles([TileKey.count_unread_thread])
      .pipe(tap(res => this.unreadThreads$.next(res.unreadThreadsCount)))
      .subscribe();
  }

  getUnreadThreadsCount(): BehaviorSubject<number> {
    return this.unreadThreads$;
  }
}
