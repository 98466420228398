import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TiimePipesModule } from '@manakincubber/tiime-utils';
import { TiimeIconModule, TiimeTooltipModule } from 'tiime-components';

import { IconsModule } from '@shared';

import { CategoryIconComponent } from '../../../../../shared/components/category-icon/category-icon.component';
import { LinkedItemsTooltipIconComponent } from './linked-items-tooltip-icon.component';

@NgModule({
  declarations: [LinkedItemsTooltipIconComponent],
  imports: [
    CommonModule,
    IconsModule,
    CategoryIconComponent,
    TiimeTooltipModule,
    TiimePipesModule,
    TiimeIconModule,
  ],
  exports: [LinkedItemsTooltipIconComponent],
})
export class LinkedItemsTooltipIconModule {}
