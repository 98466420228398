import { PaginationData } from 'tiime-components';

import {
  Address,
  AddressApiContract,
  Tag,
  TagApiContract,
  User,
} from '@models';
import { Vehicle, VehicleApiContract } from '@models/mileage-allowances';

export interface TravelWithMetadataApiContract {
  travels: Travel[];
  metadata: {
    total_distance: number;
  };
}

export class TravelsWithMetadata {
  constructor(
    public travels: PaginationData<Travel>,
    public metadata: TravelsMetadata,
  ) {}
}

export interface TravelsMetadataApiContract {
  total_distance: number;
  has_existing_travels: boolean;
}

export interface TravelsMetadata {
  totalDistance: number;
  hasExistingTravels: boolean;
}

export interface TravelApiContract {
  id: number;
  date: string;
  locked: boolean;
  distance: number;
  estimated_amount: number;
  comment: string;
  tags: TagApiContract[];
  vehicle: VehicleApiContract;
  vehicle_id: number;
  departure_address: AddressApiContract;
  arrival_company_name: string;
  arrival_address: AddressApiContract;
  round_trip: boolean;
  is_used_by_expense_report: boolean;
}
export class Travel {
  constructor(
    public id?: number,
    public date?: string,
    public locked?: boolean,
    public distance?: number,
    public estimatedAmount?: number,
    public comment?: string,
    public vehicle?: Vehicle,
    public tags?: Tag[],
    public vehicleOwner?: User,
    public departureAddress?: Address,
    public arrivalCompanyName?: string,
    public arrivalAddress?: Address,
    public roundTrip?: boolean,
    public isUsedByExpenseReport?: boolean,
  ) {}

  static fromJson(travel: TravelApiContract): Travel {
    return new Travel(
      travel.id,
      travel.date,
      travel.locked,
      travel.distance,
      travel.estimated_amount,
      travel.comment,
      travel.vehicle ? Vehicle.fromJson(travel.vehicle) : undefined,
      travel.tags ? travel.tags.map(tag => Tag.fromJson(tag)) : [],
      null,
      travel.departure_address
        ? Address.fromJson(travel.departure_address)
        : undefined,
      travel.arrival_company_name,
      travel.arrival_address
        ? Address.fromJson(travel.arrival_address)
        : undefined,
      travel.round_trip,
      travel.is_used_by_expense_report,
    );
  }

  static toJson(travel: Partial<Travel>): TravelApiContract {
    return {
      id: travel.id,
      date: travel.date,
      locked: travel.locked,
      distance: travel.distance,
      estimated_amount: travel.estimatedAmount,
      comment: travel.comment,
      vehicle: travel.vehicle ? Vehicle.toJson(travel.vehicle) : undefined,
      vehicle_id: travel.vehicle ? travel.vehicle.id : undefined,
      tags: travel.tags ? travel.tags.map(tag => Tag.toJson(tag)) : undefined,
      departure_address: travel.departureAddress
        ? Address.toJson(travel.departureAddress)
        : undefined,
      arrival_company_name: travel.arrivalCompanyName,
      arrival_address: travel.arrivalAddress
        ? Address.toJson(travel.arrivalAddress)
        : undefined,
      round_trip: travel.roundTrip,
      is_used_by_expense_report: travel.isUsedByExpenseReport,
    };
  }

  static metadataFromJson(json: TravelsMetadataApiContract): TravelsMetadata {
    return {
      totalDistance: json.total_distance,
      hasExistingTravels: json.has_existing_travels,
    };
  }
}
