import { FormControl, FormGroup } from '@angular/forms';

export class InvoicingTranslationsForm extends FormGroup {
  id = new FormControl();

  country = new FormControl();

  invoiceMailBody = new FormControl(null);
  invoiceMailSubject = new FormControl(null);
  invoicePaymentCondition = new FormControl(null);

  quotationMailBody = new FormControl(null);
  quotationMailSubject = new FormControl(null);
  quotationAcceptanceConditions = new FormControl(null);

  dunningInvoiceMailSubject = new FormControl();
  dunningInvoiceMailBody = new FormControl();
  dunningBalanceMailSubject = new FormControl();
  dunningBalanceMailBody = new FormControl();
  quotationValidityPeriod = new FormControl();

  constructor() {
    super({});
    this.addControl('id', this.id);

    this.addControl('country', this.country);

    this.addControl('invoiceMailBody', this.invoiceMailBody);
    this.addControl('invoicePaymentCondition', this.invoicePaymentCondition);
    this.addControl('invoiceMailSubject', this.invoiceMailSubject);

    this.addControl('quotationMailBody', this.quotationMailBody);
    this.addControl('quotationMailSubject', this.quotationMailSubject);
    this.addControl(
      'quotationAcceptanceConditions',
      this.quotationAcceptanceConditions,
    );

    this.addControl('quotationValidityPeriod', this.quotationValidityPeriod);

    this.addControl(
      'dunningInvoiceMailSubject',
      this.dunningInvoiceMailSubject,
    );
    this.addControl('dunningInvoiceMailBody', this.dunningInvoiceMailBody);
    this.addControl(
      'dunningBalanceMailSubject',
      this.dunningBalanceMailSubject,
    );
    this.addControl('dunningBalanceMailBody', this.dunningBalanceMailBody);
  }
}
