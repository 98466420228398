import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class IbanService {
  constructor(private readonly http: HttpClient) {}

  getBic(iban: string): Observable<string> {
    return this.http
      .get<{ bic: string }>(`api/v1/iban/${iban}/bic`)
      .pipe(map(({ bic }) => bic));
  }
}
