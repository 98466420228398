export interface WalletUserApiContract {
  id: number;
  /**
   * Remaining amount before a SCA request should be required in order to create a bank transfer
   * Automatically set to 0 by Chronos when more than 5 transactions under SCA_NEED_FROM_AMOUNT threshold have been created
   */
  amount_before_sca: number;
}

export class WalletUser {
  constructor(public id: number, public amountBeforeSca: number) {}

  static fromJson(json: WalletUserApiContract): WalletUser {
    return new WalletUser(json.id, json.amount_before_sca);
  }
}
