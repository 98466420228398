import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { Mapper } from '@manakincubber/tiime-utils';

import { Bank, BankApiContract } from '@models';

export interface BankLogoInfo {
  acronym: string;
  logo: string;
}

export type BankLogoComponentType = {
  name?: string;
  index?: number;
  bank?: Pick<Bank, 'name' | 'acronym' | 'logoUrl'> | BankApiContract;
};

@Component({
  selector: 'app-bank-logo',
  templateUrl: './bank-logo.component.html',
  styleUrls: ['./bank-logo.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BankLogoComponent {
  @Input() bankAccount: BankLogoComponentType;
  @Input() isTooltipDisabled: boolean;

  readonly mapToBankTooltip: Mapper<BankLogoComponentType, string> = (
    bankAccount: BankLogoComponentType,
  ) => {
    if (!bankAccount) {
      return '';
    }
    const bankName = bankAccount.bank?.name || 'Banque non identifiée';
    const accountName = bankAccount.name ? ` - ${bankAccount.name}` : '';
    return `${bankName}${accountName}`;
  };

  readonly mapToBankLogoInfo: Mapper<Bank | BankApiContract, BankLogoInfo> = (
    bank: Bank | BankApiContract,
  ) => {
    return bank instanceof Bank
      ? { acronym: bank?.acronym, logo: bank?.logoUrl }
      : { acronym: bank?.sigle, logo: bank?.logo_url };
  };
}
