import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { InvoiceCanBeCancelledPipe } from './invoice-can-be-cancelled.pipe';
import { InvoiceComplexFiltersPipe } from './invoice-complex-filters.pipe';
import { InvoiceStatusIconPipe } from './invoice-status-icon.pipe';
import { InvoiceStatusLabelPipe } from './invoice-status-label.pipe';
import { InvoicingDocumentNumberLabelPipe } from './invoicing-document-number-label.pipe';
import { TranslatedInvoicingUnitPipe } from './translated-invoicing-unit.pipe';
import { VatRatePipe } from './vat-rate.pipe';

@NgModule({
  imports: [CommonModule],
  declarations: [
    InvoiceCanBeCancelledPipe,
    InvoicingDocumentNumberLabelPipe,
    InvoiceComplexFiltersPipe,
    InvoiceStatusIconPipe,
    InvoiceStatusLabelPipe,
    VatRatePipe,
    TranslatedInvoicingUnitPipe,
  ],
  exports: [
    InvoiceCanBeCancelledPipe,
    InvoicingDocumentNumberLabelPipe,
    InvoiceComplexFiltersPipe,
    InvoiceStatusIconPipe,
    InvoiceStatusLabelPipe,
    VatRatePipe,
    TranslatedInvoicingUnitPipe,
  ],
})
export class InvoicePipesModule {}
