import { HttpErrorResponse, HttpStatusCode } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { SnackbarConfig, TiimeSnackbarService } from 'tiime-components';

import { SharedModule } from '@shared';

import { SentryService } from '../sentry/sentry.service';

export function ApiAlertError(
  excludedStatusList: number[] = [
    HttpStatusCode.Forbidden,
    HttpStatusCode.NotFound,
  ],
): MethodDecorator {
  return (
    _target: () => void,
    _propertyKey: string,
    descriptor: PropertyDescriptor,
  ): PropertyDescriptor => {
    const original = descriptor.value as (
      ...args: unknown[]
    ) => Observable<unknown>;
    descriptor.value = function (...args: unknown[]): Observable<unknown> {
      return original
        .apply<PropertyDescriptor, unknown[], Observable<unknown>>(this, args)
        .pipe(
          catchError((error: unknown) => {
            if (
              error instanceof HttpErrorResponse &&
              !excludedStatusList.includes(error.status)
            ) {
              displaySnackbarError(error);
            }

            return throwError(() => error);
          }),
        );
    };
    return descriptor;
  };
}

function displaySnackbarError(error: HttpErrorResponse): void {
  const nativeErrorMessage = (error.error as { error_description?: string })
    ?.error_description;

  if (!nativeErrorMessage) {
    SharedModule.injector.get(SentryService).captureException(error, {
      tags: { context: 'api-alert-error' },
    });
  }

  const readableMessage =
    nativeErrorMessage ||
    'Une erreur est survenue, merci de réessayer plus tard';

  SharedModule.injector
    .get(TiimeSnackbarService)
    .open(readableMessage, SnackbarConfig.error);
}
