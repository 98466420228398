import {
  ClientTimelineItem,
  ClientTimelineItemApiContract,
} from './client-timeline-item';
import { ClientTimelineItemDirection, ClientTimelineItemType } from './types';

export class ClientTimelineExternalInvoiceItem extends ClientTimelineItem<ClientTimelineItemType.externalInvoice> {
  constructor(
    id: number,
    date: string,
    amount: number,
    balanceAmount: number,
    matched: boolean,
    type: ClientTimelineItemType.externalInvoice,
    direction: ClientTimelineItemDirection,
    public title: string,
    public invoiceNumber: number,
  ) {
    super(id, date, amount, balanceAmount, matched, type, direction);
  }

  public static fromJson(
    json: ClientTimelineItemApiContract,
  ): ClientTimelineExternalInvoiceItem {
    return new ClientTimelineExternalInvoiceItem(
      json.id,
      json.date,
      json.amount,
      json.balance_amount,
      json.matched,
      json.type as ClientTimelineItemType.externalInvoice,
      json.direction,
      json.title,
      json.number,
    );
  }
}
