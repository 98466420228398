import { saveAs } from 'file-saver';

import { UPLOAD_DOCUMENT_CONFIG } from '@constants';

export class FileHelper {
  static readonly acceptedTypes = UPLOAD_DOCUMENT_CONFIG.acceptedTypes;
  static readonly unsupportedAcceptedTypes =
    UPLOAD_DOCUMENT_CONFIG.unsupportedAcceptedTypes;
  static readonly maximumSize = UPLOAD_DOCUMENT_CONFIG.maximumSize;

  /**
   * Saves the file after removing the first and last quotes of the filename
   * (at some point, all filenames from the api will be between quotes)
   */
  static saveAs(file: Blob, filename: string): void {
    saveAs(file, filename.replace(/"(.+)"/, '$1'));
  }

  static hasIncorrectSize(file: File): boolean {
    if (!FileHelper.maximumSize) {
      return false;
    }

    return (
      FileHelper.maximumSize && file.size > FileHelper.maximumSize * 1000000
    );
  }

  static hasAuthorizedType(file: File): boolean {
    if (FileHelper.acceptedTypes.length === 0) {
      return true;
    }

    return (
      FileHelper.acceptedTypes.some((type: string) =>
        file.type.endsWith(type.replace('.', '')),
      ) ||
      FileHelper.unsupportedAcceptedTypes.some(
        (type: string) => file.type === type,
      )
    );
  }
}
