import { CompanyAccessType } from '@enums';

import {
  BusinessAccessRequest,
  BusinessAccessRequestApiContract,
} from './business-access-request';
import { BusinessUnit, BusinessUnitApiContract } from './business-unit';
import { VatSystem, VatSystemApiContract } from './vat-system';
import { Card, CardApiContract } from './wallet/card';

export interface CompanyApiContract {
  id?: number;
  slug?: string;
  acronym?: string;
  color?: string;
  receipt_email?: string;
  city?: string;
  legal_form?: string;
  name?: string;
  phone?: string;
  company_created?: boolean;
  postal_code?: string;
  siret?: string;
  vat_number?: string;
  street?: string;
  address_complement?: string;
  country?: string;
  share_capital?: number;
  rcs_city?: string;
  ape_code?: { code: string };
  logo?: { id: number };
  selected?: boolean;
  pending_business_access_requests?: BusinessAccessRequestApiContract[];
  registration_date?: string;
  activity_start_date?: string;
  card?: CardApiContract;
  business_units?: BusinessUnitApiContract[];
  wallet_access?: boolean;
  wallet_check_activated?: boolean;
  wallet_company?: { used_sdd: boolean; sca_treezor: boolean };
  ged_activated?: boolean;
  tiime_expert?: boolean;
  document_category_creation_activated?: boolean;
  access_type?: CompanyAccessType;
  has_invoices: boolean;
  cash_registers_available: boolean;
  bank_access: boolean;
  tax_regime?: string;
  tax_regime_requested_at?: string;
  vat_system?: VatSystemApiContract;
  has_capital_deposit?: boolean;
  is_vat_allowed: boolean;
  work_in_company: boolean;
}

export class Company {
  get hasPendingBusinessAccessRequests(): boolean {
    return this.pendingBusinessAccessRequests.length > 0;
  }

  get isPersonal(): boolean {
    return this.legalForm === 'UserApp';
  }

  constructor(
    public id?: number,
    public slug?: string,
    public color?: string,
    public receiptEmail?: string,
    public city?: string,
    public legalForm?: string,
    public name?: string,
    public phone?: string,
    public companyCreated?: boolean,
    public postalCode?: string,
    public siret?: string,
    public intracomVatNumber?: string,
    public street?: string,
    public addressComplement?: string,
    public country?: string,
    public shareCapital?: number,
    public rcsCity?: string,
    public apeCode?: string,
    public logoId?: number,
    public selected?: boolean,
    public pendingBusinessAccessRequests?: BusinessAccessRequest[],
    public registrationDate?: string,
    public activityStartDate?: string,
    public card?: Card,
    public businessUnits?: BusinessUnit[],
    public walletAccess?: boolean,
    public walletCheckActivated?: boolean,
    public walletCompany?: { usedSdd: boolean; scaTreezor: boolean },
    public isOnTiimeExpert?: boolean,
    public isDocumentCategoryCreationEnabled?: boolean,
    public accessType?: CompanyAccessType,
    public hasInvoices?: boolean,
    public cashRegistersAvailable?: boolean,
    public bankAccess?: boolean,
    public taxRegime?: string,
    public taxRegimeRequestedAt?: string,
    public vatSystem?: VatSystem,
    public hasCapitalDeposit?: boolean,
    public isVatAllowed?: boolean,
    public workInCompany?: boolean,
  ) {}

  static fromJson(json: CompanyApiContract): Company {
    return new Company(
      json.id,
      json.slug ? json.slug : json.acronym,
      json.color,
      json.receipt_email,
      json.city,
      json.legal_form,
      json.name,
      json.phone,
      json.company_created,
      json.postal_code,
      json.siret,
      json.vat_number,
      json.street,
      json.address_complement,
      json.country,
      json.share_capital,
      json.rcs_city,
      json.ape_code ? json.ape_code.code : null,
      json.logo ? json.logo.id : null,
      json.selected,
      json.pending_business_access_requests
        ? json.pending_business_access_requests.map(
            pendingBusinessAccessRequest =>
              BusinessAccessRequest.fromJson(pendingBusinessAccessRequest),
          )
        : [],
      json.registration_date,
      json.activity_start_date,
      json.card ? Card.fromJson(json.card) : null,
      json.business_units?.map(businessUnit =>
        BusinessUnit.fromJson(businessUnit),
      ) || [],
      json.wallet_access,
      json.wallet_check_activated,
      json.wallet_company
        ? {
            usedSdd: json.wallet_company.used_sdd,
            scaTreezor: json.wallet_company.sca_treezor,
          }
        : null,
      json.tiime_expert,
      json.document_category_creation_activated,
      json.access_type,
      json.has_invoices,
      json.cash_registers_available,
      json.bank_access,
      json.tax_regime,
      json.tax_regime_requested_at,
      json.vat_system ? VatSystem.fromJson(json.vat_system) : null,
      json.has_capital_deposit,
      json.is_vat_allowed,
      json.work_in_company,
    );
  }

  static toJson(company: Company): Record<string, unknown> {
    const companyJson: Record<string, unknown> = {
      receipt_email: company.receiptEmail,
      city: company.city,
      legal_form: company.legalForm,
      name: company.name,
      phone: company.phone,
      company_created: company.companyCreated,
      postal_code: company.postalCode,
      siret: company.siret,
      vat_number: company.intracomVatNumber,
      street: company.street,
      address_complement: company.addressComplement,
      country: company.country,
      share_capital: company.shareCapital,
      rcs_city: company.rcsCity,
      ape_code: company.apeCode ? { code: company.apeCode } : null,
      registration_date: company.registrationDate,
      activity_start_date: company.activityStartDate,
      tiime_expert: company.isOnTiimeExpert,
      document_category_creation_activated:
        company.isDocumentCategoryCreationEnabled,
      access_type: company.accessType,
      has_invoices: company.hasInvoices,
      cash_registers_available: company.cashRegistersAvailable,
      bank_access: company.bankAccess,
      tax_regime: company.taxRegime,
      tax_regime_requested_at: company.taxRegimeRequestedAt,
      vat_system: company.vatSystem,
      has_capital_deposit: company.hasCapitalDeposit,
    };
    if (company.id) {
      companyJson.id = company.id;
    }
    return companyJson;
  }
}
