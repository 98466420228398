export const BANK_TRANSACTION_OPERATION_LABEL = {
  cash: 'Mon retrait espèces',
  check: 'Chèque',
  card: 'Ma carte bancaire',
  salaryCard: 'Carte bancaire : ',
  salaryCash: 'Retrait espèces : ',
  transfer: 'Virement',
  transferIn: 'Virement reçu',
  transferOut: 'Virement émis',
  levy: 'Prélèvement',
  undefined: 'Autre',
  pending: 'Transaction en cours',
  failed: 'Paiement refusé',
};
