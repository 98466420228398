import { FormControl } from '@angular/forms';
import { SortDirection } from '@angular/material/sort';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import moment from 'moment';
import { combineLatest } from 'rxjs';
import { startWith, tap } from 'rxjs/operators';
import { AndFilter, MonoValueFilter, ResetFilter } from 'tiime-components';

import { periodValidator } from '@core/forms/validators/period-validator';

import { TableColumnSortAndFilterForm } from '../table-column-sort-and-filter.form';

@UntilDestroy()
export class TableDateSortAndFilterForm extends TableColumnSortAndFilterForm {
  get date(): FormControl {
    return this.get('date') as FormControl;
  }

  get startDate(): FormControl {
    return this.get('startDate') as FormControl;
  }

  get endDate(): FormControl {
    return this.get('endDate') as FormControl;
  }

  constructor(
    filterKey: string,
    sortKey: string,
    sortDirection?: SortDirection,
  ) {
    super(
      {
        date: new FormControl(null),
        startDate: new FormControl(null),
        endDate: new FormControl(null),
      },
      filterKey,
      sortKey,
      sortDirection,
    );
    this.startDate.setValidators(periodValidator(this.startDate, this.endDate));
    this.endDate.setValidators(periodValidator(this.startDate, this.endDate));
    this.observeControls();
  }

  fromParam(dateFilterValue: string): void {
    const dateParamArray = dateFilterValue.split(',');
    const patch = dateParamArray
      .map(param => {
        const parsedParam = param.replace(/[>=<]/g, '');
        if (param.charAt(0) === '>') {
          return { startDate: parsedParam };
        } else if (param.charAt(0) === '<') {
          return { endDate: parsedParam };
        }
        return { date: parsedParam };
      })
      .reduce(
        (previous, current) => ({
          ...previous,
          ...current,
        }),
        {
          startDate: null,
          endDate: null,
          date: null,
        },
      );
    this.patchValue(patch, { emitEvent: false });
  }

  resetFilters(): void {
    this.patchValue({
      startDate: null,
      endDate: null,
      date: null,
    });
  }

  toFilter(): MonoValueFilter<string> | AndFilter<string> | ResetFilter {
    if (this.date.value) {
      return new MonoValueFilter(
        this.filterKey,
        this.formatDateFilter(this.date.value),
      );
    }
    if (this.startDate.value && !this.endDate.value) {
      return new MonoValueFilter(
        this.filterKey,
        `>=${this.formatDateFilter(this.startDate.value)}`,
      );
    }
    if (!this.startDate.value && this.endDate.value) {
      return new MonoValueFilter(
        this.filterKey,
        `<=${this.formatDateFilter(this.endDate.value)}`,
      );
    }
    if (this.startDate.value && this.endDate.value) {
      return new AndFilter(this.filterKey, [
        `>=${this.formatDateFilter(this.startDate.value)}`,
        `<=${this.formatDateFilter(this.endDate.value)}`,
      ]);
    }
    return ResetFilter.forKey(this.filterKey);
  }

  private formatDateFilter(date: string): string {
    return moment(date).format('y-MM-DD');
  }

  private observeControls(): void {
    this.date.valueChanges
      .pipe(
        tap(() => {
          this.startDate.patchValue(null, { emitEvent: false });
          this.endDate.patchValue(null, { emitEvent: false });
        }),
        untilDestroyed(this),
      )
      .subscribe();
    combineLatest([
      this.startDate.valueChanges.pipe(startWith(this.startDate.value)),
      this.endDate.valueChanges.pipe(startWith(this.endDate.value)),
    ])
      .pipe(
        tap(() => {
          this.date.patchValue(null, { emitEvent: false });
          this.startDate.updateValueAndValidity({ emitEvent: false });
          this.endDate.updateValueAndValidity({ emitEvent: false });
        }),
        untilDestroyed(this),
      )
      .subscribe();
  }
}
