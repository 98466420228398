import {
  AfterViewInit,
  Directive,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { MatMenu, MatMenuTrigger } from '@angular/material/menu';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { tap } from 'rxjs/operators';
import { Filter, ResetFilter } from 'tiime-components';

import { TableColumnMultipleFilterForm } from './table-column-multiple-filter.form';

@UntilDestroy()
@Directive()
export abstract class TableColumnMultipleFilterBaseDirective<
  T extends TableColumnMultipleFilterForm<V>,
  V,
> implements OnInit, AfterViewInit
{
  @Input() filterKeys!: string[];

  @Input() set filterValues(value: Filter<string | boolean>[]) {
    const currentFilterValues = this._filterValues;
    this._filterValues = value;
    if (value) {
      this.form?.fromParam(value);
    } else if (currentFilterValues) {
      this.form?.resetFilters();
    }
  }
  get filterValues(): Filter<string | boolean>[] {
    return this._filterValues;
  }
  private _filterValues: Filter<string | boolean>[];

  @Output() readonly valueChange = new EventEmitter<V[]>();

  @ViewChild(MatMenu, { static: true }) menu: MatMenu;

  form: T;

  constructor(protected readonly menuTrigger: MatMenuTrigger) {}

  ngOnInit(): void {
    this.initForm();
  }

  ngAfterViewInit(): void {
    this.resetDirtyFormOnClose();
  }

  apply(): void {
    const output = this.form.toOutput();
    this.valueChange.emit(output instanceof ResetFilter ? undefined : output);
    this.form.markAsPristine();
    this.menuTrigger.closeMenu();
  }

  protected resetToLastPristineState(): void {
    if (this.filterValues) {
      this.form.fromParam(this.filterValues);
    } else {
      this.form.resetFilters();
    }
    this.form.markAsPristine();
  }

  private resetDirtyFormOnClose(): void {
    this.menu.closed
      .pipe(
        tap(() => {
          if (!this.form.pristine) {
            this.resetToLastPristineState();
          }
        }),
        untilDestroyed(this),
      )
      .subscribe();
  }

  protected abstract initForm(): void;
  abstract reset(): void;
}
