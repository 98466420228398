import { Pipe, PipeTransform } from '@angular/core';

type Constructor<T> = new (...args: unknown[]) => T;

/**
 * Cast any object of an alias type to a more specific type.
 * Returns null if object is not of desired type.
 */
@Pipe({
  name: 'asInstanceof',
  standalone: true,
})
export class AsInstanceofPipe implements PipeTransform {
  transform<T>(value: unknown, type: Constructor<T>): T {
    if (value instanceof type) {
      return value;
    }
    return null;
  }
}
