import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { JoinModule } from '../../../company/company-shared/pipes/join/join.module';
import { FileUploaderButtonComponent } from './file-uploader-button.component';

@NgModule({
  declarations: [FileUploaderButtonComponent],
  imports: [CommonModule, JoinModule],
  exports: [FileUploaderButtonComponent],
})
export class FileUploaderButtonModule {}
