export interface SenderApiContract {
  id: number;
  color?: string;
  lastname?: string;
  firstname?: string;
  email?: string;
}

export interface Sender {
  id: number;
  color?: string;
  lastname?: string;
  firstname?: string;
  email?: string;
}

export interface MessageApiContract {
  id?: number;
  created_at?: string;
  created_by?: SenderApiContract;
  content?: string;
  content_updated_at?: string;
  content_deleted_at?: string;
}

export class Message {
  constructor(
    public id?: number,
    public date?: string,
    public sender?: Sender,
    public content?: string,
    public contentUpdatedAt?: string,
    public contentDeletedAt?: string,
  ) {}

  static fromJson(json: MessageApiContract): Message {
    return new Message(
      json.id,
      json.created_at,
      json.created_by,
      json.content,
      json.content_updated_at,
      json.content_deleted_at,
    );
  }

  static toJson(message: Message): MessageApiContract {
    return {
      id: message.id,
      created_at: message.date,
      created_by: message.sender,
      content: message.content,
      content_updated_at: message.contentUpdatedAt,
      content_deleted_at: message.contentDeletedAt,
    };
  }
}
