import {
  Directive,
  ElementRef,
  inject,
  Input,
  OnInit,
  Renderer2,
} from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { concat } from 'rxjs';
import { filter, first, repeat, tap } from 'rxjs/operators';
import { LoadingEvent, LoadingService } from 'tiime-components';

@UntilDestroy()
@Directive({
  standalone: true,
  selector: '[classOnLoading]',
})
export class ClassOnLoadingDirective implements OnInit {
  @Input() classOnLoading!: { key: string; class: string };

  private readonly elementRef = inject<ElementRef<HTMLElement>>(ElementRef);
  private readonly loadingService = inject(LoadingService);
  private readonly renderer = inject(Renderer2);

  ngOnInit(): void {
    concat(
      this.loadingService.asObservable().pipe(
        filter((event: LoadingEvent) => event.isStartEvent),
        first(({ key }: LoadingEvent) => this.classOnLoading.key === key),
        tap(() =>
          this.renderer.addClass(
            this.elementRef.nativeElement,
            this.classOnLoading.class,
          ),
        ),
      ),
      this.loadingService.asObservable().pipe(
        filter((event: LoadingEvent) => event.isEndEvent),
        first(({ key }: LoadingEvent) => this.classOnLoading.key === key),
        tap(() => {
          this.renderer.removeClass(
            this.elementRef.nativeElement,
            this.classOnLoading.class,
          );
        }),
      ),
    )
      .pipe(repeat(), untilDestroyed(this))
      .subscribe();
  }
}
