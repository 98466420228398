import { HttpErrorResponse, HttpStatusCode } from '@angular/common/http';
import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogRef,
} from '@angular/material/dialog';
import { Mapper, NgUtils } from '@manakincubber/tiime-utils';
import { Store } from '@ngrx/store';
import { BehaviorSubject, EMPTY, throwError } from 'rxjs';
import { catchError, finalize, tap } from 'rxjs/operators';
import { SnackbarConfig, TiimeSnackbarService } from 'tiime-components';

import { CardsService } from '@core';
import { CardState } from '@core/enum';
import {
  WalletAccount,
  WalletAccountDialogData,
  WalletCardActivation,
} from '@models';
import { loadWalletAccount } from '@store/wallet-account';

import { CareContactDialogComponent } from '../care-contact-dialog/care-contact-dialog.component';

enum CardTrackingDialogStatus {
  Tracking = 'tracking',
  CheckMail = 'check-mail',
  ConfirmRenew = 'confirm-renew',
  Activation = 'activation',
  Success = 'success',
}

@Component({
  templateUrl: './card-tracking-dialog.component.html',
  styleUrls: ['./card-tracking-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CardTrackingDialogComponent {
  readonly CardTrackingDialogStatus = CardTrackingDialogStatus;
  readonly trackByIndex = NgUtils.trackByIndex;
  readonly activeTrackCardStepIndex$ = new BehaviorSubject<number>(null);
  readonly currentStatus$ = new BehaviorSubject(
    CardTrackingDialogStatus.Tracking,
  );

  readonly activationCode$ = new BehaviorSubject('');
  readonly activationError$ = new BehaviorSubject(false);
  readonly activationLoading$ = new BehaviorSubject(false);

  closeValue = false;
  walletAccount: WalletAccount;

  private userId: number;

  readonly mapToDossierHeadline: Mapper<WalletAccount, string> = ({
    trackWalletStep,
  }) =>
    trackWalletStep?.validated
      ? 'Dossier validé !'
      : 'Dossier en cours de validation';
  readonly mapToDossierDescription: Mapper<WalletAccount, string> = ({
    trackWalletStep,
  }) =>
    trackWalletStep?.validated
      ? 'Votre dossier a bien été validé par nos équipes, tout est ok de notre côté !'
      : `Votre dossier est entré en phase finale de validation par nos équipes,
  il va bientôt être validé.`;
  readonly mapToDossierIllu: Mapper<WalletAccount, string> = ({
    trackWalletStep,
  }) =>
    trackWalletStep?.validated
      ? 'assets/illu_wallet_validated.svg'
      : 'assets/illu_wallet_pending_variant.svg';

  constructor(
    @Inject(MAT_DIALOG_DATA) public readonly data: WalletAccountDialogData,
    private readonly dialogRef: MatDialogRef<CardTrackingDialogComponent>,
    private readonly dialog: MatDialog,
    private readonly cardsService: CardsService,
    private readonly store: Store,
    private readonly snackbar: TiimeSnackbarService,
  ) {
    this.walletAccount = data.walletAccount;
    this.userId = data.userId;
    const firstNotCheckedStep = this.walletAccount.trackCardSteps.findIndex(
      step => step.isChecked === false,
    );
    this.activeTrackCardStepIndex$.next(
      (firstNotCheckedStep > 0
        ? firstNotCheckedStep
        : this.walletAccount.trackCardSteps.length) - 1,
    );
  }

  activateCard({ cards }: WalletAccount, activationCode: string): void {
    this.activationLoading$.next(true);
    this.dialogRef.disableClose = true;
    const orderedCard = cards.find(card => card.state === CardState.ordered);
    const isRenewCard = cards.length > 1;
    const walletCardActivation = new WalletCardActivation(
      orderedCard.id,
      activationCode,
    );
    this.cardsService
      .activate(walletCardActivation, this.userId)
      .pipe(
        finalize(() => {
          this.activationLoading$.next(false);
          this.dialogRef.disableClose = false;
        }),
        catchError((err: HttpErrorResponse) => {
          if (err.status === HttpStatusCode.InternalServerError) {
            return throwError(() => err);
          }

          this.activationError$.next(true);
          return EMPTY;
        }),
        tap(() => {
          if (isRenewCard) {
            this.closeValue = true;
            this.snackbar.open(
              'Votre nouvelle carte est à jour ! Pensez à détruire votre ancienne carte physique',
              SnackbarConfig.success as SnackbarConfig,
            );
          }
        }),
        tap(() => {
          this.currentStatus$.next(CardTrackingDialogStatus.Success);
          this.store.dispatch(loadWalletAccount());
        }),
      )
      .subscribe();
  }

  contactSupport(): void {
    this.dialogRef.close(false);
    this.dialog.open(CareContactDialogComponent);
  }

  goToActivation(
    walletAccount: WalletAccount,
    isRenewConfirmed: boolean,
  ): void {
    if (
      walletAccount.trackWalletStep?.validated &&
      walletAccount.cards?.length > 1 &&
      !isRenewConfirmed
    ) {
      this.currentStatus$.next(CardTrackingDialogStatus.ConfirmRenew);
    } else if (
      walletAccount.trackWalletStep?.validated &&
      (walletAccount.cards?.length === 1 || isRenewConfirmed)
    ) {
      this.currentStatus$.next(CardTrackingDialogStatus.Activation);
    } else {
      this.currentStatus$.next(CardTrackingDialogStatus.CheckMail);
    }
  }

  onCodeComplete(): void {
    this.activationError$.next(false);
  }
}
