import { TiimeSelectOption } from 'tiime-components';

import { DrawerType } from '@enums';

export const DRAWER_TYPE_OPTIONS: TiimeSelectOption<DrawerType>[] = [
  {
    value: DrawerType.Person,
    label: 'Une Personne',
    image: 'assets/icon-business-personne.svg',
  },
  {
    value: DrawerType.Company,
    label: 'Une entreprise',
    image: 'assets/icon-business-society.svg',
  },
];
