import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import {
  TiimeButtonModule,
  TiimeIconModule,
  TiimeInputContainerModule,
  TiimeLetModule,
  TiimeMenuModule,
  TiimeSelectModule,
} from 'tiime-components';

import { CategorySelectComponent } from './category-select.component';

@NgModule({
  imports: [
    CommonModule,
    ReactiveFormsModule,
    TiimeButtonModule,
    TiimeIconModule,
    TiimeInputContainerModule,
    TiimeLetModule,
    TiimeMenuModule,
    TiimeSelectModule,
  ],
  declarations: [CategorySelectComponent],
  exports: [CategorySelectComponent],
})
export class CategorySelectModule {}
