import { MatchableDocument } from '@matching/types/matchable-document';

import { Document, DocumentApiContract } from '../document';
import { InvoiceListItem } from '../invoice-list-item';
import { Label, LabelApiContract } from '../labels';

export interface CashFlowApiContract {
  id?: number;
  label?: LabelApiContract;
  inflow?: number;
  outflow?: number;
  comment?: string;
  documents?: DocumentApiContract[];
  invoices?: InvoiceListItem[];
}

export class CashFlow {
  get allDocuments(): MatchableDocument[] {
    return [...this.documents, ...this.invoices];
  }

  constructor(
    public id?: number,
    public label?: Label,
    public inflow?: number,
    public outflow?: number,
    public comment?: string,
    public documents?: Document[],
    public invoices?: InvoiceListItem[],
  ) {}

  static fromJson(json: CashFlowApiContract): CashFlow {
    return new CashFlow(
      json.id,
      json.label ? Label.fromJson(json.label) : undefined,
      json.inflow,
      json.outflow,
      json.comment,
      json.documents ? json.documents.map(Document.fromJson) : [],
      json.invoices ? json.invoices.map(InvoiceListItem.fromJson) : [],
    );
  }

  static toJson(cashFlow: CashFlow): CashFlowApiContract {
    return {
      id: cashFlow.id,
      label: cashFlow.label ? Label.toJson(cashFlow.label) : undefined,
      inflow: cashFlow.inflow,
      outflow: cashFlow.outflow,
      comment: cashFlow.comment,
      documents: cashFlow.documents
        ? cashFlow.documents.map(Document.toJson)
        : [],
      invoices: cashFlow.invoices
        ? cashFlow.invoices.map(invoice => new InvoiceListItem(invoice.id))
        : [],
    };
  }
}
