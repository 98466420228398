import { NgIf, NgOptimizedImage } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

import { Beneficiary } from '@models/wallet';

@Component({
  standalone: true,
  selector: 'app-trust-beneficiary',
  templateUrl: './trust-beneficiary.component.html',
  styleUrls: ['./trust-beneficiary.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [NgOptimizedImage, NgIf],
})
export class TrustBeneficiaryComponent {
  @Input() beneficiary: Beneficiary;
  @Input() personalWalletBeneficiary: Beneficiary;
}
