<div
  class="sidenav-menu-item"
  #menu
  [class.opened]="opened"
  [attr.data-cy]="'sidenav-menu-' + (opened ? 'open' : 'close')"
>
  <button data-cy="sidenav-menu__btn-toggle-menu" (click)="toggleMenu($event)">
    <mat-icon
      [svgIcon]="opened ? 'tc_icon-chevron-down' : 'tc_icon-chevron-right'"
    ></mat-icon>
  </button>
  <a [class.opened]="opened" (click)="navigate()">
    <img
      class="link-item-icon"
      alt=""
      draggable="false"
      data-cy="sidenav-menu__img-menu"
      [src]="iconSrc"
    />
    {{ menuTitle }}
  </a>
</div>
<div class="sidenav-submenu" [class.opened]="opened">
  <ng-content></ng-content>
</div>
