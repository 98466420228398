import { TrackingEvent } from '@manakincubber/tiime-tracking';
import { Filter } from 'tiime-components';

export const TRANSACTION_FILTER_APPLIED = 'Transaction Filter Applied';

export enum FilterOriginEnum {
  'list_headers',
  'search_bar',
  'page_header',
  'a_faire',
}

export type FilterOriginType =
  | 'list_headers'
  | 'search_bar'
  | 'page_header'
  | 'a_faire'
  | 'mobile_filter_dropdown';

export type TransactionFilterAmplitudeType =
  | 'label'
  | 'date'
  | 'methode'
  | 'intitule'
  | 'banque'
  | 'tag'
  | 'commentaire'
  | 'id'
  | 'montant'
  | 'credit_debit'
  | 'document_relie'
  | 'demandes_comptables'
  | 'libre';

export class TransactionFilterApplied implements TrackingEvent {
  readonly name = TRANSACTION_FILTER_APPLIED;

  constructor(
    readonly filter: Filter<unknown> | Filter<unknown>[],
    readonly filterOrigin: string,
    readonly sort?: boolean,
  ) {}
}
