import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Mapper, NgUtils } from '@manakincubber/tiime-utils';

import { ImputationsHelper } from '@core/helpers/imputations.helper';
import { MatchingType } from '@enums';
import {
  Document,
  InvoiceListItem,
  LinkedBankTransaction,
  ScheduledPayout,
} from '@models';
import {
  BankTransaction,
  BankTransactionImputation,
} from '@models/bank-transaction';
import { Label } from '@models/labels';
import {
  LinkedEntity,
  LinkedEntityBankTransaction,
  LinkedEntityBankTransactionValue,
  LinkedEntityImputation,
} from '@models/linked-entities';

import { LabelOverloadDialogResponse } from '../select-label-dialog/select-label-dialog.component';
import { MatchableBankTransaction } from '../types/matchable-bank-transaction';
import { MatchableDocument } from '../types/matchable-document';
import { MatchableImputations } from '../types/matchable-imputations';
import { MatchedItem } from '../types/matched-item';
import { MatchingSource } from '../types/matching-source';
import { itemsAreTheSame } from '../utils';

export interface MatchingDialogData {
  matchingSource?: MatchingSource;
  matchingTypes?: MatchingType[];
  matchedItems?: MatchedItem[];
  disabledItems?: MatchedItem[];
  allowMultipleSelection?: boolean;
  allowUnmatch?: boolean;
}

export interface MatchingDialogResponse extends LabelOverloadDialogResponse {
  matchingSource: MatchingSource;
  matchedItems?: MatchedItem[];
}

@Component({
  selector: 'app-matching-dialog',
  templateUrl: './matching-dialog.component.html',
  styleUrls: ['./matching-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MatchingDialogComponent {
  selectedBankTransactions?: MatchableBankTransaction[];
  selectedImputations?: MatchableImputations[];
  selectedDocuments?: MatchableDocument[];

  readonly trackById = NgUtils.trackById;

  readonly mapToLabel: Mapper<MatchingSource, Label | string | null> =
    matchingSource => {
      if (matchingSource instanceof BankTransaction) {
        return matchingSource.imputations[0]?.label;
      } else if (matchingSource instanceof ScheduledPayout) {
        return matchingSource.label;
      } else if (
        Array.isArray(matchingSource) &&
        matchingSource[0] instanceof Document
      ) {
        const label = matchingSource[0].label;
        return label &&
          matchingSource.every(
            document => document.label && document.label.id === label.id,
          )
          ? label
          : null;
      }
      return null;
    };

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: MatchingDialogData,
    private readonly dialogRef: MatDialogRef<
      MatchingDialogComponent,
      MatchingDialogResponse
    >,
  ) {
    this.initSelectedItems();
  }

  private initSelectedItems(): void {
    this.selectedBankTransactions = this.data.matchedItems.filter(
      (item): item is MatchableBankTransaction =>
        ImputationsHelper.isBankTransaction(item),
    );
    this.selectedImputations = this.data.matchedItems.filter(
      (item): item is MatchableImputations =>
        ImputationsHelper.isImputation(item),
    );
    this.selectedDocuments = this.data.matchedItems.filter(
      (item): item is MatchableDocument =>
        !ImputationsHelper.isBankTransaction(item) &&
        !ImputationsHelper.isImputation(item),
    );
  }

  documentSelected(document: MatchableDocument): void {
    this.selectedDocuments = this.onDataSelected(
      document,
      this.selectedDocuments,
    );
  }

  bankTransactionSelected(bankTransaction: MatchableBankTransaction): void {
    if (!this.data.allowMultipleSelection) {
      this.selectedImputations = [];
    }

    this.selectedBankTransactions = this.onDataSelected(
      bankTransaction,
      this.selectedBankTransactions,
    );
  }

  imputationSelected(imputation: MatchableImputations): void {
    if (!this.data.allowMultipleSelection) {
      this.selectedBankTransactions = [];
    }

    this.selectedImputations = this.onDataSelected(
      imputation,
      this.selectedImputations,
    );

    const bankTransactionToRemove = this.selectedBankTransactions
      .filter(
        // `LinkedBankTransaction` don't have imputations
        matchableBankTransaction =>
          !(matchableBankTransaction instanceof LinkedBankTransaction),
      )
      .find(
        (
          matchableBankTransaction:
            | BankTransaction
            | LinkedEntityBankTransaction,
        ) => {
          const bankTransaction =
            ImputationsHelper.getBankTransactionFromMatchableBankTransactions(
              matchableBankTransaction,
            ) as BankTransaction | LinkedEntityBankTransactionValue;

          // find a bank transaction which matches current selected imputations and remove it from the selectedBankTransactions
          // because we cannot have a bank transaction and it's children imputations
          return bankTransaction.imputations.some(bankTransactionImputation => {
            return this.selectedImputations.some(selectedImputation => {
              const imputation =
                ImputationsHelper.getImputationFromMatchableImputations(
                  selectedImputation,
                );

              return imputation.id === bankTransactionImputation.id;
            });
          });
        },
      );

    if (bankTransactionToRemove) {
      this.bankTransactionSelected(bankTransactionToRemove);
    }
  }

  onRemoveItem(item: MatchedItem): void {
    if (
      item instanceof BankTransaction ||
      item instanceof LinkedBankTransaction ||
      item instanceof LinkedEntityBankTransaction
    ) {
      this.selectedBankTransactions = this.onDataRemoved(
        item,
        this.selectedBankTransactions,
      );
    } else if (item instanceof Document || item instanceof InvoiceListItem) {
      this.selectedDocuments = this.onDataRemoved(item, this.selectedDocuments);
    } else if (
      item instanceof BankTransactionImputation ||
      item instanceof LinkedEntityImputation
    ) {
      this.selectedImputations = this.onDataRemoved(
        item,
        this.selectedImputations,
      );
    } else {
      throw new Error('Unhandled item type for removal.');
    }
  }

  matchSelectedData(): void {
    if (!this.hasSelectedItems() && !this.data.allowUnmatch) {
      return;
    }

    const matchingSource = this.data.matchingSource;

    this.dialogRef.close({
      matchingSource,
      matchedItems: this.isMatchingTransactions(this.data.matchingTypes)
        ? [...this.selectedBankTransactions, ...this.selectedImputations]
        : this.selectedDocuments,
    });
  }

  hasSelectedItems(): boolean {
    return (
      !!this.selectedDocuments?.length ||
      !!this.selectedBankTransactions?.length ||
      !!this.selectedImputations?.length
    );
  }

  private isMatchingTransactions(matchingTypes: MatchingType[]): boolean {
    return matchingTypes.every(
      matchingType => matchingType === MatchingType.bankTransactions,
    );
  }

  private onDataSelected<T extends MatchedItem>(
    data: T,
    originArray: T[],
  ): T[] {
    let updatedArray = originArray ? [...originArray] : [];
    const itemIndex = this.findMatchedItemIndex(updatedArray, data);

    if (itemIndex > -1) {
      updatedArray.splice(itemIndex, 1);
    } else {
      if (!this.data.allowMultipleSelection) {
        updatedArray = [data];
      } else {
        updatedArray.push(data);
      }
    }
    return updatedArray;
  }

  private onDataRemoved<T extends MatchedItem>(data: T, originArray: T[]): T[] {
    const updatedArray = originArray ? [...originArray] : [];

    const itemIndex = this.findMatchedItemIndex(updatedArray, data);
    if (itemIndex !== -1) {
      updatedArray.splice(itemIndex, 1);
    }

    return updatedArray;
  }

  private findMatchedItemIndex(
    matchedItems: MatchedItem[],
    itemToFind: MatchedItem,
  ): number {
    return matchedItems.findIndex(matchedItem => {
      return itemsAreTheSame(
        itemToFind instanceof LinkedEntity
          ? itemToFind.value.id
          : itemToFind.id,
        matchedItem,
      );
    });
  }
}
