import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { ApiAlertError } from '@decorators/api-alert-error';
import { DashboardType } from '@enums';
import {
  DashboardBlock,
  DashboardBlockApiContract,
  DashboardBlockListItem,
  DashboardBlockOptions,
  DashboardCustomization,
  StandardIndicator,
} from '@models/dashboards';

@Injectable({
  providedIn: 'root',
})
export class DashboardService {
  private readonly resource = 'api/v1/companies/{companyId}/dashboard_blocks';
  private readonly periodComparisonHeaders = new HttpHeaders(
    'Accept: application/vnd.tiime.blocks.option.accountant.period+json',
  );

  constructor(private readonly http: HttpClient) {}

  @ApiAlertError()
  getDashboardBlocks(): Observable<DashboardBlockListItem[]> {
    return this.http.get<DashboardBlockListItem[]>(`${this.resource}`, {
      headers: this.periodComparisonHeaders,
    });
  }

  @ApiAlertError()
  getDashboardBlock<T>(id: number): Observable<DashboardBlock<T>> {
    return this.http
      .get<DashboardBlockApiContract<T>>(`${this.resource}/${id}`, {
        headers: this.periodComparisonHeaders,
      })
      .pipe(map(response => DashboardBlock.fromJson<T>(response)));
  }

  @ApiAlertError()
  updateDashboardBlock<T>(
    id: number,
    dashboardCustomization: DashboardCustomization,
    options: DashboardBlockOptions,
  ): Observable<DashboardBlock<T>> {
    return this.http
      .patch<DashboardBlockApiContract<T>>(
        `${this.resource}/${id}`,
        {
          options: DashboardBlockOptions.toJson(
            dashboardCustomization.type,
            options,
          ),
          title: dashboardCustomization.title,
          type: dashboardCustomization.type,
        },
        { headers: this.periodComparisonHeaders },
      )
      .pipe(map(response => DashboardBlock.fromJson<T>(response)));
  }

  @ApiAlertError()
  deleteDashboardBlock<T>(
    id: number,
  ): Observable<DashboardBlockApiContract<T>> {
    return this.http.delete<DashboardBlockApiContract<T>>(
      `${this.resource}/${id}`,
      {
        headers: this.periodComparisonHeaders,
      },
    );
  }

  @ApiAlertError()
  addDashboardBlock<T>(
    dashboardType: DashboardType,
    options?: DashboardBlockOptions,
    customization?: DashboardCustomization,
  ): Observable<DashboardBlockApiContract<T>> {
    return this.http.post<DashboardBlockApiContract<T>>(`${this.resource}`, {
      type: dashboardType,
      options: DashboardBlockOptions.toJson(dashboardType, {
        ...options,
        customization: customization,
      }),
    });
  }

  @ApiAlertError()
  pinDashboardBlock<T>(
    id: number,
    pinnedAt: string,
  ): Observable<DashboardBlock<T>> {
    return this.http
      .patch<DashboardBlockApiContract<T>>(
        `${this.resource}/${id}`,
        { pinned_at: pinnedAt },
        { headers: this.periodComparisonHeaders },
      )
      .pipe(map(response => DashboardBlock.fromJson<T>(response)));
  }

  @ApiAlertError()
  getStandardIndicators(): Observable<StandardIndicator[]> {
    return this.http.get<StandardIndicator[]>(
      `${this.resource}/custom/standard_indicators`,
    );
  }
}
