<td>
  <div class="container">
    <div class="illustration-block">
      <img
        draggable="false"
        src="assets/illu-no-result.svg"
        alt="Aucun résultat"
      />
      <div *ngIf="text" class="illustration-block__text">
        {{ text }}
      </div>
    </div>
  </div>
</td>
