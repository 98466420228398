import { Injectable } from '@angular/core';
import { UrlTree, Router, Route } from '@angular/router';
import { map, Observable, switchMap, withLatestFrom } from 'rxjs';

import { AclService } from '@core/services/acl.service';
import { UserPermissionNameEnum } from '@enums/users';
import { PermissionAttribute } from '@models/user';

export type ReplacementInfo = {
  replaceFor: UserPermissionNameEnum;
  origin: string;
  redirectTo: string;
};

@Injectable({
  providedIn: 'root',
})
export class AclGuard {
  constructor(
    private readonly aclService: AclService,
    private readonly router: Router,
  ) {}

  canMatch(route: Route): Observable<boolean | UrlTree> {
    const replacementInfos: ReplacementInfo[] = route.data
      .replacementInfo as ReplacementInfo[];
    return this.aclService.getCurrentUserPermissions().pipe(
      switchMap(() =>
        this.aclService.userHasPermission(
          route.data.permissions as UserPermissionNameEnum[],
        ),
      ),
      withLatestFrom(this.aclService.getCurrentUserPermissions()),
      map(([hasPermission, user]) => {
        if (hasPermission) {
          return true;
        }
        const replacementInfo = this.getReplacementInfo(
          user.permissionAttributes,
          replacementInfos,
        );
        return this.router.createUrlTree(
          replacementInfo
            ? [
                this.buildRedirectionUrl(
                  this.router.getCurrentNavigation().extractedUrl.toString(),
                  replacementInfo.origin,
                  replacementInfo.redirectTo,
                ),
              ]
            : ['/unauthorized'],
        );
      }),
    );
  }

  private buildRedirectionUrl(
    destinationUrl: string,
    origin: string,
    redirectTo: string,
  ): string {
    if (!destinationUrl.includes(origin)) {
      return destinationUrl.concat('/', redirectTo);
    }
    return destinationUrl.replace(origin, redirectTo);
  }

  private getReplacementInfo(
    userPermissions: PermissionAttribute[],
    replacements?: ReplacementInfo[],
  ): ReplacementInfo | void {
    if (!replacements) {
      return;
    }
    return replacements.find(r =>
      userPermissions.find(p => p.permit && p.fullName === r.replaceFor),
    );
  }
}
