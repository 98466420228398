import { BankConnectionStatus, BankProvider } from '@enums';

import { Bank } from './bank';
import { BankAccount } from './bank-account';
import { BIAccount } from './bi-account';
import { BIBankConnection } from './bi-bank-connection';
import { ChronosBankConnection } from './chronos-bank-connection';

export class BankConnection {
  constructor(
    public id: number,
    public synchronizationDate: Date | null,
    public status: BankConnectionStatus,
    public providerConnectionId: number,
    public deletable: boolean,
    public closed: boolean,
    public bankAccounts: BankAccount[],
    public bank: Bank,
    public previousStatus: BankConnectionStatus | null,
    public statusUpdatedAt: string | null,
  ) {}

  public static fromBIConnection(
    biConnection: BIBankConnection,
  ): BankConnection {
    return new BankConnection(
      null,
      null,
      biConnection.status,
      biConnection.id,
      true,
      false,
      biConnection.accounts.map((biAccount: BIAccount) =>
        BankAccount.fromBIAccount(biAccount),
      ),
      new Bank(
        biConnection.connector.id,
        biConnection.connector.uuid,
        biConnection.connector.id,
        BankProvider.BUDGEA,
        biConnection.connector.name,
        biConnection.connector.slug,
        null,
        null,
      ),
      null,
      null,
    );
  }

  public static fromChronosConnection(
    cConnection: ChronosBankConnection,
  ): BankConnection {
    return new BankConnection(
      cConnection.id,
      cConnection.synchronizationDate,
      cConnection.status,
      cConnection.providerConnectionId,
      cConnection.deletable,
      cConnection.closed,
      cConnection.bankAccounts || [],
      cConnection.bank,
      cConnection.previousStatus,
      cConnection.statusUpdatedAt,
    );
  }

  public static fromReconciledBIConnectionAndChronosConnection(
    biConnection: BIBankConnection,
    chronosConnection: ChronosBankConnection,
  ): BankConnection {
    const accounts: BankAccount[] = biConnection.accounts.map(
      (biAccount: BIAccount) => {
        const visibleAccountOnChronos = chronosConnection.bankAccounts.find(
          (cAccount: BankAccount) => cAccount.providerId === biAccount.id,
        );
        if (visibleAccountOnChronos) {
          return BankAccount.fromReconciledChronosAndBIAccount(
            visibleAccountOnChronos,
            biAccount,
          );
        } else {
          return BankAccount.fromBIAccount(biAccount);
        }
      },
    );
    return new BankConnection(
      chronosConnection.id,
      chronosConnection.synchronizationDate,
      biConnection.status,
      biConnection.id,
      chronosConnection.deletable,
      chronosConnection.closed,
      accounts,
      chronosConnection.bank,
      chronosConnection.previousStatus,
      chronosConnection.statusUpdatedAt,
    );
  }
}
