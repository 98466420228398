import { TrackingEvent } from '@manakincubber/tiime-tracking';

import { Invoice } from '@models';

export const INVOICE_EDITOR_OPENED = 'Invoice Editor Opened';

export class InvoiceEditorOpened implements TrackingEvent {
  readonly name = INVOICE_EDITOR_OPENED;

  constructor(readonly invoice?: Invoice) {}
}
