import { inject } from '@angular/core';
import { CanMatchFn, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { Observable, combineLatest, of } from 'rxjs';
import { switchMap, map, catchError } from 'rxjs/operators';

import { Company, User, WalletAccount } from '@core/models';
import { WalletAccountService } from '@core/services';
import {
  selectedCompanySelector,
  userSelector,
  walletAccountSelector,
} from '@core/store';

export function HasAccessToCompanyCardsGuard(): CanMatchFn {
  return () => {
    const store = inject(Store);
    const router = inject(Router);
    const walletAccountService = inject(WalletAccountService);

    return combineLatest([
      store.select(selectedCompanySelector),
      store.select(userSelector),
      store.select(walletAccountSelector),
    ]).pipe(
      switchMap(
        ([company, user, walletAccount]: [Company, User, WalletAccount]) => {
          //if we don't have a wallet account in store but we should(ex. refresh case), we need to hydrate data via the API
          if (!walletAccount && company.walletAccess) {
            return hasWalletAccountInApi(
              company,
              user.id,
              walletAccountService,
            ).pipe(
              catchError(() => of(null)),
              map((walletAccountFromApi: WalletAccount | null) => ({
                walletAccount: walletAccountFromApi,
                company,
              })),
            );
          } else {
            return of({ walletAccount, company });
          }
        },
      ),
      switchMap(({ walletAccount, company }) => {
        const companyCardsListActivated = walletAccount
          ? walletAccount.companyCardsListActivated
          : false;
        const defaultRoute = router.createUrlTree([
          'companies',
          company.id,
          'account',
        ]);
        return companyCardsListActivated ? of(true) : of(defaultRoute);
      }),
    );
  };
}

export function hasWalletAccountInApi(
  company: Company,
  userId: number,
  walletAccountService: WalletAccountService,
): Observable<WalletAccount> {
  return walletAccountService.getWalletAccount(company.id, userId);
}
