import { FormGroup, FormControl } from '@angular/forms';

export class CanOrderCardForm extends FormGroup {
  get canOrderCard(): FormControl {
    return this.get('canOrderCard') as FormControl;
  }

  constructor() {
    super({
      canOrderCard: new FormControl(false),
    });
  }
}
