import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { tap } from 'rxjs/operators';

import { UPLOAD_DOCUMENT_CONFIG } from '@constants';
import { FileTransferOverlayService } from '@file-transfer';
import { FileHelper } from '@helpers';

import {
  DocumentAddedDialogComponent,
  DocumentAddedDialogData,
} from '../document-added-dialog/document-added-dialog.component';

@Component({
  selector: 'app-add-document-button',
  templateUrl: './add-document-button.component.html',
  styleUrls: ['./add-document-button.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AddDocumentButtonComponent {
  @Input() categoryId: number;

  @Output() readonly unauthorizedType = new EventEmitter<void>();
  @Output() readonly incorrectSize = new EventEmitter<void>();
  @Output() readonly documentAdded = new EventEmitter<void>();

  files: FileList;
  changeFileEvent: Event;

  readonly acceptedTypes = UPLOAD_DOCUMENT_CONFIG.acceptedTypes;
  readonly maximumSize = UPLOAD_DOCUMENT_CONFIG.maximumSize;

  constructor(
    private readonly dialog: MatDialog,
    private readonly fileTransferOverlayService: FileTransferOverlayService,
  ) {}

  onFileSelected(event: Event): void {
    this.changeFileEvent = event;
    const files = (event.target as HTMLInputElement).files;
    this.addFiles(files);
  }

  private addFiles(files: FileList): void {
    if (files.length === 0) {
      return;
    }

    if (this.hasSomeFilesIncorrectSize(files)) {
      this.incorrectSize.emit();
      return;
    }

    if (this.hasSomeFilesUnauthorizedType(files)) {
      this.unauthorizedType.emit();
      return;
    }

    this.files = files;
    if (!this.categoryId) {
      this.openDocumentAddedDialog();
    } else {
      this.createDocument(this.categoryId);
      (this.changeFileEvent.target as HTMLInputElement).value = '';
    }

    this.documentAdded.emit();
  }

  private openDocumentAddedDialog(): void {
    const { files } = this;
    this.dialog
      .open<DocumentAddedDialogComponent, DocumentAddedDialogData>(
        DocumentAddedDialogComponent,
        {
          data: { files },
          width: '600px',
          height: '418px',
        },
      )
      .beforeClosed()
      .pipe(
        tap(() => {
          if (this.changeFileEvent) {
            (this.changeFileEvent.target as HTMLInputElement).value = '';
          }
        }),
      )
      .subscribe();
  }

  private hasSomeFilesIncorrectSize(files: FileList): boolean {
    return Array.from(files).some(file => FileHelper.hasIncorrectSize(file));
  }

  private hasSomeFilesUnauthorizedType(files: FileList): boolean {
    return Array.from(files).some(file => !FileHelper.hasAuthorizedType(file));
  }

  private createDocument(categoryId: number): void {
    this.fileTransferOverlayService.openOverlayWithFiles(
      this.files,
      categoryId,
    );
  }
}
