import { __assign, __awaiter, __generator } from "tslib";
import { createRemoteConfigFetch } from '@amplitude/analytics-remote-config';
import { RequestMetadata } from '@amplitude/analytics-core';
var BrowserJoinedConfigGenerator = /** @class */function () {
  function BrowserJoinedConfigGenerator(localConfig) {
    this.config = localConfig;
    this.config.loggerProvider.debug('Local configuration before merging with remote config', JSON.stringify(this.config, null, 2));
  }
  BrowserJoinedConfigGenerator.prototype.initialize = function () {
    return __awaiter(this, void 0, void 0, function () {
      var _a;
      return __generator(this, function (_b) {
        switch (_b.label) {
          case 0:
            _a = this;
            return [4 /*yield*/, createRemoteConfigFetch({
              localConfig: this.config,
              configKeys: ['analyticsSDK']
            })];
          case 1:
            _a.remoteConfigFetch = _b.sent();
            return [2 /*return*/];
        }
      });
    });
  };
  BrowserJoinedConfigGenerator.prototype.generateJoinedConfig = function () {
    var _a, _b, _c;
    var _d;
    return __awaiter(this, void 0, void 0, function () {
      var remoteConfig, _e, e_1;
      return __generator(this, function (_f) {
        switch (_f.label) {
          case 0:
            _f.trys.push([0, 3,, 4]);
            _e = this.remoteConfigFetch;
            if (!_e) return [3 /*break*/, 2];
            return [4 /*yield*/, this.remoteConfigFetch.getRemoteConfig('analyticsSDK', 'browserSDK', this.config.sessionId)];
          case 1:
            _e = _f.sent();
            _f.label = 2;
          case 2:
            remoteConfig = _e;
            this.config.loggerProvider.debug('Remote configuration:', JSON.stringify(remoteConfig, null, 2));
            // merge remoteConfig.autocapture and this.config.autocapture
            // if a field is in remoteConfig.autocapture, use that value
            // if a field is not in remoteConfig.autocapture, use the value from this.config.autocapture
            if (remoteConfig && 'autocapture' in remoteConfig) {
              if (typeof remoteConfig.autocapture === 'boolean') {
                this.config.autocapture = remoteConfig.autocapture;
              }
              if (typeof remoteConfig.autocapture === 'object') {
                if (this.config.autocapture === undefined) {
                  this.config.autocapture = remoteConfig.autocapture;
                }
                if (typeof this.config.autocapture === 'boolean') {
                  this.config.autocapture = __assign({
                    attribution: this.config.autocapture,
                    fileDownloads: this.config.autocapture,
                    formInteractions: this.config.autocapture,
                    pageViews: this.config.autocapture,
                    sessions: this.config.autocapture,
                    elementInteractions: this.config.autocapture
                  }, remoteConfig.autocapture);
                }
                if (typeof this.config.autocapture === 'object') {
                  this.config.autocapture = __assign(__assign({}, this.config.autocapture), remoteConfig.autocapture);
                }
              }
              // Override default tracking options if autocapture is updated by remote config
              this.config.defaultTracking = this.config.autocapture;
            }
            this.config.loggerProvider.debug('Joined configuration: ', JSON.stringify(this.config, null, 2));
            (_a = (_d = this.config).requestMetadata) !== null && _a !== void 0 ? _a : _d.requestMetadata = new RequestMetadata();
            if ((_b = this.remoteConfigFetch) === null || _b === void 0 ? void 0 : _b.metrics.fetchTimeAPISuccess) {
              this.config.requestMetadata.recordHistogram('remote_config_fetch_time_API_success', this.remoteConfigFetch.metrics.fetchTimeAPISuccess);
            }
            if ((_c = this.remoteConfigFetch) === null || _c === void 0 ? void 0 : _c.metrics.fetchTimeAPIFail) {
              this.config.requestMetadata.recordHistogram('remote_config_fetch_time_API_fail', this.remoteConfigFetch.metrics.fetchTimeAPIFail);
            }
            return [3 /*break*/, 4];
          case 3:
            e_1 = _f.sent();
            this.config.loggerProvider.error('Failed to fetch remote configuration because of error: ', e_1);
            return [3 /*break*/, 4];
          case 4:
            return [2 /*return*/, this.config];
        }
      });
    });
  };
  return BrowserJoinedConfigGenerator;
}();
export { BrowserJoinedConfigGenerator };
export var createBrowserJoinedConfigGenerator = function (localConfig) {
  return __awaiter(void 0, void 0, void 0, function () {
    var joinedConfigGenerator;
    return __generator(this, function (_a) {
      switch (_a.label) {
        case 0:
          joinedConfigGenerator = new BrowserJoinedConfigGenerator(localConfig);
          return [4 /*yield*/, joinedConfigGenerator.initialize()];
        case 1:
          _a.sent();
          return [2 /*return*/, joinedConfigGenerator];
      }
    });
  });
};
