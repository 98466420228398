import { Component, ChangeDetectionStrategy, Input } from '@angular/core';
import { TooltipPosition } from 'tiime-components';

@Component({
  selector: 'app-comment-tooltip-icon',
  templateUrl: './comment-tooltip-icon.component.html',
  styleUrls: [
    '../tooltip-icons-base.scss',
    './comment-tooltip-icon.component.scss',
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CommentTooltipIconComponent {
  @Input() comment: string;
  @Input() tooltipPosition: TooltipPosition = 'bottom';
  @Input() tooltipInvertedPosition: TooltipPosition = 'left';
}
