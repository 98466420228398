<div
  *tiimeLet="selectedItem$ | async as selectedAccount"
  class="selector-card"
  [class.selector-card--filtering]="overlayRef !== null"
  [class.selector-card--selected]="selectedAccount"
>
  <label data-cy="selector-card__label">
    {{ label ?? (isBeneficiarySelector | map: mapToLabel) }}
  </label>

  <ng-container
    *tiimeLet="selectedAccount && !selectedAccount.id as isNewAccount"
  >
    <div
      *tiimeLet="disabled$ | async as disabled"
      class="selector-card__container"
      data-cy="selector-card__container"
      #selectorContainer
    >
      <button
        #buttonCard
        [disabled]="disabled || readonly"
        (click)="
          isNewAccount ? addAccount.emit() : cardClicked(selectedAccount)
        "
      >
        <ng-container *ngIf="!selectedAccount; else accountItemTemplate">
          <div
            class="selector-card__icon-container"
            data-cy="selector-card__placeholder"
          >
            <img
              height="32"
              width="32"
              alt=""
              [src]="isBeneficiarySelector | map: mapToIconPath"
            />
          </div>
          <div class="selector-card__content">
            <span class="selector-card__label">
              {{ label ?? (isBeneficiarySelector | map: mapToLabel) }}
            </span>
          </div>
        </ng-container>

        @if (!disabled && !readonly && !isNewAccount) {
          <mat-icon
            *ngIf="!disabled && !readonly"
            svgIcon="icon-caret-down"
            data-cy="selector-card__arrow-btn"
          ></mat-icon>
        }
      </button>

      <input
        type="text"
        placeholder="Rechercher un compte…"
        data-cy="selector-card__input-search"
        #filterInput
        (keyup)="filterChange($event)"
      />

      @if (!disabled && !readonly && isNewAccount) {
        <button
          class="dropdown-button"
          tiime-button
          neutral
          inverted
          icon
          (click)="cardClicked(selectedAccount)"
        >
          <mat-icon
            svgIcon="icon-caret-down"
            data-cy="selector-card__arrow-btn"
            [class.rotate-180]="overlayRef"
          ></mat-icon>
        </button>
      }
    </div>

    <ng-template #accountItemTemplate>
      <div class="selected-item-container" data-cy="selector-card__selected">
        <app-account-item
          [iconColor]="isNewAccount ? 'default' : 'blue'"
          [account]="selectedAccount"
          [isBeneficiary]="isBeneficiarySelector"
          [personalWalletBeneficiary]="personalWalletBeneficiary"
          (addBeneficiary)="addAccount.emit()"
        ></app-account-item>
      </div>
    </ng-template>
  </ng-container>
</div>
