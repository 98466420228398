import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ValidatorsUtils } from '@manakincubber/tiime-utils';

import { Company } from '@models';

import { INTRACOM_VAT_REGEX } from '../../shared/intracom-vat';
import { SIRET_REGEX } from '../../shared/siret';

interface InvoicingDocumentSellerFooterFormModel {
  legalForm: FormControl<string>;
  capital: FormControl<number | null>;
  apeCode: FormControl<string>;
  rcsCity: FormControl<string>;
  siret: FormControl<string>;
  intracomVatNumber: FormControl<string>;
}

export class InvoicingDocumentSellerFooterForm extends FormGroup<InvoicingDocumentSellerFooterFormModel> {
  get legalFormErrorMessage(): string | null {
    if (!this.controls.legalForm.touched) {
      return null;
    }
    return this.controls.legalForm.hasError('required')
      ? 'Forme juridique requise'
      : null;
  }
  get capitalErrorMessage(): string | null {
    if (!this.controls.capital.touched) {
      return null;
    }
    return this.controls.capital.hasError('required') ? 'Capital requis' : null;
  }
  get apeCodeErrorMessage(): string | null {
    if (!this.controls.apeCode.touched) {
      return null;
    }
    return this.controls.apeCode.hasError('required')
      ? 'Code APE requis'
      : null;
  }
  get rcsCityErrorMessage(): string | null {
    if (!this.controls.rcsCity.touched) {
      return null;
    }
    return this.controls.rcsCity.hasError('required')
      ? 'Ville RCS requise'
      : null;
  }
  get siretErrorMessage(): string | null {
    if (!this.controls.siret.touched) {
      return null;
    }
    switch (true) {
      case this.controls.siret.hasError('pattern'):
        return 'Format SIRET invalide';
      case this.controls.siret.hasError('validateSiret'):
        return 'SIRET invalide';
      case this.controls.siret.hasError('required'):
        return 'SIRET requis';
      default:
        return null;
    }
  }
  get intracomVatNumberErrorMessage(): string | null {
    if (!this.controls.intracomVatNumber.touched) {
      return null;
    }
    switch (true) {
      case this.controls.intracomVatNumber.hasError('pattern'):
        return 'Format invalide';
      case this.controls.intracomVatNumber.hasError('required'):
        return 'Numéro de TVA intracommunautaire requis';
      default:
        return null;
    }
  }

  constructor() {
    super({
      legalForm: new FormControl('', { nonNullable: true }),
      capital: new FormControl<number | null>(null, { nonNullable: true }),
      apeCode: new FormControl('', { nonNullable: true }),
      rcsCity: new FormControl('', { nonNullable: true }),
      siret: new FormControl('', { nonNullable: true }),
      intracomVatNumber: new FormControl('', { nonNullable: true }),
    });
  }

  fromCompany({
    legalForm,
    shareCapital: capital,
    apeCode,
    rcsCity,
    siret,
    intracomVatNumber,
  }: Company): void {
    this.patchValue(
      {
        legalForm,
        capital,
        apeCode,
        rcsCity,
        siret,
        intracomVatNumber,
      },
      { emitEvent: false },
    );
  }

  setInvoicingSavedStatusValidators({
    isEDocument,
    isFrenchSeller,
  }: {
    isEDocument: boolean;
    isFrenchSeller: boolean;
  }): void {
    const isEDocumentWithFrenchSeller = isEDocument && isFrenchSeller;
    this.setCountryBasedControlsRequired(isEDocumentWithFrenchSeller);

    this.controls.intracomVatNumber.setValidators(
      Validators.pattern(INTRACOM_VAT_REGEX),
    );
    this.controls.intracomVatNumber.updateValueAndValidity({
      emitEvent: false,
    });

    this.setValidators(isEDocument ? Validators.required : null);
    this.updateValueAndValidity({ emitEvent: false });
  }

  setCountryBasedControlsRequired(isRequired: boolean): void {
    this.controls.legalForm.setValidators(
      isRequired ? Validators.required : null,
    );
    this.controls.capital.setValidators(
      isRequired ? Validators.required : null,
    );

    const siretValidators = [
      Validators.pattern(SIRET_REGEX),
      ValidatorsUtils.siretValidator(),
    ];
    if (isRequired) {
      siretValidators.push(Validators.required);
    }

    this.controls.siret.setValidators(Validators.compose(siretValidators));
    this.controls.legalForm.updateValueAndValidity({ emitEvent: false });
    this.controls.capital.updateValueAndValidity({ emitEvent: false });
    this.controls.siret.updateValueAndValidity({ emitEvent: false });
    this.updateValueAndValidity({ emitEvent: false });
  }
}
