import { FormControl, FormGroup, Validators } from '@angular/forms';
import { FormUtils } from '@manakincubber/tiime-utils';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { tap } from 'rxjs/operators';

import { FrenchTelephoneCode } from '@core/enum';
import { EmployeeContact, User } from '@models';

@UntilDestroy()
export class AssignCompanyCardForm extends FormGroup {
  get user(): FormControl<User> {
    return this.get('user') as FormControl<User>;
  }

  get id(): FormControl<number> {
    return this.get('id') as FormControl<number>;
  }

  get lastname(): FormControl<string> {
    return this.get('lastname') as FormControl<string>;
  }

  get firstname(): FormControl<string> {
    return this.get('firstname') as FormControl<string>;
  }

  get phoneCode(): FormControl<FrenchTelephoneCode> {
    return this.get('phoneCode') as FormControl<FrenchTelephoneCode>;
  }

  get phone(): FormControl<string> {
    return this.get('phone') as FormControl<string>;
  }

  get email(): FormControl<string> {
    return this.get('email') as FormControl<string>;
  }

  constructor() {
    super({
      user: new FormControl(null),
      id: new FormControl(null),
      lastname: new FormControl('', [Validators.required]),
      firstname: new FormControl('', [Validators.required]),
      phoneCode: new FormControl(FrenchTelephoneCode.France),
      phone: new FormControl('', [
        Validators.required,
        Validators.pattern('[0-9]*'),
      ]),
      email: new FormControl('', [Validators.required]),
    });

    this.listenOnUserChange();
  }

  toEmployeeContact(): EmployeeContact {
    if (this.user.value.id) {
      const employeeContact = EmployeeContact.fromUser(this.user.value);

      // if the field is enabled the user has no default phone number
      if (this.phone.enabled) {
        employeeContact.phone = this.phoneCode.value + this.phone.value;
      }

      return employeeContact;
    }

    return new EmployeeContact(
      undefined,
      this.lastname.value,
      this.firstname.value,
      this.phoneCode.value + this.phone.value,
      this.email.value,
    );
  }

  private listenOnUserChange(): void {
    this.user.valueChanges
      .pipe(
        untilDestroyed(this),
        tap(user => {
          if (user.id) {
            this.disable(FormUtils.shouldNotEmitEvent);
            this.user.enable(FormUtils.shouldNotEmitEvent);

            if (!user.phone) {
              this.phoneCode.enable(FormUtils.shouldNotEmitEvent);
              this.phone.enable(FormUtils.shouldNotEmitEvent);
            }

            this.reset(
              {
                user,
                id: user.id,
                lastname: user.lastName,
                firstname: user.firstName,
                phoneCode: user.phone
                  ? User.getPhoneCodeFromPhoneNumber(user.phone)
                  : FrenchTelephoneCode.France,
                phone: user.phone
                  ? User.getPhoneNumberWithoutPhoneCode(user.phone)
                  : '',
                email: user.email,
              },
              FormUtils.shouldNotEmitEvent,
            );

            return;
          }

          this.reset(
            {
              user,
              lastname: '',
              firstname: '',
              phoneCode: FrenchTelephoneCode.France,
              phone: '',
              email: '',
            },
            FormUtils.shouldNotEmitEvent,
          );

          this.enable(FormUtils.shouldNotEmitEvent);
        }),
      )
      .subscribe();
  }
}
