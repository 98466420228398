import { Injectable, inject } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { TrackingService } from '@manakincubber/tiime-tracking';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { TagDialogService } from 'projects/tiime/src/app/shared/components/tag-dialog/tag-dialog.service';
import { Observable, Subject, switchMap, tap } from 'rxjs';
import { SnackbarConfig, TiimeSnackbarService } from 'tiime-components';

import { DocumentsService } from '@core';
import { MassActionStarted } from '@core/amplitude';
import { StandardDocumentCategoryIdentifier } from '@core/enum';
import { ActionsType } from '@core/enum/mass-actions';
import { filterNotNullOrUndefined } from '@core/helpers';
import {
  Document,
  DocumentPaymentStatusEnum,
  DocumentTypeEnum,
  ExpenseReportType,
  Tag,
} from '@core/models';
import { Label } from '@core/models/labels';
import { MatchingResponseInterface } from '@core/models/matching';
import { MassMatchingDialogService } from '@matching/matching-dialog/mass-matching-dialog.service';

import {
  MassActionsLabelDialogComponent,
  MassActionsLabelDialogData,
  MassActionsLabelDialogResponse,
} from '../../../../company-shared/dialogs/mass-actions-label-dialog/mass-actions-label-dialog.component';
import {
  getDocumentIdsEligibleForStatusUpdate,
  someDocumentsAreMatchedAndPaid,
} from '../../utils/functions';
import {
  DocumentBulkDeleteDialogComponent,
  DocumentBulkDeleteDialogData,
  DocumentBulkDeleteDialogResponse,
} from '../document-bulk-delete-dialog/document-bulk-delete-dialog.component';
import { DocumentBulkMoveDialogResponse } from '../document-bulk-move-dialog/document-bulk-move-dialog.component';
import { DocumentBulkMoveDialogService } from '../document-bulk-move-dialog/document-bulk-move-dialog.service';

@UntilDestroy()
@Injectable({
  providedIn: 'root',
})
export class DocumentsMassActionsToolbarService {
  private readonly dialog = inject(MatDialog);
  private readonly documentsService = inject(DocumentsService);
  private readonly snackbarService = inject(TiimeSnackbarService);
  private readonly trackingService = inject(TrackingService);
  private readonly massMatchingDialogService = inject(
    MassMatchingDialogService,
  );
  private readonly documentBulkMoveDialogService = inject(
    DocumentBulkMoveDialogService,
  );
  private readonly tagDialogService = inject(TagDialogService);

  readonly actionSubject$ = new Subject<ActionsType>();

  performMassAction(
    action: ActionsType,
    selectedDocuments: Document[],
    hasExtraFilters: boolean,
    areAllSelected: boolean,
  ): Observable<unknown> | null {
    switch (action) {
      case ActionsType.Label:
        return this.openLabelsDialog(
          selectedDocuments,
          hasExtraFilters,
          areAllSelected,
        );
      case ActionsType.Link:
        return this.openMatchingDialog(
          selectedDocuments,
          hasExtraFilters,
          areAllSelected,
        );
      case ActionsType.Move:
        return this.openMoveDocumentsDialog(
          selectedDocuments,
          hasExtraFilters,
          areAllSelected,
        );
      case ActionsType.Download:
        this.bulkDownloadDocuments(
          selectedDocuments,
          hasExtraFilters,
          areAllSelected,
        );
        return null;
      case ActionsType.Tag:
        return this.openTagsDialog(
          selectedDocuments,
          hasExtraFilters,
          areAllSelected,
        );
      case ActionsType.PaymentPaid:
        return this.bulkUpdatePaymentStatus(
          DocumentPaymentStatusEnum.PAID,
          selectedDocuments,
          hasExtraFilters,
          areAllSelected,
        );
      case ActionsType.PaymentPending:
        return this.bulkUpdatePaymentStatus(
          DocumentPaymentStatusEnum.PENDING,
          selectedDocuments,
          hasExtraFilters,
          areAllSelected,
        );
      case ActionsType.Delete:
        return this.openDeleteDialog(
          selectedDocuments,
          hasExtraFilters,
          areAllSelected,
        );
      default:
        throw new Error('Action not implemented');
    }
  }

  private openLabelsDialog(
    selectedDocuments: Document[],
    hasExtraFilters: boolean,
    areAllSelected: boolean,
  ): Observable<{
    labelOrTag: Label | Tag;
    documentUpdatedIds: number[];
  }> {
    this.emitMassActionTrackingEvent(
      ActionsType.Label,
      hasExtraFilters,
      areAllSelected,
    );

    const documentIds = selectedDocuments
      .filter(doc => doc.type !== DocumentTypeEnum.ExpenseReport)
      .map(doc => doc.id);

    const isExternalInvoices =
      selectedDocuments[0].category.identifier ===
      StandardDocumentCategoryIdentifier.EXTERNAL_INVOICE;

    return this.documentsService
      .getLockedAndUnlockedDocumentIds(documentIds)
      .pipe(
        switchMap(({ documentLockedIds, documentsUnlockedIds }) =>
          this.dialog
            .open<
              MassActionsLabelDialogComponent,
              MassActionsLabelDialogData,
              MassActionsLabelDialogResponse
            >(MassActionsLabelDialogComponent, {
              panelClass: 'tiime-dialog-panel',
              width: '652px',
              height: documentLockedIds.length > 0 ? '636px' : '530px',
              data: {
                documentIds: documentsUnlockedIds,
                numberOfBlockedDocuments: documentLockedIds.length,
                isExternalInvoices: isExternalInvoices,
              },
            })
            .beforeClosed(),
        ),
        filterNotNullOrUndefined(),
        switchMap(({ labelOrTag, documentsToUpdateIds }) =>
          this.documentsService.tagOrLabelizeMultipleDocuments(
            labelOrTag,
            documentsToUpdateIds,
          ),
        ),
        tap(({ labelOrTag, documentUpdatedIds }) =>
          this.displaySnackbarForMassAction(
            documentUpdatedIds.length,
            labelOrTag instanceof Label ? ActionsType.Label : ActionsType.Tag,
          ),
        ),
      );
  }

  private openMatchingDialog(
    selectedDocuments: Document[],
    hasExtraFilters: boolean,
    areAllSelected: boolean,
  ): Observable<void | MatchingResponseInterface[]> {
    this.emitMassActionTrackingEvent(
      ActionsType.Link,
      hasExtraFilters,
      areAllSelected,
    );
    return this.massMatchingDialogService
      .openMassActionsMatchingDialog(selectedDocuments)
      .pipe(
        tap(() => {
          this.displaySnackbarForMassAction(
            selectedDocuments.length,
            ActionsType.Link,
          );
        }),
      );
  }

  private openMoveDocumentsDialog(
    selectedDocuments: Document[],
    hasExtraFilters: boolean,
    areAllSelected: boolean,
  ): Observable<DocumentBulkMoveDialogResponse> {
    this.emitMassActionTrackingEvent(
      ActionsType.Move,
      hasExtraFilters,
      areAllSelected,
    );
    return this.documentBulkMoveDialogService
      .openDialog(selectedDocuments)
      .pipe(
        filterNotNullOrUndefined(),
        tap(({ numberOfUpdatedDocuments }) =>
          this.displaySnackbarForMassAction(
            numberOfUpdatedDocuments,
            ActionsType.Move,
          ),
        ),
      );
  }

  private bulkDownloadDocuments(
    selectedDocuments: Document[],
    hasExtraFilters: boolean,
    areAllSelected: boolean,
  ): void {
    this.emitMassActionTrackingEvent(
      ActionsType.Download,
      hasExtraFilters,
      areAllSelected,
    );
    const documentIds = selectedDocuments.map(doc => doc.id);
    this.documentsService
      .postDocumentsToDownload(documentIds)
      .pipe(
        tap(() => {
          const message =
            "L'export est en cours, vous recevrez un email vous permettant de le télécharger";
          this.snackbarService.open(message, SnackbarConfig.success);
        }),
        untilDestroyed(this),
      )
      .subscribe();
  }

  private openTagsDialog(
    selectedDocuments: Document[],
    hasExtraFilters: boolean,
    areAllSelected: boolean,
  ): Observable<void> {
    this.emitMassActionTrackingEvent(
      ActionsType.Tag,
      hasExtraFilters,
      areAllSelected,
    );
    const documentIds = selectedDocuments.map(doc => doc.id);
    return this.tagDialogService
      .openDialog({
        showAutomaticTags: true,
        tags: [],
        title: '',
      })
      .pipe(
        switchMap((tags: Tag[]) =>
          this.documentsService.updateDocumentsTags(documentIds, tags),
        ),
        tap(() => {
          this.displaySnackbarForMassAction(
            documentIds.length,
            ActionsType.Tag,
          );
        }),
      );
  }

  private bulkUpdatePaymentStatus(
    status: DocumentPaymentStatusEnum,
    selectedDocuments: Document[],
    hasExtraFilters: boolean,
    areAllSelected: boolean,
  ): Observable<Document[]> {
    this.emitMassActionTrackingEvent(
      status === DocumentPaymentStatusEnum.PENDING
        ? ActionsType.PaymentPending
        : ActionsType.PaymentPaid,
      hasExtraFilters,
      areAllSelected,
    );
    const hasMatchedPaidDocuments =
      someDocumentsAreMatchedAndPaid(selectedDocuments);
    const documentIds = getDocumentIdsEligibleForStatusUpdate(
      selectedDocuments,
      status,
    );

    return this.documentsService
      .bulkUpdatePaymentStatus(documentIds, status)
      .pipe(
        tap(documents => {
          this.displaySnackbarForBulkUpdatePaymentStatus(
            documents,
            status,
            hasMatchedPaidDocuments,
          );
        }),
      );
  }

  private openDeleteDialog(
    selectedDocuments: Document[],
    hasExtraFilters: boolean,
    areAllSelected: boolean,
  ): Observable<number> {
    this.emitMassActionTrackingEvent(
      ActionsType.Delete,
      hasExtraFilters,
      areAllSelected,
    );
    return this.documentsService
      .getLockedAndUnlockedDocumentIds(selectedDocuments.map(({ id }) => id))
      .pipe(
        switchMap(({ documentsUnlockedIds, documentLockedIds }) => {
          const matchedDocuments = selectedDocuments.filter(
            doc => doc.hasBankTransactions,
          );
          const travelExpenseReports = selectedDocuments.filter(
            doc => doc.expenseType === ExpenseReportType.Travel,
          );

          const documentIds = selectedDocuments
            .filter(
              doc =>
                !doc.hasBankTransactions &&
                doc.isDeletable &&
                documentsUnlockedIds.includes(doc.id),
            )
            .map(doc => doc.id);

          return this.dialog
            .open<
              DocumentBulkDeleteDialogComponent,
              DocumentBulkDeleteDialogData,
              DocumentBulkDeleteDialogResponse
            >(DocumentBulkDeleteDialogComponent, {
              panelClass: 'tiime-dialog-panel',
              width: '652px',
              minHeight: '396px',
              height: 'auto',
              data: {
                documentIds,
                numberOfLockedDocuments: documentLockedIds.length,
                numberOfMatchedDocuments: matchedDocuments.length,
                numberOfExpenseReports: travelExpenseReports.length,
              },
            })
            .afterClosed()
            .pipe(
              filterNotNullOrUndefined(),
              switchMap(response =>
                this.documentsService.bulkDeleteDocuments(
                  response.documentsToDelete,
                ),
              ),
              tap(numberOfDeletedDocuments => {
                this.displaySnackbarForMassAction(
                  numberOfDeletedDocuments,
                  ActionsType.Delete,
                );
              }),
            );
        }),
      );
  }

  private displaySnackbarForMassAction(
    numberOfDocuments: number,
    actionType: ActionsType,
  ): void {
    let actionVerb = '';
    switch (actionType) {
      case ActionsType.Label:
        actionVerb = numberOfDocuments > 1 ? 'labélisés' : 'labélisé';
        break;
      case ActionsType.Move:
        actionVerb = numberOfDocuments > 1 ? 'déplacés' : 'déplacé';
        break;
      case ActionsType.Tag:
        actionVerb = numberOfDocuments > 1 ? 'tagués' : 'tagué';
        break;
      case ActionsType.Delete:
        actionVerb = numberOfDocuments > 1 ? 'supprimés' : 'supprimé';
        break;
      case ActionsType.Link:
        actionVerb = numberOfDocuments > 1 ? 'reliés' : 'relié';
        break;
      default:
        actionVerb = numberOfDocuments > 1 ? 'traités' : 'traité';
        break;
    }
    const message =
      numberOfDocuments > 1
        ? `Les ${numberOfDocuments} documents ont bien été ${actionVerb}`
        : `Le document a bien été ${actionVerb}`;

    this.snackbarService.open(message, SnackbarConfig.success);
  }

  private displaySnackbarForBulkUpdatePaymentStatus(
    documents: Document[],
    status: DocumentPaymentStatusEnum,
    hasMatchedPaidDocuments: boolean,
  ): void {
    const actionVerb =
      status === DocumentPaymentStatusEnum.PAID ? 'ajouté' : 'retiré';
    let message = `Le statut « Payée » a bien été ${actionVerb} sur`;

    message +=
      documents.length > 1
        ? ` les ${documents.length} documents`
        : ` le document`;

    if (
      hasMatchedPaidDocuments &&
      status === DocumentPaymentStatusEnum.PENDING
    ) {
      message += documents.length > 1 ? ' non matchés.' : ' non matché.';
    } else {
      message += '.';
    }
    this.snackbarService.open(message, SnackbarConfig.success);
  }

  private emitMassActionTrackingEvent(
    actionType: ActionsType,
    hasExtraFilters: boolean,
    areAllSelected: boolean,
  ): void {
    this.trackingService.dispatch(
      new MassActionStarted(
        actionType,
        hasExtraFilters,
        areAllSelected,
        'document',
      ),
    );
  }
}
