import { TrackingEvent } from '@manakincubber/tiime-tracking';

import { Invoice } from '@models';

export const INVOICE_SAVE_ATTEMPT_FAILED = 'Invoice Save Attempt Failed';

export class InvoiceSaveAttemptFailed implements TrackingEvent {
  readonly name = INVOICE_SAVE_ATTEMPT_FAILED;

  constructor(
    readonly invoiceId?: Invoice['id'],
    readonly invalidControls?: string[],
  ) {}
}
