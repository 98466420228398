import { formatDate } from '@angular/common';
import {
  Inject,
  InjectionToken,
  LOCALE_ID,
  Optional,
  Pipe,
  PipeTransform,
} from '@angular/core';
import moment, { Moment } from 'moment';

export const DATE_PIPE_DEFAULT_TIMEZONE = new InjectionToken<string>(
  'DATE_PIPE_DEFAULT_TIMEZONE',
);

@Pipe({ name: 'tiimeDatetime', pure: true })
export class DatetimePipe implements PipeTransform {
  constructor(
    @Inject(LOCALE_ID) private locale: string,
    @Inject(DATE_PIPE_DEFAULT_TIMEZONE)
    @Optional()
    private defaultTimezone?: string | null,
  ) {}

  transform(
    value: Date | string | number | Moment,
    format?: string,
    timezone?: string,
    locale?: string,
  ): string | null;
  transform(
    value: null | undefined,
    format?: string,
    timezone?: string,
    locale?: string,
  ): null;
  transform(
    value: Date | string | number | Moment | null | undefined,
    format?: string,
    timezone?: string,
    locale?: string,
  ): string | null;
  transform(
    value: Date | string | number | Moment | null | undefined,
    format = 'mediumDate',
    timezone?: string,
    locale?: string,
  ): string | null {
    if (value == null || value === '' || value !== value) return null;

    const safariCompatibleValue =
      value instanceof moment
        ? moment(value).toDate()
        : // eslint-disable-next-line @typescript-eslint/no-base-to-string
          value.toString().replace(' ', 'T');

    try {
      return formatDate(
        safariCompatibleValue,
        format,
        locale || this.locale,
        timezone ?? this.defaultTimezone ?? undefined,
      );
    } catch (error) {
      throw Error((error as Error).message);
    }
  }
}
