import { ChangeDetectionStrategy, Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { SnackbarConfig, TiimeSnackbarService } from 'tiime-components';

import { UPLOAD_DOCUMENT_CONFIG } from '@constants';
import { UserPermissionNameEnum } from '@enums/users';

import { DocumentUploaderBase } from '../../bases/document-uploader.base';

@Component({
  selector: 'app-document-uploader-dialog',
  templateUrl: './document-uploader-dialog.component.html',
  styleUrls: ['./document-uploader-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DocumentUploaderDialogComponent extends DocumentUploaderBase {
  readonly UserPermissionNameEnum = UserPermissionNameEnum;

  constructor(
    private readonly snackbar: TiimeSnackbarService,
    private readonly dialogRef: MatDialogRef<DocumentUploaderDialogComponent>,
  ) {
    super();
  }

  openErrorSnackbar(): void {
    this.snackbar.open(
      UPLOAD_DOCUMENT_CONFIG.errorMessage,
      SnackbarConfig.error,
    );
  }

  closeDialog(): void {
    this.dialogRef.close();
  }
}
