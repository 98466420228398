import { Injectable } from '@angular/core';
import { PRIMARY_OUTLET, Router, UrlTree } from '@angular/router';

@Injectable({
  providedIn: 'root',
})
export class SettingsRedirectionService {
  private savedURL: UrlTree;

  constructor(private readonly router: Router) {}

  saveNavigationState(url: string): void {
    const tree = this.router.parseUrl(url);
    const segments = tree.root.children[PRIMARY_OUTLET].segments;
    // check if we're already in settings page, then don't save url
    if (segments.some(segment => segment.path === 'settings')) {
      return;
    }
    this.savedURL = this.router.parseUrl(url);
  }

  getSavedNavigation(): UrlTree {
    return this.savedURL;
  }

  clearNavigationState(): void {
    delete this.savedURL;
  }
}
