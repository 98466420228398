import { FormGroup, FormControl, AbstractControl } from '@angular/forms';
import { MonoValueFilter } from 'tiime-components';

export class TransactionsFilterForm extends FormGroup {
  get hideRefused(): AbstractControl<boolean> {
    return this.get('hideRefused');
  }

  constructor() {
    super({
      hideRefused: new FormControl(null),
    });
  }

  toOutput(): MonoValueFilter<boolean> {
    return new MonoValueFilter('hide_refused', this.hideRefused.value);
  }
}
