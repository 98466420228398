import { FormArray, Validators } from '@angular/forms';
import { FormUtils, noop } from '@manakincubber/tiime-utils';
import { BehaviorSubject, merge, Observable } from 'rxjs';
import { map, startWith, switchMap, tap } from 'rxjs/operators';

import {
  InvoicingCategoriesEnum,
  InvoicingTemplate,
  VatTypeCode,
} from '@enums';
import { VatTypeHelper } from '@helpers';
import { Line, TextLine, VatType } from '@models';

import { LineForm } from './line-form';
import { TextLineForm } from './text-line-form';

type AcceptedLineForm = LineForm | TextLineForm;
type AmountsLinesChange = {
  controlsAfterChange: LineForm[];
  lineAddedOrDeleted?: LineForm;
};

export class LinesFormArray extends FormArray<AcceptedLineForm> {
  private amountLinesChangesSubject: BehaviorSubject<AmountsLinesChange> =
    new BehaviorSubject({ controlsAfterChange: this.amountLines() });

  get amountLinesChanges$(): Observable<AmountsLinesChange> {
    return this.amountLinesChangesSubject.asObservable();
  }

  get amountLines$(): Observable<LineForm[]> {
    return this.amountLinesChanges$.pipe(
      map(({ controlsAfterChange }) => controlsAfterChange),
    );
  }

  get computationChanges$(): Observable<unknown> {
    return this.amountLinesChanges$.pipe(
      switchMap(({ controlsAfterChange, lineAddedOrDeleted }) =>
        merge(
          ...controlsAfterChange.map(control => control.computationChanges$),
        ).pipe(
          // Skip init when adding or deleting a line with 0 or no total
          // excluding taxes which will not alter computations
          !lineAddedOrDeleted || lineAddedOrDeleted.totalExcludingTaxes.value
            ? startWith('init')
            : tap(noop),
        ),
      ),
    );
  }

  constructor() {
    super([]);
  }

  amountLines(): LineForm[] {
    return this.controls.filter(control => !control.isTextLine) as LineForm[];
  }

  initNewLine(
    invoicingCategoryType: InvoicingCategoriesEnum,
    vatTypeCode: string,
    template: InvoicingTemplate,
  ): void {
    const lineForm = new LineForm();
    lineForm.patchValue({
      vatType: new VatType(vatTypeCode),
      invoicingCategoryType,
      sequence: 1,
      totalExcludingTaxes: template === InvoicingTemplate.standard ? null : 0,
    });
    this.push(lineForm, { emitEvent: false });
    this.amountLinesChangesSubject.next({
      controlsAfterChange: this.amountLines(),
    });
  }

  initExistingLines(lines: Line[], textLines: TextLine[]): void {
    if (this.length) {
      this.clear({ emitEvent: false });
    }

    [...lines, ...textLines]
      .sort(({ sequence: aLineSequence }, { sequence: bLineSequence }) => {
        if (!aLineSequence) {
          return bLineSequence ? 1 : 0;
        }
        return bLineSequence ? aLineSequence - bLineSequence : -1;
      })
      .map((line, index) => {
        const form = line instanceof Line ? new LineForm() : new TextLineForm();
        form.patchValue({ ...line, sequence: index + 1 });
        return form;
      })
      .forEach(form => this.push(form, { emitEvent: false }));
    this.amountLinesChangesSubject.next({
      controlsAfterChange: this.amountLines(),
    });
  }

  addLine(line: AcceptedLineForm): void {
    this.markAsDirty();
    this.push(line, { emitEvent: false });
    if (line instanceof LineForm) {
      this.amountLinesChangesSubject.next({
        controlsAfterChange: this.amountLines(),
        lineAddedOrDeleted: line,
      });
    }
  }

  deleteLine(line: AcceptedLineForm): void {
    const index = this.controls.findIndex(lineForm => lineForm === line);
    this.markAsDirty();
    this.removeAt(index, { emitEvent: false });
    if (line instanceof LineForm) {
      this.amountLinesChangesSubject.next({
        controlsAfterChange: this.amountLines(),
        lineAddedOrDeleted: line,
      });
    }
  }

  firstLineWithZeroVatTypeCode(): LineForm | undefined {
    return this.amountLines().find(control => {
      const vatTypeCodeValue = control.vatTypeCode.value as VatTypeCode;
      return (
        vatTypeCodeValue === VatTypeCode.none ||
        vatTypeCodeValue === VatTypeCode.without ||
        vatTypeCodeValue === VatTypeCode.foreigner_exoneration ||
        vatTypeCodeValue === VatTypeCode.france_exoneration
      );
    });
  }

  setInvoicingSavedStatusValidators(): void {
    this.controls.forEach(control =>
      control.setInvoicingSavedStatusValidators(),
    );
    this.setValidators(Validators.required);
    this.updateValueAndValidity(FormUtils.shouldNotEmitEvent);
  }

  setVatTypeCodeToLinesWithZeroVatRate(
    vatTypeCode: string,
    vatTypes: VatType[],
  ): void {
    this.getLinesWithZeroVatRate(vatTypes).forEach((lineForm: LineForm) => {
      lineForm.markAsDirty();
      lineForm.vatTypeCode.setValue(vatTypeCode);
    });
  }

  getLinesWithZeroVatRate(vatTypes: VatType[]): LineForm[] {
    const vatTypesWithLegalNotice =
      VatTypeHelper.vatTypesWithLegalNotice(vatTypes);
    return this.amountLines().filter(
      control =>
        !!vatTypesWithLegalNotice.find(
          (vatType: VatType) => vatType.code === control.vatTypeCode.value,
        ),
    );
  }
}
