<tiime-dialog-close-button></tiime-dialog-close-button>

<div mat-dialog-content>
  <div class="idle-state-default">
    <img
      src="assets/illu_no_document_fond_gris.svg"
      draggable="false"
      alt="Ajouter un document"
    />
    <div class="instructions">
      <div class="white-circle">
        <mat-icon svgIcon="icon-upload"></mat-icon>
      </div>
      <app-add-document-button
        (unauthorizedType)="openErrorSnackbar()"
        (incorrectSize)="openErrorSnackbar()"
        (documentAdded)="closeDialog()"
      ></app-add-document-button>
    </div>

    <p class="text-before-listing">Vous pouvez également :</p>

    <div class="info-card">
      <img
        class="illustration-dnd-documents"
        src="assets/illus/categories-list/illu_dnd_documents.svg"
        alt="Illustration drang and drop documents"
      />
      <div>
        <p class="email-card-info-text">
          Glisser / déposer les documents
          <br/>
          directement dans les espaces !
        </p>
      </div>
    </div>

    <app-email-document-info
      *hasAccess="UserPermissionNameEnum.ACCOUNTS_USER_FULL"
    ></app-email-document-info>
  </div>
</div>
