import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { AccountingPeriod } from '@core/models';
import { ApiAlertError } from '@decorators/api-alert-error';

@Injectable({
  providedIn: 'root',
})
export class AccountingPeriodService {
  readonly resource = 'api/v1/companies/{companyId}/accounting_period';

  constructor(private readonly http: HttpClient) {}

  @ApiAlertError()
  getAccountingLockDate(): Observable<string> {
    const url = `api/v1/companies/{companyId}/accounting_lock_date`;
    return this.http
      .get(url)
      .pipe(
        map(
          (accountingLockDate: { value: string }) => accountingLockDate.value,
        ),
      );
  }

  @ApiAlertError()
  getAccountingPeriod(rangeYear?: number): Observable<AccountingPeriod> {
    return this.http
      .get(`${this.resource}/current`, {
        params: {
          range_year: rangeYear,
        },
      })
      .pipe(map(json => AccountingPeriod.fromJson(json)));
  }
}
