import { ElementRef } from '@angular/core';
import { OverlayPosition, TiimeOverlayConfig } from 'tiime-components';

export const getLabelOrVentilationsOverlayConfig = (
  ref: ElementRef,
  position?: OverlayPosition,
): TiimeOverlayConfig => {
  return {
    backdropClass: 'cdk-overlay-transparent-backdrop',
    maxHeight: '100%',
    maxWidth: '100%',
    hasBackdrop: true,
    backdropClose: true,
    connectTo: {
      origin: ref,
      positions: [
        {
          originX: position?.originX || 'start',
          originY: position?.originY || 'top',
          overlayX: position?.overlayX || 'start',
          overlayY: position?.overlayY || 'top',
          offsetX: position?.offsetX || -2,
          offsetY: position?.offsetY || -1,
        },
      ],
    },
  };
};
