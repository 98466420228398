import { SelectionModel } from '@angular/cdk/collections';
import { ChangeDetectorRef, Directive, inject } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { TrackingService } from '@manakincubber/tiime-tracking';
import { untilDestroyed } from '@ngneat/until-destroy';
import { Store } from '@ngrx/store';
import { BankTransferOverlayService } from 'projects/tiime/src/app/company/account/bank-transfers/bank-transfers-shared/components/bank-transfert-overlay/bank-transfer-overlay.service';
import { Observable } from 'rxjs';
import { finalize, map, switchMap, tap } from 'rxjs/operators';
import {
  AdvancedTableBase,
  SnackbarConfig,
  TiimeOverlayService,
  TiimeSnackbarService,
} from 'tiime-components';

import { DocumentsService, TransactionsService } from '@core';
import {
  AppliedOnEnum,
  MassActionMultipleItemsSelected,
  TagAdded,
} from '@core/amplitude';
import { BankTransferSource } from '@core/amplitude/constants';
import { MatchingDialogService } from '@matching/matching-dialog/matching-dialog.service';
import {
  BankTransferState,
  Document,
  LinkedBankTransaction,
  Tag,
} from '@models';
import {
  BankTransaction,
  BankTransactionImputation,
} from '@models/bank-transaction';
import { Label } from '@models/labels';
import {
  LinkedEntity,
  LinkedEntityBankTransaction,
  LinkedEntityImputation,
} from '@models/linked-entities';
import { isKycValidatedSelector } from '@store/wallet-account';

import { ConfirmationDialogService } from '../../../../../../shared/components/confirmation-dialog/confirmation-dialog.service';
import { BankTransferOverlayOptions } from '../columns/actions/documents-actions-column-content/documents-actions-column-content.component';

@Directive()
export abstract class AbstractDocumentsTableDirective extends AdvancedTableBase<Document> {
  displayedColumns$: Observable<string[]>;

  readonly kycValidated$ = this.store.select(isKycValidatedSelector);

  protected readonly trackingService = inject(TrackingService);
  protected readonly bankTransferOverlayService = inject(
    BankTransferOverlayService,
  );

  readonly selectionModel = new SelectionModel<Document>(
    true,
    [],
    true,
    (a, b) => a.id === b.id,
  );

  readonly actionColumnDisabled$ = this.selectionModel.changed.pipe(
    map(() => this.selectionModel.hasValue()),
  );

  protected constructor(
    protected readonly router: Router,
    protected readonly route: ActivatedRoute,
    protected readonly cdr: ChangeDetectorRef,
    protected readonly store: Store,
    protected readonly dialog: MatDialog,
    protected readonly transactionsService: TransactionsService,
    protected readonly snackbarService: TiimeSnackbarService,
    protected readonly documentsService: DocumentsService,
    protected readonly confirmationDialogService: ConfirmationDialogService,
    protected readonly matchingDialogService: MatchingDialogService,
    protected readonly overlayService: TiimeOverlayService,
  ) {
    super(router, route, cdr);
  }

  openLinkTransactionDialog(document: Document): void {
    this.matchingDialogService
      .openMatchingDialog({
        matchingSource: document,
        matchedItems: [
          ...document.getBankTransactions(),
          ...document.getImputations(),
        ],
      })
      .pipe(
        switchMap(({ labelOrTagToAdd, matchedItems }) =>
          this.documentsService
            .matchDocumentTransactions(
              document.id,
              matchedItems
                .filter(matchedItem => {
                  return (
                    matchedItem instanceof BankTransaction ||
                    matchedItem instanceof LinkedBankTransaction ||
                    matchedItem instanceof LinkedEntityBankTransaction
                  );
                })
                .map(bankTransaction =>
                  bankTransaction instanceof LinkedEntity
                    ? bankTransaction.value.id
                    : bankTransaction.id,
                ),
              matchedItems
                .filter(matchedItem => {
                  return (
                    matchedItem instanceof BankTransactionImputation ||
                    matchedItem instanceof LinkedEntityImputation
                  );
                })
                .map(imputation =>
                  imputation instanceof LinkedEntity
                    ? imputation.value.id
                    : imputation.id,
                ),
              labelOrTagToAdd instanceof Label ? labelOrTagToAdd.id : undefined,
              labelOrTagToAdd instanceof Tag ? labelOrTagToAdd.name : undefined,
            )

            .pipe(
              map(matchingResponse => ({
                matchingResponse,
                labelOrTagToAdd,
              })),
            ),
        ),
        tap(res => {
          this.reload(document.id);

          let message =
            res.matchingResponse.length > 1
              ? 'Les transactions ont bien été liées au document'
              : 'La transaction a bien été liée au document';
          if (res.labelOrTagToAdd) {
            message = 'Le match et le label ont bien été pris en compte';
          }

          this.snackbarService.open(message, SnackbarConfig.success);
        }),
        untilDestroyed(this),
      )
      .subscribe();
  }

  detachLinkedBankTransaction(document: Document): void {
    this.documentsService
      .matchDocumentTransactions(document.id, [], [])
      .pipe(
        tap(() => {
          this.reload(document.id);

          this.snackbarService.open(
            'La transaction a bien été déliée du document',
            SnackbarConfig.success,
          );
        }),
        untilDestroyed(this),
      )
      .subscribe();
  }

  downloadDocument(documentId: number): void {
    this.updateLoadingState(true);
    this.documentsService
      .download(documentId)
      .pipe(
        finalize(() => {
          this.updateLoadingState(false);
          this.cdr.markForCheck();
        }),
      )
      .subscribe();
  }

  updateLoadingState(isLoading: boolean): void {
    this.isLoading = isLoading;
    this.cdr.markForCheck();
  }

  openDeleteDialog(document: Document): void {
    this.confirmationDialogService
      .requireConfirmation({
        theme: 'warn',
        title: `Êtes-vous sûr de vouloir supprimer "${document.name}" ?`,
        description: '',
        confirmLabel: 'Supprimer',
        cancelLabel: 'Annuler',
      })
      .pipe(tap(() => this.deleteDocument(document)))
      .subscribe();
  }

  openBankTransferOverlay(
    document: Document,
    opts?: BankTransferOverlayOptions,
  ): void {
    const transferSource: BankTransferSource = opts?.toMyself
      ? 'refundButton'
      : 'payButton';
    const bankTransferState: BankTransferState = {
      document,
      reason: opts?.toMyself ? `refund` : `documentPayment`,
      transferSource,
    };
    this.bankTransferOverlayService
      .open(bankTransferState)
      .pipe(tap(() => this.reloadSubject$.next()))
      .subscribe();
  }

  updateDocumentTags(tags: Tag[], document: Document): void {
    const newTags = tags.filter(tag => !document.tags.includes(tag));

    if (newTags.length) {
      this.trackingService.dispatch(new TagAdded(AppliedOnEnum.DOCUMENTS));
    }

    this.documentsService
      .update({ tags }, document.id)
      .pipe(
        tap(() => this.reload(document.id)),
        untilDestroyed(this),
      )
      .subscribe();
  }

  onQuickSelectionSelectAll(): void {
    if (this.areAllSelected) {
      this.emitQuickSelectionTrackingEvent('Aucun');
    } else {
      this.emitQuickSelectionTrackingEvent('Tous');
    }
    this.onSelectAllChange();
  }

  emitQuickSelectionTrackingEvent(selection: string): void {
    this.trackingService.dispatch(
      new MassActionMultipleItemsSelected(selection, 'document'),
    );
  }

  abstract reload(documentId?: number): void;

  abstract deleteDocument(document: Document): void;
}
