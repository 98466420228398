import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TiimeFileModule } from 'tiime-components';

import { DragAndDropZoneComponent } from './drag-and-drop-zone.component';

@NgModule({
  declarations: [DragAndDropZoneComponent],
  imports: [CommonModule, TiimeFileModule],
  exports: [DragAndDropZoneComponent],
})
export class DragAndDropZoneModule {}
