import {
  ChipFilter,
  ComplexSearchBarFilter,
  Filter,
  MonoValueFilter,
  OrFilter,
} from 'tiime-components';

import {
  INVOICE_COMPLEX_SEARCH_BAR_FILTERS,
  InvoiceComplexSearchBarFilterNameEnum,
} from '@core/constants/invoice';
import { InvoiceComplexFilterKey } from '@core/enum/invoice';
import { Client } from '@core/models';

import { DateHelper } from './date.helper';
import { RegexHelper } from './regex.helper';

export class InvoiceFiltersHelper {
  private static dateRegex = /^\d{4}-\d{2}-\d{2}$/;

  private static dateIntervalRegex =
    /^>=\d{4}-\d{2}-\d{2},<=\d{4}-\d{2}-\d{2}$/;

  private static dateFilter: ComplexSearchBarFilter = this.findFilterByName(
    InvoiceComplexSearchBarFilterNameEnum.invoiceDateValue,
  );

  private static dateGreaterOrEqualThanFilter: ComplexSearchBarFilter =
    this.findFilterByName(
      InvoiceComplexSearchBarFilterNameEnum.invoiceDateGreaterOrEqualThan,
    );
  private static dateLowerOrEqualThanFilter: ComplexSearchBarFilter =
    this.findFilterByName(
      InvoiceComplexSearchBarFilterNameEnum.invoiceDateLowerOrEqualThan,
    );

  private static amountGreaterOrEqualThanFilter: ComplexSearchBarFilter =
    this.findFilterByName(
      InvoiceComplexSearchBarFilterNameEnum.amountGreaterOrEqualThan,
    );
  private static amountLowerOrEqualThanFilter: ComplexSearchBarFilter =
    this.findFilterByName(
      InvoiceComplexSearchBarFilterNameEnum.amountLowerOrEqualThan,
    );
  private static amountIntervalFilter: ComplexSearchBarFilter =
    this.findFilterByName(InvoiceComplexSearchBarFilterNameEnum.amountInterval);

  private static dateIntervalFilter: ComplexSearchBarFilter =
    this.findFilterByName(
      InvoiceComplexSearchBarFilterNameEnum.invoiceDateInterval,
    );

  /**
   * Méthode de combinaison des filtres pour la recherche complexe
   *
   * Lorsqu'on trouve deux montants dans la recherche, on les combine pour faire un interval.
   * Exemple: "-500" et "1500" deviennent ">= -500" et "<= 1500"
   *
   * Lorsqu'on trouve deux dates dans la recherche, on les combine pour faire un interval.
   * Exemple: "01/01/2022" et "01/02/2022"  deviennent ">= 01/01/2022" et "01/02/2022"
   *
   */
  static combineComplexTransactionsFilters = (
    filters: ChipFilter[],
  ): ChipFilter[] => {
    let result: ChipFilter[] = [...filters];
    // On prend les filtres sur le champ de montant ou en recherche simple (par exemple pour le cas où on tape simplement "500")
    const amountFilters: ChipFilter[] = this.getAmountFilters(result);
    // Dans le cas où on a saisi deux nombres, on transforme ces filtres en deux filtres >= et <= pour créer un interval
    if (amountFilters.length === 2) {
      result = this.combineAmountFilters(
        [amountFilters[0], amountFilters[1]],
        result,
      );
    }

    const transactionDateFilters: ChipFilter[] = result.filter(
      cFilter => cFilter.apiFilter.key === InvoiceComplexFilterKey.date,
    );

    if (transactionDateFilters.length === 2) {
      result = this.combineDateFilters(
        [transactionDateFilters[0], transactionDateFilters[1]],
        result,
      );
    }
    return result;
  };

  static getAmountFilters(filters: ChipFilter[]): ChipFilter[] {
    const result = filters.filter(cFilter => {
      const { key, values } = cFilter.apiFilter;

      if (!values[0]) {
        return false;
      }

      const filterIsFloat = values[0].match(
        RegexHelper.floatWithNegativeSignRegex,
      );
      const filterIsInterval = values[0].match(RegexHelper.amountIntervalRegex);
      return (
        cFilter.apiFilter instanceof MonoValueFilter &&
        (key === InvoiceComplexFilterKey.amount ||
          (key === InvoiceComplexFilterKey.search &&
            (filterIsFloat || filterIsInterval)))
      );
    });

    // dans le cas ou il n'y a qu'un seul filtre comme "q=10", et d'autre filtre montant, ne pas combiner le filtre q avec les autres filtres montant
    const searchFilterWithNumber: ChipFilter[] = result.filter(
      cFilter =>
        cFilter.apiFilter instanceof MonoValueFilter &&
        cFilter.apiFilter.key === InvoiceComplexFilterKey.search,
    );

    if (searchFilterWithNumber.length === 1) {
      return result.filter(
        cFilter => cFilter.apiFilter.key !== InvoiceComplexFilterKey.search,
      );
    }

    return result;
  }

  static combineAmountFilters(
    amountFilters: [ChipFilter, ChipFilter],
    existingFilters: ChipFilter[],
  ): ChipFilter[] {
    const values = [
      amountFilters[0].apiFilter.values[0],
      amountFilters[1].apiFilter.values[0],
    ]
      // On les trie par ordre croissant
      .sort((a, b) => {
        const parsedA = parseFloat(this.sanitizeValue(a));
        const parsedB = parseFloat(this.sanitizeValue(b));
        if (parsedA > parsedB) {
          return 1;
        } else if (parsedB > parsedA) {
          return -1;
        }
        return 0;
      });
    // on enlève les filtres que l'on a combiné
    const remainingFilters = existingFilters.filter(f => {
      const possibleKeys: string[] = [
        InvoiceComplexFilterKey.amount,
        InvoiceComplexFilterKey.search,
      ];
      return !(
        possibleKeys.includes(f.apiFilter.key) &&
        values.includes(f.apiFilter.values[0])
      );
    });

    return [
      ...remainingFilters,
      {
        displayedValue: this.amountIntervalFilter.displayedValue(values),
        apiFilter: this.amountIntervalFilter.apiFilter(values),
      },
    ];
  }

  static combineDateFilters(
    transactionDateFilters: [ChipFilter, ChipFilter],
    existingFilters: ChipFilter[],
  ): ChipFilter[] {
    // On commence par transformer tous les filters en filtre "simple"
    const values: string[] = this.transformTransactionDateFilters(
      transactionDateFilters,
    ).map(v => v.apiFilter.values[0]);

    // On supprime les deux filtres qu'on transforme,
    const remainingFilters = existingFilters.filter(
      f => f.apiFilter.key !== InvoiceComplexFilterKey.date,
    );

    return [
      ...remainingFilters,
      {
        displayedValue: this.dateIntervalFilter.displayedValue(values),
        apiFilter: this.dateIntervalFilter.apiFilter(values),
      },
    ];
  }

  // /**
  //  * On transforme les filtres sur les dates pour n'avoir que des dates simples
  //  */
  public static transformTransactionDateFilters(
    filters: [ChipFilter, ChipFilter],
  ): ChipFilter[] {
    const intervalFilters: ChipFilter[] = filters.filter(
      ({ apiFilter }) =>
        apiFilter.values.length === 2 &&
        apiFilter.values.join(',').match(this.dateIntervalRegex),
    );

    // Dans le cas où on n'a pas d'interval, on peut renvoyer directement les filters
    if (intervalFilters.length === 0) {
      return filters;
    }

    /**
     * Si on a un seul interval, on doit en extraire les deux dates et trouver la date à utiliser
     * Si la date du filtre simple est avant l'interval, on l'utilise comme date de debut
     * Sinon, on utilise l'utilise comme date de fin
     */
    if (intervalFilters.length === 1) {
      const [startDate, endDate] = [
        this.extractDatesFromInterval(intervalFilters[0].apiFilter.values[0]),
        this.extractDatesFromInterval(intervalFilters[0].apiFilter.values[1]),
      ];
      const simpleFilter = filters.find(filter =>
        filter.apiFilter.values[0].match(this.dateRegex),
      );
      const simpleFilterDate = simpleFilter.apiFilter.values[0];

      let lowestDate = startDate;
      let highestDate = simpleFilterDate;

      if (simpleFilterDate < startDate) {
        lowestDate = simpleFilterDate;
        highestDate = endDate;
      }

      return [
        this.createDateFilter(lowestDate),
        this.createDateFilter(highestDate),
      ];
    }

    const [startDate1, endDate1] = [
      this.extractDatesFromInterval(intervalFilters[0].apiFilter.values[0]),
      this.extractDatesFromInterval(intervalFilters[0].apiFilter.values[1]),
    ];
    const [startDate2, endDate2] = [
      this.extractDatesFromInterval(intervalFilters[1].apiFilter.values[0]),
      this.extractDatesFromInterval(intervalFilters[1].apiFilter.values[1]),
    ];

    if (DateHelper.isAfter(startDate1, startDate2)) {
      return [
        this.createDateFilter(startDate2),
        this.createDateFilter(endDate1),
      ];
    }

    return [this.createDateFilter(startDate1), this.createDateFilter(endDate2)];
  }

  static extractDatesFromInterval(interval: string): string {
    return interval.replace('>', '').replace('<', '').replace('=', '');
  }

  static createDateFilter(date: string): ChipFilter {
    const formattedDate: string = DateHelper.format(date, 'DD/MM/yyyy');

    return {
      displayedValue: this.dateFilter.displayedValue(formattedDate),
      apiFilter: this.dateFilter.apiFilter(formattedDate),
    };
  }

  static sanitizeValue(value: string): string {
    return value
      .replace('>', '')
      .replace('<', '')
      .replace('=', '')
      .replace(',', '.');
  }

  private static findFilterByName(
    filterName: InvoiceComplexSearchBarFilterNameEnum,
  ): ComplexSearchBarFilter {
    return INVOICE_COMPLEX_SEARCH_BAR_FILTERS.find(
      ({ name }) => name === filterName,
    );
  }

  static convertFiltersClientToClientName(
    filters: Filter<unknown>[],
    clients: Client[],
  ): Filter<unknown>[] {
    return filters.map(filter => {
      if (filter.key !== 'client' || !clients) {
        return filter;
      }
      const values =
        filter.values.length === 1
          ? // eslint-disable-next-line @typescript-eslint/no-base-to-string
            filter.values[0].toString().split('|')
          : filter.values;
      const clientsNames = values.map(
        value => clients.find(client => client.id === +value).name,
      );
      if (clientsNames.length > 1) {
        return new OrFilter(InvoiceComplexFilterKey.clientName, clientsNames);
      }
      return new MonoValueFilter(
        InvoiceComplexFilterKey.clientName,
        clientsNames[0],
      );
    });
  }

  static convertFiltersClientNameToClient(
    filters: Filter<unknown>[],
    clients: Client[],
  ): Filter<unknown>[] {
    return filters.reduce((acc, filter) => {
      if (filter.key !== InvoiceComplexFilterKey.clientName) {
        acc.push(filter);
        return acc as Filter<unknown>[];
      }

      const newFilterValues: string[] = [];
      const clientIds = filter.values.reduce((ids: number[], value: string) => {
        const client = clients.find(
          client => client.name.toLowerCase() === value.toLowerCase(),
        );

        if (client) {
          ids.push(client.id);
        } else {
          newFilterValues.push(value);
        }

        return ids;
      }, []) as string[];

      return acc.concat(
        this.addFilter('client_name', newFilterValues),
        this.addFilter('client', clientIds),
      ) as Filter<unknown>[];
    }, []) as Filter<unknown>[];
  }

  private static addFilter(key: string, values: string[]): Filter<unknown>[] {
    const filters = [];
    if (values.length === 1) {
      filters.push(new MonoValueFilter(key, values[0]));
    } else if (values.length > 1) {
      filters.push(new OrFilter(key, values));
    }
    return filters;
  }
}
