import {
  ChangeDetectionStrategy,
  Component,
  Input,
  ViewEncapsulation,
} from '@angular/core';
import { Mapper } from '@manakincubber/tiime-utils';

type DateType = string | number | Date;

@Component({
  selector: 'tiime-date-list-cell',
  templateUrl: './date-list-cell.component.html',
  styleUrls: ['./date-list-cell.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class DateListCellComponent {
  @Input() date: DateType;

  readonly mapToBelongsToCurrentYear: Mapper<DateType, boolean> = (
    date: DateType,
  ) => {
    const dateToCompare = date instanceof Date ? date : new Date(date);
    return dateToCompare.getFullYear() === new Date().getFullYear();
  };
  readonly mapToIsDateValid: Mapper<DateType, boolean> = (date: DateType) => {
    const dateToVerify = date instanceof Date ? date : new Date(date);
    return dateToVerify instanceof Date && !isNaN(dateToVerify.getTime());
  };
}
