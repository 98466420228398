import { Pipe, PipeTransform } from '@angular/core';

import { Metadata } from '@models/documents';

import { getDateFromDocumentMetadata } from '../utils/functions';

@Pipe({
  name: 'documentDate',
})
export class DocumentDatePipe implements PipeTransform {
  transform(metadata: Metadata[]): string {
    return getDateFromDocumentMetadata(metadata);
  }
}
