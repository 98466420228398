import { SelectionModel } from '@angular/cdk/collections';
import {
  ChangeDetectorRef,
  Directive,
  Optional,
  ViewChild,
} from '@angular/core';
import { MatSort, Sort } from '@angular/material/sort';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { OperatorFunction, Subject, Subscription } from 'rxjs';
import { filter, switchMap, tap } from 'rxjs/operators';

import {
  DefaultGetOptions,
  Filter,
  MonoValueFilter,
  RequiredGetOptions,
  ResetFilter,
} from '../get-options';
import {
  PaginationData,
  PaginationRange,
  PaginatorComponent,
} from '../paginator';
import { SearchBarComponent } from '../searchbar';
import { SortHelper } from '../sort';
import { AbstractTableDirective } from './abstract-table-directive';
import { TableAnchorComponent } from './table-anchor.component';
import { StickyTableDirective } from './table-sticky.directive';

@UntilDestroy()
@Directive()
// eslint-disable-next-line @angular-eslint/directive-class-suffix
export abstract class AdvancedTableBase<T> extends AbstractTableDirective<T> {
  @ViewChild(MatSort)
  set setSort(sort: MatSort) {
    if (sort) {
      const isInit = !this.sort;
      this.sort = sort;
      if (isInit && this.route) {
        this.sort.start = 'desc';
        this.setSortFromDefaultSearchQueryParams();
      }
      this.observeSort();
    }
  }
  @ViewChild(PaginatorComponent)
  set setPaginator(paginator: PaginatorComponent) {
    if (paginator) {
      this.paginator = paginator;
      this.observePaginator();
    }
  }
  @ViewChild(SearchBarComponent)
  set setSearchBar(searchbar: SearchBarComponent) {
    if (searchbar) {
      const isInit = !this.searchbar;
      this.searchbar = searchbar;
      if (isInit && this.route && this.shouldInitSearchbarFromQueryParams) {
        this.setSearchFromDefaultSearchQueryParams();
      }
      this.observeSearch();
    }
  }
  @ViewChild(StickyTableDirective)
  set setStickyTable(stickyTable: StickyTableDirective) {
    if (stickyTable) {
      stickyTable.updateObserversAndSentinels();
    }
  }
  @ViewChild(TableAnchorComponent)
  tableAnchorComponent?: TableAnchorComponent;

  getOptions: RequiredGetOptions<'range'> = {
    range: this.savedPaginationRange,
  };

  protected filters: { [key: string]: string[] };
  protected onPaginateInTableSub: Subscription;
  protected onSearchInTableSub: Subscription;
  protected onSortInTableSub: Subscription;
  protected paginator: PaginatorComponent;
  protected searchbar: SearchBarComponent;
  protected sort: MatSort;
  protected selectionModel: SelectionModel<T>;
  protected readonly commonQuickSelection = [
    { label: 'Tous', action: (): void => this.selectAll() },
    { label: 'Aucun', action: (): void => this.unselectAll() },
  ];
  protected readonly defaultSort?: Sort;
  protected readonly reloadSubject$ = new Subject<void>();

  /**
   * Sometimes we don't want to initialize the searchbar when a table is instantiated.
   * (eg: when we have 2 searchbars in the same page, we don't want the second one to
   * be initialized with the search from the first one).
   */
  protected shouldInitSearchbarFromQueryParams = true;

  private paginatorSub: Subscription;
  private searchBarSub: Subscription;
  private sortSub: Subscription;

  protected get rangeValue(): PaginationRange | null {
    return this.paginator ? this.paginator.range : null;
  }
  protected get searchValue(): string | null {
    return this.searchbar
      ? (this.searchbar.searchbarForm.search.value as string | null)
      : null;
  }

  protected get sortValue(): Sort | null {
    return this.sort?.active
      ? {
          active: this.sort.active,
          direction: this.sort.direction,
        }
      : null;
  }

  get areAllSelected(): boolean {
    return (
      this.paginationData.data.length === this.selectionModel?.selected.length
    );
  }

  constructor(
    @Optional() protected readonly router: Router | null,
    @Optional() protected readonly route: ActivatedRoute | null,
    protected readonly cdr: ChangeDetectorRef,
  ) {
    super();
  }

  /**
   * This function enables to automatize API calls when it is called after the
   * table's data subscription has been initialized once.
   *
   * It also prevents from having to create differents Subscription each time data
   * should be fetched.
   */
  protected initReloadSubject(): OperatorFunction<unknown, PaginationData<T>> {
    return switchMap(() => {
      return this.reloadSubject$.pipe(
        tap(() => {
          // getOptions must be updated in order to pure pipes to detects changes
          this.getOptions = {
            ...this.getOptions,
            sort: this.sortValue || null,
          };
        }),
        switchMap(() => this.getTableData()),
      );
    });
  }

  protected pageQueryParamsToRange(pageQueryParams: string): PaginationRange {
    return pageQueryParams
      ? PaginationRange.fromQueryParams(
          pageQueryParams,
          this.paginationData.paginationRange.pageSize,
        )
      : this.paginationData.paginationRange;
  }

  protected saveQueryParams(
    range: PaginationRange,
    search: string | null = null,
    sort: Sort | null = null,
    filters: { [key: string]: string[] | string } | null = null,
  ): void {
    if (!this.router) {
      throw Error('Router dependency is missing');
    }

    const queryParams: Params = {
      page: range && PaginationRange.toQueryParams(range),
      search: search || null,
      sort: sort && SortHelper.toStringParam(sort),
      ...filters,
    };

    void this.router.navigate([], {
      queryParams,
      relativeTo: this.route,
      replaceUrl: true,
      queryParamsHandling: 'merge',
    });
  }

  protected searchQueryParamsToSearch(
    searchQueryParams: string,
  ): string | null {
    return searchQueryParams || null;
  }

  protected sortQueryParamsToSort(sortQueryParams: string): Sort | null {
    return sortQueryParams
      ? SortHelper.fromQueryParamsToSort(sortQueryParams)
      : null;
  }

  protected paginateInTable(range: PaginationRange): void {
    this.getOptions.range = range;
    this.reloadSubject$.next();
  }

  protected searchInTable(search: string | undefined): void {
    this.getOptions.range = this.savedPaginationRange;
    if (search && !isNaN(Number(search.replace(',', '.')))) {
      this.getOptions.search = search.replace(',', '.');
    } else {
      this.getOptions.search = search;
    }
    this.reloadSubject$.next();
  }

  protected sortInTable(sort: Sort): void {
    this.getOptions.range = this.savedPaginationRange;
    this.getOptions.sort = sort;
    this.reloadSubject$.next();
  }

  protected resetGetOptions(): void {
    this.paginationData = new PaginationData(
      [] as T[],
      this.savedPaginationRange,
    );
    this.getOptions = {
      range: this.savedPaginationRange,
    };
  }

  protected handleFilterChange(
    tableFilter: Filter<unknown> | Filter<unknown>[],
  ): void {
    this.getOptions.range = this.savedPaginationRange;
    if (Array.isArray(tableFilter)) {
      tableFilter.forEach(filter => {
        this.updateGetOptionsFilters(filter);
      });
    } else {
      this.updateGetOptionsFilters(tableFilter);
    }

    this.reloadSubject$.next();
  }

  protected handleSortAndFilterChange({
    sort,
    filter,
  }: {
    sort: Sort | null;
    filter: Filter<unknown> | Filter<unknown>[];
  }): void {
    if (sort) {
      this.sort.active = sort.active;
      this.sort.direction = sort.direction;
    } else if (this.defaultSort && !this.sort.active) {
      this.sort.active = this.defaultSort.active;
      this.sort.direction = this.defaultSort.direction;
    }
    this.getOptions.sort = this.sort;

    this.handleFilterChange(filter);
  }

  private updateGetOptionsFilters(filter: Filter<unknown>): void {
    this.getOptions.filters = this.getOptions.filters?.filter(
      ({ key }) => key !== filter.key,
    );
    if (!(filter instanceof ResetFilter)) {
      this.getOptions.filters = [...(this.getOptions.filters ?? []), filter];
    }
  }

  private observePaginator(): void {
    if (this.paginatorSub) {
      this.paginatorSub.unsubscribe();
    }
    this.paginatorSub = this.paginator.rangeEvent
      .pipe(
        tap((range: PaginationRange) =>
          this.paginatorService.savePreferences(
            range.pageSize,
            this.constructor.name,
          ),
        ),
        tap((range: PaginationRange) => this.paginateInTable(range)),
        tap(() => this.tableAnchorComponent?.scrollTo()),
        untilDestroyed(this),
      )
      .subscribe();
  }

  private observeSearch(): void {
    if (this.searchBarSub) {
      this.searchBarSub.unsubscribe();
    }
    this.searchBarSub = this.searchbar.search
      .pipe(
        filter(() => !this.searchbar.enterToSearch),
        tap((searchTerms: string) => this.searchInTable(searchTerms)),
        untilDestroyed(this),
      )
      .subscribe();
  }

  private observeSort(): void {
    if (this.sortSub) {
      this.sortSub.unsubscribe();
    }
    this.sortSub = this.sort.sortChange
      .pipe(
        tap((sort: Sort) => this.sortInTable(sort)),
        untilDestroyed(this),
      )
      .subscribe();
  }

  private setSearchFromDefaultSearchQueryParams(): void {
    if (!this.route) {
      throw Error('ActivatedRoute dependency is missing');
    }

    const { search } = this.route.snapshot.queryParams;
    if (this.searchbar && search && search !== this.searchValue) {
      this.searchbar.setSearch(search);
      this.cdr.markForCheck();
    }
  }

  private setSortFromDefaultSearchQueryParams(): void {
    if (!this.route) {
      throw Error('ActivatedRoute dependency is missing');
    }

    const { sort } = this.route.snapshot.queryParams;
    if (this.sort && sort) {
      this.sort.sort(SortHelper.fromQueryParamsToMatSortable(sort));
      this.cdr.markForCheck();
    }
  }

  selectAll(): void {
    this.selectionModel.select(...this.paginationData.data);
  }

  unselectAll(): void {
    this.selectionModel.clear();
  }

  onSelectAllChange(): void {
    if (!this.selectionModel) {
      return;
    }
    if (this.areAllSelected) {
      this.selectionModel.clear();
    } else {
      this.selectAll();
    }
  }
}

/**
 * @param params Les queryParams à extraire
 * @param filterKeys Les clés correspondantes aux queryParams à parser
 * @param booleanFilterKeys Les clés correspondantes aux queryParams à parser en
 * type booléen. Ce paramètre est temporaire et permet de connaitre
 * les `params` qui doivent être convertis en valeur de type booléen au lieu
 * de les gérer en type string.
 * @see https://manakin.atlassian.net/browse/TA-29959
 */
export function extractFiltersFromQueryParams(
  params: Params,
  filterKeys: ReadonlyArray<string>,
  booleanFilterKeys: ReadonlyArray<string> = [],
): DefaultGetOptions['filters'] {
  const filteredParams = Object.keys(params)
    .filter((key: string) => filterKeys.includes(key))
    .map((key: string) => new MonoValueFilter(key, params[key]));

  const booleanFilteredParams = Object.keys(params)
    .filter((key: string) => booleanFilterKeys.includes(key))
    .map((key: string) => new MonoValueFilter(key, params[key] === 'true'));

  return [...filteredParams, ...booleanFilteredParams];
}

export function getFiltersQueryParams(
  filters: DefaultGetOptions['filters'],
  filterKeys: ReadonlyArray<string>,
): Record<string, string> | null {
  if (!filters) {
    return null;
  }

  const noFilterObject = filterKeys.reduce(
    (accumulator, key) => ({
      ...accumulator,
      [key]: undefined,
    }),
    {},
  );

  return filters.reduce(
    (filterObject, currentFilter) => ({
      ...filterObject,
      ...currentFilter.toParamObject(),
    }),
    noFilterObject,
  );
}
