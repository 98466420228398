import {
  Component,
  OnInit,
  ChangeDetectorRef,
  ViewEncapsulation,
} from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { TiimeAuthService } from '@manakincubber/tiime-auth';
import { MarkFormAsTouchedIfInvalid } from '@manakincubber/tiime-utils';
import { tap } from 'rxjs/operators';

import { AuthBase } from '../auth-shared/bases/auth.base';
import { ChangePasswordForm } from './change-password-form';

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['../auth-base.scss', './change-password.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ChangePasswordComponent extends AuthBase implements OnInit {
  changePasswordForm: ChangePasswordForm;
  isMailSent = false;

  constructor(
    private cdr: ChangeDetectorRef,
    private route: ActivatedRoute,
    protected authService: TiimeAuthService,
    protected router: Router,
  ) {
    super(authService, router);
  }

  ngOnInit(): void {
    this.initChangePasswordForm();
  }

  @MarkFormAsTouchedIfInvalid('changePasswordForm')
  submitChangePasswordForm(): void {
    this.changePassword();
  }

  navigateToSignin(): void {
    this.navigateWithEmailAsQueryParam(
      '/signin',
      this.changePasswordForm.email.value,
    );
  }

  private initChangePasswordForm(): void {
    this.changePasswordForm = new ChangePasswordForm(
      this.route.snapshot.queryParamMap.get('email'),
    );
  }

  private changePassword(): void {
    this.authService
      .changePassword(this.changePasswordForm.email.value)
      .pipe(tap(() => this.changePasswordSuccess()))
      .subscribe();
  }

  private changePasswordSuccess(): void {
    this.isMailSent = true;
    this.cdr.markForCheck();
  }
}
