export interface BIAccountApiContract {
  id: number;
  deleted: string;
  last_update: Date | null;
  balance: number;
  iban: string | null;
  bic: string | null;
  name: string;
  type: string;
  usage: 'PRIV' | 'ORGA';
  disabled: Date | null | boolean;
  currency: { id: string };
}

export class BIAccount {
  constructor(
    public id?: number,
    public deleted?: string,
    public lastUpdate?: Date,
    public balance?: number,
    public iban?: string,
    public bic?: string,
    public name?: string,
    public type?: string,
    public usage?: 'PRIV' | 'ORGA',
    public disabled?: Date | null | boolean,
    public currency?: { id: string },
  ) {}

  public static fromJson(json: BIAccountApiContract): BIAccount {
    return new BIAccount(
      json.id,
      json.deleted,
      json.last_update,
      json.balance,
      json.iban,
      json.bic,
      json.name,
      json.type,
      json.usage,
      json.disabled,
      json.currency,
    );
  }

  public static toJson(biAccount: BIAccount): BIAccountApiContract {
    return {
      id: biAccount.id,
      deleted: biAccount.deleted,
      last_update: biAccount.lastUpdate,
      balance: biAccount.balance,
      iban: biAccount.iban,
      bic: biAccount.bic,
      name: biAccount.name,
      type: biAccount.type,
      usage: biAccount.usage,
      disabled: biAccount.disabled,
      currency: biAccount.currency,
    };
  }
}
