import { Pipe, PipeTransform } from '@angular/core';

import {
  LinkedEntity,
  LinkedEntityBankTransaction,
  LinkedEntityExpenseReport,
  LinkedEntityImputation,
} from '@models/linked-entities';

@Pipe({
  standalone: true,
  name: 'linkedEntityAmountAndCurrency',
})
export class LinkedEntityAmountAndCurrencyPipe implements PipeTransform {
  transform(linkedEntity: LinkedEntity): { amount: number; currency: string } {
    if (
      linkedEntity instanceof LinkedEntityBankTransaction ||
      linkedEntity instanceof LinkedEntityExpenseReport
    ) {
      return {
        amount: linkedEntity.value.amount.value,
        currency: linkedEntity.value.amount.currency,
      };
    } else if (linkedEntity instanceof LinkedEntityImputation) {
      return {
        amount: linkedEntity.value.amount,
        currency: linkedEntity.value.currency,
      };
    }

    return {
      amount: 0,
      currency: undefined,
    };
  }
}
