<tiime-dialog-close-button></tiime-dialog-close-button>
<div class="dialog-image">
  <img src="assets/illu_tags.svg" draggable="false" alt=""/>
</div>
<span class="title" mat-dialog-title [ngPlural]="data?.tags?.length">
  <ng-template ngPluralCase="=0">Ajouter un tag</ng-template>
  <ng-template ngPluralCase="other">{{ data.title }}</ng-template>
</span>
<mat-dialog-content>
  <form (ngSubmit)="submitForm()">
    <div class="form-content">
      <div class="form-column">
        <div class="form-row tag-row">
          <div class="form-row tag-row">
            <app-tag-selector-form-input
              [formControl]="tagForm"
              [floatingPlaceholder]="true"
              [tagSuggestions]="tagSuggestions$ | async"
              [value]="data?.tags"
              [showAutomaticTags]="data?.showAutomaticTags"
              [autofocus]="true"
            ></app-tag-selector-form-input>
          </div>
        </div>
      </div>
    </div>
  </form>
</mat-dialog-content>
<mat-dialog-actions class="dialog-actions">
  <button class="validate" tiime-button accent (click)="submitForm()">
    Valider
  </button>
  <button
    class="cancel"
    type="button"
    tiime-button
    neutral
    inverted
    mat-dialog-close
  >
    Annuler
  </button>
</mat-dialog-actions>
