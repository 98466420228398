import {
  ChangeDetectionStrategy,
  Component,
  Input,
  ViewChild,
} from '@angular/core';
import { MatMenu, MatMenuTrigger } from '@angular/material/menu';
import { UntilDestroy } from '@ngneat/until-destroy';
import { OrFilter, RequiredGetOptions, ResetFilter } from 'tiime-components';

import { AccountantDetailRequestFilterValue } from '@core/enum/filters';
import { TransactionsMatchedFilterForm } from '@core/forms';
import { TransactionsTodoFilterForm } from '@core/forms/transactions-todo-filter-form';
import { ToDoCounts } from '@core/models/tiles';
import { TableColumnFilterDirective } from '@shared';

import { getTodoFilters } from '../transactions-todo-filters';

export const allTransactionsKey = 'all';

@UntilDestroy()
@Component({
  selector: 'app-transactions-actions-filter',
  templateUrl: './transactions-actions-filter.component.html',
  styleUrls: [
    '../../../../../shared/components/table-column-filter/table-column-filter-base.scss',
    './transactions-actions-filter.component.scss',
    '../transactions-todo-filter.base.scss',
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TransactionsActionsFilterComponent extends TableColumnFilterDirective {
  @Input() set getOptions(value: RequiredGetOptions<'range'>) {
    this._getOptions = value;
    this.todoForm.fromParam(getTodoFilters(value));
  }

  get getOptions(): RequiredGetOptions<'range'> {
    return this._getOptions;
  }

  private _getOptions: RequiredGetOptions<'range'>;

  @Input() todoCounts: ToDoCounts;

  @ViewChild(MatMenu, { static: true }) menu: MatMenu;
  readonly filterKey = 'matched';
  readonly todoFilterKey = 'accountant_detail_request';
  readonly allTransactionsKey = allTransactionsKey;
  readonly AccountantDetailRequestFilterValue =
    AccountantDetailRequestFilterValue;

  todoForm = new TransactionsTodoFilterForm();

  constructor(protected readonly menuTrigger: MatMenuTrigger) {
    super(menuTrigger);
  }

  protected initForm(): void {
    this.form = new TransactionsMatchedFilterForm(this.filterKey);
    this.form.fromParam(this.filterValue);
  }

  applyWithTodoFilters(): void {
    this.valueChange.emit(this.form.toOutput());
    this.valueChange.emit(this.todoForm.toOutput());
    this.form.markAsPristine();
    this.menuTrigger.closeMenu();
  }

  reset(): void {
    this.valueChange.emit(ResetFilter.forKey(this.filterKey));
    const otherTodoFilters = getTodoFilters(this.getOptions).filter(
      filterKey =>
        filterKey !== AccountantDetailRequestFilterValue.Message &&
        filterKey !== AccountantDetailRequestFilterValue.Receipt,
    );
    if (otherTodoFilters.length > 0) {
      this.valueChange.emit(
        new OrFilter(this.todoFilterKey, [...otherTodoFilters]),
      );
    } else {
      this.valueChange.emit(ResetFilter.forKey(this.todoFilterKey));
    }
    this.menuTrigger.closeMenu();
  }
}
