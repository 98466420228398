import { CommonModule } from '@angular/common';
import { Component, EventEmitter, inject, Input, Output } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Store } from '@ngrx/store';
import { map } from 'rxjs';
import { tap } from 'rxjs/operators';
import { TiimeLetModule } from 'tiime-components';

import { DocumentsService, FileService } from '@core';
import { HasAccessDirective } from '@core/directives/acl/has-access.directive';
import { userSelector } from '@core/store';
import { UserPermissionNameEnum } from '@enums/users';
import { Document, InvoiceListItem } from '@models';
import { IconsModule } from '@shared';

import { InvoiceService } from '../../../../../../invoice/invoices/invoices-core/services/invoice.service';
import { OperationTypeLogoModule } from '../../../../operation-type-logo/operation-type-logo.module';
import { MatchedItem } from '../../../types/matched-item';
import { MatchedItemAmountPipe } from './pipes/matched-item-amount.pipe';
import { MatchedItemDatePipe } from './pipes/matched-item-date.pipe';
import { MatchedItemHasPreviewPipe } from './pipes/matched-item-has-preview.pipe';
import { MatchedItemMainTextPipe } from './pipes/matched-item-main-text.pipe';
import { MatchedItemSecondaryTextPipe } from './pipes/matched-item-secondary-text.pipe';
import { ToOperationTypePipe } from './pipes/to-operation-type.pipe';

@UntilDestroy()
@Component({
  standalone: true,
  imports: [
    CommonModule,
    MatchedItemDatePipe,
    MatchedItemMainTextPipe,
    MatchedItemAmountPipe,
    MatchedItemSecondaryTextPipe,
    MatchedItemHasPreviewPipe,
    IconsModule,
    OperationTypeLogoModule,
    ToOperationTypePipe,
    HasAccessDirective,
    TiimeLetModule,
  ],
  selector: 'app-matched-item',
  templateUrl: './matched-item.component.html',
  styleUrls: ['./matched-item.component.scss'],
})
export class MatchedItemComponent {
  @Input() item: MatchedItem;

  @Output() readonly remove = new EventEmitter<MatchedItem>();
  readonly UserPermissionNameEnum = UserPermissionNameEnum;

  readonly userId$ = inject(Store)
    .select(userSelector)
    .pipe(map(user => user.id));

  constructor(
    private readonly fileService: FileService,
    private readonly documentsService: DocumentsService,
    private readonly invoiceService: InvoiceService,
  ) {}

  openPreviewInNewTab(): void {
    let service: InvoiceService | DocumentsService = null;
    let id: number = null;
    if (this.item instanceof Document) {
      service = this.documentsService;
      id = this.item.id;
    } else if (this.item instanceof InvoiceListItem) {
      service = this.invoiceService;
      id = this.item.id;
    }

    if (service && id) {
      service
        .getPreview(id)
        .pipe(
          tap((file: Blob) => this.fileService.openInNewTab(file)),
          untilDestroyed(this),
        )
        .subscribe();
    }
  }
}
