import { TrackingEvent } from '@manakincubber/tiime-tracking';

import { StandardDocumentCategoryIdentifier } from '@enums';

export const DOCUMENT_UPLOADED = 'Document Uploaded';

export class DocumentUploaded implements TrackingEvent {
  readonly name = DOCUMENT_UPLOADED;

  constructor(
    readonly documentCategoryIdentifier: StandardDocumentCategoryIdentifier,
  ) {}
}
