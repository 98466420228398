import { LinkedEntity, LinkedEntityType } from './linked-entity';
import { LinkedEntityAmount, LinkedEntityBase } from './linked-entity-base';

export interface LinkedEntityExpenseReportValue
  extends LinkedEntityBase,
    LinkedEntityAmount {
  label: string;
}
/**
 * LinkedEntities of a document which type is `advanced_expense`
 */
export class LinkedEntityExpenseReport extends LinkedEntity<LinkedEntityExpenseReportValue> {
  type = LinkedEntityType.EXPENSE_REPORT;
  constructor(value: LinkedEntityExpenseReportValue) {
    super(value);
  }
}
