import {
  MAX_INVOICING_LOGO_HEIGHT,
  MAX_INVOICING_LOGO_WIDTH,
  MIN_INVOICING_LOGO_HEIGHT,
  MIN_INVOICING_LOGO_WIDTH,
} from '@core/constants/invoice';
import { clamp } from '@core/utils';
import {
  DueDateMode,
  InvoiceStatus,
  InvoiceStatusUpdatedBy,
  InvoiceType,
  InvoicingTemplate,
} from '@enums';
import {
  InvoicingDocumentSeller,
  LineApiContract,
  LinkedBankTransaction,
  LinkedBankTransactionApiContract,
  Tag,
  TagApiContract,
  TextLine,
  TextLineApiContract,
} from '@models';

import { AbstractInvoicingDocument } from '../bases/abstract-invoicing-document';
import { Client } from './client';
import { Country } from './country';
import { DeliveryAddress } from './delivery-address';
import { Discount } from './discount';
import { InvoiceConfig } from './invoice-config';
import { InvoiceSchedule } from './invoice-schedule';
import { Line } from './line';
import {
  linkedEntityFromJson,
  LinkedEntityImputation,
} from './linked-entities';
import { LinkedEntityApiContract } from './linked-entities/api-contracts';
import { Logo } from './logo';
import { PaymentLink } from './payment-link';
import { Quote } from './quote';
import { TotalsPerVatType } from './totals-per-vat-type';

export class Invoice extends AbstractInvoicingDocument {
  constructor(
    id?: number,
    logo?: Logo,
    seller?: InvoicingDocumentSeller,
    title?: string,
    invoiceNumber?: number,
    emissionDate?: string,
    client?: Client,
    totalExcludingTaxes?: number,
    totalIncludingTaxes?: number,
    lines?: Line[],
    textLines?: TextLine[],
    color?: string,
    header?: string,
    footer?: string,
    freeField?: string,
    template?: InvoicingTemplate,
    comment?: string,
    tags?: Tag[],
    unitsEnabled?: boolean,
    totalsPerVatType?: TotalsPerVatType,
    pdfFilename?: string,
    deliveryAddress?: DeliveryAddress,
    discount?: Discount,
    nonApplicableVatReason?: string,
    nonApplicableVatReasonEnabled?: boolean,
    public status?: InvoiceStatus,
    public statusUpdatedBy?: InvoiceStatusUpdatedBy,
    public compiledNumber?: string,
    public iban?: string,
    public bic?: string,
    public paymentCondition?: string,
    public type?: InvoiceType,
    public bankTransactions?: LinkedBankTransaction[],
    public numberTemplate?: string,
    public dueDateMode?: DueDateMode,
    public dueDate?: string,
    public creditNoteLinkedCompiledNumber?: string,
    public invoiceLinkedCompiledNumber?: string,
    public invoiceSchedule?: InvoiceSchedule | null,
    public config?: InvoiceConfig,
    public advancePaymentInvoicesIds?: number[],
    public quotation?: Quote,
    public paymentLink?: PaymentLink,
    public imputations?: LinkedEntityImputation[],
  ) {
    super(
      id,
      title,
      invoiceNumber,
      emissionDate,
      totalExcludingTaxes,
      totalIncludingTaxes,
      lines,
      textLines,
      color,
      template,
      comment,
      tags,
      unitsEnabled,
      logo,
      seller,
      client,
      header,
      footer,
      freeField,
      totalsPerVatType,
      pdfFilename,
      deliveryAddress,
      discount,
      nonApplicableVatReason,
      nonApplicableVatReasonEnabled,
    );
  }

  public static fromJson(json: any): Invoice {
    return new Invoice(
      json.id,
      json.logo
        ? Logo.fromJson({
            ...json.logo,
            width: json.logo_width,
            height: json.logo_height,
          })
        : null,
      json.seller ? InvoicingDocumentSeller.fromJson(json.seller) : null,
      json.title,
      json.number,
      json.emission_date,
      Client.fromJson({
        id: json.client ? json.client.id : null,
        name: json.client_name,
        address: json.client_address,
        address_complement: json.client_address_complement,
        city: json.client_city,
        country: json.client_country,
        postal_code: json.client_postal_code,
        intracom_vat_number: json.client_intracom_vat_number,
        siren_or_siret: json.client_siren_or_siret,
      }),
      json.total_excluding_taxes,
      json.total_including_taxes,
      json.lines
        ? json.lines.map((lineJson: LineApiContract) => Line.fromJson(lineJson))
        : [],
      json.text_lines
        ? json.text_lines.map((textlineJson: TextLineApiContract) =>
            TextLine.fromJson(textlineJson),
          )
        : [],
      json.color,
      json.header,
      json.footer,
      json.free_field,
      json.template,
      json.comment,
      json.tags
        ? json.tags.map((tagJson: TagApiContract) => Tag.fromJson(tagJson))
        : [],
      json.units_enabled,
      json.totals_per_vat_type
        ? TotalsPerVatType.fromJson(json.totals_per_vat_type)
        : null,
      json.pdf_filename,
      new DeliveryAddress(
        json.client_delivery_address,
        json.client_delivery_postal_code,
        json.client_delivery_city,
        json.client_delivery_country
          ? Country.fromJson(json.client_delivery_country)
          : null,
      ),
      new Discount(
        json.discount_description,
        json.discount_percentage,
        json.discount_amount,
      ),
      json.non_applicable_vat_reason,
      json.non_applicable_vat_reason_enabled,
      json.status,
      json.status_updated_by,
      json.compiled_number,
      json.iban,
      json.bic,
      json.payment_condition,
      json.total_including_taxes < 0 ? InvoiceType.creditNote : json.type,
      json.bank_transactions
        ? json.bank_transactions.map(
            (bankTransactionJson: LinkedBankTransactionApiContract) =>
              LinkedBankTransaction.fromJson(bankTransactionJson),
          )
        : [],
      json.number_template,
      json.due_date_mode,
      json.due_date,
      json.credit_note_linked_compiled_number,
      json.invoice_linked_compiled_number,
      json.invoice_schedule && InvoiceSchedule.fromJson(json.invoice_schedule),
      new InvoiceConfig(
        json.client_delivery_enabled,
        json.client_siren_or_siret_enabled,
        json.client_intracom_vat_number_enabled,
        json.free_field_enabled,
        json.country && Country.fromJson(json.country),
        json.discount_enabled,
        json.template,
        json.title_enabled,
        json.bank_detail_enabled,
        json.payment_condition_enabled,
      ),
      json.advance_payment_invoices_ids,
      json.quotation && Quote.fromJson(json.quotation),
      json.payment_link && PaymentLink.fromJson(json.payment_link),
      json.imputations
        ? json.imputations.map((entity: LinkedEntityApiContract) =>
            linkedEntityFromJson(entity),
          )
        : [],
    );
  }

  public static toJson(invoice: Invoice): Record<string, unknown> {
    return {
      id: invoice.id || undefined,
      logo: invoice.logo?.id && {
        id: invoice.logo.id,
      },
      logo_width:
        invoice.logo?.width &&
        clamp(
          Math.round(invoice.logo.width),
          MIN_INVOICING_LOGO_WIDTH,
          MAX_INVOICING_LOGO_WIDTH,
        ),
      logo_height:
        invoice.logo?.height &&
        clamp(
          Math.round(invoice.logo.height),
          MIN_INVOICING_LOGO_HEIGHT,
          MAX_INVOICING_LOGO_HEIGHT,
        ),
      status: invoice.status,
      ...(invoice.totalIncludingTaxes > 0 && { type: invoice.type }),
      status_updated_by: invoice.statusUpdatedBy,
      seller: invoice.seller?.toJson(),
      title: invoice.title,
      number: invoice.invoicingDocumentNumber,
      emission_date: invoice.emissionDate,
      client: invoice.client?.id && { id: invoice.client.id },
      client_name: invoice.client?.name,
      client_address: invoice.client?.address,
      client_address_complement: invoice.client?.addressComplement,
      client_city: invoice.client?.city,
      client_country: invoice.client?.country?.id && invoice.client.country,
      client_postal_code: invoice.client?.postalCode,
      client_intracom_vat_number: invoice.client?.intracomVatNumber,
      client_siren_or_siret: invoice.client && invoice.client.sirenOrSiret,
      total_excluding_taxes: invoice.totalExcludingTaxes,
      total_including_taxes: invoice.totalIncludingTaxes,
      lines: invoice.lines?.map((line: Line) => Line.toJson(line)),
      text_lines: invoice.textLines?.map((textLine: TextLine) =>
        TextLine.toJson(textLine),
      ),
      iban: invoice.iban,
      bic: invoice.bic,
      bank_detail_enabled: invoice.config?.bankDetailEnabled,
      color: invoice.color,
      footer: invoice.footer,
      header: invoice.header,
      payment_condition: invoice.paymentCondition,
      payment_condition_enabled: invoice.config?.paymentConditionEnabled,
      free_field: invoice.freeField,
      free_field_enabled: invoice.config?.freeFieldEnabled,
      non_applicable_vat_reason: invoice.nonApplicableVatReason,
      non_applicable_vat_reason_enabled: invoice.nonApplicableVatReasonEnabled,
      template: invoice.template,
      comment: invoice.comment,
      tags: invoice.tags?.map((tags: Tag) => Tag.toJson(tags)),
      totals_per_vat_type:
        invoice.totalsPerVatType &&
        TotalsPerVatType.toJson(invoice.totalsPerVatType),
      number_template: invoice.numberTemplate,
      due_date: invoice.dueDate || undefined,
      due_date_mode: invoice.dueDateMode,
      invoice_schedule:
        invoice.invoiceSchedule &&
        InvoiceSchedule.toJson(invoice.invoiceSchedule),
      client_delivery_enabled: invoice.config?.deliveryAddressEnabled,
      client_delivery_address: invoice.deliveryAddress?.address,
      client_delivery_postal_code: invoice.deliveryAddress?.postalCode,
      client_delivery_city: invoice.deliveryAddress?.city,
      client_delivery_country:
        invoice.deliveryAddress?.country.id && invoice.deliveryAddress.country,
      client_siren_or_siret_enabled: invoice.config?.sirenOrSiretEnabled,
      client_intracom_vat_number_enabled:
        invoice.config?.intracomVatNumberEnabled,
      country: invoice.config?.country && { id: invoice.config?.country?.id },
      discount_enabled: invoice.config?.discountEnabled,
      title_enabled: invoice.config?.titleEnabled,
      discount_description: invoice.discount?.description,
      discount_amount: invoice.discount?.amount,
      discount_percentage: invoice.discount?.percentage,
      advance_payment_invoices_ids: invoice.advancePaymentInvoicesIds,
    };
  }
}
