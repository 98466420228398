import { ClientPaymentStatus } from '@enums';

import { ExportConfigurationBase } from '../../settings/export/export-core/bases/export-configuration.base';

export interface ClientsExportApiContract {
  status: ClientPaymentStatus[] | null;
}

export class ClientsExportConfiguration extends ExportConfigurationBase {
  constructor(public status: ClientPaymentStatus[] | null) {
    super();
  }

  static toJson(configuration: ClientsExportConfiguration): {
    clients: ClientsExportApiContract;
  } {
    return {
      clients: {
        status: configuration.status,
      },
    };
  }
}
