import { HttpClient, HttpStatusCode } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { ApiAlertError } from '@decorators/api-alert-error';

export type AddPromotionCodeContract = {
  code: string;
};

export interface PromotionCodeApiContract {
  next_invoice_date: Date;
}

@Injectable({
  providedIn: 'root',
})
export class MarketingActionsService {
  private readonly resource = 'api/v1/wallet/companies/{companyId}';

  constructor(private readonly http: HttpClient) {}

  @ApiAlertError([
    HttpStatusCode.Forbidden,
    HttpStatusCode.NotFound,
    HttpStatusCode.BadRequest,
  ])
  addPromotionCode(
    promotionCodeContract: AddPromotionCodeContract,
  ): Observable<PromotionCodeApiContract> {
    return this.http.post<PromotionCodeApiContract>(
      `${this.resource}/promo`,
      promotionCodeContract,
    );
  }
}
