import { CompanyRoleEnum, InvitationStatusEnum } from '@enums/users';

import {
  PermissionAttribute,
  PermissionAttributeApiContract,
} from './permission-attribute';

export interface UserWithPermissionsApiContract {
  id: number;
  firstname: string;
  lastname: string;
  email: string;
  company_role: CompanyRoleEnum;
  invitation_status: InvitationStatusEnum;
  invitation_date: string;
  can_access_company: boolean;
  permission_attributes: PermissionAttributeApiContract[];
}

export type UnsavedUserWithPermissions = Omit<UserWithPermissions, 'id'>;

export class UserWithPermissions {
  constructor(
    public id: number,
    public firstName: string,
    public lastName: string,
    public email: string,
    public companyRole: CompanyRoleEnum,
    public permissionAttributes: PermissionAttribute[],
    public canAccessCompany: boolean,
    public invitationStatus?: InvitationStatusEnum,
    public invitationDate?: string,
  ) {}

  static fromJson(json: UserWithPermissionsApiContract): UserWithPermissions {
    return new UserWithPermissions(
      json.id,
      json.firstname,
      json.lastname,
      json.email,
      json.company_role,
      json.permission_attributes.map(attr =>
        PermissionAttribute.fromJson(attr),
      ),
      json.can_access_company,
      json.invitation_status,
      json.invitation_date,
    );
  }

  static toJson(user: UserWithPermissions): UserWithPermissionsApiContract {
    return {
      id: user.id,
      firstname: user.firstName,
      lastname: user.lastName,
      email: user.email,
      company_role: user.companyRole,
      permission_attributes: user.permissionAttributes.map(attr =>
        PermissionAttribute.toJson(attr),
      ),
      invitation_status: user.invitationStatus,
      invitation_date: user.invitationDate,
      can_access_company: user.canAccessCompany,
    };
  }
}
