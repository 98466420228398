<div class="parameters" [formGroup]="form">
  <div class="parameters__periodicity">
    <div>
      <div class="parameters__date">
        <label for="date" data-cy="bank-transfer-date__label">Prévu le</label>
        <app-datepicker
          id="date"
          formControlName="date"
          data-cy="bank-transfer-date__datepicker"
          [min]="minDate"
        ></app-datepicker>
      </div>

      <tiime-input-container
        class="parameters__frequency"
        label="Fréquence"
        data-cy="bank-transfer__frequency-input"
      >
        <select
          id="frequency"
          tiimeInputContainerNativeControl
          formControlName="frequency"
          data-cy="bank-transfer__select"
          [class.selected]="!!form.frequency.value"
        >
          <option
            *ngFor="
              let frequencyOption of frequencyOptions;
              trackBy: trackByOptionValue
            "
            attr.data-cy="bank-transfer__select-option-{{
              frequencyOption.value
            }}"
            [value]="frequencyOption.value"
          >
            {{ frequencyOption.label }}
          </option>
        </select>
      </tiime-input-container>
    </div>

    <div
      *ngIf="form.frequency.value | map : mapToShouldDisplayFrequencyParameters"
    >
      <div class="parameters__date">
        <label for="until-date">Jusqu'au</label>
        <app-datepicker
          id="until-date"
          formControlName="untilDate"
          data-cy="bank-transfer__until-date-datepicker"
          [min]="untilDateMin$ | async"
        ></app-datepicker>
      </div>

      <div class="parameters__indefinitely">
        <mat-checkbox
          id="indefinitely"
          color="primary"
          formControlName="indefinitely"
          disableRipple
          data-cy="bank-transfer__indefinitely-checkbox"
        >
          Indéfiniment
        </mat-checkbox>
      </div>
    </div>
  </div>

  <tiime-input-container label="Libellé (facultatif)">
    <textarea
      tiimeInputContainerNativeControl
      formControlName="label"
      maxlength="140"
      data-cy="bank-transfer__input"
    ></textarea>
  </tiime-input-container>
  <div class="length-counter" data-cy="bank-transfer__input-length-counter">
    {{ form.label.value?.length || '0' }} / 140
  </div>
</div>
