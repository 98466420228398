import { LinkedEntity } from '@models/linked-entities';

import { MatchedItem } from './types/matched-item';

export const itemsAreTheSame = (
  itemId: number,
  itemToCompare: MatchedItem,
): boolean => {
  // Comparing the id of a MatchedItem...
  return (
    itemId ===
    (itemToCompare instanceof LinkedEntity
      ? itemToCompare.value.id // ... to a LinkedEntity
      : itemToCompare.id) // ... to a MatchedItem which is not a LinkedEntity
  );
};
