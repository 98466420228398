import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

import { Document } from '@models';

export enum AmountTypeEnum {
  PRE_TAX_AMOUNT,
  AMOUNT_INCLUDING_TAX,
}

@Component({
  selector: 'app-documents-amount-column-content',
  templateUrl: './documents-amount-column-content.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DocumentsAmountColumnContentComponent {
  @Input() document: Document;
  @Input() amountType: AmountTypeEnum;

  readonly AmountTypeEnum = AmountTypeEnum;
}
