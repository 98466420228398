import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatMenuModule } from '@angular/material/menu';

import { TiimeButtonModule } from '../button/index';
import { TiimeIconModule } from '../icon/index';
import { DropdownButtonComponent } from './dropdown-button.component';

@NgModule({
  imports: [CommonModule, TiimeIconModule, TiimeButtonModule, MatMenuModule],
  declarations: [DropdownButtonComponent],
  exports: [DropdownButtonComponent],
})
export class TiimeDropdownButtonModule {}
