interface StatusLabels {
  draft: string;
  saved: string;
  accepted: string;
  cancelled: string;
  refused: string;
}

interface QuoteLabels {
  status: StatusLabels;
}

export const QUOTE_LABEL: QuoteLabels = {
  status: {
    saved: 'Enregistré',
    accepted: 'Accepté',
    cancelled: 'Annulé',
    refused: 'Refusé',
    draft: 'Brouillon',
  },
};
