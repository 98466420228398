export enum DocumentComplexFilterKey {
  search = 'q',
  comment = 'comment',
  name = 'name',
  labelName = 'label_name',
  amountType = 'amount_type',
  amount = 'amount',
  amountExcludingTaxes = 'amount_excluding_taxes',
  date = 'date',
  due_date = 'due_date',
  tags = 'tag_name',
  payment_status = 'payment_status',
  document_categories_name = 'document_categories_name',
  withComment = 'has_comment',
  withLabel = 'has_label',
  withTag = 'has_tag',
  withTransactions = 'linked_entities_has_bank_transactions',
}
