<img
  alt="Category Icon"
  draggable="false"
  tiimeTooltip
  [src]="categoryIconSrc"
  [tooltipDisabled]="!withTooltip || isPending"
  [content]="categoryNameTooltip"
/>
<ng-template #categoryNameTooltip>{{ category?.name }}</ng-template>
<app-document-pending *ngIf="isPending"></app-document-pending>
