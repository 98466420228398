import { HttpClient, HttpStatusCode } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map, shareReplay, tap } from 'rxjs/operators';

import { ApiAlertError } from '@decorators/api-alert-error';
import { FileHelper } from '@helpers';
import {
  ApeCode,
  ApeCodeContract,
  Bank,
  BusinessAccessRequest,
  BusinessAccessRequestPatchUpdates,
  Country,
  ExportRequest,
  ExportRequestApiContract,
  RcsCity,
  RcsCityContract,
  ThirdParty,
  ThirdPartyApiContract,
  User,
  UserApiContract,
  VatType,
  WalletLegalInformations,
} from '@models';

@Injectable({
  providedIn: 'root',
})
export class ApiService {
  private banksCache: Observable<Bank[]>;
  private countriesCache: Observable<Country[]>;
  private legalFormsCache: Observable<string[]>;
  private apeCodesCache: Observable<ApeCode[]>;
  private rcsCitiesCache: Observable<RcsCity[]>;
  private vatTypesCache: Observable<VatType[]>;

  constructor(private readonly http: HttpClient) {}

  @ApiAlertError()
  changeUserActiveCompany(companyId: number): Observable<void> {
    const url = `api/v1/users/me/companies/${companyId}/active`;

    return this.http.patch<void>(url, null);
  }

  @ApiAlertError()
  createUser(user: User): Observable<User> {
    const url = 'api/v1/users';

    return this.http
      .post<UserApiContract>(url, User.toJson(user))
      .pipe(map(userJson => User.fromJson(userJson)));
  }

  @ApiAlertError()
  updateUser(user: User): Observable<User> {
    const url = 'api/v1/users/me';

    return this.http
      .patch<UserApiContract>(url, User.toJson(user))
      .pipe(map(userJson => User.fromJson(userJson)));
  }

  @ApiAlertError()
  getVatTypes(): Observable<VatType[]> {
    if (!this.vatTypesCache) {
      const url = `api/v1/vat_types`;

      this.vatTypesCache = this.http.get(url).pipe(
        map((vatTypesJson: any) =>
          vatTypesJson.map((vatTypeJson: any) => VatType.fromJson(vatTypeJson)),
        ),
        shareReplay({ refCount: true, bufferSize: 1 }),
      );
    }

    return this.vatTypesCache;
  }

  @ApiAlertError()
  getCountries(): Observable<Country[]> {
    if (!this.countriesCache) {
      const url = `api/v1/countries`;

      this.countriesCache = this.http.get(url).pipe(
        map((countriesJson: any) =>
          countriesJson.map((countryJson: any) =>
            Country.fromJson(countryJson),
          ),
        ),
        shareReplay({ refCount: true, bufferSize: 1 }),
      );
    }

    return this.countriesCache;
  }

  @ApiAlertError()
  getLegalForms(): Observable<string[]> {
    if (!this.legalFormsCache) {
      const url = `api/v1/legal_forms`;

      this.legalFormsCache = this.http.get<string[]>(url).pipe(
        map((legalForms: string[]) =>
          legalForms.filter((legalForm: string) => legalForm !== 'UserApp'),
        ),
        shareReplay({ refCount: true, bufferSize: 1 }),
      );
    }
    return this.legalFormsCache;
  }

  @ApiAlertError()
  getApeCodes(): Observable<ApeCode[]> {
    if (!this.apeCodesCache) {
      const url = 'api/v1/ape_codes';

      this.apeCodesCache = this.http
        .get<ApeCodeContract[]>(url)
        .pipe(map(apeCodes => apeCodes.map(ApeCode.serialize)));
    }
    return this.apeCodesCache;
  }

  @ApiAlertError()
  getRcsCities(): Observable<RcsCity[]> {
    if (!this.rcsCitiesCache) {
      const url = 'api/v1/company_registries';

      this.rcsCitiesCache = this.http
        .get<RcsCityContract[]>(url)
        .pipe(map(apeCodes => apeCodes.map(RcsCity.fromJson)));
    }
    return this.rcsCitiesCache;
  }

  @ApiAlertError()
  getLogo(logoId: number, companyId?: number): Observable<Blob> {
    const url = `api/v1/companies/${
      companyId || '{companyId}'
    }/logos/${logoId}/preview`;

    return this.http.get(url, { responseType: 'blob' });
  }

  @ApiAlertError()
  uploadLogo(file: File, isUpdatingCompanyLogo: boolean): Observable<number> {
    const url = `api/v1/companies/{companyId}/logos`;

    const formData = new FormData();
    formData.append('updateCompany', isUpdatingCompanyLogo.toString());
    formData.append('file', file);
    return this.http
      .post(url, formData)
      .pipe(map((response: { id: number }) => response.id));
  }

  @ApiAlertError()
  updatePassword(password: string): Observable<any> {
    const url = 'api/v1/users/me/update_password';
    const body = { password };

    return this.http.post(url, body);
  }

  @ApiAlertError()
  updateBusinessAccessRequest(
    businessAccessRequestId: number,
    updates: BusinessAccessRequestPatchUpdates,
    companyId: number | string = '{companyId}',
  ): Observable<BusinessAccessRequest> {
    const url = `api/v1/companies/${companyId}/business_access_requests/${businessAccessRequestId}`;
    return this.http.patch(url, updates);
  }

  @ApiAlertError()
  getRibPdf(): Observable<Blob> {
    const url = `api/v1/wallet/companies/{companyId}/rib`;

    return this.http
      .get(url, {
        headers: { 'Content-Type': 'application/pdf' },
        responseType: 'blob',
      })
      .pipe(map(file => new Blob([file], { type: 'application/pdf' })));
  }

  @ApiAlertError([HttpStatusCode.NotFound])
  downloadExport(
    exportRequest: ExportRequest | ExportRequestApiContract,
    filename: string,
  ): Observable<Blob> {
    const url = 'api/v1/companies/{companyId}/export';
    const exportRequestContract =
      exportRequest instanceof ExportRequest
        ? ExportRequest.toJson(exportRequest)
        : exportRequest;
    return this.http
      .post(url, exportRequestContract, {
        observe: 'response',
        headers: { Accept: 'application/vnd.tiime.export.v2+json' },
        responseType: 'blob',
      })
      .pipe(
        tap(response => FileHelper.saveAs(response.body, filename)),
        map(response => response.body),
      );
  }

  @ApiAlertError()
  getThirdParties(): Observable<ThirdParty[]> {
    const url =
      'api/v1/companies/{companyId}/filters/third_parties?ocr_status=ok';
    return this.http
      .get<ThirdPartyApiContract[]>(url)
      .pipe(
        map(thirdPartiesJson =>
          thirdPartiesJson.map(thirdPartyJson =>
            ThirdParty.fromJson(thirdPartyJson),
          ),
        ),
      );
  }

  @ApiAlertError()
  validateLegalInformations(
    walletLegalInformations: WalletLegalInformations,
  ): Observable<any> {
    const url = `api/v1/wallet/users/me/companies/{companyId}/validate_legal_informations`;

    return this.http.patch(url, walletLegalInformations.toJson());
  }

  @ApiAlertError()
  getBudgeaToken(): Observable<string> {
    const url = `api/v1/companies/{companyId}/budgea_token`;

    return this.http
      .get(url)
      .pipe(map((tokenJson: { token: string }) => tokenJson.token));
  }
}
