import { Pipe, PipeTransform } from '@angular/core';
import { ValidationErrors } from '@angular/forms';

@Pipe({
  name: 'isConfirmCodeInvalid',
})
export class IsConfirmCodeInvalidPipe implements PipeTransform {
  transform(
    formErrors: ValidationErrors,
    isCodeInputValid: boolean,
    confirmCodeErrors: ValidationErrors,
  ): unknown {
    return (
      formErrors?.matchingCodes &&
      isCodeInputValid &&
      !confirmCodeErrors?.lengthError
    );
  }
}
