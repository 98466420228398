import { FormControl } from '@angular/forms';

import { AbstractLineForm } from './abstract-line-form';

export class TextLineForm extends AbstractLineForm<{
  id: FormControl<number | null>;
}> {
  readonly isTextLine = true;

  get descriptionErrorMessage(): string {
    return this.description.touched && this.description.hasError('required')
      ? `Désignation requise`
      : '';
  }
}
