import { TrackingEvent } from '@manakincubber/tiime-tracking';

import { Quote } from '@models';

export const QUOTE_SENT = 'Quote Sent';

export class QuoteSent implements TrackingEvent {
  readonly name = QUOTE_SENT;

  constructor(readonly quoteId: Quote['id']) {}
}
