import { OperationType } from '@enums';
import { Bank } from '@models';

import { LinkedEntity, LinkedEntityType } from './linked-entity';
import { LinkedEntityAmount, LinkedEntityBase } from './linked-entity-base';

export interface LinkedEntityBankTransactionValueBase {
  wording: string;
  operationType: OperationType;
  bankAccount: { bank: Pick<Bank, 'name' | 'acronym' | 'logoUrl'> };
}

export interface LinkedEntityValueImputation {
  id: string;
  amount: number;
  label: { id: number };
}

export interface LinkedEntityBankTransactionValue
  extends LinkedEntityBase,
    LinkedEntityAmount,
    LinkedEntityBankTransactionValueBase {
  countDocuments: number;
  countInvoices: number;
  imputations: LinkedEntityValueImputation[];
  shortBankName: string;
}

/**
 * LinkedEntities of a document which type is neither `expense_report` nor `advanced_expense`.
 */
export class LinkedEntityBankTransaction extends LinkedEntity<LinkedEntityBankTransactionValue> {
  type = LinkedEntityType.BANK_TRANSACTION;
  constructor(value: LinkedEntityBankTransactionValue) {
    super(value);
  }
}
