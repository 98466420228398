import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  HostBinding,
  Input,
  Output,
} from '@angular/core';

@Component({
  selector: 'tiime-chip',
  templateUrl: './chip.component.html',
  styleUrls: ['./chip.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TiimeChipComponent {
  @Input() canRemove = false;
  @Input() removeSvgIcon = '';

  @HostBinding('class.error')
  @Input()
  error = false;

  @HostBinding('class.large')
  @Input()
  large = false;

  @HostBinding('class.small')
  @Input()
  small = false;

  /**
   * Material like design: https://material.angular.io/components/button/overview#button-overview
   */
  @HostBinding('class.basic')
  @Input()
  basic = false;

  /**
   * Metarial like design: https://material.angular.io/components/button/overview#button-overview
   */
  @HostBinding('class.flat')
  @Input()
  flat = false;

  @HostBinding('class.readonly')
  @Input()
  readonly = false;

  @HostBinding('class.disabled')
  @Input()
  disabled = false;

  @HostBinding('class.show-remove-icon-on-hover-only')
  @Input()
  showRemoveIconOnHoverOnly = false;

  @Output() readonly remove = new EventEmitter();
}
