import { FormControl, FormGroup } from '@angular/forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { tap } from 'rxjs/operators';
import { OrFilter, ResetFilter } from 'tiime-components';

import { TableColumnFilterForm } from '@shared';

interface OperationTypes {
  card: FormControl<boolean>;
  transfer: FormControl<boolean>;
  check: FormControl<boolean>;
  levy: FormControl<boolean>;
  cash: FormControl<boolean>;
  undefined: FormControl<boolean>;
}

@UntilDestroy()
export class TransactionsOperationTypeFilterForm extends TableColumnFilterForm {
  get filters(): FormGroup<OperationTypes> {
    return this.get('filters') as FormGroup;
  }
  get all(): FormControl {
    return this.get('all') as FormControl;
  }
  get card(): FormControl {
    return this.get('filters.card') as FormControl;
  }
  get transfer(): FormControl {
    return this.get('filters.transfer') as FormControl;
  }
  get check(): FormControl {
    return this.get('filters.check') as FormControl;
  }
  get levy(): FormControl {
    return this.get('filters.levy') as FormControl;
  }
  get cash(): FormControl {
    return this.get('filters.cash') as FormControl;
  }
  get undefined(): FormControl {
    return this.get('filters.undefined') as FormControl;
  }

  constructor(filterKey: string) {
    super(
      {
        all: new FormControl(null),
        filters: new FormGroup({
          card: new FormControl(),
          transfer: new FormControl(),
          check: new FormControl(),
          levy: new FormControl(),
          cash: new FormControl(),
          undefined: new FormControl(),
        }),
      },
      filterKey,
    );
    this.initAllMethodsCheckbox();
  }

  fromParam(operationTypeFilterValue: string): void {
    const operationTypeArray = operationTypeFilterValue.split('|');
    const patch = operationTypeArray
      .map((type: string) => ({ [type]: true }))
      .reduce(
        (previous, current) => ({
          ...previous,
          ...current,
        }),
        {
          card: null,
          transfer: null,
          check: null,
          levy: null,
          cash: null,
          undefined: null,
        },
      );

    this.patchValue(
      {
        all:
          operationTypeArray.length ===
          Object.keys(this.filters.controls).length,
        filters: patch,
      },
      { emitEvent: false }, // Otherwise observable on 'select all' checkbox will disable all filters
    );
  }

  resetFilters(): void {
    this.patchValue({ all: false });
  }

  toOutput(): OrFilter<string> | ResetFilter {
    const filterValue = Object.keys(this.filters.value).filter(
      (key: string) => (this.filters.value as { [key: string]: string })[key],
    );
    return filterValue.length
      ? new OrFilter(this.filterKey, filterValue)
      : ResetFilter.forKey(this.filterKey);
  }

  private initAllMethodsCheckbox(): void {
    this.all.valueChanges
      .pipe(
        tap((value: boolean) => {
          Object.keys(this.filters.controls).forEach(key => {
            this.filters.get(key).patchValue(value);
          });
        }),
        untilDestroyed(this),
      )
      .subscribe();

    this.filters.valueChanges
      .pipe(
        tap((values: Record<string, boolean>) => {
          const allValueUpdated = Object.values(values).every(v => v === true);
          this.all.patchValue(allValueUpdated, { emitEvent: false });
        }),
        untilDestroyed(this),
      )
      .subscribe();
  }
}
