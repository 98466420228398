import {
  ElementRef,
  Renderer2,
  Directive,
  AfterContentInit,
} from '@angular/core';

@Directive({
  selector: '[tiimeMultilineEllipsis]',
})
export class MultilineEllipsisDirective implements AfterContentInit {
  private readonly ellipsis = '...';

  constructor(private el: ElementRef, private renderer: Renderer2) {}

  ngAfterContentInit() {
    setTimeout(() => this.displayEllipsis(), 0);
  }

  private displayEllipsis(): void {
    const el: HTMLElement = this.el.nativeElement;
    let text: string[] = JSON.parse(JSON.stringify(el.innerText.split(' ')));
    while (el.scrollHeight > el.offsetHeight + 1) {
      text = text.slice(0, -1);
      this.renderer.setProperty(
        el,
        'innerText',
        `${text.join(' ')}${this.ellipsis}`,
      );
    }

    if (el.innerText.endsWith(this.ellipsis)) {
      this.renderer.removeAttribute(el, 'hideWithoutEllipsis');
    }
  }
}
