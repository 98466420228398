import { TrackingEvent } from '@manakincubber/tiime-tracking';

import { ActionsType } from '@enums/mass-actions';

export const MASS_ACTION_DISABLED_TOOLTIP_HOVERED =
  'Mass Action Disabled Tooltip Hovered';

export class MassActionDisabledTooltipHovered implements TrackingEvent {
  readonly name = MASS_ACTION_DISABLED_TOOLTIP_HOVERED;

  constructor(readonly actionType: ActionsType, readonly itemsType: string) {}
}
