import {
  FormControl,
  FormGroup,
  ValidationErrors,
  Validators,
} from '@angular/forms';
import { merge, Observable } from 'rxjs';
import { distinctUntilChanged } from 'rxjs/operators';

interface DiscountFormModel {
  description: FormControl<string>;
  amount: FormControl<number>;
  percentage: FormControl<number>;
}

const DISCOUNT_VALIDATOR = ({
  description,
  amount,
  percentage,
}: DiscountForm): ValidationErrors | null => {
  if (
    (amount.value ||
      amount.value === 0 ||
      percentage.value ||
      percentage.value === 0 ||
      description.value) &&
    (!description.value || (!amount.value && amount.value !== 0))
  ) {
    return {
      descriptionRequired: !description.value,
      amountRequired: !amount.value && amount.value !== 0,
    };
  }

  return null;
};

export class DiscountForm extends FormGroup<DiscountFormModel> {
  private static readonly TWO_DECIMALS_VALIDATOR = Validators.pattern(
    /^[-+]?\d+([.,]\d{1,2})?$/,
  );

  get description(): DiscountFormModel['description'] {
    return this.controls.description;
  }

  get percentage(): DiscountFormModel['percentage'] {
    return this.controls.percentage;
  }

  get amount(): DiscountFormModel['amount'] {
    return this.controls.amount;
  }

  get descriptionErrorMessage(): string {
    return this.description.touched && this.hasError('descriptionRequired')
      ? `Désignation requise`
      : '';
  }

  get amountErrorMessage(): string {
    if (this.amount.touched || this.amount.dirty) {
      if (this.hasError('amountRequired')) {
        return `Montant requis`;
      } else if (this.amount.hasError('pattern')) {
        return `Format du montant limité à 2 décimales`;
      }
    }

    return '';
  }

  get percentErrorMessage(): string {
    return (this.percentage.touched || this.percentage.dirty) &&
      this.percentage.hasError('pattern')
      ? `Format du pourcentage limité à 2 décimales`
      : '';
  }

  amountOrPercentageChanges$: Observable<string | number> = merge(
    this.amount.valueChanges.pipe(distinctUntilChanged()),
    this.percentage.valueChanges.pipe(distinctUntilChanged()),
  );

  constructor(enableValidation = false) {
    super({
      description: new FormControl<string>(null, { nonNullable: true }),
      percentage: new FormControl<number>(null, {
        validators: DiscountForm.TWO_DECIMALS_VALIDATOR,
        nonNullable: true,
      }),
      amount: new FormControl<number>(null, {
        validators: DiscountForm.TWO_DECIMALS_VALIDATOR,
        nonNullable: true,
      }),
    });

    if (enableValidation) {
      this.setValidators(DISCOUNT_VALIDATOR);
    }
  }

  setRequired(isRequired: boolean): void {
    this.setValidators(isRequired ? DISCOUNT_VALIDATOR : null);
    this.updateValueAndValidity({ emitEvent: false });
  }
}
