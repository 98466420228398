import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';

export enum BankTransfersActiveTab {
  TRANSFERS_HISTORY = 'transfers_history',
  PENDING_TRANSFERS = 'pending_transfers',
  BENEFICIARIES = 'beneficiaries',
}

@Injectable({
  providedIn: 'root',
})
export class BankTransfersListsService {
  readonly activeTab$ = new BehaviorSubject<BankTransfersActiveTab>(
    BankTransfersActiveTab.TRANSFERS_HISTORY,
  );

  readonly onOverlayClosed$ = new Subject<void>();
}
