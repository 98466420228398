import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  Router,
  UrlSegment,
  UrlTree,
} from '@angular/router';
import { Actions, ofType } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import { merge, Observable, of, throwError } from 'rxjs';
import { catchError, filter, map, switchMap, take, tap } from 'rxjs/operators';
import { PaginationData } from 'tiime-components';

import { DOCUMENT_CATEGORIES_WITH_SPECIFIC_UI } from '@constants';
import { Document } from '@models';
import {
  categoriesSelector,
  categoryByIdSelector,
  loadCategories,
} from '@store/categories';
import {
  documentByIdSelector,
  documentsByCategorySelector,
  LOAD_DOCUMENT_FAIL,
  LOAD_DOCUMENT_SUCCESS,
  loadDocument,
} from '@store/documents';

import { selectedCompanySelector } from '../store';

@Injectable({
  providedIn: 'root',
})
export class CanCreateOrEditDocumentCategoryGuard {
  constructor(
    private readonly store: Store,
    private readonly router: Router,
    private readonly actions$: Actions,
  ) {}

  canActivate(route: ActivatedRouteSnapshot): Observable<boolean | UrlTree> {
    return this.canCreateDocumentCategory(route.url);
  }

  private canCreateDocumentCategory(
    urlSegments: UrlSegment[],
  ): Observable<boolean | UrlTree> {
    this.loadCategoriesIfNeeded();

    const categoryId = this.getCategoryId(urlSegments);
    if (categoryId === null) {
      return this.redirectToGedHome();
    }
    const mode = this.getMode(urlSegments);

    return this.store.pipe(
      select(categoriesSelector),
      filter(categories => categories !== null),
      take(1),
      switchMap(() =>
        this.store.pipe(select(categoryByIdSelector(categoryId))),
      ),
      take(1),
      map(
        category =>
          category &&
          DOCUMENT_CATEGORIES_WITH_SPECIFIC_UI.has(category.identifier),
      ),
      switchMap(canCreateOrEdit => {
        if (canCreateOrEdit === false) {
          return this.redirectToGedHome();
        }
        if (mode === 'create') {
          return of(true);
        }

        const documentId = this.getDocumentId(urlSegments);
        return this.canDocumentBeEdited(documentId, categoryId);
      }),
      switchMap(canBeEdited => {
        if (canBeEdited === true) {
          return of(true);
        }

        return this.redirectToGedHome();
      }),
    );
  }

  private redirectToGedHome(): Observable<UrlTree> {
    return this.store.pipe(
      select(selectedCompanySelector),
      map(({ id }) =>
        this.router.createUrlTree(['companies', id, 'documents', 'categories']),
      ),
    );
  }

  private getCategoryId(urlSegments: UrlSegment[]): number | null {
    const strCategoryId = urlSegments[0]?.path;
    if (!strCategoryId) {
      return null;
    }
    try {
      return Number(strCategoryId);
    } catch {
      return null;
    }
  }

  private getDocumentId(urlSegments: UrlSegment[]): number | null {
    const strDocumentId = urlSegments[1]?.path;
    if (!strDocumentId) {
      return null;
    }
    try {
      return Number(strDocumentId);
    } catch {
      return null;
    }
  }

  private canDocumentBeEdited(
    documentId: number,
    categoryId: number,
  ): Observable<boolean> {
    return this.getDocument(documentId, categoryId).pipe(
      map(({ previewOnly }) => !previewOnly),
      catchError(() => of(false)),
    );
  }

  private getDocument(
    documentId: number,
    categoryId: number,
  ): Observable<Document> {
    return this.store.pipe(
      select(documentsByCategorySelector(categoryId)),
      take(1),
      switchMap((documents: PaginationData<Document>) => {
        if (!documents?.data.find(({ id }) => id === documentId)) {
          this.store.dispatch(loadDocument({ documentId, categoryId }));

          return merge(
            this.actions$.pipe(
              ofType(LOAD_DOCUMENT_FAIL),
              switchMap(() =>
                throwError(
                  'An error occured while trying to fetch the document',
                ),
              ),
            ),
            this.actions$.pipe(ofType(LOAD_DOCUMENT_SUCCESS)),
          );
        }

        return this.store.pipe(select(documentByIdSelector(documentId)));
      }),
      take(1),
    );
  }

  private loadCategoriesIfNeeded(): void {
    this.store
      .pipe(
        select(categoriesSelector),
        take(1),
        filter(categories => categories === null),
        tap(() => this.store.dispatch(loadCategories())),
      )
      .subscribe();
  }

  private getMode(urlSegments: UrlSegment[]): 'create' | 'edit' {
    if (urlSegments[urlSegments.length - 1].path === 'new') {
      return 'create';
    }
    return 'edit';
  }
}
