import { CommonModule } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnInit,
  Optional,
  Self,
} from '@angular/core';
import { ControlValueAccessor, NgControl } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { UntilDestroy } from '@ngneat/until-destroy';
import {
  BehaviorSubject,
  filter,
  map,
  Observable,
  of,
  switchMap,
  tap,
} from 'rxjs';
import {
  SnackbarConfig,
  TiimeButtonModule,
  TiimeSnackbarService,
} from 'tiime-components';

import { CategoriesService, DocumentsService, HttpFileService } from '@core';
import { UPLOAD_DOCUMENT_CONFIG } from '@core/constants';
import { filterNotNullOrUndefined } from '@core/helpers';
import {
  Beneficiary,
  Category,
  DefaultDocumentRoutes,
  Document,
} from '@core/models';
import { IconsModule } from '@shared';

import { FileUploaderButtonModule } from '../../../../../../shared/components/file-uploader-button/file-uploader-button.module';
import { DragAndDropZoneModule } from '../../../../../company-shared/components/drag-and-drop-zone/drag-and-drop-zone.module';
import {
  MatchBankTransferDialogComponent,
  MatchBankTransferDialogData,
} from '../match-bank-transfer-dialog/match-bank-transfer-dialog.component';

export interface RIBsDocumentApiContract {
  id: number;
  file_name: string;
}

const fromRIBDocumentJson = (json: RIBsDocumentApiContract): Document => {
  return new Document(json.id, null, json.file_name);
};

@UntilDestroy()
@Component({
  selector: 'app-bank-transfer-document-proof-picker',
  standalone: true,
  imports: [
    CommonModule,
    IconsModule,
    FileUploaderButtonModule,
    DragAndDropZoneModule,
    TiimeButtonModule,
  ],
  templateUrl: './bank-transfer-document-proof-picker.component.html',
  styleUrls: ['./bank-transfer-document-proof-picker.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BankTransferDocumentProofPickerComponent
  implements OnInit, ControlValueAccessor
{
  @Input() beneficiary: Beneficiary;
  @Input() canMatch = true;
  @Input() description: string;
  @Input() defaultDocumentRoutes?: DefaultDocumentRoutes;
  @Input() documentCategory?: Pick<Category, 'name' | 'identifier'>;
  @Input() label: string;
  @Input() transferAmount: number;
  @Input() mandatory: boolean;

  readonly uploadedDocument$: BehaviorSubject<Document> =
    new BehaviorSubject<Document>(undefined);

  readonly acceptedTypes = UPLOAD_DOCUMENT_CONFIG.acceptedTypes;

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  private onChange: (document: Document | null) => void = () => {};
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  private onTouched = (): void => {};

  constructor(
    private readonly categoriesService: CategoriesService,
    private readonly dialog: MatDialog,
    private readonly documentsService: DocumentsService,
    private readonly http: HttpClient,
    private readonly httpFileService: HttpFileService,
    private readonly snackbar: TiimeSnackbarService,
    @Self() @Optional() public readonly ngControl: NgControl,
  ) {
    if (this.ngControl) {
      this.ngControl.valueAccessor = this;
    }
  }

  ngOnInit(): void {
    if (this.defaultDocumentRoutes) {
      this.getDocumentFromDefaultRoute();
    }
  }

  writeValue(document: Document | undefined | null): void {
    this.uploadedDocument$.next(document);
    this.onChange(document);
  }

  registerOnChange(fn: () => void): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  openMatchingDialog(): void {
    this.dialog
      .open<
        MatchBankTransferDialogComponent,
        MatchBankTransferDialogData,
        Document | FileList
      >(MatchBankTransferDialogComponent, {
        data: {
          amount: this.transferAmount,
          acceptedTypes: this.acceptedTypes,
        },
      })
      .beforeClosed()
      .pipe(
        filter(
          document =>
            document instanceof FileList || document instanceof Document,
        ),
        tap(document => {
          if (document instanceof FileList) {
            this.uploadDocument(document);
          } else {
            this.writeValue(document);
            this.displayUploadSnackbar();
          }
        }),
      )
      .subscribe();
  }

  uploadDocument(files: FileList): void {
    if (this.documentCategory) {
      this.uploadDocumentToCategory(files, this.documentCategory);
    } else {
      this.uploadDocumentToDefaultRoute(files);
    }
  }

  removeDocument(): void {
    this.writeValue(null);
    this.ngControl.control.updateValueAndValidity();
  }

  private getDocumentFromDefaultRoute(): void {
    const url = `api/${this.defaultDocumentRoutes.get_document_route}`.replace(
      '{beneficiaryId}',
      this.beneficiary.id.toString(),
    );

    this.http
      .get<RIBsDocumentApiContract | undefined>(url)
      .pipe(
        filterNotNullOrUndefined(),
        map(r => fromRIBDocumentJson(r)),
        tap((document: Document) => this.writeValue(document)),
      )
      .subscribe();
  }

  private uploadDocumentToDefaultRoute(files: FileList): void {
    const url = `api/${this.defaultDocumentRoutes.post_document_route}`.replace(
      '{beneficiaryId}',
      this.beneficiary.id.toString(),
    );

    this.httpFileService
      .upload(url, files[0], fromRIBDocumentJson, 'post')
      .pipe(
        filter(result => result instanceof Document),
        tap((document: Document) => this.writeValue(document)),
        tap(() => this.displayUploadSnackbar()),
      )
      .subscribe();
  }

  private uploadDocumentToCategory(
    files: FileList,
    category: Pick<Category, 'name' | 'identifier'>,
  ): void {
    this.getCategoryId(category)
      .pipe(
        switchMap(id => this.documentsService.upload(files[0], id)),
        filter(result => result instanceof Document),
        tap((document: Document) => this.writeValue(document)),
        tap(() => this.displayUploadSnackbar()),
      )
      .subscribe();
  }

  private getCategoryId(
    category: Pick<Category, 'name' | 'identifier'>,
  ): Observable<number> {
    return this.categoriesService.getAll().pipe(
      map(
        categories =>
          categories.find(c => c.identifier === category.identifier).id,
      ),
      switchMap(id =>
        id
          ? of(id)
          : this.categoriesService
              .create(category.name)
              .pipe(map(category => category.id)),
      ),
    );
  }

  private displayUploadSnackbar(): void {
    this.snackbar.open(
      'Le justificatif a bien été ajouté',
      SnackbarConfig.success,
    );
  }
}
