import {
  Card,
  CardDeliveryAddress,
  CompanyWalletStakeholder,
  CompanyWalletStakeholderApiContract,
  WalletAccount,
} from '..';

export interface WalletAccountDialogData {
  userId?: number;
  walletAccount?: WalletAccount;
  kycValidated?: boolean;
  cardDeliveryAddress?: CardDeliveryAddress;
  card?: Card;
  walletUserRequest?: CompanyWalletUserRequest;
  hasBusinessFullPermission?: boolean;
}

export interface CompanyWalletUserRequestApiContract {
  id: number;
  lastname?: string;
  firstname?: string;
  acronym?: string;
  color?: string;
  stakeholder?: CompanyWalletStakeholderApiContract;
}

export class CompanyWalletUserRequest {
  constructor(
    public id: number,
    public lastname?: string,
    public firstname?: string,
    public acronym?: string,
    public color?: string,
    public stakeholder?: CompanyWalletStakeholder,
  ) {}

  static fromJson(
    json: CompanyWalletUserRequestApiContract,
  ): CompanyWalletUserRequest {
    return new CompanyWalletUserRequest(
      json.id,
      json.lastname,
      json.firstname,
      json.acronym,
      json.color,
      json.stakeholder
        ? CompanyWalletStakeholder.fromJson(json.stakeholder)
        : null,
    );
  }
}
