import {
  createSelector,
  createFeatureSelector,
  MemoizedSelector,
} from '@ngrx/store';

import { CATEGORY_IDENTIFIER_NOT_ALLOWING_DOCUMENT_IMPORT } from '@constants';
import { Category } from '@models';

import { AppState } from '../app-state';

export const categoriesSelector =
  createFeatureSelector<Category[]>('categories');

export const categoriesWithIdSelector = createSelector(
  categoriesSelector,
  state => state && state.filter(category => category.id),
);

export const uncreatedCategoriesSelector = createSelector(
  categoriesSelector,
  state => state && state.filter(category => !category.id),
);

export const categoryNotEmptySelector = createSelector(
  categoriesSelector,
  state => state && state.filter(category => !!category.id).length > 0,
);

export const categoryByIdSelector = (
  id: number,
): MemoizedSelector<AppState, Category> =>
  createSelector(categoriesSelector, state =>
    id ? state?.find(category => category.id === id) : null,
  );

export const categoriesLoadingSelector = createSelector(
  categoriesSelector,
  state => state === null,
);

export const categoryWithIdSortedByPinSelector = createSelector(
  categoriesSelector,
  state => state && Category.sortByPin(state.filter(category => category.id)),
);

export const categoriesAllowingDocumentImportSelector = createSelector(
  categoriesSelector,
  state =>
    state &&
    state.filter(
      category =>
        !CATEGORY_IDENTIFIER_NOT_ALLOWING_DOCUMENT_IMPORT.has(
          category.identifier,
        ),
    ),
);
