import { Pipe, PipeTransform } from '@angular/core';

import { Metadata } from '@models/documents';

import { getAmountFromDocumentMetadata } from '../utils/functions';

@Pipe({
  name: 'documentAmount',
})
export class DocumentAmountPipe implements PipeTransform {
  transform(metadata: Metadata[]): number {
    return getAmountFromDocumentMetadata(metadata);
  }
}
