import { Pipe, PipeTransform } from '@angular/core';
import { FormGroup } from '@angular/forms';

import { DocumentMetadataForm } from '@core/forms/documents';

@Pipe({ name: 'metadataFormGroup' })
export class MetadataFormGroupPipe implements PipeTransform {
  transform(key: string, form: DocumentMetadataForm): FormGroup {
    return form.get(key) as FormGroup;
  }
}
