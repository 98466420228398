import {
  AccountantDetailRequestType,
  BankTransactionStatus,
  OperationType,
} from '@enums';

import { AccountantDetailRequest } from '../accountant-detail-request';
import { BankTransactionThread } from '../messaging';
import {
  BankTransactionAuthor,
  BankTransactionAuthorApiContract,
} from './bank-transaction-author';
import { BankTransactionBeneficiary } from './bank-transaction-beneficiary';
import { DocumentsAndInvoices } from './documents-and-invoices';

export interface BankTransactionBaseApiContract {
  id?: number;
  realization_date?: string;
  wording?: string;
  original_wording?: string;
  bank_name?: string;
  short_bank_name?: string;
  beneficiary?: BankTransactionBeneficiary;
  transfer_label?: string;
  amount?: number;
  currency?: string;
  operation_type?: OperationType;
  status?: BankTransactionStatus;
  transaction_author?: BankTransactionAuthorApiContract;
  comment?: string;
}

export class BankTransactionBase extends DocumentsAndInvoices {
  constructor(
    public id?: number,
    public realizationDate?: string,
    public wording?: string,
    public originalWording?: string,
    public bankName?: string,
    public shortBankName?: string,
    public beneficiary?: BankTransactionBeneficiary,
    public transferLabel?: string,
    public amount?: number,
    public currency?: string,
    public operationType?: OperationType,
    public status?: BankTransactionStatus,
    public transactionAuthor?: BankTransactionAuthor,
    public comment?: string | null,
    public accountantDetailRequests?: AccountantDetailRequest[],
    public thread?: BankTransactionThread,
    ...parameters: ConstructorParameters<typeof DocumentsAndInvoices>
  ) {
    super(...parameters);
  }
  get hasPendingReceiptRequest(): boolean {
    return (
      this.accountantDetailRequests.length > 0 &&
      this.accountantDetailRequests.findIndex(
        accountantDetailRequest =>
          accountantDetailRequest.type ===
            AccountantDetailRequestType.RECEIPT &&
          !accountantDetailRequest.answeredAt &&
          !accountantDetailRequest.documentDeclaredUnavailableAt,
      ) > -1
    );
  }
}
