import { HttpClient, HttpParams, HttpStatusCode } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import {
  GetOptions,
  PaginationData,
  RequiredGetOptions,
} from 'tiime-components';

import { ApiAlertError } from '@decorators/api-alert-error';
import {
  Beneficiary,
  BeneficiaryApiContract,
  CreateBeneficiaryBody,
} from '@models';

import { HttpHelper } from '../../helpers';
import { TemporaryEncoder } from '../../temporary-encoder';
import { IbanService } from '../iban.service';

@Injectable({
  providedIn: 'root',
})
export class BeneficiariesService {
  readonly resource = 'api/v1/companies/{companyId}/beneficiaries';

  constructor(
    private readonly http: HttpClient,
    private readonly ibanService: IbanService,
  ) {}

  @ApiAlertError()
  get(): Observable<Beneficiary[]> {
    return this.http
      .get<BeneficiaryApiContract[]>(this.resource)
      .pipe(
        map(beneficiaries =>
          beneficiaries.map(beneficiary => Beneficiary.fromJson(beneficiary)),
        ),
      );
  }

  @ApiAlertError()
  getAll(
    getOptions: RequiredGetOptions<'range'>,
  ): Observable<PaginationData<Beneficiary>> {
    const partialOptions = new GetOptions(getOptions).toHttpGetOptions();

    let params = new HttpParams({
      fromObject: partialOptions.params,
      encoder: new TemporaryEncoder(),
    });
    params = HttpHelper.setSortParam(params, getOptions.sort);

    const options = {
      params: params,
      headers: partialOptions.headers,
    };

    return this.http
      .get<BeneficiaryApiContract[]>(this.resource, {
        ...options,
        observe: 'response',
      })
      .pipe(
        HttpHelper.mapToPaginationData(
          getOptions.range,
          (beneficiary: BeneficiaryApiContract) =>
            Beneficiary.fromJson(beneficiary),
        ),
      );
  }

  @ApiAlertError([
    HttpStatusCode.UnprocessableEntity,
    HttpStatusCode.TooManyRequests,
  ])
  create(
    beneficiary: Omit<CreateBeneficiaryBody, 'bic'>,
  ): Observable<Beneficiary> {
    return this.ibanService
      .getBic(beneficiary.iban)
      .pipe(
        switchMap(bic =>
          this.http.post<Beneficiary>(this.resource, { ...beneficiary, bic }),
        ),
      );
  }

  @ApiAlertError()
  patch(
    id: number,
    beneficiary: Partial<Beneficiary>,
  ): Observable<Beneficiary> {
    return this.http.patch<Beneficiary>(`${this.resource}/${id}`, beneficiary);
  }

  @ApiAlertError()
  delete(id: number): Observable<null> {
    return this.http.delete<null>(`${this.resource}/${id}`);
  }
}
