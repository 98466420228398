export enum BankConnectionStatus {
  pending = 'pending',
  closed = 'closed',
  action_needed = 'action_needed',
  additional_information_needed = 'additional_information_needed',
  bug = 'bug',
  connected = 'connected',
  unavailable = 'unavailable',
  wrongpass = 'wrongpass',
  broken = 'broken',
  password_expired = 'password_expired',
  sleeping = 'sleeping',
  webauthRequired = 'webauthRequired',
  rateLimiting = 'rateLimiting',
  websiteUnavailable = 'websiteUnavailable',
  SCARequired = 'SCARequired',
  decoupled = 'decoupled',
}
