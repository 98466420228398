import { FormControl, FormGroup } from '@angular/forms';

import { CountryForm } from '@core/forms/country-form';
import { InvoicingTemplate } from '@enums';

export type InvoicingDocumentConfigFormModel = {
  deliveryAddressEnabled: FormControl<boolean>;
  sirenOrSiretEnabled: FormControl<boolean>;
  intracomVatNumberEnabled: FormControl<boolean>;
  freeFieldEnabled: FormControl<boolean>;
  country: CountryForm;
  bankDetailEnabled: FormControl<boolean>;
  paymentConditionEnabled: FormControl<boolean>;
  acceptanceConditionsEnabled: FormControl<boolean>;
  discountEnabled: FormControl<boolean>;
  signatureEnabled: FormControl<boolean>;
  template: FormControl<InvoicingTemplate>;
  titleEnabled: FormControl<boolean>;
};

export class InvoicingDocumentConfigForm extends FormGroup<InvoicingDocumentConfigFormModel> {
  constructor() {
    super({
      deliveryAddressEnabled: new FormControl(false),
      sirenOrSiretEnabled: new FormControl(false),
      intracomVatNumberEnabled: new FormControl(false),
      freeFieldEnabled: new FormControl(false),
      country: new CountryForm(),
      bankDetailEnabled: new FormControl(false),
      paymentConditionEnabled: new FormControl(false),
      acceptanceConditionsEnabled: new FormControl(false),
      discountEnabled: new FormControl(false),
      signatureEnabled: new FormControl(true),
      template: new FormControl(InvoicingTemplate.standard),
      titleEnabled: new FormControl(false),
    });
  }
}
