<input
  id="file"
  hidden
  multiple
  type="file"
  #file
  [accept]="acceptedTypes.join()"
  (change)="onFileSelected($event)"
/>
<button tiime-button accent data-cy="documents__btn-add" (click)="file.click()">
  Ajouter un document
</button>
