import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import {
  TiimeButtonModule,
  TiimeDialogModule,
  TiimeMailModule,
} from 'tiime-components';

@NgModule({
  imports: [
    CommonModule,
    TiimeDialogModule,
    MatIconModule,
    TiimeMailModule,
    TiimeButtonModule,
  ],
  exports: [
    TiimeDialogModule,
    MatIconModule,
    TiimeMailModule,
    TiimeButtonModule,
  ],
})
export class MailDialogModule {}
