import {
  Component,
  ChangeDetectionStrategy,
  Input,
  Output,
  EventEmitter,
} from '@angular/core';

@Component({
  selector: 'app-drag-and-drop-zone',
  templateUrl: './drag-and-drop-zone.component.html',
  styleUrls: ['./drag-and-drop-zone.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DragAndDropZoneComponent {
  @Input() acceptedTypes: string[] = [];
  @Input() maximumSize: number;
  @Input() multiple: boolean;

  @Output() readonly dropped = new EventEmitter<FileList>();
  @Output() readonly unauthorizedType = new EventEmitter<boolean>();
  @Output() readonly incorrectSize = new EventEmitter<boolean>();

  hovered: boolean;

  onFileChanges(files: FileList): void {
    this.dropped.emit(files);
  }

  onHover(hovered: boolean): void {
    this.hovered = hovered;
  }

  onIncorrectSize(hasIncorrectSize: boolean): void {
    this.incorrectSize.emit(hasIncorrectSize);
  }

  onUnauthorizedType(isUnauthorizedType: boolean): void {
    this.unauthorizedType.emit(isUnauthorizedType);
  }
}
