<div class="answer-business-access-request-dialog-content">
  <tiime-dialog-close-button></tiime-dialog-close-button>
  <div class="dialog-title" mat-dialog-title>Demande de rattachement</div>
  <div class="dialog-content" mat-dialog-content>
    <img
      src="assets/illu_attachment_request.svg"
      alt="Demande de rattachement"
    />
    <p>
      Autorisez-vous
      {{ data.businessAccessRequest.businessUnit.brand || 'Tiime' }} à accéder
      aux données de
      <br/>
      votre société {{ data.companyName }} ?
    </p>
  </div>
  <div class="dialog-actions" mat-dialog-actions>
    <button tiime-button neutral inverted [mat-dialog-close]="">
      Plus tard
    </button>
    <button tiime-button accent (click)="acceptBusinessAccessRequest()">
      Autoriser
    </button>
  </div>
  <tiime-dialog-footer
    appCopyToClipboard
    confirmationMessage="L'adresse a bien été copiée dans le presse-papier"
    [textToCopy]="customerCareMailAddress"
  >
    Si vous ne comprenez pas cette démarche,
    <br/>
    <span class="contact-text">
      contactez le service client à {{ customerCareMailAddress }}
      <mat-icon inline="true" fontIcon="icon-ic-copy"></mat-icon>
    </span>
  </tiime-dialog-footer>
</div>
