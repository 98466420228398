import { NgModule } from '@angular/core';
import {
  MAT_DIALOG_DEFAULT_OPTIONS,
  MatDialogModule,
} from '@angular/material/dialog';

import { TiimeDialogCloseButtonComponent } from './dialog-close-button/dialog-close-button.component';
import { TiimeDialogFooterComponent } from './dialog-footer/dialog-footer.component';

@NgModule({
  imports: [TiimeDialogFooterComponent, TiimeDialogCloseButtonComponent],
  exports: [
    MatDialogModule,
    TiimeDialogFooterComponent,
    TiimeDialogCloseButtonComponent,
  ],
  providers: [
    {
      provide: MAT_DIALOG_DEFAULT_OPTIONS,
      useValue: {
        panelClass: 'tiime-dialog-panel',
        backdropClass: 'tiime-backdrop-dialog',
        hasBackdrop: true,
        closeOnNavigation: true,
      },
    },
  ],
})
export class TiimeDialogModule {}
