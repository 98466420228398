import {
  AbstractControl,
  UntypedFormControl,
  UntypedFormGroup,
  ValidationErrors,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import { FormUtils, IbanUtils } from '@manakincubber/tiime-utils';
import { EMPTY, Observable } from 'rxjs';
import {
  distinctUntilChanged,
  skip,
  startWith,
  switchMapTo,
  tap,
} from 'rxjs/operators';

import { Client } from '@models';
import { sirenOrSiretValidator, SIRET_OR_SIREN_REGEX } from '@shared';

import { CountryForm } from '../forms/country-form';

export const SIREN_OR_SIRET_DEFAULT_VALIDATORS: ValidatorFn[] = [
  Validators.pattern(SIRET_OR_SIREN_REGEX),
  sirenOrSiretValidator(),
];

export const BIC_REGEX = /^[A-Z]{6}[A-Z2-9][A-NP-Z0-9]([A-Z0-9]{3})?$/i;

export function ibanValidator(
  control: AbstractControl,
): ValidationErrors | null {
  return control.value && IbanUtils.isValid(control.value as string) !== true
    ? {
        validateIban: {
          valid: false,
        },
      }
    : null;
}

export function bicValidator(
  control: AbstractControl,
): ValidationErrors | null {
  return control.value && !BIC_REGEX.test(control.value as string)
    ? {
        validateBic: {
          valid: false,
        },
      }
    : null;
}

export abstract class AbstractClientForm extends UntypedFormGroup {
  get id(): UntypedFormControl {
    return this.get('id') as UntypedFormControl;
  }
  get name(): UntypedFormControl {
    return this.get('name') as UntypedFormControl;
  }
  get address(): UntypedFormControl {
    return this.get('address') as UntypedFormControl;
  }
  get addressComplement(): UntypedFormControl {
    return this.get('addressComplement') as UntypedFormControl;
  }
  get postalCode(): UntypedFormControl {
    return this.get('postalCode') as UntypedFormControl;
  }
  get city(): UntypedFormControl {
    return this.get('city') as UntypedFormControl;
  }
  get country(): CountryForm {
    return this.get('country') as CountryForm;
  }
  get intracomVatNumber(): UntypedFormControl {
    return this.get('intracomVatNumber') as UntypedFormControl;
  }
  get sirenOrSiret(): UntypedFormControl {
    return this.get('sirenOrSiret') as UntypedFormControl;
  }
  get iban(): UntypedFormControl {
    return this.get('iban') as UntypedFormControl;
  }
  get bic(): UntypedFormControl {
    return this.get('bic') as UntypedFormControl;
  }

  get nameErrorMessage(): string {
    return this.name.touched && this.name.hasError('required')
      ? `Nom requis`
      : '';
  }

  get clientErrorMessage(): string {
    return this.name.value && this.id.hasError('required')
      ? `Le client doit être créé`
      : '';
  }

  get addressComplementErrorMessage(): string {
    return this.addressComplement.touched &&
      this.addressComplement.hasError('maxlength')
      ? `Limité à 255 caractères`
      : '';
  }

  get sirenOrSiretErrorMessage(): string {
    if (!this.sirenOrSiret.touched) {
      return '';
    }
    if (this.sirenOrSiret.hasError('required')) {
      return 'SIREN ou SIRET requis';
    } else {
      if (this.sirenOrSiret.hasError('pattern')) {
        return 'Format invalide';
      } else {
        return this.sirenOrSiret.hasError('validSirenOrSiret')
          ? 'SIREN ou SIRET invalide'
          : '';
      }
    }
  }

  get intracomVatNumberMessage(): string {
    if (!this.intracomVatNumber.touched) {
      return '';
    }
    return this.intracomVatNumber.hasError('required')
      ? 'N° de TVA intracommunautaire requis'
      : '';
  }

  get ibanErrorMessage(): string {
    return this.iban.touched && this.iban.hasError('validateIban')
      ? `Format invalide`
      : '';
  }

  get bicErrorMessage(): string {
    return this.bic.touched && this.bic.hasError('validateBic')
      ? `Format invalide`
      : '';
  }

  protected constructor(controls?: Record<string, AbstractControl>) {
    super({
      id: new UntypedFormControl(),
      name: new UntypedFormControl(null),
      address: new UntypedFormControl(null),
      addressComplement: new UntypedFormControl(
        null,
        Validators.maxLength(255),
      ),
      postalCode: new UntypedFormControl(null),
      city: new UntypedFormControl(null),
      country: new CountryForm(),
      intracomVatNumber: new UntypedFormControl(),
      sirenOrSiret: new UntypedFormControl(
        null,
        SIREN_OR_SIRET_DEFAULT_VALIDATORS,
      ),
      iban: new UntypedFormControl(null, ibanValidator),
      bic: new UntypedFormControl(null, bicValidator),
      ...controls,
    });
  }

  fromClient(client: Client): void {
    if (!client.country) {
      delete client.country;
      this.country.reset({}, { onlySelf: true });
    }
    this.patchValue(Object.assign({}, client));
  }

  getIntracomVatNumberResetObservable(): Observable<void> {
    return this.country.valueChanges.pipe(
      startWith(this.country.value), // enable distinctUntilChanged on first real value change
      distinctUntilChanged(
        ({ id: previousId }, { id: currentId }) => previousId == currentId,
      ),
      skip(1), // skip the startWith value
      tap(() => this.intracomVatNumber.setValue(null)),
      switchMapTo(EMPTY),
    );
  }

  setClientValidators(config?: {
    sirenOrSiretRequired: boolean;
    intracomVatNumberRequired: boolean;
    clientIdRequired: boolean;
  }): void {
    this.name.setValidators(Validators.required);
    this.name.updateValueAndValidity(FormUtils.shouldNotEmitEvent);
    if (config) {
      this.sirenOrSiret.setValidators(
        config.sirenOrSiretRequired
          ? [Validators.required, ...SIREN_OR_SIRET_DEFAULT_VALIDATORS]
          : SIREN_OR_SIRET_DEFAULT_VALIDATORS,
      );
      this.intracomVatNumber.setValidators(
        config.intracomVatNumberRequired ? Validators.required : null,
      );
      this.id.setValidators(
        config.clientIdRequired ? Validators.required : null,
      );
      this.id.updateValueAndValidity(
        config.clientIdRequired ? FormUtils.shouldNotEmitEvent : null,
      );
      this.sirenOrSiret.updateValueAndValidity();
      this.intracomVatNumber.updateValueAndValidity();
    }
    this.updateValueAndValidity(FormUtils.shouldNotEmitEvent);
  }
}
