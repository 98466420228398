import { createTracker, withName } from '@manakincubber/tiime-tracking';
import { tap, withLatestFrom } from 'rxjs/operators';

import { optionsWithCompanyGroupExtra } from '@core/amplitude/utils';
import { VirementCommenceProperties } from '@core/amplitude/wrapper';
import { selectedCompanyIdSelector } from '@core/store';

import {
  ADVISOR_CARD_EMAIL_CLICKED,
  AdvisorCardEmailClicked,
  BANK_TRANSFER_REASON_CHOSE,
  BANK_TRANSFER_RECEIPT_INFO_CLICKED,
  BANK_TRANSFER_SENT,
  BANK_TRANSFER_SENT_25k,
  BANK_TRANSFER_STARTED,
  BANK_TRANSFER_STARTED_25k,
  BankTransferReasonChose,
  BankTransferReceiptInfoClicked,
  BankTransferSent,
  BankTransferSent25k,
  BankTransferStarted,
  BankTransferStarted25k,
} from '../events';
import { BaseTrackingGroup } from './base-tracking-group';

export class BusinessTrackingGroup extends BaseTrackingGroup {
  handleAdvisorCardEmailClicked$ = createTracker(
    this.events$.pipe(
      withName(ADVISOR_CARD_EMAIL_CLICKED),
      withLatestFrom(this.store.select(selectedCompanyIdSelector)),
      tap(([, companyId]: [AdvisorCardEmailClicked, number]) =>
        this.ampli.conseillerEmailContacte(
          optionsWithCompanyGroupExtra(companyId),
        ),
      ),
    ),
  );

  handle25kBankTransferStarted$ = createTracker(
    this.events$.pipe(
      withName(BANK_TRANSFER_STARTED_25k),
      withLatestFrom(this.store.select(selectedCompanyIdSelector)),
      tap(([, companyId]: [BankTransferStarted25k, number]) =>
        this.ampli.virement25KCommence(optionsWithCompanyGroupExtra(companyId)),
      ),
    ),
  );

  handleBankTransferStarted$ = createTracker(
    this.events$.pipe(
      withName(BANK_TRANSFER_STARTED),
      withLatestFrom(this.store.select(selectedCompanyIdSelector)),
      tap(([source, companyId]: [BankTransferStarted, number]) =>
        this.ampli.virementCommence(
          { source_virement: this.mapBankTransferSource(source) },
          optionsWithCompanyGroupExtra(companyId),
        ),
      ),
    ),
  );

  handleBankTransferReasonChose$ = createTracker(
    this.events$.pipe(
      withName(BANK_TRANSFER_REASON_CHOSE),
      withLatestFrom(this.store.select(selectedCompanyIdSelector)),
      tap(([reason, companyId]: [BankTransferReasonChose, number]) => {
        this.ampli.motifVirement25KChoisi(
          {
            motif_virement_25k_choisi:
              this.getLabelFromDecisionTreeActionTypeChoice(reason),
          },
          optionsWithCompanyGroupExtra(companyId),
        );
      }),
    ),
  );

  handle25kBankTransferSent$ = createTracker(
    this.events$.pipe(
      withName(BANK_TRANSFER_SENT_25k),
      withLatestFrom(this.store.select(selectedCompanyIdSelector)),
      tap(([, companyId]: [BankTransferSent25k, number]) =>
        this.ampli.demandeVirement25KEnvoye(
          optionsWithCompanyGroupExtra(companyId),
        ),
      ),
    ),
  );

  handleBankTransferSent$ = createTracker(
    this.events$.pipe(
      withName(BANK_TRANSFER_SENT),
      withLatestFrom(this.store.select(selectedCompanyIdSelector)),
      tap(([, companyId]: [BankTransferSent, number]) =>
        this.ampli.virementEnvoye(optionsWithCompanyGroupExtra(companyId)),
      ),
    ),
  );

  handleBankTransferReceiptInfoClicked$ = createTracker(
    this.events$.pipe(
      withName(BANK_TRANSFER_RECEIPT_INFO_CLICKED),
      withLatestFrom(this.store.select(selectedCompanyIdSelector)),
      tap(([, companyId]: [BankTransferReceiptInfoClicked, number]) =>
        this.ampli.virement25KEnSavoirPlusConsulte(
          optionsWithCompanyGroupExtra(companyId),
        ),
      ),
    ),
  );

  private getLabelFromDecisionTreeActionTypeChoice({
    reason,
  }: BankTransferReasonChose): string {
    if (!reason.action_type) {
      return reason.label.toLowerCase();
    }

    switch (reason.action_type) {
      case 'pay_invoice':
        return 'facture';
      case 'dividend':
        return 'dividendes';
      case 'salary':
        return 'salaire';
      default:
        return 'autre';
    }
  }

  private mapBankTransferSource({
    source,
  }: BankTransferStarted): VirementCommenceProperties['source_virement'] {
    switch (source) {
      case 'menuEntry':
        return 'bouton_comptes';
      case 'editBeneficiaryDialog':
        return 'profil_beneficiaire';
      case 'refundButton':
        return 'me_rembourser';
      case 'payButton':
        return 'payer';
      case 'sepaPayments':
        return 'virement_groupe';
      default:
        throw new Error('Unknown source for BankTransferStarted');
    }
  }
}
