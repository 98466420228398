import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { AcronymComponent } from './acronym.component';

@NgModule({
  declarations: [AcronymComponent],
  imports: [CommonModule],
  exports: [AcronymComponent],
})
export class AcronymModule {}
