import { FormControl, FormGroup } from '@angular/forms';

export class CountryForm extends FormGroup {
  get id(): FormControl {
    return this.get('id') as FormControl;
  }
  get name(): FormControl {
    return this.get('name') as FormControl;
  }
  get code(): FormControl {
    return this.get('code') as FormControl;
  }

  constructor() {
    super({
      id: new FormControl(null),
      name: new FormControl(null),
      code: new FormControl(null),
      invoicingVatExonerationArea: new FormControl(null),
    });
  }
}
