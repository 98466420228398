export type PaymentLinkType = 'stripe';

export interface PaymentLinkApiContract {
  id: number;
  type: PaymentLinkType;
  url: string;
}

export class PaymentLink {
  constructor(
    public id: number,
    public type: PaymentLinkType,
    public url: string,
  ) {}

  static fromJson(contract: PaymentLinkApiContract): PaymentLink {
    return new PaymentLink(contract.id, contract.type, contract.url);
  }
}
