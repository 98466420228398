import { Directive, Input } from '@angular/core';
import { SortDirection } from '@angular/material/sort';
import { UntilDestroy } from '@ngneat/until-destroy';
import { ResetFilter } from 'tiime-components';

import { TableColumnFilterBaseDirective } from './table-column-filter-base.directive';
import { TableColumnSortAndFilter } from './table-column-sort-and-filter';
import { TableColumnSortAndFilterForm } from './table-column-sort-and-filter.form';

@UntilDestroy()
@Directive()
export abstract class TableColumnSortAndFilterDirective extends TableColumnFilterBaseDirective<
  TableColumnSortAndFilterForm,
  TableColumnSortAndFilter
> {
  @Input() sortKey: string;

  @Input() set sort(sort: SortDirection) {
    this._sort = sort;
    this.form?.sortDirection.setValue(sort);
  }
  get sort(): SortDirection {
    return this._sort;
  }
  private _sort: SortDirection;

  reset(): void {
    this.valueChange.emit({
      sort: { active: undefined, direction: undefined },
      filter: ResetFilter.forKey(this.filterKey),
    });
    this.initForm();
    this.menuTrigger.closeMenu();
  }

  protected resetToLastPristineState(): void {
    this.form.sortDirection.setValue(this.sort);
    super.resetToLastPristineState();
  }
}
