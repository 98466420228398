import { BankTransferRecurrency, ScaActionType } from '@enums';

interface ScaApiAction {
  type: ScaActionType;
  value?: NewBankTransactionActionValue | NewSepaPayment;
}

interface NewBankTransactionActionValue {
  iban: string;
  amount: number;
  recurrency: BankTransferRecurrency;
  start_date: string;
  end_date: string;
}

interface NewSepaPayment {
  count: number;
}

export abstract class ScaAction {
  constructor(public type: ScaActionType) {}

  toJson(): ScaApiAction {
    return {
      type: this.type,
    };
  }
}

export class ScaNewBankTransactionAction extends ScaAction {
  constructor(
    public beneficiaryName: string,
    public iban: string,
    public amount: number,
    public startDate: string,
    public endDate: string,
    public recurrency: BankTransferRecurrency,
    public label: string,
  ) {
    super(ScaActionType.NewBankTransaction);
  }

  toJson(): ScaApiAction {
    return {
      type: this.type,
      value: {
        amount: this.amount,
        iban: this.iban,
        beneficiary_name: this.beneficiaryName,
        start_date: this.startDate,
        end_date: this.endDate,
        recurrency: this.recurrency,
        label: this.label,
      } as NewBankTransactionActionValue,
    };
  }
}

export class ScaCreateSession extends ScaAction {
  constructor() {
    super(ScaActionType.CreateScaSession);
  }
  toJson(): ScaApiAction {
    return {
      type: this.type,
      value: null,
    };
  }
}

export class ScaUpdatePinCardAction extends ScaAction {
  constructor() {
    super(ScaActionType.UpdatePinCard);
  }
}

export class ScaUnblockPinCardAction extends ScaAction {
  constructor() {
    super(ScaActionType.UnlockCard);
  }
}

export class ScaConfirmSepaPaymentAction extends ScaAction {
  constructor(public count: number) {
    super(ScaActionType.ConfirmSepaPayment);
  }

  toJson(): ScaApiAction {
    return {
      type: this.type,
      value: {
        count: this.count,
      },
    };
  }
}
