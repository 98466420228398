import { TrackingEvent } from '@manakincubber/tiime-tracking';

import { Invoice } from '@models';

export const INVOICE_SENT = 'Invoice Sent';

export class InvoiceSent implements TrackingEvent {
  readonly name = INVOICE_SENT;

  constructor(readonly invoiceId: Invoice['id']) {}
}
