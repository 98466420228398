import { InjectionToken } from '@angular/core';

import { ampli, Ampli } from './wrapper';

export const AMPLI = new InjectionToken<Ampli>(
  'An abstraction over the ampli wrapper',
  {
    factory: (): Ampli => ampli,
  },
);
