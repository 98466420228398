export enum FrenchTelephoneCode {
  France = '+33',
  Guadeloupe = '+590',
  Guyane = '+594',
  Martinique = '+596',
  LaReunion = '+262',
  SaintPierreEtMiquelon = '+508',
  WallisEtFutuna = '+681',
  NouvelleCaledonie = '+687',
  PolynesieFrancaise = '+689',
}
