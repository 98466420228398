export interface BusinessUnitApiContract {
  id: number;
  brand: string;
}

export class BusinessUnit {
  constructor(public id?: number, public brand?: string) {}

  static fromJson(json: BusinessUnitApiContract): BusinessUnit {
    return new BusinessUnit(json.id, json.brand);
  }
}
