export interface ClientContactApiContract {
  id: number;
  firstname?: string;
  lastname?: string;
  job?: string;
  email?: string;
  phone?: string;
  invoicing_use_email?: boolean;
}

export class ClientContact {
  constructor(
    public id?: number,
    public firstname?: string,
    public lastname?: string,
    public job?: string,
    public email?: string,
    public phone?: string,
    public useEmail?: boolean,
  ) {}

  public static fromJson(json: ClientContactApiContract): ClientContact {
    return new ClientContact(
      json.id,
      json.firstname,
      json.lastname,
      json.job,
      json.email,
      json.phone,
      json.invoicing_use_email || false,
    );
  }

  public static toJson(contact: ClientContact): ClientContactApiContract {
    return {
      id: contact.id || null,
      firstname: contact.firstname || null,
      lastname: contact.lastname || null,
      job: contact.job || null,
      email: contact.email || null,
      phone: contact.phone || null,
      invoicing_use_email: contact.useEmail,
    };
  }
}
