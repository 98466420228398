import { MatchableBankTransaction } from '@matching/types/matchable-bank-transaction';
import { MatchableImputations } from '@matching/types/matchable-imputations';
import { MatchedItem } from '@matching/types/matched-item';
import { LinkedBankTransaction } from '@models';
import {
  BankTransaction,
  BankTransactionImputation,
} from '@models/bank-transaction';
import {
  LinkedEntityBankTransaction,
  LinkedEntityBankTransactionValue,
  LinkedEntityImputation,
  LinkedEntityImputationValue,
} from '@models/linked-entities';

export class ImputationsHelper {
  static isBankTransaction(item: MatchedItem): boolean {
    return (
      item instanceof BankTransaction ||
      item instanceof LinkedEntityBankTransaction ||
      item instanceof LinkedBankTransaction
    );
  }

  static isImputation(item: MatchedItem): boolean {
    return (
      item instanceof BankTransactionImputation ||
      item instanceof LinkedEntityImputation
    );
  }

  static getBankTransactionFromMatchableBankTransactions(
    bankTransaction: MatchableBankTransaction,
  ):
    | BankTransaction
    | LinkedBankTransaction
    | LinkedEntityBankTransactionValue {
    if (
      bankTransaction instanceof BankTransaction ||
      bankTransaction instanceof LinkedBankTransaction
    ) {
      return bankTransaction;
    }

    return bankTransaction.value;
  }

  static getImputationFromMatchableImputations(
    imputation: MatchableImputations,
  ): BankTransactionImputation | LinkedEntityImputationValue {
    if (imputation instanceof BankTransactionImputation) {
      return imputation;
    }

    return imputation.value;
  }
}
