@switch (bankTransferOverlayService.ocerisationStep$ | async) {
  @case (OcerisationStep.NOT_STARTED) {
    @if (newDocument$ | async) {
      <app-bank-transfer-document-added
        (categorySelected)="onCategorySelected($event)"
      ></app-bank-transfer-document-added>
    } @else {
      <app-bank-transfer-add-document
        (fileDropped)="newDocument$.next($event[0])"
        (documentSelected)="updateDocument($event, OcerisationStep.SKIPPED)"
      ></app-bank-transfer-add-document>
    }
  }
  @case (OcerisationStep.DOC_UPLOADING) {
    <!-- Nothing should be displayed -->
  }
  @case (OcerisationStep.ERROR) {
    <!-- Nothing should be displayed -->
  }
  @default {
    <app-bank-transfer-document-preview />
  }
}
