import {
  AfterViewInit,
  Directive,
  DoCheck,
  ElementRef,
  OnDestroy,
  Renderer2,
} from '@angular/core';

/**
 * Components using this directive should alse import and use 'scroll-container' mixin
 */
@Directive({
  selector: '[scrollShadows]',
})
export class ScrollShadowsDirective
  implements AfterViewInit, OnDestroy, DoCheck
{
  private initialized = false;
  private topObserver!: IntersectionObserver;
  private bottomObserver!: IntersectionObserver;

  private get nativeElement(): HTMLElement {
    return this.scrollContainer.nativeElement;
  }

  constructor(
    private readonly scrollContainer: ElementRef<HTMLElement>,
    private readonly renderer: Renderer2,
  ) {}

  ngAfterViewInit(): void {
    this.initObserversAndSentinels();
  }

  /**
   * We need to use `ngDoCheck` instead of `ngAfterViewInit` because sometimes children divs are not ready yet
   * @returns void
   */
  ngDoCheck(): void {
    this.initObserversAndSentinels();
  }

  private initObserversAndSentinels(): void {
    if (this.initialized) return;

    if (this.nativeElement.childElementCount > 0) {
      this.renderer.insertBefore(
        this.nativeElement,
        this.renderer.createElement('div'),
        this.nativeElement.children[0],
      );
      this.renderer.appendChild(
        this.nativeElement,
        this.renderer.createElement('div'),
      );
      this.updateObservers();
      this.initialized = true;
    }
  }

  ngOnDestroy(): void {
    this.cleanObservers();
  }

  updateObservers(): void {
    this.setObserver('top');
    this.setObserver('bottom');
  }

  /**
   * Start listening to the scroll event on the container element
   */
  private setObserver(position: 'top' | 'bottom'): void {
    const formerObserver =
      position === 'top' ? this.topObserver : this.bottomObserver;

    const observer = new IntersectionObserver(
      records => this.onIntersectionChange(records, position),
      {
        threshold: 1.0,
        root: this.nativeElement,
      },
    );

    const children = this.nativeElement.children;
    const sentinel = children[position === 'top' ? 0 : children.length - 1];

    if (sentinel) {
      observer.observe(sentinel);
      if (formerObserver) {
        formerObserver.disconnect();
      }
      if (position === 'top') {
        this.topObserver = observer;
      } else {
        this.bottomObserver = observer;
      }
    }
  }

  /**
   * Add/Remove class to the target element when the sentinel element disappear/appear
   */
  private onIntersectionChange(
    records: IntersectionObserverEntry[],
    position: 'top' | 'bottom',
  ): void {
    const shadow = `${position}-shadow`;
    if (records.some(record => record.intersectionRatio < 1)) {
      this.nativeElement.classList.add(shadow);
    } else {
      this.nativeElement.classList.remove(shadow);
    }
  }

  private cleanObservers(): void {
    if (this.bottomObserver) {
      this.bottomObserver.disconnect();
    }
    if (this.topObserver) {
      this.topObserver.disconnect();
    }
  }
}
