<div class="label">{{ totalWording }}</div>

<div class="amounts-container">
  <div
    *ngIf="subTotal"
    class="vat-total"
    tiimeTooltip
    tooltipPosition="bottom"
    hideWithoutEllipsis
    [content]="subTotalTooltip"
  >
    <ng-container *ngTemplateOutlet="subTotalTooltip"></ng-container>
    <ng-template #subTotalTooltip>
      <ng-container
        *ngTemplateOutlet="tooltipContent; context: { value: subTotal }"
      ></ng-container>
    </ng-template>
  </div>

  <div
    class="total"
    tiimeTooltip
    tooltipPosition="bottom"
    hideWithoutEllipsis
    [content]="totalTooltip"
  >
    <ng-container *ngTemplateOutlet="totalTooltip"></ng-container>
    <ng-template #totalTooltip>
      <ng-container
        *ngTemplateOutlet="tooltipContent; context: { value: total }"
      ></ng-container>
    </ng-template>
  </div>
</div>

<ng-template let-value="value" #tooltipContent>
  <ng-container *ngIf="totalType === 'currency'; else numberTemplate">
    {{ value | currency }}
  </ng-container>

  <ng-template #numberTemplate>
    {{ value | number : '1.2-2' }} {{ customSuffix }}
  </ng-template>
</ng-template>
