<ng-container *tiimeLet="fileHasError$ | async as fileHasError">
  <app-drag-and-drop-zone
    [acceptedTypes]="acceptedTypes"
    [maximumSize]="maximumSize"
    (dropped)="onFileDropped($event)"
    (unauthorizedType)="onUnauthorizedType($event)"
    (incorrectSize)="onIncorrectSize($event)"
  >
    <ng-container idleState>
      <ng-container
        *ngIf="loading; then loadingView; else idleView"
      ></ng-container>
      <ng-template #idleView>
        <div class="state idle-state">
          <app-educational-spaces-dialog-button></app-educational-spaces-dialog-button>
          <ng-container
            *ngIf="fileHasError; then errorView; else headerView"
          ></ng-container>
          <ng-template #headerView>
            <ng-content select="[header]"></ng-content>
          </ng-template>
          <ng-template #errorView>
            <div class="error">
              <img
                class="error-illustration"
                src="assets/illu_unsupported_receipt_file.svg"
                draggable="false"
                alt="Erreur lors du dépôt"
              />
              <div class="error-content">
                <div class="error-title">Fichier incorrect</div>
                <div class="error-hint">
                  La taille maximale doit être de {{ maximumSize }}Mo
                  <br />
                  et le fichier doit être de type
                  {{ acceptedTypes | join: ', ' }}
                </div>
                <button
                  tiime-button
                  accent
                  type="button"
                  (click)="removeError()"
                >
                  Continuer
                </button>
              </div>
            </div>
          </ng-template>

          <div *ngIf="!fileHasError" class="idle-state-default">
            <span class="no-document" data-cy="documents__txt__empty-state">
              Aucun document
            </span>
            <img
              src="assets/illu_no_document.svg"
              draggable="false"
              alt="Ajouter un document"
            />
            <div class="instructions">
              <div class="white-circle">
                <mat-icon svgIcon="icon-upload"></mat-icon>
              </div>
              <app-add-document-button
                (unauthorizedType)="onUnauthorizedType(true)"
                (incorrectSize)="onIncorrectSize(true)"
              ></app-add-document-button>
              <span>
                Ou
                <br />
                Glisser / Déposer dans ce cadre
              </span>
            </div>
          </div>
        </div>
      </ng-template>
      <ng-template #loadingView>
        <div class="state loading-state">
          <span class="progress-value">{{ progress }}%</span>
          <div class="progress-bar">
            <tiime-progress-bar-loader
              mode="determinate"
              color="accent"
              [value]="progress"
            >
              Chargement en cours...
            </tiime-progress-bar-loader>
          </div>
        </div>
      </ng-template>
    </ng-container>
    <ng-container hoverState>
      <div class="state hover-state">
        <img
          class="hover-illustration"
          src="assets/illu_added_document.svg"
          draggable="false"
          alt="Déposer un document"
        />
        <span>Déposer ici</span>
      </div>
    </ng-container>
  </app-drag-and-drop-zone>

  <app-email-document-info
    *hasAccess="UserPermissionNameEnum.ACCOUNTS_USER_FULL"
  ></app-email-document-info>
</ng-container>
