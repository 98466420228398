import { Pipe, PipeTransform } from '@angular/core';

import { Metadata } from '@models/documents';

import { getWordingFromDocumentMetadata } from '../utils/functions';

@Pipe({
  standalone: true,
  name: 'documentWording',
})
export class DocumentWordingPipe implements PipeTransform {
  transform(metadata: Metadata[]): string {
    return getWordingFromDocumentMetadata(metadata);
  }
}
