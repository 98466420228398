import { Pipe, PipeTransform } from '@angular/core';

import { Metadata } from '@models/documents';

import { getAmountCurrencyFromDocumentMetadata } from '../utils/functions';

@Pipe({
  name: 'documentAmountCurrency',
})
export class DocumentAmountCurrencyPipe implements PipeTransform {
  transform(metadata: Metadata[]): string {
    return getAmountCurrencyFromDocumentMetadata(metadata);
  }
}
