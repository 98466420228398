export interface VatValidationRequestApiContract {
  vat_declaration_id: number;
  period_year: number;
  period_month: number;
}

export class VatValidationRequest {
  constructor(
    public vatDeclarationId?: number,
    public periodYear?: number,
    public periodMonth?: number,
  ) {}

  static fromJson(json: VatValidationRequestApiContract): VatValidationRequest {
    return new VatValidationRequest(
      json.vat_declaration_id,
      json.period_year,
      json.period_month,
    );
  }
}
