<tiime-dialog-close-button></tiime-dialog-close-button>

<app-confirmation-dialog-content
  [illu]="illu"
  [title]="title"
  [description]="description"
></app-confirmation-dialog-content>

<tiime-input-container [label]="data.messageLabel">
  <textarea tiimeInputContainerNativeControl [formControl]="control"></textarea>
</tiime-input-container>

<app-confirmation-dialog-actions
  [theme]="theme"
  [confirmLabel]="confirmLabel"
  [cancelLabel]="cancelLabel"
  [actionsButtonsWidth]="actionsButtonsWidth"
  [confirmButtonDisabled]="control.invalid"
  (confirm)="confirm()"
  (decline)="decline()"
></app-confirmation-dialog-actions>
