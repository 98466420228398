import { PaginationData } from 'tiime-components';

import {
  CompensationRateType,
  VehicleTypeEnum,
} from '@enums/mileage-allowances';
import { Travel, TravelApiContract, User, UserApiContract } from '@models';

import { VehicleType } from './vehicle-type';
import {
  VehiclesMetadata,
  VehiclesMetadataApiContract,
} from './vehicles-metadata';

export type VehicleCompensationRate = {
  type: CompensationRateType;
  value: number;
};

export interface VehicleMetadataApiContract {
  total_distance_year?: number;
}

export class VehicleMetadata {
  constructor(public totalDistanceYear?: number) {}

  static fromJson(json: VehicleMetadataApiContract): VehicleMetadata {
    return new VehicleMetadata(json.total_distance_year);
  }
}

export interface VehicleApiContract {
  id: number;
  created_at: string;
  owner: UserApiContract;
  owner_id: number;
  name: string;
  vehicle_type: VehicleTypeEnum;
  tax_horsepower: string;
  mileage: number;
  compensation_rate: VehicleCompensationRate;
  registration_document_id: number;
  total_distance: number;
  total_estimated_amount: number;
  archived_at: string;
  distance_declared: number;
  distance_to_declare: number;
  already_paid_amount: number;
  external_already_paid_amount: number;
  metadata: VehicleMetadataApiContract;
  is_mileage_updatable: boolean;
}
export interface VehicleWithTravelsApiContract
  extends Pick<
    VehicleApiContract,
    'id' | 'name' | 'archived_at' | 'created_at' | 'is_mileage_updatable'
  > {
  external_previous_distance: number;
  travels: Omit<TravelApiContract, 'vehicle'>[];
}

export class Vehicle {
  constructor(
    public id?: number,
    public createdAt?: string,
    public owner?: User,
    public name?: string,
    public vehicleType?: VehicleType,
    public taxHorsepower?: string,
    public mileage?: number,
    public compensationRate?: VehicleCompensationRate,
    public registrationDocumentId?: number,
    public externalAlreadyPaidAmount?: number,
    public totalDistance?: number,
    public totalEstimatedAmount?: number,
    public archivedAt?: string,
    public distanceDeclared?: number,
    public distanceToDeclare?: number,
    public alreadyPaidAmount?: number,
    public metadata?: VehicleMetadata,
    public isMileageUpdatable?: boolean,
  ) {}

  static fromJson(json: VehicleApiContract): Vehicle {
    return new Vehicle(
      json.id,
      json.created_at,
      json.owner ? User.fromJson(json.owner) : undefined,
      json.name,
      json.vehicle_type ? VehicleType.fromJson(json.vehicle_type) : undefined,
      json.tax_horsepower,
      json.mileage,
      json.compensation_rate,
      json.registration_document_id,
      json.external_already_paid_amount,
      json.total_distance,
      json.total_estimated_amount,
      json.archived_at,
      json.distance_declared,
      json.distance_to_declare,
      json.already_paid_amount,
      json.metadata ? VehicleMetadata.fromJson(json.metadata) : undefined,
      json.is_mileage_updatable,
    );
  }

  static toJson(vehicle: Vehicle): VehicleApiContract {
    return {
      id: vehicle.id,
      created_at: vehicle.createdAt,
      owner: vehicle.owner ? User.toJson(vehicle.owner) : undefined,
      owner_id: vehicle.owner ? vehicle.owner.id : undefined,
      name: vehicle.name,
      vehicle_type: vehicle.vehicleType
        ? VehicleType.toJson(vehicle.vehicleType)
        : undefined,
      tax_horsepower: vehicle.taxHorsepower,
      mileage: vehicle.mileage,
      compensation_rate: vehicle.compensationRate,
      registration_document_id: vehicle.registrationDocumentId,
      external_already_paid_amount: vehicle.externalAlreadyPaidAmount,
      total_distance: vehicle.totalDistance,
      total_estimated_amount: vehicle.totalEstimatedAmount,
      archived_at: vehicle.archivedAt,
      distance_declared: vehicle.distanceDeclared,
      distance_to_declare: vehicle.distanceToDeclare,
      already_paid_amount: vehicle.alreadyPaidAmount,
      metadata: undefined,
      is_mileage_updatable: vehicle.isMileageUpdatable,
    };
  }

  static metadataFromJson(json: VehiclesMetadataApiContract): VehiclesMetadata {
    return {
      filterYears: json.filter_years,
    };
  }
}

export class VehicleWithTravels {
  constructor(
    public id: number,
    public createdAt: string,
    public name: string,
    public archivedAt: string,
    public isMileageUpdatable: boolean,
    public externalPreviousDistance: number,
    public travels: Travel[],
  ) {}

  static fromJson(json: VehicleWithTravelsApiContract): VehicleWithTravels {
    return new VehicleWithTravels(
      json.id,
      json.created_at,
      json.name,
      json.archived_at,
      json.is_mileage_updatable,
      json.external_previous_distance,
      json.travels ? json.travels.map(Travel.fromJson) : undefined,
    );
  }
}

export class VehiclesWithMetadata {
  constructor(
    public vehicles: PaginationData<Vehicle>,
    public metadata: VehiclesMetadata,
  ) {}
}
