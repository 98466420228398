import {
  AbstractControl,
  FormControl,
  FormGroup,
  ValidationErrors,
  Validators,
} from '@angular/forms';

import { Label } from '@models/labels';

export class ImputationForm extends FormGroup {
  get id(): FormControl<number> {
    return this.get('id') as FormControl<number>;
  }

  get label(): FormControl<Label> {
    return this.get('label') as FormControl<Label>;
  }

  get amount(): FormControl<number> {
    return this.get('amount') as FormControl<number>;
  }

  get comment(): FormControl<string> {
    return this.get('comment') as FormControl<string>;
  }

  constructor(id?: number, label?: Label, amount?: number, comment?: string) {
    super({
      id: new FormControl(id ?? null),
      label: new FormControl(label ?? null, Validators.required),
      amount: new FormControl(amount ?? null, [
        Validators.required,
        (control: AbstractControl): ValidationErrors | null => {
          return control.value === 0 ? { required: true } : null;
        },
      ]),
      comment: new FormControl(comment ?? ''),
    });
  }
}
