import { createReducer, on } from '@ngrx/store';

import { WalletAccount } from '@models';

import { updateWalletAccount } from './wallet-account.actions';

export const walletAccountFeatureKey = 'walletAccount';

export const walletAccountInitialState: WalletAccount | null = null;

export const walletAccountReducer = createReducer(
  walletAccountInitialState,
  on(updateWalletAccount, (_state, { walletAccount }) => ({
    ...walletAccount,
  })),
);
