export enum ClientTimelineItemType {
  invoice = 'invoice',
  creditNote = 'credit_note',
  advancePayment = 'advance_payment',
  bankTransaction = 'bank_transaction',
  cashFlow = 'cash_flow',
  externalInvoice = 'external_invoice',
}

export type ClientTimelineInvoiceItemType =
  | ClientTimelineItemType.invoice
  | ClientTimelineItemType.creditNote
  | ClientTimelineItemType.advancePayment;

export type ClientTimelineItemDirection = 'debit' | 'credit';

export enum ClientTimelineProvider {
  invoices = 'invoices',
  bankTransactions = 'bank_transactions',
  cashFlows = 'cash_flows',
  externalInvoices = 'external_invoices',
}
