import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  forwardRef,
  Input,
  Output,
  ViewChild,
} from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { Mapper, TiimePipesModule } from '@manakincubber/tiime-utils';
import { UntilDestroy } from '@ngneat/until-destroy';
import { BehaviorSubject, combineLatest } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import {
  TiimeButtonModule,
  TiimeLetModule,
  TiimeOverlayConfig,
} from 'tiime-components';

import { filterNotNullOrUndefined } from '@helpers';
import { BankAccount, BankTransferReason, Beneficiary } from '@models';
import { IconsModule } from '@shared';

import {
  FilterOverlayReturnBase,
  SelectorCardWithFilterOverlayBase,
} from '../../bases';
import { AccountItemComponent } from './account-item/account-item.component';
import {
  AccountSelectorOverlayComponent,
  AccountSelectorOverlayData,
} from './account-selector-overlay/account-selector-overlay.component';

@UntilDestroy()
@Component({
  standalone: true,
  selector: 'app-account-selector',
  imports: [
    CommonModule,
    AccountItemComponent,
    IconsModule,
    TiimeLetModule,
    TiimePipesModule,
    TiimeButtonModule,
  ],
  templateUrl: './account-selector.component.html',
  styleUrls: [
    './account-selector.component.scss',
    '../../bases/selector-card-with-filter-overlay.base.scss',
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => AccountSelectorComponent),
      multi: true,
    },
    {
      provide: SelectorCardWithFilterOverlayBase,
      useExisting: forwardRef(() => AccountSelectorComponent),
    },
  ],
})
export class AccountSelectorComponent extends SelectorCardWithFilterOverlayBase<
  BankAccount | Beneficiary,
  FilterOverlayReturnBase<BankAccount | Beneficiary, string>
> {
  @Input() set accounts(accounts: (BankAccount | Beneficiary)[] | null) {
    this.accounts$.next(accounts);
  }

  @Input() label: string;
  @Input() canAddAccount = true;
  @Input() addAccountPosition: 'top' | 'bottom' = 'top';
  @Input() autocompleteAddAccountWithFilter = false;
  @Input() readonly = false;
  @Input() isBeneficiarySelector = false;
  @Input() personalWalletBeneficiary?: Beneficiary;
  @Input() reason?: BankTransferReason;

  @Output() readonly addAccount = new EventEmitter<string | null>();

  @ViewChild('buttonCard')
  private readonly buttonCard: ElementRef<HTMLElement>;

  readonly selectedItem$ = new BehaviorSubject<BankAccount | Beneficiary>(null);

  private readonly accounts$ = new BehaviorSubject<
    (BankAccount | Beneficiary)[]
  >([]);

  readonly mapToIconPath: Mapper<boolean, string> = isBeneficiarySelector =>
    isBeneficiarySelector
      ? 'assets/illus/bank-transfer/illu_bank_transfer_beneficiary.svg'
      : 'assets/illu_bank_transfer_account_blue.svg';
  readonly mapToLabel: Mapper<boolean, string> = isBeneficiarySelector => {
    if (!isBeneficiarySelector) {
      return 'Depuis le compte…';
    }
    return this.reason
      ? this.reason === 'refund'
        ? 'Choisir le bénéficiaire par défaut'
        : 'Choisir un fournisseur'
      : 'Vers le compte…';
  };

  cardClicked(selectedAccount: BankAccount | Beneficiary): void {
    if (this.overlayRef) {
      this.overlayRef.close();
      this.overlayRef = null;
      return;
    }

    const accounts$ = combineLatest([this.accounts$, this.filterValue$]).pipe(
      map(([accounts, filterValue]) => this.filterItems(accounts, filterValue)),
    );

    const overlayRef = this.overlayService.open<
      AccountSelectorOverlayComponent,
      AccountSelectorOverlayData,
      FilterOverlayReturnBase<BankAccount | Beneficiary, string>
    >(
      AccountSelectorOverlayComponent,
      {
        items$: accounts$,
        selectedItem: selectedAccount,
        searchFilterValue$: this.autocompleteAddAccountWithFilter
          ? this.filterValue$
          : null,
        canAddAccount: this.canAddAccount,
        addAccountPosition: this.addAccountPosition,
        isBeneficiarySelector: this.isBeneficiarySelector,
        personalWalletBeneficiary: this.personalWalletBeneficiary,
      },
      this.getOverlayConfig(),
    );

    this.openOverlay(overlayRef);

    this.overlayRef
      .beforeClosed()
      .pipe(
        filterNotNullOrUndefined(),
        tap(result => {
          if (result.itemSelected) {
            this.selectedItem$.next(result.itemSelected);
            this.notifyUpdate(result.itemSelected);
          } else {
            this.addAccount.emit(result.createItem);
          }
        }),
      )
      .subscribe();
  }

  protected getOverlayConfig(): TiimeOverlayConfig {
    return {
      hasBackdrop: false,
      width: this.buttonCard.nativeElement.offsetWidth,
      maxHeight: '296px',
      connectTo: {
        origin: this.selectorContainer,
        positions: [
          {
            originX: 'start',
            originY: 'bottom',
            overlayX: 'start',
            overlayY: 'top',
          },
        ],
      },
      repositionOnScroll: true,
    };
  }

  protected filterItems(
    items: (BankAccount | Beneficiary)[],
    filter: string,
  ): (BankAccount | Beneficiary)[] {
    if (!filter || filter === '') {
      return items;
    }
    return items.filter(account => {
      if (this.isBankAccount(account)) {
        return (
          account.name.toLowerCase().includes(filter) ||
          account.bankName.toLowerCase().includes(filter) ||
          account.iban.toLowerCase().includes(filter)
        );
      } else {
        return (
          account.name.toLowerCase().includes(filter) ||
          account.iban.toLowerCase().includes(filter)
        );
      }
    });
  }

  private isBankAccount(
    account: BankAccount | Beneficiary,
  ): account is BankAccount {
    return (
      (account as BankAccount).enabled !== null &&
      (account as BankAccount).enabled !== undefined
    );
  }
}
