import { BooleanInput, coerceBooleanProperty } from '@angular/cdk/coercion';
import { CdkScrollable, ScrollDispatcher } from '@angular/cdk/scrolling';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  HostBinding,
  Input,
  OnInit,
  Renderer2,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Observable } from 'rxjs';

import { AutoSaveData } from '../auto-save/auto-save-data';
import { AutoSaveService } from '../auto-save/auto-save.service';
import { ActionBarBackButtonConfig } from './action-bar-back-button-config';

@UntilDestroy()
@Component({
  selector: 'tiime-action-bar',
  templateUrl: './action-bar.component.html',
  styleUrls: ['./action-bar.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  // eslint-disable-next-line @angular-eslint/no-host-metadata-property
  host: {
    class: 'tiime-action-bar',
  },
})
export class ActionBarComponent implements OnInit, AfterViewInit {
  @Input()
  backButtonConfig: ActionBarBackButtonConfig;
  @Input()
  loading: boolean;
  @Input() hideRightSection = false;
  @Input() scrollElevation = false;

  // eslint-disable-next-line @angular-eslint/no-input-rename
  @Input('stickyTop') set setStickyTop(value: BooleanInput) {
    this.stickyTop = coerceBooleanProperty(value);
  }

  @HostBinding('class.tiime-action-bar--sticky-top')
  protected stickyTop = true;

  @ViewChild('actionBar')
  actionBarRef: ElementRef;

  autoSaveData$: Observable<AutoSaveData>;

  constructor(
    private scrollDispatcher: ScrollDispatcher,
    private renderer: Renderer2,
    private autoSaveService: AutoSaveService,
  ) {}

  ngOnInit(): void {
    this.initAutoSave();
  }

  ngAfterViewInit(): void {
    if (this.scrollElevation) {
      this.manageElevationOnScroll();
    }
  }

  private initAutoSave(): void {
    this.autoSaveData$ = this.autoSaveService.observeAutoSave();
  }

  private manageElevationOnScroll(): void {
    this.scrollDispatcher
      .scrolled()
      .pipe(untilDestroyed(this))
      .subscribe((scrollable: CdkScrollable) => {
        if (scrollable?.measureScrollOffset('top') > 0) {
          this.renderer.addClass(
            this.actionBarRef.nativeElement,
            'elevation-2',
          );
        } else {
          this.renderer.removeClass(
            this.actionBarRef.nativeElement,
            'elevation-2',
          );
        }
      });
  }
}
