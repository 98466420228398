import { BankTransactionStatus, OperationType } from '@enums';

import {
  AccountantDetailRequest,
  AccountantDetailRequestApiContract,
} from '../accountant-detail-request';
import { Document } from '../document';
import { InvoiceListItem } from '../invoice-list-item';
import { Label, LabelApiContract } from '../labels/label';
import {
  BankTransactionThread,
  BankTransactionThreadApiContract,
} from '../messaging';
import { BankTransactionAuthor } from './bank-transaction-author';
import {
  BankTransactionBase,
  BankTransactionBaseApiContract,
} from './bank-transaction-base';
import { BankTransactionBeneficiary } from './bank-transaction-beneficiary';
import { DocumentsAndInvoicesApiContact } from './documents-and-invoices';

export interface BankTransactionImputationApiContract
  extends DocumentsAndInvoicesApiContact {
  id: number;
  label: LabelApiContract | null;
  amount: number;
  comment: string;
  accountant_detail_requests: AccountantDetailRequestApiContract[];
  threads?: BankTransactionThreadApiContract[];
}

export class BankTransactionImputation extends BankTransactionBase {
  public parentId?: number;
  constructor(
    public label?: Label,
    id?: number,
    realizationDate?: string,
    wording?: string,
    originalWording?: string,
    bankName?: string,
    shortBankName?: string,
    beneficiary?: BankTransactionBeneficiary,
    transferLabel?: string,
    amount?: number,
    currency?: string,
    operationType?: OperationType,
    status?: BankTransactionStatus,
    transactionAuthor?: BankTransactionAuthor,
    countDocuments?: number,
    countInvoices?: number,
    documents?: Document[],
    invoices?: InvoiceListItem[],
    comment?: string,
    accountantDetailRequests?: AccountantDetailRequest[],
    thread?: BankTransactionThread,
  ) {
    super(
      id,
      realizationDate,
      wording,
      originalWording,
      bankName,
      shortBankName,
      beneficiary,
      transferLabel,
      amount,
      currency,
      operationType,
      status,
      transactionAuthor,
      comment,
      accountantDetailRequests,
      thread,
      countDocuments,
      countInvoices,
      documents,
      invoices,
    );
  }

  static fromJson(
    json: BankTransactionImputationApiContract,
    bankTransactionBase: BankTransactionBaseApiContract,
  ): BankTransactionImputation {
    return new BankTransactionImputation(
      Label.fromJson(json.label),
      json.id,
      bankTransactionBase.realization_date,
      bankTransactionBase.wording,
      bankTransactionBase.original_wording,
      bankTransactionBase.bank_name,
      bankTransactionBase.short_bank_name,
      bankTransactionBase.beneficiary,
      bankTransactionBase.transfer_label,
      json.amount,
      bankTransactionBase.currency,
      bankTransactionBase.operation_type,
      bankTransactionBase.status,
      bankTransactionBase.transaction_author
        ? BankTransactionAuthor.fromJson(bankTransactionBase.transaction_author)
        : null,
      json.count_documents,
      json.count_invoices,
      json.documents
        ? json.documents.map(document => Document.fromJson(document))
        : [],
      json.invoices
        ? json.invoices.map(invoiceJson =>
            InvoiceListItem.fromJson(invoiceJson),
          )
        : [],
      json.comment,
      json.accountant_detail_requests
        ? json.accountant_detail_requests.map(req =>
            AccountantDetailRequest.fromJson(req),
          )
        : [],
      json.threads ? BankTransactionThread.fromJsonArray(json.threads) : null,
    );
  }

  static toJson(
    imputation: BankTransactionImputation,
  ): BankTransactionImputationApiContract {
    return {
      id: imputation.id,
      label: Label.toJson(imputation.label),
      amount: imputation.amount,
      count_documents: imputation.countDocuments,
      count_invoices: imputation.countInvoices,
      documents: imputation.documents
        ? imputation.documents.map(Document.toJson)
        : [],
      invoices: imputation.invoices,
      comment: imputation.comment,
      accountant_detail_requests: imputation.accountantDetailRequests
        ? imputation.accountantDetailRequests.map(req =>
            AccountantDetailRequest.toJson(req),
          )
        : [],
    };
  }
}
