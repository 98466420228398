<div
  *ngIf="hasFilesInQueue$ | async"
  class="container"
  data-cy="document-uploader-overlay"
  [class.compressed]="!this.isExpanded"
>
  <ng-container *tiimeLet="currentFiles$ | async as currentFiles">
    <div class="header row">
      <div>
        <mat-icon
          class="toggle"
          svgIcon="{{ TIIME_ICON_PREFIX + 'chevron' }}"
          [class]="isExpanded ? 'rotate-90' : 'rotate-270'"
          (click)="toggleContentDisplay()"
        ></mat-icon>
        <span>{{ headerDescription$ | async }}</span>
      </div>
      @if ((hasPendingTransfer$ | async) === false) {
        <mat-icon
          class="closing-icon"
          inline="true"
          fontIcon="icon-ic-close-r"
          (click)="closeOverlay()"
        ></mat-icon>
      } @else if (!isExpanded) {
        <span>
          {{ currentFiles.length - (pendingTransferringFilesCount$ | async) }} /
          {{ currentFiles.length }}
        </span>
      }
    </div>

    <div class="files-container">
      <!-- No trackBy here because the BehaviorSubject that returns the queue of documents -->
      <!-- returns the newly selected documents first and the old ones after -->
      <!-- which makes the trackBy not update the view since the indexes are replaced. -->
      <!-- Also there is no unique property in the objects to define a new trackBy. -->
      <!-- eslint-disable-next-line @angular-eslint/template/use-track-by-function -->
      <div *ngFor="let fileTransfer of currentFiles" class="row">
        <app-transferring-file
          [fileTransfer]="fileTransfer"
        ></app-transferring-file>
      </div>
    </div>
  </ng-container>
</div>
