import { CommonModule, CurrencyPipe } from '@angular/common';
import {
  Attribute,
  ChangeDetectionStrategy,
  Component,
  Input,
} from '@angular/core';
import { TiimeTooltipModule } from 'tiime-components';

@Component({
  standalone: true,
  imports: [CommonModule, CurrencyPipe, TiimeTooltipModule],
  selector: 'app-expenses-report-editor-total',
  templateUrl: './expenses-report-editor-total.component.html',
  styleUrls: ['./expenses-report-editor-total.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ExpensesReportEditorTotalComponent {
  @Input() totalWording: string;
  @Input() totalType: 'currency' | 'number' = 'currency';
  @Input() customSuffix: string;
  @Input() subTotal: number;
  @Input() total: number;

  constructor(@Attribute('large') private readonly large: boolean) {}
}
