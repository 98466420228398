import { HttpClient } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { Observable, map } from 'rxjs';

import { ApiAlertError } from '@core/decorators/api-alert-error';
import {
  BusinessAdvisorContact,
  BusinessAdvisorContactApi,
} from '@core/models';

@Injectable({
  providedIn: 'root',
})
export class BusinessAdvisorContactService {
  readonly #resource = 'api/v1/wallet/companies/{companyId}/adviser';
  readonly #http = inject(HttpClient);

  @ApiAlertError()
  get(): Observable<BusinessAdvisorContact> {
    return this.#http.get<BusinessAdvisorContactApi>(this.#resource).pipe(
      map((contact: BusinessAdvisorContactApi) => {
        return BusinessAdvisorContact.fromJson(contact);
      }),
    );
  }
}
