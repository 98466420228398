import { inject, InjectionToken } from '@angular/core';

import { WINDOW } from './window.token';

export const INTERSECTION_OBSERVER_SUPPORT = new InjectionToken<boolean>(
  'Intersection Observer API support',
  {
    providedIn: 'root',
    factory: (): boolean => {
      const windowRef = inject(WINDOW);
      return (
        'IntersectionObserver' in windowRef &&
        !!(windowRef as Window & { IntersectionObserver: IntersectionObserver })
          .IntersectionObserver
      );
    },
  },
);
