export interface BankStatementApiContract {
  id: number;
  emission_date: string;
}

export class BankStatement {
  constructor(public id: number, public emissionDate: string) {}

  static fromJson(json: BankStatementApiContract): BankStatement {
    return new BankStatement(json.id, json.emission_date);
  }
}
