import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

export type LoadingEventState = 'start' | 'end';

export class LoadingEvent {
  get isStartEvent(): boolean {
    return this.stage === 'start';
  }

  get isEndEvent(): boolean {
    return this.stage === 'end';
  }

  constructor(
    readonly key: string,
    readonly stage: LoadingEventState,
  ) {}

  static start(key: string): LoadingEvent {
    return new LoadingEvent(key, 'start');
  }

  static end(key: string): LoadingEvent {
    return new LoadingEvent(key, 'end');
  }
}

@Injectable()
export class LoadingService {
  private readonly loadingSubject = new Subject<LoadingEvent>();

  asObservable(): Observable<LoadingEvent> {
    return this.loadingSubject.asObservable();
  }

  start(key: string): void {
    this.loadingSubject.next(LoadingEvent.start(key));
  }

  end(key: string): void {
    this.loadingSubject.next(LoadingEvent.end(key));
  }
}
