import { FormControl, FormGroup, Validators } from '@angular/forms';

import { InvoicingCategoriesEnum } from '@enums';

type ArticleFormModel = {
  code: FormControl<string>;
  description: FormControl<string | null>;
  unitAmount: FormControl<number | null>;
  vatTypeCode: FormControl<string | null>;
  invoicingUnitId: FormControl<number | null>;
  invoicingCategoryType: FormControl<InvoicingCategoriesEnum | null>;
};

export class ArticleForm extends FormGroup<ArticleFormModel> {
  constructor(value?: {
    vatTypeCode: string;
    invoicingCategoryType: InvoicingCategoriesEnum;
  }) {
    super({
      code: new FormControl('', Validators.required),
      description: new FormControl(''),
      unitAmount: new FormControl<number | null>(null),
      vatTypeCode: new FormControl(value?.vatTypeCode),
      invoicingUnitId: new FormControl<number | null>(null),
      invoicingCategoryType: new FormControl<InvoicingCategoriesEnum | null>(
        value?.invoicingCategoryType,
      ),
    });
  }
}
