import { Directive, HostBinding, Input } from '@angular/core';

import { TableCellBase } from './table-cell.base';

@Directive({
  selector: '[tiimeTableHeaderCell]',
})
export class TableHeaderCellDirective extends TableCellBase {
  @Input('tiimeTableHeaderCell')
  set name(name: string) {
    this.setColumnClass(name);
  }

  @HostBinding('class') class = 'cdk-header-cell';
  @HostBinding('scope') scope = 'col';
}
