<tiime-chip
  removeSvgIcon="tc_icon-button-close"
  [canRemove]="true"
  [flat]="true"
  (remove)="remove.emit()"
>
  <span tiimeTooltip tooltipPosition="bottom" hideWithoutEllipsis>
    {{ filter.displayedValue }}
  </span>
</tiime-chip>
