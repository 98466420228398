import { MatchableDocument } from '@matching/types/matchable-document';

import { Document, DocumentApiContract } from '../document';
import { InvoiceListItem } from '../invoice-list-item';

export interface DocumentsAndInvoicesApiContact {
  count_documents: number;
  count_invoices: number;
  invoices: any[];
  documents: DocumentApiContract[];
}

export class DocumentsAndInvoices {
  get hasInvoices(): boolean {
    return this.countInvoices > 0;
  }

  get allDocuments(): MatchableDocument[] {
    const allDocuments = [];
    if (this.documents) {
      allDocuments.push(...this.documents);
    }
    if (this.invoices) {
      allDocuments.push(...this.invoices);
    }
    return allDocuments;
  }

  get totalDocumentsAndInvoices(): number {
    return this.countDocuments + this.countInvoices;
  }

  constructor(
    public countDocuments: number,
    public countInvoices: number,
    public documents: Document[],
    public invoices: InvoiceListItem[],
  ) {}
}
