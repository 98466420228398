import { createAction, props } from '@ngrx/store';
import { RequiredGetOptions } from 'tiime-components';

import { Document, DocumentsWithMetadata } from '@models';

export const LOAD_DOCUMENTS_FOR_SEARCH =
  '[documents] load documents for search';
export const LOAD_DOCUMENTS_FOR_SEARCH_SUCCESS =
  '[documents] load documents for search success';
export const LOAD_DOCUMENTS_FOR_CATEGORY =
  '[document] load documents for category';
export const LOAD_DOCUMENTS_FOR_CATEGORY_SUCCESS =
  '[document] load documents for category success';
export const UPDATE_DOCUMENTS_FOR_CATEGORY =
  '[document] update documents for category';
export const RESET_DOCUMENTS_FOR_CATEGORY =
  '[document] reset documents for category';
export const LOAD_RECENT_DOCUMENTS = '[document] load recent documents';
export const UPDATE_RECENT_DOCUMENTS = '[document] update recent documents';
export const UPDATE_DOCUMENT = '[document] update document';
export const UPDATE_DOCUMENT_SUCCESS = '[document] update document success';
export const CREATE_DOCUMENT = '[document] create document';
export const CREATE_DOCUMENT_SUCCESS = '[document] create document success';
export const DELETE_DOCUMENT = '[document] delete document';
export const LOAD_DOCUMENT = '[document] load document';
export const LOAD_DOCUMENT_SUCCESS = '[document] load document success';
export const LOAD_DOCUMENT_FAIL = '[document] load document fail';
export const DELETE_DOCUMENT_SUCCESS = '[document] delete document success';
export const UPDATE_DOCUMENT_CATEGORY = '[document] update document category';
export const UPDATE_DOCUMENT_CATEGORY_SUCCESS =
  '[document] update document category success';
export const UPDATE_DOCUMENT_CATEGORY_FAIL =
  '[document] update document category fail';
export const LOAD_DOCUMENT_DETAIL = '[document] load preview document';
export const REMOVE_DOCUMENT_FROM_STORE =
  '[document] remove document from store';

export const loadDocumentDetail = createAction(
  LOAD_DOCUMENT_DETAIL,
  props<{ documentId: number }>(),
);

export const loadDocumentsForSearch = createAction(
  LOAD_DOCUMENTS_FOR_SEARCH,
  props<RequiredGetOptions<'range'>>(),
);

export const loadDocumentsForSearchSuccess = createAction(
  LOAD_DOCUMENTS_FOR_SEARCH_SUCCESS,
  props<{ documents: DocumentsWithMetadata }>(),
);

export const loadDocumentsForCategory = createAction(
  LOAD_DOCUMENTS_FOR_CATEGORY,
  props<{
    categoryId: number | number[];
    options: RequiredGetOptions<'range'>;
  }>(),
);

export const loadDocumentsForCategorySuccess = createAction(
  LOAD_DOCUMENTS_FOR_CATEGORY_SUCCESS,
  props<{
    documents: DocumentsWithMetadata;
  }>(),
);

export const updateDocumentsForCategory = createAction(
  UPDATE_DOCUMENTS_FOR_CATEGORY,
  props<{
    categoryId: number;
    documents: DocumentsWithMetadata;
  }>(),
);

export const resetDocumentsForCategory = createAction(
  RESET_DOCUMENTS_FOR_CATEGORY,
  props<{ categoryId: number }>(),
);

export const updateRecentDocuments = createAction(
  UPDATE_RECENT_DOCUMENTS,
  props<{
    documents: DocumentsWithMetadata;
  }>(),
);

export const loadRecentDocuments = createAction(LOAD_RECENT_DOCUMENTS);

export const updateDocument = createAction(
  UPDATE_DOCUMENT,
  props<{ payload: Partial<Document>; documentId: number }>(),
);

export const createDocument = createAction(
  CREATE_DOCUMENT,
  props<{ payload: Partial<Document> }>(),
);

export const createDocumentSuccess = createAction(
  CREATE_DOCUMENT_SUCCESS,
  props<{ document: Document }>(),
);

export const loadDocument = createAction(
  LOAD_DOCUMENT,
  props<{
    documentId: number;
    categoryId: number;
  }>(),
);

export const loadDocumentSuccess = createAction(
  LOAD_DOCUMENT_SUCCESS,
  props<{ document: Document }>(),
);
export const loadDocumentFail = createAction(LOAD_DOCUMENT_FAIL);

export const updateDocumentCategory = createAction(
  UPDATE_DOCUMENT_CATEGORY,
  props<{ payload: Partial<Document>; document: Document }>(),
);
export const updateDocumentCategorySuccess = createAction(
  UPDATE_DOCUMENT_CATEGORY_SUCCESS,
  props<{ document: Document }>(),
);
export const updateDocumentCategoryFail = createAction(
  UPDATE_DOCUMENT_CATEGORY_FAIL,
);

export const updateDocumentSuccess = createAction(
  UPDATE_DOCUMENT_SUCCESS,
  props<{ document: Document }>(),
);

export const deleteDocument = createAction(
  DELETE_DOCUMENT,
  props<{
    document: Document;
    options: RequiredGetOptions<'range'>;
  }>(),
);

export const deleteDocumentSuccess = createAction(DELETE_DOCUMENT_SUCCESS);

export const removeDocumentFromStore = createAction(
  REMOVE_DOCUMENT_FROM_STORE,
  props<{ documentId: number }>(),
);
