import {
  AfterViewInit,
  Directive,
  ElementRef,
  Input,
  OnDestroy,
  OnInit,
} from '@angular/core';

@Directive({
  selector: '[tiimeStickyTable]',
  exportAs: 'tiime-sticky-table',
})
export class StickyTableDirective implements AfterViewInit, OnInit, OnDestroy {
  /**
   * It must be a top container of the sticky element and of the element that will trigger the custom class on the sticky element.
   * This should be the element with the scroll
   * If an string is provided, it must be the ID of the element.
   */
  @Input()
  get scrollContainer(): HTMLElement {
    return this._scrollContainerRef;
  }
  set scrollContainer(value: HTMLElement) {
    this._scrollContainerRef = value;
  }

  private get lastRow(): HTMLElement | undefined {
    const tableRows =
      this.tableElement.nativeElement.getElementsByTagName('tr');
    if (tableRows.length === 0) {
      return undefined;
    }

    return tableRows[tableRows.length - 1];
  }

  private get nativeTableHeaderElementToStick(): Element {
    return this.tableElement.nativeElement.getElementsByClassName(
      'cdk-header-row',
    )[0];
  }

  // eslint-disable-next-line @typescript-eslint/naming-convention, no-underscore-dangle, id-blacklist, id-match
  private _scrollContainerRef: HTMLElement;
  private topSentinel: HTMLElement;
  private topObserver: IntersectionObserver;
  private bottomObserver: IntersectionObserver;

  constructor(private tableElement: ElementRef<HTMLTableElement>) {}

  ngOnInit(): void {
    this.scrollContainer.classList.add('sticky-table-container');
  }

  ngOnDestroy(): void {
    this.cleanObservers();
  }

  updateObserversAndSentinels(): void {
    this.makeStickyHeader();
    this.putTopSentinel();
    this.setTopObserver();
    this.setBottomObserver();
  }

  ngAfterViewInit(): void {
    if (!this.lastRow) {
      return;
    }
    this.updateObserversAndSentinels();
  }

  private makeStickyHeader(): void {
    const nativeElement: HTMLElement =
      this.tableElement.nativeElement.getElementsByTagName('thead')[0];
    nativeElement.style.position = 'sticky';
    nativeElement.style.top = '0px';
    nativeElement.style.zIndex = '5';
  }

  /**
   * Start listening to the scroll event on the container element
   */
  private setTopObserver(): void {
    const formerObserver = this.topObserver;
    const observer = new IntersectionObserver(
      records => this.onTopAppears(records),
      {
        threshold: [0],
        root: this.scrollContainer,
      },
    );

    // Add the top sentinels to the table and observe interactions.
    observer.observe(this.topSentinel);

    this.topObserver = observer;
    if (formerObserver) {
      formerObserver.disconnect();
    }
  }

  /**
   * Start listening to the scroll event on the container element
   */
  private setBottomObserver(): void {
    setTimeout(() => {
      const formerObserver = this.bottomObserver;
      const observer = new IntersectionObserver(
        records => this.onBottomAppears(records),
        {
          threshold: [0],
          root: this.scrollContainer,
        },
      );
      // Add the bottom sentinels to each section and attach an observer.
      const lastRow = this.lastRow;
      if (lastRow) {
        observer.observe(lastRow);
        this.bottomObserver = observer;
        if (formerObserver) {
          formerObserver.disconnect();
        }
      }
    }, 0);
  }

  /**
   * Add/Remove class to the target element when the sentinel element disappear/appear
   */
  private onTopAppears(records: IntersectionObserverEntry[]): void {
    for (const record of records) {
      const targetInfo = record.boundingClientRect;
      const rootBoundsInfo = record.rootBounds;

      if (!rootBoundsInfo) {
        continue;
      }

      if (targetInfo.bottom < rootBoundsInfo.top) {
        this.nativeTableHeaderElementToStick.classList.add('should-stick');
      }

      if (
        targetInfo.bottom >= rootBoundsInfo.top &&
        targetInfo.bottom < rootBoundsInfo.bottom
      ) {
        this.nativeTableHeaderElementToStick.classList.remove('should-stick');
      }
    }
  }

  /**
   * Add/Remove class to the target element when the sentinel element disappear/appear
   */
  private onBottomAppears(records: IntersectionObserverEntry[]): void {
    for (const record of records) {
      if (record.isIntersecting) {
        this.scrollContainer.classList.remove('bottom-shadow');
      } else {
        this.scrollContainer.classList.add('bottom-shadow');
      }
    }
  }

  /**
   * Generates the sentinel element with the necessary styles
   */
  private generateTopSentinelElement(): HTMLElement {
    const sentinelEl = document.createElement('div');
    sentinelEl.style.height = '20px';
    sentinelEl.style.width = '100%';
    sentinelEl.style.top = '-40px';
    sentinelEl.style.position = 'absolute';
    sentinelEl.style.visibility = 'hidden';

    return sentinelEl;
  }

  /**
   * Add the sentinel element as the first child of the table element
   */
  private putTopSentinel(): void {
    const formerTopSentinel = this.topSentinel;
    const sentinel = this.generateTopSentinelElement();

    let rowContainer: Element =
      this.tableElement.nativeElement.getElementsByTagName('tbody')[0];

    if (
      rowContainer.getElementsByClassName('cdk-virtual-scroll-content-wrapper')
        .length > 0
    ) {
      rowContainer = rowContainer.getElementsByClassName(
        'cdk-virtual-scroll-content-wrapper',
      )[0];
    }

    this.topSentinel = rowContainer.insertAdjacentElement(
      'afterbegin',
      sentinel,
    ) as HTMLElement;
    if (formerTopSentinel) {
      formerTopSentinel.remove();
    }
  }

  private cleanObservers(): void {
    if (this.bottomObserver) {
      this.bottomObserver.disconnect();
    }
    if (this.topObserver) {
      this.topObserver.disconnect();
    }
  }
}
