import { createAction, props } from '@ngrx/store';

import { Company } from '@models';

import { User } from '../../models/user';

export const LOAD_SELECTED_COMPANY_ACTIVE_CARD =
  '[User] Load selected company active card';
export const SELECT_COMPANY = '[User] Select company';
export const UPDATE = '[User] Update';
export const UPDATE_SELECTED_COMPANY_CARD =
  '[User] Update selected company card';
export const UPDATE_SELECTED_COMPANY_LOGO =
  '[User] Update selected company logo';

export const loadSelectedCompanyActiveCard = createAction(
  LOAD_SELECTED_COMPANY_ACTIVE_CARD,
);
export const selectCompany = createAction(
  SELECT_COMPANY,
  props<{ company: Company }>(),
);
export const update = createAction(UPDATE, props<{ user: User }>());
export const updateSelectedCompanyLogo = createAction(
  UPDATE_SELECTED_COMPANY_LOGO,
  props<{ logoId: number }>(),
);
