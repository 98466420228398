export interface TotalPerVatTypeApiContract {
  total_excluding_taxes: number;
  vat_amount: number;
}

export class TotalPerVatType {
  constructor(public totalExcludingTaxes?: number, public vatAmount?: number) {}

  static fromJson(json: TotalPerVatTypeApiContract): TotalPerVatType {
    return new TotalPerVatType(json.total_excluding_taxes, json.vat_amount);
  }

  static toJson(totalPerVatType: TotalPerVatType): TotalPerVatTypeApiContract {
    return {
      total_excluding_taxes: totalPerVatType.totalExcludingTaxes,
      vat_amount: totalPerVatType.vatAmount,
    };
  }
}
