import { A11yModule } from '@angular/cdk/a11y';
import { CdkStepperModule } from '@angular/cdk/stepper';
import { CommonModule } from '@angular/common';
import { Injectable, NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { DateAdapter, NativeDateAdapter } from '@angular/material/core';
import { MatDividerModule } from '@angular/material/divider';
import { TiimePipesModule } from '@manakincubber/tiime-utils';
import moment from 'moment';
import { LottieComponent } from 'ngx-lottie';
import {
  TiimeButtonModule,
  TiimeCardModule,
  TiimeCheckboxModule,
  TiimeDialogModule,
  TiimeIconModule,
  TiimeInputContainerModule,
  TiimeLetModule,
  TiimeTwocolorsIconModule,
} from 'tiime-components';

import { MomentHelper } from '@helpers';
import { SharedModule } from '@shared';

import { FileUploaderButtonModule } from '../../../../shared/components/file-uploader-button/file-uploader-button.module';
import { AccountSelectorComponent } from '../../../company-shared/components/account-selector/account-selector.component';
import { AmountFormControlComponent } from '../../../company-shared/components/amount-form-control/amount-form-control.component';
import { IbanInputModule } from '../../account-shared/iban-input/iban-input.module';
import { TrustBeneficiaryComponent } from '../beneficiaries/trust-beneficiary/trust-beneficiary.component';
import { AddBeneficiaryDialogModule } from './components/add-beneficiary-dialog/add-beneficiary-dialog.module';
import { BankTransferCardComponent } from './components/bank-transfer-card/bank-transfer-card.component';
import { LabelCreatedTransactionSnackbarComponent } from './components/bank-transfer-card/label-created-transaction-snackbar/label-created-transaction-snackbar.component';
import { BankTransferInfoDialogComponent } from './components/bank-transfer-document-info-dialog/bank-transfer-document-info-dialog.component';
import { BankTransferDocumentProofChoiceComponent } from './components/bank-transfer-document-proof-choice/bank-transfer-document-proof-choice.component';
import { BankTransferDocumentProofPickerComponent } from './components/bank-transfer-document-proof-picker/bank-transfer-document-proof-picker.component';
import { BankTransferDocumentProofComponent } from './components/bank-transfer-document-proof/bank-transfer-document-proof.component';
import { BankTransferInitiationComponent } from './components/bank-transfer-initiation/bank-transfer-initiation.component';
import { BankTransferParametersModule } from './components/bank-transfer-parameters/bank-transfer-parameters.module';
import { BankTransferRecapModule } from './components/bank-transfer-recap/bank-transfer-recap.module';
import { BankTransferStepperComponent } from './components/bank-transfer-stepper/bank-transfer-stepper.component';
import { MatchBankTransferDialogModule } from './components/match-bank-transfer-dialog/match-bank-transfer-dialog.module';
import { SepaPaymentsImportDialogComponent } from './components/sepa-payements-import-dialog/sepa-payements-import-dialog.component';
import { BankTransferPipesModule } from './pipes/bank-transfer-pipes.module';

@Injectable()
class TodayDateAdapter extends NativeDateAdapter {
  format(date: Date, displayFormat: Record<string, unknown>): string {
    const today = new Date();
    if (MomentHelper.isSameDate(moment(date), moment(today))) {
      return `Aujourd'hui`;
    }
    return super.format(date, displayFormat);
  }
}

@NgModule({
  declarations: [
    BankTransferInitiationComponent,
    BankTransferCardComponent,
    BankTransferStepperComponent,
    BankTransferDocumentProofComponent,
    SepaPaymentsImportDialogComponent,
    LabelCreatedTransactionSnackbarComponent,
  ],
  imports: [
    CommonModule,
    A11yModule,
    ReactiveFormsModule,
    LottieComponent,
    FileUploaderButtonModule,
    MatchBankTransferDialogModule,
    SharedModule,
    AddBeneficiaryDialogModule,
    BankTransferParametersModule,
    BankTransferPipesModule,
    BankTransferRecapModule,
    TrustBeneficiaryComponent,
    AmountFormControlComponent,
    BankTransferInfoDialogComponent,
    BankTransferDocumentProofPickerComponent,
    BankTransferDocumentProofChoiceComponent,
    // Tiime
    TiimeCardModule,
    TiimeLetModule,
    TiimeButtonModule,
    TiimeDialogModule,
    TiimeIconModule,
    TiimeCheckboxModule,
    TiimeTwocolorsIconModule,
    TiimePipesModule,
    TiimeInputContainerModule,
    // Material
    MatDividerModule,
    CdkStepperModule,
    // App Components Modules
    IbanInputModule,
    AccountSelectorComponent,
  ],
  exports: [BankTransferCardComponent, SepaPaymentsImportDialogComponent],
  providers: [{ provide: DateAdapter, useClass: TodayDateAdapter }],
})
export class BankTransfersSharedModule {}
