import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TiimeIconModule } from 'tiime-components';

import { SavedCheckmarkComponent } from './saved-checkmark.component';

@NgModule({
  declarations: [SavedCheckmarkComponent],
  imports: [CommonModule, TiimeIconModule],
  exports: [SavedCheckmarkComponent],
})
export class SavedCheckmarkModule {}
