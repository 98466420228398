import { TiimeSelectOption } from 'tiime-components';

import { FrenchTelephoneCode } from '@core/enum';

export const FRENCH_TELEPHONE_CODES_OPTIONS: TiimeSelectOption<FrenchTelephoneCode>[] =
  [
    {
      label: '+33 (0)',
      value: FrenchTelephoneCode.France,
    },
    {
      label: '+590 (0)',
      value: FrenchTelephoneCode.Guadeloupe,
    },
    {
      label: '+594 (0)',
      value: FrenchTelephoneCode.Guyane,
    },
    {
      label: '+596 (0)',
      value: FrenchTelephoneCode.Martinique,
    },
    {
      label: '+262 (0)',
      value: FrenchTelephoneCode.LaReunion,
    },
    {
      label: '+508 (0)',
      value: FrenchTelephoneCode.SaintPierreEtMiquelon,
    },
    {
      label: '+681 (0)',
      value: FrenchTelephoneCode.WallisEtFutuna,
    },
    {
      label: '+687 (0)',
      value: FrenchTelephoneCode.NouvelleCaledonie,
    },
    {
      label: '+689 (0)',
      value: FrenchTelephoneCode.PolynesieFrancaise,
    },
  ];
