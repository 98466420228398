import { TrackingEvent } from '@manakincubber/tiime-tracking';

import { Quote } from '@models';

export const QUOTE_DOWNLOADED = 'Quote Downloaded';

export class QuoteDownloaded implements TrackingEvent {
  readonly name = QUOTE_DOWNLOADED;

  constructor(readonly quoteId: Quote['id']) {}
}
