import { inject, InjectionToken } from '@angular/core';

import { WINDOW } from './window.token';

export const GO_CARDLESS_REDIRECT_URI = new InjectionToken<string>(
  'The redirect URI of the GoCardless OAuth flow',
  {
    providedIn: 'root',
    factory: (): string =>
      `${inject(WINDOW).location.origin}/go-cardless-callback`,
  },
);
