import { FormControl, FormGroup, Validators } from '@angular/forms';

import { Check } from '@models';

export class CheckDepositForm extends FormGroup {
  get cmc7(): FormControl {
    return this.get('cmc7') as FormControl;
  }
  get drawerType(): FormControl {
    return this.get('drawerType') as FormControl;
  }
  get amount(): FormControl {
    return this.get('amount') as FormControl;
  }
  get rlmcKey(): FormControl {
    return this.get('rlmcKey') as FormControl;
  }
  get drawer(): FormControl {
    return this.get('drawer') as FormControl;
  }
  get drawerFirstname(): FormControl {
    return this.get('drawerFirstname') as FormControl;
  }
  get drawerLastname(): FormControl {
    return this.get('drawerLastname') as FormControl;
  }

  get drawerErrorMessage(): string {
    return this.drawer.touched && this.drawer.hasError('required')
      ? `Emetteur requis`
      : '';
  }

  get drawerNameErrorMessage(): string {
    return this.drawerFirstname.touched &&
      this.drawerFirstname.hasError('required')
      ? `Prénom de l'emetteur requis`
      : '';
  }

  get drawerLastnameErrorMessage(): string {
    return this.drawerLastname.touched &&
      this.drawerLastname.hasError('required')
      ? `Nom de l'emetteur requis`
      : '';
  }

  get drawerTypeErrorMessage(): string {
    return this.drawerType.touched && this.drawerType.hasError('required')
      ? `Type d'émetteur requis`
      : '';
  }

  get rlmcKeyErrorMessage(): string {
    let error = '';
    if (
      this.rlmcKey.errors?.minlength ||
      this.rlmcKey.errors?.maxlength ||
      this.rlmcKey.errors?.pattern
    ) {
      error = `Clé erronée`;
    }
    if (this.rlmcKey.touched && this.rlmcKey.hasError('required')) {
      error = `Clé requise`;
    }
    return error;
  }

  get amountErrorMessage(): string {
    let error = '';
    if (this.amount.touched && this.amount.hasError('max')) {
      error = `Valeur max : 10000`;
    }
    if (this.amount.touched && this.amount.hasError('min')) {
      error = `Valeur min : 1`;
    }
    if (this.amount.touched && this.amount.hasError('required')) {
      error = `Montant requis`;
    }
    return error;
  }

  get cmcErrorMessage(): string {
    let error = '';
    if (
      this.cmc7.errors?.minlength ||
      this.cmc7.errors?.maxlength ||
      this.cmc7.errors?.pattern
    ) {
      error = `Le numéro du chèque doit contenir 31 chiffres`;
    }
    if (this.cmc7.touched && this.cmc7.hasError('required')) {
      error = `Numéro du chèque requis`;
    }
    return error;
  }

  constructor() {
    super({
      cmc7: new FormControl(null, [
        Validators.required,
        Validators.minLength(31),
        Validators.maxLength(31),
        Validators.pattern('[0-9]*'),
      ]),
      drawerType: new FormControl(null, [Validators.required]),
      amount: new FormControl(null, [
        Validators.required,
        Validators.min(1.0),
        Validators.max(10000.0),
      ]),
      rlmcKey: new FormControl(null, [
        Validators.required,
        Validators.minLength(2),
        Validators.maxLength(2),
        Validators.pattern('[0-9]*'),
      ]),
      drawer: new FormControl(null, []),
      drawerFirstname: new FormControl(null, [Validators.required]),
      drawerLastname: new FormControl(null, [Validators.required]),
    });
  }

  toggleDrawerValidators(isCompany: boolean): void {
    if (isCompany) {
      this.drawer.setValidators(Validators.required);
      this.drawerFirstname.clearValidators();
      this.drawerLastname.clearValidators();
    } else {
      this.drawerFirstname.setValidators(Validators.required);
      this.drawerLastname.setValidators(Validators.required);
      this.drawer.clearValidators();
    }
    this.drawerFirstname.updateValueAndValidity();
    this.drawerLastname.updateValueAndValidity();
    this.drawer.updateValueAndValidity();
  }

  resetDrawerValues(isCompany: boolean): void {
    if (isCompany) {
      this.drawerFirstname.reset();
      this.drawerLastname.reset();
    } else {
      this.drawer.reset();
    }
    this.drawerFirstname.updateValueAndValidity();
    this.drawerLastname.updateValueAndValidity();
    this.drawer.updateValueAndValidity();
  }

  toCheck(): Check {
    return new Check(
      this.cmc7.value as string,
      this.drawerType.value as string,
      this.amount.value as string,
      this.rlmcKey.value as string,
      this.drawer.value as string | undefined,
      this.drawerFirstname.value as string | undefined,
      this.drawerLastname.value as string | undefined,
    );
  }
}
