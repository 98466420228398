import { Directive, inject } from '@angular/core';
import { NgUtils } from '@manakincubber/tiime-utils';
import { Observable, Subscription } from 'rxjs';

import {
  PaginationData,
  PaginationRange,
  PaginatorService,
} from '../paginator';
import { TableState } from './table-state.enum';

@Directive()
export abstract class AbstractTableDirective<T> {
  currentState: TableState;
  isInitialized: boolean;
  isLoading: boolean;
  readonly TableState = TableState;
  readonly trackById = NgUtils.trackById;

  protected readonly paginatorService = inject(PaginatorService);

  paginationData: PaginationData<T> = new PaginationData<T>(
    [] as T[],
    this.savedPaginationRange,
  );

  /**
   * Executes the Observable returned by `getTableData()`. Must be unsubscribed
   * after the selected company change, then subscribed again the same way it was
   * at initialization.
   */
  protected tableSubscription: Subscription;

  protected get defaultPaginationRange(): PaginationRange {
    return new PaginationRange();
  }

  protected get savedPaginationRange(): PaginationRange {
    return (
      this.paginatorService.getLocalPreferences(this.constructor.name) ||
      this.defaultPaginationRange
    );
  }

  setState(newState: TableState): void {
    this.currentState = newState;
  }

  /**
   * This Observable should be subscribed to at table initialization and after the selected company has changed.
   * ```
   * this.tableSubscription = this.initTableObservable().subscribe();
   * ```
   * As we're not relying on route params changes to update table views, this Observable
   * should rely on `ActivatedRoute.snapshot.queryParams` instead of `ActivatedRoute.queryParams`
   * to ensure a proper behavior. Furthermore, this simplifies the code.
   */
  protected abstract initTableObservable(): Observable<PaginationData<T>>;

  /**
   * Each list should implement a `getTableData()` function that will be called at
   * initialization and via the `reloadSubject$`.
   *
   * This function should:
   * - call the api
   * - update `paginationData` and `currentState`
   * - update `isLoading` and call `cdr.markForCheck` if needed
   * - potentially save query params
   *
   * This function should not:
   * - update `getOptions` properties => this should be done before calling `reloadSubject$.next()`
   */
  protected abstract getTableData(): Observable<PaginationData<T>>;
}
