<app-expenses-report-editor-header-container
  title="Frais à rembourser"
  [isAdvancedExpenseHeader]="true"
>
  <app-document-editor-table-header
    [columns]="columns"
    [color]="color"
  ></app-document-editor-table-header>
</app-expenses-report-editor-header-container>

<ng-container
  *ngIf="
    expenseReportForm.advancedExpenses.controls.length > 0;
    else linesEmptyState
  "
>
  <ng-container
    *ngFor="
      let expense of expenseReportForm.advancedExpenses.controls;
      trackBy: trackById
    "
  >
    <app-expenses-report-editor-line
      *ngIf="
        currentEditorStatus !== ExpensesReportEditorStatus.Preview ||
        expense.checked.value
      "
      [editorLineForm]="expense"
      [disabled]="disableLines"
      [cellDefinitions]="cellDefinitions"
      (lineChecked)="
        addOrRemoveLineFromExpenseToImport(expense.line.value, $event)
      "
      (lineClicked)="openPreview(expense.line.value, $event)"
    ></app-expenses-report-editor-line>
  </ng-container>

  <ng-container [ngTemplateOutlet]="createAdvancedExpenseButton"></ng-container>

  <div class="expenses-total-container">
    <div class="expenses-total">
      <app-expenses-report-editor-total
        *tiimeLet="
          expenseReportForm.advancedExpenses.value
            | computeAdvancedExpensesAmount as amounts
        "
        totalWording="Total"
        totalType="currency"
        [subTotal]="
          currentEditorStatus | getAmount : metadata : amounts.vatAmount : true
        "
        [total]="currentEditorStatus | getAmount : metadata : amounts.amount"
      ></app-expenses-report-editor-total>
    </div>
  </div>
</ng-container>

<ng-template #linesEmptyState>
  <app-expenses-report-editor-empty-state
    *ngIf="(loading$ | async) === false; else placeholderTemplate"
    [expenseReportType]="ExpenseReportType.AdvancedExpense"
  ></app-expenses-report-editor-empty-state>

  <ng-template #placeholderTemplate>
    <app-expenses-report-editor-table-skeleton></app-expenses-report-editor-table-skeleton>
  </ng-template>

  <ng-container [ngTemplateOutlet]="createAdvancedExpenseButton"></ng-container>
</ng-template>

<ng-template #createAdvancedExpenseButton>
  <button
    *ngIf="currentEditorStatus !== ExpensesReportEditorStatus.Preview"
    class="create-advanced-expense-button"
    neutral
    inverted
    tiime-dropdown-button
    (click)="openReceiptsImportDialog()"
  >
    <span label>Ajouter un justificatif</span>
    <button (click)="createAdvancedExpense()">
      Créer un frais à rembourser
    </button>
  </button>
</ng-template>
