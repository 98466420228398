import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';

import { TiimeButtonModule } from '../button/index';
import { TiimeIconModule } from '../icon/index';
import { TiimeTooltipModule } from '../tooltip/index';
import { TiimeSidenavItemComponent } from './sidenav-item.component';
import { TiimeSidenavLayoutComponent } from './sidenav-layout.component';
import { TiimeSidenavMenuComponent } from './sidenav-menu/sidenav-menu.component';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    TiimeIconModule,
    TiimeButtonModule,
    TiimeTooltipModule,
  ],
  declarations: [
    TiimeSidenavLayoutComponent,
    TiimeSidenavItemComponent,
    TiimeSidenavMenuComponent,
  ],
  exports: [
    TiimeSidenavLayoutComponent,
    TiimeSidenavItemComponent,
    TiimeSidenavMenuComponent,
  ],
})
export class TiimeSidenavLayoutModule {}
