import { Pipe, PipeTransform } from '@angular/core';
import { RegexUtils, IbanPipe } from '@manakincubber/tiime-utils';

@Pipe({
  standalone: true,
  name: 'offuscatedIban',
})
export class OffuscatedIbanPipe implements PipeTransform {
  transform(value: string | null): string | null {
    value = value?.replace(/\s/g, '');
    if (!value) {
      return null;
    }
    if (!this.isSupportedValue(value)) {
      throw Error(`InvalidPipeArgument: '${value}' for OffuscatedIbanPipe`);
    }
    const iban = new IbanPipe().transform(value);
    return `${iban.slice(0, 10)} ••• ••• ${iban.slice(-3)}`;
  }

  private isSupportedValue(value: unknown): boolean {
    return typeof value === 'string' && RegexUtils.ibanRegex.test(value);
  }
}
