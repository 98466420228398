import { MatSortable, Sort } from '@angular/material/sort';

export class SortHelper {
  static fromQueryParamsToMatSortable(sortParam: string): MatSortable {
    const sort: Sort = SortHelper.fromQueryParamsToSort(sortParam);
    return {
      id: sort.active,
      start: sort.direction as 'asc' | 'desc',
      disableClear: false,
    };
  }

  static fromQueryParamsToSort(sortParam: string): Sort {
    const sortInfo: string[] = sortParam.split(':');
    const direction: 'asc' | 'desc' = sortInfo[1] as 'asc' | 'desc';
    return {
      active: sortInfo[0],
      direction,
    };
  }

  static toStringParam(sort: Sort): string | null {
    return sort.direction ? `${sort.active}:${sort.direction}` : null;
  }
}
