import { TrackCardStep } from './track-card-step';
import { TrackWalletStep } from './track-wallet-step';
import { WalletCardInUse, WalletCardInUseApiContract } from './wallet';

export interface HomeWalletApiContract {
  bank_account_wallet?: unknown;
  fatca_pending: boolean;
  transfer_activated: boolean;
  order_card: boolean;
  use_card: boolean;
  track_card_steps: unknown[] | undefined;
  track_wallet_step: TrackWalletStep | undefined;
  can_add_promo_code: boolean;
  latest_card: WalletCardInUseApiContract | undefined;
  wallet_company_cards_list_activated: boolean;
  first_transfer: boolean;
}

export class HomeWallet {
  constructor(
    public bankAccountWallet?: WalletBankAccount,
    public fatcaPending?: boolean,
    public transferActivated?: boolean,
    public orderCard?: boolean,
    public useCard?: boolean,
    public trackCardSteps?: TrackCardStep[],
    public trackWalletStep?: TrackWalletStep,
    public canAddPromoCode?: boolean,
    public cardInUse?: WalletCardInUse,
    public companyCardsListActivated?: boolean,
    public firstTransfer?: boolean,
  ) {}

  static fromJson(json: HomeWalletApiContract): HomeWallet {
    return new HomeWallet(
      json.bank_account_wallet
        ? WalletBankAccount.fromJson(json.bank_account_wallet)
        : undefined,
      json.fatca_pending,
      json.transfer_activated,
      json.order_card,
      json.use_card,
      json.track_card_steps
        ? json.track_card_steps.map(TrackCardStep.fromJson)
        : undefined,
      json.track_wallet_step,
      json.can_add_promo_code,
      json.latest_card ? WalletCardInUse.fromJson(json.latest_card) : undefined,
      json.wallet_company_cards_list_activated,
      json.first_transfer,
    );
  }

  static toJson(homeWallet: HomeWallet): any {
    return {
      bank_account_wallet: homeWallet.bankAccountWallet,
      fatca_pending: homeWallet.fatcaPending,
      transfer_activated: homeWallet.transferActivated,
      order_card: homeWallet.orderCard,
    };
  }
}

class WalletBankAccount {
  constructor(
    public id?: string,
    public iban?: string,
    public bic?: string,
    public closed?: boolean,
  ) {}

  static fromJson(json: any): WalletBankAccount {
    return new WalletBankAccount(json.id, json.iban, json.bic, json.closed);
  }
}
