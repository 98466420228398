export enum StandardDocumentCategory {
  receipt = 'Justificatifs',
  advanced_expense = 'Frais à rembourser',
  expense_report = 'Notes de frais Tiime',
  kilometric_allowance = 'Indemnités kilométriques',
  sales_invoice = 'Factures de vente',
  fiscal = 'Fiscal',
  legal = 'Juridique',
  social_employee = 'Social salariés',
  social_director = 'Social dirigeants',
  payslip = 'Bulletins de paie',
  bank_statement = 'Relevés bancaires',
  annual_account = 'Comptes annuels',
  permanent_document = 'Documents permanents',
  other_document = 'Autres types de documents',
}

export enum StandardDocumentCategoryIdentifier {
  RECEIPT = 'receipt',
  ADVANCED_EXPENSE = 'advanced_expense',
  EXPENSE_REPORT = 'expense_report',
  MILEAGE_ALLOWANCE = 'mileage_allowance',
  FISCAL = 'fiscal',
  SOCIAL_EMPLOYEE = 'social_employee',
  JURIDICAL = 'juridical',
  SOCIAL_DIRECTOR = 'social_director',
  ANNUAL_ACCOUNT = 'annual_account',
  BANK_STATEMENT = 'bank_statement',
  PAYSLIP = 'payslip',
  PERMANENT_DOCUMENT = 'permanent_document',
  OTHER_DOCUMENT = 'other_document',
  EXTERNAL_INVOICE = 'external_invoice',
}
