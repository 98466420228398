import { Injectable } from '@angular/core';

import {
  createScopedSidePanelService,
  SidePanelConfig,
  SidePanelRef,
} from '@services/side-panel';

import { BankTransferSidePanelData } from './bank-transfer-side-panel-data';
import { BankTransferSidePanelComponent } from './bank-transfer-side-panel.component';

@Injectable({ providedIn: 'root' })
export class BankTransferSidePanelService extends createScopedSidePanelService<
  BankTransferSidePanelComponent,
  BankTransferSidePanelData
>(BankTransferSidePanelComponent) {
  override open(
    config: SidePanelConfig<BankTransferSidePanelData> &
      Required<Pick<SidePanelConfig<BankTransferSidePanelData>, 'data'>>,
  ): SidePanelRef<BankTransferSidePanelComponent> {
    return super.open({
      width: '512px',
      backdropClass: 'cdk-overlay-transparent-backdrop',
      autoFocus: 'first-tabbable',
      ...config,
    });
  }
}
