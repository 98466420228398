import { TrackingEvent } from '@manakincubber/tiime-tracking';

import { AppliedOnType } from './add-tag-button-clicked';

export const TAG_ADDED = 'Tag Added';

export class TagAdded implements TrackingEvent {
  readonly name = TAG_ADDED;

  constructor(readonly appliedOn: AppliedOnType) {}
}
