import { ElementRef, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { OverlayPosition, TiimeOverlayService } from 'tiime-components';

import { filterNotNullOrUndefined } from '@core/helpers';

import { getLabelOrVentilationsOverlayConfig } from '../overlay-options';
import { LabelSelectionOverlayComponent } from './label-selection-overlay.component';
import {
  LabelSelectionOverlayData,
  LabelSelectionOverlayResponse,
} from './types';

@Injectable({
  providedIn: 'root',
})
export class LabelSelectionOverlayService {
  constructor(private readonly overlayService: TiimeOverlayService) {}

  openLabelSelectionOverlay(
    options: LabelSelectionOverlayData,
    ref: ElementRef,
    position?: OverlayPosition,
  ): Observable<LabelSelectionOverlayResponse> {
    return this.overlayService
      .open<
        LabelSelectionOverlayComponent,
        LabelSelectionOverlayData,
        LabelSelectionOverlayResponse
      >(
        LabelSelectionOverlayComponent,
        options,
        getLabelOrVentilationsOverlayConfig(ref, position),
      )
      .beforeClosed()
      .pipe(filterNotNullOrUndefined());
  }
}
