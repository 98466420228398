import { ENTER } from '@angular/cdk/keycodes';
import { ComponentType } from '@angular/cdk/overlay';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  Output,
  QueryList,
  ViewChild,
  ViewChildren,
  ViewEncapsulation,
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { NgUtils } from '@manakincubber/tiime-utils';
import { tap } from 'rxjs';

import { HasAttributesBase } from '../core/index';
import { AndFilter, Filter, MonoValueFilter, OrFilter } from '../get-options';
import { TiimeOverlayRef, TiimeOverlayService } from '../overlay';
import { FilterListOverlayComponent } from './components/filter-list-overlay/filter-list-overlay.component';
import { ChipFilter } from './models/chip-filter';
import { ComplexSearchBarFilter } from './models/complex-search-bar-filter';

@Component({
  selector: 'tiime-complex-search-bar',
  templateUrl: './complex-search-bar.component.html',
  styleUrls: ['./complex-search-bar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class ComplexSearchBarComponent
  extends HasAttributesBase
  implements AfterViewInit
{
  @Input() placeholder: string;
  @Input() rulesOverlayComponent: ComponentType<unknown>;
  @Input() additionalFilterData: Map<string, unknown>;
  @Input() rulesOverlayComponentData = {};
  @Input() complexSearchBarFilters: ComplexSearchBarFilter[];
  @Input() disabled: boolean;
  @Input() viewportMargin = 2;
  @Input() offsetX: number;
  @Input() offsetY = 5;
  @Input() complexFilterKeys: string[];
  @Input() set paramFilters(value: Filter<string>[]) {
    if (!value) {
      return;
    }
    this.filters = [];
    value.forEach(v => this.createChipFilterFromFilterParams(v));
  }
  /**
   * Méthode permettant de transformer les filtres selon les besoins
   * On peut par exemple l'utiliser pour faire de la combinaison de filtre
   */
  @Input() transformFiltersFn?: (filters: ChipFilter[]) => ChipFilter[];

  @Output() readonly search = new EventEmitter<Filter<unknown>[]>();

  @ViewChild('chipList', { read: ElementRef })
  private readonly chipList: ElementRef<HTMLElement>;
  @ViewChild('seeAllFiltersButtonRef')
  private readonly seeAllFiltersButtonRef: ElementRef<HTMLElement>;
  @ViewChild('input')
  private readonly input: ElementRef<HTMLInputElement>;
  @ViewChildren('chip', { read: ElementRef })
  private readonly chips: QueryList<ElementRef<HTMLElement>>;

  filterControl = new FormControl<string | null>(null);
  filters: ChipFilter[] = [];
  displayedFilters: ChipFilter[] = [];
  private rulesOverlay: TiimeOverlayRef;
  private filterOverlay: TiimeOverlayRef | null;

  private readonly amountKeys = [
    'amount',
    'total_including_taxes',
    'amount_excluding_taxes',
  ];

  readonly separatorKeysCodes: number[] = [ENTER];
  readonly trackByIndex = NgUtils.trackByIndex;

  constructor(
    elementRef: ElementRef<HTMLElement>,
    private readonly cdr: ChangeDetectorRef,
    private readonly overlayService: TiimeOverlayService,
  ) {
    super(elementRef);
  }

  ngAfterViewInit(): void {
    this.computeVisibleChip();
  }

  addFilter(filter: string): void {
    const trimmedFilter = (filter || '')
      .split(':')
      .map(str =>
        str
          .trim()
          .normalize('NFD')
          .replace(/[\u0300-\u036f]/g, ''),
      )
      .join(':');

    if (trimmedFilter) {
      this.createFilter(trimmedFilter);
      this.resetFilterInput();
      this.closeRulesOverlay();
      this.emitSearch();
    }
  }

  clearFilters(emitSearch = true): void {
    this.filters = [];
    this.resetFilterInput();
    this.closeRulesOverlay();
    if (emitSearch) {
      this.emitSearch();
    }
    this.input.nativeElement.focus();
  }

  removeFilter(index: number): void {
    if (index !== -1) {
      const isOverlayOpen = !!this.filterOverlay;
      this.filterOverlay?.close();
      this.filters.splice(index, 1);
      this.emitSearch();
      if (isOverlayOpen) {
        this.openFilterListOverlay();
      }
    }
  }

  openRulesOverlay(): void {
    if (!this.filters.length) {
      this.rulesOverlay = this.overlayService.open(
        this.rulesOverlayComponent,
        this.rulesOverlayComponentData,
        {
          backdropClass: 'cdk-overlay-transparent-backdrop',
          maxHeight: '360px',
          maxWidth: '570px',
          hasBackdrop: true,
          backdropClose: true,
          connectTo: {
            origin: this.chipList,
            positions: [
              {
                originX: 'start',
                originY: 'bottom',
                overlayX: 'start',
                overlayY: 'top',
                offsetY: 5,
              },
            ],
          },
        },
      );
    }
  }

  openFilterListOverlay($event?: Event): void {
    $event?.stopPropagation();
    this.filterOverlay = this.overlayService.open<FilterListOverlayComponent>(
      FilterListOverlayComponent,
      {
        filters: this.filters,
        removeFilter: (index: number) => this.removeFilter(index),
      },
      {
        backdropClass: 'cdk-overlay-transparent-backdrop',
        maxHeight: '100%',
        maxWidth: '100%',
        hasBackdrop: true,
        backdropClose: true,
        connectTo: {
          origin: this.seeAllFiltersButtonRef,
          positions: [
            {
              originX: 'end',
              originY: 'center',
              overlayX: 'start',
              overlayY: 'center',
              offsetX: 10,
              offsetY: -1,
            },
          ],
        },
      },
    );
    this.filterOverlay
      .afterClosed()
      .pipe(tap(() => (this.filterOverlay = null)))
      .subscribe();
  }

  private createChipFilterFromFilterParams(filter: Filter<string>): void {
    // on recupère les complexFilter correspondant au filtre passé en param
    const complexSearchBarFilter = this.complexSearchBarFilters.filter(
      cFilter => cFilter.apiFilterKey === filter.key,
    );
    if (complexSearchBarFilter.length) {
      let filterValues: string[] = [];
      //ie. filter est MonoValueFilter, et peut avoir par exemple {values: "true,false"} donc on split pour recuperer chaque valeur
      if (
        filter.separator === '' &&
        filter.values.length === 1 &&
        !filter.values[0].match(/^-?(\d*[.,])?\d+$/)
      ) {
        filterValues = filter.values[0].split(',');
      } else {
        filterValues = [...filter.values];
      }
      complexSearchBarFilter.forEach(cFilter => {
        filterValues.forEach(v => {
          // on match le filtre avec le complexFilter
          if (this.isFilterMatch(cFilter, v)) {
            this.addFilterToFilterList(cFilter, v);
          }
        });
      });
      this.transformFilters();
    } else {
      filter.values.forEach(v => {
        v = this.isEqualAmount(filter.key, v) ? `=${filter.values[0]}` : v;
        this.createFilter(v);
      });
    }
    this.computeVisibleChip();
  }

  private isFilterMatch(
    filter: ComplexSearchBarFilter,
    value: string,
  ): boolean {
    if (filter.apiFilterIdentifier || filter.apiFilterIdentifier === '') {
      const extractedValue = value.substring(
        filter.apiFilterIdentifier.length,
        value.length,
      );
      return !!(
        value.toLowerCase().startsWith(filter.apiFilterIdentifier) &&
        filter.regex.exec(extractedValue.toLowerCase())
      );
    }

    return !filter.apiFilterValues || filter.apiFilterValues.includes(value);
  }

  private createFilter(value: string): void {
    let extractedValue = '';
    const complexSearchBarFilter = this.complexSearchBarFilters.find(
      (filter: ComplexSearchBarFilter) => {
        extractedValue = value.substring(
          filter.identifier.length,
          value.length,
        );
        return (
          value.toLowerCase().startsWith(filter.identifier) &&
          filter.regex.exec(extractedValue.toLowerCase())
        );
      },
    );
    if (complexSearchBarFilter) {
      this.addFilterToFilterList(complexSearchBarFilter, extractedValue);
      this.transformFilters();
    }
  }

  private addFilterToFilterList(
    complexSearchBarFilter: ComplexSearchBarFilter,
    extractedValue: string,
  ): void {
    const filter: ChipFilter = {
      displayedValue: complexSearchBarFilter.displayedValue(
        extractedValue,
        this.additionalFilterData?.get(
          complexSearchBarFilter.apiFilterKey || '',
        ),
      ),
      apiFilter: complexSearchBarFilter.apiFilter(
        extractedValue,
        this.additionalFilterData?.get(
          complexSearchBarFilter.apiFilterKey || '',
        ),
      ),
    };

    if (filter.apiFilter.values[0] !== undefined) {
      this.filters.push(filter);
    }
  }

  private transformFilters(): void {
    if (
      this.transformFiltersFn &&
      typeof this.transformFiltersFn === 'function'
    ) {
      this.filters = this.transformFiltersFn(this.filters);
    }
  }

  private emitSearch(): void {
    const complexSearchBarApiFilters: Filter<unknown>[] =
      this.groupMonoValueFilters(this.filters)
        .map((filterValue: ChipFilter) => filterValue.apiFilter)
        .filter(apiFilter => apiFilter.values[0]);
    this.search.emit(complexSearchBarApiFilters);
  }

  /**
   * Permet de transformer plusieurs MonoValueFilter avec la même clé en AndFilter ou OrFilter
   */
  private groupMonoValueFilters(filters: ChipFilter[]): ChipFilter[] {
    const map = new Map<string, ChipFilter>();

    filters.forEach(({ apiFilter, displayedValue }) => {
      const filterType =
        apiFilter instanceof OrFilter ||
        apiFilter.key === 'accountant_detail_request'
          ? OrFilter
          : AndFilter;
      // check if we already have a AndFilter with the corresponding key
      if (map.has(apiFilter.key)) {
        // if yes we 'concat' values
        const existingFilter = map.get(apiFilter.key);
        if (existingFilter) {
          const values = [
            ...existingFilter.apiFilter.values,
            ...apiFilter.values,
          ];
          existingFilter.apiFilter = new filterType(apiFilter.key, values);
        }
      } else {
        const newFilter = new filterType(apiFilter.key, apiFilter.values);

        map.set(apiFilter.key, {
          displayedValue: displayedValue,
          apiFilter: newFilter,
        });
      }
    });

    //convert AndFilter with single values to MonoValueFilter
    const result = Array.from(map.values()).map(filter => {
      if (filter.apiFilter.values.length <= 1) {
        return {
          displayedValue: filter.displayedValue,
          apiFilter: new MonoValueFilter(
            filter.apiFilter.key,
            filter.apiFilter.values[0],
          ),
        };
      }
      return filter;
    });
    return result;
  }

  private resetFilterInput(): void {
    setTimeout(() => this.filterControl.setValue(''));
  }

  private computeVisibleChip(): void {
    if (!this.chipList) {
      return;
    }

    const searchBarWidth = this.chipList.nativeElement.clientWidth;
    let totalChipsWidth = 0;
    this.chips.forEach(chip => {
      const updatedTotalChipsWidth =
        totalChipsWidth + chip.nativeElement.clientWidth;
      if (searchBarWidth > updatedTotalChipsWidth) {
        totalChipsWidth = updatedTotalChipsWidth;
      }
    });
    this.cdr.markForCheck();
  }

  private closeRulesOverlay(): void {
    if (this.rulesOverlay) {
      this.rulesOverlay.close();
    }
  }

  // Check for illegal equal param
  private isEqualAmount(key: string, value: string): boolean {
    return this.amountKeys.includes(key) && /^\d$/.test(value.substring(0, 1));
  }
}
