import { ErrorHandler, Injectable } from '@angular/core';

import { SentryService } from './sentry/sentry.service';

function isErrorOrErrorEvent(error: any) {
  return (
    Object.prototype.toString.call(error) === '[object Error]' ||
    Object.prototype.toString.call(error) === '[object ErrorEvent]'
  );
}

@Injectable()
export class GlobalErrorHandler implements ErrorHandler {
  constructor(private sentryService: SentryService) {}

  handleError(error: any) {
    error = error.originalError || error;

    if (isErrorOrErrorEvent(error)) {
      this.sentryService.captureException(error);
    } else {
      console.error(error);
    }
  }
}
