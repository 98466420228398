import { CardDesign, CardState } from '@enums';

import {
  CardDeliveryAddress,
  CardDeliveryAddressApiContract,
} from './card-delivery-address';

export interface CardApiContract {
  atm: boolean;
  card_design: CardDesign;
  embossed_name: string;
  expiry_date: string;
  foreign: boolean;
  id: number;
  limit_atm_week: number;
  limit_payment_week: number;
  masked_pan: string;
  max_limit_atm_week: number;
  max_limit_payment_week: number;
  nfc: boolean;
  online: boolean;
  state: CardState;
  will_expire_soon: boolean;
  total_atm_week: number;
  total_payment_week: number;
  delivery_address?: CardDeliveryAddressApiContract;
}

export class Card {
  constructor(
    public id?: number,
    public atm?: boolean,
    public cardDesign?: CardDesign,
    public embossedName?: string,
    public expiryDate?: string,
    public foreign?: boolean,
    public maskedPan?: string,
    public nfc?: boolean,
    public online?: boolean,
    public state?: CardState,
    public willExpireSoon?: boolean,
    public totalAtmWeek?: number,
    public limitAtmWeek?: number,
    public totalPaymentWeek?: number,
    public limitPaymentWeek?: number,
    public maxLimitPaymentWeek?: number,
    public deliveryAddress?: CardDeliveryAddress,
  ) {}

  static fromJson(json: CardApiContract): Card {
    return new Card(
      json.id,
      json.atm,
      json.card_design,
      json.embossed_name,
      json.expiry_date,
      json.foreign,
      json.masked_pan,
      json.nfc,
      json.online,
      json.state,
      json.will_expire_soon,
      json.total_atm_week,
      json.limit_atm_week,
      json.total_payment_week,
      json.limit_payment_week,
      json.max_limit_payment_week,
      json.delivery_address
        ? CardDeliveryAddress.fromJson(json.delivery_address)
        : undefined,
    );
  }

  static toJson(
    card: Card,
  ): Pick<CardApiContract, 'id' | 'atm' | 'foreign' | 'nfc' | 'online'> {
    return {
      id: card.id,
      atm: card.atm,
      foreign: card.foreign,
      nfc: card.nfc,
      online: card.online,
    };
  }
}

export interface UpdateCardPinBody {
  new_pin: string;
  confirm_pin: string;
}
