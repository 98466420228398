import { Directive, Input, TemplateRef, ViewContainerRef } from '@angular/core';

import { TiimeLetDirectiveContext } from './let-context';

/**
 * @deprecated Use the Angular @let syntax instead.
 */
@Directive({
  standalone: true,
  selector: '[tiimeLet]',
})
export class TiimeLetDirective<T> {
  @Input() tiimeLet!: T;

  constructor(
    viewContainer: ViewContainerRef,
    templateRef: TemplateRef<TiimeLetDirectiveContext<T>>,
  ) {
    viewContainer.createEmbeddedView(
      templateRef,
      new TiimeLetDirectiveContext<T>(this),
    );
  }

  static ngTemplateContextGuard<T>(
    _directive: TiimeLetDirective<T>,
    _context: any,
  ): _context is TiimeLetDirective<T> {
    return true;
  }
}
