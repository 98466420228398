export interface AbstractLineApiContract {
  id: number;
  description: string;
  sequence: number;
}

export abstract class AbstractLine {
  constructor(
    public id: number,
    public description: string,
    public sequence: number,
  ) {}
}
