import { TrackingEvent } from '@manakincubber/tiime-tracking';

import { Quote } from '@models';

export const QUOTE_SAVED = 'Quote Saved';

export class QuoteSaved implements TrackingEvent {
  readonly name = QUOTE_SAVED;

  constructor(readonly quote: Quote) {}
}
