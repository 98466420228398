import { Pipe, PipeTransform } from '@angular/core';
import { DefaultGetOptions, Filter } from 'tiime-components';

@Pipe({
  name: 'isFilterActive',
})
export class IsFilterActivePipe implements PipeTransform {
  transform(
    options: DefaultGetOptions,
    filterKey: string,
    sortKey?: string,
  ): boolean {
    return (
      (sortKey ? options.sort?.active === sortKey : false) ||
      options.filters?.some(({ key }: Filter<unknown>) => key === filterKey)
    );
  }
}
