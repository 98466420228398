import { Pipe, PipeTransform } from '@angular/core';

import { OperationType } from '@enums';
import { BankTransaction } from '@models/bank-transaction';
import {
  LinkedEntityBankTransaction,
  LinkedEntityImputation,
} from '@models/linked-entities';

import { MatchedItem } from '../../../../types/matched-item';

@Pipe({
  name: 'toOperationType',
  standalone: true,
})
export class ToOperationTypePipe implements PipeTransform {
  transform(item: MatchedItem): OperationType | null | undefined {
    if (
      item instanceof LinkedEntityBankTransaction ||
      item instanceof LinkedEntityImputation
    ) {
      return item.value.operationType;
    }
    if (item instanceof BankTransaction) {
      return item.operationType;
    }
    return null;
  }
}
