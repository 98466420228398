<ng-container
  *tiimeLet="options | selectedOption : value : compareWith as option"
>
  <div
    class="dropdown-toggle themed"
    data-cy="select__dropdown-toggle"
    #dropdownToggle
    [class.disabled]="disabled"
    [class.with-image]="option?.image || option?.icon"
    [attr.aria-owns]="isDropdownOpen ? id + '-panel' : null"
    (click)="toggleDropdown()"
  >
    <div class="content-container" #customLabel>
      <ng-content></ng-content>
    </div>
    <label
      *ngIf="!customLabel.children.length && empty"
      class="placeholder"
      data-cy="select__txt__empty-placeholder"
    >
      {{ placeholder }}
    </label>
    <label *ngIf="!customLabel.children.length && !empty">
      <mat-icon *ngIf="option?.icon" [svgIcon]="option?.icon"></mat-icon>
      <img
        *ngIf="option?.image"
        alt=""
        data-cy="select__img__option-image"
        [src]="option.image"
      />
      <span
        class="label-text"
        tiimeTooltip
        tooltipPosition="bottom"
        hideWithoutEllipsis
        data-cy="select__txt__option-label"
        [content]="optionLabel"
      >
        <ng-container *ngTemplateOutlet="optionLabel"></ng-container>
      </span>
      <ng-template #optionLabel>
        {{ option?.label | translate }}
      </ng-template>
    </label>
    <div
      *ngIf="!disabled && !icon"
      class="select-arrow themed"
      data-cy="select__dropdown-arrow"
      [class.upside-down]="isDropdownOpen"
    ></div>
    <mat-icon
      *ngIf="icon"
      class="select-arrow-icon"
      [ngClass]="icon"
      [class.upside-down]="isDropdownOpen"
      [svgIcon]="icon"
    ></mat-icon>
  </div>
</ng-container>
