import { FormControl, FormGroup, Validators } from '@angular/forms';
import moment from 'moment';
import { AndFilter, DefaultGetOptions } from 'tiime-components';

import { DateHelper } from '@core/helpers/date.helper';

export class RegistryExportPeriodForm extends FormGroup {
  get isAllRegistries(): FormControl<boolean> {
    return this.get('isAllRegistries') as FormControl<boolean>;
  }
  get dateFrom(): FormControl<Date> {
    return this.get('dateFrom') as FormControl<Date>;
  }
  get dateTo(): FormControl<Date> {
    return this.get('dateTo') as FormControl<Date>;
  }

  constructor() {
    super({
      isAllRegistries: new FormControl(false, Validators.required),
      dateFrom: new FormControl(moment().startOf('year'), Validators.required),
      dateTo: new FormControl(new Date(), Validators.required),
    });
  }

  toExportOptions(): DefaultGetOptions {
    if (this.isAllRegistries.value) {
      return {};
    }

    return {
      filters: [
        new AndFilter('date', [
          `>=${DateHelper.format(this.dateFrom.value)}`,
          `<=${DateHelper.format(this.dateTo.value)}`,
        ]),
      ],
    };
  }
}
