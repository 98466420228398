import { formatDate } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';

import {
  Document,
  Invoice,
  InvoiceListItem,
  LinkedBankTransaction,
} from '@models';
import {
  BankTransaction,
  BankTransactionImputation,
} from '@models/bank-transaction';
import { LinkedEntity } from '@models/linked-entities';

import { DocumentDatePipe } from '../../../../../../../document/document-shared/pipes/document-date.pipe';
import { MatchedItem } from '../../../../types/matched-item';

@Pipe({
  name: 'matchedItemDate',
  standalone: true,
})
export class MatchedItemDatePipe implements PipeTransform {
  transform(item: MatchedItem): string | null {
    let date: string | number | Date;
    if (
      item instanceof BankTransaction ||
      item instanceof BankTransactionImputation ||
      item instanceof LinkedBankTransaction
    ) {
      date = item.realizationDate;
    } else if (item instanceof LinkedEntity) {
      date = item.value.date;
    } else if (item instanceof Document) {
      const documentDatePipe = new DocumentDatePipe();
      date = documentDatePipe.transform(item.metadata);
    } else if (item instanceof Invoice || item instanceof InvoiceListItem) {
      date = item.emissionDate;
    } else {
      throw new Error('Unhandled matched item type.');
    }

    if (!date) {
      return null;
    }
    date = new Date(date);
    return (
      date &&
      formatDate(
        date,
        date.getFullYear() === new Date().getFullYear()
          ? 'dd MMM'
          : 'dd MMM yyyy',
        'fr',
      )
    );
  }
}
