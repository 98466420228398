import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

import { StandardDocumentCategoryIdentifier } from '@enums';
import { ExpenseReportType } from '@models';
import { IconsModule } from '@shared';

import { CategoryIconComponent } from '../../../../../../../shared/components/category-icon/category-icon.component';
import { ExpenseReportEditorEmptyStateIllusPipe } from './expense-report-editor-empty-state-illus.pipe';

@Component({
  standalone: true,
  imports: [
    CommonModule,
    CategoryIconComponent,
    IconsModule,
    ExpenseReportEditorEmptyStateIllusPipe,
  ],
  selector: 'app-expenses-report-editor-empty-state',
  templateUrl: './expenses-report-editor-empty-state.component.html',
  styleUrls: ['./expenses-report-editor-empty-state.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ExpensesReportEditorEmptyStateComponent {
  readonly StandardDocumentCategoryIdentifier =
    StandardDocumentCategoryIdentifier;

  readonly ExpenseReportType = ExpenseReportType;

  @Input() expenseReportType: ExpenseReportType;
}
