<div
  class="complex-search-bar-wrapper"
>
  <div class="complex-search-bar-icn search-icon" #origin>
    <mat-icon *ngIf="!filters.length" svgIcon="tc_icon-search"></mat-icon>
  </div>
  <mat-chip-grid #chipList [class.with-icn]="!filters.length">
    <mat-basic-chip
      *ngFor="let filter of filters; let i = index; trackBy: trackByIndex"
      #chip
      (removed)="removeFilter(i)"
    >
      <tiime-filter-chip
        [filter]="filter"
        (remove)="removeFilter(i)"
      ></tiime-filter-chip>
    </mat-basic-chip>
    <input
      class="complex-search-bar"
      autocomplete="off"
      #input
      [placeholder]="!filters.length ? placeholder : ''"
      [formControl]="filterControl"
      [matChipInputFor]="chipList"
      [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
      (focus)="openRulesOverlay()"
      (matChipInputTokenEnd)="addFilter($event.value)"
    />
  </mat-chip-grid>
  <div
    *ngIf="filters.length > 0"
    class="complex-search-bar-icn clear-icon"
    (click)="clearFilters()"
  >
    <mat-icon svgIcon="tc_icon-cross-slim"></mat-icon>
  </div>
  <button
    *ngIf="filters.length"
    class="see-all-filters"
    #seeAllFiltersButtonRef
    (click)="openFilterListOverlay($event)"
  >
    {{ filters.length }}
  </button>
</div>
