import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  OnInit,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { MatMenuTrigger } from '@angular/material/menu';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { fromEvent, map } from 'rxjs';

import { ButtonComponent } from '../button';

@UntilDestroy()
@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: '[tiime-dropdown-button]',
  templateUrl: './dropdown-button.component.html',
  styleUrls: ['./dropdown-button.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DropdownButtonComponent extends ButtonComponent implements OnInit {
  @ViewChild('dropdown')
  private readonly dropdownRef: ElementRef<HTMLButtonElement>;
  @ViewChild('menuTrigger') private readonly menuTrigger: MatMenuTrigger;

  get hostWidth(): number {
    return this.elementRef.nativeElement.offsetWidth;
  }

  ngOnInit(): void {
    fromEvent(this.elementRef.nativeElement, 'click', { capture: true })
      .pipe(
        map((e: PointerEvent) => {
          const targets = [
            e.target,
            (e.target as HTMLButtonElement).parentNode,
          ];
          if (targets.includes(this.dropdownRef.nativeElement)) {
            e.stopPropagation();
            this.menuTrigger.openMenu();
          }
        }),
        untilDestroyed(this),
      )
      .subscribe();
  }
}
