import { Label } from '@models/labels';

import { StringHelper } from './string.helper';

export class LabelsHelper {
  static combineLabels(labels: Label[], standardLabels: Label[]): Label[] {
    if (!labels) {
      return standardLabels;
    }
    const combined = Array.from(labels);

    standardLabels.forEach(item => {
      const duplicate = labels.find(
        tp =>
          !tp.client && StringHelper.equalsWithoutAccents(tp.label, item.label),
      );
      if (!duplicate) {
        combined.push(item);
      }
    });

    return combined;
  }
}
