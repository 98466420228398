import { RequiredGetOptions } from 'tiime-components';

export const todoFilterKey = 'accountant_detail_request';

export function getTodoFilters(
  getOptions: RequiredGetOptions<'range'>,
): string[] {
  const accountantDetailRequestFilter = getOptions.filters?.find(
    filter => filter.key === todoFilterKey,
  );
  if (!accountantDetailRequestFilter) {
    return [];
  }
  return (accountantDetailRequestFilter.values as string[]).reduce(
    (acc, currentValue) => {
      if (currentValue.includes('|')) {
        const splitValues = currentValue.split('|');
        return [...acc, ...splitValues];
      } else {
        return [...acc, currentValue];
      }
    },
    [],
  ) as string[];
}
