import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  Input,
  ViewChild,
} from '@angular/core';

@Component({
  selector: 'tiime-google-maps',
  styleUrls: ['./google-maps.component.scss'],
  templateUrl: './google-maps.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GoogleMapsComponent implements AfterViewInit {
  @ViewChild('mapCanvas')
  private mapCanvas: ElementRef<HTMLDivElement>;

  @Input() set apiKey(apiKey: string) {
    this.mapsURL = `https://maps.googleapis.com/maps/api/js?key=${apiKey}&callback=initMap`;
  }

  @Input() set options(options: google.maps.MapOptions) {
    this._options = options;
    if (this.map) {
      this.map.setOptions(options);
    }
  }

  get options(): google.maps.MapOptions {
    return this._options;
  }

  @Input() set markers(markers: google.maps.Marker[]) {
    markers.forEach(marker => {
      if (marker.getPosition()) {
        marker.setMap(this.map);
      } else {
        marker.setMap(null);
      }
    });
    this._markers = markers;
  }

  get markers(): google.maps.Marker[] {
    return this._markers;
  }

  @Input() set directions(directions: google.maps.DirectionsRequest) {
    if (directions) {
      void this.directionsService.route(directions, (result, status) => {
        if (status === google.maps.DirectionsStatus.OK) {
          this.directionRender.setDirections(result);
        }
      });
      this.directionRender.setMap(this.map);
    } else {
      this.directionRender.setMap(null);
    }
    this._directions = directions;
  }

  get directions(): google.maps.DirectionsRequest {
    return this._directions;
  }

  private directionRender = new google.maps.DirectionsRenderer({
    suppressMarkers: true,
    polylineOptions: {
      strokeColor: '#ff7483',
    },
  });
  private directionsService = new google.maps.DirectionsService();

  private _options: google.maps.MapOptions;
  private _markers: google.maps.Marker[];
  private _directions: google.maps.DirectionsRequest;

  map: google.maps.Map;
  mapsURL: string;

  ngAfterViewInit(): void {
    this.map = new google.maps.Map(this.mapCanvas.nativeElement, this.options);
    if (this.markers) {
      this.markers.forEach(marker => marker.setMap(this.map));
    }
    this.directionRender.setMap(this.map);
  }
}
