import { Pipe, PipeTransform } from '@angular/core';

import { Document, DocumentPaymentStatusEnum, DocumentTypeEnum } from '@models';

import { BankTransferOverlayOptions } from '../components/documents-table/columns/actions/documents-actions-column-content/documents-actions-column-content.component';

@Pipe({
  name: 'canPayDocument',
})
export class CanPayDocumentPipe implements PipeTransform {
  transform(
    document: Document,
    kycValidated: boolean,
    options?: BankTransferOverlayOptions,
  ): boolean {
    const allowedDocumentTypes = [DocumentTypeEnum.Receipt];
    if (options?.toMyself) {
      allowedDocumentTypes.push(DocumentTypeEnum.ExpenseReport);
    }
    return (
      allowedDocumentTypes.includes(document.type) &&
      kycValidated &&
      !document.linkedEntities?.length &&
      document.paymentStatus === DocumentPaymentStatusEnum.PENDING
    );
  }
}
