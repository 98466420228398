import { Injectable } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { cloneDeep } from 'lodash-es';
import { BehaviorSubject, Observable, iif } from 'rxjs';
import { take, tap } from 'rxjs/operators';

import { PaginationData } from '../paginator';

@UntilDestroy()
@Injectable({ providedIn: 'root' })
export abstract class AdvancedTableCacheService<T> {
  private tableCache$ = new BehaviorSubject<PaginationData<T> | undefined>(
    undefined,
  );
  readonly tableCacheArgs$ = new BehaviorSubject<unknown[] | undefined>(
    undefined,
  );

  getTableCache(...args: unknown[]): Observable<PaginationData<T> | undefined> {
    return iif(
      () => JSON.stringify(this.tableCacheArgs$.value) !== JSON.stringify(args),
      this.getTableData(...args).pipe(
        tap(paginationData => {
          this.tableCacheArgs$.next(cloneDeep(args));
          this.tableCache$.next(paginationData);
        }),
      ),
      this.tableCache$.pipe(take(1)),
    ).pipe(untilDestroyed(this));
  }

  abstract getTableData(...args: unknown[]): Observable<PaginationData<T>>;

  resetTableCache(): void {
    this.tableCache$.next(undefined);
    this.tableCacheArgs$.next(undefined);
  }
}
