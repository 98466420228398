import {
  AndFilter,
  ComplexSearchBarFilter,
  Filter,
  MonoValueFilter,
} from 'tiime-components';

import { AmountType } from '@core/enum';
import { AccountantDetailRequestFilterValue } from '@core/enum/filters';
import { DateHelper } from '@core/helpers/date.helper';
import { RegexHelper } from '@core/helpers/regex.helper';
import { User } from '@core/models';
import { TransactionComplexFilterKey } from '@enums/transactions';

const dateRegex = RegexHelper.dateRegex;
const monthRegex = /^\d{2}[/-](\d{2}|\d{4})$/;
const floatRegex = RegexHelper.floatRegex;
const floatWithNegativeSignRegex = RegexHelper.floatWithNegativeSignRegex;
const emptyRegex = /^$/;
const stringRegex = /^(?!s*$).+/;
const labelRegex = /^label$/;
const commentRegex = /^commentaire$/;
const documentRegex = /^document$/;
const messageRegex = /^message$/;
const tagRegex = /^tag$/;

const dateFormats = ['DD/MM/YYYY', 'DD-MM-YY', 'YYYY-MM-DD'];

export enum TransactionComplexSearchBarFilterNameEnum {
  amountPositive = 'amountPositive',
  amountPositiveValue = 'amountPositiveValue',
  amountNegative = 'amountNegative',
  amountNegativeValue = 'amountNegativeValue',
  amountValue = 'amountValue',
  amountGreaterOrEqualThan = 'amountGreaterOrEqualThan',
  amountGreaterThan = 'amountGreaterThan',
  amountLowerOrEqualThan = 'amountLowerOrEqualThan',
  amountLowerThan = 'amountLowerThan',
  amountInterval = 'amountInterval',
  transactionDateInterval = 'transactionDateInterval',
  transactionDateValue = 'transactionDateValue',
  transactionDateMonthValue = 'transactionDateMonthValue',
  transactionDateGreaterOrEqualThan = 'transactionDateGreaterOrEqualThan',
  transactionDateGreaterThan = 'transactionDateGreaterThan',
  transactionDateLowerOrEqualThan = 'transactionDateLowerOrEqualThan',
  transactionDateLowerThan = 'transactionDateLowerThan',
  journalValue = 'journalValue',
  wordingValue = 'wordingValue',
  idValue = 'idValue',
  labelValue = 'labelValue',
  labelWith = 'labelWith',
  labelWithout = 'labelWithout',
  tagValue = 'tagValue',
  temporaryAccount = 'temporaryAccount',
  userValue = 'userValue',
  commentValue = 'commentValue',
  commentWith = 'commentWith',
  commentWithout = 'commentWithout',
  documentWith = 'documentWith',
  documentWithout = 'documentWithout',
  tagWith = 'tagWith',
  tagWithout = 'tagWithout',
  messageWith = 'messageWith',
  messageWithout = 'messageWithout',
  deleted = 'deleted',
  withDocumentRequest = 'withDocumentRequest',
  withInformationRequest = 'withInformationRequest',
  withMessageRequest = 'withMessageRequest',
  search = 'q',
}

/**
 * Filtres complexes de la liste des transactions
 * L'ordre est important
 */
export const TRANSACTION_COMPLEX_SEARCH_BAR_FILTERS: ComplexSearchBarFilter[] =
  [
    {
      name: TransactionComplexSearchBarFilterNameEnum.amountPositive,
      identifier: '+',
      regex: emptyRegex,
      displayedValue: (): string => `Positif`,
      apiFilter: () =>
        new MonoValueFilter(
          TransactionComplexFilterKey.amountType,
          AmountType.cashing,
        ),
      apiFilterKey: TransactionComplexFilterKey.amountType,
      apiFilterValues: [AmountType.cashing],
    },
    {
      name: TransactionComplexSearchBarFilterNameEnum.amountPositiveValue,
      identifier: '+',
      regex: floatRegex,
      displayedValue: (value: string): string =>
        `Égal à ${value.replace('.', ',')}€`,
      apiFilter: (value: string) =>
        new MonoValueFilter(
          TransactionComplexFilterKey.amount,
          value.replace(',', '.'),
        ),
    },
    {
      name: TransactionComplexSearchBarFilterNameEnum.amountNegative,
      identifier: '-',
      regex: emptyRegex,
      displayedValue: (): string => `Négatif`,
      apiFilter: () =>
        new MonoValueFilter(
          TransactionComplexFilterKey.amountType,
          AmountType.disbursements,
        ),
      apiFilterKey: TransactionComplexFilterKey.amountType,
      apiFilterValues: [AmountType.disbursements],
    },
    {
      name: TransactionComplexSearchBarFilterNameEnum.amountNegativeValue,
      identifier: '-',
      regex: floatRegex,
      displayedValue: (value: string): string =>
        `Égal à -${value.replace('.', ',')}€`,
      apiFilter: (value: string) =>
        new MonoValueFilter(
          TransactionComplexFilterKey.amount,
          `-${value.replace(',', '.')}`,
        ),
    },
    {
      name: TransactionComplexSearchBarFilterNameEnum.amountValue,
      identifier: '=',
      regex: floatWithNegativeSignRegex,
      displayedValue: (value: string): string =>
        `Égal à ${value.replace('.', ',')}€`,
      apiFilter: (value: string) =>
        new MonoValueFilter(
          TransactionComplexFilterKey.amount,
          value.replace(',', '.'),
        ),
    },
    {
      name: TransactionComplexSearchBarFilterNameEnum.amountGreaterOrEqualThan,
      identifier: '>=',
      regex: floatWithNegativeSignRegex,
      displayedValue: (value: string): string =>
        `Supérieur ou égal à ${value.replace('.', ',')}€`,
      apiFilter: (value: string) =>
        new MonoValueFilter(TransactionComplexFilterKey.amount, `>=${value}`),
    },
    {
      name: TransactionComplexSearchBarFilterNameEnum.amountGreaterThan,
      identifier: '>',
      regex: floatWithNegativeSignRegex,
      displayedValue: (value: string): string =>
        value === '0' ? 'Positif' : `Supérieur à ${value.replace('.', ',')}€`,
      apiFilter: (value: string) =>
        new MonoValueFilter(TransactionComplexFilterKey.amount, `>${value}`),
    },
    {
      name: TransactionComplexSearchBarFilterNameEnum.amountLowerOrEqualThan,
      identifier: '<=',
      regex: floatWithNegativeSignRegex,
      displayedValue: (value: string): string =>
        `Inférieur ou égal à ${value.replace('.', ',')}€`,
      apiFilter: (value: string) =>
        new MonoValueFilter(TransactionComplexFilterKey.amount, `<=${value}`),
    },
    {
      name: TransactionComplexSearchBarFilterNameEnum.amountLowerThan,
      identifier: '<',
      regex: floatWithNegativeSignRegex,
      displayedValue: (value: string): string =>
        value === '0' ? 'Négatif' : `Inférieur à ${value.replace('.', ',')}€`,
      apiFilter: (value: string) =>
        new MonoValueFilter(TransactionComplexFilterKey.amount, `<${value}`),
    },
    {
      name: TransactionComplexSearchBarFilterNameEnum.amountInterval,
      identifier: '',
      regex: RegexHelper.amountIntervalRegex,
      displayedValue: (value: string | string[]): string => {
        const filterArray = Array.isArray(value) ? value : value.split(',');
        const withoutSymbol = filterArray.map(v =>
          v
            .replace('>', '')
            .replace('<', '')
            .replace('=', '')
            .replace('.', ','),
        );
        return `Entre ${withoutSymbol[0]}€ et ${withoutSymbol[1]}€`;
      },
      apiFilter: (value: string | string[]): AndFilter<string> => {
        const filterArray = Array.isArray(value) ? value : value.split(',');
        const withoutSymbol = filterArray.map(v =>
          v
            .replace('>', '')
            .replace('<', '')
            .replace('=', '')
            .replace(',', '.'),
        );
        return new AndFilter(TransactionComplexFilterKey.amount, [
          `>=${withoutSymbol[0]}`,
          `<=${withoutSymbol[1]}`,
        ]);
      },
    },
    {
      name: TransactionComplexSearchBarFilterNameEnum.transactionDateInterval,
      identifier: '',
      regex: RegexHelper.dateIntervalRegex,
      displayedValue: (value: string | string[]): string => {
        const filterArray = Array.isArray(value) ? value : value.split(',');
        const withoutSymbol = filterArray.map(v =>
          v.replace('>', '').replace('<', '').replace('=', ''),
        );
        const formattedFromDate = DateHelper.format(
          DateHelper.toDate(withoutSymbol[0], dateFormats),
          'DD/MM/YYYY',
        );
        const formattedToDate = DateHelper.format(
          DateHelper.toDate(withoutSymbol[1], dateFormats),
          'DD/MM/YYYY',
        );
        return `Entre ${formattedFromDate} et ${formattedToDate}`;
      },
      apiFilter: (value: string | string[]): AndFilter<string> => {
        const filterArray = Array.isArray(value) ? value : value.split(',');
        const withoutSymbol = filterArray.map(v =>
          v.replace('>', '').replace('<', '').replace('=', ''),
        );
        return new AndFilter(TransactionComplexFilterKey.date, [
          `>=${withoutSymbol[0].replace(',', '.')}`,
          `<=${withoutSymbol[1].replace(',', '.')}`,
        ]);
      },
    },
    {
      name: TransactionComplexSearchBarFilterNameEnum.transactionDateLowerThan,
      identifier: '<',
      regex: dateRegex,
      displayedValue: (value: string): string =>
        `< ${DateHelper.format(
          DateHelper.toDate(value, dateFormats),
          'DD/MM/YYYY',
        )}`,
      apiFilter: (value: string): Filter<string> =>
        new MonoValueFilter(
          TransactionComplexFilterKey.date,
          `<${DateHelper.format(DateHelper.toDate(value, dateFormats))}`,
        ),
    },
    {
      name: TransactionComplexSearchBarFilterNameEnum.transactionDateLowerOrEqualThan,
      identifier: '<=',
      regex: dateRegex,
      displayedValue: (value: string): string =>
        `<= ${DateHelper.format(
          DateHelper.toDate(value, dateFormats),
          'DD/MM/yyyy',
        )}`,
      apiFilter: (value: string): MonoValueFilter<string> =>
        new MonoValueFilter(
          TransactionComplexFilterKey.date,
          `<=${DateHelper.format(DateHelper.toDate(value, dateFormats))}`,
        ),
    },
    {
      name: TransactionComplexSearchBarFilterNameEnum.transactionDateGreaterOrEqualThan,
      identifier: '>=',
      regex: dateRegex,
      displayedValue: (value: string): string =>
        `>= ${DateHelper.format(
          DateHelper.toDate(value, dateFormats),
          'DD/MM/yyyy',
        )}`,
      apiFilter: (value: string): MonoValueFilter<string> =>
        new MonoValueFilter(
          TransactionComplexFilterKey.date,
          `>=${DateHelper.format(DateHelper.toDate(value, dateFormats))}`,
        ),
    },
    {
      name: TransactionComplexSearchBarFilterNameEnum.transactionDateGreaterThan,
      identifier: '>',
      regex: dateRegex,
      displayedValue: (value: string): string =>
        `> ${DateHelper.format(
          DateHelper.toDate(value, dateFormats),
          'DD/MM/yyyy',
        )}`,
      apiFilter: (value: string): Filter<string> => {
        return new MonoValueFilter(
          TransactionComplexFilterKey.date,
          `>${DateHelper.format(DateHelper.toDate(value, dateFormats))}`,
        );
      },
    },
    {
      name: TransactionComplexSearchBarFilterNameEnum.transactionDateValue,
      identifier: '',
      regex: dateRegex,
      displayedValue: (value: string | string[]): string => {
        const filterArray = Array.isArray(value) ? value : value.split(',');
        const withoutSymbol = filterArray.map(v =>
          v.replace('>', '').replace('<', '').replace('=', ''),
        );
        return `${DateHelper.format(
          DateHelper.toDate(withoutSymbol, dateFormats),
          'DD/MM/yyyy',
        )}`;
      },
      apiFilter: (value: string): Filter<string> => {
        return new MonoValueFilter(
          TransactionComplexFilterKey.date,
          DateHelper.format(DateHelper.toDate(value, dateFormats)),
        );
      },
    },
    {
      name: TransactionComplexSearchBarFilterNameEnum.transactionDateMonthValue,
      identifier: '',
      regex: monthRegex,
      displayedValue: (value: string): string => value,
      apiFilter: (value: string): Filter<string> =>
        new AndFilter(TransactionComplexFilterKey.date, [
          `>=${DateHelper.format(
            DateHelper.firstDayOfMonth(
              DateHelper.format(DateHelper.toDate(value, ['MM-YYYY'])),
            ),
          )}`,
          `<=${DateHelper.format(
            DateHelper.lastDayOfMonth(
              DateHelper.format(DateHelper.toDate(value, ['MM-YYYY'])),
            ),
          )}`,
        ]),
    },
    {
      name: TransactionComplexSearchBarFilterNameEnum.idValue,
      identifier: 'id:',
      regex: stringRegex,
      displayedValue: (value: string): string => `ID: ${value}`,
      apiFilter: (value: string | string[]): AndFilter<string> => {
        const filterArray = Array.isArray(value) ? value : value.split(',');
        return new AndFilter(TransactionComplexFilterKey.ids, filterArray);
      },
      apiFilterKey: TransactionComplexFilterKey.ids,
    },
    {
      name: TransactionComplexSearchBarFilterNameEnum.labelValue,
      identifier: 'label:',
      regex: stringRegex,
      displayedValue: (value: string): string => `Label: ${value}`,
      apiFilter: (value: string | string[]): AndFilter<string> => {
        const filterArray = Array.isArray(value) ? value : value.split(',');
        return new AndFilter(
          TransactionComplexFilterKey.labelName,
          filterArray,
        );
      },
      apiFilterKey: TransactionComplexFilterKey.labelName,
    },
    {
      name: TransactionComplexSearchBarFilterNameEnum.tagValue,
      identifier: 'tag:',
      regex: stringRegex,
      displayedValue: (value: string): string => `Tag: ${value}`,
      apiFilter: (value: string | string[]): AndFilter<string> => {
        const filterArray = Array.isArray(value) ? value : value.split(',');
        return new AndFilter(TransactionComplexFilterKey.tags, filterArray);
      },
      apiFilterKey: TransactionComplexFilterKey.tags,
    },
    {
      name: TransactionComplexSearchBarFilterNameEnum.wordingValue,
      identifier: 'intitule:',
      regex: stringRegex,
      displayedValue: (value: string): string => `Intitulé: ${value}`,
      apiFilter: (value: string): MonoValueFilter<string> =>
        new MonoValueFilter(TransactionComplexFilterKey.wording, value),
      apiFilterKey: TransactionComplexFilterKey.wording,
    },
    {
      name: TransactionComplexSearchBarFilterNameEnum.commentValue,
      identifier: 'com:',
      regex: stringRegex,
      displayedValue: (value: string): string => `Commentaire: ${value}`,
      apiFilter: (value: string): MonoValueFilter<string> =>
        new MonoValueFilter(TransactionComplexFilterKey.comment, value),
      apiFilterKey: TransactionComplexFilterKey.comment,
    },
    {
      name: TransactionComplexSearchBarFilterNameEnum.userValue,
      identifier: 'utilisateur:',
      regex: stringRegex,
      displayedValue: (value: string, data: User[]): string => {
        const id = Number.parseInt(value);
        const user = isNaN(id)
          ? getUserByName(value, data)
          : data.find(user => user.id === id);
        return user
          ? `Utilisateur: ${user.firstName} ${user.lastName}`
          : `Utilisateur: ${value}`;
      },
      apiFilter: (value: string, data: User[]): Filter<string> => {
        const id = Number.parseInt(value);
        const user = isNaN(id)
          ? getUserByName(value, data)
          : data.find(user => user.id === id);
        return user
          ? new MonoValueFilter(
              TransactionComplexFilterKey.owners,
              `${user.id}`,
            )
          : new MonoValueFilter(TransactionComplexFilterKey.owners, undefined);
      },
      apiFilterKey: TransactionComplexFilterKey.owners,
    },
    {
      name: TransactionComplexSearchBarFilterNameEnum.labelWith,
      identifier: 'avec:',
      regex: labelRegex,
      displayedValue: (): string => `Avec label`,
      apiFilter: (): Filter<string> =>
        new MonoValueFilter(TransactionComplexFilterKey.withLabel, 'true'),
      apiFilterKey: TransactionComplexFilterKey.withLabel,
      apiFilterValues: ['true'],
    },
    {
      name: TransactionComplexSearchBarFilterNameEnum.labelWithout,
      identifier: 'sans:',
      regex: labelRegex,
      displayedValue: (): string => `Sans label`,
      apiFilter: (): Filter<string> =>
        new MonoValueFilter(TransactionComplexFilterKey.withLabel, 'false'),
      apiFilterKey: TransactionComplexFilterKey.withLabel,
      apiFilterValues: ['false'],
    },
    {
      name: TransactionComplexSearchBarFilterNameEnum.commentWith,
      identifier: 'avec:',
      regex: commentRegex,
      displayedValue: (): string => `Avec commentaire`,
      apiFilter: (): MonoValueFilter<string> =>
        new MonoValueFilter(TransactionComplexFilterKey.withComment, 'true'),
      apiFilterKey: TransactionComplexFilterKey.withComment,
      apiFilterValues: ['true'],
    },
    {
      name: TransactionComplexSearchBarFilterNameEnum.commentWithout,
      identifier: 'sans:',
      regex: commentRegex,
      displayedValue: (): string => `Sans commentaire`,
      apiFilter: (): MonoValueFilter<string> =>
        new MonoValueFilter(TransactionComplexFilterKey.withComment, 'false'),
      apiFilterKey: TransactionComplexFilterKey.withComment,
      apiFilterValues: ['false'],
    },
    {
      name: TransactionComplexSearchBarFilterNameEnum.documentWith,
      identifier: 'avec:',
      regex: documentRegex,
      displayedValue: (): string => `Avec document`,
      apiFilter: (): MonoValueFilter<string> =>
        new MonoValueFilter(TransactionComplexFilterKey.withDocument, 'true'),
      apiFilterKey: TransactionComplexFilterKey.withDocument,
      apiFilterValues: ['true'],
    },
    {
      name: TransactionComplexSearchBarFilterNameEnum.documentWithout,
      identifier: 'sans:',
      regex: documentRegex,
      displayedValue: (): string => `Sans document`,
      apiFilter: (): MonoValueFilter<string> =>
        new MonoValueFilter(TransactionComplexFilterKey.withDocument, 'false'),
      apiFilterKey: TransactionComplexFilterKey.withDocument,
      apiFilterValues: ['false'],
    },
    {
      name: TransactionComplexSearchBarFilterNameEnum.messageWith,
      identifier: 'avec:',
      regex: messageRegex,
      displayedValue: (): string => `Avec message`,
      apiFilter: (): MonoValueFilter<string> =>
        new MonoValueFilter(TransactionComplexFilterKey.withMessage, 'true'),
      apiFilterKey: TransactionComplexFilterKey.withMessage,
      apiFilterValues: ['true'],
    },
    {
      name: TransactionComplexSearchBarFilterNameEnum.messageWithout,
      identifier: 'sans:',
      regex: messageRegex,
      displayedValue: (): string => `Sans message`,
      apiFilter: (): MonoValueFilter<string> =>
        new MonoValueFilter(TransactionComplexFilterKey.withMessage, 'false'),
      apiFilterKey: TransactionComplexFilterKey.withMessage,
      apiFilterValues: ['false'],
    },
    {
      name: TransactionComplexSearchBarFilterNameEnum.tagWith,
      identifier: 'avec:',
      regex: tagRegex,
      displayedValue: (): string => `Avec tag`,
      apiFilter: (): MonoValueFilter<string> =>
        new MonoValueFilter(TransactionComplexFilterKey.withTag, 'true'),
      apiFilterKey: TransactionComplexFilterKey.withTag,
      apiFilterValues: ['true'],
    },
    {
      name: TransactionComplexSearchBarFilterNameEnum.tagWithout,
      identifier: 'sans:',
      regex: tagRegex,
      displayedValue: (): string => `Sans tag`,
      apiFilter: (): MonoValueFilter<string> =>
        new MonoValueFilter(TransactionComplexFilterKey.withTag, 'false'),
      apiFilterKey: TransactionComplexFilterKey.withTag,
      apiFilterValues: ['false'],
    },
    {
      name: TransactionComplexSearchBarFilterNameEnum.withDocumentRequest,
      identifier: 'demande:',
      regex: documentRegex,
      displayedValue: (): string => `Demande de document`,
      apiFilter: (): MonoValueFilter<string> =>
        new MonoValueFilter(
          TransactionComplexFilterKey.accountantDetailRequest,
          AccountantDetailRequestFilterValue.Receipt,
        ),
      apiFilterKey: TransactionComplexFilterKey.accountantDetailRequest,
      apiFilterValues: [AccountantDetailRequestFilterValue.Receipt],
    },
    {
      name: TransactionComplexSearchBarFilterNameEnum.withInformationRequest,
      identifier: 'demande:',
      regex: labelRegex,
      displayedValue: (): string => `Demande de label`,
      apiFilter: (): MonoValueFilter<string> =>
        new MonoValueFilter(
          TransactionComplexFilterKey.accountantDetailRequest,
          AccountantDetailRequestFilterValue.Label,
        ),
      apiFilterKey: TransactionComplexFilterKey.accountantDetailRequest,
      apiFilterValues: [AccountantDetailRequestFilterValue.Label],
    },
    {
      name: TransactionComplexSearchBarFilterNameEnum.withMessageRequest,
      identifier: 'demande:',
      regex: messageRegex,
      displayedValue: (): string => `Demande par message`,
      apiFilter: (): MonoValueFilter<string> =>
        new MonoValueFilter(
          TransactionComplexFilterKey.accountantDetailRequest,
          AccountantDetailRequestFilterValue.Message,
        ),
      apiFilterKey: TransactionComplexFilterKey.accountantDetailRequest,
      apiFilterValues: [AccountantDetailRequestFilterValue.Message],
    },
    {
      name: TransactionComplexSearchBarFilterNameEnum.search,
      identifier: '',
      regex: stringRegex,
      displayedValue: (value: string): string => {
        if (!isNaN(Number(value.replace(',', '.')))) {
          return value.replace('.', ',');
        } else {
          return value;
        }
      },
      apiFilter: (value: string): Filter<string> => {
        if (!isNaN(Number(value.replace(',', '.')))) {
          return new MonoValueFilter('q', value.replace(',', '.'));
        } else {
          return new MonoValueFilter('q', value);
        }
      },
      apiFilterKey: 'q',
    },
  ];

export const getUserByName = (searchedName: string, users: User[]): User => {
  return users.find(user =>
    `${user.firstName} ${user.lastName}`
      .toLowerCase()
      .includes(searchedName?.toLowerCase()),
  );
};
