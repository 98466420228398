export class CustomDashboardDatasetIndicatorsJson {
  standard_indicators: string[];
  labels: number[];
  tags: number[];

  constructor() {
    this.standard_indicators = [];
    this.labels = [];
    this.tags = [];
  }
}
