<app-tiime-layout>
  <form
    class="auth-form change-password"
    [formGroup]="changePasswordForm"
    (ngSubmit)="submitChangePasswordForm()"
  >
    <div class="auth-form-header">
      <div class="auth-form-header-illustration"></div>
      <div class="auth-form-header-separator"></div>
      <span class="auth-form-header-title">Modifier mon mot de passe</span>
    </div>
    <div *ngIf="isMailSent; then mailSent; else form"></div>
    <ng-template #form>
      <div class="auth-form-content">
        <tiime-input-container
          class="input-container"
          label="Adresse mail"
          data-cy="email-input"
          [errorMessage]="changePasswordForm.emailErrorMessage"
        >
          <input
            id="email"
            tiimeInputContainerNativeControl
            type="text"
            placeholder="Adresse mail"
            formControlName="email"
            data-cy="reset-password-email"
          />
        </tiime-input-container>
      </div>
      <div class="auth-form-actions">
        <button
          class="send-mail-button"
          type="submit"
          tiime-button
          accent
          data-cy="reset-password-submit"
        >
          Envoyer le mail
        </button>
        <button
          type="button"
          tiime-button
          inverted
          neutral
          (click)="navigateToSignin()"
        >
          Déjà un compte ?
        </button>
      </div>
    </ng-template>
    <ng-template #mailSent>
      <div class="mail-sent" data-cy="reset-password-success-message">
        <div class="wrap-info">
          <mat-icon
            class="check"
            inline="true"
            fontIcon="icon-header-grey-n3-24px-check"
          ></mat-icon>
          <span class="info">
            Un mail vient de vous être envoyé pour réinitialiser votre mot de
            passe
          </span>
        </div>
      </div>

      <div class="auth-form-actions">
        <button type="button" tiime-button accent (click)="navigateToSignin()">
          Je me connecte
        </button>

        <button
          type="button"
          tiime-button
          inverted
          neutral
          (click)="navigateToSignin()"
        >
          Déjà un compte ?
        </button>
      </div>
    </ng-template>
  </form>
</app-tiime-layout>
