<div (click)="file.click()">
  <input
    hidden
    #file
    id="file"
    type="file"
    [multiple]="multiple"
    [accept]="acceptedTypes | join"
    (change)="onFileSelected(file.files)"
    data-cy="file-input"
  />
  <ng-content select="[action]"></ng-content>
</div>
