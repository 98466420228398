import { ClientPaymentStatus } from '../enum/client-payment-status';

export class ClientListItem {
  get hasFullDataForBusiness(): boolean {
    return (
      !!this.iban &&
      !!this.bic &&
      !!this.name &&
      !!this.address &&
      !!this.postalCode &&
      !!this.city &&
      !!this.countryCode
    );
  }

  constructor(
    public id?: number,
    public name?: string,
    public slug?: string,
    public color?: string,
    public paymentStatus?: ClientPaymentStatus,
    public balance?: number,
    public encasement?: number,
    public billedIncludingTaxes?: number,
    public billedExcludingTaxes?: number,
    public archived?: boolean,
    public iban?: string,
    public bic?: string,
    public bankName?: string,
    public address?: string,
    public postalCode?: string,
    public city?: string,
    public countryCode?: string,
  ) {}

  public static fromJson(json: any): ClientListItem {
    return new ClientListItem(
      json.id,
      json.name,
      json.slug,
      json.color,
      json.payment_status,
      json.balance_including_taxes,
      json.encasement,
      json.billed_including_taxes,
      json.billed_excluding_taxes,
      json.archived,
      json.iban,
      json.bic,
      json.bank_name,
      json.address,
      json.postal_code,
      json.city,
      json.country_code,
    );
  }
}
