import {
  CompanyWalletUser,
  CompanyWalletUserApiContract,
} from './company-wallet-user';

export interface CompanyWalletStakeholderApiContract {
  id: number;
  is_admin: boolean;
  wallet_user: CompanyWalletUserApiContract;
}

export class CompanyWalletStakeholder {
  constructor(
    public id: number,
    public isAdmin: boolean,
    public walletUser?: CompanyWalletUser,
  ) {}

  static fromJson(
    json: CompanyWalletStakeholderApiContract,
  ): CompanyWalletStakeholder {
    return new CompanyWalletStakeholder(
      json.id,
      json.is_admin,
      json.wallet_user ? CompanyWalletUser.fromJson(json.wallet_user) : null,
    );
  }
}
