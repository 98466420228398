import { Directive, inject } from '@angular/core';

import { SIDE_PANEL_DATA } from './side-panel-config';
import { SidePanelRef } from './side-panel-ref';

@Directive()
export abstract class SidePanelComponentBase<
  TData = unknown,
  TResult = unknown,
> {
  protected readonly sidePanelRef =
    inject<SidePanelRef<this, TResult>>(SidePanelRef);
  protected readonly sidePanelData = inject(SIDE_PANEL_DATA) as TData;

  protected cancel(): void {
    this.sidePanelRef.close();
  }
}
