export class BankTransactionBeneficiary {
  constructor(
    public name: string,
    public color: string,
    public acronym: string,
  ) {}

  static fromJson(
    beneficiary: BankTransactionBeneficiary,
  ): BankTransactionBeneficiary {
    return new BankTransactionBeneficiary(
      beneficiary.name,
      beneficiary.color,
      beneficiary.acronym,
    );
  }
}
