export interface ThirdPartyApiContract {
  id: number;
  name: string;
}

export class ThirdParty {
  constructor(public id?: number, public name?: string) {}

  static fromJson(json: ThirdPartyApiContract): ThirdParty {
    return new ThirdParty(json.id, json.name);
  }

  static fromName(name: string): ThirdParty {
    return new ThirdParty(null, name);
  }

  toJson(): ThirdPartyApiContract {
    return {
      id: this.id,
      name: this.name,
    };
  }
}
