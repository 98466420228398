import {
  AndFilter,
  ComplexSearchBarFilter,
  Filter,
  MonoValueFilter,
  OrFilter,
} from 'tiime-components';

import { AmountType, InvoiceStatus, QuoteStatus } from '@core/enum';
import { InvoiceComplexFilterKey } from '@core/enum/invoice';
import { DateHelper } from '@core/helpers/date.helper';
import { RegexHelper } from '@core/helpers/regex.helper';

import { INVOICE_LABEL } from '../invoice-label.constants';
import { QUOTE_LABEL } from '../quote-label.constants';

const dateRegex = RegexHelper.dateRegex;
const monthRegex = /^\d{2}[/-](\d{2}|\d{4})$/;
const floatRegex = RegexHelper.floatRegex;
const floatWithNegativeSignRegex = RegexHelper.floatWithNegativeSignRegex;
const emptyRegex = /^$/;
const stringRegex = /^(?!s*$).+/;
const clientRegex = /^client$/;
const commentRegex = /^commentaire$/;
const transactionRegex = /^transaction$/;
const tagRegex = /^tag$/;

const dateFormats = ['DD/MM/YYYY', 'DD-MM-YY', 'YYYY-MM-DD'];

export enum InvoiceComplexSearchBarFilterNameEnum {
  amountPositive = 'amountPositive',
  amountPositiveValue = 'amountPositiveValue',
  amountNegative = 'amountNegative',
  amountNegativeValue = 'amountNegativeValue',
  amountValue = 'amountValue',
  amountGreaterOrEqualThan = 'amountGreaterOrEqualThan',
  amountGreaterThan = 'amountGreaterThan',
  amountLowerOrEqualThan = 'amountLowerOrEqualThan',
  amountLowerThan = 'amountLowerThan',
  amountInterval = 'amountInterval',
  invoiceVatAmount = 'invoiceVatAmount',
  invoiceDateInterval = 'invoiceDateInterval',
  invoiceDateValue = 'invoiceDateValue',
  invoiceDateMonthValue = 'invoiceDateMonthValue',
  invoiceDateGreaterOrEqualThan = 'invoiceDateGreaterOrEqualThan',
  invoiceDateGreaterThan = 'invoiceDateGreaterThan',
  invoiceDateLowerOrEqualThan = 'invoiceDateLowerOrEqualThan',
  invoiceDateLowerThan = 'invoiceDateLowerThan',
  journalValue = 'journalValue',
  wordingValue = 'wordingValue',
  numberValue = 'numberValue',
  clientValue = 'clientValue',
  statusValue = 'statusValue',
  clientWith = 'clientWith',
  clientWithout = 'clientWithout',
  tagValue = 'tagValue',
  temporaryAccount = 'temporaryAccount',
  commentValue = 'commentValue',
  commentWith = 'commentWith',
  commentWithout = 'commentWithout',
  transactionWith = 'transactionWith',
  transactionWithout = 'transactionWithout',
  tagWith = 'tagWith',
  tagWithout = 'tagWithout',
  deleted = 'deleted',
  withDocumentRequest = 'withDocumentRequest',
  withLabelRequest = 'withLabelRequest',
  withMessageRequest = 'withMessageRequest',
  search = 'q',
}

const invoiceStatusValuesMap: Record<string, string> = {
  brouillon: 'draft',
  facture: 'saved',
  facturee: 'saved',
  paye: 'paid',
  payee: 'paid',
  annule: 'cancelled',
  annulee: 'cancelled',
  envoye: 'sent',
  envoyee: 'sent',
};

const quoteStatusValuesMap: Record<string, string> = {
  enregistre: 'saved',
  enregistree: 'saved',
  accepte: 'accepted',
  acceptee: 'accepted',
  annule: 'cancelled',
  annulee: 'cancelled',
  refuse: 'refused',
  refusee: 'refused',
};

const statusFormatter = (
  statusList: string,
  statusMap: Record<string, string>,
): string[] =>
  statusList
    .split(/\W/)
    .filter(
      value => statusMap[value] || Object.values(statusMap).includes(value),
    )
    .map(value => statusMap[value] || value);

/**
 * Filtres complexes de la liste des transactions
 * L'ordre est important
 */
export const INVOICE_COMPLEX_SEARCH_BAR_FILTERS: ComplexSearchBarFilter[] = [
  {
    name: InvoiceComplexSearchBarFilterNameEnum.amountPositive,
    identifier: '+',
    regex: emptyRegex,
    displayedValue: (): string => `Positif`,
    apiFilter: () =>
      new MonoValueFilter(
        InvoiceComplexFilterKey.amountType,
        AmountType.cashing,
      ),
    apiFilterKey: InvoiceComplexFilterKey.amountType,
    apiFilterValues: [AmountType.cashing],
  },
  {
    name: InvoiceComplexSearchBarFilterNameEnum.amountPositiveValue,
    identifier: '+',
    regex: floatRegex,
    displayedValue: (value: string): string =>
      `Égal à ${value.replace('.', ',')}€`,
    apiFilter: (value: string) =>
      new MonoValueFilter(
        InvoiceComplexFilterKey.amount,
        value.replace(',', '.'),
      ),
  },
  {
    name: InvoiceComplexSearchBarFilterNameEnum.amountNegative,
    identifier: '-',
    regex: emptyRegex,
    displayedValue: (): string => `Négatif`,
    apiFilter: () =>
      new MonoValueFilter(
        InvoiceComplexFilterKey.amountType,
        AmountType.disbursements,
      ),
    apiFilterKey: InvoiceComplexFilterKey.amountType,
    apiFilterValues: [AmountType.disbursements],
  },
  {
    name: InvoiceComplexSearchBarFilterNameEnum.amountNegativeValue,
    identifier: '-',
    regex: floatRegex,
    displayedValue: (value: string): string =>
      `Égal à -${value.replace('.', ',')}€`,
    apiFilter: (value: string) =>
      new MonoValueFilter(
        InvoiceComplexFilterKey.amount,
        `-${value.replace(',', '.')}`,
      ),
  },
  {
    name: InvoiceComplexSearchBarFilterNameEnum.amountValue,
    identifier: '=',
    regex: floatWithNegativeSignRegex,
    displayedValue: (value: string): string =>
      `Égal à ${value.replace('.', ',')}€`,
    apiFilter: (value: string) =>
      new MonoValueFilter(
        InvoiceComplexFilterKey.amount,
        value.replace(',', '.'),
      ),
  },
  {
    name: InvoiceComplexSearchBarFilterNameEnum.amountGreaterOrEqualThan,
    identifier: '>=',
    regex: floatWithNegativeSignRegex,
    displayedValue: (value: string): string =>
      `Supérieur ou égal à ${value.replace('.', ',')}€`,
    apiFilter: (value: string) =>
      new MonoValueFilter(InvoiceComplexFilterKey.amount, `>=${value}`),
  },
  {
    name: InvoiceComplexSearchBarFilterNameEnum.amountGreaterThan,
    identifier: '>',
    regex: floatWithNegativeSignRegex,
    displayedValue: (value: string): string =>
      value === '0' ? 'Positif' : `Supérieur à ${value.replace('.', ',')}€`,
    apiFilter: (value: string) =>
      new MonoValueFilter(InvoiceComplexFilterKey.amount, `>${value}`),
  },
  {
    name: InvoiceComplexSearchBarFilterNameEnum.amountLowerOrEqualThan,
    identifier: '<=',
    regex: floatWithNegativeSignRegex,
    displayedValue: (value: string): string =>
      `Inférieur ou égal à ${value.replace('.', ',')}€`,
    apiFilter: (value: string) =>
      new MonoValueFilter(InvoiceComplexFilterKey.amount, `<=${value}`),
  },
  {
    name: InvoiceComplexSearchBarFilterNameEnum.amountLowerThan,
    identifier: '<',
    regex: floatWithNegativeSignRegex,
    displayedValue: (value: string): string =>
      value === '0' ? 'Négatif' : `Inférieur à ${value.replace('.', ',')}€`,
    apiFilter: (value: string) =>
      new MonoValueFilter(InvoiceComplexFilterKey.amount, `<${value}`),
  },
  {
    name: InvoiceComplexSearchBarFilterNameEnum.amountInterval,
    identifier: '',
    regex: RegexHelper.amountIntervalRegex,
    displayedValue: (value: string | string[]): string => {
      const filterArray = Array.isArray(value) ? value : value.split(',');
      const withoutSymbol = filterArray.map(v =>
        v.replace('>', '').replace('<', '').replace('=', '').replace('.', ','),
      );
      return `Entre ${withoutSymbol[0]}€ et ${withoutSymbol[1]}€`;
    },
    apiFilter: (value: string | string[]): AndFilter<string> => {
      const filterArray = Array.isArray(value) ? value : value.split(',');
      const withoutSymbol = filterArray.map(v =>
        v.replace('>', '').replace('<', '').replace('=', '').replace(',', '.'),
      );
      return new AndFilter(InvoiceComplexFilterKey.amount, [
        `>=${withoutSymbol[0]}`,
        `<=${withoutSymbol[1]}`,
      ]);
    },
  },
  {
    name: InvoiceComplexSearchBarFilterNameEnum.invoiceDateInterval,
    identifier: '',
    regex: RegexHelper.dateIntervalRegex,
    displayedValue: (value: string | string[]): string => {
      const filterArray = Array.isArray(value) ? value : value.split(',');
      const withoutSymbol = filterArray.map(v =>
        v.replace('>', '').replace('<', '').replace('=', ''),
      );
      const formattedFromDate = DateHelper.format(
        DateHelper.toDate(withoutSymbol[0], dateFormats),
        'DD/MM/YYYY',
      );
      const formattedToDate = DateHelper.format(
        DateHelper.toDate(withoutSymbol[1], dateFormats),
        'DD/MM/YYYY',
      );
      return `Entre ${formattedFromDate} et ${formattedToDate}`;
    },
    apiFilter: (value: string | string[]): AndFilter<string> => {
      const filterArray = Array.isArray(value) ? value : value.split(',');
      const withoutSymbol = filterArray.map(v =>
        v.replace('>', '').replace('<', '').replace('=', ''),
      );
      return new AndFilter(InvoiceComplexFilterKey.date, [
        `>=${withoutSymbol[0].replace(',', '.')}`,
        `<=${withoutSymbol[1].replace(',', '.')}`,
      ]);
    },
  },
  {
    name: InvoiceComplexSearchBarFilterNameEnum.invoiceDateLowerThan,
    identifier: '<',
    regex: dateRegex,
    displayedValue: (value: string): string =>
      `< ${DateHelper.format(
        DateHelper.toDate(value, dateFormats),
        'DD/MM/YYYY',
      )}`,
    apiFilter: (value: string): Filter<string> =>
      new MonoValueFilter(
        InvoiceComplexFilterKey.date,
        `<${DateHelper.format(DateHelper.toDate(value, dateFormats))}`,
      ),
  },
  {
    name: InvoiceComplexSearchBarFilterNameEnum.invoiceDateLowerOrEqualThan,
    identifier: '<=',
    regex: dateRegex,
    displayedValue: (value: string): string =>
      `<= ${DateHelper.format(
        DateHelper.toDate(value, dateFormats),
        'DD/MM/yyyy',
      )}`,
    apiFilter: (value: string): MonoValueFilter<string> =>
      new MonoValueFilter(
        InvoiceComplexFilterKey.date,
        `<=${DateHelper.format(DateHelper.toDate(value, dateFormats))}`,
      ),
  },
  {
    name: InvoiceComplexSearchBarFilterNameEnum.invoiceDateGreaterOrEqualThan,
    identifier: '>=',
    regex: dateRegex,
    displayedValue: (value: string): string =>
      `>= ${DateHelper.format(
        DateHelper.toDate(value, dateFormats),
        'DD/MM/yyyy',
      )}`,
    apiFilter: (value: string): MonoValueFilter<string> =>
      new MonoValueFilter(
        InvoiceComplexFilterKey.date,
        `>=${DateHelper.format(DateHelper.toDate(value, dateFormats))}`,
      ),
  },
  {
    name: InvoiceComplexSearchBarFilterNameEnum.invoiceDateGreaterThan,
    identifier: '>',
    regex: dateRegex,
    displayedValue: (value: string): string =>
      `> ${DateHelper.format(
        DateHelper.toDate(value, dateFormats),
        'DD/MM/yyyy',
      )}`,
    apiFilter: (value: string): Filter<string> => {
      return new MonoValueFilter(
        InvoiceComplexFilterKey.date,
        `>${DateHelper.format(DateHelper.toDate(value, dateFormats))}`,
      );
    },
  },
  {
    name: InvoiceComplexSearchBarFilterNameEnum.invoiceDateValue,
    identifier: '',
    regex: dateRegex,
    displayedValue: (value: string | string[]): string => {
      const filterArray = Array.isArray(value) ? value : value.split(',');
      const withoutSymbol = filterArray.map(v =>
        v.replace('>', '').replace('<', '').replace('=', ''),
      );
      return `${DateHelper.format(
        DateHelper.toDate(withoutSymbol, dateFormats),
        'DD/MM/yyyy',
      )}`;
    },
    apiFilter: (value: string): Filter<string> => {
      return new MonoValueFilter(
        InvoiceComplexFilterKey.date,
        DateHelper.format(DateHelper.toDate(value, dateFormats)),
      );
    },
  },
  {
    name: InvoiceComplexSearchBarFilterNameEnum.invoiceDateMonthValue,
    identifier: '',
    regex: monthRegex,
    displayedValue: (value: string): string => value,
    apiFilter: (value: string): Filter<string> =>
      new AndFilter(InvoiceComplexFilterKey.date, [
        `>=${DateHelper.format(
          DateHelper.firstDayOfMonth(
            DateHelper.format(DateHelper.toDate(value, ['MM-YYYY'])),
          ),
        )}`,
        `<=${DateHelper.format(
          DateHelper.lastDayOfMonth(
            DateHelper.format(DateHelper.toDate(value, ['MM-YYYY'])),
          ),
        )}`,
      ]),
  },
  {
    name: InvoiceComplexSearchBarFilterNameEnum.numberValue,
    identifier: 'numero:',
    regex: stringRegex,
    displayedValue: (value: string): string => `Numéro de facture: ${value}`,
    apiFilter: (value: string | string[]): AndFilter<string> => {
      const filterArray = Array.isArray(value) ? value : value.split(',');
      return new AndFilter(InvoiceComplexFilterKey.numbers, filterArray);
    },
    apiFilterKey: InvoiceComplexFilterKey.numbers,
  },
  {
    name: InvoiceComplexSearchBarFilterNameEnum.clientValue,
    identifier: 'client:',
    regex: stringRegex,
    displayedValue: (value: string): string => `Nom de client: ${value}`,
    apiFilter: (value: string | string[]): AndFilter<string> => {
      const filterArray = Array.isArray(value) ? value : value.split(',');
      return new AndFilter(InvoiceComplexFilterKey.clientName, filterArray);
    },
    apiFilterKey: InvoiceComplexFilterKey.clientName,
  },
  {
    name: InvoiceComplexSearchBarFilterNameEnum.tagValue,
    identifier: 'tag:',
    regex: stringRegex,
    displayedValue: (value: string): string => `Tag: ${value}`,
    apiFilter: (value: string | string[]): AndFilter<string> => {
      const filterArray = Array.isArray(value) ? value : value.split(',');
      return new AndFilter(InvoiceComplexFilterKey.tags, filterArray);
    },
    apiFilterKey: InvoiceComplexFilterKey.tags,
  },
  {
    name: InvoiceComplexSearchBarFilterNameEnum.wordingValue,
    identifier: 'intitule:',
    regex: stringRegex,
    displayedValue: (value: string): string => `Intitulé: ${value}`,
    apiFilter: (value: string): MonoValueFilter<string> =>
      new MonoValueFilter(InvoiceComplexFilterKey.wording, value),
    apiFilterKey: InvoiceComplexFilterKey.wording,
  },
  {
    name: InvoiceComplexSearchBarFilterNameEnum.invoiceVatAmount,
    identifier: 'montant ht:',
    regex: floatWithNegativeSignRegex,
    displayedValue: (value: string): string => `Montant HT: ${value}`,
    apiFilter: (value: string): Filter<string> => {
      return new MonoValueFilter(
        InvoiceComplexFilterKey.amountExcludingTaxes,
        value,
      );
    },
    apiFilterKey: InvoiceComplexFilterKey.amountExcludingTaxes,
  },
  {
    name: InvoiceComplexSearchBarFilterNameEnum.commentValue,
    identifier: 'com:',
    regex: stringRegex,
    displayedValue: (value: string): string => `Commentaire: ${value}`,
    apiFilter: (value: string): MonoValueFilter<string> =>
      new MonoValueFilter(InvoiceComplexFilterKey.comment, value),
    apiFilterKey: InvoiceComplexFilterKey.comment,
  },
  {
    name: InvoiceComplexSearchBarFilterNameEnum.statusValue,
    identifier: 'statut:',
    regex: stringRegex,
    displayedValue: (value: string): string =>
      `Statut: ${INVOICE_LABEL.invoice.status[value as InvoiceStatus]}`,
    apiFilter: (value: string): OrFilter<string> =>
      new OrFilter(
        InvoiceComplexFilterKey.status,
        statusFormatter(value, invoiceStatusValuesMap),
      ),
    apiFilterKey: InvoiceComplexFilterKey.status,
  },
  {
    name: InvoiceComplexSearchBarFilterNameEnum.clientWith,
    identifier: 'avec:',
    regex: clientRegex,
    displayedValue: (): string => `Avec client`,
    apiFilter: (): Filter<string> =>
      new MonoValueFilter(InvoiceComplexFilterKey.withClient, 'true'),
    apiFilterKey: InvoiceComplexFilterKey.withClient,
    apiFilterValues: ['true'],
  },
  {
    name: InvoiceComplexSearchBarFilterNameEnum.clientWithout,
    identifier: 'sans:',
    regex: clientRegex,
    displayedValue: (): string => `Sans client`,
    apiFilter: (): Filter<string> =>
      new MonoValueFilter(InvoiceComplexFilterKey.withClient, 'false'),
    apiFilterKey: InvoiceComplexFilterKey.withClient,
    apiFilterValues: ['false'],
  },
  {
    name: InvoiceComplexSearchBarFilterNameEnum.commentWith,
    identifier: 'avec:',
    regex: commentRegex,
    displayedValue: (): string => `Avec commentaire`,
    apiFilter: (): MonoValueFilter<string> =>
      new MonoValueFilter(InvoiceComplexFilterKey.withComment, 'true'),
    apiFilterKey: InvoiceComplexFilterKey.withComment,
    apiFilterValues: ['true'],
  },
  {
    name: InvoiceComplexSearchBarFilterNameEnum.commentWithout,
    identifier: 'sans:',
    regex: commentRegex,
    displayedValue: (): string => `Sans commentaire`,
    apiFilter: (): MonoValueFilter<string> =>
      new MonoValueFilter(InvoiceComplexFilterKey.withComment, 'false'),
    apiFilterKey: InvoiceComplexFilterKey.withComment,
    apiFilterValues: ['false'],
  },
  {
    name: InvoiceComplexSearchBarFilterNameEnum.transactionWith,
    identifier: 'avec:',
    regex: transactionRegex,
    displayedValue: (): string => `Avec transaction`,
    apiFilter: (): MonoValueFilter<string> =>
      new MonoValueFilter(InvoiceComplexFilterKey.withTransaction, 'true'),
    apiFilterKey: InvoiceComplexFilterKey.withTransaction,
    apiFilterValues: ['true'],
  },
  {
    name: InvoiceComplexSearchBarFilterNameEnum.transactionWithout,
    identifier: 'sans:',
    regex: transactionRegex,
    displayedValue: (): string => `Sans transaction`,
    apiFilter: (): MonoValueFilter<string> =>
      new MonoValueFilter(InvoiceComplexFilterKey.withTransaction, 'false'),
    apiFilterKey: InvoiceComplexFilterKey.withTransaction,
    apiFilterValues: ['false'],
  },
  {
    name: InvoiceComplexSearchBarFilterNameEnum.tagWith,
    identifier: 'avec:',
    regex: tagRegex,
    displayedValue: (): string => `Avec tag`,
    apiFilter: (): MonoValueFilter<string> =>
      new MonoValueFilter(InvoiceComplexFilterKey.withTag, 'true'),
    apiFilterKey: InvoiceComplexFilterKey.withTag,
    apiFilterValues: ['true'],
  },
  {
    name: InvoiceComplexSearchBarFilterNameEnum.tagWithout,
    identifier: 'sans:',
    regex: tagRegex,
    displayedValue: (): string => `Sans tag`,
    apiFilter: (): MonoValueFilter<string> =>
      new MonoValueFilter(InvoiceComplexFilterKey.withTag, 'false'),
    apiFilterKey: InvoiceComplexFilterKey.withTag,
    apiFilterValues: ['false'],
  },
  {
    name: InvoiceComplexSearchBarFilterNameEnum.search,
    identifier: '',
    regex: stringRegex,
    displayedValue: (value: string): string => {
      if (!isNaN(Number(value.replace(',', '.')))) {
        return value.replace('.', ',');
      }
      return value;
    },
    apiFilter: (value: string): Filter<string> => {
      if (!isNaN(Number(value.replace(',', '.')))) {
        return new MonoValueFilter('q', value.replace(',', '.'));
      }
      return new MonoValueFilter('q', value);
    },
    apiFilterKey: 'q',
  },
];

export const QUOTE_COMPLEX_SEARCH_BAR_FILTERS =
  INVOICE_COMPLEX_SEARCH_BAR_FILTERS.slice();

const numberIndex = QUOTE_COMPLEX_SEARCH_BAR_FILTERS.findIndex(
  f => f.name === InvoiceComplexSearchBarFilterNameEnum.numberValue,
);
QUOTE_COMPLEX_SEARCH_BAR_FILTERS.splice(numberIndex, 1, {
  ...INVOICE_COMPLEX_SEARCH_BAR_FILTERS[numberIndex],
  displayedValue: (value: string): string => `Numéro de devis: ${value}`,
});

const statusIndex = QUOTE_COMPLEX_SEARCH_BAR_FILTERS.findIndex(
  f => f.name === InvoiceComplexSearchBarFilterNameEnum.statusValue,
);
QUOTE_COMPLEX_SEARCH_BAR_FILTERS.splice(statusIndex, 1, {
  ...INVOICE_COMPLEX_SEARCH_BAR_FILTERS[statusIndex],
  displayedValue: (value: string): string =>
    `Statut: ${QUOTE_LABEL.status[value as QuoteStatus]}`,
  apiFilter: (value: string): OrFilter<string> =>
    new OrFilter(
      InvoiceComplexFilterKey.status,
      statusFormatter(value, quoteStatusValuesMap),
    ),
});
