import { TrackingEvent } from '@manakincubber/tiime-tracking';

import { Invoice } from '@models';

export const INVOICE_DOWNLOADED = 'Invoice Downloaded';

export class InvoiceDownloaded implements TrackingEvent {
  readonly name = INVOICE_DOWNLOADED;

  constructor(readonly invoiceId: Invoice['id']) {}
}
