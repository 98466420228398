<img
  *ngIf="invoiceType === 'external_invoice'; else invoiceIcon"
  class="item-icon"
  ngSrc="assets/category-icons/icon_external_invoice.svg"
  width="20"
  height="20"
  alt=""
/>
<ng-template #invoiceIcon>
  <tiime-twocolors-icon
    [icon]="invoiceStatus | invoiceStatusIcon : invoiceType"
    [size]="iconSize || '20'"
    [ngClass]="[invoiceStatus || 'draft']"
  ></tiime-twocolors-icon>
</ng-template>
<div *ngIf="invoiceNumber; then number; else noNumber"></div>
<ng-template #number>
  <div class="label-container">
    <span class="invoice-label">
      {{ invoiceType | map : mapToInvoiceLabel }}
    </span>
    <div
      class="invoice-number"
      tiimeTooltip
      tooltipPosition="bottom"
      hideWithoutEllipsis
      [content]="invoiceNumberTooltip"
    >
      {{ invoiceNumber }}
    </div>
  </div>
</ng-template>
<ng-template #invoiceNumberTooltip>
  {{ invoiceType | map : mapToInvoiceLabel }} {{ invoiceNumber }}
</ng-template>
<ng-template #noNumber>
  <span class="invoice-no-number italic">Pas de numéro</span>
</ng-template>
