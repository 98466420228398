import { ParamOption } from './param-option';

export abstract class Filter<T> implements ParamOption {
  abstract readonly separator: string;

  constructor(readonly key: string, readonly values: ReadonlyArray<T>) {}

  toParamObject(): { [param: string]: string | undefined } {
    return { [this.key]: this.values.join(this.separator) };
  }
}

export class MonoValueFilter<T> extends Filter<T> {
  readonly separator = '';

  constructor(key: string, value: T) {
    super(key, [value]);
  }
}

export class QueryFilter<T> extends Filter<T> {
  readonly separator = ';';
}

export class AndFilter<T> extends Filter<T> {
  readonly separator = ',';
}

export class OrFilter<T> extends Filter<T> {
  readonly separator = '|';
}

export class ResetFilter extends Filter<void> {
  separator = '';

  private constructor(key: string) {
    super(key, []);
  }

  static forKey(key: string): ResetFilter {
    return new ResetFilter(key);
  }

  toParamObject(): { [param: string]: string | undefined } {
    return { [this.key]: undefined };
  }
}
