import { Injectable } from '@angular/core';
import { NavigationExtras, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { lastValueFrom } from 'rxjs';
import { take } from 'rxjs/operators';

import { selectedCompanyIdSelector } from '@core/store';

type NavigationExtrasWithTypedQueryParams<T> = Omit<
  NavigationExtras,
  'queryParams'
> & {
  queryParams?: T;
};

export enum InvoiceNavigationQueryParamKeys {
  skip_preview = 'skip_preview',
  from_transactions = 'from_transactions',
  from_todo = 'from_todo',
}

export type InvoiceNavigationQueryParams = {
  [InvoiceNavigationQueryParamKeys.skip_preview]?: boolean;
  [InvoiceNavigationQueryParamKeys.from_transactions]?: boolean;
};

@Injectable({ providedIn: 'root' })
export class NavigationService {
  constructor(private readonly router: Router, private readonly store: Store) {}

  navigateToInvoice(
    invoiceId: number,
    extras?: NavigationExtrasWithTypedQueryParams<InvoiceNavigationQueryParams>,
  ): Promise<boolean> {
    return this.navigateFromCurrentCompany(
      ['invoice', 'invoices', invoiceId],
      extras,
    );
  }

  navigateToExpenseReport(
    expensesReportId: number,
    categoryId: number,
    extras?: NavigationExtrasWithTypedQueryParams<InvoiceNavigationQueryParams>,
  ): Promise<boolean> {
    return this.navigateFromCurrentCompany(
      ['documents', 'categories', categoryId, expensesReportId],
      extras,
    );
  }

  async navigateFromCurrentCompany(
    commands: unknown[],
    extras?: NavigationExtras,
  ): Promise<boolean> {
    const currentCompanyId = await lastValueFrom(
      this.store.select(selectedCompanyIdSelector).pipe(take(1)),
    );

    if (!currentCompanyId) {
      throw new Error('No company selected in the store');
    }

    const path = ['/', 'companies', currentCompanyId, ...commands];
    return this.router.navigate(path, extras);
  }
}
