import { CommonModule, DecimalPipe } from '@angular/common';
import { NgModule } from '@angular/core';
import { TiimePipesModule } from '@manakincubber/tiime-utils';
import { TiimeLetModule } from 'tiime-components';

import { FormattedNumberInputComponent } from './formatted-number-input.component';
import { FormattedNumberOutputPipe } from './formatted-number-output.pipe';

@NgModule({
  exports: [FormattedNumberInputComponent],
  imports: [TiimePipesModule, CommonModule, TiimeLetModule],
  declarations: [FormattedNumberInputComponent, FormattedNumberOutputPipe],
  providers: [DecimalPipe],
})
export class FormattedNumberInputModule {}
