<ng-container *tiimeLet="linkedEntities.length > 1 as isFull">
  <div
    *ngIf="linkedEntities.length > 0"
    class="linked-entities"
    [class.full]="isFull"
  >
    <div class="header">
      <div class="title">
        <ng-container
          *ngIf="
            entityType === LinkedEntityType.EXPENSE_REPORT;
            then expenses;
            else transactionMatches
          "
        ></ng-container>
        <ng-template #expenses>
          <img src="assets/icon_link.svg" alt="Notes de frais" />
          <span>lié à une note de frais</span>
        </ng-template>
        <ng-template #transactionMatches>
          <img src="assets/icon_link_ok.svg" alt="Transactions liées" />
          <span [ngPlural]="linkedEntities.length">
            <ng-template ngPluralCase="=1">
              {{ linkedEntities.length }} transaction liée
            </ng-template>
            <ng-template ngPluralCase="other">
              {{ linkedEntities.length }} transactions liées
            </ng-template>
          </span>
        </ng-template>
      </div>

      <button
        *ngIf="entityType !== LinkedEntityType.EXPENSE_REPORT"
        type="button"
        tiime-button
        inverted
        neutral
        xsmall
        icon
        (click)="editLink.emit()"
      >
        <mat-icon inline="true" fontIcon="icon-ic-sign" accent></mat-icon>
      </button>
    </div>

    <div
      *ngFor="let entity of linkedEntities; trackBy: trackById"
      class="linked-entity"
    >
      <ng-container
        *ngIf="
          entityType === LinkedEntityType.EXPENSE_REPORT;
          else transactions
        "
      >
        <app-category-icon
          [category]="StandardDocumentCategoryIdentifier.EXPENSE_REPORT"
        ></app-category-icon>
        <div class="infos">
          <div
            class="linked-entity-name"
            tiimeTooltip
            hideWithoutEllipsis
            tooltipPosition="bottom"
            [content]="fullWording"
          >
            {{ entity.value.wording }}
          </div>
          <ng-template #fullWording>
            {{ entity.value.wording }}
          </ng-template>
          <div
            *tiimeLet="
              entity | linkedEntityAmountAndCurrency as amountAndCurrency
            "
            class="linked-entity-amount"
            [class.positive]="amountAndCurrency.amount > 0"
          >
            {{
              amountAndCurrency.amount | currency: amountAndCurrency.currency
            }}
          </div>
          <div class="footer">
            <tiime-date-list-cell
              [date]="entity.value.date"
            ></tiime-date-list-cell>
          </div>
        </div>
      </ng-container>
      <ng-template #transactions>
        <button
          class="remove-transaction-btn"
          type="button"
          tiime-button
          neutral
          inverted
          icon
          (click)="unmatch(entity)"
        >
          <mat-icon inline="true" fontIcon="icon-ic-croix"></mat-icon>
        </button>

        <app-operation-type-logo
          [oneColor]="true"
          [operationType]="entity.value.operationType"
        ></app-operation-type-logo>

        <div class="infos">
          <div
            class="linked-entity-name"
            tiimeTooltip
            hideWithoutEllipsis
            tooltipPosition="bottom"
            [content]="fullWording"
          >
            {{ entity.value.wording }}
          </div>
          <ng-template #fullWording>
            {{ entity.value.wording }}
          </ng-template>
          <div
            *tiimeLet="
              entity | linkedEntityAmountAndCurrency as amountAndCurrency
            "
            class="linked-entity-amount"
            [class.positive]="amountAndCurrency.amount > 0"
          >
            {{
              amountAndCurrency.amount | currency: amountAndCurrency.currency
            }}
          </div>
          <div class="footer">
            <span>{{ entity.value.date | tiimeDatetime: 'dd MMM yyyy' }}</span>
            <ng-container *ngIf="entity.value.shortBankName">
              &nbsp;-&nbsp;
            </ng-container>
            {{ entity.value.shortBankName }}
          </div>
        </div>
      </ng-template>
    </div>
    <div *ngIf="isFull" class="see-more-items">
      <button
        type="button"
        tiime-button
        primary
        inverted
        [matMenuTriggerFor]="linkedTransactions"
      >
        Voir plus...
      </button>
      <mat-menu
        class="document-supp-menu"
        xPosition="before"
        yPosition="above"
        #linkedTransactions="matMenu"
      >
        <app-matched-item
          *ngFor="let entity of linkedEntities; trackBy: trackById"
          [item]="entity"
          (remove)="unmatch(entity)"
        ></app-matched-item>
      </mat-menu>
    </div>
    <div [class.gradient]="isFull"></div>
  </div>
</ng-container>
