import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  Optional,
  Output,
  ViewChild,
} from '@angular/core';
import { ControlValueAccessor, FormControl, NgControl } from '@angular/forms';
import {
  MatAutocompleteSelectedEvent,
  MatAutocompleteTrigger,
} from '@angular/material/autocomplete';
import { MatOptionSelectionChange } from '@angular/material/core';
import { NgUtils, StringUtils } from '@manakincubber/tiime-utils';
import { BehaviorSubject, combineLatest, Observable } from 'rxjs';
import { filter, map, startWith, switchMap, take, tap } from 'rxjs/operators';

import { Tag } from '@models';

import { DatasetIndicatorsSearchService } from '../../../home/indicators/dashboards/dashboards-shared/dashboard-customization/dataset-indicators-search.service';

@Component({
  selector: 'app-tag-selector-form-input',
  templateUrl: './tag-selector-form-input.component.html',
  styleUrls: ['./tag-selector-form-input.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TagSelectorFormInputComponent implements ControlValueAccessor {
  @ViewChild(MatAutocompleteTrigger)
  autocompleteTrigger: MatAutocompleteTrigger;

  @Input() set value(value: Tag[]) {
    if (value === null) return;
    this.value$.next(value);
  }

  @Input() floatingPlaceholder = false;
  @Input() placeholder: string;
  @Input() autofocus = false;

  @Input() set tagSuggestions(tagSuggestions: Tag[]) {
    if (tagSuggestions === null) return;
    this.tagSuggestions$.next(tagSuggestions);
  }

  @Input() showAutomaticTags: boolean;

  @Output() valueChange = new EventEmitter<Tag[]>();

  readonly tagControl = new FormControl('');
  readonly tagSuggestions$ = new BehaviorSubject<Tag[]>([]);
  readonly filteredTagSuggestions$ = combineLatest([
    this.tagSuggestions$,
    this.tagControl.valueChanges.pipe(
      startWith(''),
      filter((value: string | Tag) => !(value instanceof Tag)),
    ),
  ]).pipe(
    switchMap(([suggestions, filterValue]) =>
      filterValue
        ? this.filterSuggestions(filterValue as string, suggestions)
        : this.filterActiveTags(suggestions),
    ),
  );
  readonly value$ = new BehaviorSubject<Tag[]>([]);
  readonly trackById = NgUtils.trackById;
  readonly trackByIndex = NgUtils.trackByIndex;

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  private onChange: (value: Tag[]) => void = () => {};
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  private onTouched: () => void = () => {};

  constructor(
    private readonly cdr: ChangeDetectorRef,
    private readonly datasetIndicatorsSearchService: DatasetIndicatorsSearchService,
    @Optional() private readonly control?: NgControl,
  ) {
    if (control) {
      control.valueAccessor = this;
    }
  }

  writeValue(value: Tag[] | null): void {
    this.value$.next(value || []);
    this.cdr.markForCheck();
  }

  registerOnChange(fn: (tag: Tag[]) => void): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  createTag(event: MatOptionSelectionChange): void {
    if (!event.isUserInput) {
      return;
    }
    const tagToCreate = new Tag(null, event.source.viewValue);
    this.tagSuggestions$
      .pipe(
        take(1),
        tap(suggestions =>
          this.tagSuggestions$.next([...suggestions, tagToCreate]),
        ),
      )
      .subscribe();
  }

  removeTag(index: number): void {
    if (index > -1) {
      this.value$
        .pipe(
          take(1),
          tap(value => {
            const newTags = [...value];
            newTags.splice(index, 1);
            this.updateValue(newTags);
          }),
        )
        .subscribe();
    }
  }

  selectSuggestion(event: MatAutocompleteSelectedEvent): void {
    const newTag =
      event.option.value instanceof Tag
        ? event.option.value
        : new Tag(null, event.option.value as string);
    this.value$
      .pipe(
        take(1),
        tap(value => {
          const newTags = [...value, newTag];
          this.updateValue(newTags);
          this.tagControl.setValue('');
          setTimeout(() => this.autocompleteTrigger.openPanel(), 0);
        }),
      )
      .subscribe();
  }

  private filterActiveTags(suggestions: Tag[]): Observable<Tag[]> {
    return this.value$.pipe(
      take(1),
      map(value => {
        const currentTagNames = new Set(value.map(({ name }) => name));
        return suggestions.filter(({ name }) => !currentTagNames.has(name));
      }),
    );
  }

  private filterSuggestions(
    value: string,
    tagSuggestions: Tag[],
  ): Observable<Tag[]> {
    const filterValue = StringUtils.normalizeToLowerCase(value.trim());
    const filteredSuggestions = tagSuggestions.filter((tag: Tag) =>
      StringUtils.normalizeToLowerCase(tag.name).includes(filterValue),
    );
    return this.filterActiveTags(filteredSuggestions);
  }

  private updateValue(newValue: Tag[]): void {
    this.datasetIndicatorsSearchService.clearIndicators();
    this.value$.next(newValue);
    this.valueChange.emit(newValue);
    this.onChange(newValue);
    this.markAsTouched();
    this.cdr.markForCheck();
  }

  private markAsTouched(): void {
    if (!this.control?.touched) {
      this.onTouched();
    }
  }
}
