import { Pipe, PipeTransform } from '@angular/core';
import { DefaultGetOptions, Filter } from 'tiime-components';

@Pipe({
  name: 'toFilterValue',
})
export class ToFilterValuePipe implements PipeTransform {
  transform(
    filters: DefaultGetOptions['filters'] | undefined,
    filterKey: string,
  ): string | undefined {
    const filter = filters?.find(
      ({ key }: Filter<unknown>) => key === filterKey,
    );
    return filter?.values.join(filter.separator);
  }
}
