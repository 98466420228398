import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatSnackBarModule } from '@angular/material/snack-bar';

import { TiimeButtonModule } from '../button';
import { TiimeIconModule } from '../icon/icon.module';
import { SnackbarComponent } from './snackbar.component';

@NgModule({
  imports: [
    CommonModule,
    MatSnackBarModule,
    TiimeIconModule,
    TiimeButtonModule,
  ],
  declarations: [SnackbarComponent],
  exports: [SnackbarComponent],
})
export class TiimeSnackbarModule {}
