import { SelectionModel } from '@angular/cdk/collections';
import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnInit,
  inject,
} from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { tap } from 'rxjs';

@UntilDestroy()
@Component({
  standalone: true,
  imports: [CommonModule, MatIconModule],
  selector: 'app-mass-action-toolbar',
  templateUrl: './mass-action-toolbar.component.html',
  styleUrls: ['./mass-action-toolbar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MassActionToolBarComponent<T> implements OnInit {
  @Input() selectionModel: SelectionModel<T>;

  private readonly cdr = inject(ChangeDetectorRef);

  ngOnInit(): void {
    this.selectionModel.changed
      .pipe(
        tap(() => this.cdr.markForCheck()),
        untilDestroyed(this),
      )
      .subscribe();
  }

  close(): void {
    this.selectionModel.clear();
  }
}
