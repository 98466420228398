<ng-container *tiimeLet="filteredTagSuggestions$ | async as suggestions">
  <div
    *tiimeLet="value$ | async | displayableTags : showAutomaticTags as tags"
    class="container"
    [class.with-floating-placeholder]="floatingPlaceholder"
    (click)="tagInput.focus()"
  >
    <div
      class="tag-list-wrapper"
      scrollShadows
      matAutocompleteOrigin
      #origin="matAutocompleteOrigin"
    >
      <mat-chip-grid tabIndex="null" #chipList>
        <app-tag-chip
          *ngFor="let tag of tags; let i = index; trackBy: trackByIndex"
          [tag]="tag"
          [canRemove]="true"
          (remove)="removeTag(i)"
        ></app-tag-chip>
        <input
          id="tag"
          class="tag-input"
          data-cy="tag__input"
          #tagInput
          [tiimeAutoFocus]="autofocus"
          [matChipInputFor]="chipList"
          [formControl]="tagControl"
          [matAutocomplete]="tagAutocomplete"
          [matAutocompleteConnectedTo]="origin"
          [placeholder]="tags.length === 0 && placeholder"
        />
        <label
          *ngIf="!placeholder"
          for="tag"
          [class.float]="tags.length > 0 || tagControl.value"
        >
          {{ tags.length > 0 || tagControl.value ? 'Tags' : 'Aucun tag' }}
        </label>
      </mat-chip-grid>
      <mat-autocomplete
        class="edit-tag-autocomplete-panel"
        #tagAutocomplete="matAutocomplete"
        (optionSelected)="selectSuggestion($event)"
      >
        <mat-option
          *ngIf="tagControl.value | isTagInSomeList : tags"
          class="existing-tag-option"
        >
          Le tag est déjà saisi
        </mat-option>
        <mat-option
          *ngIf="
            (tagControl.value | isTagInSomeList : tags : suggestions) === false
          "
          class="tag-option"
          [value]="tagControl.value"
          (onSelectionChange)="createTag($event)"
        >
          <div class="create-suggestion" data-cy="tag-selector__btn-add">
            <mat-icon fontIcon="icon-add-fontastic"></mat-icon>
            <span class="option-text">Créer le tag</span>
          </div>
        </mat-option>
        <ng-container *ngIf="tagControl.value">
          <mat-option
            *ngFor="let suggestion of suggestions; trackBy: trackById"
            class="tag-option"
            [value]="suggestion"
          >
            <app-tag-chip [tag]="suggestion" [basic]="true"></app-tag-chip>
          </mat-option>
        </ng-container>
      </mat-autocomplete>
    </div>
  </div>
</ng-container>
