import { coerceBooleanProperty } from '@angular/cdk/coercion';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { FormUtils, Mapper } from '@manakincubber/tiime-utils';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { debounceTime, distinctUntilChanged, tap } from 'rxjs/operators';

import { SearchBarForm } from './search-bar-form';

@UntilDestroy()
@Component({
  selector: 'tiime-search-bar',
  templateUrl: './search-bar.component.html',
  styleUrls: ['./search-bar.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SearchBarComponent implements OnInit {
  @ViewChild('searchInput') readonly searchInput: ElementRef<HTMLInputElement>;

  @Input()
  placeholder: string;
  @Input()
  debounceTime = 500;
  @Input()
  enterToSearch = false;

  @Input() set disabled(value: boolean) {
    this._disabled = coerceBooleanProperty(value);
    if (this._disabled) {
      this.searchbarForm.search.disable(FormUtils.shouldNotEmitEvent);
    } else {
      this.searchbarForm.search.enable(FormUtils.shouldNotEmitEvent);
    }
  }
  get disabled(): boolean {
    return this._disabled;
  }

  @Output()
  readonly search: EventEmitter<string> = new EventEmitter<string>();

  @Output()
  readonly enter: EventEmitter<string> = new EventEmitter<string>();

  @Output()
  readonly clear: EventEmitter<void> = new EventEmitter<void>();

  focused = false;
  showClearButton = false;
  readonly searchbarForm: SearchBarForm = new SearchBarForm();

  private _disabled: boolean;

  readonly mapToIsActive: Mapper<boolean, boolean> = (
    focused: boolean,
    value: string,
  ) => focused || !!value;

  constructor(private cdr: ChangeDetectorRef) {}

  ngOnInit(): void {
    this.observeSearch();
  }

  clearSearch(focus = true): void {
    this.searchbarForm.reset();
    this.clear.emit();
    if (focus) {
      this.searchInput.nativeElement.focus();
    }
  }

  setFocused(focus: boolean): void {
    this.focused = focus;
  }

  setSearch(searchTerms: string): void {
    this.searchbarForm.search.setValue(
      searchTerms,
      FormUtils.shouldNotEmitEvent,
    );
    setTimeout(() => {
      this.showClearButton = !!searchTerms;
      this.cdr.markForCheck();
    });
  }

  onEnter(): void {
    if (this.enterToSearch) {
      this.enter.emit(this.searchbarForm.search.value);
    }
  }

  private observeSearch(): void {
    this.searchbarForm.search.valueChanges
      .pipe(
        tap((searchTerms: string) => (this.showClearButton = !!searchTerms)),
        debounceTime(this.debounceTime),
        distinctUntilChanged(),
        tap((searchTerms: string) => this.search.emit(searchTerms)),
        untilDestroyed(this),
      )
      .subscribe();
  }
}
