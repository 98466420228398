interface StatusLabels {
  draft: string;
  saved: string;
  paid: string;
  cancelled: string;
  sent: string;
}

interface CancelDialogLabels {
  title: string;
  confirm: string;
  message: {
    first_line: string;
    second_line: string;
  };
  reset: string;
}

interface InvoiceLabels {
  type: string;
  label: string;
  adjective: string;
  translationKey: string;
  status: StatusLabels;
  new: string;
  edit: string;
  create: string;
  createAndSend: string;
  created: string;
  save: string;
  saved: string;
  send: string;
  resend: string;
  cancel: string;
  cancelled: string;
  cancelDialog: CancelDialogLabels;
  entitled: string;
  sendMail: string;
  updateStatus: string;
  duplicate: string;
  duplicated: string;
  match: string;
  dun: string;
  sendMailSuccess: string;
  deleted: string;
}

export const INVOICE_LABEL: Record<string, InvoiceLabels> = {
  invoice: {
    type: 'Facture',
    label: 'Facture',
    adjective: 'cette facture',
    translationKey: 'invoice',
    status: {
      draft: 'Brouillon',
      saved: 'Facturée',
      paid: 'Payée',
      cancelled: 'Annulée',
      sent: 'Envoyée',
    },
    new: 'Nouvelle facture',
    edit: 'Modifier la facture',
    create: 'Créer la facture',
    createAndSend: 'Créer et envoyer la facture',
    created: 'Facture créée',
    save: 'Enregistrer',
    saved: 'Facture enregistrée',
    send: 'Envoyer la facture',
    resend: 'Renvoyer la facture',
    cancel: 'Annuler la facture',
    cancelled: 'Facture annulée',
    cancelDialog: {
      title: 'Souhaitez-vous annuler cette facture ?',
      confirm: 'Annuler la facture',
      message: {
        first_line: 'Pour la bonne tenue de votre compte',
        second_line: 'nous allons créer un avoir',
      },
      reset: 'Supprimer définitivement cette facture.',
    },
    entitled: 'Intitulé de la facture',
    sendMail: 'Envoyer une facture par mail',
    updateStatus: 'Statut de la facture',
    duplicate: 'Dupliquer la facture',
    duplicated: 'La facture a bien été dupliquée',
    match: 'Matcher la facture',
    dun: 'Relancer la facture',
    sendMailSuccess: 'La facture a bien été envoyée',
    deleted: 'La facture a bien été supprimée',
  },
  credit_note: {
    type: 'Avoir',
    label: 'Avoir',
    adjective: 'cet avoir',
    translationKey: 'creditNote',
    status: {
      draft: 'Brouillon',
      saved: 'Facturé',
      paid: 'Payé',
      cancelled: 'Annulé',
      sent: 'Envoyé',
    },
    new: 'Nouvel avoir',
    edit: `Modifier l'avoir`,
    create: `Créer l'avoir`,
    createAndSend: `Créer et envoyer l'avoir`,
    created: 'Avoir créé',
    save: `Enregistrer`,
    saved: 'Avoir enregistré',
    send: `Envoyer l'avoir`,
    resend: `Renvoyer l'avoir`,
    cancel: `Annuler l'avoir`,
    cancelled: 'Avoir annulé',
    cancelDialog: {
      title: 'Souhaitez-vous annuler cet avoir ?',
      confirm: `Annuler l'avoir`,
      message: {
        first_line: `Pour la bonne tenue de votre compte nous allons mettre le`,
        second_line: `montant de l'avoir à 0€, ce qui aura pour effet de l'annuler.`,
      },
      reset: 'Supprimer définitivement cet avoir.',
    },
    entitled: `Intitulé de l'avoir`,
    sendMail: 'Envoyer un avoir par mail',
    updateStatus: `Statut de l'avoir`,
    duplicate: `Dupliquer l'avoir`,
    duplicated: `L'avoir a bien été dupliqué`,
    match: `Matcher l'avoir`,
    dun: `Relancer l'avoir`,
    sendMailSuccess: `L'avoir a bien été envoyé`,
    deleted: `L'avoir a bien été supprimé`,
  },
  advance_payment: {
    type: `Facture d'acompte`,
    label: 'Facture',
    adjective: 'cet acompte',
    translationKey: 'advancePayment',
    status: {
      draft: 'Brouillon',
      saved: 'Facturée',
      paid: 'Payée',
      cancelled: 'Annulée',
      sent: 'Envoyée',
    },
    new: 'Nouvelle facture',
    edit: 'Modifier la facture',
    create: 'Créer la facture',
    createAndSend: 'Créer et envoyer la facture',
    created: 'Facture créée',
    save: 'Enregistrer',
    saved: 'Facture enregistrée',
    send: 'Envoyer la facture',
    resend: 'Renvoyer la facture',
    cancel: 'Annuler la facture',
    cancelled: 'Facture annulée',
    cancelDialog: {
      title: 'Souhaitez-vous annuler cette facture ?',
      confirm: 'Annuler la facture',
      message: {
        first_line: 'Pour la bonne tenue de votre compte',
        second_line: 'nous allons créer un avoir',
      },
      reset: 'Supprimer définitivement cette facture.',
    },
    entitled: 'Intitulé de la facture',
    sendMail: 'Envoyer une facture par mail',
    updateStatus: 'Statut de la facture',
    duplicate: 'Dupliquer la facture',
    duplicated: 'La facture a bien été dupliquée',
    match: 'Matcher la facture',
    dun: 'Relancer la facture',
    sendMailSuccess: 'La facture a bien été envoyée',
    deleted: 'La facture a bien été supprimée',
  },
};
