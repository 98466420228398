import { HttpClient } from '@angular/common/http';
import { Injectable, Injector } from '@angular/core';
import { of, Subject } from 'rxjs';
import { tap } from 'rxjs/operators';

import { environment, RuntimeEnvironment } from '@environments';

@Injectable({
  providedIn: 'root',
})
export class AppConfigService {
  private config: RuntimeEnvironment;
  public isConfigReady$ = new Subject();

  constructor(private injector: Injector) {}

  appConfigFactory<T>(fn: (...args: unknown[]) => unknown): () => Promise<T> {
    return (): Promise<T> =>
      new Promise((resolve, reject) => {
        this.isConfigReady$.pipe(tap(() => fn())).subscribe(resolve, reject);
      });
  }

  loadAppConfig(): Promise<void | RuntimeEnvironment> {
    const http = this.injector.get(HttpClient);

    if (environment.dynamic) {
      return http
        .get<RuntimeEnvironment>('/assets/config/app-config.json')
        .toPromise()
        .then(data => (this.appConfig = data));
    }
    return of()
      .toPromise()
      .then(() => (this.appConfig = environment));
  }

  get appConfig(): RuntimeEnvironment {
    return this.config;
  }

  set appConfig(config: RuntimeEnvironment) {
    this.config = config;
    this.isConfigReady$.next(true);
  }
}
