import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  Input,
  ViewChild,
} from '@angular/core';
import { FormArray, FormControl } from '@angular/forms';
import { MatMenuTrigger } from '@angular/material/menu';
import { NgUtils } from '@manakincubber/tiime-utils';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { BehaviorSubject } from 'rxjs';
import { tap } from 'rxjs/operators';

import { User } from '@models';

import { TableColumnFilterDirective } from '../table-column-filter.directive';
import { TableUserFilterForm } from './table-user-filter.form';

@UntilDestroy()
@Component({
  selector: 'app-table-user-filter',
  templateUrl: './table-user-filter.component.html',
  styleUrls: [
    '../table-column-filter-base.scss',
    '../table-column-filter-list-base.scss',
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TableUserFilterComponent extends TableColumnFilterDirective {
  @Input() set isOpen(value: boolean) {
    if (value) {
      this.searchControl.setValue('');
      this.focusSearchInput();
    }
  }
  @Input() set users(value: User[]) {
    const sortedUsers = value?.sort((a, b) =>
      a.firstName.localeCompare(b.firstName),
    );
    this._users = sortedUsers;
    this.filteredUsers = sortedUsers;
    if (sortedUsers && this.form) {
      this.form.users = sortedUsers;
      this.form.fromParam(this.filterValue ?? '');
    }
  }
  get users(): User[] {
    return this._users;
  }
  get filtersFormArray(): FormArray {
    return this.form.controls.filters as FormArray;
  }

  searchControl = new FormControl();
  filteredUsers: User[];
  form: TableUserFilterForm;
  private _users: User[];
  readonly filterKey = 'owners';
  readonly isSearching$ = new BehaviorSubject<boolean>(false);
  readonly trackById = NgUtils.trackById;

  @ViewChild('search') private readonly search: ElementRef<HTMLInputElement>;

  constructor(menuTrigger: MatMenuTrigger) {
    super(menuTrigger);
    this.initSearch();
  }

  protected initForm(): void {
    this.form = new TableUserFilterForm(this.filterKey, this._users ?? []);
    this.form.fromParam(this.filterValue ?? '');
  }

  private focusSearchInput(): void {
    setTimeout(() => {
      this.search.nativeElement.focus();
    });
  }

  private initSearch(): void {
    this.searchControl.valueChanges
      .pipe(
        tap((value: string) => this.isSearching$.next(!!value?.length)),
        tap((value: string) => {
          this.filteredUsers = this.users?.filter(t =>
            `${t.firstName} ${t.lastName}`
              .toLowerCase()
              .includes(value.toLowerCase()),
          );
        }),
        untilDestroyed(this),
      )
      .subscribe();
  }
}
