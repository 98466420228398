import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Observable, Subject, Subscription } from 'rxjs';
import { catchError, filter, map, switchMap, tap } from 'rxjs/operators';
import { SnackbarConfig, TiimeSnackbarService } from 'tiime-components';

import { StrongCustomerAuthenticationService } from './auth';
import {
  defaultGenerator,
  REDIRECTION_RULES,
} from './constants/redirections.constants';
import { ScaCreateSession } from './models';
import { CompaniesService } from './services';
import { getCompanyIdFromUrl } from './utils/selected-company.util';

type AppPath = 'home' | 'invoice' | 'account' | 'documents';

export interface Reload {
  reload(): void;
}

export interface ReloadAfterCompanyChanged {
  readonly companyChangedSub: Subscription;
}

@UntilDestroy()
@Injectable({
  providedIn: 'root',
})
export class ReloadService {
  readonly #currentAppMatcher = /^\/companies\/\d+\/([a-z-]+)(\/.*)?/;
  readonly #navigationSubject$ = new Subject<void>();

  #currentCompanyId?: number;
  readonly #companyChangedSubject$ = new Subject<void>();

  /**
   * This Observable can still be used for views that can be updated and do not rely on url or query params
   * (mostly components that rely on the stored selected company)
   */
  get navigationSubject(): Observable<void> {
    return this.#navigationSubject$.asObservable();
  }

  /**
   * This Observable can be used for views that needs to be updated after the selected company has changed.
   * As it is updated after the route has been updated it is safe to use for views such as lists which rely on query params.
   */
  get companyChanged(): Observable<void> {
    return this.#companyChangedSubject$.asObservable();
  }

  constructor(
    private router: Router,
    private companiesService: CompaniesService,
    private scaService: StrongCustomerAuthenticationService,
    private snackbar: TiimeSnackbarService,
  ) {
    this.#currentCompanyId = getCompanyIdFromUrl(router);
    router.events
      .pipe(filter(event => event instanceof NavigationEnd))
      .pipe(
        tap(() => {
          const currentCompanyId = getCompanyIdFromUrl(router);
          if (this.#currentCompanyId !== currentCompanyId) {
            this.#currentCompanyId = currentCompanyId;
            this.#companyChangedSubject$.next();
          }
        }),
        untilDestroyed(this),
      )
      .subscribe();
  }

  navigateToCompany(companyId: number): void {
    const currentUrl = this.router.url;
    const generateRedirectUrl = this.getRedirectUrlGenerator(currentUrl);
    const redirectUrl = generateRedirectUrl(companyId);
    this.checkForSCA(companyId, redirectUrl).subscribe();
  }

  reloadCurrentView(): void {
    this.#navigationSubject$.next();
  }

  private checkForSCA(
    companyId: number,
    redirectUrl: string,
  ): Observable<unknown> {
    return this.mustSca$(companyId).pipe(
      switchMap(mustSca => {
        return mustSca
          ? this.scaAuthenticationForWallet(companyId, redirectUrl)
          : void this.router.navigate([redirectUrl], { queryParams: {} });
      }),
    );
  }

  private scaAuthenticationForWallet(
    companyId: number,
    redirectUrl: string,
  ): Observable<unknown> {
    return this.scaService
      .authenticate(
        new ScaCreateSession(),
        {
          hasBackdrop: false,
          displayValidationStep: false,
        },
        companyId,
        true,
      )
      .pipe(
        switchMap(() => {
          return void this.router.navigate([redirectUrl], { queryParams: {} });
        }),
        catchError(({ error }: HttpErrorResponse) => {
          this.snackbar.open(
            "Une erreur s'est produite lors de l'authentification forte. Merci de vérifier votre application mobile.",
            SnackbarConfig.error,
          );
          throw error;
        }),
      );
  }

  private mustSca$(companyId: number): Observable<boolean> {
    return this.companiesService.get(companyId).pipe(
      map(company => {
        const hasWalletAccess = company.walletAccess ?? false;
        const hasScaTreezor = company.walletCompany?.scaTreezor ?? false;
        return hasWalletAccess && hasScaTreezor;
      }),
    );
  }

  private getAppPathFrom(url: string): AppPath | null {
    const matchedGroups = this.#currentAppMatcher.exec(url);
    if (!matchedGroups) {
      return null;
    }
    const firstMatch = matchedGroups[1];
    if (!REDIRECTION_RULES[firstMatch]) {
      return null;
    }

    return firstMatch as AppPath;
  }

  private getRedirectUrlGenerator(url: string): (companyId?: number) => string {
    const appPath = this.getAppPathFrom(url);
    if (!appPath) {
      return defaultGenerator;
    }
    for (const rule of REDIRECTION_RULES[appPath]) {
      const currentUrlMatchesRoute = rule.routePattern.test(url);
      if (currentUrlMatchesRoute) {
        return rule.generateRedirectUrl;
      }
    }
    return defaultGenerator;
  }
}
