import { NgOptimizedImage } from '@angular/common';
import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import {
  TiimeButtonModule,
  TiimeDialogModule,
  TiimeIconModule,
} from 'tiime-components';

import { CareContactDialogComponent } from '../../../../../company-shared/components';

@Component({
  standalone: true,
  imports: [
    TiimeButtonModule,
    TiimeDialogModule,
    TiimeIconModule,
    NgOptimizedImage,
  ],
  selector: 'app-bank-transfer-document-info-dialog',
  templateUrl: './bank-transfer-document-info-dialog.component.html',
  styleUrls: ['./bank-transfer-document-info-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BankTransferInfoDialogComponent {
  private readonly dialog = inject(MatDialog);

  onSupportContact(): void {
    this.dialog.open<CareContactDialogComponent>(CareContactDialogComponent);
  }
}
