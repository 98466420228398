import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { PRIMARY_OUTLET, Router } from '@angular/router';
import { Observable, Subject } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import {
  GetOptions,
  PaginationData,
  RequiredGetOptions,
} from 'tiime-components';

import { TemporaryEncoder } from '@core/temporary-encoder';
import { ApiAlertError } from '@decorators/api-alert-error';
import { HttpHelper } from '@helpers';
import {
  DailyRecord,
  DailyRecordApiContract,
  DailyRecordDetail,
  DailyRecordDetailApiContract,
} from '@models/cash-tracking';

@Injectable({
  providedIn: 'root',
})
export class DailyRecordsService {
  private get resource(): string {
    return 'api/v1/companies/{companyId}/cash_registers/{cashRegisterId}/daily_records'.replace(
      '{cashRegisterId}',
      this.getCashRegisterIdFromUrl(),
    );
  }

  private _dailyRecordUpserted$ = new Subject<DailyRecordDetail>();

  constructor(
    private readonly http: HttpClient,
    private readonly router: Router,
  ) {}

  get dailyRecordUpserted$(): Observable<DailyRecordDetail> {
    return this._dailyRecordUpserted$.asObservable();
  }

  @ApiAlertError()
  getAll(
    getOptions: RequiredGetOptions<'range'>,
  ): Observable<PaginationData<DailyRecord>> {
    const params = new HttpParams({
      fromObject: new GetOptions(getOptions).toHttpGetOptions().params,
      encoder: new TemporaryEncoder(),
    });

    const headers = HttpHelper.setRangeHeader(
      new HttpHeaders(),
      getOptions.range,
    );

    const options = { params, headers };

    return this.http
      .get(this.resource, { ...options, observe: 'response' })
      .pipe(
        HttpHelper.mapToPaginationData(
          getOptions.range,
          (json: DailyRecordApiContract) => DailyRecord.fromJson(json),
        ),
      );
  }

  @ApiAlertError()
  get(id: number): Observable<DailyRecordDetail> {
    return this.http
      .get<DailyRecordDetailApiContract>(`${this.resource}/${id}`)
      .pipe(
        map(dailyRecordDetail => DailyRecordDetail.fromJson(dailyRecordDetail)),
      );
  }

  @ApiAlertError()
  create(dailyRecordDetail: DailyRecordDetail): Observable<DailyRecordDetail> {
    return this.http
      .post<DailyRecordDetailApiContract>(
        this.resource,
        DailyRecordDetail.toJson(dailyRecordDetail),
      )
      .pipe(
        map(dailyRecordDetailJson =>
          DailyRecordDetail.fromJson(dailyRecordDetailJson),
        ),
        tap(dailyRecordDetail =>
          this._dailyRecordUpserted$.next(dailyRecordDetail),
        ),
      );
  }

  @ApiAlertError()
  update(dailyRecordDetail: DailyRecordDetail): Observable<DailyRecordDetail> {
    return this.http
      .put<DailyRecordDetailApiContract>(
        `${this.resource}/${dailyRecordDetail.id}`,
        DailyRecordDetail.toJson(dailyRecordDetail),
      )
      .pipe(
        map(dailyRecordDetailJson =>
          DailyRecordDetail.fromJson(dailyRecordDetailJson),
        ),
        tap(dailyRecordDetail =>
          this._dailyRecordUpserted$.next(dailyRecordDetail),
        ),
      );
  }

  private getCashRegisterIdFromUrl = (): string | null => {
    const url = this.router?.parseUrl(this.router?.url);

    if (!url) {
      return null;
    }

    const primaryOutletSegments = url.root.children[PRIMARY_OUTLET].segments;
    return primaryOutletSegments[primaryOutletSegments.length - 1].path;
  };
}
