import {
  ChangeDetectionStrategy,
  Component,
  HostBinding,
  Input,
} from '@angular/core';
import { AbstractTiimeChipDirective } from 'tiime-components';

import { Tag } from '@models';

@Component({
  selector: 'app-tag-chip',
  templateUrl: './tag-chip.component.html',
  styleUrls: ['./tag-chip.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TagChipComponent extends AbstractTiimeChipDirective {
  @Input() tag: Tag;

  @HostBinding('class.small')
  @Input()
  small = false;

  @HostBinding('class.large')
  @Input()
  large = false;
}
