import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { map, Observable, of, switchMap } from 'rxjs';

import { TransactionsService } from '@core';
import { filterNotNullOrUndefined } from '@helpers';
import { LinkedEntityBankTransaction } from '@models/linked-entities';

import {
  LabelOverloadDialogResponse,
  SelectLabelDialogComponent,
  SelectLabelDialogData,
} from './select-label-dialog.component';

export enum LabelOverloadStatus {
  AVAILABLE = 'available',
  UNAVAILABLE = 'unavailable',
}

@Injectable({
  providedIn: 'root',
})
export class SelectingLabelDialogService {
  constructor(
    private readonly matDialog: MatDialog,
    private readonly transactionsService: TransactionsService,
  ) {}

  openLabelOverloadDialogIfAvailable(
    dialogData: SelectLabelDialogData,
  ): Observable<LabelOverloadDialogResponse> {
    const doc = dialogData.document;
    const transaction = dialogData.transaction;
    return this.transactionsService
      .getLabelOverloadStatus(
        doc.id,
        transaction instanceof LinkedEntityBankTransaction
          ? transaction.value.id
          : transaction.id,
      )
      .pipe(
        switchMap((status: { label_overload_status: string }) => {
          if (status.label_overload_status === LabelOverloadStatus.AVAILABLE) {
            return this.matDialog
              .open<
                SelectLabelDialogComponent,
                SelectLabelDialogData,
                LabelOverloadDialogResponse
              >(SelectLabelDialogComponent, {
                data: dialogData,
                width: '600px',
                height: '549px',
              })
              .afterClosed()
              .pipe(
                filterNotNullOrUndefined(),
                map(labelOverloadDialogResponse => ({
                  ...labelOverloadDialogResponse,
                  labelOverloadCheckNeeded: true,
                })),
              );
          } else {
            return of({ labelOverloadUnavailable: true });
          }
        }),
      );
  }
}
