import { HomeWallet } from './home-wallet';

export interface HomeMarketingActionsApiContract {
  can_add_promo_code?: boolean;
}

export class HomeMarketingActions {
  get hasAction(): boolean {
    return this.canAddPromoCode;
  }

  constructor(public canAddPromoCode?: boolean) {}

  public static fromJson(
    json: HomeMarketingActionsApiContract,
  ): HomeMarketingActions {
    return new HomeMarketingActions(json.can_add_promo_code);
  }

  public static fromHomeWallet(homeWallet: HomeWallet): HomeMarketingActions {
    return new HomeMarketingActions(homeWallet.canAddPromoCode);
  }
}
