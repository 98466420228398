import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Input,
  Output,
  EventEmitter,
} from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Mapper } from '@manakincubber/tiime-utils';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import {
  AdvancedTableBase,
  MonoValueFilter,
  PaginationData,
  RequiredGetOptions,
  TableState,
} from 'tiime-components';

import { DocumentsService } from '@core';
import { Document } from '@models';
import { LinkedEntityType } from '@models/linked-entities';

@UntilDestroy()
@Component({
  selector: 'app-receipts-to-match-table',
  templateUrl: './receipts-to-match-table.component.html',
  styleUrls: ['./receipts-to-match-table.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ReceiptsToMatchTableComponent
  extends AdvancedTableBase<Document>
  implements OnInit
{
  @Input() selectedReceipt: Document;

  @Output() selectedReceiptChange = new EventEmitter<Document>();

  readonly LinkedEntityType = LinkedEntityType;

  readonly displayedColumns = [
    'date',
    'provider',
    'amount',
    'vatAmount',
    'matches',
    'comments',
  ];

  readonly mapToReceiptRowClass: Mapper<Document, Record<string, boolean>> = (
    receipt: Document,
    selectedReceipt: Document,
  ) => ({
    selected: selectedReceipt && selectedReceipt.id === receipt.id,
    'pending-row': receipt.hasPendingOcr,
  });

  constructor(
    protected readonly router: Router,
    protected readonly route: ActivatedRoute,
    protected readonly cdr: ChangeDetectorRef,
    private readonly documentsService: DocumentsService,
  ) {
    super(router, route, cdr);
  }

  ngOnInit(): void {
    this.tableSubscription = this.initTableObservable().subscribe();
  }

  selectReceipt(receipt: Document): void {
    this.selectedReceiptChange.emit(receipt);
  }

  protected initTableObservable(): Observable<PaginationData<Document>> {
    this.setState(TableState.contentPlaceholder);
    return this.getTableData().pipe(
      this.initReloadSubject(),
      untilDestroyed(this),
    );
  }

  protected getTableData(): Observable<PaginationData<Document>> {
    return this.documentsService
      .getDocuments({
        ...this.getOptions,
        filters: [new MonoValueFilter('matchable', true)],
      })
      .pipe(
        tap((paginationData: PaginationData<Document>) => {
          this.paginationData = paginationData;
          this.updateState(paginationData, this.getOptions);
        }),
      );
  }

  private updateState(
    paginationData: PaginationData<Document>,
    getOptions: RequiredGetOptions<'range'>,
  ): void {
    if (paginationData.data.length > 0) {
      this.setState(TableState.done);
    } else if (!getOptions.search && getOptions.range.min === 0) {
      this.setState(TableState.onboard);
    } else {
      this.setState(TableState.noResult);
    }
    this.cdr.markForCheck();
  }
}
