import { TextFieldModule } from '@angular/cdk/text-field';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatChipsModule } from '@angular/material/chips';

import { TiimeButtonModule } from '../button/index';
import { TiimeIconModule } from '../icon/index';
import { TiimeInputContainerModule } from '../input-container/index';
import { TiimeLetModule } from '../let/index';
import { TiimeTagModule } from '../tag/index';
import { TiimeMailFormComponent } from './mail-form/mail-form.component';
import { TiimeMailRecipientComponent } from './mail-recipient/mail-recipient.component';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    TextFieldModule,
    MatChipsModule,
    TiimeButtonModule,
    TiimeInputContainerModule,
    TiimeTagModule,
    TiimeIconModule,
    TiimeLetModule,
  ],
  declarations: [TiimeMailRecipientComponent, TiimeMailFormComponent],
  exports: [TiimeMailRecipientComponent, TiimeMailFormComponent],
})
export class TiimeMailModule {}
