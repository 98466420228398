import { TransactionFilterAmplitudeType } from '@core/amplitude';
import { TransactionComplexFilterKey } from '@core/enum/transactions';

export const TRANSACTION_FILTERS = [
  ...Object.values(TransactionComplexFilterKey),
  'labels',
  'operation_type',
  'bank_account',
];

export const TRANSACTION_FILTER_TO_AMPLITUDE = new Map<
  string,
  TransactionFilterAmplitudeType
>([
  ['q', 'libre'],
  ['labels', 'label'],
  ['label_name', 'label'],
  ['operation_type', 'methode'],
  ['bank_account', 'banque'],
  ['comment', 'commentaire'],
  ['wording', 'intitule'],
  ['amount_type', 'credit_debit'],
  ['amount', 'montant'],
  ['date', 'date'],
  ['included_ids', 'id'],
  ['tags', 'tag'],
  ['matched', 'document_relie'],
  ['accountant_detail_request', 'demandes_comptables'],
]);
