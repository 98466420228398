export class WalletLegalInformations {
  constructor(
    public hasNonEuBusinessActivities?: boolean,
    public isSubjectToEconomicSanctions?: boolean,
    public isPhysicallyPresentInCountriesSubjectToSanctions?: boolean,
    public isInvolvedInEntitiesSubjectToSanctions?: boolean,
  ) {}

  toJson(): any {
    return {
      activity_outside_eu:
        this.hasNonEuBusinessActivities ?? false,
      economic_sanctions:
        this.isSubjectToEconomicSanctions ?? false,
      resident_countries_sanctions:
        this.isPhysicallyPresentInCountriesSubjectToSanctions ?? false,
      involved_sanctions:
        this.isInvolvedInEntitiesSubjectToSanctions ?? false,
    };
  }
}
