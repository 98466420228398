import { Pipe, PipeTransform } from '@angular/core';
import { FormControl } from '@angular/forms';

import { StringHelper } from '@helpers';
import { Label } from '@models/labels';

@Pipe({
  standalone: true,
  name: 'canCreateLabelOrTag',
})
export class CanCreateLabelOrTagPipe implements PipeTransform {
  transform(
    data: FormControl<string>,
    searchResults: Label[],
    enabledByConfig: boolean,
  ): boolean {
    return (
      enabledByConfig &&
      data.dirty &&
      data.value &&
      data.value.trim().length > 0 &&
      !this.doesLabelOrClientExist(data, searchResults)
    );
  }

  private doesLabelOrClientExist(
    data: FormControl<string>,
    labels: Label[],
  ): boolean {
    const sameNames = labels?.filter((label: Label) =>
      StringHelper.equalsWithoutAccents(data.value.trim(), label.label),
    );
    return sameNames?.length > 0;
  }
}
