<span *ngIf="forList; else defaultTemplate">
  {{ payout.recurrency | frequencyLabel }}
  <ng-container *ngIf="payout | map : mapToShouldDisplayUntilDate">
    <br />
    jusqu'au
    {{ payout.endDate | formattedDate }}
  </ng-container>
</span>

<ng-template #defaultTemplate>
  <div>
    <span data-cy="payout-frequency-recurrency">
      <mat-icon svgIcon="icon-synchro"></mat-icon>
      {{ payout.recurrency | frequencyLabel }}
    </span>

    <span *ngIf="payout | map : mapToShouldDisplayUntilDate">
      <mat-icon svgIcon="t-ic_calendar"></mat-icon>
      Jusqu'au
      {{ payout.endDate | formattedDate }}
    </span>
  </div>
</ng-template>
