import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { TiimeChipModule, TiimeTooltipModule } from 'tiime-components';

import {
  LabelArchivedIconComponent,
  LabelArchivedTextComponent,
} from '@shared';

import { LabelChipPlaceholderComponent } from '../label-chip-placeholder/label-chip-placeholder.component';
import { LabelChipComponent } from './label-chip.component';
import { LabelIconModule } from './label-icon/label-icon.module';

@NgModule({
  declarations: [LabelChipComponent, LabelChipPlaceholderComponent],
  imports: [
    CommonModule,
    MatIconModule,
    LabelIconModule,
    LabelArchivedIconComponent,
    LabelArchivedTextComponent,
    TiimeChipModule,
    TiimeTooltipModule,
  ],
  exports: [LabelChipComponent, LabelChipPlaceholderComponent],
})
export class LabelChipModule {}
