import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import { NgUtils } from '@manakincubber/tiime-utils';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

import { LabelSuggestionContext } from '@enums';
import { CashRegister } from '@models/cash-tracking';
import { Label } from '@models/labels';
import { CashRegistersService } from '@services/cash-tracking';
import { LabelsService } from '@services/labels.service';

import { LabelChipModule } from '../../../labels';
import { LastUsedLabelsTextPipe } from './last-used-labels-text.pipe';

@UntilDestroy()
@Component({
  standalone: true,
  imports: [CommonModule, LabelChipModule, LastUsedLabelsTextPipe],
  selector: 'app-last-used-labels',
  templateUrl: './last-used-labels.component.html',
  styleUrls: ['./last-used-labels.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LastUsedLabelsComponent {
  @Input() set transactionId(transactionId: number) {
    if (transactionId != null) {
      this.shouldDisplaySuggestions = true;
      this.lastUsedLabels$ =
        this.labelsService.getLastUsedTransactionLabels(transactionId);
      this.loadLastUsedLabels();
    }
  }

  @Input() set documentId(documentId: number) {
    if (documentId != null) {
      this.shouldDisplaySuggestions = true;
      this.lastUsedLabels$ =
        this.labelsService.getLastUsedDocumentLabels(documentId);
      this.loadLastUsedLabels();
    }
  }

  @Input() showLastUsedLabels: boolean;

  @Output()
  readonly selectedLabel = new EventEmitter<Label>();

  lastUsedLabels: Label[];
  lastLabelsLoaded = false;

  lastUsedLabels$: Observable<Label[]>;

  /**
   * true => "Suggestion(s)",
   * false => "Dernier(s) label(s) utilisé(s)"
   */
  shouldDisplaySuggestions = false;

  readonly trackById = NgUtils.trackById;

  private readonly selectedCashRegister?: CashRegister;

  constructor(
    private readonly labelsService: LabelsService,
    private readonly cashRegistersService: CashRegistersService,
    private readonly cdr: ChangeDetectorRef,
  ) {
    this.selectedCashRegister = this.cashRegistersService.selectedCashRegister;
    this.initForCashRegister();
  }

  private initForCashRegister(): void {
    if (this.selectedCashRegister) {
      if (this.selectedCashRegister.hasCashFlow) {
        this.lastUsedLabels$ = this.labelsService.getRecentlyUsedLabels(
          3,
          LabelSuggestionContext.CashRegister,
        );
      } else {
        this.shouldDisplaySuggestions = true;
        this.lastUsedLabels$ =
          this.cashRegistersService.getInitialCashRegisterLabels(
            this.selectedCashRegister.id,
          );
      }
      this.loadLastUsedLabels();
    }
  }

  private loadLastUsedLabels(): void {
    if (this.lastUsedLabels$) {
      this.lastUsedLabels$
        .pipe(
          tap(labels => {
            this.lastUsedLabels = labels;
            this.lastLabelsLoaded = true;
            this.showLastUsedLabels = this.showLastUsedLabels
              ? this.lastUsedLabels.length > 0
              : this.showLastUsedLabels;
            this.cdr.markForCheck();
          }),
          untilDestroyed(this),
        )
        .subscribe();
    }
  }
}
