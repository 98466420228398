<mat-menu class="transaction-filter-menu actions-filter">
  <div
    class="container"
    (click)="$event.stopPropagation()"
    (keydown.tab)="$event.stopPropagation()"
  >
    <form
      *ngIf="todoCounts?.message + todoCounts?.document > 0"
      [formGroup]="todoForm"
    >
      <div class="title">
        <span>Filtrer les actions</span>
        <mat-icon
          class="reset-filter-btn"
          svgIcon="icon-reset"
          (click)="reset()"
        ></mat-icon>
      </div>

      <div class="todo-filter-container">
        <mat-slide-toggle
          *ngIf="todoCounts.message"
          color="primary"
          labelPosition="after"
          formControlName="message"
        >
          Uniquement les messages
          <span class="todo-counts">{{ todoCounts.message }}</span>
        </mat-slide-toggle>
        <mat-slide-toggle
          *ngIf="todoCounts.document"
          color="primary"
          labelPosition="after"
          formControlName="document"
        >
          Uniquement les demandes de documents
          <span class="todo-counts">{{ todoCounts.document }}</span>
        </mat-slide-toggle>
      </div>
    </form>

    <div class="title">
      <span>Transactions reliées à un document</span>
      <mat-icon
        *ngIf="todoCounts?.message + todoCounts?.document === 0"
        class="reset-filter-btn"
        svgIcon="icon-reset"
        (click)="reset()"
      ></mat-icon>
    </div>

    <form [formGroup]="form" (ngSubmit)="applyWithTodoFilters()">
      <div class="filter-container">
        <mat-radio-group formControlName="matched">
          <div>
            <mat-radio-button color="primary" [value]="allTransactionsKey">
              Toutes
            </mat-radio-button>
          </div>
          <div>
            <mat-radio-button color="primary" value="true">
              Reliées
            </mat-radio-button>
          </div>
          <div>
            <mat-radio-button color="primary" value="false">
              Non reliées
            </mat-radio-button>
          </div>
        </mat-radio-group>
      </div>
      <button class="apply" tiime-button xsmall accent>Appliquer</button>
    </form>
  </div>
</mat-menu>
