import { FormControl, FormGroup } from '@angular/forms';

export class ExpenseReportEditorLineForm<T> extends FormGroup {
  get line(): FormControl<T> {
    return this.get('line') as FormControl<T>;
  }

  get checked(): FormControl<boolean> {
    return this.get('checked') as FormControl;
  }

  constructor(lineData: T, checked?: boolean) {
    super({
      line: new FormControl(lineData),
      checked: new FormControl(checked || false),
    });
  }
}
