import { FormGroup } from '@angular/forms';

import { SddPayin, SddPayinRecurrencyEnum } from '@models/wallet/payins';

import { DateHelper } from '../../helpers/date.helper';
import { CreateSddPayinInitiationForm } from './create-sdd-payin-initiation-form';
import { CreateSddPayinSequenceForm } from './create-sdd-payin-sequence-form';

export class CreateSddPayinForm extends FormGroup {
  get initiation(): CreateSddPayinInitiationForm {
    return this.get('initiation') as CreateSddPayinInitiationForm;
  }

  get sequence(): CreateSddPayinSequenceForm {
    return this.get('sequence') as CreateSddPayinSequenceForm;
  }

  constructor() {
    super({
      initiation: new CreateSddPayinInitiationForm(),
      sequence: new CreateSddPayinSequenceForm(),
    });
  }

  toSddPayin(): SddPayin {
    return new SddPayin(
      undefined,
      this.sequence.recurrency.value,
      this.sequence.label.value ?? null,
      this.initiation.amount.value,
      DateHelper.format(this.sequence.date.value),
      this.sequence.indefinitely.value ||
      !this.sequence.endDate.value ||
      this.sequence.recurrency.value === SddPayinRecurrencyEnum.ONCE
        ? null
        : DateHelper.format(this.sequence.endDate.value),
      undefined,
      undefined,
    );
  }
}
