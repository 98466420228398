import { Document } from '@models';
import {
  DailyRecord,
  DailyRecordApiContract,
} from '@models/cash-tracking/daily-record';

import { CashFlow, CashFlowApiContract } from './cash-flow';

export interface DailyRecordDetailApiContract extends DailyRecordApiContract {
  cash_flows: CashFlowApiContract[];
}

export class DailyRecordDetail extends DailyRecord {
  constructor(
    public id?: number,
    public date?: string,
    public balance?: number,
    public previousBalance?: number,
    public zTickets?: Document[],
    public totalInflows?: number,
    public totalOutflows?: number,
    public comment?: string,
    public cashFlows?: CashFlow[],
  ) {
    super(
      id,
      date,
      balance,
      previousBalance,
      zTickets,
      totalInflows,
      totalOutflows,
      comment,
    );
  }

  static fromJson(json: DailyRecordDetailApiContract): DailyRecordDetail {
    return new DailyRecordDetail(
      json.id,
      json.date,
      json.balance,
      json.previous_balance,
      json.z_tickets ? json.z_tickets.map(Document.fromJson) : [],
      json.total_inflows,
      json.total_outflows,
      json.comment,
      json.cash_flows ? json.cash_flows.map(CashFlow.fromJson) : [],
    );
  }

  static toJson(dailyRecord: DailyRecordDetail): DailyRecordDetailApiContract {
    return {
      id: dailyRecord.id,
      date: dailyRecord.date,
      balance: dailyRecord.balance,
      previous_balance: dailyRecord.previousBalance,
      z_tickets: dailyRecord.zTickets
        ? dailyRecord.zTickets?.map(Document.toJson)
        : [],
      total_inflows: dailyRecord.totalInflows,
      total_outflows: dailyRecord.totalOutflows,
      comment: dailyRecord.comment,
      cash_flows: dailyRecord.cashFlows
        ? dailyRecord.cashFlows?.map(CashFlow.toJson)
        : [],
    };
  }
}
