import { TrackingEvent } from '@manakincubber/tiime-tracking';

import { Invoice } from '@models';

export const INVOICE_SAVED = 'Invoice Saved';

export class InvoiceSaved implements TrackingEvent {
  readonly name = INVOICE_SAVED;

  constructor(readonly invoice: Invoice) {}
}
