<div class="snackbar-content" data-cy="snackbar">
  <div class="message">
    <div *ngIf="isSuccessSnackbar; then successIcon; else failedIcon"></div>
    <ng-template #successIcon>
      <mat-icon class="success-icon" svgIcon="tc_icon-check"></mat-icon>
    </ng-template>
    <ng-template #failedIcon>
      <mat-icon class="failed-icon" svgIcon="tc_icon-warning"></mat-icon>
    </ng-template>
    <span data-cy="snackbar-message">{{ data.message }}</span>
  </div>
  <span matSnackBarActions>
    <button
      *ngIf="data.actionButtonText else defaultActionTemplate"
      class="actions"
      xxsmall
      tiime-button
      neutral
      inverted
      matSnackBarAction
      (click)="close()"
    >
      {{ data.actionButtonText }}
    </button>
  </span>

  <ng-template #defaultActionTemplate>
    <div class="actions" (click)="close()">OK</div>
  </ng-template>
</div>
