import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';

import { ApiAlertError } from '@decorators/api-alert-error';
import {
  CreateBankTransferBody,
  CreatedBankTransferApiContract,
  DecisionTreeDocumentChoice,
  ScheduledPayout,
  ScheduledPayoutApiContract,
} from '@models';

import { StrongCustomerAuthenticationService } from '../auth';

@Injectable({
  providedIn: 'root',
})
export class BankTransferService {
  readonly resource = 'api/v1/wallet/companies/{companyId}/payouts';
  readonly resourceDocumentChoice =
    'api/v1/wallet/companies/{companyId}/payout_decision_tree';

  constructor(
    private readonly http: HttpClient,
    private readonly sca: StrongCustomerAuthenticationService,
  ) {}

  @ApiAlertError()
  create(
    createPayoutBody: CreateBankTransferBody,
  ): Observable<number | ScheduledPayout | undefined> {
    return this.sca.authenticate(createPayoutBody.toScaAction()).pipe(
      switchMap(authenticationSCAHeader =>
        this.http.post<
          | CreatedBankTransferApiContract
          | ScheduledPayoutApiContract
          | undefined
        >(this.resource, createPayoutBody.toJson(), {
          headers: {
            ...authenticationSCAHeader,
            Accept: 'application/vnd.tiime.payout_schedule.return+json',
          },
        }),
      ),
      map(response => {
        if (!response) {
          return undefined;
        }

        if ('bank_transaction' in response) {
          return response.bank_transaction?.id;
        }

        return ScheduledPayout.fromJson(response as ScheduledPayoutApiContract);
      }),
    );
  }

  loadDocumentProofDecisionTree(): Observable<DecisionTreeDocumentChoice> {
    return this.http.get<DecisionTreeDocumentChoice>(
      this.resourceDocumentChoice,
      {
        headers: {
          Accept: 'application/vnd.tiime.decision_tree.v2+json',
        },
      },
    );
  }
}
