import { Injectable } from '@angular/core';
import {
  BehaviorSubject,
  forkJoin,
  Observable,
  of,
  OperatorFunction,
  pipe,
  Subject,
} from 'rxjs';
import { map, tap } from 'rxjs/operators';

import { CustomDashboardIndicatorType } from '@enums/dashboards';
import { Tag } from '@models';
import { StandardIndicator } from '@models/dashboards';
import { SimpleBankTransactionLabel } from '@models/labels';
import { DashboardService, TagsService } from '@services';
import { LabelsService } from '@services/labels.service';

import {
  CustomDashboardIndicators,
  DashboardIndicatorOperation,
  SelectedDashboardIndicator,
  TypedDashboardIndicator,
  TypedLabelIndicator,
  TypedStandardIndicator,
  TypedTagIndicator,
} from './types';

@Injectable({
  providedIn: 'root',
})
export class DatasetIndicatorsSearchService {
  customDashboardIndicators: CustomDashboardIndicators;

  search$ = new BehaviorSubject<string>('');

  lastSelectedDashboardIndicator$ = new Subject<SelectedDashboardIndicator>();

  selectedIndicators$ = new BehaviorSubject<
    {
      datasetTrackId: string;
      indicators: TypedDashboardIndicator[];
    }[]
  >([]);

  constructor(
    private readonly dashboardService: DashboardService,
    private readonly tagsService: TagsService,
    private readonly labelsService: LabelsService,
  ) {}

  getIndicators(
    operation?: DashboardIndicatorOperation,
  ): Observable<CustomDashboardIndicators> {
    if (this.customDashboardIndicators) {
      return operation === DashboardIndicatorOperation.EXCLUDE
        ? of({
            ...this.customDashboardIndicators,
            standardIndicators: [],
          })
        : of(this.customDashboardIndicators);
    } else {
      return forkJoin({
        standardIndicators: this.dashboardService
          .getStandardIndicators()
          .pipe(
            this.typeIndicators(CustomDashboardIndicatorType.StandardIndicator),
          ),
        recentlyUsedLabels: this.labelsService
          .getRecentlyUsedLabels()
          .pipe(this.typeIndicators(CustomDashboardIndicatorType.Label)),
        recentlyUsedTags: this.tagsService
          .getRecentlyUsedTags()
          .pipe(this.typeIndicators(CustomDashboardIndicatorType.Tag)),
        labels: this.labelsService
          .getUnpaginatedLabels()
          .pipe(this.typeIndicators(CustomDashboardIndicatorType.Label)),
        tags: this.tagsService
          .getTags()
          .pipe(this.typeIndicators(CustomDashboardIndicatorType.Tag)),
      }).pipe(
        tap(response => {
          this.customDashboardIndicators = response;
        }),
        map(response => {
          if (operation === DashboardIndicatorOperation.EXCLUDE) {
            return { ...response, standardIndicators: [] };
          }
          return response;
        }),
      );
    }
  }

  selectDashboardIndicator(
    datasetTrackId: string,
    indicator: TypedStandardIndicator | TypedLabelIndicator | TypedTagIndicator,
    operation: DashboardIndicatorOperation,
  ): void {
    this.lastSelectedDashboardIndicator$.next({
      datasetTrackId,
      indicator,
      operation,
    });
  }

  private typeIndicators<
    T = SimpleBankTransactionLabel | StandardIndicator | Tag,
  >(
    type: CustomDashboardIndicatorType,
  ): OperatorFunction<T[], (T & { type: CustomDashboardIndicatorType })[]> {
    return pipe(
      map((indicators: T[]) =>
        indicators.map(indicator => ({
          ...indicator,
          type,
        })),
      ),
    );
  }

  clearIndicators(): void {
    this.customDashboardIndicators = null;
  }

  clearSearch(): void {
    this.search$.next(null);
  }

  clearSelectedIndicators(): void {
    this.selectedIndicators$.next([]);
  }
}
