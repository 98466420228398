import { Component, Input } from '@angular/core';
import { ControlContainer } from '@angular/forms';
import { MAT_DATE_FORMATS } from '@angular/material/core';
import moment from 'moment';
import {
  DATEPICKER_DEFAULT_FORMATS,
  DatepickerHeaderComponent,
} from 'tiime-components';

import { TIIME_ICON_PREFIX } from '../../../icons/icons.module';

@Component({
  selector: 'app-date-and-period-filter',
  templateUrl: './date-and-period-filter.component.html',
  styleUrls: ['../table-column-filter-base.scss'],
  providers: [
    { provide: MAT_DATE_FORMATS, useValue: DATEPICKER_DEFAULT_FORMATS },
  ],
})
export class DateAndPeriodFilterComponent {
  @Input() min: moment.Moment;
  @Input() max: moment.Moment;

  readonly tiimeDatepickerHeader = DatepickerHeaderComponent;
  readonly TIIME_ICON_PREFIX = TIIME_ICON_PREFIX;

  constructor(public readonly controlContainer: ControlContainer) {}
}
