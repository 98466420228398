import {
  HttpHeaders,
  HttpParams,
  HttpResponse,
  HttpStatusCode,
} from '@angular/common/http';
import { Sort } from '@angular/material/sort';
import { MonoTypeOperatorFunction, OperatorFunction } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { PaginationData, PaginationRange, SortHelper } from 'tiime-components';

import { FileHelper } from './file.helper';

export class HttpHelper {
  static saveBlob: MonoTypeOperatorFunction<HttpResponse<Blob>> = tap(
    response => {
      const filename = response.headers
        .get('Content-Disposition')
        .split(';')[1]
        .split('=')[1];
      FileHelper.saveAs(response.body, filename);
    },
  );

  static getListBody<T>(range: PaginationRange, body: T[]): T[] {
    if (body.length > range.max - range.min + 1) {
      body.pop();
    }
    return body;
  }

  static getRangeFromResponseHeaders(
    headers: HttpHeaders,
    pageSize: number,
  ): PaginationRange {
    return PaginationRange.fromHttpResponseHeaders(
      headers.get('Content-Range'),
      pageSize,
    );
  }

  static mapToPaginationData<T>(
    range: PaginationRange,
    mapJsonToEntity: (_: unknown) => T,
  ): OperatorFunction<HttpResponse<unknown[]>, PaginationData<T>> {
    return map((response: HttpResponse<unknown[]>) => {
      if (response.status === HttpStatusCode.NoContent) {
        return new PaginationData<T>([], range);
      }
      const responseRange: PaginationRange =
        HttpHelper.getRangeFromResponseHeaders(
          response.headers,
          range.pageSize,
        );
      const items: T[] = HttpHelper.getListBody(range, response.body).map(
        mapJsonToEntity,
      );
      return new PaginationData(items, responseRange);
    });
  }

  static mapToPaginationDataWithMetadata<T, V, X>(
    dataKey: string,
    range: PaginationRange,
    mapJsonToEntity: (_: unknown) => T,
    mapJsonToMetadataEntity: (metadata: unknown) => unknown,
    mapJsonToEntityWithMetadata: (
      item: PaginationData<T>,
      metadata: unknown,
    ) => V,
  ): OperatorFunction<HttpResponse<X>, V> {
    return map((response: HttpResponse<X>) => {
      if (response.status === HttpStatusCode.NoContent) {
        return mapJsonToEntityWithMetadata(
          new PaginationData<T>([], range),
          mapJsonToMetadataEntity({}),
        );
      }
      const responseRange = HttpHelper.getRangeFromResponseHeaders(
        response.headers,
        range.pageSize,
      );
      const body = response.body as Record<string, unknown>;
      const items: T[] = HttpHelper.getListBody(
        range,
        body[dataKey] as unknown[],
      ).map(mapJsonToEntity);
      return mapJsonToEntityWithMetadata(
        new PaginationData(items, responseRange),
        mapJsonToMetadataEntity(body.metadata || {}),
      );
    });
  }

  static setRangeHeader(
    headers: HttpHeaders,
    range: PaginationRange,
  ): HttpHeaders {
    const rangeMaxPlusUn = new PaginationRange(range.min, range.max + 1);
    headers = headers.set('Range', rangeMaxPlusUn.toHttpRequestHeader());
    return headers;
  }

  static setSearchParam(params: HttpParams, search: string): HttpParams {
    if (search) {
      params = params.set('q', search);
    }
    return params;
  }

  static setSortParam(params: HttpParams, sort: Sort): HttpParams {
    params = sort?.direction
      ? params.set('sorts', SortHelper.toStringParam(sort))
      : params;
    return params;
  }
}
