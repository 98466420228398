import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { NoDataRowComponent } from './no-data-row.component';

@NgModule({
  imports: [CommonModule],
  exports: [NoDataRowComponent],
  declarations: [NoDataRowComponent],
})
export class NoDataRowModule {}
