import { Pipe, PipeTransform } from '@angular/core';

import { AmountMetadataValue, Metadata } from '@models/documents';

@Pipe({
  name: 'documentPreTaxAmount',
})
export class DocumentPreTaxAmountPipe implements PipeTransform {
  transform(metadata: Metadata[]): number {
    if (!metadata) {
      return null;
    }

    const vatAmountData = metadata?.find(
      metadata => metadata.key === 'vat_amount',
    );
    let vatAmount: number;
    if (!vatAmountData?.value) {
      return null;
    }
    if (Array.isArray(vatAmountData.value)) {
      vatAmount = (
        vatAmountData.value.find(m => m.key === 'total')
          ?.value as AmountMetadataValue
      )?.value;
    } else {
      vatAmount = (vatAmountData.value as AmountMetadataValue).value;
    }

    const amount =
      (
        metadata.find(data => data.key === 'amount')
          ?.value as AmountMetadataValue
      )?.value ?? null;
    if (amount === null || vatAmount === null) {
      return null;
    }
    return amount - vatAmount;
  }
}
