import { InvoiceStatus } from '../enum/invoice-status';
import { Invoice } from '../models/invoice';
import { InvoiceListItem } from '../models/invoice-list-item';
export class InvoiceHelper {
  static invoiceCanBeCancelled = (
    invoice: Invoice | InvoiceListItem,
  ): boolean => {
    const invoiceAmount = invoice.totalIncludingTaxes;
    return (
      (invoice.status === InvoiceStatus.saved ||
        invoice.status === InvoiceStatus.sent ||
        invoice.status === InvoiceStatus.paid) &&
      ((invoiceAmount < 0 && !invoice.invoiceLinkedCompiledNumber) ||
        (invoiceAmount > 0 && !invoice.creditNoteLinkedCompiledNumber))
    );
  };
}
