import {
  ChangeDetectionStrategy,
  Component,
  HostListener,
  inject,
  Inject,
  ViewChild,
} from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Store } from '@ngrx/store';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';

import { userSelector } from '@core/store';
import { MatchableDocument } from '@matching/types/matchable-document';
import { Document, LinkedBankTransaction, Tag } from '@models';
import { BankTransaction } from '@models/bank-transaction';
import { Label, LabelUpdateEvent } from '@models/labels';
import { CompanyConfigService, TransactionsService } from '@services';
import { LabelsService } from '@services/labels.service';

import { LabelSelectorComponent } from '../../label-selector/label-selector.component';
import { MatchableBankTransaction } from '../types/matchable-bank-transaction';

export interface SelectLabelDialogData {
  transaction: MatchableBankTransaction;
  document: MatchableDocument;
}

export enum LabelSource {
  Transaction,
  Document,
  Label,
}

export interface LabelOverloadDialogResponse {
  labelOverloadCheckNeeded?: boolean;
  /**
   * If value is defined, it means matchingSource label/tag has been overloaded
   */
  labelOrTagToAdd?: Label | Tag;
  /**
   * Used to differentiate between closing the dialog (undefined) and when the
   * dialog has not been showed because it's unavailable ({labelOverloadUnavailable: true})
   */
  labelOverloadUnavailable?: boolean;
}

@UntilDestroy()
@Component({
  selector: 'app-select-label-dialog',
  templateUrl: './select-label-dialog.component.html',
  styleUrls: ['./select-label-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SelectLabelDialogComponent {
  @ViewChild(LabelSelectorComponent)
  private readonly labelSelectorComponent: LabelSelectorComponent;

  selectedBankTransaction$?: Observable<BankTransaction>;
  selectedDocument?: Document;
  labelSourceControl = new FormControl<LabelSource>(null, Validators.required);

  private selectedEntity: Label | Tag;

  readonly LabelSource = LabelSource;

  readonly labels$: Observable<Label[]> =
    this.labelsService.getUnpaginatedLabels();

  readonly labelCreationEnabled$: Observable<boolean> =
    this.companyConfigService.get().pipe(
      map(config => config.labelCreationEnabled),
      untilDestroyed(this),
    );

  readonly userId$ = inject(Store)
    .select(userSelector)
    .pipe(map(user => user.id));

  @HostListener('click', ['$event'])
  onClick($event: Event): void {
    $event.stopPropagation();

    const isLabelSelectorSectionClicked =
      ($event.target as HTMLElement).id === 'labelSelectorSection';

    // This check allows us to control when the label selector overlay should be opened.
    // If the user clicks on the `app-label-selector` component directly Angular will trigger
    // "expression has changed after it was checked" error because the click event is fired twice :
    // first on `div` click then on component click.
    if (isLabelSelectorSectionClicked) {
      this.openLabelSelectorOverlay();
    }
  }

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: SelectLabelDialogData,
    private readonly dialogRef: MatDialogRef<
      SelectLabelDialogComponent,
      LabelOverloadDialogResponse
    >,
    private readonly labelsService: LabelsService,
    private readonly companyConfigService: CompanyConfigService,
    private readonly transactionsService: TransactionsService,
  ) {
    if (data.transaction instanceof BankTransaction) {
      this.selectedBankTransaction$ = of(data.transaction);
    } else {
      const bankTransactionId =
        data.transaction instanceof LinkedBankTransaction
          ? data.transaction.id
          : data.transaction.value.id;
      this.selectedBankTransaction$ =
        this.transactionsService.get(bankTransactionId);
    }

    this.selectedDocument = data.document as Document;
    this.labels$ = this.labelsService.getUnpaginatedLabels();
  }

  submitForm(bankTransaction: BankTransaction): void {
    this.dialogRef.close({
      labelOrTagToAdd: this.getEntityToMatch(
        bankTransaction,
        this.labelSourceControl.value,
      ),
    });
  }

  updateSelectedEntity(entity: LabelUpdateEvent): void {
    this.selectedEntity = entity.value;

    if (entity.value) {
      this.labelSourceControl.setValue(LabelSource.Label);
    } else {
      // We don't want to empty the current selection if the user remove a tag or a label
      // and the LabelSource is not `Label`
      if (this.labelSourceControl.value === LabelSource.Label) {
        this.labelSourceControl.setValue(null);
      }
    }
  }

  selectRow(selection: LabelSource): void {
    if (selection === this.labelSourceControl.value) {
      return;
    }

    this.labelSourceControl.setValue(selection);
  }

  openLabelSelectorOverlay(): void {
    this.labelSelectorComponent.openLabelSelectionOverlay();
  }

  private getEntityToMatch(
    bankTransaction: BankTransaction,
    selection: LabelSource,
  ): Label | Tag {
    switch (selection) {
      case LabelSource.Transaction:
        return bankTransaction.imputations[0].label;
      case LabelSource.Document:
        return this.selectedDocument.label;
      case LabelSource.Label:
        return this.selectedEntity;
      default:
        throw new Error('Invalid selection');
    }
  }
}
