import { Injectable } from '@angular/core';
import { Observable, Subject, timer } from 'rxjs';
import { debounce } from 'rxjs/operators';

import { AutoSaveData } from './auto-save-data';

@Injectable({
  providedIn: 'root',
})
export class AutoSaveService {
  private subject = new Subject<AutoSaveData>();

  beginAutoSave(): void {
    const autoSave: AutoSaveData = {
      message: 'Enregistrement en cours...',
    };
    this.subject.next(autoSave);
  }

  autoSaveSuccess(): void {
    const autoSaveSuccess: AutoSaveData = {
      message: 'Enregistré !',
      delay: 2000,
    };
    this.subject.next(autoSaveSuccess);
  }

  autoSaveError(): void {
    const autoSaveError: AutoSaveData = {
      message: 'Erreur lors de l’enregistrement',
      delay: 2000,
    };
    this.subject.next(autoSaveError);
  }

  autoSaveCustomMessage(autoSaveData: AutoSaveData): void {
    this.subject.next(autoSaveData);
  }

  observeAutoSave(): Observable<AutoSaveData> {
    this.subject
      .asObservable()
      .pipe(debounce(res => timer(res.delay || 0)))
      .subscribe(res => {
        if (res.delay) this.cleanAutoSave();
      });

    return this.subject.asObservable();
  }

  cleanAutoSave(): void {
    this.subject.next({});
  }
}
