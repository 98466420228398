import { Directive } from '@angular/core';
import { AbstractControl } from '@angular/forms';
import { Mapper } from '@manakincubber/tiime-utils';
import { TiimeSelectOption } from 'tiime-components';

import { CountryForm } from '@forms';
import { Country } from '@models';

@Directive()
export abstract class AbstractWithCountrySelectDirective<
  T extends AbstractControl = CountryForm,
> {
  countries: TiimeSelectOption<Country>[];
  countryForm: T;

  readonly mapToSelectClass: Mapper<T['value'], string> = (
    formValue: T['value'],
    disabled: boolean,
    error: boolean,
  ) =>
    [
      disabled && !formValue ? 'hidden' : 'visible',
      !disabled && !!error ? 'error' : '',
    ].join(' ');

  onCountryChanges(selectedValue: Country): void {
    if (!selectedValue?.id) {
      this.countryForm.reset();
      return;
    }
    const formValue = this.extractCountryFormValue(selectedValue);
    this.countryForm.setValue(formValue);
    this.countryForm.markAsDirty();
  }

  extractCountryFormValue(selectedCountry: Country): T['value'] {
    return selectedCountry;
  }

  compareCountries(option: Country | null, selected: Country | null): boolean {
    return (
      ((option == null || option?.id == null) &&
        (selected == null || selected?.id == null)) ||
      option?.id === selected.id
    );
  }

  isNoCountrySelected(country: Country): boolean {
    return !country?.id;
  }
}
