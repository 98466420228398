import { TrackingEvent } from '@manakincubber/tiime-tracking';

import { DecisionTreeDocumentChoice } from '@models';

export const BANK_TRANSFER_REASON_CHOSE = '25k bank transfer reason chose';

export class BankTransferReasonChose implements TrackingEvent {
  readonly name = BANK_TRANSFER_REASON_CHOSE;

  constructor(readonly reason: DecisionTreeDocumentChoice) {}
}
