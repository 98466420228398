import { Router } from '@angular/router';

export const getCompanyIdFromUrl = (router: Router): number | null => {
  const url = router?.url || '';
  if (/^\/companies\/\d+\//.test(url)) {
    const companyIdString = url.split('/companies/').pop()?.split('/')?.shift();
    return +companyIdString;
  }
  return null;
};
