import { Types } from '@amplitude/analytics-browser';
import { inject, Injectable } from '@angular/core';

import { GTM_DATA_LAYER } from '@core/tokens';

@Injectable({ providedIn: 'root' })
export class GtmPlugin implements Types.DestinationPlugin {
  type = 'destination' as const;

  private readonly gtmDataLayer = inject(GTM_DATA_LAYER);

  execute(event: Types.Event): Promise<Types.Result> {
    if (!this.gtmDataLayer) {
      return Promise.resolve({
        event: event,
        code: 400,
        message: 'No GTM data layer found',
      });
    }

    this.gtmDataLayer.push({ ...event, event: event.event_type });

    return Promise.resolve({
      event: event,
      code: 200,
      message: 'Event pushed to GTM data layer',
    });
  }
}
