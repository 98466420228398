import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';

import { WarningBannerComponent } from '@shared';

@Component({
  standalone: true,
  imports: [CommonModule, MatIconModule, WarningBannerComponent],
  selector: 'app-locked-documents-warning',
  templateUrl: './locked-documents-warning.component.html',
  styleUrls: ['./locked-documents-warning.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LockedDocumentsWarningComponent {
  @Input() set numberOfLockedDocuments(value: number) {
    if (value === 1) {
      this.title =
        'Parmi les documents sélectionnés, 1 est verrouillé par votre comptable';
      this.subtitle = 'Il ne sera pas modifié';
    } else {
      this.title = `Parmi les documents sélectionnés, ${value} sont verrouillés par votre comptable`;
      this.subtitle = 'Ils ne seront pas modifiés';
    }
  }

  title: string;
  subtitle: string;
}
