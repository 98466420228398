import { Directive, ElementRef, Renderer2, OnInit } from '@angular/core';

@Directive({
  selector: '[appPasswordToggle]',
})
export class PasswordToggleDirective implements OnInit {
  toggleButton: ElementRef;
  passwordInput: HTMLInputElement;

  constructor(
    private el: ElementRef<HTMLInputElement>,
    private renderer: Renderer2,
  ) {}

  ngOnInit(): void {
    this.createToggleButton();
    this.listenToggleButton();
  }

  private createToggleButton(): void {
    this.toggleButton = this.renderer.createElement('div') as ElementRef;
    this.passwordInput = this.el.nativeElement;

    this.renderer.setAttribute(this.toggleButton, 'class', 'toggle-password');
    this.renderer.appendChild(
      this.renderer.parentNode(this.passwordInput),
      this.toggleButton,
    );
  }

  private listenToggleButton(): void {
    if (this.toggleButton) {
      this.renderer.listen(this.toggleButton, 'click', () => {
        this.renderer.setAttribute(
          this.passwordInput,
          'type',
          this.passwordInput.type === 'text' ? 'password' : 'text',
        );
      });
    }
  }
}
