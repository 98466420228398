import { TrackingEvent } from '@manakincubber/tiime-tracking';

import { ActionsType } from '@enums/mass-actions';

export const MASS_ACTION_STARTED = 'Mass Action Started';

export class MassActionStarted implements TrackingEvent {
  readonly name = MASS_ACTION_STARTED;

  constructor(
    readonly actionType: ActionsType,
    readonly hasExtraFilters: boolean,
    readonly areAllSelected: boolean,
    readonly itemsType: string,
  ) {}
}
