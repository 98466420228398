<button
  class="close-button"
  tiime-button
  neutral
  inverted
  icon
  xsmall
  (click)="categorySelected.emit()"
>
  <mat-icon svgIcon="tc_icon-close"></mat-icon>
</button>

<div class="header">
  <img
    class="header__illu"
    width="275"
    height="211"
    ngSrc="assets/illus/document-category/illu_document_added.svg"
    alt="Document ajouté"
  />
  <div class="header__title">
    1 document prêt
    <br />
    à être ajouté
  </div>
</div>

<div class="category-select-container">
  @if (categories$ | async; as categories) {
    <app-category-select
      [categories]="categories"
      [isCategoryCreationEnabled]="false"
      [selectedCategory]="selectedCategory$ | async"
      (selectCategory)="selectedCategory$.next($event)"
    ></app-category-select>
  }
</div>

<button
  tiime-button
  accent
  (click)="categorySelected.emit(selectedCategory$.value)"
>
  Ajouter
</button>
