import { Injectable } from '@angular/core';
import { Params, Router } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { map, take, tap } from 'rxjs/operators';
import { RequiredGetOptions } from 'tiime-components';

import { DOCUMENT_CATEGORIES_WITH_SPECIFIC_UI } from '@constants';
import { loadDocumentsForSearch } from '@core/store';
import { Category, Document } from '@models';
import { selectedCompanySelector } from '@user-store';

import { DocumentPreviewResponseType } from '../../../company/document/document-shared/components/document-preview-dialog/document-preview-dialog.component';
import { DocumentPreviewDialogService } from '../../../company/document/document-shared/components/document-preview-dialog/document-preview-dialog.service';

@UntilDestroy()
@Injectable({
  providedIn: 'root',
})
export class DocumentsUiService {
  private readonly activeCompanyId$ = this.store
    .select(selectedCompanySelector)
    .pipe(map(company => company.id));
  constructor(
    private readonly documentPreviewDialogService: DocumentPreviewDialogService,
    private readonly router: Router,
    private readonly store: Store,
  ) {}

  openDocumentOrPreview(
    document: Document,
    params?: RequiredGetOptions<'range'>,
    queryParams?: Params,
  ): void {
    if (
      document.previewOnly === false &&
      DOCUMENT_CATEGORIES_WITH_SPECIFIC_UI.has(document.category.identifier)
    ) {
      this.navigateToDocumentDetailUi(document.category, document, queryParams);
    } else {
      this.openPreview(document, params)
        .pipe(
          this.documentPreviewDialogService.openBankTransferOverlayIfAsked(),
        )
        .subscribe();
    }
  }

  /**
   * Redirects to the document creation page, for document category having a specific UI
   * @param category The category in which the user wants to create a document
   */
  createDocument(category: Category): void {
    if (!DOCUMENT_CATEGORIES_WITH_SPECIFIC_UI.has(category.identifier)) {
      // This is a security, buttons triggering this action should only appear
      // if the document category has a specifig UI
      return;
    }
    this.navigateToDocumentDetailUi(category);
  }

  private navigateToDocumentDetailUi(
    category: Category,
    document?: Document,
    queryParams?: Params,
  ): void {
    if (!DOCUMENT_CATEGORIES_WITH_SPECIFIC_UI.has(category.identifier)) {
      return;
    }
    this.activeCompanyId$
      .pipe(
        take(1),
        tap(companyId => {
          void this.router.navigateByUrl(
            this.router.createUrlTree(
              [
                'companies',
                companyId.toString(),
                'documents',
                'categories',
                category.id.toString(),
                document ? document.id.toString() : 'new',
              ],
              {
                queryParams,
              },
            ),
          );
        }),
        untilDestroyed(this),
      )
      .subscribe();
  }

  private openPreview(
    document: Document,
    params: RequiredGetOptions<'range'>,
  ): Observable<DocumentPreviewResponseType> {
    return this.documentPreviewDialogService
      .openDocumentPreview({
        document,
        params,
        disableLabel: document.hasLinkedExpenseReport,
        disableMetadata: document.hasLinkedExpenseReport,
      })
      .afterClosed()
      .pipe(
        tap(() => {
          if (params) {
            this.store.dispatch(loadDocumentsForSearch(params));
          }
        }),
      );
  }
}
