export interface VatRateApiContract {
  rate: number;
  start_date: string | null;
  end_date: string | null;
}

export class VatRate {
  constructor(
    public rate: number,
    public startDate: string | null,
    public endDate: string | null,
  ) {}

  public static fromJson(json: VatRateApiContract): VatRate {
    return new VatRate(json.rate, json.start_date, json.end_date);
  }

  public static toJson(vatRate: VatRate): VatRateApiContract {
    return {
      rate: vatRate.rate,
      start_date: vatRate.startDate,
      end_date: vatRate.endDate,
    };
  }

  public isDateIn(date: Date = new Date()): boolean {
    const startDate = new Date(this.startDate);
    const endDate = new Date(this.endDate);
    endDate.setDate(endDate.getDate() + 1);

    if (!this.startDate && date < endDate) {
      return true;
    }

    if (!this.endDate && startDate <= date) {
      return true;
    }

    return startDate <= date && date < endDate;
  }
}
