<div
  class="input-like-wrapper"
  [ngClass]="{
    'has-error': (!formArray.valid && formArray.touched) || errorMessage,
  }"
>
  <mat-chip-grid #chipList>
    <mat-basic-chip
      *ngFor="let recipient of formArray.controls; let i = index"
      removable
      [ngClass]="{ error: !recipient.valid, primary: color === 'primary' }"
      (removed)="remove(i)"
    >
      <span class="mail">{{ recipient.value }}</span>
      <mat-icon matChipRemove svgIcon="tc_icon-close"></mat-icon>
    </mat-basic-chip>
    <input
      matChipInputAddOnBlur
      data-cy="mail-recipient__input"
      #input
      [matChipInputFor]="chipList"
      [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
      (matChipInputTokenEnd)="add($event)"
    />
    <label class="float-placeholder" [ngClass]="{ focus: isFocused }">
      {{ label }}
    </label>
  </mat-chip-grid>
  <span *ngIf="errorMessage" class="error-label">{{ errorMessage }}</span>
</div>
