<div class="by-mail">
  <span class="by-mail__title">
    Envoyer un document
    <br />
    par mail
  </span>

  <ng-container *tiimeLet="receiptEmail$ | async as receiptEmail">
    <span
      class="by-mail__copy"
      appCopyToClipboard
      confirmationMessage="L'adresse mail a bien été copiée dans le presse-papier."
      tiimeTooltip
      tooltipPosition="above"
      [textToCopy]="receiptEmail"
      [content]="email"
    >
      <mat-icon svgIcon="icon-copy-deprecated"></mat-icon>
      <span class="by-mail__copy__mail">{{ receiptEmail }}</span>
      &#64;tiime.fr
    </span>

    <ng-template #email>
      {{ receiptEmail }}
    </ng-template>
  </ng-container>
</div>
<app-drag-and-drop-zone
  class="side-nav-document-upload-zone"
  (dropped)="onFileDropped($event)"
>
  <div class="idle-state" idleState>
    <app-file-uploader-button (fileSelected)="onFileDropped($event)">
      <div class="instructions" action>
        <button
          class="upload-button"
          tiime-button
          accent
          type="button"
          (click)="openDocumentUploader($event)"
        >
          <mat-icon svgIcon="icon-upload"></mat-icon>
        </button>

        <span class="drop-document-label">Déposer ici un document</span>
      </div>
    </app-file-uploader-button>
  </div>

  <div class="idle-state" hoverState>
    <div class="instructions hover-state">
      <button
        class="upload-button"
        tiime-button
        accent
        type="button"
        (click)="openDocumentUploader($event)"
      >
        <mat-icon svgIcon="icon-upload"></mat-icon>
      </button>

      <span class="drop-document-label">Déposer ici un document</span>
    </div>
  </div>
</app-drag-and-drop-zone>
