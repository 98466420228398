import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnChanges,
  SimpleChanges,
} from '@angular/core';
import { Mapper, NgUtils } from '@manakincubber/tiime-utils';

import { StandardDocumentCategoryIdentifier } from '@enums';
import {
  LinkedEntity,
  LinkedEntityBankTransaction,
  LinkedEntityImputation,
  LinkedEntityType,
} from '@models/linked-entities';

interface Wording {
  singular: string;
  plural: string;
}

const iconByEntityType: Record<
  Exclude<LinkedEntityType, LinkedEntityType.EXPENSE_REPORT>,
  string
> = {
  [LinkedEntityType.IMPUTATION]: 'icon_link_ok',
  [LinkedEntityType.BANK_TRANSACTION]: 'icon_link_ok',
};

const wordingByEntityType: Record<LinkedEntity['type'], Wording> = {
  [LinkedEntityType.BANK_TRANSACTION]: {
    singular: 'Transaction liée',
    plural: 'Transactions liées',
  },
  [LinkedEntityType.IMPUTATION]: {
    singular: 'Transaction liée',
    plural: 'Transactions liées',
  },
  [LinkedEntityType.EXPENSE_REPORT]: {
    singular: 'Note de frais liée à ce document',
    plural: 'Notes de frais liées à ce document',
  },
};

@Component({
  selector: 'app-linked-entities-tooltip',
  styleUrls: ['./linked-entities-tooltip.component.scss'],
  templateUrl: './linked-entities-tooltip.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LinkedEntitiesTooltipComponent implements OnChanges {
  @Input() linkedEntities: LinkedEntity[] = [];

  entitiesType: LinkedEntity['type'];
  headerIcon: string;
  headerWording: Wording;

  readonly StandardDocumentCategoryIdentifier =
    StandardDocumentCategoryIdentifier;
  readonly LinkedEntityType = LinkedEntityType;

  readonly trackById = NgUtils.trackById;
  readonly mapToTitleIcon: Mapper<string, string> = (icon: string) => {
    return icon
      ? `assets/${icon}.svg`
      : 'assets/actions/icon-link-ok-green.svg';
  };

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.linkedEntities) {
      this.updateLinkedEntities(this.linkedEntities);
    }
  }

  private updateLinkedEntities(linkedEntities: LinkedEntity[]): void {
    this.linkedEntities = linkedEntities;

    if (linkedEntities.length > 0) {
      const isTransactionsOrImputations = linkedEntities.every(
        linkedEntity =>
          linkedEntity instanceof LinkedEntityBankTransaction ||
          linkedEntity instanceof LinkedEntityImputation,
      );

      if (isTransactionsOrImputations) {
        // handle imputations the same way as transactions
        this.entitiesType = LinkedEntityType.BANK_TRANSACTION;
      } else {
        this.entitiesType = LinkedEntityType.EXPENSE_REPORT;
      }

      if (this.entitiesType !== LinkedEntityType.EXPENSE_REPORT) {
        this.headerIcon = iconByEntityType[this.entitiesType];
      }

      this.headerWording = wordingByEntityType[this.entitiesType];
    }
  }
}
