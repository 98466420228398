import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

import { PinCodeForm } from '@forms';

@Component({
  selector: 'app-pin-code-form',
  templateUrl: './pin-code-form.component.html',
  styleUrls: ['./pin-code-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PinCodeFormComponent {
  @Input() pinCodeForm: PinCodeForm;
}
