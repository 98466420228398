<div data-cy="bank-transfer__match-receipt-dialog">
  <tiime-dialog-close-button></tiime-dialog-close-button>

  <div
    class="dialog-title"
    mat-dialog-title
    data-cy="bank-transfer__match-receipt-dialog__title"
  >
    {{ data.amount | currency }} à matcher
  </div>

  <ng-container *tiimeLet="selection$ | async as selection">
    <mat-dialog-content class="dialog-content">
      <app-receipts-to-match-table
        #receiptsTable
        [selectedReceipt]="selection$ | async"
        (selectedReceiptChange)="selection$.next($event)"
      ></app-receipts-to-match-table>

      <app-file-uploader-button
        [multiple]="false"
        [acceptedTypes]="data.acceptedTypes"
        (fileSelected)="onFileSelected($event)"
      >
        <button
          class="import-button"
          action
          tiime-button
          accent
          xsmall
          inverted
          data-cy="bank-transfer__match-receipt-dialog__upload-btn"
        >
          <mat-icon svgIcon="icon-upload"></mat-icon>
          Importer depuis l’ordinateur
        </button>
      </app-file-uploader-button>
    </mat-dialog-content>

    <mat-dialog-actions class="dialog-actions">
      <button
        tiime-button
        accent
        inverted
        mat-dialog-close
        data-cy="bank-transfer__match-receipt-dialog__cancel-btn"
      >
        Annuler
      </button>
      <button
        tiime-button
        accent
        data-cy="bank-transfer__match-receipt-dialog__confirm-btn"
        [disabled]="!selection"
        (click)="submitMatch(selection)"
      >
        Matcher
      </button>
    </mat-dialog-actions>
  </ng-container>
</div>
