import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TiimeButtonModule } from 'tiime-components';

import { EmptyStateSearchComponent } from './empty-state-search.component';

@NgModule({
  declarations: [EmptyStateSearchComponent],
  imports: [CommonModule, TiimeButtonModule],
  exports: [EmptyStateSearchComponent],
})
export class EmptyStateSearchModule {}
