export interface TodoCountsApiContract {
  information_needed?: number;
  document?: number;
  message?: number;
  label?: number;
}

export class ToDoCounts {
  constructor(
    public informationNeeded?: number,
    public document?: number,
    public message?: number,
  ) {}

  static fromJson(json: TodoCountsApiContract): ToDoCounts {
    return new ToDoCounts(
      json?.information_needed || 0,
      json?.document || 0,
      json?.message || 0,
    );
  }

  get total(): number {
    return this.informationNeeded + this.document + this.message;
  }
}
