import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { filter, map } from 'rxjs/operators';

import { ExpenseReportType } from '@models';

export const EXPENSE_REPORT_TYPE_QUERY_PARAM = 'expense_report_type';

@Component({
  selector: 'app-expense-report-editor',
  templateUrl: './expense-report-editor.component.html',
})
export class ExpenseReportEditorComponent {
  readonly documentId$ = this.route.paramMap.pipe(
    map(paramMap => paramMap.get('documentId')),
    filter(documentId => !!documentId),
    map(documentId => +documentId),
  );

  readonly expenseReportType$ = this.route.queryParamMap.pipe(
    map(
      queryParamMap =>
        (queryParamMap.get(
          EXPENSE_REPORT_TYPE_QUERY_PARAM,
        ) as ExpenseReportType) || ExpenseReportType.AdvancedExpense,
    ),
  );

  constructor(private readonly route: ActivatedRoute) {}
}
