import { ComponentType } from '@angular/cdk/portal';
import { inject, Injectable } from '@angular/core';

import { SidePanelComponentBase } from './side-panel-component.base';
import { SidePanelConfig } from './side-panel-config';
import { SidePanelRef } from './side-panel-ref';
import { SidePanelService } from './side-panel.service';

type Class<T, Arguments extends unknown[] = unknown[]> = Constructor<
  T,
  Arguments
> & { prototype: T };

type Constructor<T, Arguments extends unknown[] = unknown[]> = new (
  ...arguments_: Arguments
) => T;

interface SidePanelServiceBaseInterface<TComponent, TData, TResult> {
  open(config?: SidePanelConfig<TData>): SidePanelRef<TComponent, TResult>;
}

@Injectable()
export abstract class SidePanelServiceBase<
  TComponent,
  TData = unknown,
  TResult = unknown,
> implements SidePanelServiceBaseInterface<TComponent, TData, TResult>
{
  protected readonly sidePanelService = inject(SidePanelService);

  abstract open(
    config?: SidePanelConfig<TData>,
  ): SidePanelRef<TComponent, TResult>;
}

/**
 * Mixin pour créer rapidement un SidePanelService scopé au contexte d'un composant
 *
 * @example
 * class MySidePanelService extends createSidePanelServiceBase<MyComponent, MyDataType, MyResultType>(MyComponent) {}
 */
export function createScopedSidePanelService<
  TComponent extends SidePanelComponentBase<TData, TResult>,
  TData = unknown,
  TResult = unknown,
>(
  component: ComponentType<TComponent>,
): Class<SidePanelServiceBaseInterface<TComponent, TData, TResult>> {
  return class extends SidePanelServiceBase<TComponent, TData, TResult> {
    override open(
      config?: SidePanelConfig<TData>,
    ): SidePanelRef<TComponent, TResult> {
      return this.sidePanelService.open(component, config);
    }
  };
}
