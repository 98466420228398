import { FormGroup, FormControl } from '@angular/forms';
import { FormUtils } from '@manakincubber/tiime-utils';

import { Card } from '@models';

export class CardForm extends FormGroup {
  get id(): FormControl {
    return this.get('id') as FormControl;
  }

  constructor() {
    super({
      id: new FormControl(),
      atm: new FormControl(),
      foreign: new FormControl(),
      nfc: new FormControl(),
      online: new FormControl(),
    });
  }

  fromCard(card: Card): void {
    this.patchValue(card, FormUtils.shouldNotEmitEvent);
  }

  toCard(): Card {
    return this.getRawValue() as Card;
  }
}
