import { FormArray } from '@angular/forms';

import { ExpenseReportEditorLineForm } from '@forms';

import { ExpensesReportEditorStatus } from '../../../company/document/categories/category-document/category-document-shared/components/expenses-report-editor/expenses-report-editor.component';

export class ExpenseReportEditorLinesForm<
  T extends { id: number },
> extends FormArray<ExpenseReportEditorLineForm<T>> {
  update(lines: T[], status?: ExpensesReportEditorStatus): void {
    const isCheckedById: Map<number, boolean> = new Map();

    lines.forEach(l => {
      const control = this.controls.find(({ line }) => line.value.id === l.id);

      isCheckedById.set(
        l.id,
        control
          ? control.checked.value
          : status === ExpensesReportEditorStatus.Create,
      );
    });

    this.clear();

    for (const line of lines) {
      this.push(
        new ExpenseReportEditorLineForm(line, isCheckedById.get(line.id)),
      );
    }
  }

  addLine(line: T, checked?: boolean): ExpenseReportEditorLineForm<T> {
    const control = this.controls.find(c => c.line.value.id === line.id);
    const isChecked =
      control?.checked.value !== undefined ? control?.checked.value : checked;
    const lineForm = new ExpenseReportEditorLineForm(line, isChecked);
    this.push(lineForm);
    return lineForm;
  }

  toMatchedLines(): T[] {
    return this.controls
      .filter(editorLineForm => editorLineForm.checked.value === true)
      .map(expenseForm => expenseForm.line.value);
  }
}
