import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Observable, throwError } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { SnackbarConfig, TiimeSnackbarService } from 'tiime-components';

import { MagicLinkDialogComponent } from '../auth/signin/magic-link-dialog/magic-link-dialog.component';

/**
 * Provides a way to log into the app when auth0 is unavailable.
 * An email will be sent to the user, which contains a "magic link" that will
 * redirecting them to `/connect-as?access_token=token&magic_link=true`.
 * TODO: should be deleted when upgrading to NG13+ (conflicts between ngrx versions)
 */
@Injectable({
  providedIn: 'root',
})
export class MagicLinksService {
  static MAGIC_LINK_LS_KEY = 'is_logged_in_via_magic_link';

  private readonly resource = 'api/v1/magic_links';

  constructor(
    private readonly http: HttpClient,
    private readonly matDialog: MatDialog,
    private readonly snackbar: TiimeSnackbarService,
  ) {}

  askMagicLink(email: string): Observable<null> {
    return this.http
      .post<null>(this.resource, { email, application: 'accounts' })
      .pipe(
        catchError(error => {
          this.snackbar.open(
            `Une erreur est survenue, merci de réessayer plus tard.`,
            SnackbarConfig.error,
          );
          return throwError(error);
        }),
        tap(() => this.matDialog.open(MagicLinkDialogComponent)),
      );
  }

  setIsLoggedInViaMagicLink(param: string): void {
    if (param === '1') {
      localStorage.setItem(MagicLinksService.MAGIC_LINK_LS_KEY, param);
    } else {
      // In case someone connects from another app and there was still this value in localStorage
      this.removeLocalStorageItem();
    }
  }

  /**
   * The localStorage item should be removed everytime the user tries to log in via auth0
   */
  removeLocalStorageItem(): void {
    localStorage.removeItem(MagicLinksService.MAGIC_LINK_LS_KEY);
  }

  /**
   * Some actions should be unavailable when the user logs in via magic link, e.g. changing password.
   * @returns `true` if the user has been logged via magic link, `false` otherwise
   */
  isLoggedInViaMagicLink(): boolean {
    const isLoggedInViaMagicLink =
      localStorage.getItem(MagicLinksService.MAGIC_LINK_LS_KEY) === '1';
    if (isLoggedInViaMagicLink) {
      // TODO: when moving to NG13+ and using tiime-auth'library's MagicLinksService, move this snackbar notif
      this.snackbar.open(
        `Cette fonctionnalité est momentanément indisponible`,
        SnackbarConfig.error,
      );
    }
    return isLoggedInViaMagicLink;
  }
}
