import { FormGroup, FormControl } from '@angular/forms';

export class SearchBarForm extends FormGroup {
  get search(): FormControl {
    return this.get('search') as FormControl;
  }

  constructor() {
    super({
      search: new FormControl(null),
    });
  }
}
