import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';

import { ApiAlertError } from '@decorators/api-alert-error';
import { CashRegister, CashRegisterApiContract } from '@models/cash-tracking';
import { Label, LabelApiContract } from '@models/labels';

@Injectable({
  providedIn: 'root',
})
export class CashRegistersService {
  private readonly resource = 'api/v1/companies/{companyId}/cash_registers';

  private _selectedCashRegister$ = new BehaviorSubject<
    CashRegister | undefined
  >(undefined);

  constructor(private readonly http: HttpClient) {}

  get selectedCashRegister$(): Observable<CashRegister | undefined> {
    return this._selectedCashRegister$.asObservable();
  }

  get selectedCashRegister(): CashRegister | undefined {
    return this._selectedCashRegister$.value;
  }

  /**
   * TODO : Remove this function when the CashRegisters selection page is created.
   * It's only used when selecting a default CashRegister
   */
  setSelectedCashRegister(cashRegister?: CashRegister): void {
    this._selectedCashRegister$.next(cashRegister);
  }

  @ApiAlertError()
  get(id: number): Observable<CashRegister> {
    return this.http
      .get<CashRegisterApiContract>(`${this.resource}/${id}`)
      .pipe(
        map((cashRegister: CashRegisterApiContract) =>
          CashRegister.fromJson(cashRegister),
        ),
        tap(cashRegister => this._selectedCashRegister$.next(cashRegister)),
      );
  }

  @ApiAlertError()
  getAll(): Observable<CashRegister[]> {
    return this.http
      .get(this.resource)
      .pipe(
        map((cashRegisters: CashRegisterApiContract[]) =>
          cashRegisters.map(CashRegister.fromJson),
        ),
      );
  }

  @ApiAlertError()
  create(cashRegister: CashRegister): Observable<CashRegister> {
    return this.http
      .post(this.resource, CashRegister.toJson(cashRegister))
      .pipe(
        map((response: CashRegisterApiContract) =>
          CashRegister.fromJson(response),
        ),
      );
  }

  @ApiAlertError()
  getInitialCashRegisterLabels(id: number): Observable<Label[]> {
    return this.http
      .get<LabelApiContract[]>(`${this.resource}/${id}/label_suggestions`)
      .pipe(
        map((labelsJson: LabelApiContract[]) =>
          labelsJson.map(labelJson => Label.fromJson(labelJson)),
        ),
      );
  }
}
