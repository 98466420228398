<div data-cy="bank-transfer__document-acceptance-dialog">
  <tiime-dialog-close-button></tiime-dialog-close-button>

  <div class="dialog__container">
    <div class="dialog__header">
      <span data-cy="bank-transfer__document-acceptance-dialog__title">
        Critères d’acceptation des fichiers envoyés
      </span>
    </div>

    <img
      ngSrc="assets/illus/bank-transfer/bank_transfer_document_acceptance.svg"
      width="135"
      height="135"
      draggable="false"
      alt="Critères d’acceptation des fichiers envoyés"
    />

    <h2
      class="dialog__subtitle"
      data-cy="bank-transfer__document-acceptance-dialog__subtitle"
    >
      Pour être validés le plus rapidement possible, les fichiers doivent :
    </h2>
    <ul class="dialog__list">
      <li class="list__item">
        <p class="bold">Être conforme au document demandé</p>
        <p class="italic">
          Ex: Je joins une facture et pas un autre type de document
        </p>
      </li>
      <li class="list__item">
        <p class="bold">Être lisible et de bonne qualité</p>
        <p class="italic">
          Ex: Mon fichier est bien éclairé, il n’est ni pixelisé, ni flou, ni
          rogné
        </p>
      </li>
      <li class="list__item">
        <p class="bold">Mentionner les informations requises</p>
        <p class="italic">
          Ex: Le nom, prénom et ou adresse du bénéficiaires doivent être lisible
          si demandés
        </p>
      </li>
      <li class="list__item">
        <p class="bold">Être à jour</p>
        <p class="italic">
          Ex: Le RIB de mon bénéficiaire est-il toujours valable ?
        </p>
      </li>
    </ul>

    <div class="dialog-actions__container">
      <button
        tiime-button
        accent
        mat-dialog-close
        data-cy="bank-transfer__document-acceptance-dialog__close-btn"
      >
        J’ai compris
      </button>
    </div>
  </div>
</div>
