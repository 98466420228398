import { TrackingEvent } from '@manakincubber/tiime-tracking';

import { Client, Invoice } from '@models';

export const INVOICE_CLIENT_ADDED = 'Invoice Client Added';

export class InvoiceClientAdded implements TrackingEvent {
  readonly name = INVOICE_CLIENT_ADDED;

  constructor(
    readonly invoiceId?: Invoice['id'],
    readonly clientId?: Client['id'],
  ) {}
}
