import { Observable, switchMap, throwError, catchError } from 'rxjs';

import {
  ScaDialogOptions,
  StrongCustomerAuthenticationService,
  ScaAuthenticateHeader,
} from '@core/auth';
import { ScaAction } from '@core/models';
import { AppInjector } from '@core/utils/app-injector';

export function ScaValidation(
  scaAction: ScaAction,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  getCompanyId: (context: any) => number | null = () => null,
  scaDialogOptions: ScaDialogOptions = {
    hasBackdrop: false,
    displayValidationStep: false,
  },
): MethodDecorator {
  return function (
    _target: () => void,
    _propertyKey: string,
    descriptor: PropertyDescriptor,
  ) {
    const originalMethod = descriptor.value as (
      ...args: unknown[]
    ) => Observable<unknown>;
    descriptor.value = function (...args: unknown[]): Observable<unknown> {
      const companyId = getCompanyId(this);
      const sca = AppInjector.getInjector().get(
        StrongCustomerAuthenticationService,
      );
      return sca.authenticate(scaAction, scaDialogOptions, companyId).pipe(
        switchMap((authenticationSCAHeader: ScaAuthenticateHeader) => {
          {
            if (authenticationSCAHeader) {
              const result = originalMethod.apply(this, [
                ...args,
                authenticationSCAHeader,
              ]);
              return result;
            } else {
              return throwError(
                () => new Error('Strong Customer Authentication failed'),
              );
            }
          }
        }),
        catchError((error: unknown) => {
          return throwError(() => error);
        }),
      );
    };
    return descriptor;
  };
}
