import { DocumentEditorTableColumn } from '../../shared/components/document-editor-table-header/document-editor-table-column';

export const INVOICING_STANDARD_TABLE_COLUMNS: DocumentEditorTableColumn[] = [
  {
    label: 'description',
    testId: 'description-header',
    translate: true,
  },
  {
    label: 'vat',
    testId: 'vat-header',
    translate: true,
    width: '55px',
  },
  {
    label: 'amountBeforeTax',
    testId: 'total-excluding-taxes-header',
    translate: true,
    width: '90px',
  },
];
