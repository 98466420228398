import { Component, ChangeDetectionStrategy } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';
import { BehaviorSubject } from 'rxjs';

import { TitleService } from '@core/services/title.service';

export interface BackButtonConfig {
  /** The link to redirect to */
  routerLink: string | unknown[];
  /** The label to show with the button */
  label: string;
  /** Whether if the link is relative to the current route or absolute */
  relativeTo?: ActivatedRoute;
  /** The query params for the redirection */
  queryParams?: Params;
}

@Component({
  selector: 'app-content-navigation-layout',
  templateUrl: './content-navigation-layout.component.html',
  styleUrls: ['./content-navigation-layout.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ContentNavigationLayoutComponent {
  readonly backButtonConfig$ = new BehaviorSubject<BackButtonConfig | null>(
    null,
  );

  readonly contentTitle$ = this.titleService.getContentTitle();
  centeredTitle = false;

  constructor(
    public readonly route: ActivatedRoute,
    private readonly titleService: TitleService,
  ) {}

  enableNavigationBackButton(config: BackButtonConfig): void {
    this.centeredTitle = true;
    this.backButtonConfig$.next(config);
  }

  disableNavigationBackButton(): void {
    this.centeredTitle = false;
    this.backButtonConfig$.next(null);
  }
}
