import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Inject,
  OnInit,
  inject,
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { PRIMARY_OUTLET, Router } from '@angular/router';
import { TiimeAuthService } from '@manakincubber/tiime-auth';
import { TrackingService } from '@manakincubber/tiime-tracking';
import { NgUtils } from '@manakincubber/tiime-utils';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Store } from '@ngrx/store';
import { BehaviorSubject, Observable } from 'rxjs';
import {
  debounceTime,
  filter,
  finalize,
  map,
  switchMap,
  tap,
} from 'rxjs/operators';
import {
  MonoValueFilter,
  PaginationRange,
  TIIME_OVERLAY_DATA,
  TiimeOverlayRef,
} from 'tiime-components';

import { LegalService, ReloadService } from '@core';
import { UserLoggedOut } from '@core/amplitude';
import { UserPermissionNameEnum } from '@core/enum/users';
import { selectedCompanySelector } from '@core/store';
import { LegalDocumentType } from '@enums';
import { Company, User } from '@models';
import { CompaniesService } from '@services/companies.service';

import { SettingsRedirectionService } from '../../../settings/settings-redirection.service';
import { CareContactDialogComponent } from '../../company-shared/components/care-contact-dialog/care-contact-dialog.component';
import { DatasetIndicatorsSearchService } from '../../home/indicators/dashboards/dashboards-shared/dashboard-customization/dataset-indicators-search.service';

export interface UserAccountMenuData {
  selectedCompany: Company;
  user: User;
}

@UntilDestroy()
@Component({
  selector: 'app-user-account-menu-overlay',
  templateUrl: './user-account-menu-overlay.component.html',
  styleUrls: ['./user-account-menu-overlay.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UserAccountMenuOverlayComponent implements OnInit {
  inactiveCompanies: Company[] = [];
  companySuggestions$: Observable<Company[]>;
  companyPaginationRange = new PaginationRange(0, 4, '*', 5);

  isAccountant$ = inject(Store)
    .select(selectedCompanySelector)
    .pipe(map((company: Company) => !company.workInCompany));

  readonly UserPermissionNameEnum = UserPermissionNameEnum;
  readonly trackById = NgUtils.trackById;
  readonly searchCompanyFormControl = new FormControl();
  readonly searchCompanyloading$ = new BehaviorSubject(false);
  readonly inactiveCompanyLoading$ = new BehaviorSubject(false);

  constructor(
    @Inject(TIIME_OVERLAY_DATA)
    public readonly userAccountMenuData: UserAccountMenuData,
    private readonly authService: TiimeAuthService,
    private readonly dialog: MatDialog,
    private readonly overlayRef: TiimeOverlayRef,
    private readonly reloadService: ReloadService,
    private readonly router: Router,
    private readonly trackingService: TrackingService,
    private readonly companiesService: CompaniesService,
    private readonly legalService: LegalService,
    private readonly cdr: ChangeDetectorRef,
    private readonly datasetIndicatorsSearchService: DatasetIndicatorsSearchService,
    private readonly settingsRedirectionService: SettingsRedirectionService,
  ) {}

  ngOnInit(): void {
    this.getInactiveCompanies();
    this.initSearchCompanies();
  }

  logout(): void {
    this.authService.logout();
    this.trackingService.dispatch(new UserLoggedOut());
  }

  navigateToBiller(): void {
    this.overlayRef.close();
    const billerOverlayAlreadyOpened = !!this.router
      .parseUrl(this.router.url)
      .root.children[PRIMARY_OUTLET].segments.find(
        segment => segment.toString() === 'biller',
      );
    if (billerOverlayAlreadyOpened) {
      return;
    }
    void this.router.navigate([
      'companies',
      this.userAccountMenuData.selectedCompany.id,
      'biller',
    ]);
  }

  openHelp(): void {
    window.open('https://support.tiime.fr/fr/', 'Support', 'noopener');
  }

  openCareContactDialog(): void {
    this.overlayRef.close();
    this.dialog.open(CareContactDialogComponent);
  }

  openSettings(company: Company): void {
    this.overlayRef.close();
    this.settingsRedirectionService.saveNavigationState(
      this.router.routerState.snapshot.url,
    );
    void this.router.navigate(['companies', company.id, 'settings', 'company']);
  }

  switchToCompany(company: Company): void {
    if (company.selected) {
      return;
    }
    this.datasetIndicatorsSearchService.clearIndicators();
    this.reloadService.navigateToCompany(company.id);
    this.overlayRef.close();
  }

  displayFn(company: Company): string {
    return company?.name ? company.name : '';
  }

  getGdprPdf(): void {
    this.legalService.openLegalDocumentPdf(LegalDocumentType.GDPR).subscribe();
  }

  getCguPdf(): void {
    this.legalService.openLegalDocumentPdf(LegalDocumentType.TOS).subscribe();
  }

  loadMoreCompanies(): void {
    this.companyPaginationRange = this.companyPaginationRange.next();
    this.getInactiveCompanies();
  }

  private getInactiveCompanies(): void {
    this.inactiveCompanyLoading$.next(true);
    this.companiesService
      .getAll({
        range: this.companyPaginationRange,
        filters: [new MonoValueFilter('work_in_companies', 'true')],
      })
      .pipe(
        tap(paginationData => {
          this.companyPaginationRange = paginationData.paginationRange;
          this.inactiveCompanies = [
            ...this.inactiveCompanies,
            ...paginationData.data.filter(
              company =>
                company.id !== this.userAccountMenuData.selectedCompany.id,
            ),
          ];
        }),
        finalize(() => {
          this.inactiveCompanyLoading$.next(false);
          this.cdr.markForCheck();
        }),
        untilDestroyed(this),
      )
      .subscribe();
  }

  private initSearchCompanies(): void {
    this.companySuggestions$ = this.searchCompanyFormControl.valueChanges.pipe(
      filter(value => typeof value === 'string'),
      debounceTime(300),
      tap(() => this.searchCompanyloading$.next(true)),
      switchMap((search: string) =>
        this.companiesService.getAll({
          search,
          range: new PaginationRange(0, 5),
        }),
      ),
      map(paginationData => paginationData.data),
      tap(() => this.searchCompanyloading$.next(false)),
    );
  }
}
