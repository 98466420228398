import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import {
  TiimeButtonModule,
  TiimeDialogModule,
  TiimeIconModule,
  TiimeLoaderModule,
  TiimeTwocolorsIconModule,
} from 'tiime-components';

import { ImportDialogContainerComponent } from './import-dialog-container.component';

@NgModule({
  imports: [
    CommonModule,
    ReactiveFormsModule,
    TiimeButtonModule,
    TiimeDialogModule,
    TiimeIconModule,
    TiimeLoaderModule,
    TiimeTwocolorsIconModule,
  ],
  exports: [ImportDialogContainerComponent],
  declarations: [ImportDialogContainerComponent],
})
export class ImportDialogContainerModule {}
