import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import {
  TiimeButtonModule,
  TiimeDialogModule,
  TiimeTwocolorsIconModule,
} from 'tiime-components';

import { BankConnectionReinsuranceDialogComponent } from './bank-connection-reinsurance-dialog.component';

@NgModule({
  declarations: [BankConnectionReinsuranceDialogComponent],
  imports: [
    CommonModule,
    TiimeButtonModule,
    TiimeDialogModule,
    TiimeTwocolorsIconModule,
  ],
  exports: [BankConnectionReinsuranceDialogComponent],
})
export class BankConnectionReinsuranceDialogModule {}
