<div class="container">
  <div class="description">
    <span class="message">Aucun résultat...</span>
    <button
      tiime-button
      accent
      data-cy="empty-state-search-action"
      (click)="clearSearch.emit()"
    >
      Nouvelle recherche ?
    </button>
  </div>
  <img class="logo" src="assets/empty_search.svg" alt="logo" />
</div>
