import {
  BooleanInput,
  coerceBooleanProperty,
  coerceNumberProperty,
  NumberInput,
} from '@angular/cdk/coercion';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ContentChild,
  ElementRef,
  EventEmitter,
  HostBinding,
  HostListener,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Optional,
  Output,
  Self,
  SimpleChanges,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { ControlValueAccessor, NgControl } from '@angular/forms';
import { MatFormFieldControl } from '@angular/material/form-field';
import { filter, tap } from 'rxjs/operators';

import { InputContainerControl } from '../../input-container';
import { TiimeOverlayRef, TiimeOverlayService } from '../../overlay';
import { OverlayPosition } from '../../overlay/overlay.service';
import { SelectDropdownData } from './dropdown/dropdown-data';
import { DropdownOverlayResponseType } from './dropdown/dropdown-overlay-response.type';
import { TiimeDropdownComponent } from './dropdown/dropdown.component';
import { TiimeSelectOption } from './select-option';
import { TiimeSelectSize } from './select-size';

let nextUniqueId = 0;

const DEFAULT_SIZE: TiimeSelectSize = 'md';

@Component({
  selector: 'tiime-select',
  templateUrl: './select.component.html',
  styleUrls: ['./select.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: InputContainerControl,
      useExisting: TiimeSelectComponent,
    },
    { provide: MatFormFieldControl, useExisting: TiimeSelectComponent },
  ],
})
export class TiimeSelectComponent<T>
  implements
    ControlValueAccessor,
    InputContainerControl<T>,
    MatFormFieldControl<T>,
    OnChanges,
    OnInit,
    OnDestroy
{
  @Input() options: TiimeSelectOption<T>[];
  @Input() icon: string;
  @Input() customOverlayPosition: OverlayPosition;
  @Input() disabled = false;
  @Input() maxHeight = '180px';

  @Input() set selected(selected: T) {
    this.value = selected;
  }

  private _size = DEFAULT_SIZE;
  get size(): TiimeSelectSize {
    return this._size;
  }

  @Input() set size(size: TiimeSelectSize) {
    this._size = size;
  }

  private _small: boolean;
  /** @deprecated use the size input instead */
  @Input() set small(small: BooleanInput) {
    this._small = coerceBooleanProperty(small);
    if (this._small) {
      this.size = 'sm';
    } else if (this._size === 'sm' && !this._large) {
      this._size = DEFAULT_SIZE;
    } else {
      this._size = 'lg';
    }
  }

  private _large: boolean;
  /** @deprecated use the size input instead */
  @Input() set large(large: BooleanInput) {
    this._large = coerceBooleanProperty(large);
    if (this._large) {
      this.size = 'lg';
    } else if (this._size === 'lg' && !this._small) {
      this._size = DEFAULT_SIZE;
    } else {
      this._size = 'sm';
    }
  }

  private _light: boolean;
  @Input() set light(light: BooleanInput) {
    this._light = coerceBooleanProperty(light);
  }

  private _flat: boolean;
  @Input() set flat(flat: BooleanInput) {
    this._flat = coerceBooleanProperty(flat);
  }

  private _fakeInput: boolean;
  @Input() set fakeInput(fakeInput: BooleanInput) {
    this._fakeInput = coerceBooleanProperty(fakeInput);
  }

  private _flexEnd: boolean;
  @Input() set flexEnd(flexEnd: BooleanInput) {
    this._flexEnd = coerceBooleanProperty(flexEnd);
  }

  private _withSearch: boolean;
  @Input() set withSearch(value: BooleanInput) {
    this._withSearch = coerceBooleanProperty(value);
  }

  private _overlayDynamicWidth: boolean;
  @Input() set overlayDynamicWidth(value: BooleanInput) {
    this._overlayDynamicWidth = coerceBooleanProperty(value);
  }

  private _customWidth: number;
  @Input() set customWidth(value: NumberInput) {
    this._customWidth = coerceNumberProperty(value);
  }

  private _required = false;
  @Input()
  get required(): boolean {
    return this._required;
  }

  set required(value: boolean) {
    this._required = coerceBooleanProperty(value);
    this.stateChanges.next();
  }

  private _placeholder: string;
  @Input()
  get placeholder(): string {
    return this._placeholder;
  }

  set placeholder(value: string) {
    this._placeholder = value;
    this.stateChanges.next();
  }

  private _uid = `mat-select-${nextUniqueId++}`;
  private _id: string;
  @Input()
  get id(): string {
    return this._id;
  }

  set id(value: string) {
    this._id = value || this._uid;
    this.stateChanges.next();
  }

  @Output() readonly valueChanged = new EventEmitter<T>();

  @ViewChild('dropdownToggle') dropdownToggleRef: ElementRef<HTMLElement>;

  @ContentChild(TemplateRef, { static: false })
  customTemplate: TemplateRef<{ option: TiimeSelectOption<T> }> | undefined;

  /** Whether the select is focused. */
  get focused(): boolean {
    return this._focused || this.isDropdownOpen;
  }

  private _focused = false;

  // Necessary to implement MatFormFieldControl but not used here.
  shouldLabelFloat: boolean;
  errorState: boolean;
  /** A name for this control that can be used by `mat-form-field`. */
  controlType = 'tiime-select';
  isDropdownOpen = false;
  value: T;
  overlayRef: TiimeOverlayRef<DropdownOverlayResponseType<T>> | undefined;

  readonly stateChanges = new EventEmitter<void>();

  @HostBinding('class.empty-selection') get empty(): boolean {
    return this.isNoValueSelected(this.value);
  }

  @HostBinding('class') get hostClasses(): string {
    return [
      this._size === 'sm' ? 'small' : '',
      this._size === 'lg' ? 'large' : '',
      this._light ? 'light' : '',
      this._flat ? 'flat' : '',
      this._fakeInput ? 'fake-input' : '',
      this._flexEnd ? 'flex-end' : '',
      this.focused ? 'focus' : '',
      this.isDropdownOpen ? 'dropdown-open' : '',
    ].join(' ');
  }

  @HostBinding('tabindex') tabindex = '0';

  /** The aria-describedby attribute on the select for improved a11y. */
  @HostBinding('attr.aria-describedby') _ariaDescribedBy = '';

  @HostListener('focus')
  onFocus(): void {
    this._onFocus();
    this._focused = true;
  }

  @HostListener('focusout')
  onFocusout(): void {
    this._onBlur();
    this._focused = false;
  }

  @HostListener('keydown', ['$event'])
  onKeyDown(keyboardEvent: KeyboardEvent): void {
    const key = keyboardEvent.key;
    switch (keyboardEvent.key) {
      case 'ArrowUp':
      case 'ArrowDown':
      case 'ArrowLeft':
      case 'ArrowRight':
      case ' ':
        keyboardEvent.preventDefault();
        break;
    }
    if (!this.overlayRef) {
      if (key === 'ArrowUp' || key === 'ArrowDown' || key === ' ') {
        this.toggleDropdown();
      }
    }
  }

  @Input() compareWith: (a: unknown, b: unknown) => boolean = (
    option: T,
    selected: T,
  ) => {
    return option === selected;
  };

  /**
   * Function to determine if there is currently no value selected.
   * It could be something different than 'null' (e.g. {id: null}), so the function can be set via this Input
   * @param selected Currently selected value
   * @returns Wether there is a value selected
   */
  @Input() isNoValueSelected = (selected: T): boolean => {
    return selected === null;
  };

  constructor(
    private readonly cdr: ChangeDetectorRef,
    private readonly tiimeOverlayService: TiimeOverlayService,
    private readonly elementRef: ElementRef<HTMLElement>,
    @Self() @Optional() public ngControl: NgControl,
  ) {
    if (this.ngControl) {
      // Note: we provide the value accessor through here, instead of
      // the `providers` to avoid running into a circular import.
      this.ngControl.valueAccessor = this;
    }

    // Force setter to be called in case id was not specified.
    // eslint-disable-next-line no-self-assign
    this.id = this.id;
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.disabled) {
      this.stateChanges.next();
      this.toggleDisabledAttribute(changes.disabled.currentValue as boolean);
    }
  }

  ngOnInit(): void {
    this.stateChanges.next();
  }

  ngOnDestroy(): void {
    this.stateChanges.complete();
  }

  setDescribedByIds(ids: string[]): void {
    this._ariaDescribedBy = ids.join(' ');
  }

  focus(options?: FocusOptions): void {
    this.elementRef.nativeElement.focus(options);
  }

  /**
   * Implemented as part of MatFormFieldControl.
   * @docs-private
   */
  onContainerClick(): void {
    this.focus();
    this.toggleDropdown();
  }

  registerOnChange(fn: (_: T) => void): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  writeValue(value: T): void {
    if (this.value !== value) {
      this.value = value;
      this.onChange(value);
      this.valueChanged.emit(value);
      this.cdr.markForCheck();
    }
  }

  onChange: (_: T) => void = () => {
    // Will be overridden by registerOnChange(...)
  };
  onTouched: () => void = () => {
    // Will be overridden by registerOnTouched(...)
  };

  _onFocus(): void {
    if (!this.disabled) {
      this._focused = true;
      this.stateChanges.next();
    }
  }

  /**
   * Calls the touched callback only if the panel is closed. Otherwise, the trigger will
   * "blur" to the panel when it opens, causing a false positive.
   */
  _onBlur(): void {
    this._focused = false;

    if (!this.disabled && !this.isDropdownOpen) {
      this.onTouched();
      this.cdr.markForCheck();
      this.stateChanges.next();
    }
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
    this.cdr.markForCheck();
    this.stateChanges.next();
    this.toggleDisabledAttribute(this.disabled);
  }

  toggleDropdown(): void {
    if (!this.overlayRef) {
      this.isDropdownOpen = true;
      this.overlayRef = this.tiimeOverlayService.open<
        TiimeDropdownComponent<T>,
        SelectDropdownData<T>,
        DropdownOverlayResponseType<T>
      >(
        TiimeDropdownComponent,
        {
          options: this.options,
          compareWith: this.compareWith,
          currentValue: this.value,
          id: `${this.id}-panel`,
          customTemplate: this.customTemplate,
          withSearch: this._withSearch,
        },
        {
          backdropClass: 'cdk-overlay-transparent-backdrop',
          panelClass: this._flexEnd ? 'tiime-select-overlay--flex-end' : '',
          maxHeight: this.maxHeight,
          dynamicWidth: this._overlayDynamicWidth,
          width: this._overlayDynamicWidth
            ? undefined
            : this._customWidth ||
              this.dropdownToggleRef.nativeElement.parentElement?.offsetWidth,
          hasBackdrop: true,
          backdropClose: true,
          connectTo: {
            origin: this.elementRef,
            positions: [
              {
                originX: this.customOverlayPosition?.originX || 'start',
                originY: this.customOverlayPosition?.originY || 'bottom',
                overlayX: this.customOverlayPosition?.overlayX || 'start',
                overlayY: this.customOverlayPosition?.overlayY || 'top',
                offsetX: this.customOverlayPosition?.offsetX || 0,
                offsetY: this.customOverlayPosition?.offsetY || 5,
              },
            ],
          },
        },
      );

      this.overlayRef
        .beforeClosed()
        .pipe(
          tap(() => {
            this.isDropdownOpen = false;
            this.overlayRef = undefined;
            this.elementRef.nativeElement.focus();
            this.cdr.markForCheck();
          }),
          filter(isNotNullOrUndefined),
          tap(({ value }) => this.writeValue(value)),
        )
        .subscribe();
    }
  }

  private toggleDisabledAttribute(isDisabled: boolean): void {
    this.elementRef.nativeElement.toggleAttribute('disabled', isDisabled);
  }
}

function isNotNullOrUndefined<T>(input: null | undefined | T): input is T {
  return input !== null && input !== undefined;
}
