<div
  class="table-actions"
  [class.table-actions--hidden]="
    currentState !== TableState.done && currentState !== TableState.noResult
  "
>
  <tiime-search-bar
    placeholder="Rechercher dans mes reçus..."
    #searchBar
  ></tiime-search-bar>
  <tiime-paginator [range]="paginationData.paginationRange"></tiime-paginator>
</div>

<div
  class="table-wrapper"
  [class.table-wrapper--hidden]="currentState === TableState.contentPlaceholder"
>
  <div class="receipts-table-container" #scrollContainer>
    <tiime-table-anchor></tiime-table-anchor>

    <table
      class="receipts-table"
      tiime-table
      matSort
      tiimeStickyTable
      aria-label="a table containing a list of the uploaded receipts"
      [dataSource]="paginationData.data"
      [trackBy]="trackById"
      [scrollContainer]="scrollContainer"
    >
      <ng-container cdkColumnDef="date">
        <th
          *cdkHeaderCellDef
          cdk-header-cell
          mat-sort-header="metadata.date"
          scope="col"
          data-cy="column-header-date"
        >
          Date
        </th>
        <td *cdkCellDef="let receipt" cdk-cell>
          <tiime-date-list-cell
            *ngIf="!receipt.hasPendingOcr"
            [date]="receipt.metadata | documentDate"
          ></tiime-date-list-cell>
          <mat-icon
            *ngIf="receipt.hasPendingOcr"
            svgIcon="icon-clock-future"
          ></mat-icon>
        </td>
      </ng-container>

      <ng-container cdkColumnDef="provider">
        <th
          *cdkHeaderCellDef
          cdk-header-cell
          mat-sort-header="metadata.wording"
          scope="col"
          data-cy="column-header-third-party"
        >
          Fournisseur
        </th>
        <td *cdkCellDef="let receipt" cdk-cell>
          <div>
            <span
              *ngIf="!receipt.hasPendingOcr"
              tiimeTooltip
              tooltipPosition="bottom"
              hideWithoutEllipsis
              [content]="receiptProviderNameTooltipTemplate"
            >
              {{ receipt.metadata | documentWording }}
            </span>
            <ng-template #receiptProviderNameTooltipTemplate>
              <div class="tooltip">
                {{ receipt.metadata | documentWording }}
              </div>
            </ng-template>
            <span *ngIf="receipt.hasPendingOcr" class="pending-message">
              En cours d'analyse
            </span>
            <span
              *ngIf="receipt.hasManualOcrAndIsEmpty"
              class="not-complete-message"
            >
              <span class="italic">
                Aucune info détectée sur ce justificatif -
              </span>
              <span class="not-complete-second-message">
                Vous pouvez le compléter manuellement si besoin.
              </span>
            </span>
          </div>
          <app-tags-list [tags]="receipt.tags"></app-tags-list>
        </td>
      </ng-container>

      <ng-container cdkColumnDef="amount">
        <th
          *cdkHeaderCellDef
          cdk-header-cell
          mat-sort-header="metadata.amount"
          scope="col"
          data-cy="column-header-amount"
        >
          Montant TTC
        </th>
        <td *cdkCellDef="let receipt" cdk-cell>
          <span>
            {{
              receipt.metadata
                | documentAmount
                | currency
                  : (receipt.metadata | documentMetadataCurrency : 'amount')
            }}
          </span>
        </td>
      </ng-container>

      <ng-container cdkColumnDef="vatAmount">
        <th
          *cdkHeaderCellDef
          cdk-header-cell
          mat-sort-header="metadata.vat_amount"
          scope="col"
          data-cy="column-header-vat-amount"
        >
          Montant TVA
        </th>
        <td *cdkCellDef="let receipt" cdk-cell>
          <span>
            {{
              receipt.metadata
                | documentVatAmount
                | currency
                  : (receipt.metadata | documentMetadataCurrency : 'vat_amount')
            }}
          </span>
        </td>
      </ng-container>

      <ng-container cdkColumnDef="matches">
        <th
          *cdkHeaderCellDef
          class="no-padding"
          cdk-header-cell
          scope="col"
        ></th>
        <td *cdkCellDef="let receipt" class="no-padding" cdk-cell>
          <ng-container *ngIf="receipt.hasBankTransactions">
            <app-linked-items-tooltip-icon
              [linkedItemsNumber]="receipt.linkedEntities.length"
            >
              <app-linked-entities-tooltip
                *ngIf="!!receipt.linkedEntities?.length"
                [linkedEntities]="
                  receipt
                    | filterLinkedEntities
                      : LinkedEntityType.BANK_TRANSACTION
                      : LinkedEntityType.IMPUTATION
                "
              ></app-linked-entities-tooltip>
            </app-linked-items-tooltip-icon>
          </ng-container>
        </td>
      </ng-container>

      <ng-container cdkColumnDef="comments">
        <th
          *cdkHeaderCellDef
          class="no-padding"
          cdk-header-cell
          scope="col"
        ></th>
        <td *cdkCellDef="let receipt" class="no-padding" cdk-cell>
          <app-comment-tooltip-icon
            *ngIf="receipt.comment"
            [comment]="receipt.comment"
          ></app-comment-tooltip-icon>
        </td>
      </ng-container>

      <tr *cdkHeaderRowDef="displayedColumns" cdk-header-row></tr>
      <tr
        *cdkRowDef="let receipt; columns: displayedColumns"
        cdk-row
        attr.data-cy="receipts-list-item-{{ receipt.id }}"
        [ngClass]="receipt | map : mapToReceiptRowClass : selectedReceipt"
        [class.disabled]="receipt.hasBankTransactions"
        (click)="selectReceipt(receipt)"
      ></tr>
    </table>
  </div>
</div>
<tiime-table-content-placeholder
  *ngIf="currentState === TableState.contentPlaceholder"
  [withoutElevation]="true"
  [withoutBorderRadius]="true"
  [withoutBorders]="true"
  [linesLength]="11"
></tiime-table-content-placeholder>
