<ng-container *ngIf="form$ | async as form" [formGroup]="form.parent">
  <section class="receipt">
    <img
      class="receipt__illu"
      src="assets/illus/bank-transfer/illu_bank_transfer_receipt.svg"
      alt=""
      height="80"
      width="80"
    />

    <h1 class="receipt__title" data-cy="bank-transfer__title">
      J'ajoute le ou les justificatif(s) requis
    </h1>
    <h3 class="receipt__subtitle" data-cy="bank-transfer__title">
      afin de faire valider mon virement
    </h3>
    <div
      *ngIf="decisionTreeSample$ | async as decisionTreeSample"
      class="receipt__container"
    >
      <app-bank-transfer-document-proof-choice
        [label]="decisionTreeSample.label"
        [children]="decisionTreeSample.children"
        [actionType]="decisionTreeSample.action_type"
        (documentsNeeded)="updateNeededDocuments($event)"
        (selectedChoice)="selectedChoice.emit($event)"
      ></app-bank-transfer-document-proof-choice>
      <ng-container
        *ngIf="documentsNeeded$ | async as documentsNeeded; else choiceNotMade"
      >
        <ng-container
          *ngFor="
            let documentNeeded of documentsNeeded;
            let i = index;
            trackBy: trackByDocumentNeededLabel
          "
          formArrayName="documents"
        >
          <ng-container [formGroup]="form.controls[i]">
            <app-bank-transfer-document-proof-picker
              formControlName="document"
              [beneficiary]="beneficiary"
              [label]="documentNeeded.label"
              [description]="documentNeeded.description"
              [documentCategory]="
                isPickerAction(documentNeeded)
                  ? documentNeeded.document_category
                  : undefined
              "
              [defaultDocumentRoutes]="
                !isPickerAction(documentNeeded)
                  ? documentNeeded.default_document
                  : undefined
              "
              [transferAmount]="amount"
              [canMatch]="isPickerAction(documentNeeded)"
              [mandatory]="documentNeeded.mandatory"
            ></app-bank-transfer-document-proof-picker>
          </ng-container>
        </ng-container>
        <button
          tiime-button
          neutral
          inverted
          xsmall
          data-cy="bank-transfer__receipt-document-info-dialog-btn"
          (click)="openDocumentAcceptanceDialog()"
        >
          Fichier attendus
        </button>
      </ng-container>
      <ng-template #choiceNotMade>
        <span
          class="receipt__container__empty-description"
          data-cy="bank-transfer__receipt__empty-description"
        >
          Merci d'envoyer les justificatifs demandés,
          <br />
          afin qu'ils soient contrôlés conformément à la loi.
        </span>
        <button
          tiime-button
          neutral
          inverted
          xsmall
          data-cy="bank-transfer__receipt-document-info-dialog-btn"
          (click)="openDocumentInfoDialog()"
        >
          En savoir plus
        </button>
      </ng-template>
    </div>
  </section>
</ng-container>
