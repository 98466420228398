import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';

import { AppConfigService } from './services/app-config.service';

@Injectable({
  providedIn: 'root',
})
export class QontoConnectService {
  constructor(private readonly appConfigService: AppConfigService) {}

  getConnectionWebviewUrl(
    redirectUri: string,
    state: string,
  ): Observable<string> {
    // state is sent to Tiime backend and has to be the in-app redirectUri in b64 format
    const inAppRedirectUri = `${redirectUri}?state=${state}`;
    const appConfig = this.appConfigService.appConfig;

    return of(
      `${appConfig.QONTO_CONNECT_WEBVIEW_URL}?client_id=${
        appConfig.QONTO_CONNECT_CLIENT_ID
      }&redirect_uri=${
        appConfig.QONTO_CONNECT_REDIRECT_URI
      }&response_type=code&scope=offline_access organization.read&state=${btoa(
        inAppRedirectUri,
      )}`,
    );
  }

  getReconnectionWebviewUrl(
    bankConnectionId: number,
    redirectUri: string,
    state: string,
  ): Observable<string> {
    const inAppRedirectUri = `${redirectUri}?state=${state}&connection_id=${bankConnectionId}`;
    const appConfig = this.appConfigService.appConfig;

    return of(
      `${appConfig.QONTO_CONNECT_WEBVIEW_URL}?client_id=${
        appConfig.QONTO_CONNECT_CLIENT_ID
      }&redirect_uri=${
        appConfig.QONTO_CONNECT_REDIRECT_URI
      }&response_type=code&scope=offline_access organization.read&state=${btoa(
        inAppRedirectUri,
      )}`,
    );
  }
}
