import { animate, style, transition, trigger } from '@angular/animations';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'app-saved-checkmark',
  templateUrl: './saved-checkmark.component.html',
  styleUrls: ['./saved-checkmark.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [
    trigger('fadeEnterLeave', [
      transition(':enter', [
        style({
          opacity: 0,
        }),
        animate('100ms', style({ opacity: 1 })),
      ]),
      transition(':leave', [animate('100ms', style({ opacity: 0 }))]),
    ]),
  ],
})
export class SavedCheckmarkComponent {
  @Input() showCheckmark = false;
}
