import { Injectable } from '@angular/core';
import { Route, Router, UrlSegment, UrlTree } from '@angular/router';
import { TiimeAuthService } from '@manakincubber/tiime-auth';

import { REDIRECT_TO_PARAM_NAME } from '@constants';

@Injectable({
  providedIn: 'root',
})
export class AuthGuard {
  constructor(private authService: TiimeAuthService, private router: Router) {}

  canMatch(_: Route, segments: UrlSegment[]): boolean | UrlTree {
    return this.isAuthenticated(segments);
  }

  isAuthenticated(segments: UrlSegment[]): boolean | UrlTree {
    if (!this.authService.isAuthenticated()) {
      // always redirect to user's requested page
      const redirectUrl = segments.map(segment => segment.path).join('/');
      const isDefaultRedirect = redirectUrl === 'companies';

      return this.router.createUrlTree(
        ['signin'],
        redirectUrl && !isDefaultRedirect
          ? {
              queryParams: {
                [REDIRECT_TO_PARAM_NAME]: redirectUrl,
              },
            }
          : {},
      );
    }

    return true;
  }
}
