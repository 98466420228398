<ng-content></ng-content>
<div
  class="formatted-value fake-input"
  [class.error]="!!error"
  [style.display]="isInputFocused | map : mapToDisplayStyle"
>
  <div
    *tiimeLet="injectedInput?.nativeElement.valueAsNumber as inputValue"
    class="value"
  >
    <ng-container *ngIf="currency; else number">
      {{ (inputValue | currency : currency) || forcedValue }} {{ customSuffix }}
    </ng-container>
  </div>
  <ng-template #number>
    <div>
      {{
        (injectedInput?.nativeElement.valueAsNumber | number : format) ||
          forcedValue
      }}
      {{ customSuffix }}
    </div>
  </ng-template>
</div>
