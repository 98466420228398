import { Injectable, Inject } from '@angular/core';

import { WINDOW } from '@core/tokens';

@Injectable({
  providedIn: 'root',
})
export class WindowService {
  get href(): string {
    return this.windowRef.location.href;
  }

  get origin(): string {
    return this.windowRef.location.origin;
  }

  get hostname(): string {
    return this.windowRef.location.hostname;
  }

  constructor(@Inject(WINDOW) private readonly windowRef: Window) {}

  goToUrl(url: string): void {
    this.windowRef.location.href = url;
  }

  openInNewTab(url: string, windowFeatures?: string): void {
    this.windowRef.open(url, '_blank', windowFeatures);
  }

  clearQueryParams(...paramsName: string[]): void {
    const queryParams = new URLSearchParams(this.windowRef.location.search);
    paramsName.forEach(paramName => queryParams.delete(paramName));
    const queryParamsString = queryParams.toString();
    const urlSearch =
      paramsName.length && queryParamsString ? `?${queryParamsString}` : '';
    this.windowRef.history.replaceState(
      {},
      '',
      `${this.windowRef.location.pathname}${urlSearch}`,
    );
  }
}
