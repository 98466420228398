<ng-container *tiimeLet="loading$ | async as loading">
  <tiime-dialog-close-button [disabled]="loading"></tiime-dialog-close-button>
  <ng-container *tiimeLet="cardInUse$ | async as cardInUse">
    <div mat-dialog-title>
      <ng-container *ngIf="!cardInUse; else renewCardTitle">
        Commander ma carte Tiime Business
      </ng-container>
    </div>
    <ng-template #renewCardTitle>
      Renouveler ma carte Tiime Business
    </ng-template>
    <ng-container *ngIf="currentStep$ | async as currentStep">
      <ng-container [ngSwitch]="currentStep">
        <div
          *ngSwitchCase="OrderStep.Information"
          class="informations"
          mat-dialog-content
        >
          <ng-container *ngIf="!cardInUse; else renewCard">
            <div class="informations__item">
              <img
                src="assets/illu_card_reassurance.svg"
                height="80"
                width="80"
                alt="Code Pin"
              />
              <p>
                Je n’oublie plus mon code PIN en le choisissant dès maintenant
              </p>
            </div>
            <div class="informations__item">
              <img
                src="assets/illu_card_tracking.svg"
                height="80"
                width="80"
                alt="Livraison"
              />
              <p>Livraison en 10 jours ouvrés à votre adresse</p>
            </div>
            <div class="informations__item">
              <img
                src="assets/illu_card_activation.svg"
                height="80"
                width="80"
                alt="Activation"
              />
              <p>
                J’attends la validation de mon compte pour l’activer une fois
                reçue
              </p>
            </div>
          </ng-container>
          <ng-template #renewCard>
            <div class="informations__item">
              <img
                src="assets/illu_card_reassurance.svg"
                height="80"
                width="80"
                alt="Code Pin"
              />
              <p>Je paramètre le code PIN de ma nouvelle carte</p>
            </div>
            <div class="informations__item">
              <img
                src="assets/illu_card_adress_check.svg"
                height="80"
                width="80"
                alt="Livraison"
              />
              <p>Je vérifie mon adresse de livraison de ma carte</p>
            </div>
            <div class="informations__item">
              <img
                src="assets/illu_card_reception_ok.svg"
                height="80"
                width="80"
                alt="Livraison"
              />
              <p>Je confirme la réception de ma nouvelle carte</p>
            </div>
            <div class="informations__item">
              <img
                src="assets/illu_card_activation.svg"
                height="80"
                width="80"
                alt="Activation"
              />
              <p>J’active ma nouvelle carte et supprime l’ancienne</p>
            </div>
          </ng-template>
        </div>

        <app-pin-code-form
          *ngSwitchCase="OrderStep.PinCode"
          class="pin-code"
          mat-dialog-content
          [pinCodeForm]="pinCodeForm"
        ></app-pin-code-form>

        <div
          *ngSwitchCase="OrderStep.Confirmation"
          class="confirmation"
          mat-dialog-content
        >
          <img
            src="assets/illu_card_tracking.svg"
            height="80"
            width="80"
            alt="Expédition"
          />

          <p class="confirmation__address-label">Adresse de livraison</p>
          <div class="confirmation__address">
            {{ cardDeliveryAddress.firstName }}
            {{ cardDeliveryAddress.lastName }}
            <br />
            {{ cardDeliveryAddress.street }}
            <br />
            {{ cardDeliveryAddress.postalCode }}
            {{ cardDeliveryAddress.city }}
            <br />
            {{ cardDeliveryAddress.countryName }}
          </div>
          <p class="confirmation__warning">
            Le nom et l'adresse correspondent-ils
            <br />
            à ceux qui sont notés sur la boîte aux lettres ?
          </p>

          <mat-radio-group [formControl]="hasCheckedAddressFormControl">
            <mat-radio-button color="primary" [value]="true">
              Oui, l’adresse et le nom sont corrects
            </mat-radio-button>
            <mat-radio-button color="primary" [value]="false">
              Non, une erreur se glisse dans l’adresse et/ou le nom
            </mat-radio-button>
          </mat-radio-group>
        </div>
      </ng-container>

      <div *ngIf="currentStep !== OrderStep.CareContact" mat-dialog-actions>
        <button
          *ngIf="currentStep === OrderStep.Information"
          tiime-button
          accent
          (click)="nextStep(currentStep)"
        >
          OK c’est parti !
        </button>

        <button
          *ngIf="currentStep === OrderStep.PinCode"
          tiime-button
          accent
          [disabled]="!pinCodeForm.valid"
          (click)="nextStep(currentStep)"
        >
          Suivant
        </button>

        <ng-container *ngIf="currentStep === OrderStep.Confirmation">
          <button
            tiime-button
            neutral
            inverted
            [disabled]="loading"
            (click)="contactSupport()"
          >
            Je préviens mon conseiller
          </button>

          <button
            tiime-button
            accent
            [disabled]="loading || hasCheckedAddressFormControl.invalid"
            (click)="validate()"
          >
            <ng-container *ngIf="!loading; else spinner">
              Je lance la commande
            </ng-container>

            <ng-template #spinner>
              <tiime-progress-spinner-loader
                diameter="20"
                color="white"
                mode="indeterminate"
              ></tiime-progress-spinner-loader>
            </ng-template>
          </button>
        </ng-container>
      </div>
    </ng-container>
  </ng-container>
</ng-container>
