import { createTracker, withName } from '@manakincubber/tiime-tracking';
import { tap, withLatestFrom } from 'rxjs/operators';

import {
  mapDocumentCategoryIdentifier,
  optionsWithCompanyGroupExtra,
} from '@core/amplitude/utils';
import { selectedCompanyIdSelector } from '@core/store';

import {
  DOCUMENT_UPLOADED,
  DocumentUploaded,
  GED_ACCESSED,
  GED_DOCUMENT_ACCESSED,
  GedAccessed,
  GedDocumentAccessed,
} from '../events';
import { BaseTrackingGroup } from './base-tracking-group';

export class GedTrackingGroup extends BaseTrackingGroup {
  handleGedAccessed$ = createTracker(
    this.events$.pipe(
      withName(GED_ACCESSED),
      withLatestFrom(this.store.select(selectedCompanyIdSelector)),
      tap(([, companyId]: [GedAccessed, number]) =>
        this.ampli.gedAccedee(optionsWithCompanyGroupExtra(companyId)),
      ),
    ),
  );

  handleGedDocumentAccessed$ = createTracker(
    this.events$.pipe(
      withName(GED_DOCUMENT_ACCESSED),
      withLatestFrom(this.store.select(selectedCompanyIdSelector)),
      tap(([event, companyId]: [GedDocumentAccessed, number]) =>
        this.ampli.detailDocumentAccede(
          {
            type_document: mapDocumentCategoryIdentifier(
              event.documentCategoryIdentifier,
            ),
          },
          optionsWithCompanyGroupExtra(companyId),
        ),
      ),
    ),
  );

  handleDocumentUploaded$ = createTracker(
    this.events$.pipe(
      withName(DOCUMENT_UPLOADED),
      withLatestFrom(this.store.select(selectedCompanyIdSelector)),
      tap(([event, companyId]: [DocumentUploaded, number]) => {
        this.ampli.documentAjoute(
          {
            type_document: mapDocumentCategoryIdentifier(
              event.documentCategoryIdentifier,
            ),
          },
          optionsWithCompanyGroupExtra(companyId),
        );
      }),
    ),
  );
}
