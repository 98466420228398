export interface BankTransactionAuthorApiContract {
  user_id?: number;
  name?: string;
}

export class BankTransactionAuthor {
  constructor(public userId?: number, public name?: string) {}

  static fromJson(
    json: BankTransactionAuthorApiContract,
  ): BankTransactionAuthor {
    return new BankTransactionAuthor(json.user_id, json.name);
  }
}
