interface AccountingPeriodApiContract {
  id?: number;
  start_date?: string;
  end_date?: string;
}

export class AccountingPeriod {
  constructor(
    public id?: number,
    public startDate?: string,
    public endDate?: string,
  ) {}

  static fromJson(json: AccountingPeriodApiContract): AccountingPeriod {
    return new AccountingPeriod(json?.id, json?.start_date, json?.end_date);
  }

  static toJson(period: AccountingPeriod): AccountingPeriodApiContract {
    return {
      id: period.id,
      start_date: period.startDate,
      end_date: period.endDate,
    };
  }
}
