<div *ngIf="withSearch" class="input-container">
  <div class="search-input">
    <mat-icon svgIcon="tc_icon-search"></mat-icon>
    <input
      tiimeAutoFocus
      type="text"
      placeholder="Rechercher..."
      #input
      [formControl]="search"
    />
  </div>
</div>
<div *tiimeLet="filteredOptions$ | async as options" class="options-container">
  <div
    *ngFor="let option of options; trackBy: trackByIndex"
    class="option"
    tabindex="-1"
    tiimeTooltip
    tooltipPosition="right"
    #items
    [attr.id]="id"
    [class.default-option]="!customTemplate"
    [class.with-image]="!!option.image"
    [class.disabled]="option.disabled"
    [tooltipDisabled]="!option.disabled"
    [content]="disabledReason"
    (click)="changeValue(option)"
  >
    <div class="label" data-cy="dropdown__option">
      <mat-icon *ngIf="option.icon" [svgIcon]="option.icon"></mat-icon>
      <img *ngIf="option.image" alt="" [src]="option.image" />
      <ng-container
        *ngIf="customTemplate; else defaultOption"
        [ngTemplateOutlet]="customTemplate"
        [ngTemplateOutletContext]="{ option: option }"
      ></ng-container>
      <ng-template #defaultOption>
        <span
          class="label-text"
          tiimeTooltip
          hideWithoutEllipsis
          [content]="optionLabel"
        >
          {{ option.optionLabel || option.label | translate }}
        </span>
      </ng-template>
    </div>
    <ng-template #optionLabel>
      {{ option.optionLabel || option.label | translate }}
    </ng-template>
    <ng-template #disabledReason>
      <div [innerHTML]="option.disabledTooltipContent"></div>
    </ng-template>
  </div>
</div>
