import { ChangeDetectionStrategy, Component } from '@angular/core';
import { AbstractTiimeChipDirective } from 'tiime-components';

@Component({
  selector: 'app-label-chip-placeholder',
  templateUrl: './label-chip-placeholder.component.html',
  styleUrls: ['./label-chip-placeholder.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LabelChipPlaceholderComponent extends AbstractTiimeChipDirective {
  readonly width = Math.floor(Math.random() * (100 - 50 + 1) + 50);
}
