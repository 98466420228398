export class WalletCardActivation {
  constructor(public id: number, public confirmPublicToken: string) {}

  static toJson(walletCardActivation: WalletCardActivation): any {
    return {
      id: walletCardActivation.id,
      confirm_public_token: walletCardActivation.confirmPublicToken,
    };
  }
}
