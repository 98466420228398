import { Component, EventEmitter, Output } from '@angular/core';

@Component({
  selector: 'app-empty-state-search',
  templateUrl: './empty-state-search.component.html',
  styleUrls: ['./empty-state-search.component.scss'],
})
export class EmptyStateSearchComponent {
  @Output()
  clearSearch: EventEmitter<void> = new EventEmitter<void>();
}
