import { Pipe, PipeTransform } from '@angular/core';
import { SortDirection } from '@angular/material/sort';
import { DefaultGetOptions } from 'tiime-components';

@Pipe({
  name: 'toSortValue',
})
export class ToSortValuePipe implements PipeTransform {
  transform(
    sort: DefaultGetOptions['sort'] | undefined,
    sortKey?: string,
  ): SortDirection | null {
    return sort?.active === sortKey ? sort.direction : null;
  }
}
