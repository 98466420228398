import { Pipe, PipeTransform } from '@angular/core';

import { NumberFilterTypeEnum } from '@enums/filters';

@Pipe({
  name: 'numberFilterPattern',
})
export class NumberFilterPatternPipe implements PipeTransform {
  transform(type: NumberFilterTypeEnum): string {
    switch (type) {
      case NumberFilterTypeEnum.INTEGER:
        return '-?[0-9]+';
      case NumberFilterTypeEnum.DECIMAL:
      default:
        return '-?[0-9]+([.|,]?[0-9]+)?';
    }
  }
}
