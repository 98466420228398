import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { TiimeDatepickerModule } from 'tiime-components';

import { IconsModule } from '../../../icons/icons.module';
import { DateAndPeriodFilterComponent } from './date-and-period-filter.component';

@NgModule({
  declarations: [DateAndPeriodFilterComponent],
  exports: [DateAndPeriodFilterComponent],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    IconsModule,
    TiimeDatepickerModule,
  ],
})
export class DateAndPeriodFilterModule {}
