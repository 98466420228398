import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { switchMap } from 'rxjs/operators';

import { ImputationsHelper } from '@core/helpers/imputations.helper';
import { MatchingType } from '@enums';
import { MatchingDialogService } from '@matching/matching-dialog/matching-dialog.service';
import { MatchableBankTransaction } from '@matching/types/matchable-bank-transaction';
import { MatchableImputations } from '@matching/types/matchable-imputations';
import { Document, Tag } from '@models';
import { Label } from '@models/labels';
import { MatchingResponseInterface } from '@models/matching';
import { TransactionsService } from '@services';

import { MatchedItem } from '../types/matched-item';

@Injectable({
  providedIn: 'root',
})
export class MassMatchingDialogService {
  constructor(
    private readonly matchingDialogService: MatchingDialogService,
    private readonly transactionsService: TransactionsService,
  ) {}

  openMassActionsMatchingDialog(
    documents: Document[],
  ): Observable<void | MatchingResponseInterface[]> {
    return this.matchingDialogService
      .openMatchingDialog({
        matchingSource: documents.length > 1 ? documents : documents[0],
        matchedItems: [],
        allowMultipleSelection: false,
      })
      .pipe(
        switchMap(({ matchedItems, labelOrTagToAdd }) => {
          const matchedItem = matchedItems[0];
          if (ImputationsHelper.isBankTransaction(matchedItem)) {
            return this.matchBankTransactionToDocuments(
              matchedItem,
              documents,
              labelOrTagToAdd,
            );
          } else if (ImputationsHelper.isImputation(matchedItem)) {
            return this.matchImputationToDocuments(matchedItem, documents);
          }

          return of(null);
        }),
      );
  }

  private matchImputationToDocuments(
    matchedItem: MatchedItem,
    documents: Document[],
  ): Observable<void> {
    const matchedImputation =
      ImputationsHelper.getImputationFromMatchableImputations(
        matchedItem as MatchableImputations,
      );
    return this.transactionsService.matchDocumentsToImputation(
      matchedImputation.parentId,
      matchedImputation.id,
      documents,
    );
  }

  private matchBankTransactionToDocuments(
    matchedItem: MatchedItem,
    documents: Document[],
    labelOrTagToAdd: Label | Tag,
  ): Observable<MatchingResponseInterface[]> {
    const matchedBankTransaction =
      ImputationsHelper.getBankTransactionFromMatchableBankTransactions(
        matchedItem as MatchableBankTransaction,
      );

    return this.transactionsService.matchDocuments(
      matchedBankTransaction.id,
      documents.map(document => document.id),
      MatchingType.documents,
      labelOrTagToAdd instanceof Label ? labelOrTagToAdd.id : undefined,
      labelOrTagToAdd instanceof Tag ? labelOrTagToAdd.name : undefined,
    );
  }
}
