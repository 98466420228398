import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  HostListener,
  Inject,
  OnDestroy,
} from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { AutoUnsubscribe } from '@manakincubber/tiime-utils';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { delay, filter, tap } from 'rxjs/operators';
import { SnackbarConfig, TiimeSnackbarService } from 'tiime-components';

import { ScaDialogOptions } from '@core';
import { ScaValidationStatus } from '@enums';

export interface StrongCustomerAuthenticationDialogData {
  validationStatus$: Observable<ScaValidationStatus>;
  limitDate$: Observable<Date>;
  scaDialogOptions?: ScaDialogOptions;
}

@Component({
  templateUrl: './strong-customer-authentication-dialog.component.html',
  styleUrls: ['./strong-customer-authentication-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
@AutoUnsubscribe()
export class StrongCustomerAuthenticationDialogComponent implements OnDestroy {
  readonly resendNotification = new EventEmitter<void>();
  readonly ScaValidationStatus = ScaValidationStatus;
  readonly canResendNotification$ = new BehaviorSubject(false);

  private handleValidationSuccessSub: Subscription;
  private handleValidationTimeoutSub: Subscription;

  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: StrongCustomerAuthenticationDialogData,
    private snackbar: TiimeSnackbarService,
    private dialogRef: MatDialogRef<StrongCustomerAuthenticationDialogComponent>,
  ) {
    const validationDelay =
      this.data?.scaDialogOptions?.displayValidationStep !== true ? 2000 : 0;
    this.handleValidationSuccess(
      data.validationStatus$,
      dialogRef,
      validationDelay,
    );
    this.handleValidationFailed(data.validationStatus$, dialogRef);
    this.handleValidationTimeout(data.validationStatus$, dialogRef);
  }

  // eslint-disable-next-line @angular-eslint/no-empty-lifecycle-method
  ngOnDestroy(): void {
    // Needed for AutoUnsubscribe
  }

  /**
   * Send a close event before the window is closed to trigger the cancellation of the SCA request
   */
  @HostListener('window:beforeunload', ['$event'])
  unloadHandler(event: BeforeUnloadEvent): void {
    event.preventDefault();
    this.dialogRef.close();
  }

  onResendNotification(): void {
    this.resendNotification.emit();
    this.canResendNotification$.next(false);
  }

  private handleValidationSuccess(
    validationStatus$: Observable<ScaValidationStatus>,
    dialogRef: MatDialogRef<StrongCustomerAuthenticationDialogComponent>,
    validationDelay: number,
  ): void {
    this.handleValidationSuccessSub = validationStatus$
      .pipe(
        filter(status => status === ScaValidationStatus.Validated),
        tap(() => dialogRef.close()),
        delay(validationDelay),
      )
      .subscribe();
  }

  private handleValidationTimeout(
    validationStatus$: Observable<ScaValidationStatus>,
    dialogRef: MatDialogRef<StrongCustomerAuthenticationDialogComponent>,
  ): void {
    this.handleValidationTimeoutSub = validationStatus$
      .pipe(
        filter(status => status === ScaValidationStatus.Timeout),
        tap(() => {
          this.snackbar.open(
            'Temps de validation dépassé ! Veuillez réessayer et pensez à préparer votre téléphone de confiance pour valider cette action.',
            SnackbarConfig.error,
          );
          dialogRef.close();
        }),
      )
      .subscribe();
  }

  private handleValidationFailed(
    validationStatus$: Observable<ScaValidationStatus>,
    dialogRef: MatDialogRef<StrongCustomerAuthenticationDialogComponent>,
  ): void {
    this.handleValidationTimeoutSub = validationStatus$
      .pipe(
        filter(status => status === ScaValidationStatus.Failed),
        tap(() => {
          this.snackbar.open(
            "Une erreur s'est produite lors de l'authentification forte. Merci de vérifier votre application mobile.",
            SnackbarConfig.error,
          );
          dialogRef.close();
        }),
      )
      .subscribe();
  }
}
