<ng-container *tiimeLet="data.validationStatus$ | async as validationStatus">
  <tiime-dialog-close-button></tiime-dialog-close-button>

  <ng-container
    *tiimeLet="canResendNotification$ | async as canResendNotification"
  >
    <ng-container [ngSwitch]="validationStatus">
      <ng-container *ngSwitchCase="ScaValidationStatus.Pending">
        <div mat-dialog-content data-ut="pending">
          <ng-container
            *ngIf="!canResendNotification; else canResendNotificationTemplate"
          >
            <img
              src="assets/illu_wallet_sca.svg"
              height="200"
              width="260"
              alt=""
            />
            <h1>Confirmer votre connexion depuis votre téléphone</h1>
            <span
              *ngIf="data.limitDate$ | async as limitDate"
              class="limit-date"
            >
              <mat-icon fontIcon="icon-horloge-1"></mat-icon>
              <p>
                Vous avez jusqu’à
                {{ limitDate | date : 'shortTime' }} pour valider
              </p>
            </span>
            <p>
              Des notifications sont envoyées sur votre téléphone de confiance
              pour valider vos opérations sensibles.
            </p>
          </ng-container>

          <ng-template #canResendNotificationTemplate>
            <h1>Vous ne recevez pas de notifications ?</h1>
            <p>
              Vérifiez que votre téléphone est bien lié à votre compte Tiime
              Business.
            </p>

            <div class="link-device-card">
              <img
                class="link-device-card__illu"
                src="assets/illu_wallet_sca_success.svg"
                height="100"
                width="100"
                alt=""
              />

              <span class="link-device-card__content">
                <h2>Comment lier votre téléphone à votre compte ?</h2>
                <p>
                  Rendez-vous sur l’onglet ‘Plus’ de votre application, puis
                  dans ‘Mot de passe et sécurité’ et activer l’option.
                </p>
              </span>
            </div>
          </ng-template>
        </div>
      </ng-container>

      <ng-container *ngSwitchCase="ScaValidationStatus.Validated">
        <div mat-dialog-content data-ut="validated">
          <img
            src="assets/illu_wallet_sca_success.svg"
            height="100"
            width="100"
            alt=""
          />
          <h1>L’opération a été autorisée</h1>
        </div>
      </ng-container>

      <ng-container *ngSwitchCase="ScaValidationStatus.Refused">
        <div mat-dialog-content data-ut="refused">
          <img
            src="assets/illu_wallet_sca_refused.svg"
            height="100"
            width="100"
            alt=""
          />
          <h1>L’opération a été refusée sur votre mobile de confiance</h1>
          <p>
            Si vous n’êtes pas à l’origine de l’opération, veuillez vérifier la
            connexion à votre mobile.
          </p>
        </div>
      </ng-container>

      <ng-container *ngSwitchCase="ScaValidationStatus.Cancelled">
        <div mat-dialog-content data-ut="cancelled">
          <img
            src="assets/illu_wallet_sca_error.svg"
            height="100"
            width="100"
            alt=""
          />
          <h1>Une erreur est survenue</h1>
          <p>
            Il semblerait que vous ayez rencontré un problème technique.
            <br/>
            Veuillez ré-essayer.
          </p>
        </div>
      </ng-container>
    </ng-container>

    <div mat-dialog-actions>
      <ng-container [ngSwitch]="validationStatus">
        <ng-container *ngSwitchCase="ScaValidationStatus.Pending">
          <button
            *ngIf="
              canResendNotification === false;
              else resendNotificationButton
            "
            tiime-button
            accent
            inverted
            (click)="this.canResendNotification$.next(true)"
          >
            Je n’ai pas reçu de notification
          </button>
        </ng-container>

        <ng-container *ngSwitchCase="ScaValidationStatus.Refused">
          <ng-container
            *ngTemplateOutlet="resendNotificationButton"
          ></ng-container>
        </ng-container>

        <ng-container *ngSwitchCase="ScaValidationStatus.Cancelled">
          <ng-container *ngTemplateOutlet="understoodButton"></ng-container>
        </ng-container>

        <ng-template #understoodButton>
          <button tiime-button accent mat-dialog-close>OK j'ai compris</button>
        </ng-template>

        <ng-template #resendNotificationButton>
          <button tiime-button accent (click)="onResendNotification()">
            Renvoyer une notification
          </button>
        </ng-template>

        <button
          *ngSwitchCase="ScaValidationStatus.Validated"
          tiime-button
          accent
          [disabled]="true"
        >
          <tiime-progress-spinner-loader
            diameter="20"
            color="white"
            mode="indeterminate"
          ></tiime-progress-spinner-loader>
        </button>
      </ng-container>
    </div>
  </ng-container>
</ng-container>
