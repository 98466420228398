import { Location } from '@angular/common';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  inject,
  Input,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TrackingService } from '@manakincubber/tiime-tracking';
import { FormUtils } from '@manakincubber/tiime-utils';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Store } from '@ngrx/store';
import moment from 'moment';
import { BankTransferOverlayService } from 'projects/tiime/src/app/company/account/bank-transfers/bank-transfers-shared/components/bank-transfert-overlay/bank-transfer-overlay.service';
import {
  BehaviorSubject,
  combineLatest,
  forkJoin,
  iif,
  Observable,
  of,
  ReplaySubject,
  Subscription,
  throwError,
} from 'rxjs';
import {
  catchError,
  debounceTime,
  distinctUntilChanged,
  filter,
  finalize,
  map,
  skip,
  startWith,
  switchMap,
  take,
  tap,
  withLatestFrom,
} from 'rxjs/operators';
import {
  SnackbarConfig,
  TiimeSelectOption,
  TiimeSnackbarService,
} from 'tiime-components';

import {
  DocumentsService,
  ExpensesReportService,
  TagsService,
  TitleService,
  UsersService,
} from '@core';
import {
  AddTagButtonClicked,
  AppliedOnEnum,
  GedDocumentAccessed,
  TagAdded,
} from '@core/amplitude';
import { AccountingPeriodService } from '@core/services/accounting-period.service';
import { AclService } from '@core/services/acl.service';
import { StandardDocumentCategoryIdentifier } from '@enums';
import { UserPermissionNameEnum } from '@enums/users';
import { ExpensesReportForm } from '@forms';
import { filterNotNullOrUndefined } from '@helpers';
import { MatchingDialogService } from '@matching/matching-dialog/matching-dialog.service';
import {
  AdvancedExpense,
  BankTransferState,
  Document,
  ExpenseReportType,
  ExpensesReport,
  ForcedTravelWithId,
  LinkedBankTransaction,
  Tag,
  User,
} from '@models';
import {
  BankTransaction,
  BankTransactionImputation,
} from '@models/bank-transaction';
import { Label } from '@models/labels';
import {
  LinkedEntity,
  LinkedEntityBankTransaction,
  LinkedEntityImputation,
  LinkedEntityType,
} from '@models/linked-entities';
import { loadCategories } from '@store/categories';
import { userSelector } from '@store/user';

import { ConfirmationDialogService } from '../../../../../../../shared/components/confirmation-dialog/confirmation-dialog.service';
import { ContentNavigationLayoutComponent } from '../../../../../../company-shared/components/content-navigation-layout/content-navigation-layout.component';
import { EXPENSE_REPORT_TYPE_QUERY_PARAM } from '../../../../../../expense/expense-report-editor/expense-report-editor.component';
import { UnmatchLinkedEntityEvent } from '../../../../../document-shared/components/linked-entities/linked-entities.component';
import { ExpenseReportEditorAdvancedExpensesComponent } from '../expense-report-editor-advanced-expenses/expense-report-editor-advanced-expenses.component';
import { ExpensesReportEditorService } from './expenses-report-editor.service';

export enum ExpensesReportEditorStatus {
  Edit = 'edit',
  Preview = 'preview',
  Create = 'create',
}

@UntilDestroy()
@Component({
  selector: 'app-expenses-report-editor',
  templateUrl: './expenses-report-editor.component.html',
  styleUrls: ['./expenses-report-editor.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ExpensesReportEditorComponent implements OnInit, OnDestroy {
  @Input() set expensesReportId(expensesReportId: number | null) {
    this.expensesReportId$.next(expensesReportId);
  }

  @Input() set creation(creation: boolean) {
    if (creation === true) {
      this.editorStatus$.next(ExpensesReportEditorStatus.Create);
    }
  }

  @Input() expenseType: ExpenseReportType;

  @ViewChild(ExpenseReportEditorAdvancedExpensesComponent)
  advancedExpensesComponent: ExpenseReportEditorAdvancedExpensesComponent;

  expenseReport: ExpensesReport;
  document: Document;

  private previousRoute = '../../';
  private redirections = new Map<string, string>([
    ['expense-report', 'advanced-expenses/expense-report'],
  ]);

  private infoChangedSubscription: Subscription;

  private ownerChangedSubscription: Subscription;

  private accountingLockDate: string;

  private readonly trackingService = inject(TrackingService);

  readonly usersSelectOptions$: Observable<TiimeSelectOption<User>[]> =
    this.aclService
      .userHasPermission([UserPermissionNameEnum.ACCOUNTS_USER_FULL])
      .pipe(
        switchMap(userHasPermission =>
          iif(
            () => userHasPermission,
            this.usersService.getAll(),
            this.store.select(userSelector).pipe(map(user => [user])),
          ),
        ),
        map(users => [
          ...users.map((user: User) => ({
            value: user,
            label: `${user.firstName} ${user.lastName}`,
          })),
        ]),
      );

  readonly expenseReportTypeSelectOptions: TiimeSelectOption<ExpenseReportType>[] =
    [
      ...Object.values(ExpenseReportType).map(expenseReportType => ({
        value: expenseReportType,
        label: ExpensesReport.getExpenseReportTypeLabel(expenseReportType),
      })),
    ];

  readonly ExpensesReportEditorStatus = ExpensesReportEditorStatus;
  readonly ExpenseReportType = ExpenseReportType;
  readonly isInformationCardClosed$ = new BehaviorSubject(false);
  readonly editorStatus$ = new BehaviorSubject(
    ExpensesReportEditorStatus.Preview,
  );
  readonly tagSuggestions$ = this.tagsService.getTags();
  readonly form = new ExpensesReportForm();
  readonly UserPermissionNameEnum = UserPermissionNameEnum;

  readonly hasCheckedLines$ = combineLatest([
    this.form.advancedExpenses.valueChanges.pipe(
      startWith(this.form.advancedExpenses.value),
    ),
    this.form.travels.valueChanges.pipe(startWith(this.form.travels.value)),
  ]).pipe(
    map(
      ([advancedExpensesLinesFormsValues, travelsLinesFormsValues]: [
        {
          line: AdvancedExpense;
          checked: boolean;
        }[],
        {
          line: ForcedTravelWithId;
          checked: boolean;
        }[],
      ]) => {
        return (
          advancedExpensesLinesFormsValues.some(value => value.checked) ||
          travelsLinesFormsValues.some(value => value.checked)
        );
      },
    ),
  );

  readonly loading$ = new BehaviorSubject(true);

  readonly minDate$ = new BehaviorSubject<moment.Moment>(undefined);

  readonly canEditDate$: Observable<boolean> = this.editorStatus$.pipe(
    withLatestFrom(
      this.aclService.userHasPermission([
        UserPermissionNameEnum.ACCOUNTS_USER_FULL,
      ]),
    ),
    map(([status, hasPermission]) =>
      hasPermission
        ? status === ExpensesReportEditorStatus.Edit ||
          status === ExpensesReportEditorStatus.Create
        : false,
    ),
  );

  readonly expensesReportId$ = new ReplaySubject<ExpensesReport['id']>(1);

  readonly compareOptions = (a?: Label | User, b?: Label | User): boolean =>
    a?.id === b?.id;

  constructor(
    private readonly expensesReportService: ExpensesReportService,
    private readonly documentsService: DocumentsService,
    private readonly confirmationDialogService: ConfirmationDialogService,
    private readonly accountingPeriod: AccountingPeriodService,
    private readonly store: Store,
    private readonly router: Router,
    private readonly route: ActivatedRoute,
    private readonly layout: ContentNavigationLayoutComponent,
    private readonly cdr: ChangeDetectorRef,
    private readonly usersService: UsersService,
    private readonly tagsService: TagsService,
    private readonly expensesReportEditorService: ExpensesReportEditorService,
    private location: Location,
    private readonly matchingDialogService: MatchingDialogService,
    private readonly snackbarService: TiimeSnackbarService,
    private readonly titleService: TitleService,
    private readonly aclService: AclService,
    private readonly bankTransferOverlayService: BankTransferOverlayService,
  ) {
    this.titleService.setContentTitle('Note de frais');
    const fromTransactions =
      this.route.snapshot.queryParams.from_transactions === 'true';

    const fromTodo = this.route.snapshot.queryParams.from_todo === 'true';

    const fromAdvancedExpenses =
      this.route.snapshot.queryParams.from_advanced_expenses === 'true';

    const fromMileageAllowances =
      this.route.snapshot.queryParams.from_mileage_allowances === 'true';

    const fromBankTransfers =
      this.route.snapshot.queryParams.from_bank_transfers === 'true';

    if (fromTransactions) {
      this.previousRoute = '../../../../account/transactions';
    } else if (fromTodo) {
      this.previousRoute = '../../../../todo';
    } else if (fromAdvancedExpenses) {
      this.previousRoute = '../../advanced-expenses';
    } else if (fromMileageAllowances) {
      this.previousRoute = '../../mileage-allowances';
    } else if (fromBankTransfers) {
      this.previousRoute = '../../../../account/bank-transfers';
    } else {
      this.redirections.forEach((key, value) => {
        if (
          this.route.snapshot.url.findIndex(
            segment => segment.toString() === key,
          ) !== -1
        ) {
          this.previousRoute += value;
        }
      });
    }

    this.layout.enableNavigationBackButton({
      routerLink: this.previousRoute,
      label: 'Retour',
      relativeTo: this.route,
      queryParams: this.route.snapshot.queryParams,
    });
  }

  ngOnInit(): void {
    this.initForm();
    this.observeExpenseTypeChanges();
    this.handleEditorStatusChanges();
    this.store.dispatch(loadCategories());
  }

  ngOnDestroy(): void {
    this.layout.disableNavigationBackButton();
  }

  save(): void {
    const reportToSave = this.form.toExpensesReport();
    this.editorStatus$
      .pipe(
        take(1),
        switchMap(status => {
          if (
            this.form.expenseType.value === ExpenseReportType.AdvancedExpense
          ) {
            return this.saveAdvancedExpenses().pipe(map(() => status));
          }

          return of(true).pipe(
            tap(() => this.loading$.next(true)),
            map(() => status),
          );
        }),
        switchMap((status: ExpensesReportEditorStatus) => {
          if (status === ExpensesReportEditorStatus.Create) {
            return this.createExpensesReport(reportToSave);
          } else {
            return this.updateExpensesReport(reportToSave);
          }
        }),
        tap(expenseReport => {
          this.expenseReport = expenseReport;
          this.cdr.markForCheck();
          this.loading$.next(false);
        }),
      )
      .subscribe();
  }

  delete(): void {
    this.confirmationDialogService
      .requireConfirmation({
        theme: 'warn',
        title: [
          'Êtes-vous sûr de vouloir supprimer',
          `“ ${this.form.name.value} ” ?`,
        ],
        description: '',
        illu: 'assets/illus/illus-remove.svg',
      })
      .pipe(
        switchMap(() => this.documentsService.delete(this.form.reportId.value)),
        tap(
          (): void =>
            void this.router.navigate([this.previousRoute], {
              relativeTo: this.route,
            }),
        ),
      )
      .subscribe();
  }

  download(): void {
    this.documentsService
      .downloadPdf(this.form.toExpensesReport().id)
      .subscribe();
  }

  cancel(): void {
    this.expensesReportEditorService.cancelEdit();

    this.editorStatus$
      .pipe(
        take(1),
        tap(editorStatus => {
          if (editorStatus === ExpensesReportEditorStatus.Create) {
            this.location.back();
          } else if (editorStatus === ExpensesReportEditorStatus.Edit) {
            this.loading$.next(true);
            this.editorStatus$.next(ExpensesReportEditorStatus.Preview);
            this.form.reset(this.form.value, FormUtils.shouldNotEmitEvent);

            this.initForm();
          }
        }),
      )
      .subscribe();
  }

  openBankTransferOverlay(expensesReportId: number): void {
    this.documentsService
      .getDetail(expensesReportId)
      .pipe(
        take(1),
        tap(document => {
          const bankTransferState: BankTransferState = {
            document,
            reason: 'refund',
            transferSource: 'refundButton',
          };
          this.bankTransferOverlayService
            .open(bankTransferState)
            .pipe(tap(() => this.reloadExpenseReport()))
            .subscribe();
        }),
      )
      .subscribe();
  }

  reloadExpenseReport(): void {
    this.getExpensesReport().subscribe();
  }

  saveInfos(expensesReport: ExpensesReport): Observable<ExpensesReport> {
    return this.expensesReportService
      .updateCommentAndTags(expensesReport)
      .pipe(untilDestroyed(this));
  }

  onTagsInputClick(): void {
    this.trackingService.dispatch(
      new AddTagButtonClicked(AppliedOnEnum.DOCUMENTS),
    );
  }

  onTagsUpdate(tags: Tag[]): void {
    const newTags = tags.filter(tag => !this.form.tags.value.includes(tag));

    if (newTags.length) {
      this.trackingService.dispatch(new TagAdded(AppliedOnEnum.DOCUMENTS));
    }
  }

  private getAccountingLockDate(): Observable<string> {
    return this.aclService
      .userHasPermission([UserPermissionNameEnum.ACCOUNTS_USER_FULL])
      .pipe(
        filter(hasAccess => hasAccess),
        switchMap(() =>
          this.accountingPeriod.getAccountingLockDate().pipe(
            tap((date: string) => {
              if (!date) {
                return;
              }

              this.accountingLockDate = moment(date)
                .add(1, 'days')
                .format('YYYY-MM-DD');
            }),
          ),
        ),
      );
  }

  private getLastExpenseReportDate(user?: User): Observable<string> {
    const userId = user ? user.id : this.form.owner.value.id;
    return this.editorStatus$.pipe(
      take(1),
      switchMap(editorStatus =>
        this.expensesReportService.getLastExpenseReportDate(
          userId,
          editorStatus === ExpensesReportEditorStatus.Edit
            ? this.expenseReport.id
            : undefined,
        ),
      ),
    );
  }

  private setMinExpenseReportDate(): void {
    forkJoin([
      this.accountingLockDate
        ? of(this.accountingLockDate)
        : this.getAccountingLockDate(),
      this.form.expenseType.value === ExpenseReportType.Travel
        ? this.getLastExpenseReportDate(this.form.owner.value)
        : of(undefined),
    ])
      .pipe(
        untilDestroyed(this),
        tap(([accountingLockDate, lastExpenseReportDate]) => {
          if (accountingLockDate && lastExpenseReportDate) {
            this.minDate$.next(
              moment.max(
                moment(accountingLockDate),
                moment(lastExpenseReportDate),
              ),
            );
          } else if (lastExpenseReportDate) {
            this.minDate$.next(moment(lastExpenseReportDate));
          } else {
            this.minDate$.next(moment(accountingLockDate));
          }
        }),
      )
      .subscribe();
  }

  private handleEditorStatusChanges(): void {
    this.editorStatus$
      .pipe(
        tap(status => {
          if (status === ExpensesReportEditorStatus.Edit) {
            const wasExpenseTypeDisabled = this.form.expenseType.disabled;

            this.form.markAsPristine(FormUtils.shouldNotEmitEvent);
            this.form.enable();

            if (wasExpenseTypeDisabled) {
              this.form.expenseType.disable({ emitEvent: false });
            }
          } else if (status === ExpensesReportEditorStatus.Preview) {
            this.form.disable();
            this.form.comment.enable(FormUtils.shouldNotEmitEvent);
            this.form.tags.enable(FormUtils.shouldNotEmitEvent);
          }
        }),
        untilDestroyed(this),
      )
      .subscribe();
  }

  private getDocument(): void {
    this.expensesReportId$
      .pipe(
        take(1),
        switchMap(id => this.documentsService.getDetail(id)),
        tap(document => {
          if (!this.document) {
            this.trackingService.dispatch(
              new GedDocumentAccessed(
                document.expenseType === ExpenseReportType.AdvancedExpense
                  ? StandardDocumentCategoryIdentifier.ADVANCED_EXPENSE
                  : StandardDocumentCategoryIdentifier.MILEAGE_ALLOWANCE,
              ),
            );
          }

          this.document = document;
          this.cdr.markForCheck();
        }),
      )
      .subscribe();
  }

  private getExpensesReport(): Observable<ExpensesReport | null> {
    return this.editorStatus$.pipe(
      take(1),
      switchMap(status =>
        iif(
          () => status === ExpensesReportEditorStatus.Create,
          of(null),
          this.expensesReportId$.pipe(
            take(1),
            switchMap(id => this.expensesReportService.get({ id })),
            tap(expenseReport => {
              this.expenseReport = expenseReport;
              this.getDocument();
              this.cdr.markForCheck();
            }),
            catchError(err => {
              void this.router.navigate(['../../', { relativeTo: this.route }]);
              return throwError(err);
            }),
          ),
        ),
      ),
    );
  }

  private initForm(): void {
    forkJoin([this.getExpensesReport(), this.usersSelectOptions$.pipe(take(1))])
      .pipe(
        tap(([report, owner]) => {
          this.form.fromReport(
            report || {
              name:
                (this.expenseType === ExpenseReportType.AdvancedExpense
                  ? 'Note de frais à rembourser du'
                  : 'Note de frais kilométrique du') +
                ` ${moment().format('DD/MM/YYYY')}`,
              date: `${moment().format('YYYY-MM-DD')}`,
              owner: owner[0]?.value,
              expenseType: this.expenseType,
            },
          );

          this.observeInfosChanges();
        }),
        tap(() => {
          this.setMinExpenseReportDate();

          this.form.markAsPristine(FormUtils.shouldNotEmitEvent);
          this.cdr.markForCheck();
        }),
        finalize(() => this.loading$.next(false)),
      )
      .subscribe();
  }

  private createExpensesReport(
    report: ExpensesReport,
  ): Observable<ExpensesReport> {
    const queryParams = Object.keys(this.route.snapshot.queryParams)
      .filter(
        queryParamKey => queryParamKey !== EXPENSE_REPORT_TYPE_QUERY_PARAM,
      )
      .reduce((obj: { [key: string]: unknown }, key) => {
        obj[key] = this.route.snapshot.queryParams[key];
        return obj;
      }, {});

    return this.expensesReportService.create(report).pipe(
      switchMap(response => {
        response.comment = report.comment;
        response.tags = report.tags;
        return this.saveInfos(response);
      }),
      tap(
        expensesReport =>
          void this.router.navigate([`../${expensesReport.id}`], {
            relativeTo: this.route,
            queryParams,
          }),
      ),
    );
  }

  private updateExpensesReport(
    report: ExpensesReport,
  ): Observable<ExpensesReport> {
    return this.expensesReportService.update(report).pipe(
      tap(expenseReport => {
        this.expenseReport = expenseReport;
        this.editorStatus$.next(ExpensesReportEditorStatus.Preview);
      }),
    );
  }

  private observeInfosChanges(): void {
    if (this.infoChangedSubscription) {
      return;
    }

    this.infoChangedSubscription = combineLatest([
      this.form.comment.valueChanges.pipe(
        startWith(this.form.comment.value),
        distinctUntilChanged(),
      ),
      this.form.tags.valueChanges.pipe(
        startWith(this.form.tags.value),
        distinctUntilChanged(),
      ),
    ])
      .pipe(
        skip(1),
        debounceTime(300),
        switchMap(() => this.saveInfos(this.form.toExpensesReport())),
        untilDestroyed(this),
      )
      .subscribe();
  }

  protected openMatchingDialog(): void {
    this.matchingDialogService
      .openMatchingDialog({
        matchingSource: this.document,
        matchedItems: [
          ...this.document.getBankTransactions(),
          ...this.document.getImputations(),
        ],
      })
      .pipe(
        filterNotNullOrUndefined(),
        switchMap(({ labelOrTagToAdd, matchedItems }) =>
          this.documentsService
            .matchDocumentTransactions(
              this.document.id,
              matchedItems
                .filter(matchedItem => {
                  return (
                    matchedItem instanceof BankTransaction ||
                    matchedItem instanceof LinkedBankTransaction ||
                    matchedItem instanceof LinkedEntityBankTransaction
                  );
                })
                .map(transaction =>
                  transaction instanceof LinkedEntity
                    ? transaction.value.id
                    : transaction.id,
                ),
              matchedItems
                .filter(matchedItem => {
                  return (
                    matchedItem instanceof BankTransactionImputation ||
                    matchedItem instanceof LinkedEntityImputation
                  );
                })
                .map(imputation =>
                  imputation instanceof LinkedEntity
                    ? imputation.value.id
                    : imputation.id,
                ),
              labelOrTagToAdd instanceof Label ? labelOrTagToAdd.id : undefined,
              labelOrTagToAdd instanceof Tag ? labelOrTagToAdd.name : undefined,
            )
            .pipe(
              map(matchingResponse => ({
                matchingResponse,
                labelOrTagToAdd,
              })),
            ),
        ),
        tap(res => {
          let message =
            res.matchingResponse.length > 1
              ? 'Les transactions ont bien été liées au document'
              : 'La transaction a bien été liée au document';
          if (res.labelOrTagToAdd) {
            message = 'Le match et le label ont bien été pris en compte';
          }
          this.refreshDocumentAndShowSuccessMessage(message);
        }),
      )
      .subscribe();
  }

  protected handleUnmatchExpenseReport({
    linkedEntityType,
    id,
  }: UnmatchLinkedEntityEvent): void {
    let remainingTransactionIds = this.document.linkedEntities
      .filter(entity => entity.type === LinkedEntityType.BANK_TRANSACTION)
      .map(({ value }) => value.id);

    if (linkedEntityType === LinkedEntityType.BANK_TRANSACTION) {
      remainingTransactionIds = remainingTransactionIds.filter(
        entityId => entityId !== id,
      );
    }

    let remainingImputationIds = this.document.linkedEntities
      .filter(entity => entity.type === LinkedEntityType.IMPUTATION)
      .map(({ value }) => value.id);

    if (linkedEntityType === LinkedEntityType.IMPUTATION) {
      remainingImputationIds = remainingImputationIds.filter(
        entityId => entityId !== id,
      );
    }

    this.documentsService
      .matchDocumentTransactions(
        this.document.id,
        remainingTransactionIds,
        remainingImputationIds,
      )
      .pipe(
        tap(() =>
          this.refreshDocumentAndShowSuccessMessage(
            'La transaction a bien été déliée du document',
          ),
        ),
      )
      .subscribe();
  }

  private refreshDocumentAndShowSuccessMessage(successMessage: string): void {
    this.getDocument();
    this.snackbarService.open(successMessage, SnackbarConfig.success);
  }

  private observeExpenseTypeChanges(): void {
    this.form.expenseType.valueChanges
      .pipe(
        tap(expenseType => {
          this.setMinExpenseReportDate();
          if (expenseType === ExpenseReportType.Travel) {
            this.observeOwnerChanges();
          }

          if (this.form.name.pristine && !this.expenseReport) {
            this.setDefaultExpenseReportName(expenseType);
          }
        }),
        untilDestroyed(this),
      )
      .subscribe();
  }

  private observeOwnerChanges(): void {
    if (this.ownerChangedSubscription) {
      return;
    }

    this.ownerChangedSubscription = this.form.owner.valueChanges
      .pipe(
        filter(owner => !!owner),
        tap(() => this.setMinExpenseReportDate()),
        tap(() => {
          this.form.advancedExpenses.clear();
          this.form.travels.clear();
        }),
        untilDestroyed(this),
      )
      .subscribe();
  }

  private setDefaultExpenseReportName(expenseType: ExpenseReportType): void {
    const newName =
      (expenseType === ExpenseReportType.AdvancedExpense
        ? 'Note de frais à rembourser du'
        : 'Note de frais kilométrique du') +
      ` ${moment().format('DD/MM/YYYY')}`;

    this.form.name.setValue(newName, FormUtils.shouldNotEmitEvent);
  }

  private saveAdvancedExpenses(): Observable<Document[]> {
    return this.advancedExpensesComponent
      .checkForIncompleteAdvancedExpenses()
      .pipe(
        tap(() => this.loading$.next(true)),
        switchMap(() => this.advancedExpensesComponent.importDocuments()),
      );
  }
}
