import { TrackingEvent } from '@manakincubber/tiime-tracking';

export const ADD_TAG_BUTTON_CLICKED = 'Add Tag Button Clicked';

export enum AppliedOnEnum {
  TRANSACTIONS = 'transactions',
  DOCUMENTS = 'documents',
  INVOICE = 'facture',
  EXPENSE = 'frais_a_remourser',
  TRAVEL_EXPENSE = 'frais_kilometriques',
  QUOTE = 'devis',
}

export type AppliedOnType =
  | AppliedOnEnum.TRANSACTIONS
  | AppliedOnEnum.DOCUMENTS
  | AppliedOnEnum.INVOICE
  | AppliedOnEnum.EXPENSE
  | AppliedOnEnum.TRAVEL_EXPENSE
  | AppliedOnEnum.QUOTE;

export class AddTagButtonClicked implements TrackingEvent {
  readonly name = ADD_TAG_BUTTON_CLICKED;

  constructor(readonly appliedOn: AppliedOnType) {}
}
