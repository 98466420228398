import { PaginationData } from 'tiime-components';

import {
  getAmountCurrencyFromDocumentMetadata,
  getAmountFromDocumentMetadata,
  getDateFromDocumentMetadata,
  getVatFromDocumentMetadata,
  getWordingFromDocumentMetadata,
} from '../../company/document/document-shared/utils/functions';
import { Document } from './document';
import { Label, LabelApiContract } from './labels/label';
import { UserApiContract, User } from './user';

export interface AdvancedExpenseApiContract {
  id: number;
  date?: string | null;
  amount?: number | null;
  vat_amount?: number | null;
  currency?: string | null;
  label?: LabelApiContract | null;
  comment?: string | null;
  wording?: string | null;
  owner?: UserApiContract;
}

export class AdvancedExpense {
  constructor(
    public id: number,
    public date?: string,
    public amount?: number,
    public vatAmount?: number,
    public currency?: string,
    public label?: Label,
    public comment?: string,
    public wording?: string | null,
    public owner?: User,
  ) {}

  static fromJson(json: AdvancedExpenseApiContract): AdvancedExpense {
    return new AdvancedExpense(
      json.id,
      json.date || undefined,
      json.amount ?? undefined,
      json.vat_amount ?? undefined,
      json.currency || undefined,
      json.label ? Label.fromJson(json.label) : undefined,
      json.comment || undefined,
      json.wording || undefined,
      json.owner ? User.fromJson(json.owner) : undefined,
    );
  }

  static fromDocument(document: Document): AdvancedExpense {
    const { metadata } = document;
    return new AdvancedExpense(
      document.id,
      getDateFromDocumentMetadata(metadata),
      getAmountFromDocumentMetadata(metadata),
      getVatFromDocumentMetadata(metadata),
      getAmountCurrencyFromDocumentMetadata(metadata),
      document.label,
      document.comment,
      getWordingFromDocumentMetadata(metadata),
      document.owner,
    );
  }

  toJson(): AdvancedExpenseApiContract {
    return {
      id: this.id,
      amount: this.amount,
      currency: this.currency,
      date: this.date,
      vat_amount: this.vatAmount,
      label: this.label ? Label.toJson(this.label) : undefined,
      comment: this.comment,
      owner: this.owner ? User.toJson(this.owner) : undefined,
    };
  }
}

export interface AdvancedExpensesWithMetadataApiContract {
  advanced_expenses: AdvancedExpenseApiContract;
  metadata: {
    total_amount: number;
  };
}
export class AdvancedExpensesWithMetadata {
  constructor(
    public advanceExpenses: PaginationData<AdvancedExpense>,
    public metadata: AdvancedExpensesMetadata,
  ) {}
}

export interface AdvancedExpensesMetadata {
  totalAmount: number;
}
