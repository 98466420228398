import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import {
  TiimeCheckboxModule,
  TiimeInputContainerModule,
} from 'tiime-components';

import { SharedModule } from '@shared';

import { BankTransferParametersComponent } from './bank-transfer-parameters.component';

@NgModule({
  declarations: [BankTransferParametersComponent],
  imports: [
    CommonModule,
    SharedModule,
    ReactiveFormsModule,
    TiimeCheckboxModule,
    TiimeInputContainerModule,
  ],
  exports: [BankTransferParametersComponent],
})
export class BankTransferParametersModule {}
