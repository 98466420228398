<mat-menu class="user-filter-menu" xPosition="before">
  <div
    class="container list-container"
    (click)="$event.stopPropagation()"
    (keydown.tab)="$event.stopPropagation()"
  >
    <div class="title">
      <span>Filtrer les propriétaires</span>
      <mat-icon
        class="reset-filter-btn"
        svgIcon="icon-reset"
        (click)="reset()"
      ></mat-icon>
    </div>

    <form [formGroup]="form" (ngSubmit)="apply()">
      <div class="search">
        <input
          type="text"
          placeholder="Rechercher un propriétaire"
          #search
          [formControl]="searchControl"
        />
      </div>
      <div
        *ngIf="(isSearching$ | async) === false"
        class="list__menu-item select-all"
      >
        <mat-checkbox ngDefaultControl [formControl]="form.all">
          Tous les propriétaires
        </mat-checkbox>
      </div>
      <div class="separator"></div>
      <div class="filter-container" scrollShadows>
        <ng-container
          *ngFor="
            let user of filtersFormArray.controls;
            let i = index;
            trackBy: trackById
          "
        >
          <div *ngIf="filteredUsers.includes(users[i])" class="filter">
            <mat-checkbox
              ngDefaultControl
              [formControl]="filtersFormArray.controls[i]"
            >
              {{ users[i].firstName }} {{ users[i].lastName }}
            </mat-checkbox>
          </div>
        </ng-container>
      </div>
      <button class="apply" tiime-button xsmall accent>Appliquer</button>
    </form>
  </div>
</mat-menu>
