import { Directive } from '@angular/core';

@Directive({
  standalone: true,
  selector: '[appTabGroupItemIcon]',
  // eslint-disable-next-line @angular-eslint/no-host-metadata-property
  host: {
    class: 'tab-group-item-icon',
  },
})
// eslint-disable-next-line @angular-eslint/directive-class-suffix
export class TabGroupItemIcon {}
