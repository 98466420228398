import { Injectable } from '@angular/core';
import { UrlTree, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { map, Observable, withLatestFrom } from 'rxjs';

import { AclService } from '@core/services/acl.service';
import { selectedCompanySelector } from '@core/store';
import { UserPermissionNameEnum } from '@enums/users';

const CAN_ACCESS_HOME = [
  UserPermissionNameEnum.ACCOUNTS_USER_FULL,
  UserPermissionNameEnum.INVOICE_USER_FULL,
  UserPermissionNameEnum.INVOICE_USER_PARTIAL,
  UserPermissionNameEnum.BUSINESS_USER_FULL,
  UserPermissionNameEnum.BUSINESS_USER_PARTIAL,
];

@Injectable({
  providedIn: 'root',
})
export class AclHomeGuard {
  constructor(
    private readonly aclService: AclService,
    private readonly router: Router,
    private readonly store: Store,
  ) {}

  canMatch(): Observable<boolean | UrlTree> {
    return this.aclService.getCurrentUserPermissions().pipe(
      withLatestFrom(this.store.select(selectedCompanySelector)),
      map(([{ permissionAttributes }, company]) => {
        if (
          permissionAttributes.some(
            p => p.permit && CAN_ACCESS_HOME.includes(p.fullName),
          )
        ) {
          return true;
        }
        return this.router.createUrlTree(['companies', company.id, 'expense']);
      }),
    );
  }
}
