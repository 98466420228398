<form
  class="search-bar-wrapper"
  data-cy="search-bar"
  [formGroup]="searchbarForm"
  [class.active]="focused | map: mapToIsActive:searchbarForm.search.value"
  [class.disabled]="disabled"
>
  <div *ngIf="!searchbarForm.search.value" class="searchbar-icn">
    <mat-icon svgIcon="tc_icon-search"></mat-icon>
  </div>
  <input
    class="search-bar"
    placeholder="{{ placeholder }}"
    formControlName="search"
    #searchInput
    (keyup.enter)="onEnter()"
    (focus)="setFocused(true)"
    (blur)="setFocused(false)"
  />
  <div
    *ngIf="showClearButton"
    class="searchbar-icn clear"
    (click)="clearSearch()"
  >
    <mat-icon inline="true" svgIcon="tc_icon-cross-slim"></mat-icon>
  </div>
</form>
