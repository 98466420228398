import { RegistryTypeEnum } from '@enums';
import { MomentHelper } from '@helpers';

import { Label, LabelApiContract } from './labels';

export interface RegistryApiContract {
  id: number;
  company: { id: string };
  label: LabelApiContract;
  amount: number;
  date: string;
  operation_type: string;
  registry_type: RegistryTypeEnum;
  comment: string;
}

export class Registry {
  constructor(
    public id?: number,
    public company?: { id: string },
    public label?: Label,
    public amount?: number,
    public date: string = MomentHelper.todayDateString(),
    public operationType?: string,
    public registryType?: RegistryTypeEnum,
    public comment?: string,
  ) {}

  static fromJson(json: RegistryApiContract): Registry {
    return new Registry(
      json.id,
      json.company,
      json.label && Label.fromJson(json.label),
      json.amount,
      json.date,
      json.operation_type,
      json.registry_type,
      json.comment,
    );
  }

  static toJson(registry: Registry): Record<string, unknown> {
    return {
      id: registry.id,
      company: { id: registry.company?.id },
      label: registry.label ? { id: registry.label.id } : null,
      date: registry.date,
      amount: registry.amount,
      operation_type: registry.operationType,
      registry_type: registry.registryType,
      comment: registry.comment,
    };
  }
}
