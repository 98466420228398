import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  inject,
  Input,
  Output,
} from '@angular/core';
import { MatCheckboxModule } from '@angular/material/checkbox';

@Component({
  standalone: true,
  imports: [MatCheckboxModule],
  selector: 'tiime-mass-action-cell',
  templateUrl: './mass-action-cell.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MassActionCellComponent {
  protected elementRef = inject<ElementRef<HTMLTableCellElement>>(ElementRef);

  @Input() set checked(value: boolean) {
    this._checked = value;
    if (value) {
      this.elementRef.nativeElement.parentElement?.classList.add('selected');
    } else {
      this.elementRef.nativeElement.parentElement?.classList.remove('selected');
    }
  }

  get checked(): boolean {
    return this._checked;
  }

  @Output() readonly selectRow = new EventEmitter<void>();

  private _checked: boolean;
}
