import { BankConnectionStatus } from '../enum/bank-connection-status';
import { Bank, BankApiContract } from './bank';
import { BankAccount, BankAccountApiContract } from './bank-account';

export interface ChronosBankConnectionApiContract {
  id: number;
  synchronization_date: Date;
  status: BankConnectionStatus;
  bank: BankApiContract;
  provider_connection_id: number | null;
  provider_connection_status: string | null;
  deletable: boolean;
  closed: boolean;
  bank_accounts: BankAccountApiContract[] | null;
  short_bank_name: string | null;
  previous_status: BankConnectionStatus | null;
  status_updated_at: string | null;
}

export class ChronosBankConnection {
  constructor(
    public id: number,
    public synchronizationDate: Date | null,
    public status: BankConnectionStatus,
    public bank: Bank,
    public providerConnectionId: number,
    public providerConnectionStatus: string | null,
    public deletable: boolean,
    public closed: boolean,
    public bankAccounts: BankAccount[],
    public shortBankName: string,
    public previousStatus: BankConnectionStatus | null,
    public statusUpdatedAt: string | null,
  ) {}

  public static fromJson(
    json: ChronosBankConnectionApiContract,
  ): ChronosBankConnection {
    return new ChronosBankConnection(
      json.id,
      json.synchronization_date,
      json.status,
      Bank.fromJson(json.bank),
      json.provider_connection_id,
      json.provider_connection_status,
      json.deletable,
      json.closed,
      json.bank_accounts
        ? json.bank_accounts.map((accountJson: BankAccountApiContract) =>
            BankAccount.fromJson(accountJson),
          )
        : [],
      json.short_bank_name,
      json.previous_status,
      json.status_updated_at,
    );
  }
}
