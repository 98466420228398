import { Component, Input, ChangeDetectionStrategy } from '@angular/core';

@Component({
  selector: 'tiime-twocolors-icon',
  templateUrl: './twocolors-icon.component.html',
  styleUrls: ['./twocolors-icon.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TwocolorsIconComponent {
  @Input() icon: string;
  @Input() size: string;
  @Input() darkColor: string;
  @Input() lightColor: string;

  get iconStyle(): Record<string, string> {
    return {
      width: `${this.size}px`,
      height: `${this.size}px`,
      fill: this.darkColor,
      color: this.lightColor,
    };
  }
}
