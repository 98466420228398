import {
  Component,
  ViewEncapsulation,
  ChangeDetectionStrategy,
  Input,
  ElementRef,
} from '@angular/core';

import { HasAttributesBase } from '../core/index';

@Component({
  selector: 'tiime-tag',
  templateUrl: './tag.component.html',
  styleUrls: ['./tag.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TagComponent extends HasAttributesBase {
  @Input() tag: string;
  @Input() color:
    | ''
    | 'primary'
    | 'accent'
    | 'warn'
    | 'border'
    | 'light'
    | 'foreground'
    | 'text' = '';

  get colorClass(): Record<string, boolean> {
    return {
      primary: this.color === 'primary',
      accent: this.color === 'accent',
      warn: this.color === 'warn',
      border: this.color === 'border',
      light: this.color === 'light',
      foreground: this.color === 'foreground',
      text: this.color === 'text',
      small: this._hasHostAttributes('small'),
    };
  }

  constructor(elementRef: ElementRef) {
    super(elementRef);
  }
}
