<div class="linked-items-content">
  <div class="linked-items-header">
    <app-category-icon
      *ngIf="entitiesType === LinkedEntityType.EXPENSE_REPORT; else imgTemplate"
      [category]="StandardDocumentCategoryIdentifier.EXPENSE_REPORT"
      [greyed]="true"
    ></app-category-icon>

    <ng-template #imgTemplate>
      <img alt="" [src]="headerIcon | map: mapToTitleIcon" />
    </ng-template>
    <span class="linked-items-title" [ngPlural]="linkedEntities.length">
      <ng-template ngPluralCase="=0">{{ headerWording.singular }}</ng-template>
      <ng-template ngPluralCase="=1">{{ headerWording.singular }}</ng-template>
      <ng-template ngPluralCase="other">{{ headerWording.plural }}</ng-template>
      :
    </span>
  </div>
  <div
    *ngFor="let linkedEntity of linkedEntities; trackBy: trackById"
    class="linked-item transaction-item"
  >
    <ng-container [ngSwitch]="entitiesType">
      <ng-container *ngSwitchCase="LinkedEntityType.BANK_TRANSACTION">
        <app-linked-bank-transaction-line
          [linkedEntity]="linkedEntity"
        ></app-linked-bank-transaction-line>
      </ng-container>
      <ng-container *ngSwitchCase="LinkedEntityType.EXPENSE_REPORT">
        <app-linked-expense-report-line
          [linkedEntity]="linkedEntity"
        ></app-linked-expense-report-line>
      </ng-container>
    </ng-container>
  </div>
</div>
