<form *ngIf="pinCodeForm" class="pin-code" [formGroup]="pinCodeForm">
  <div
    class="pin-code__input-container"
    [class.pin-code__input-container--error]="
      pinCodeForm.code.errors | isCodeInputInvalid
    "
  >
    <p>Choisissez votre code PIN</p>
    <app-pin-code-input
      formControlName="code"
      [color]="(pinCodeForm.code.errors | isCodeInputInvalid) ? 'warn' : null"
      [showCheckmarkOnInputValid]="
        !(pinCodeForm.code.errors | isCodeInputInvalid)
      "
    ></app-pin-code-input>
    <div *ngIf="pinCodeForm.code.errors | isCodeInputInvalid" class="error">
      Sécurité trop faible (évitez la répétition du même chiffre)
    </div>
  </div>
  <div
    class="pin-code__input-container"
    [class.pin-code__input-container--error]="
      pinCodeForm.errors
        | isConfirmCodeInvalid
          : pinCodeForm.code.valid
          : pinCodeForm.confirmCode.errors
    "
  >
    <p>Confirmez votre code PIN</p>
    <app-pin-code-input
      formControlName="confirmCode"
      [color]="
        (pinCodeForm.errors
        | isConfirmCodeInvalid
          : pinCodeForm.code.valid
          : pinCodeForm.confirmCode.errors)
          ? 'warn'
          : null
      "
      [showCheckmarkOnInputValid]="
        !(
          (pinCodeForm.errors
            | isConfirmCodeInvalid
              : pinCodeForm.code.valid
              : pinCodeForm.confirmCode.errors) ||
          (pinCodeForm.code.errors | isCodeInputInvalid)
        )
      "
    ></app-pin-code-input>
    <div
      *ngIf="
        pinCodeForm.errors
          | isConfirmCodeInvalid
            : pinCodeForm.code.valid
            : pinCodeForm.confirmCode.errors
      "
      class="error"
    >
      Le code n’est pas identique
    </div>
  </div>
</form>
