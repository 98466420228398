import { ChangeDetectionStrategy, Component } from '@angular/core';

import {
  LinkedEntityBankTransaction,
  LinkedEntityImputation,
} from '@models/linked-entities';

import { AbstractLinkedLineDirective } from '../abstract-linked-line/abstract-linked-line.directive';

@Component({
  selector: 'app-linked-bank-transaction-line',
  styleUrls: ['./linked-bank-transaction-line.component.scss'],
  templateUrl: './linked-bank-transaction-line.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LinkedBankTransactionLineComponent extends AbstractLinkedLineDirective<
  LinkedEntityBankTransaction | LinkedEntityImputation
> {}
