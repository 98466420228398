<div class="user-account-menu-panel tiime-theme" cdkScrollable>
  <div class="selected-company">
    <app-company-acronym-logo
      [company]="userAccountMenuData.selectedCompany"
      [size]="50"
    ></app-company-acronym-logo>
    <div class="company-name-container">
      <span
        class="company-name"
        tiimeTooltip
        tooltipPosition="bottom"
        hideWithoutEllipsis
        tiimeMultilineEllipsis
        [content]="companyName"
      >
        {{ userAccountMenuData.selectedCompany.name }}
      </span>
      <ng-template #companyName>
        <div class="template-tooltip">
          {{ userAccountMenuData.selectedCompany.name }}
        </div>
      </ng-template>
      <span class="user-name">
        {{ userAccountMenuData.user.firstName }}
        {{ userAccountMenuData.user.lastName }}
      </span>
    </div>
    <button
      *hasAccess="[
        UserPermissionNameEnum.ACCOUNTS_USER_FULL,
        UserPermissionNameEnum.BUSINESS_USER_FULL
      ]"
      class="selected-company-see-more"
      tiime-button
      neutral
      inverted
      xsmall
      (click)="openSettings(userAccountMenuData.selectedCompany)"
    >
      Voir plus...
    </button>
  </div>
  <div
    *ngIf="userAccountMenuData.user.isBusinessAgent"
    class="search-company"
    (click)="$event.stopPropagation()"
  >
    <ng-container *tiimeLet="companySuggestions$ | async as suggestions">
      <div class="search-company__input--container">
        <input
          class="search-company__input"
          placeholder="Rechercher une société"
          tiimeAutoFocus
          #companyInput
          [formControl]="searchCompanyFormControl"
          [matAutocomplete]="companyAutocomplete"
          [matAutocompleteConnectedTo]="origin"
        />
        <mat-progress-spinner
          *ngIf="searchCompanyloading$ | async"
          diameter="18"
          color="primary"
          mode="indeterminate"
        ></mat-progress-spinner>
      </div>
      <mat-autocomplete
        class="company-autocomplete-panel"
        #companyAutocomplete="matAutocomplete"
        [displayWith]="displayFn"
        (optionSelected)="switchToCompany($event.option.value)"
      >
        <mat-option
          *ngFor="let suggestion of suggestions; trackBy: trackById"
          class="company-option"
          [value]="suggestion"
        >
          <div class="suggestion">
            <app-company-acronym-logo
              [company]="suggestion"
              [size]="18"
            ></app-company-acronym-logo>
            <span class="suggestion-name">{{ suggestion.name }}</span>
          </div>
        </mat-option>
      </mat-autocomplete>
    </ng-container>
  </div>
  <div
    *tiimeLet="inactiveCompanyLoading$ | async as inactiveCompanyLoading"
    class="menu-items"
  >
    <div
      *ngFor="let company of inactiveCompanies; trackBy: trackById"
      class="menu-item companies"
      (click)="switchToCompany(company)"
    >
      <app-company-acronym-logo
        [company]="company"
        [size]="28"
      ></app-company-acronym-logo>
      <span class="name">{{ company.name }}</span>
    </div>
    <div
      *ngIf="companyPaginationRange.next() && !inactiveCompanyLoading"
      class="see-more-container"
    >
      <button
        class="see-more"
        tiime-button
        neutral
        inverted
        (click)="loadMoreCompanies()"
      >
        Voir plus de sociétés...
      </button>
    </div>
    <app-tiime-spinner *ngIf="inactiveCompanyLoading"></app-tiime-spinner>
    <div class="menu-item settings" (click)="openHelp()">
      <mat-icon class="icon-info" svgIcon="icon-info"></mat-icon>
      <span>Aide en ligne</span>
    </div>
    <div
      *tiimeLet="isAccountant$ | async as isAccountant"
      class="menu-item settings"
      tiimeTooltip
      tooltipPosition="bottom"
      [class.disabled]="isAccountant"
      [tooltipDisabled]="!isAccountant"
      [content]="accountantTooltip"
      (click)="!isAccountant && openCareContactDialog()"
    >
      <mat-icon svgIcon="icon-help"></mat-icon>
      <span>Contacter le support Tiime</span>
      <mat-icon *ngIf="isAccountant" svgIcon="icon-info"></mat-icon>
    </div>
    <ng-template #accountantTooltip>
      Pour effectuer une demande de support,
      <br />
      vous devez nous contacter depuis Tiime Expert
    </ng-template>
    <div
      class="menu-item logout"
      data-cy="user-account-menu-logout"
      (click)="logout()"
    >
      <span>Me déconnecter</span>
    </div>
  </div>
</div>
