import { AsyncPipe } from '@angular/common';
import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { AnimationOptions, LottieComponent } from 'ngx-lottie';
import { TiimeSpinnerComponent } from 'projects/tiime/src/app/company/company-shared/components/tiime-spinner/tiime-spinner.component';
import { delay, filter, tap } from 'rxjs';
import { TiimeButtonModule } from 'tiime-components';

import { SidePanelComponentBase } from '@services/side-panel';
import { ErrorBannerComponent, IconsModule } from '@shared';

import { BankTransfersSharedModule } from '../../bank-transfers-shared.module';
import {
  BankTransferOverlayService,
  OcerisationStep,
} from '../bank-transfert-overlay/bank-transfer-overlay.service';
import { BankTransferSidePanelData } from './bank-transfer-side-panel-data';

@UntilDestroy()
@Component({
  standalone: true,
  selector: 'app-bank-transfer-side-panel',
  templateUrl: 'bank-transfer-side-panel.component.html',
  styleUrls: ['bank-transfer-side-panel.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    AsyncPipe,
    BankTransfersSharedModule,
    TiimeButtonModule,
    TiimeSpinnerComponent,
    IconsModule,
    ErrorBannerComponent,
    LottieComponent,
  ],
})
export class BankTransferSidePanelComponent extends SidePanelComponentBase<BankTransferSidePanelData> {
  private readonly SKIP_DELAY = 4000;

  private readonly bankTransferOverlayService = inject(
    BankTransferOverlayService,
  );

  readonly OcerisationStep = OcerisationStep;
  readonly ocerisationStep$ = this.bankTransferOverlayService.ocerisationStep$;

  readonly allowSkip$ = this.bankTransferOverlayService.allowSkip$;

  readonly loaderOptions: AnimationOptions = {
    path: '/assets/anims/bank-transfer/during-scan.json',
  };

  constructor() {
    super();

    this.ocerisationStep$
      .pipe(
        filter(step => step === OcerisationStep.OCERISATION),
        tap(() => this.allowSkip$.next(false)),
        delay(this.SKIP_DELAY),
        tap(() => this.allowSkip$.next(true)),
        untilDestroyed(this),
      )
      .subscribe();
  }
}
