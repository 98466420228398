import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TiimePipesModule } from '@manakincubber/tiime-utils';

import { TiimeIconModule } from '../icon';
import { InputContainerNativeControl } from './input-container-native-control.directive';
import { InputContainerComponent } from './input-container.component';

@NgModule({
  imports: [CommonModule, TiimeIconModule, TiimePipesModule],
  declarations: [InputContainerComponent, InputContainerNativeControl],
  exports: [InputContainerComponent, InputContainerNativeControl],
})
export class TiimeInputContainerModule {}
