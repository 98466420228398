import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { NgUtils } from '@manakincubber/tiime-utils';
import { TiimeDialogModule } from 'tiime-components';

@Component({
  standalone: true,
  imports: [CommonModule, TiimeDialogModule],
  selector: 'app-confirmation-dialog-content',
  templateUrl: './confirmation-dialog-content.component.html',
  styleUrls: ['./confirmation-dialog-content.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ConfirmationDialogContentComponent {
  @Input() illu: string;
  @Input() title: string[];
  @Input() description: string[];

  readonly trackByIndex = NgUtils.trackByIndex;
}
