import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TiimePipesModule } from '@manakincubber/tiime-utils';

import { DatetimePipeModule } from '../pipes';
import { DateListCellComponent } from './date-list-cell.component';

@NgModule({
  imports: [CommonModule, TiimePipesModule, DatetimePipeModule],
  declarations: [DateListCellComponent],
  exports: [DateListCellComponent],
})
export class TiimeDateListCellModule {}
