import { AbstractLine, AbstractLineApiContract } from '@models';

export type TextLineApiContract = AbstractLineApiContract;

export class TextLine extends AbstractLine {
  constructor(id: number, description: string, sequence: number) {
    super(id, description, sequence);
  }

  public static fromJson(json: TextLineApiContract): TextLine {
    return new TextLine(json.id, json.description, json.sequence);
  }

  public static toJson(textLine: TextLine): Record<string, unknown> {
    return {
      id: textLine.id || undefined,
      description: textLine.description,
      sequence: textLine.sequence,
    };
  }
}
