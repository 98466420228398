import { Pipe, PipeTransform } from '@angular/core';

import { Document } from '@models';
import { LinkedEntity, LinkedEntityType } from '@models/linked-entities';

@Pipe({
  name: 'filterLinkedEntities',
})
export class FilterLinkedEntitiesPipe implements PipeTransform {
  transform(
    document: Document,
    ...linkedEntityType: LinkedEntityType[]
  ): LinkedEntity[] {
    return (
      document.linkedEntities?.filter(entity =>
        linkedEntityType.includes(entity.type),
      ) || []
    );
  }
}
