<span *ngIf="linkedItemsNumber > 1" class="linked-items-number">
  {{ linkedItemsNumber }}
</span>
<div
  class="tooltip-icon linked-items-icon"
  tiimeTooltip
  [content]="linkedItemsTooltipTemplate"
  [tooltipPosition]="tooltipPosition"
  [tooltipInvertedPosition]="tooltipInvertedPosition"
  [tooltipClass]="['white-tooltip', 'without-padding']"
  (click)="openLinkTransactionDialog.emit()"
>
  <app-category-icon
    *ngIf="icon === 'icon-expense-report'; else matIconTemplate"
    [category]="StandardDocumentCategoryIdentifier.EXPENSE_REPORT"
    [greyed]="iconGreyed"
  ></app-category-icon>

  <ng-template #matIconTemplate>
    <mat-icon [svgIcon]="icon"></mat-icon>
  </ng-template>
</div>
<ng-template #linkedItemsTooltipTemplate>
  <div class="linked-items-tooltip">
    <ng-content></ng-content>
  </div>
</ng-template>
