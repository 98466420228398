import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  Output,
  ViewChild,
} from '@angular/core';
import { Mapper, TiimePipesModule } from '@manakincubber/tiime-utils';
import { BehaviorSubject, finalize, tap } from 'rxjs';
import { filter, withLatestFrom } from 'rxjs/operators';
import {
  TiimeIconModule,
  TiimeLetModule,
  TiimeOverlayRef,
  TiimeOverlayService,
} from 'tiime-components';

import { filterNotNullOrUndefined } from '@core/helpers';
import {
  DecisionTreeActionTypeChoice,
  DecisionTreeDocumentChoice,
  DecisionTreeDocumentNeeded,
} from '@core/models';
import { mapToActionTypeIconPath } from '@core/utils/bank-transfer.utils';

import { BankTransferDocumentProofChoiceOverlayComponent } from '../bank-transfer-document-proof-choice-overlay/bank-transfer-document-proof-choice-overlay.component';

@Component({
  selector: 'app-bank-transfer-document-proof-choice',
  standalone: true,
  imports: [
    CommonModule,
    TiimeLetModule,
    TiimePipesModule,
    TiimeIconModule,
    BankTransferDocumentProofChoiceOverlayComponent,
  ],
  templateUrl: './bank-transfer-document-proof-choice.component.html',
  styleUrls: ['./bank-transfer-document-proof-choice.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BankTransferDocumentProofChoiceComponent {
  @Input() label: string;
  @Input() actionType: DecisionTreeActionTypeChoice;

  @Input() set children(
    children: DecisionTreeDocumentNeeded[] | DecisionTreeDocumentChoice[],
  ) {
    if (this.isChoiceAction(children[0])) {
      this.childrenChoices = children as DecisionTreeDocumentChoice[];
    } else {
      this.childrenChoices = undefined;
    }
  }

  @Output() readonly documentsNeeded = new EventEmitter<
    DecisionTreeDocumentNeeded[]
  >();

  @Output() readonly selectedChoice =
    new EventEmitter<DecisionTreeDocumentChoice>();

  @ViewChild('selectorContainer')
  private readonly selectorContainer: ElementRef<HTMLElement>;

  readonly mapToIconPath: Mapper<string | undefined, string> =
    mapToActionTypeIconPath;

  isMenuOpened = false;
  readonly selectedChild$ = new BehaviorSubject<
    DecisionTreeDocumentNeeded | DecisionTreeDocumentChoice
  >(null);
  readonly nextChoice$ = new BehaviorSubject<DecisionTreeDocumentChoice>(
    undefined,
  );

  private overlayRef: TiimeOverlayRef | null = null;
  private childrenChoices: DecisionTreeDocumentChoice[] = undefined;

  constructor(private readonly overlayService: TiimeOverlayService) {}

  toggleMenu(): void {
    if (this.overlayRef) {
      this.overlayRef.close();
      this.isMenuOpened = false;
      return this.resetOverlay();
    }
    this.isMenuOpened = true;
    const width = this.selectorContainer.nativeElement.offsetWidth;
    const overlayRef = this.overlayService.open<
      BankTransferDocumentProofChoiceOverlayComponent,
      DecisionTreeDocumentChoice[],
      DecisionTreeDocumentChoice
    >(BankTransferDocumentProofChoiceOverlayComponent, this.childrenChoices, {
      hasBackdrop: true,
      backdropClose: true,
      backdropClass: 'cdk-overlay-transparent-backdrop',
      width,
      maxHeight: '200px',
      connectTo: {
        origin: this.selectorContainer,
        positions: [
          {
            originX: 'center',
            originY: 'bottom',
            overlayX: 'center',
            overlayY: 'top',
            offsetY: 10,
          },
        ],
      },
      repositionOnScroll: true,
    });
    this.overlayRef = overlayRef;

    overlayRef
      .beforeClosed()
      .pipe(
        tap(() => this.resetOverlay()),
        filterNotNullOrUndefined(),
        withLatestFrom(this.selectedChild$),
        filter(([currentChoice, previousChoice]) =>
          this.shouldUpdateChoice(previousChoice, currentChoice),
        ),
        tap(([currentChoice]) => {
          this.selectedChild$.next(currentChoice);
          this.selectedChoice.emit(currentChoice);

          if (this.isChoiceAction(currentChoice.children[0])) {
            this.nextChoice$.next(currentChoice.children[0]);
            this.documentsNeeded.emit(undefined);
          } else {
            this.documentsNeeded.emit(
              currentChoice.children as DecisionTreeDocumentNeeded[],
            );
            this.nextChoice$.next(undefined);
          }
        }),
        finalize(() => (this.isMenuOpened = false)),
      )
      .subscribe();
  }

  private isChoiceAction(
    maybeChoice: DecisionTreeDocumentChoice | DecisionTreeDocumentNeeded,
  ): maybeChoice is DecisionTreeDocumentChoice {
    return maybeChoice.action === 'choice';
  }

  private resetOverlay(): void {
    this.overlayRef = null;
  }

  private shouldUpdateChoice(
    previousChoice:
      | DecisionTreeDocumentChoice
      | DecisionTreeDocumentNeeded
      | null,
    currentChoice: DecisionTreeDocumentChoice,
  ): boolean {
    if (!previousChoice) {
      return true;
    }

    const prevChoice = previousChoice as DecisionTreeDocumentChoice;

    const prevChild = prevChoice.children ? prevChoice.children[0] : null;
    const currChild = currentChoice.children ? currentChoice.children[0] : null;

    if (
      prevChild &&
      currChild &&
      this.isChoiceAction(prevChild) &&
      this.isChoiceAction(currChild) &&
      currentChoice.action_type !== prevChoice.action_type
    ) {
      return true;
    }

    return prevChoice.label !== currentChoice.label;
  }
}
