import { Country } from '@core/models/country';
import {
  ClientBalanceMethod,
  DueDateMode,
  InvoicingTemplate,
  InvoicingCategoriesEnum,
} from '@enums';

import { BankAccount } from './bank-account';
import { VatType } from './vat-type';

export class InvoicingConfig {
  constructor(
    public id?: number,
    public email?: string,
    public phone?: string,
    public header?: string,
    public footer?: string,
    public logoId?: number,
    public logoWidth?: number,
    public logoHeight?: number,
    public color?: string,
    public template?: InvoicingTemplate,
    public vatType?: VatType,
    public bankAccount?: BankAccount,
    public invoiceBankDetailEnabled?: boolean,
    public invoicePaymentConditionEnabled?: boolean,
    public invoiceFreeField?: string,
    public invoiceFreeFieldEnabled?: boolean,
    public quotationAcceptanceConditionsEnabled?: boolean,
    public quotationFreeField?: string,
    public quotationFreeFieldEnabled?: boolean,
    public quotationFirstNumber?: number,
    public allowResetInvoicing?: boolean,
    public invoiceFirstNumber?: number,
    public clientBalanceMethod?: ClientBalanceMethod,
    public numberTemplate?: string,
    public dueDateMode?: DueDateMode,
    public dueDate?: string,
    public country?: Country,
    public stripePaymentsEnabled?: boolean,
    public invoicingCategoryType?: InvoicingCategoriesEnum,
  ) {}

  public static fromJson(json: Record<string, any>): InvoicingConfig {
    return new InvoicingConfig(
      json.id,
      json.email,
      json.phone || null,
      json.header,
      json.footer,
      json.logo ? json.logo.id : null,
      json.logo_width,
      json.logo_height,
      json.color,
      json.template,
      VatType.fromJson(json.vat_type),
      json.bank_account ? BankAccount.fromJson(json.bank_account) : null,
      json.invoice_bank_detail_enabled,
      json.invoice_payment_condition_enabled,
      json.invoice_free_field,
      json.invoice_free_field_enabled,
      json.quotation_acceptance_conditions_enabled,
      json.quotation_free_field,
      json.quotation_free_field_enabled,
      json.quotation_first_number,
      json.allow_reset_invoicing,
      json.invoice_first_number,
      json.client_balance_method,
      json.number_template,
      json.due_date_mode,
      json.due_date,
      json.country,
      json.stripe_enabled,
      json.invoicing_category_type,
    );
  }

  public static toJson(
    invoicingConfig: InvoicingConfig,
  ): Record<string, unknown> {
    return {
      id: invoicingConfig.id,
      email: invoicingConfig.email || null,
      phone: invoicingConfig.phone || null,
      header: invoicingConfig.header,
      footer: invoicingConfig.footer,
      logo: invoicingConfig.logoId
        ? {
            id: invoicingConfig.logoId,
          }
        : null,
      color: invoicingConfig.color,
      template: invoicingConfig.template,
      vat_type: VatType.toJson(invoicingConfig.vatType),
      bank_account:
        invoicingConfig.bankAccount && invoicingConfig.bankAccount.id
          ? BankAccount.toJson(invoicingConfig.bankAccount)
          : null,
      invoice_bank_detail_enabled: invoicingConfig.invoiceBankDetailEnabled,
      invoice_payment_condition_enabled:
        invoicingConfig.invoicePaymentConditionEnabled,
      invoice_free_field: invoicingConfig.invoiceFreeField,
      invoice_free_field_enabled: invoicingConfig.invoiceFreeFieldEnabled,
      quotation_acceptance_conditions_enabled:
        invoicingConfig.quotationAcceptanceConditionsEnabled,
      quotation_free_field: invoicingConfig.quotationFreeField,
      quotation_free_field_enabled: invoicingConfig.quotationFreeFieldEnabled,
      invoice_first_number: invoicingConfig.invoiceFirstNumber,
      quotation_first_number: invoicingConfig.quotationFirstNumber,
      number_template: invoicingConfig.numberTemplate,
      due_date_mode: invoicingConfig.dueDateMode,
      due_date: invoicingConfig.dueDate || undefined,
      invoicing_category_type: invoicingConfig.invoicingCategoryType,
    };
  }
}
