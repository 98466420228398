import { FormControl, Validators } from '@angular/forms';
import { FormUtils } from '@manakincubber/tiime-utils';

import { AbstractInvoicingDocumentForm } from '@bases';
import { InvoicingTemplate } from '@enums';
import { InvoicingConfig, Quote } from '@models';

export class QuoteForm extends AbstractInvoicingDocumentForm<Quote> {
  get invoiceId(): FormControl {
    return this.get('invoiceId') as FormControl;
  }
  get acceptanceConditions(): FormControl {
    return this.get('acceptanceConditions') as FormControl;
  }
  get validityPeriod(): FormControl {
    return this.get('validityPeriod') as FormControl;
  }
  get totalExcludingTaxesErrorMessage(): string {
    return this.totalExcludingTaxes.touched &&
      this.totalExcludingTaxes.hasError('min')
      ? `Le devis doit être positif`
      : '';
  }

  constructor() {
    super({
      invoiceId: new FormControl(null),
      totalExcludingTaxes: new FormControl(null, Validators.min(0)),
      acceptanceConditions: new FormControl(null),
      validityPeriod: new FormControl(null),
    });
  }

  fromInvoicingConfig(invoicingConfig: InvoicingConfig): void {
    this.patchValue(
      {
        header: invoicingConfig.header,
        footer: invoicingConfig.footer,
        logo: {
          id: invoicingConfig.logoId,
          width: invoicingConfig.logoWidth,
          height: invoicingConfig.logoHeight,
        },
        color: invoicingConfig.color,
        template: invoicingConfig.template,
        freeField: invoicingConfig.quotationFreeField,
        config: {
          freeFieldEnabled: invoicingConfig.quotationFreeFieldEnabled,
          acceptanceConditionsEnabled:
            invoicingConfig.quotationAcceptanceConditionsEnabled,
          country: invoicingConfig.country,
          template: invoicingConfig.template,
          titleEnabled: invoicingConfig.template !== InvoicingTemplate.standard,
        },
      },
      FormUtils.shouldNotEmitEvent,
    );
  }

  toQuote(): Quote {
    return this.toInvoicingDocument();
  }
}
