import { NgModule } from '@angular/core';

import { IsFilterActivePipe } from './is-filter-active.pipe';
import { NumberFilterPatternPipe } from './number-filter-pattern.pipe';
import { ToFilterValuePipe } from './to-filter-value.pipe';
import { ToSortValuePipe } from './to-sort-value.pipe';

@NgModule({
  imports: [],
  exports: [
    IsFilterActivePipe,
    ToSortValuePipe,
    ToFilterValuePipe,
    NumberFilterPatternPipe,
  ],
  declarations: [
    IsFilterActivePipe,
    ToSortValuePipe,
    ToFilterValuePipe,
    NumberFilterPatternPipe,
  ],
})
export class TableColumnFilterPipesModule {}
