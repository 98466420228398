import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { GoogleMapsComponent } from './google-maps/google-maps.component';
import { GooglePlacesAutocompleteDirective } from './google-places-autocomplete/google-places-autocomplete.directive';

export function initializeGooglePlacesModule(googleApiKey: string): void {
  const mapsScript = document.createElement('script');
  mapsScript.type = 'text/javascript';
  mapsScript.async = false;
  mapsScript.src = `https://maps.googleapis.com/maps/api/js?key=${googleApiKey}&libraries=places,maps`;
  document.head.appendChild(mapsScript);
}

@NgModule({
  imports: [CommonModule],
  declarations: [GooglePlacesAutocompleteDirective, GoogleMapsComponent],
  exports: [GooglePlacesAutocompleteDirective, GoogleMapsComponent],
})
export class TiimeGooglePlacesModule {}
