import { AndFilter } from './filter';
import { ParamOption } from './param-option';

export class Expand implements ParamOption {
  private readonly andFilter: AndFilter<string>;

  constructor(...expansionKeys: ReadonlyArray<string>) {
    this.andFilter = new AndFilter('expand', expansionKeys);
  }

  toParamObject(): { [param: string]: string | undefined } {
    return this.andFilter.toParamObject();
  }
}
