import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
  inject,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { AnimationOptions } from 'ngx-lottie';
import { tap } from 'rxjs/operators';
import { SnackbarConfig, TiimeSnackbarService } from 'tiime-components';

import { BankTransferInitiationForm } from '@forms';
import { filterNotNullOrUndefined } from '@helpers';
import { BankAccount, BankTransferReason, Beneficiary } from '@models';
import { TIIME_ICON_PREFIX } from '@shared';

import {
  AddBeneficiaryDialogComponent,
  AddBeneficiaryDialogData,
} from '../add-beneficiary-dialog/add-beneficiary-dialog.component';
import {
  BankTransferOverlayService,
  OcerisationStep,
} from '../bank-transfert-overlay/bank-transfer-overlay.service';

@Component({
  selector: 'app-bank-transfer-initiation',
  templateUrl: './bank-transfer-initiation.component.html',
  styleUrls: ['./bank-transfer-initiation.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BankTransferInitiationComponent {
  @Input() form: BankTransferInitiationForm;
  @Input() bankAccounts: BankAccount[];
  @Input() beneficiaries: Beneficiary[];
  @Input() nameFromDocument: string | null = '';
  @Input() ibanFromDocument: string | null = '';
  @Input() personalWalletBeneficiary: Beneficiary;
  @Input() selectedAccount: BankAccount | null = null;
  @Input() selectedBeneficiary: Beneficiary | null = null;
  @Input() amount: number | null = null;
  @Input() reason: BankTransferReason;
  @Input() shouldDisplayLastScreenRecap: boolean;

  @Output() readonly amountChange = new EventEmitter<number | null>();
  @Output() readonly beneficiaryCreated = new EventEmitter<Beneficiary>();

  readonly OcerisationStep = OcerisationStep;
  readonly ocerisationStep$ = inject(BankTransferOverlayService)
    .ocerisationStep$;
  readonly TIIME_ICON_PREFIX = TIIME_ICON_PREFIX;

  readonly loaderOptions: AnimationOptions = {
    path: '/assets/anims/bank-transfer/before-scan.json',
  };

  constructor(
    private readonly dialog: MatDialog,
    private readonly snackbar: TiimeSnackbarService,
  ) {}

  addBeneficiary(name: string): void {
    this.dialog
      .open<
        AddBeneficiaryDialogComponent,
        AddBeneficiaryDialogData,
        Beneficiary | null
      >(AddBeneficiaryDialogComponent, {
        data: {
          name: name || this.nameFromDocument,
          iban: this.ibanFromDocument === null ? '' : this.ibanFromDocument,
        },
        width: '600px',
      })
      .beforeClosed()
      .pipe(
        filterNotNullOrUndefined(),
        tap(beneficiary => {
          this.snackbar.open(
            'Votre bénéficiaire a bien été ajouté',
            SnackbarConfig.success,
          );
          this.beneficiaryCreated.emit(beneficiary);
          this.form.beneficiary.setValue(beneficiary);
        }),
      )
      .subscribe();
  }
}
