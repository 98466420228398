import {
  HttpClient,
  HttpHeaders,
  HttpParams,
  HttpProgressEvent,
  HttpResponse,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of, throwError } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import {
  GetOptions,
  PaginationData,
  PaginationRange,
  RequiredGetOptions,
} from 'tiime-components';

import { TemporaryEncoder } from '@core/temporary-encoder';
import { ApiAlertError } from '@decorators/api-alert-error';
import { StandardDocumentCategoryIdentifier } from '@enums';
import { HttpHelper } from '@helpers';
import {
  Document,
  DocumentApiContract,
  DocumentLike,
  DocumentPaymentStatusEnum,
  DocumentsMetadata,
  DocumentsWithMetadata,
  Tag,
} from '@models';
import {
  DocumentLockedByAccountant,
  DocumentLockedByAccountantApiContract,
  DocumentUploadOptions,
} from '@models/documents';
import { Label } from '@models/labels';
import { LinkedEntityBankTransaction } from '@models/linked-entities';
import { MatchingBodyInterface } from '@models/matching';

import { HttpFileService } from './http-file.service';
import { TitleService } from './title.service';

@Injectable({
  providedIn: 'root',
})
export class DocumentsService {
  private readonly resource = 'api/v1/companies/{companyId}/documents';
  private readonly headers = new HttpHeaders({
    Accept: [
      'application/vnd.tiime.documents.v3+json',
      'application/vnd.tiime.docs.imputation+json',
    ],
  });

  constructor(
    private readonly http: HttpClient,
    private readonly httpFileService: HttpFileService,
    private readonly titleService: TitleService,
  ) {
    this.titleService.setContentTitle('Documents');
  }

  @ApiAlertError()
  update(payload: Partial<Document>, documentId: number): Observable<Document> {
    const url = `${this.resource}/${documentId}`;
    return this.http
      .patch(url, Document.toJson(payload), { headers: this.headers })
      .pipe(
        map((documentJson: DocumentApiContract) =>
          Document.fromJson(documentJson),
        ),
      );
  }

  @ApiAlertError()
  updateDocumentsTags(documentIds: number[], tags: Tag[]): Observable<void> {
    const url = `${this.resource}/tags`;
    const body = {
      document_ids: documentIds,
      tags: tags.map((tag: Tag) => ({
        name: tag.name,
      })),
    };
    return this.http.put<void>(url, body, { headers: this.headers });
  }

  @ApiAlertError()
  getDetail(documentId: number): Observable<Document> {
    const url = `${this.resource}/${documentId}`;

    return this.http
      .get(url, { headers: this.headers })
      .pipe(
        map((document: DocumentApiContract) => Document.fromJson(document)),
      );
  }

  @ApiAlertError()
  downloadPdf(documentId: number): Observable<HttpResponse<Blob>> {
    return this.httpFileService.download(
      `${this.resource}/${documentId}/file`,
      { headers: { 'Content-Type': 'application/pdf' } },
    );
  }

  @ApiAlertError()
  getPdfPreview(documentId: number): Observable<Blob> {
    return this.http.get(`${this.resource}/${documentId}/file`, {
      responseType: 'blob',
    });
  }

  @ApiAlertError()
  getPreview(documentId: number): Observable<Blob> {
    const url = `${this.resource}/${documentId}/preview`;

    return this.http.get(url, { responseType: 'blob' });
  }

  @ApiAlertError()
  getRecents(
    isRequestForDashboard?: boolean,
  ): Observable<PaginationData<Document>> {
    const range = isRequestForDashboard
      ? new PaginationRange(0, 4)
      : new PaginationRange(0, 5);
    const headers = HttpHelper.setRangeHeader(this.headers, range);
    let params = HttpHelper.setSortParam(new HttpParams(), {
      direction: 'desc',
      active: 'created_at',
    });
    if (isRequestForDashboard) {
      params = params.append('source', 'accountant');
    }
    params = params.append('expand', 'file_family,preview_available');
    const options = { params, headers };

    return this.http
      .get(this.resource, { ...options, observe: 'response' })
      .pipe(
        HttpHelper.mapToPaginationData(
          range,
          (documentJson: DocumentApiContract) =>
            Document.fromJson(documentJson),
        ),
      );
  }

  @ApiAlertError()
  getDocuments(
    getOptions: RequiredGetOptions<'range'>,
    categoryId?: number,
  ): Observable<PaginationData<Document>> {
    const headers = HttpHelper.setRangeHeader(this.headers, getOptions.range);
    let params = new HttpParams({
      fromObject: {
        ...(categoryId && { document_categories: `${categoryId}` }),
        ...new GetOptions(getOptions).toHttpGetOptions().params,
      },
    });
    params = HttpHelper.setSearchParam(params, getOptions.search);
    params = HttpHelper.setSortParam(params, getOptions.sort);
    const options = { params, headers };

    return this.http
      .get(this.resource, { ...options, observe: 'response' })
      .pipe(
        HttpHelper.mapToPaginationData(
          getOptions.range,
          (documentJson: DocumentApiContract) =>
            Document.fromJson(documentJson),
        ),
      );
  }

  @ApiAlertError()
  getDocumentsWithoutPagination(
    getOptions: Partial<GetOptions>,
  ): Observable<Document[]> {
    const partialOptions = new GetOptions(getOptions).toHttpGetOptions();
    const options = {
      params: new HttpParams({
        fromObject: partialOptions.params,
        encoder: new TemporaryEncoder(),
      }),
      headers: partialOptions.headers,
    };

    return this.http
      .get<DocumentApiContract[]>(this.resource, { ...options })
      .pipe(map(json => json.map(doc => Document.fromJson(doc))));
  }

  @ApiAlertError()
  getDocumentsWithMetadata(
    getOptions: RequiredGetOptions<'range'>,
    types?: string[],
    hasExistingAdvancedExpenses?: boolean,
  ): Observable<DocumentsWithMetadata> {
    let headers = HttpHelper.setRangeHeader(
      new HttpHeaders(),
      getOptions.range,
    );
    headers = headers.set('Accept', [
      'application/vnd.tiime.documents.v2+json',
      'application/vnd.tiime.docs.query+json',
      'application/vnd.tiime.docs.imputation+json',
    ]);
    let params = new HttpParams({
      fromObject: {
        ...(types && { types: types.join('|') }),
        ...(hasExistingAdvancedExpenses && {
          has_existing_advanced_expenses: hasExistingAdvancedExpenses,
        }),
        ...{ expand: 'file_family,preview_available' },
        ...new GetOptions({ ...getOptions }).toHttpGetOptions().params,
      },
    });
    params = HttpHelper.setSearchParam(params, getOptions.search);
    params = HttpHelper.setSortParam(params, getOptions.sort);
    const options = { params, headers };

    return this.http
      .get(this.resource, { ...options, observe: 'response' })
      .pipe(
        HttpHelper.mapToPaginationDataWithMetadata(
          'documents',
          getOptions.range,
          Document.fromJson,
          Document.metadataFromJson,
          (data, metadata: DocumentsMetadata) =>
            new DocumentsWithMetadata(data, metadata),
        ),
      );
  }

  @ApiAlertError()
  upload(
    file: File,
    categoryId: number,
    documentId?: number,
    documentUploadOptions?: DocumentUploadOptions,
  ): Observable<HttpProgressEvent | Document> {
    const urlEnd =
      documentId !== undefined
        ? `/documents/${documentId}/file`
        : `/document_categories/${categoryId}/documents`;
    const url = `api/v1/companies/{companyId}${urlEnd}`;
    return this.httpFileService.upload(
      url,
      file,
      Document.fromJson,
      'post',
      {
        headers: this.headers,
      },
      documentUploadOptions,
    );
  }

  @ApiAlertError()
  create(document: DocumentLike): Observable<Document> {
    if (document.category?.id === undefined) {
      return throwError('The category must be defined in the document');
    }

    const url = `api/v1/companies/{companyId}/document_categories/${document.category.id}/documents`;

    return this.http
      .post<DocumentApiContract>(url, Document.toJson(document), {
        headers: this.headers,
      })
      .pipe(
        switchMap(apiResponse => {
          if (
            document.category.identifier ===
            StandardDocumentCategoryIdentifier.ADVANCED_EXPENSE
          ) {
            /* TODO (TA-17828): use an API endpoint (when available) that enables de create advanced expense directly
                instead of creating the doc then updating it */
            return this.update(document, apiResponse.id);
          }
          return of(Document.fromJson(apiResponse));
        }),
      );
  }

  @ApiAlertError()
  download(documentId: number): Observable<unknown> {
    const url = `${this.resource}/${documentId}/file`;
    return this.httpFileService.download(url);
  }

  @ApiAlertError()
  postDocumentsToDownload(documentIds: number[]): Observable<unknown> {
    const url = `${this.resource}/file`;
    const body = {
      document_ids: documentIds,
    };
    return this.http.post(url, body, { headers: this.headers });
  }

  @ApiAlertError()
  delete(documentId: number): Observable<unknown> {
    return this.http.delete(`${this.resource}/${documentId}`);
  }

  @ApiAlertError()
  matchDocumentTransactions(
    documentId: number,
    transactionIds: number[],
    imputationIds: number[],
    labelId?: number,
    tagName?: string,
  ): Observable<LinkedEntityBankTransaction[]> {
    const url = `${this.resource}/${documentId}/matchings`;
    const body: MatchingBodyInterface = {
      bank_transactions: transactionIds.map(id => ({ id })),
      imputations: imputationIds.map(id => ({ id })),
    };

    if (labelId) {
      body.label = { id: labelId };
    } else if (tagName) {
      body.tag = { name: tagName };
    }

    return this.http.put<LinkedEntityBankTransaction[]>(url, body);
  }

  @ApiAlertError()
  bulkUpdateCategory(
    documentIds: number[],
    categoryId: number,
  ): Observable<unknown> {
    const url = `${this.resource}/category`;
    return this.http.put(
      url,
      { document_ids: documentIds, category_id: categoryId },
      { headers: this.headers },
    );
  }

  /**
   *
   * @param documentIds document ids to delete
   * @returns number of deleted documents
   */
  @ApiAlertError()
  bulkDeleteDocuments(documentIds: number[]): Observable<number> {
    return this.http
      .delete<DocumentApiContract[]>(`${this.resource}`, {
        body: { document_ids: documentIds },
      })
      .pipe(map(documents => documents.length));
  }

  /**
   *
   * @param documentIds document ids to update
   * @returns updated documents
   */
  @ApiAlertError()
  bulkUpdatePaymentStatus(
    documentIds: number[],
    status: DocumentPaymentStatusEnum,
  ): Observable<Document[]> {
    return this.http
      .put<DocumentApiContract[]>(`${this.resource}/payment_status`, {
        document_ids: documentIds,
        payment_status: status,
      })
      .pipe(
        map(documents =>
          documents.map(document => Document.fromJson(document)),
        ),
      );
  }

  @ApiAlertError()
  getDocumentsLockedByAccountant(
    documentIds: number[],
  ): Observable<DocumentLockedByAccountant[]> {
    return this.http
      .post<DocumentLockedByAccountantApiContract[]>(
        `${this.resource}/locked_by_accountant`,
        { document_ids: documentIds },
      )
      .pipe(
        map(response =>
          response.map(r => DocumentLockedByAccountant.fromJson(r)),
        ),
      );
  }

  getLockedAndUnlockedDocumentIds(documentIds: number[]): Observable<{
    documentLockedIds: number[];
    documentsUnlockedIds: number[];
  }> {
    return this.getDocumentsLockedByAccountant(documentIds).pipe(
      map(documents => {
        const documentLockedIds: number[] = [];
        const documentsUnlockedIds: number[] = [];
        documents.forEach(document => {
          if (document.lockedByAccountant) {
            documentLockedIds.push(document.id);
          } else {
            documentsUnlockedIds.push(document.id);
          }
        });
        return { documentLockedIds, documentsUnlockedIds };
      }),
    );
  }

  /**
   determine the type of the entity to send and use the service's method accordingly
   @returns an observable with the @param labelOrTag
   */
  tagOrLabelizeMultipleDocuments(
    labelOrTag: Label | Tag,
    documentIds: number[],
  ): Observable<{
    labelOrTag: Label | Tag;
    documentUpdatedIds: number[];
  }> {
    const responseIsALabel = labelOrTag instanceof Label;
    const observable = responseIsALabel
      ? this.putDocumentsLabel(documentIds, labelOrTag.id)
      : this.updateDocumentsTags(documentIds, [labelOrTag]);
    return observable.pipe(
      switchMap(() =>
        // if we apply a tag instead of a label, we need to remove the initial label
        !responseIsALabel
          ? this.putDocumentsLabel(documentIds, null)
          : of(true),
      ),
      map(() => ({ labelOrTag, documentUpdatedIds: documentIds })),
    );
  }

  @ApiAlertError()
  putDocumentsLabel(
    documentIds: number[],
    labelId: number,
  ): Observable<unknown> {
    return this.http.put(`${this.resource}/label`, {
      document_ids: documentIds,
      label_id: labelId,
    });
  }

  @ApiAlertError()
  sendToMindee(documentId: number): Observable<Document> {
    return this.http
      .patch(`${this.resource}/${documentId}/send_to_mindee`, {})
      .pipe(map((json: DocumentApiContract) => Document.fromJson(json)));
  }
}
