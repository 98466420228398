import { Injectable } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class TitleService {
  private contentTitle$ = new BehaviorSubject<string>('');

  constructor(private readonly appTitleService: Title) {}

  getContentTitle(): Observable<string> {
    return this.contentTitle$.asObservable();
  }

  setContentTitle(title: string): void {
    this.contentTitle$.next(title);
  }

  setAppTitle(title: string): void {
    this.appTitleService.setTitle(title);
  }

  resetAppTitle(): void {
    this.appTitleService.setTitle('Tiime');
  }

  setEmptyTitle(): void {
    this.setContentTitle('');
  }
}
