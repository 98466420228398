import { InvoicingTemplate } from '@enums';
import { Country } from '@models';

export abstract class AbstractInvoicingDocumentConfig {
  protected constructor(
    public deliveryAddressEnabled: boolean,
    public sirenOrSiretEnabled: boolean,
    public intracomVatNumberEnabled: boolean,
    public freeFieldEnabled: boolean,
    public country: Country,
    public discountEnabled: boolean,
    public template: InvoicingTemplate,
    public titleEnabled: boolean,
  ) {}
}
