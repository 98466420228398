import { TiimeSelectOption } from 'tiime-components';

import { CountryExonerationArea } from '@enums';

export interface CountryApiContract {
  id: number;
  name: string;
  code: string;
  invoicing_vat_exoneration_area: CountryExonerationArea;
}

export class Country {
  constructor(
    public id: number,
    public name: string,
    public code: string,
    public invoicingVatExonerationArea: CountryExonerationArea,
  ) {}

  public static fromJson(json: CountryApiContract): Country {
    return new Country(
      json.id,
      json.name,
      json.code,
      json.invoicing_vat_exoneration_area,
    );
  }

  public static toJson(country: Country): CountryApiContract {
    return {
      id: country.id,
      name: country.name,
      code: country.code,
      invoicing_vat_exoneration_area: country.invoicingVatExonerationArea,
    };
  }

  public static mapToSelectOptions(
    countries: Country[],
    allowNoCountrySelect: true,
  ): TiimeSelectOption<Country | null>[];
  public static mapToSelectOptions(
    countries: Country[],
    allowNoCountrySelect: false,
  ): TiimeSelectOption<Country>[];
  // consider true as the default value
  public static mapToSelectOptions(
    countries: Country[],
  ): TiimeSelectOption<Country | null>[];
  public static mapToSelectOptions(
    countries: Country[],
    allowNoCountrySelect?: boolean,
  ): TiimeSelectOption<Country | null>[] {
    const selectOptions = countries.map(country => ({
      value: country,
      label: country.name,
      optionLabel: undefined,
    }));

    if (allowNoCountrySelect || allowNoCountrySelect === undefined) {
      selectOptions.unshift({
        value: null,
        label: 'Pays',
        optionLabel: 'Aucun pays',
      });
    }

    return selectOptions;
  }
}
