import {
  ActionEnMasseCommenceeProperties,
  MultiplesElementsSelectionnesProperties,
} from '@core/amplitude/wrapper';
import { ActionsType } from '@enums/mass-actions';

export const ACTIONS_TYPE_TO_AMPLITUDE = new Map<
  ActionsType,
  ActionEnMasseCommenceeProperties['type_action']
>([
  [ActionsType.Label, 'labeliser'],
  [ActionsType.Link, 'relier'],
  [ActionsType.Delete, 'supprimer'],
  [ActionsType.Move, 'deplacer'],
  [ActionsType.Download, 'telecharger'],
  [ActionsType.Tag, 'taguer'],
  [ActionsType.PaymentPaid, 'paye'],
  [ActionsType.PaymentPending, 'non_paye'],
]);

export const SELECTION_MODE_TO_AMPLITUDE = new Map<
  string,
  MultiplesElementsSelectionnesProperties['mode_selection']
>([
  ['Tous', 'tous'],
  ['Aucun', 'aucun'],
  ['Payée', 'paye'],
  ['Non payée', 'non-paye'],
]);
