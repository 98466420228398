import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  HostListener,
  inject,
  Inject,
  OnDestroy,
  OnInit,
  Optional,
  ViewChild,
} from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { TrackingService } from '@manakincubber/tiime-tracking';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Actions, ofType } from '@ngrx/effects';
import { Action, select, Store } from '@ngrx/store';
import { cloneDeep } from 'lodash-es';
import { merge } from 'rxjs';
import {
  filter,
  map,
  switchMap,
  take,
  tap,
  withLatestFrom,
} from 'rxjs/operators';
import {
  RequiredGetOptions,
  SnackbarConfig,
  TiimeSnackbarService,
} from 'tiime-components';

import { DocumentsService, TagsService } from '@core';
import { GedDocumentAccessed } from '@core/amplitude';
import { filterNotNullOrUndefined } from '@core/helpers';
import { StandardDocumentCategoryIdentifier } from '@enums';
import { Category, Document, DocumentLike, DocumentTypeEnum } from '@models';
import {
  LinkedEntityPredicates,
  LinkedEntityType,
} from '@models/linked-entities';
import { DocumentPaymentData } from '@models/wallet';
import {
  ADD_CATEGORY_SUCCESS,
  addCategory,
  categoriesSelector,
  loadCategories,
} from '@store/categories';
import {
  CREATE_DOCUMENT_SUCCESS,
  createDocument,
  DELETE_DOCUMENT_SUCCESS,
  deleteDocument,
  LOAD_DOCUMENT_SUCCESS,
  loadDocumentDetail,
  UPDATE_DOCUMENT_CATEGORY_FAIL,
  UPDATE_DOCUMENT_CATEGORY_SUCCESS,
  UPDATE_DOCUMENT_SUCCESS,
  updateDocument,
  updateDocumentCategory,
} from '@store/documents';

import { ConfirmationDialogService } from '../../../../../shared/components/confirmation-dialog/confirmation-dialog.service';
import { UnmatchLinkedEntityEvent } from '../linked-entities/linked-entities.component';
import { DocumentPreviewDialogService } from './document-preview-dialog.service';

export interface DocumentPreviewDialogData {
  document: Document;
  disableCategorySelect?: boolean;
  disableMetadata?: boolean;
  disableLabel?: boolean;
  params?: RequiredGetOptions<'range'>;
}

export enum DocumentPreviewResponseAction {
  PayDocument = 'PayDocument',
  DocumentDeleted = 'DocumentDeleted',
}

export interface DocumentPreviewResponseType {
  action?: DocumentPreviewResponseAction;
  document?: Document;
  hasDocumentChanged?: boolean;
  toMyself?: boolean;
}

@UntilDestroy()
@Component({
  templateUrl: './document-preview-dialog.component.html',
  styleUrls: ['./document-preview-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DocumentPreviewDialogComponent implements OnInit, OnDestroy {
  @ViewChild('documentContainer') documentContainer: ElementRef<HTMLElement>;

  private readonly trackingService = inject(TrackingService);

  hasDocumentChanged = false;
  switchableCategories: Category[];

  readonly tagSuggestions$ = this.tagsService.getTagsAndTagSuggestions();
  readonly categories$ = this.store.pipe(select(categoriesSelector));
  readonly document$ = this.documentPreviewDialogService.document$;

  @HostListener('document:mousedown', ['$event'])
  private onClick(event: MouseEvent): void {
    if (!this.dialogRef) {
      return;
    }
    const target = event.target as HTMLElement;
    if (target === this.documentContainer?.nativeElement) {
      this.close();
    }
  }

  constructor(
    private readonly documentsService: DocumentsService,
    private readonly dialogRef: MatDialogRef<
      DocumentPreviewDialogComponent,
      DocumentPreviewResponseType
    >,
    private readonly store: Store,
    private readonly actions$: Actions,
    private readonly confirmationDialogService: ConfirmationDialogService,
    private readonly tagsService: TagsService,
    private readonly snackbarService: TiimeSnackbarService,
    private readonly documentPreviewDialogService: DocumentPreviewDialogService,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: DocumentPreviewDialogData,
  ) {}

  ngOnInit(): void {
    this.handleBackdropClickAndEscape();
    this.handleDocumentLoaded();
    this.handleDocumentChanges();
    this.initCategories();

    if (this.data.document.id !== undefined) {
      this.initDocument();
    } else {
      this.documentPreviewDialogService.setDocument(this.data.document);
    }
  }

  previewImported(): void {
    this.hasDocumentChanged = true;
  }

  downloadDocument(): void {
    this.documentsService.download(this.data.document.id).subscribe();
  }

  openDeleteDialog(document: Document): void {
    this.confirmationDialogService
      .requireConfirmation({
        theme: 'warn',
        title: `Êtes-vous sûr de vouloir supprimer "${document.name}" ?`,
        description: '',
        confirmLabel: 'Supprimer',
        cancelLabel: 'Annuler',
      })
      .pipe(tap(() => this.deleteDocument(document)))
      .subscribe();
  }

  updateCategory(category: Category): void {
    this.document$
      .pipe(
        take(1),
        tap(document => {
          this.store.dispatch(
            updateDocumentCategory({
              payload: { category },
              document,
            }),
          );
          this.initSwitchableCategories(category);
        }),
      )
      .subscribe();

    this.actions$
      .pipe(
        take(1),
        ofType(UPDATE_DOCUMENT_CATEGORY_FAIL),
        withLatestFrom(this.document$),
        tap(([, document]) =>
          this.documentPreviewDialogService.setDocument(cloneDeep(document)),
        ),
      )
      .subscribe();
  }

  updateDocument(partialDocument: Partial<DocumentLike>): void {
    this.document$
      .pipe(
        take(1),
        tap((document: Document) => {
          if (document.id !== undefined) {
            this.store.dispatch(
              updateDocument({
                payload: partialDocument,
                documentId: document.id,
              }),
            );
          } else {
            partialDocument.category = document.category;
            partialDocument.owner = document.owner;
            this.store.dispatch(createDocument({ payload: partialDocument }));
          }
        }),
      )
      .subscribe();
  }

  onPayDocument(event: DocumentPaymentData): void {
    this.close({
      action: DocumentPreviewResponseAction.PayDocument,
      ...event,
    });
  }

  close(response: DocumentPreviewResponseType = {}): void {
    this.document$
      .pipe(
        take(1),
        tap(document =>
          this.dialogRef.close({
            document,
            hasDocumentChanged: this.hasDocumentChanged,
            ...response,
          }),
        ),
      )
      .subscribe();
  }

  unmatchLinkedEntity({
    linkedEntityType,
    id,
  }: UnmatchLinkedEntityEvent): void {
    this.document$
      .pipe(
        take(1),
        switchMap((document: Document) => {
          return this.documentsService.matchDocumentTransactions(
            document.id,
            document.linkedEntities
              .filter(LinkedEntityPredicates.isBankTransaction)
              .filter(({ value }) =>
                linkedEntityType !== LinkedEntityType.BANK_TRANSACTION
                  ? true
                  : value.id !== id,
              )
              .map(({ value }) => value.id),
            document.linkedEntities
              .filter(LinkedEntityPredicates.isImputation)
              .filter(({ value }) =>
                linkedEntityType !== LinkedEntityType.IMPUTATION
                  ? true
                  : value.id !== id,
              )
              .map(({ value }) => value.id),
          );
        }),
        tap(() =>
          this.refreshDocumentAndShowSuccessMessage(
            'La transaction a bien été déliée du document',
          ),
        ),
      )
      .subscribe();
  }

  linkBankTransaction({
    linkedBankTransactionIds,
    linkedImputationIds,
    labelId,
    tagName,
    wasMatchFromLabelSelectDialog,
  }: {
    linkedBankTransactionIds: number[];
    linkedImputationIds: number[];
    labelId?: number;
    tagName?: string;
    wasMatchFromLabelSelectDialog?: boolean;
  }): void {
    this.document$
      .pipe(
        take(1),
        switchMap(({ id }: Document) =>
          this.documentsService.matchDocumentTransactions(
            id,
            linkedBankTransactionIds,
            linkedImputationIds,
            labelId,
            tagName,
          ),
        ),
        tap(() => {
          let message: string;

          if (wasMatchFromLabelSelectDialog) {
            message = 'Le match et le label ont bien été pris en compte';
          } else {
            message =
              linkedBankTransactionIds.length > 1
                ? 'Les transactions ont bien été liées au document'
                : 'La transaction a bien été liée au document';
          }

          this.refreshDocumentAndShowSuccessMessage(message);
        }),
      )
      .subscribe();
  }

  createCategory(category: Category): void {
    this.store.dispatch(addCategory({ name: category.name }));
    this.actions$
      .pipe(
        ofType(ADD_CATEGORY_SUCCESS),
        take(1),
        tap((action: { category: Category }) =>
          this.updateCategory(action.category),
        ),
        tap(() => (this.hasDocumentChanged = true)),
      )
      .subscribe();
  }

  private handleDocumentChanges(): void {
    this.actions$
      .pipe(
        ofType(
          UPDATE_DOCUMENT_SUCCESS,
          UPDATE_DOCUMENT_CATEGORY_SUCCESS,
          DELETE_DOCUMENT_SUCCESS,
          CREATE_DOCUMENT_SUCCESS,
        ),
        tap((action: Action & { document: Document }) => {
          this.documentPreviewDialogService.setDocument(action.document);
          this.showSnackbarAfterDocumentUpdated(action);
        }),
        untilDestroyed(this),
      )
      .subscribe();
  }

  private updateLinkedDocuments(document: Document): void {
    if (
      document?.type === DocumentTypeEnum.AdvancedExpense &&
      document.linkedEntities.length
    ) {
      document.linkedEntities.forEach(entity => {
        this.store.dispatch(
          loadDocumentDetail({ documentId: entity.value.id }),
        );
      });
    }
  }

  private initDocument(): void {
    this.documentPreviewDialogService.setLoadingState(true);

    this.store.dispatch(
      loadDocumentDetail({ documentId: this.data.document.id }),
    );
  }

  private handleDocumentLoaded(): void {
    this.actions$
      .pipe(
        ofType(LOAD_DOCUMENT_SUCCESS),
        map(({ document }: { document: Document }) => document),
        filter(document => {
          // We have to do this check because we don't want to set the current document
          // if we're reloading the linkedEntities.
          const currentDocument = this.documentPreviewDialogService.document;
          return !currentDocument || currentDocument.type === document?.type;
        }),
        tap(document => {
          if (document) {
            this.trackingService.dispatch(
              new GedDocumentAccessed(document.category.identifier),
            );
          }

          this.documentPreviewDialogService.setDocument(document);
          this.initSwitchableCategories(document.category);
        }),
        untilDestroyed(this),
      )
      .subscribe();
  }

  private refreshDocumentAndShowSuccessMessage(successMessage: string): void {
    this.store.dispatch(
      loadDocumentDetail({ documentId: this.data.document.id }),
    );
    this.hasDocumentChanged = true;
    this.snackbarService.open(successMessage, SnackbarConfig.success);
  }

  private initCategories(): void {
    this.categories$
      .pipe(
        take(1),
        filter(categories => !categories || categories?.length === 0),
        tap(() => this.store.dispatch(loadCategories())),
      )
      .subscribe();
  }

  private deleteDocument(document: Document): void {
    this.store.dispatch(
      deleteDocument({ document, options: this.data.params }),
    );
  }

  private handleBackdropClickAndEscape(): void {
    this.dialogRef.disableClose = true;

    merge(
      this.dialogRef.backdropClick(),
      this.dialogRef
        .keydownEvents()
        .pipe(filter(event => event.key === 'Escape')),
    )
      .pipe(
        tap(() => this.close()),
        untilDestroyed(this),
      )
      .subscribe();
  }

  private showSnackbarAfterDocumentUpdated(
    action: Action & { document: Document },
  ): void {
    this.hasDocumentChanged = true;

    let message = '';

    switch (action.type) {
      case UPDATE_DOCUMENT_SUCCESS:
        message = 'Le document a bien été modifié';
        this.updateLinkedDocuments(action.document);
        break;
      case UPDATE_DOCUMENT_CATEGORY_SUCCESS:
        message = "Le document a bien été changé d'espace";
        break;
      case DELETE_DOCUMENT_SUCCESS:
        message = 'Le document a bien été supprimé';
        this.close({ action: DocumentPreviewResponseAction.DocumentDeleted });
        break;
      case CREATE_DOCUMENT_SUCCESS:
        message = 'Le document a bien été créé';
        break;
      default:
        break;
    }

    if (message) {
      this.snackbarService.open(message, SnackbarConfig.success);
    }
  }

  private initSwitchableCategories(currentCategory: Category): void {
    const forbiddenCategories = [
      StandardDocumentCategoryIdentifier.EXPENSE_REPORT,
    ];
    this.categories$
      .pipe(
        filterNotNullOrUndefined(),
        untilDestroyed(this),
        map(categories =>
          categories.filter(
            category =>
              !forbiddenCategories.includes(category.identifier) ||
              forbiddenCategories.includes(currentCategory.identifier),
          ),
        ),
        tap(categories => (this.switchableCategories = categories)),
      )
      .subscribe();
  }

  ngOnDestroy(): void {
    this.documentPreviewDialogService.setDocument();
  }
}
