import { DialogRef } from '@angular/cdk/dialog';
import { ESCAPE, hasModifierKey } from '@angular/cdk/keycodes';
import { ComponentRef } from '@angular/core';
import { merge, Observable, Subject } from 'rxjs';
import { filter, take, tap } from 'rxjs/operators';

import { SidePanelConfig } from './side-panel-config';
import { SidePanelContainerComponent } from './side-panel-container/side-panel-container.component';

export class SidePanelRef<TComponent = unknown, TResult = unknown> {
  containerInstance: SidePanelContainerComponent;

  disableClose: boolean | undefined;

  private result: TResult | undefined;

  // Handle to the timeout that's running as a fallback in case the exit animation doesn't fire.
  private closeFallbackTimeout: ReturnType<typeof setTimeout>;

  private readonly afterOpened = new Subject<void>();
  private readonly beforeClosed = new Subject<TResult | undefined>();

  get instance(): TComponent {
    return this.ref.componentInstance;
  }

  get componentRef(): ComponentRef<TComponent> {
    return this.ref.componentRef;
  }

  constructor(
    private ref: DialogRef<TResult, TComponent>,
    config: SidePanelConfig,
    containerInstance: SidePanelContainerComponent,
  ) {
    this.containerInstance = containerInstance;
    this.disableClose = config.disableClose;

    containerInstance.animationStateChanged
      .pipe(
        filter(
          event => event.phaseName === 'done' && event.toState === 'visible',
        ),
        take(1),
        tap(() => {
          this.afterOpened.next();
          this.afterOpened.complete();
        }),
      )
      .subscribe();

    containerInstance.animationStateChanged
      .pipe(
        filter(
          event => event.phaseName === 'done' && event.toState === 'hidden',
        ),
        take(1),
        tap(() => {
          clearTimeout(this.closeFallbackTimeout);
          this.ref.close(this.result);
        }),
      )
      .subscribe();

    ref.overlayRef
      .detachments()
      .pipe(
        tap(() => {
          this.beforeClosed.next(this.result);
          this.beforeClosed.complete();
          this.ref.close(this.result);
        }),
      )
      .subscribe();

    merge(
      this.backdropClick(),
      this.keydownEvents().pipe(filter(event => event.keyCode === ESCAPE)),
    )
      .pipe(
        tap(event => {
          if (
            !this.disableClose &&
            (event.type !== 'keydown' ||
              !hasModifierKey(event as KeyboardEvent))
          ) {
            event.preventDefault();
            this.close();
          }
        }),
      )
      .subscribe();
  }

  close(result?: TResult): void {
    if (!this.containerInstance) {
      return;
    }

    this.containerInstance.animationStateChanged
      .pipe(
        filter(event => event.phaseName === 'start'),
        take(1),
        tap(event => {
          // The logic that disposes of the overlay depends on the exit animation completing, however
          // it isn't guaranteed if the parent view is destroyed while it's running. Add a fallback
          // timeout which will clean everything up if the animation hasn't fired within the specified
          // amount of time plus 100ms. We don't need to run this outside the NgZone, because for the
          // vast majority of cases the timeout will have been cleared before it has fired.
          this.closeFallbackTimeout = setTimeout(() => {
            this.ref.close(this.result);
          }, event.totalTime + 100);

          this.beforeClosed.next(result);
          this.beforeClosed.complete();
          this.ref.overlayRef.detachBackdrop();
        }),
      )
      .subscribe();

    this.result = result;
    this.containerInstance.exit();
    this.containerInstance = null;
  }

  afterOpened$(): Observable<void> {
    return this.afterOpened;
  }

  beforeClosed$(): Observable<TResult | undefined> {
    return this.beforeClosed;
  }

  afterClosed$(): Observable<TResult | undefined> {
    return this.ref.closed;
  }

  backdropClick(): Observable<MouseEvent> {
    return this.ref.backdropClick;
  }

  keydownEvents(): Observable<KeyboardEvent> {
    return this.ref.keydownEvents;
  }
}
