import { ChangeDetectionStrategy, Component, forwardRef } from '@angular/core';

import { ButtonBase } from './button.base';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'button[app-button]',
  templateUrl: 'button-like.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    { provide: ButtonBase, useExisting: forwardRef(() => ButtonLikeComponent) },
  ],
})
export class ButtonLikeComponent extends ButtonBase {}
