import { AbstractControl, FormArray, FormControl } from '@angular/forms';
import { SortDirection } from '@angular/material/sort';
import { FormUtils } from '@manakincubber/tiime-utils';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { filter, map, tap } from 'rxjs/operators';
import { OrFilter, ResetFilter } from 'tiime-components';

import { LabelFilterValue } from '@enums/filters';
import { Label } from '@models/labels';

import { TableColumnSortAndFilter } from '../table-column-sort-and-filter';
import { TableColumnSortAndFilterForm } from '../table-column-sort-and-filter.form';

@UntilDestroy()
export class TableLabelFilterForm extends TableColumnSortAndFilterForm {
  get all(): FormControl<boolean> {
    return this.get('all') as FormControl;
  }

  get filters(): FormArray<AbstractControl<boolean>> {
    return this.get('filters') as FormArray;
  }

  get without(): FormControl {
    return this.get('without') as FormControl;
  }

  constructor(
    filterKey: string,
    sortKey?: string,
    sortDirection?: SortDirection,
    public labels: Label[] = [],
  ) {
    super(
      {
        without: new FormControl(null),
        all: new FormControl(null),
        filters: new FormArray([]),
      },
      filterKey,
      sortKey,
      sortDirection,
    );
    this.toggleAllFilterChange();
    this.initAllMethodsCheckbox();
  }

  fromParam(labelFilterValue: string): void {
    const labelArray = labelFilterValue.split('|');

    // empty previous controls
    this.filters.clear(FormUtils.shouldNotEmitEvent);

    this.labels.forEach(label => {
      this.filters.push(new FormControl(labelArray.includes(`${label.id}`)));
    });

    this.without.setValue(labelArray.includes(LabelFilterValue.Without));
    this.all.setValue(labelArray.length >= this.labels.length, {
      emitEvent: false, // Otherwise observable on 'select all' checkbox will disable all filters
    });
  }

  resetFilters(): void {
    this.patchValue({
      all: false,
      without: false,
    });
  }

  toOutput(): TableColumnSortAndFilter {
    return {
      sort: this.sortDirection.dirty
        ? {
            active: this.sortKey,
            direction: this.sortDirection.value as SortDirection,
          }
        : null,
      filter: this.toFilter(),
    };
  }

  toFilter(): OrFilter<string | number> | ResetFilter {
    const selectedLabels: (string | number)[] = this.filters.controls
      .map((control, i) => (control.value ? this.labels[i].id : null))
      .filter(v => v !== null);

    if (this.without.value) {
      selectedLabels.push(LabelFilterValue.Without);
    }

    return selectedLabels.length
      ? new OrFilter(this.filterKey, selectedLabels)
      : ResetFilter.forKey(this.filterKey);
  }

  private initAllMethodsCheckbox(): void {
    this.all.valueChanges
      .pipe(
        tap(value => {
          Object.keys(this.filters.controls).forEach(key => {
            this.filters
              .get(key)
              .patchValue(value, FormUtils.shouldNotEmitEvent);
          });
        }),
        untilDestroyed(this),
      )
      .subscribe();
  }

  private toggleAllFilterChange(): void {
    this.filters.valueChanges
      .pipe(
        map(values => Object.values(values).every(value => value)),
        filter(shouldCheckAll => this.all.value !== shouldCheckAll),
        tap(shouldCheckAll =>
          this.all.setValue(shouldCheckAll, FormUtils.shouldNotEmitEvent),
        ),
        untilDestroyed(this),
      )
      .subscribe();
  }
}
