import {
  Component,
  Input,
  Output,
  EventEmitter,
  ChangeDetectionStrategy,
} from '@angular/core';

@Component({
  selector: 'app-file-uploader-button',
  templateUrl: './file-uploader-button.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FileUploaderButtonComponent {
  @Input() acceptedTypes: string[] = [];
  @Input() multiple = true;

  @Output()
  readonly fileSelected: EventEmitter<FileList> = new EventEmitter<FileList>();

  onFileSelected(files: FileList): void {
    this.fileSelected.emit(files);
  }
}
