import { PaymentMethod } from '@enums';
import { Invoice } from '@models';

import {
  GoCardlessPaymentMandateStatus,
  LinxoPaymentMandateStatus,
} from '../payment-mandate';

export interface WhitelistIbanContract {
  provider: PaymentMethod;
  iban: string;
}

export interface CreatePaymentRequestContract {
  provider: PaymentMethod;
  invoice_id: number;
  client_id?: number;
  mandate_id?: number;
  charge_date?: string;
}

export interface InvoicePaymentRequest {
  id: number;
  provider: PaymentMethod;
  invoice: { id: Invoice['id'] };
  status: GoCardlessPaymentMandateStatus | LinxoPaymentMandateStatus;
  url: string;
}

export const LINXO_PAYMENT_LINK_CUSTOM_QUERY_PARAMS = `&display_qr_code=false&locale=fr&button_radius=20&font_color=476388&font_secondary_color=88a0bf&elements_color=53da85&secondary_elements_color=a9ecc2&item_border_color=&item_arrow_color=88a0bf`;
