import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, of, throwError } from 'rxjs';
import { catchError, filter, tap } from 'rxjs/operators';

import { ActivatedModules } from '@models';

@Injectable({
  providedIn: 'root',
})
export class ActivatedModulesService {
  private readonly _activatedModules$ = new BehaviorSubject<ActivatedModules>(
    null,
  );

  constructor(private readonly http: HttpClient) {}

  get activatedModules$(): Observable<ActivatedModules> {
    return this._activatedModules$.asObservable();
  }

  getModuleLink(url: string, basegiToken: string): string {
    return `${url}/bridge.php?key=${encodeURIComponent(basegiToken)}`;
  }

  getActivatedModules(): Observable<ActivatedModules> {
    const accessToken = localStorage.getItem('access_token');
    if (!accessToken) {
      return of();
    }
    const url = `api/v1/companies/{companyId}/v1_modules`;

    return this.http.get<ActivatedModules>(url).pipe(
      filter(
        activatedModules => activatedModules && activatedModules.code === 0,
      ),
      tap(activatedModules => this._activatedModules$.next(activatedModules)),
      catchError((error: HttpErrorResponse) => {
        if (error.status === 400) {
          return of(null);
        }
        return throwError(() => error);
      }),
    );
  }
}
