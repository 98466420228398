import { AsyncPipe } from '@angular/common';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import { MatIcon } from '@angular/material/icon';
import {
  IsActiveMatchOptions,
  RouterLink,
  RouterLinkActive,
} from '@angular/router';
import { TiimeButtonModule, TiimeSidenavLayoutModule } from 'tiime-components';

import { HasAccessDirective } from '@core/directives/acl/has-access.directive';
import { ScrollShadowsModule } from '@directives';

import { NavigationMenuBase } from '../navigation-menu-base';

@Component({
  standalone: true,
  selector: 'app-freemium-navigation-menu',
  templateUrl: 'freemium-navigation-menu.component.html',
  styleUrls: ['../navigation-menu-base.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    AsyncPipe,
    HasAccessDirective,
    MatIcon,
    RouterLink,
    RouterLinkActive,
    ScrollShadowsModule,
    TiimeButtonModule,
    TiimeSidenavLayoutModule,
  ],
})
export class FreemiumNavigationMenuComponent extends NavigationMenuBase {
  protected readonly upsellRouterLinkActiveOptions: IsActiveMatchOptions = {
    matrixParams: 'ignored',
    queryParams: 'exact',
    paths: 'exact',
    fragment: 'ignored',
  };
}
