export enum UserPermissionNameEnum {
  INVOICE_USER_FULL = 'invoice.user.full',
  INVOICE_USER_PARTIAL = 'invoice.user.partial',
  ACCOUNTS_USER_FULL = 'accounts.user.full',
  ACCOUNTS_USER_PARTIAL = 'accounts.user.partial',
  BUSINESS_USER_FULL = 'business.user.full',
  BUSINESS_USER_PARTIAL = 'business.user.partial',
  ACCOUNTS_ADMINISTRATION_USER = 'accounts.administration.manage_user',
  ACCOUNTS_ADMINISTRATION_SET_SUPER_ADMIN = 'accounts.administration.set_super_administrator',
}
