import { FormControl, UntypedFormGroup, Validators } from '@angular/forms';

import { PhoneHelper } from '@core/helpers';
import { ClientContact } from '@models';

export class ClientContactForm extends UntypedFormGroup {
  get id(): FormControl<number> {
    return this.get('id') as FormControl;
  }
  get firstname(): FormControl<string> {
    return this.get('firstname') as FormControl;
  }
  get lastname(): FormControl<string> {
    return this.get('lastname') as FormControl;
  }
  get job(): FormControl<string> {
    return this.get('job') as FormControl;
  }
  get email(): FormControl<string> {
    return this.get('email') as FormControl;
  }
  get phone(): FormControl<string> {
    return this.get('phone') as FormControl;
  }
  get useEmail(): FormControl<boolean> {
    return this.get('useEmail') as FormControl;
  }

  get mailErrorMessage(): string {
    return this.email.touched && this.email.hasError('email')
      ? `Le format de ce champ est invalide`
      : '';
  }

  get phoneErrorMessage(): string {
    return this.phone.touched && !this.phone.valid
      ? `Le format est invalide`
      : '';
  }

  constructor() {
    super({
      id: new FormControl<number>(null),
      firstname: new FormControl(''),
      lastname: new FormControl(''),
      job: new FormControl(''),
      email: new FormControl('', Validators.email),
      phone: new FormControl('', [
        Validators.minLength(4),
        PhoneHelper.isValidNumber(),
      ]),
      useEmail: new FormControl<boolean>(null),
    });
  }

  toContact(): ClientContact {
    return new ClientContact(
      this.id.value,
      this.firstname.value,
      this.lastname.value,
      this.job.value,
      this.email.value,
      this.phone.value,
      this.useEmail.value || false,
    );
  }
}
