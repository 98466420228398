import { FormControl, ValidationErrors, ValidatorFn } from '@angular/forms';

export function numberIntervalValidator(
  fromControl: FormControl<number>,
  toControl: FormControl<number>,
): ValidatorFn {
  return (): ValidationErrors | null => {
    const from = +fromControl.value;
    const to = +toControl.value;
    if (from && to && from > to) {
      return { invalidInterval: true };
    }
    return null;
  };
}
