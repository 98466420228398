import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

export interface BankConnectionReinsuranceDialogData {
  withoutRedirectOption: boolean;
}

@Component({
  selector: 'app-bank-connection-reinsurance-dialog',
  templateUrl: './bank-connection-reinsurance-dialog.component.html',
  styleUrls: ['./bank-connection-reinsurance-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BankConnectionReinsuranceDialogComponent {
  readonly withoutRedirectOption: boolean;

  constructor(
    private readonly dialogRef: MatDialogRef<BankConnectionReinsuranceDialogComponent>,
    @Inject(MAT_DIALOG_DATA) readonly data: BankConnectionReinsuranceDialogData,
  ) {
    this.withoutRedirectOption = data?.withoutRedirectOption;
  }

  continue(): void {
    this.dialogRef.close(true);
  }
}
