import { Label, LabelApiContract } from './labels/label';

export interface LabelRuleApiContract {
  id: number;
  created_at: string;
  label: LabelApiContract;
  wording: string;
}

export class LabelRule {
  constructor(
    public id?: number,
    public createdAt?: string,
    public label?: Label,
    public wording?: string,
  ) {}

  static fromJson(json: LabelRuleApiContract): LabelRule {
    return new LabelRule(
      json.id,
      json.created_at,
      json.label ? Label.fromJson(json.label) : null,
      json.wording,
    );
  }

  static toJson(rule: LabelRule): LabelRuleApiContract {
    return {
      id: rule.id,
      created_at: rule.createdAt,
      label: Label.toJson(rule.label),
      wording: rule.wording,
    };
  }
}
