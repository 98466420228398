<tiime-chip
  removeSvgIcon="icon-button-close"
  [canRemove]="canRemove"
  [showRemoveIconOnHoverOnly]="showRemoveIconOnHoverOnly"
  [readonly]="readonly"
  [flat]="flat"
  [basic]="basic"
  [large]="large"
  [disabled]="disabled"
  [class.locked]="label?.disabled && !hideBlockedInformation"
  (remove)="remove.emit()"
>
  <app-label-icon
    *ngIf="label?.id || label?.acronym"
    [label]="label"
    [large]="large"
    [invoiceClient]="invoiceClient"
  ></app-label-icon>
  <span
    tiimeTooltip
    tooltipPosition="bottom"
    hideWithoutEllipsis
    data-cy="label-chip__txt-label-name"
    [tooltipDisabled]="label?.disabled && !hideBlockedInformation"
    [content]="labelName"
    [class.right-padded]="!canRemove"
  >
    <ng-container *ngTemplateOutlet="labelName"></ng-container>
  </span>
  <ng-template #labelName>
    {{ label?.label }}
  </ng-template>
</tiime-chip>

<app-label-archived-icon
  *ngIf="label?.disabled && !hideBlockedInformation"
  class="disabled-by-expert-icon"
  [labelDisabledBy]="label?.disabledBy"
  [disableTooltip]="true"
></app-label-archived-icon>
<div
  *ngIf="label?.disabled && !hideBlockedInformation"
  class="disabled-by-expert-overlay"
  tiimeTooltip
  tooltipPosition="below"
  [class.pointer-even-if-disabled]="pointerEvenIfDisabled"
  [content]="expertBlockedContent"
></div>
<ng-template #expertBlockedContent>
  <div class="tooltip">
    <app-label-archived-text
      [labelDisabledBy]="label?.disabledBy"
    ></app-label-archived-text>
  </div>
</ng-template>
