import { VatTypeCode } from '@enums';

import {
  TotalPerVatType,
  TotalPerVatTypeApiContract,
} from './total-per-vat-type';

export type TotalsPerVatTypeApiContract = {
  [key in VatTypeCode]?: TotalPerVatTypeApiContract;
};

export class TotalsPerVatType {
  [key: string]: TotalPerVatType;

  static fromJson(json: TotalsPerVatTypeApiContract): TotalsPerVatType {
    const totalsPerVatType: TotalsPerVatType = {};
    Object.keys(json).forEach(
      (key: VatTypeCode) =>
        (totalsPerVatType[key] = TotalPerVatType.fromJson(json[key])),
    );
    return totalsPerVatType;
  }

  static toJson(
    totalsPerVatType: TotalsPerVatType,
  ): TotalsPerVatTypeApiContract {
    const totalsPerVatTypeJson: TotalsPerVatTypeApiContract = {};
    Object.keys(totalsPerVatType).forEach(
      (key: VatTypeCode) =>
        (totalsPerVatTypeJson[key] = TotalPerVatType.toJson(
          totalsPerVatType[key],
        )),
    );

    return totalsPerVatTypeJson;
  }
}
