import { Injectable } from '@angular/core';

export interface BannerContent {
  title: string;
  subtitle: string;
}

@Injectable({
  providedIn: 'root',
})
export class BannerContentService {
  getLockedDocumentsBannerText(
    numberOfLockedDocuments: number,
    documentIds: number[],
  ): BannerContent | null {
    if (numberOfLockedDocuments === 0) {
      return null;
    }

    if (documentIds.length === 0) {
      return {
        title:
          'Tous les documents sélectionnés sont verrouillés par votre comptable',
        subtitle: 'Ils ne seront pas supprimés',
      };
    } else if (numberOfLockedDocuments === 1) {
      return {
        title:
          'Parmi les documents sélectionnés, 1 est verrouillé par votre comptable',
        subtitle: 'Il ne sera pas supprimé',
      };
    } else {
      return {
        title: `Parmi les documents sélectionnés, ${numberOfLockedDocuments} sont verrouillés par votre comptable`,
        subtitle: 'Ils ne seront pas supprimés',
      };
    }
  }

  getMatchedDocumentsBannerText(
    numberOfMatchedDocuments: number,
    isDelete: boolean,
  ): BannerContent | null {
    if (numberOfMatchedDocuments === 0) {
      return null;
    }

    return numberOfMatchedDocuments === 1
      ? {
          title: 'Parmi les documents sélectionnés, 1 est matché',
          subtitle: isDelete
            ? 'Il ne sera pas supprimé'
            : 'Il ne sera pas concerné par cette action',
        }
      : {
          title: `Parmi les documents sélectionnés, ${numberOfMatchedDocuments} sont matchés`,
          subtitle: isDelete
            ? 'Ils ne seront pas supprimés'
            : 'Ils ne seront pas concernés par cette action',
        };
  }

  getTravelExpenseReportsBannerText(
    numberOfExpenseReports: number,
  ): BannerContent | null {
    if (numberOfExpenseReports === 0) {
      return null;
    }

    return numberOfExpenseReports === 1
      ? {
          title:
            'Parmi les documents sélectionnés, 1 est une note de frais kilométrique.',
          subtitle:
            "Elle ne sera pas supprimée, sauf si c'est la dernière en date",
        }
      : {
          title: `Parmi les documents sélectionnés, ${numberOfExpenseReports} sont des notes de frais kilométriques`,
          subtitle:
            "Elles ne seront pas supprimées, à l'exception de la dernière en date",
        };
  }

  getExpenseReportsBannerText(
    numberOfExpenseReports: number,
  ): BannerContent | null {
    if (numberOfExpenseReports === 0) {
      return null;
    }

    return numberOfExpenseReports === 1
      ? {
          title: 'Parmi les documents sélectionnés, un est une Note de frais.',
          subtitle: 'Il ne sera pas concerné par cette action',
        }
      : {
          title: `Parmi les documents sélectionnés, ${numberOfExpenseReports} sont des Notes de frais.`,
          subtitle: 'Ils ne seront pas concernés par cette action',
        };
  }

  getScheduledPaymentsBannerText(
    numberOfScheduledPayments: number,
  ): BannerContent | null {
    if (numberOfScheduledPayments === 0) {
      return null;
    }

    return numberOfScheduledPayments === 1
      ? {
          title: 'Parmi les documents sélectionnés, un a un paiement planifié',
          subtitle: 'Il ne sera pas concerné par cette action',
        }
      : {
          title: `Parmi les documents sélectionnés, ${numberOfScheduledPayments} factures ont un paiement planifié`,
          subtitle: 'Elles ne seront pas concernés par cette action',
        };
  }
}
