<ng-container *ngIf="range.count !== 0">
  <mat-icon
    class="rotate-180"
    svgIcon="icon-arrow-right"
    [class.disabled]="!hasPreviousPage()"
    (click)="previousPage()"
  ></mat-icon>
  <tiime-select
    [formControl]="rangeForm"
    [options]="options"
    [customOverlayPosition]="overlayPosition"
  >
    <span class="label">{{ paginatorText }}</span>
  </tiime-select>
  <mat-icon
    svgIcon="icon-arrow-right"
    [class.disabled]="!hasNextPage()"
    (click)="nextPage()"
  ></mat-icon>
</ng-container>
