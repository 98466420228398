import { VatType } from './vat-type';

export class InvoicingVatType extends VatType {
  static fromVatType(vatType: VatType): InvoicingVatType {
    return new InvoicingVatType(
      vatType.code,
      vatType.name,
      vatType.main,
      vatType.area,
      vatType.vatRates,
      vatType.invoiceLegalNotice,
    );
  }
}
