import { FormArray, FormControl, FormGroup } from '@angular/forms';
import { SortDirection } from '@angular/material/sort';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { tap } from 'rxjs/operators';
import { OrFilter, ResetFilter } from 'tiime-components';

import { Client } from '@models';

import { TableColumnSortAndFilterForm } from '../table-column-sort-and-filter.form';

interface ClientGroupModel {
  id: FormControl<number>;
  name: FormControl<string>;
  selected: FormControl<boolean>;
}

@UntilDestroy()
export class TableClientNameFilterForm extends TableColumnSortAndFilterForm {
  get all(): FormControl<boolean> {
    return this.get('all') as FormControl<boolean>;
  }

  get filters(): FormArray<FormGroup<ClientGroupModel>> {
    return this.get('filters') as FormArray<FormGroup<ClientGroupModel>>;
  }

  constructor(
    filterKey: string,
    sortKey: string,
    sortDirection?: SortDirection,
    public clients: Client[] = [],
  ) {
    super(
      {
        all: new FormControl(false),
        filters: new FormArray<FormGroup<ClientGroupModel>>([]),
      },
      filterKey,
      sortKey,
      sortDirection,
    );
    this.initAllCheckbox();
  }

  private initAllCheckbox(): void {
    this.all.valueChanges
      .pipe(
        tap((value: boolean) => {
          this.filters.controls.forEach(clientFormGroup =>
            clientFormGroup.controls.selected.setValue(value, {
              emitEvent: false,
            }),
          );
        }),
        untilDestroyed(this),
      )
      .subscribe();
  }

  resetFilters(): void {
    this.patchValue({
      all: false,
    });
  }

  fromParam(clientFilterValue: string): void {
    const clientParamArray = clientFilterValue?.includes(',')
      ? clientFilterValue?.split(',')
      : clientFilterValue?.split('|');
    const allSelected = clientParamArray.length === this.clients.length;

    const patch: FormGroup<ClientGroupModel>[] = this.clients.map(
      client =>
        new FormGroup<ClientGroupModel>({
          id: new FormControl(client.id),
          name: new FormControl(client.name),
          selected: new FormControl(
            allSelected || clientParamArray.includes(`${client.id}`),
          ),
        }),
    );

    this.setControl('filters', this.setFormArray(patch));
    this.all.setValue(
      allSelected || clientParamArray.length === this.clients.length,
      {
        emitEvent: false,
      },
    );
  }

  private setFormArray(
    formGroups: FormGroup<ClientGroupModel>[],
  ): FormArray<FormGroup<ClientGroupModel>> {
    const formArray = new FormArray(formGroups);
    formArray.valueChanges
      .pipe(
        tap(() => {
          this.all.setValue(
            this.filters.controls.every(
              clientFormGroup =>
                clientFormGroup.controls.selected.value === true,
            ),
            { emitEvent: false },
          );
        }),
        untilDestroyed(this),
      )
      .subscribe();
    return formArray;
  }

  toFilter(): OrFilter<number> | ResetFilter {
    const selectedClientIds = this.filters.controls
      .filter(clientFormGroup => clientFormGroup.controls.selected.value)
      .map(clientFormGroup => clientFormGroup.controls.id.value);

    return selectedClientIds.length
      ? new OrFilter(this.filterKey, selectedClientIds)
      : ResetFilter.forKey(this.filterKey);
  }
}
