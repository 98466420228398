import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { NgUtils } from '@manakincubber/tiime-utils';
import moment from 'moment';

import { Boundary, Month } from '@constants';

export interface MonthPeriodPickerData {
  dates: moment.Moment[];
}

@Component({
  selector: 'app-month-period-picker',
  templateUrl: './month-period-picker.component.html',
  styleUrls: ['./month-period-picker.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MonthPeriodPickerComponent {
  readonly trackById = NgUtils.trackById;

  years: number[];
  months: Month[] = [
    ...moment.monthsShort().map(month => ({
      label: month.toUpperCase(),
      isInRange: false,
    })),
    ...moment.monthsShort().map(month => ({
      label: month.toUpperCase(),
      isInRange: false,
    })),
  ];
  boundaries: Boundary[] = [null, null];

  constructor(
    @Inject(MAT_DIALOG_DATA) readonly data: MonthPeriodPickerData,
    private readonly dialogRef: MatDialogRef<MonthPeriodPickerComponent>,
  ) {
    this.initialize();
  }

  decrementYears(): void {
    this.years = [this.years[0] - 1, this.years[1] - 1];
    this.resetBoundaries();
  }

  incrementYears(): void {
    this.years = [this.years[0] + 1, this.years[1] + 1];
    this.resetBoundaries();
  }

  onClick(monthIndex: number): void {
    //use `this.years[0]` because `monthIndex` is included in [0, 23]
    //so if `monthIndex` > 12 moment will bump the year automatically
    const boundary = moment().year(this.years[0]).month(monthIndex);
    if (this.boundaries[0] === null) {
      //first click: selecting first day of clicked month as left boundary
      this.boundaries = [{ date: boundary.date(1), monthIndex }, null];
    } else if (this.boundaries[1] === null) {
      //second click: selecting last day of clicked month as right boundary
      this.boundaries = [
        this.boundaries[0],
        { date: boundary.date(1), monthIndex },
      ];
      this.boundaries.sort((a, b) => a.date.valueOf() - b.date.valueOf());
      this.computeMonthsInRange();
    } else {
      //third click: reset boundaries
      this.resetBoundaries();
    }
  }

  save(): void {
    this.dialogRef.close(this.boundaries.map(b => b.date));
  }

  /**
   * Determines which months are to be highlighted.
   * Index goes from 0 to 23, as there are 2 years to choose from
   */
  private computeMonthsInRange(): void {
    this.months.forEach((month, index) => {
      if (this.boundaries.every(boundary => !boundary)) {
        month.isInRange = false;
      } else if (
        this.boundaries[0].date.year() === this.years[0] &&
        this.boundaries[1].date.year() === this.years[0]
      ) {
        // both boundaries are on the first year
        month.isInRange =
          index >= this.boundaries[0].date.month() &&
          index <= this.boundaries[1].date.month();
      } else if (
        this.boundaries[0].date.year() === this.years[1] &&
        this.boundaries[1].date.year() === this.years[1]
      ) {
        // both boundaries are on the second year
        month.isInRange =
          index >= this.boundaries[0].date.month() + 12 &&
          index <= this.boundaries[1].date.month() + 12;
      } else {
        // boundaries are on the first and second years
        month.isInRange =
          index >= this.boundaries[0].date.month() &&
          index <= this.boundaries[1].date.month() + 12;
      }
    });
  }

  private resetBoundaries(): void {
    this.boundaries = [null, null];
    this.computeMonthsInRange();
  }

  private initialize(): void {
    if (this.data.dates?.length) {
      this.years = [this.data.dates[1].year() - 1, this.data.dates[1].year()];
      this.data.dates.forEach((date, index) => {
        this.boundaries[index] = {
          date,
          monthIndex:
            date.year() === this.years[0] ? date.month() : date.month() + 12,
        };
      });
      this.computeMonthsInRange();
    } else {
      this.years = [
        moment().subtract(1, 'year').get('year'),
        moment().get('year'),
      ];
    }
  }
}
