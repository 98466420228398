<div
  class="document-type-container"
  data-cy="document-proof-selector__options-container"
>
  <div
    *ngFor="let option of choices; trackBy: trackByIndex"
    class="document-type-container__option"
    attr.data-cy="document-proof-selector__options-container__option-{{
      option.label
    }}"
    (click)="select(option)"
  >
    <div class="flex-row">
      <img
        *ngIf="option.action_type"
        draggable="false"
        alt=""
        width="24px"
        height="24px"
        [src]="option.action_type | map : mapToIconPath"
      />
      {{ option.label }}
    </div>
  </div>
</div>
