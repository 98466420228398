import { ChangeDetectionStrategy, Component } from '@angular/core';

import { StandardDocumentCategoryIdentifier } from '@enums';
import { LinkedEntityExpenseReport } from '@models/linked-entities';

import { AbstractLinkedLineDirective } from '../abstract-linked-line/abstract-linked-line.directive';

@Component({
  selector: 'app-linked-expense-report-line',
  styleUrls: ['./linked-expense-report-line.component.scss'],
  templateUrl: './linked-expense-report-line.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LinkedExpenseReportLineComponent extends AbstractLinkedLineDirective<LinkedEntityExpenseReport> {
  readonly StandardDocumentCategoryIdentifier =
    StandardDocumentCategoryIdentifier;
}
