import { inject, InjectionToken } from '@angular/core';

import { WINDOW } from './window.token';

declare global {
  interface GtmDataLayer {
    push: (value: unknown) => void;
  }

  interface Window {
    dataLayer?: GtmDataLayer;
  }
}

export const GTM_DATA_LAYER = new InjectionToken<GtmDataLayer | undefined>(
  'An abstraction over window.dataLayer object if it exists',
  { factory: (): GtmDataLayer | undefined => inject(WINDOW).dataLayer },
);
