<section class="bank-transfer-stepper">
  <header>
    <div class="bank-transfer-stepper__steps">
      <ng-container
        *ngFor="
          let step of steps;
          let i = index;
          let last = last;
          trackBy: trackByIndex
        "
      >
        <div
          class="bank-transfer-stepper__step"
          attr.data-cy="bank-transfer-stepper__step-{{ i + 1 }}"
          [class.bank-transfer-stepper__step--validated]="
            selectedIndex > i || allValidated
          "
          [class.bank-transfer-stepper__step--active]="selectedIndex === i"
        >
          <mat-icon svgIcon="icon-check"></mat-icon>
          <span class="bank-transfer-stepper__step__label">{{ i + 1 }}</span>
        </div>
        <div
          *ngIf="!last"
          class="bank-transfer-stepper__horizontal-line"
          [class.bank-transfer-stepper__horizontal-line--validated]="
            selectedIndex > i
          "
        ></div>
      </ng-container>
    </div>
  </header>

  <div class="bank-transfer-stepper__content">
    <ng-container
      [ngTemplateOutlet]="selected ? selected.content : null"
    ></ng-container>
  </div>

  <div
    *ngIf="!allValidated || loading"
    class="bank-transfer-stepper__navigation"
  >
    <button
      *ngIf="selectedIndex > 0"
      tiime-button
      accent
      inverted
      cdkStepperPrevious
      data-cy="bank-transfer-stepper__edit-btn"
      [disabled]="loading"
      (click)="allValidated = false"
    >
      Précédent
    </button>

    @if (withCancelButton && selectedIndex === 0) {
      <button
        tiime-button
        accent
        inverted
        cdkStepperPrevious
        (click)="cancel.emit()"
      >
        Annuler
      </button>
    }

    <button
      *ngIf="selectedIndex < steps.length - 1"
      tiime-button
      accent
      data-cy="bank-transfer-stepper__next-btn"
      [disabled]="loading || (isNextDisabled$ | async)"
      (click)="stepperNext.emit()"
    >
      Suivant
    </button>

    <button
      *ngIf="selectedIndex === steps.length - 1"
      tiime-button
      accent
      cdkStepperNext
      data-cy="bank-transfer-stepper__confirm-btn"
      [disabled]="loading"
      (click)="stepperComplete.emit()"
    >
      <ng-container *ngIf="!loading; else spinner">
        {{ isDocumentProofNeeded ? 'Envoyer pour validation' : 'Confirmer' }}
      </ng-container>

      <ng-template #spinner>
        <tiime-progress-spinner-loader
          diameter="20"
          color="white"
          mode="indeterminate"
        ></tiime-progress-spinner-loader>
      </ng-template>
    </button>
  </div>
</section>
