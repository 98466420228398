import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { FormattedDatePipe } from './formatted-date.pipe';
import { FrequencyLabelPipe } from './frequency-label.pipe';

@NgModule({
  declarations: [FrequencyLabelPipe, FormattedDatePipe],
  imports: [CommonModule],
  exports: [FrequencyLabelPipe, FormattedDatePipe],
})
export class BankTransferPipesModule {}
