import { createAction, props } from '@ngrx/store';

import { CardAction } from '@enums';
import { Card, CardDeliveryAddress, WalletAccount } from '@models';

export const LOAD_WALLET_ACCOUNT = '[Wallet Account] Load';
export const UPDATE_WALLET_ACCOUNT = '[Wallet Account] Update';
export const UPDATE_CARD_OPTIONS = '[Wallet Account] Update card options';
export const ORDER_CARD = '[Wallet Account] Order card';
export const UPDATE_CARD_STATE = '[Wallet Account] Update card state';
export const UPDATE_CARD_LIMITS = '[Wallet Account] Update card limits';

export const loadWalletAccount = createAction(LOAD_WALLET_ACCOUNT);

export const updateWalletAccount = createAction(
  UPDATE_WALLET_ACCOUNT,
  props<{ walletAccount: WalletAccount | null }>(),
);

export const orderCard = createAction(
  ORDER_CARD,
  props<{
    pinCode: string;
    cardDeliveryAddress: CardDeliveryAddress;
    userId: number;
  }>(),
);

export const updateCardState = createAction(
  UPDATE_CARD_STATE,
  props<{ id: number; action: CardAction; userId: number }>(),
);

export const updateCardOptions = createAction(
  UPDATE_CARD_OPTIONS,
  props<{ card: Card; userId: number }>(),
);

export const updateCardLimits = createAction(
  UPDATE_CARD_LIMITS,
  props<{ limitPaymentWeek: number; userId: number }>(),
);
