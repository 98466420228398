import {
  ClientTimelineItem,
  ClientTimelineItemApiContract,
} from './client-timeline-item';
import { ClientTimelineItemType } from './types';

export class ClientTimelineCashFlowItem extends ClientTimelineItem<ClientTimelineItemType.cashFlow> {
  public static fromJson(
    json: ClientTimelineItemApiContract,
  ): ClientTimelineCashFlowItem {
    return new ClientTimelineCashFlowItem(
      json.id,
      json.date,
      json.amount,
      json.balance_amount,
      json.matched,
      json.type as ClientTimelineItemType.cashFlow,
      json.direction,
    );
  }
}
