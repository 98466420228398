export enum AppTheme {
  tiime = 'tiime',
  accounts = 'accounts',
  todo = 'todo',
  receipt = 'receipt',
  invoice = 'invoice',
  home = 'home',
  cash_tracking = 'cash-tracking',
  expense = 'expense',
}
