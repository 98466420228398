import moment from 'moment';

import { BankTransferRecurrency } from '@enums';

import { ScaNewBankTransactionAction } from '../sca-action';
import { BaseBeneficiaryApiContract } from './beneficiary';

export interface BasePayoutBodyContract {
  amount: number;
  label: string;
  /** ISO Date truncated at the day */
  start_date: string;
  /** ISO Date truncated at the day */
  end_date: string;
  recurrency: BankTransferRecurrency;
}

export class Payout {
  public scaId?: number;

  constructor(
    public amount: number,
    public beneficiary: BaseBeneficiaryApiContract,
    public startDate: Date,
    public recurrency: BankTransferRecurrency,
    public endDate?: Date,
    public label?: string,
  ) {}

  toScaAction(): ScaNewBankTransactionAction {
    return new ScaNewBankTransactionAction(
      this.beneficiary.name,
      this.beneficiary.iban,
      this.amount,
      this.formatDate(this.startDate),
      this.endDate ? this.formatDate(this.endDate) : null,
      this.recurrency,
      this.label,
    );
  }

  protected formatDate(date: Date): string {
    return moment(date).format('YYYY-MM-DD');
  }
}
