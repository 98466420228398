import { getSupportedInputTypes } from '@angular/cdk/platform';
import { AutofillMonitor } from '@angular/cdk/text-field';
import {
  AfterViewInit,
  Directive,
  ElementRef,
  HostBinding,
  Inject,
  inject,
  InjectionToken,
  Input,
  OnDestroy,
  Optional,
  Self,
} from '@angular/core';
import { tap } from 'rxjs/operators';

import { InputContainerControl } from './input-container-control';

export const TIIME_INPUT_VALUE_ACCESSOR = new InjectionToken<{
  value: unknown;
}>('TIIME_INPUT_VALUE_ACCESSOR');

let nextUniqueId = 0;
const neverEmptyInputTypes = [
  'date',
  'datetime',
  'datetime-local',
  'month',
  'time',
  'week',
].filter(type => getSupportedInputTypes().has(type));

@Directive({
  selector: `input[tiimeInputContainerNativeControl],
             textarea[tiimeInputContainerNativeControl],
             select[tiimeInputContainerNativeControl]`,
  // eslint-disable-next-line @angular-eslint/no-host-metadata-property
  host: { class: 'tiime-native-control' },
  providers: [
    {
      provide: InputContainerControl,
      useExisting: InputContainerNativeControl,
    },
  ],
})
// eslint-disable-next-line @angular-eslint/directive-class-suffix
export class InputContainerNativeControl
  implements InputContainerControl<unknown>, AfterViewInit, OnDestroy
{
  autofilled = false;
  readonly isTextarea: boolean;
  private readonly uid = `tiime-control-${nextUniqueId++}`;
  private readonly inputValueAccessor: { value: string };
  private readonly elementRef =
    inject<
      ElementRef<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>
    >(ElementRef);
  private autofillMonitor = inject(AutofillMonitor);

  @HostBinding('attr.id')
  @Input()
  get id(): string {
    return this._id;
  }
  set id(value: string) {
    this._id = value || this.uid;
  }
  private _id = this.uid;

  @Input()
  get value(): string {
    return this.inputValueAccessor.value;
  }
  set value(value: string) {
    if (value !== this.value) {
      this.inputValueAccessor.value = value;
    }
  }

  @Input()
  get type(): string {
    return this._type;
  }
  set type(value: string) {
    this._type = value || 'text';

    // When using Angular inputs, developers are no longer able to set the properties on the native
    // input element. To ensure that bindings for `type` work, we need to sync the setter
    // with the native property. Textarea elements don't support the type property or attribute.
    if (!this.isTextarea && getSupportedInputTypes().has(this._type)) {
      (this.elementRef.nativeElement as HTMLInputElement).type = this._type;
    }
  }
  private _type = 'text';

  get empty(): boolean {
    return (
      !this.isNeverEmpty() &&
      !this.elementRef.nativeElement.value &&
      !this.isBadInput() &&
      !this.autofilled
    );
  }

  constructor(
    @Optional()
    @Self()
    @Inject(TIIME_INPUT_VALUE_ACCESSOR)
    inputValueAccessor: { value: string } | null,
  ) {
    // If no input value accessor was explicitly specified, use the element as the input value
    // accessor.
    this.inputValueAccessor =
      inputValueAccessor || this.elementRef.nativeElement;

    // Force setter to be called in case id was not specified.
    // eslint-disable-next-line no-self-assign
    this.id = this.id;

    this.isTextarea =
      this.elementRef.nativeElement.nodeName.toLowerCase() === 'textarea';
  }

  ngAfterViewInit(): void {
    this.autofillMonitor
      .monitor(this.elementRef.nativeElement)
      .pipe(
        tap(event => {
          this.autofilled = event.isAutofilled;
        }),
      )
      .subscribe();
  }

  ngOnDestroy(): void {
    this.autofillMonitor.stopMonitoring(this.elementRef.nativeElement);
  }

  private isBadInput(): boolean {
    const validity = (this.elementRef.nativeElement as HTMLInputElement)
      .validity;
    return validity?.badInput;
  }

  private isNeverEmpty(): boolean {
    return neverEmptyInputTypes.indexOf(this._type) > -1;
  }
}
