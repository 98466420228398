<div
  class="bank-transfer-card"
  [class.bank-transfer-card--compressed]="shouldDisplayLastScreenRecap"
>
  <div class="scroll-container">
    <form class="bank-transfer-card__content" [formGroup]="form">
      <app-bank-transfer-stepper
        linear
        withCancelButton
        #stepper
        [loading]="loading$ | async"
        [isDocumentProofNeeded]="isDocumentProofStepNeeded$ | async"
        (stepperNext)="goToNextStep()"
        (stepperComplete)="triggerBankTransfer()"
        (cancel)="closeEmitter.emit()"
      >
        <cdk-step [stepControl]="form.initiation">
          <ng-container [ngTemplateOutlet]="illuAndTitle"></ng-container>

          <app-bank-transfer-initiation
            [form]="form.initiation"
            [bankAccounts]="bankAccounts$ | async"
            [beneficiaries]="beneficiaries$ | async"
            [nameFromDocument]="nameFromDocument$ | async"
            [ibanFromDocument]="ibanFromDocument$ | async"
            [personalWalletBeneficiary]="personalWalletBeneficiary"
            [reason]="documentReason"
            [shouldDisplayLastScreenRecap]="shouldDisplayLastScreenRecap"
            (beneficiaryCreated)="onBeneficiaryCreated()"
          ></app-bank-transfer-initiation>
        </cdk-step>

        <cdk-step [stepControl]="form.parameters">
          <ng-container [ngTemplateOutlet]="illuAndTitle"></ng-container>

          <app-bank-transfer-parameters
            [form]="form.parameters"
          ></app-bank-transfer-parameters>
        </cdk-step>

        <cdk-step
          *ngIf="isDocumentProofStepNeeded$ | async"
          [stepControl]="form.documents"
        >
          <app-bank-transfer-document-proof
            [beneficiary]="
              form.initiation.beneficiary.value | map: mapToBeneficiary
            "
            [amount]="form.initiation.amount.value"
            [form]="form.documents"
            (selectedChoice)="updateSelectedChoice($event)"
          ></app-bank-transfer-document-proof>
        </cdk-step>

        <cdk-step>
          <ng-container
            *ngIf="shouldDisplayLastScreenRecap; else bankTransferRecap"
          >
            <img
              class="bank-transfer-card__illu"
              src="assets/illus/bank-transfer/illu_bank_transfer_validated.svg"
              height="80"
              width="80"
              alt=""
            />
            <h1
              class="bank-transfer-card__title"
              data-cy="bank-transfer__title"
            >
              Mon virement a bien été pris en compte !
            </h1>
            <h2
              class="bank-transfer-card__subtitle"
              data-cy="bank-transfer__10k-confirmation-subtitle"
            >
              Pour les virements d’un montant important, votre justificatif sera
              analysé par notre équipe. Nous vous contacterons en cas de besoin.
            </h2>
            <div
              class="bank-transfer-card__helper"
              data-cy="bank-transfer__10k-confirmation-helper"
            >
              <img
                src="assets/illus/bank-transfer/illu_bank_transfer_helper.svg"
                height="90"
                width="100"
                draggable="false"
                alt=""
              />
              <span>
                L'équipe Tiime se tient à votre disposition au
                <br />
                <a href="tel:0176420389">01 76 42 03 89</a>
                de 9h à 18h du lundi au vendredi ou à l'adresse
                <a href="mailto:comptepro@tiime.fr">comptepro&#64;tiime.fr</a>
              </span>
            </div>
            <button
              class="bank-transfer-card__action"
              tiime-button
              accent
              data-cy="bank-transfer__10k-confirmation-got-it"
              (click)="completeBankTransferSteps()"
            >
              J'ai compris
            </button>
          </ng-container>
          <ng-template #bankTransferRecap>
            <app-bank-transfer-recap
              [formValue]="formRawValue"
              [reason]="documentReason"
              [personalWalletBeneficiary]="personalWalletBeneficiary"
            ></app-bank-transfer-recap>
          </ng-template>
        </cdk-step>
      </app-bank-transfer-stepper>
    </form>
  </div>
</div>

<ng-template #illuAndTitle>
  <img
    class="bank-transfer-card__illu"
    src="assets/illus/bank-transfer/illu_bank_transfer.svg"
    height="80"
    width="80"
    alt=""
  />
  <h1 class="bank-transfer-card__title" data-cy="bank-transfer__title">
    {{ documentReason | map: mapToTitle }}
  </h1>
</ng-template>
