import { BankConnectionStatus } from '../enum/bank-connection-status';
import { BIAccount, BIAccountApiContract } from './bi-account';
import { BIConnector, BIConnectorApiContract } from './bi-connector';

export interface BIConnectionApiContract {
  id: number;
  error: BankConnectionStatus | null;
  connector: BIConnectorApiContract;
  all_accounts: BIAccountApiContract[];
  accounts: BIAccountApiContract[];
  active: boolean;
  state: BankConnectionStatus;
}

export interface PostBIConnectionApiContract {
  id: number;
  error: BankConnectionStatus | null;
  connector: BIConnectorApiContract;
  accounts: Partial<BIAccountApiContract>[];
  active: boolean;
}

export class BIBankConnection {
  constructor(
    public id?: number,
    public status?: BankConnectionStatus,
    public connector?: BIConnector,
    public accounts?: BIAccount[],
    public active?: boolean,
  ) {}

  public static fromJson(json: BIConnectionApiContract): BIBankConnection {
    return new BIBankConnection(
      json.id,
      json.state,
      json.connector ? BIConnector.fromJson(json.connector) : null,
      json.all_accounts
        ? json.all_accounts.map((accountJson: any) =>
            BIAccount.fromJson(accountJson),
          )
        : [],
      json.active,
    );
  }

  public static toChronosConnectionJson(
    biBankConnection: BIBankConnection,
  ): Partial<PostBIConnectionApiContract> {
    return {
      id: biBankConnection.id,
      error:
        biBankConnection.status === BankConnectionStatus.connected
          ? null
          : biBankConnection.status,
      connector: biBankConnection.connector
        ? BIConnector.toJson(biBankConnection.connector)
        : null,
      accounts: biBankConnection.accounts
        ? biBankConnection.accounts
            .filter((biAccount: BIAccount) => biAccount.disabled === null)
            .map((biAccount: BIAccount) => BIAccount.toJson(biAccount))
        : undefined,
    };
  }
}
