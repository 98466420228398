import { Entity, EntityApiContract } from './entity';
import {
  Message,
  MessageApiContract,
  Sender,
  SenderApiContract,
} from './message';

export interface BankTransactionThreadApiContract {
  id: number;
  type: string;
  closed_at: string;
  archived_at: string;
  read_by_user: boolean;
  last_answer_by: 'accountant' | 'customer';
}

export class BankTransactionThread {
  constructor(
    public id: number,
    public type: string,
    public closedAt: string,
    public archivedAt: string,
    public readByUser: boolean,
    public lastAnswerBy: 'accountant' | 'customer',
  ) {}

  static fromJsonArray(
    threads: BankTransactionThreadApiContract[],
  ): BankTransactionThread | undefined {
    let thread = threads.find(
      (transactionThread: BankTransactionThreadApiContract) =>
        !transactionThread.closed_at && !transactionThread.read_by_user,
    );
    thread =
      thread ||
      threads.find(
        (transactionThread: BankTransactionThreadApiContract) =>
          !transactionThread.closed_at && transactionThread.read_by_user,
      );
    thread =
      thread ||
      threads.find(
        (transactionThread: BankTransactionThreadApiContract) =>
          transactionThread.closed_at,
      );
    return thread && BankTransactionThread.fromJson(thread);
  }

  static fromJson(
    json: BankTransactionThreadApiContract,
  ): BankTransactionThread {
    return new BankTransactionThread(
      json.id,
      json.type,
      json.closed_at,
      json.archived_at,
      json.read_by_user,
      json.last_answer_by,
    );
  }
}

export interface ThreadApiContract {
  id: number;
  created_at: string;
  entity: EntityApiContract;
  closed_at: string;
  closed_by: number;
  archived_at: string;
  archived_by: number;
  creator: SenderApiContract;
  read_by_user: boolean;
  messages: MessageApiContract[];
}

export class Thread {
  constructor(
    public id: number,
    public createdAt: string,
    public entity: Entity,
    public closedAt: string,
    public closedBy: number,
    public archivedAt: string,
    public archivedBy: number,
    public creator: Sender,
    public readByUser: boolean,
    public messages: Message[],
  ) {}

  static fromJson(json: ThreadApiContract): Thread {
    return new Thread(
      json.id,
      json.created_at,
      Entity.fromJson(json.entity),
      json.closed_at,
      json.closed_by,
      json.archived_at,
      json.archived_by,
      json.creator,
      json.read_by_user,
      json.messages.map(m => Message.fromJson(m)),
    );
  }
}
