export interface BusinessAdvisorContactApi {
  id: string;
  first_name: string;
  email: string;
  agenda: string;
  phone: string;
}

export class BusinessAdvisorContact {
  constructor(
    public id: string,
    public firstName: string,
    public email: string,
    public agenda: string,
    public phone: string,
  ) {}

  static fromJson(
    businessAdvisorContact: BusinessAdvisorContactApi,
  ): BusinessAdvisorContact {
    return new BusinessAdvisorContact(
      businessAdvisorContact.id,
      businessAdvisorContact.first_name,
      businessAdvisorContact.email,
      businessAdvisorContact.agenda,
      businessAdvisorContact.phone,
    );
  }
}
