import { inject, InjectionToken } from '@angular/core';
import { ActivatedRoute, ParamMap } from '@angular/router';
import { Observable } from 'rxjs';

export const ROUTE_QUERY_PARAMS = new InjectionToken<Observable<ParamMap>>(
  'An Observable that contains a map of the query parameters available to the current route',
  {
    factory: (): Observable<ParamMap> => inject(ActivatedRoute).queryParamMap,
  },
);
