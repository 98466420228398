import { Mail } from 'tiime-components';

export class ExtraAttachment {
  name: string;
  content: string;
}

export class InvoicingDocumentMail extends Mail {
  constructor(
    mailOptions: Mail,
    public filename?: string,
    public extraAttachments?: ExtraAttachment[],
  ) {
    super(mailOptions);
  }

  public static toJson(mail: InvoicingDocumentMail): Record<string, unknown> {
    return {
      ...super.toJson(mail),
      filename: mail.filename,
      extra_attachments: mail.extraAttachments,
    };
  }
}
