export interface WalletCardInUseApiContract {
  will_expire_soon: boolean;
  expiry_date: string;
}

export class WalletCardInUse {
  constructor(public willExpireSoon?: boolean, public expiryDate?: string) {}

  public static fromJson(json: WalletCardInUseApiContract): WalletCardInUse {
    return new WalletCardInUse(json.will_expire_soon, json.expiry_date);
  }
}
