<div
  *ngIf="!isRightPanel; else rightPanelHeaderTemplate"
  class="year-container__header"
>
  <button class="year-container__header--button" (click)="changeYear.emit()">
    <mat-icon
      class="rotate-180"
      svgIcon="{{ TIIME_ICON_PREFIX + 'chevron' }}"
    ></mat-icon>
  </button>
  <span class="year-label">{{ year }}</span>
</div>
<div class="year-container__months">
  <ng-container *ngFor="let month of months; let i = index; trackBy: trackById">
    <div
      *tiimeLet="i | map: mapToIndex:isRightPanel as index"
      class="month"
      [class.highlighted]="month.isInRange"
      [class.left-boundary]="index === boundaries[0]?.monthIndex"
      [class.right-boundary]="index === boundaries[1]?.monthIndex"
      [class.disabled]="index | map: mapToIsMonthDisabled:boundaries[0]"
      [class.current-month]="i | map: mapToIsCurrentMonth:year"
      (click)="onMonthClicked(index)"
    >
      <div
        class="month__label"
        [class.boundary]="
          index === boundaries[0]?.monthIndex ||
          index === boundaries[1]?.monthIndex
        "
      >
        {{ month.label }}
      </div>
    </div>
  </ng-container>
</div>

<ng-template #rightPanelHeaderTemplate>
  <div class="year-container__header right-panel">
    <span class="year-label">{{ year }}</span>
    <button class="year-container__header--button" (click)="changeYear.emit()">
      <mat-icon svgIcon="{{ TIIME_ICON_PREFIX + 'chevron' }}"></mat-icon>
    </button>
  </div>
</ng-template>
