import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { catchError, switchMap, take, tap } from 'rxjs/operators';

import { DownloadFile } from '@core/models';
import { FileTransferStatus } from '@enums';

import { FileTransferServiceBase } from './file-transfer-service.base';

@Injectable({
  providedIn: 'root',
})
export class DownloadFilesService extends FileTransferServiceBase<DownloadFile> {
  protected defaultErrorMessage = 'Erreur lors du téléchargement';

  private currentId = 0;

  addToQueue(
    dowloadObservables: Observable<Blob>[],
    filenames?: string[],
  ): void {
    this._queue$
      .pipe(
        take(1),
        tap(queue => {
          const downloadDocuments = this.convertToTransferFiles(
            dowloadObservables,
            filenames,
          );
          this._queue$.next([...downloadDocuments, ...queue]);
        }),
      )
      .subscribe();
  }

  protected convertToTransferFiles(
    files: Observable<Blob>[],
    filenames?: string[],
  ): DownloadFile[] {
    return Array.from(files).map((observable, i) => ({
      id: this.currentId++,
      file: observable,
      filename: filenames?.[i] || 'download.zip',
      transferStatus: FileTransferStatus.NOT_STARTED,
      progress: 0,
      isUpload: false,
    }));
  }

  protected isSameFile(a: DownloadFile, b: DownloadFile): boolean {
    return a.id === b.id;
  }

  protected transferFile(
    fileToDownload: DownloadFile,
  ): Observable<Blob | DownloadFile> {
    return of(fileToDownload).pipe(
      tap(
        (downloadFile: DownloadFile) =>
          (downloadFile.transferStatus = FileTransferStatus.IN_PROGRESS),
      ),
      switchMap(downloadFile => downloadFile.file),
      tap(() => {
        fileToDownload.transferStatus = FileTransferStatus.COMPLETED;
        this.updateFileInQueue(fileToDownload);
      }),
      catchError(error => this.fileTransferError(fileToDownload, error)),
    );
  }
}
