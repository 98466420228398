<tiime-dialog-close-button></tiime-dialog-close-button>

<div mat-dialog-content>
  <img
    class="illu"
    ngSrc="assets/illus/label/documents-label-request-illu.svg"
    width="105"
    height="98"
  />
  <span class="title">Veuillez choisir un label</span>
  <div class="label-selector-container">
    <span class="subtitle">
      {{ data.documentIds.length }}
      <ng-container [ngPlural]="data.documentIds.length">
        <ng-template ngPluralCase="=0">élément à labéliser :</ng-template>
        <ng-template ngPluralCase="=1">élément à labéliser :</ng-template>
        <ng-template ngPluralCase="other">éléments à labéliser :</ng-template>
      </ng-container>
    </span>
    <div *tiimeLet="labelCreationEnabled$ | async as labelCreationEnabled">
      <app-label-selector
        *ngIf="labels$ | async as labels"
        accent
        [labels]="labels"
        [xsmall]="false"
        [showTagChip]="true"
        [enableTagCreation]="true"
        [labelCreationEnabled]="labelCreationEnabled"
        [disableLastUsedLabel]="true"
        [isExternalInvoices]="data.isExternalInvoices"
        (valueChange)="setLabelOrTags($event)"
      ></app-label-selector>
    </div>
  </div>
  <div class="hint">
    <mat-icon svgIcon="icon-warning-filled"></mat-icon>
    <div class="hint__text">
      <div class="hint__title">Les labels déjà présents seront remplacés</div>
      <div class="hint__subtitle">
        Le label sera appliqué à l'ensemble des éléments sélectionnés, hors note
        de frais
      </div>
    </div>
  </div>

  <app-locked-documents-warning
    *ngIf="data.numberOfBlockedDocuments"
    [numberOfLockedDocuments]="data.numberOfBlockedDocuments"
  ></app-locked-documents-warning>
</div>
<div class="dialog-actions" mat-dialog-actions>
  <button
    class="cancel"
    type="button"
    tiime-button
    neutral
    inverted
    [mat-dialog-close]="null"
  >
    Annuler
  </button>
  <button
    class="validate"
    tiime-button
    accent
    [disabled]="!this.labelOrTag || !data.documentIds.length"
    (click)="submit()"
  >
    Valider
  </button>
</div>
