import { TrackingEvent } from '@manakincubber/tiime-tracking';

import { Client } from '@models';

export const CLIENT_EDITOR_OPENED = 'Client Editor Opened';

export class ClientEditorOpened implements TrackingEvent {
  readonly name = CLIENT_EDITOR_OPENED;

  constructor(readonly clientId?: Client['id']) {}
}
