import { FormControl, FormGroup, Validators } from '@angular/forms';

import { Company, InvoicingConfig } from '@models';

interface InvoicingDocumentSellerHeaderFormModel {
  name: FormControl<string>;
  address: FormControl<string>;
  addressComplement: FormControl<string | null>;
  city: FormControl<string>;
  postalCode: FormControl<string>;
  country: FormControl<string>;
  email: FormControl<string | null>;
  phone: FormControl<string | null>;
}

export class InvoicingDocumentSellerHeaderForm extends FormGroup<InvoicingDocumentSellerHeaderFormModel> {
  get nameErrorMessage(): string | null {
    if (!this.controls.name.touched) {
      return null;
    }
    return this.controls.name.hasError('required') ? 'Société requise' : null;
  }
  get addressErrorMessage(): string | null {
    if (!this.controls.address.touched) {
      return null;
    }
    return this.controls.address.hasError('required')
      ? 'Adresse requise'
      : null;
  }
  get addressComplementErrorMessage(): string | null {
    if (!this.controls.addressComplement.touched) {
      return null;
    }
    return this.controls.addressComplement.hasError('maxlength')
      ? `Complément d'adresse limité à 255 caractères`
      : null;
  }
  get cityErrorMessage(): string | null {
    if (!this.controls.city.touched) {
      return null;
    }
    return this.controls.city.hasError('required') ? 'Ville requise' : null;
  }
  get countryErrorMessage(): string | null {
    if (!this.controls.country.touched) {
      return null;
    }
    return this.controls.country.hasError('required') ? 'Pays requis' : null;
  }
  get postalCodeErrorMessage(): string | null {
    if (!this.controls.postalCode.touched) {
      return null;
    }
    return this.controls.postalCode.hasError('required')
      ? 'Code postal requis'
      : null;
  }

  constructor() {
    super({
      name: new FormControl('', { nonNullable: true }),
      address: new FormControl('', { nonNullable: true }),
      addressComplement: new FormControl<string | null>(null, {
        nonNullable: true,
        validators: Validators.maxLength(255),
      }),
      city: new FormControl('', { nonNullable: true }),
      postalCode: new FormControl('', { nonNullable: true }),
      country: new FormControl('', { nonNullable: true }),
      email: new FormControl<string | null>(null),
      phone: new FormControl<string | null>(null),
    });
  }

  fromData(company: Company, { email, phone }: InvoicingConfig): void {
    this.patchValue(
      {
        name: company.name,
        address: company.street,
        addressComplement: company.addressComplement,
        city: company.city,
        postalCode: company.postalCode,
        country: company.country || 'FR',
        email,
        phone,
      },
      { emitEvent: false },
    );
  }

  setRequired(isRequired: boolean): void {
    this.controls.name.setValidators(isRequired ? Validators.required : null);
    this.controls.address.setValidators(
      isRequired ? Validators.required : null,
    );
    this.controls.city.setValidators(isRequired ? Validators.required : null);
    this.controls.postalCode.setValidators(
      isRequired ? Validators.required : null,
    );
    this.controls.country.setValidators(
      isRequired ? Validators.required : null,
    );
    this.setValidators(isRequired ? Validators.required : null);

    Object.keys(this.controls).forEach(
      (controlKey: keyof InvoicingDocumentSellerHeaderFormModel) =>
        this.controls[controlKey].updateValueAndValidity({ emitEvent: false }),
    );
    this.updateValueAndValidity({ emitEvent: false });
  }
}
