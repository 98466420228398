import { InvoicingTemplate } from '@enums';
import { Country } from '@models';

import { AbstractInvoicingDocumentConfig } from '../bases/abstract-invoicing-document-config';

export class InvoiceConfig extends AbstractInvoicingDocumentConfig {
  constructor(
    deliveryAddressEnabled: boolean,
    sirenOrSiretEnabled: boolean,
    intracomVatNumberEnabled: boolean,
    freeFieldEnabled: boolean,
    country: Country,
    discountEnabled: boolean,
    template: InvoicingTemplate,
    titleEnabled: boolean,
    public bankDetailEnabled: boolean,
    public paymentConditionEnabled: boolean,
  ) {
    super(
      deliveryAddressEnabled,
      sirenOrSiretEnabled,
      intracomVatNumberEnabled,
      freeFieldEnabled,
      country,
      discountEnabled,
      template,
      titleEnabled,
    );
  }
}
