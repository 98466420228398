import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { Mapper } from '@manakincubber/tiime-utils';
import { UntilDestroy } from '@ngneat/until-destroy';

import { SimpleBankTransactionLabel } from '@models/labels';

@UntilDestroy()
@Component({
  selector: 'app-label-icon',
  templateUrl: './label-icon.component.html',
  styleUrls: ['./label-icon.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LabelIconComponent {
  @Input() label: SimpleBankTransactionLabel;
  @Input() large: boolean;
  @Input() invoiceClient: boolean;

  readonly mapToIconBackgroundColor: Mapper<
    SimpleBankTransactionLabel,
    string
  > = (label: SimpleBankTransactionLabel) => `background-color: ${label.color}`;

  readonly mapToAcronymFormat: Mapper<SimpleBankTransactionLabel, string> = (
    label: SimpleBankTransactionLabel,
  ) => (this.large ? label.acronym : label.acronym[0]);
}
