import {
  FormArray,
  FormControl,
  FormGroup,
  ValidatorFn,
  Validators,
} from '@angular/forms';

import { RECEIPT_NEEDED_FROM_AMOUNT } from '@constants';
import { BankTransferRecurrency } from '@enums';
import { BankAccount, Beneficiary, Document } from '@models';

export interface BankTransferFormValue {
  initiation: BankTransferInitiationFormValue;
  parameters: BankTransferParametersFormValue;
  documents: BankTransferDocumentProofFormValue[];
}

export interface BankTransferInitiationFormValue {
  amount: number | null;
  account: BankAccount | null;
  beneficiary: Beneficiary | null;
}

export interface BankTransferParametersFormValue {
  date: Date | null;
  frequency: BankTransferRecurrency | null;
  label: string | null;
  indefinitely: boolean;
  untilDate: Date | null;
}

export interface BankTransferDocumentProofFormValue {
  document: Pick<Document, 'id' | 'type'> | null;
}

export const bankTransferFrequencyOptions = [
  { value: BankTransferRecurrency.Once, label: 'Une seule fois' },
  { value: BankTransferRecurrency.Weekly, label: 'Chaque semaine' },
  { value: BankTransferRecurrency.Monthly, label: 'Chaque mois' },
] as const;

const UntilRequiredValidator: ValidatorFn | null = (
  bankTransferParametersForm: BankTransferParametersForm,
) => {
  const frequencyValue = bankTransferParametersForm.frequency
    .value as BankTransferRecurrency | null;
  const indefinitelyValue = bankTransferParametersForm.indefinitely.value as
    | boolean
    | null;
  if (frequencyValue !== BankTransferRecurrency.Once && !indefinitelyValue) {
    return Validators.required(bankTransferParametersForm.untilDate);
  }
  return null;
};

const DocumentProofNeededValidator: ValidatorFn | null = (
  bankTransferDocumentsProofFormArray: BankTransferDocumentsProofFormArray,
) => {
  const amount = (
    bankTransferDocumentsProofFormArray.parent as BankTransferForm
  )?.initiation.amount.value as number | null;
  if (amount >= RECEIPT_NEEDED_FROM_AMOUNT) {
    return bankTransferDocumentsProofFormArray.length < 1
      ? { minLength: true }
      : null;
  }
  return null;
};

export class BankTransferForm extends FormGroup {
  get initiation(): BankTransferInitiationForm {
    return this.get('initiation') as BankTransferInitiationForm;
  }

  get parameters(): BankTransferParametersForm {
    return this.get('parameters') as BankTransferParametersForm;
  }

  get documents(): BankTransferDocumentsProofFormArray {
    return this.get('documents') as BankTransferDocumentsProofFormArray;
  }

  constructor() {
    const today = new Date();

    super({
      initiation: new BankTransferInitiationForm(),
      parameters: new BankTransferParametersForm(today),
      documents: new BankTransferDocumentsProofFormArray(
        DocumentProofNeededValidator,
      ),
    });
  }
}

export class BankTransferInitiationForm extends FormGroup {
  get amount(): FormControl {
    return this.get('amount') as FormControl;
  }

  get account(): FormControl {
    return this.get('account') as FormControl;
  }

  get beneficiary(): FormControl {
    return this.get('beneficiary') as FormControl;
  }

  constructor() {
    super(
      {
        amount: new FormControl(null, [
          Validators.required,
          Validators.min(0.01),
        ]),
        account: new FormControl(null, Validators.required),
        beneficiary: new FormControl(null, Validators.required),
      },
      [Validators.required],
    );
  }
}

export class BankTransferParametersForm extends FormGroup {
  get date(): FormControl {
    return this.get('date') as FormControl;
  }

  get frequency(): FormControl {
    return this.get('frequency') as FormControl;
  }

  get label(): FormControl {
    return this.get('label') as FormControl;
  }

  get untilDate(): FormControl {
    return this.get('untilDate') as FormControl;
  }

  get indefinitely(): FormControl {
    return this.get('indefinitely') as FormControl;
  }

  constructor(minDate: Date) {
    super(
      {
        date: new FormControl(minDate, Validators.required),
        frequency: new FormControl(
          BankTransferRecurrency.Once,
          Validators.required,
        ),
        untilDate: new FormControl(null),
        indefinitely: new FormControl(true),
        label: new FormControl(null, Validators.maxLength(140)),
      },
      [UntilRequiredValidator, Validators.required],
    );
  }
}

export class BankTransferDocumentsProofFormArray extends FormArray<BankTransferDocumentProofForm> {
  constructor(validatorFn?: ValidatorFn) {
    super([], validatorFn);
  }

  addDocumentNeeded(forceRequiredValidator = true): void {
    this.push(new BankTransferDocumentProofForm(forceRequiredValidator));
    this.markAsTouched();
  }
}

export class BankTransferDocumentProofForm extends FormGroup {
  get document(): FormControl<Document> {
    return this.get('document') as FormControl;
  }

  constructor(forceRequiredValidator = true) {
    super({
      document: new FormControl(
        null,
        forceRequiredValidator ? Validators.required : null,
      ),
    });
  }
}
