<div data-cy="beneficiary-dialog">
  <h2 mat-dialog-title data-cy="beneficiary-dialog__title">
    Ajouter un bénéficiaire
  </h2>
  <tiime-dialog-close-button></tiime-dialog-close-button>

  <form class="add-beneficiary-form" mat-dialog-content [formGroup]="form">
    <img src="assets/illu_add_beneficiary.svg" height="80" width="80" alt=""/>

    <div class="add-beneficiary-form__name">
      <label for="name" data-cy="beneficiary-dialog__label--name">
        Nom du bénéficiaire
      </label>
      <input
        id="name"
        class="add-beneficiary-form__name-input"
        type="text"
        formControlName="name"
        data-cy="beneficiary-dialog__input--name"
      />
    </div>

    <div
      *tiimeLet="ibanErrorMessage$ | async as ibanErrorMessage"
      class="add-beneficiary-form__iban"
    >
      <label data-cy="beneficiary-dialog__label--iban">IBAN du compte</label>
      <app-iban-input
        formControlName="iban"
        [isFormatted]="true"
      ></app-iban-input>

      <div *ngIf="ibanErrorMessage || form.ibanErrorMessage" class="error">
        <ng-container *ngIf="form.ibanErrorMessage; else errorMessageTemplate">
          {{ form.ibanErrorMessage }}
        </ng-container>
        <ng-template #errorMessageTemplate>{{ ibanErrorMessage }}</ng-template>
      </div>
    </div>
  </form>

  <div mat-dialog-actions>
    <button
      tiime-button
      accent
      data-cy="add-beneficiary__btn"
      [disabled]="!form.valid || (loading$ | async)"
      (click)="addBeneficiary()"
    >
      Ajouter ce bénéficiaire
    </button>
  </div>
</div>
