import moment from 'moment';

import { DashboardPeriodType, DashboardType } from '@enums';

import { CustomDataset, CustomDatasetApiContract } from './custom-dataset';

export interface DashboardBlockOptionsApiContract {
  cumulated?: boolean;
  pinned_at?: string;
  period?: {
    type?: DashboardPeriodType;
    values?: {
      start?: string;
      end?: string;
      number_of_months?: number;
    };
  };
  period_comparison?: {
    years_back_count: number;
    colors: string[];
  };
  customization?: DashboardCustomizationApiContract;
}

export interface DashboardBlockApiContract<T> {
  data_are_uncertain?: boolean;
  id: number;
  type: DashboardType;
  options: DashboardBlockOptionsApiContract;
  data: T[] | T[][];
  pinned_at?: string;
  metadata?: {
    header?: { due_before?: string; due_pending?: number; total_due?: number };
  };
}

export class DashboardCustomization {
  constructor(
    public title?: string,
    public datasets?: CustomDataset[],
    public type?: DashboardType,
  ) {}

  static fromJson(
    json?: DashboardCustomizationApiContract,
  ): DashboardCustomization | undefined {
    return (
      json &&
      new DashboardCustomization(
        json.title,
        json.datasets.map(dataset => CustomDataset.fromJson(dataset)),
        json.type,
      )
    );
  }

  static toJson(
    dashboardCustomization: DashboardCustomization,
  ): DashboardCustomizationApiContract {
    return {
      title: dashboardCustomization.title,
      datasets: dashboardCustomization.datasets.map(dataset =>
        CustomDataset.toJson(dataset),
      ),
      type: dashboardCustomization.type,
    };
  }
}

export interface DashboardCustomizationApiContract {
  title: string;
  datasets: CustomDatasetApiContract[];
  type: DashboardType;
}

export class DashboardBlockOptionsPeriodComparisonApiContract {
  years_back_count: number;
  colors: string[];
}
export class DashboardBlockOptionsPeriodComparison {
  constructor(public yearsBackCount?: number, public colors?: string[]) {}

  static fromJson(
    json: DashboardBlockOptionsPeriodComparisonApiContract,
  ): DashboardBlockOptionsPeriodComparison {
    return json
      ? new DashboardBlockOptionsPeriodComparison(
          json.years_back_count,
          json.colors,
        )
      : undefined;
  }

  static toJson(
    periodComparison: DashboardBlockOptionsPeriodComparison,
  ): DashboardBlockOptionsPeriodComparisonApiContract {
    return periodComparison
      ? {
          years_back_count: periodComparison.yearsBackCount,
          colors: periodComparison.colors,
        }
      : undefined;
  }
}

export class DashboardBlockOptions {
  constructor(
    public period: {
      type: DashboardPeriodType;
      values: {
        start?: string;
        end?: string;
        numberOfMonths?: number;
      };
    },
    public cumulated?: boolean,
    public pinnedAt?: string,
    public periodComparison?: {
      yearsBackCount?: number;
      colors?: string[];
    },
    public customization?: DashboardCustomization,
  ) {}

  static fromJson(
    json: DashboardBlockOptionsApiContract,
  ): DashboardBlockOptions {
    return new DashboardBlockOptions(
      {
        type: json.period?.type,
        values: {
          start: json.period?.values?.start,
          end: json.period?.values?.end,
          numberOfMonths: json.period?.values?.number_of_months,
        },
      },
      json?.cumulated,
      json?.pinned_at,
      DashboardBlockOptionsPeriodComparison.fromJson(json?.period_comparison),
      DashboardCustomization.fromJson(json?.customization),
    );
  }

  static toJson(
    dashboardType: DashboardType,
    options?: DashboardBlockOptions,
  ): DashboardBlockOptionsApiContract {
    const json: DashboardBlockOptionsApiContract = {
      cumulated: options?.cumulated,
      pinned_at: options?.pinnedAt,
    };
    const period = options?.period;
    if (period) {
      json.period = {
        type: period.type,
        values: {
          start: moment(period.values.start).format('YYYY-MM-DD'),
          end: moment(period.values.end).format('YYYY-MM-DD'),
          number_of_months: period.values?.numberOfMonths,
        },
      };
    }
    if (
      dashboardType === DashboardType.sales ||
      dashboardType === DashboardType.custom
    ) {
      const periodComparison = options?.periodComparison;
      if (periodComparison) {
        json.period_comparison =
          DashboardBlockOptionsPeriodComparison.toJson(periodComparison);
      }
    }
    if (
      (dashboardType === DashboardType.custom ||
        dashboardType === DashboardType.donut) &&
      options?.customization
    ) {
      json.customization = DashboardCustomization.toJson(
        options?.customization,
      );
    }
    return json;
  }
}

export interface DashboardMetadataApiContract {
  header?: { due_before?: string; due_pending?: number; total_due?: number };
}

export class DashboardMetadata {
  constructor(
    public header?: {
      dueBefore?: string;
      duePending?: number;
      totalDue?: number;
    },
  ) {}

  static fromJson(json: DashboardMetadataApiContract): DashboardMetadata {
    return new DashboardMetadata({
      dueBefore: json?.header?.due_before,
      duePending: json?.header?.due_pending,
      totalDue: json?.header?.total_due,
    });
  }
}

export class DashboardBlockListItem {
  constructor(
    public id: number,
    public type: DashboardType,
    public pinnedAt: string,
  ) {}
}

export class DashboardBlock<T> extends DashboardBlockListItem {
  constructor(
    id: number,
    type: DashboardType,
    public options: DashboardBlockOptions,
    public data: T | T[] | T[][],
    pinnedAt: string,
    public dataAreUncertain: boolean,
    public metadata?: DashboardMetadata,
  ) {
    super(id, type, pinnedAt);
  }

  static fromJson<JSON>(
    json: DashboardBlockApiContract<JSON>,
  ): DashboardBlock<JSON> {
    return new DashboardBlock<JSON>(
      json.id,
      json.type,
      json.options ? DashboardBlockOptions.fromJson(json.options) : null,
      json.data,
      json.pinned_at,
      json.data_are_uncertain,
      DashboardMetadata.fromJson(json.metadata),
    );
  }
}
