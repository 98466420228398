import { Pipe, PipeTransform } from '@angular/core';

import { InvoiceHelper } from '@helpers';
import { Invoice, InvoiceListItem } from '@models';

import { InvoiceForm } from '../../../../core/forms/invoice-form';

@Pipe({
  name: 'invoiceCanBeCancelled',
})
export class InvoiceCanBeCancelledPipe implements PipeTransform {
  /**
   * Returns wether the invoice can be cancelled or not.
   * The second param is only used to retrigger the pipe in lists without having to
   * change the reference of the invoice or to make the pipe unpure.
   */
  transform(invoice: Invoice | InvoiceListItem | InvoiceForm): boolean {
    if (invoice instanceof InvoiceForm) {
      return InvoiceHelper.invoiceCanBeCancelled(invoice.getRawValue());
    }

    return InvoiceHelper.invoiceCanBeCancelled(invoice);
  }
}
