<div class="search-input">
  <input
    class="search"
    type="text"
    data-cy="label__input-search"
    #search
    [placeholder]="searchLabelWording"
    [formControl]="labelNameFormControl"
    (click)="$event.stopPropagation()"
  />
</div>
<ng-container *tiimeLet="searchResults$ | async as searchResults">
  <app-last-used-labels
    *ngIf="!data.disableLastUsedLabel && data.labelCreationEnabled"
    [transactionId]="data.transactionId"
    [documentId]="data.documentId"
    [showLastUsedLabels]="!labelNameFormControl.value"
    (selectedLabel)="select($event, null)"
  ></app-last-used-labels>

  <span
    *ngIf="noMatch === true && !data.labelCreationEnabled"
    class="no-result"
    (click)="$event.stopPropagation()"
  >
    Aucune correspondance dans cette liste
  </span>
  <div
    *ngIf="labelNameFormControl.value || !data.labelCreationEnabled"
    class="results"
    #resultContainer
  >
    <ng-container *ngIf="(loading$ | async) === true; else resultsTemplate">
      <app-label-chip-placeholder
        *ngFor="let placeholder of placeholders; trackBy: trackByIndex"
        [basic]="true"
      ></app-label-chip-placeholder>
    </ng-container>
    <ng-template #resultsTemplate>
      <ng-container
        *ngFor="
          let result of searchResults;
          let index = index;
          trackBy: trackById
        "
      >
        <app-label-chip
          class="18px"
          tabindex="0"
          [label]="result"
          [basic]="true"
          [attr.data-cy]="
            'label__search-result-' +
            index +
            (result.disabled ? '__disabled' : '')
          "
          (click)="select(result, $event)"
        ></app-label-chip>
      </ng-container>
    </ng-template>
  </div>
  <div [class.actions]="searchResults?.length > 5">
    <button
      *ngIf="
        data.enableTagCreation && labelNameFormControl
          | canCreateLabelOrTag : searchResults : !data.labelCreationEnabled
      "
      class="menu-btn"
      data-cy="tag__btn-add"
      (click)="tagCreation(labelNameFormControl.value)"
    >
      <span class="icon-wrapper tag">
        <img class="filled-plus" alt="" src="assets/actions/icon-plus.svg" />
      </span>
      <span class="item-title bold">
        Ajouter le tag « {{ labelNameFormControl.value }} »
      </span>
    </button>
    <button
      *ngIf="
        labelNameFormControl
          | canCreateLabelOrTag : searchResults : data.labelCreationEnabled
      "
      class="menu-btn"
      data-cy="label__btn-add"
      (click)="create($event)"
    >
      <span class="icon-wrapper label">
        <img class="filled-plus" alt="" src="assets/actions/icon-plus.svg" />
      </span>
      <span class="item-title bold">{{ labelCreationOptionWording }}</span>
    </button>

    <button
      *ngIf="data.enableRuleCreation"
      class="menu-btn"
      data-cy="label__btn-create-rule"
      (click)="close(LabelSelectionResponseEnum.OPEN_RULE_DIALOG)"
    >
      <span class="icon">
        <mat-icon class="add-rule-icon" svgIcon="icon-lightning"></mat-icon>
      </span>

      <span class="item-title bold">Créer une règle</span>
    </button>
    <button
      *ngIf="data.enableVentilation"
      class="menu-btn"
      data-cy="label__btn-split"
      (click)="close(LabelSelectionResponseEnum.OPEN_VENTILATIONS_OVERLAY)"
    >
      <span class="icon">
        <mat-icon
          class="ventilation-icon"
          fontIcon="icon-ventilation-fontastic"
        ></mat-icon>
      </span>
      <span class="item-title bold">Scinder en plusieurs labels</span>
    </button>
  </div>
</ng-container>
