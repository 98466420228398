import { User } from '@models';

export class EmployeeContact {
  constructor(
    public id?: number,
    public lastname?: string,
    public firstname?: string,
    public phone?: string,
    public email?: string,
  ) {}

  static fromUser(user: User): EmployeeContact {
    return new EmployeeContact(
      user.id,
      user.lastName,
      user.firstName,
      user.phone,
      user.email,
    );
  }
}
