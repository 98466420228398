import { LinkedEntity, LinkedEntityType } from './linked-entity';
import { LinkedEntityBankTransaction } from './linked-entity-bank-transaction';
import { LinkedEntityExpenseReport } from './linked-entity-expense-report';
import { LinkedEntityImputation } from './linked-entity-imputation';

export class LinkedEntityPredicates {
  /**
   * Type predicate function used to filter linked bank transaction
   * @param linkedEntity Either a linked bank transaction, linked expense report
   * or a linked bank transaction imputation
   */
  static isBankTransaction(
    linkedEntity: LinkedEntity,
  ): linkedEntity is LinkedEntityBankTransaction {
    return linkedEntity.type === LinkedEntityType.BANK_TRANSACTION;
  }

  /**
   * Type predicate function used to filter linked expense reports
   * @param linkedEntity Either a linked bank transaction, linked expense report
   * or a linked bank imputation
   */
  static isExpenseReport(
    linkedEntity: LinkedEntity,
  ): linkedEntity is LinkedEntityExpenseReport {
    return linkedEntity.type === LinkedEntityType.EXPENSE_REPORT;
  }

  /**
   * Type predicate function used to filter linked bank transaction imputations
   * @param linkedEntity Either a linked bank transaction, linked expense report
   * or a linked bank transaction imputation
   */
  static isImputation(
    linkedEntity: LinkedEntity,
  ): linkedEntity is LinkedEntityImputation {
    return linkedEntity.type === LinkedEntityType.IMPUTATION;
  }
}
