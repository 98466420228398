import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Moment } from 'moment';

import {
  ClientPaymentStatus,
  ExportLinkFilterOption,
  InvoiceStatus,
  QuoteStatus,
} from '@enums';
import { MomentHelper } from '@helpers';
import {
  BankAccount,
  Category,
  Client,
  ClientsExportConfiguration,
  DocumentsExportConfiguration,
  ExportRequest,
  InvoicesExportConfiguration,
  QuotesExportConfiguration,
  Tag,
  ThirdParty,
  TransactionsExportConfiguration,
} from '@models';

export class ExportForm extends FormGroup {
  get endDate(): FormControl<Moment> {
    return this.get('endDate') as FormControl<Moment>;
  }

  get invoiceAttachmentsEnabled(): FormControl<boolean> {
    return this.get('invoiceAttachmentsEnabled') as FormControl<boolean>;
  }

  get invoiceBlockExportEnabled(): FormControl<boolean> {
    return this.get('invoiceBlockExportEnabled') as FormControl<boolean>;
  }

  get quoteAttachmentsEnabled(): FormControl<boolean> {
    return this.get('quoteAttachmentsEnabled') as FormControl<boolean>;
  }

  get selectedQuoteClients(): FormControl<Client[]> {
    return this.get('selectedQuoteClients') as FormControl<Client[]>;
  }

  get selectedInvoiceClients(): FormControl<Client[]> {
    return this.get('selectedInvoiceClients') as FormControl<Client[]>;
  }

  get quoteExportEnabled(): FormControl<boolean> {
    return this.get('quoteExportEnabled') as FormControl<boolean>;
  }

  get invoiceExportEnabled(): FormControl<boolean> {
    return this.get('invoiceExportEnabled') as FormControl<boolean>;
  }

  get clientExportEnabled(): FormControl<boolean> {
    return this.get('clientExportEnabled') as FormControl<boolean>;
  }

  get invoicesLinkedToTransactionsFilter(): FormControl<ExportLinkFilterOption> {
    return this.get(
      'invoicesLinkedToTransactionsFilter',
    ) as FormControl<ExportLinkFilterOption>;
  }

  get isInvoiceExportBlockDisabled(): boolean {
    return !this.invoiceBlockExportEnabled.value;
  }

  get isInvoiceExportDisabled(): boolean {
    return !this.invoiceExportEnabled.value;
  }

  get isClientExportDisabled(): boolean {
    return !this.clientExportEnabled.value;
  }

  get isQuoteExportDisabled(): boolean {
    return !this.quoteExportEnabled.value;
  }

  get isReceiptExportDisabled(): boolean {
    return !this.receiptExportEnabled.value;
  }

  get isTransactionExportDisabled(): boolean {
    return !this.transactionExportEnabled.value;
  }

  get receiptAttachmentsEnabled(): FormControl<boolean> {
    return this.get('receiptAttachmentsEnabled') as FormControl<boolean>;
  }

  get receiptExportEnabled(): FormControl<boolean> {
    return this.get('receiptExportEnabled') as FormControl<boolean>;
  }

  get selectedReceiptTags(): FormControl<Tag[]> {
    return this.get('selectedReceiptTags') as FormControl<Tag[]>;
  }

  get receiptsLinkedToTransactionsFilter(): FormControl<ExportLinkFilterOption> {
    return this.get(
      'receiptsLinkedToTransactionsFilter',
    ) as FormControl<ExportLinkFilterOption>;
  }

  get selectedBankAccounts(): FormControl<BankAccount[]> {
    return this.get('selectedBankAccounts') as FormControl<BankAccount[]>;
  }

  get selectedInvoiceStatuses(): FormControl<InvoiceStatus[]> {
    return this.get('selectedInvoiceStatuses') as FormControl<InvoiceStatus[]>;
  }

  get selectedQuoteStatuses(): FormControl<QuoteStatus[]> {
    return this.get('selectedQuoteStatuses') as FormControl<QuoteStatus[]>;
  }

  get selectedClientStatuses(): FormControl<ClientPaymentStatus[]> {
    return this.get('selectedClientStatuses') as FormControl<
      ClientPaymentStatus[]
    >;
  }

  get selectedThirdParties(): FormControl<ThirdParty[]> {
    return this.get('selectedThirdParties') as FormControl<ThirdParty[]>;
  }

  get selectedDocumentCategories(): FormControl<Category[]> {
    return this.get('selectedDocumentCategories') as FormControl<Category[]>;
  }

  get startDate(): FormControl<Moment> {
    return this.get('startDate') as FormControl<Moment>;
  }

  get transactionExportEnabled(): FormControl<boolean> {
    return this.get('transactionExportEnabled') as FormControl<boolean>;
  }

  get transactionsLinkedToInvoicesFilter(): FormControl<ExportLinkFilterOption> {
    return this.get(
      'transactionsLinkedToInvoicesFilter',
    ) as FormControl<ExportLinkFilterOption>;
  }

  get transactionsLinkedToReceiptsFilter(): FormControl<ExportLinkFilterOption> {
    return this.get(
      'transactionsLinkedToReceiptsFilter',
    ) as FormControl<ExportLinkFilterOption>;
  }

  get selectedInvoiceTags(): FormControl<Tag[]> {
    return this.get('selectedInvoiceTags') as FormControl<Tag[]>;
  }

  get selectedQuoteTags(): FormControl<Tag[]> {
    return this.get('selectedQuoteTags') as FormControl<Tag[]>;
  }

  constructor() {
    super({
      startDate: new FormControl<Moment>(
        MomentHelper.firstDayOfMonth(),
        Validators.required,
      ),
      endDate: new FormControl<Moment>(
        MomentHelper.today(),
        Validators.required,
      ),
      transactionExportEnabled: new FormControl<boolean>(
        true,
        Validators.required,
      ),
      selectedBankAccounts: new FormControl<BankAccount[]>([]),
      transactionsLinkedToReceiptsFilter:
        new FormControl<ExportLinkFilterOption>(
          ExportLinkFilterOption.all,
          Validators.required,
        ),
      transactionsLinkedToInvoicesFilter:
        new FormControl<ExportLinkFilterOption>(
          ExportLinkFilterOption.all,
          Validators.required,
        ),
      receiptExportEnabled: new FormControl<boolean>(true, Validators.required),
      selectedReceiptTags: new FormControl<Tag[]>([]),
      selectedThirdParties: new FormControl<ThirdParty[]>([]),
      selectedDocumentCategories: new FormControl<Category[]>([]),
      receiptsLinkedToTransactionsFilter:
        new FormControl<ExportLinkFilterOption>(
          ExportLinkFilterOption.all,
          Validators.required,
        ),
      receiptAttachmentsEnabled: new FormControl<boolean>(
        true,
        Validators.required,
      ),
      invoiceBlockExportEnabled: new FormControl<boolean>(
        true,
        Validators.required,
      ),
      invoiceExportEnabled: new FormControl<boolean>(true, Validators.required),
      quoteExportEnabled: new FormControl<boolean>(true, Validators.required),
      clientExportEnabled: new FormControl<boolean>(true, Validators.required),
      selectedInvoiceStatuses: new FormControl<InvoiceStatus[]>([]),
      selectedQuoteStatuses: new FormControl<QuoteStatus[]>([]),
      selectedClientStatuses: new FormControl<ClientPaymentStatus[]>([]),
      invoicesLinkedToTransactionsFilter:
        new FormControl<ExportLinkFilterOption>(
          ExportLinkFilterOption.all,
          Validators.required,
        ),
      invoiceAttachmentsEnabled: new FormControl<boolean>(
        true,
        Validators.required,
      ),
      quoteAttachmentsEnabled: new FormControl<boolean>(
        true,
        Validators.required,
      ),
      selectedQuoteClients: new FormControl<Client[]>([]),
      selectedInvoiceClients: new FormControl<Client[]>([]),
      selectedInvoiceTags: new FormControl<Tag[]>([]),
      selectedQuoteTags: new FormControl<Tag[]>([]),
    });
  }

  setFreemiumState(): void {
    this.patchValue(
      { transactionExportEnabled: false, receiptExportEnabled: false },
      { emitEvent: false },
    );
  }

  resetToInitialState(isFree22 = false): void {
    super.reset({
      startDate: MomentHelper.firstDayOfMonth(),
      endDate: MomentHelper.today(),
      transactionExportEnabled: !isFree22,
      selectedBankAccounts: [],
      transactionsLinkedToReceiptsFilter: ExportLinkFilterOption.all,
      transactionsLinkedToInvoicesFilter: ExportLinkFilterOption.all,
      receiptExportEnabled: !isFree22,
      selectedReceiptTags: [],
      selectedThirdParties: [],
      selectedDocumentCategories: [],
      receiptsLinkedToTransactionsFilter: ExportLinkFilterOption.all,
      receiptAttachmentsEnabled: true,
      invoiceBlockExportEnabled: true,
      invoiceExportEnabled: true,
      quoteExportEnabled: true,
      selectedInvoiceStatuses: [],
      selectedQuoteStatuses: [],
      invoicesLinkedToTransactionsFilter: ExportLinkFilterOption.all,
      invoiceAttachmentsEnabled: true,
      clientExportEnabled: true,
      quoteAttachmentsEnabled: true,
      selectedQuoteClients: [],
      selectedInvoiceClients: [],
      selectedInvoiceTags: [],
      selectedQuoteTags: [],
    });
  }

  toExportRequest(): ExportRequest {
    return new ExportRequest(
      MomentHelper.toCalendarDateString(this.startDate.value),
      MomentHelper.toCalendarDateString(this.endDate.value),
      this.getTransactionsExportConfiguration(),
      this.getInvoicesExportConfiguration(),
      this.getQuoteExportConfiguration(),
      this.getClientExportConfiguration(),
      this.getDocumentsExportConfiguration(),
    );
  }

  private getClientExportConfiguration():
    | ClientsExportConfiguration
    | undefined {
    return this.clientExportEnabled.value
      ? new ClientsExportConfiguration(this.selectedClientStatuses.value)
      : undefined;
  }

  private getQuoteExportConfiguration(): QuotesExportConfiguration | undefined {
    return this.quoteExportEnabled.value
      ? new QuotesExportConfiguration(
          this.selectedQuoteStatuses.value,
          this.quoteAttachmentsEnabled.value,
          this.selectedQuoteClients.value,
          this.selectedQuoteTags.value,
        )
      : undefined;
  }

  private getInvoicesExportConfiguration():
    | InvoicesExportConfiguration
    | undefined {
    return this.invoiceExportEnabled.value
      ? new InvoicesExportConfiguration(
          this.selectedInvoiceStatuses.value,
          this.transformExportLinkFilterOptionToValue(
            this.invoicesLinkedToTransactionsFilter.value,
          ),
          this.invoiceAttachmentsEnabled.value,
          this.selectedInvoiceClients.value,
          this.selectedInvoiceTags.value,
        )
      : undefined;
  }

  private getDocumentsExportConfiguration():
    | DocumentsExportConfiguration
    | undefined {
    return this.receiptExportEnabled.value
      ? new DocumentsExportConfiguration(
          this.transformExportLinkFilterOptionToValue(
            this.receiptsLinkedToTransactionsFilter.value,
          ),
          this.receiptAttachmentsEnabled.value,
          this.selectedReceiptTags.value,
          this.selectedThirdParties.value,
          this.selectedDocumentCategories.value,
        )
      : undefined;
  }

  private getTransactionsExportConfiguration():
    | TransactionsExportConfiguration
    | undefined {
    return this.transactionExportEnabled.value
      ? new TransactionsExportConfiguration(
          this.transformExportLinkFilterOptionToValue(
            this.transactionsLinkedToReceiptsFilter.value,
          ),
          this.transformExportLinkFilterOptionToValue(
            this.transactionsLinkedToInvoicesFilter.value,
          ),
          this.selectedBankAccounts.value,
        )
      : undefined;
  }

  private transformExportLinkFilterOptionToValue(
    exportLinkFilterOption: ExportLinkFilterOption,
  ): boolean | null {
    switch (exportLinkFilterOption) {
      case ExportLinkFilterOption.linked_only:
        return true;
      case ExportLinkFilterOption.unlinked_only:
        return false;
      default:
        return null;
    }
  }
}
