import { CommonModule } from '@angular/common';
import { Attribute, ChangeDetectionStrategy, Component } from '@angular/core';
import { TiimeTooltipModule } from 'tiime-components';

import { IconsModule } from '@shared';

@Component({
  standalone: true,
  imports: [CommonModule, TiimeTooltipModule, IconsModule],
  selector: 'app-document-pending',
  templateUrl: './document-pending.component.html',
  styleUrls: ['./document-pending.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DocumentPendingComponent {
  constructor(@Attribute('primary') private readonly primary: boolean) {}
}
