export interface InvoiceBankContract {
  id: number;
  name: string;
  brand: string;
  sigle?: string;
  code: string;
  logo_url?: string;
}

export interface InvoiceBankAccountContract {
  id: number;
  iban?: string;
  bic?: string;
  name: string;
  bank: InvoiceBankContract;
}

export class InvoiceBank {
  constructor(
    public id: number,
    public name: string,
    public brand: string,
    public code: string,
    public sigle?: string,
    public logoUrl?: string,
  ) {}

  static fromJson(contract: InvoiceBankContract): InvoiceBank {
    return new InvoiceBank(
      contract.id,
      contract.name,
      contract.brand,
      contract.code,
      contract.sigle,
      contract.logo_url,
    );
  }
}

export class InvoiceBankAccount {
  constructor(
    public id: number,
    public name: string,
    public bank: InvoiceBank,
    public iban?: string,
    public bic?: string,
  ) {}

  static fromJson(contract: InvoiceBankAccountContract): InvoiceBankAccount {
    return new InvoiceBankAccount(
      contract.id,
      contract.name,
      InvoiceBank.fromJson(contract.bank),
      contract.iban,
      contract.bic,
    );
  }
}
