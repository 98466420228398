import { Injectable } from '@angular/core';
import { Observable, tap } from 'rxjs';
import { TiimeOverlayRef, TiimeOverlayService } from 'tiime-components';

import { DownloadFilesService, UploadDocumentsService } from '@core';
import { DocumentUploadOptions } from '@core/models/documents/document-upload-options';

import { FileTransferOverlayComponent } from './file-transfer-overlay.component';

@Injectable({
  providedIn: 'root',
})
export class FileTransferOverlayService {
  private overlayRef: TiimeOverlayRef;

  constructor(
    private overlayService: TiimeOverlayService,
    private uploadDocumentsService: UploadDocumentsService,
    private downloadFilesService: DownloadFilesService,
  ) {}

  closeOverlay(): void {
    if (!this.overlayRef) {
      return;
    }
    this.overlayRef.close();
  }

  openOverlayWithFiles(
    files: FileList | File[],
    categoryId?: number,
    documentUploadOptions?: DocumentUploadOptions,
  ): void {
    this.openFileTransferOverlay();
    this.uploadDocumentsService.addToQueue(
      files,
      categoryId,
      documentUploadOptions,
    );
  }

  openOverlayWithDownloads(
    observables: Observable<Blob>[],
    filenames?: string[],
  ): void {
    this.openFileTransferOverlay();
    this.downloadFilesService.addToQueue(observables, filenames);
  }

  private openFileTransferOverlay(): void {
    if (this.overlayRef) {
      return;
    }
    this.overlayRef = this.overlayService.open(
      FileTransferOverlayComponent,
      null,
      {
        panelClass: 'upload-receipt-overlay-panel',
        backdropClass: 'cdk-overlay-transparent-backdrop',
        minHeight: '0px',
        maxHeight: '275px',
        width: '400px',
        disposeOnNavigation: false,
      },
    );
    this.overlayRef
      .afterClosed()
      .pipe(
        tap(() => {
          this.downloadFilesService.clearQueue();
          this.uploadDocumentsService.clearQueue();
        }),
        tap((): void => (this.overlayRef = undefined)),
      )
      .subscribe();
  }
}
