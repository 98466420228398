import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { TiimePipesModule } from '@manakincubber/tiime-utils';
import {
  TiimeButtonModule,
  TiimeDialogModule,
  TiimeLetModule,
} from 'tiime-components';

import { MonthPeriodPickerComponent } from './month-period-picker.component';
import { YearItemComponent } from './year-item/year-item.component';

@NgModule({
  exports: [MonthPeriodPickerComponent],
  imports: [
    TiimeDialogModule,
    TiimePipesModule,
    TiimeLetModule,
    TiimeButtonModule,
    CommonModule,
    MatIconModule,
  ],
  declarations: [MonthPeriodPickerComponent, YearItemComponent],
})
export class MonthPeriodPickerModule {}
