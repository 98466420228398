import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { Mapper } from '@manakincubber/tiime-utils';

import { BankTransferFormValue } from '@forms';
import { BankAccount, BankTransferReason, Beneficiary, Payout } from '@models';
import { TIIME_ICON_PREFIX } from '@shared';

@Component({
  selector: 'app-bank-transfer-recap',
  templateUrl: './bank-transfer-recap.component.html',
  styleUrls: ['./bank-transfer-recap.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BankTransferRecapComponent {
  @Input() formValue: BankTransferFormValue;
  @Input() hideHeader: boolean;
  @Input() reason: BankTransferReason;
  @Input() personalWalletBeneficiary: Beneficiary;

  readonly TIIME_ICON_PREFIX = TIIME_ICON_PREFIX;

  readonly mapToIconPath: Mapper<BankAccount | Beneficiary, string> =
    account => {
      if ((account as BankAccount)?.isWallet) {
        return 'assets/logo_tiime_no_text.svg';
      }
      return 'assets/illu_bank_transfer_account.svg';
    };

  readonly mapToPayout: Mapper<BankTransferFormValue, Payout> = formValue => {
    return new Payout(
      formValue.initiation.amount,
      formValue.initiation.beneficiary,
      formValue.parameters.date,
      formValue.parameters.frequency,
      formValue.parameters.indefinitely ? null : formValue.parameters.untilDate,
      formValue.parameters.label,
    );
  };
}
