/**
 * Member values are used to determine the url that will be called
 */
export enum MatchingType {
  invoices = 'invoice',
  documents = 'document',
  expense_reports = 'expense_reports',
  bankTransactions = 'bank_transaction',
  imputations = 'imputations',
}
