import { Directive } from '@angular/core';
import { ParamMap, Router } from '@angular/router';
import { from, Observable } from 'rxjs';

import {
  REDIRECT_TO_PARAM_NAME,
  REDIRECTION_TARGET_APP,
  REDIRECTION_TARGET_APP_PARAM_NAME,
} from '@constants';

@Directive()
// eslint-disable-next-line @angular-eslint/directive-class-suffix
export abstract class AppRedirectionBase {
  protected constructor(protected readonly router: Router) {}

  protected authRedirectUri(queryParams: ParamMap): string {
    const basePath = `${window.location.origin}/auth-callback`;

    const redirectUri = queryParams.get(REDIRECT_TO_PARAM_NAME);
    if (redirectUri) {
      return `${basePath}?${REDIRECT_TO_PARAM_NAME}=${redirectUri}`;
    }

    const targetApp = queryParams.get(REDIRECTION_TARGET_APP_PARAM_NAME);
    if (targetApp) {
      return `${basePath}?${REDIRECTION_TARGET_APP_PARAM_NAME}=${targetApp}`;
    }

    return basePath;
  }

  protected handleRedirectionFromQueryParams(
    companyId: number,
    queryParams: ParamMap,
    fallback: unknown[] = ['/companies', companyId],
  ): Observable<boolean> {
    const redirectUri = queryParams.get(REDIRECT_TO_PARAM_NAME);
    if (redirectUri) {
      return this.redirectToAppInnerRoute(redirectUri);
    }

    const targetApp = queryParams.get(REDIRECTION_TARGET_APP_PARAM_NAME);
    if (targetApp) {
      return this.redirectToApp(companyId, targetApp);
    }

    return from(this.router.navigate(fallback));
  }

  private getRedirectCommands(companyId: number, targetApp: string): unknown[] {
    const baseCommands = ['/companies', companyId];
    const targetAppPath = REDIRECTION_TARGET_APP[targetApp];
    return targetAppPath ? [...baseCommands, targetAppPath] : baseCommands;
  }

  private redirectToApp(
    companyId: number,
    targetApp: string,
  ): Observable<boolean> {
    const redirectCommands = this.getRedirectCommands(companyId, targetApp);
    return from(this.router.navigate(redirectCommands));
  }

  private redirectToAppInnerRoute(
    innerAppUriRedirection?: string | null,
  ): Observable<boolean> {
    const redirectCommands = decodeURIComponent(innerAppUriRedirection).split(
      '/',
    );

    return from(this.router.navigate(redirectCommands));
  }
}
