import { FormControl, FormGroup, Validators } from '@angular/forms';
import moment from 'moment';

import { Period } from '@constants';
import { DashboardPeriodType } from '@enums';
import { DashboardBlockOptions } from '@models/dashboards';

export class DashboardPeriodForm extends FormGroup {
  get period(): FormControl {
    return this.get('period') as FormControl;
  }
  get dateFrom(): FormControl {
    return this.get('dateFrom') as FormControl;
  }
  get dateTo(): FormControl {
    return this.get('dateTo') as FormControl;
  }
  get yearsBackCount(): FormControl {
    return this.get('yearsBackCount') as FormControl;
  }
  get nColor(): FormControl {
    return this.get('nColor') as FormControl;
  }
  get n1Color(): FormControl {
    return this.get('n1Color') as FormControl;
  }
  get n2Color(): FormControl {
    return this.get('n2Color') as FormControl;
  }

  constructor() {
    super({
      period: new FormControl(null, Validators.required),
      dateFrom: new FormControl(null, Validators.required),
      dateTo: new FormControl(null, Validators.required),
      yearsBackCount: new FormControl(null),
      nColor: new FormControl(null),
      n1Color: new FormControl(null),
      n2Color: new FormControl(null),
    });
  }

  toDashboardOptions(): DashboardBlockOptions {
    const isCustomPeriodDefined = (this.period.value === Period.Custom &&
      this.dateFrom.value &&
      this.dateTo.value) as boolean;

    const startDate = moment(this.dateFrom.value).format('YYYY-MM-DD');
    const endDate = moment(this.dateTo.value).format('YYYY-MM-DD');

    const options = {
      period: {
        type: Number(this.period.value)
          ? DashboardPeriodType.lastMonths
          : (this.period.value as DashboardPeriodType),
        values: {},
      },
      periodComparison: {
        yearsBackCount: this.yearsBackCount.value as number,
        colors: [
          this.nColor.value as string,
          this.n1Color.value as string,
          this.n2Color.value as string,
        ],
      },
    };

    if (isCustomPeriodDefined) {
      options.period.values = {
        start: startDate,
        end: endDate,
      };
    } else if (this.period.value !== Period.CurrentAccountingPeriod) {
      options.period.values = {
        numberOfMonths: this.period.value as number,
      };
    }

    return options;
  }
}
