import { createSelector } from '@ngrx/store';

import { TAX_REGIME_MICRO } from '@constants';
import { Company, User } from '@models';

export const userSelector = (state: any) => state?.user as User;

export const userFirstnameSelector = createSelector(
  userSelector,
  (user: User) => user?.firstName,
);

export const selectedCompanySelector = createSelector(
  userSelector,
  (user: User) => user?.activeCompany,
);

export const selectedCompanyIdSelector = createSelector(
  userSelector,
  (user: User) => user?.activeCompany?.id || user?.activeCompanyId,
);

export const hasBankAccessSelector = createSelector(
  selectedCompanySelector,
  (company: Company) => company?.bankAccess,
);

export const isFree22selector = createSelector(
  hasBankAccessSelector,
  (hasBankAccess: boolean) => !hasBankAccess,
);

export const isMicroEntrepreneur = createSelector(
  selectedCompanySelector,
  (company: Company) => company.taxRegime === TAX_REGIME_MICRO,
);
