import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  HostBinding,
  Input,
} from '@angular/core';
import { Mapper, TiimePipesModule } from '@manakincubber/tiime-utils';

@Component({
  standalone: true,
  imports: [CommonModule, TiimePipesModule],
  selector: 'app-acronym-illustration',
  templateUrl: './acronym-illustration.component.html',
  styleUrls: ['./acronym-illustration.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AcronymIllustrationComponent {
  @Input() acronym!: string;
  @Input() color!: string;

  @Input()
  @HostBinding('style.--scale')
  scale = 1;

  readonly mapToBackgroundIllustration: Mapper<string, string> =
    hexadecimalColor => {
      // remove #
      const color = hexadecimalColor.slice(1).toLocaleUpperCase();
      return `assets/illus/label/bg_${color}.svg`;
    };
}
