import { InvoicingCategoriesEnum } from '@enums';
import {
  AbstractLine,
  AbstractLineApiContract,
  Unit,
  UnitApiContract,
  VatTypeApiContract,
} from '@models';
import { ArticleApiContract } from '@services/articles';

import { Discount } from './discount';
import { VatType } from './vat-type';

export interface LineApiContract extends AbstractLineApiContract {
  quantity: number;
  unit_amount: number;
  article: { id: ArticleApiContract['id'] } | null;
  discount_amount: number | null;
  discount_description: string | null;
  discount_percentage: number | null;
  vat_type: VatTypeApiContract;
  line_amount: number;
  invoicing_unit: UnitApiContract;
  invoicing_category_type: InvoicingCategoriesEnum;
}

export class Line extends AbstractLine {
  constructor(
    id: number,
    description: string,
    public quantity: number,
    public totalExcludingTaxes: number,
    public unitAmount: number,
    public vatType: VatType | null,
    public articleId: number | null,
    public discount: Discount,
    public lineAmount: number,
    public invoicingUnit: Unit,
    public invoicingCategoryType: InvoicingCategoriesEnum,
    sequence: number,
  ) {
    super(id, description, sequence);
  }

  public static fromJson(json: LineApiContract): Line {
    return new Line(
      json.id,
      json.description,
      json.quantity,
      Number((json.line_amount + json.discount_amount).toFixed(2)),
      json.unit_amount,
      json.vat_type ? VatType.fromJson(json.vat_type) : null,
      json.article?.id ?? null,
      new Discount(
        json.discount_description,
        json.discount_percentage,
        json.discount_amount,
      ),
      json.line_amount,
      json.invoicing_unit ? Unit.fromJson(json.invoicing_unit) : null,
      json.invoicing_category_type !== InvoicingCategoriesEnum.sale
        ? InvoicingCategoriesEnum.benefit
        : InvoicingCategoriesEnum.sale,
      json.sequence,
    );
  }

  public static toJson(line: Line): Record<string, unknown> {
    return {
      id: line.id || undefined,
      description: line.description,
      quantity: line.quantity ?? null,
      line_amount:
        line.totalExcludingTaxes !== undefined
          ? Number((line.totalExcludingTaxes - line.discount.amount).toFixed(2))
          : null,
      unit_amount: line.unitAmount !== undefined ? line.unitAmount : null,
      vat_type: VatType.toJson(line.vatType),
      article: line.articleId ? { id: line.articleId } : null,
      discount_description: line.discount.description,
      discount_percentage: line.discount.percentage,
      discount_amount: line.discount.amount,
      invoicing_unit: line.invoicingUnit?.id
        ? { id: line.invoicingUnit.id }
        : null,
      invoicing_category_type: line.invoicingCategoryType,
      sequence: line.sequence,
    };
  }
}
