<div class="account-item" [attr.data-cy]="'account-item__id-' + account.id">
  <div class="account-item__icon-container">
    <div class="account-icon">
      <img height="32" width="32" alt="" [src]="account | map: mapToIconPath" />
    </div>
  </div>

  <div class="account-item__content">
    <div class="account-item__top-row">
      <div class="account-item__owner">
        @if (account.id) {
          <span data-cy="account-item__name">
            {{ account.name }}
          </span>
        } @else {
          <button
            class="account-item__add-button"
            xxsmall
            tiime-button
            accent
            (click)="addNewBeneficiary($event)"
          >
            <mat-icon svgIcon="icon-plus"></mat-icon>
            Créer ce bénéficiaire
          </button>
        }
        <ng-container *ngIf="isBeneficiary">
          <app-trust-beneficiary
            [beneficiary]="account"
            [personalWalletBeneficiary]="personalWalletBeneficiary"
          ></app-trust-beneficiary>
        </ng-container>
      </div>
      <div *ngIf="!isBeneficiary" class="account-item__balance">
        {{ account.authorizedBalance | currency }}
      </div>
    </div>
    <div class="account-item__bottom-row">
      <div class="account-item__details">
        <ng-container *ngIf="!isBeneficiary">
          <span class="account-item__bank">{{ account.bankName }}</span>
          -
        </ng-container>
        <span class="account-item__iban">
          {{
            account.id
              ? (account.iban | offuscatedIban)
              : (account.iban | t2UtilsIbanPipe)
          }}
        </span>
      </div>

      <mat-icon *ngIf="isSelected" svgIcon="icon-check"></mat-icon>
    </div>
  </div>
</div>
