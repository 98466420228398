import { Pipe, PipeTransform } from '@angular/core';

import { Label } from '@models/labels';

@Pipe({
  standalone: true,
  name: 'lastUsedLabelsText',
})
export class LastUsedLabelsTextPipe implements PipeTransform {
  transform(
    lastUsedLabels?: Label[],
    shouldDisplaySuggestions?: boolean,
  ): string {
    if (shouldDisplaySuggestions) {
      return lastUsedLabels.length > 1 ? 'Suggestions' : 'Suggestion';
    }

    return lastUsedLabels?.length === 1
      ? 'Dernier label utilisé'
      : 'Derniers labels utilisés';
  }
}
