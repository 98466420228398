import { Sort } from '@angular/material/sort';

import { PaginationRange } from '../paginator';
import { SortHelper } from '../sort';
import { Expand } from './expand';
import { Filter } from './filter';

export interface DefaultGetOptions {
  range?: PaginationRange;
  search?: string;
  sort?: Sort | null;
  filters?: ReadonlyArray<Filter<unknown>>;
  expand?: Expand;
}

export type RequiredGetOptions<T extends keyof DefaultGetOptions> =
  DefaultGetOptions & Required<Pick<DefaultGetOptions, T>>;

export type HttpGetOptions = {
  headers: { [header: string]: string };
  params: { [param: string]: string };
};

export class GetOptions<T extends DefaultGetOptions = DefaultGetOptions> {
  readonly range: T['range'];
  readonly search: T['search'];
  readonly sort: T['sort'];
  readonly filters: T['filters'];
  readonly expand: T['expand'];

  constructor(options: T) {
    this.range = options.range;
    this.search = options.search;
    this.sort = options.sort;
    this.filters = options.filters;
    this.expand = options.expand;
  }

  toHttpGetOptions(): HttpGetOptions {
    const headers = {
      ...(this.range && {
        Range: new PaginationRange(
          this.range.min,
          this.range.max + 1,
        ).toHttpRequestHeader(),
      }),
    };

    const params = {
      ...(this.expand && this.expand.toParamObject()),
      ...(this.filters &&
        Object.assign(
          {},
          ...this.filters.map((filter: Filter<unknown>) =>
            filter.toParamObject(),
          ),
        )),
      ...(this.search && { q: this.search }),
      ...(this.sort?.direction && {
        sorts: SortHelper.toStringParam(this.sort),
      }),
    };

    return { headers, params };
  }
}
