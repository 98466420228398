import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { switchMap, map, catchError, mergeMap } from 'rxjs/operators';

import { CategoriesService } from '@core';
import { Category } from '@models';

import {
  addCategorySuccess,
  ADD_CATEGORY,
  LOAD_CATEGORIES,
  storeCategories,
  updateCategorySuccess,
  UPDATE_CATEGORY,
} from './categories.actions';

@Injectable()
export class CategoriesEffects {
  constructor(
    private readonly actions$: Actions,
    private readonly categoriesService: CategoriesService,
  ) {}

  loadCategories$ = createEffect(() =>
    this.actions$.pipe(
      ofType(LOAD_CATEGORIES),
      switchMap(() => this.categoriesService.getAll()),
      map(categories => storeCategories({ categories })),
      catchError(() => of(storeCategories({ categories: [] }))),
    ),
  );

  addCategory$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ADD_CATEGORY),
      mergeMap((action: { name: string }) =>
        this.categoriesService
          .create(action.name.trim())
          .pipe(map(category => addCategorySuccess({ category }))),
      ),
    ),
  );

  updateCategory$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UPDATE_CATEGORY),
      mergeMap((action: { category: Category }) => {
        return this.categoriesService
          .update(action.category)
          .pipe(map(category => updateCategorySuccess({ category })));
      }),
    ),
  );
}
