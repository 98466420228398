import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import {
  TiimeButtonModule,
  TiimeCheckboxModule,
  TiimeIconModule,
  TiimeMenuModule,
  TiimeRadioModule,
} from 'tiime-components';

import { ScrollShadowsModule } from '@directives';

import { IconsModule } from '../../../icons/icons.module';
import { TableLabelFilterComponent } from './table-label-filter.component';

@NgModule({
  imports: [
    CommonModule,
    TiimeButtonModule,
    TiimeIconModule,
    IconsModule,
    TiimeMenuModule,
    TiimeRadioModule,
    ReactiveFormsModule,
    TiimeCheckboxModule,
    ScrollShadowsModule,
  ],
  exports: [TableLabelFilterComponent, TiimeMenuModule],
  declarations: [TableLabelFilterComponent],
})
export class TableLabelFilterModule {}
