import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { Observable, of } from 'rxjs';
import { catchError, map, switchMap, take, tap } from 'rxjs/operators';

import { UsersService } from '@core';
import { User } from '@models';
import { update as updateUser, userSelector } from '@store/user';

@Injectable({
  providedIn: 'root',
})
export class UserGuard {
  constructor(private store: Store, private usersService: UsersService) {}

  canMatch(): Observable<boolean> {
    return this.hasUserInStore().pipe(
      switchMap((inStore: boolean) =>
        inStore ? of(inStore) : this.hasUserInApi(),
      ),
    );
  }

  private hasUserInStore(): Observable<boolean> {
    return this.store.pipe(
      select(userSelector),
      map((user: User) => !!user),
      take(1),
    );
  }

  private hasUserInApi(): Observable<boolean> {
    return this.usersService.getUser().pipe(
      tap(user => this.store.dispatch(updateUser({ user }))),
      catchError(() => of(false)),
      switchMap(() => this.hasUserInStore()),
    );
  }
}
