import {
  Component,
  ChangeDetectionStrategy,
  ElementRef,
  QueryList,
  ViewChildren,
  Input,
  EventEmitter,
  Output,
  Renderer2,
  ChangeDetectorRef,
  OnChanges,
  SimpleChanges,
  AfterViewInit,
} from '@angular/core';
import {
  AbstractControl,
  ControlValueAccessor,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  ValidationErrors,
  Validator,
} from '@angular/forms';
import { NgUtils } from '@manakincubber/tiime-utils';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'app-pin-code-input',
  templateUrl: './pin-code-input.component.html',
  styleUrls: ['./pin-code-input.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: PinCodeInputComponent,
    },
    {
      provide: NG_VALIDATORS,
      multi: true,
      useExisting: PinCodeInputComponent,
    },
  ],
})
export class PinCodeInputComponent
  implements OnChanges, AfterViewInit, ControlValueAccessor, Validator
{
  @Input() code?: string | number;
  @Input() color?: 'warn' | 'accent';
  /**
   * Allows to configure how to group the pin code inputs.
   *
   * For example, a value of 3 will result in groups of 3 inputs.
   *
   * If no value is provided, the inputs won't be grouped.
   */
  @Input() groupSize = 0;
  /**
   * WARNING: Only works if used inside a reactive form.
   * Otherwise, no chechmark will be displayed.
   */
  @Input() showCheckmarkOnInputValid = true;
  /**
   * Whether if the inputs must be masked or not.
   *
   * WARNING: only works at initilization, cannot be changed dynamiccaly.
   */
  @Input() masked = true;
  @Input() codeLength = 4;
  @Input() set disabled(disabled: boolean) {
    this.disabled$.next(disabled);
  }

  @Output() readonly codeChange = new EventEmitter<string>();
  @Output() readonly codeComplete = new EventEmitter<string>();

  @ViewChildren('input') inputsList: QueryList<ElementRef<HTMLInputElement>>;
  @ViewChildren('hiddenInput') hiddenInputsList: QueryList<
    ElementRef<HTMLInputElement>
  >;

  touched = false;
  errors: ValidationErrors;
  valid = false;
  placeholders = new Array<number>(this.codeLength || 0);

  readonly disabled$ = new BehaviorSubject<boolean>(false);
  readonly inputType = 'number';
  readonly isCodeHidden = true;
  readonly trackByIndex = NgUtils.trackByIndex;

  private onChange: (pinCode: string) => void;
  private onTouched: () => void;

  constructor(
    private readonly renderer: Renderer2,
    private changeDetector: ChangeDetectorRef,
  ) {}

  ngOnChanges({ codeLength }: SimpleChanges): void {
    if (codeLength !== null && codeLength !== undefined) {
      this.placeholders = new Array<number>(this.codeLength);
    }
  }

  ngAfterViewInit(): void {
    if (this.masked) {
      for (const { nativeElement } of this.inputsList) {
        if (document.activeElement !== nativeElement) {
          this.renderer.addClass(nativeElement, 'masked');
        }
      }
    }
  }

  writeValue(pinCode: string | number): void {
    this.writePinCode(pinCode);
  }

  registerOnChange(fn: (pinCode: string) => void): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    this.disabled$.next(isDisabled);
    this.changeDetector.markForCheck();
  }

  validate(control: AbstractControl): ValidationErrors | null {
    const pinCode = control.value as number | string;
    const stringPinCode = pinCode.toString().trim();

    const errors: ValidationErrors = {};

    // The entered pin code length must match the asked code length
    if (stringPinCode.length !== this.codeLength) {
      errors.lengthError = {
        given: stringPinCode.length,
        required: this.codeLength,
      };
    }

    this.valid = Object.keys(errors).length === 0;
    this.errors = errors;
    this.changeDetector.markForCheck();

    return this.valid ? null : errors;
  }

  onInput(event: InputEvent, inputIndex: number): void {
    const target: HTMLInputElement = event.target as HTMLInputElement;
    const value = event.data || target.value;

    this.writePinCode(value, inputIndex, event);

    const valueLength = value.toString().trim().length;
    const next = inputIndex + valueLength;
    if (next < this.codeLength) {
      this.focusInput(next);
    }
  }

  onKeydown(event: KeyboardEvent, inputIndex: number): void {
    if (event.key === 'Backspace') {
      event.preventDefault();
      this.setInputValue(null, inputIndex);
      if (inputIndex > 0) {
        this.focusInput(inputIndex - 1);
      }
      this.emitCode();
    }
    if (event.key === 'ArrowRight' && inputIndex < this.codeLength - 1) {
      this.focusInput(inputIndex + 1);
    }
    if (event.key === 'ArrowLeft' && inputIndex > 0) {
      this.focusInput(inputIndex - 1);
    }
  }

  onPaste(event: ClipboardEvent, inputIndex: number): void {
    event.preventDefault();
    event.stopPropagation();

    const data = event.clipboardData
      ? event.clipboardData.getData('text').trim()
      : null;

    if (this.isEmpty(data)) {
      return;
    }

    // Convert paste text into iterable
    const values = data.split('');
    let valIndex = 0;

    for (let i = inputIndex; i < this.inputsList.length; i++) {
      // The values end is reached. Loop exit
      if (valIndex === values.length) {
        break;
      }

      const val = values[valIndex];
      // Cancel the loop when a value cannot be used
      if (!this.canUseValue(val)) {
        this.setInputValue(null, i);
        return;
      }
      this.onFocus(i);
      this.setInputValue(val.toString(), i);
      this.onBlur(i);
      valIndex++;
    }
    this.emitCode();
  }

  onFocus(inputIndex: number): void {
    if (this.masked) {
      const inputElement = this.inputsList.get(inputIndex).nativeElement;
      inputElement.type = 'number';
      this.renderer.removeClass(inputElement, 'masked');
      inputElement.value =
        this.hiddenInputsList.get(inputIndex).nativeElement.value;
    }
  }

  onBlur(inputIndex: number): void {
    if (this.masked) {
      const inputElement = this.inputsList.get(inputIndex).nativeElement;
      this.renderer.addClass(inputElement, 'masked');
      inputElement.type = 'text';
      if (!this.isEmpty(inputElement.value)) {
        inputElement.value = '﹡';
      }
    }
  }

  private canUseValue(value?: number | string | null): boolean {
    if (this.isEmpty(value)) {
      return false;
    }
    return /^\d+$/.test(value.toString());
  }

  private emitCode(): void {
    const code = this.getCurrentFilledCode();
    this.onChange?.(code);
    this.codeChange.emit(code);

    if (code.length >= this.codeLength) {
      this.codeComplete.emit(code);
    }
  }

  private getCurrentFilledCode(): string {
    let code = '';

    for (const input of this.hiddenInputsList) {
      if (!this.isEmpty(input.nativeElement.value)) {
        code += input.nativeElement.value;
      }
    }
    return code;
  }

  private isEmpty(value?: string | number | null): boolean {
    return (
      value === null || value === undefined || value.toString().length === 0
    );
  }

  private setInputValue(value: string, inputIndex: number): void {
    this.markAsTouched();
    this.inputsList.get(inputIndex).nativeElement.value = value;
    this.hiddenInputsList.get(inputIndex).nativeElement.value = value;
  }

  private focusInput(inputIndex: number): void {
    this.inputsList.get(inputIndex).nativeElement.focus();
  }

  private blurInput(inputIndex: number): void {
    this.inputsList.get(inputIndex).nativeElement.blur();
  }

  private writePinCode(
    value: string | number,
    inputIndex = 0,
    event?: Event,
  ): void {
    if (this.isEmpty(value)) {
      return;
    }

    if (!this.canUseValue(value)) {
      event.preventDefault();
      event.stopPropagation();
      this.setInputValue(null, inputIndex);
      return;
    }

    const values = value.toString().trim().split('');
    for (let i = 0; i < values.length; i++) {
      const index = inputIndex + i;
      if (index >= this.codeLength) {
        break;
      }
      this.setInputValue(values[i], index);
    }
    this.emitCode();
  }

  private markAsTouched(): void {
    if (!this.touched) {
      this.onTouched?.();
      this.touched = true;
      this.markAsTouched();
    }
  }
}
