import { VehicleTypeEnum } from '@enums/mileage-allowances';

export type AllowedTaxHorsepower = { name: string; key: string };

export interface VehicleTypeApiContract {
  name: string;
  key: VehicleTypeEnum;
  allowed_tax_horsepower?: AllowedTaxHorsepower[];
}

export class VehicleType {
  constructor(
    public name?: string,
    public key?: VehicleTypeEnum,
    public allowedTaxHorsepower?: AllowedTaxHorsepower[],
  ) {}

  static fromJson(json: VehicleTypeApiContract | VehicleTypeEnum): VehicleType {
    if (typeof json === 'string') {
      return new VehicleType(null, json);
    } else {
      return new VehicleType(
        json?.name,
        json?.key,
        json?.allowed_tax_horsepower,
      );
    }
  }

  static toJson(vehicleType: VehicleType): VehicleTypeEnum {
    return vehicleType.key;
  }

  static getTaxHorsepowerByKey(
    taxHorsepowerKey: string,
    vehicleType: VehicleType,
  ): AllowedTaxHorsepower {
    return taxHorsepowerKey
      ? vehicleType.allowedTaxHorsepower.find(
          allowedTaxHorsepower => allowedTaxHorsepower.key === taxHorsepowerKey,
        )
      : null;
  }
}
