import { TilesApiResponse } from '@services';

import { ToDoCounts } from './to-do-counts';

export class Tiles {
  /**
   * - true: display tile
   * - false: tile dismissed by user
   * - undefined: no need to display tile
   */
  public createInvoice?: boolean;
  public clientsTotalDueAmount?: number;
  public totalSalesAmounts?: { current: number; lastMonth: number };
  public toDoCounts?: ToDoCounts;
  public unreadThreadsCount?: number;

  constructor(
    createInvoice?: boolean,
    clientsTotalDueAmount?: number,
    totalSalesAmounts?: { current: number; lastMonth: number },
    toDoCounts?: ToDoCounts,
    unreadThreadsCount?: number,
  ) {
    this.createInvoice = createInvoice;
    this.clientsTotalDueAmount = clientsTotalDueAmount;
    this.totalSalesAmounts = totalSalesAmounts;
    this.toDoCounts = toDoCounts;
    this.unreadThreadsCount = unreadThreadsCount;
  }

  public static fromJson(json: TilesApiResponse): Tiles {
    return new Tiles(
      json.create_invoice,
      json.clients_total_due_amount,
      json.total_sales_amounts
        ? {
            current: json.total_sales_amounts.current,
            lastMonth: json.total_sales_amounts.last_month,
          }
        : undefined,
      ToDoCounts.fromJson(json.to_do_counts),
      json.count_unread_thread,
    );
  }
}
