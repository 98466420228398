import { HttpErrorResponse, HttpProgressEvent } from '@angular/common/http';
import { ChangeDetectorRef, Directive, OnDestroy } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { finalize, last, takeUntil, tap } from 'rxjs/operators';

@Directive()
// eslint-disable-next-line @angular-eslint/directive-class-suffix
export abstract class UploadFileBase<T extends object> implements OnDestroy {
  loading = false;
  progress = 0;

  private readonly unsubscribe$ = new Subject<void>();

  protected constructor(protected readonly cdr: ChangeDetectorRef) {}

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  protected upload(
    uploadObservable: Observable<HttpProgressEvent | T>,
  ): Observable<T | HttpErrorResponse> {
    this.loading = true;

    return uploadObservable.pipe(
      takeUntil(this.unsubscribe$),
      tap((result: HttpProgressEvent | T) => {
        if ('loaded' in result && 'total' in result) {
          this.progress = Math.round((100 * result.loaded) / result.total);
          this.cdr.markForCheck();
        }
      }),
      last(),
      tap((finalResponse: T) => this.onFileUploaded(finalResponse)),
      finalize(() => {
        this.loading = false;
        this.progress = 0;
        this.cdr.markForCheck();
      }),
    );
  }

  protected abstract onFileUploaded(finalResponse: T): void;
}
