export enum UserRoles {
  ROLE_CARE = 'ROLE_CARE',
  ROLE_USER = 'ROLE_USER',
  ROLE_SALESFORCE = 'ROLE_SALESFORCE',
  ROLE_MACHINE_LEARNING = 'ROLE_MACHINE_LEARNING',
  ROLE_WALLET_ADMIN = 'ROLE_WALLET_ADMIN',
  ROLE_ENGINE_ADMIN = 'ROLE_ENGINE_ADMIN',
  ROLE_BUSINESS_AGENT = 'ROLE_BUSINESS_AGENT',
  ROLE_BUSINESS_ADMIN = 'ROLE_BUSINESS_ADMIN',
  ROLE_MANAGER = 'ROLE_MANAGER',
  ROLE_EMPLOYEE = 'ROLE_EMPLOYEE',
  ROLE_COMPANY_SUPER_ADMIN = 'ROLE_COMPANY_SUPER_ADMIN',
}
