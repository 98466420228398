import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { cloneDeep } from 'lodash-es';
import { iif, of } from 'rxjs';
import { catchError, map, mergeMap, switchMap } from 'rxjs/operators';
import {
  DefaultGetOptions,
  MonoValueFilter,
  PaginationData,
  PaginationRange,
  RequiredGetOptions,
} from 'tiime-components';

import { CategoriesService, DocumentsService } from '@core/services';
import { Category, Document, DocumentsWithMetadata } from '@models';
import { loadCategories } from '@store/categories';

import {
  CREATE_DOCUMENT,
  createDocumentSuccess,
  DELETE_DOCUMENT,
  deleteDocumentSuccess,
  LOAD_DOCUMENT,
  LOAD_DOCUMENT_DETAIL,
  LOAD_DOCUMENTS_FOR_CATEGORY,
  LOAD_DOCUMENTS_FOR_SEARCH,
  LOAD_RECENT_DOCUMENTS,
  loadDocumentFail,
  loadDocumentsForCategory,
  loadDocumentsForCategorySuccess,
  loadDocumentsForSearchSuccess,
  loadDocumentSuccess,
  loadRecentDocuments,
  UPDATE_DOCUMENT,
  UPDATE_DOCUMENT_CATEGORY,
  updateDocumentCategoryFail,
  updateDocumentCategorySuccess,
  updateDocumentsForCategory,
  updateDocumentSuccess,
  updateRecentDocuments,
} from './documents.actions';

@Injectable()
export class DocumentsEffects {
  constructor(
    private readonly actions$: Actions,
    private readonly categoriesService: CategoriesService,
    private readonly documentsService: DocumentsService,
  ) {}

  loadDocumentsDetail$ = createEffect(() =>
    this.actions$.pipe(
      ofType(LOAD_DOCUMENT_DETAIL),
      switchMap((action: { documentId: number }) =>
        this.documentsService
          .getDetail(action.documentId)
          .pipe(map(document => loadDocumentSuccess({ document }))),
      ),
    ),
  );

  loadDocumentsForSearch$ = createEffect(() =>
    this.actions$.pipe(
      ofType(LOAD_DOCUMENTS_FOR_SEARCH),
      switchMap((action: RequiredGetOptions<'range'>) =>
        this.documentsService
          .getDocumentsWithMetadata(action, null)
          .pipe(map(documents => loadDocumentsForSearchSuccess({ documents }))),
      ),
    ),
  );

  loadDocumentsForCategory$ = createEffect(() =>
    this.actions$.pipe(
      ofType(LOAD_DOCUMENTS_FOR_CATEGORY),
      mergeMap(
        (action: {
          categoryId: number;
          options: RequiredGetOptions<'range'>;
        }) => {
          const newOptions = cloneDeep(action.options);
          const categoryFilter = new MonoValueFilter<number>(
            'document_categories',
            action.categoryId,
          );
          newOptions.filters = [
            ...(action.options.filters || []),
            categoryFilter,
          ];
          return this.documentsService
            .getDocumentsWithMetadata(newOptions)
            .pipe(
              catchError(() =>
                of(
                  new DocumentsWithMetadata(
                    new PaginationData<Document>([]),
                    null,
                  ),
                ),
              ),
              switchMap((documents: DocumentsWithMetadata) => [
                loadDocumentsForCategorySuccess({ documents }),
                updateDocumentsForCategory({
                  categoryId: action.categoryId,
                  documents,
                }),
              ]),
            );
        },
      ),
    ),
  );

  loadRecentDocuments$ = createEffect(() =>
    this.actions$.pipe(
      ofType(LOAD_RECENT_DOCUMENTS),
      switchMap(() =>
        this.documentsService.getRecents().pipe(
          map(documents => new DocumentsWithMetadata(documents, null)),
          map((documentsWithMetadata: DocumentsWithMetadata) =>
            updateRecentDocuments({ documents: documentsWithMetadata }),
          ),
        ),
      ),
    ),
  );

  updateDocument$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UPDATE_DOCUMENT),
      switchMap((action: { payload: Partial<Document>; documentId: number }) =>
        this.documentsService
          .update(action.payload, action.documentId)
          .pipe(
            switchMap(document => [
              updateDocumentSuccess({ document }),
              loadRecentDocuments(),
            ]),
          ),
      ),
    ),
  );

  loadDocument$ = createEffect(() =>
    this.actions$.pipe(
      ofType(LOAD_DOCUMENT),
      switchMap(({ documentId, categoryId }) =>
        this.documentsService.getDetail(documentId).pipe(
          switchMap(document => [
            loadDocumentSuccess({ document }),
            updateDocumentsForCategory({
              documents: new DocumentsWithMetadata(
                new PaginationData([document]),
                null,
              ),
              categoryId,
            }),
          ]),
          catchError(() => of(loadDocumentFail())),
        ),
      ),
    ),
  );

  createDocument$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CREATE_DOCUMENT),
      switchMap((action: { payload: Partial<Document> }) =>
        this.documentsService
          .create(action.payload)
          .pipe(
            switchMap(document => [
              createDocumentSuccess({ document }),
              loadRecentDocuments(),
            ]),
          ),
      ),
    ),
  );

  updateDocumentCategory$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UPDATE_DOCUMENT_CATEGORY),
      switchMap(
        (action: { payload: Partial<Document>; document: Document }) => {
          const previousCategoryId = action.document.category.id;
          const nextCategory = new Category(
            action.payload.category.id,
            action.payload.category.name,
            action.payload.category.pinnedAt,
            action.payload.category.documents,
            action.payload.category.availableMetadata,
            action.payload.category.identifier,
          );

          return iif(
            () => !nextCategory.id && !!nextCategory.name,
            this.categoriesService.create(nextCategory.name),
            of(nextCategory),
          ).pipe(
            switchMap(category =>
              this.documentsService.update({ category }, action.document.id),
            ),
            switchMap(document => [
              loadCategories(),
              updateDocumentCategorySuccess({ document }),
              loadDocumentsForCategory({
                categoryId: previousCategoryId,
                options: {
                  range: new PaginationRange(),
                  sort: { active: 'created_at', direction: 'desc' },
                },
              }),
              loadDocumentsForCategory({
                categoryId: document.category.id,
                options: {
                  range: new PaginationRange(),
                  sort: { active: 'created_at', direction: 'desc' },
                },
              }),
              loadRecentDocuments(),
            ]),
            catchError(() => of(updateDocumentCategoryFail())),
          );
        },
      ),
    ),
  );

  deleteDocument$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DELETE_DOCUMENT),
      switchMap((action: { document: Document; options: DefaultGetOptions }) =>
        this.documentsService.delete(action.document.id).pipe(
          switchMap(() => [
            deleteDocumentSuccess(),
            loadRecentDocuments(),
            loadDocumentsForCategory({
              categoryId: action.document.category.id,
              options: {
                range: action?.options?.range || new PaginationRange(),
                sort: action?.options?.sort || {
                  active: 'created_at',
                  direction: 'desc',
                },
                search: action?.options?.search,
              },
            }),
          ]),
        ),
      ),
    ),
  );
}
