import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

export enum UpdatedResourceType {
  TRANSACTION,
  ADVANCED_EXPENSE,
}

export type UpdatedResource = {
  resourceType: UpdatedResourceType;
  resourceId: number;
};

@Injectable({
  providedIn: 'root',
})
export class UpdatedCheckmarkService {
  /**
   * Contains all the resources that should have the checkmark indicating it has been updated.
   * This is an array which enables to keep a data inside it until it should be removed and the checkmark hidden, in case
   * two data are updated in a short amount of time.
   */
  updatedResources$: BehaviorSubject<UpdatedResource[]> = new BehaviorSubject(
    null,
  );

  watchUpdatedResource(
    resourceType: UpdatedResourceType,
    resourceId: number,
  ): Observable<UpdatedResource> {
    return this.updatedResources$.pipe(
      map(resources =>
        resources?.find(
          resource =>
            resource.resourceType === resourceType &&
            resource.resourceId === resourceId,
        ),
      ),
    );
  }

  addUpdatedResource(resource: UpdatedResource): void {
    this.updatedResources$.next([
      ...(this.updatedResources$.getValue() || []),
      resource,
    ]);
    setTimeout(() => this.removeUpdatedResource(resource), 1000);
  }

  /**
   * Called after `addUpdatedResource(...)`, this will remove the resource from updatedResources$, so the checkmark will disappear
   * @param resource The resource that should be removed from the updatedResources$
   * @private
   */
  private removeUpdatedResource(resource: UpdatedResource) {
    const updatedResources: any[] = this.updatedResources$.getValue();

    updatedResources.forEach((item, index) => {
      if (
        item.resourceType === resource.resourceType &&
        item.resourceId === resource.resourceId
      ) {
        updatedResources.splice(index, 1);
      }
    });

    this.updatedResources$.next(updatedResources);
  }
}
