import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { filter, tap } from 'rxjs/operators';

import { Company } from '@models';

import { selectedCompanySelector } from '../store/user/user-selector';

type SettingValue = number | boolean | string | undefined;

export enum TransactionsSettingsKey {
  hide_refused = 'hide_refused',
}

export interface Settings {
  transactions: Record<TransactionsSettingsKey, SettingValue>;
}

@Injectable({
  providedIn: 'root',
})
export class SettingsService {
  private companyId: number;

  private static SETTINGS_KEY(companyId: number): string {
    return `tiime-apps-client-settings-${companyId}`;
  }

  constructor(private store: Store) {
    this.observeSelectedCompany();
  }

  setTransactionsSetting(
    settingKey: TransactionsSettingsKey,
    value: SettingValue,
  ): void {
    const settings: Settings =
      this.getSettings() || ({ transactions: {} } as Settings);
    settings.transactions[settingKey] = value;

    this.setSettings(settings);
  }

  getSettings(): Settings {
    return JSON.parse(
      localStorage.getItem(SettingsService.SETTINGS_KEY(this.companyId)),
    ) as Settings;
  }

  private observeSelectedCompany(): void {
    this.store
      .pipe(
        select(selectedCompanySelector),
        filter((company: Company) => !!company),
        tap((company: Company) => (this.companyId = company.id)),
      )
      .subscribe();
  }

  private setSettings(settings: Settings): void {
    const jsonSettings = JSON.stringify(settings);

    localStorage.setItem(
      SettingsService.SETTINGS_KEY(this.companyId),
      jsonSettings,
    );
  }
}
