import { Country, CountryApiContract } from './country';
import { Label, LabelApiContract } from './labels/label';

export interface ClientApiContract {
  id: number;
  name: string;
  address?: string;
  address_complement?: string;
  archived?: boolean;
  postal_code?: string;
  city?: string;
  color?: string;
  country?: CountryApiContract;
  country_code?: string;
  email?: string;
  phone?: string;
  intracom_vat_number?: string;
  balance_including_taxes?: number;
  historical_balance?: number;
  siren_or_siret?: string;
  slug?: string;
  label?: LabelApiContract;
  note?: string;
  invoicing_use_email?: boolean;
  professional?: boolean;
  iban?: string;
  bic?: string;
}

export class Client {
  constructor(
    public id?: number,
    public name?: string,
    public address?: string,
    public addressComplement?: string,
    public archived?: boolean,
    public postalCode?: string,
    public city?: string,
    public color?: string,
    public country?: Country,
    public countryCode?: string,
    public email?: string,
    public phone?: string,
    public intracomVatNumber?: string,
    public balanceIncludingTaxes?: number,
    public historicalBalance?: number,
    public sirenOrSiret?: string,
    public label?: Label,
    public note?: string,
    public slug?: string,
    public useEmail?: boolean,
    public isProfessional?: boolean,
    public iban?: string,
    public bic?: string,
  ) {}

  public static fromJson(json: ClientApiContract): Client {
    return new Client(
      json.id,
      json.name,
      json.address,
      json.address_complement,
      json.archived,
      json.postal_code,
      json.city,
      json.color,
      json.country ? Country.fromJson(json.country) : null,
      json.country_code,
      json.email,
      json.phone,
      json.intracom_vat_number,
      json.balance_including_taxes,
      json.historical_balance,
      json.siren_or_siret,
      json.label ? Label.fromJson(json.label) : null,
      json.note,
      json.slug,
      json.invoicing_use_email && !!json.email,
      json.professional,
      json.iban,
      json.bic,
    );
  }

  public static toJson(client: Client): Partial<ClientApiContract> {
    return {
      id: client.id || undefined,
      name: client.name,
      address: this.mapToJsonValue(client.address),
      address_complement: client.addressComplement,
      archived: client.archived,
      postal_code: this.mapToJsonValue(client.postalCode),
      city: this.mapToJsonValue(client.city),
      country: this.mapToCountryJsonValue(client.country),
      email: this.mapToJsonValue(client.email),
      phone: client.phone,
      intracom_vat_number: this.mapToJsonValue(
        client.intracomVatNumber,
        intracomVatNumber =>
          intracomVatNumber
            ? intracomVatNumber.toLocaleUpperCase().replace(/ /gi, '')
            : intracomVatNumber,
      ),
      siren_or_siret: this.mapToJsonValue(client.sirenOrSiret),
      label: this.mapToLabelJsonValue(client.label),
      historical_balance: client.historicalBalance,
      note: client.note,
      invoicing_use_email: client.useEmail,
      professional: client.isProfessional,
      iban: this.mapToJsonValue(client.iban),
      bic: this.mapToJsonValue(client.bic),
    };
  }

  private static mapToJsonValue(
    value: string,
    map?: (_: string) => string,
  ): string | null {
    if (value === '') {
      return null;
    }
    return map ? map(value) : value;
  }

  private static mapToCountryJsonValue(
    country?: Country,
  ): CountryApiContract | undefined | null {
    if (country === undefined) {
      return undefined;
    }
    return country?.id ? Country.toJson(country) : null;
  }

  private static mapToLabelJsonValue(
    label?: Label,
  ): LabelApiContract | undefined | null {
    if (label === undefined) {
      return undefined;
    }
    return label ? Label.toJson(label) : null;
  }
}
