import { Country } from './country';

export class DeliveryAddress {
  constructor(
    public address: string | null,
    public postalCode: string | null,
    public city: string | null,
    public country: Country | null,
  ) {}
}
