import { HttpClient, HttpParams, HttpResponse } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import {
  DefaultGetOptions,
  GetOptions,
  PaginationData,
  RequiredGetOptions,
} from 'tiime-components';

import { RegistryForm } from '@core/forms/registry-form';
import { TemporaryEncoder } from '@core/temporary-encoder';
import { ApiAlertError } from '@decorators/api-alert-error';
import { RegistryTypeEnum } from '@enums';
import { HttpHelper } from '@helpers';
import { Registry, RegistryApiContract, RegistryExportFormat } from '@models';

export type RegistryType = {
  value: RegistryTypeEnum;
  translation: string;
};

export type RegistryStat = {
  type: RegistryTypeEnum;
  amount: number;
};

@Injectable({
  providedIn: 'root',
})
export class RegistryService {
  private readonly http = inject(HttpClient);

  private readonly resourceUri = 'api/v1/companies/{companyId}/registries';

  @ApiAlertError()
  create(registry: Registry): Observable<RegistryForm> {
    return this.http
      .post(this.resourceUri, Registry.toJson(registry))
      .pipe(
        map(
          (registryJson: RegistryApiContract) =>
            new RegistryForm(Registry.fromJson(registryJson)),
        ),
      );
  }

  @ApiAlertError()
  getAllAsForm(
    getOptions: RequiredGetOptions<'range'>,
  ): Observable<PaginationData<RegistryForm>> {
    const partialOptions = new GetOptions(getOptions).toHttpGetOptions();
    const options = {
      params: new HttpParams({
        fromObject: partialOptions.params,
        encoder: new TemporaryEncoder(),
      }),
      headers: partialOptions.headers,
    };

    return this.http
      .get(this.resourceUri, { ...options, observe: 'response' })
      .pipe(
        HttpHelper.mapToPaginationData(
          getOptions.range,
          (registryJson: RegistryApiContract) =>
            new RegistryForm(Registry.fromJson(registryJson)),
        ),
      );
  }

  @ApiAlertError()
  patch(registryId: number, registry: Registry): Observable<Registry> {
    return this.http
      .patch(`${this.resourceUri}/${registryId}`, Registry.toJson(registry))
      .pipe(
        map((registryJson: RegistryApiContract) =>
          Registry.fromJson(registryJson),
        ),
      );
  }

  @ApiAlertError()
  delete(registryId: number): Observable<void> {
    return this.http.delete<void>(`${this.resourceUri}/${registryId}`);
  }

  @ApiAlertError()
  getRegistryExport(
    format: RegistryExportFormat,
    getOptions: DefaultGetOptions = {},
  ): Observable<HttpResponse<Blob>> {
    const partialOptions = new GetOptions(getOptions).toHttpGetOptions();
    const options = {
      params: new HttpParams({
        fromObject: partialOptions.params,
        encoder: new TemporaryEncoder(),
      }),
      headers: { 'Content-Type': `application/${format}` },
    };

    return this.http.get(`${this.resourceUri}/${format}`, {
      ...options,
      responseType: 'blob',
      observe: 'response',
    });
  }

  @ApiAlertError()
  getRegistryType(): Observable<RegistryType[]> {
    return this.http.get<RegistryType[]>('api/v1/registry_types');
  }

  @ApiAlertError()
  getStats(
    getOptions: Pick<DefaultGetOptions, 'filters' | 'search'>,
  ): Observable<RegistryStat[]> {
    const partialOptions = new GetOptions(getOptions).toHttpGetOptions();
    const options = {
      params: new HttpParams({
        fromObject: partialOptions.params,
        encoder: new TemporaryEncoder(),
      }),
    };
    return this.http.get<RegistryStat[]>(
      `${this.resourceUri}/statistics`,
      options,
    );
  }

  getTotal(
    getOptions: Pick<DefaultGetOptions, 'filters' | 'search'>,
  ): Observable<number> {
    return this.getStats(getOptions).pipe(
      map(statistics =>
        statistics.reduce((total, stat) => total + stat.amount, 0),
      ),
    );
  }
}
