import { NgModule } from '@angular/core';
import { MatDatepickerModule } from '@angular/material/datepicker';

import { IconsModule } from '@shared';

import { DatepickerComponent } from './datepicker.component';

@NgModule({
  exports: [DatepickerComponent],
  imports: [MatDatepickerModule, IconsModule],
  declarations: [DatepickerComponent],
})
export class DatepickerModule {}
