<div class="action-bar-wrapper">
  <div class="action-bar" #actionBar>
    <div class="left">
      <ng-container *ngIf="backButtonConfig">
        <a
          class="back-button"
          tiimeTooltip
          tooltipPosition="bottom"
          data-cy="action-bar__back-btn"
          [routerLink]="backButtonConfig.link"
          [queryParamsHandling]="backButtonConfig.queryParamsHandling"
          [content]="backButtonLinkTooltip"
        >
          <mat-icon
            inline="true"
            svgIcon="tc_icon-arrow-left"
            data-cy="action-bar__back--btn"
          ></mat-icon>
        </a>
      </ng-container>
      <ng-template #backButtonLinkTooltip>
        <div class="back-button-link-tooltip">
          {{ backButtonConfig.title }}
        </div>
      </ng-template>
      <div class="feature" [class.with-back-button]="backButtonConfig">
        <ng-content select="[action-bar-searchbar]"></ng-content>
        <ng-content select="[action-bar-title]"></ng-content>
      </div>
      <mat-progress-spinner
        *ngIf="loading"
        diameter="25"
        color="primary"
        mode="indeterminate"
      ></mat-progress-spinner>
      <tiime-auto-save [autoSaveData]="autoSaveData$ | async"></tiime-auto-save>
    </div>
    <ng-content select="[action-bar-content]"></ng-content>
    <div class="right" [class.hidden]="hideRightSection">
      <ng-content select="[action-bar-actions]"></ng-content>
      <ng-content select="[action-bar-more-actions]"></ng-content>
    </div>
  </div>
</div>
