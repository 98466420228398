import { HttpClient, HttpParams } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { BehaviorSubject, Observable, switchMap } from 'rxjs';
import { shareReplay } from 'rxjs/operators';
import { DefaultGetOptions, GetOptions } from 'tiime-components';

import { TemporaryEncoder } from '@core/temporary-encoder';
import { ApiAlertError } from '@decorators/api-alert-error';
import { Unit } from '@models';

@Injectable({ providedIn: 'root' })
export class InvoicingUnitsService {
  private readonly httpClient = inject(HttpClient);

  private readonly resourceUri = 'api/v1/companies/{companyId}/invoicing_units';

  private readonly refreshUnitsCache$ = new BehaviorSubject<void>(undefined);
  private readonly invoicingUnitsCache: Observable<Unit[]> =
    this.refreshUnitsCache$.pipe(
      switchMap(() => this.httpClient.get<Unit[]>(this.resourceUri)),
      shareReplay({ refCount: true, bufferSize: 1 }),
    );

  @ApiAlertError()
  getUnits(
    options:
      | DefaultGetOptions['search']
      | Pick<DefaultGetOptions, 'search' | 'filters'>
      | { fromCache: boolean } = {
      fromCache: true,
    },
  ): Observable<Unit[]> {
    if (typeof options === 'string') {
      return this.httpClient.get<Unit[]>(this.resourceUri, {
        params: { q: options },
      });
    }

    if ('search' in options) {
      return this.httpClient.get<Unit[]>(this.resourceUri, {
        params: new HttpParams({
          fromObject: new GetOptions(options).toHttpGetOptions().params,
          encoder: new TemporaryEncoder(),
        }),
      });
    }

    if ('fromCache' in options && !options.fromCache) {
      this.refreshUnitsCache$.next();
    }

    return this.invoicingUnitsCache;
  }

  @ApiAlertError()
  markFavorite(unitId: number): Observable<void> {
    return this.httpClient.post<void>(
      `${this.resourceUri}/${unitId}/favorite`,
      null,
    );
  }

  @ApiAlertError()
  unmarkFavorite(unitId: number): Observable<void> {
    return this.httpClient.delete<void>(
      `${this.resourceUri}/${unitId}/favorite`,
    );
  }
}
