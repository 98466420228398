export interface LogoApiContract {
  id: number;
  width?: number;
  height?: number;
}

export class Logo {
  constructor(
    public id: number,
    public width?: number,
    public height?: number,
  ) {}

  static fromJson(contract: LogoApiContract): Logo {
    return new Logo(contract.id, contract.width, contract.height);
  }
}
