import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { ApiAlertError } from '@decorators/api-alert-error';
import { CreatePaymentMethodContract, PaymentMethodContract } from '@services';

@Injectable({ providedIn: 'root' })
export class PaymentMethodService {
  private readonly http = inject(HttpClient);
  private readonly resourceUri = `api/v1/companies/{companyId}/payment_methods`;

  @ApiAlertError()
  create(
    contract: CreatePaymentMethodContract,
    companyId?: number,
  ): Observable<PaymentMethodContract> {
    const resourceUri = companyId
      ? this.resourceUri.replace('{companyId}', String(companyId))
      : this.resourceUri;
    return this.http.post<PaymentMethodContract>(
      `${resourceUri}/auth`,
      contract,
    );
  }

  @ApiAlertError()
  getAll(): Observable<PaymentMethodContract[]> {
    return this.http.get<PaymentMethodContract[]>(this.resourceUri);
  }

  @ApiAlertError()
  delete(paymentMethodId: number): Observable<unknown> {
    return this.http.delete(`${this.resourceUri}/${paymentMethodId}`);
  }
}
