import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { TiimeButtonModule, TiimeDialogModule } from 'tiime-components';

import { ConfirmationDialogBase } from './bases/confirmation-dialog.base';
import { ConfirmationDialogActionsComponent } from './components/confirmation-dialog-actions/confirmation-dialog-actions.component';
import { ConfirmationDialogContentComponent } from './components/confirmation-dialog-content/confirmation-dialog-content.component';
import { ConfirmationDialogData } from './confirmation-dialog.data';

@Component({
  standalone: true,
  imports: [
    CommonModule,
    TiimeDialogModule,
    TiimeButtonModule,
    ConfirmationDialogContentComponent,
    ConfirmationDialogActionsComponent,
  ],
  templateUrl: './confirmation-dialog.component.html',
  styleUrls: [
    './bases/confirmation-dialog.base.scss',
    './confirmation-dialog.component.scss',
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ConfirmationDialogComponent extends ConfirmationDialogBase<ConfirmationDialogData> {
  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: ConfirmationDialogData,
    private dialogRef: MatDialogRef<
      ConfirmationDialogComponent,
      boolean | undefined
    >,
  ) {
    super(data);
  }

  confirm(): void {
    this.dialogRef.close(true);
  }

  decline(): void {
    this.dialogRef.close(false);
  }
}
