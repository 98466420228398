import { FormControl, Validators } from '@angular/forms';

const FIRST_NUMBER_MIN = 1;
const FIRST_NUMBER_MAX = 999999;

export class InvoicingDocumentFirstNumberFormControl extends FormControl {
  get errorMessage(): string | null {
    if (!this.touched) {
      return null;
    }
    if (this.hasError('required')) {
      return 'Requis';
    }
    if (this.hasError('min') || this.hasError('max')) {
      return `Compris entre ${FIRST_NUMBER_MIN} et ${FIRST_NUMBER_MAX}`;
    }
    if (this.hasError('pattern')) {
      return 'Ne doit pas commencer par 0';
    }
    return null;
  }

  constructor() {
    super(null, [
      Validators.required,
      Validators.min(FIRST_NUMBER_MIN),
      Validators.max(FIRST_NUMBER_MAX),
      Validators.pattern(/^[1-9]\d*$/),
    ]);
  }
}
