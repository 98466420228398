import { Injectable, inject } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Observable } from 'rxjs';

import { User } from '@models/user';

import {
  CreatePasswordDialogComponent,
  CreatePasswordDialogData,
  CreatePasswordDialogResponse,
} from './create-password-dialog.component';

@Injectable({
  providedIn: 'root',
})
export class CreatePasswordDialogService {
  private readonly dialog = inject(MatDialog);

  open(user: User): Observable<CreatePasswordDialogResponse> {
    return this.dialog
      .open<
        CreatePasswordDialogComponent,
        CreatePasswordDialogData,
        CreatePasswordDialogResponse
      >(CreatePasswordDialogComponent, {
        data: { user },
        panelClass: 'tiime-dialog-panel',
      })
      .beforeClosed();
  }
}
