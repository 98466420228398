import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { FormControl, ReactiveFormsModule, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { TiimeDialogModule, TiimeInputContainerModule } from 'tiime-components';

import { ConfirmationDialogBase } from '../bases/confirmation-dialog.base';
import { ConfirmationDialogActionsComponent } from '../components/confirmation-dialog-actions/confirmation-dialog-actions.component';
import { ConfirmationDialogContentComponent } from '../components/confirmation-dialog-content/confirmation-dialog-content.component';
import { ConfirmationDialogWithMessageData } from './confirmation-dialog-with-message-data';

@Component({
  standalone: true,
  imports: [
    CommonModule,
    TiimeDialogModule,
    ConfirmationDialogActionsComponent,
    ConfirmationDialogContentComponent,
    TiimeInputContainerModule,
    ReactiveFormsModule,
  ],
  templateUrl: './confirmation-dialog-with-message.component.html',
  styleUrls: [
    './confirmation-dialog-with-message.component.scss',
    '../bases/confirmation-dialog.base.scss',
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ConfirmationDialogWithMessageComponent extends ConfirmationDialogBase<ConfirmationDialogWithMessageData> {
  readonly control = new FormControl<string>(null, Validators.required);

  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: ConfirmationDialogWithMessageData,
    private dialogRef: MatDialogRef<
      ConfirmationDialogWithMessageComponent,
      string | undefined
    >,
  ) {
    super(data);
  }

  confirm(): void {
    this.dialogRef.close(this.control.value);
  }

  decline(): void {
    this.dialogRef.close();
  }
}
