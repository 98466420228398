import {
  Component,
  Inject,
  ChangeDetectorRef,
  OnDestroy,
  ChangeDetectionStrategy,
} from '@angular/core';
import {
  DateAdapter,
  MAT_DATE_FORMATS,
  MatDateFormats,
} from '@angular/material/core';
import { MatCalendar } from '@angular/material/datepicker';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'tiime-datepicker-header',
  templateUrl: './datepicker-header.component.html',
  styleUrls: ['./datepicker-header.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DatepickerHeaderComponent<D> implements OnDestroy {
  private destroyed = new Subject<void>();
  private readonly yearsPerPage = 24;

  constructor(
    public calendar: MatCalendar<D>,
    private dateAdapter: DateAdapter<D>,
    @Inject(MAT_DATE_FORMATS) private dateFormats: MatDateFormats,
    cdr: ChangeDetectorRef,
  ) {
    calendar.stateChanges
      .pipe(takeUntil(this.destroyed))
      .subscribe(() => cdr.markForCheck());
  }

  ngOnDestroy(): void {
    this.destroyed.next();
    this.destroyed.complete();
  }

  get periodButtonText(): string {
    if (this.calendar.currentView === 'month') {
      return this.dateAdapter
        .format(
          this.calendar.activeDate,
          this.dateFormats.display.monthYearLabel,
        )
        .toLocaleLowerCase();
    }
    if (this.calendar.currentView === 'year') {
      return this.dateAdapter.getYearName(this.calendar.activeDate);
    }
    const activeYear = this.dateAdapter.getYear(this.calendar.activeDate);
    const firstYearInView = this.getFirstYearInView(activeYear);
    const lastYearInView = this.getLastYearInView(activeYear);
    return `${firstYearInView} \u2013 ${lastYearInView}`;
  }

  previousClicked(): void {
    if (!this.previousEnabled()) {
      return;
    }

    this.processCalendarActiveData(true);
  }

  nextClicked(): void {
    if (!this.nextEnabled()) {
      return;
    }

    this.processCalendarActiveData();
  }

  currentPeriodClicked(): void {
    this.calendar.currentView =
      this.calendar.currentView === 'month' ? 'multi-year' : 'month';
  }

  previousEnabled(): boolean {
    if (!this.calendar.minDate) {
      return true;
    }
    return (
      !this.calendar.minDate ||
      !this.isSameView(this.calendar.activeDate, this.calendar.minDate)
    );
  }

  nextEnabled(): boolean {
    return (
      !this.calendar.maxDate ||
      !this.isSameView(this.calendar.activeDate, this.calendar.maxDate)
    );
  }

  private getFirstYearInView(activeYear: number): number {
    let firstYearInView = this.dateAdapter.createDate(
      activeYear - (activeYear % this.yearsPerPage),
      0,
      1,
    );
    if (this.calendar.minDate) {
      firstYearInView =
        this.dateAdapter.compareDate(firstYearInView, this.calendar.minDate) < 0
          ? this.calendar.minDate
          : firstYearInView;
    }

    return this.dateAdapter.getYear(firstYearInView);
  }

  private getLastYearInView(activeYear: number): number {
    let lastYearInView = this.dateAdapter.createDate(
      activeYear + this.yearsPerPage - 1 - (activeYear % this.yearsPerPage),
      0,
      1,
    );
    if (this.calendar.maxDate) {
      lastYearInView =
        this.dateAdapter.compareDate(lastYearInView, this.calendar.maxDate) > 0
          ? this.calendar.maxDate
          : lastYearInView;
    }

    return this.dateAdapter.getYear(lastYearInView);
  }

  private isSameView(date1: D, date2: D): boolean {
    if (this.calendar.currentView === 'month') {
      return (
        this.dateAdapter.getYear(date1) === this.dateAdapter.getYear(date2) &&
        this.dateAdapter.getMonth(date1) === this.dateAdapter.getMonth(date2)
      );
    }
    if (this.calendar.currentView === 'year') {
      return (
        this.dateAdapter.getYear(date1) === this.dateAdapter.getYear(date2)
      );
    }
    return (
      Math.floor(this.dateAdapter.getYear(date1) / this.yearsPerPage) ===
      Math.floor(this.dateAdapter.getYear(date2) / this.yearsPerPage)
    );
  }

  private processCalendarActiveData(isFromPreviousButtonClick = false): void {
    const modifier = isFromPreviousButtonClick ? -1 : 1;

    if (this.calendar.currentView === 'month') {
      this.calendar.activeDate = this.dateAdapter.addCalendarMonths(
        this.calendar.activeDate,
        modifier,
      );
    } else {
      this.calendar.activeDate = this.dateAdapter.addCalendarYears(
        this.calendar.activeDate,
        this.calendar.currentView === 'year'
          ? modifier
          : modifier * this.yearsPerPage,
      );
    }
  }
}
