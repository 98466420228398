import {
  Attribute,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  HostBinding,
  Input,
  Optional,
  Output,
  Self,
  ViewChild,
  inject,
} from '@angular/core';
import { ControlValueAccessor, NgControl } from '@angular/forms';
import { TrackingService } from '@manakincubber/tiime-tracking';
import { BehaviorSubject } from 'rxjs';
import { tap } from 'rxjs/operators';

import {
  LabelRuleCreationStarted,
  TransactionAddLabelButtonClicked,
} from '@core/amplitude';
import { LabelTypeEnum } from '@enums';
import { Document, DocumentTypeEnum, Tag } from '@models';
import { BankTransaction } from '@models/bank-transaction';
import { Label, LabelOrTag, LabelUpdateEvent } from '@models/labels';

import { DatasetIndicatorsSearchService } from '../../../home/indicators/dashboards/dashboards-shared/dashboard-customization/dataset-indicators-search.service';
import {
  LabelSelectionOverlayService,
  LabelSelectionResponseEnum,
} from './label-selection-overlay';

/**
 * Dans ce composant, il est possible de se retrouver avec 2 entités différentes : les labels et les tags.
 * Lorsque la création d'un label n'est pas autorisée, l'utilisateur peut créer des tags à la place.
 * Il existe certains cas dans l'application, lorsque l'utilisateur clique sur "Créer le tag XXX", qu'on veuille
 * afficher une chip (voir le booléen `showTagChip`) en rapport à celui-ci et nous devons donc conserver cette valeur.
 * Ce composant prend néanmoins seulement un label en entrée, le cas du tag n'ayant pas sa place ici.
 */

@Component({
  selector: 'app-label-selector',
  templateUrl: './label-selector.component.html',
  styleUrls: ['./label-selector.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LabelSelectorComponent implements ControlValueAccessor {
  @ViewChild('labelChip', { read: ElementRef, static: false }) chip: ElementRef;

  @Input() labels: Label[] = [];
  @Input() set value(value: Label) {
    this.value$.next(value);
  }

  @Input() xsmall = true;
  @Input() bankTransaction: BankTransaction;
  @Input() document: Document;
  @Input() disableUnmatch: boolean;
  @Input() disableLastUsedLabel: boolean;
  @Input() enableVentilation = false;
  @Input() enableRuleCreation = false;
  @Input() enableTagCreation = false;
  @Input() disabled: boolean;
  @Input() isReadOnly: boolean;
  @Input() isInvalid: boolean;
  @Input() isExternalInvoices = false;
  @Input() labelCreationEnabled: boolean;
  @Input() labelSelectionWording = 'Ajouter un label';
  @Input() showTagChip = false;

  @HostBinding('class.dashed-when-empty')
  @Input()
  dashedWhenEmpty = false;

  @Output()
  readonly valueChange = new EventEmitter<LabelUpdateEvent>();
  @Output()
  readonly openImputationsOverlay = new EventEmitter<void>();
  @Output()
  readonly openRuleDialog = new EventEmitter<void>();
  @Output()
  readonly tagCreation = new EventEmitter<string>();

  readonly value$ = new BehaviorSubject<LabelOrTag>(null);

  private readonly trackingService = inject(TrackingService);

  onChange: (_: LabelOrTag) => void = () => {
    // Will be overridden by registerOnChange(...)
  };

  onTouched: () => void = () => {
    // Will be overridden by registerOnTouched(...)
  };

  constructor(
    @Self() @Optional() public ngControl: NgControl,
    @Attribute('accent') private readonly accent: boolean,
    private readonly datasetIndicatorsSearchService: DatasetIndicatorsSearchService,
    private readonly labelSelectionOverlayService: LabelSelectionOverlayService,
    private elementRef: ElementRef,
    private readonly cdr: ChangeDetectorRef,
  ) {
    if (this.ngControl) {
      // Note: we provide the value accessor through here, instead of
      // the `providers` to avoid running into a circular import.
      this.ngControl.valueAccessor = this;
    }
  }

  openLabelSelectionOverlay($event?: KeyboardEvent): void {
    if ($event && $event.key === 'Tab') {
      return;
    }
    if (!this.disabled && !this.isReadOnly) {
      this.labelSelectionOverlayService
        .openLabelSelectionOverlay(
          {
            transactionId: this.bankTransaction?.id,
            documentId: this.document?.id,
            disableLastUsedLabel: this.disableLastUsedLabel,
            labelCreationEnabled:
              this.document?.type === DocumentTypeEnum.ExternalInvoice ||
              this.labelCreationEnabled,
            labelCreationType:
              this.isExternalInvoices ||
              this.document?.type === DocumentTypeEnum.ExternalInvoice
                ? LabelTypeEnum.client
                : LabelTypeEnum.other,
            enableVentilation: this.enableVentilation,
            enableRuleCreation: this.enableRuleCreation,
            labels: this.labels,
            enableTagCreation: this.enableTagCreation,
          },
          this.elementRef,
        )
        .pipe(
          tap(response => {
            switch (response.action) {
              case LabelSelectionResponseEnum.OPEN_RULE_DIALOG:
                this.trackingService.dispatch(new LabelRuleCreationStarted());
                this.openRuleDialog.emit();
                break;
              case LabelSelectionResponseEnum.OPEN_VENTILATIONS_OVERLAY:
                this.openImputationsOverlay.emit();
                break;
              case LabelSelectionResponseEnum.CREATE_TAG:
                this.tagCreation.emit(response.tag);
                this.updateSelectedEntity(new Tag(undefined, response.tag));
                break;
              case LabelSelectionResponseEnum.SELECT_LABEL:
                this.updateSelectedEntity(
                  response.label,
                  response.labelCreated,
                );
                break;
              default:
                break;
            }
            setTimeout(() => {
              if (
                response.focusSelector &&
                this.chip &&
                this.chip.nativeElement
              ) {
                this.chip.nativeElement.focus();
              }
            });
          }),
        )
        .subscribe();
      if (this.bankTransaction) {
        this.trackingService.dispatch(new TransactionAddLabelButtonClicked());
      }
    }
  }

  writeValue(value: LabelOrTag | null): void {
    this.value$.next(value);
  }

  updateSelectedEntity(value?: LabelOrTag, labelCreated?: boolean): void {
    this.datasetIndicatorsSearchService.clearIndicators();
    this.value$.next(value);
    this.valueChange.emit({ value, labelCreated });
    this.onChange(value);
    this.markAsTouched();
  }

  registerOnChange(fn: (_: Label) => void): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  private markAsTouched(): void {
    if (!this.ngControl?.touched) {
      this.onTouched();
    }
  }
}
