import { createSelector, MemoizedSelector } from '@ngrx/store';
import { PaginationData } from 'tiime-components';

import { Document } from '@models';

import { AppState } from '../app-state';
import { DocumentsState } from './documents.reducers';

export const documentsSelector = (state: AppState): DocumentsState => {
  return state.documents;
};

export const documentByIdSelector = (
  id: number,
): MemoizedSelector<AppState, Document | null> =>
  createSelector(documentsSelector, state => {
    for (const category in state) {
      const document = state[category]?.documents?.data.find(
        doc => doc.id === id,
      );
      if (document) {
        return document;
      }
    }
    return null;
  });

export const documentsByCategorySelector = (
  id: number | number[],
): MemoizedSelector<AppState, PaginationData<Document>> =>
  createSelector(documentsSelector, state => {
    if (!state) {
      return null;
    }
    if (Array.isArray(id)) {
      const ids = id.map(categoryId => categoryId.toString()).join(',');
      return state[ids]?.documents;
    } else {
      return state[id]?.documents;
    }
  });

export const documentsAreLoadingSelector = (
  id: number,
): MemoizedSelector<AppState, boolean> =>
  createSelector(documentsSelector, state => state && !state[id]);

export const recentDocumentsSelector = createSelector(
  documentsSelector,
  state => state && state?.recent?.documents,
);

export const searchedDocumentsSelector = createSelector(
  documentsSelector,
  state => state && state?.search,
);
