import { Injectable } from '@angular/core';

import { WindowService } from '@core';

@Injectable({
  providedIn: 'root',
})
export class FileService {
  constructor(private readonly windowService: WindowService) {}

  openInNewTab(file: Blob): void {
    const documentBlobUrl: string = URL.createObjectURL(file);
    this.windowService.openInNewTab(documentBlobUrl);
  }
}
