import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import moment from 'moment';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { ApiAlertError } from '@decorators/api-alert-error';
import { DocumentDeclaredUnavailableReason } from '@matching/documents-matching-menu-button/no-document-dialog/no-document-reason';
import {
  AccountantDetailRequest,
  AccountantDetailRequestApiContract,
} from '@models';

@Injectable({
  providedIn: 'root',
})
export class AccountantDetailRequestsService {
  private readonly resource = 'api/v1/companies/{companyId}/bank_transactions';

  constructor(private readonly http: HttpClient) {}

  @ApiAlertError()
  get(
    bankTransactionId: number,
    requestId: number,
  ): Observable<AccountantDetailRequest> {
    return this.http
      .get<AccountantDetailRequestApiContract>(
        `${this.resource}/${bankTransactionId}/accountant_detail_requests/${requestId}`,
      )
      .pipe(map(request => AccountantDetailRequest.fromJson(request)));
  }

  @ApiAlertError()
  declareDocumentUnavailable(
    bankTransactionId: number,
    requestId: number,
    documentDeclaredUnavailableReason: DocumentDeclaredUnavailableReason,
    messageContent: string,
  ): Observable<AccountantDetailRequest> {
    return this.http
      .patch<AccountantDetailRequestApiContract>(
        `${this.resource}/${bankTransactionId}/accountant_detail_requests/${requestId}/document_declared_unavailable`,
        {
          document_declared_unavailable_at: moment().utc().format(),
          document_declared_unavailable_reason:
            documentDeclaredUnavailableReason,
          message_content: messageContent,
        },
        {
          headers: new HttpHeaders({
            Accept:
              'application/vnd.tiime.accountant_request.document_unavailable.v2+json',
          }),
        },
      )
      .pipe(map(request => AccountantDetailRequest.fromJson(request)));
  }
}
