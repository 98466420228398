import {
  ChangeDetectionStrategy,
  Component,
  HostBinding,
  Input,
} from '@angular/core';

import { AbstractTiimeChipDirective } from '../../../chip';
import { ChipFilter } from '../../models/chip-filter';

@Component({
  selector: 'tiime-filter-chip',
  templateUrl: './filter-chip.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FilterChipComponent extends AbstractTiimeChipDirective {
  @Input() filter: ChipFilter;

  @HostBinding('class.advanced-filter') get classAdvancedFilter(): boolean {
    return this.filter.apiFilter.key !== 'q';
  }
}
