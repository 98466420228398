<div
  class="advanced-expense"
  [class.advanced-expense--checked]="!!editorLineForm.checked.value"
  [class.advanced-expense--disabled]="disabled"
  [class.advanced-expense--line-disabled]="disableLineAction"
  [class.advanced-expense--incomplete]="isIncomplete"
  [formGroup]="editorLineForm"
  (click)="this.lineClicked.emit(disabled)"
>
  <ng-container *ngIf="disabled; else checkbox">
    <div class="advanced-expense__preview">
      <button tiime-button xsmall icon neutral inverted>
        <mat-icon svgIcon="icon-eye"></mat-icon>
      </button>
    </div>
  </ng-container>
  <ng-template #checkbox>
    <mat-checkbox
      class="advanced-expense__checkbox"
      large
      formControlName="checked"
      (change)="lineChecked.emit($event.checked)"
      (click)="$event.stopPropagation()"
    ></mat-checkbox>
  </ng-template>

  <div
    class="advanced-expense__details-container"
    [class.advanced-expense__details-container--checked]="
      !!editorLineForm.checked.value
    "
    [class.advanced-expense__details-container--disabled]="disabled"
  >
    <ng-container
      *ngFor="let cellDefinition of cellDefinitions; trackBy: trackByIndex"
    >
      <ng-container
        *tiimeLet="
          editorLineForm.line.value
            | map: mapToCellValue : cellDefinition as value
        "
      >
        <div
          role="cell"
          [class]="
            'advanced-expense__details-container__cell advanced-expense__details-container__' +
            (cellDefinition.containerClass || cellDefinition.formControlName)
          "
          [class.advanced-expense__details-container__cell--incomplete]="
            value | map: mapToIsCellIncomplete : cellDefinition
          "
        >
          <mat-icon fontIcon="icon-alerte-input"></mat-icon>

          <ng-container *ngTemplateOutlet="formattedValue"></ng-container>
        </div>
        <ng-template #formattedValue>
          <div
            tiimeTooltip
            hideWithoutEllipsis
            tooltipPosition="bottom"
            [content]="formattedValue"
          >
            <ng-container [ngSwitch]="cellDefinition.type">
              <ng-container *ngSwitchCase="'date'">
                {{ value | tiimeDatetime: 'dd/MM/yyyy' }}
              </ng-container>
              <ng-container *ngSwitchCase="'currency'">
                {{ value | currency: editorLineForm.line.value.currency }}
              </ng-container>
              <ng-container *ngSwitchCase="'number'">
                {{ value | number }} {{ cellDefinition.customSuffix }}
              </ng-container>
              <ng-container *ngSwitchDefault>
                {{ value }} {{ cellDefinition.customSuffix }}
              </ng-container>
            </ng-container>
          </div>
        </ng-template>
      </ng-container>
    </ng-container>
  </div>

  <div *ngIf="!disableLineAction" class="advanced-expense__menu">
    <button tiime-button xsmall icon neutral inverted>
      <mat-icon fontIcon="icon-ic-modify"></mat-icon>
    </button>
  </div>
</div>
