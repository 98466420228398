import {
  ComputedVehicleResponse,
  ComputedVehicleResponseApiContract,
} from './compute-vehicle-response';

export interface ComputeTravelsAmountApiContract {
  amount: number;
  compute_vehicle_responses: ComputedVehicleResponseApiContract[];
}

export class ComputedTravelsAmount {
  constructor(
    public amount: number,
    public computedVehicleResponses: ComputedVehicleResponse[],
  ) {}

  static fromJson(
    jsonApiContract: ComputeTravelsAmountApiContract,
  ): ComputedTravelsAmount {
    return new ComputedTravelsAmount(
      jsonApiContract.amount,
      jsonApiContract.compute_vehicle_responses.map(res =>
        ComputedVehicleResponse.fromJson(res),
      ),
    );
  }
}
