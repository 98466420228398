import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { CanMatchTransactionPipe } from './can-match-transaction.pipe';
import { CanPayDocumentPipe } from './can-pay-document.pipe';
import { DocumentAmountCurrencyPipe } from './document-amount-currency.pipe';
import { DocumentAmountPipe } from './document-amount.pipe';
import { DocumentDatePipe } from './document-date.pipe';
import { DocumentMetadataCurrencyPipe } from './document-metadata-currency.pipe';
import { DocumentPreTaxAmountPipe } from './document-pre-tax-amount.pipe';
import { DocumentVatAmountPipe } from './document-vat-amount.pipe';
import { DocumentsComplexFiltersPipe } from './documents-complex-filters.pipe';
import { FilterLinkedEntitiesPipe } from './filter-linked-entities.pipe';
import { MetadataFormGroupPipe } from './metadata-formgroup.pipe';
import { MetadataPriorityPipe } from './metadata-priority.pipe';
import { MetadataTotalAmountPipe } from './metadata-total-amount.pipe';
@NgModule({
  declarations: [
    CanPayDocumentPipe,
    DocumentAmountPipe,
    DocumentVatAmountPipe,
    DocumentDatePipe,
    DocumentMetadataCurrencyPipe,
    DocumentsComplexFiltersPipe,
    FilterLinkedEntitiesPipe,
    DocumentAmountCurrencyPipe,
    DocumentPreTaxAmountPipe,
    MetadataTotalAmountPipe,
    MetadataFormGroupPipe,
    MetadataPriorityPipe,
  ],
  imports: [CommonModule, CanMatchTransactionPipe],
  exports: [
    CanPayDocumentPipe,
    DocumentAmountPipe,
    DocumentVatAmountPipe,
    DocumentDatePipe,
    DocumentMetadataCurrencyPipe,
    DocumentsComplexFiltersPipe,
    FilterLinkedEntitiesPipe,
    DocumentAmountCurrencyPipe,
    DocumentPreTaxAmountPipe,
    MetadataTotalAmountPipe,
    MetadataFormGroupPipe,
    MetadataPriorityPipe,
    CanMatchTransactionPipe,
  ],
})
export class DocumentsPipesModule {}
