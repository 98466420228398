import {
  ChangeDetectionStrategy,
  Component,
  Input,
  ViewEncapsulation,
} from '@angular/core';

@Component({
  selector: 'tiime-action-bar-content-placeholder',
  templateUrl: './action-bar-content-placeholder.component.html',
  styleUrls: ['./action-bar-content-placeholder.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ActionBarContentPlaceholderComponent {
  @Input() hideLeftPlaceholder = false;
  @Input() hideMiddlePlaceholder = false;
  @Input() hideRightPlaceholder = false;
}
