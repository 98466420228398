/**
 * Default CSS selectors to define which elements on the page to track.
 * Extend this list to include additional elements to track. For example:
 * ```
 * autocapturePlugin({
 *    cssSelectorAllowlist: [...DEFAULT_CSS_SELECTOR_ALLOWLIST, ".my-class"],
 * })
 * ```
 */
export var DEFAULT_CSS_SELECTOR_ALLOWLIST = ['a', 'button', 'input', 'select', 'textarea', 'label', '[data-amp-default-track]', '.amp-default-track'];
/**
 * Default prefix to allo the plugin to capture data attributes as an event property.
 */
export var DEFAULT_DATA_ATTRIBUTE_PREFIX = 'data-amp-track-';
