import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';

import { TIIME_ICON_PREFIX } from '../../icons';

@Component({
  standalone: true,
  imports: [CommonModule, MatIconModule],
  selector: 'app-error-banner',
  templateUrl: './error-banner.component.html',
  styleUrl: './error-banner.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ErrorBannerComponent {
  readonly TIIME_ICON_PREFIX = TIIME_ICON_PREFIX;

  @Input() title: string;
  @Input() subtitle: string;
}
