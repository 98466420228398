<div class="content-wrapper">
  <ng-content select="[label]"></ng-content>
</div>
<button
  class="expand-options"
  data-cy="dropdown-button_expand-button"
  #menuTrigger="matMenuTrigger"
  #dropdown
  [matMenuTriggerFor]="options"
>
  <mat-icon fontIcon="icon-ic-arrow-bottom-1"></mat-icon>
</button>
<mat-menu class="options-menu-button" xPosition="before" #options>
  <div [style.width.px]="hostWidth">
    <ng-content select="button"></ng-content>
  </div>
</mat-menu>
