<div *ngIf="showLastUsedLabels" class="last-used-label">
  <span
    *ngIf="lastUsedLabels?.length"
    data-cy="last-used-labels__txt-last-used-labels"
  >
    {{ lastUsedLabels | lastUsedLabelsText: shouldDisplaySuggestions }}
  </span>
  <div *ngIf="lastLabelsLoaded" class="last-used-container">
    <app-label-chip
      *ngFor="let lastUsedLabel of lastUsedLabels; trackBy: trackById"
      data-cy="last-label-used"
      [label]="lastUsedLabel"
      (click)="selectedLabel.emit(lastUsedLabel)"
    ></app-label-chip>
  </div>
  <div *ngIf="!lastLabelsLoaded" class="loading-state">
    <div
      *ngFor="let number of [0, 1, 2, 3]; trackBy: trackById"
      [class]="'empty-label-' + number"
    ></div>
  </div>
</div>
