import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { IconsModule } from '../../icons/icons.module';
import { FormGenericErrorMessageComponent } from './form-generic-error-message.component';

@NgModule({
  declarations: [FormGenericErrorMessageComponent],
  imports: [CommonModule, IconsModule],
  exports: [FormGenericErrorMessageComponent],
})
export class FormGenericErrorMessageModule {}
