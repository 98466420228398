import {
  InvoiceStatus,
  InvoiceStatusUpdatedBy,
  InvoiceType,
  InvoicingTemplate,
} from '@enums';
import {
  LinkedBankTransaction,
  Tag,
  TagApiContract,
  TextLine,
  TextLineApiContract,
  TotalsPerVatType,
} from '@models';

import { AbstractInvoicingDocumentListItem } from '../bases/abstract-invoicing-document-list-item';
import { InvoiceSchedule } from './invoice-schedule';
import { Line, LineApiContract } from './line';
import {
  linkedEntityFromJson,
  LinkedEntityImputation,
} from './linked-entities';
import { LinkedEntityApiContract } from './linked-entities/api-contracts';

export class InvoiceListItem extends AbstractInvoicingDocumentListItem {
  get hasBankTransactionsOrImputations(): boolean {
    return this.countBankTransactions > 0 || this.countImputations > 0;
  }

  get countBankTransactionsAndImputations(): number {
    return this.countBankTransactions + this.countImputations;
  }

  get bankTransactionsAndImputations(): (
    | LinkedBankTransaction
    | LinkedEntityImputation
  )[] {
    return [...(this.bankTransactions || []), ...(this.imputations || [])];
  }

  constructor(
    id?: number,
    title?: string,
    invoiceNumber?: number,
    emissionDate?: string,
    clientId?: number,
    clientName?: string,
    totalExcludingTaxes?: number,
    totalIncludingTaxes?: number,
    lines?: Line[],
    textLines?: TextLine[],
    color?: string,
    template?: InvoicingTemplate,
    comment?: string,
    tags?: Tag[],
    unitsEnabled?: boolean,
    public status?: InvoiceStatus,
    public statusUpdatedBy?: InvoiceStatusUpdatedBy,
    public type?: InvoiceType,
    public countBankTransactions?: number,
    public bankTransactions?: LinkedBankTransaction[],
    public countImputations?: number,
    public imputations?: LinkedEntityImputation[],
    public creditNoteLinkedCompiledNumber?: string,
    public invoiceLinkedCompiledNumber?: string,
    public invoiceSchedule?: InvoiceSchedule,
    public totalsPerVatType?: TotalsPerVatType,
  ) {
    super(
      id,
      title,
      invoiceNumber,
      emissionDate,
      totalExcludingTaxes,
      totalIncludingTaxes,
      lines,
      textLines,
      color,
      template,
      comment,
      tags,
      unitsEnabled,
      clientId,
      clientName,
    );
  }

  public static fromJson(json: any): InvoiceListItem {
    return new InvoiceListItem(
      json.id,
      json.title,
      json.number,
      json.emission_date,
      json.client_id,
      json.client_name,
      json.total_excluding_taxes,
      json.total_including_taxes,
      json.lines
        ? json.lines.map((lineJson: LineApiContract) => Line.fromJson(lineJson))
        : [],
      json.text_lines
        ? json.text_lines.map((textLineJson: TextLineApiContract) =>
            TextLine.fromJson(textLineJson),
          )
        : [],
      json.color,
      json.template,
      json.comment,
      json.tags
        ? json.tags.map((tagJson: TagApiContract) => Tag.fromJson(tagJson))
        : [],
      json.units_enabled,
      json.status,
      json.status_updated_by,
      json.total_excluding_taxes < 0
        ? InvoiceType.creditNote
        : InvoiceType.invoice,
      json.count_bank_transactions,
      json.bank_transactions
        ? json.bank_transactions.map((bankTransactionJson: any) =>
            LinkedBankTransaction.fromJson(bankTransactionJson),
          )
        : [],
      json.count_imputations,
      json.imputations
        ? json.imputations.map((entity: LinkedEntityApiContract) =>
            linkedEntityFromJson(entity),
          )
        : [],
      json.credit_note_linked_compiled_number,
      json.invoice_linked_compiled_number,
      json.invoice_schedule && InvoiceSchedule.fromJson(json.invoice_schedule),
      json.totals_per_vat_type
        ? TotalsPerVatType.fromJson(json.totals_per_vat_type)
        : null,
    );
  }
}
