import { CompanyRoleEnum } from '@enums/users';

import {
  PermissionAttribute,
  PermissionAttributeApiContract,
} from './permission-attribute';

export interface RoleBasedPermissionApiContract {
  uuid: string;
  role: CompanyRoleEnum;
  permission_attributes: PermissionAttributeApiContract[];
}

export class RoleBasedPermission {
  constructor(
    public uuid: string,
    public role: CompanyRoleEnum,
    public permissionAttributes: PermissionAttribute[],
  ) {}

  static fromJson(json: RoleBasedPermissionApiContract): RoleBasedPermission {
    return new RoleBasedPermission(
      json.uuid,
      json.role,
      json.permission_attributes.map(attr =>
        PermissionAttribute.fromJson(attr),
      ),
    );
  }
}
