import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatChipsModule } from '@angular/material/chips';
import { TiimeAutoFocusModule, TiimeLetModule } from 'tiime-components';

import { IconsModule, ScrollShadowsModule, TagChipModule } from '@shared';

import { DisplayableTagsPipe } from '../../pipes/tags/displayable-tags.pipe';
import { IsTagInSomeListPipe } from './is-tag-in-some-list.pipe';
import { TagSelectorFormInputComponent } from './tag-selector-form-input.component';

@NgModule({
  declarations: [TagSelectorFormInputComponent, IsTagInSomeListPipe],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    MatAutocompleteModule,
    MatChipsModule,
    IconsModule,
    TagChipModule,
    TiimeAutoFocusModule,
    TiimeLetModule,
    TiimeAutoFocusModule,
    ScrollShadowsModule,
    DisplayableTagsPipe,
  ],
  exports: [TagSelectorFormInputComponent],
})
export class TagSelectorFormInputModule {}
