import {
  HttpClient,
  HttpContext,
  HttpEvent,
  HttpEventType,
  HttpHeaders,
  HttpParams,
  HttpProgressEvent,
  HttpResponse,
} from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { filter, map, tap } from 'rxjs/operators';

import { FileService } from '@core/file.service';
import { DocumentUploadOptions } from '@core/models/documents/document-upload-options';
import { ReceiptSource } from '@enums';
import { HttpHelper } from '@helpers';

type DownloadOptions = {
  headers?:
    | HttpHeaders
    | {
        [header: string]: string | string[];
      };
  params?:
    | HttpParams
    | {
        [param: string]:
          | string
          | number
          | boolean
          | ReadonlyArray<string | number | boolean>;
      };
  context?: HttpContext;
};

@Injectable({
  providedIn: 'root',
})
export class HttpFileService {
  private readonly fileService = inject(FileService);
  private readonly http = inject(HttpClient);

  download(
    source: Observable<HttpResponse<Blob>>,
  ): Observable<HttpResponse<Blob>>;
  download(
    source: string,
    options?: DownloadOptions,
  ): Observable<HttpResponse<Blob>>;
  download(
    source: string | Observable<HttpResponse<Blob>>,
    options?: DownloadOptions,
  ): Observable<HttpResponse<Blob>> {
    const sourceObservable =
      typeof source === 'string'
        ? this.http.get(source, {
            ...options,
            responseType: 'blob',
            observe: 'response',
          })
        : source;
    return sourceObservable.pipe(HttpHelper.saveBlob);
  }

  openInNewTab(
    source: Observable<HttpResponse<Blob>>,
  ): Observable<HttpResponse<Blob>> {
    return source.pipe(
      tap(response => {
        this.fileService.openInNewTab(response.body);
      }),
    );
  }

  upload<T>(
    url: string,
    file: File,
    fromJson: (apiResponse: unknown) => T,
    method: 'post' | 'patch' = 'post',
    options?: {
      headers?: HttpHeaders;
    },
    uploadOptions?: DocumentUploadOptions,
  ): Observable<HttpProgressEvent | T> {
    const formData = new FormData();

    formData.append('file', file);
    formData.append('source', ReceiptSource.web);
    if (uploadOptions) {
      Object.keys(uploadOptions).forEach((key, index) => {
        formData.append(key, Object.values(uploadOptions)[index] as string);
      });
    }

    return this.http[method](url, formData, {
      ...(options || {}),
      reportProgress: true,
      observe: 'events',
    }).pipe(
      filter(
        (event: HttpEvent<unknown>) =>
          event.type === HttpEventType.UploadProgress ||
          event.type === HttpEventType.Response,
      ),
      map((event: HttpEvent<unknown>) =>
        event.type === HttpEventType.Response &&
        typeof event.body === 'object' &&
        Object.keys(event.body).length !== 0
          ? fromJson(event.body)
          : (event as HttpProgressEvent),
      ),
    );
  }
}
