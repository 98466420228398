import {
  AfterContentInit,
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  HostListener,
  Inject,
  QueryList,
  TemplateRef,
  ViewChildren,
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { NgUtils, StringUtils } from '@manakincubber/tiime-utils';
import { map, Observable, startWith } from 'rxjs';

import { TIIME_OVERLAY_DATA, TiimeOverlayRef } from '../../../overlay';
import { TiimeSelectOption } from '../select-option';
import { SelectDropdownData } from './dropdown-data';
import { DropdownOverlayResponseType } from './dropdown-overlay-response.type';

@Component({
  selector: 'tiime-select-dropdown',
  templateUrl: './dropdown.component.html',
  styleUrls: ['./dropdown.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TiimeDropdownComponent<T>
  implements AfterContentInit, AfterViewInit
{
  readonly trackByIndex = NgUtils.trackByIndex;
  protected readonly options: TiimeSelectOption<T>[];
  readonly compareWith: (a: unknown, b: unknown) => boolean;
  readonly currentValue: T;
  readonly id?: string;
  readonly customTemplate?: TemplateRef<{ option: TiimeSelectOption<T> }>;
  readonly withSearch?: boolean = false;

  readonly search = new FormControl<string>('');
  filteredOptions$: Observable<TiimeSelectOption<T>[]>;

  @ViewChildren('items') items!: QueryList<ElementRef>;

  keyboardSelectedIndex = -1;

  @HostListener('document:keydown', ['$event'])
  preventScroll(keyboardEvent: KeyboardEvent): void {
    const key = keyboardEvent.key;

    const isInputFocused =
      (keyboardEvent.target as HTMLElement)?.tagName === 'INPUT';

    if (isInputFocused) {
      this.preventSearchScroll(keyboardEvent);
      return;
    }

    switch (key) {
      case 'ArrowUp':
      case 'ArrowDown':
      case 'ArrowLeft':
      case 'ArrowRight':
      case 'Tab':
      case ' ':
        keyboardEvent.preventDefault();
        break;
    }

    if (key === 'ArrowUp') {
      --this.keyboardSelectedIndex;
      if (this.keyboardSelectedIndex === -1) {
        this.keyboardSelectedIndex = this.items.length - 1;
      }
      this.focusItem(this.keyboardSelectedIndex);
    } else if (key === 'ArrowDown') {
      ++this.keyboardSelectedIndex;
      if (this.keyboardSelectedIndex === this.items.length) {
        this.keyboardSelectedIndex = 0;
      }
      this.focusItem(this.keyboardSelectedIndex);
    } else if (key === ' ' || key === 'Enter') {
      (keyboardEvent.target as HTMLElement).click();
    } else if (key === 'Escape') {
      this.overlayRef.close();
    }
  }

  private preventSearchScroll(keyboardEvent: KeyboardEvent): void {
    switch (keyboardEvent.key) {
      case 'ArrowUp':
        keyboardEvent.preventDefault();
        this.keyboardSelectedIndex = this.items.length - 1;
        this.focusItem(this.keyboardSelectedIndex);
        return;
      case 'ArrowDown':
        keyboardEvent.preventDefault();
        this.keyboardSelectedIndex = 0;
        this.focusItem(this.keyboardSelectedIndex);
        return;
      case 'ArrowLeft':
      case 'ArrowRight':
      case ' ':
        return;
      case 'Tab':
        keyboardEvent.preventDefault();
        break;
    }
  }

  constructor(
    private overlayRef: TiimeOverlayRef<DropdownOverlayResponseType<T>>,
    @Inject(TIIME_OVERLAY_DATA) data: SelectDropdownData<T>,
  ) {
    this.options = data.options;
    this.compareWith = data.compareWith;
    this.currentValue = data.currentValue;
    this.id = data.id;
    this.customTemplate = data.customTemplate;
    this.withSearch = data.withSearch;
  }

  ngAfterContentInit(): void {
    this.filteredOptions$ = this.search.valueChanges.pipe(
      startWith(''),
      map(value => this.filterOptions(value)),
    );
  }

  ngAfterViewInit(): void {
    if (this.withSearch) {
      this.keyboardSelectedIndex = 0;
      return;
    }

    this.keyboardSelectedIndex = this.options.findIndex(option =>
      this.compareWith(option.value, this.currentValue),
    );
    this.focusItem(this.keyboardSelectedIndex);
  }

  changeValue(option: TiimeSelectOption<T>): void {
    if (!option.disabled) {
      const value = option.value;
      this.overlayRef.close({ value });
    }
  }

  private focusItem(index: number): void {
    (
      this.items.get(index > -1 ? index : 0)?.nativeElement as HTMLElement
    )?.focus();
  }

  private filterOptions(value: string | null): TiimeSelectOption<T>[] {
    this.keyboardSelectedIndex = 0;
    if (!value) {
      return this.withSearch ? this.options.slice(0, 150) : this.options;
    }

    const search = StringUtils.normalizeToLowerCase(value);
    return this.options
      .filter((s: TiimeSelectOption<T>) =>
        StringUtils.normalizeToLowerCase(s.label).includes(search),
      )
      .slice(0, 100);
  }
}
