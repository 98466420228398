<app-tiime-layout>
  <form
    class="auth-form"
    [formGroup]="signInForm"
    (ngSubmit)="submitSignInForm()"
  >
    <div class="auth-form-header">
      <div class="auth-form-header-illustration"></div>
      <div class="auth-form-header-separator"></div>
      <span class="auth-form-header-title">Connexion</span>
    </div>
    <div class="auth-form-content">
      <tiime-input-container
        class="input-container"
        label="Adresse mail"
        data-cy="email-input"
        [errorMessage]="signInForm.emailErrorMessage || wrongEmailErrorMessage"
      >
        <input
          id="email"
          tiimeInputContainerNativeControl
          type="text"
          placeholder="Adresse mail"
          formControlName="email"
          data-cy="signin-email"
        />
      </tiime-input-container>

      <tiime-input-container
        class="input-container"
        label="Mot de passe"
        data-cy="password-input"
        [errorMessage]="signInForm.passwordErrorMessage"
      >
        <span
          *ngIf="formErrorMessage"
          class="error-label"
          data-cy="input-container-error"
        >
          {{ formErrorMessage }}
        </span>
        <input
          id="password"
          tiimeInputContainerNativeControl
          type="password"
          placeholder="Mot de passe"
          formControlName="password"
          appPasswordToggle
          data-cy="signin-password"
        />
      </tiime-input-container>
      <div class="auth-form-link">
        <a
          class="forgot-password"
          data-cy="reset-password-link"
          (click)="navigateTo('/changepassword')"
        >
          Réinitialiser mon mot de passe
        </a>
      </div>
    </div>
    <div class="auth-form-actions">
      <button type="submit" tiime-button action accent data-cy="signin-submit">
        Je me connecte
      </button>
      <a
        type="button"
        tiime-button
        inverted
        neutral
        target="_blank"
        rel="noopener"
        href="https://www.tiime.fr/signup/"
        data-cy="signup-link"
      >
        Pas encore de compte ?
      </a>
    </div>
  </form>
</app-tiime-layout>
