import { Pipe, PipeTransform } from '@angular/core';
import { ValidationErrors } from '@angular/forms';

@Pipe({
  name: 'isCodeInputInvalid',
})
export class IsCodeInputInvalidPipe implements PipeTransform {
  transform(codeInputErrors: ValidationErrors): boolean {
    return (
      codeInputErrors?.notOnlyOneDigit === true && !codeInputErrors?.lengthError
    );
  }
}
