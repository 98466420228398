import { Injectable } from '@angular/core';
import { Router, Route, UrlSegment } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { cloneDeep } from 'lodash-es';
import { Observable, of } from 'rxjs';
import {
  catchError,
  map,
  switchMap,
  take,
  tap,
  withLatestFrom,
} from 'rxjs/operators';
import { MonoValueFilter, PaginationRange } from 'tiime-components';

import { AclService } from '@core/services/acl.service';
import { resetCategories } from '@core/store';
import { CompaniesService } from '@services/companies.service';
import { loadBusinessUnit } from '@store/business-unit';
import { selectCompany, selectedCompanySelector, update } from '@store/user';
import { userSelector } from '@user-store';

import { UserGuard } from './user.guard';

@Injectable({
  providedIn: 'root',
})
export class CompanyGuard {
  constructor(
    private readonly store: Store,
    private readonly router: Router,
    private readonly aclService: AclService,
    private readonly companiesService: CompaniesService,
    private readonly userGuard: UserGuard,
  ) {}

  canMatch(_route: Route, segments: UrlSegment[]): Observable<boolean> {
    if (segments.length > 1 && !isNaN(+segments[1].path)) {
      const companyId = Number(segments[1].path);
      return this.userGuard.canMatch().pipe(
        switchMap(userExists =>
          this.hasCompanyInStore(companyId).pipe(
            map(companyInStore => [companyInStore, userExists]),
          ),
        ),
        switchMap(([companyInStore, userExists]) => {
          if (!userExists) {
            return of(false);
          }
          return companyInStore
            ? of(companyInStore)
            : this.hasCompanyInApi(companyId);
        }),
      );
    }
    return this.userGuard.canMatch();
  }

  private hasCompanyInStore(companyId: number): Observable<boolean> {
    return this.store.pipe(
      select(selectedCompanySelector),
      map(company => !!company && company.id === companyId),
      take(1),
    );
  }

  private hasCompanyInApi(companyId: number): Observable<boolean> {
    return this.companiesService.get(companyId).pipe(
      withLatestFrom(this.store.select(userSelector)),
      tap(([company, user]) => {
        if (company) {
          const newUser = cloneDeep(user);
          newUser.activeCompany = company;
          this.store.dispatch(update({ user: newUser }));
          this.store.dispatch(selectCompany({ company }));
          this.store.dispatch(loadBusinessUnit({ company }));
          this.store.dispatch(resetCategories());
        } else {
          void this.router.navigate(['unauthorized']);
        }
      }),
      switchMap(() => this.hasCompanyInStore(companyId)),
      switchMap(hasCompany =>
        this.aclService.getUserPermissions().pipe(map(() => hasCompany)),
      ),
      catchError(() => this.getFirstCompanyOfUserAndNavigate()),
    );
  }

  private getFirstCompanyOfUserAndNavigate(): Observable<boolean> {
    return this.companiesService
      .getAll({
        range: new PaginationRange(0, 0),
        filters: [new MonoValueFilter('work_in_companies', 'true')],
      })
      .pipe(
        tap(
          companies =>
            void this.router.navigate(['/companies', companies.data[0].id]),
        ),
        map(() => true),
      );
  }
}
