import { InvoiceStatus } from '@enums';

import {
  ClientTimelineItem,
  ClientTimelineItemApiContract,
} from './client-timeline-item';
import {
  ClientTimelineInvoiceItemType,
  ClientTimelineItemDirection,
} from './types';

export class ClientTimelineInvoiceItem extends ClientTimelineItem<ClientTimelineInvoiceItemType> {
  constructor(
    id: number,
    date: string,
    amount: number,
    balanceAmount: number,
    matched: boolean,
    type: ClientTimelineInvoiceItemType,
    direction: ClientTimelineItemDirection,
    public title: string | null,
    public invoiceNumber: number,
    public status: InvoiceStatus,
    public invoiceLinkedId?: number,
    public creditNoteLinkedId?: number,
  ) {
    super(id, date, amount, balanceAmount, matched, type, direction);
  }

  public static fromJson(
    json: ClientTimelineItemApiContract,
  ): ClientTimelineInvoiceItem {
    return new ClientTimelineInvoiceItem(
      json.id,
      json.date,
      json.amount,
      json.balance_amount,
      json.matched,
      json.type as ClientTimelineInvoiceItemType,
      json.direction,
      json.title,
      json.number,
      json.status,
      json.invoice_linked_id,
      json.credit_note_linked_id,
    );
  }
}
