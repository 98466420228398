<div class="progress-spinner-container" [ngStyle]="containerSize">
  <mat-progress-spinner
    *ngIf="colorPalette"
    class="background-spinner"
    [diameter]="diameter"
    mode="determinate"
    value="100"
  ></mat-progress-spinner>
  <mat-progress-spinner
    class="progress-spinner"
    [class.white]="color === 'white'"
    [diameter]="diameter"
    [color]="colorPalette"
    [mode]="mode"
    [value]="value"
  ></mat-progress-spinner>
</div>
