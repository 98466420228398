export enum SddPayinRecurrencyEnum {
  ONCE = 'once',
  WEEKLY = 'weekly',
  MONTHLY = 'monthly',
}

export interface SddPayinApiContract {
  id?: number;
  recurrency?: SddPayinRecurrencyEnum;
  label?: string;
  amount?: number;
  start_date?: string;
  end_date?: string;
  next_date?: string;
  mandate_id?: number;
  mandate_debtor_name?: string;
}

export class SddPayin {
  constructor(
    public id?: number,
    public recurrency?: SddPayinRecurrencyEnum,
    public label?: string,
    public amount?: number,
    public startDate?: string,
    public endDate?: string,
    public nextDate?: string,
    public mandateId?: number,
    public mandateDebtorName?: string,
  ) {}

  static fromJson(sddPayin: SddPayinApiContract): SddPayin {
    return {
      id: sddPayin.id,
      recurrency: sddPayin.recurrency,
      label: sddPayin.label,
      amount: sddPayin.amount,
      startDate: sddPayin.start_date,
      endDate: sddPayin.end_date,
      nextDate: sddPayin.next_date,
      mandateId: sddPayin.mandate_id,
      mandateDebtorName: sddPayin.mandate_debtor_name,
    };
  }

  static toJson(sddPayin: Partial<SddPayin>): SddPayinApiContract {
    return {
      recurrency: sddPayin.recurrency,
      label: sddPayin.label ?? null,
      amount: sddPayin.amount,
      start_date: sddPayin.startDate,
      end_date: sddPayin.endDate ?? null,
    };
  }
}
