import {
  ChangeDetectionStrategy,
  Component,
  Inject,
  OnInit,
} from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { PRIMARY_OUTLET, Router } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Actions, ofType } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import { BehaviorSubject, Observable } from 'rxjs';
import { filter, map, switchMap, take, tap } from 'rxjs/operators';

import { CATEGORIES_OF_TYPE_RECEIPT } from '@constants';
import { StandardDocumentCategoryIdentifier } from '@core/enum';
import { FileTransferOverlayService } from '@file-transfer';
import { Category } from '@models';
import {
  ADD_CATEGORY_SUCCESS,
  addCategory,
  categoriesAllowingDocumentImportSelector,
  categoriesSelector,
  loadCategories,
  STORE_CATEGORIES,
} from '@store/categories';

export interface DocumentAddedDialogData {
  files: FileList;
  receiptCategoriesOnly?: boolean;
}

@UntilDestroy()
@Component({
  selector: 'app-document-added',
  templateUrl: './document-added-dialog.component.html',
  styleUrls: ['./document-added-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DocumentAddedDialogComponent implements OnInit {
  readonly categories$: Observable<Category[]> = this.store
    .select(categoriesAllowingDocumentImportSelector)
    .pipe(
      map(categories =>
        this.data.receiptCategoriesOnly
          ? categories?.filter(category =>
              CATEGORIES_OF_TYPE_RECEIPT.includes(category.identifier),
            )
          : categories,
      ),
    );
  readonly selectedCategory$ = new BehaviorSubject<Category>(null);

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: DocumentAddedDialogData,
    private readonly dialogRef: MatDialogRef<DocumentAddedDialogComponent>,
    private readonly fileTransferOverlayService: FileTransferOverlayService,
    private readonly store: Store,
    private actions$: Actions,
    private router: Router,
  ) {}

  ngOnInit(): void {
    this.loadCategoriesIfNeeded();
  }

  selectCategory(category: Category): void {
    this.selectedCategory$.next(category);
  }

  createStandardCategory(): Observable<Category> {
    return this.selectedCategory$.pipe(
      take(1),
      switchMap(selectedCategory => {
        this.store.dispatch(addCategory({ name: selectedCategory.name }));

        return this.actions$.pipe(
          ofType(ADD_CATEGORY_SUCCESS),
          take(1),
          tap((action: { category: Category }) => {
            if (action.category.name === selectedCategory.name) {
              selectedCategory = action.category;
            }
          }),
          map(action => action.category),
          untilDestroyed(this),
        );
      }),
    );
  }

  createCategory(category: Category): void {
    this.store.dispatch(addCategory({ name: category.name }));

    this.actions$
      .pipe(
        ofType(ADD_CATEGORY_SUCCESS),
        take(1),
        tap((action: { category: Category }) => {
          if (action.category.name === category.name) {
            this.selectedCategory$.next(action.category);
          }
        }),
        untilDestroyed(this),
      )
      .subscribe();
  }

  submitForm(): void {
    this.selectedCategory$
      .pipe(
        take(1),
        tap(selectedCategory => {
          if (selectedCategory.id) {
            this.createDocument(selectedCategory.id);
          } else {
            this.createStandardCategory()
              .pipe(
                tap(category => this.createDocument(category.id)),
                untilDestroyed(this),
              )
              .subscribe();
          }
        }),
      )
      .subscribe();
  }

  createDocument(categoryId: number): void {
    const files = this.data.files;
    this.fileTransferOverlayService.openOverlayWithFiles(files, categoryId);
    this.dialogRef.close();
  }

  private loadCategoriesIfNeeded(): void {
    this.store
      .pipe(
        select(categoriesSelector),
        take(1),
        tap(categories => {
          if (!categories || categories.length === 0) {
            this.store.dispatch(loadCategories());
          } else {
            this.initSelectedCategory();
          }
        }),
        untilDestroyed(this),
      )
      .subscribe();

    this.actions$
      .pipe(
        ofType(STORE_CATEGORIES),
        take(1),
        tap(() => this.initSelectedCategory()),
        untilDestroyed(this),
      )
      .subscribe();
  }

  private initSelectedCategory(): void {
    this.categories$
      .pipe(
        take(1),
        filter(categories => categories && categories.length > 0),
        map(categories => this.getSelectedCategoryFromUrl(categories)),
        tap(category => this.selectedCategory$.next(category)),
        untilDestroyed(this),
      )
      .subscribe();
  }

  private getSelectedCategoryFromUrl(categories: Category[]): Category {
    const paths = this.router
      .parseUrl(this.router.url)
      .root.children[PRIMARY_OUTLET].segments.map(segment => segment.path);

    const receiptCategory = categories.find(
      category =>
        category.identifier === StandardDocumentCategoryIdentifier.RECEIPT,
    );
    if (paths.includes('categories')) {
      //ie. route is like /documents/categories/{categoryId}
      const category = categories.find(
        category => category.id === +paths[paths.length - 1],
      );
      return category ?? receiptCategory;
    } else if (paths.includes('advanced-expenses')) {
      return categories.find(
        category =>
          category.identifier ===
          StandardDocumentCategoryIdentifier.ADVANCED_EXPENSE,
      );
    } else {
      return receiptCategory;
    }
  }
}
