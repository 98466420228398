import {
  CdkFixedSizeVirtualScroll,
  CdkVirtualForOf,
  CdkVirtualScrollViewport,
} from '@angular/cdk/scrolling';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatMenuModule } from '@angular/material/menu';
import {
  TiimeButtonModule,
  TiimeCheckboxModule,
  TiimeIconModule,
  TiimeMenuModule,
  TiimeRadioModule,
} from 'tiime-components';

import { ScrollShadowsModule } from '@directives';

import { IconsModule } from '../../../icons/icons.module';
import { TableClientNameFilterComponent } from './table-client-name-filter.component';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    TiimeButtonModule,
    TiimeIconModule,
    IconsModule,
    MatMenuModule,
    TiimeCheckboxModule,
    TiimeMenuModule,
    TiimeRadioModule,
    ReactiveFormsModule,
    ScrollShadowsModule,
    CdkVirtualScrollViewport,
    CdkVirtualForOf,
    CdkFixedSizeVirtualScroll,
  ],
  exports: [TableClientNameFilterComponent],
  declarations: [TableClientNameFilterComponent],
})
export class TableClientNameFilterModule {}
