import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

import { BusinessAccessRequest } from '@models';

@Injectable({
  providedIn: 'root',
})
export class BusinessAccessRequestService {
  private readonly acceptedRequest: BehaviorSubject<BusinessAccessRequest> =
    new BehaviorSubject(undefined);

  get acceptedBusinessAccessRequest$(): Observable<BusinessAccessRequest> {
    return this.acceptedRequest.asObservable();
  }

  acceptBusinessAccessRequest(
    businessAccessRequest: BusinessAccessRequest,
  ): void {
    this.acceptedRequest.next(businessAccessRequest);
  }
}
