<div class="empty-state">
  <div class="line"></div>
  <div class="line"></div>
  <div class="line"></div>

  <div class="content">
    <img
      draggable="false"
      alt=""
      [src]="expenseReportType | expenseReportEditorEmptyStateIllus"
    />
    <p>
      Cette liste est vide car vous n'avez aucun
      <br />
      <span class="advanced-expenses">
        <ng-container
          *ngIf="
            expenseReportType === ExpenseReportType.AdvancedExpense;
            else travelTemplate
          "
        >
          <app-category-icon
            [category]="StandardDocumentCategoryIdentifier.ADVANCED_EXPENSE"
          ></app-category-icon>
          Frais à rembourser
        </ng-container>
        <ng-template #travelTemplate>
          <mat-icon svgIcon="icon-address"></mat-icon>
          Frais kilométriques
        </ng-template>
      </span>
      de rattaché à ce propriétaire dans vos frais
    </p>
  </div>
</div>
