import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import {
  LOGIN_INITIATOR_PARAM_NAME,
  LoginInitiator,
  TiimeAuthService,
} from '@manakincubber/tiime-auth';
import { TrackingService } from '@manakincubber/tiime-tracking';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Store } from '@ngrx/store';
import { Auth0UserProfile } from 'auth0-js';
import { Observable, of } from 'rxjs';
import { catchError, map, switchMap, tap } from 'rxjs/operators';
import {
  MonoValueFilter,
  PaginationRange,
  SnackbarConfig,
  TiimeSnackbarService,
} from 'tiime-components';

import { CallbackComponentBase } from '@bases';
import { IMPERSONATE_EMAIL_REGEX } from '@constants';
import {
  CompaniesService,
  StrongCustomerAuthenticationService,
  UsersService,
  WindowService,
} from '@core';
import { AppOpenIdentified, UserManuallyLoggedIn } from '@core/amplitude';
import { ScaCreateSession } from '@core/models';
import { SentryService } from '@core/sentry/sentry.service';
import { LOCAL_STORAGE } from '@core/tokens';
import { AppConfigService } from '@services/app-config.service';

@UntilDestroy()
@Component({
  selector: 'app-auth-callback',
  templateUrl: './auth-callback.component.html',
  styleUrls: ['./auth-callback.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AuthCallbackComponent extends CallbackComponentBase {
  readonly #authService = inject(TiimeAuthService);
  readonly #companiesService = inject(CompaniesService);
  readonly #route = inject(ActivatedRoute);
  readonly #trackingService = inject(TrackingService);
  readonly #appConfigService = inject(AppConfigService);
  readonly #windowService = inject(WindowService);
  readonly #scaService = inject(StrongCustomerAuthenticationService);
  readonly #sentryService = inject(SentryService);
  readonly #snackbarService = inject(TiimeSnackbarService);
  readonly #storage = inject(LOCAL_STORAGE);

  constructor(router: Router, store: Store, userService: UsersService) {
    super(router, store, userService);
  }

  initAuthentication(): void {
    this.#authService
      .parseHash()
      .pipe(
        switchMap(() => this.disableTrackingOnImpersonation()),
        tap(() => this.trackIdentification()),
        tap(() => this.resetUserPreferences()),
        switchMap(() => this.handleRedirection()),
        catchError((error: Error) => {
          const contextEmail =
            this.#route.snapshot.queryParamMap.get('ctx-email');
          this.#sentryService.captureException(error, {
            tags: { context: 'auth-callback' },
            ...(contextEmail ? { user: { email: contextEmail } } : null),
          });
          return this.navigateToSignIn();
        }),
        untilDestroyed(this),
      )
      .subscribe();
  }

  private handleRedirection(): Observable<boolean> {
    return this.companyId$().pipe(
      switchMap(companyId => {
        if (!companyId) {
          const accessToken = this.#storage.getItem('access_token');
          // Force user to auth again if he comes back
          this.#storage.removeItem('access_token');
          this.#windowService.goToUrl(
            `${this.#appConfigService.appConfig.ONBOARDING_URL}/token-callback?access_token=${accessToken}`,
          );
          return of(true);
        } else {
          return this.checkForSCA(companyId);
        }
      }),
      catchError(error => {
        //eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
        const tiimeError = error.error as {
          error_description: string;
          errors: { [key: string]: string[] };
        };
        this.#snackbarService.open(
          tiimeError.error_description,
          SnackbarConfig.error,
        );
        return this.router.navigate(['signin']);
      }),
    );
  }

  private checkForSCA(companyId: number): Observable<boolean> {
    return this.mustSca$(companyId).pipe(
      switchMap(mustSca => {
        if (mustSca) {
          return this.scaAuthenticationForWallet(companyId);
        } else {
          return this.redirectAfterScaCheck(companyId);
        }
      }),
    );
  }

  private redirectAfterScaCheck(companyId: number): Observable<boolean> {
    return this.#route.queryParamMap.pipe(
      switchMap(paramMap =>
        this.handleRedirectionFromQueryParams(companyId, paramMap),
      ),
    );
  }

  private scaAuthenticationForWallet(companyId: number): Observable<boolean> {
    return this.#scaService
      .authenticate(
        new ScaCreateSession(),
        {
          hasBackdrop: false,
          displayValidationStep: false,
        },
        companyId,
        true,
      )
      .pipe(
        switchMap(() => {
          return this.redirectAfterScaCheck(companyId);
        }),
      );
  }

  private companyId$(): Observable<number | null> {
    return this.getCurrentUser().pipe(
      switchMap(user => {
        if (user.activeCompanyId || user.activeCompany) {
          return of(user.activeCompanyId || user.activeCompany.id);
        }

        return this.#companiesService
          .getAll({
            range: new PaginationRange(0, 0),
            filters: [new MonoValueFilter('work_in_companies', 'true')],
          })
          .pipe(map(({ data }) => (data.length ? data[0].id : null)));
      }),
    );
  }

  private mustSca$(companyId: number): Observable<boolean | null> {
    return this.#companiesService.get(companyId).pipe(
      switchMap(company => {
        return of(company.walletAccess && company.walletCompany?.scaTreezor);
      }),
    );
  }

  private disableTrackingOnImpersonation(): Observable<Auth0UserProfile> {
    return this.#authService.userInfo().pipe(
      tap((userInfo: Auth0UserProfile) => {
        if (IMPERSONATE_EMAIL_REGEX.test(userInfo.email)) {
          this.#trackingService.stopTracking();
        }
      }),
    );
  }

  private trackIdentification(): void {
    const loginInitiator = this.#route.snapshot.queryParamMap.get(
      LOGIN_INITIATOR_PARAM_NAME,
    );

    if (loginInitiator === LoginInitiator.user) {
      this.#trackingService.dispatch(new UserManuallyLoggedIn());
    } else {
      this.#trackingService.dispatch(new AppOpenIdentified());
    }
  }

  private resetUserPreferences(): void {
    this.#storage.removeItem('expense_ht_default');
  }
}
