import { BankConnection } from './bank-connection';
import {
  BusinessAccessRequest,
  BusinessAccessRequestApiContract,
} from './business-access-request';
import {
  ChronosBankConnection,
  ChronosBankConnectionApiContract,
} from './chronos-bank-connection';
import { Invoice } from './invoice';
import {
  QontoConnectMigration,
  QontoConnectMigrationApiContract,
} from './qonto-connect-migration';
import { ToDoCounts, TodoCountsApiContract } from './tiles';
import {
  VatValidationRequest,
  VatValidationRequestApiContract,
} from './vat-validation-request';

export interface HomeRequestActionsApiContract {
  pending_business_access_requests?: BusinessAccessRequestApiContract[];
  scheduled_invoices?: any[];
  update_needed_bank_connections?: ChronosBankConnectionApiContract[];
  add_siret?: boolean;
  to_do_counts?: TodoCountsApiContract;
  qonto_connect_migration?: QontoConnectMigrationApiContract;
  cvv_try_exceeded?: boolean;
  pin_try_exceeded?: boolean;
  wallet_pending?: boolean;
  has_sepa_payment_initiation_pending?: boolean;
  has_estimated_vat_validation_requested?: VatValidationRequestApiContract;
}

export class HomeRequestActions {
  constructor(
    public pendingBusinessAccessRequests?: BusinessAccessRequest[],
    public scheduledInvoices?: Invoice[],
    public brokenBankConnections?: BankConnection[],
    public siretMissing?: boolean,
    public todoCounts?: ToDoCounts,
    public qontoConnectMigration?: QontoConnectMigration,
    public cvvTryExceeded?: boolean,
    public pinTryExceeded?: boolean,
    public walletPending?: boolean,
    public hasSepaPaymentInitiationPending?: boolean,
    public hasEstimatedVatValidationRequested?: VatValidationRequest,
  ) {}

  public static fromJson(
    json: HomeRequestActionsApiContract,
  ): HomeRequestActions {
    return new HomeRequestActions(
      json.pending_business_access_requests
        ? json.pending_business_access_requests.map(
            pendingBusinessAccessRequestJson =>
              BusinessAccessRequest.fromJson(pendingBusinessAccessRequestJson),
          )
        : [],
      json.scheduled_invoices
        ? json.scheduled_invoices.map(scheduleInvoice =>
            Invoice.fromJson(scheduleInvoice),
          )
        : [],
      json.update_needed_bank_connections
        ? json.update_needed_bank_connections.map(updateNeededBankConnection =>
            ChronosBankConnection.fromJson(updateNeededBankConnection),
          )
        : [],
      json.add_siret,
      json.to_do_counts ? ToDoCounts.fromJson(json.to_do_counts) : null,
      json.qonto_connect_migration
        ? QontoConnectMigration.fromJson(json.qonto_connect_migration)
        : null,
      json.cvv_try_exceeded,
      json.pin_try_exceeded,
      json.wallet_pending,
      json.has_sepa_payment_initiation_pending,
      json.has_estimated_vat_validation_requested
        ? VatValidationRequest.fromJson(
            json.has_estimated_vat_validation_requested,
          )
        : null,
    );
  }
}
