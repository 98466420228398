<tiime-dialog-close-button></tiime-dialog-close-button>

<div mat-dialog-content>
  <img src="assets/illus/document-category/illu_all_documents.svg" />
  <div class="flex-container">
    <div class="bulk-move-title">
      Changer l'espace
      <br />
      <ng-container
        *tiimeLet="
          selectedCategory | map : mapToNumberOfDocuments as numberOfDocuments
        "
      >
        <span [ngPlural]="numberOfDocuments">
          <ng-template ngPluralCase="=1">d'un document</ng-template>
          <ng-template ngPluralCase="other">
            de {{ numberOfDocuments }} documents
          </ng-template>
        </span>
      </ng-container>
    </div>
    <div
      *tiimeLet="categories$ | async as categories"
      class="selector-container"
    >
      <label>Espace de documents</label>
      <app-category-select
        *ngIf="categories"
        [categories]="categories"
        [disabledCategories]="disabledCategories$ | async"
        [selectedCategory]="selectedCategory ?? categories[0]"
        (selectCategory)="selectedCategory = $event"
        (createCategory)="createCategory($event)"
      ></app-category-select>
    </div>

    <app-info-banner
      *ngIf="data.expenseReportsDocumentIds.length"
      [title]="expenseReportsBannerText.title"
      [subtitle]="expenseReportsBannerText.subtitle"
    ></app-info-banner>
    <app-info-banner
      *ngIf="selectedCategory | map : mapToScheduledBannerVisible"
      [title]="scheduledPaymentBannerText.title"
      [subtitle]="scheduledPaymentBannerText.subtitle"
    ></app-info-banner>
    <app-info-banner
      *ngIf="selectedCategory | map : mapToMatchedBannerVisible"
      [title]="matchedDocumentsBannerText.title"
      [subtitle]="matchedDocumentsBannerText.subtitle"
    ></app-info-banner>
    <app-locked-documents-warning
      *ngIf="selectedCategory | map : mapToLockedBannerVisible"
      [numberOfLockedDocuments]="data.lockedDocumentIds.length"
    ></app-locked-documents-warning>

    <mat-dialog-actions>
      <button
        tiime-button
        neutral
        inverted
        [mat-dialog-close]="null"
        [disabled]="actionsDisabled"
      >
        Annuler
      </button>
      <button
        tiime-button
        accent
        [disabled]="actionsDisabled"
        (click)="apply()"
      >
        Valider
      </button>
    </mat-dialog-actions>
  </div>
</div>
