import { FormControl, FormGroup } from '@angular/forms';
import { OrFilter, ResetFilter } from 'tiime-components';

import { AccountantDetailRequestFilterValue } from '@core/enum/filters';

export class TransactionsTodoFilterForm extends FormGroup {
  readonly todoFilterKey = 'accountant_detail_request';

  get label(): FormControl<boolean> {
    return this.get('label') as FormControl<boolean>;
  }
  get message(): FormControl<boolean> {
    return this.get('message') as FormControl<boolean>;
  }
  get document(): FormControl<boolean> {
    return this.get('document') as FormControl<boolean>;
  }

  constructor() {
    super({
      label: new FormControl<boolean>(null),
      message: new FormControl<boolean>(null),
      document: new FormControl<boolean>(null),
    });
  }

  fromParam(values?: string[]): void {
    this.label.setValue(
      values?.includes(AccountantDetailRequestFilterValue.Label),
    );
    this.message.setValue(
      values?.includes(AccountantDetailRequestFilterValue.Message),
    );
    this.document.setValue(
      values?.includes(AccountantDetailRequestFilterValue.Receipt),
    );
  }

  resetFilters(): void {
    this.patchValue({
      label: false,
      message: false,
      document: false,
    });
  }

  toOutput(): OrFilter<string> | ResetFilter {
    const values: string[] = [];
    if (this.label.value) {
      values.push(AccountantDetailRequestFilterValue.Label);
    }
    if (this.message.value) {
      values.push(AccountantDetailRequestFilterValue.Message);
    }
    if (this.document.value) {
      values.push(AccountantDetailRequestFilterValue.Receipt);
    }
    return values.length
      ? new OrFilter(this.todoFilterKey, values)
      : ResetFilter.forKey(this.todoFilterKey);
  }
}
