import { Pipe, PipeTransform } from '@angular/core';

import { Metadata } from '@models/documents';

import { getVatFromDocumentMetadata } from '../utils/functions';

@Pipe({
  name: 'documentVatAmount',
})
export class DocumentVatAmountPipe implements PipeTransform {
  transform(metadata: Metadata[]): number {
    return getVatFromDocumentMetadata(metadata);
  }
}
