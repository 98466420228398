import { OverlayContainer } from '@angular/cdk/overlay';
import { Directive } from '@angular/core';

import { AppTheme } from '../enum/app-theme.enum';

@Directive()
// eslint-disable-next-line @angular-eslint/directive-class-suffix
export abstract class ThemedComponentBase {
  protected constructor(
    overlayContainer: OverlayContainer,
    appTheme: AppTheme,
  ) {
    this.applyAppThemeToOverlayContainer(overlayContainer, appTheme);
  }

  private applyAppThemeToOverlayContainer(
    overlayContainer: OverlayContainer,
    appThemeToApply: AppTheme,
  ): void {
    const classesToRemove = Object.values(AppTheme).map(
      (appTheme: AppTheme) => `${appTheme}-theme`,
    );
    overlayContainer.getContainerElement().classList.remove(...classesToRemove);
    overlayContainer
      .getContainerElement()
      .classList.add(`${appThemeToApply}-theme`);
  }
}
