import { ExportConfigurationBase } from '../../settings/export/export-core/bases/export-configuration.base';
import { BankAccount } from './bank-account';

export interface TransactionsExportApiContract {
  options: {
    linked_to_receipt: boolean | null;
    linked_to_invoice: boolean | null;
  };
  bank_account_ids?: BankAccount['id'][];
}

export class TransactionsExportConfiguration extends ExportConfigurationBase {
  constructor(
    public exportTransactionsLinkedToReceipts: boolean | null,
    public exportTransactionsLinkedToInvoices: boolean | null,
    public bankAccountsToExportFrom: BankAccount[] | null,
  ) {
    super();
  }

  static toJson(configuration: TransactionsExportConfiguration): {
    bank_transactions: TransactionsExportApiContract;
  } {
    return {
      bank_transactions: {
        options: {
          linked_to_receipt: configuration.exportTransactionsLinkedToReceipts,
          linked_to_invoice: configuration.exportTransactionsLinkedToInvoices,
        },
        ...(this.shouldFilterBy<BankAccount>(
          configuration.bankAccountsToExportFrom,
        ) && {
          bank_account_ids: configuration.bankAccountsToExportFrom.map(
            (bankAccount: BankAccount) => bankAccount.id,
          ),
        }),
      },
    };
  }
}
