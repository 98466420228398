import { TrackingEvent } from '@manakincubber/tiime-tracking';

import { StandardDocumentCategoryIdentifier } from '@core/enum';

export type ampliCategoryType =
  | 'justificatif'
  | 'frais_a_rembourser'
  | 'notes_frais'
  | 'ik'
  | 'facture_vente'
  | 'juridique'
  | 'social_salarie'
  | 'social_dirigeant'
  | 'bulletin_paie'
  | 'releve_bancaire'
  | 'compte_annuel'
  | 'document_permanent'
  | 'autres'
  | 'fiscal';

export const DOCUMENT_MATCHED = 'Document Matched ';

export class DocumentMatched implements TrackingEvent {
  readonly name = DOCUMENT_MATCHED;

  constructor(
    readonly TransactionWithRequest: boolean,
    readonly documentCategory: StandardDocumentCategoryIdentifier,
  ) {}
}
