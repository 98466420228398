import { createAction, props } from '@ngrx/store';

import { BusinessUnit, Company } from '@core/models';

export const LOAD_UNIT = '[B_UNIT] Load business unit';
export const LOAD_UNIT_SUCCESS = '[B_UNIT] Load business unit success';

export const loadBusinessUnit = createAction(
  LOAD_UNIT,
  props<{ company: Company }>(),
);

export const loadBusinessUnitSuccess = createAction(
  LOAD_UNIT_SUCCESS,
  props<{ businessUnit: BusinessUnit }>(),
);
