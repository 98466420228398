export type InvoiceScheduleApiContract = {
  id: string;
  schedule_day: number;
  ended_at: string;
};

export class InvoiceSchedule {
  constructor(
    public id?: string,
    public scheduleDay?: number,
    public endedAt?: string,
  ) {}

  public static fromJson(json: InvoiceScheduleApiContract): InvoiceSchedule {
    return new InvoiceSchedule(json.id, json.schedule_day, json.ended_at);
  }

  public static toJson(
    invoiceSchedule: InvoiceSchedule,
  ): InvoiceScheduleApiContract {
    return {
      id: invoiceSchedule.id,
      schedule_day: invoiceSchedule.scheduleDay,
      ended_at: invoiceSchedule.endedAt,
    };
  }
}
