import { Address, AddressApiContract } from './address';

export interface TravelBookmarkAddressesApiContract {
  travel_bookmark_addresses: BookmarkAddressApiContract[];
}

export interface BookmarkAddressApiContract {
  id: number;
  address: AddressApiContract;
  address_company_name?: string;
}

export class BookmarkAddress {
  constructor(
    public address: Address,
    public addressCompanyName?: string,
    public id?: number,
    public isBookmarked?: boolean,
  ) {}

  static fromJson(json: BookmarkAddressApiContract): BookmarkAddress {
    return new BookmarkAddress(
      Address.fromJson({
        postal_code: '',
        ...json.address,
      }),
      json.address_company_name,
      json.id,
      true,
    );
  }

  static toJson(
    bookmarkAddress: BookmarkAddress,
  ): Partial<BookmarkAddressApiContract> {
    return {
      address: Address.toJson({
        postalCode: '',
        ...bookmarkAddress.address,
      }),
      address_company_name: bookmarkAddress.addressCompanyName,
    };
  }
}
