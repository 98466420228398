<div class="left">
  <button class="close" (click)="close()">
    <mat-icon svgIcon="icon-close"></mat-icon>
  </button>
  <span [ngPlural]="selectionModel.selected.length">
    {{ selectionModel.selected.length }}&nbsp;
    <ng-template ngPluralCase="=1">élément sélectionné</ng-template>
    <ng-template ngPluralCase="other">éléments sélectionnés</ng-template>
  </span>
</div>
<div class="buttons">
  <ng-content></ng-content>
</div>
