import { StandardDocumentCategoryIdentifier } from '@core/enum';
import { MetadataKey, MetadataType } from '@core/enum/documents';
import { MatchableDocument } from '@matching/types/matchable-document';

import { Document } from '../document';
import { InvoiceListItem } from '../invoice-list-item';

export class BankTransactionMatching {
  constructor(
    public matchings?: {
      amount: number;
      date: string;
      id: number;
      name: string;
      type: StandardDocumentCategoryIdentifier | 'invoice';
    }[],
  ) {}

  static toMatchedDocuments(
    bankTransactionMatchings: BankTransactionMatching,
  ): MatchableDocument[] {
    return bankTransactionMatchings.matchings.map(matching =>
      matching.type === 'invoice'
        ? InvoiceListItem.fromJson({
            id: matching.id,
            client_name: matching.name,
            total_including_taxes: matching.amount,
            emission_date: matching.date,
          })
        : Document.fromJson({
            metadata: [
              {
                key: MetadataKey.Amount,
                type: MetadataType.Amount,
                value: { value: matching.amount },
              },
            ],
            id: matching.id,
            name: matching.name,
            created_at: matching.date,
            category: {
              id: null,
              name: null,
              pinned_at: null,
              available_metadata: [],
              identifier: matching.type,
            },
          }),
    ) as MatchableDocument[];
  }
}
