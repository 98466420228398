import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import {
  GetOptions,
  MonoValueFilter,
  OrFilter,
  PaginationData,
  RequiredGetOptions,
} from 'tiime-components';

import { ApiAlertError } from '@core/decorators/api-alert-error';
import { HttpHelper } from '@core/helpers';
import { LinkedEntityType } from '@core/models/linked-entities';
import { TemporaryEncoder } from '@core/temporary-encoder';
import {
  Message,
  MessageApiContract,
  Thread,
  ThreadApiContract,
} from '@models';

export enum ThreadStatus {
  OPENED = 'opened',
  CLOSED = 'closed',
  ARCHIVED = 'archived',
}

@Injectable({
  providedIn: 'root',
})
export class ThreadsService {
  private readonly resource = 'api/v1/companies/{companyId}/threads';

  observeThreadSentMessage$ = new Subject<number>();

  constructor(private readonly http: HttpClient) {}

  @ApiAlertError()
  getAll(
    getOptions: RequiredGetOptions<'range'>,
  ): Observable<PaginationData<Thread>> {
    const partialOptions = new GetOptions({
      filters: [
        new MonoValueFilter('types', LinkedEntityType.BANK_TRANSACTION),
        new OrFilter('status', [ThreadStatus.OPENED, ThreadStatus.CLOSED]),
      ],
      sort: { active: 'message_created_at', direction: 'desc' },
      ...getOptions,
    }).toHttpGetOptions();
    const options = {
      params: new HttpParams({
        fromObject: partialOptions.params,
        encoder: new TemporaryEncoder(),
      }),
      headers: partialOptions.headers,
    };

    return this.http
      .get<Record<string, unknown>[]>(this.resource, {
        ...options,
        observe: 'response',
      })
      .pipe(
        HttpHelper.mapToPaginationData(
          getOptions.range,
          (request: ThreadApiContract) => Thread.fromJson(request),
        ),
      );
  }

  @ApiAlertError()
  get(threadId: number): Observable<Thread> {
    return this.http
      .get<ThreadApiContract>(`${this.resource}/${threadId}`)
      .pipe(map(request => Thread.fromJson(request)));
  }

  @ApiAlertError()
  getFromBankTransaction(bankTransactionId: number): Observable<Thread> {
    const params = new HttpParams({
      fromObject: {
        types: 'bank_transaction',
        bank_transaction: bankTransactionId,
      },
      encoder: new TemporaryEncoder(),
    });

    return this.http
      .get<ThreadApiContract[]>(`${this.resource}`, { params })
      .pipe(
        map(threads => {
          const thread = threads.find(thread => !thread.archived_at);
          return Thread.fromJson(thread);
        }),
      );
  }

  @ApiAlertError()
  createMessage(message: Message, threadId: number): Observable<Message> {
    return this.http
      .post<MessageApiContract>(
        `${this.resource}/${threadId}/messages`,
        Message.toJson(message),
      )
      .pipe(
        map((message: MessageApiContract) => Message.fromJson(message)),
        tap(() => this.observeThreadSentMessage$.next(threadId)),
      );
  }

  createThreadWithMessage(
    message: Message,
    bankTransactionId: number,
  ): Observable<unknown> {
    return this.http.post<ThreadApiContract>(`${this.resource}`, {
      entity: {
        type: 'bank_transaction',
        bank_transaction: {
          id: bankTransactionId,
        },
      },
      messages: [message],
    });
  }

  @ApiAlertError()
  deleteMessage(message: Message, threadId: number): Observable<void> {
    return this.http.delete<void>(
      `${this.resource}/${threadId}/messages/${message.id}`,
    );
  }

  @ApiAlertError()
  updateMessage(message: Message, threadId: number): Observable<void> {
    return this.http.patch<void>(
      `${this.resource}/${threadId}/messages/${message.id}`,
      Message.toJson(message),
    );
  }

  @ApiAlertError()
  markAsRead(threadId: number): Observable<void> {
    return this.http.post<void>(`${this.resource}/${threadId}/read`, true);
  }
}
