import { AbstractControl, FormGroup } from '@angular/forms';
import { Filter } from 'tiime-components';

export type TableColumnMultipleFilter = Filter<unknown>;

export abstract class TableColumnMultipleFilterForm<
  T = TableColumnMultipleFilter,
> extends FormGroup {
  protected constructor(
    controls: {
      [key: string]: AbstractControl;
    },
    protected readonly filterKeys: string[],
  ) {
    super(controls);
  }

  abstract fromParam(paramValue: Filter<string | boolean>[]): void;
  abstract resetFilters(): void;
  abstract toOutput(): T[];
}
