import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import {
  BehaviorSubject,
  iif,
  Observable,
  of,
  OperatorFunction,
  pipe,
} from 'rxjs';
import { filter, map, switchMap, tap, withLatestFrom } from 'rxjs/operators';

import { ApiAlertError } from '@decorators/api-alert-error';
import { CompanyAppConfigKeys } from '@enums/config';
import { CompanyAppConfig, CompanyAppConfigApiContract } from '@models/config';

import { ReloadService } from '../reload.service';

@UntilDestroy()
@Injectable({
  providedIn: 'root',
})
export class CompanyConfigService {
  private readonly resource = 'api/v1/companies/{companyId}/app_config';
  private readonly companyConfig$ = new BehaviorSubject<CompanyAppConfig>(null);

  constructor(
    private readonly http: HttpClient,
    private readonly reloadService: ReloadService,
  ) {
    this.reloadCompanyConfigurationOnCompanyChange();
  }

  @ApiAlertError()
  get(): Observable<CompanyAppConfig> {
    return this.companyConfig$.pipe(
      switchMap(config =>
        iif(() => !!config, of(config), this.getConfigFromApi()),
      ),
    );
  }

  @ApiAlertError()
  update(
    key: CompanyAppConfigKeys,
    value: boolean,
  ): Observable<CompanyAppConfig> {
    return this.http
      .patch(`${this.resource}`, {
        [key]: value,
      })
      .pipe(this.serializeAndStoreConfig());
  }

  @ApiAlertError()
  private getConfigFromApi(): Observable<CompanyAppConfig> {
    return this.http
      .get(`${this.resource}`)
      .pipe(this.serializeAndStoreConfig());
  }

  private serializeAndStoreConfig(): OperatorFunction<
    CompanyAppConfigApiContract,
    CompanyAppConfig
  > {
    return pipe(
      map((json: CompanyAppConfigApiContract) =>
        CompanyAppConfig.fromJson(json),
      ),
      tap(companyConfig => this.companyConfig$.next(companyConfig)),
    );
  }

  private reloadCompanyConfigurationOnCompanyChange(): void {
    this.reloadService.companyChanged
      .pipe(
        withLatestFrom(this.companyConfig$),
        filter(companyConfig => !!companyConfig),
        switchMap(() => this.getConfigFromApi()),
        untilDestroyed(this),
      )
      .subscribe();
  }
}
