import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

import { TableColumnSortAndFilterDirective } from '../table-column-sort-and-filter.directive';
import { TableDateSortAndFilterForm } from './table-date-sort-and-filter.form';

@Component({
  selector: 'app-table-date-sort-and-filter',
  templateUrl: './table-date-sort-and-filter.component.html',
  styleUrls: ['../table-column-filter-base.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TableDateSortAndFilterComponent extends TableColumnSortAndFilterDirective {
  @Input() noSortingOption? = false;

  protected initForm(): void {
    this.form = new TableDateSortAndFilterForm(
      this.filterKey,
      this.sortKey,
      this.sort,
    );
    if (this.filterValue) {
      this.form.fromParam(this.filterValue);
    }
  }
}
