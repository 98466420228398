<tiime-chip
  removeSvgIcon="icon-button-close"
  [canRemove]="canRemove"
  [basic]="basic"
  [flat]="flat"
  [disabled]="disabled"
  [small]="small"
  [large]="large"
  (remove)="remove.emit()"
>
  <mat-icon svgIcon="icon-tag"></mat-icon>
  <span data-cy="tag-chip__txt-tag-name">
    {{ tag.name }}
  </span>
</tiime-chip>
