import { TrackingEvent } from '@manakincubber/tiime-tracking';

import { Client } from '@models';

export const CLIENT_CREATED = 'Client Created';

export class ClientCreated implements TrackingEvent {
  readonly name = CLIENT_CREATED;

  constructor(readonly client: Client) {}
}
