import { AsyncPipe } from '@angular/common';
import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { MatIcon } from '@angular/material/icon';
import { RouterLink, RouterLinkActive } from '@angular/router';
import { Store } from '@ngrx/store';
import { combineLatest } from 'rxjs';
import { map, shareReplay } from 'rxjs/operators';
import {
  TiimeButtonModule,
  TiimeSidenavLayoutModule,
  TiimeTooltipModule,
} from 'tiime-components';

import { ActivatedModulesService } from '@core/activated-modules.service';
import { HasAccessDirective } from '@core/directives/acl/has-access.directive';
import { selectedCompanySelector } from '@core/store';
import { ScrollShadowsModule } from '@directives';
import { WalletSDDStatusEnum } from '@enums';
import { UserPermissionNameEnum } from '@enums/users';
import { isKycValidated } from '@guards';
import { CompanyConfigService } from '@services';
import { AclService } from '@services/acl.service';

import { BankTransferOverlayService } from '../../../../account/bank-transfers/bank-transfers-shared/components/bank-transfert-overlay/bank-transfer-overlay.service';
import { SidenavDocumentUploadModule } from '../../../../sidenav-document-upload/sidenav-document-upload.module';
import { NavigationMenuBase } from '../navigation-menu-base';

@Component({
  standalone: true,
  selector: 'app-billed-navigation-menu',
  templateUrl: 'billed-navigation-menu.component.html',
  styleUrls: [
    '../navigation-menu-base.scss',
    'billed-navigation-menu.component.scss',
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    AsyncPipe,
    HasAccessDirective,
    MatIcon,
    RouterLink,
    RouterLinkActive,
    ScrollShadowsModule,
    SidenavDocumentUploadModule,
    TiimeButtonModule,
    TiimeSidenavLayoutModule,
    TiimeTooltipModule,
  ],
})
export class BilledNavigationMenuComponent extends NavigationMenuBase {
  private readonly aclService = inject(AclService);
  private readonly activatedModulesService = inject(ActivatedModulesService);
  private readonly bankTransferOverlayService = inject(
    BankTransferOverlayService,
  );

  protected readonly activatedModules$ =
    this.activatedModulesService.activatedModules$;

  protected readonly selectedCompany$ = inject(Store)
    .select(selectedCompanySelector)
    .pipe(shareReplay({ bufferSize: 1, refCount: true }));

  protected readonly showTodoMenu$ = combineLatest([
    this.aclService
      .getCurrentUserPermissions()
      .pipe(map(user => user.permissionAttributes)),
    this.selectedCompany$,
  ]).pipe(
    map(([permissions, company]) => {
      const hasAccountsFull = permissions.some(
        permission =>
          permission.fullName === UserPermissionNameEnum.ACCOUNTS_USER_FULL &&
          permission.permit === true,
      );
      const hasBusinessFull = permissions.some(
        permission =>
          permission.fullName === UserPermissionNameEnum.BUSINESS_USER_FULL &&
          permission.permit === true,
      );
      return hasAccountsFull || (hasBusinessFull && company.walletAccess);
    }),
  );

  protected readonly showBankTransfersMenu$ = combineLatest([
    this.aclService.userHasPermission([
      UserPermissionNameEnum.BUSINESS_USER_FULL,
    ]),
    this.selectedCompany$.pipe(
      map(selectedCompany => selectedCompany.walletAccess),
    ),
    isKycValidated(),
  ]).pipe(
    map(
      ([hasPermissions, hasWalletAccess, hasValidatedKyc]) =>
        hasPermissions && hasWalletAccess && hasValidatedKyc,
    ),
  );

  protected readonly showPayinsMenu$ = inject(CompanyConfigService)
    .get()
    .pipe(
      map(config => config.walletSDDStatus !== WalletSDDStatusEnum.DISABLED),
    );

  getModuleLink(url: string, basegiToken: string): string {
    return this.activatedModulesService.getModuleLink(url, basegiToken);
  }

  openBankTransferOverlay($event: Event): void {
    $event.preventDefault();
    $event.stopPropagation();
    this.bankTransferOverlayService.open();
  }
}
