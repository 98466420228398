import { CdkStepper, StepperSelectionEvent } from '@angular/cdk/stepper';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
  booleanAttribute,
} from '@angular/core';
import { NgUtils } from '@manakincubber/tiime-utils';
import { of } from 'rxjs';
import { debounceTime, map, startWith, switchMap } from 'rxjs/operators';

@Component({
  selector: 'app-bank-transfer-stepper',
  templateUrl: './bank-transfer-stepper.component.html',
  styleUrls: ['./bank-transfer-stepper.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    { provide: CdkStepper, useExisting: BankTransferStepperComponent },
  ],
})
export class BankTransferStepperComponent extends CdkStepper {
  @Input() loading = false;
  @Input() isDocumentProofNeeded = false;
  @Input({ transform: booleanAttribute }) withCancelButton = false;

  @Output() readonly stepperComplete = new EventEmitter<void>();
  @Output() readonly stepperNext = new EventEmitter<void>();
  @Output() readonly cancel = new EventEmitter<void>();

  readonly trackByIndex = NgUtils.trackByIndex.bind(this);

  readonly isNextDisabled$ = this.selectionChange.pipe(
    startWith({ selectedIndex: 0 } as Partial<StepperSelectionEvent>),
    // Mandatory to update the selectedIndex in case of cdkPreviousStep clicked
    // The index would be null || 0 in other case
    debounceTime(0),
    map(() => this.selectedIndex || 0),
    switchMap(selectedIndex => {
      return this.steps.get(selectedIndex)?.stepControl
        ? this.steps
            .get(selectedIndex)
            .stepControl.statusChanges.pipe(
              startWith(this.steps.get(selectedIndex).stepControl.status),
            )
        : of('VALID');
    }),
    map((status: 'VALID' | 'INVALID') => {
      return status === 'INVALID';
    }),
  );

  allValidated = false;

  setAsValidated(): void {
    this.allValidated = true;
  }

  resetStepper(): void {
    this.allValidated = false;
    this.reset();
  }
}
