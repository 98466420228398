import { BooleanInput, coerceBooleanProperty } from '@angular/cdk/coercion';
import {
  Attribute,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  forwardRef,
  Input,
  ViewEncapsulation,
} from '@angular/core';

import { ButtonBase } from './button.base';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: '[tiime-button]',
  templateUrl: './button.component.html',
  styleUrls: ['button.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    { provide: ButtonBase, useExisting: forwardRef(() => ButtonComponent) },
  ],
})
export class ButtonComponent extends ButtonBase {
  @Input() set xsmall(xsmall: BooleanInput) {
    this.elementRef.nativeElement.toggleAttribute(
      'xsmall',
      coerceBooleanProperty(xsmall),
    );
  }
  constructor(
    elementRef: ElementRef<HTMLButtonElement>,
    cdr: ChangeDetectorRef,
    @Attribute('primary') private readonly primary: boolean,
    @Attribute('warn') private readonly warn: boolean,
    @Attribute('accent') private readonly accent: boolean,
    @Attribute('neutral') private readonly neutral: boolean,
    @Attribute('inverted') private readonly inverted: boolean,
    @Attribute('flat') private readonly flat: boolean,
    @Attribute('xxsmall') private readonly xxsmall: boolean,
    @Attribute('small') private readonly small: boolean,
    @Attribute('big') private readonly big: boolean,
    @Attribute('icon') private readonly icon: boolean,
    @Attribute('contrast') private readonly contrast: boolean, // Apparently not used anymore
  ) {
    super(elementRef, cdr);
    if (!this._hasHostAttributes('contrast')) {
      this.elementRef.nativeElement.setAttribute('light', '');
    }
    if (
      !this._hasHostAttributes('inverted') &&
      !this._hasHostAttributes('flat')
    ) {
      this.elementRef.nativeElement.setAttribute('normal', '');
    }
  }
}
