export const INTRACOM_VAT_REGEX = /^FR[A-HJ-NP-Z0-9]{2}\d{9}$/;

export function computeIntracomVatNumber(fullSiret: string): string {
  if (!fullSiret || fullSiret.length < 9) {
    return null;
  }
  const siret = fullSiret.substring(0, 9);
  const vatKey = (12 + 3 * (Number(siret) % 97)) % 97;
  const vatKeyString = (vatKey < 10 ? '0' : '') + String(vatKey);
  return 'FR' + vatKeyString + siret;
}
