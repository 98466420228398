import { Action, createReducer, on } from '@ngrx/store';

import { BusinessUnit } from '@models';

import { loadBusinessUnitSuccess } from './business-unit.actions';

export const businessUnitInitialState: BusinessUnit | null = null;

const businessUnit = createReducer(
  businessUnitInitialState,
  on(loadBusinessUnitSuccess, (_state, { businessUnit }) => businessUnit),
);

export function businessUnitReducer(
  state: BusinessUnit | undefined,
  action: Action,
): BusinessUnit {
  return businessUnit(state, action);
}
