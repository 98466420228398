/// <reference types="@types/google.maps" />
import {
  Directive,
  AfterViewInit,
  Output,
  ElementRef,
  EventEmitter,
  OnDestroy,
  Input,
} from '@angular/core';

import { AutocompleteAddress } from './google-places-autocomplete.interface';
import { GooglePlacesAutocompleteService } from './google-places-autocomplete.service';

@Directive({
  selector: '[tiimeGooglePlacesAutocomplete]',
})
export class GooglePlacesAutocompleteDirective
  implements AfterViewInit, OnDestroy
{
  @Input()
  set tiimeGooglePlacesAutocompleteDisabled(disabled: boolean) {
    if (disabled === this.disabled) {
      return;
    }

    if (disabled) {
      this.clearAutocompleteListeners();
    } else {
      this.initAutocomplete();
      this.observePlaceChange();
    }
    this.disabled = disabled;
  }
  private disabled = false;

  @Output()
  placeChanged = new EventEmitter<AutocompleteAddress>();

  private autocomplete: google.maps.places.Autocomplete;

  constructor(
    private el: ElementRef<HTMLInputElement>,
    private googlePlacesAutocompleteService: GooglePlacesAutocompleteService,
  ) {}

  ngAfterViewInit(): void {
    this.initAutocomplete();
    this.observePlaceChange();
  }

  ngOnDestroy(): void {
    this.clearAutocompleteListeners();
  }

  private initAutocomplete(): void {
    this.autocomplete = new google.maps.places.Autocomplete(
      this.el.nativeElement,
    );
  }

  private observePlaceChange(): void {
    this.autocomplete.addListener('place_changed', () =>
      this.placeChanged.emit(
        this.googlePlacesAutocompleteService.getAutocompleteAddress(
          this.autocomplete.getPlace(),
        ),
      ),
    );
  }

  private clearAutocompleteListeners(): void {
    google.maps.event.clearInstanceListeners(this.autocomplete);
    const autocompleteContainers =
      document.getElementsByClassName('pac-container');
    Array.from(autocompleteContainers).forEach(el => {
      el.remove();
    });
  }
}
