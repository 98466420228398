import { CheckDepositStatusValues } from '@enums';

import { CheckDepositStatus } from './check-deposit-status';

export class CheckListItem {
  constructor(
    public id: number,
    public creationDate: string,
    public amount: string,
    public depositStatus: CheckDepositStatus,
    public status: CheckDepositStatusValues,
    public statusUpdatedAt: string,
    public cmc7: string,
    public drawer?: string,
    public drawerFirstname?: string,
    public drawerLastname?: string,
  ) {}

  public static fromJson(json: any): CheckListItem {
    return new CheckListItem(
      json.id,
      json.creation_date,
      json.amount,
      CheckDepositStatus.fromJson(json.deposit_status),
      json.status,
      json.status_updated_at,
      json.cmc7,
      json.drawer,
      json.drawer_firstname,
      json.drawer_lastname,
    );
  }
}
