import {
  FormControl,
  FormGroup,
  ValidatorFn,
  Validators,
} from '@angular/forms';

export class CreditCardLimitForm extends FormGroup {
  get limit(): FormControl {
    return this.get('limit') as FormControl;
  }

  constructor(max: number) {
    super({
      limit: new FormControl(null),
    });
    this.limit.setValidators(this.getLimitValidators(max));
  }

  updateValidators(max: number): void {
    this.limit.setValidators(this.getLimitValidators(max));
  }

  private getLimitValidators(max: number): ValidatorFn[] {
    return [Validators.required, Validators.max(max), Validators.min(1)];
  }
}
