import { Clipboard } from '@angular/cdk/clipboard';
import { Directive, HostListener, Input } from '@angular/core';
import { SnackbarConfig, TiimeSnackbarService } from 'tiime-components';

@Directive({
  standalone: true,
  selector: '[appCopyToClipboard]',
})
export class CopyToClipboardDirective {
  @Input() textToCopy: string;
  @Input() confirmationMessage: string;

  @HostListener('click') onClick(): void {
    if (!this.textToCopy) {
      return;
    }

    if (this.clipboard.copy(this.textToCopy) && this.confirmationMessage) {
      this.snackbar.open(this.confirmationMessage, SnackbarConfig.success);
    }
  }
  constructor(
    private readonly clipboard: Clipboard,
    private readonly snackbar: TiimeSnackbarService,
  ) {}
}
