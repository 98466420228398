<div class="empty-state">
  <span class="title">
    <span class="baseline">{{ config.titleBaseline }}</span>
    {{ config.titleSubline }}
  </span>
  <div class="card">
    <span class="helper">{{ config.cardTopHelper }}</span>
    <div class="body">
      <ng-content class="illu" select="[illu]"></ng-content>
      <div class="option-list">
        <span *ngFor="let line of config.cardLinesContent" class="content-text">
          <mat-icon svgIcon="tc_icon-check"></mat-icon>
          {{ line }}
        </span>
      </div>
    </div>
    <span class="helper">{{ config.cardBottomHelper }}</span>
  </div>
  <button
    *ngIf="config.actionFooter"
    tiime-button
    accent
    inverted
    data-cy="empty-state-action"
    (click)="action()"
  >
    {{ config.actionFooter }}
  </button>
  <div *ngIf="config.createHelper" class="create-helper">
    <mat-icon svgIcon="tc_icon-arrow-slim"></mat-icon>
    {{ config.createHelper }}
  </div>
</div>
