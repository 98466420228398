import { Component, Inject, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { BehaviorSubject } from 'rxjs';
import { filter, take, tap } from 'rxjs/operators';
import { TableState } from 'tiime-components';

import { StandardDocumentCategoryIdentifier } from '@enums';
import { Document } from '@models';

import { DocumentPreviewDialogService } from '../../../../../document-shared/components/document-preview-dialog/document-preview-dialog.service';
import { DocumentsTableAction } from '../../../../../document-shared/components/documents-table/columns/actions/documents-actions-column-content/documents-actions-column-content.component';
import { DocumentsTableComponent } from '../../../../../document-shared/components/documents-table/documents-table.component';

export interface ExpensesReportAddReceiptsDialogData {
  categoryId: number[];
  alreadyAddedReceipts: Document[];
}

@Component({
  templateUrl: './expenses-report-add-receipts-dialog.component.html',
  styleUrls: ['./expenses-report-add-receipts-dialog.component.scss'],
})
export class ExpensesReportAddReceiptsDialogComponent {
  @ViewChild(DocumentsTableComponent) documentsTable: DocumentsTableComponent;
  readonly TableState = TableState;

  readonly selection$ = new BehaviorSubject<Document[]>([]);
  readonly receiptsTableState$ = new BehaviorSubject<TableState>(
    TableState.contentPlaceholder,
  );
  readonly displayedColumns = [
    'date',
    'provider',
    'amount',
    'vatAmount',
    'matches',
    'comments',
  ];
  readonly customActions: DocumentsTableAction[] = [
    {
      icon: 'icon-open-in-new-window-2',
      onClick: (document: Document): void => this.openPreview(document),
    },
  ];
  readonly StandardDocumentCategoryIdentifier =
    StandardDocumentCategoryIdentifier;

  constructor(
    @Inject(MAT_DIALOG_DATA)
    public readonly data: ExpensesReportAddReceiptsDialogData,
    private readonly dialogRef: MatDialogRef<
      ExpensesReportAddReceiptsDialogComponent,
      Document[]
    >,
    private readonly documentPreviewDialogService: DocumentPreviewDialogService,
  ) {}

  openPreview(document: Document): void {
    this.documentPreviewDialogService
      .openDocumentPreview({
        document,
      })
      .beforeClosed()
      .pipe(
        filter(({ hasDocumentChanged }) => hasDocumentChanged),
        tap(() => this.documentsTable.reload()),
      )
      .subscribe();
  }

  submitMatch(selection: Document[]): void {
    this.dialogRef.close(selection);
  }

  handleStateChange(state: TableState): void {
    setTimeout(() => this.receiptsTableState$.next(state));
  }

  updateSelection(document: Document): void {
    this.selection$
      .pipe(
        take(1),
        tap(selection => {
          const documentInSelectionIndex = selection.findIndex(
            ({ id }) => id === document.id,
          );
          const newSelection = [...selection];
          if (documentInSelectionIndex !== -1) {
            newSelection.splice(documentInSelectionIndex, 1);
          } else {
            newSelection.push(document);
          }
          this.selection$.next(newSelection);
        }),
      )
      .subscribe();
  }
}
