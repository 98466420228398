import {
  ChronosBankConnection,
  ChronosBankConnectionApiContract,
} from './chronos-bank-connection';

export interface QontoConnectMigrationApiContract {
  target_bank: {
    uuid: string;
  };
  connection: ChronosBankConnectionApiContract;
}

export class QontoConnectMigration {
  constructor(
    public targetBankUUID: string,
    public connection: ChronosBankConnection,
  ) {}

  public static fromJson(
    json: QontoConnectMigrationApiContract,
  ): QontoConnectMigration {
    return new QontoConnectMigration(
      json.target_bank.uuid,
      ChronosBankConnection.fromJson(json.connection),
    );
  }
}
