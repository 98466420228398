import {
  AbstractControl,
  FormControl,
  FormGroup,
  ValidationErrors,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import { FormUtils } from '@manakincubber/tiime-utils';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { tap } from 'rxjs/operators';

import { VEHICLE_WITHOUT_HORSPOWER } from '@core/constants/vehicle-without-horsepower';
import {
  CompensationRateType,
  VehicleTypeEnum,
} from '@enums/mileage-allowances';
import { AllowedTaxHorsepower, VehicleType } from '@models/mileage-allowances';
import { Vehicle } from '@models/mileage-allowances/vehicle';

function CompensationRateValidator(
  computedCompensationRate?: number,
): ValidatorFn {
  return (formControl: AbstractControl): ValidationErrors | null => {
    if (!computedCompensationRate || !formControl.value) {
      return null;
    }

    return formControl.value <= computedCompensationRate
      ? null
      : {
          compensationRateExceeded: true,
        };
  };
}

@UntilDestroy()
export class VehicleForm extends FormGroup {
  get owner(): FormControl {
    return this.get('owner') as FormControl;
  }

  get name(): FormControl {
    return this.get('name') as FormControl;
  }

  get vehicleType(): FormControl {
    return this.get('vehicleType') as FormControl;
  }

  get taxHorsepower(): FormControl {
    return this.get('taxHorsepower') as FormControl;
  }

  get mileage(): FormControl {
    return this.get('mileage') as FormControl;
  }

  get compensationRateType(): FormControl {
    return this.get('compensationRateType') as FormControl;
  }

  get compensationRateValue(): FormControl {
    return this.get('compensationRateValue') as FormControl;
  }

  get registrationDocumentId(): FormControl {
    return this.get('registrationDocumentId') as FormControl;
  }

  get externalAlreadyPaid(): FormControl {
    return this.get('externalAlreadyPaid') as FormControl;
  }

  constructor(private vehicle?: Vehicle) {
    super({
      owner: new FormControl(vehicle?.owner),
      name: new FormControl(vehicle?.name, Validators.required),
      vehicleType: new FormControl(vehicle?.vehicleType),
      taxHorsepower: new FormControl(
        VehicleType.getTaxHorsepowerByKey(
          vehicle?.taxHorsepower,
          vehicle?.vehicleType,
        ),
      ),
      mileage: new FormControl(vehicle?.mileage),
      externalAlreadyPaid: new FormControl(vehicle?.externalAlreadyPaidAmount),
      compensationRateType: new FormControl(
        vehicle?.compensationRate?.type || CompensationRateType.Computed,
      ),
      compensationRateValue: new FormControl(
        {
          value: vehicle?.compensationRate?.value,
          disabled:
            !vehicle?.compensationRate?.type ||
            vehicle?.compensationRate?.type === CompensationRateType.Computed,
        },
        { validators: [CompensationRateValidator()], updateOn: 'blur' },
      ),
      registrationDocumentId: new FormControl(vehicle?.registrationDocumentId),
    });

    this.toggleTaxHorsepower(vehicle?.vehicleType.key);
    this.listenToVehicleTypeValueChange();
    this.listenToCompensationRateTypeValueChange();
  }

  setComputedCompensationRate(computedCompensationRate: number): void {
    this.compensationRateValue.setValidators(
      CompensationRateValidator(computedCompensationRate),
    );
    this.compensationRateValue.updateValueAndValidity();
  }

  toVehicle(): Vehicle {
    return new Vehicle(
      this.vehicle?.id,
      this.vehicle?.createdAt ?? new Date().toISOString(),
      this.owner.value,
      this.name.value,
      this.vehicleType.value as VehicleType,
      (this.taxHorsepower.value as AllowedTaxHorsepower)?.key,
      this.mileage.value ?? 0,
      {
        type: this.compensationRateType.value as CompensationRateType,
        value: this.compensationRateValue.value as number,
      },
      this.registrationDocumentId.value,
      this.externalAlreadyPaid.value ?? 0,
    );
  }

  private toggleTaxHorsepower(vehicleType: VehicleTypeEnum): void {
    this.taxHorsepower.enable(FormUtils.shouldNotEmitEvent);
    if (VEHICLE_WITHOUT_HORSPOWER.includes(vehicleType)) {
      this.taxHorsepower.disable(FormUtils.shouldNotEmitEvent);
    }
  }

  private listenToVehicleTypeValueChange(): void {
    this.vehicleType.valueChanges
      .pipe(
        tap(({ key, allowedTaxHorsepower }: VehicleType) => {
          this.patchValue(
            {
              taxHorsepower: allowedTaxHorsepower[0],
            },
            FormUtils.shouldNotEmitEvent,
          );

          this.toggleTaxHorsepower(key);
        }),
        untilDestroyed(this),
      )
      .subscribe();
  }

  private listenToCompensationRateTypeValueChange(): void {
    this.compensationRateType.valueChanges
      .pipe(
        tap((compensationRateType: CompensationRateType) => {
          if (compensationRateType === CompensationRateType.Computed) {
            this.compensationRateValue.disable(FormUtils.shouldNotEmitEvent);
          } else if (!this.compensationRateValue.enabled) {
            this.compensationRateValue.enable(FormUtils.shouldNotEmitEvent);
          }
        }),
        untilDestroyed(this),
      )
      .subscribe();
  }
}
