import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  Output,
  ViewChild,
} from '@angular/core';
import { FormArray, FormControl } from '@angular/forms';
import { MatMenuTrigger } from '@angular/material/menu';
import { NgUtils } from '@manakincubber/tiime-utils';
import { BehaviorSubject } from 'rxjs';
import { tap } from 'rxjs/operators';
import { ResetFilter } from 'tiime-components';

import { MenuPositions } from '@core/types';
import { Label } from '@models/labels';
import { TableColumnSortAndFilter } from '@shared';

import { TableColumnSortAndFilterDirective } from '../table-column-sort-and-filter.directive';
import { TableLabelFilterForm } from './table-label-filter.form';

@Component({
  selector: 'app-table-label-filter',
  templateUrl: './table-label-filter.component.html',
  styleUrls: [
    '../table-column-filter-base.scss',
    '../table-column-filter-list-base.scss',
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TableLabelFilterComponent extends TableColumnSortAndFilterDirective {
  @Input() allowFilterWithout = true;

  @Input() menuPositions: MenuPositions;

  @Input() set isOpen(value: boolean) {
    if (value) {
      this.searchControl.setValue('');
      this.focusSearchInput();
    }
  }
  @Input() set labels(value: Label[]) {
    const sortedLabels = value?.sort((a, b) => a.label.localeCompare(b.label));
    this._labels = sortedLabels;
    this.filteredLabels = sortedLabels;
    if (sortedLabels && this.form) {
      this.form.labels = sortedLabels;
      this.form.fromParam(this.filterValue ?? '');
    }
  }
  get labels(): Label[] {
    return this._labels;
  }
  get filtersFormArray(): FormArray {
    return this.form.controls.filters as FormArray;
  }

  @Output() resetFilters = new EventEmitter<TableColumnSortAndFilter>();

  searchControl = new FormControl();
  filteredLabels: Label[];
  form: TableLabelFilterForm;
  private _labels: Label[];
  readonly filterKey = 'labels';
  readonly isSearching$ = new BehaviorSubject<boolean>(false);
  readonly trackById = NgUtils.trackById;

  @ViewChild('search') private readonly search: ElementRef<HTMLInputElement>;

  constructor(menuTrigger: MatMenuTrigger) {
    super(menuTrigger);
    this.initSearch();
  }

  protected initForm(): void {
    this.form = new TableLabelFilterForm(
      this.filterKey,
      this.sortKey,
      this.sort,
      this._labels ?? [],
    );
    this.form.fromParam(this.filterValue ?? '');
  }

  private focusSearchInput(): void {
    setTimeout(() => {
      this.search.nativeElement.focus();
    });
  }

  private initSearch(): void {
    this.searchControl.valueChanges
      .pipe(
        tap((value: string) => this.isSearching$.next(!!value?.length)),
        tap((value: string) => {
          this.filteredLabels = this.labels?.filter(t =>
            t.label.toLowerCase().includes(value.toLowerCase()),
          );
        }),
      )
      .subscribe();
  }

  reset(): void {
    this.resetFilters.emit({
      sort: { active: undefined, direction: undefined },
      filter: ResetFilter.forKey(this.filterKey),
    });
    this.initForm();
    this.menuTrigger.closeMenu();
  }
}
