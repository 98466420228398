<div
  class="input-container-wrapper"
  [class.input-container-wrapper--with-label]="!!label"
  [class.empty]="control.empty"
>
  <span *ngIf="suffix" class="suffix">{{ suffix }}</span>
  <mat-icon
    *ngIf="suffixIcon"
    class="suffix suffix-icon"
    [svgIcon]="suffixIcon"
  ></mat-icon>
  <ng-content></ng-content>
  <label *ngIf="label" data-cy="input-container-label" [attr.for]="control.id">
    {{ label }}
  </label>
  <ng-container *ngIf="errorMessage">
    <mat-icon class="suffix warning-icon" svgIcon="tc_icon-warning"></mat-icon>
    <span class="error-label" data-cy="input-container-error">
      {{ errorMessage }}
    </span>
  </ng-container>
</div>
