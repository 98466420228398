import { COMMA, ENTER, SPACE } from '@angular/cdk/keycodes';
import { Component, Input } from '@angular/core';
import { MatChipInputEvent } from '@angular/material/chips';

import { RecipientMailFormArray } from './mail-recipient-form-array';

@Component({
  selector: 'tiime-mail-recipient',
  templateUrl: './mail-recipient.component.html',
  styleUrls: ['./mail-recipient.scss'],
})
export class TiimeMailRecipientComponent {
  @Input() formArray: RecipientMailFormArray;
  @Input() label: string;
  @Input() color: string;
  @Input() errorMessage: string;

  get isFocused(): boolean {
    return this.formArray.controls.length > 0;
  }

  readonly separatorKeysCodes = [ENTER, COMMA, SPACE];

  add(event: MatChipInputEvent): void {
    const input = event.input;
    const value = event.value;
    if ((value || '').trim()) {
      this.formArray.addRecipient(value.trim());
    }
    if (input) {
      input.value = '';
    }
  }

  remove(index: number): void {
    this.formArray.removeRecipient(index);
  }
}
