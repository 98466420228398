import { Dialog } from '@angular/cdk/dialog';
import { Overlay } from '@angular/cdk/overlay';
import { ComponentType } from '@angular/cdk/portal';
import { inject, Injectable, OnDestroy } from '@angular/core';
import { tap } from 'rxjs/operators';

import { SIDE_PANEL_DATA, SidePanelConfig } from './side-panel-config';
import { SidePanelContainerComponent } from './side-panel-container/side-panel-container.component';
import { SidePanelRef } from './side-panel-ref';

@Injectable({ providedIn: 'root' })
export class SidePanelService implements OnDestroy {
  private readonly dialog = inject(Dialog);
  private readonly overlay = inject(Overlay);

  private openedSidePanelRef: SidePanelRef | null = null;

  open<TComponent, TData = unknown, TResult = unknown>(
    component: ComponentType<TComponent>,
    config?: SidePanelConfig<TData>,
  ): SidePanelRef<TComponent, TResult> {
    const finalConfig = { ...new SidePanelConfig<TData>(), ...config };
    let ref: SidePanelRef<TComponent, TResult>;

    this.dialog.open<TResult, TData, TComponent>(component, {
      ...finalConfig,
      // We need to sync it with the close animation.
      disableClose: true,
      // We need to sync it with the close animation.
      closeOnOverlayDetachments: false,
      maxWidth: '100%',
      height: '100%',
      container: SidePanelContainerComponent,
      scrollStrategy:
        finalConfig.scrollStrategy || this.overlay.scrollStrategies.block(),
      positionStrategy: this.overlay
        .position()
        .global()
        .top('0')
        .right('0')
        .bottom('0'),
      providers: (cdkRef, _cdkConfig, container) => {
        ref = new SidePanelRef(
          cdkRef,
          finalConfig,
          container as SidePanelContainerComponent,
        );
        return [
          { provide: SidePanelRef, useValue: ref },
          { provide: SIDE_PANEL_DATA, useValue: finalConfig.data },
        ];
      },
    });

    ref?.afterClosed$().subscribe(() => {
      if (this.openedSidePanelRef === ref) {
        this.openedSidePanelRef = null;
      }
    });

    if (this.openedSidePanelRef) {
      // If a side panel is already in view, dismiss it and enter the
      // new side panel after exit animation is complete.
      this.openedSidePanelRef
        .afterClosed$()
        .pipe(tap(() => ref?.containerInstance?.enter()))
        .subscribe();
      this.openedSidePanelRef.close();
    } else {
      ref?.containerInstance.enter();
    }

    this.openedSidePanelRef = ref;
    return ref;
  }

  dismiss<TResult = unknown>(result?: TResult): void {
    if (this.openedSidePanelRef) {
      this.openedSidePanelRef.close(result);
    }
  }

  ngOnDestroy(): void {
    this.dismiss();
  }
}
