import { Component, ChangeDetectionStrategy, Input } from '@angular/core';
import { Mapper, NgUtils } from '@manakincubber/tiime-utils';

import { DocumentEditorTableColumn } from './document-editor-table-column';

@Component({
  selector: 'app-document-editor-table-header',
  templateUrl: './document-editor-table-header.component.html',
  styleUrls: ['./document-editor-table-header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DocumentEditorTableHeaderComponent {
  @Input() columns: DocumentEditorTableColumn[];
  @Input() color: string;

  readonly trackByIndex = NgUtils.trackByIndex;

  readonly mapToColumnStyles: Mapper<
    DocumentEditorTableColumn,
    Record<string, string>
  > = column => {
    return {
      'justify-content': column.align && this.mapToFlexAlign(column.align),
      'max-width': column.width,
    };
  };

  private mapToFlexAlign(
    align: 'left' | 'right' | 'center',
  ): 'flex-start' | 'center' | 'flex-end' {
    if (align === 'center') {
      return 'center';
    } else if (align === 'left') {
      return 'flex-start';
    } else {
      return 'flex-end';
    }
  }
}
