import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TiimePipesModule } from '@manakincubber/tiime-utils';
import { TiimeLetModule, TiimeTooltipModule } from 'tiime-components';

import { BankLogoComponent } from './bank-logo.component';

@NgModule({
  declarations: [BankLogoComponent],
  imports: [CommonModule, TiimePipesModule, TiimeLetModule, TiimeTooltipModule],
  exports: [BankLogoComponent],
})
export class BankLogoModule {}
