import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';
import { isValidPhoneNumber } from 'libphonenumber-js/min';

export class PhoneHelper {
  static isValidNumber(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      if (!control?.value) return null;

      return isValidPhoneNumber(control.value as string, 'FR')
        ? null
        : { isValidNumber: true };
    };
  }
}
