import { CommonModule, NgOptimizedImage } from '@angular/common';
import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import {
  MAT_DIALOG_DATA,
  MatDialogModule,
  MatDialogRef,
} from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { map } from 'rxjs';
import {
  TiimeButtonModule,
  TiimeDialogCloseButtonComponent,
  TiimeLetModule,
} from 'tiime-components';

import { CompanyConfigService } from '@core';
import { Label, LabelUpdateEvent } from '@models/labels';
import { Tag } from '@models/tag';
import { LabelsService } from '@services/labels.service';

import { LockedDocumentsWarningComponent } from '../../../document/document-shared/components/locked-documents-warning/locked-documents-warning.component';
import { LabelSelectorModule } from '../../components/label-selector/label-selector.module';

export type MassActionsLabelDialogData = {
  documentIds: number[];
  numberOfBlockedDocuments: number;
  isExternalInvoices: boolean;
};

export type MassActionsLabelDialogResponse = {
  labelOrTag: Label | Tag;
  documentsToUpdateIds: number[];
};

@Component({
  selector: 'app-mass-actions-label-dialog',
  standalone: true,
  imports: [
    CommonModule,
    NgOptimizedImage,
    MatIconModule,
    MatDialogModule,
    TiimeDialogCloseButtonComponent,
    LockedDocumentsWarningComponent,
    TiimeButtonModule,
    LabelSelectorModule,
    TiimeLetModule,
  ],
  templateUrl: './mass-actions-label-dialog.component.html',
  styleUrls: ['./mass-actions-label-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MassActionsLabelDialogComponent {
  private readonly dialogRef =
    inject<
      MatDialogRef<
        MassActionsLabelDialogComponent,
        MassActionsLabelDialogResponse
      >
    >(MatDialogRef);

  private readonly labelsService = inject(LabelsService);
  private readonly companyConfigService = inject(CompanyConfigService);

  readonly data = inject<MassActionsLabelDialogData>(MAT_DIALOG_DATA);
  readonly labels$ = this.labelsService
    .getUnpaginatedLabels()
    .pipe(
      map(labels =>
        this.data.isExternalInvoices ? labels.filter(l => l.client) : labels,
      ),
    );
  readonly labelCreationEnabled$ = this.companyConfigService
    .get()
    .pipe(map(config => config.labelCreationEnabled));

  labelOrTag: Label | Tag;

  setLabelOrTags(entity: LabelUpdateEvent): void {
    this.labelOrTag = entity.value;
  }

  submit(): void {
    this.dialogRef.close({
      labelOrTag: this.labelOrTag,
      documentsToUpdateIds: this.data.documentIds,
    });
  }
}
