import { HttpErrorResponse } from '@angular/common/http';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
} from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { of } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';

import { UploadFileBase } from '@core';
import { SepaPayment } from '@core/models';
import { SepaPaymentsService } from '@core/services/wallet/sepa-payments.service';
import { ImportDialogConfig } from '@shared';

@Component({
  selector: 'app-sepa-payements-import-dialog',
  templateUrl: './sepa-payements-import-dialog.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SepaPaymentsImportDialogComponent extends UploadFileBase<
  SepaPayment[]
> {
  errorList: string;
  readonly config: ImportDialogConfig = {
    dialogTitle: 'Importer une liste de virements',
    modelName: 'ExempleDeFichier.xml',
    illustration: 'assets/illus/illu-xml-group-transfers.svg',
    errorIllustration: 'assets/illu-import-clients-erreur.svg',
    fileType: '.xml',
    description:
      'Les formats XML Pain.001.001.02 et Pain.001.001.03 sont supportés.',
  };

  constructor(
    private readonly dialogRef: MatDialogRef<SepaPaymentsImportDialogComponent>,
    private readonly sepaPaymentsService: SepaPaymentsService,
    cdr: ChangeDetectorRef,
  ) {
    super(cdr);
  }

  downloadModel(): void {
    this.sepaPaymentsService.getImportModel().subscribe();
  }

  importFile(file: File): void {
    this.upload(this.sepaPaymentsService.import(file))
      .pipe(
        catchError(({ error }: HttpErrorResponse) =>
          of(error.error_description),
        ),
        tap((errorDescription: string) => (this.errorList = errorDescription)),
      )
      .subscribe();
  }

  protected onFileUploaded(): void {
    this.dialogRef.close(true);
  }
}
