import {
  AbstractControl,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { FormUtils } from '@manakincubber/tiime-utils';
import moment from 'moment';
import { filter, tap } from 'rxjs';

import { InvoicingTemplate } from '@enums';
import { MomentHelper, VatTypeHelper } from '@helpers';
import {
  Invoice,
  InvoicingConfig,
  InvoicingDocumentSeller,
  Quote,
  Tag,
  VatType,
} from '@models';

import { DeliveryAddressForm } from '../forms/delivery-address-form';
import { DiscountForm } from '../forms/discount-form';
import { InvoicingClientForm } from '../forms/invoicing-client-form';
import { InvoicingDocumentConfigForm } from '../forms/invoicing-document-config-form';
import { InvoicingDocumentSellerFooterForm } from '../forms/invoicing-document-seller-footer.form';
import { InvoicingDocumentSellerHeaderForm } from '../forms/invoicing-document-seller-header-form';
import { LineForm } from '../forms/line-form';
import { LinesFormArray } from '../forms/lines-form-array';
import { LogoForm } from '../forms/logo-form';

export abstract class AbstractInvoicingDocumentForm<
  T extends Invoice | Quote,
> extends FormGroup {
  get id(): FormControl {
    return this.get('id') as FormControl;
  }

  get logo(): LogoForm {
    return this.get('logo') as LogoForm;
  }

  get number(): FormControl {
    return this.get('invoicingDocumentNumber') as FormControl;
  }

  get freeField(): FormControl {
    return this.get('freeField') as FormControl;
  }

  get template(): FormControl {
    return this.get('template') as FormControl;
  }

  get linesForm(): LinesFormArray {
    return this.get('lines') as LinesFormArray;
  }

  get linesFormErrorMessage(): string {
    return this.linesForm.touched && this.linesForm.hasError('required')
      ? 'Au moins une ligne est requise'
      : '';
  }

  get totalsPerVatType(): FormControl {
    return this.get('totalsPerVatType') as FormControl;
  }

  get footer(): FormControl {
    return this.get('footer') as FormControl;
  }

  get title(): FormControl {
    return this.get('title') as FormControl;
  }

  get header(): FormControl {
    return this.get('header') as FormControl;
  }

  get headerErrorMessage(): string {
    return this.header.touched && this.header.hasError('required')
      ? `Vos coordonnées sont requises`
      : '';
  }

  get emissionDate(): FormControl {
    return this.get('emissionDate') as FormControl;
  }

  get emissionDateErrorMessage(): string {
    return this.emissionDate.touched && this.emissionDate.hasError('required')
      ? `La date d'émission est requise`
      : '';
  }

  get color(): FormControl {
    return this.get('color') as FormControl;
  }

  get totalExcludingTaxes(): FormControl {
    return this.get('totalExcludingTaxes') as FormControl;
  }

  get totalIncludingTaxes(): FormControl {
    return this.get('totalIncludingTaxes') as FormControl;
  }

  get invoicingClientForm(): InvoicingClientForm {
    return this.get('client') as InvoicingClientForm;
  }

  get sellerHeaderForm(): InvoicingDocumentSellerHeaderForm {
    return this.get('sellerHeader') as InvoicingDocumentSellerHeaderForm;
  }

  get documentStatus(): FormControl {
    return this.get('status') as FormControl;
  }

  get deliveryAddress(): DeliveryAddressForm {
    return this.get('deliveryAddress') as DeliveryAddressForm;
  }

  get nonApplicableVatReasonEnabled(): FormControl {
    return this.get('nonApplicableVatReasonEnabled') as FormControl;
  }

  get nonApplicableVatReason(): FormControl {
    return this.get('nonApplicableVatReason') as FormControl;
  }

  get config(): InvoicingDocumentConfigForm {
    return this.get('config') as InvoicingDocumentConfigForm;
  }

  get discount(): DiscountForm {
    return this.get('discount') as DiscountForm;
  }

  get comment(): FormControl {
    return this.get('comment') as FormControl;
  }

  get tags(): FormControl<Tag[]> {
    return this.get('tags') as FormControl<Tag[]>;
  }

  get unitsEnabled(): FormControl {
    return this.get('unitsEnabled') as FormControl;
  }

  get sellerFooterForm(): InvoicingDocumentSellerFooterForm {
    return this.get('sellerFooter') as InvoicingDocumentSellerFooterForm;
  }

  protected constructor(controls?: { [key: string]: AbstractControl }) {
    super({
      id: new FormControl(null),
      logo: new LogoForm(),
      status: new FormControl(null),
      title: new FormControl(null),
      invoicingDocumentNumber: new FormControl(null),
      emissionDate: new FormControl(new Date()),
      client: new InvoicingClientForm(),
      sellerHeader: new InvoicingDocumentSellerHeaderForm(),
      sellerFooter: new InvoicingDocumentSellerFooterForm(),
      totalExcludingTaxes: new FormControl(null),
      totalIncludingTaxes: new FormControl(null),
      lines: new LinesFormArray(),
      color: new FormControl(null),
      nonApplicableVatReason: new FormControl(null),
      nonApplicableVatReasonEnabled: new FormControl(false),
      header: new FormControl(null, Validators.maxLength(500)),
      footer: new FormControl(null, Validators.maxLength(500)),
      freeField: new FormControl(null),
      template: new FormControl(null),
      totalsPerVatType: new FormControl(),
      pdfFilename: new FormControl(null),
      deliveryAddress: new DeliveryAddressForm(),
      config: new InvoicingDocumentConfigForm(),
      discount: new DiscountForm(),
      comment: new FormControl(null),
      tags: new FormControl([]),
      unitsEnabled: new FormControl(),
      ...controls,
    });
    this.sellerHeaderForm.controls.country.valueChanges
      .pipe(
        filter(() => this.enabled),
        tap(() =>
          this.sellerFooterForm.setInvoicingSavedStatusValidators({
            isEDocument: this.template.value === InvoicingTemplate.eInvoice,
            isFrenchSeller:
              this.sellerHeaderForm.controls.country.value?.toUpperCase() ===
              'FR',
          }),
        ),
      )
      .subscribe();
  }

  getFirstLineWithZeroVatTypeCode(vatTypes: VatType[]): string | undefined {
    return this.linesForm.amountLines().find(({ vatTypeCode }: LineForm) =>
      vatTypes
        .filter(VatType.filterZeroRateVatTypes)
        .map(({ code }: VatType) => code)
        .includes(vatTypeCode.value),
    )?.value.vatType.code;
  }

  fromInvoicingDocumentAndMarkAsPristine(invoicingDocument: T): void {
    this.fromInvoicingDocument(invoicingDocument);
    this.markAsPristine();
  }

  setSavedStatusValidators(): void {
    this.emissionDate.setValidators(Validators.required);
    this.emissionDate.updateValueAndValidity(FormUtils.shouldNotEmitEvent);
    this.header.setValidators(
      this.template.value !== InvoicingTemplate.eInvoice
        ? Validators.required
        : null,
    );
    this.header.updateValueAndValidity(FormUtils.shouldNotEmitEvent);
    this.invoicingClientForm.setClientValidators({
      sirenOrSiretRequired: !!this.config.controls.sirenOrSiretEnabled.value,
      intracomVatNumberRequired:
        !!this.config.controls.intracomVatNumberEnabled.value,
      clientIdRequired: true,
    });
    this.sellerHeaderForm.setRequired(
      this.template.value === InvoicingTemplate.eInvoice,
    );
    this.deliveryAddress.setRequired(
      this.config.controls.deliveryAddressEnabled.value,
    );
    this.linesForm.setInvoicingSavedStatusValidators();
    this.discount.setRequired(this.config.controls.discountEnabled.value);
    this.sellerFooterForm.setInvoicingSavedStatusValidators({
      isEDocument: this.template.value === InvoicingTemplate.eInvoice,
      isFrenchSeller:
        this.sellerHeaderForm.controls.country.value?.toUpperCase() === 'FR',
    });
    this.updateValueAndValidity(FormUtils.shouldNotEmitEvent);
  }

  fromInvoicingDocument(invoicingDocument: T): void {
    const patchObject = { ...invoicingDocument } as Parameters<
      FormGroup['patchValue']
    >[0];
    delete patchObject.lines;
    delete patchObject.seller;
    if (!invoicingDocument.client.country) {
      patchObject.client = {
        ...invoicingDocument.client,
        country: { id: null },
      };
    }
    if (invoicingDocument.seller) {
      patchObject.sellerHeader = {
        name: invoicingDocument.seller.name,
        address: invoicingDocument.seller.address,
        addressComplement: invoicingDocument.seller.addressComplement,
        city: invoicingDocument.seller.city,
        postalCode: invoicingDocument.seller.postalCode,
        country: invoicingDocument.seller.country,
        email: invoicingDocument.seller.email,
        phone: invoicingDocument.seller.phone,
      };
      patchObject.sellerFooter = {
        legalForm: invoicingDocument.seller.legalForm,
        capital: invoicingDocument.seller.capital,
        apeCode: invoicingDocument.seller.apeCode,
        rcsCity: invoicingDocument.seller.rcsCity,
        siret: invoicingDocument.seller.siret,
        intracomVatNumber: invoicingDocument.seller.vatNumber,
      };
    }
    if (!invoicingDocument.deliveryAddress.country) {
      patchObject.deliveryAddress = {
        ...invoicingDocument.deliveryAddress,
        country: { id: null },
      };
    }
    if (!invoicingDocument.config.discountEnabled) {
      this.discount.disable({ emitEvent: false });
    }
    this.patchValue(patchObject, FormUtils.shouldNotEmitEvent);
  }

  setTotalExcludingTaxes(totalExcludingTaxes: number): void {
    this.totalExcludingTaxes.setValue(
      totalExcludingTaxes,
      FormUtils.shouldNotEmitEvent,
    );
  }

  setTotalIncludingTaxes(totalIncludingTaxes: number): void {
    this.totalIncludingTaxes.setValue(
      totalIncludingTaxes,
      FormUtils.shouldNotEmitEvent,
    );
  }

  updateNonApplicableVatReason(vatTypes: VatType[]): void {
    const firstLineWithZeroVatTypeCode = this.linesForm
      .amountLines()
      .find(({ vatTypeCode }: LineForm) =>
        vatTypes.map(({ code }: VatType) => code).includes(vatTypeCode.value),
      );

    if (firstLineWithZeroVatTypeCode) {
      this.nonApplicableVatReasonEnabled.setValue(
        true,
        FormUtils.shouldNotEmitEvent,
      );
      this.nonApplicableVatReason.setValue(
        VatTypeHelper.hasCustomLegalNotice(
          vatTypes,
          this.nonApplicableVatReason.value as string,
        )
          ? this.nonApplicableVatReason.value
          : VatTypeHelper.vatTypeLegalNotice(
              vatTypes,
              firstLineWithZeroVatTypeCode.vatTypeCode.value,
            ),
        FormUtils.shouldNotEmitEvent,
      );
    } else {
      this.nonApplicableVatReasonEnabled.setValue(
        false,
        FormUtils.shouldNotEmitEvent,
      );
      this.nonApplicableVatReason.setValue(null, FormUtils.shouldNotEmitEvent);
    }
  }

  protected toInvoicingDocument(): T {
    const formValue = this.getRawValue();
    const invoicingDocument = this.getRawValue() as T;
    invoicingDocument.template = this.config.controls.template.value;

    invoicingDocument.seller = new InvoicingDocumentSeller(
      formValue.sellerHeader.name as string,
      formValue.sellerHeader.address as string,
      formValue.sellerHeader.addressComplement as string,
      formValue.sellerHeader.city as string,
      formValue.sellerHeader.postalCode as string,
      formValue.sellerHeader.country as string,
      formValue.sellerFooter.legalForm as string,
      formValue.sellerFooter.siret as string,
      formValue.sellerFooter.intracomVatNumber as string,
      formValue.sellerFooter.apeCode as string,
      formValue.sellerFooter.rcsCity as string,
      formValue.sellerFooter.capital as number,
      formValue.sellerHeader.email as string,
      formValue.sellerHeader.phone as string,
    );

    invoicingDocument.lines = formValue.lines.filter(
      (line: Record<string, unknown>) => 'totalExcludingTaxes' in line,
    );
    invoicingDocument.textLines = formValue.lines.filter(
      (line: Record<string, unknown>) => !('totalExcludingTaxes' in line),
    );

    if (invoicingDocument.emissionDate) {
      invoicingDocument.emissionDate = MomentHelper.toCalendarDateString(
        moment(invoicingDocument.emissionDate),
      );
    }
    if (invoicingDocument.client) {
      invoicingDocument.client.sirenOrSiret =
        invoicingDocument.client?.sirenOrSiret?.replace(/\s/g, '');
    }

    return invoicingDocument;
  }

  abstract fromInvoicingConfig(invoicingConfig: InvoicingConfig): void;
}
