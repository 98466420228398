import { BankTransactionImputation } from '../bank-transaction';
import { LinkedEntity, LinkedEntityType } from './linked-entity';
import { LinkedEntityBankTransactionValueBase } from './linked-entity-bank-transaction';
import { LinkedEntityBase } from './linked-entity-base';

export interface LinkedEntityImputationValue
  extends LinkedEntityBase,
    LinkedEntityBankTransactionValueBase {
  parentId?: number;
  amount: number;
  currency: string;
}

export class LinkedEntityImputation extends LinkedEntity<LinkedEntityImputationValue> {
  type = LinkedEntityType.IMPUTATION;

  constructor(value: LinkedEntityImputationValue) {
    super(value);
  }

  static fromBankTransactionImputation(
    imputation: BankTransactionImputation,
  ): LinkedEntityImputation {
    return new LinkedEntityImputation({
      id: imputation.id,
      wording: imputation.wording,
      date: imputation.realizationDate,
      operationType: imputation.operationType,
      bankAccount: {
        bank: {
          name: imputation.shortBankName,
          acronym: undefined,
          logoUrl: undefined,
        },
      },
      amount: imputation.amount,
      currency: imputation.currency,
    });
  }
}
