<div
  *ngIf="
    labelDisabledBy === LabelDisabledByEnum.Accountant;
    then disabledByAccountant;
    else disabledByTiimer
  "
  data-cy="label-archived-text__txt"
></div>
<ng-template #disabledByAccountant>
  Ce label a été archivé par votre expert-comptable, il ne peut
  <br />
  plus être ajouté sur une transaction ou un document.
</ng-template>
<ng-template #disabledByTiimer>
  Ce label a été archivé, il ne peut plus être
  <br />
  ajouté sur une transaction ou un document
</ng-template>
