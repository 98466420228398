export enum ActionsType {
  Label = 'label',
  Move = 'move',
  Tag = 'tag',
  Delete = 'delete',
  Download = 'download',
  PaymentPaid = 'payment_paid',
  PaymentPending = 'payment_pending',
  Link = 'link',
}
