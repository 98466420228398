<section class="container" [formGroup]="form">
  <h2 class="subtitle" data-cy="bank-transfer__subtitle">d'un montant de</h2>

  <amount-form-control [formControl]="form.amount"></amount-form-control>

  @if ((ocerisationStep$ | async) === OcerisationStep.DONE) {
    <div class="warning-container">
      <div class="warning-container__lottie">
        <ng-lottie height="52px" [options]="loaderOptions"></ng-lottie>
      </div>
      <p class="warning-container__description">
        Pensez à vérifier si les
        <br />
        informations saisies sont correctes
      </p>
    </div>
  }

  <div class="accounts">
    <app-account-selector
      addAccountPosition="bottom"
      formControlName="account"
      data-cy="bank-tansfer__debitor"
      [accounts]="bankAccounts"
      [canAddAccount]="false"
      [readonly]="true"
    ></app-account-selector>
  </div>

  <div class="beneficiaries">
    <app-account-selector
      formControlName="beneficiary"
      data-cy="bank-tansfer__creditor"
      [isBeneficiarySelector]="true"
      [accounts]="beneficiaries"
      [autocompleteAddAccountWithFilter]="true"
      [personalWalletBeneficiary]="personalWalletBeneficiary"
      [reason]="reason"
      (addAccount)="addBeneficiary($event)"
    ></app-account-selector>
    <div *ngIf="reason === 'refund'" class="personal-wallet-beneficiary-info">
      <img
        src="assets/misc/icones-communication-support.svg"
        alt="Informations sur le compte de remboursement"
      />
      <span>
        Vous pourrez le modifier si besoin lors de vos prochains remboursements
        ou depuis la liste de vos bénéficiaires.
      </span>
    </div>
  </div>

  <div
    *ngIf="!shouldDisplayLastScreenRecap"
    class="sca"
    data-cy="bank-transfer-sca__advise"
  >
    <mat-icon
      class="sca__icon"
      svgIcon="{{ TIIME_ICON_PREFIX + 'shield' }}"
    ></mat-icon>
    <p class="sca__description">
      Cette action nécessite d'avoir lié son téléphone à votre compte Tiime.
      Pour le faire, rendez-vous sur votre application mobile Tiime, dans votre
      profil en haut à gauche, puis dans
      <strong>
        Sécurité et confidentialité > Lier ce téléphone à votre compte.
      </strong>
    </p>
  </div>
</section>
