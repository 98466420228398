<ng-container
  *ngIf="label.client || invoiceClient; then invoiceClientIcon; else acronym"
></ng-container>
<ng-template #invoiceClientIcon>
  <img
    class="icon"
    src="assets/icon-invoice-client.svg"
    alt=""
    data-cy="label-icon__icon-invoice"
    [class.large]="large"
  />
</ng-template>
<ng-template #acronym>
  <div
    class="icon acronym"
    data-cy="label-icon__acronym"
    [class.large]="large"
    [style]="label | map: mapToIconBackgroundColor"
  >
    {{ label | map: mapToAcronymFormat }}
  </div>
</ng-template>
