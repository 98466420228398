import { Directive, inject } from '@angular/core';
import { NgUtils } from '@manakincubber/tiime-utils';
import { TIIME_OVERLAY_DATA, TiimeOverlayRef } from 'tiime-components';

import { FilterOverlayDataBase } from './filter-overlay-data.base';
import { FilterOverlayReturnBase } from './filter-overlay-return.base';

@Directive()
export abstract class FilterOverlayBase<T, D, R, I> {
  readonly trackByIndex = NgUtils.trackByIndex;

  protected overlayRef = inject(
    TiimeOverlayRef<R & FilterOverlayReturnBase<T, I>>,
  );
  public data: D & FilterOverlayDataBase<T> = inject(TIIME_OVERLAY_DATA);

  abstract selectItem(item: T): void;
}
