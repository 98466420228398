import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TiimeChipModule } from 'tiime-components';

import { IconsModule } from '../../icons/icons.module';
import { TagChipComponent } from './tag-chip.component';

@NgModule({
  declarations: [TagChipComponent],
  imports: [CommonModule, TiimeChipModule, IconsModule],
  exports: [TagChipComponent],
})
export class TagChipModule {}
