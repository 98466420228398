<button
  class="close"
  tiime-button
  neutral
  flat
  inverted
  xsmall
  icon
  (click)="snackbarRef.dismiss()"
>
  <mat-icon fontIcon="icon-ic-remove-1"></mat-icon>
</button>

<div class="icon-container">
  <img
    class="icon-background"
    src="assets/illu_transfer_created_to_label.svg"
    draggable="false"
    alt=""
  />
</div>
<div class="infos">
  <span class="infos--title">Votre virement a bien été effectué</span>
  <span>
    Ajoutez ici un label et un justificatif pour les besoins de la comptabilité
  </span>
</div>
