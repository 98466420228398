import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TiimePipesModule } from '@manakincubber/tiime-utils';
import {
  TiimeIconModule,
  TiimeTooltipModule,
  TiimeTwocolorsIconModule,
} from 'tiime-components';

import { IconsModule } from '@shared';

import { OperationTypeLogoComponent } from './operation-type-logo.component';

@NgModule({
  imports: [
    CommonModule,
    TiimePipesModule,
    TiimeTwocolorsIconModule,
    TiimeIconModule,
    IconsModule,
    TiimeTooltipModule,
  ],
  exports: [OperationTypeLogoComponent],
  declarations: [OperationTypeLogoComponent],
})
export class OperationTypeLogoModule {}
