import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MarkFormAsTouchedIfInvalid } from '@manakincubber/tiime-utils';

import { TagsService } from '@core';
import { Tag } from '@models';

import { TagDialogData } from './tag-dialog-data';

export type TagDialogResponse = Tag[] | undefined;

@Component({
  selector: 'app-tag-dialog',
  templateUrl: './tag-dialog.component.html',
  styleUrls: ['./tag-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TagDialogComponent {
  readonly tagSuggestions$ = this.tagsService.getTagsAndTagSuggestions();
  readonly tagForm = new FormControl<Tag[]>([]);

  constructor(
    @Inject(MAT_DIALOG_DATA) readonly data: TagDialogData,
    private readonly tagsService: TagsService,
    private readonly dialogRef: MatDialogRef<
      TagDialogComponent,
      TagDialogResponse
    >,
  ) {
    this.tagForm.patchValue(data.tags);
  }

  @MarkFormAsTouchedIfInvalid('tagForm')
  submitForm(): void {
    this.dialogRef.close(this.tagForm.value);
  }

  close(): void {
    this.dialogRef.close();
  }
}
