import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import {
  HomeRequestActions,
  HomeRequestActionsApiContract,
} from '@core/models';
import { HomeWallet, HomeWalletApiContract } from '@core/models/home-wallet';
import { ApiAlertError } from '@decorators/api-alert-error';
import { TileKey } from '@enums';
import { Tiles, TodoCountsApiContract } from '@models/tiles';

export interface TilesApiResponse {
  create_invoice?: boolean;
  clients_total_due_amount?: number;
  total_sales_amounts?: { current: number; last_month: number };
  to_do_counts?: TodoCountsApiContract;
  count_unread_thread?: number;
}

@Injectable({
  providedIn: 'root',
})
export class TilesService {
  private readonly resource = 'api/v1/companies/{companyId}/tiles';
  private readonly headers = {
    Accept: 'application/vnd.tiime.tile.todo.v2+json',
  };

  constructor(private readonly http: HttpClient) {}

  @ApiAlertError()
  getTiles(tileKeys?: TileKey[]): Observable<Tiles> {
    let params = new HttpParams();

    if (tileKeys?.length > 0) {
      params = params.append('keys', tileKeys.join(','));
    }
    return this.http
      .get<TilesApiResponse>(`${this.resource}`, {
        params,
        headers: this.headers,
      })
      .pipe(map(json => Tiles.fromJson(json)));
  }

  @ApiAlertError()
  getHomeWallet(): Observable<HomeWallet> {
    return this.http
      .get<HomeWalletApiContract>(`${this.resource}/wallet`)
      .pipe(map(homeWalletJson => HomeWallet.fromJson(homeWalletJson)));
  }

  @ApiAlertError()
  getHomeRequestActions(
    filteredActions?: (keyof HomeRequestActionsApiContract)[],
  ): Observable<HomeRequestActions> {
    let params = new HttpParams();

    if (filteredActions?.length > 0) {
      params = params.append('keys', filteredActions.join(','));
    }

    return this.http
      .get<HomeRequestActionsApiContract>(`${this.resource}/request_actions`, {
        params,
        headers: this.headers,
      })
      .pipe(map(requestActions => HomeRequestActions.fromJson(requestActions)));
  }

  /**
   * Delete tiles for the current company. Tiles can be showed again by calling ```POST .../tiles/restore```.
   * @param tileKeys an array of tiles that will not be showed to the company anymore
   * @returns
   */
  @ApiAlertError()
  deleteTiles(tileKeys: TileKey[]): Observable<void> {
    return this.http.delete<void>(`${this.resource}`, {
      body: tileKeys.map(key => ({ key })),
    });
  }

  @ApiAlertError()
  restoreTiles(tileKeys: TileKey[]): Observable<void> {
    return this.http.post<void>(
      `${this.resource}/restore`,
      tileKeys.map(key => ({ key })),
    );
  }
}
