import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatIconModule } from '@angular/material/icon';
import { NgUtils } from '@manakincubber/tiime-utils';

import { TiimeMenuModule } from '../../../menu';

export type QuickSelectionOverlayData = {
  label: string;
  action: () => void;
}[];

@Component({
  standalone: true,
  imports: [CommonModule, TiimeMenuModule, MatCheckboxModule, MatIconModule],
  selector: 'tiime-mass-action-header-cell',
  templateUrl: './mass-action-header-cell.component.html',
  styleUrls: ['./mass-action-header-cell.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MassActionHeaderCellComponent {
  @Input() indeterminate: boolean;
  @Input() areAllSelected: boolean;
  @Input() quickSelection: QuickSelectionOverlayData;

  @Output() readonly selectAll = new EventEmitter<void>();
  @Output() readonly trackingSelection = new EventEmitter<string>();

  readonly trackByIndex = NgUtils.trackByIndex;
}
