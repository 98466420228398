import { IbanUtils } from '@manakincubber/tiime-utils';

import { Bank, BankApiContract } from './bank';
import { BIAccount, BIAccountApiContract } from './bi-account';

export interface BankAccountApiContract {
  id: number;
  authorized_balance: number | null;
  balance_amount: number | null;
  pending_balance: number | null;
  balance_currency: string | null;
  balance_date: Date | null;
  bank_name: string;
  bic: string | null;
  closed: boolean;
  closurable: boolean;
  disableable: boolean;
  enabled: boolean;
  provider_id: number | null;
  iban: string | null;
  is_wallet: boolean;
  last_push_date: Date | null;
  name: string;
  short_bank_name: string;
  bank: Partial<BankApiContract>;
  synchronization_date: Date | null;
  type: string;
}

export interface CreateChronosBankAccountApiContract {
  name: string;
  type: string;
  provider_bank_account_id: number;
  balance_amount?: number;
  balance_currency?: string;
  iban?: string;
}

export class BankAccount {
  get displayBalance(): number {
    return this.isWallet ? this.authorizedBalance : this.balanceAmount;
  }
  get isCheckingType(): boolean {
    return this.type === 'checking';
  }

  get missingIban(): boolean {
    return !this.iban && !this.closed && this.enabled && this.isCheckingType;
  }

  constructor(
    public id: number,
    public iban?: string,
    public bic?: string,
    public name?: string,
    public enabled?: boolean,
    public balanceAmount?: number,
    public pendingBalance?: number,
    public balanceCurrency?: string,
    public bankName?: string,
    public shortBankName?: string,
    public bank?: Bank,
    public isWallet?: boolean,
    public authorizedBalance?: number,
    public providerId?: number,
    public type?: string,
    public disableable?: boolean,
    public closed?: boolean,
    public closurable?: boolean,
    public index?: number,
    public deleted?: string,
    public reconciled?: boolean,
  ) {}

  public static fromBIAccount(biAccount: BIAccount): BankAccount {
    return new BankAccount(
      null,
      biAccount.iban && IbanUtils.isValid(biAccount.iban)
        ? biAccount.iban
        : undefined,
      biAccount.bic,
      biAccount.name,
      !biAccount.disabled,
      biAccount.balance,
      null,
      biAccount.currency?.id,
      null,
      null,
      null,
      false,
      null,
      biAccount.id,
      biAccount.type,
      true,
      false,
      true,
      null,
      biAccount.deleted,
      false,
    );
  }

  public static fromReconciledChronosAndBIAccount(
    chronosAccount: BankAccount,
    biAccount: BIAccount,
  ): BankAccount {
    return new BankAccount(
      chronosAccount.id,
      chronosAccount.iban,
      chronosAccount.bic,
      chronosAccount.name || biAccount.name,
      chronosAccount.enabled,
      biAccount.balance,
      chronosAccount.pendingBalance,
      chronosAccount.balanceCurrency,
      chronosAccount.bankName,
      chronosAccount.shortBankName,
      chronosAccount.bank,
      chronosAccount.isWallet,
      chronosAccount.authorizedBalance,
      biAccount.id,
      chronosAccount.type || biAccount.type,
      chronosAccount.disableable,
      chronosAccount.closed,
      chronosAccount.closurable,
      chronosAccount.index,
      biAccount.deleted,
      true,
    );
  }

  public static fromJson(json: BankAccountApiContract): BankAccount {
    return new BankAccount(
      json.id,
      json.iban,
      json.bic,
      json.name,
      json.enabled,
      json.balance_amount,
      json.pending_balance,
      json.balance_currency,
      json.bank_name,
      json.short_bank_name,
      json.bank ? Bank.fromJson(json.bank as BankApiContract) : null,
      json.is_wallet,
      json.authorized_balance,
      json.provider_id,
      json.type,
      json.disableable,
      json.closed,
      json.closurable,
    );
  }

  public static toJson(
    bankAccount: BankAccount,
  ): Pick<BankAccountApiContract, 'id' | 'iban' | 'bic' | 'name' | 'enabled'> {
    return {
      id: bankAccount.id,
      iban: bankAccount.iban,
      bic: bankAccount.bic,
      name: bankAccount.name,
      enabled: bankAccount.enabled,
    };
  }

  public static toCreateChronosAccountJson(
    bankAccount: BankAccount,
  ): CreateChronosBankAccountApiContract {
    return {
      name: bankAccount.name,
      provider_bank_account_id: bankAccount.providerId,
      type: bankAccount.type,
      balance_amount: bankAccount.balanceAmount,
      balance_currency: bankAccount.balanceCurrency,
      iban: bankAccount.iban || undefined,
    };
  }

  public static toBIJson(
    bankAccount: BankAccount,
  ): Pick<BIAccountApiContract, 'disabled'> {
    return {
      disabled: !bankAccount.enabled,
    };
  }
}
