import { InvoiceStatus, OperationType } from '@enums';

import { ClientTimelineItemDirection, ClientTimelineItemType } from './types';

export interface ClientTimelineItemApiContract {
  id: number;
  type: ClientTimelineItemType;
  direction: ClientTimelineItemDirection;
  date: string;
  amount: number;
  balance_amount: number;
  matched: boolean;
  title: string | null;
  number: number | null;
  status: InvoiceStatus | null;
  wording: string | null;
  bank_name: string | null;
  operation_type: OperationType | null;
  invoice_linked_id: number | null;
  credit_note_linked_id: number | null;
}

export abstract class ClientTimelineItem<
  T extends ClientTimelineItemType = ClientTimelineItemType,
> {
  protected constructor(
    public id: number,
    public date: string,
    public amount: number,
    public balanceAmount: number,
    public matched: boolean,
    public type: T,
    public direction: ClientTimelineItemDirection,
  ) {}
}
