<button class="close-preview-button" tiime-button icon (click)="close()">
  <mat-icon inline="true" fontIcon="icon-ic-croix"></mat-icon>
</button>

<div *tiimeLet="document$ | async as document" mat-dialog-content>
  <div class="document-container" #documentContainer>
    <div class="document-container__left-filler"></div>

    <div class="document-preview-container">
      <app-document-preview
        (previewImported)="previewImported()"
      ></app-document-preview>
    </div>

    <div class="document-information-container">
      <app-document-information
        *ngIf="document"
        [document]="document"
        [categories]="switchableCategories"
        [disableMetadata]="data?.disableMetadata"
        [disableLabel]="data?.disableLabel"
        [disableCategorySelect]="data?.disableCategorySelect"
        [tagSuggestions]="tagSuggestions$ | async"
        (deleteDocument)="openDeleteDialog(document)"
        (downloadDocument)="downloadDocument()"
        (updateDocument)="updateDocument($event)"
        (updateDocumentCategory)="updateCategory($event)"
        (createDocumentCategory)="createCategory($event)"
        (payDocument)="onPayDocument($event)"
        (unmatchLinkedEntity)="unmatchLinkedEntity($event)"
        (linkBankTransactionIds)="linkBankTransaction($event)"
      ></app-document-information>
    </div>
  </div>
</div>
