<img class="tiime-logo" ngSrc="assets/logo_tiime.svg" width="135" height="40" />
<img
  class="illu"
  ngSrc="assets/illus/password/illu_change_password.png"
  width="192"
  height="192"
  alt="bouclier"
/>
<span class="title">
  <ng-container *ngFor="let titleLine of titleWording; trackBy: trackByIndex">
    {{ titleLine }}
    <br />
  </ng-container>
</span>
<span class="subtitle">
  <ng-container
    *ngFor="let subtitleLine of subtitleWording; trackBy: trackByIndex"
  >
    {{ subtitleLine }}
    <br />
  </ng-container>
</span>
<button
  class="reset-button"
  tiime-button
  action
  accent
  [disabled]="(loading$ | async) === true"
  (click)="openUpdatePasswordDialog()"
>
  {{ buttonWording }}
</button>
