import { LinkedEntityBankTransactionValue } from './linked-entity-bank-transaction';
import { LinkedEntityExpenseReportValue } from './linked-entity-expense-report';
import { LinkedEntityImputationValue } from './linked-entity-imputation';

export enum LinkedEntityType {
  BANK_TRANSACTION = 'bank_transaction',
  EXPENSE_REPORT = 'expense_report',
  IMPUTATION = 'imputation',
}

type LinkedEntityValue =
  | LinkedEntityExpenseReportValue
  | LinkedEntityBankTransactionValue
  | LinkedEntityImputationValue;

export abstract class LinkedEntity<
  T extends LinkedEntityValue = LinkedEntityValue,
> {
  type: LinkedEntityType;

  protected constructor(public value: T) {}
}
