export class PaginationRange {
  static readonly DEFAULT_PAGE_SIZE = 25;

  readonly min!: number;
  readonly max!: number;
  readonly count: string | number | undefined;
  readonly pageSize!: number;

  get isFirstPage(): boolean {
    return this.max <= this.pageSize - 1;
  }

  constructor(
    min?: number,
    max?: number,
    count?: string | number,
    pageSize: number = PaginationRange.DEFAULT_PAGE_SIZE,
  ) {
    if (min === undefined || max === undefined) {
      this.min = 0;
      this.max = pageSize - 1;
      this.count = '*';
    } else {
      this.min = min;
      this.max = max;
      this.count = count;
    }
    this.pageSize = pageSize;
  }

  static afterNewItem(currentRange: PaginationRange): PaginationRange {
    return new PaginationRange(
      currentRange.min,
      currentRange.max - currentRange.min < currentRange.pageSize - 1
        ? currentRange.max + 1
        : currentRange.max,
      currentRange.count,
      currentRange.pageSize,
    );
  }

  static afterDeleteItem(currentRange: PaginationRange): PaginationRange {
    if (currentRange.min === currentRange.max && currentRange.min > 0) {
      return new PaginationRange(
        currentRange.min - currentRange.pageSize,
        currentRange.min,
        currentRange.count,
        currentRange.pageSize,
      );
    }
    return new PaginationRange(
      currentRange.min,
      currentRange.max,
      currentRange.count,
      currentRange.pageSize,
    );
  }

  static fromHttpResponseHeaders(
    contentRangerHeader: string,
    pageSize?: number,
  ): PaginationRange {
    const rangeRegex = /(^[a-zA-Z]\w*)\s+(\d+)\s?-\s?(\d+)?\s?\/?\s?(\d+|\*)?/;
    const matches = rangeRegex.exec(contentRangerHeader);
    let min: number | undefined;
    let max: number | undefined;
    let count;

    if (matches !== null) {
      count = isNaN(Number(matches[4])) ? matches[4] : Number(matches[4]);
      min = Number(matches[2]);
      max = count === '*' ? Number(matches[3]) - 1 : Number(matches[3]);
    }
    return new PaginationRange(min, max, count, pageSize);
  }

  static fromQueryParams(
    queryParams: string,
    pageSize: number = PaginationRange.DEFAULT_PAGE_SIZE,
  ): PaginationRange {
    const pageNumber = Number(queryParams);
    if (isNaN(pageNumber)) {
      return new PaginationRange(0, pageSize - 1, '*', pageSize);
    }

    const min = (pageNumber - 1) * pageSize;
    return new PaginationRange(min, min + pageSize - 1, '*', pageSize);
  }

  static toQueryParams(range: PaginationRange): string {
    return `${range.min / range.pageSize + 1}`;
  }

  static withPageSize(pageSize?: number): PaginationRange {
    return new PaginationRange(undefined, undefined, undefined, pageSize);
  }

  next(): PaginationRange | undefined {
    if (!isNaN(Number(this.count))) {
      return undefined;
    }

    const min = this.min + this.pageSize;
    const max = min + this.pageSize - 1;
    return new PaginationRange(min, max, undefined, this.pageSize);
  }

  previous(): PaginationRange | undefined {
    if (this.min === 0) {
      return undefined;
    }

    const min = this.min - this.pageSize < 0 ? 0 : this.min - this.pageSize;
    const max = min + this.pageSize - 1;
    return new PaginationRange(min, max, undefined, this.pageSize);
  }

  toHttpRequestHeader(): string {
    return `items=${this.min}-${this.max}`;
  }
}
