import { OperationType } from '@enums';

import {
  ClientTimelineItem,
  ClientTimelineItemApiContract,
} from './client-timeline-item';
import { ClientTimelineItemDirection, ClientTimelineItemType } from './types';

export class ClientTimelineBankTransactionItem extends ClientTimelineItem<ClientTimelineItemType.bankTransaction> {
  constructor(
    id: number,
    date: string,
    amount: number,
    balanceAmount: number,
    matched: boolean,
    type: ClientTimelineItemType.bankTransaction,
    direction: ClientTimelineItemDirection,
    public wording: string,
    public bankName: string,
    public operationType: OperationType,
  ) {
    super(id, date, amount, balanceAmount, matched, type, direction);
  }

  public static fromJson(
    json: ClientTimelineItemApiContract,
  ): ClientTimelineBankTransactionItem {
    return new ClientTimelineBankTransactionItem(
      json.id,
      json.date,
      json.amount,
      json.balance_amount,
      json.matched,
      json.type as ClientTimelineItemType.bankTransaction,
      json.direction,
      json.wording,
      json.bank_name,
      json.operation_type,
    );
  }
}
