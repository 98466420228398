<div
  class="file"
  [ngClass]="fileTransfer.transferStatus | map: mapToStatusClass"
>
  <div class="file-container">
    @if (
      fileTransfer.isUpload &&
      fileTransfer.transferStatus === FileTransferStatus.COMPLETED
    ) {
      <app-category-icon [category]="selectedCategory"></app-category-icon>
    } @else {
      <mat-icon
        inline="true"
        [class.upload]="fileTransfer.isUpload"
        [svgIcon]="fileTransfer.isUpload ? 'icon-upload' : 'icon-download'"
      ></mat-icon>
    }
    <span class="filename">{{ fileTransfer.filename }}</span>
  </div>

  @switch (fileTransfer.transferStatus) {
    @case (FileTransferStatus.ON_ERROR) {
      <ng-container [ngTemplateOutlet]="transferError"></ng-container>
    }
    @case (FileTransferStatus.NO_DATA) {
      <ng-container [ngTemplateOutlet]="transferError"></ng-container>
    }
    @case (FileTransferStatus.COMPLETED) {
      <mat-icon inline="true" fontIcon="icon-check-1c-b-24"></mat-icon>
    }
    @default {
      <app-tiime-spinner class="spinner" height="44px"></app-tiime-spinner>
    }
  }
</div>

<ng-template #transferError>
  <mat-icon inline="true" fontIcon="icon-alerte-input"></mat-icon>
  <div class="hover-state">
    {{ fileTransfer.error }}
    <mat-icon inline="true" fontIcon="icon-alerte-input"></mat-icon>
  </div>
</ng-template>
