import { AnimationEvent } from '@angular/animations';
import { CdkDialogContainer } from '@angular/cdk/dialog';
import { PortalModule } from '@angular/cdk/portal';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  inject,
  OnDestroy,
} from '@angular/core';

import { sidePanelAnimations } from './side-panel-animations';

@Component({
  standalone: true,
  selector: 'app-side-panel-container',
  templateUrl: 'side-panel-container.component.html',
  styleUrls: ['side-panel-container.component.scss'],
  changeDetection: ChangeDetectionStrategy.Default,
  animations: [sidePanelAnimations.sidePanelState],
  imports: [PortalModule],
  // eslint-disable-next-line @angular-eslint/no-host-metadata-property
  host: {
    class: 'side-panel-container',
    tabindex: '-1',
    '[attr.role]': '_config.role',
    '[attr.aria-modal]': '_config.ariaModal',
    '[attr.aria-label]': '_config.ariaLabel',
    '[@state]': 'animationState',
    '(@state.start)': 'onAnimationStart($event)',
    '(@state.done)': 'onAnimationDone($event)',
  },
})
export class SidePanelContainerComponent
  extends CdkDialogContainer
  implements OnDestroy
{
  private readonly cdr = inject(ChangeDetectorRef);

  protected animationState: 'void' | 'visible' | 'hidden' = 'void';
  animationStateChanged = new EventEmitter<AnimationEvent>();

  private destroyed = false;

  override ngOnDestroy(): void {
    super.ngOnDestroy();
    this.destroyed = true;
  }

  enter(): void {
    if (!this.destroyed) {
      this.animationState = 'visible';
      this.cdr.detectChanges();
    }
  }

  exit(): void {
    if (!this.destroyed) {
      this.animationState = 'hidden';
      this.cdr.markForCheck();
    }
  }

  protected onAnimationDone(event: AnimationEvent): void {
    if (event.toState === 'visible') {
      this._trapFocus();
    }

    this.animationStateChanged.emit(event);
  }

  protected onAnimationStart(event: AnimationEvent): void {
    this.animationStateChanged.emit(event);
  }

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  protected override _captureInitialFocus(): void {}
}
