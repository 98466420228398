import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TiimePipesModule } from '@manakincubber/tiime-utils';
import { TranslateModule } from '@ngx-translate/core';
import { TiimeLetModule } from 'tiime-components';

import { DocumentEditorTableHeaderComponent } from './document-editor-table-header.component';

@NgModule({
  declarations: [DocumentEditorTableHeaderComponent],
  imports: [CommonModule, TranslateModule, TiimePipesModule, TiimeLetModule],
  exports: [DocumentEditorTableHeaderComponent],
})
export class DocumentEditorTableHeaderModule {}
