import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { iif, Observable, of } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import {
  GetOptions,
  PaginationData,
  RequiredGetOptions,
} from 'tiime-components';

import { TemporaryEncoder } from '@core/temporary-encoder';
import { ApiAlertError } from '@decorators/api-alert-error';
import { HttpHelper } from '@helpers';
import { ScheduledPayout, ScheduledPayoutApiContract } from '@models/wallet';

import { StrongCustomerAuthenticationService } from '../../auth/strong-customer-authentication/strong-customer-authentication.service';

@Injectable({
  providedIn: 'root',
})
export class ScheduledPayoutsService {
  readonly resource = 'api/v1/wallet/companies/{companyId}/payout_schedules';

  constructor(
    private readonly http: HttpClient,
    private readonly sca: StrongCustomerAuthenticationService,
  ) {}

  @ApiAlertError()
  getAll(
    getOptions: RequiredGetOptions<'range'>,
  ): Observable<PaginationData<ScheduledPayout>> {
    const partialOptions = new GetOptions(getOptions).toHttpGetOptions();
    const options = {
      params: new HttpParams({
        fromObject: partialOptions.params,
        encoder: new TemporaryEncoder(),
      }),
      headers: partialOptions.headers,
    };

    return this.http
      .get<ScheduledPayout[]>(this.resource, {
        ...options,
        observe: 'response',
      })
      .pipe(
        HttpHelper.mapToPaginationData(
          getOptions.range,
          (scheduledBankTransfer: ScheduledPayoutApiContract) =>
            ScheduledPayout.fromJson(scheduledBankTransfer),
        ),
      );
  }

  @ApiAlertError()
  delete(bankTransferId: number): Observable<null> {
    return this.http.delete<null>(`${this.resource}/${bankTransferId}`);
  }

  @ApiAlertError()
  patch(
    scheduledPayoutId: number,
    scheduledPayout: ScheduledPayout,
    { isPatchingDocuments = false }: { isPatchingDocuments?: boolean } = {
      isPatchingDocuments: false,
    },
  ): Observable<ScheduledPayout> {
    // no SCA needed for patching documents
    return iif(() => isPatchingDocuments, of(false), of(true)).pipe(
      switchMap((scaNeeded: boolean) => {
        if (!scaNeeded) {
          return of(null);
        }
        return this.sca.authenticate(scheduledPayout.toScaAction());
      }),
      switchMap(authenticationSCAHeader =>
        this.http
          .patch<ScheduledPayoutApiContract>(
            `${this.resource}/${scheduledPayoutId}`,
            scheduledPayout.toJson(),
            { headers: authenticationSCAHeader },
          )
          .pipe(
            map((scheduledPayoutApiContract: ScheduledPayoutApiContract) =>
              ScheduledPayout.fromJson(scheduledPayoutApiContract),
            ),
          ),
      ),
    );
  }
}
