<div class="tiime-date-list-cell">
  <ng-container *ngIf="date | map: mapToIsDateValid">
    <ng-container
      *ngIf="
        date | map: mapToBelongsToCurrentYear;
        then singleLineDate;
        else multilineDate
      "
    ></ng-container>
  </ng-container>
</div>
<ng-template #singleLineDate>
  <span class="tiime-date-list-cell-label">
    {{ date | tiimeDatetime: 'dd MMM' }}
  </span>
</ng-template>
<ng-template #multilineDate>
  <span class="tiime-date-list-cell-label">
    {{ date | tiimeDatetime: 'dd MMM' }}
  </span>
  <span class="tiime-date-list-cell-year">
    {{ date | tiimeDatetime: 'yyyy' }}
  </span>
</ng-template>
