export interface UserAddressApiContract {
  country: {
    name: string;
    code: string;
  };
  street: string;
  postal_code: string;
  city: string;
  additional_information1: string | null;
  additional_information2: string | null;
}

export class UserAddress {
  constructor(
    public street: string,
    public postalCode: string,
    public city: string,
    public countryName: string,
    public countryCode: string,
    public additionalInformation1: string | null,
    public additionalInformation2: string | null,
  ) {}

  static fromJson(json: UserAddressApiContract): UserAddress {
    return new UserAddress(
      json.street,
      json.postal_code,
      json.city,
      json.country.name,
      json.country.code,
      json.additional_information1,
      json.additional_information2,
    );
  }

  static toJson(address: UserAddress): UserAddressApiContract {
    return {
      country: {
        name: address.countryName,
        code: address.countryCode,
      },
      street: address.street,
      additional_information1: address.additionalInformation1,
      additional_information2: address.additionalInformation2,
      postal_code: address.postalCode,
      city: address.city,
    };
  }
}
