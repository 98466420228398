<form [formGroup]="mailForm" [class.simple]="isSimpleDisplay">
  <tiime-input-container label="De" [errorMessage]="mailForm.fromErrorMessage">
    <input
      id="email"
      type="text"
      formControlName="from"
      autocomplete="nope"
      tiimeInputContainerNativeControl
    />
  </tiime-input-container>
  <ng-container *tiimeLet="shouldDisplayCc$ | async as shouldDisplayCc">
    <ng-container *tiimeLet="shouldDisplayCci$ | async as shouldDisplayCci">
      <div *ngIf="!shouldDisplayCc" class="cc-cci">
        <button tiime-button xsmall neutral inverted (click)="displayCc()">
          Cc
        </button>
      </div>
      <div *ngIf="!shouldDisplayCci" class="cc-cci">
        <button tiime-button xsmall neutral inverted (click)="displayCci()">
          Cci
        </button>
      </div>
      <tiime-mail-recipient
        label="Envoyer à"
        color="primary"
        data-cy="main-recipient-mail"
        [formArray]="mailForm.toRecipientFormArray"
        [errorMessage]="mailForm.toErrorMessage"
      ></tiime-mail-recipient>
      <tiime-mail-recipient
        *ngIf="shouldDisplayCc"
        label="Cc"
        color="primary"
        [formArray]="mailForm.ccFormArray"
      ></tiime-mail-recipient>
      <tiime-mail-recipient
        *ngIf="shouldDisplayCci"
        label="Cci"
        color="primary"
        [formArray]="mailForm.cciFormArray"
      ></tiime-mail-recipient>
    </ng-container>
  </ng-container>
  <div *ngIf="!isSimpleDisplay" class="separation-bar"></div>
  <tiime-input-container
    label="Sujet de l'email"
    [errorMessage]="mailForm.subjectErrorMessage"
  >
    <input
      id="subject"
      tiimeInputContainerNativeControl
      type="text"
      formControlName="subject"
      autocomplete="nope"
    />
  </tiime-input-container>

  <tiime-input-container
    *ngIf="isSimpleDisplay"
    label="Corps de l'email"
    [errorMessage]="mailForm.bodyErrorMessage"
  >
    <textarea
      id="body"
      tiimeInputContainerNativeControl
      type="text"
      formControlName="body"
      cdkTextareaAutosize
    ></textarea>
  </tiime-input-container>

  <div *ngIf="!isSimpleDisplay" class="mail-body">
    <div class="mail-body__label">
      <ng-content select="[header]"></ng-content>
    </div>
    <img
      class="mail-body__illu"
      src="assets/illus/illu_mail_form.svg"
      alt="Mail"
    />
    <tiime-input-container
      label="Vous pouvez saisir ici un message personnalisé"
      [errorMessage]="mailForm.bodyErrorMessage"
    >
      <textarea
        id="body"
        tiimeInputContainerNativeControl
        type="text"
        formControlName="body"
        cdkTextareaAutosize
      ></textarea>
    </tiime-input-container>
    <div class="mail-body__footer">
      <ng-content select="[footer]"></ng-content>
    </div>
  </div>

  <ng-content select="[attachments]"></ng-content>
</form>
