import { createTracker, withName } from '@manakincubber/tiime-tracking';
import { mergeMap, take, tap } from 'rxjs/operators';

import { selectedCompanyIdSelector } from '@core/store';

import {
  CLIENT_DETAIL_ARCHIVE_MENU_CLICKED,
  CLIENT_DETAIL_DUN_BUTTON_CLICKED,
  CLIENT_DETAIL_EDIT_BUTTON_CLICKED,
  CLIENT_DETAIL_EXPORT_TIMELINE_MENU_CLICKED,
  CLIENT_DETAIL_HISTORY_TAB_CLICKED,
  CLIENT_DETAIL_NEW_INVOICE_BUTTON_CLICKED,
  CLIENT_DETAIL_NEW_QUOTE_BUTTON_CLICKED,
  CLIENT_DETAIL_PAGE_OPENED,
  CLIENT_DETAIL_QUOTES_TAB_CLICKED,
  CLIENT_DETAIL_TIMELINE_INVOICE_CLICKED,
  DUN_CLIENT_DOWNLOAD_PDF_BUTTON_CLICKED,
  DUN_CLIENT_SEND_VIA_TIIME_BUTTON_CLICKED,
} from '../../events';
import { optionsWithCompanyGroupExtra } from '../../utils';
import { BaseTrackingGroup } from '../base-tracking-group';

export class ClientTrackingGroup extends BaseTrackingGroup {
  handleClientDetailPageOpened$ = createTracker(
    this.events$.pipe(
      withName(CLIENT_DETAIL_PAGE_OPENED),
      mergeMap(() =>
        this.store.select(selectedCompanyIdSelector).pipe(
          take(1),
          tap(companyId => {
            this.ampli.ficheClientOuverte(
              optionsWithCompanyGroupExtra(companyId),
            );
          }),
        ),
      ),
    ),
  );

  handleClientDetailEditButtonClicked$ = createTracker(
    this.events$.pipe(
      withName(CLIENT_DETAIL_EDIT_BUTTON_CLICKED),
      mergeMap(() =>
        this.store.select(selectedCompanyIdSelector).pipe(
          take(1),
          tap(companyId => {
            this.ampli.boutonModifierFicheClientClique(
              optionsWithCompanyGroupExtra(companyId),
            );
          }),
        ),
      ),
    ),
  );

  handleClientDetailHistoryTabClicked$ = createTracker(
    this.events$.pipe(
      withName(CLIENT_DETAIL_HISTORY_TAB_CLICKED),
      mergeMap(() =>
        this.store.select(selectedCompanyIdSelector).pipe(
          take(1),
          tap(companyId => {
            this.ampli.ongletHistoriqueFicheClientClique(
              optionsWithCompanyGroupExtra(companyId),
            );
          }),
        ),
      ),
    ),
  );

  handleClientDetailQuotesTabClicked$ = createTracker(
    this.events$.pipe(
      withName(CLIENT_DETAIL_QUOTES_TAB_CLICKED),
      mergeMap(() =>
        this.store.select(selectedCompanyIdSelector).pipe(
          take(1),
          tap(companyId => {
            this.ampli.ongletDevisFicheClientClique(
              optionsWithCompanyGroupExtra(companyId),
            );
          }),
        ),
      ),
    ),
  );

  handleClientDetailNewInvoiceButtonClicked$ = createTracker(
    this.events$.pipe(
      withName(CLIENT_DETAIL_NEW_INVOICE_BUTTON_CLICKED),
      mergeMap(() =>
        this.store.select(selectedCompanyIdSelector).pipe(
          take(1),
          tap(companyId => {
            this.ampli.boutonNouvelleFactureFicheClientClique(
              optionsWithCompanyGroupExtra(companyId),
            );
          }),
        ),
      ),
    ),
  );

  handleClientDetailNewQuoteButtonClicked$ = createTracker(
    this.events$.pipe(
      withName(CLIENT_DETAIL_NEW_QUOTE_BUTTON_CLICKED),
      mergeMap(() =>
        this.store.select(selectedCompanyIdSelector).pipe(
          take(1),
          tap(companyId => {
            this.ampli.boutonNouveauDevisFicheClientClique(
              optionsWithCompanyGroupExtra(companyId),
            );
          }),
        ),
      ),
    ),
  );

  handleClientDetailDunButtonButtonClicked$ = createTracker(
    this.events$.pipe(
      withName(CLIENT_DETAIL_DUN_BUTTON_CLICKED),
      mergeMap(() =>
        this.store.select(selectedCompanyIdSelector).pipe(
          take(1),
          tap(companyId => {
            this.ampli.boutonRelanceClientTimelineClique(
              optionsWithCompanyGroupExtra(companyId),
            );
          }),
        ),
      ),
    ),
  );

  handleClientDetailTimelineInvoiceClicked$ = createTracker(
    this.events$.pipe(
      withName(CLIENT_DETAIL_TIMELINE_INVOICE_CLICKED),
      mergeMap(() =>
        this.store.select(selectedCompanyIdSelector).pipe(
          take(1),
          tap(companyId => {
            this.ampli.factureTimelineCliquee(
              optionsWithCompanyGroupExtra(companyId),
            );
          }),
        ),
      ),
    ),
  );

  handleClientDetailArchiveMenuClicked$ = createTracker(
    this.events$.pipe(
      withName(CLIENT_DETAIL_ARCHIVE_MENU_CLICKED),
      mergeMap(() =>
        this.store.select(selectedCompanyIdSelector).pipe(
          take(1),
          tap(companyId => {
            this.ampli.menuArchiverFicheClientClique(
              optionsWithCompanyGroupExtra(companyId),
            );
          }),
        ),
      ),
    ),
  );

  handleClientDetailAExportTimelineMenuClicked$ = createTracker(
    this.events$.pipe(
      withName(CLIENT_DETAIL_EXPORT_TIMELINE_MENU_CLICKED),
      mergeMap(() =>
        this.store.select(selectedCompanyIdSelector).pipe(
          take(1),
          tap(companyId => {
            this.ampli.menuExporterEncoursFicheClientClique(
              optionsWithCompanyGroupExtra(companyId),
            );
          }),
        ),
      ),
    ),
  );

  handleDunClientDownloadPdfButtonClickedClicked$ = createTracker(
    this.events$.pipe(
      withName(DUN_CLIENT_DOWNLOAD_PDF_BUTTON_CLICKED),
      mergeMap(() =>
        this.store.select(selectedCompanyIdSelector).pipe(
          take(1),
          tap(companyId => {
            this.ampli.boutonTelechargerPdfPopinRelanceClientClique(
              optionsWithCompanyGroupExtra(companyId),
            );
          }),
        ),
      ),
    ),
  );

  handleDunClientSendViaTiimeButtonClickedClicked$ = createTracker(
    this.events$.pipe(
      withName(DUN_CLIENT_SEND_VIA_TIIME_BUTTON_CLICKED),
      mergeMap(() =>
        this.store.select(selectedCompanyIdSelector).pipe(
          take(1),
          tap(companyId => {
            this.ampli.boutonEnvoyerViaTiimePopinRelanceClientClique(
              optionsWithCompanyGroupExtra(companyId),
            );
          }),
        ),
      ),
    ),
  );
}
