import { InvoicingTemplate, QuoteStatus } from '@enums';
import {
  LineApiContract,
  Tag,
  TagApiContract,
  TextLine,
  TextLineApiContract,
} from '@models';

import { AbstractInvoicingDocumentListItem } from '../bases/abstract-invoicing-document-list-item';
import { InvoiceListItem } from './invoice-list-item';
import { Line } from './line';

export interface QuoteListItemApiContract {
  client_id: number;
  client_name: string;
  color: string;
  compiled_number: string;
  emission_date: string;
  id: number;
  invoice_id: number;
  invoices: unknown[];
  lines: unknown[];
  text_lines: unknown[];
  number: number;
  status: QuoteStatus;
  template: InvoicingTemplate;
  comment: string | null;
  tags: Tag[];
  units_enabled: boolean;
  title: string;
  total_excluding_taxes: number;
  total_including_taxes: number;
}

export class QuoteListItem extends AbstractInvoicingDocumentListItem {
  constructor(
    id?: number,
    title?: string,
    quoteNumber?: number,
    emissionDate?: string,
    clientId?: number,
    clientName?: string,
    totalExcludingTaxes?: number,
    totalIncludingTaxes?: number,
    lines?: Line[],
    textLines?: TextLine[],
    color?: string,
    template?: InvoicingTemplate,
    comment?: string,
    tags?: Tag[],
    unitsEnabled?: boolean,
    public invoiceId?: number,
    public status?: QuoteStatus,
    public invoices?: InvoiceListItem[],
  ) {
    super(
      id,
      title,
      quoteNumber,
      emissionDate,
      totalExcludingTaxes,
      totalIncludingTaxes,
      lines,
      textLines,
      color,
      template,
      comment,
      tags,
      unitsEnabled,
      clientId,
      clientName,
    );
  }

  public static fromJson(json: QuoteListItemApiContract): QuoteListItem {
    return new QuoteListItem(
      json.id,
      json.title,
      json.number,
      json.emission_date,
      json.client_id,
      json.client_name,
      json.total_excluding_taxes,
      json.total_including_taxes,
      json.lines
        ? json.lines.map((lineJson: LineApiContract) => Line.fromJson(lineJson))
        : [],
      json.text_lines
        ? json.text_lines.map((textLineJson: TextLineApiContract) =>
            TextLine.fromJson(textLineJson),
          )
        : [],
      json.color,
      json.template,
      json.comment,
      json.tags
        ? json.tags.map((tagJson: TagApiContract) => Tag.fromJson(tagJson))
        : [],
      json.units_enabled,
      json.invoice_id,
      json.status,
      json.invoices
        ? json.invoices.map((invoiceJson: unknown) =>
            InvoiceListItem.fromJson(invoiceJson),
          )
        : [],
    );
  }
}
