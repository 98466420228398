import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { MatMenuModule } from '@angular/material/menu';
import { NgUtils } from '@manakincubber/tiime-utils';
import { CategoryIconComponent } from 'projects/tiime/src/app/shared/components/category-icon/category-icon.component';
import {
  DatetimePipeModule,
  TiimeButtonModule,
  TiimeDateListCellModule,
  TiimeIconModule,
  TiimeLetModule,
} from 'tiime-components';

import { StandardDocumentCategoryIdentifier } from '@enums';
import { MatchedItemComponent } from '@matching/matching-dialog/matched-items/matched-item/matched-item.component';
import { LinkedEntity, LinkedEntityType } from '@models/linked-entities';

import { OperationTypeLogoModule } from '../../../../company-shared/components/operation-type-logo/operation-type-logo.module';
import { LinkedEntityAmountAndCurrencyPipe } from './linked-entity-amount-and-currency.pipe';

export interface UnmatchLinkedEntityEvent {
  linkedEntityType: LinkedEntityType;
  id: number;
}

@Component({
  standalone: true,
  imports: [
    CommonModule,
    TiimeButtonModule,
    MatMenuModule,
    MatchedItemComponent,
    OperationTypeLogoModule,
    TiimeDateListCellModule,
    TiimeIconModule,
    TiimeLetModule,
    CategoryIconComponent,
    DatetimePipeModule,
    LinkedEntityAmountAndCurrencyPipe,
  ],
  selector: 'app-linked-entities',
  templateUrl: './linked-entities.component.html',
  styleUrls: [
    './linked-entities.component.scss',
    '../../../../../../styles/ui-components/_multiple-transactions-matchs.scss',
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LinkedEntitiesComponent implements OnInit {
  @Input() linkedEntities: LinkedEntity[];

  @Output() readonly unmatchLinkedEntity =
    new EventEmitter<UnmatchLinkedEntityEvent>();

  @Output() readonly editLink = new EventEmitter<void>();

  entityType: LinkedEntityType;

  readonly trackById = NgUtils.trackById;

  readonly LinkedEntityType = LinkedEntityType;

  readonly StandardDocumentCategoryIdentifier =
    StandardDocumentCategoryIdentifier;

  ngOnInit(): void {
    if (this.linkedEntities.length > 0) {
      this.entityType = this.linkedEntities[0].type;
    }
  }

  unmatch({ type, value }: LinkedEntity): void {
    this.unmatchLinkedEntity.emit({
      linkedEntityType: type,
      id: value.id,
    });
  }
}
