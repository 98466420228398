import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import * as Sentry from '@sentry/angular-ivy';
import {
  ErrorEvent,
  EventHint,
  ScopeContext,
  TransactionContext,
} from '@sentry/types';

import { RuntimeEnvironment } from '@environments';
import { User } from '@models';
import { AppConfigService } from '@services';

import packageInfo from '../../../../../../package.json';
import { SENTRY_IGNORED_ERRORS } from './sentry-ignored-errors.const';

@Injectable({
  providedIn: 'root',
})
export class SentryService {
  get config(): RuntimeEnvironment {
    return this.appConfigService.appConfig;
  }

  constructor(private readonly appConfigService: AppConfigService) {}

  captureException(
    error: Error & { originalError?: Error },
    captureContext?: Partial<{
      user: ScopeContext['user'];
      tags: ScopeContext['tags'];
    }>,
  ): void {
    if (this.config?.SENTRY_DNS) {
      Sentry.captureException(error.originalError || error, captureContext);
    } else {
      console.error(error);
    }
  }

  init(): void {
    if (this.config?.SENTRY_DNS) {
      const release = `Apps ${packageInfo.version}`;

      Sentry.init({
        dsn: this.config.SENTRY_DNS,
        environment: this.config.ENV_NAME,
        release,
        integrations: [
          Sentry.browserTracingIntegration({
            tracingOrigins: [this.config.API_URL],
            // https://docs.sentry.io/platforms/javascript/guides/angular/performance/instrumentation/automatic-instrumentation
            beforeStartSpan(context): TransactionContext {
              return {
                ...context,
                name: context.name
                  .replace(/\?.*/g, '?[queryParams]') // On remplace les queryParams (/module?myQuery=param -> /module?[queryParams])
                  .replace(/\/\d+/g, '/{id}'), // On remplace les ids (/company/1234/module -> /company/{id}/module)
              };
            },
          }),
        ],
        ignoreErrors: SENTRY_IGNORED_ERRORS,
        tracesSampleRate: 0.15,
        beforeSend(event: ErrorEvent, hint: EventHint) {
          if (hint?.originalException instanceof HttpErrorResponse) {
            // Handled by NgModule's ErrorHandler
            return null;
          }

          if (event?.message?.startsWith('Http failure')) {
            const split = event.message.split(': ');
            if (split?.length != 2) {
              /* The message for most of the http request error will look like this:
              "Http failure response for https://chronos-development.preprod.tiime.tech/v1/companies/1223333/dashboard_blocks: 404 OK"
              So we can split it via separator ': ' to have one part with url and one part with the error code.
              Some messages are too long so the split does not work, like the call to 'api.cubbit.it'. We don't process them. */
              return event;
            }
            // Message is anonymized, but the error still contains ids
            event.message = event.message.replace(/\/\d+/g, '/{id}');
            /* Providing a better fingerprint than the default one will group all the similar api calls and enable us
            to easily target calls that generates the most errors. Errors on similar routes will be grouped, unless the
            error code differ (404s will be grouped together, 403s will be grouped together, etc.) */
            const errorCode = split[1].trim().split(' ')[0];
            event.fingerprint = ['HTTPS', errorCode, event.message];
          }
          return event;
        },
      });
    }
  }

  setUser(user: User): void {
    if (this.config?.SENTRY_DNS) {
      Sentry.getCurrentScope().setUser({
        id: String(user.id),
        username: `${user.firstName} ${user.lastName}`,
        email: user.email,
        phone: user.phone,
        activeCompanyId: user.activeCompany,
      });
    }
  }
}
