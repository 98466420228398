import {
  CommonModule,
  NgOptimizedImage,
  NgTemplateOutlet,
} from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Output,
  inject,
} from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { AnimationOptions, LottieComponent } from 'ngx-lottie';
import { DragAndDropZoneModule } from 'projects/tiime/src/app/company/company-shared/components/drag-and-drop-zone/drag-and-drop-zone.module';
import { Observable, map } from 'rxjs';
import { TiimeButtonModule, TiimeLetModule } from 'tiime-components';

import { CompanyConfigService } from '@core';
import { MatchingType } from '@core/enum';
import { MatchingDialogService } from '@matching/matching-dialog/matching-dialog.service';
import {
  FileUploaderButtonModule,
  IconsModule,
  InfoBannerComponent,
} from '@shared';

import { BankTransferOverlayService } from '../bank-transfer-overlay.service';

@UntilDestroy()
@Component({
  standalone: true,
  imports: [
    CommonModule,
    TiimeButtonModule,
    TiimeLetModule,
    IconsModule,
    NgOptimizedImage,
    NgTemplateOutlet,
    InfoBannerComponent,
    DragAndDropZoneModule,
    LottieComponent,
    FileUploaderButtonModule,
  ],
  selector: 'app-bank-transfer-add-document',
  templateUrl: './add-document.component.html',
  styleUrls: ['./add-document.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BankTransferAddDocumentComponent {
  @Output() fileDropped = new EventEmitter<FileList>();
  @Output() documentSelected = new EventEmitter<Document>();

  private readonly companyConfigService = inject(CompanyConfigService);
  private readonly matchingDialogService = inject(MatchingDialogService);
  readonly ocerisationAvailable$ = inject(BankTransferOverlayService)
    .ocerisationAvailable$;

  readonly canSendToMindee$: Observable<boolean> = this.companyConfigService
    .get()
    .pipe(
      map(config => config.isFeaturePayoutFromReceiptEnabled),
      untilDestroyed(this),
    );

  readonly loaderOptions: AnimationOptions = {
    path: '/assets/anims/bank-transfer/before-scan.json',
  };

  readonly acceptedTypes = ['.jpeg', '.jpg', '.png', '.pdf'];
  readonly maximumSize = 15;

  openMatchingDialog(event: MouseEvent): void {
    event.stopPropagation();

    this.matchingDialogService
      .openMatchingDialog({
        matchedItems: [],
        matchingTypes: [MatchingType.documents],
        allowMultipleSelection: false,
      })
      .pipe(
        map(response =>
          this.documentSelected.emit(response.matchedItems[0] as Document),
        ),
        untilDestroyed(this),
      )
      .subscribe();
  }
}
