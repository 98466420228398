<mat-menu class="transaction-filter-menu client-name-filter">
  <div
    class="container list-container"
    (click)="$event.stopPropagation()"
    (keydown.tab)="$event.stopPropagation()"
  >
    <div class="title">
      <span>Trier</span>
      <mat-icon
        class="reset-filter-btn"
        svgIcon="icon-reset"
        (click)="reset()"
      ></mat-icon>
    </div>

    <form [formGroup]="form" (ngSubmit)="apply()">
      <mat-radio-group
        class="sort-container"
        color="primary"
        formControlName="sortDirection"
      >
        <mat-radio-button value="asc">
          <span>
            De A
            <mat-icon svgIcon="icon-arrow-right"></mat-icon>
            Z
          </span>
        </mat-radio-button>
        <mat-radio-button value="desc">
          <span>
            De Z
            <mat-icon svgIcon="icon-arrow-right"></mat-icon>
            A
          </span>
        </mat-radio-button>
      </mat-radio-group>
      <div class="separator"></div>

      <div>
        <mat-checkbox formControlName="all">
          <span>Tous les clients</span>
        </mat-checkbox>
        <div class="separator"></div>
        <cdk-virtual-scroll-viewport
          class="filter-container"
          itemSize="24"
          minBufferPx="120"
          maxBufferPx="216"
          scrollShadows
        >
          <mat-checkbox
            *cdkVirtualFor="
              let clientFormGroup of form.filters.controls;
              trackBy: trackByFormId
            "
            class="filter"
            [formControl]="clientFormGroup.controls.selected"
          >
            <span class="checkbox-label">
              {{ clientFormGroup.controls.name.value }}
            </span>
          </mat-checkbox>
        </cdk-virtual-scroll-viewport>
      </div>
      <button class="apply" tiime-button xsmall accent>Appliquer</button>
    </form>
  </div>
</mat-menu>
