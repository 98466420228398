<tiime-dialog-close-button></tiime-dialog-close-button>

<app-confirmation-dialog-content
  [illu]="illu"
  [title]="title"
  [description]="description"
></app-confirmation-dialog-content>

<app-confirmation-dialog-actions
  [theme]="theme"
  [confirmLabel]="confirmLabel"
  [cancelLabel]="cancelLabel"
  [actionsButtonsWidth]="actionsButtonsWidth"
  (decline)="decline()"
  (confirm)="confirm()"
></app-confirmation-dialog-actions>
<tiime-dialog-footer
  *ngIf="footer?.length > 0"
  data-cy="confirm-dialog__txt-footer"
>
  <ng-container *ngFor="let footerLine of footer; trackBy: trackByIndex">
    {{ footerLine }}
    <br/>
  </ng-container>
</tiime-dialog-footer>
