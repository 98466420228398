import { InvoicingCategoriesEnum, VatTypeCode } from '@enums';
import { Unit } from '@models/unit';
import { ArticleApiContract } from '@services/articles';

export class Article {
  constructor(
    public id: number,
    public code: string,
    public description: string | null,
    public unitAmount: number | null,
    public vatTypeCode: VatTypeCode | null,
    public invoicingUnitId: Unit['id'] | null,
    public invoicingCategoryType: InvoicingCategoriesEnum | null,
  ) {}

  static fromJson(contract: ArticleApiContract): Article {
    return new Article(
      contract.id,
      contract.code,
      contract.description,
      contract.unit_amount,
      contract.vat_type?.code as VatTypeCode,
      contract.invoicing_unit?.id ?? null,
      contract.invoicing_category_type as InvoicingCategoriesEnum,
    );
  }
}
