import { FormControl, FormGroup, Validators } from '@angular/forms';
import moment from 'moment';

import { ExpenseReportEditorLinesForm } from '@forms';
import {
  AdvancedExpense,
  DocumentPaymentStatusEnum,
  ExpenseReportType,
  ExpensesReport,
  ForcedTravelWithId,
  Tag,
  User,
} from '@models';
import { Label } from '@models/labels';

export class ExpensesReportForm extends FormGroup {
  get reportId(): FormControl<number> {
    return this.get('reportId') as FormControl;
  }

  get name(): FormControl<string> {
    return this.get('name') as FormControl;
  }

  get owner(): FormControl<User> {
    return this.get('owner') as FormControl;
  }

  get advancedExpenses(): ExpenseReportEditorLinesForm<AdvancedExpense> {
    return this.get(
      'advancedExpenses',
    ) as ExpenseReportEditorLinesForm<AdvancedExpense>;
  }

  get date(): FormControl<string> {
    return this.get('date') as FormControl;
  }

  get comment(): FormControl<string> {
    return this.get('comment') as FormControl;
  }

  get tags(): FormControl<Tag[]> {
    return this.get('tags') as FormControl;
  }

  get label(): FormControl<Label> {
    return this.get('label') as FormControl;
  }

  get paymentStatus(): FormControl<DocumentPaymentStatusEnum> {
    return this.get('paymentStatus') as FormControl;
  }

  get expenseType(): FormControl<ExpenseReportType> {
    return this.get('expenseType') as FormControl;
  }

  get travels(): ExpenseReportEditorLinesForm<ForcedTravelWithId> {
    return this.get(
      'travels',
    ) as ExpenseReportEditorLinesForm<ForcedTravelWithId>;
  }

  constructor() {
    super({
      reportId: new FormControl(null),
      name: new FormControl(null, Validators.required),
      date: new FormControl('', Validators.required),
      owner: new FormControl(null, Validators.required),
      advancedExpenses: new ExpenseReportEditorLinesForm<AdvancedExpense>([]),
      comment: new FormControl(''),
      tags: new FormControl(null),
      label: new FormControl(null),
      paymentStatus: new FormControl(null),
      expenseType: new FormControl(null),
      travels: new ExpenseReportEditorLinesForm<ForcedTravelWithId>([]),
    });
  }

  fromReport(report: Partial<ExpensesReport>): void {
    this.reportId.setValue(report.id);
    this.name.setValue(report.name);
    this.date.setValue(report.date);
    this.owner.setValue(report.owner);
    this.comment.setValue(report.comment, { emitEvent: false });
    this.tags.setValue(report.tags, { emitEvent: false });
    this.label.setValue(report.label, { emitEvent: false });
    this.paymentStatus.setValue(report.paymentStatus, { emitEvent: false });
    this.expenseType.setValue(report.expenseType, { emitEvent: false });

    if (report.id) {
      this.expenseType.disable({ emitEvent: false });
    }
  }

  toExpensesReport(): ExpensesReport {
    return new ExpensesReport(
      this.reportId.value,
      this.name.value,
      moment(this.date.value).format('YYYY-MM-DD').toString(),
      this.advancedExpenses.toMatchedLines(),
      this.owner.value,
      this.comment.value,
      this.tags.value,
      this.label.value,
      this.paymentStatus.value,
      this.expenseType.value,
      this.travels.toMatchedLines(),
    );
  }
}
