<mat-menu class="transaction-filter-menu date-filter">
  <div
    class="container"
    (keydown.tab)="$event.stopPropagation()"
    (click)="$event.stopPropagation()"
  >
    <div class="title">
      <span>Trier</span>
      <mat-icon
        class="reset-filter-btn"
        svgIcon="icon-reset"
        (click)="reset()"
      ></mat-icon>
    </div>
    <form [formGroup]="form" (ngSubmit)="apply()">
      <mat-radio-group
        class="sort-container"
        formControlName="sortDirection"
        color="primary"
      >
        <mat-radio-button
          *ngIf="noSortingOption"
          class="date-filter__radio-button"
          [value]="null"
        >
          Aucun tri
        </mat-radio-button>
        <mat-radio-button class="date-filter__radio-button" value="asc">
          Du + ancien au + récent
        </mat-radio-button>
        <mat-radio-button class="date-filter__radio-button" value="desc">
          Du + récent au + ancien
        </mat-radio-button>
      </mat-radio-group>
      <div class="separator"></div>
      <app-date-and-period-filter
        [formGroup]="form"
      ></app-date-and-period-filter>
      <button
        class="apply"
        tiime-button
        xsmall
        accent
        [disabled]="form.invalid"
      >
        Appliquer
      </button>
    </form>
  </div>
</mat-menu>
