import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TiimePipesModule } from '@manakincubber/tiime-utils';
import { TiimeLetModule } from 'tiime-components';

import { IbanInputComponent } from './iban-input.component';

@NgModule({
  declarations: [IbanInputComponent],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
    TiimeLetModule,
    TiimePipesModule,
  ],
  exports: [IbanInputComponent],
})
export class IbanInputModule {}
