import { Document, DocumentApiContract } from '@models';

export interface DailyRecordApiContract {
  id?: number;
  date: string;
  balance: number;
  previous_balance: number;
  z_tickets: DocumentApiContract[];
  total_inflows?: number;
  total_outflows?: number;
  comment?: string;
}

export class DailyRecord {
  constructor(
    public id?: number,
    public date?: string,
    public balance?: number,
    public previousBalance?: number,
    public zTickets?: Document[],
    public totalInflows?: number,
    public totalOutflows?: number,
    public comment?: string,
  ) {}

  static fromJson(json: DailyRecordApiContract): DailyRecord {
    return new DailyRecord(
      json.id,
      json.date,
      json.balance,
      json.previous_balance,
      json.z_tickets ? json.z_tickets.map(Document.fromJson) : [],
      json.total_inflows,
      json.total_outflows,
      json.comment,
    );
  }
}
