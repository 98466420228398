export enum InvoiceComplexFilterKey {
  search = 'q',
  comment = 'comment',
  wording = 'title',
  clientName = 'client_name',
  status = 'status',
  amountType = 'amount_type',
  amount = 'total_including_taxes',
  amountExcludingTaxes = 'total_excluding_taxes',
  date = 'emission_date',
  numbers = 'number',
  tags = 'tag_name',
  withComment = 'has_comment',
  withClient = 'has_client',
  withTag = 'has_tag',
  withTransaction = 'matched',
}
