<tiime-dialog-close-button
  [disabled]="activationLoading$ | async"
  [closeValue]="closeValue"
></tiime-dialog-close-button>

<ng-container
  *tiimeLet="activeTrackCardStepIndex$ | async as activeTrackCardStepIndex"
>
  <ng-container *tiimeLet="currentStatus$ | async as currentStatus">
    <div mat-dialog-title>
      <ng-container
        *ngIf="
          currentStatus === CardTrackingDialogStatus.Activation;
          else trackingTitle
        "
      >
        Activer ma carte Tiime Business
      </ng-container>
      <ng-template #trackingTitle>
        Suivi de livraison de ma carte Tiime Business
      </ng-template>
    </div>

    <ng-container [ngSwitch]="currentStatus">
      <div
        *ngSwitchCase="CardTrackingDialogStatus.Tracking"
        class="tracking"
        mat-dialog-content
      >
        <img
          ngSrc="assets/illu_card_tracking.svg"
          height="100"
          width="100"
          alt="Expédition"
        />

        <section class="tracking__status">
          <header>
            <h2>
              {{ walletAccount.trackCardSteps[activeTrackCardStepIndex].name }}
            </h2>
            <h3>
              {{
                walletAccount.trackCardSteps[activeTrackCardStepIndex].date
                  | date: 'dd MMM yyyy'
              }}
            </h3>
          </header>

          <p>
            {{
              walletAccount.trackCardSteps[activeTrackCardStepIndex].description
            }}
          </p>
        </section>

        <section class="tracking__timeline">
          <ol class="timeline-steps">
            <li
              *ngFor="
                let trackCardStep of walletAccount.trackCardSteps;
                let index = index;
                trackBy: trackByIndex
              "
              class="timeline-steps__step"
              [class.timeline-steps__step--active]="trackCardStep.isChecked"
            >
              <div class="step-icon-container">
                <mat-icon
                  inline="true"
                  fontIcon="icon-check-1c-r-b-24-fontastic"
                ></mat-icon>
              </div>
              <p class="step-title">{{ trackCardStep.name }}</p>
              <p class="step-date">
                <ng-container
                  *ngIf="trackCardStep.isChecked; else estimatedDatePrefix"
                >
                  Le
                </ng-container>
                <ng-template #estimatedDatePrefix>Estimée pour le</ng-template>

                {{ trackCardStep.date | date: 'dd MMM yyyy' }}
              </p>
            </li>
          </ol>
        </section>
        <section class="tracking__dossier">
          <img
            height="100"
            width="100"
            alt="Illustration dossier"
            [src]="walletAccount | map: mapToDossierIllu"
          />

          <div class="dossier__text-container">
            <p class="dossier__headline">
              {{ walletAccount | map: mapToDossierHeadline }}
            </p>
            <p class="dossier__description">
              {{ walletAccount | map: mapToDossierDescription }}
            </p>
          </div>
        </section>
      </div>

      <section
        *ngSwitchCase="CardTrackingDialogStatus.CheckMail"
        class="check-mail"
        mat-dialog-content
      >
        <img
          class="check-mail__illu"
          ngSrc="assets/illu_card_activation.svg"
          height="100"
          width="100"
          alt="Illustration boite mail"
        />
        <p class="check-mail__headline">Surveillez votre boite mail</p>
        <p class="check-mail__description">
          Dès la validation de votre dossier par notre service client, vous
          recevrez un mail pour activer votre carte Tiime Business.
        </p>
      </section>

      <section
        *ngSwitchCase="CardTrackingDialogStatus.ConfirmRenew"
        class="confirm-renew"
        mat-dialog-content
      >
        <img
          class="confirm-renew__illu"
          ngSrc="assets/illu_card_activation.svg"
          height="100"
          width="100"
          alt="Illustration boite mail"
        />
        <p class="confirm-renew__headline">Vous avez reçu votre carte Tiime</p>
        <p class="confirm-renew__description">
          Félicitations, vous pouvez l’activer dès maintenant !
        </p>
        <p class="confirm-renew__info">
          <mat-icon svgIcon="icon-info"></mat-icon>
          Une fois activée, cette nouvelle carte remplacera définitivement
          l’ancienne.
        </p>
      </section>

      <section
        *ngSwitchCase="CardTrackingDialogStatus.Activation"
        class="activation"
        mat-dialog-content
      >
        <img
          ngSrc="assets/illu_wallet_activate_card.svg"
          height="133"
          width="200"
          alt="Illustration CB"
        />
        <p class="activation__description">
          Je renseigne les 9 chiffres de l'ID situé au dos de ma carte
        </p>
        <div
          *tiimeLet="activationError$ | async as activationError"
          class="activation__code-container"
        >
          <app-pin-code-input
            [code]="activationCode$ | async"
            [masked]="false"
            [groupSize]="3"
            [codeLength]="9"
            [color]="activationError ? 'warn' : undefined"
            [disabled]="activationLoading$ | async"
            (codeChange)="activationCode$.next($event)"
            (codeComplete)="onCodeComplete()"
          ></app-pin-code-input>
          <div *ngIf="activationError" class="error">
            Le code est erroné, veuillez recommencer.
          </div>
        </div>
      </section>

      <section
        *ngSwitchCase="CardTrackingDialogStatus.Success"
        class="success"
        mat-dialog-content
      >
        <img
          class="success__illu"
          ngSrc="assets/illu_wallet_card_abstract.svg"
          height="100"
          width="100"
          alt="Illustration carte activée"
        />
        <p class="success__headline">Ma carte est activée !</p>
        <div class="success__description">
          Pour finaliser l’activation, j’effectue un paiement en magasin avec la
          saisie de mon code PIN. Une fois la carte activée, je pourrai réaliser
          des paiements en ligne et sans contact dans la limite des permissions
          définies par Tiime.
        </div>
      </section>
    </ng-container>

    <div
      *tiimeLet="activationCode$ | async as activationCode"
      mat-dialog-actions
    >
      <ng-container [ngSwitch]="currentStatus">
        <ng-container *ngSwitchCase="CardTrackingDialogStatus.Tracking">
          <button tiime-button neutral inverted (click)="contactSupport()">
            J'ai un problème
          </button>

          <button
            tiime-button
            accent
            (click)="goToActivation(walletAccount, false)"
          >
            J’ai reçu ma carte
          </button>
        </ng-container>

        <ng-container *ngSwitchCase="CardTrackingDialogStatus.ConfirmRenew">
          <button tiime-button neutral inverted mat-dialog-close>
            Plus Tard
          </button>

          <button
            tiime-button
            accent
            (click)="goToActivation(walletAccount, true)"
          >
            Activer ma carte
          </button>
        </ng-container>

        <ng-container *ngSwitchCase="CardTrackingDialogStatus.CheckMail">
          <button tiime-button accent mat-dialog-close>OK j’ai compris</button>
        </ng-container>

        <ng-container *ngSwitchCase="CardTrackingDialogStatus.Activation">
          <button
            *tiimeLet="activationLoading$ | async as activationLoading"
            tiime-button
            accent
            [disabled]="
              activationCode.length !== 9 ||
              (activationError$ | async) ||
              activationLoading
            "
            (click)="activateCard(walletAccount, activationCode)"
          >
            <ng-container *ngIf="activationLoading === false; else spinner">
              Suivant
            </ng-container>

            <ng-template #spinner>
              <tiime-progress-spinner-loader
                diameter="20"
                color="white"
                mode="indeterminate"
              ></tiime-progress-spinner-loader>
            </ng-template>
          </button>
        </ng-container>

        <ng-container *ngSwitchCase="CardTrackingDialogStatus.Success">
          <button tiime-button accent mat-dialog-close>OK c’est parti !</button>
        </ng-container>
      </ng-container>
    </div>
  </ng-container>
</ng-container>
