import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { TiimeIconModule } from '../icon/index';
import { TagComponent } from './tag.component';

@NgModule({
  imports: [CommonModule, TiimeIconModule],
  declarations: [TagComponent],
  exports: [TagComponent],
})
export class TiimeTagModule {}
