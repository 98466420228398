import { DocumentEditorTableColumn } from '../../shared/components/document-editor-table-header/document-editor-table-column';

const INVOICING_TABLE_COLUMNS: DocumentEditorTableColumn[] = [
  {
    label: 'description',
    testId: 'description-header',
    translate: true,
  },
  {
    label: 'quantity',
    testId: 'quantity-header',
    translate: true,
    width: '90px',
  },
  {
    label: 'unit',
    testId: 'unit-header',
    translate: true,
    width: '68px',
  },
  {
    label: 'unitPrice',
    testId: 'unit-amount-header',
    translate: true,
    width: '90px',
  },
  {
    label: 'vat',
    testId: 'vat-header',
    translate: true,
    width: '55px',
  },
];
export const INVOICING_ADVANCED_TABLE_COLUMNS: DocumentEditorTableColumn[] = [
  ...INVOICING_TABLE_COLUMNS,
  {
    label: 'amountBeforeTax',
    testId: 'total-excluding-taxes-header',
    translate: true,
    width: '90px',
  },
];
export const INVOICING_ADVANCED_NOT_EDITABLE_TABLE_COLUMNS: DocumentEditorTableColumn[] =
  [
    ...INVOICING_TABLE_COLUMNS,
    {
      label: 'totalBeforeTax',
      testId: 'total-excluding-taxes-header',
      translate: true,
      width: '90px',
    },
  ];

export const getFree22Columns = (
  columns: DocumentEditorTableColumn[],
): DocumentEditorTableColumn[] => columns.filter(unit => unit.label !== 'unit');
