import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { TiimeIconModule } from '../icon/index';
import { TiimeSelectModule } from '../select/select.module';
import { PaginatorComponent } from './paginator.component';

@NgModule({
  imports: [
    CommonModule,
    TiimeIconModule,
    TiimeSelectModule,
    FormsModule,
    ReactiveFormsModule,
  ],
  declarations: [PaginatorComponent],
  exports: [PaginatorComponent],
})
export class TiimePaginatorModule {}
