import { NgOptimizedImage } from '@angular/common';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import { TiimeButtonModule, TiimeDialogModule } from 'tiime-components';

@Component({
  selector: 'app-bank-transfer-document-acceptance-dialog',
  standalone: true,
  imports: [TiimeButtonModule, TiimeDialogModule, NgOptimizedImage],
  templateUrl: './bank-transfer-document-acceptance-dialog.component.html',
  styleUrls: ['./bank-transfer-document-acceptance-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BankTransferDocumentAcceptanceDialogComponent {}
