import moment from 'moment';

export class MomentHelper {
  static isDateAfter(
    date: moment.Moment,
    isAfter: moment.Moment,
    format: 'year' | 'month' | 'day',
  ): boolean {
    return date.isAfter(isAfter, format);
  }

  static isDateBefore(
    date: moment.Moment,
    isBefore: moment.Moment,
    format: 'year' | 'month' | 'day',
  ): boolean {
    return date.isBefore(isBefore, format);
  }

  static firstDayOfMonth(): moment.Moment {
    return moment().startOf('month');
  }

  static toCalendarDateString(momentDate: moment.Moment): string {
    return momentDate.format('YYYY-MM-DD');
  }

  static toFullMonthString(momentDate: moment.Moment): string {
    return momentDate.format('MMMM YYYY');
  }

  static toCalendarDateAndTimeString(
    momentDate: moment.Moment = MomentHelper.today(),
  ): string {
    return momentDate.format('YYYY-MM-DD HH:mm:ss');
  }

  static today(): moment.Moment {
    return moment();
  }

  static todayDateString(): string {
    return this.toCalendarDateString(this.today());
  }

  static isSameDate(
    firstDate: moment.Moment,
    secondDate: moment.Moment,
  ): boolean {
    return (
      firstDate.year() === secondDate.year() &&
      firstDate.dayOfYear() === secondDate.dayOfYear()
    );
  }
}
