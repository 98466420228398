import { CommonModule, NgOptimizedImage } from '@angular/common';
import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { BehaviorSubject, finalize, tap } from 'rxjs';
import {
  TiimeButtonModule,
  TiimeDialogModule,
  TiimeInputContainerModule,
} from 'tiime-components';

import { ApiService } from '@core/api.service';
import { PasswordToggleModule } from '@core/directives/password-toggle/password-toggle.module';
import { User } from '@models/user';

import { CreatePasswordForm } from '../create-password-form';

export type CreatePasswordDialogData = {
  user: User;
};

export type CreatePasswordDialogResponse = {
  password: string;
};

@Component({
  selector: 'app-create-password-dialog',
  standalone: true,
  imports: [
    CommonModule,
    NgOptimizedImage,
    TiimeButtonModule,
    TiimeDialogModule,
    MatIconModule,
    TiimeInputContainerModule,
    ReactiveFormsModule,
    FormsModule,
    PasswordToggleModule,
  ],
  templateUrl: './create-password-dialog.component.html',
  styleUrls: ['./create-password-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CreatePasswordDialogComponent {
  private readonly apiService = inject(ApiService);

  readonly dialogRef =
    inject<
      MatDialogRef<CreatePasswordDialogComponent, CreatePasswordDialogResponse>
    >(MatDialogRef);
  readonly loading$ = new BehaviorSubject(false);

  createPasswordForm = new CreatePasswordForm();

  get formErrorMessage(): string {
    return (
      this.createPasswordForm.passwordConfirmation.dirty &&
      !this.createPasswordForm.passwordConfirmationErrorMessage &&
      this.createPasswordForm.differentPasswordsErrorMessage
    );
  }

  submitCreatePasswordForm(): void {
    if (this.createPasswordForm.invalid) {
      this.createPasswordForm.markAllAsTouched();
      return;
    }
    this.updatePassword();
  }

  private updatePassword(): void {
    this.loading$.next(true);
    this.apiService
      .updatePassword(this.createPasswordForm.toPassword())
      .pipe(
        tap(() => {
          this.dialogRef.close({
            password: this.createPasswordForm.password.value as string,
          });
        }),
        finalize(() => this.loading$.next(false)),
      )
      .subscribe();
  }
}
