import { Directive, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { NgUtils } from '@manakincubber/tiime-utils';

import { ConfirmationDialogDataBase } from './confirmation-dialog-data.base';

@Directive()
export abstract class ConfirmationDialogBase<
  D extends ConfirmationDialogDataBase,
> {
  readonly theme: 'accent' | 'warn' | 'accent-with-warn-cancel';
  readonly title: string[];
  readonly illu?: string;
  readonly description: string[];
  readonly footer?: string[];
  readonly cancelLabel?: string;
  readonly confirmLabel?: string;
  readonly actionsButtonsWidth?: number;
  readonly trackByIndex = NgUtils.trackByIndex;

  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: D,
  ) {
    this.theme = data.theme ?? 'accent';
    this.title = Array.isArray(data.title) ? data.title : [data.title];
    this.illu = data.illu;
    this.description = Array.isArray(data.description)
      ? data.description
      : [data.description];
    if (data.footer) {
      this.footer = Array.isArray(data.footer) ? data.footer : [data.footer];
    }
    this.cancelLabel = data.cancelLabel;
    this.confirmLabel = data.confirmLabel;
    this.actionsButtonsWidth = data.actionsButtonsWidth;
  }

  abstract confirm(): void;

  abstract decline(): void;
}
