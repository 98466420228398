import { NgOptimizedImage } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';

import { SharedModule } from '@shared';

import { DatetimePipeModule } from '../../../../../tiime-components/src/lib/pipes/datetime/datetime.module';
import { DocumentEditorTableHeaderModule } from '../../shared/components/document-editor-table-header/document-editor-table-header.module';
import { FileUploaderButtonModule } from '../../shared/components/file-uploader-button/file-uploader-button.module';
import { FormattedNumberInputModule } from '../../shared/components/formatted-number-input/formatted-number-input.module';
import { InvoicePipesModule } from '../invoice/invoice-shared/pipes/invoice-pipes.module';
import { UserAccountMenuOverlayModule } from '../user-account-menu/user-account-menu-overlay/user-account-menu-overlay.module';
import * as Components from './components';
import { AcronymModule } from './components/acronym/acronym.module';
import { AnswerBusinessAccessRequestDialogModule } from './components/answer-business-access-request-dialog/answer-business-access-request-dialog.module';
import { BankConnectionReinsuranceDialogModule } from './components/bank-connection-reinsurance-dialog';
import { BankLogoModule } from './components/bank-logo/bank-logo.module';
import { CardTrackingDialogComponent } from './components/card-tracking-dialog/card-tracking-dialog.component';
import { CompanyAcronymLogoModule } from './components/company-acronym-logo/company-acronym-logo.module';
import { ContentNavigationLayoutModule } from './components/content-navigation-layout/content-navigation-layout.module';
import { DragAndDropZoneModule } from './components/drag-and-drop-zone/drag-and-drop-zone.module';
import { FileTransferOverlayModule } from './components/file-transfer-overlay/file-transfer-overlay.module';
import { InvoiceNumberModule } from './components/invoice-number/invoice-number.module';
import { LabelSelectorModule } from './components/label-selector/label-selector.module';
import { LinkedEntitiesTooltipModule } from './components/linked-entities-tooltip/linked-entities-tooltip.module';
import { OperationTypeLogoModule } from './components/operation-type-logo/operation-type-logo.module';
import { PinCodeFormModule } from './components/pin-code-form/pin-code-form.module';
import { TagSelectorFormInputModule } from './components/tag-selector-form-input/tag-selector-form-input.module';
import { CommentTooltipIconModule } from './components/tooltip-icons/comment-tooltip-icon/comment-tooltip-icon.module';
import { LinkedItemsTooltipIconModule } from './components/tooltip-icons/linked-items-tooltip-icon/linked-items-tooltip-icon.module';

@NgModule({
  imports: [
    FormattedNumberInputModule,
    SharedModule,
    RouterModule,
    CommentTooltipIconModule,
    LinkedItemsTooltipIconModule,
    InvoicePipesModule,
    OperationTypeLogoModule,
    FileUploaderButtonModule,
    TranslateModule,
    PinCodeFormModule,
    DocumentEditorTableHeaderModule,
    InvoiceNumberModule,
    LabelSelectorModule,
    BankLogoModule,
    CompanyAcronymLogoModule,
    AcronymModule,
    DragAndDropZoneModule,
    AnswerBusinessAccessRequestDialogModule,
    ContentNavigationLayoutModule,
    BankConnectionReinsuranceDialogModule,
    UserAccountMenuOverlayModule,
    TagSelectorFormInputModule,
    FileTransferOverlayModule,
    NgOptimizedImage,
  ],
  declarations: [
    Components.CareContactDialogComponent,
    Components.InvoicingDocumentLinesPreviewComponent,
    Components.CardOrderDialogComponent,
    CardTrackingDialogComponent,
  ],
  exports: [
    CommentTooltipIconModule,
    OperationTypeLogoModule,
    FileUploaderButtonModule,
    Components.CareContactDialogComponent,
    Components.InvoicingDocumentLinesPreviewComponent,
    Components.CardOrderDialogComponent,
    InvoicePipesModule,
    SharedModule,
    CardTrackingDialogComponent,
    InvoiceNumberModule,
    LabelSelectorModule,
    LinkedEntitiesTooltipModule,
    LinkedItemsTooltipIconModule,
    BankLogoModule,
    DragAndDropZoneModule,
    AnswerBusinessAccessRequestDialogModule,
    ContentNavigationLayoutModule,
    BankConnectionReinsuranceDialogModule,
    DatetimePipeModule,
    TagSelectorFormInputModule,
    FileTransferOverlayModule,
  ],
})
export class CompanySharedModule {}
