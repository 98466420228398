import { TiimeSelectOption } from 'tiime-components';

export interface RcsCityContract {
  id: number;
  rcs_city: string;
}

export class RcsCity {
  constructor(public id: number, public city: string) {}

  public static mapToSelectOptions(
    rcsCities: RcsCity[],
  ): TiimeSelectOption<string>[] {
    return rcsCities.map(rcsCity => ({
      value: rcsCity.city,
      label: rcsCity.city,
    }));
  }

  public static fromJson(json: RcsCityContract): RcsCity {
    return new RcsCity(json.id, json.rcs_city);
  }
}
