import { AbstractControl, FormControl } from '@angular/forms';
import { SortDirection } from '@angular/material/sort';

import { TableColumnFilterForm } from './table-column-filter.form';
import { TableColumnSortAndFilter } from './table-column-sort-and-filter';

export abstract class TableColumnSortAndFilterForm extends TableColumnFilterForm<TableColumnSortAndFilter> {
  get sortDirection(): FormControl {
    return this.get('sortDirection') as FormControl;
  }

  protected constructor(
    controls: {
      [key: string]: AbstractControl;
    },
    filterKey: string,
    protected readonly sortKey: string,
    sortDirection?: SortDirection,
  ) {
    super(
      {
        ...controls,
        sortDirection: new FormControl(sortDirection),
      },
      filterKey,
    );
  }

  toOutput(): TableColumnSortAndFilter {
    return {
      sort: this.sortDirection.dirty
        ? {
            active: this.sortKey,
            direction: this.sortDirection.value as SortDirection,
          }
        : null,
      filter: this.toFilter(),
    };
  }

  protected abstract toFilter(): TableColumnSortAndFilter['filter'];
}
