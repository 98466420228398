export enum TransactionComplexFilterKey {
  search = 'q',
  comment = 'comment',
  owners = 'owners',
  wording = 'wording',
  labelName = 'label_name',
  amountType = 'amount_type',
  amount = 'amount',
  amount_excluding_taxes = 'amount_excluding_taxes',
  date = 'date',
  ids = 'included_ids',
  tags = 'tags',
  withComment = 'has_comment',
  withMessage = 'has_message',
  withLabel = 'has_label',
  withTag = 'has_tag',
  withDocument = 'matched',
  accountantDetailRequest = 'accountant_detail_request',
}
