import { AbstractControl, ValidationErrors } from '@angular/forms';
import { IbanUtils, RegexUtils } from '@manakincubber/tiime-utils';

export function ibanValidator(
  control: AbstractControl<string>,
): ValidationErrors | null {
  return control.dirty &&
    IbanUtils.isValid(control.value.replace(/\s/g, '')) !== true
    ? {
        validateIban: {
          valid: false,
        },
      }
    : null;
}

export function ibanPatternValidator(
  control: AbstractControl<string>,
): ValidationErrors | null {
  return !RegexUtils.ibanRegex.test(control.value.replace(/\s/g, ''))
    ? {
        regexIban: {
          valid: false,
        },
      }
    : null;
}
