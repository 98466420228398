import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';

import { filterNotNullOrUndefined } from '@core/helpers';

import { ConfirmationDialogWithMessageData } from './confirmation-dialog-with-message/confirmation-dialog-with-message-data';
import { ConfirmationDialogWithMessageComponent } from './confirmation-dialog-with-message/confirmation-dialog-with-message.component';
import { ConfirmationDialogComponent } from './confirmation-dialog.component';
import { ConfirmationDialogData } from './confirmation-dialog.data';

@Injectable({
  providedIn: 'root',
})
export class ConfirmationDialogService {
  constructor(private readonly dialog: MatDialog) {}

  requestConfirmation(
    dialogData: ConfirmationDialogData,
    closeOnNavigation?: boolean,
  ): Observable<boolean> {
    return this.openDialog(dialogData, closeOnNavigation).pipe(map(Boolean));
  }

  requireConfirmation(dialogData: ConfirmationDialogData): Observable<true> {
    return this.openDialog(dialogData).pipe(
      filterNotNullOrUndefined(),
      filter<true>(res => res),
    );
  }

  requestConfirmeDeclineOrCancel(
    dialogData: ConfirmationDialogData,
  ): Observable<boolean | undefined> {
    return this.openDialog(dialogData);
  }

  requestConfirmationWithMessage(
    data: ConfirmationDialogWithMessageData,
  ): Observable<string | undefined> {
    return this.dialog
      .open<
        ConfirmationDialogWithMessageComponent,
        ConfirmationDialogWithMessageData,
        string | undefined
      >(ConfirmationDialogWithMessageComponent, {
        data,
        width: data.width,
        height: data.height,
      })
      .beforeClosed();
  }

  private openDialog(
    dialogData: ConfirmationDialogData,
    closeOnNavigation = true,
  ): Observable<boolean | undefined> {
    return this.dialog
      .open<
        ConfirmationDialogComponent,
        ConfirmationDialogData,
        boolean | undefined
      >(ConfirmationDialogComponent, {
        data: dialogData,
        width: dialogData.width,
        height: dialogData.height,
        closeOnNavigation,
      })
      .beforeClosed();
  }
}
