import {
  AdvancedExpense,
  AdvancedExpenseApiContract,
} from './advanced-expense';
import { DocumentPaymentStatusEnum } from './document';
import { MetadataDef } from './documents';
import { Label, LabelApiContract } from './labels/label';
import { Tag, TagApiContract } from './tag';
import { Travel, TravelApiContract } from './travel';
import { User, UserApiContract } from './user';

export enum ExpenseReportType {
  AdvancedExpense = 'advanced_expense',
  Travel = 'travel',
}

export type ForcedTravelWithId = Omit<Travel, 'id'> & { id: number };

export interface ExpensesReportApiContract {
  id: number;
  name: string;
  date: string;
  owner?: UserApiContract;
  advanced_expenses: AdvancedExpenseApiContract[];
  comment: string;
  tags: TagApiContract[];
  label: LabelApiContract;
  payment_status: DocumentPaymentStatusEnum;
  expense_type: ExpenseReportType;
  travels: TravelApiContract[];
  metadata: MetadataDef[];
}

export class ExpensesReport {
  constructor(
    public id: number,
    public name: string,
    public date: string,
    public advancedExpenses: AdvancedExpense[],
    public owner?: User,
    public comment?: string,
    public tags?: Tag[],
    public label?: Label,
    public paymentStatus?: DocumentPaymentStatusEnum,
    public expenseType?: ExpenseReportType,
    public travels?: ForcedTravelWithId[],
    public metadata?: MetadataDef[],
  ) {}

  static fromJson(json: ExpensesReportApiContract): ExpensesReport {
    return new ExpensesReport(
      json.id,
      json.name,
      json.date,
      json.expense_type === ExpenseReportType.AdvancedExpense
        ? json.advanced_expenses?.map(ae => AdvancedExpense.fromJson(ae))
        : [],
      json.owner ? User.fromJson(json.owner) : undefined,
      json.comment,
      json.tags?.length > 0 ? json.tags.map(t => Tag.fromJson(t)) : [],
      json.label ? Label.fromJson(json.label) : undefined,
      json.payment_status,
      json.expense_type,
      json.expense_type === ExpenseReportType.Travel
        ? json.travels?.map(
            travel => Travel.fromJson(travel) as ForcedTravelWithId,
          )
        : [],
      json.metadata,
    );
  }

  static getExpenseReportTypeLabel(
    expenseReportType: ExpenseReportType,
  ): string {
    switch (expenseReportType) {
      case ExpenseReportType.AdvancedExpense:
        return 'Frais à rembourser';
      case ExpenseReportType.Travel:
        return 'Frais kilométriques';
      default:
        throw new Error('Expense report type not handled');
    }
  }

  toJson(): ExpensesReportApiContract {
    return {
      id: this.id,
      name: this.name,
      date: this.date,
      owner: this.owner ? User.toJson(this.owner) : undefined,
      advanced_expenses:
        this.expenseType === ExpenseReportType.AdvancedExpense
          ? this.advancedExpenses?.map(ae => ae.toJson())
          : [],
      comment: this.comment,
      tags: this.tags?.length > 0 ? this.tags.map(t => Tag.toJson(t)) : [],
      label: this.label ? Label.toJson(this.label) : undefined,
      payment_status: this.paymentStatus,
      expense_type: this.expenseType,
      travels:
        this.expenseType === ExpenseReportType.Travel
          ? this.travels?.map(travel => Travel.toJson(travel))
          : [],
      metadata: this.metadata,
    };
  }
}
