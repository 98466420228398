<div>
  <app-category-icon
    *ngIf="isAdvancedExpenseHeader; else addressIconTemplate"
    [category]="StandardDocumentCategoryIdentifier.ADVANCED_EXPENSE"
  ></app-category-icon>
  <ng-template #addressIconTemplate>
    <mat-icon svgIcon="icon-address"></mat-icon>
  </ng-template>
  {{ title }}
</div>

<ng-content select="app-document-editor-table-header"></ng-content>
