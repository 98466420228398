import { TiimeSelectOption } from 'tiime-components';

export interface ApeCodeContract {
  id: number;
  code: string;
  label: string;
  authorized_by_treezor?: boolean;
  embargo_questionnaire_needed?: boolean;
}

export class ApeCode {
  constructor(
    public id: number,
    public code: string,
    public label: string,
    public authorizedByTreezor?: boolean,
    public embargoQuestionnaireNeeded?: boolean,
  ) {}

  static serialize(contract: ApeCodeContract): ApeCode {
    return new ApeCode(contract.id, contract.code, contract.label);
  }

  public static mapToSelectOptions(
    apeCodes: ApeCode[],
  ): TiimeSelectOption<ApeCode>[] {
    return [
      ...apeCodes.map(apeCode => ({
        value: apeCode,
        label: apeCode.code + ' : ' + apeCode.label,
      })),
    ];
  }

  public static mapToSelectCodeOptions(
    apeCodes: ApeCode[],
  ): TiimeSelectOption<string>[] {
    return [
      ...apeCodes.map(apeCode => ({
        value: apeCode.code,
        label: apeCode.code + ' : ' + apeCode.label,
      })),
    ];
  }

  public static fromJson(json: ApeCodeContract): ApeCode {
    return new ApeCode(
      json.id,
      json.code,
      json.label,
      json.authorized_by_treezor,
      json.embargo_questionnaire_needed,
    );
  }
}
