import { Injectable } from '@angular/core';
import { Router, UrlTree } from '@angular/router';
import { Store } from '@ngrx/store';
import { combineLatest, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { isMicroEntrepreneur, selectedCompanySelector } from '@user-store';

@Injectable({
  providedIn: 'root',
})
export class IsMicroEntrepreneurGuard {
  constructor(private router: Router, private readonly store: Store) {}

  canActivate(): Observable<boolean | UrlTree> {
    return this.isMicroEntrepreneurGuard();
  }

  private isMicroEntrepreneurGuard(): Observable<boolean | UrlTree> {
    return combineLatest([
      this.store.select(isMicroEntrepreneur),
      this.store.select(selectedCompanySelector).pipe(map(({ id }) => id)),
    ]).pipe(
      map(
        ([isMicroEntrepreneur, companyId]) =>
          isMicroEntrepreneur ||
          this.router.createUrlTree(['companies', companyId, 'home']),
      ),
    );
  }
}
