import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  QueryList,
  SimpleChanges,
  ViewChildren,
  inject,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { TrackingService } from '@manakincubber/tiime-tracking';
import { FormUtils } from '@manakincubber/tiime-utils';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Actions, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { BehaviorSubject, defer, forkJoin, Observable, of } from 'rxjs';
import {
  catchError,
  filter,
  finalize,
  map,
  switchMap,
  take,
  tap,
} from 'rxjs/operators';
import {
  MonoValueFilter,
  TiimeButtonModule,
  TiimeDropdownButtonModule,
  TiimeLetModule,
  TiimeMenuModule,
} from 'tiime-components';

import { ExpenseFromReportPreviewOpened } from '@core/amplitude';
import {
  ADD_CATEGORY_SUCCESS,
  addCategory,
  categoriesSelector,
  categoriesWithIdSelector,
} from '@core/store';
import {
  DocumentOpeningMode,
  StandardDocumentCategory,
  StandardDocumentCategoryIdentifier,
} from '@enums';
import { ExpenseReportEditorLineForm } from '@forms';
import { AdvancedExpense, Category, Document, User } from '@models';
import { DocumentsService } from '@services';
import { IconsModule } from '@shared';

import { ConfirmationDialogService } from '../../../../../../../shared/components/confirmation-dialog/confirmation-dialog.service';
import { DocumentEditorTableColumn } from '../../../../../../../shared/components/document-editor-table-header/document-editor-table-column';
import { DocumentEditorTableHeaderModule } from '../../../../../../../shared/components/document-editor-table-header/document-editor-table-header.module';
import { ExpenseListApiFilters } from '../../../../../../expense/expense-shared/expense-list-api-filters';
import { DocumentPreviewDialogService } from '../../../../../document-shared/components/document-preview-dialog/document-preview-dialog.service';
import { GetAmountPipe } from '../../pipes/get-amount.pipe';
import { EditorBodyComponentBase } from '../bases/editor-body-component.base';
import {
  ExpensesReportAddReceiptsDialogComponent,
  ExpensesReportAddReceiptsDialogData,
} from '../expenses-report-add-receipts-dialog/expenses-report-add-receipts-dialog.component';
import { ExpensesReportEditorEmptyStateComponent } from '../expenses-report-editor-empty-state/expenses-report-editor-empty-state.component';
import { ExpensesReportEditorHeaderContainerComponent } from '../expenses-report-editor-header-container/expenses-report-editor-header-container.component';
import {
  CellDefinition,
  ExpensesReportEditorLineComponent,
} from '../expenses-report-editor-line/expenses-report-editor-line.component';
import { ExpensesReportEditorTableSkeletonComponent } from '../expenses-report-editor-skeleton/expense-report-editor-table-skeleton/expenses-report-editor-table-skeleton.component';
import { ExpensesReportEditorTotalComponent } from '../expenses-report-editor-total/expenses-report-editor-total.component';
import { ExpensesReportEditorStatus } from '../expenses-report-editor/expenses-report-editor.component';
import { ExpensesReportEditorService } from '../expenses-report-editor/expenses-report-editor.service';
import { ComputeAdvancedExpensesAmountPipe } from './compute-advanced-expenses-amount';

@UntilDestroy()
@Component({
  standalone: true,
  imports: [
    CommonModule,
    DocumentEditorTableHeaderModule,
    IconsModule,
    ExpensesReportEditorLineComponent,
    ExpensesReportEditorEmptyStateComponent,
    ExpensesReportEditorTotalComponent,
    ComputeAdvancedExpensesAmountPipe,
    GetAmountPipe,
    ExpensesReportEditorHeaderContainerComponent,
    ExpensesReportEditorTableSkeletonComponent,
    TiimeButtonModule,
    TiimeLetModule,
    TiimeMenuModule,
    TiimeDropdownButtonModule,
  ],
  selector: 'app-expense-report-editor-advanced-expenses',
  templateUrl: './expense-report-editor-advanced-expenses.component.html',
  styleUrls: ['./expense-report-editor-advanced-expenses.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ExpenseReportEditorAdvancedExpensesComponent
  extends EditorBodyComponentBase<AdvancedExpense>
  implements OnInit, OnChanges, OnDestroy
{
  @Input() advancedExpenses: AdvancedExpense[];

  @Output() readonly reloadExpenseReport = new EventEmitter<void>();

  @ViewChildren(ExpensesReportEditorLineComponent<AdvancedExpense>)
  lines?: QueryList<ExpensesReportEditorLineComponent<AdvancedExpense>>;

  readonly loading$ = new BehaviorSubject(false);

  private readonly trackingService = inject(TrackingService);

  readonly advancedExpenseCategory$ = this.store
    .select(categoriesSelector)
    .pipe(
      take(1),
      map(categories =>
        categories.find(
          category =>
            category.identifier ===
            StandardDocumentCategoryIdentifier.ADVANCED_EXPENSE,
        ),
      ),
    );

  readonly columns: DocumentEditorTableColumn[] = [
    { label: `Date de l'opération`, width: '140px', align: 'left' },
    { label: 'Nom du label', width: '185px', align: 'left' },
    { label: 'Commentaires', align: 'left' },
    { label: 'TVA', width: '80px' },
    { label: 'Montant TTC', width: '105px' },
  ];

  readonly cellDefinitions: CellDefinition<AdvancedExpense>[] = [
    { formControlName: 'date', required: true, type: 'date' },
    { formControlName: 'label', required: true, valuePath: 'label' },
    { formControlName: 'comment' },
    { formControlName: 'vatAmount', type: 'currency' },
    { formControlName: 'amount', required: true, type: 'currency' },
  ];

  private receiptsToImport: Document[] = [];
  private initialExpenses: AdvancedExpense[] = [];

  constructor(
    private readonly store: Store,
    private readonly documentPreviewService: DocumentPreviewDialogService,
    private readonly documentsService: DocumentsService,
    private readonly dialog: MatDialog,
    private readonly actions$: Actions,
    private readonly expensesReportEditorService: ExpensesReportEditorService,
    private readonly confirmationDialogService: ConfirmationDialogService,
    private readonly cdr: ChangeDetectorRef,
  ) {
    super();
  }

  ngOnInit(): void {
    if (
      this.currentEditorStatus === ExpensesReportEditorStatus.Create &&
      this.advancedExpenses.length === 0
    ) {
      this.reloadAdvancedExpenses();
    } else {
      for (const expense of this.advancedExpenses) {
        this.expenseReportForm.advancedExpenses.push(
          new ExpenseReportEditorLineForm<AdvancedExpense>(expense, true),
        );
      }
    }

    this.observeCancelEdit();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (
      !changes.currentEditorStatus?.isFirstChange() &&
      (changes.currentEditorStatus?.currentValue ||
        (changes.owner?.currentValue &&
          this.currentEditorStatus !== ExpensesReportEditorStatus.Preview))
    ) {
      this.reloadAdvancedExpenses();
    }

    if (
      !changes.advancedExpenses?.isFirstChange() &&
      changes.advancedExpenses?.currentValue
    ) {
      this.advancedExpensesUpdated(
        changes.advancedExpenses.currentValue as AdvancedExpense[],
      );
    }
  }

  ngOnDestroy(): void {
    this.clearOnDestroy();
  }

  openPreview(advancedExpense: AdvancedExpense, previewOnly: boolean): void {
    if (previewOnly) {
      this.trackingService.dispatch(new ExpenseFromReportPreviewOpened());
    }

    this.documentsService
      .getDetail(advancedExpense.id)
      .pipe(
        switchMap(document => {
          return this.documentPreviewService
            .openDocumentPreview({
              document,
              disableCategorySelect: true,
              disableLabel: previewOnly,
              disableMetadata: previewOnly,
            })
            .beforeClosed();
        }),
        filter(({ hasDocumentChanged }) => hasDocumentChanged),
        tap(() => this.reloadAfterPreviewClosed(advancedExpense.id)),
        untilDestroyed(this),
      )
      .subscribe();
  }

  openReceiptsImportDialog(): void {
    this.store
      .select(categoriesWithIdSelector)
      .pipe(
        take(1),
        map(categories =>
          categories
            .filter(
              category =>
                category.identifier !==
                  StandardDocumentCategoryIdentifier.EXPENSE_REPORT &&
                category.identifier !==
                  StandardDocumentCategoryIdentifier.EXTERNAL_INVOICE,
            )
            .map(category => category.id),
        ),
        switchMap(categoriesId => this.openAddReceiptsDialog(categoriesId)),
        tap(receipts => {
          this.receiptsToImport.push(...receipts);
          this.addImportedDocumentsToForm(receipts);
        }),
      )
      .subscribe();
  }

  createAdvancedExpense(): void {
    this.advancedExpenseCategory$
      .pipe(
        switchMap(category =>
          this.createAdvancedExpensesCategoryIfNeeded(category),
        ),
        switchMap(category => {
          const document: Partial<Document> = {
            metadata: category.availableMetadata?.map(metadataDef => ({
              ...metadataDef,
              display_value: null,
            })),
            category,
            openingMode: DocumentOpeningMode.NoFileAvailable,
            owner: this.owner,
          };

          return this.documentPreviewService
            .openDocumentPreview({
              document: document as Document,
              disableCategorySelect: true,
            })
            .beforeClosed();
        }),
        filter(({ hasDocumentChanged }) => hasDocumentChanged),
        tap(({ document }) => {
          this.addImportedDocumentsToForm([document]);
        }),
        untilDestroyed(this),
      )
      .subscribe();
  }

  checkForIncompleteAdvancedExpenses(): Observable<boolean> {
    if (this.hasSomeIncompleteLines()) {
      return defer(() =>
        this.confirmationDialogService.requireConfirmation({
          theme: 'warn',
          title:
            'Certains champs de cette note de frais sont vides, êtes-vous sûr de bien vouloir la valider ?',
          description: [],
        }),
      );
    }

    return of(true);
  }

  importDocuments(): Observable<Document[]> {
    this.removeTemporaryDocumentsToImportFromAdvancedExpenses();

    if (this.receiptsToImport.length > 0) {
      return this.advancedExpenseCategory$.pipe(
        switchMap(advancedExpensesCategory => {
          return this.createAdvancedExpensesCategoryIfNeeded(
            advancedExpensesCategory,
          ).pipe(
            map((category: Category) => ({
              category,
              receipts: this.receiptsToImport,
            })),
          );
        }),
        switchMap(({ category, receipts }) =>
          this.moveReceipts(receipts, category, this.owner.id),
        ),
        tap(() => {
          this.receiptsToImport = [];
        }),
      );
    } else {
      return of([] as Document[]);
    }
  }

  addOrRemoveLineFromExpenseToImport(
    checkedExpense: Document,
    checked: boolean,
  ): void {
    const isImportedReceipt = !this.initialExpenses.find(
      expense => expense.id === checkedExpense.id,
    );
    if (isImportedReceipt) {
      if (!checked) {
        const index = this.receiptsToImport.findIndex(
          receipt => receipt.id === checkedExpense.id,
        );
        this.receiptsToImport.splice(index);
      } else {
        this.receiptsToImport.push(checkedExpense);
      }
    }
    this.cdr.markForCheck();
  }

  protected clearOnDestroy(): void {
    this.expenseReportForm.advancedExpenses.clear(FormUtils.shouldNotEmitEvent);
  }

  private hasSomeIncompleteLines(): boolean {
    return this.lines
      ?.filter(line => line.isChecked)
      .some(({ isIncomplete }) => isIncomplete);
  }

  private openAddReceiptsDialog(
    categoriesId: number[],
  ): Observable<Document[]> {
    return this.dialog
      .open<
        ExpensesReportAddReceiptsDialogComponent,
        ExpensesReportAddReceiptsDialogData,
        Document[]
      >(ExpensesReportAddReceiptsDialogComponent, {
        width: '800px',
        minHeight: 500,
        maxHeight: 756,
        data: {
          categoryId: categoriesId,
          alreadyAddedReceipts: this.receiptsToImport,
        },
      })
      .beforeClosed()
      .pipe(
        filter(selection => Array.isArray(selection) && selection.length > 0),
      );
  }

  private createAdvancedExpensesCategoryIfNeeded(
    category: Category,
  ): Observable<Category> {
    if (category.id === null) {
      this.store.dispatch(
        addCategory({
          name: StandardDocumentCategory.advanced_expense,
        }),
      );

      return this.actions$.pipe(
        ofType(ADD_CATEGORY_SUCCESS),
        take(1),
        map(({ category }) => category as Category),
      );
    } else {
      return of(category);
    }
  }

  private reloadAfterPreviewClosed(advancedExpenseId: number): void {
    const receiptToImportIndex = this.receiptsToImport.findIndex(
      doc => doc.id === advancedExpenseId,
    );

    if (receiptToImportIndex !== -1) {
      this.reloadReceiptToImport(advancedExpenseId, receiptToImportIndex);
    } else {
      this.reloadAdvancedExpenses();
    }
  }

  private reloadReceiptToImport(id: number, index: number): void {
    this.documentsService
      .getDetail(id)
      .pipe(
        tap(document => (this.receiptsToImport[index] = document)),
        tap(() => this.reloadAdvancedExpenses()),
      )
      .subscribe();
  }

  private removeTemporaryDocumentsToImportFromAdvancedExpenses(): void {
    const advancedExpenses = this.expenseReportForm.advancedExpenses.value as {
      expense: AdvancedExpense;
      checked: boolean;
    }[];
    this.receiptsToImport.forEach(receipt => {
      const index = advancedExpenses.findIndex(
        advancedExpense => advancedExpense.expense?.id === receipt.id,
      );

      if (index !== -1) {
        this.expenseReportForm.advancedExpenses.removeAt(index);
      }
    });
  }

  private addImportedDocumentsToForm(documents: Document[]): void {
    const advancedExpenses = documents.map(document =>
      AdvancedExpense.fromDocument(document),
    );

    for (const advancedExpense of advancedExpenses) {
      const expenseControl =
        this.expenseReportForm.advancedExpenses.controls.find(
          control => control.line.value.id === advancedExpense.id,
        );
      if (expenseControl) {
        expenseControl.line.patchValue(advancedExpense);
        expenseControl.patchValue({ checked: true });
      } else {
        this.expenseReportForm.advancedExpenses.push(
          new ExpenseReportEditorLineForm<AdvancedExpense>(
            advancedExpense,
            true,
          ),
        );
      }
    }
    this.cdr.markForCheck();
  }

  private reloadAdvancedExpenses(): void {
    if (this.currentEditorStatus === ExpensesReportEditorStatus.Create) {
      this.advancedExpensesUpdated([]);
      return;
    }

    this.reloadExpenseReport.emit();
  }

  private getAdvancedExpenses(): Observable<AdvancedExpense[]> {
    this.loading$.next(true);
    return this.advancedExpenseCategory$.pipe(
      filter(advancedExpenseCategory => !!advancedExpenseCategory.id),
      switchMap(advancedExpenseCategory => {
        return this.documentsService.getDocumentsWithoutPagination({
          sort: {
            active: 'metadata.date',
            direction: 'asc',
          },
          filters: [
            new MonoValueFilter(
              ExpenseListApiFilters.LINKED_TO_EXPENSE_REPORT,
              false,
            ),
            new MonoValueFilter('owners', this.owner.id),
            new MonoValueFilter(
              'document_categories',
              advancedExpenseCategory.id,
            ),
          ],
        });
      }),
      map(documents =>
        documents.map(document => AdvancedExpense.fromDocument(document)),
      ),
      finalize(() => this.loading$.next(false)),
    );
  }

  private advancedExpensesUpdated(
    newAdvancedExpenses: AdvancedExpense[],
  ): void {
    this.getAdvancedExpenses()
      .pipe(
        untilDestroyed(this),
        map(advancedExpenses => [...newAdvancedExpenses, ...advancedExpenses]),
        tap(expenses => {
          this.initialExpenses = expenses;
          this.expenseReportForm.advancedExpenses.update(
            expenses,
            this.currentEditorStatus,
          );
        }),
        finalize(() => {
          this.addImportedDocumentsToForm(this.receiptsToImport);
          this.cdr.markForCheck();
        }),
      )
      .subscribe();
  }

  private observeCancelEdit(): void {
    this.expensesReportEditorService.cancelEdit$
      .pipe(
        untilDestroyed(this),
        tap(() => {
          this.removeTemporaryDocumentsToImportFromAdvancedExpenses();
          this.receiptsToImport = [];
        }),
      )
      .subscribe();
  }

  private moveReceipts(
    receipts: Document[],
    newCategory: Category,
    ownerId: number,
  ): Observable<Document[]> {
    return forkJoin(
      receipts.map(({ id }) =>
        this.documentsService
          .update(
            {
              category: newCategory,
              owner: new User(ownerId),
            },
            id,
          )
          .pipe(catchError(() => of(null))),
      ),
    ).pipe(map(documents => documents.filter(document => document !== null)));
  }
}
