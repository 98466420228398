export interface ComputedVehicleResponseApiContract {
  vehicle_id: number;
  formula: string;
  is_electric: boolean;
  distance: number;
  travels_distance: number;
  compensation_rate: string;
  already_paid: number;
  external_already_paid: number;
  total: number;
  compensation_rate_legend: string;
  sub_total_distance: number;
}

export class ComputedVehicleResponse {
  constructor(
    public vehicleId: number,
    public formula: string,
    public isElectric: boolean,
    public distance: number,
    public travelsDistance: number,
    public compensationRate: string,
    public alreadyPaidAmount: number,
    public externalAlreadyPaidAmount: number,
    public total: number,
    public compensationRateLegend: string,
    public subTotalDistance: number,
  ) {}

  static fromJson(
    json: ComputedVehicleResponseApiContract,
  ): ComputedVehicleResponse {
    return new ComputedVehicleResponse(
      json.vehicle_id,
      json.formula,
      json.is_electric,
      json.distance,
      json.travels_distance,
      json.compensation_rate,
      json.already_paid,
      json.external_already_paid,
      json.total,
      json.compensation_rate_legend,
      json.sub_total_distance,
    );
  }
}
