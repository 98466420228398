import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import {
  TiimeAutoFocusModule,
  TiimeButtonModule,
  TiimeIconModule,
  TiimeLetModule,
  TiimeTooltipModule,
  TiimeTwocolorsIconModule,
} from 'tiime-components';

import { HasAccessDirective } from '@core/directives/acl/has-access.directive';

import { CompanyAcronymLogoModule } from '../../company-shared/components/company-acronym-logo/company-acronym-logo.module';
import { UserAccountMenuOverlayComponent } from './user-account-menu-overlay.component';

@NgModule({
  declarations: [UserAccountMenuOverlayComponent],
  imports: [
    CommonModule,
    CompanyAcronymLogoModule,
    MatProgressSpinnerModule,
    ReactiveFormsModule,
    TiimeAutoFocusModule,
    TiimeButtonModule,
    TiimeIconModule,
    TiimeTooltipModule,
    TiimeTwocolorsIconModule,
    TiimeLetModule,
    HasAccessDirective,
    MatAutocompleteModule,
  ],
  exports: [UserAccountMenuOverlayComponent],
})
export class UserAccountMenuOverlayModule {}
