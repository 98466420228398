<tiime-dialog-close-button></tiime-dialog-close-button>

<mat-dialog-content>
  <app-matching-dialog-header
    *ngIf="data.matchingSource; else defaultHeader"
    [matchingSource]="data.matchingSource"
    [label]="data.matchingSource | map: mapToLabel"
  ></app-matching-dialog-header>

  <ng-template #defaultHeader>
    <div class="default-header">Relier depuis mes documents</div>
  </ng-template>

  <app-matching-tables
    [selectedDocuments]="selectedDocuments"
    [selectedBankTransactions]="selectedBankTransactions"
    [selectedImputations]="selectedImputations"
    [disabledItems]="data.disabledItems"
    [matchingTypes]="data.matchingTypes"
    (documentSelected)="documentSelected($event)"
    (bankTransactionSelected)="bankTransactionSelected($event)"
    (imputationSelected)="imputationSelected($event)"
  ></app-matching-tables>
</mat-dialog-content>

<mat-dialog-actions>
  <app-matched-items
    [matchedItems]="
      selectedDocuments
        | concatMatchedItems: selectedBankTransactions : selectedImputations
    "
    [matchingTypes]="data.matchingTypes"
    (removeItem)="onRemoveItem($event)"
  ></app-matched-items>

  <button
    tiime-button
    accent
    data-cy="dialog__btn-submit"
    [disabled]="!data.allowUnmatch && !hasSelectedItems()"
    (click)="matchSelectedData()"
  >
    <span data-cy="dialog__txt-btn-submit">
      {{
        data.matchingSource
          | submitButtonText: data.allowUnmatch && !hasSelectedItems()
      }}&nbsp;
    </span>
    <span data-cy="dialog__txt-amount-btn-submit">
      {{
        data.matchingSource
          | matchingAmount
            : selectedDocuments
            : selectedBankTransactions
            : selectedImputations
          | currency
      }}
    </span>
  </button>
</mat-dialog-actions>
