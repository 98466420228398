import { Directive, Input } from '@angular/core';

import { ExpenseReportEditorLineForm, ExpensesReportForm } from '@forms';
import { ExpenseReportType, User } from '@models';
import { MetadataDef } from '@models/documents';

import { DocumentEditorTableColumn } from '../../../../../../../shared/components/document-editor-table-header/document-editor-table-column';
import { CellDefinition } from '../expenses-report-editor-line/expenses-report-editor-line.component';
import { ExpensesReportEditorStatus } from '../expenses-report-editor/expenses-report-editor.component';

@Directive()
export abstract class EditorBodyComponentBase<T extends { id: number }> {
  @Input() expenseReportForm: ExpensesReportForm;

  @Input() currentEditorStatus: ExpensesReportEditorStatus;

  @Input() disableLines: boolean;

  @Input() owner: User;

  @Input() metadata: MetadataDef[];

  readonly ExpenseReportType = ExpenseReportType;

  readonly ExpensesReportEditorStatus = ExpensesReportEditorStatus;

  readonly color = '#ffba3f';

  abstract readonly columns: DocumentEditorTableColumn[];

  abstract readonly cellDefinitions: CellDefinition<T>[];

  protected abstract clearOnDestroy(): void;

  trackById(_: number, form: ExpenseReportEditorLineForm<T>): number {
    return form.line.value.id;
  }
}
