import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { NgUtils } from '@manakincubber/tiime-utils';

@Component({
  selector: 'tiime-table-content-placeholder',
  templateUrl: './table-content-placeholder.component.html',
  styleUrls: ['./table-content-placeholder.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TableContentPlaceholderComponent {
  @Input() withoutBorderRadius = false;
  @Input() withoutElevation = false;
  @Input() withoutBorders = false;
  @Input()
  set linesLength(value: number) {
    this.lines = new Array(value);
  }

  lines: void[] = new Array(10);
  readonly trackByIndex = NgUtils.trackByIndex;
}
