import { Action, createReducer, on } from '@ngrx/store';
import { cloneDeep } from 'lodash-es';
import { PaginationData } from 'tiime-components';

import { Document, DocumentsWithMetadata } from '@models';

import { addCategorySuccess } from '../categories/categories.actions';
import {
  createDocumentSuccess,
  loadDocumentsForSearchSuccess,
  loadDocumentSuccess,
  removeDocumentFromStore,
  resetDocumentsForCategory,
  updateDocumentsForCategory,
  updateDocumentSuccess,
  updateRecentDocuments,
} from './documents.actions';

export interface DocumentsState {
  [id: string]: DocumentsWithMetadata;
  recent: DocumentsWithMetadata | null;
  search: DocumentsWithMetadata | null;
}

export const documentsInitalState: DocumentsState = {
  recent: null,
  search: null,
};

export const documents = createReducer(
  documentsInitalState,
  on(updateDocumentsForCategory, (state, action) => ({
    ...state,
    [action.categoryId]: action.documents,
  })),
  on(resetDocumentsForCategory, (state, action) => ({
    ...state,
    [action.categoryId]: null,
  })),
  on(updateRecentDocuments, (state, action) => ({
    ...state,
    recent: action.documents,
  })),
  on(createDocumentSuccess, (state, action) =>
    insertDocumentInState(action.document, state),
  ),
  on(updateDocumentSuccess, (state, action) =>
    insertDocumentInState(action.document, state),
  ),
  on(loadDocumentSuccess, (state, action) =>
    insertDocumentInState(action.document, state),
  ),
  on(loadDocumentsForSearchSuccess, (state, action) => ({
    ...state,
    search: action.documents,
  })),
  on(addCategorySuccess, (state, action) => ({
    ...state,
    [action.category.id]: new DocumentsWithMetadata(
      new PaginationData<Document>([]),
      {},
    ),
  })),
  on(removeDocumentFromStore, (state, action) =>
    removeDocumentFromState(action.documentId, state),
  ),
);

export function documentsReducer(
  state: DocumentsState,
  action: Action,
): DocumentsState {
  return documents(state, action);
}

function removeDocumentFromState(
  documentId: number,
  state: DocumentsState,
): DocumentsState {
  let categoryId: string;
  let documentIndex = -1;
  Object.entries(state).forEach(([id, documents]) => {
    documentIndex = documents?.documents.data.findIndex(
      doc => doc.id === documentId,
    );
    if (documentIndex > -1) {
      categoryId = id;
    }
  });
  if (!categoryId) {
    return state;
  }
  const documentsToUpdate = cloneDeep(state[categoryId].documents);
  documentsToUpdate.data.splice(documentIndex, 1);
  return {
    ...state,
    [categoryId]: {
      ...state[categoryId],
      documents: documentsToUpdate,
    },
  };
}

function insertDocumentInState(
  document: Document,
  state: DocumentsState,
): DocumentsState {
  const categoryId = document.category.id;
  const documentsToUpdate: PaginationData<Document> = state[categoryId]
    ? cloneDeep(state[categoryId].documents)
    : new PaginationData<Document>([]);
  const documentIndex = documentsToUpdate.data.findIndex(
    doc => doc.id === document.id,
  );
  documentsToUpdate.data.splice(documentIndex, 1, document);
  return {
    ...state,
    [categoryId]: {
      ...state[categoryId],
      documents: documentsToUpdate,
    },
  };
}
