import {
  OverlayConfig,
  OverlayRef,
  OverlaySizeConfig,
} from '@angular/cdk/overlay';
import { Observable, Subject } from 'rxjs';

export class TiimeOverlayRef<R = unknown> {
  private readonly afterClosedSub = new Subject<R | undefined>();
  private readonly beforeClosedSub = new Subject<R | undefined>();

  get config(): OverlayConfig {
    return this.overlayRef.getConfig();
  }

  get overlayElement(): HTMLElement {
    return this.overlayRef.overlayElement;
  }

  constructor(private readonly overlayRef: OverlayRef) {}

  close(data?: R): void {
    this.beforeClosedSub.next(data);
    this.beforeClosedSub.complete();
    this.overlayRef.dispose();
    this.afterClosedSub.next(data);
    this.afterClosedSub.complete();
  }

  afterClosed(): Observable<R | undefined> {
    return this.afterClosedSub.asObservable();
  }

  beforeClosed(): Observable<R | undefined> {
    return this.beforeClosedSub.asObservable();
  }

  backdropClick(): Observable<MouseEvent> {
    return this.overlayRef.backdropClick();
  }

  keydownEvents(): Observable<KeyboardEvent> {
    return this.overlayRef.keydownEvents();
  }

  updateSize(size: OverlaySizeConfig): void {
    this.overlayRef.updateSize(size);
  }

  updatePosition(): void {
    this.overlayRef.updatePosition();
  }
}
