import {
  ChangeDetectorRef,
  Directive,
  ElementRef,
  HostListener,
  inject,
  OnDestroy,
  ViewChild,
} from '@angular/core';
import { ControlValueAccessor } from '@angular/forms';
import { BehaviorSubject } from 'rxjs';
import { finalize } from 'rxjs/operators';
import {
  TiimeOverlayConfig,
  TiimeOverlayRef,
  TiimeOverlayService,
} from 'tiime-components';

@Directive()
export abstract class SelectorCardWithFilterOverlayBase<T, R>
  implements OnDestroy, ControlValueAccessor
{
  private onChange: (value: T) => void;
  private onTouched: () => void;

  protected readonly overlayService = inject(TiimeOverlayService);
  protected readonly cdr = inject(ChangeDetectorRef);

  protected readonly filterValue$ = new BehaviorSubject('');

  protected overlayRef: TiimeOverlayRef<R> = null;

  readonly disabled$ = new BehaviorSubject(false);

  readonly selectedItem$ = new BehaviorSubject<T>(undefined);

  @ViewChild('filterInput')
  protected readonly filterInput: ElementRef<HTMLInputElement>;
  @ViewChild('selectorContainer')
  protected readonly selectorContainer: ElementRef<HTMLElement>;

  @HostListener('document:keydown.escape', ['$event'])
  private onEscape(): void {
    this.updateOverlayRef(null);
  }

  @HostListener('document:keydown.tab', ['$event'])
  private onTab($event: KeyboardEvent): void {
    if (!this.overlayRef) {
      return;
    }

    const hasAlreadyFocus =
      this.overlayRef.overlayElement.querySelector(':focus');

    if (hasAlreadyFocus) {
      return;
    }

    $event.stopImmediatePropagation();
    $event.preventDefault();

    const firstAccountButton: HTMLButtonElement =
      this.overlayRef.overlayElement.querySelector('.account-button');
    firstAccountButton?.focus();
  }

  @HostListener('document:click', ['$event'])
  private onClick(event: MouseEvent): void {
    if (!this.overlayRef) {
      return;
    }

    const clickInSelectorContainer =
      this.selectorContainer.nativeElement?.contains(
        event.target as HTMLElement,
      );
    const clickInOverlayElement = this.overlayRef.overlayElement?.contains(
      event.target as HTMLElement,
    );

    if (!clickInSelectorContainer && !clickInOverlayElement) {
      this.updateOverlayRef(null);
    }
  }

  ngOnDestroy(): void {
    this.updateOverlayRef(null);
  }

  writeValue(value: T): void {
    this.selectedItem$.next(value);
  }

  registerOnChange(fn: (value: T) => void): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled$.next(isDisabled);
  }

  abstract cardClicked(args?: unknown): void;

  protected openOverlay(overlayRef: TiimeOverlayRef<R>): void {
    if (this.overlayRef) {
      this.overlayRef.close();
      this.updateOverlayRef(null);
    }

    this.updateOverlayRef(overlayRef);

    this.overlayRef
      .beforeClosed()
      .pipe(finalize(() => this.updateOverlayRef(null)))
      .subscribe();

    setTimeout(() => this.filterInput.nativeElement.focus());
  }

  protected getOverlayConfig(): TiimeOverlayConfig {
    return {
      hasBackdrop: false,
      width: this.selectorContainer.nativeElement.offsetWidth,
      maxHeight: '296px',
      connectTo: {
        origin: this.selectorContainer,
        positions: [
          {
            originX: 'center',
            originY: 'bottom',
            overlayX: 'center',
            overlayY: 'top',
          },
        ],
      },
      repositionOnScroll: true,
    };
  }

  protected filterChange($event: KeyboardEvent): void {
    // handled by `@HostListener`
    if ($event.code === 'Tab') {
      return;
    }

    const value = ($event.target as HTMLInputElement)?.value;
    this.filterValue$.next(value.trim().toLowerCase());
  }

  protected notifyUpdate(value: T): void {
    this.onChange(value);
    this.onTouched();
  }

  private updateOverlayRef(overlayRef?: TiimeOverlayRef<R>): void {
    if (!overlayRef) {
      this.overlayRef?.close();
      this.resetFilter();
    }
    this.overlayRef = overlayRef;
    this.cdr.markForCheck();
  }

  private resetFilter(): void {
    if (!this.filterValue$.closed) {
      this.filterValue$.next(null);
    }
    this.filterInput.nativeElement.value = null;
  }
}
