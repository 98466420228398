import { BusinessAccessRequestStatus } from '../enum/business-access-request-status.enum';
import { BusinessUnit, BusinessUnitApiContract } from './business-unit';

export interface BusinessAccessRequestApiContract {
  id: number;
  status: BusinessAccessRequestStatus;
  business_unit: BusinessUnitApiContract;
}

export class BusinessAccessRequest {
  constructor(
    public id?: number,
    public status?: BusinessAccessRequestStatus,
    public businessUnit?: BusinessUnit,
  ) {}

  static fromJson(
    json: BusinessAccessRequestApiContract,
  ): BusinessAccessRequest {
    return new BusinessAccessRequest(
      json.id,
      json.status,
      BusinessUnit.fromJson(json.business_unit),
    );
  }
}

export interface BusinessAccessRequestPatchUpdates {
  status?: BusinessAccessRequestStatus;
}
