import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Sort } from '@angular/material/sort';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { PaginationData, PaginationRange } from 'tiime-components';

import { ApiAlertError } from '@decorators/api-alert-error';
import { HttpHelper } from '@helpers';
import {
  Category,
  CategoryApiContract,
  Document,
  DocumentApiContract,
} from '@models';

@Injectable({
  providedIn: 'root',
})
export class CategoriesService {
  private readonly resource =
    'api/v1/companies/{companyId}/document_categories';

  constructor(private readonly http: HttpClient) {}

  @ApiAlertError()
  getDocumentsByCategory(
    categoryId: number,
    range: PaginationRange,
    sort: Sort,
    searchTerm?: string,
  ): Observable<PaginationData<Document>> {
    const url = `${this.resource}/${categoryId}/documents`;
    const headers = HttpHelper.setRangeHeader(new HttpHeaders(), range);
    let params = new HttpParams();
    params = HttpHelper.setSortParam(params, sort);
    if (searchTerm) {
      params = params.set('name', searchTerm);
    }
    const options = { params, headers };

    return this.http
      .get(url, { ...options, observe: 'response' })
      .pipe(
        HttpHelper.mapToPaginationData(
          range,
          (documentJson: DocumentApiContract) =>
            Document.fromJson(documentJson),
        ),
      );
  }

  @ApiAlertError()
  delete(categoryId: number): Observable<unknown> {
    return this.http.delete(`${this.resource}/${categoryId}`);
  }

  @ApiAlertError()
  update(category: Category): Observable<Category> {
    const url = `${this.resource}/${category.id}`;

    return this.http
      .patch(url, Category.toJson(category))
      .pipe(
        map((categoryJson: CategoryApiContract) =>
          Category.fromJson(categoryJson),
        ),
      );
  }

  @ApiAlertError()
  create(name: string): Observable<Category> {
    const body = { name };

    return this.http
      .post(this.resource, body)
      .pipe(
        map((categoryJson: CategoryApiContract) =>
          Category.fromJson(categoryJson),
        ),
      );
  }

  @ApiAlertError()
  getAll(): Observable<Category[]> {
    const headers = new HttpHeaders(
      'Accept: application/vnd.tiime.documents.v3+json',
    );
    return this.http
      .get(this.resource, { headers })
      .pipe(
        map((categories: CategoryApiContract[]) =>
          categories.map(Category.fromJson),
        ),
      );
  }
}
