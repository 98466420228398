<div *ngIf="loading$ | async; then loader; else logo"></div>
<ng-template #logo>
  <img
    *ngIf="src; else acronym"
    alt="Logo"
    [src]="src"
    [style.width.px]="width"
    [style.height.px]="height"
  />
</ng-template>
<ng-template #loader>
  <tiime-progress-spinner-loader
    color="primary"
    mode="indeterminate"
    [diameter]="loaderSize"
  ></tiime-progress-spinner-loader>
</ng-template>
<ng-template #acronym>
  <app-acronym
    [color]="color"
    [slug]="slug"
    [style.width.px]="width"
    [style.height.px]="height"
  ></app-acronym>
</ng-template>
