import moment from 'moment';

import { VatRate, VatRateApiContract } from './vat-rate';

export interface VatTypeApiContract {
  code: string;
  name: string;
  main: boolean;
  area: string;
  vat_rates: VatRateApiContract[];
  available_if_subject_to_vat: boolean;
  invoice_legal_notice?: string;
}

export class VatType {
  get hasName(): boolean {
    return this.name !== '{{rate}}';
  }

  constructor(
    public code?: string,
    public name?: string,
    public main?: boolean,
    public area?: string,
    public vatRates?: VatRate[],
    public invoiceLegalNotice?: string,
    public availableIfSubjectToVat?: boolean,
  ) {}

  public static fromJson(json: VatTypeApiContract): VatType {
    return new VatType(
      json.code,
      json.name,
      json.main,
      json.area,
      json.vat_rates
        ? json.vat_rates.map((vatRateJson: VatRateApiContract) =>
            VatRate.fromJson(vatRateJson),
          )
        : [],
      json.invoice_legal_notice,
      json.available_if_subject_to_vat,
    );
  }

  public static toJson(vatType: VatType): Record<string, unknown> {
    return {
      code: vatType.code,
      name: vatType.name,
      main: vatType.main,
      area: vatType.area,
      vat_rates: vatType.vatRates?.map((vatRate: VatRate) =>
        VatRate.toJson(vatRate),
      ),
      invoice_legal_notice: vatType.invoiceLegalNotice,
      available_if_subject_to_vat: vatType.availableIfSubjectToVat,
    };
  }

  public static filterZeroRateVatTypes(vatType: VatType): boolean {
    return !!vatType.invoiceLegalNotice;
  }

  vatRateEmissionDateIn(emissionDate?: string | moment.Moment): number {
    let emissionDateAsDate: Date;
    if (!emissionDate) {
      emissionDateAsDate = new Date();
    } else if (moment.isMoment(emissionDate)) {
      emissionDateAsDate = emissionDate.toDate();
    } else if (typeof emissionDate === 'string') {
      emissionDateAsDate = moment(emissionDate, ['YYYY-MM-DD']).toDate();
    } else {
      emissionDateAsDate = moment(emissionDate).toDate();
    }

    const vatRate = this.vatRates.find((rate: VatRate) =>
      rate.isDateIn(emissionDateAsDate),
    );
    return vatRate?.rate ?? 0;
  }
}
