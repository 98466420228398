import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ContentChild,
  ElementRef,
  HostBinding,
  HostListener,
  Input,
  OnDestroy,
  Renderer2,
} from '@angular/core';
import { Mapper } from '@manakincubber/tiime-utils';

@Component({
  selector: 'app-formatted-number-input',
  templateUrl: './formatted-number-input.component.html',
  styleUrls: ['./formatted-number-input.component.scss'],
})
export class FormattedNumberInputComponent implements AfterViewInit, OnDestroy {
  @Input() forcedValue: number;
  @Input() error: string | boolean;
  @Input() currency?: string;
  @Input() customSuffix = '';
  @Input() format? = '1.2-2';

  @HostBinding('class.input-focused') isInputFocused = false;

  @HostBinding('class.empty')
  get empty(): boolean {
    return !this.injectedInput?.nativeElement.value;
  }

  @HostBinding('class.error')
  get hasError(): boolean {
    return !!this.error;
  }

  @ContentChild('targetInput')
  injectedInput: ElementRef<HTMLInputElement>;

  private blurListenerFn: () => void;
  private focusListenerFn: () => void;

  readonly mapToDisplayStyle: Mapper<boolean, string> = (
    isInputFocused: boolean,
  ) => (isInputFocused ? 'none' : 'flex');

  constructor(private renderer: Renderer2, private cdr: ChangeDetectorRef) {}

  ngAfterViewInit(): void {
    setTimeout(() => {
      this.onInputBlur();
      this.initInputEventListeners();
    });
  }

  ngOnDestroy(): void {
    if (this.blurListenerFn) {
      this.blurListenerFn();
    }
    if (this.focusListenerFn) {
      this.focusListenerFn();
    }
  }

  refresh(): void {
    this.cdr.markForCheck();
  }

  initInputEventListeners(): void {
    this.blurListenerFn = this.renderer.listen(
      this.injectedInput.nativeElement,
      'blur',
      () => this.onInputBlur(),
    );
    this.focusListenerFn = this.renderer.listen(
      this.injectedInput.nativeElement,
      'focus',
      () => this.onFocus(),
    );
  }

  @HostListener('click')
  onClick(): void {
    this.injectedInput.nativeElement.focus();
  }

  private onInputBlur(): void {
    const opacity = this.empty ? 1 : 0;
    this.renderer.setStyle(
      this.injectedInput.nativeElement,
      'opacity',
      opacity,
    );
    this.isInputFocused = false;
    this.cdr.markForCheck();
  }

  private onFocus(): void {
    this.renderer.setStyle(this.injectedInput.nativeElement, 'opacity', 1);
    this.isInputFocused = true;
    this.cdr.markForCheck();
  }
}
