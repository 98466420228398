import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { TiimeIconModule } from '../icon';
import { AutoSaveComponent } from './auto-save.component';

@NgModule({
  imports: [CommonModule, TiimeIconModule],
  declarations: [AutoSaveComponent],
  exports: [AutoSaveComponent],
})
export class TiimeAutoSaveModule {}
