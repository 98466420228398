import { BankAccount, BankAccountApiContract } from '../bank-account';

export interface SepaPaymentId {
  id: number;
}

export interface SepaPaymentBodyContract {
  sepa_payment_initiations: SepaPaymentId[];
}

export interface SepaPaymentApiContract {
  id: number;
  beneficiary_name?: string;
  beneficiary_iban?: string;
  amount: number;
  execution_date: string;
  created_at?: string;
  confirmed_at?: string;
  bank_account?: BankAccountApiContract;
}

export class SepaPayment {
  constructor(
    public id: number,
    public beneficiaryName: string,
    public beneficiaryIban: string,
    public amount: number,
    public executionDate: string,
    public createdAt: string,
    public confirmedAt: string,
    public bank_account: BankAccount,
  ) {}

  public static fromJson(json: SepaPaymentApiContract): SepaPayment {
    return new SepaPayment(
      json.id,
      json.beneficiary_name,
      json.beneficiary_iban,
      json.amount,
      json.execution_date,
      json.created_at,
      json.confirmed_at,
      json.bank_account ? BankAccount.fromJson(json.bank_account) : null,
    );
  }

  public static toJson(payment: SepaPayment): SepaPaymentApiContract {
    return {
      id: payment.id,
      amount: payment.amount,
      execution_date: payment.executionDate,
    };
  }
}
