export enum DashboardType {
  custom = 'custom',
  sales = 'sales',
  cashing = 'in_out',
  lastTransactions = 'last_transactions',
  lastDocumentsFromAccountant = 'last_documents_from_accountant',
  estimatedVat = 'estimated_vat',
  inOutGauge = 'in_out_gauge',
  donut = 'donut',
}

export enum DashboardPeriodType {
  customPeriod = 'custom_period',
  lastMonths = 'last_months',
  currentAccountingPeriod = 'current_accounting_period',
}
