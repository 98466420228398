import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TiimePipesModule } from '@manakincubber/tiime-utils';

import { LabelIconComponent } from './label-icon.component';

@NgModule({
  declarations: [LabelIconComponent],
  imports: [CommonModule, TiimePipesModule],
  exports: [LabelIconComponent],
})
export class LabelIconModule {}
