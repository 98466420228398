import { Directive } from '@angular/core';
import { UntilDestroy } from '@ngneat/until-destroy';
import { ResetFilter } from 'tiime-components';

import { TableColumnFilterBaseDirective } from './table-column-filter-base.directive';
import { TableColumnFilterForm } from './table-column-filter.form';
import { TableColumnFilter } from './table-column-sort-and-filter';

@UntilDestroy()
@Directive()
export abstract class TableColumnFilterDirective extends TableColumnFilterBaseDirective<
  TableColumnFilterForm,
  TableColumnFilter
> {
  reset(): void {
    this.valueChange.emit(ResetFilter.forKey(this.filterKey));
    this.menuTrigger.closeMenu();
  }
}
