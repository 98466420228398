<div class="preview-container">
  <app-document-editor-table-header
    [columns]="columns$ | async"
    [color]="color$ | async"
  ></app-document-editor-table-header>

  <div
    *ngFor="let line of invoicingDocument.lines; trackBy: trackById"
    class="line-row"
  >
    <!-- Spaces are interpreted with `white-space: pre-wrap` so we -->
    <!-- need to keep this element space-free -->
    <!-- prettier-ignore -->
    <span class="cell designation-cell">{{ line.description }}</span>
    <ng-container
      *ngIf="invoicingDocument.template === InvoicingTemplate.advanced"
    >
      <span class="cell quantity-cell">
        {{ line.quantity | number: '1.2-5' }}
      </span>
      <span *ngIf="invoicingDocument.unitsEnabled" class="cell unit-cell">
        {{ line | translatedInvoicingUnit | async }}
      </span>
      <span class="cell unit-amount-cell">
        {{ line.unitAmount | number: '1.2-5' }}
      </span>
    </ng-container>
    <span class="cell vat-cell">
      {{
        line.vatType.code
          | vatRate: invoicingVatTypes:invoicingDocument.emissionDate
          | percent: '1.0-2'
      }}
    </span>
    <span class="cell total-excluding-taxes-cell">
      {{ line.lineAmount | number: '1.2-2' }}
    </span>
  </div>
</div>
<div *ngIf="displayOverflowOverlay | async" class="overflow-overlay"></div>
