import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { map, switchMap } from 'rxjs/operators';

import { BusinessUnitsService } from '@core';
import { Company } from '@core/models';

import { LOAD_UNIT, loadBusinessUnitSuccess } from './business-unit.actions';

@Injectable()
export class BusinessUnitEffects {
  constructor(
    private readonly actions$: Actions,
    private readonly businessUnitsService: BusinessUnitsService,
  ) {}

  loadBusinessUnit$ = createEffect(() =>
    this.actions$.pipe(
      ofType(LOAD_UNIT),
      switchMap((action: { company: Company }) =>
        this.businessUnitsService
          .getBusinessUnit(action.company.id)
          .pipe(
            map(businessUnit =>
              loadBusinessUnitSuccess({ businessUnit: businessUnit }),
            ),
          ),
      ),
    ),
  );
}
