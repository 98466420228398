import { InvoicingTemplate } from '@enums';
import { Tag, TextLine } from '@models';

import { Line } from '../models/line';

export abstract class AbstractInvoicingDocumentBase {
  protected constructor(
    public id?: number,
    public title?: string,
    public invoicingDocumentNumber?: number,
    public emissionDate?: string,
    public totalExcludingTaxes?: number,
    public totalIncludingTaxes?: number,
    public lines?: Line[],
    public textLines?: TextLine[],
    public color?: string,
    public template?: InvoicingTemplate,
    public comment?: string,
    public tags?: Tag[],
    public unitsEnabled?: boolean,
  ) {}
}
