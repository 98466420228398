@if (preview$ | async; as preview) {
  @if (isPdf) {
    <pdf-viewer
      class="bank-transfer"
      style="width: 730px; height: calc(100vh - 82px)"
      [src]="preview"
      [zoom]="1"
      [zoom-scale]="'page-width'"
      [original-size]="false"
      [show-all]="true"
    ></pdf-viewer>
  } @else {
    <img class="preview" alt="Preview" draggable="false" [src]="preview" />
  }
} @else {
  <div class="loading-container">
    <app-tiime-spinner white></app-tiime-spinner>
    Chargement...
  </div>
}
