import { AccountantDetailRequestType } from '@enums';

export interface AccountantDetailRequestApiContract {
  id: number;
  type: AccountantDetailRequestType;
  answered_at: string;
  answered_at_client: string;
  requested_document_type?: string;
  document_declared_unavailable_at?: string;
}

export class AccountantDetailRequest {
  constructor(
    public id?: number,
    public type?: AccountantDetailRequestType,
    public answeredAt?: string,
    public answeredAtClient?: string,
    public requestedDocumentType?: string,
    public documentDeclaredUnavailableAt?: string,
  ) {}

  static fromJson(
    json: AccountantDetailRequestApiContract,
  ): AccountantDetailRequest {
    return new AccountantDetailRequest(
      json.id,
      json.type,
      json.answered_at,
      json.answered_at_client,
      json.requested_document_type,
      json.document_declared_unavailable_at,
    );
  }

  static toJson(
    request: AccountantDetailRequest,
  ): AccountantDetailRequestApiContract {
    return {
      id: request.id,
      type: request.type,
      answered_at: request.answeredAt,
      answered_at_client: request.answeredAtClient,
      requested_document_type: request.requestedDocumentType,
      document_declared_unavailable_at: request.documentDeclaredUnavailableAt,
    };
  }
}
