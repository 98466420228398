import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import {
  TiimeButtonModule,
  TiimeDialogModule,
  TiimeLetModule,
} from 'tiime-components';

import { IbanInputModule } from '../../../../account-shared/iban-input/iban-input.module';
import { AddBeneficiaryDialogComponent } from './add-beneficiary-dialog.component';

@NgModule({
  declarations: [AddBeneficiaryDialogComponent],
  imports: [
    CommonModule,
    IbanInputModule,
    TiimeDialogModule,
    ReactiveFormsModule,
    TiimeButtonModule,
    TiimeLetModule,
  ],
  exports: [AddBeneficiaryDialogComponent],
})
export class AddBeneficiaryDialogModule {}
