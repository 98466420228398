import { KycStatus } from '@core/enum';

import { Card, CardApiContract } from './card';

export interface CompanyWalletUserApiContract {
  id: number;
  kyc_status: KycStatus;
  should_order_card: boolean;
  wallet_card: CardApiContract;
}

export class CompanyWalletUser {
  constructor(
    public id: number,
    public kycStatus?: KycStatus,
    public shouldOrderCard?: boolean,
    public card?: Card,
  ) {}

  static fromJson(json: CompanyWalletUserApiContract): CompanyWalletUser {
    return new CompanyWalletUser(
      json.id,
      json.kyc_status,
      json.should_order_card,
      json.wallet_card ? Card.fromJson(json.wallet_card) : null,
    );
  }
}
