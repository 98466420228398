import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { BehaviorSubject } from 'rxjs';

export interface MatchBankTransferDialogData {
  amount: number;
  acceptedTypes: string[];
}

@Component({
  templateUrl: './match-bank-transfer-dialog.component.html',
  styleUrls: ['./match-bank-transfer-dialog.component.scss'],
})
export class MatchBankTransferDialogComponent {
  readonly selection$ = new BehaviorSubject<Document | null>(null);

  constructor(
    @Inject(MAT_DIALOG_DATA) public readonly data: MatchBankTransferDialogData,
    private readonly dialogRef: MatDialogRef<
      MatchBankTransferDialogComponent,
      Document | FileList
    >,
  ) {}

  submitMatch(receipt: Document): void {
    this.dialogRef.close(receipt);
  }

  onFileSelected(files: FileList): void {
    this.dialogRef.close(files);
  }
}
