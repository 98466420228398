interface RedirectionRule {
  routePattern: RegExp;
  generateRedirectUrl: (companyId?: number) => string;
}

export const defaultGenerator = (companyId: number): string =>
  `/companies/${companyId}`;

export const REDIRECTION_RULES: { [key: string]: RedirectionRule[] } = {
  todo: [
    {
      routePattern:
        /^\/companies\/\d+\/todo\/transactions-expert-request(([/?]).*)?$/,
      generateRedirectUrl: (companyId: number): string =>
        `/companies/${companyId}/todo`,
    },
  ],
  'cash-tracking': [
    {
      routePattern: /^\/companies\/\d+\/cash-tracking\/cash-registers\/.*$/,
      generateRedirectUrl: (companyId: number): string =>
        `/companies/${companyId}/cash-tracking`,
    },
  ],
  invoice: [
    {
      routePattern: /^\/companies\/\d+\/invoice\/invoices(([/?]).*)?$/,
      generateRedirectUrl: (companyId: number): string =>
        `/companies/${companyId}/invoice`,
    },
    {
      routePattern: /^\/companies\/\d+\/invoice\/quotes(([/?]).*)?$/,
      generateRedirectUrl: (companyId: number): string =>
        `/companies/${companyId}/invoice/quotes`,
    },
    {
      routePattern: /^\/companies\/\d+\/invoice\/clients(([/?]).*)?$/,
      generateRedirectUrl: (companyId: number): string =>
        `/companies/${companyId}/invoice/clients`,
    },
  ],
  expense: [
    {
      routePattern: /^\/companies\/\d+\/expense\/advanced-expenses(([/?]).*)?$/,
      generateRedirectUrl: (companyId: number): string =>
        `/companies/${companyId}/expense/advanced-expenses`,
    },
    {
      routePattern: /^\/companies\/\d+\/expense\/expense-invoice(([/?]).*)?$/,
      generateRedirectUrl: (companyId: number): string =>
        `/companies/${companyId}/expense/expense-invoice`,
    },
    {
      routePattern:
        /^\/companies\/\d+\/expense\/mileage-allowances(([/?]).*)?$/,
      generateRedirectUrl: (companyId: number): string =>
        `/companies/${companyId}/expense/mileage-allowances`,
    },
  ],
  documents: [
    {
      routePattern: /^\/companies\/\d+\/documents\/categories(([/?]).*)?$/,
      generateRedirectUrl: (companyId: number): string =>
        `/companies/${companyId}/documents/categories`,
    },
  ],
  account: [
    {
      routePattern: /^\/companies\/\d+\/account\/accounts(([/?]).*)?$/,
      generateRedirectUrl: (companyId: number): string =>
        `/companies/${companyId}/account/accounts`,
    },
    {
      routePattern: /^\/companies\/\d+\/account\/transactions(([/?]).*)?$/,
      generateRedirectUrl: (companyId: number): string =>
        `/companies/${companyId}/account/transactions`,
    },
    {
      routePattern: /^\/companies\/\d+\/account\/tiime-business$/,
      generateRedirectUrl: (companyId: number): string =>
        `/companies/${companyId}/account`,
    },
  ],
  registry: [
    {
      routePattern: /^\/companies\/\d+\/registry\/registries(([/?]).*)?$/,
      generateRedirectUrl: (companyId: number): string =>
        `/companies/${companyId}/registry/registries`,
    },
  ],
  settings: [
    'profile',
    'company',
    'bank-accounts',
    'invoices',
    'invoices/articles',
    'billers([?].*)?',
    'labels',
  ].map(route => ({
    routePattern: new RegExp('^/companies/\\d+/settings/' + route + '$'),
    generateRedirectUrl: (companyId: number): string =>
      `/companies/${companyId}/settings/company`,
  })),
};
