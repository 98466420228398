<tiime-dialog-close-button></tiime-dialog-close-button>

<mat-dialog-content class="dialog-content" mat-dialog-content>
  <iframe
    width="100%"
    height="100%"
    title="Contacter le support"
    [src]="formUrl$ | async"
  ></iframe>
</mat-dialog-content>
