import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

import { StandardDocumentCategoryIdentifier } from '@enums';
import { IconsModule } from '@shared';

import { CategoryIconComponent } from '../../../../../../../shared/components/category-icon/category-icon.component';

@Component({
  standalone: true,
  imports: [CommonModule, IconsModule, CategoryIconComponent],
  selector: 'app-expenses-report-editor-header-container',
  templateUrl: './expenses-report-editor-header-container.component.html',
  styleUrls: ['./expenses-report-editor-header-container.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ExpensesReportEditorHeaderContainerComponent {
  @Input() title: string;
  @Input() isAdvancedExpenseHeader = false;

  readonly StandardDocumentCategoryIdentifier =
    StandardDocumentCategoryIdentifier;
}
