import { TrackingEvent } from '@manakincubber/tiime-tracking';

export const MASS_ACTION_MULTIPLE_ITEMS_SELECTED =
  'Mass Action Multiple Items Selected';

export class MassActionMultipleItemsSelected implements TrackingEvent {
  readonly name = MASS_ACTION_MULTIPLE_ITEMS_SELECTED;

  constructor(readonly selectionLabel: string, readonly itemsType: string) {}
}
