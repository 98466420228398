import { Mapper } from '@manakincubber/tiime-utils';

import {
  DecisionTreeActionTypeChoice,
  DecisionTreeDocumentNeeded,
  DecisionTreeDocumentPicker,
  DECISION_TREE_ACTION_TYPE_CHOICE,
} from '@core/models';

function isDecisionTreeActionTypeChoice(maybeActionType: unknown): boolean {
  return DECISION_TREE_ACTION_TYPE_CHOICE.includes(
    maybeActionType as DecisionTreeActionTypeChoice,
  );
}

export function isPickerAction(
  maybePicker: DecisionTreeDocumentNeeded,
): maybePicker is DecisionTreeDocumentPicker {
  return maybePicker.action === 'document_picker';
}

export const mapToActionTypeIconPath: Mapper<string | undefined, string> = (
  actionType: string | undefined,
) =>
  `assets/illus/bank-transfer/illu_bank_transfer_document_proof_choice--${
    actionType && isDecisionTreeActionTypeChoice(actionType)
      ? actionType
      : 'transfer_reason'
  }.svg`;
