import { coerceBooleanProperty } from '@angular/cdk/coercion';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnDestroy,
} from '@angular/core';
import { AutoUnsubscribe } from '@manakincubber/tiime-utils';
import { BehaviorSubject, Subscription } from 'rxjs';
import { tap } from 'rxjs/operators';

import { MailForm } from './mail-form';

@AutoUnsubscribe()
@Component({
  selector: 'tiime-mail-form',
  templateUrl: './mail-form.component.html',
  styleUrls: ['./mail-form.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TiimeMailFormComponent implements OnDestroy, AfterViewInit {
  @Input() isSimpleDisplay: boolean;

  @Input()
  set enableCci(value: boolean) {
    this.shouldDisplayCci$.next(coerceBooleanProperty(value));
  }

  @Input()
  set mailForm(mailForm: MailForm) {
    this._mailForm = mailForm;

    if (this.formGroupTouchedSubscription) {
      this.formGroupTouchedSubscription.unsubscribe();
    }

    this.formGroupTouchedSubscription = this.mailForm.touchedChanges
      .pipe(tap(() => this.cdr.markForCheck()))
      .subscribe();
  }
  get mailForm(): MailForm {
    return this._mailForm;
  }

  private _mailForm: MailForm;
  private formGroupTouchedSubscription?: Subscription;

  readonly shouldDisplayCc$ = new BehaviorSubject(false);
  readonly shouldDisplayCci$ = new BehaviorSubject(false);

  constructor(private readonly cdr: ChangeDetectorRef) {}

  ngAfterViewInit(): void {
    const textArea = document.getElementById('body');
    if (textArea) {
      const textAreaHeight = window.getComputedStyle(textArea).height;
      const newHeight = parseFloat(textAreaHeight.split('px')[0]) + 36;
      textArea.setAttribute('style', `height: ${newHeight}px`);
    }
  }

  // eslint-disable-next-line @angular-eslint/no-empty-lifecycle-method
  ngOnDestroy(): void {
    /* @AutoUnsubscribe */
  }

  displayCc(): void {
    this.shouldDisplayCc$.next(true);
  }

  displayCci(): void {
    this.shouldDisplayCci$.next(true);
  }
}
