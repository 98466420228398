<div class="header">
  <span>J'effectue un virement</span>
  <button tiime-button neutral inverted icon (click)="sidePanelRef.close()">
    <mat-icon svgIcon="tc_icon-close"></mat-icon>
  </button>
</div>

@switch (ocerisationStep$ | async) {
  @case (OcerisationStep.DOC_UPLOADING) {
    <div class="overlay-content">
      <div class="overlay-content__title">
        <app-tiime-spinner></app-tiime-spinner>
        Envoi du document...
      </div>
    </div>
  }
  @case (OcerisationStep.OCERISATION) {
    <div class="overlay-content with-lottie">
      <div class="overlay-content__title">Document en cours d'analyse</div>
      <div class="overlay-content__lottie">
        <ng-lottie height="453px" [options]="loaderOptions"></ng-lottie>
      </div>
      @if (allowSkip$ | async) {
        <div class="overlay-content__subtitle">
          Il semblerait que l'analyse
          <br />
          prenne plus de temps...
          <button
            class="overlay-content__button skip-button"
            tiime-button
            neutral
            inverted
            (click)="ocerisationStep$.next(OcerisationStep.SKIPPED)"
          >
            Passer l'analyse
          </button>
        </div>
      } @else {
        <div class="overlay-content__subtitle">
          Veuillez attendre quelques instants
          <br />
          Tiime récupère les informations...
        </div>
      }
    </div>
  }
  @case (OcerisationStep.ERROR) {
    <div class="overlay-content">
      <div class="overlay-content__title">Envoi du document...</div>
      <app-error-banner
        title="Erreur lors de l'envoi du document"
        subtitle="Veuillez envoyer un nouveau document"
      ></app-error-banner>
      <button
        class="overlay-content__button"
        tiime-button
        neutral
        inverted
        (click)="ocerisationStep$.next(OcerisationStep.NOT_STARTED)"
      >
        Retour
      </button>
    </div>
  }
  @default {
    <div class="overlay-card-container">
      <app-bank-transfer-card
        [documentReason]="sidePanelData.bankTransferState?.reason"
        [selectedBeneficiary]="sidePanelData.bankTransferState?.beneficiary"
        [transferSource]="sidePanelData.bankTransferState?.transferSource"
        (closeEmitter)="sidePanelRef.close($event)"
      ></app-bank-transfer-card>
    </div>
  }
}
