import {
  ChangeDetectionStrategy,
  Component,
  HostBinding,
  Input,
} from '@angular/core';
import { AbstractTiimeChipDirective } from 'tiime-components';

import { Label } from '@models/labels';

@Component({
  selector: 'app-label-chip',
  templateUrl: './label-chip.component.html',
  styleUrls: ['./label-chip.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LabelChipComponent extends AbstractTiimeChipDirective {
  @HostBinding('class.large')
  @Input()
  large: boolean;

  @Input()
  readonly: boolean;

  @Input() label: Label;

  @Input() showRemoveIconOnHoverOnly = false;

  @Input() pointerEvenIfDisabled = false;

  /** Used to display the label in the regular way even if it is blocked (disabled by expert) */
  @Input() hideBlockedInformation = false;

  @Input() invoiceClient: boolean;
}
