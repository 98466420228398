import { Pipe, PipeTransform } from '@angular/core';

import { INVOICE_LABEL } from '@constants';
import { Document, InvoiceListItem, LinkedBankTransaction } from '@models';
import {
  BankTransaction,
  BankTransactionImputation,
} from '@models/bank-transaction';
import { LinkedEntity } from '@models/linked-entities';

import { MatchedItem } from '../../../../types/matched-item';

@Pipe({
  name: 'matchedItemSecondaryText',
  standalone: true,
})
export class MatchedItemSecondaryTextPipe implements PipeTransform {
  transform(item: MatchedItem): string | null {
    if (
      item instanceof BankTransaction ||
      item instanceof BankTransactionImputation ||
      item instanceof LinkedBankTransaction ||
      item instanceof LinkedEntity ||
      item instanceof Document
    ) {
      return null;
    } else if (item instanceof InvoiceListItem) {
      return `${INVOICE_LABEL[item.type].label} n°${
        item.invoicingDocumentNumber
      }`;
    } else {
      throw new Error('Unhandled matched item type.');
    }
  }
}
