export interface CheckDeliveryAddressRequestDialogData {
  address: CheckDeliveryAddress;
}

export interface CheckDeliveryAddressApiContract {
  name: string;
  address: string;
  postal_code: string;
  city: string;
}

export class CheckDeliveryAddress {
  constructor(
    public name: string,
    public address: string,
    public postalCode: string,
    public city: string,
  ) {}

  public static fromJson(
    json: CheckDeliveryAddressApiContract,
  ): CheckDeliveryAddress {
    return new CheckDeliveryAddress(
      json.name,
      json.address,
      json.postal_code,
      json.city,
    );
  }

  public static toJson(
    checkAddress: CheckDeliveryAddress,
  ): CheckDeliveryAddressApiContract {
    return {
      name: checkAddress.name,
      address: checkAddress.address,
      postal_code: checkAddress.postalCode,
      city: checkAddress.city,
    };
  }
}
