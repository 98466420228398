import { CommonModule, NgOptimizedImage } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  OnInit,
  Output,
} from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Store } from '@ngrx/store';
import { CategorySelectModule } from 'projects/tiime/src/app/company/document/document-shared/components/category-selector/category-select.module';
import { BehaviorSubject, Observable, tap } from 'rxjs';
import {
  TiimeButtonModule,
  TiimeDialogModule,
  TiimeIconModule,
} from 'tiime-components';

import { filterNotNullOrUndefined } from '@core/helpers';
import { Category } from '@core/models';
import {
  categoriesAllowingDocumentImportSelector,
  loadCategories,
} from '@core/store';

@UntilDestroy()
@Component({
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    TiimeDialogModule,
    TiimeButtonModule,
    TiimeIconModule,
    CategorySelectModule,
    NgOptimizedImage,
  ],
  selector: 'app-bank-transfer-document-added',
  templateUrl: './document-added.component.html',
  styleUrls: ['./document-added.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BankTransferDocumentAddedComponent implements OnInit {
  @Output() categorySelected = new EventEmitter<Category | undefined>();

  readonly categories$: Observable<Category[]> = this.store
    .select(categoriesAllowingDocumentImportSelector)
    .pipe(filterNotNullOrUndefined());
  readonly selectedCategory$ = new BehaviorSubject<Category>(null);

  constructor(private readonly store: Store) {}

  ngOnInit(): void {
    this.store.dispatch(loadCategories());
    this.categories$
      .pipe(
        tap(categories => this.selectedCategory$.next(categories[0])),
        untilDestroyed(this),
      )
      .subscribe();
  }
}
