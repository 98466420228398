export interface BIConnectorApiContract {
  id: number;
  uuid: string;
  slug: string;
  name: string;
  capabilities: string[];
}

export class BIConnector {
  constructor(
    public id?: number,
    public uuid?: string,
    public slug?: string,
    public name?: string,
    public capabilities?: string[],
  ) {}

  public static fromJson(json: BIConnectorApiContract): BIConnector {
    return new BIConnector(
      json.id,
      json.uuid,
      json.slug,
      json.name,
      json.capabilities,
    );
  }

  public static toJson(biBankConnection: BIConnector): any {
    return {
      id: biBankConnection.id,
      uuid: biBankConnection.uuid,
      slug: biBankConnection.slug,
      name: biBankConnection.name,
    };
  }
}
