import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogRef,
} from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import { BehaviorSubject } from 'rxjs';
import { finalize, skip, take, tap } from 'rxjs/operators';

import { PinCodeForm } from '@core/forms';
import { userSelector } from '@core/store';
import { CardDeliveryAddress } from '@models';
import { cardInUseSelector, orderCard } from '@store/wallet-account';

import { CareContactDialogComponent } from '../care-contact-dialog/care-contact-dialog.component';

enum OrderStep {
  Information = 1,
  PinCode = 2,
  Confirmation = 3,
  CareContact = 4,
}

@Component({
  selector: 'app-card-order-dialog',
  templateUrl: './card-order-dialog.component.html',
  styleUrls: ['./card-order-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CardOrderDialogComponent {
  readonly cardInUse$ = this.store.select(cardInUseSelector);
  readonly currentStep$ = new BehaviorSubject(OrderStep.Information);
  readonly OrderStep = OrderStep;
  readonly pinCodeForm = new PinCodeForm();
  readonly hasCheckedAddressFormControl = new FormControl<undefined | boolean>(
    undefined,
    Validators.requiredTrue,
  );
  readonly loading$ = new BehaviorSubject(false);

  constructor(
    @Inject(MAT_DIALOG_DATA)
    public readonly cardDeliveryAddress: CardDeliveryAddress,
    private readonly dialogRef: MatDialogRef<CardOrderDialogComponent>,
    private readonly dialog: MatDialog,
    private readonly store: Store,
  ) {}

  contactSupport(): void {
    this.dialogRef.close();
    this.dialog.open(CareContactDialogComponent);
  }

  nextStep(currentStep: OrderStep): void {
    this.currentStep$.next(currentStep + 1);
  }

  validate(): void {
    if (this.hasCheckedAddressFormControl.invalid) {
      return;
    }

    this.loading$.next(true);
    this.dialogRef.disableClose = true;
    const pinCode = this.pinCodeForm.code.value as string;
    this.store
      .select(cardInUseSelector)
      .pipe(
        skip(1),
        take(1),
        finalize(() => {
          this.dialogRef.close(true);
          this.loading$.next(false);
          this.dialogRef.disableClose = false;
        }),
      )
      .subscribe();
    this.store
      .select(userSelector)
      .pipe(
        take(1),
        tap(({ id }) => {
          this.store.dispatch(
            orderCard({
              cardDeliveryAddress: this.cardDeliveryAddress,
              pinCode,
              userId: id,
            }),
          );
        }),
      )
      .subscribe();
  }
}
