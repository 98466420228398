interface CategoryDescription {
  illu: string;
  name: string;
  description: string;
}

export const CATEGORIES_DESCRIPTION: CategoryDescription[] = [
  {
    illu: 'assets/illu_educational_message_receipt.svg',
    name: 'Justificatifs',
    description: `L’ensemble de vos justificatifs de dépenses professionnelles.`,
  },
  {
    illu: 'assets/illu_educational_message_advanced-expenses.svg',
    name: 'Frais à rembourser',
    description: `Les dépenses professionnelles payées par un autre moyen de paiement que professionnel.
    Ces frais pourront être repris dans les notes de frais afin de vous les rembourser.`,
  },
  {
    illu: 'assets/illu_educational_message_expense-report.svg',
    name: 'Notes de frais Tiime',
    description: `Les notes de frais créées à partir des frais à rembourser personnellement
    pour le compte de la société, que vous pouvez ensuite rembourser à partir de la note de frais établie.`,
  },
  {
    illu: 'assets/illu_educational_message_kilometric-allowances.svg',
    name: 'Indemnités kilometriques',
    description:
      "L’ensemble des indemnités kilométriques effectuées pour l'activité professionnelle.",
  },
  {
    illu: 'assets/illu_educational_message_sales-invoice.svg',
    name: 'Factures de vente',
    description: `Les factures de vente établies dans le cadre de votre activité professionnelle,
    autres que les factures établies depuis l'onglet "Mes factures" ou l'application Invoice de Tiime.`,
  },

  {
    illu: 'assets/illu_educational_message_fiscal.svg',
    name: 'Fiscal',
    description: `Toutes les déclarations fiscales relatives à votre société,
    composées des déclarations de TVA, d'impôt sur les sociétés ou encore de la cotisation foncière des entreprises...`,
  },
  {
    illu: 'assets/illu_educational_message_legal.svg',
    name: 'Juridique',
    description: `Les documents juridiques de votre société, comme les procès-verbaux ou
      autres documents établis dans le cadre de formalités juridiques
      (changements statutaires, affectation de résultats annuelle...)`,
  },
  {
    illu: 'assets/illu_educational_message_social-employees.svg',
    name: 'Social salariés',
    description: `Les documents liés à vos salariés, comme les contrats de travail,
      déclarations d'embauche ou encore les déclarations sociales...`,
  },
  {
    illu: 'assets/illu_educational_message_social-leader.svg',
    name: 'Social dirigeants',
    description: `Les documents sociaux liés aux dirigeants de la société,
    comme les bordereaux de cotisations sociales des indépendants...`,
  },
  {
    illu: 'assets/illu_educational_message_payslip.svg',
    name: 'Bulletins de paie',
    description: `Tous les bulletins de paie de vos salariés.`,
  },
  {
    illu: 'assets/illu_educational_message_bank-statement.svg',
    name: 'Relevés bancaires',
    description: `Les relevés bancaires de vos comptes professionnels.
    Si vous avez un compte Tiime Business, vous pourrez y retrouver les relevés bancaires associés.`,
  },
  {
    illu: 'assets/illu_educational_message_annual-accounts.svg',
    name: 'Comptes annuels',
    description: `Les comptes annuels de votre société, à savoir la liasse fiscale, le bilan, le compte de résultat et les annexes.`,
  },
  {
    illu: 'assets/illu_educational_message_permanent_document.svg',
    name: 'Documents permanents',
    description: `Les documents devant être conservés durant toute la vie de la société, ayant une incidence sur plusieurs exercices.`,
  },
  {
    illu: 'assets/illu_educational_message_other_document.svg',
    name: 'Autres types de documents',
    description: `L'ensemble des documents autres que ceux pouvant être classés dans les autres espaces de documents.`,
  },
];
