import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, iif, of } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import {
  GetOptions,
  MonoValueFilter,
  PaginationData,
  PaginationRange,
  RequiredGetOptions,
} from 'tiime-components';

import { BankTransactionMatching } from '@core/models/bank-transaction/bank-transaction-matching';
import { TemporaryEncoder } from '@core/temporary-encoder';
import { ApiAlertError } from '@decorators/api-alert-error';
import { AmountType, MatchingType, OperationType } from '@enums';
import { HttpHelper } from '@helpers';
import { MatchableDocument } from '@matching/types/matchable-document';
import { Document, InvoiceListItem, Tag, TagApiContract } from '@models';
import {
  BankTransaction,
  BankTransactionApiContract,
  BankTransactionImputation,
  BankTransactionsMetadata,
  BankTransactionsParams,
  BankTransactionsWithMetadata,
  BankTransactionsWithMetadataApiContract,
} from '@models/bank-transaction';
import {
  MatchingBodyInterface,
  MatchingResponseInterface,
} from '@models/matching';

import { BankAccountService } from './bank-account.service';

@Injectable({
  providedIn: 'root',
})
export class TransactionsService {
  readonly resource = `api/v1/companies/{companyId}/bank_transactions`;

  constructor(
    private readonly http: HttpClient,
    private readonly bankAccountService: BankAccountService,
  ) {}

  get(id: number): Observable<BankTransaction> {
    return this.http
      .get(`${this.resource}/${id}`, {
        headers: {
          Accept: 'application/vnd.tiime.docs.imputation+json',
        },
      })
      .pipe(
        map((transaction: BankTransactionApiContract) =>
          BankTransaction.fromJson(transaction),
        ),
      );
  }

  getAll(
    getOptions: RequiredGetOptions<'range'>,
    bankTransactionsParams?: BankTransactionsParams,
  ): Observable<PaginationData<BankTransaction>>;
  getAll(
    getOptions: RequiredGetOptions<'range'>,
    bankTransactionsParams?: BankTransactionsParams,
    withMetadata?: boolean,
  ): Observable<BankTransactionsWithMetadata>;
  @ApiAlertError()
  getAll(
    getOptions: RequiredGetOptions<'range'>,
    bankTransactionsParams?: BankTransactionsParams,
    withMetadata?: boolean,
  ): unknown {
    const { bankAccountId, amountType } = bankTransactionsParams || {};

    let params = new HttpParams({
      fromObject: {
        ...new GetOptions({ ...getOptions }).toHttpGetOptions().params,
        ...(bankAccountId && {
          bank_account: bankAccountId.join('|'),
        }),
        ...(amountType && {
          amount_type: String(amountType),
        }),
      },
      encoder: new TemporaryEncoder(),
    });
    params = HttpHelper.setSearchParam(params, getOptions.search);
    params = HttpHelper.setSortParam(params, getOptions.sort);

    const headers = HttpHelper.setRangeHeader(
      new HttpHeaders({
        Accept:
          'application/vnd.tiime.bank_transactions.without_documents+json',
      }),
      getOptions.range,
    );
    const options = { params, headers };

    return iif(
      () => withMetadata,
      this.getTransactionsWithMetadata(getOptions.range, params, headers),
      this.http
        .get(this.resource, { ...options, observe: 'response' })
        .pipe(
          HttpHelper.mapToPaginationData(
            getOptions.range,
            (bankTransactionJson: BankTransactionApiContract) =>
              BankTransaction.fromJson(bankTransactionJson),
          ),
        ),
    );
  }

  @ApiAlertError()
  getTiimeTransfers(
    getOptions: RequiredGetOptions<'range'>,
    tiimeBankAccountId: number | undefined,
  ): Observable<PaginationData<BankTransaction>> {
    return iif(
      () => !!tiimeBankAccountId,
      of(tiimeBankAccountId),
      this.bankAccountService
        .getTiimeBankAccount()
        .pipe(map(bankAccount => bankAccount.id)),
    ).pipe(
      map(bankAccountId => {
        const filteredOptions = { ...getOptions };
        filteredOptions.filters = [
          ...(getOptions.filters ?? []),
          ...[
            new MonoValueFilter('bank_account', bankAccountId),
            new MonoValueFilter('amount_type', AmountType.disbursements),
            new MonoValueFilter('operation_type', OperationType.transfer),
            new MonoValueFilter('hide_refused', true),
          ],
        ];
        const partialOptions = new GetOptions(
          filteredOptions,
        ).toHttpGetOptions();
        return {
          params: new HttpParams({
            fromObject: partialOptions.params,
            encoder: new TemporaryEncoder(),
          }),
          headers: partialOptions.headers,
        };
      }),
      switchMap(options =>
        this.http.get<BankTransaction[]>(this.resource, {
          ...options,
          observe: 'response',
        }),
      ),
      HttpHelper.mapToPaginationData(
        getOptions.range,
        (bankTransaction: BankTransactionApiContract) =>
          BankTransaction.fromJson(bankTransaction),
      ),
    );
  }

  @ApiAlertError()
  updateComment(
    bankTransactionId: number,
    comment: string,
  ): Observable<BankTransaction> {
    return this.http
      .patch(`${this.resource}/${bankTransactionId}`, {
        comment,
      })
      .pipe(
        map((bankTransactionJson: BankTransactionApiContract) =>
          BankTransaction.fromJson(bankTransactionJson),
        ),
      );
  }

  @ApiAlertError()
  updateTags(bankTransactionId: number, tags: Tag[]): Observable<Tag[]> {
    const body = tags.map((tag: Tag) => Tag.toJson(tag));

    return this.http
      .put(`${this.resource}/${bankTransactionId}/tags`, body)
      .pipe(
        map((tagsJson: TagApiContract[]) =>
          tagsJson.map((tagJson: TagApiContract) => Tag.fromJson(tagJson)),
        ),
      );
  }

  @ApiAlertError()
  getMatchedDocuments(
    bankTransactionId: number,
    type?: 'document' | 'invoice',
  ): Observable<MatchableDocument[]> {
    let options;
    if (type) {
      options = {
        params: new HttpParams({
          fromObject: { type: type },
          encoder: new TemporaryEncoder(),
        }),
      };
    }
    return this.http
      .get<BankTransactionMatching>(
        `${this.resource}/${bankTransactionId}/matchings`,
        options,
      )
      .pipe(
        map((matching: BankTransactionMatching) =>
          BankTransactionMatching.toMatchedDocuments(matching),
        ),
      );
  }

  // MATCHING DOCUMENTS
  @ApiAlertError()
  matchDocuments(
    transactionId: number,
    documentsIds: number[],
    matchingType: MatchingType,
    labelId?: number,
    tagName?: string,
  ): Observable<MatchingResponseInterface[]> {
    const url = `${this.resource}/${transactionId}/${matchingType}_matchings`;
    let body: MatchingBodyInterface | { id: number }[];

    if (matchingType !== MatchingType.invoices) {
      body = {
        documents: documentsIds.map(id => ({ id })),
      };

      if (labelId) {
        body.label = { id: labelId };
      } else if (tagName) {
        body.tag = { name: tagName };
      }
    } else {
      body = documentsIds.map(id => ({ id }));
    }

    return this.http.put<MatchingResponseInterface[]>(url, body);
  }

  @ApiAlertError()
  patchImputations(
    bankTransactionId: number,
    imputationsToUpdate: BankTransactionImputation[],
  ): Observable<BankTransaction> {
    const body = {
      imputations:
        imputationsToUpdate.map((imputations: BankTransactionImputation) =>
          BankTransactionImputation.toJson(imputations),
        ) || [],
    };

    return this.http
      .patch(`${this.resource}/${bankTransactionId}`, body)
      .pipe(
        map((bankTransactionJson: BankTransactionApiContract) =>
          BankTransaction.fromJson(bankTransactionJson),
        ),
      );
  }

  @ApiAlertError()
  getImputationMatchedDocuments(
    bankTransactionId: number,
    imputationId: number,
    type?: 'document' | 'invoice',
  ): Observable<MatchableDocument[]> {
    let options;
    if (type) {
      options = {
        params: new HttpParams({
          fromObject: { type: type },
          encoder: new TemporaryEncoder(),
        }),
      };
    }
    return this.http
      .get<BankTransactionMatching>(
        `${this.resource}/${bankTransactionId}/imputations/${imputationId}/matchings`,
        options,
      )
      .pipe(
        map((matching: BankTransactionMatching) =>
          BankTransactionMatching.toMatchedDocuments(matching),
        ),
      );
  }

  @ApiAlertError()
  matchDocumentsToImputation(
    bankTransactionId: number,
    imputationId: number,
    matchableDocuments: MatchableDocument[],
  ): Observable<void> {
    const documents = matchableDocuments
      .filter((doc): doc is Document => doc instanceof Document)
      .map(({ id }) => ({ id }));
    const invoices = matchableDocuments
      .filter((doc): doc is InvoiceListItem => doc instanceof InvoiceListItem)
      .map(({ id }) => ({ id }));

    const body = {
      documents,
      invoices,
    };

    return this.http.put<void>(
      `${this.resource}/${bankTransactionId}/imputations/${imputationId}/matchings`,
      body,
    );
  }

  @ApiAlertError()
  delete(bankTransactionId: number): Observable<null> {
    return this.http.delete<null>(`${this.resource}/${bankTransactionId}`);
  }

  private getTransactionsWithMetadata(
    range: PaginationRange,
    params: HttpParams,
    headers: HttpHeaders,
  ): Observable<BankTransactionsWithMetadata> {
    headers = headers.set('Accept', [
      'application/vnd.tiime.bank_transactions.v2+json',
      'application/vnd.tiime.bank_transactions.without_documents+json',
    ]);
    const options = { params, headers };
    return this.http
      .get<BankTransactionsWithMetadataApiContract>(this.resource, {
        ...options,
        observe: 'response',
      })
      .pipe(
        HttpHelper.mapToPaginationDataWithMetadata(
          'transactions',
          range,
          BankTransaction.fromJson,
          BankTransactionsWithMetadata.fromJson,
          (data, metadata: BankTransactionsMetadata) =>
            new BankTransactionsWithMetadata(data, metadata),
        ),
      );
  }

  @ApiAlertError()
  hasUnimputedTransaction(): Observable<boolean> {
    return this.http
      .get<{ id: BankTransaction['id'] }[]>(`${this.resource}/unimputed`)
      .pipe(map(transactions => transactions.length > 0));
  }

  @ApiAlertError()
  getLabelOverloadStatus(
    documentId: number,
    transactionId: number,
  ): Observable<{
    label_overload_status: string;
  }> {
    const url = `${this.resource}/${transactionId}/documents/${documentId}/label_overload_status`;
    return this.http.get<{
      label_overload_status: string;
    }>(url);
  }
}
