import {
  FormGroup,
  FormControl,
  Validators,
  AbstractControl,
  ValidationErrors,
} from '@angular/forms';
import { ValidatorsUtils } from '@manakincubber/tiime-utils';

const notOnlyOneDigitValidator = (
  control: AbstractControl,
): ValidationErrors | null => {
  const values = (control.value as string | number).toString().split('');

  if (values.length === 0) {
    return null;
  }

  const containsOnlyOneDigit = !values.some((value, index, digits) => {
    if (index < digits.length - 1) {
      return value !== digits[index + 1];
    }
    return false;
  });

  if (containsOnlyOneDigit) {
    return { notOnlyOneDigit: true };
  }
  return null;
};

export class PinCodeForm extends FormGroup {
  get code(): FormControl {
    return this.get('code') as FormControl;
  }

  get confirmCode(): FormControl {
    return this.get('confirmCode') as FormControl;
  }

  constructor() {
    super(
      {
        code: new FormControl('', [
          Validators.required,
          notOnlyOneDigitValidator,
        ]),
        confirmCode: new FormControl('', Validators.required),
      },
      ValidatorsUtils.controlComparisonValidator(
        'code',
        'confirmCode',
        (code: string, confirmCode: string) => code === confirmCode,
        'matchingCodes',
      ),
    );
  }
}
