import {
  CommonModule,
  NgOptimizedImage,
  NgTemplateOutlet,
} from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  Inject,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { DragAndDropZoneModule } from 'projects/tiime/src/app/company/company-shared/components/drag-and-drop-zone/drag-and-drop-zone.module';
import {
  BehaviorSubject,
  Observable,
  catchError,
  combineLatest,
  filter,
  map,
  of,
  switchMap,
  tap,
  timer,
  withLatestFrom,
} from 'rxjs';
import {
  TIIME_OVERLAY_DATA,
  TiimeButtonModule,
  TiimeOverlayRef,
} from 'tiime-components';

import { CompanyConfigService, DocumentsService } from '@core';
import { BankTransferState, Category, Document } from '@core/models';
import { IconsModule, InfoBannerComponent } from '@shared';

import { BankTransfersSharedModule } from '../../bank-transfers-shared.module';
import { BankTransferSidePanelService } from '../bank-transfer-side-panel/bank-transfer-side-panel.service';
import { BankTransferAddDocumentComponent } from './add-document/add-document.component';
import {
  BankTransferOverlayService,
  OcerisationStep,
} from './bank-transfer-overlay.service';
import { BankTransferDocumentAddedComponent } from './document-added/document-added.component';
import { BankTransferDocumentPreviewComponent } from './document-preview/document-preview.component';

export interface BankTransferOverlayResponse {
  refresh?: boolean;
}

@UntilDestroy()
@Component({
  standalone: true,
  imports: [
    CommonModule,
    BankTransfersSharedModule,
    TiimeButtonModule,
    IconsModule,
    NgOptimizedImage,
    NgTemplateOutlet,
    InfoBannerComponent,
    DragAndDropZoneModule,
    BankTransferAddDocumentComponent,
    BankTransferDocumentAddedComponent,
    BankTransferDocumentPreviewComponent,
  ],
  selector: 'app-bank-transfer-overlay',
  templateUrl: './bank-transfer-overlay.component.html',
  styleUrls: ['./bank-transfer-overlay.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BankTransferOverlayComponent implements OnInit, OnDestroy {
  bankTransferState: BankTransferState;
  readonly newDocument$ = new BehaviorSubject<File | null>(null);

  readonly canSendToMindee$: Observable<boolean> = this.companyConfigService
    .get()
    .pipe(
      map(config => config.isFeaturePayoutFromReceiptEnabled),
      untilDestroyed(this),
    );

  readonly OcerisationStep = OcerisationStep;

  constructor(
    private readonly overlayRef: TiimeOverlayRef<BankTransferOverlayResponse>,
    private readonly bankTransferSidePanelService: BankTransferSidePanelService,
    readonly bankTransferOverlayService: BankTransferOverlayService,
    private readonly documentsService: DocumentsService,
    private readonly companyConfigService: CompanyConfigService,
    @Inject(TIIME_OVERLAY_DATA)
    public data: BankTransferState,
  ) {
    this.bankTransferState = data;
    this.bankTransferOverlayService.form.reset();
  }

  ngOnInit(): void {
    this.bankTransferOverlayService.ocerisationStep$.next(
      OcerisationStep.NOT_STARTED,
    );

    if (this.data?.document) {
      this.updateDocument(this.data.document, OcerisationStep.SKIPPED);
    }

    this.bankTransferSidePanelService
      .open({
        data: { bankTransferState: this.data },
        disableClose: true,
        hasBackdrop: false,
      })
      .beforeClosed$()
      .pipe(
        tap((refresh?: boolean) => this.overlayRef.close({ refresh })),
        untilDestroyed(this),
      )
      .subscribe();
  }

  ngOnDestroy(): void {
    this.bankTransferOverlayService.document$.next(null);
  }

  updateDocument(document: Document, step: OcerisationStep): void {
    this.bankTransferOverlayService.document$.next(document);
    this.bankTransferOverlayService.ocerisationStep$.next(step);
  }

  onCategorySelected(category?: Category): void {
    if (!category) {
      this.newDocument$.next(null);
      return;
    }

    this.bankTransferOverlayService.ocerisationStep$.next(
      OcerisationStep.DOC_UPLOADING,
    );
    this.documentsService
      .upload(this.newDocument$.value, category.id)
      .pipe(
        filter(document => document instanceof Document),
        tap((document: Document) =>
          this.bankTransferOverlayService.document$.next(document),
        ),
        withLatestFrom(this.canSendToMindee$),
        tap(([, canSendToMindee]) =>
          this.bankTransferOverlayService.ocerisationStep$.next(
            canSendToMindee
              ? OcerisationStep.OCERISATION
              : OcerisationStep.SKIPPED,
          ),
        ),
        filter(([, canSendToMindee]) => canSendToMindee),
        switchMap(([document]) =>
          combineLatest([
            this.documentsService.sendToMindee(document.id),
            timer(2000), // To show the scan animation
          ]),
        ),
        filter(
          () =>
            this.bankTransferOverlayService.ocerisationStep$.value ===
            OcerisationStep.OCERISATION,
        ),
        tap(([document]) =>
          this.updateDocument(document, OcerisationStep.DONE),
        ),
        catchError(() => {
          if (
            this.bankTransferOverlayService.ocerisationStep$.value ===
            OcerisationStep.DOC_UPLOADING
          ) {
            this.bankTransferOverlayService.document$.next(null);
            this.bankTransferOverlayService.ocerisationStep$.next(
              OcerisationStep.ERROR,
            );
          }
          return of(null);
        }),
      )
      .subscribe();
  }
}
