import { HttpClient, HttpParams } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import {
  DefaultGetOptions,
  GetOptions,
  MonoValueFilter,
  OrFilter,
} from 'tiime-components';

import {
  CreatePaymentRequestContract,
  GoCardlessPaymentMandateStatus,
  InvoicePaymentRequest,
  LinxoPaymentMandateStatus,
  WhitelistIbanContract,
} from '@core/services';
import { ApiAlertError } from '@decorators/api-alert-error';
import { Invoice } from '@models';

import { TemporaryEncoder } from '../../temporary-encoder';

@Injectable({ providedIn: 'root' })
export class PaymentRequestService {
  private readonly http = inject(HttpClient);
  private readonly resourceUri =
    'api/v1/companies/{companyId}/payment_requests';

  private readonly goCardlessStatusToHandle: GoCardlessPaymentMandateStatus[] =
    [
      GoCardlessPaymentMandateStatus.Delayed,
      GoCardlessPaymentMandateStatus.PendingCustomerApproval,
      GoCardlessPaymentMandateStatus.PendingSubmission,
      GoCardlessPaymentMandateStatus.Submitted,
      GoCardlessPaymentMandateStatus.Confirmed,
      GoCardlessPaymentMandateStatus.PaidOut,
    ];

  private readonly linxoStatusToHandle: LinxoPaymentMandateStatus[] = [
    LinxoPaymentMandateStatus.Initiated,
    LinxoPaymentMandateStatus.Submitted,
    LinxoPaymentMandateStatus.Authorized,
    LinxoPaymentMandateStatus.Expired,
    LinxoPaymentMandateStatus.Failed,
    LinxoPaymentMandateStatus.Rejected,
    LinxoPaymentMandateStatus.Executed,
  ];

  @ApiAlertError()
  whitelist(contract: WhitelistIbanContract): Observable<unknown> {
    const whitelistUri =
      'api/v1/companies/{companyId}/payment_authorize_beneficiary_account';
    return this.http.post<unknown>(whitelistUri, contract);
  }

  @ApiAlertError()
  create(contract: CreatePaymentRequestContract): Observable<unknown> {
    return this.http.post<unknown>(this.resourceUri, contract);
  }

  @ApiAlertError()
  delete(id: InvoicePaymentRequest['id']): Observable<void> {
    return this.http.delete<void>(`${this.resourceUri}/${id}`);
  }

  @ApiAlertError()
  cancel(id: InvoicePaymentRequest['id']): Observable<InvoicePaymentRequest> {
    return this.http.patch<InvoicePaymentRequest>(`${this.resourceUri}/${id}`, {
      status: 'cancelled',
    });
  }

  @ApiAlertError()
  getByInvoice(id: Invoice['id']): Observable<InvoicePaymentRequest> {
    return this.getAll({
      filters: [
        new MonoValueFilter('invoice', id),
        new OrFilter('status', [
          ...this.goCardlessStatusToHandle,
          ...this.linxoStatusToHandle,
        ]),
      ],
    }).pipe(map(paymentRequests => paymentRequests[0]));
  }

  @ApiAlertError()
  getAll(getOptions: DefaultGetOptions): Observable<InvoicePaymentRequest[]> {
    const partialOptions = new GetOptions(getOptions).toHttpGetOptions();
    const options = {
      params: new HttpParams({
        fromObject: partialOptions.params,
        encoder: new TemporaryEncoder(),
      }),
      headers: partialOptions.headers,
    };
    return this.http.get<InvoicePaymentRequest[]>(this.resourceUri, options);
  }
}
