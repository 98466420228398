import {
  AccountantDetailRequestType,
  BankTransactionStatus,
  BankTransactionStatusCode,
} from '@enums';

import {
  AccountantDetailRequest,
  AccountantDetailRequestApiContract,
} from '../accountant-detail-request';
import { BankAccount, BankAccountApiContract } from '../bank-account';
import { Document } from '../document';
import { InvoiceListItem } from '../invoice-list-item';
import {
  BankTransactionThread,
  BankTransactionThreadApiContract,
} from '../messaging';
import { Tag, TagApiContract } from '../tag';
import { BankTransactionAuthor } from './bank-transaction-author';
import {
  BankTransactionBase,
  BankTransactionBaseApiContract,
} from './bank-transaction-base';
import { BankTransactionBeneficiary } from './bank-transaction-beneficiary';
import {
  BankTransactionImputation,
  BankTransactionImputationApiContract,
} from './bank-transaction-imputation';
import { DocumentsAndInvoicesApiContact } from './documents-and-invoices';

export interface BankTransactionApiContract
  extends BankTransactionBaseApiContract,
    DocumentsAndInvoicesApiContact {
  transaction_date: string;
  bank_account: BankAccountApiContract;
  annotation_needed: boolean;
  tags: TagApiContract[] | null;
  status_code: BankTransactionStatusCode;
  imputations: BankTransactionImputationApiContract[] | null;
  imputation_in_progress: boolean;
  accountant_detail_requests: AccountantDetailRequestApiContract[];
  threads: BankTransactionThreadApiContract[];
}

export class BankTransaction extends BankTransactionBase {
  get hasFailed(): boolean {
    return this.status === BankTransactionStatus.failed;
  }

  get wordingOrOriginalWording(): string {
    return this.wording || this.originalWording;
  }

  get hasPositiveAmount(): boolean {
    return this.amount > 0;
  }

  get hasMessageRequest(): boolean {
    return (
      this.thread &&
      this.thread.lastAnswerBy === 'accountant' &&
      !this.thread.closedAt
    );
  }

  get hasReceiptRequest(): boolean {
    return (
      this.accountantDetailRequests.filter(
        request =>
          request.type === AccountantDetailRequestType.RECEIPT &&
          !request.answeredAt,
      ).length > 0
    );
  }

  get lastAnsweredReceiptRequest(): AccountantDetailRequest {
    return this.accountantDetailRequests
      .filter(
        request =>
          request.type === AccountantDetailRequestType.RECEIPT &&
          request.answeredAt,
      )
      .sort(
        (a, b) =>
          new Date(b.answeredAt).getTime() - new Date(a.answeredAt).getTime(),
      )[0];
  }

  constructor(
    public transactionDate?: string,
    public bankAccount?: BankAccount,
    public annotationNeeded?: boolean,
    public tags?: Tag[],
    public statusCode?: BankTransactionStatusCode,
    public imputations?: BankTransactionImputation[],
    public imputationInProgress?: boolean,
    ...parameters: ConstructorParameters<typeof BankTransactionBase>
  ) {
    super(...parameters);
  }

  static fromJson(json: BankTransactionApiContract): BankTransaction {
    return new BankTransaction(
      json.transaction_date,
      json.bank_account ? BankAccount.fromJson(json.bank_account) : null,
      json.annotation_needed,
      json.tags ? json.tags.map(tagJson => Tag.fromJson(tagJson)) : [],
      json.status_code,
      json.imputations
        ? json.imputations.map(imputation =>
            BankTransactionImputation.fromJson(imputation, {
              id: json.id,
              realization_date: json.realization_date,
              wording: json.wording,
              original_wording: json.original_wording,
              bank_name: json.bank_name,
              short_bank_name: json.short_bank_name,
              beneficiary: json.beneficiary,
              transfer_label: json.transfer_label,
              currency: json.currency,
              operation_type: json.operation_type,
              status: json.status,
            }),
          )
        : [],
      json.imputation_in_progress,
      json.id,
      json.realization_date,
      json.wording,
      json.original_wording,
      json.bank_name,
      json.short_bank_name,
      json.beneficiary && BankTransactionBeneficiary.fromJson(json.beneficiary),
      json.transfer_label,
      json.amount,
      json.currency,
      json.operation_type,
      json.status,
      json.transaction_author
        ? BankTransactionAuthor.fromJson(json.transaction_author)
        : null,
      json.comment,
      json.accountant_detail_requests
        ? json.accountant_detail_requests.map(request =>
            AccountantDetailRequest.fromJson(request),
          )
        : [],
      json.threads ? BankTransactionThread.fromJsonArray(json.threads) : null,
      json.count_documents,
      json.count_invoices,
      json.documents
        ? json.documents.map(document => Document.fromJson(document))
        : [],
      json.invoices
        ? json.invoices.map(invoiceJson =>
            InvoiceListItem.fromJson(invoiceJson),
          )
        : [],
    );
  }
}
