import { inject } from '@angular/core';
import { CanMatchFn, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { map } from 'rxjs';
import { withLatestFrom } from 'rxjs/operators';

import { WalletSDDStatusEnum } from '@enums';
import { CompanyConfigService } from '@services';
import { selectedCompanySelector } from '@user-store';

export function HasAccessToPayinsGuard(): CanMatchFn {
  return () => {
    const companyConfigService = inject(CompanyConfigService);
    const router = inject(Router);
    const store = inject(Store);

    return companyConfigService.get().pipe(
      withLatestFrom(store.select(selectedCompanySelector)),
      map(([companyConfig, { id }]) => {
        const hasAccess = [
          WalletSDDStatusEnum.ENABLED,
          WalletSDDStatusEnum.PENDING,
        ].includes(companyConfig.walletSDDStatus);

        return hasAccess
          ? true
          : router.createUrlTree(['companies', id, 'home']);
      }),
    );
  };
}
