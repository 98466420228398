import {
  ChangeDetectionStrategy,
  Component,
  Input,
  ViewEncapsulation,
} from '@angular/core';

import { AutoSaveData } from './auto-save-data';

@Component({
  selector: 'tiime-auto-save',
  templateUrl: './auto-save.component.html',
  styleUrls: ['auto-save.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AutoSaveComponent {
  @Input()
  autoSaveData: AutoSaveData | null;
}
