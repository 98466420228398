import { Pipe, PipeTransform } from '@angular/core';

import { Tag } from '@models';
import { Label } from '@models/labels';

@Pipe({
  name: 'isInstanceOfLabel',
})
export class IsInstanceOfLabelPipe implements PipeTransform {
  transform(value: typeof Label | Tag): value is typeof Label {
    return value instanceof Label;
  }
}
