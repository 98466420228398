import { CommonModule, NgOptimizedImage } from '@angular/common';
import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { LoginInitiator, TiimeAuthService } from '@manakincubber/tiime-auth';
import { NgUtils } from '@manakincubber/tiime-utils';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Store, select } from '@ngrx/store';
import { BehaviorSubject, Observable } from 'rxjs';
import { delay, filter, finalize, switchMap, take, tap } from 'rxjs/operators';
import {
  SnackbarConfig,
  TiimeButtonModule,
  TiimeDialogModule,
  TiimeSnackbarService,
} from 'tiime-components';

import { AppRedirectionBase } from '@bases';
import { User } from '@models';
import { userSelector } from '@user-store';

import { CreatePasswordDialogService } from './create-password-dialog/create-password-dialog.service';

@UntilDestroy()
@Component({
  standalone: true,
  imports: [
    CommonModule,
    NgOptimizedImage,
    TiimeButtonModule,
    TiimeDialogModule,
  ],
  selector: 'app-create-password',
  templateUrl: './create-password.component.html',
  styleUrls: ['../auth-base.scss', './create-password.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CreatePasswordComponent
  extends AppRedirectionBase
  implements OnInit
{
  currentUser: User;
  subtitleWording: string[];
  titleWording: string[];
  buttonWording: string;

  readonly trackByIndex = NgUtils.trackByIndex;
  readonly loading$ = new BehaviorSubject(false);

  constructor(
    router: Router,
    private authService: TiimeAuthService,
    private store: Store,
    private route: ActivatedRoute,
    private readonly createPasswordDialogService: CreatePasswordDialogService,
    private readonly snackbar: TiimeSnackbarService,
  ) {
    super(router);
  }

  ngOnInit(): void {
    this.initCurrentUser();
  }

  openUpdatePasswordDialog(): void {
    this.loading$.next(true);
    this.createPasswordDialogService
      .open(this.currentUser)
      .pipe(
        filter(response => !!response),
        tap(() =>
          this.snackbar.open(
            `Le mot de passe a bien été ${
              this.route.snapshot.data.renewPassword ? 'réinitialisé' : 'créé'
            } `,
            SnackbarConfig.success,
          ),
        ),
        delay(1500),
        switchMap(response => this.login(response.password)),
        finalize(() => this.loading$.next(false)),
      )
      .subscribe();
  }

  private initSubtitleWording(): void {
    if (this.route.snapshot.data.renewPassword) {
      this.titleWording = [
        'Pour vous connecter de nouveau,',
        'veuillez réinitialiser votre mot de passe.',
      ];
      this.subtitleWording = [
        'Nous renforçons notre politique de sécurité afin de vous offrir un outil',
        'toujours plus adapté aux normes et usages actuels.',
      ];
      this.buttonWording = 'Réinitialiser';
    } else {
      this.titleWording = [`Félicitations ${this.currentUser.firstName} !`];
      this.subtitleWording = [
        "Il ne vous reste plus qu'à créer un nouveau mot de passe pour",
        "simplifier votre quotidien d'entrepreneur",
      ];
      this.buttonWording = 'Créer mon mot de passe';
    }
  }

  private initCurrentUser(): void {
    this.store
      .pipe(
        select(userSelector),
        take(1),
        tap((user: User) => {
          this.currentUser = user;
          this.initSubtitleWording();
        }),
        untilDestroyed(this),
      )
      .subscribe();
  }

  private login(password: string): Observable<unknown> {
    return this.authService.login(
      this.currentUser.email,
      password,
      LoginInitiator.system,
      this.authRedirectUri(this.route.snapshot.queryParamMap),
    );
  }
}
