import { TiimeSelectOption } from 'tiime-components';

export const PLATFORM_LANGUAGES: TiimeSelectOption<string>[] = [
  {
    label: 'Français',
    value: 'FR',
  },
  {
    label: 'Anglais',
    value: 'GB',
  },
  {
    label: 'Allemand',
    value: 'DE',
  },
  {
    label: 'Espagnol',
    value: 'ES',
  },
  {
    label: 'Italien',
    value: 'IT',
  },
  {
    label: 'Portugais',
    value: 'PT',
  },
];
