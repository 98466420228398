import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { TiimeChipModule } from '../../../chip';
import { FilterChipComponent } from './filter-chip.component';

@NgModule({
  declarations: [FilterChipComponent],
  imports: [CommonModule, TiimeChipModule],
  exports: [FilterChipComponent],
})
export class TiimeFilterChipModule {}
