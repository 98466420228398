import { Pipe, PipeTransform } from '@angular/core';

import { Document, InvoiceListItem, LinkedBankTransaction } from '@models';
import {
  BankTransaction,
  BankTransactionImputation,
} from '@models/bank-transaction';
import {
  LinkedEntity,
  LinkedEntityExpenseReport,
} from '@models/linked-entities';

import { MatchedItem } from '../../../../types/matched-item';

@Pipe({
  name: 'matchedItemMainText',
  standalone: true,
})
export class MatchedItemMainTextPipe implements PipeTransform {
  transform(item: MatchedItem): string {
    if (
      item instanceof BankTransaction ||
      item instanceof BankTransactionImputation ||
      item instanceof LinkedBankTransaction
    ) {
      return item.wording;
    } else if (item instanceof LinkedEntityExpenseReport) {
      return item.value.label;
    } else if (item instanceof LinkedEntity) {
      return item.value.wording;
    } else if (item instanceof Document) {
      return item.name;
    } else if (item instanceof InvoiceListItem) {
      return item.clientName;
    } else {
      throw new Error('Unhandled matched item type.');
    }
  }
}
