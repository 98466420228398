import { StandardDocumentCategoryIdentifier } from '@core/enum';

import { DetailDocumentAccedeProperties } from './wrapper';

export const COMPANY_GROUP_NAME = 'societe';

export type BankTransferSource =
  | 'menuEntry'
  | 'editBeneficiaryDialog'
  | 'refundButton'
  | 'payButton'
  | 'bankTransferPage'
  | 'sepaPayments';

export const AmpliDocumentCategory = new Map<
  StandardDocumentCategoryIdentifier,
  DetailDocumentAccedeProperties['type_document']
>([
  [StandardDocumentCategoryIdentifier.RECEIPT, 'justificatif'],
  [StandardDocumentCategoryIdentifier.ADVANCED_EXPENSE, 'frais_a_rembourser'],
  [StandardDocumentCategoryIdentifier.EXPENSE_REPORT, 'notes_frais'],
  [StandardDocumentCategoryIdentifier.MILEAGE_ALLOWANCE, 'ik'],
  [StandardDocumentCategoryIdentifier.EXTERNAL_INVOICE, 'facture_vente'],
  [StandardDocumentCategoryIdentifier.JURIDICAL, 'juridique'],
  [StandardDocumentCategoryIdentifier.SOCIAL_EMPLOYEE, 'social_salarie'],
  [StandardDocumentCategoryIdentifier.SOCIAL_DIRECTOR, 'social_dirigeant'],
  [StandardDocumentCategoryIdentifier.PAYSLIP, 'bulletin_paie'],
  [StandardDocumentCategoryIdentifier.BANK_STATEMENT, 'releve_bancaire'],
  [StandardDocumentCategoryIdentifier.ANNUAL_ACCOUNT, 'compte_annuel'],
  [StandardDocumentCategoryIdentifier.PERMANENT_DOCUMENT, 'document_permanent'],
  [StandardDocumentCategoryIdentifier.OTHER_DOCUMENT, 'autres'],
  [StandardDocumentCategoryIdentifier.FISCAL, 'fiscal'],
]);
