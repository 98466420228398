import {
  FormControl,
  FormGroup,
  ValidationErrors,
  ValidatorFn,
} from '@angular/forms';
import { FormUtils } from '@manakincubber/tiime-utils';
import moment from 'moment';
import { AndFilter } from 'tiime-components';

export class LabelTimelineFilterForm extends FormGroup {
  get startDate(): FormControl {
    return this.get('startDate') as FormControl;
  }

  get endDate(): FormControl {
    return this.get('endDate') as FormControl;
  }

  constructor() {
    super({
      startDate: new FormControl(null),
      endDate: new FormControl(null),
    });
    this.startDate.setValidators(startDateValidator(this.endDate));
    this.endDate.setValidators(endDateValidator(this.startDate));
  }

  resetFilters(): void {
    this.patchValue({
      startDate: null,
      endDate: null,
    });
    this.markAsPristine();
  }

  toFilter(): AndFilter<string>[] | null {
    const momentStartDate = moment(this.startDate.value);
    const momentEndDate = moment(this.endDate.value);
    if (!this.valid) {
      return null;
    }

    const res = [];
    if (momentStartDate.isValid()) {
      res.push(new AndFilter('from', [momentStartDate.format('y-MM-DD')]));
    }
    if (momentEndDate.isValid()) {
      res.push(new AndFilter('to', [momentEndDate.format('y-MM-DD')]));
    }
    return res;
  }
}

function endDateValidator(startDateControl: FormControl): ValidatorFn {
  return (control): ValidationErrors | null => {
    if (control.pristine || !control.value || !startDateControl.value) {
      return null;
    }
    return moment(startDateControl.value).isSameOrBefore(moment(control.value))
      ? null
      : { invalidDate: true };
  };
}
function startDateValidator(endDateControl: FormControl): ValidatorFn {
  return (control): ValidationErrors | null => {
    if (control.pristine || !control.value || !endDateControl.value) {
      return null;
    }
    endDateControl.updateValueAndValidity(FormUtils.shouldNotEmitEvent);
    return moment(endDateControl.value).isSameOrAfter(moment(control.value))
      ? null
      : { invalidDate: true };
  };
}
