import { OperatorFunction } from 'rxjs';
import { filter } from 'rxjs/operators';

import { TypeHelper } from './type.helper';

export function filterNotNullOrUndefined<T>(): OperatorFunction<
  T | undefined,
  NonNullable<T>
> {
  return filter(TypeHelper.isNotNullOrUndefined);
}
