import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { MatDialogModule } from '@angular/material/dialog';

import { TiimeButtonModule } from '../../button';
import { TiimeIconModule } from '../../icon';

@Component({
  standalone: true,
  imports: [CommonModule, TiimeIconModule, TiimeButtonModule, MatDialogModule],
  selector: 'tiime-dialog-close-button',
  templateUrl: './dialog-close-button.component.html',
  styleUrls: ['./dialog-close-button.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TiimeDialogCloseButtonComponent {
  @Input() closeValue?: unknown;
  @Input() disabled = false;
}
