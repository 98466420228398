import { Pipe, PipeTransform } from '@angular/core';

import { InvoiceStatus, QuoteStatus } from '@enums';

@Pipe({
  name: 'invoiceStatusIcon',
})
export class InvoiceStatusIconPipe implements PipeTransform {
  transform(
    status?: InvoiceStatus | QuoteStatus,
    invoiceType?: string,
  ): string {
    switch (status) {
      case InvoiceStatus.paid:
        return 'ic_twocolors_invoices_paid';
      case InvoiceStatus.cancelled:
      case QuoteStatus.cancelled:
      case QuoteStatus.refused:
        return 'ic_twocolors_invoices_cancelled';
      case InvoiceStatus.saved:
      case QuoteStatus.saved:
        return invoiceType === 'quotation'
          ? 'ic_twocolors_invoices_draft'
          : 'ic_twocolors_invoices_saved';
      case QuoteStatus.accepted:
        return 'ic_twocolors_invoices_saved';
      case InvoiceStatus.sent:
        return 'ic_twocolors_invoices_sent';
      case InvoiceStatus.draft:
      default:
        return 'ic_twocolors_invoices_draft';
    }
  }
}
