import { BankTransferSource } from '@core/amplitude/constants';
import { BankTransferRecurrency } from '@enums';

import { Category } from './category';
import { Document } from './document';
import { Beneficiary } from './wallet';
import { BasePayoutBodyContract, Payout } from './wallet/payout';

export type BankTransferReason = 'refund' | 'documentPayment' | undefined;

export interface BankTransferState {
  document?: Document | undefined;
  reason?: BankTransferReason;
  beneficiary?: Beneficiary | undefined;
  transferSource?: BankTransferSource | undefined;
}

interface CreateBankTransferBodyContract extends BasePayoutBodyContract {
  beneficiary: number;
  documents?: Partial<Document>[];
}

export interface CreatedBankTransferApiContract {
  id: number;
  bank_transaction?: { id: number };
}

export class CreateBankTransferBody extends Payout {
  constructor(
    beneficiary: Beneficiary,
    amount: number,
    startDate: Date,
    recurrency: BankTransferRecurrency,
    endDate?: Date,
    label?: string,
    public documents?: Pick<Document, 'id' | 'type'>[],
  ) {
    super(amount, beneficiary, startDate, recurrency, endDate, label);
  }

  toJson(): CreateBankTransferBodyContract {
    return {
      beneficiary: this.beneficiary.id,
      amount: this.amount,
      start_date: this.formatDate(this.startDate),
      end_date: this.endDate ? this.formatDate(this.endDate) : undefined,
      recurrency: this.recurrency,
      label: this.label,
      documents: this.documents,
    };
  }
}

export type DecisionTreeActionChoice = 'choice';
export type DecisionTreeActionPicker = 'document_picker';
export type DecisionTreeActionUpload = 'document_upload';

export type DecisionTreeAction =
  | DecisionTreeActionChoice
  | DecisionTreeActionPicker
  | DecisionTreeActionUpload;

export interface DecisionTreeDocumentPicker {
  action: DecisionTreeActionPicker;
  label: string;
  description: string;
  document_category: Pick<Category, 'name' | 'identifier'>;
  mandatory: boolean;
}

export interface DefaultDocumentRoutes {
  get_document_route: string;
  post_document_route: string;
}

export interface DecisionTreeDocumentUpload {
  action: DecisionTreeActionUpload;
  label: string;
  description: string;
  default_document: DefaultDocumentRoutes;
  mandatory: boolean;
}

export type DecisionTreeDocumentNeeded =
  | DecisionTreeDocumentPicker
  | DecisionTreeDocumentUpload;

export interface DecisionTreeDocumentChoice {
  action: DecisionTreeActionChoice;
  label: string;
  children: DecisionTreeDocumentNeeded[] | DecisionTreeDocumentChoice[];
  action_type?: DecisionTreeActionTypeChoice;
}

export const DECISION_TREE_ACTION_TYPE_CHOICE = [
  'transfer_reason',
  'pay_invoice',
  'salary',
  'dividend',
  'remuneration',
  'remuneration_kind',
  'other',
] as const;

export type DecisionTreeActionTypeChoice =
  (typeof DECISION_TREE_ACTION_TYPE_CHOICE)[number];
