import { Directive, HostBinding, Input } from '@angular/core';

import { TableCellBase } from './table-cell.base';

@Directive({
  selector: '[tiimeTableCell]',
})
export class TableCellDirective extends TableCellBase {
  @HostBinding('class') class = 'cdk-cell';

  @Input('tiimeTableCell')
  set name(name: string) {
    this.setColumnClass(name);
  }
}
