import { Civility } from '@core/enum';

import { User } from '../user';

export interface CardDeliveryAddressApiContract {
  additional_information1: string | null;
  additional_information2: string | null;
  city: string;
  civility: Civility;
  country: string;
  country_name?: string;
  country_code?: string;
  first_name: string;
  last_name: string;
  postal_code: string;
  street: string;
}

export class CardDeliveryAddress {
  constructor(
    public civility: Civility,
    public firstName: string,
    public lastName: string,
    public street: string,
    public postalCode: string,
    public city: string,
    public countryName: string,
    public countryCode: string,
    public additionalInformation1: string | null,
    public additionalInformation2: string | null,
  ) {}

  static fromJson(
    deliveryAddress: CardDeliveryAddressApiContract,
  ): CardDeliveryAddress {
    return new CardDeliveryAddress(
      deliveryAddress.civility,
      deliveryAddress.first_name,
      deliveryAddress.last_name,
      deliveryAddress.street,
      deliveryAddress.postal_code,
      deliveryAddress.city,
      deliveryAddress.country_name,
      deliveryAddress.country_code,
      deliveryAddress.additional_information1,
      deliveryAddress.additional_information2,
    );
  }

  static fromUser(user: User): CardDeliveryAddress {
    return new CardDeliveryAddress(
      user.civility,
      user.firstName,
      user.lastName,
      user.address.street,
      user.address.postalCode,
      user.address.city,
      user.address.countryName,
      user.address.countryCode,
      user.address.additionalInformation1,
      user.address.additionalInformation2,
    );
  }

  static toJson(
    cardDeliveryAddress: CardDeliveryAddress,
  ): CardDeliveryAddressApiContract {
    return {
      city: cardDeliveryAddress.city,
      street: cardDeliveryAddress.street,
      country_name: cardDeliveryAddress.countryName,
      country: cardDeliveryAddress.countryCode,
      civility: cardDeliveryAddress.civility,
      last_name: cardDeliveryAddress.lastName,
      first_name: cardDeliveryAddress.firstName,
      postal_code: cardDeliveryAddress.postalCode,
      additional_information1: cardDeliveryAddress.additionalInformation1,
      additional_information2: cardDeliveryAddress.additionalInformation2,
    };
  }
}
