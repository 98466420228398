import { Civility, FrenchTelephoneCode, UserRoles } from '@enums';
import { CompanyRoleEnum } from '@enums/users';

import { Company, CompanyApiContract } from '../company';
import { Vehicle, VehicleApiContract } from '../mileage-allowances/vehicle';
import { UserAddress, UserAddressApiContract } from '../user-address';

export interface UserApiContract {
  id: number;
  firstname?: string;
  lastname?: string;
  phone?: string;
  email?: string;
  onboarding_status?: boolean;
  companies?: CompanyApiContract[];
  active_company?: number;
  billing_plan?: string;
  civility?: Civility;
  address?: UserAddressApiContract;
  roles: UserRoles[];
  company_role?: CompanyRoleEnum;
  vehicles: VehicleApiContract[];
  has_wallet_access?: boolean;
}

export class User {
  get isBusinessAgent(): boolean {
    return this.roles.includes(UserRoles.ROLE_BUSINESS_AGENT);
  }

  constructor(
    public id?: number,
    public firstName?: string,
    public lastName?: string,
    public phone?: string,
    public email?: string,
    public onboardingStatus?: boolean,
    public activeCompanyId?: number,
    public activeCompany?: Company,
    public billingPlan?: string,
    public civility?: Civility,
    public address?: UserAddress,
    public roles?: UserRoles[],
    public companyRole?: CompanyRoleEnum,
    public vehicles?: Vehicle[],
    public hasWalletAccess?: boolean,
  ) {}

  static fromJson(json: UserApiContract): User {
    return new User(
      json.id,
      json.firstname,
      json.lastname,
      json.phone,
      json.email,
      json.onboarding_status,
      json.active_company,
      null,
      json.billing_plan,
      json.civility,
      json.address ? UserAddress.fromJson(json.address) : undefined,
      json.roles,
      json.company_role,
      json.vehicles
        ? json.vehicles.map(vehicle => Vehicle.fromJson(vehicle))
        : undefined,
      json.has_wallet_access,
    );
  }

  static toJson(user: User): UserApiContract {
    return {
      id: user.id || undefined,
      firstname: user.firstName,
      lastname: user.lastName,
      phone: user.phone,
      email: user.email,
      onboarding_status: user.onboardingStatus,
      active_company: user.activeCompanyId,
      roles: user.roles,
      vehicles: user.vehicles
        ? user.vehicles.map(vehicle => Vehicle.toJson(vehicle))
        : undefined,
      company_role: user.companyRole,
    };
  }

  static getPhoneCodeFromPhoneNumber(phoneNumber: string): string {
    const phoneCode = Object.values(FrenchTelephoneCode).find(code =>
      phoneNumber.startsWith(code),
    );

    return phoneCode ?? null;
  }

  static getPhoneNumberWithoutPhoneCode(phoneNumber: string): string {
    const phoneCode = User.getPhoneCodeFromPhoneNumber(phoneNumber);

    if (phoneCode) {
      return phoneNumber.substring(phoneCode.length);
    }

    return null;
  }
}
