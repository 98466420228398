<tiime-dialog-close-button></tiime-dialog-close-button>

<ng-container
  *ngIf="selectedBankTransaction$ | async as selectedBankTransaction"
>
  <div mat-dialog-title>
    Le label doit être identique sur la
    <br/>
    transaction et le document
  </div>

  <mat-dialog-content>
    <form>
      <mat-radio-group
        class="tiime-theme"
        color="primary"
        [formControl]="labelSourceControl"
      >
        <div class="section">
          <span class="description">Choisir le label de la transaction</span>
          <div class="infos">
            <div class="details">
              <ng-container *tiimeLet="userId$ | async as userId">
                <app-operation-type-logo
                  [size]="12"
                  [operationType]="selectedBankTransaction.operationType"
                  [isSalaryOperation]="
                    selectedBankTransaction.transactionAuthor &&
                    selectedBankTransaction.transactionAuthor?.userId !== userId
                  "
                ></app-operation-type-logo>
              </ng-container>
              <span>
                {{
                  selectedBankTransaction.transactionDate
                    | tiimeDatetime : 'dd/MM/yyyy'
                }}
              </span>
              <span class="wording">
                {{ selectedBankTransaction.wording }}
              </span>
            </div>
            <div class="amount">
              {{ selectedBankTransaction.amount | currency }}
            </div>
          </div>
          <div class="selection" (click)="selectRow(LabelSource.Transaction)">
            <mat-radio-button
              color="primary"
              disableRipple
              [value]="LabelSource.Transaction"
            ></mat-radio-button>

            <app-label-chip
              [large]="true"
              [label]="selectedBankTransaction.imputations[0].label"
              [hideBlockedInformation]="true"
            ></app-label-chip>
          </div>

          <div class="link-two-selections">
            <div class="links">
              <mat-icon svgIcon="icon-link-ok"></mat-icon>
            </div>
          </div>
        </div>

        <div class="section">
          <span class="description">Choisir le label du document</span>
          <div class="infos">
            <div class="details">
              <app-category-icon
                *ngIf="selectedDocument.category"
                size="12"
                [category]="selectedDocument.category"
              ></app-category-icon>
              <span>
                {{ selectedDocument.createdAt | tiimeDatetime : 'dd/MM/yyyy' }}
              </span>
              <span class="wording">
                {{ selectedDocument.name }}
              </span>
            </div>
            <div class="two-amounts">
              <span *ngIf="selectedDocument.metadata | documentVatAmount">
                TVA
                {{
                  selectedDocument.metadata
                    | documentVatAmount
                    | currency
                    : (selectedDocument.metadata
                      | documentMetadataCurrency : 'vat_amount')
                }}
              </span>
              <div class="amount">
                {{
                  selectedDocument.metadata
                    | documentAmount
                    | currency
                    : (selectedDocument.metadata
                      | documentMetadataCurrency : 'amount')
                }}
              </div>
            </div>
          </div>
          <div class="selection" (click)="selectRow(LabelSource.Document)">
            <mat-radio-button
              color="primary"
              disableRipple
              [value]="LabelSource.Document"
            ></mat-radio-button>

            <app-label-chip
              [large]="true"
              [label]="selectedDocument.label"
              [hideBlockedInformation]="true"
            ></app-label-chip>
          </div>
        </div>

        <div class="section">
          <span class="description">
            Ou bien choisir un autre label pour ces 2 éléments
          </span>
          <div id="labelSelectorSection" class="selection">
            <mat-radio-button
              color="primary"
              disableRipple
              [value]="LabelSource.Label"
              [checked]="labelSourceControl.value === LabelSource.Label"
              (click)="$event.preventDefault(); openLabelSelectorOverlay()"
            ></mat-radio-button>

            <app-label-selector
              *tiimeLet="labelCreationEnabled$ | async as labelCreationEnabled"
              [xsmall]="false"
              [labels]="labels$ | async"
              [bankTransaction]="selectedBankTransaction"
              [labelCreationEnabled]="labelCreationEnabled"
              [enableTagCreation]="!labelCreationEnabled"
              [showTagChip]="!labelCreationEnabled"
              (valueChange)="updateSelectedEntity($event)"
            ></app-label-selector>
          </div>
        </div>
      </mat-radio-group>
    </form>
  </mat-dialog-content>

  <mat-dialog-actions>
    <button mat-dialog-close tiime-button neutral inverted>Annuler</button>
    <button
      tiime-button
      accent
      [disabled]="labelSourceControl.invalid"
      (click)="submitForm(selectedBankTransaction)"
    >
      Valider
    </button>
  </mat-dialog-actions>
</ng-container>
