<tiime-dialog-close-button></tiime-dialog-close-button>

<ng-container *ngIf="errorList; else dialogContent">
  <mat-dialog-content class="dialog-content">
    <ng-container *ngTemplateOutlet="description"></ng-container>
    <div class="error-block">
      <div class="error-title">Erreur</div>
      <div>{{ errorList }}</div>
    </div>
  </mat-dialog-content>
  <mat-dialog-actions class="dialog-actions">
    <button tiime-button accent (click)="removeError()">J'ai compris</button>
  </mat-dialog-actions>
</ng-container>

<ng-template #dialogContent>
  <mat-dialog-content class="dialog-content">
    <ng-container *ngTemplateOutlet="description"></ng-container>
    <div class="upload-card">
      <tiime-twocolors-icon
        icon="ic_twocolors_file_list"
        size="24"
      ></tiime-twocolors-icon>
      <ng-container *ngIf="importedListFile; else noFileSelected">
        <mat-icon
          class="checked"
          inline="true"
          fontIcon="icon-check-1c-b-24"
        ></mat-icon>
        <span class="file-name">
          {{ importedListFile.name }}
        </span>
        <mat-icon
          class="remove"
          inline="true"
          fontIcon="icon-ic-remove"
          (click)="removeFile()"
        ></mat-icon>
      </ng-container>
      <ng-template #noFileSelected>
        <span class="file-name">{{ config.modelName }}</span>
        <button
          class="download-icon"
          tiime-button
          inverted
          accent
          xsmall
          icon
          (click)="modelRequested.emit()"
        >
          <mat-icon inline="true" fontIcon="icon-download-24"></mat-icon>
        </button>
      </ng-template>
    </div>
    <div
      *ngIf="config.helpMessage && !importedListFile"
      class="help-block"
      [innerHTML]="config.helpMessage"
    ></div>
    <div *ngIf="loading" class="loading-state">
      <div class="progress">{{ progress }}%</div>
      <tiime-progress-bar-loader
        mode="determinate"
        color="accent"
        [value]="progress"
      >
        Chargement en cours...
      </tiime-progress-bar-loader>
    </div>
  </mat-dialog-content>
  <mat-dialog-actions *ngIf="!loading" class="dialog-actions">
    <input
      id="file"
      hidden
      type="file"
      #file
      [accept]="config.fileType"
      [formControl]="fileControl"
      (change)="onFileSelected(file.files)"
    />
    <button
      *ngIf="!importedListFile"
      tiime-button
      accent
      data-cy="browse-file__btn"
      (click)="file.click()"
    >
      Parcourir mes fichiers
    </button>
    <button
      *ngIf="importedListFile"
      tiime-button
      accent
      data-cy="upload-file__btn"
      (click)="uploadRequested.emit(importedListFile)"
    >
      Importer cette liste
    </button>
  </mat-dialog-actions>
</ng-template>

<ng-template #description>
  <img
    alt=""
    draggable="false"
    [src]="errorList ? config.errorIllustration : config.illustration"
  />
  <span mat-dialog-title>{{ config.dialogTitle }}</span>
  <span class="description">
    {{ config.description }}
  </span>
</ng-template>
