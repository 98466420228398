import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import { TooltipPosition } from 'tiime-components';

import { StandardDocumentCategoryIdentifier } from '@enums';

@Component({
  selector: 'app-linked-items-tooltip-icon',
  templateUrl: './linked-items-tooltip-icon.component.html',
  styleUrls: [
    '../tooltip-icons-base.scss',
    './linked-items-tooltip-icon.component.scss',
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LinkedItemsTooltipIconComponent {
  readonly StandardDocumentCategoryIdentifier =
    StandardDocumentCategoryIdentifier;

  @Input() tooltipPosition: TooltipPosition = 'bottom';
  @Input() tooltipInvertedPosition: TooltipPosition = 'left';
  @Input() linkedItemsNumber: number;
  @Input() icon: 'icon-link-ok' | 'icon-expense-report' = 'icon-link-ok';
  @Input() iconGreyed = false;

  @Output() openLinkTransactionDialog = new EventEmitter();
}
