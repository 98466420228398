import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import {
  TiimeButtonModule,
  TiimeLetModule,
  TiimeTooltipModule,
} from 'tiime-components';

import { CopyToClipboardDirective } from '@shared';

import { FileUploaderButtonModule } from '../../shared/components/file-uploader-button/file-uploader-button.module';
import { DragAndDropZoneModule } from '../company-shared/components/drag-and-drop-zone/drag-and-drop-zone.module';
import { SidenavDocumentUploadComponent } from './sidenav-document-upload.component';

@NgModule({
  declarations: [SidenavDocumentUploadComponent],
  imports: [
    CommonModule,
    DragAndDropZoneModule,
    FileUploaderButtonModule,
    MatIconModule,
    TiimeButtonModule,
    TiimeLetModule,
    TiimeTooltipModule,
    CopyToClipboardDirective,
  ],
  exports: [SidenavDocumentUploadComponent],
})
export class SidenavDocumentUploadModule {}
