import {
  Directive,
  Output,
  HostListener,
  EventEmitter,
  Input,
} from '@angular/core';

@Directive({
  selector: '[tiimeFilesDropZone]',
  exportAs: 'tiime-files-drop-zone',
})
export class FilesDropZoneDirective {
  @Input() acceptedTypes: string[] = [];
  @Input() maximumSize: number | null = null;
  @Input() multiple: boolean;

  @Output() readonly dropped = new EventEmitter<FileList | null>();

  /**
   * `hovered` output is the prefered way to use this directive.
   * However if behavior is incorrect (mostly when hovered state is handled by ngIfs), use `dragOver`/`dragLeave` output
   */
  @Output() readonly hovered = new EventEmitter<boolean>();
  @Output() readonly unauthorizedType = new EventEmitter<boolean>();
  @Output() readonly incorrectSize = new EventEmitter<boolean>();

  /**
   * Outputs evertytime a `dragover` event is catched.
   * Use this when `hovered` is not working correctly.
   */
  @Output() readonly dragOver = new EventEmitter<boolean>();

  /**
   * Outputs evertytime a `dragleave` event is catched
   * Use this when `hovered` is not working correctly.
   */
  @Output() readonly dragLeave = new EventEmitter<boolean>();

  /**
   * As `dragenter` triggers once but also on child elements, a counter enables to know if the `dragleave` event
   * is on the current div or on a child div
   */
  dragEnterCount = 0;

  @HostListener('drop', ['$event'])
  onDrop($event: DragEvent): void {
    $event.preventDefault();
    $event.stopPropagation();
    if (!$event.dataTransfer) {
      this.dropped.emit(null);
      this.unauthorizedType.emit(false);
      this.incorrectSize.emit(false);
      this.resetHover();
    } else {
      this.handleDroppedFiles($event.dataTransfer.files);
    }
  }

  /**
   * Necessary to prevent opening the dropped file in a new tab, and for components where `hovered.emit(boolean)` does not work
   */
  @HostListener('dragover', ['$event'])
  preventOpenInNewTab($event: DragEvent): void {
    $event.preventDefault();
    this.dragOver.emit(true);
  }

  /**
   * `dragenter` in only triggered once, unlike `dragover`, so it is better to use with a counter
   */
  @HostListener('dragenter', ['$event'])
  onDragEnter($event: DragEvent): void {
    $event.preventDefault();
    this.dragEnterCount++;
    this.hovered.emit(this.dragEnterCount > 0);
  }

  @HostListener('dragleave', ['$event'])
  onDragLeave($event: DragEvent): void {
    $event.preventDefault();
    this.dragEnterCount--;
    this.hovered.emit(this.dragEnterCount > 0);
    this.dragLeave.emit(true);
  }

  private handleDroppedFiles(fileList: FileList): void {
    this.resetHover();

    if (this.multiple) {
      this.dropped.emit(fileList);
      this.unauthorizedType.emit(false);
      this.incorrectSize.emit(false);
      return;
    }
    const file = fileList.item(0);
    if (file && !this.isAcceptedType(file)) {
      return this.unauthorizedType.emit(true);
    }
    if (file && this.hasIncorrectSize(file)) {
      return this.incorrectSize.emit(true);
    }
    this.dropped.emit(fileList);
    this.unauthorizedType.emit(false);
    this.incorrectSize.emit(false);
  }

  private resetHover(): void {
    this.dragEnterCount = 0;
    this.hovered.emit(false);
    this.dragLeave.emit(true);
  }

  private hasIncorrectSize(file: File): boolean {
    if (!this.maximumSize) {
      return false;
    }
    return file.size > this.maximumSize * 1000000;
  }

  private isAcceptedType(file: File): boolean {
    if (this.acceptedTypes.length === 0) {
      return true;
    }
    return this.acceptedTypes.some((type: string) =>
      file.type.endsWith(type.replace('.', '')),
    );
  }
}
