export interface AddressApiContract {
  street: string;
  postal_code: string;
  city: string;
  country: string;
}

export class Address {
  get fullAddress(): string {
    return `${this.street}, ${this.postalCode} ${this.city}, ${this.country}`.trim();
  }

  constructor(
    public street?: string,
    public postalCode?: string,
    public city?: string,
    public country?: string,
  ) {}

  static fromJson(json: AddressApiContract): Address {
    return new Address(json.street, json.postal_code, json.city, json.country);
  }

  static toJson(address: Partial<Address>): AddressApiContract {
    return {
      street: address?.street,
      postal_code: address.postalCode,
      city: address.city,
      country: address.country,
    };
  }
}
