<div class="container">
  <a
    class="homelink"
    tiime-sidenav-item
    routerLinkActive="active"
    data-cy="home-menu-link"
    #home="routerLinkActive"
    [routerLink]="['home']"
  >
    <img
      class="tiime-logo"
      alt="app logo"
      draggable="false"
      src="assets/logo_tiime.svg"
    />
  </a>
  <div class="separator"></div>

  <div class="app-links-container" scrollShadows>
    <tiime-sidenav-menu
      *hasAccess="UserPermissionNameEnum.INVOICE_USER_PARTIAL"
      theme="invoice-theme"
      iconSrc="assets/icon_invoice.svg"
      menuTitle="Facturation"
      routerLinkActive="active"
      data-cy="invoice-menu-link"
    >
      <a
        tiime-sidenav-item
        routerLinkActive="active"
        data-cy="expense-invoice-menu-link"
        [routerLink]="['invoice', 'invoices']"
      >
        Factures
      </a>

      <a
        tiime-sidenav-item
        routerLinkActive="active"
        data-cy="quotes-menu-link"
        [routerLink]="['invoice', 'quotes']"
      >
        Devis
      </a>

      <a
        tiime-sidenav-item
        routerLinkActive="active"
        data-cy="clients-menu-link"
        [routerLink]="['invoice', 'clients']"
      >
        Clients
      </a>
    </tiime-sidenav-menu>

    <ng-container *hasAccess="UserPermissionNameEnum.ACCOUNTS_USER_FULL">
      @if (isMicroEntrepreneur$ | async) {
        <div class="cash-tracking-theme">
          <a
            class="cash-tracking"
            tiime-sidenav-item
            routerLinkActive="active"
            data-cy="registry-menu-link"
            #registryLink="routerLinkActive"
            [routerLink]="['registry']"
          >
            <img
              class="link-item-icon"
              alt="Registres"
              draggable="false"
              [src]="
                registryLink?.isActive
                  ? 'assets/icon_registers_inverted.svg'
                  : 'assets/icon_registers.svg'
              "
            />
            Registres
          </a>
        </div>
      }
    </ng-container>

    <tiime-sidenav-menu
      *hasAccess="[
        UserPermissionNameEnum.ACCOUNTS_USER_FULL,
        UserPermissionNameEnum.BUSINESS_USER_PARTIAL,
      ]"
      theme="accounts-theme"
      iconSrc="assets/icon_bank_transactions.svg"
      menuTitle="Comptes"
      routerLinkActive="active"
      data-cy="account-menu-link"
    >
      <a
        tiime-sidenav-item
        routerLinkActive="active"
        data-cy="bank-transactions-menu-link"
        [routerLink]="['upgrade']"
        [queryParams]="{ context: 'bank-transactions' }"
      >
        Transactions
      </a>
    </tiime-sidenav-menu>

    <tiime-sidenav-menu
      *hasAccess="UserPermissionNameEnum.ACCOUNTS_USER_PARTIAL"
      theme="expense-theme"
      iconSrc="assets/category-icons/icon_receipt.svg"
      menuTitle="Dépenses"
      routerLinkActive="active"
      data-cy="expense-menu-link"
    >
      <a
        tiime-sidenav-item
        routerLinkActive="active"
        data-cy="expense-invoice-menu-link"
        [routerLink]="['upgrade']"
        [queryParams]="{ context: 'expense-invoices' }"
      >
        Factures
      </a>

      <a
        *hasAccess="UserPermissionNameEnum.ACCOUNTS_USER_FULL"
        tiime-sidenav-item
        routerLinkActive="active"
        data-cy="advanced-expenses-menu-link"
        [routerLink]="['upgrade']"
        [queryParams]="{ context: 'advanced-expenses' }"
      >
        Frais à rembourser
      </a>

      <a
        *hasAccess="UserPermissionNameEnum.ACCOUNTS_USER_FULL"
        tiime-sidenav-item
        routerLinkActive="active"
        data-cy="mileage-allowances-menu-link"
        [routerLink]="['upgrade']"
        [queryParams]="{ context: 'mileage-allowances' }"
      >
        Frais kilométriques
      </a>
    </tiime-sidenav-menu>

    <div
      *hasAccess="UserPermissionNameEnum.ACCOUNTS_USER_FULL"
      class="receipt-theme"
    >
      <a
        tiime-sidenav-item
        routerLinkActive="active"
        data-cy="documents-menu-link"
        #documentIsActive="routerLinkActive"
        [routerLinkActiveOptions]="upsellRouterLinkActiveOptions"
        [routerLink]="['upgrade']"
        [queryParams]="{ context: 'documents' }"
      >
        <img
          class="link-item-icon"
          alt="Documents"
          draggable="false"
          [src]="
            documentIsActive.isActive
              ? 'assets/icon_folder_inverted.svg'
              : 'assets/icon_folder.svg'
          "
        />
        Documents
      </a>
    </div>
  </div>
</div>
