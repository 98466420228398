import { TrackingEvent } from '@manakincubber/tiime-tracking';

import { StandardDocumentCategoryIdentifier } from '@enums';

export const GED_DOCUMENT_ACCESSED = 'Ged Document Accessed';

export class GedDocumentAccessed implements TrackingEvent {
  readonly name = GED_DOCUMENT_ACCESSED;

  constructor(
    readonly documentCategoryIdentifier: StandardDocumentCategoryIdentifier,
  ) {}
}
