<input
  *ngFor="let part of parts; let i = index; trackBy: trackByIndex"
  type="text"
  maxlength="4"
  #ibanInput
  [value]="part"
  [disabled]="disabled"
  (input)="updateIban(i, $event)"
  (keydown)="handleKeyDown(i, $event)"
  (paste)="onPaste($event)"
  (blur)="onBlur()"
/>
