import { StandardDocumentCategoryIdentifier } from '@enums';

export const CATEGORIES_OF_TYPE_RECEIPT = [
  StandardDocumentCategoryIdentifier.RECEIPT,
  StandardDocumentCategoryIdentifier.MILEAGE_ALLOWANCE,
  StandardDocumentCategoryIdentifier.FISCAL,
  StandardDocumentCategoryIdentifier.SOCIAL_EMPLOYEE,
  StandardDocumentCategoryIdentifier.JURIDICAL,
  StandardDocumentCategoryIdentifier.SOCIAL_DIRECTOR,
  StandardDocumentCategoryIdentifier.ANNUAL_ACCOUNT,
  StandardDocumentCategoryIdentifier.PAYSLIP,
  StandardDocumentCategoryIdentifier.PERMANENT_DOCUMENT,
  StandardDocumentCategoryIdentifier.OTHER_DOCUMENT,
];
