import { formatPercent } from '@angular/common';

import { VatTypeCode } from '../enum/vat-type-code';
import { VatType } from '../models/vat-type';

export class VatTypeHelper {
  static hasCustomLegalNotice(
    vatTypes: VatType[],
    legalNotice: string,
  ): boolean {
    // An empty string is considered as a valid legal notice
    if (legalNotice == null) {
      return false;
    }

    return !vatTypes.some(
      (vatType: VatType) =>
        vatType.invoiceLegalNotice &&
        vatType.invoiceLegalNotice === legalNotice,
    );
  }

  static uniqueVatRateTypes(vatTypes: VatType[]): VatType[] {
    const uniqueVatRateTypes =
      VatTypeHelper.vatTypesWithoutLegalNotice(vatTypes);
    const zeroVatType = vatTypes.find(
      (vatType: VatType) => vatType.code === VatTypeCode.none,
    );
    if (zeroVatType) {
      uniqueVatRateTypes.push(zeroVatType);
    }
    return uniqueVatRateTypes;
  }

  static vatRate(vatTypes: VatType[], code: string, date?: string): number {
    const selectedVatType = vatTypes.find(
      (vatType: VatType) => vatType.code === code,
    );
    return selectedVatType?.vatRateEmissionDateIn(date);
  }

  static hasZeroVatRate(
    vatTypeCode: string,
    vatTypes: VatType[],
    date?: string,
  ): boolean {
    const vatRate = this.vatRate(vatTypes, vatTypeCode, date);
    return vatRate === 0;
  }

  static vatTypeLabel(
    vatTypes: VatType[],
    code: string,
    date?: string,
  ): string {
    if (!vatTypes || !code) {
      return null;
    }

    const selectedVatType = vatTypes.find(
      (vatType: VatType) => vatType.code === code,
    );
    return selectedVatType.hasName
      ? selectedVatType.name
      : `TVA ${formatPercent(
          selectedVatType.vatRateEmissionDateIn(date),
          'fr',
          '1.0-2',
        )}`;
  }

  static vatTypeLegalNotice(vatTypes: VatType[], code: string): string {
    return vatTypes.find((vatType: VatType) => vatType.code === code)
      .invoiceLegalNotice;
  }

  static vatTypeCode(vatTypes: VatType[], legalNotice: string): string {
    return vatTypes.find(
      ({ invoiceLegalNotice }: VatType) => invoiceLegalNotice === legalNotice,
    ).code;
  }

  static vatTypesWithLegalNotice(vatTypes: VatType[]): VatType[] {
    return vatTypes.filter((vatType: VatType) => vatType.invoiceLegalNotice);
  }

  static translateLegalNotice(vatTypes: VatType[]): VatType[] {
    return vatTypes.filter((vatType: VatType) => vatType.invoiceLegalNotice);
  }

  static vatTypesWithoutLegalNotice(vatTypes: VatType[]): VatType[] {
    return vatTypes.filter((vatType: VatType) => !vatType.invoiceLegalNotice);
  }
}
