import { CdkTableModule } from '@angular/cdk/table';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { MassActionCellComponent } from './mass-actions/mass-actions-cell/mass-action-cell.component';
import { MassActionHeaderCellComponent } from './mass-actions/mass-actions-header-cell/mass-action-header-cell.component';
import { TableAnchorComponent } from './table-anchor.component';
import { TableCellDirective } from './table-cell.directive';
import { TableContentPlaceholderComponent } from './table-content-placeholder.component';
import { TableHeaderCellDirective } from './table-header-cell.directive';
import { TableHeaderRowDirective } from './table-header-row.directive';
import { TableRowDirective } from './table-row.directive';
import { StickyTableDirective } from './table-sticky.directive';
import { TableComponent } from './table.component';
import { VirtualTableComponent } from './virtual-table.component';

@NgModule({
  imports: [
    CommonModule,
    CdkTableModule,
    MassActionHeaderCellComponent,
    MassActionCellComponent,
  ],
  declarations: [
    TableComponent,
    TableContentPlaceholderComponent,
    TableAnchorComponent,
    StickyTableDirective,
    VirtualTableComponent,
    TableHeaderCellDirective,
    TableHeaderRowDirective,
    TableCellDirective,
    TableRowDirective,
  ],
  exports: [
    CdkTableModule,
    TableComponent,
    TableContentPlaceholderComponent,
    TableAnchorComponent,
    StickyTableDirective,
    VirtualTableComponent,
    TableHeaderCellDirective,
    TableHeaderRowDirective,
    TableCellDirective,
    TableRowDirective,
    MassActionHeaderCellComponent,
    MassActionCellComponent,
  ],
})
export class TiimeTableModule {}
