<div class="filter-container" [formGroup]="controlContainer.control">
  <label>Date</label>
  <div class="datepicker-container">
    <input
      type="text"
      formControlName="date"
      placeholder="Date exacte (JJ/MM/AAAA)"
      [matDatepicker]="dateFilterDatepicker"
      [min]="min"
      [max]="max"
    />
    <mat-datepicker
      panelClass="tiime-components-datepicker"
      placeholder="Aucune information"
      #dateFilterDatepicker
      [calendarHeaderComponent]="tiimeDatepickerHeader"
    ></mat-datepicker>
    <span
      class="datepicker-trigger pointer"
      (click)="dateFilterDatepicker.open()"
    >
      <mat-icon svgIcon="{{ TIIME_ICON_PREFIX + 'calendar' }}"></mat-icon>
    </span>
  </div>
  <label>Période</label>
  <div class="interval-group">
    <div class="datepicker-container">
      <input
        type="text"
        placeholder="Date de début (JJ/MM/AAAA)"
        formControlName="startDate"
        [matDatepicker]="dateStartFilterDatepicker"
        [min]="min"
        [max]="max"
      />
      <mat-datepicker
        panelClass="tiime-components-datepicker"
        placeholder="Aucune information"
        #dateStartFilterDatepicker
        [calendarHeaderComponent]="tiimeDatepickerHeader"
      ></mat-datepicker>
      <span
        class="datepicker-trigger pointer"
        (click)="dateStartFilterDatepicker.open()"
      >
        <mat-icon svgIcon="{{ TIIME_ICON_PREFIX + 'calendar' }}"></mat-icon>
      </span>
    </div>
    <div class="datepicker-container">
      <input
        type="text"
        placeholder="Date de fin (JJ/MM/AAAA)"
        formControlName="endDate"
        [matDatepicker]="dateEndFilterDatepicker"
        [min]="min"
        [max]="max"
      />
      <mat-datepicker
        panelClass="tiime-components-datepicker"
        placeholder="Aucune information"
        #dateEndFilterDatepicker
        [calendarHeaderComponent]="tiimeDatepickerHeader"
      ></mat-datepicker>
      <span
        class="datepicker-trigger pointer"
        (click)="dateEndFilterDatepicker.open()"
      >
        <mat-icon svgIcon="{{ TIIME_ICON_PREFIX + 'calendar' }}"></mat-icon>
      </span>
    </div>
  </div>
</div>
