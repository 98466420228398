import { ExportConfigurationBase } from '../../settings/export/export-core/bases/export-configuration.base';
import { Category } from './category';
import { Tag } from './tag';
import { ThirdParty } from './third_party';

export interface ReceiptsExportApiContract {
  options: {
    linked_to_bank_transaction: boolean | null;
    with_attachments: boolean;
  };
  tag_ids?: Tag['id'][];
  third_partie_ids?: ThirdParty['id'][];
  categorie_ids?: Category['id'][];
}

export class DocumentsExportConfiguration extends ExportConfigurationBase {
  constructor(
    public exportReceiptsLinkedToTransactions: boolean | null,
    public attachDocuments: boolean,
    public tags: Tag[] | null,
    public thirdParties: ThirdParty[] | null,
    public categories: Category[] | null,
  ) {
    super();
  }

  static toJson({
    exportReceiptsLinkedToTransactions,
    attachDocuments,
    tags,
    thirdParties,
    categories,
  }: DocumentsExportConfiguration): { documents: ReceiptsExportApiContract } {
    return {
      documents: {
        options: {
          linked_to_bank_transaction: exportReceiptsLinkedToTransactions,
          with_attachments: attachDocuments,
        },
        ...(this.shouldFilterBy<Tag>(tags) && {
          tag_ids: tags.map(tag => tag.id),
        }),
        ...(this.shouldFilterBy<ThirdParty>(thirdParties) && {
          third_partie_ids: thirdParties.map(thirdParty => thirdParty.id),
        }),
        ...(this.shouldFilterBy<Category>(categories) && {
          categorie_ids: categories.map(category => category.id),
        }),
      },
    };
  }
}
