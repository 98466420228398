import { Injectable, inject } from '@angular/core';
import { BehaviorSubject, Observable, filter } from 'rxjs';
import { TiimeOverlayService } from 'tiime-components';

import { BankTransferForm } from '@core/forms';
import { BankTransferState, Document } from '@core/models';

import { BankTransferOverlayComponent } from './bank-transfer-overlay.component';

export interface BankTransferOverlayResponse {
  refresh?: boolean;
}

export enum OcerisationStep {
  NOT_STARTED = 'not_started',
  DOC_UPLOADING = 'doc_uploading',
  OCERISATION = 'ocerisation',
  DONE = 'done',
  SKIPPED = 'skipped',
  ERROR = 'error',
}

@Injectable({ providedIn: 'root' })
export class BankTransferOverlayService {
  private readonly overlayService = inject(TiimeOverlayService);

  readonly document$ = new BehaviorSubject<Document | null>(null);
  readonly ocerisationStep$ = new BehaviorSubject<OcerisationStep>(
    OcerisationStep.NOT_STARTED,
  );
  readonly ocerisationAvailable$ = new BehaviorSubject<boolean>(true);
  readonly allowSkip$ = new BehaviorSubject<boolean>(false);

  readonly form = new BankTransferForm();

  open(
    bankTransferState?: BankTransferState,
  ): Observable<BankTransferOverlayResponse> {
    return this.overlayService
      .open(BankTransferOverlayComponent, bankTransferState || {}, {
        hasBackdrop: true,
        backdropClass: 'opaque-backdrop',
      })
      .beforeClosed()
      .pipe(
        filter((response: BankTransferOverlayResponse) => response?.refresh),
      );
  }
}
