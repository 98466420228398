<div class="acronym-illustration__container">
  <img
    class="acronym-illustration__image"
    draggable="false"
    alt=""
    [src]="color | map: mapToBackgroundIllustration"
  />

  <div class="acronym-illustration__acronym">
    {{ acronym }}
  </div>
</div>
