import { ComponentType } from '@angular/cdk/portal';
import { Injectable } from '@angular/core';
import {
  MatSnackBar,
  MatSnackBarConfig,
  MatSnackBarRef,
} from '@angular/material/snack-bar';

import { SnackbarDataType, SnackbarType } from './snackbar-config';
import { SnackbarComponent } from './snackbar.component';
import { TiimeSnackbarModule } from './snackbar.module';

@Injectable({
  providedIn: TiimeSnackbarModule,
})
export class TiimeSnackbarService {
  constructor(private matSnackbar: MatSnackBar) {}

  open(
    message: string,
    config: MatSnackBarConfig<Omit<SnackbarDataType, 'message'>>,
    actionButtonText?: string,
  ): MatSnackBarRef<SnackbarComponent> {
    const snackbarType: SnackbarType | undefined = config.data?.type;
    return this.matSnackbar.openFromComponent(SnackbarComponent, {
      ...config,
      data: { message, type: snackbarType, actionButtonText },
    });
  }

  openFromComponent<T, D = unknown>(
    component: ComponentType<T>,
    config?: MatSnackBarConfig<D>,
  ): MatSnackBarRef<T> {
    let classes: string[] = [];
    if (config?.panelClass) {
      classes = Array.isArray(config.panelClass)
        ? config.panelClass
        : [config.panelClass];
    }

    return this.matSnackbar.openFromComponent(component, {
      ...config,
      panelClass: ['from-component', ...classes],
    });
  }
}
