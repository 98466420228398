import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { combineLatest, iif, of } from 'rxjs';
import {
  catchError,
  map,
  switchMap,
  take,
  tap,
  withLatestFrom,
} from 'rxjs/operators';
import { SnackbarConfig, TiimeSnackbarService } from 'tiime-components';

import { CardsService } from '@core';
import { WalletAccountService } from '@core/services/wallet-account.service';

import { Company, User } from '../../models';
import { selectedCompanySelector, userSelector } from '../user';
import {
  loadWalletAccount,
  LOAD_WALLET_ACCOUNT,
  ORDER_CARD,
  updateWalletAccount,
  UPDATE_CARD_LIMITS,
  UPDATE_CARD_OPTIONS,
  UPDATE_CARD_STATE,
} from './wallet-account.actions';
import { cardInUseSelector } from './wallet-account.selectors';

@Injectable()
export class WalletAccountEffects {
  constructor(
    private readonly actions$: Actions,
    private readonly walletAccountService: WalletAccountService,
    private readonly cardsService: CardsService,
    private readonly snackbar: TiimeSnackbarService,
    private readonly store: Store,
  ) {}

  loadWalletAccount$ = createEffect(() =>
    this.actions$.pipe(
      ofType(LOAD_WALLET_ACCOUNT),
      switchMap(() =>
        combineLatest([
          this.store.select(selectedCompanySelector),
          this.store.select(userSelector),
        ]).pipe(
          switchMap(([company, user]: [Company, User]) =>
            iif(
              () => company.walletAccess,
              this.walletAccountService.getWalletAccount(company.id, user.id),
              of(null),
            ),
          ),
          map(walletAccount => updateWalletAccount({ walletAccount })),
          catchError(() => of(updateWalletAccount({ walletAccount: null }))),
        ),
      ),
    ),
  );

  orderCard$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ORDER_CARD),
      switchMap(({ pinCode, cardDeliveryAddress, userId }) =>
        this.cardsService.create(pinCode, cardDeliveryAddress, userId),
      ),
      // Refresh the wallet account after the card creation
      map(() => loadWalletAccount()),
      tap(() =>
        this.snackbar.open(
          'Ma carte est commandée ! Je surveille ma boîte aux lettres.',
          SnackbarConfig.success,
        ),
      ),
      catchError(() => of(loadWalletAccount())),
    ),
  );

  updateCardState$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UPDATE_CARD_STATE),
      switchMap(({ id, action, userId }) =>
        this.cardsService
          .updateState(id, action, userId)
          .pipe(
            catchError(() =>
              this.store.select(cardInUseSelector).pipe(take(1)),
            ),
          ),
      ),
      map(() => loadWalletAccount()),
    ),
  );

  updateCardOptions$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UPDATE_CARD_OPTIONS),
      switchMap(({ card, userId }) =>
        this.cardsService
          .updateOptions(card, userId)
          .pipe(
            catchError(() =>
              this.store.select(cardInUseSelector).pipe(take(1)),
            ),
          ),
      ),
      map(() => loadWalletAccount()),
    ),
  );

  updateCardLimits$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UPDATE_CARD_LIMITS),
      withLatestFrom(this.store.select(cardInUseSelector)),
      switchMap(([{ limitPaymentWeek, userId }, card]) =>
        this.cardsService.updateLimits(card, limitPaymentWeek, userId).pipe(
          catchError(() => this.store.select(cardInUseSelector)),
          take(1),
        ),
      ),
      map(() => loadWalletAccount()),
    ),
  );
}
