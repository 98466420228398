import { FormControl, FormGroup, Validators } from '@angular/forms';
import { FormUtils } from '@manakincubber/tiime-utils';
import { tap } from 'rxjs/operators';

export class TiimeBusinessOnboardingForm extends FormGroup {
  get hasNonEuBusinessActivities(): FormControl {
    return this.get('hasNonEuBusinessActivities') as FormControl;
  }

  get isSubjectToEconomicSanctions(): FormControl {
    return this.get('isSubjectToEconomicSanctions') as FormControl;
  }

  get isPhysicallyPresentInCountriesSubjectToSanctions(): FormControl {
    return this.get(
      'isPhysicallyPresentInCountriesSubjectToSanctions',
    ) as FormControl;
  }

  get isInvolvedInEntitiesSubjectToSanctions(): FormControl {
    return this.get('isInvolvedInEntitiesSubjectToSanctions') as FormControl;
  }

  constructor() {
    super({
      personalTaxResidence: new FormControl(null, Validators.requiredTrue),
      companyTaxResidence: new FormControl(null, Validators.requiredTrue),
      hasNonEuBusinessActivities: new FormControl(null, Validators.required),
      isSubjectToEconomicSanctions: new FormControl(
        { value: null, disabled: true },
        Validators.required,
      ),
      isPhysicallyPresentInCountriesSubjectToSanctions: new FormControl(
        { value: null, disabled: true },
        Validators.required,
      ),
      isInvolvedInEntitiesSubjectToSanctions: new FormControl(
        { value: null, disabled: true },
        Validators.required,
      ),
    });

    this.hasNonEuBusinessActivities.valueChanges
      .pipe(
        tap((checked: boolean) => {
          const toggleState = checked ? 'enable' : 'disable';

          this.isInvolvedInEntitiesSubjectToSanctions[toggleState](
            FormUtils.shouldNotEmitEvent,
          );
          this.isPhysicallyPresentInCountriesSubjectToSanctions[toggleState](
            FormUtils.shouldNotEmitEvent,
          );
          this.isSubjectToEconomicSanctions[toggleState](
            FormUtils.shouldNotEmitEvent,
          );
        }),
      )
      .subscribe();
  }
}
