import { LabelDisabledByEnum, RegistryTypeEnum } from '@enums';

export interface LabelApiContract {
  id?: number;
  label: string;
  client?: { id: number };
  color?: string;
  acronym?: string;
  disabled?: boolean;
  disabled_by?: LabelDisabledByEnum;
  name?: string;
  registry_type?: RegistryTypeEnum;
  rules_count?: number;
  is_standard?: boolean;
}

export interface SimpleBankTransactionLabel {
  id?: number;
  name?: string;
  acronym?: string;
  color?: string;
  disabled?: boolean;
  disabledBy?: LabelDisabledByEnum;
  client?: { id: number };
  rulesCount?: number;
  isStandard?: boolean;
}

export class Label implements SimpleBankTransactionLabel {
  invoiceClient: boolean;

  get canBeEdited(): boolean {
    return !this.isStandard && !this.disabled;
  }

  get notCreated(): boolean {
    return !(this.id && this.acronym);
  }

  constructor(
    public id?: number,
    public label?: string,
    public client?: { id: number },
    public color?: string,
    public acronym?: string,
    public disabled?: boolean,
    public name?: string,
    public registryType?: RegistryTypeEnum,
    public rulesCount?: number,
    public isStandard?: boolean,
    public disabledBy?: LabelDisabledByEnum,
  ) {}

  static fromJson(json: LabelApiContract): Label {
    return new Label(
      json.id,
      json.label,
      json.client ? json.client : null,
      json.color,
      json.acronym,
      json.disabled,
      json.name,
      json.registry_type,
      json.rules_count,
      json.is_standard,
      json.disabled_by,
    );
  }

  static toJson(label: Label): LabelApiContract {
    return {
      id: label.id,
      label: label.label,
      client: label.client,
      color: label.color,
      acronym: label.acronym,
      disabled: label.disabled,
      name: label.name,
    };
  }
}
