import {
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
} from '@angular/core';
import { Mapper } from '@manakincubber/tiime-utils';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { select, Store } from '@ngrx/store';
import { filter, take, tap } from 'rxjs/operators';

import {
  categoriesSelector,
  categoryByIdSelector,
  loadCategories,
} from '@core/store';
import { FileTransferStatus } from '@enums';
import { Category, FileTransfer, UploadDocument } from '@models';
import { AppState } from '@store/app-state';

enum StatusClass {
  ON_ERROR = 'on-error',
  NO_DATA = 'no-data',
  COMPLETED = 'completed',
  IN_PROGRESS = 'in-progress',
}

@UntilDestroy()
@Component({
  selector: 'app-transferring-file',
  templateUrl: './transferring-file.component.html',
  styleUrls: ['./transferring-file.component.scss'],
})
export class TransferringFileComponent implements OnInit, OnChanges {
  @Input() fileTransfer: FileTransfer;

  readonly FileTransferStatus = FileTransferStatus;

  selectedCategory: Category;

  readonly mapToStatusClass: Mapper<FileTransferStatus, string> = (
    status: FileTransferStatus,
  ) => {
    switch (status) {
      case FileTransferStatus.ON_ERROR:
        return StatusClass.ON_ERROR;
      case FileTransferStatus.NO_DATA:
        return StatusClass.NO_DATA;
      case FileTransferStatus.COMPLETED:
        return StatusClass.COMPLETED;
      default:
        return StatusClass.IN_PROGRESS;
    }
  };

  constructor(private readonly store: Store<AppState>) {}

  ngOnInit(): void {
    this.loadCategoriesIfNeeded();
  }

  ngOnChanges(changes: SimpleChanges): void {
    const categoryId = (this.fileTransfer as UploadDocument).categoryId;
    if (changes.fileTransfer && categoryId) {
      this.store
        .pipe(
          select(categoryByIdSelector(categoryId)),
          tap(category => {
            this.selectedCategory = category;
          }),
          untilDestroyed(this),
        )
        .subscribe();
    }
  }

  private loadCategoriesIfNeeded(): void {
    this.store
      .pipe(
        select(categoriesSelector),
        take(1),
        filter(categories => categories === null),
        tap(() => this.store.dispatch(loadCategories())),
        untilDestroyed(this),
      )
      .subscribe();
  }
}
