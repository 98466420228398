import { InvoiceStatus } from '@enums';
import { Client, Tag } from '@models';

import { ExportConfigurationBase } from '../../settings/export/export-core/bases/export-configuration.base';

export interface InvoicesExportApiContract {
  type: 'invoices';
  status?: InvoiceStatus[] | null;
  options: {
    linked_to_bank_transaction: boolean | null;
    with_attachments: boolean;
  };
  client_ids?: Client['id'][];
  tag_ids?: Tag['id'][];
}

export class InvoicesExportConfiguration extends ExportConfigurationBase {
  constructor(
    public invoiceStatusesToExport: InvoiceStatus[] | null,
    public exportInvoicesLinkedToBankTransaction: boolean | null,
    public attachDocuments: boolean,
    public clients: Client[] | null,
    public tags: Tag[] | null,
  ) {
    super();
  }

  static toJson(configuration: InvoicesExportConfiguration): {
    invoices: InvoicesExportApiContract;
  } {
    return {
      invoices: {
        type: 'invoices',
        ...(this.shouldFilterBy<InvoiceStatus>(
          configuration.invoiceStatusesToExport,
        ) && {
          status: configuration.invoiceStatusesToExport,
        }),
        options: {
          linked_to_bank_transaction:
            configuration.exportInvoicesLinkedToBankTransaction,
          with_attachments: configuration.attachDocuments,
        },
        client_ids: configuration.clients?.map(({ id }) => id),
        tag_ids: configuration.tags?.map(({ id }) => id),
      },
    };
  }
}
