import { Action, createReducer, on } from '@ngrx/store';
import { cloneDeep } from 'lodash-es';

import { User } from '@models';

import * as UserActions from './user-actions';

export const initialState: User = null;

const userReducer = createReducer(
  initialState,
  on(UserActions.selectCompany, (state, { company }) => {
    const user = cloneDeep(state);
    user.activeCompany = company;
    user.activeCompanyId = company.id;
    return user;
  }),
  on(UserActions.update, (_state, { user }) => cloneDeep(user)),
  on(UserActions.updateSelectedCompanyLogo, (state, { logoId }) => {
    const user = cloneDeep(state);
    user.activeCompany.logoId = logoId;
    return user;
  }),
);

export function reducer(state: User | undefined, action: Action): User {
  return userReducer(state, action);
}
