import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'th[filter-header-cell]',
  templateUrl: './filter-header-cell.component.html',
  styleUrls: ['./filter-header-cell.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FilterHeaderCellComponent {
  @Input() filterActive = false;
}
