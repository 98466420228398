import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { PasswordToggleDirective } from './password-toggle.directive';

@NgModule({
  declarations: [PasswordToggleDirective],
  imports: [CommonModule],
  exports: [PasswordToggleDirective],
})
export class PasswordToggleModule {}
