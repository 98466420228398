import {
  AbstractControl,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';

interface AbstractLineFormModel {
  id: FormControl<number | null>;
  description: FormControl<string>;
  sequence: FormControl<number | null>;
}

export abstract class AbstractLineForm<
  T extends { [key: string]: AbstractControl },
> extends FormGroup<AbstractLineFormModel & T> {
  abstract isTextLine: boolean;

  get id(): AbstractLineFormModel['id'] {
    return this.controls.id as AbstractLineFormModel['id'];
  }

  get description(): AbstractLineFormModel['description'] {
    return this.controls.description as AbstractLineFormModel['description'];
  }

  get sequence(): AbstractLineFormModel['sequence'] {
    return this.controls.sequence as AbstractLineFormModel['sequence'];
  }

  constructor(controls?: T) {
    super({
      ...controls,
      id: new FormControl<number | null>(null),
      description: new FormControl<string>(null, { nonNullable: true }),
      sequence: new FormControl<number | null>(null),
    });
  }

  setInvoicingSavedStatusValidators(): void {
    this.description.setValidators(Validators.required);
    this.description.updateValueAndValidity({ emitEvent: false });
  }
}
