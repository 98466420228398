import { FormControl, FormGroup } from '@angular/forms';

import { User } from '@core/models';
import { CompanyRoleEnum } from '@enums/users';

export class UserForm extends FormGroup {
  get id(): FormControl<number> {
    return this.get('id') as FormControl;
  }
  get firstName(): FormControl<string> {
    return this.get('firstName') as FormControl;
  }
  get lastName(): FormControl<string> {
    return this.get('lastName') as FormControl;
  }
  get email(): FormControl<string> {
    return this.get('email') as FormControl;
  }
  get phone(): FormControl<string> {
    return this.get('phone') as FormControl;
  }

  get companyRole(): FormControl<CompanyRoleEnum> {
    return this.get('companyRole') as FormControl;
  }

  constructor() {
    super({
      id: new FormControl(),
      firstName: new FormControl(),
      lastName: new FormControl(),
      email: new FormControl(),
      phone: new FormControl(),
      companyRole: new FormControl(),
    });
  }

  fromUser(user: User): void {
    this.patchValue({ ...user }, { emitEvent: false });
  }

  toUser(): User {
    return new User(
      this.id.value,
      this.firstName.value,
      this.lastName.value,
      this.phone.value,
      this.email.value,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      this.companyRole.value,
    );
  }
}
