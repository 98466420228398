import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TiimeLoaderModule } from 'tiime-components';

import { AcronymModule } from '../acronym/acronym.module';
import { CompanyAcronymLogoComponent } from './company-acronym-logo.component';
import { LogoComponent } from './logo/logo.component';

@NgModule({
  declarations: [CompanyAcronymLogoComponent, LogoComponent],
  imports: [CommonModule, AcronymModule, TiimeLoaderModule],
  exports: [CompanyAcronymLogoComponent],
})
export class CompanyAcronymLogoModule {}
