import { NumberUtils } from '@manakincubber/tiime-utils';
import { PaginationData } from 'tiime-components';

import {
  DocumentOpeningMode,
  OcrStatus,
  StandardDocumentCategoryIdentifier,
} from '@enums';

import { Category, CategoryApiContract } from './category';
import { AmountMetadataValue, Metadata } from './documents';
import { ExpenseReportType } from './expenses-report';
import { LabelApiContract, Label } from './labels';
import {
  LinkedEntityBankTransaction,
  LinkedEntityExpenseReport,
  linkedEntityFromJson,
  LinkedEntityImputation,
  LinkedEntityPredicates,
  linkedEntityToJson,
} from './linked-entities';
import { LinkedEntityApiContract } from './linked-entities/api-contracts';
import { LinkedEntity } from './linked-entities/linked-entity';
import { Tag, TagApiContract } from './tag';
import { User, UserApiContract } from './user';

export interface DocumentApiContract {
  id: number;
  mime_type?: string;
  name?: string;
  created_at?: string;
  comment?: string;
  count_linked_entities?: number;
  metadata?: Metadata[];
  category?: CategoryApiContract;
  tags?: TagApiContract[];
  linked_entities?: LinkedEntityApiContract[];
  preview_only?: boolean;
  opening_mode?: DocumentOpeningMode;
  ocr_status?: OcrStatus;
  label?: LabelApiContract;
  type?: DocumentTypeEnum;
  owner?: UserApiContract;
  payment_status?: DocumentPaymentStatusEnum;
  parent?: ParentDocumentInformations;
  expense_type?: ExpenseReportType;
  is_updatable?: boolean;
  is_deletable?: boolean;
  file_family?: string;
  preview_available?: boolean;
}

export interface ParentDocumentInformations {
  id: number;
  name: string;
}

export enum DocumentTypeEnum {
  ExpenseReport = 'expense_report',
  Receipt = 'receipt',
  Document = 'document',
  AdvancedExpense = 'advanced_expense',
  ExternalInvoice = 'external_invoice',
}

export interface DocumentLike {
  id?: number;
  mimeType?: string;
  name?: string;
  createdAt?: string;
  comment?: string;
  metadata?: Metadata[];
  category?: Category;
  tags?: Tag[];
  linkedEntities?: LinkedEntity[];
  previewOnly?: boolean;
  openingMode?: DocumentOpeningMode;
  ocrStatus?: OcrStatus;
  label?: Label;
  type?: DocumentTypeEnum;
  owner?: User;
  paymentStatus?: DocumentPaymentStatusEnum;
  fileFamiy?: string;
  previewAvailable?: boolean;
}

export class Document implements DocumentLike {
  get hasBankTransactions(): boolean {
    return (
      this.getBankTransactions().length > 0 || this.getImputations()?.length > 0
    );
  }

  get hasLinkedExpenseReport(): boolean {
    return this.getExpenseReports().length > 0;
  }

  get hasPendingOcr(): boolean {
    return this.ocrStatus === OcrStatus.inProgress;
  }

  get hasManualOcrAndIsEmpty(): boolean {
    const amount = Document.getAmount(this);
    const vatAmount = Document.getVatAmount(this);
    return (
      this.ocrStatus === OcrStatus.manual &&
      !this.metadata.some(m => m.label === 'date' && m.value) &&
      !this.metadata.some(m => m.label === 'wording' && m.value) &&
      this.tags.length === 0 &&
      !NumberUtils.isValidNumber(amount) &&
      !NumberUtils.isValidNumber(vatAmount)
    );
  }

  constructor(
    public id?: number,
    public mimeType?: string,
    public name?: string,
    public createdAt?: string,
    public comment?: string,
    public metadata?: Metadata[],
    public category?: Category,
    public tags?: Tag[],
    public linkedEntities?: LinkedEntity[],
    public previewOnly?: boolean,
    public ocrStatus?: OcrStatus,
    public label?: Label,
    public openingMode?: DocumentOpeningMode,
    public type?: DocumentTypeEnum,
    public owner?: User,
    public paymentStatus?: DocumentPaymentStatusEnum,
    public parent?: ParentDocumentInformations,
    public expenseType?: ExpenseReportType,
    public isDeletable?: boolean,
    public isUpdatable?: boolean,
    public countLinkedEntities?: number,
    public fileFamily?: string,
    public previewAvailable?: boolean,
  ) {}

  static fromJson(json: DocumentApiContract): Document {
    return new Document(
      json.id,
      json.mime_type,
      json.name,
      json.created_at,
      json.comment,
      json.metadata,
      json.category ? Category.fromJson(json.category) : undefined,
      json.tags ? json.tags.map(tagJson => Tag.fromJson(tagJson)) : [],
      json.linked_entities
        ? json.linked_entities.map((entity: LinkedEntityApiContract) =>
            linkedEntityFromJson(entity),
          )
        : undefined,
      json.preview_only,
      json.ocr_status,
      json.label ? Label.fromJson(json.label) : undefined,
      json.opening_mode,
      json.type,
      json.owner ? User.fromJson(json.owner) : undefined,
      json.payment_status,
      json.parent,
      json.expense_type,
      json.category?.identifier ===
        StandardDocumentCategoryIdentifier.EXPENSE_REPORT &&
      json.expense_type === ExpenseReportType.Travel
        ? json.is_deletable
        : true,
      json.category?.identifier ===
        StandardDocumentCategoryIdentifier.EXPENSE_REPORT &&
      json.expense_type === ExpenseReportType.Travel
        ? json.is_updatable
        : true,
      json.count_linked_entities,
      json.file_family,
      json.preview_available,
    );
  }

  static toJson(document: Partial<Document>): Partial<DocumentApiContract>;
  static toJson(document: Document): DocumentApiContract;
  static toJson(
    document: Partial<Document> | Document,
  ): Partial<DocumentApiContract> | DocumentApiContract {
    return {
      id: document.id,
      mime_type: document.mimeType,
      name: document.name,
      created_at: document.createdAt,
      comment: document.comment,
      metadata: document.metadata,
      ...(document.category
        ? { category: Category.toJson(document.category) }
        : {}),
      tags: document.tags
        ? document.tags.map(tag => Tag.toJson(tag))
        : undefined,
      linked_entities: document.linkedEntities
        ? document.linkedEntities.map(linkedEntityToJson)
        : undefined,
      preview_only: document.previewOnly,
      ocr_status: document.ocrStatus,
      ...(document.label !== undefined
        ? {
            label: document.label ? Label.toJson(document.label) : null,
          }
        : {}),
      opening_mode: document.openingMode,
      type: document.type,
      owner: document.owner ? User.toJson(document.owner) : undefined,
      payment_status: document.paymentStatus,
      file_family: document.fileFamily,
      preview_available: document.previewAvailable,
    };
  }

  static getAmount(document: Document): undefined | number {
    const amountMetadata = document?.metadata?.find(
      metadata => metadata.key === 'amount',
    );
    return (amountMetadata?.value as AmountMetadataValue)?.value;
  }

  static getIban(document: Document): undefined | string {
    const ibanMetadata = document?.metadata?.find(
      metadata => metadata.key === 'iban',
    );
    return ibanMetadata?.value as string;
  }

  static getWording(document: Document): undefined | string {
    const wordingMetadata = document?.metadata?.find(
      metadata => metadata.key === 'wording',
    );
    return wordingMetadata?.value as string;
  }

  static getVatAmount(document: Document): number {
    const amountData = document.metadata?.find(
      metadata => metadata.key === 'vat_amount',
    );
    if (Array.isArray(amountData?.value)) {
      return (
        amountData.value?.find(m => m.key === 'total')
          ?.value as AmountMetadataValue
      )?.value;
    }
    return amountData?.value
      ? (amountData.value as AmountMetadataValue).value
      : null;
  }

  static metadataFromJson(
    json: DocumentsMetadataApiContract,
  ): DocumentsMetadata {
    return {
      totalAmount: json.total_amount,
      totalAmountExcludingTaxes: json.total_amount_excluding_taxes,
      hasExistingAdvancedExpenses: json.has_existing_advanced_expenses,
    };
  }

  getBankTransactions(): LinkedEntityBankTransaction[] {
    return this.linkedEntities?.filter(
      LinkedEntityPredicates.isBankTransaction,
    );
  }

  getExpenseReports(): LinkedEntityExpenseReport[] {
    return this.linkedEntities?.filter(LinkedEntityPredicates.isExpenseReport);
  }

  getImputations(): LinkedEntityImputation[] {
    return this.linkedEntities?.filter(LinkedEntityPredicates.isImputation);
  }
}

export interface DocumentsWithMetadataApiContract {
  documents: DocumentApiContract;
  metadata: DocumentsMetadataApiContract;
}
export interface DocumentsMetadataApiContract {
  total_amount?: number;
  total_amount_excluding_taxes?: number;
  has_existing_advanced_expenses?: boolean;
}
export interface DocumentsMetadata {
  totalAmount?: number;
  totalAmountExcludingTaxes?: number;
  hasExistingAdvancedExpenses?: boolean;
}

export enum DocumentPaymentStatusEnum {
  PENDING = 'pending',
  PAID = 'paid',
  SCHEDULED = 'scheduled',
}

export class DocumentsWithMetadata {
  constructor(
    public documents: PaginationData<Document>,
    public metadata: DocumentsMetadata,
  ) {}
}
