<tiime-dialog-close-button></tiime-dialog-close-button>
<img
  class="illu"
  ngSrc="assets/illus/password/illu_lock.png"
  width="92"
  height="92"
  alt="cadenas"
/>
<div mat-dialog-title>Modification de votre mot de passe</div>
<mat-dialog-content>
  <div class="hint">
    <div class="hint__left">
      <mat-icon svgIcon="icon-warning-filled"></mat-icon>
    </div>
    <div class="hint__right">
      <div class="hint__right--title">
        Le mot de passe doit contenir 12
        <br />
        caractères minimum
      </div>
      <div class="hint__right--subtitle">
        incluant au moins une minuscule, une
        <br />
        majuscule, un chiffre et un caractère
        <br />
        spécial
      </div>
    </div>
  </div>
  <form
    class="auth-form"
    [formGroup]="createPasswordForm"
    (ngSubmit)="submitCreatePasswordForm()"
  >
    <div class="auth-form-content">
      <tiime-input-container
        class="input-container"
        label="Mot de passe"
        [errorMessage]="createPasswordForm.passwordErrorMessage"
        [class.error]="
          createPasswordForm.password.touched &&
          createPasswordForm.password.invalid
        "
      >
        <input
          id="password"
          tiimeInputContainerNativeControl
          type="password"
          placeholder="mot de passe"
          formControlName="password"
          appPasswordToggle
        />
      </tiime-input-container>

      <tiime-input-container
        class="input-container"
        label="Confirmer le mot de passe"
        [class.error]="
          createPasswordForm.passwordConfirmation.touched &&
          createPasswordForm.invalid
        "
        [errorMessage]="createPasswordForm.passwordConfirmationErrorMessage"
      >
        <span
          *ngIf="
            createPasswordForm.passwordConfirmation.touched && formErrorMessage
          "
          class="error-label"
        >
          {{ formErrorMessage }}
        </span>
        <input
          id="password-confirm"
          tiimeInputContainerNativeControl
          type="password"
          placeholder="Confirmer le mot de passe"
          formControlName="passwordConfirmation"
          appPasswordToggle
        />
      </tiime-input-container>
    </div>
  </form>
  <mat-dialog-actions>
    <button type="submit" tiime-button action neutral inverted mat-dialog-close>
      Annuler
    </button>
    <button
      type="submit"
      tiime-button
      action
      accent
      [disabled]="createPasswordForm.invalid || (loading$ | async) === true"
      (click)="submitCreatePasswordForm()"
    >
      Valider
    </button>
  </mat-dialog-actions>
</mat-dialog-content>
