import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

import { LabelDisabledByEnum } from '@enums';

@Component({
  standalone: true,
  imports: [CommonModule],
  selector: 'app-label-archived-text',
  templateUrl: './label-archived-text.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LabelArchivedTextComponent {
  readonly LabelDisabledByEnum = LabelDisabledByEnum;

  @Input() labelDisabledBy: LabelDisabledByEnum;
}
