import {
  Component,
  Input,
  Output,
  EventEmitter,
  ViewEncapsulation,
} from '@angular/core';

import { EmptyStateConfig } from './empty-state-config';

@Component({
  selector: 'tiime-empty-state',
  templateUrl: './empty-state.component.html',
  styleUrls: ['./empty-state.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class TiimeEmptyStateComponent {
  @Input()
  config: EmptyStateConfig;

  @Output()
  public bottomAction = new EventEmitter<any>();

  action(): void {
    this.bottomAction.emit();
  }
}
