<ng-container *tiimeLet="config$ | async as config" [ngSwitch]="currentState">
  <ng-container *ngSwitchCase="TableState.done">
    <div
      class="documents-table-container"
      tiimeFilesDropZone
      multiple="true"
      #scrollContainer
      [class.documents-table-container--flat]="flat"
      [class.display-none]="editing"
      [class.hovered]="hovered"
      [acceptedTypes]="acceptedTypes"
      [maximumSize]="maximumSize"
      (hovered)="onHover($event)"
      (dropped)="onFilesDropped($event)"
    >
      <tiime-table-anchor></tiime-table-anchor>
      <table
        tiime-table
        matSort
        tiimeStickyTable
        aria-label="document-upload"
        [dataSource]="(documentsWithMetadata$ | async).documents.data"
        [trackBy]="trackById"
        [scrollContainer]="scrollContainer"
      >
        <ng-container cdkColumnDef="selection">
          <tiime-mass-action-header-cell
            *cdkHeaderCellDef
            id="document-selection"
            cdk-header-cell
            [indeterminate]="selectionModel.hasValue() && !areAllSelected"
            [areAllSelected]="areAllSelected"
            (selectAll)="onQuickSelectionSelectAll()"
            (trackingSelection)="emitQuickSelectionTrackingEvent($event)"
          ></tiime-mass-action-header-cell>
          <tiime-mass-action-cell
            *cdkCellDef="let document"
            cdk-cell
            [checked]="selectionModel.isSelected(document)"
            (selectRow)="selectionModel.toggle(document)"
            (click)="$event.stopPropagation()"
          ></tiime-mass-action-cell>
        </ng-container>
        <ng-container cdkColumnDef="date">
          <th
            *cdkHeaderCellDef
            id="document-date"
            cdk-header-cell
            mat-sort-header="metadata.date"
          >
            Date
          </th>
          <td *cdkCellDef="let document" cdk-cell>
            <app-documents-date-column-content
              [document]="document"
            ></app-documents-date-column-content>
          </td>
        </ng-container>
        <ng-container cdkColumnDef="name">
          <th *cdkHeaderCellDef id="col-name" cdk-header-cell>Nom</th>
          <td *cdkCellDef="let document" cdk-cell>
            <app-documents-name-column-content
              [document]="document"
              [category]="
                document
                  | map
                    : mapToCategory
                    : (selectedDocumentIds$ | async).has(document.id)
              "
              [showAutomaticTags]="config?.showAutomaticTags"
            ></app-documents-name-column-content>
          </td>
        </ng-container>

        <ng-container cdkColumnDef="pre-tax-amount">
          <th *cdkHeaderCellDef id="pre-tax-amount" cdk-header-cell>
            Montant HT
          </th>
          <td *cdkCellDef="let document" cdk-cell>
            <app-documents-amount-column-content
              [document]="document"
              [amountType]="AmountTypeEnum.PRE_TAX_AMOUNT"
            ></app-documents-amount-column-content>
          </td>
        </ng-container>
        <ng-container cdkColumnDef="amount-including-tax">
          <th
            *cdkHeaderCellDef
            id="amount"
            cdk-header-cell
            mat-sort-header="metadata.amount"
          >
            Montant TTC
          </th>
          <td *cdkCellDef="let document" cdk-cell>
            <app-documents-amount-column-content
              [document]="document"
              [amountType]="AmountTypeEnum.AMOUNT_INCLUDING_TAX"
            ></app-documents-amount-column-content>
          </td>
        </ng-container>
        <ng-container cdkColumnDef="label">
          <th *cdkHeaderCellDef id="label" cdk-header-cell>Label</th>
          <td *cdkCellDef="let document" cdk-cell>
            <app-label-chip
              *ngIf="document.label"
              [readonly]="true"
              [hideBlockedInformation]="true"
              [label]="document.label"
              [attr.data-cy]="'documents__document-' + document.id + '__label'"
            ></app-label-chip>
          </td>
        </ng-container>
        <ng-container cdkColumnDef="actions">
          <th *cdkHeaderCellDef id="col-actions" cdk-header-cell>
            {{ forMatching ? 'Action' : 'Actions' }}
          </th>
          <td
            *cdkCellDef="let document"
            class="actions-container"
            cdk-cell
            [class.disabled]="actionColumnDisabled$ | async"
            (click)="$event.stopPropagation()"
          >
            <app-documents-actions-column-content
              [document]="document"
              [kycValidated]="kycValidated$ | async"
              [companyAppConfig]="config"
              [forMatching]="!!forMatching"
              (downloadDocument)="downloadDocument(document.id)"
              (openLinkTransactionDialog)="openLinkTransactionDialog(document)"
              (detachLinkedBankTransaction)="
                detachLinkedBankTransaction(document)
              "
              (openBankTransferOverlay)="
                openBankTransferOverlay(document, $event)
              "
              (openDeleteDialog)="openDeleteDialog(document)"
              (tagsUpdated)="updateDocumentTags($event, document)"
            ></app-documents-actions-column-content>
          </td>
        </ng-container>

        <tr *cdkHeaderRowDef="displayedColumns" cdk-header-row></tr>
        <tr
          *cdkRowDef="let document; columns: displayedColumns"
          class="pointer"
          cdk-row
          [class.disabled]="document | map : mapToIsRowDisabled"
          [class.selected]="(selectedDocumentIds$ | async).has(document.id)"
          [attr.data-cy]="'documents__document-' + document.id"
          (click)="onRowClick(document)"
        ></tr>
      </table>

      <div *ngIf="hovered && !this.disableFileDrop" class="on-file-hover">
        <img
          class="hover-illustration"
          src="assets/illu_upload_documents_hover.svg"
          draggable="false"
          alt="Déposer un document"
        />
        <span>Déposer un document</span>
      </div>
    </div>
  </ng-container>
  <ng-container *ngSwitchCase="TableState.contentPlaceholder">
    <tiime-table-content-placeholder></tiime-table-content-placeholder>
  </ng-container>
  <ng-container *ngSwitchCase="TableState.noResult">
    <div class="no-result">
      <img src="assets/illu_no_result.svg" alt="" />
      <p>Aucun résultat...</p>
    </div>
  </ng-container>
</ng-container>
