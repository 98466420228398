export interface MandateApiContract {
  id?: number;
  created_at?: string;
  debtor_client_id?: number;
  status?: 'enabled' | 'disabled';
  rum?: string;
  debtor_name?: string;
  debtor_bank?: {
    id: number;
    name: string;
    brand: string;
    logo_url: string;
  };
  debtor_country?: {
    id: number;
    name: string;
    code: string;
  };
  debtor_iban?: string;
  debtor_bic?: string;
  debtor_address?: string;
  debtor_city?: string;
  debtor_zip_code?: string;
  wallet_company_id?: number;
  sequence?: 'once' | 'recurrent';
  ics?: string;
}

export class Mandate {
  constructor(
    public id?: number,
    public createdAt?: string,
    public debtorClientId?: number,
    public status?: 'enabled' | 'disabled',
    public rum?: string,
    public debtorName?: string,
    public debtorBank?: {
      id: number;
      name: string;
      brand: string;
      logoUrl: string;
    },
    public debtorCountry?: {
      id: number;
      name: string;
      code: string;
    },
    public debtorIban?: string,
    public debtorBic?: string,
    public debtorAddress?: string,
    public debtorCity?: string,
    public debtorZipCode?: string,
    public walletCompanyId?: number,
    public sequence?: 'once' | 'recurrent',
    public ics?: string,
  ) {}

  static fromJson(json: MandateApiContract): Mandate {
    return new Mandate(
      json.id,
      json.created_at,
      json.debtor_client_id,
      json.status,
      json.rum,
      json.debtor_name,
      json.debtor_bank
        ? {
            id: json.debtor_bank.id,
            name: json.debtor_bank.name,
            brand: json.debtor_bank.brand,
            logoUrl: json.debtor_bank.logo_url,
          }
        : undefined,
      json.debtor_country ?? undefined,
      json.debtor_iban,
      json.debtor_bic,
      json.debtor_address,
      json.debtor_city,
      json.debtor_zip_code,
      json.wallet_company_id,
      json.sequence,
      json.ics,
    );
  }
}
