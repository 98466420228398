import { Pipe, PipeTransform } from '@angular/core';

import { Tag } from '@models';

@Pipe({
  standalone: true,
  name: 'displayableTags',
})
export class DisplayableTagsPipe implements PipeTransform {
  transform(tags: Tag[], showAutomaticTags: boolean): Tag[] {
    return showAutomaticTags ? tags : tags.filter(tag => !tag.isAutomatic);
  }
}
