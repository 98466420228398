/* eslint-disable import/namespace */
import moment from 'moment';

export type DateInput = moment.MomentInput;

export class DateHelper {
  static add(
    date: DateInput,
    amount: moment.DurationInputArg1,
    unit: moment.unitOfTime.DurationConstructor,
  ): moment.Moment {
    return moment(date).add(amount, unit);
  }

  static daysInMonth(date: DateInput): number {
    return moment(date).daysInMonth();
  }

  static diff(
    date1: DateInput,
    date2: DateInput,
    unitOfTime?: moment.unitOfTime.DurationConstructor,
    precise?: boolean,
  ): number {
    return moment(date1).diff(date2, unitOfTime, precise);
  }

  static firstDayOfCurrentMonth(): moment.Moment {
    return moment().startOf('month');
  }

  static firstDayOfLastMonth(monthToSubstract = 1): moment.Moment {
    return moment().subtract(monthToSubstract, 'month').startOf('month');
  }

  static format(date: DateInput, format = 'YYYY-MM-DD'): string {
    return moment(date).format(format);
  }

  static lastDayOfCurrentMonth(): moment.Moment {
    return moment().endOf('month');
  }

  static lastDayOfLastMonth(): moment.Moment {
    return moment().subtract(1, 'month').endOf('month');
  }

  static isAfter(
    date1: DateInput,
    date2: DateInput,
    granularity?: moment.unitOfTime.StartOf,
  ): boolean {
    return moment(date1).isAfter(date2, granularity);
  }

  static isBefore(
    date1: DateInput,
    date2: DateInput,
    granularity?: moment.unitOfTime.StartOf,
  ): boolean {
    return moment(date1).isBefore(date2, granularity);
  }

  static isBetween(
    date1: DateInput,
    date2: DateInput,
    date3: DateInput,
    granularity?: moment.unitOfTime.StartOf,
  ): boolean {
    return moment(date1).isBetween(date2, date3, granularity, '[]');
  }

  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  static isMoment(date: unknown): boolean {
    return moment.isMoment(date);
  }

  static isSame(
    date1: DateInput,
    date2: DateInput,
    granularity?: moment.unitOfTime.StartOf,
  ): boolean {
    return moment(date1).isSame(date2, granularity);
  }

  static isSameOrAfter(
    date1: DateInput,
    date2: DateInput,
    granularity?: moment.unitOfTime.StartOf,
  ): boolean {
    return moment(date1).isSameOrAfter(date2, granularity);
  }

  static isSameOrBefore(
    date1: DateInput,
    date2: DateInput,
    granularity?: moment.unitOfTime.StartOf,
  ): boolean {
    return moment(date1).isSameOrBefore(date2, granularity);
  }

  static toDate(
    date: DateInput,
    format?: moment.MomentFormatSpecification,
  ): Date {
    return moment(date, format).toDate();
  }

  static updateLocale(
    language: string,
    localeSpec: moment.LocaleSpecification,
  ): void {
    moment.updateLocale(language, localeSpec);
  }

  static fromUtc(date: DateInput): Date {
    return moment.utc(date).toDate();
  }

  static firstDayOfMonth(date: DateInput): Date {
    return moment(date).startOf('month').toDate();
  }

  static lastDayOfMonth(date: DateInput): Date {
    return moment(date).endOf('month').toDate();
  }

  static lastDayOfQuarter(date: DateInput): Date {
    return moment(date).endOf('quarter').toDate();
  }

  static max(dates: DateInput[]): Date {
    return moment.max(dates.map(date => moment(date))).toDate();
  }

  static min(dates: DateInput[]): Date {
    return moment.min(dates.map(date => moment(date))).toDate();
  }

  static currentDate(): moment.Moment {
    return moment();
  }

  static startOf(date: DateInput, unitOfTime: moment.unitOfTime.StartOf): Date {
    return moment(date).startOf(unitOfTime).toDate();
  }
}
