import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { ApiAlertError } from '@decorators/api-alert-error';
import { Device } from '@models';

@Injectable({
  providedIn: 'root',
})
export class DevicesService {
  readonly resource =
    'api/v1/wallet/users/me/companies/{companyId}/enrolled_device';

  constructor(private readonly http: HttpClient) {}

  @ApiAlertError()
  get(): Observable<Device | Record<string, never>> {
    return this.http.get<Device | Record<string, never>>(this.resource);
  }
}
