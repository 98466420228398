import { Pipe, PipeTransform } from '@angular/core';

import { MetadataKey } from '@enums/documents';
import { AmountMetadata, MetadataDef } from '@models/documents';

import { ExpensesReportEditorStatus } from '../components/expenses-report-editor/expenses-report-editor.component';

@Pipe({
  standalone: true,
  name: 'getAmount',
})
export class GetAmountPipe implements PipeTransform {
  transform(
    currentEditorStatus: ExpensesReportEditorStatus,
    metadata: MetadataDef[],
    amountFromForm: number,
    isVATAmount = false,
  ): number {
    if (currentEditorStatus === ExpensesReportEditorStatus.Preview) {
      return (
        metadata.find(
          ({ key }) =>
            key === (isVATAmount ? 'vat_amount' : MetadataKey.Amount),
        ) as AmountMetadata
      )?.value?.value;
    }

    return amountFromForm;
  }
}
