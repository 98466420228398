import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { forkJoin, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { ApiAlertError } from '@decorators/api-alert-error';

import { Tag, TagApiContract } from '../models';

@Injectable({
  providedIn: 'root',
})
export class TagsService {
  constructor(private readonly http: HttpClient) {}

  getTagsAndTagSuggestions(): Observable<Tag[]> {
    return forkJoin({
      tags: this.getTags(),
      tagSuggestion: this.getNewTagSuggestions(),
    }).pipe(
      map(({ tags, tagSuggestion }) => [
        ...new Set([...tags, ...tagSuggestion]),
      ]),
    );
  }

  @ApiAlertError()
  getTags(): Observable<Tag[]> {
    return this.http
      .get<TagApiContract[]>('api/v1/companies/{companyId}/tags')
      .pipe(map(tagsJson => tagsJson.map(tagJson => Tag.fromJson(tagJson))));
  }

  /** Returns the tags that the user has used recently */
  @ApiAlertError()
  getRecentlyUsedTags(numberOfDataToDisplay = 3): Observable<Tag[]> {
    const url =
      'api/v1/companies/{companyId}/tag_suggestions?for=bank_transaction';

    return this.http
      .get<TagApiContract[]>(url)
      .pipe(
        map(tagsJson =>
          tagsJson
            .filter((_, index) => index < numberOfDataToDisplay)
            .map(tagJson => Tag.fromJson(tagJson)),
        ),
      );
  }

  /** Returns an array of new tags that could be usefull for the user */
  @ApiAlertError()
  getNewTagSuggestions(): Observable<Tag[]> {
    const url = 'api/v1/tag_suggestions';

    return this.http
      .get<TagApiContract[]>(url)
      .pipe(map(tagsJson => tagsJson.map(tagJson => Tag.fromJson(tagJson))));
  }
}
