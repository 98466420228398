import { Component, ChangeDetectionStrategy, Input } from '@angular/core';

@Component({
  selector: 'app-acronym',
  templateUrl: './acronym.component.html',
  styleUrls: ['./acronym.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AcronymComponent {
  @Input() slug: string;
  @Input() color: string;
}
