import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { TiimeLoaderModule } from '../loader';
import { ButtonLikeComponent } from './button-like.component';
import { ButtonComponent } from './button.component';

@NgModule({
  imports: [CommonModule, TiimeLoaderModule],
  declarations: [ButtonComponent, ButtonLikeComponent],
  exports: [ButtonComponent, ButtonLikeComponent],
})
export class TiimeButtonModule {}
