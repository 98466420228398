import { HttpClient } from '@angular/common/http';
import { Injectable, OnDestroy } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import moment from 'moment';
import {
  BehaviorSubject,
  defer,
  interval,
  Observable,
  Subject,
  throwError,
} from 'rxjs';
import {
  filter,
  map,
  mergeMap,
  startWith,
  switchMap,
  takeUntil,
  takeWhile,
  tap,
  withLatestFrom,
} from 'rxjs/operators';

import { ApiAlertError } from '@decorators/api-alert-error';
import { ScaValidationStatus } from '@enums';
import { ScaAction } from '@models';
import { MagicLinksService } from '@services';

import {
  StrongCustomerAuthenticationDialogComponent,
  StrongCustomerAuthenticationDialogData,
} from './strong-customer-authentication-dialog.component';

interface AuthorizeActionResponse {
  id: number | null;
  status: ScaValidationStatus;
  // Format: 2021-05-07 09:44:39
  date?: string;
  sca?: string;
}

export interface ScaDialogOptions {
  hasBackdrop?: boolean;
  displayValidationStep?: boolean;
}

export type ScaAuthenticateHeader = {
  'tiime-sca-request-id': string;
  'tiime-sca-request-code'?: string;
};
export type ScaDialogRef = MatDialogRef<
  StrongCustomerAuthenticationDialogComponent,
  Partial<AuthorizeActionResponse> | null | undefined
>;

@Injectable({
  providedIn: 'root',
})
export class StrongCustomerAuthenticationService implements OnDestroy {
  private resource = `api/v1/companies/{companyId}/sca_requests`;

  private readonly destroy$ = new Subject<void>();

  constructor(
    private readonly dialog: MatDialog,
    private readonly http: HttpClient,
    private readonly magicLinksService: MagicLinksService,
    private readonly router: Router,
  ) {}

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  authenticate(
    action: ScaAction,
    scaDialogOptions?: ScaDialogOptions,
    companyId: number | null = null,
    logoutIfFailed = false,
  ): Observable<ScaAuthenticateHeader> {
    if (this.magicLinksService.isLoggedInViaMagicLink()) {
      return throwError('Logged in via magic link');
    }
    return defer(() => {
      // Needed to keep the dialog up to date with the status fetched by polling the API.
      const validationStatus$ = new BehaviorSubject(
        ScaValidationStatus.Pending,
      );
      const date$ = new BehaviorSubject<Date | null>(null);
      const requestId$ = new BehaviorSubject<number | null>(null);
      const requestSca$ = new BehaviorSubject<string | null>(null);

      if (companyId !== null) {
        if (this.resource.includes('{companyId}')) {
          this.resource = this.replaceCompanyId(this.resource, companyId);
        }
      }

      return this.requestSca(action, companyId).pipe(
        map(({ id, sca, date }) => {
          const formattedDate = this.formatDate(date);

          requestId$.next(id);
          requestSca$.next(sca);
          date$.next(formattedDate);

          const dialogRef = this.openDialog(
            validationStatus$,
            date$,
            scaDialogOptions,
          );
          this.handleDialogClose(
            dialogRef,
            requestId$,
            validationStatus$,
            logoutIfFailed,
          );

          return [id, dialogRef] as const;
        }),
        switchMap(([id, dialogRef]) =>
          this.handleSendNotification(dialogRef, action, id).pipe(
            tap(res => {
              requestId$.next(res.id);
              requestSca$.next(res.sca);
              date$.next(this.formatDate(res.date));
            }),
            map(res => [res.id, res.sca, dialogRef] as const),
            // Needed to start the polling at the initial state.
            startWith([id, dialogRef] as const),
          ),
        ),
        switchMap(([id, dialogRef]) =>
          this.pollValidationStatus(id).pipe(
            tap(({ status }) => validationStatus$.next(status)),
            filter(({ status }) => status === ScaValidationStatus.Validated),
            map(({ status }) => [id, dialogRef, status] as const),
          ),
        ),
        map(([id]) => ({
          'tiime-sca-request-id': `${id}`,
          ...(requestSca$.value
            ? { 'tiime-sca-request-code': `${requestSca$.value}` }
            : {}),
        })),
      );
    });
  }

  private replaceCompanyId(url: string, companyId: number): string {
    return url.replace('{companyId}', String(companyId));
  }

  /**
   * Open the SCA dialog
   */
  private openDialog(
    validationStatus$: Observable<ScaValidationStatus>,
    limitDate$: Observable<Date>,
    scaDialogOptions?: ScaDialogOptions,
  ): ScaDialogRef {
    return this.dialog.open<
      StrongCustomerAuthenticationDialogComponent,
      StrongCustomerAuthenticationDialogData,
      unknown
    >(StrongCustomerAuthenticationDialogComponent, {
      data: {
        validationStatus$,
        limitDate$,
        scaDialogOptions,
      },
      width: '600px',
      ...scaDialogOptions,
    });
  }

  /**
   * Formats the date returned by the backend so it's ready to be consumed by the dialog.
   */
  private formatDate(date: string): Date {
    return moment.utc(date).toDate();
  }

  /**
   * Send a new notification when asked by the dialog and returns an Observable of the new validation request id.
   */
  private handleSendNotification(
    dialogRef: ScaDialogRef,
    action: ScaAction,
    validationRequestId: number,
  ): Observable<AuthorizeActionResponse> {
    return dialogRef.componentInstance.resendNotification.pipe(
      takeUntil(dialogRef.beforeClosed()),
      mergeMap(() => this.requestSca(action, validationRequestId)),
    );
  }

  /**
   * Cancel the validation request if the dialog is closed before the process is over.
   */
  private handleDialogClose(
    dialogRef: ScaDialogRef,
    validationRequestId$: Observable<number>,
    validationStatus$: Observable<ScaValidationStatus>,
    logoutIfFailed: boolean,
  ): void {
    dialogRef
      .afterClosed()
      .pipe(
        withLatestFrom(validationRequestId$, validationStatus$),
        tap(([, , validationStatus]) => {
          if (
            validationStatus !== ScaValidationStatus.Validated &&
            logoutIfFailed
          ) {
            void this.router.navigate(['signin']);
          }
        }),
        filter(
          ([, , validationStatus]) =>
            validationStatus === ScaValidationStatus.Pending,
        ),
        switchMap(([, validationRequestId]) =>
          this.cancelValidationRequest(validationRequestId),
        ),
      )
      .subscribe();
  }

  /**
   * Polls the validation status of the current validation request.
   */
  private pollValidationStatus(
    validationRequestId: number,
  ): Observable<AuthorizeActionResponse> {
    return interval(1000).pipe(
      mergeMap(() => this.getValidationStatus(validationRequestId), 1),
      takeWhile(({ status }) => status === ScaValidationStatus.Pending, true),
    );
  }

  @ApiAlertError()
  private requestSca(
    action: ScaAction,
    id?: number,
  ): Observable<AuthorizeActionResponse> {
    return this.http.post<AuthorizeActionResponse>(this.resource, {
      ...action.toJson(),
      id,
    });
  }

  @ApiAlertError()
  private getValidationStatus(id: number): Observable<AuthorizeActionResponse> {
    return this.http.get<AuthorizeActionResponse>(`${this.resource}/${id}`);
  }

  @ApiAlertError()
  private cancelValidationRequest(id: number): Observable<void> {
    return this.http.patch<void>(`${this.resource}/${id}`, {
      status: ScaValidationStatus.Cancelled,
    });
  }
}
