import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TiimePipesModule } from '@manakincubber/tiime-utils';
import {
  TiimeButtonModule,
  TiimeDateListCellModule,
  TiimeDialogModule,
  TiimeLetModule,
  TiimePaginatorModule,
  TiimeSearchBarModule,
  TiimeSortModule,
  TiimeTableModule,
  TiimeTooltipModule,
} from 'tiime-components';

import { IconsModule } from '@shared';

import { FileUploaderButtonModule } from '../../../../../../shared/components/file-uploader-button/file-uploader-button.module';
import { CompanySharedModule } from '../../../../../company-shared/company-shared.module';
import { LinkedItemsTooltipIconModule } from '../../../../../company-shared/components/tooltip-icons/linked-items-tooltip-icon/linked-items-tooltip-icon.module';
import { DocumentWordingPipe } from '../../../../../document/document-shared/pipes/document-wording.pipe';
import { DocumentsPipesModule } from '../../../../../document/document-shared/pipes/documents-pipes.module';
import { MatchBankTransferDialogComponent } from './match-bank-transfer-dialog.component';
import { ReceiptsToMatchTableComponent } from './receipts-to-match-table/receipts-to-match-table.component';

@NgModule({
  declarations: [
    MatchBankTransferDialogComponent,
    ReceiptsToMatchTableComponent,
  ],
  imports: [
    CommonModule,
    CompanySharedModule,
    FileUploaderButtonModule,
    LinkedItemsTooltipIconModule,
    IconsModule,
    DocumentWordingPipe,
    DocumentsPipesModule,
    // Tiime
    TiimeDateListCellModule,
    TiimeLetModule,
    TiimePaginatorModule,
    TiimeSearchBarModule,
    TiimeSortModule,
    TiimePipesModule,
    TiimeTableModule,
    TiimeDialogModule,
    TiimeButtonModule,
    TiimeTooltipModule,
  ],
  exports: [MatchBankTransferDialogComponent],
})
export class MatchBankTransferDialogModule {}
