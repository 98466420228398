<div
  class="drop-zone"
  tiimeFilesDropZone
  [ngClass]="{ hovered: hovered }"
  [multiple]="multiple"
  [acceptedTypes]="acceptedTypes"
  [maximumSize]="maximumSize"
  (dragOver)="onHover(true)"
  (dragLeave)="onHover(false)"
  (dropped)="onFileChanges($event)"
  (unauthorizedType)="onUnauthorizedType($event)"
  (incorrectSize)="onIncorrectSize($event)"
>
  <div *ngIf="!hovered" class="container">
    <ng-content select="[idleState]"></ng-content>
  </div>
  <div *ngIf="hovered" class="container">
    <ng-content select="[hoverState]"></ng-content>
  </div>
</div>
