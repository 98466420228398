import { FormControl, FormGroup, Validators } from '@angular/forms';

import { BankAccount, ClientListItem } from '@models';
import { Mandate } from '@models/wallet/payins';

export class CreateSddPayinInitiationForm extends FormGroup {
  get amount(): FormControl<number> {
    return this.get('amount') as FormControl<number>;
  }

  get client(): FormControl<ClientListItem> {
    return this.get('client') as FormControl<ClientListItem>;
  }

  get bankAccount(): FormControl<BankAccount> {
    return this.get('bankAccount') as FormControl<BankAccount>;
  }

  get mandate(): FormControl<Mandate> {
    return this.get('mandate') as FormControl<Mandate>;
  }

  constructor() {
    super({
      amount: new FormControl<number>(null, [
        Validators.required,
        Validators.min(1),
      ]),
      client: new FormControl<ClientListItem>(null, Validators.required),
      bankAccount: new FormControl<BankAccount>(null, Validators.required),
      mandate: new FormControl<Mandate>(null, Validators.required),
    });
  }
}
