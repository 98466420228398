import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { NgUtils } from '@manakincubber/tiime-utils';

import { TIIME_OVERLAY_DATA } from '../../../overlay';
import { ChipFilter } from '../../models/chip-filter';
import { TiimeFilterChipModule } from '../filter-chip/filter-chip.module';

export interface FilterListOverlayData {
  filters: ChipFilter[];
  removeFilter: (index: number) => void;
}

@Component({
  standalone: true,
  imports: [CommonModule, TiimeFilterChipModule],
  selector: 'tiime-filter-list-overlay',
  templateUrl: './filter-list-overlay.component.html',
  styleUrls: ['./filter-list-overlay.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FilterListOverlayComponent {
  readonly trackByIndex = NgUtils.trackByIndex;

  constructor(
    @Inject(TIIME_OVERLAY_DATA) readonly data: FilterListOverlayData,
  ) {}
}
