export enum BankTransactionStatusCode {
  unknown = 'unknwon',
  lost_card = 'lost_card',
  stolen_card = 'stolen_card',
  insufficient_funds = 'insufficient_funds',
  expired_card = 'expired_card',
  allowable_number_of_pin_tries_exceeded = 'allowable_number_of_pin_tries_exceeded',
  card_not_yet_activated = 'card_not_yet_activated',
  capture_card_capture = 'capture_card_capture',
  do_not_honor = 'do_not_honor',
  invalid_pin = 'invalid_pin',
  transaction_not_permitted_to_cardholder = 'transaction_not_permitted_to_cardholder',
  transaction_not_permitted_to_acquirer_terminal = 'transaction_not_permitted_to_acquirer_terminal',
  exceeds_withdrawal_amout_limit = 'exceeds_withdrawal_amout_limit',
  card_destroyed = 'card_destroyed',
}
