import { Pipe, PipeTransform } from '@angular/core';

import { INVOICE_LABEL, QUOTE_LABEL } from '@constants';
import { InvoiceSchedule } from '@core/models/invoice-schedule';
import { InvoiceStatus, InvoiceType, QuoteStatus } from '@enums';

@Pipe({ name: 'invoiceStatusLabel' })
export class InvoiceStatusLabelPipe implements PipeTransform {
  transform(
    input: InvoiceStatus | InvoiceStatus[] | QuoteStatus,
    type?: InvoiceType,
    schedule?: InvoiceSchedule,
    forQuote?: boolean,
  ): string | string[] {
    if (Array.isArray(input)) {
      return input.map((status: InvoiceStatus) =>
        this.toLabel(status, type, forQuote),
      );
    }
    if (schedule && input === InvoiceStatus.draft) {
      return 'A valider';
    }

    return this.toLabel(input, type, forQuote);
  }

  private toLabel(
    status: InvoiceStatus | QuoteStatus | null,
    type: InvoiceType,
    forQuote?: boolean,
  ): string {
    if (forQuote) {
      return QUOTE_LABEL.status[(status as QuoteStatus) || 'draft'];
    }
    return INVOICE_LABEL[type].status[(status as InvoiceStatus) || 'draft'];
  }
}
