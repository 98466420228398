import { PaymentMethod } from '@enums';

export type CreatePaymentMandateContract = {
  provider: PaymentMethod;
  identifier?: string;
  email?: string;
  client_id: number;
};

export interface GoCardlessPaymentMandateContract {
  id: number;
  provider: PaymentMethod;
  identifier: string;
  created_at: string;
  reference: string;
  status: GoCardlessPaymentMandateStatus;
  scheme: string;
  customerIdentifier: string;
  creditorIdentifier: string;
  bankAccountIdentifier: string;
  company: { id: number };
  client: { id: number };
  urlExpiredAt: Date;
  email: string;
  billingRequestUrl: string;
  bank_name: string;
}

export enum GoCardlessPaymentMandateStatus {
  Delayed = 'delayed',
  PendingCustomerApproval = 'pending_customer_approval',
  PendingSubmission = 'pending_submission',
  Submitted = 'submitted',
  Confirmed = 'confirmed',
  PaidOut = 'paid_out',
  Active = 'active',
  SuspendedByPayer = 'suspended_by_payer',
  Failed = 'failed',
  Cancelled = 'cancelled',
  Expired = 'expired',
  Consumed = 'consumed',
  Blocked = 'blocked',
}

export enum LinxoPaymentMandateStatus {
  Initiated = 'new',
  Submitted = 'submitted',
  Authorized = 'authorized',
  Expired = 'expired',
  Failed = 'failed',
  Rejected = 'rejected',
  Cancelled = 'cancelled',
  Closed = 'closed',
  Executed = 'executed',
}
