<div class="comment-tooltip-icon">
  <mat-icon
    class="tooltip-icon comment-icon"
    fontIcon="icon-commentaire"
    tiimeTooltip
    [content]="commentTooltipTemplate"
    [tooltipPosition]="tooltipPosition"
    [tooltipInvertedPosition]="tooltipInvertedPosition"
    [tooltipDisabled]="!comment"
  ></mat-icon>
  <ng-template #commentTooltipTemplate>
    <div class="comment-tooltip">
      <span class="comment-tooltip-title">Commentaire :</span>
      <span class="comment-tooltip-content">{{ comment }}</span>
    </div>
  </ng-template>
</div>
