import { Pipe, PipeTransform } from '@angular/core';

import { BankTransferRecurrency } from '@enums';
import { bankTransferFrequencyOptions } from '@forms';

@Pipe({
  name: 'frequencyLabel',
})
export class FrequencyLabelPipe implements PipeTransform {
  transform(currentValue = BankTransferRecurrency.Once): unknown {
    return bankTransferFrequencyOptions.find(
      ({ value }) => value === currentValue,
    )?.label;
  }
}
