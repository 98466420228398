import { Pipe, PipeTransform } from '@angular/core';

import { AdvancedExpense } from '@models';

@Pipe({
  standalone: true,
  name: 'computeAdvancedExpensesAmount',
})
export class ComputeAdvancedExpensesAmountPipe implements PipeTransform {
  transform(
    advancedExpensesFormValues: {
      checked: boolean;
      line: AdvancedExpense;
    }[],
  ): { vatAmount: number; amount: number } {
    const amounts = { vatAmount: 0, amount: 0 };

    advancedExpensesFormValues
      .filter(advancedExpenseFormValue => advancedExpenseFormValue.checked)
      .forEach(({ line }) => {
        amounts.vatAmount += line.vatAmount || 0;
        amounts.amount += line.amount || 0;
      });

    return amounts;
  }
}
