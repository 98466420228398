import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { ScaValidation } from '@core/decorators/sca-validation';
import { ApiAlertError } from '@decorators/api-alert-error';
import { CardAction } from '@enums';
import {
  Card,
  CardApiContract,
  CardDeliveryAddress,
  ScaUnblockPinCardAction,
  ScaUpdatePinCardAction,
  UpdateCardPinBody,
  WalletCardActivation,
} from '@models';

import { ScaAuthenticateHeader } from '../auth/strong-customer-authentication/strong-customer-authentication.service';

@Injectable({
  providedIn: 'root',
})
export class CardsService {
  private readonly resourceUri = (userId: number): string =>
    `api/v1/wallet/companies/{companyId}/users/${userId}/cards`;

  constructor(private readonly http: HttpClient) {}

  @ApiAlertError()
  getCards(userId: number): Observable<Card[]> {
    return this.http
      .get(`${this.resourceUri(userId)}?is_destroyed=false`)
      .pipe(
        map((json: CardApiContract[]) => json.map(card => Card.fromJson(card))),
      );
  }

  @ApiAlertError()
  create(
    pinCode: string,
    address: CardDeliveryAddress,
    userId: number,
  ): Observable<unknown> {
    return this.http
      .post(`${this.resourceUri(userId)}`, {
        new_pin: pinCode,
        delivery_address: CardDeliveryAddress.toJson(address),
      })
      .pipe(map((cardJson: CardApiContract) => Card.fromJson(cardJson)));
  }

  @ApiAlertError()
  activate(
    walletCardActivation: WalletCardActivation,
    userId: number,
  ): Observable<Card> {
    return this.http.patch(
      `${this.resourceUri(userId)}/${walletCardActivation.id}/validate_token`,
      WalletCardActivation.toJson(walletCardActivation),
    );
  }

  @ScaValidation(new ScaUpdatePinCardAction())
  @ApiAlertError()
  updatePinCode(
    { id }: Card,
    updateCardPinBody: UpdateCardPinBody,
    userId: number,
    authenticationSCAHeader?: ScaAuthenticateHeader,
  ): Observable<Card> {
    return this.http
      .patch<CardApiContract>(
        `${this.resourceUri(userId)}/${id}/pin`,
        updateCardPinBody,
        { headers: authenticationSCAHeader },
      )
      .pipe(map((cardJson: CardApiContract) => Card.fromJson(cardJson)));
  }

  @ScaValidation(new ScaUnblockPinCardAction())
  @ApiAlertError()
  restorePin(
    { id }: Card,
    userId: number,
    authenticationSCAHeader?: ScaAuthenticateHeader,
  ): Observable<Card> {
    return this.http
      .patch<CardApiContract>(
        `${this.resourceUri(userId)}/${id}/unblockpin`,
        null,
        { headers: authenticationSCAHeader },
      )
      .pipe(map((cardJson: CardApiContract) => Card.fromJson(cardJson)));
  }

  @ApiAlertError()
  updateOptions(card: Card, userId: number): Observable<Card> {
    return this.http
      .patch<CardApiContract>(
        `${this.resourceUri(userId)}/${card.id}/options`,
        Card.toJson(card),
      )
      .pipe(map((cardJson: CardApiContract) => Card.fromJson(cardJson)));
  }

  @ApiAlertError()
  updateLimits(
    { id }: Card,
    limitPaymentWeek: number,
    userId: number,
  ): Observable<Card> {
    return this.http
      .patch<CardApiContract>(`${this.resourceUri(userId)}/${id}/limits`, {
        limit_payment_week: limitPaymentWeek,
      })
      .pipe(map((cardJson: CardApiContract) => Card.fromJson(cardJson)));
  }

  @ApiAlertError()
  updateState(
    cardId: number,
    cardAction: CardAction,
    userId: number,
  ): Observable<Card> {
    const body = {
      status: cardAction,
    };
    return this.http
      .patch<CardApiContract>(
        `${this.resourceUri(userId)}/${cardId}/status`,
        body,
      )
      .pipe(map((cardJson: CardApiContract) => Card.fromJson(cardJson)));
  }
}
