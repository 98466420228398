import { BooleanInput, coerceBooleanProperty } from '@angular/cdk/coercion';
import {
  ChangeDetectionStrategy,
  Component,
  HostBinding,
  Input,
} from '@angular/core';
import { Mapper } from '@manakincubber/tiime-utils';

import { INVOICE_LABEL } from '@constants';
import { InvoiceType, InvoiceStatus } from '@enums';

@Component({
  selector: 'app-invoice-number',
  templateUrl: './invoice-number.component.html',
  styleUrls: ['./invoice-number.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InvoiceNumberComponent {
  @Input() iconSize: number;
  @Input() invoiceNumber: number;
  @Input() invoiceType: InvoiceType | 'quotation' | 'external_invoice';
  @Input() invoiceStatus: InvoiceStatus;

  _alternativeStyle: boolean;
  @Input() set alternativeStyle(value: BooleanInput) {
    this._alternativeStyle = coerceBooleanProperty(value);
  }

  @HostBinding('class.alternative-style') get classAlternativeStyle(): boolean {
    return this._alternativeStyle;
  }

  private readonly otherLabels: Record<string, string> = {
    quotation: 'Devis',
    external_invoice: 'Facture externe',
  };

  readonly mapToInvoiceLabel: Mapper<InvoiceType | 'quotation', string> = (
    invoiceType: InvoiceType | 'quotation',
  ) => this.otherLabels[invoiceType] || INVOICE_LABEL[invoiceType].label;
}
