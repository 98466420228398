import { FormControl, FormGroup, Validators } from '@angular/forms';
import moment from 'moment';

import { MomentHelper } from '@core/helpers';
import { SepaPayment } from '@core/models';

export const LIMIT_SEPA_PAYMENT_AMOUNT = 24999;

export class SepaPaymentForm extends FormGroup {
  get id(): FormControl {
    return this.get('id') as FormControl;
  }

  get beneficiaryName(): FormControl {
    return this.get('beneficiaryName') as FormControl;
  }
  get beneficiaryIban(): FormControl {
    return this.get('beneficiaryIban') as FormControl;
  }

  get amount(): FormControl {
    return this.get('amount') as FormControl;
  }

  get executionDate(): FormControl {
    return this.get('executionDate') as FormControl;
  }

  constructor(readonly sepaPayment: SepaPayment) {
    super({
      id: new FormControl(sepaPayment.id),
      beneficiaryName: new FormControl(sepaPayment.beneficiaryName),
      beneficiaryIban: new FormControl(sepaPayment.beneficiaryIban),
      amount: new FormControl(
        sepaPayment.amount,
        Validators.max(LIMIT_SEPA_PAYMENT_AMOUNT),
      ),
      executionDate: new FormControl(moment(sepaPayment?.executionDate)),
    });
  }

  toSepaPayment(): SepaPayment {
    return {
      ...this.value,
      executionDate: MomentHelper.toCalendarDateString(
        this.executionDate.value as moment.Moment,
      ),
    } as SepaPayment;
  }
}
