import { Injectable } from '@angular/core';
import { Router, UrlTree } from '@angular/router';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { selectedCompanySelector } from '@store/user';

@Injectable({
  providedIn: 'root',
})
export class TiimeBusinessGuard {
  constructor(private readonly router: Router, private readonly store: Store) {}

  canActivate(): Observable<boolean | UrlTree> {
    return this.store.select(selectedCompanySelector).pipe(
      map(({ walletAccess, id }) => {
        if (walletAccess) {
          return true;
        } else {
          return this.router.createUrlTree(['companies', id, 'account']);
        }
      }),
    );
  }
}
