import { HttpClient, HttpParams, HttpResponse } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { map } from 'rxjs/operators';
import {
  GetOptions,
  PaginationData,
  RequiredGetOptions,
} from 'tiime-components';

import { TemporaryEncoder } from '@core/temporary-encoder';
import { ApiAlertError } from '@decorators/api-alert-error';
import { HttpHelper } from '@helpers';
import {
  CreateMandatePayload,
  Mandate,
  MandateApiContract,
  SddPayin,
} from '@models/wallet/payins';
import { HttpFileService } from '@services/http-file.service';

@Injectable({
  providedIn: 'root',
})
export class MandatesService {
  private readonly resource = 'api/v1/wallet/companies/{companyId}/mandates';
  private readonly http = inject(HttpClient);
  private readonly httpFileService = inject(HttpFileService);

  private readonly _reloadMandatesList$ = new Subject<void>();

  get reloadMandatesList$(): Observable<void> {
    return this._reloadMandatesList$.asObservable();
  }

  reloadMandatesList(): void {
    this._reloadMandatesList$.next();
  }

  @ApiAlertError()
  generateMandate(
    mandate: CreateMandatePayload,
    clientId: number,
  ): Observable<Mandate> {
    return this.http
      .post<MandateApiContract>(
        `api/v1/wallet/companies/{companyId}/client/${clientId}/mandates`,
        CreateMandatePayload.toJson(mandate),
      )
      .pipe(map(mandateApiContract => Mandate.fromJson(mandateApiContract)));
  }

  @ApiAlertError()
  getMandates(
    getOptions: RequiredGetOptions<'range'>,
  ): Observable<PaginationData<Mandate>> {
    const partialOptions = new GetOptions(getOptions).toHttpGetOptions();
    const options = {
      params: new HttpParams({
        fromObject: partialOptions.params,
        encoder: new TemporaryEncoder(),
      }),
      headers: partialOptions.headers,
    };

    return this.http
      .get(this.resource, { ...options, observe: 'response' })
      .pipe(
        HttpHelper.mapToPaginationData(
          getOptions.range,
          (mandatesJson: MandateApiContract) => Mandate.fromJson(mandatesJson),
        ),
      );
  }

  @ApiAlertError()
  getMandate(mandateId: number): Observable<Mandate> {
    return this.http
      .get(`${this.resource}/${mandateId}`)
      .pipe(
        map((mandatesJson: MandateApiContract) =>
          Mandate.fromJson(mandatesJson),
        ),
      );
  }

  @ApiAlertError()
  revokeMandate(mandateId: number): Observable<Mandate> {
    return this.http
      .put(`${this.resource}/${mandateId}/disable`, {
        id: mandateId,
      })
      .pipe(
        map((mandatesJson: MandateApiContract) =>
          Mandate.fromJson(mandatesJson),
        ),
      );
  }

  @ApiAlertError()
  createSDDPayin(
    mandateId: number,
    sddPayin: Partial<SddPayin>,
  ): Observable<void> {
    return this.http.post<void>(
      `${this.resource}/${mandateId}/sdd_payin`,
      SddPayin.toJson(sddPayin),
    );
  }

  @ApiAlertError()
  getPdf(mandateId: number): Observable<HttpResponse<Blob>> {
    return this.httpFileService.download(`${this.resource}/${mandateId}/pdf`, {
      headers: { 'Content-Type': 'application/pdf' },
    });
  }
}
