import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import { FormControl } from '@angular/forms';

import { ImportDialogConfig } from './import-dialog-config';

@Component({
  selector: 'app-import-dialog-container',
  templateUrl: './import-dialog-container.component.html',
  styleUrls: ['./import-dialog-container.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ImportDialogContainerComponent {
  @Input() config!: ImportDialogConfig;
  @Input() errorList = '';
  @Input() loading = false;
  @Input() progress = 0;

  @Output() readonly modelRequested = new EventEmitter<void>();
  @Output() readonly uploadRequested = new EventEmitter<File>();

  importedListFile: File;
  readonly fileControl = new FormControl();

  onFileSelected(files: FileList): void {
    if (files.length === 0) {
      return;
    }
    this.importedListFile = files.item(0);
  }

  removeFile(): void {
    this.importedListFile = undefined;
    this.fileControl.reset();
  }

  removeError(): void {
    this.errorList = '';
    this.importedListFile = undefined;
  }
}
