import { Injectable } from '@angular/core';
import { iif, Observable, of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';

import { Card, WalletAccount } from '@models';

import { HomeWallet } from '../models/home-wallet';
import { CardsService } from './cards.service';
import { TilesService } from './tiles.service';

@Injectable({
  providedIn: 'root',
})
export class WalletAccountService {
  constructor(
    private readonly cardsService: CardsService,
    private readonly tilesService: TilesService,
  ) {}

  getWalletAccount(
    companyId: number,
    userId: number,
  ): Observable<WalletAccount> {
    return this.tilesService.getHomeWallet().pipe(
      switchMap(homeWallet =>
        iif(
          () =>
            !homeWallet.orderCard ||
            (homeWallet.orderCard &&
              homeWallet.cardInUse &&
              homeWallet.cardInUse?.willExpireSoon),
          this.cardsService.getCards(userId).pipe(catchError(() => of(null))),
          of(null),
        ).pipe(
          map(cards => [homeWallet, cards] as [HomeWallet, Card[] | null]),
        ),
      ),
      map(([homeWallet, cards]) => {
        const {
          fatcaPending,
          transferActivated,
          orderCard,
          trackCardSteps,
          trackWalletStep,
          useCard,
          firstTransfer,
        } = homeWallet;

        return {
          companyId,
          id: homeWallet.bankAccountWallet?.id,
          bic: homeWallet.bankAccountWallet?.bic,
          closed: homeWallet.bankAccountWallet?.closed,
          iban: homeWallet.bankAccountWallet?.iban,
          fatcaPending,
          transferActivated,
          orderCard,
          useCard,
          trackCardSteps,
          trackWalletStep,
          cards: cards ? cards : undefined,
          cardInUse: homeWallet.cardInUse,
          companyCardsListActivated: homeWallet.companyCardsListActivated,
          firstTransfer,
        } as WalletAccount;
      }),
    );
  }
}
