import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TiimePipesModule } from '@manakincubber/tiime-utils';
import { TiimeIconModule, TiimeTwocolorsIconModule } from 'tiime-components';

import { OffuscatedIbanPipe } from '../../../../../company-shared/pipes/offuscated-iban.pipe';
import { TrustBeneficiaryComponent } from '../../../beneficiaries/trust-beneficiary/trust-beneficiary.component';
import { BankTransferPipesModule } from '../../pipes/bank-transfer-pipes.module';
import { PayoutFrequencyRecapModule } from '../payout-frequency-recap/payout-frequency-recap.module';
import { BankTransferRecapComponent } from './bank-transfer-recap.component';

@NgModule({
  declarations: [BankTransferRecapComponent],
  imports: [
    CommonModule,
    PayoutFrequencyRecapModule,
    BankTransferPipesModule,
    TiimeIconModule,
    TrustBeneficiaryComponent,
    OffuscatedIbanPipe,
    TiimePipesModule,
    TiimeTwocolorsIconModule,
  ],
  exports: [BankTransferRecapComponent],
})
export class BankTransferRecapModule {}
