import { Types } from '@amplitude/analytics-browser';
import { sessionReplayPlugin } from '@amplitude/plugin-session-replay-browser';
import {
  APP_INITIALIZER,
  inject,
  InjectionToken,
  NgModule,
} from '@angular/core';
import { TiimeAuthService } from '@manakincubber/tiime-auth';

import { GTM_DATA_LAYER, WINDOW } from '@core/tokens';
import { AmplitudeOptions } from '@environments';
import { AppConfigService } from '@services';

import packageInfo from '../../../../../../package.json';
import { GroupsPatchPlugin, GtmPlugin } from './plugins';
import { AMPLI } from './tokens';
import { Ampli } from './wrapper';

const AMPLITUDE_PLUGINS = new InjectionToken<Types.Plugin[]>(
  'Amplitude Plugins',
);

@NgModule({
  providers: [
    {
      provide: AMPLITUDE_PLUGINS,
      useFactory: (): Types.Plugin[] => {
        const groupsPatchPlugin = inject(GroupsPatchPlugin);
        const isGtmActive = !!inject(GTM_DATA_LAYER);
        const sessionReplayTraking = sessionReplayPlugin({
          forceSessionTracking: false,
          sampleRate: 0.1,
        });

        return isGtmActive
          ? [groupsPatchPlugin, sessionReplayTraking, inject(GtmPlugin)]
          : [groupsPatchPlugin, sessionReplayTraking];
      },
    },
    {
      provide: APP_INITIALIZER,
      multi: true,
      useFactory: (): (() => Promise<void>) => {
        const appConfigService = inject(AppConfigService);
        const ampli = inject(AMPLI);
        const plugins = inject(AMPLITUDE_PLUGINS);
        const authService = inject(TiimeAuthService);
        const windowRef = inject(WINDOW);

        return appConfigService.appConfigFactory<void>(() => {
          if (!appConfigService.appConfig.TRACKING.ENABLED) {
            return;
          }

          initializeAmplitude(
            ampli,
            plugins,
            authService,
            windowRef.location.hostname,
            appConfigService.appConfig.TRACKING.AMPLITUDE_OPTIONS,
          );
        });
      },
    },
  ],
})
export class AmplitudeModule {}

function initializeAmplitude(
  ampli: Ampli,
  plugins: Types.Plugin[],
  authService: TiimeAuthService,
  hostname: string,
  amplitudeOptions: AmplitudeOptions,
): void {
  const clientConfig: Types.BrowserOptions = {
    appVersion: packageInfo.version,
    serverZone: 'EU',
    defaultTracking: {
      attribution: { excludeReferrers: [hostname] },
      fileDownloads: false,
      pageViews: false,
      sessions: false,
      formInteractions: false,
    },
    ...amplitudeOptions.configuration,
  };

  ampli.load({
    environment: amplitudeOptions.environment,
    client: { configuration: clientConfig },
  });

  plugins.forEach(plugin => ampli.client.add(plugin));

  if (authService.hasExpiredAuthentication()) {
    ampli.client.reset();
  }
}
