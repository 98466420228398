import { inject } from '@angular/core';
import { Router, UrlTree } from '@angular/router';
import { TiimeAuthService } from '@manakincubber/tiime-auth';

import { PasswordStatusEnum, tokenInfos } from '../auth/token-infos';

export function passwordCreatedGuard(): boolean | UrlTree {
  const authService = inject(TiimeAuthService);
  const router = inject(Router);

  const tokenInfos: tokenInfos = authService.tokenInfos as tokenInfos;
  const mustRenewPassword = tokenInfos?.['tiime/mustRenewPassword'];

  if (mustRenewPassword === PasswordStatusEnum.PasswordPolicy) {
    return router.createUrlTree(['renewpassword'], {
      queryParamsHandling: 'preserve',
    });
  } else if (mustRenewPassword === PasswordStatusEnum.Onboarding) {
    return router.createUrlTree(['createpassword'], {
      queryParamsHandling: 'preserve',
    });
  }

  return true;
}
