import { Pipe, PipeTransform } from '@angular/core';

import { ExpenseReportType } from '@models';

@Pipe({
  standalone: true,
  name: 'expenseReportEditorEmptyStateIllus',
})
export class ExpenseReportEditorEmptyStateIllusPipe implements PipeTransform {
  transform(expenseType: ExpenseReportType): string {
    const basePath = 'assets/illus/expenses/expense-report';

    if (expenseType === ExpenseReportType.AdvancedExpense) {
      return `${basePath}/expenses-report-match.svg`;
    }

    return `${basePath}/illu_empty_travel.svg`;
  }
}
