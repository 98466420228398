import { coerceBooleanProperty } from '@angular/cdk/coercion';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  forwardRef,
  HostBinding,
  HostListener,
  Input,
  ViewChild,
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { MAT_DATE_FORMATS } from '@angular/material/core';
import {
  DateFilterFn,
  MatDatepicker,
  MatDatepickerInputEvent,
} from '@angular/material/datepicker';
import moment from 'moment';
import {
  DATEPICKER_DEFAULT_FORMATS,
  DatepickerHeaderComponent,
  InputContainerControl,
} from 'tiime-components';

import { TIIME_ICON_PREFIX } from '@shared';

let nextUniqueId = 0;

@Component({
  selector: 'app-datepicker',
  templateUrl: './datepicker.component.html',
  styleUrls: ['./datepicker.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: InputContainerControl,
      useExisting: DatepickerComponent,
    },
    { provide: MAT_DATE_FORMATS, useValue: DATEPICKER_DEFAULT_FORMATS },
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => DatepickerComponent),
      multi: true,
    },
  ],
})
export class DatepickerComponent
  implements ControlValueAccessor, InputContainerControl<moment.Moment>
{
  tiimeDatepickerHeader = DatepickerHeaderComponent;
  readonly TIIME_ICON_PREFIX = TIIME_ICON_PREFIX;
  value: moment.Moment;

  private _uid = `app-datepicker-${nextUniqueId++}`;
  private _id: string;

  @ViewChild(MatDatepicker) matDatepicker: MatDatepicker<unknown>;
  @HostBinding('class') class = 'tiime-datepicker-input-wrapper';
  @HostBinding('class.empty') empty: boolean;
  @HostBinding('class.active') active: boolean;
  @HostBinding('class.small') _small: boolean;
  @HostBinding('class.xsmall') _xsmall: boolean;
  @HostBinding('class.disabled') disabled: boolean;

  @Input() max: Date | moment.Moment;
  @Input() min: Date | moment.Moment;
  @Input() datepickerFilterFn: DateFilterFn<moment.Moment> = () => true;

  @Input() set small(value: boolean) {
    this._small = coerceBooleanProperty(value);
  }

  get small(): boolean {
    return this._small;
  }

  @Input() set xsmall(value: boolean) {
    this._xsmall = coerceBooleanProperty(value);
  }

  get xsmall(): boolean {
    return this._xsmall;
  }

  @Input()
  get id(): string {
    return this._id;
  }

  set id(value: string) {
    this._id = value || this._uid;
  }

  @HostListener('click') onClick(): void {
    if (!this.disabled && !this.active) {
      this.active = true;
      this.matDatepicker.open();
    }
  }

  constructor(private readonly cdr: ChangeDetectorRef) {
    // Force setter to be called in case id was not specified.
    // eslint-disable-next-line no-self-assign
    this.id = this.id;
  }

  onMatDatepickerClosed(): void {
    this.active = false;
    this.onTouched();
  }

  onChange: (_value: moment.Moment) => void = () => {
    // Will be overridden by registerOnChange(...)
  };
  onTouched: () => void = () => {
    // Will be overridden by registerOnTouched(...)
  };

  registerOnChange(fn: (value: moment.Moment) => void): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  setValue(event: MatDatepickerInputEvent<moment.Moment>): void {
    this.writeValue(event.value);
  }

  writeValue(value: moment.Moment): void {
    this.value = value;
    this.empty = !this.value;
    this.onChange(value);
    // Required to update input value when it's programmatically changed
    this.cdr.markForCheck();
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
    this.cdr.markForCheck();
  }
}
