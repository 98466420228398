export const SENTRY_IGNORED_ERRORS: (string | RegExp)[] = [
  'TypeError: Failed to fetch', // gtm blocked by ad-blocker
  "Can't find variable: Attrilab", // blocked by ad-blocker
  'Handled unknown error', // triggered when authentication failed (wrong password or email)
  /^Non-Error exception captured with keys:(.*)/, // triggered when authentication failed (429 TOO MANY REQUESTS)
  /(.*)Loading chunk(.*)/, // triggered after a release, but error resolves itself
  /(.*)401 OK(.*)/, // triggered when session_token is expired.
  /(.*)401 Unauthorized(.*)/, // triggered when session_token is expired.
  /(.*)magic_links(.*)/, // triggered when user is clicking multiple times on login button. endpoint is disabled backend unless auth0 is failing
  /(.*)Bad request(.*)/, // user generated errors
  /(.*)400 OK(.*)/, // user generated errors (chrome)
];
