import {
  Component,
  ChangeDetectionStrategy,
  Input,
  ElementRef,
  ContentChildren,
  QueryList,
  ViewEncapsulation,
  ChangeDetectorRef,
  AfterContentInit,
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { tap } from 'rxjs';

import { TiimeSidenavItemComponent } from '../sidenav-item.component';

@UntilDestroy()
@Component({
  selector: 'tiime-sidenav-menu',
  templateUrl: './sidenav-menu.component.html',
  styleUrls: ['./sidenav-menu.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class TiimeSidenavMenuComponent implements AfterContentInit {
  @ContentChildren(TiimeSidenavItemComponent, { descendants: true })
  private content: QueryList<TiimeSidenavItemComponent>;

  @Input() iconSrc: string;
  @Input() menuTitle: string;
  @Input() set theme(value: string) {
    this.elementRef.nativeElement.classList.add(value);
  }
  @Input() opened: boolean;

  constructor(
    private readonly route: ActivatedRoute,
    private readonly router: Router,
    private readonly elementRef: ElementRef<HTMLElement>,
    private readonly cdr: ChangeDetectorRef,
  ) {}

  ngAfterContentInit(): void {
    this.observeChildrenClasses();
    this.observeCompanyChange();
  }

  toggleMenu($event: MouseEvent): void {
    $event.stopPropagation();
    this.opened = !this.opened;
  }

  navigate(): void {
    const route = this.content.first.hostElement.getAttribute('href');
    if (route) {
      void this.router.navigateByUrl(route);
    }
  }

  private hasActiveItem(): boolean {
    return this.content.some(item =>
      item.hostElement.classList.contains('active'),
    );
  }

  private observeCompanyChange(): void {
    this.route.params
      .pipe(
        tap(() => {
          if (!this.hasActiveItem()) {
            this.opened = false;
          }
          this.cdr.markForCheck();
        }),
        untilDestroyed(this),
      )
      .subscribe();
  }

  private observeChildrenClasses(): void {
    const changes = new MutationObserver((mutations: MutationRecord[]) => {
      mutations.forEach((mutation: MutationRecord) => {
        if ((mutation.target as HTMLElement).classList.contains('active')) {
          this.opened = true;
          this.cdr.markForCheck();
        }
      });
    });

    this.content.forEach(c => {
      changes.observe(c.hostElement, {
        attributeFilter: ['class'],
      });
    });
  }
}
