import { createTracker, withName } from '@manakincubber/tiime-tracking';
import { tap, withLatestFrom } from 'rxjs/operators';
import { ResetFilter } from 'tiime-components';

import {
  ActionEnMasseCommenceeProperties,
  MultiplesElementsSelectionnesProperties,
} from '@core/amplitude/wrapper';
import {
  ACTIONS_TYPE_TO_AMPLITUDE,
  SELECTION_MODE_TO_AMPLITUDE,
  TRANSACTION_FILTER_TO_AMPLITUDE,
} from '@core/constants';
import { selectedCompanyIdSelector } from '@core/store';

import {
  DOCUMENT_MATCHED,
  DocumentMatched,
  EXPENSE_FROM_REPORT_PREVIEW_OPENED,
  ExpenseFromReportPreviewOpened,
  FilterOriginType,
  LABEL_RULE_CREATED,
  LABEL_RULE_CREATION_STARTED,
  LABEL_RULES_SEEN,
  LabelRuleCreated,
  LabelRuleCreationStarted,
  LabelRulesSeen,
  MASS_ACTION_DISABLED_TOOLTIP_HOVERED,
  MASS_ACTION_MULTIPLE_ITEMS_SELECTED,
  MASS_ACTION_STARTED,
  MassActionDisabledTooltipHovered,
  MassActionMultipleItemsSelected,
  MassActionStarted,
  TODO_ACCOUNTING_ACCESSED,
  TodoAccountingAccessed,
  TRANSACTION_ADD_LABEL_BUTTON_CLICKED,
  ADD_TAG_BUTTON_CLICKED,
  TRANSACTION_FILTER_APPLIED,
  TRANSACTION_LABEL_APPLIED,
  TRANSACTION_VENTILATED,
  TransactionAddLabelButtonClicked,
  AddTagButtonClicked,
  TransactionFilterApplied,
  TransactionLabelApplied,
  TRANSACTIONS_ACCESSED,
  TransactionsAccessed,
  TransactionVentilated,
  VAT_ESTIMATION_SEEN,
  VAT_VALIDATION_ABORTED,
  VAT_VALIDATION_STARTED,
  VatEstimationSeen,
  VatValidationAborted,
  VatValidationStarted,
  TAG_ADDED,
  TagAdded,
  VAT_VALIDATED,
  VatValidated,
} from '../events';
import {
  mapDocumentCategoryIdentifier,
  optionsWithCompanyGroupExtra,
} from '../utils';
import { BaseTrackingGroup } from './base-tracking-group';

export class AccountsTrackingGroup extends BaseTrackingGroup {
  handleAddTagButtonClicked$ = createTracker(
    this.events$.pipe(
      withName(ADD_TAG_BUTTON_CLICKED),
      withLatestFrom(this.store.select(selectedCompanyIdSelector)),
      tap(([event, companyId]: [AddTagButtonClicked, number]) => {
        this.ampli.boutonAjoutTagClique(
          {
            applique_sur: event.appliedOn,
          },
          optionsWithCompanyGroupExtra(companyId),
        );
      }),
    ),
  );

  handleTagAdded$ = createTracker(
    this.events$.pipe(
      withName(TAG_ADDED),
      withLatestFrom(this.store.select(selectedCompanyIdSelector)),
      tap(([event, companyId]: [TagAdded, number]) => {
        this.ampli.tagAjoute(
          {
            applique_sur: event.appliedOn,
          },
          optionsWithCompanyGroupExtra(companyId),
        );
      }),
    ),
  );

  handleExpenseFromReportPreviewOpened$ = createTracker(
    this.events$.pipe(
      withName(EXPENSE_FROM_REPORT_PREVIEW_OPENED),
      withLatestFrom(this.store.select(selectedCompanyIdSelector)),
      tap(([, companyId]: [ExpenseFromReportPreviewOpened, number]) => {
        this.ampli.detailsFraisSurNoteFraisVu(
          optionsWithCompanyGroupExtra(companyId),
        );
      }),
    ),
  );

  handleVatValidationAborted$ = createTracker(
    this.events$.pipe(
      withName(VAT_VALIDATION_ABORTED),
      withLatestFrom(this.store.select(selectedCompanyIdSelector)),
      tap(([, companyId]: [VatValidationAborted, number]) => {
        this.ampli.validationTvaAvortee(
          optionsWithCompanyGroupExtra(companyId),
        );
      }),
    ),
  );

  handleVatEstimationSeen$ = createTracker(
    this.events$.pipe(
      withName(VAT_ESTIMATION_SEEN),
      withLatestFrom(this.store.select(selectedCompanyIdSelector)),
      tap(([event, companyId]: [VatEstimationSeen, number]) => {
        this.ampli.tvaEstimationVue(
          {
            tva_period_en_cours: event.currentVatPeriod,
          },
          optionsWithCompanyGroupExtra(companyId),
        );
      }),
    ),
  );

  handleVatValidationStarted$ = createTracker(
    this.events$.pipe(
      withName(VAT_VALIDATION_STARTED),
      withLatestFrom(this.store.select(selectedCompanyIdSelector)),
      tap(([event, companyId]: [VatValidationStarted, number]) => {
        this.ampli.validationTvaCommencee(
          {
            validation_tva_acces: event.validationTvaAcces,
          },
          optionsWithCompanyGroupExtra(companyId),
        );
      }),
    ),
  );

  handleVatValidated$ = createTracker(
    this.events$.pipe(
      withName(VAT_VALIDATED),
      withLatestFrom(this.store.select(selectedCompanyIdSelector)),
      tap(([, companyId]: [VatValidated, number]) => {
        this.ampli.tvaValidee(
          {
            element_de_validation: 'ecran',
          },
          optionsWithCompanyGroupExtra(companyId),
        );
      }),
    ),
  );

  handleTransactionLabelApplied$ = createTracker(
    this.events$.pipe(
      withName(TRANSACTION_LABEL_APPLIED),
      withLatestFrom(this.store.select(selectedCompanyIdSelector)),
      tap(([event, companyId]: [TransactionLabelApplied, number]) => {
        this.ampli.labelTransactionApplique(
          { label_existant: !event.newLabel },
          optionsWithCompanyGroupExtra(companyId),
        );
      }),
    ),
  );

  handleTransactionFiltered$ = createTracker(
    this.events$.pipe(
      withName(TRANSACTION_FILTER_APPLIED),
      withLatestFrom(this.store.select(selectedCompanyIdSelector)),
      tap(([event, companyId]: [TransactionFilterApplied, number]) => {
        const arrayFilter = Array.isArray(event.filter)
          ? event.filter
          : [event.filter];
        arrayFilter.forEach(filter => {
          const ampliFilter = TRANSACTION_FILTER_TO_AMPLITUDE.get(filter.key);
          if (!ampliFilter || filter instanceof ResetFilter) {
            return;
          }
          this.ampli.filtreTransactionsApplique(
            {
              methode_filtre: event.filterOrigin as FilterOriginType,
              cible_filtre: ampliFilter,
              type_filtre: 'filtre',
            },
            optionsWithCompanyGroupExtra(companyId),
          );
          if (event.sort) {
            this.ampli.filtreTransactionsApplique(
              {
                methode_filtre: event.filterOrigin as FilterOriginType,
                cible_filtre: ampliFilter,
                type_filtre: 'ordre',
              },
              optionsWithCompanyGroupExtra(companyId),
            );
          }
        });
      }),
    ),
  );

  handleTodoAccountingAccessed$ = createTracker(
    this.events$.pipe(
      withName(TODO_ACCOUNTING_ACCESSED),
      withLatestFrom(this.store.select(selectedCompanyIdSelector)),
      tap(([, companyId]: [TodoAccountingAccessed, number]) => {
        this.ampli.comptabiliteAFaireAccedee(
          optionsWithCompanyGroupExtra(companyId),
        );
      }),
    ),
  );

  handleTransactionAddLabelButtonClicked$ = createTracker(
    this.events$.pipe(
      withName(TRANSACTION_ADD_LABEL_BUTTON_CLICKED),
      withLatestFrom(this.store.select(selectedCompanyIdSelector)),
      tap(([, companyId]: [TransactionAddLabelButtonClicked, number]) => {
        this.ampli.boutonAjouteLabelClique(
          optionsWithCompanyGroupExtra(companyId),
        );
      }),
    ),
  );

  handleLabelRuleCreationStarted$ = createTracker(
    this.events$.pipe(
      withName(LABEL_RULE_CREATION_STARTED),
      withLatestFrom(this.store.select(selectedCompanyIdSelector)),
      tap(([, companyId]: [LabelRuleCreationStarted, number]) => {
        this.ampli.creationRegleLabelCommencee(
          optionsWithCompanyGroupExtra(companyId),
        );
      }),
    ),
  );

  handleTransactionVentilated$ = createTracker(
    this.events$.pipe(
      withName(TRANSACTION_VENTILATED),
      withLatestFrom(this.store.select(selectedCompanyIdSelector)),
      tap(([, companyId]: [TransactionVentilated, number]) => {
        this.ampli.transactionScindee(optionsWithCompanyGroupExtra(companyId));
      }),
    ),
  );

  handleLabelRuleCreated$ = createTracker(
    this.events$.pipe(
      withName(LABEL_RULE_CREATED),
      withLatestFrom(this.store.select(selectedCompanyIdSelector)),
      tap(([event, companyId]: [LabelRuleCreated, number]) => {
        this.ampli.regleLabelCree(
          { intitule_matching_modifie: event.operationNameModified },
          optionsWithCompanyGroupExtra(companyId),
        );
      }),
    ),
  );

  handleLabelRulesSeen$ = createTracker(
    this.events$.pipe(
      withName(LABEL_RULES_SEEN),
      withLatestFrom(this.store.select(selectedCompanyIdSelector)),
      tap(([, companyId]: [LabelRulesSeen, number]) => {
        this.ampli.listeRegleVue(optionsWithCompanyGroupExtra(companyId));
      }),
    ),
  );

  handleDocumentMatched$ = createTracker(
    this.events$.pipe(
      withName(DOCUMENT_MATCHED),
      withLatestFrom(this.store.select(selectedCompanyIdSelector)),
      tap(([event, companyId]: [DocumentMatched, number]) => {
        this.ampli.documentRelie(
          {
            transaction_avec_demande_comptable: event.TransactionWithRequest,
            type_document: mapDocumentCategoryIdentifier(
              event.documentCategory,
            ),
          },
          optionsWithCompanyGroupExtra(companyId),
        );
      }),
    ),
  );

  handleTransactionsAccessed$ = createTracker(
    this.events$.pipe(
      withName(TRANSACTIONS_ACCESSED),
      withLatestFrom(this.store.select(selectedCompanyIdSelector)),
      tap(([, companyId]: [TransactionsAccessed, number]) => {
        this.ampli.transactionsComptesAccedee(
          optionsWithCompanyGroupExtra(companyId),
        );
      }),
    ),
  );

  handleMassActionStarted = createTracker(
    this.events$.pipe(
      withName(MASS_ACTION_STARTED),
      withLatestFrom(this.store.select(selectedCompanyIdSelector)),
      tap(([event, companyId]: [MassActionStarted, number]) => {
        this.ampli.actionEnMasseCommencee(
          {
            type_action: ACTIONS_TYPE_TO_AMPLITUDE.get(event.actionType),
            a_filtre: event.hasExtraFilters,
            a_tous_elements_page_selectionnes: event.areAllSelected,
            type_element:
              event.itemsType as ActionEnMasseCommenceeProperties['type_element'],
          },
          optionsWithCompanyGroupExtra(companyId),
        );
      }),
    ),
  );

  handleMassActionMultipleItemsSelected = createTracker(
    this.events$.pipe(
      withName(MASS_ACTION_MULTIPLE_ITEMS_SELECTED),
      withLatestFrom(this.store.select(selectedCompanyIdSelector)),
      tap(([event, companyId]: [MassActionMultipleItemsSelected, number]) => {
        this.ampli.multiplesElementsSelectionnes(
          {
            mode_selection: SELECTION_MODE_TO_AMPLITUDE.get(
              event.selectionLabel,
            ),
            type_element:
              event.itemsType as MultiplesElementsSelectionnesProperties['type_element'],
          },
          optionsWithCompanyGroupExtra(companyId),
        );
      }),
    ),
  );

  handleMassActionDisabledTooltipHovered = createTracker(
    this.events$.pipe(
      withName(MASS_ACTION_DISABLED_TOOLTIP_HOVERED),
      withLatestFrom(this.store.select(selectedCompanyIdSelector)),
      tap(([event, companyId]: [MassActionDisabledTooltipHovered, number]) => {
        this.ampli.actionEnMasseTooltipDisabledVue(
          {
            type_action: ACTIONS_TYPE_TO_AMPLITUDE.get(event.actionType),
            type_element:
              event.itemsType as MultiplesElementsSelectionnesProperties['type_element'],
          },
          optionsWithCompanyGroupExtra(companyId),
        );
      }),
    ),
  );
}
