export interface PostQontoConnectionApiContract {
  redirect_uri: string;
  code: string;
}

export class QontoBankConnection {
  constructor(public redirectURI: string, public code: string) {}

  public static toChronosConnectionJson(
    qontoBankConnection: QontoBankConnection,
  ): PostQontoConnectionApiContract {
    return {
      redirect_uri: qontoBankConnection.redirectURI,
      code: qontoBankConnection.code,
    };
  }
}
