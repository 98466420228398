import { Platform } from '@angular/cdk/platform';
import { CdkVirtualScrollViewport } from '@angular/cdk/scrolling';
import {
  ChangeDetectionStrategy,
  Component,
  ContentChild,
  ElementRef,
  HostBinding,
  ViewEncapsulation,
} from '@angular/core';

@Component({
  selector: 'tiime-virtual-table, table[tiime-virtual-table]',
  template: `
    <ng-content></ng-content>
  `,
  styleUrls: ['./table.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class VirtualTableComponent {
  @HostBinding('class') class = 'tiime-virtual-table';

  @ContentChild(CdkVirtualScrollViewport)
  private virtualScrollViewport: CdkVirtualScrollViewport;
  private elementRef: ElementRef<HTMLElement>;

  constructor(elementRef: ElementRef<HTMLElement>, public platform: Platform) {
    this.elementRef = elementRef;
  }

  scrollToIndex(index = 0, behavior?: ScrollBehavior): void {
    if (!this.virtualScrollViewport) {
      return;
    }

    this.virtualScrollViewport?.scrollToIndex(index, behavior);
  }
}
