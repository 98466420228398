import { FormControl, FormGroup } from '@angular/forms';

export class LogoForm extends FormGroup {
  get id(): FormControl {
    return this.get('id') as FormControl;
  }

  get width(): FormControl {
    return this.get('width') as FormControl;
  }

  get height(): FormControl {
    return this.get('height') as FormControl;
  }

  constructor() {
    super({
      id: new FormControl(null),
      width: new FormControl(null),
      height: new FormControl(null),
    });
  }
}
