import { FormControl } from '@angular/forms';
import { SortDirection } from '@angular/material/sort';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { combineLatest } from 'rxjs';
import { startWith, tap } from 'rxjs/operators';
import { AndFilter, MonoValueFilter, ResetFilter } from 'tiime-components';

import { TableColumnSortAndFilterForm } from '../table-column-sort-and-filter.form';
import { numberIntervalValidator } from './table-number-filter-validator';

@UntilDestroy()
export class TableNumberFilterForm extends TableColumnSortAndFilterForm {
  get exactNumber(): FormControl {
    return this.get('exactNumber') as FormControl;
  }

  get fromNumber(): FormControl<number> {
    return this.get('fromNumber') as FormControl;
  }

  get toNumber(): FormControl<number> {
    return this.get('toNumber') as FormControl;
  }

  constructor(
    filterKey: string,
    sortKey: string,
    sortDirection?: SortDirection,
  ) {
    super(
      {
        exactNumber: new FormControl(null),
        fromNumber: new FormControl(null),
        toNumber: new FormControl(null),
      },
      filterKey,
      sortKey,
      sortDirection,
    );
    this.fromNumber.addValidators(
      numberIntervalValidator(this.fromNumber, this.toNumber),
    );
    this.toNumber.addValidators(
      numberIntervalValidator(this.fromNumber, this.toNumber),
    );
    this.observeControls();
  }

  fromParam(amountFilterValue: string): void {
    const amountParamArray = amountFilterValue.split(',');
    const patch = amountParamArray
      .map(param => {
        const parsedParam = param.replace(/[>=<]/g, '');
        if (param.startsWith('>')) {
          return { fromNumber: this.changeDotToComma(parsedParam) };
        } else if (param.startsWith('<')) {
          return { toNumber: this.changeDotToComma(parsedParam) };
        }
        return { exactNumber: this.changeDotToComma(param) };
      })
      .reduce(
        (previous, current) => ({
          ...previous,
          ...current,
        }),
        {
          exactNumber: null,
          fromNumber: null,
          toNumber: null,
        },
      );

    this.patchValue(patch, { emitEvent: false });
  }

  resetFilters(): void {
    this.patchValue({
      exactNumber: null,
      fromNumber: null,
      toNumber: null,
    });
  }

  protected toFilter():
    | MonoValueFilter<string>
    | AndFilter<string>
    | ResetFilter {
    if (this.exactNumber.value) {
      return new MonoValueFilter(
        this.filterKey,
        this.changeCommaToDot((this.exactNumber.value as number).toString()),
      );
    }
    if (this.fromNumber.value && !this.toNumber.value) {
      return new MonoValueFilter(
        this.filterKey,
        `>=${this.changeCommaToDot(this.fromNumber.value.toString())}`,
      );
    }
    if (!this.fromNumber.value && this.toNumber.value) {
      return new MonoValueFilter(
        this.filterKey,
        `<=${this.changeCommaToDot(this.toNumber.value.toString())}`,
      );
    }
    if (this.fromNumber.value && this.toNumber.value) {
      return new AndFilter(this.filterKey, [
        `>=${this.changeCommaToDot(this.fromNumber.value.toString())}`,
        `<=${this.changeCommaToDot(this.toNumber.value.toString())}`,
      ]);
    }
    return ResetFilter.forKey(this.filterKey);
  }

  private changeCommaToDot(value: string): string {
    return value.replace(',', '.');
  }

  private changeDotToComma(value: string): string {
    return value.replace('.', ',');
  }

  private observeControls(): void {
    this.exactNumber.valueChanges
      .pipe(
        tap(() => {
          this.fromNumber.patchValue(null, { emitEvent: false });
          this.toNumber.patchValue(null, { emitEvent: false });
        }),
        untilDestroyed(this),
      )
      .subscribe();
    combineLatest([
      this.fromNumber.valueChanges.pipe(startWith(this.fromNumber.value)),
      this.toNumber.valueChanges.pipe(startWith(this.toNumber.value)),
    ])
      .pipe(
        tap(() => {
          this.exactNumber.patchValue(null, { emitEvent: false });
          this.fromNumber.updateValueAndValidity({ emitEvent: false });
          this.toNumber.updateValueAndValidity({ emitEvent: false });
        }),
        untilDestroyed(this),
      )
      .subscribe();
  }
}
