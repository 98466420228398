import { TiimeSelectOption } from 'tiime-components';

import { DueDateMode } from '@enums';

export type DueDateModeLabel = TiimeSelectOption<DueDateMode>[];

export const PARAMETER_DUE_DATE_MODE_LABEL: DueDateModeLabel = [
  { value: DueDateMode.emission_date, label: 'uponReceipt' },
  { value: DueDateMode.fifteen_days, label: 'due15days' },
  { value: DueDateMode.thirty_days, label: 'due30days' },
  { value: DueDateMode.sixty_days, label: 'due60days' },
  {
    value: DueDateMode.forty_five_days_end_of_month,
    label: 'due45days',
  },
];

export const INVOICE_DUE_DATE_MODE_LABEL: DueDateModeLabel = [
  ...PARAMETER_DUE_DATE_MODE_LABEL,
  { value: DueDateMode.custom_date, label: 'Choisir une date' },
];
