import { Pipe, PipeTransform } from '@angular/core';

import { Metadata } from '@models/documents';

@Pipe({
  name: 'metadataPriority',
})
export class MetadataPriorityPipe implements PipeTransform {
  transform(metadata: Metadata[], priority: number): Metadata[] {
    return metadata?.filter(metadatum => metadatum.priority === priority);
  }
}
