import {
  Attribute,
  ChangeDetectionStrategy,
  Component,
  HostBinding,
  Input,
} from '@angular/core';
import { Mapper } from '@manakincubber/tiime-utils';

import { BankTransferRecurrency } from '@enums';
import { Payout } from '@models';

@Component({
  selector: 'app-payout-frequency-recap',
  templateUrl: './payout-frequency-recap.component.html',
  styleUrls: ['./payout-frequency-recap.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BankTransferFrequencyRecapComponent {
  @HostBinding('class.small') get isForList(): boolean {
    return this.forList;
  }

  @Input() forList: boolean;
  @Input() payout: Payout;

  readonly mapToShouldDisplayUntilDate: Mapper<Payout, boolean> = payout =>
    !!payout.recurrency &&
    payout.recurrency !== BankTransferRecurrency.Once &&
    !!payout.endDate;

  constructor(@Attribute('primary') public primary: string | null) {}
}
