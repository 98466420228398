import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, map } from 'rxjs';

import { ApiAlertError } from '@decorators/api-alert-error';

export interface QardWebviewData {
  connection_id: string;
  webview_url: string;
}

@Injectable({
  providedIn: 'root',
})
export class QardService {
  static STORAGE_QARD_CONNECTION_ID = 'quard_connection_id';
  static STORAGE_QARD_STATE = 'quard_state';
  static STORAGE_QARD_WEBVIEW_URL = 'quard_webview_url';

  constructor(private readonly http: HttpClient) {}

  @ApiAlertError()
  getConnectionWebviewUrl(
    id: number,
    redirectUri: string,
    state: string,
  ): Observable<string> {
    redirectUri = redirectUri.replace('http:', 'https:');
    const headers = new HttpHeaders(
      `return-url: ${redirectUri}?state=${state}&code=hub`,
    );
    return this.http
      .get<QardWebviewData>(
        `api/v1/companies/{companyId}/banks/${id}/webview_url`,
        {
          headers,
        },
      )
      .pipe(
        map((qardWebviewData: QardWebviewData) => {
          this.setConnectionId(qardWebviewData.connection_id);
          this.setState('connection');
          this.setWebviewURL(qardWebviewData.webview_url);
          return qardWebviewData.webview_url;
        }),
      );
  }

  @ApiAlertError()
  getReconnectionWebviewUrl(id: number): Observable<string> {
    return this.http
      .get<QardWebviewData>(
        `api/v1/companies/{companyId}/bank_connections/${id}/webview_url`,
      )
      .pipe(
        map((qardWebviewData: QardWebviewData) => {
          this.setConnectionId(qardWebviewData.connection_id);
          this.setState('reconnection');
          this.setWebviewURL(qardWebviewData.webview_url);
          return qardWebviewData.webview_url;
        }),
      );
  }

  @ApiAlertError()
  triggerPushStripe(connectionId: string): Observable<unknown> {
    return this.http.post('api/v1/companies/{companyId}/trigger_push_stripe', {
      connection_id: connectionId,
    });
  }

  getConnectionId(): string {
    return localStorage.getItem(QardService.STORAGE_QARD_CONNECTION_ID);
  }

  setConnectionId(connectionId: string): void {
    localStorage.setItem(QardService.STORAGE_QARD_CONNECTION_ID, connectionId);
  }

  getState(): string {
    return localStorage.getItem(QardService.STORAGE_QARD_STATE);
  }

  setState(state: string): void {
    localStorage.setItem(QardService.STORAGE_QARD_STATE, state);
  }

  getWebviewURL(): string {
    return localStorage.getItem(QardService.STORAGE_QARD_WEBVIEW_URL);
  }

  setWebviewURL(url: string): void {
    localStorage.setItem(QardService.STORAGE_QARD_WEBVIEW_URL, url);
  }

  removeQardParametersFromLocalStorage(): void {
    localStorage.removeItem(QardService.STORAGE_QARD_STATE);
    localStorage.removeItem(QardService.STORAGE_QARD_CONNECTION_ID);
    localStorage.removeItem(QardService.STORAGE_QARD_WEBVIEW_URL);
  }
}
