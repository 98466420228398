import { FormGroup, FormControl, Validators } from '@angular/forms';
import { RegexUtils } from '@manakincubber/tiime-utils';

export class ChangePasswordForm extends FormGroup {
  get email(): FormControl<string> {
    return this.get('email') as FormControl;
  }
  get emailErrorMessage(): string {
    if (this.email.hasError('required')) {
      return this.email.touched && 'Mail requis';
    } else {
      return this.email.touched && this.email.hasError('pattern')
        ? 'Mail invalide'
        : null;
    }
  }

  constructor(email: string) {
    super({
      email: new FormControl(
        email,
        Validators.compose([
          Validators.pattern(RegexUtils.mailRegex),
          Validators.required,
        ]),
      ),
    });
  }
}
