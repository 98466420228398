<ng-container *ngIf="value$ | async as selectedEntity; else addLabelTemplate">
  <app-label-chip
    *ngIf="selectedEntity | isInstanceOfLabel; else tagChipTemplate"
    tabindex="0"
    #labelChip
    [label]="selectedEntity"
    [disabled]="disabled"
    [large]="!xsmall"
    [readonly]="isReadOnly"
    [attr.data-cy]="
      'label__btn-edit' +
      (isReadOnly ? '__readonly' : '') +
      (disabled ? '__disabled' : '')
    "
    [canRemove]="!disableUnmatch"
    [showRemoveIconOnHoverOnly]="true"
    [hideBlockedInformation]="true"
    (keydown)="openLabelSelectionOverlay($event)"
    (click)="openLabelSelectionOverlay()"
    (remove)="updateSelectedEntity()"
  ></app-label-chip>

  <ng-template #tagChipTemplate>
    <app-tag-chip
      *ngIf="showTagChip; else addLabelTemplate"
      [large]="!xsmall"
      [tag]="selectedEntity"
      [canRemove]="true"
      (click)="openLabelSelectionOverlay()"
      (remove)="updateSelectedEntity()"
    ></app-tag-chip>
  </ng-template>
</ng-container>
<ng-template #addLabelTemplate>
  <ng-container *ngIf="!isReadOnly">
    <button
      class="label-button"
      type="button"
      tiime-button
      inverted
      neutral
      data-cy="label__btn-add"
      [xsmall]="xsmall"
      [class.disabled]="disabled"
      [class.is-invalid]="isInvalid"
      [class.imputation-in-progress]="bankTransaction?.imputationInProgress"
      (click)="openLabelSelectionOverlay()"
    >
      <div class="default-wording">
        <mat-icon class="icon-plus" svgIcon="icon-plus"></mat-icon>
        {{ labelSelectionWording }}
      </div>
      <div class="alternative-wording warn-wording">
        <mat-icon
          class="icon-warning-filled"
          svgIcon="icon-warning-filled"
        ></mat-icon>
        Aucun label
      </div>
    </button>

    <span
      *ngIf="bankTransaction?.imputationInProgress"
      class="alternative-wording processing-label"
    >
      En cours de traitement...
    </span>
  </ng-container>
</ng-template>
