import { formatDate } from '@angular/common';
import { Inject, LOCALE_ID, Pipe, PipeTransform } from '@angular/core';
import moment from 'moment';

import { MomentHelper } from '@helpers';

@Pipe({
  name: 'formattedDate',
})
export class FormattedDatePipe implements PipeTransform {
  transform(date: Date, prefix = false): string | null {
    if (!date) {
      return null;
    }

    const today = new Date();
    if (MomentHelper.isSameDate(moment(date), moment(today))) {
      return `Aujourd'hui`;
    }
    return `${prefix ? 'Le ' : ''}${formatDate(
      date,
      'shortDate',
      this.locale,
    )}`;
  }

  constructor(@Inject(LOCALE_ID) public locale: string) {}
}
