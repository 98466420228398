import { Action, createReducer, on } from '@ngrx/store';

import { Category } from '@models';

import {
  addCategorySuccess,
  resetCategories,
  storeCategories,
  updateCategorySuccess,
} from './categories.actions';

export const categoriesInitalState: Category[] = null;

export const categoryReducer = createReducer(
  categoriesInitalState,
  on(storeCategories, (_state, { categories }) => categories),
  on(addCategorySuccess, (state, { category }) => {
    if (Category.isStandardCategory(category.identifier)) {
      return state.map(c => {
        return c.identifier === category.identifier ? category : c;
      });
    }
    return [...state, category];
  }),
  on(updateCategorySuccess, (state, { category }) => {
    return state.map(c => {
      if (c.id === category.id) {
        return category;
      }
      return c;
    });
  }),
  on(resetCategories, () => categoriesInitalState),
);

export function categoriesReducer(
  state: Category[],
  action: Action,
): Category[] {
  return categoryReducer(state, action);
}
