import { Pipe, PipeTransform } from '@angular/core';
import { Filter } from 'tiime-components';

import { DocumentComplexFilterKey } from '@core/enum/documents/document-complex-filter-key.enum';

@Pipe({
  name: 'documentsComplexFilters',
})
export class DocumentsComplexFiltersPipe implements PipeTransform {
  transform(filters: Filter<unknown>[]): Filter<unknown>[] {
    return filters.filter(filter =>
      Object.values<string>(DocumentComplexFilterKey).includes(filter.key),
    );
  }
}
