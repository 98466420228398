import { Directive, inject, Input } from '@angular/core';
import { MatIcon } from '@angular/material/icon';

import { TIIME_ICON_PREFIX } from '@shared';

@Directive({ standalone: true, selector: 'mat-icon[tiimeSvgIcon]' })
export class TiimeSvgIconDirective {
  private readonly matIcon = inject(MatIcon, { host: true });

  @Input({ alias: 'tiimeSvgIcon', required: true })
  set setTiimeSvgIcon(value: string) {
    this.matIcon.svgIcon = value.startsWith(TIIME_ICON_PREFIX)
      ? value
      : `${TIIME_ICON_PREFIX}${value}`;
  }
}
