import { FormControl, FormGroup } from '@angular/forms';
import moment from 'moment';

import { MomentHelper } from '@helpers';
import { Registry } from '@models/registry';

export class RegistryForm extends FormGroup {
  isDropdownOpen = false;
  get id(): FormControl {
    return this.get('id') as FormControl;
  }

  get label(): FormControl {
    return this.get('label') as FormControl;
  }

  get date(): FormControl {
    return this.get('date') as FormControl;
  }

  get amount(): FormControl {
    return this.get('amount') as FormControl;
  }

  get operationType(): FormControl {
    return this.get('operationType') as FormControl;
  }

  get registryType(): FormControl {
    return this.get('registryType') as FormControl;
  }

  get comment(): FormControl {
    return this.get('comment') as FormControl;
  }

  get deleteRegistry(): FormControl {
    return this.get('deleteRegistry') as FormControl;
  }

  constructor(readonly registry: Registry) {
    super({
      id: new FormControl(registry.id),
      label: new FormControl(registry.label),
      date: new FormControl(moment(registry?.date)),
      amount: new FormControl(registry.amount, { updateOn: 'blur' }),
      operationType: new FormControl(registry.operationType),
      registryType: new FormControl(registry.registryType),
      comment: new FormControl(registry.comment, { updateOn: 'blur' }),
      deleteRegistry: new FormControl(false),
    });
  }

  toRegistry(): Registry {
    return {
      ...this.value,
      date: MomentHelper.toCalendarDateString(this.date.value),
    } as Registry;
  }
}
