import { createFeatureSelector, createSelector } from '@ngrx/store';

import { CardState } from '@enums';
import { WalletAccount } from '@models';

export const walletAccountSelector =
  createFeatureSelector<WalletAccount>('walletAccount');
export const isKycValidatedSelector = createSelector(
  walletAccountSelector,
  walletAccount => walletAccount?.trackWalletStep?.validated === true,
);

export const cardInUseSelector = createSelector(
  walletAccountSelector,
  walletAccount => {
    if (walletAccount?.cards) {
      return walletAccount?.cards[walletAccount?.cards?.length - 1];
    } else {
      return null;
    }
  },
);

export const isFirstCardInOrderOrTracking = createSelector(
  walletAccountSelector,
  walletAccount =>
    !walletAccount?.cards &&
    (walletAccount?.orderCard ||
      walletAccount?.trackCardSteps?.length > 0 ||
      walletAccount?.useCard),
);

export const isPinTryExeeded = createSelector(
  cardInUseSelector,
  card => card.state === CardState.pinTryExceeded,
);

export const isCvvTryExeeded = createSelector(
  cardInUseSelector,
  card => card.state === CardState.cvvTryExceeded,
);

export const cardCanBeActivated = createSelector(
  walletAccountSelector,
  walletAccount =>
    walletAccount?.trackCardSteps.every(({ isChecked }) => isChecked) &&
    walletAccount?.trackWalletStep?.validated === true,
);
