import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { ApiAlertError } from '@decorators/api-alert-error';
import { WalletUser, WalletUserApiContract } from '@models';

@Injectable({
  providedIn: 'root',
})
export class WalletUserService {
  readonly resource = 'api/v1/companies/{companyId}/wallet_users';

  constructor(private readonly http: HttpClient) {}

  @ApiAlertError()
  getMe(): Observable<WalletUser> {
    return this.http
      .get<WalletUserApiContract>(`${this.resource}/me`)
      .pipe(map(walletUser => WalletUser.fromJson(walletUser)));
  }
}
