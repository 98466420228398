import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  inject,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import {
  MatSnackBarHorizontalPosition,
  MatSnackBarRef,
  MatSnackBarVerticalPosition,
} from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { TrackingService } from '@manakincubber/tiime-tracking';
import { TrackingEvent } from '@manakincubber/tiime-tracking/lib/tracking-event';
import { Mapper } from '@manakincubber/tiime-utils';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Store } from '@ngrx/store';
import { BehaviorSubject, Observable, of, Subject } from 'rxjs';
import {
  distinctUntilChanged,
  finalize,
  map,
  startWith,
  switchMap,
  take,
  tap,
} from 'rxjs/operators';
import { SnackbarConfig, TiimeSnackbarService } from 'tiime-components';

import { RECEIPT_NEEDED_FROM_AMOUNT } from '@constants';
import { BankTransferService, BeneficiariesService } from '@core';
import {
  BankTransferReasonChose,
  BankTransferSent,
  BankTransferSent25k,
  BankTransferStarted,
  BankTransferStarted25k,
} from '@core/amplitude';
import { BankTransferSource } from '@core/amplitude/constants';
import { filterNotNullOrUndefined } from '@core/helpers';
import { selectedCompanySelector } from '@core/store';
import { BankTransferRecurrency } from '@enums';
import { BankTransferFormValue } from '@forms';
import {
  BankAccount,
  BankTransferReason,
  Beneficiary,
  CreateBankTransferBody,
  DecisionTreeDocumentChoice,
  Document,
  ScheduledPayout,
} from '@models';
import { BankAccountService } from '@services/bank-account.service';
import { UsersService } from '@services/users.service';

import {
  BankTransfersActiveTab,
  BankTransfersListsService,
} from '../../../bank-transfers-lists/bank-transfers-lists.service';
import { ScheduledPayoutsComponentService } from '../../../scheduled-payouts/scheduled-payouts.service';
import { BankTransferStepperComponent } from '../bank-transfer-stepper/bank-transfer-stepper.component';
import { BankTransferOverlayService } from '../bank-transfert-overlay/bank-transfer-overlay.service';
import { LabelCreatedTransactionSnackbarComponent } from './label-created-transaction-snackbar/label-created-transaction-snackbar.component';

@UntilDestroy()
@Component({
  selector: 'app-bank-transfer-card',
  templateUrl: './bank-transfer-card.component.html',
  styleUrls: ['./bank-transfer-card.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BankTransferCardComponent implements OnInit, AfterViewInit {
  @ViewChild('stepper') private readonly stepper: BankTransferStepperComponent;

  @Input() documentReason: BankTransferReason;
  @Input() selectedBeneficiary: Beneficiary | undefined;
  @Input() transferSource: BankTransferSource | undefined;

  @Output() closeEmitter = new EventEmitter<boolean>();

  isDocumentProofStepNeeded$?: Observable<boolean>;
  personalWalletBeneficiary?: Beneficiary;
  formRawValue: BankTransferFormValue;
  shouldDisplayLastScreenRecap = false;
  beneficiaries$: Observable<Beneficiary[]>;
  document?: Document;

  readonly form = this.bankTransferOverlayService.form;
  readonly bankAccounts$ = this.bankAccountService.getAll().pipe(
    map(accounts => accounts.filter(account => account.isWallet)),
    tap(accounts => this.updateDefaultAccount(accounts)),
  );
  readonly loading$ = new BehaviorSubject(false);
  readonly ibanFromDocument$ = new BehaviorSubject<string | null>(null);
  readonly nameFromDocument$ = new BehaviorSubject<string | null>(null);
  private selectedChoice: DecisionTreeDocumentChoice;
  private readonly reloadBeneficiaries$ = new Subject<true>();
  private readonly trackingService = inject(TrackingService);
  private readonly activeCompanyId$ = this.store
    .select(selectedCompanySelector)
    .pipe(map(company => company.id));

  readonly mapToBeneficiary: Mapper<Beneficiary, Beneficiary> = beneficiary =>
    !!beneficiary && Beneficiary.fromBeneficiary(beneficiary);
  readonly mapToTitle: Mapper<BankTransferReason, string> = documentReason =>
    documentReason
      ? documentReason === 'refund'
        ? `Je me rembourse`
        : `Je paye un fournisseur`
      : `J'effectue un virement`;

  constructor(
    private readonly router: Router,
    private readonly beneficiariesService: BeneficiariesService,
    private readonly bankTransferService: BankTransferService,
    private readonly snackbar: TiimeSnackbarService,
    private readonly usersService: UsersService,
    private readonly bankAccountService: BankAccountService,
    private readonly bankTransfersListsService: BankTransfersListsService,
    private readonly bankTransferOverlayService: BankTransferOverlayService,
    private readonly scheduledPayoutsComponentService: ScheduledPayoutsComponentService,
    private readonly store: Store,
  ) {}

  ngOnInit(): void {
    this.beneficiaries$ = this.loadBeneficiaries();
    if (this.transferSource) {
      this.trackingService.dispatch(
        new BankTransferStarted(this.transferSource),
      );
    }

    this.listenToDocumentAdded();

    this.form.valueChanges
      .pipe(
        tap(() => {
          this.formRawValue = this.form.getRawValue() as BankTransferFormValue;
          this.bankTransferOverlayService.ocerisationAvailable$.next(
            !this.form.dirty,
          );
        }),
        untilDestroyed(this),
      )
      .subscribe();

    if (this.selectedBeneficiary) {
      this.form.initiation.beneficiary.setValue(this.selectedBeneficiary);
    }

    this.getPersonalWalletBeneficiary();
  }

  private listenToDocumentAdded(): void {
    this.form.initiation.amount?.enable();
    this.bankTransferOverlayService.document$
      .pipe(
        filterNotNullOrUndefined(),
        tap((document: Document) => {
          this.document = document;

          if (!this.form.documents.length) {
            this.form.documents.addDocumentNeeded();
            this.form.documents.setValue([{ document }]);
          }
        }),
        tap((document: Document) => {
          if (!this.form.initiation.beneficiary.value) {
            this.getBeneficiaryIbanFromDocument(document);
          }

          if (
            !this.form.parameters.date.touched &&
            this.documentReason !== 'refund'
          ) {
            this.form.parameters.date.setValue(
              this.getDueDateFromDocument(document),
            );
          }

          if (!this.form.parameters.label.value) {
            this.form.parameters.label.setValue(
              this.getLabelFromDocument(document),
            );
          }

          const receiptAmount = Document.getAmount(document);
          if (!this.form.initiation.amount?.value && receiptAmount) {
            this.form.initiation.patchValue({
              amount: receiptAmount,
            });

            if (this.documentReason === 'refund') {
              this.form.initiation.amount?.disable();
            }
          }
        }),
        untilDestroyed(this),
      )
      .subscribe();
  }

  private getBeneficiaryIbanFromDocument(document: Document): void {
    if (
      !this.ibanFromDocument$.value &&
      document.metadata.some(meta => meta.key === 'iban')
    ) {
      this.ibanFromDocument$.next(Document.getIban(document));
      this.nameFromDocument$.next(Document.getWording(document));
      this.initBeneficiaryInfo();
    } else {
      this.ibanFromDocument$.next(null);
    }
  }

  private getDueDateFromDocument(document: Document): Date | null {
    const today = new Date();
    const dueDateMetadata = document.metadata.find(
      meta => meta.key === 'due_date',
    );
    if (!dueDateMetadata) {
      return null;
    }
    const dateString = dueDateMetadata.value as string;
    if (!dateString) {
      return null;
    }
    const parsedDate = new Date(dateString);
    return parsedDate < today ? today : parsedDate;
  }

  private getLabelFromDocument(document: Document): string {
    const labelMetadata = document.metadata.find(
      meta => meta.key === 'invoice_number',
    );
    if (!labelMetadata?.value) {
      return null;
    }
    return labelMetadata.label + (labelMetadata.value as string);
  }

  private initBeneficiaryInfo(): void {
    if (!this.ibanFromDocument$.value) {
      return;
    }

    this.beneficiaries$
      .pipe(
        tap(beneficiaries => {
          const beneficiary = beneficiaries.find(
            b => b.iban === this.ibanFromDocument$.value,
          );
          if (beneficiary) {
            this.form.initiation.beneficiary.setValue(beneficiary);
          } else {
            this.form.initiation.beneficiary.setValue({
              name: this.nameFromDocument$.value,
              iban: this.ibanFromDocument$.value,
            });
          }
        }),
      )
      .subscribe();
  }

  ngAfterViewInit(): void {
    this.initDocumentProofStepNeeded();
    this.handleDocumentStepUpdate();
    this.handleBeneficiaryChange();
  }

  onBeneficiaryCreated(): void {
    this.reloadBeneficiaries$.next(true);
  }

  triggerBankTransfer(): void {
    const formValue: BankTransferFormValue =
      this.form.getRawValue() as BankTransferFormValue;
    this.loading$.next(true);

    const beneficiaryId = formValue.initiation.beneficiary?.id;

    const createPayoutBody = this.createBankTransferPayoutBody(formValue);
    this.bankTransferService
      .create(createPayoutBody)
      .pipe(
        switchMap(response => {
          this.trackingService.dispatch(new BankTransferSent());

          if (this.form.initiation.amount.value >= RECEIPT_NEEDED_FROM_AMOUNT) {
            this.trackingService.dispatch(new BankTransferSent25k());
          }

          if (
            this.document &&
            this.documentReason === 'refund' &&
            this.personalWalletBeneficiary?.id !== beneficiaryId
          ) {
            return this.usersService.putPersonalWalletBeneficiarySettings(
              beneficiaryId,
            );
          }
          return of(response);
        }),
        tap(response => {
          const transactionId =
            typeof response === 'number' ? response : undefined;
          const scheduledPayout =
            response instanceof ScheduledPayout ? response : undefined;

          this.onBankTransferCreated(transactionId, scheduledPayout);
        }),
        finalize(() => this.loading$.next(false)),
      )
      .subscribe();
  }

  private createBankTransferPayoutBody(
    formValue: BankTransferFormValue,
  ): CreateBankTransferBody {
    let mainDocumentNeeded = false;

    if (this.document) {
      mainDocumentNeeded = !formValue.documents.find(
        el => el.document?.id === this.document.id,
      );
    }

    if (mainDocumentNeeded) {
      formValue.documents.unshift({ document: this.document });
    }

    return new CreateBankTransferBody(
      formValue.initiation.beneficiary,
      this.formRawValue.initiation?.amount,
      formValue.parameters.date,
      formValue.parameters.frequency || BankTransferRecurrency.Once,
      formValue.parameters.indefinitely
        ? undefined
        : formValue.parameters.untilDate,
      formValue.parameters.label || undefined,
      formValue.documents.length > 0
        ? formValue.documents
            .filter(docProof => docProof.document?.type)
            .map(docProof => ({
              id: docProof.document.id,
              type: docProof.document.type,
            }))
        : undefined,
    );
  }

  completeBankTransferSteps(createdTransactionId?: number | undefined): void {
    if (this.documentReason) {
      this.closeEmitter.emit(true);
      this.openTransferCreatedSnackbar();
    } else if (createdTransactionId) {
      this.openTransferCreatedToLabelTransactionSnackbar();
      this.activeCompanyId$
        .pipe(
          take(1),
          tap(companyId => {
            void this.router.navigateByUrl(
              this.router.createUrlTree(
                ['companies', companyId.toString(), 'account', 'transactions'],
                {
                  queryParams: { included_ids: createdTransactionId },
                },
              ),
            );
          }),
          untilDestroyed(this),
        )
        .subscribe();
      this.closeEmitter.emit();
    } else {
      this.closeEmitter.emit(true);
      this.openTransferCreatedSnackbar();
      this.resetBankTransfer();
    }
    this.shouldDisplayLastScreenRecap = false;
  }

  updateSelectedChoice(choice: DecisionTreeDocumentChoice): void {
    this.selectedChoice = choice;
  }

  goToNextStep(): void {
    this.dispatchAmplitudeEventBeforeStepChange();
    this.stepper.next();
  }

  private openTransferCreatedToLabelTransactionSnackbar(): MatSnackBarRef<LabelCreatedTransactionSnackbarComponent> {
    const snackbarConfig = {
      horizontalPosition: 'center' as MatSnackBarHorizontalPosition,
      verticalPosition: 'bottom' as MatSnackBarVerticalPosition,
      panelClass: 'request-action-snackbar-container',
      duration: 7000,
    };

    return this.snackbar.openFromComponent(
      LabelCreatedTransactionSnackbarComponent,
      snackbarConfig,
    );
  }

  private openTransferCreatedSnackbar(): void {
    this.snackbar.open(
      'Votre virement a bien été effectué',
      SnackbarConfig.success,
    );
  }

  private setPersonalWalletBeneficiary(): void {
    if (
      this.documentReason === 'refund' &&
      this.document &&
      this.personalWalletBeneficiary
    ) {
      this.form.initiation.beneficiary.setValue(this.personalWalletBeneficiary);
    }
  }

  private initDocumentProofStepNeeded(): void {
    this.isDocumentProofStepNeeded$ =
      this.form.initiation.amount.valueChanges.pipe(
        map(
          () => this.form.initiation.amount.value >= RECEIPT_NEEDED_FROM_AMOUNT,
        ),
        distinctUntilChanged(),
      );
  }

  private handleDocumentStepUpdate(): void {
    this.isDocumentProofStepNeeded$
      .pipe(
        tap(isReceiptNeeded => {
          if (isReceiptNeeded || this.document) {
            this.form.parameters.frequency.setValue(
              BankTransferRecurrency.Once,
            );
            this.form.parameters.frequency.disable();
            this.form.parameters.untilDate.disable();
          } else {
            this.form.parameters.indefinitely.setValue(true);
            this.form.parameters.indefinitely.enable();
            this.form.parameters.frequency.enable();
          }
        }),
        untilDestroyed(this),
      )
      .subscribe();
  }

  private handleBeneficiaryChange(): void {
    this.form.initiation.beneficiary.valueChanges
      .pipe(
        tap(() => {
          if (!this.document) {
            this.form.documents.clear();
          }
        }),
        untilDestroyed(this),
      )
      .subscribe();
  }

  private onBankTransferCreated(
    createdTransactionId: number | undefined,
    scheduledPayout: ScheduledPayout | undefined,
  ): void {
    if (scheduledPayout) {
      this.handleScheduledRedirection();
    } else if (
      this.form.initiation.amount.value >= RECEIPT_NEEDED_FROM_AMOUNT
    ) {
      this.stepper.setAsValidated();
      this.shouldDisplayLastScreenRecap = true;
      this.openTransferCreatedSnackbar();
    } else {
      this.completeBankTransferSteps(createdTransactionId);
    }
  }

  private handleScheduledRedirection(): void {
    this.activeCompanyId$
      .pipe(
        take(1),
        map(companyId => {
          return this.router.createUrlTree(
            ['companies', companyId, 'account', 'bank-transfers'],
            {
              queryParams: {
                tab: BankTransfersActiveTab.PENDING_TRANSFERS,
              },
            },
          );
        }),
        tap(url => {
          const isSameUrl =
            url.toString().split(/\?/)[0] === this.router.url.split(/\?/)[0];
          if (isSameUrl) {
            this.scheduledPayoutsComponentService.resetTableCache();
            this.bankTransfersListsService.activeTab$.next(
              BankTransfersActiveTab.PENDING_TRANSFERS,
            );
          } else {
            void this.router.navigateByUrl(url);
          }
          this.closeEmitter.emit(isSameUrl);
        }),
        untilDestroyed(this),
      )
      .subscribe();
  }

  private resetBankTransfer(): void {
    this.bankTransferOverlayService.document$.next(null);
    this.stepper.resetStepper();
    this.form.reset({
      parameters: {
        date: new Date(),
        frequency: BankTransferRecurrency.Once,
        indefinitely: true,
      },
    } as Partial<BankTransferFormValue>);

    this.bankAccounts$
      .pipe(
        take(1),
        tap(accounts => this.updateDefaultAccount(accounts)),
      )
      .subscribe();
    this.getPersonalWalletBeneficiary();
  }

  private updateDefaultAccount(accounts: BankAccount[]): void {
    // A user can only have one Tiime Business account
    if (accounts.length > 0 && accounts[0].enabled) {
      this.form.initiation.account.setValue(accounts[0]);
    }
  }

  private getPersonalWalletBeneficiary(): void {
    this.usersService
      .getPersonalWalletBeneficiary()
      .pipe(
        tap(personalWalletBeneficiary => {
          this.personalWalletBeneficiary = personalWalletBeneficiary;
          this.setPersonalWalletBeneficiary();
        }),
        untilDestroyed(this),
      )
      .subscribe();
  }

  private loadBeneficiaries(): Observable<Beneficiary[]> {
    return this.reloadBeneficiaries$.pipe(
      startWith(true),
      switchMap(() => this.beneficiariesService.get()),
    );
  }

  private dispatchAmplitudeEventBeforeStepChange(): void {
    if (this.form.initiation.amount.value < RECEIPT_NEEDED_FROM_AMOUNT) {
      return;
    }

    let trackingEvent: TrackingEvent;

    switch (this.stepper.selectedIndex) {
      case 0:
        trackingEvent = new BankTransferStarted25k();
        break;
      case 2:
        trackingEvent = new BankTransferReasonChose(this.selectedChoice);
        break;
    }

    this.trackingService.dispatch(trackingEvent);
  }
}
