import { Pipe, PipeTransform } from '@angular/core';
import { StringUtils } from '@manakincubber/tiime-utils';

import { Tag } from '@models';

@Pipe({
  name: 'isTagInSomeList',
})
export class IsTagInSomeListPipe implements PipeTransform {
  transform(tagWritten: string, ...tagsLists: Tag[][]): boolean {
    return (
      tagWritten.trim() &&
      tagsLists.some(tagsList => this.isTagInList(tagWritten, tagsList))
    );
  }

  private isTagInList(tagWritten: string, tagsList: Tag[]): boolean {
    return tagsList.some(
      (tag: Tag) =>
        StringUtils.normalizeToLowerCase(tag.name) ===
        StringUtils.normalizeToLowerCase(tagWritten.trim()),
    );
  }
}
