import {
  ChangeDetectionStrategy,
  Component,
  HostBinding,
  Input,
} from '@angular/core';

@Component({
  selector: 'app-form-generic-error-message',
  templateUrl: './form-generic-error-message.component.html',
  styleUrls: ['./form-generic-error-message.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FormGenericErrorMessageComponent {
  @HostBinding('class.align-icon-right')
  @Input()
  alignIconRight = false;

  /** The `centered` class needs to be used in a div with `position: relative` */
  @HostBinding('class.centered')
  @Input()
  centered = false;

  @HostBinding('class.bold')
  @Input()
  bold = false;
}
