import {
  ClientsExportApiContract,
  ClientsExportConfiguration,
} from './clients-export-configuration';
import {
  ReceiptsExportApiContract,
  DocumentsExportConfiguration,
} from './documents-export-configuration';
import { ExportQueryParamsApiContract } from './export-query-params-request';
import {
  InvoicesExportApiContract,
  InvoicesExportConfiguration,
} from './invoices-export-configuration';
import {
  QuotesExportApiContract,
  QuotesExportConfiguration,
} from './quotes-export-configuration';
import {
  TransactionsExportApiContract,
  TransactionsExportConfiguration,
} from './transactions-export-configuration';

export enum ExportType {
  ExportAccountsData = 'ExportAccountsData',
  ExportBankTransactions = 'ExportBankTransactions',
  ExportDocuments = 'ExportDocuments',
  ExportTravels = 'ExportTravels',
}

export interface ExportRequestApiContract {
  type: ExportType;
  date_range?: { start_date: string; end_date: string };
  bank_transactions?:
    | TransactionsExportApiContract
    | ExportQueryParamsApiContract;
  invoices?: InvoicesExportApiContract;
  documents?: ReceiptsExportApiContract;
  quotations?: QuotesExportApiContract;
  clients?: ClientsExportApiContract;
}

export class ExportRequest {
  constructor(
    private startDate?: string,
    private endDate?: string,
    private transactionsExportConfiguration?: TransactionsExportConfiguration,
    private invoicesExportConfiguration?: InvoicesExportConfiguration,
    private quotesExportConfiguration?: QuotesExportConfiguration,
    private clientsExportConfiguration?: ClientsExportConfiguration,
    private documentsExportConfiguration?: DocumentsExportConfiguration,
  ) {}

  static toJson(exportRequest: ExportRequest): ExportRequestApiContract {
    return {
      date_range: {
        start_date: exportRequest.startDate,
        end_date: exportRequest.endDate,
      },
      type: ExportType.ExportAccountsData,
      ...(!!exportRequest.transactionsExportConfiguration &&
        TransactionsExportConfiguration.toJson(
          exportRequest.transactionsExportConfiguration,
        )),
      ...(!!exportRequest.invoicesExportConfiguration &&
        InvoicesExportConfiguration.toJson(
          exportRequest.invoicesExportConfiguration,
        )),
      ...(!!exportRequest.quotesExportConfiguration &&
        QuotesExportConfiguration.toJson(
          exportRequest.quotesExportConfiguration,
        )),
      ...(!!exportRequest.clientsExportConfiguration &&
        ClientsExportConfiguration.toJson(
          exportRequest.clientsExportConfiguration,
        )),
      ...(!!exportRequest.documentsExportConfiguration &&
        DocumentsExportConfiguration.toJson(
          exportRequest.documentsExportConfiguration,
        )),
    };
  }
}
