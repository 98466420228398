import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatChipsModule } from '@angular/material/chips';
import { TiimePipesModule } from '@manakincubber/tiime-utils';

import { TiimeChipModule } from '../chip';
import { TiimeIconModule } from '../icon/index';
import { TiimeLetModule } from '../let';
import { TiimeOverlayModule } from '../overlay';
import { TiimeTooltipModule } from '../tooltip/index';
import { ComplexSearchBarComponent } from './complex-search-bar.component';
import { TiimeFilterChipModule } from './components/filter-chip/filter-chip.module';
import { FilterListOverlayComponent } from './components/filter-list-overlay/filter-list-overlay.component';

@NgModule({
  declarations: [ComplexSearchBarComponent],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    MatChipsModule,
    TiimeIconModule,
    TiimeChipModule,
    TiimePipesModule,
    TiimeLetModule,
    TiimeOverlayModule,
    TiimeTooltipModule,
    TiimeFilterChipModule,
    FilterListOverlayComponent,
  ],
  exports: [ComplexSearchBarComponent],
})
export class TiimeComplexSearchBarModule {}
