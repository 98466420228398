import {
  AfterViewInit,
  Directive,
  ElementRef,
  inject,
  Input,
  Renderer2,
} from '@angular/core';

@Directive({
  standalone: true,
  selector: '[appFixedActiveWidth]',
})
export class FixedActiveWidthDirective implements AfterViewInit {
  @Input() activeClass = 'active';

  private readonly nativeElement =
    inject<ElementRef<HTMLElement>>(ElementRef).nativeElement;
  private readonly renderer = inject(Renderer2);

  ngAfterViewInit(): void {
    const fixedWidth = this.widthWhenActive();
    this.nativeElement.style.width = `${fixedWidth}px`;
  }

  private widthWhenActive(): number {
    const isActive = this.nativeElement.classList.contains(this.activeClass);

    if (!isActive) {
      this.renderer.addClass(this.nativeElement, this.activeClass);
    }

    const width = this.nativeElement.offsetWidth;

    if (!isActive) {
      this.renderer.removeClass(this.nativeElement, this.activeClass);
    }

    return width;
  }
}
