import { MatSnackBarConfig } from '@angular/material/snack-bar';

const DURATION = 7000;

export enum SnackbarType {
  validation = 'validation',
  success = 'success',
  error = 'error',
}

export interface SnackbarDataType {
  message: string;
  type: SnackbarType;
  actionButtonText?: string;
}

export class SnackbarConfig {
  static readonly success: MatSnackBarConfig<
    Omit<SnackbarDataType, 'message'>
  > = {
    duration: DURATION,
    panelClass: ['snackbar', SnackbarType.success],
    data: { type: SnackbarType.success },
  };

  static readonly error: MatSnackBarConfig<Omit<SnackbarDataType, 'message'>> =
    {
      duration: DURATION,
      panelClass: ['snackbar', SnackbarType.error],
      data: { type: SnackbarType.error },
    };

  static readonly validation: MatSnackBarConfig<
    Omit<SnackbarDataType, 'message'>
  > = {
    duration: DURATION,
    panelClass: ['snackbar', SnackbarType.validation],
    data: { type: SnackbarType.validation },
  };
}
