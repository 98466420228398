import { createTracker, withName } from '@manakincubber/tiime-tracking';
import { forkJoin } from 'rxjs';
import { first, switchMap, tap } from 'rxjs/operators';

import { selectedCompanyIdSelector, userSelector } from '@core/store';
import { UserRoles } from '@enums';
import { User } from '@models';

import {
  APP_OPEN_IDENTIFIED,
  USER_LOGGED_OUT,
  USER_MANUALLY_LOGGED_IN,
} from '../events';
import { prefixUserId } from '../id-prefix';
import { optionsWithCompanyGroupExtra } from '../utils';
import { BaseTrackingGroup } from './base-tracking-group';

const entrepreneurRoles = [
  UserRoles.ROLE_USER,
  UserRoles.ROLE_MANAGER,
  UserRoles.ROLE_EMPLOYEE,
  UserRoles.ROLE_WALLET_ADMIN,
  UserRoles.ROLE_COMPANY_SUPER_ADMIN,
];

export class InternalTrackingGroup extends BaseTrackingGroup {
  handleUserIdentification$ = createTracker(
    this.events$.pipe(
      withName(APP_OPEN_IDENTIFIED),
      switchMap(() =>
        this.store.select(userSelector).pipe(first(user => !!user)),
      ),
      tap((user: User) => {
        const amplitudeUserId = prefixUserId(user.id);
        this.ampli.identify(amplitudeUserId, {
          chronos_user_id: user.id,
          utilise_en_tant_que: this.isEntrepreneur(user)
            ? 'utilisateur'
            : 'utilisateur_expert',
        });
      }),
    ),
  );

  handleUserManuallyLoggedIn$ = createTracker(
    this.events$.pipe(
      withName(USER_MANUALLY_LOGGED_IN),
      switchMap(() =>
        forkJoin([
          this.store.select(userSelector).pipe(first(user => !!user)),
          this.store.select(selectedCompanyIdSelector).pipe(first(id => !!id)),
        ]),
      ),
      tap(([user, companyId]: [User, number]) => {
        const amplitudeUserId = prefixUserId(user.id);
        this.ampli.identify(amplitudeUserId, {
          chronos_user_id: user.id,
          utilise_en_tant_que: this.isEntrepreneur(user)
            ? 'utilisateur'
            : 'utilisateur_expert',
        });
        this.ampli.connexionWeb(optionsWithCompanyGroupExtra(companyId));
      }),
    ),
  );

  handleUserLoggedOut$ = createTracker(
    this.events$.pipe(
      withName(USER_LOGGED_OUT),
      tap(() => {
        this.ampli.client.reset();
      }),
    ),
  );

  private isEntrepreneur(user: User): boolean {
    const isAccountantUser = user.roles.some(
      role => entrepreneurRoles.indexOf(role) < 0,
    );
    return !isAccountantUser;
  }
}
