export class InvoicingDocumentSeller {
  constructor(
    public name: string,
    public address: string,
    public addressComplement: string | null,
    public city: string,
    public postalCode: string,
    public country: string,
    public legalForm: string,
    public siret: string,
    public vatNumber: string,
    public apeCode: string,
    public rcsCity: string,
    public capital: number,
    public email: string | null,
    public phone: string | null,
  ) {}

  static fromJson(
    contract: InvoicingDocumentSellerApiContract,
  ): InvoicingDocumentSeller {
    return new InvoicingDocumentSeller(
      contract.name,
      contract.address.street,
      contract.address.complement,
      contract.address.city,
      contract.address.postal_code,
      contract.address.country,
      contract.legal_form,
      contract.siret,
      contract.vat_number,
      contract.ape_code,
      contract.rcs_city,
      contract.capital,
      contract.contact.email,
      contract.contact.phone,
    );
  }

  toJson(): InvoicingDocumentSellerApiContract {
    return {
      name: this.name,
      legal_form: this.legalForm,
      siret: this.siret,
      vat_number: this.vatNumber,
      ape_code: this.apeCode,
      rcs_city: this.rcsCity,
      capital: this.capital,
      contact: {
        email: this.email,
        phone: this.phone,
      },
      address: {
        street: this.address,
        complement: this.addressComplement,
        city: this.city,
        postal_code: this.postalCode,
        country: this.country,
      },
    };
  }
}

export interface InvoicingDocumentSellerApiContract {
  name: string;
  legal_form: string;
  siret: string;
  vat_number: string;
  ape_code: string;
  rcs_city: string;
  capital: number;
  contact: {
    email: string | null;
    phone: string | null;
  };
  address: {
    street: string;
    complement: string | null;
    city: string;
    postal_code: string;
    country: string;
  };
}
