import { ElementRef, inject } from '@angular/core';

export abstract class TableCellBase {
  protected elementRef = inject<ElementRef<HTMLElement>>(ElementRef);

  protected setColumnClass(name: string): void {
    if (name) {
      this.elementRef.nativeElement.classList.add(`cdk-column-${name}`);
    }
  }
}
