import {
  FormGroup,
  FormControl,
  ValidatorFn,
  AbstractControl,
  ValidationErrors,
  Validators,
} from '@angular/forms';

import { Category } from '@models';

function nameAlreadyExistsValidator(categories: Category[]): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    if (categories.some(category => category.name === control.value)) {
      return { alreadyExists: true };
    }
    return null;
  };
}

function trimmedNameNotEmptyValidator(): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    if ((control.value as string)?.trim().length === 0) {
      return { trimmedNameNotEmpty: true };
    }
    return null;
  };
}

export class CreateCategoryForm extends FormGroup {
  get name(): FormControl {
    return this.get('name') as FormControl;
  }

  get errorMessage(): string {
    if (!this.name.touched) {
      return '';
    }
    if (this.name.hasError('required')) {
      return 'Veuillez renseigner un nom';
    }
    if (this.name.hasError('alreadyExists')) {
      return 'Le nom saisi existe déjà';
    }
    if (this.name.hasError('trimmedNameNotEmpty')) {
      return 'Nom incorrect';
    }
    return '';
  }

  constructor(readonly categories: Category[]) {
    super({
      name: new FormControl(null, [
        Validators.required,
        nameAlreadyExistsValidator(categories),
        trimmedNameNotEmptyValidator(),
      ]),
    });
  }
}
