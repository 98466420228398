import { Directive, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { from, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { User } from '@models';
import { UsersService } from '@services/users.service';
import * as UserActions from '@store/user/user-actions';

import { AppRedirectionBase } from './app-redirection.base';

@Directive()
// eslint-disable-next-line @angular-eslint/directive-class-suffix
export abstract class CallbackComponentBase
  extends AppRedirectionBase
  implements OnInit
{
  protected constructor(
    router: Router,
    protected store: Store,
    protected usersService: UsersService,
  ) {
    super(router);
  }

  ngOnInit(): void {
    this.initAuthentication();
  }

  protected abstract initAuthentication(): void;

  protected getCurrentUser(): Observable<User> {
    return this.usersService.getUser().pipe(
      map(user => {
        this.store.dispatch(UserActions.update({ user }));
        return user;
      }),
    );
  }

  protected navigateToSignIn(): Observable<boolean> {
    return from(this.router.navigate(['signin']));
  }
}
