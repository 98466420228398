<div class="line-header" role="rowheader">
  <div
    class="column-header"
    [attr.data-cy]="column.testId"
    [ngStyle]="column | map: mapToColumnStyles"
    [style.--color]="color"
    *ngFor="let column of columns; trackBy: trackByIndex"
    role="cell"
  >
    <ng-container *ngIf="!!column.translate; else noTranslate">
      {{ column.label | translate }}
    </ng-container>

    <ng-template #noTranslate>
      {{ column.label }}
    </ng-template>
  </div>
</div>
