import { HttpClient, HttpParams } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { DefaultGetOptions, GetOptions } from 'tiime-components';

import { TemporaryEncoder } from '@core/temporary-encoder';
import { ApiAlertError } from '@decorators/api-alert-error';
import {
  CreatePaymentMandateContract,
  GoCardlessPaymentMandateContract,
} from '@services';

@Injectable({ providedIn: 'root' })
export class PaymentMandateService {
  private readonly http = inject(HttpClient);
  private readonly resourceUri = `api/v1/companies/{companyId}/payment_mandates`;

  @ApiAlertError()
  create(
    contract: CreatePaymentMandateContract,
  ): Observable<GoCardlessPaymentMandateContract> {
    return this.http.post<GoCardlessPaymentMandateContract>(
      this.resourceUri,
      contract,
    );
  }

  @ApiAlertError()
  getAll(
    getOptions: DefaultGetOptions,
  ): Observable<GoCardlessPaymentMandateContract[]> {
    const partialOptions = new GetOptions(getOptions).toHttpGetOptions();
    const options = {
      params: new HttpParams({
        fromObject: partialOptions.params,
        encoder: new TemporaryEncoder(),
      }),
      headers: partialOptions.headers,
    };
    return this.http.get<GoCardlessPaymentMandateContract[]>(
      this.resourceUri,
      options,
    );
  }

  @ApiAlertError()
  delete(
    mandateId: GoCardlessPaymentMandateContract['id'],
  ): Observable<GoCardlessPaymentMandateContract> {
    return this.http.delete<GoCardlessPaymentMandateContract>(
      `${this.resourceUri}/${mandateId}`,
    );
  }
}
