<mat-icon
  tiimeTooltip
  tooltipPosition="below"
  data-cy="label-archived-icon__icon"
  [svgIcon]="
    labelDisabledBy === LabelDisabledByEnum.Accountant
      ? 'icon-archive-lock'
      : 'icon-archive'
  "
  [tooltipDisabled]="disableTooltip"
  [content]="labelArchivedTooltipContent"
></mat-icon>
<ng-template #labelArchivedTooltipContent>
  <app-label-archived-text
    [labelDisabledBy]="labelDisabledBy"
  ></app-label-archived-text>
</ng-template>
