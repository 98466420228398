<ng-container [ngSwitch]="amountType">
  <ng-container *ngSwitchCase="AmountTypeEnum.PRE_TAX_AMOUNT">
    {{
      document?.metadata
        | documentPreTaxAmount
        | currency: documentAmountCurrency:'symbol'
    }}
  </ng-container>
  <ng-container *ngSwitchCase="AmountTypeEnum.AMOUNT_INCLUDING_TAX">
    {{
      document?.metadata
        | documentAmount
        | currency: documentAmountCurrency:'symbol'
    }}
  </ng-container>
</ng-container>
