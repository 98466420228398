export interface UnitApiContract {
  id: number;
  code: string;
  favorite: boolean;
  translations?: UnitTranslation[];
}

export type UnitTranslation = {
  lang: string;
  singular: string;
  plurals: string;
};

export class Unit {
  constructor(
    public id: number,
    public code: string,
    public favorite: boolean,
    public translations?: UnitTranslation[],
  ) {}

  public static fromJson(json: UnitApiContract): Unit {
    return new Unit(json.id, json.code, json.favorite, json.translations);
  }
}
