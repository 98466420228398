<input
  class="tiime-datepicker-input"
  readonly
  [id]="id"
  [class.xsmall]="xsmall"
  [min]="min"
  [max]="max"
  [matDatepicker]="datepicker"
  [matDatepickerFilter]="datepickerFilterFn"
  [value]="value"
  [disabled]="disabled"
  (dateChange)="setValue($event)"
/>
<mat-icon
  svgIcon="{{ TIIME_ICON_PREFIX + 'calendar' }}"
  data-cy="datepicker__icon"
></mat-icon>
<mat-datepicker
  panelClass="tiime-components-datepicker"
  #datepicker
  [calendarHeaderComponent]="tiimeDatepickerHeader"
  (closed)="onMatDatepickerClosed()"
></mat-datepicker>
