import { CommonModule, NgOptimizedImage } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  OnInit,
  inject,
} from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { UntilDestroy } from '@ngneat/until-destroy';
import {
  TiimeButtonModule,
  TiimeDialogCloseButtonComponent,
  TiimeDialogModule,
  TiimeLetModule,
} from 'tiime-components';

import { BannerContent, BannerContentService } from '@core';
import { IconsModule, WarningBannerComponent } from '@shared';

import { CategorySelectModule } from '../category-selector/category-select.module';

export interface DocumentBulkDeleteDialogData {
  documentIds: number[];
  numberOfLockedDocuments: number;
  numberOfMatchedDocuments: number;
  numberOfExpenseReports: number;
}

export interface DocumentBulkDeleteDialogResponse {
  documentsToDelete: number[];
}

@UntilDestroy()
@Component({
  standalone: true,
  imports: [
    CommonModule,
    TiimeDialogCloseButtonComponent,
    WarningBannerComponent,
    TiimeButtonModule,
    NgOptimizedImage,
    TiimeDialogModule,
    IconsModule,
    MatIconModule,
    CategorySelectModule,
    TiimeLetModule,
  ],
  selector: 'app-document-bulk-delete-dialog',
  templateUrl: './document-bulk-delete-dialog.component.html',
  styleUrls: ['./document-bulk-delete-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DocumentBulkDeleteDialogComponent implements OnInit {
  readonly bannerContentService = inject(BannerContentService);

  readonly data = inject<DocumentBulkDeleteDialogData>(MAT_DIALOG_DATA);
  readonly dialogRef =
    inject<
      MatDialogRef<
        DocumentBulkDeleteDialogComponent,
        DocumentBulkDeleteDialogResponse
      >
    >(MatDialogRef);

  lockedDocumentsBannerText: BannerContent | null;
  matchedDocumentsBannerText: BannerContent | null;
  expenseReportsBannerText: BannerContent | null;

  ngOnInit(): void {
    this.lockedDocumentsBannerText =
      this.bannerContentService.getLockedDocumentsBannerText(
        this.data.numberOfLockedDocuments,
        this.data.documentIds,
      );
    this.matchedDocumentsBannerText =
      this.bannerContentService.getMatchedDocumentsBannerText(
        this.data.numberOfMatchedDocuments,
        true,
      );
    this.expenseReportsBannerText =
      this.bannerContentService.getTravelExpenseReportsBannerText(
        this.data.numberOfExpenseReports,
      );
  }

  validate(): void {
    this.dialogRef.close({ documentsToDelete: this.data.documentIds });
  }
}
