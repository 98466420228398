import { HttpErrorResponse } from '@angular/common/http';
import {
  Component,
  ElementRef,
  Inject,
  OnInit,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { LoginInitiator, TiimeAuthService } from '@manakincubber/tiime-auth';
import { MarkFormAsTouchedIfInvalid } from '@manakincubber/tiime-utils';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { of, throwError } from 'rxjs';
import { catchError, timeout } from 'rxjs/operators';
import { SnackbarConfig, TiimeSnackbarService } from 'tiime-components';

import { REDIRECT_TO_PARAM_NAME } from '@constants';
import { WINDOW } from '@core/tokens';
import { MagicLinksService } from '@services';

import { AuthBase } from '../auth-shared/bases/auth.base';
import { SignInForm } from './signin-form';

@UntilDestroy()
@Component({
  selector: 'app-signin',
  templateUrl: './signin.component.html',
  styleUrls: ['../auth-base.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class SigninComponent extends AuthBase implements OnInit {
  @ViewChild('emailInput') emailInput: ElementRef;

  signInForm: SignInForm;
  wrongEmailErrorMessage: string;
  wrongPasswordError: boolean;

  get formErrorMessage(): string {
    return !this.signInForm.passwordErrorMessage && this.wrongPasswordError
      ? 'Identifiant ou mot de passe incorrect'
      : '';
  }

  constructor(
    @Inject(WINDOW) private readonly windowRef: Window,
    private readonly route: ActivatedRoute,
    private readonly snackbar: TiimeSnackbarService,
    private readonly magicLinksService: MagicLinksService,
    protected readonly authService: TiimeAuthService,
    protected readonly router: Router,
  ) {
    super(authService, router);
  }

  ngOnInit(): void {
    this.initSignInForm();
  }

  navigateTo(url: string): void {
    this.navigateWithEmailAsQueryParam(url, this.signInForm.email.value);
  }

  @MarkFormAsTouchedIfInvalid('signInForm')
  submitSignInForm(): void {
    this.login();
  }

  private initSignInForm(): void {
    this.signInForm = new SignInForm(
      this.route.snapshot.queryParamMap.get('email'),
    );
  }

  private login(): void {
    this.magicLinksService.removeLocalStorageItem();
    const email = this.signInForm.email.value;

    if (email.endsWith('+magic-link@tiime.fr')) {
      /**
       * This pattern of email address enables to test the magic link even when Auth0 is up.
       */
      this.magicLinksService
        .askMagicLink(email)
        .pipe(untilDestroyed(this))
        .subscribe();
    } else {
      const baseRedirectUrl = new URL(
        `${this.windowRef.location.origin}/auth-callback`,
      );
      baseRedirectUrl.searchParams.set('ctx-email', email);

      const redirectionPath = this.route.snapshot.queryParamMap.get(
        REDIRECT_TO_PARAM_NAME,
      );
      if (redirectionPath) {
        baseRedirectUrl.searchParams.set(
          REDIRECT_TO_PARAM_NAME,
          redirectionPath,
        );
      }

      this.authService
        .login(
          email,
          this.signInForm.password.value,
          LoginInitiator.user,
          baseRedirectUrl.href,
        )
        .pipe(
          timeout(5000),
          catchError((error: HttpErrorResponse) => {
            // Auth0 errors: https://auth0.com/docs/libraries/common-auth0-library-authentication-errors#log-in
            if (error.error === 'access_denied') {
              this.wrongPasswordError = true;
              return of(null);
            } else if (error.error === 'too_many_attempts') {
              this.snackbar.open(
                `Une erreur est survenue.`,
                SnackbarConfig.error,
              );
            } else {
              return this.magicLinksService.askMagicLink(email);
            }
            return throwError(error);
          }),
          untilDestroyed(this),
        )
        .subscribe();
    }
  }
}
