import { InvoicingDocumentMail } from './invoicing-document-mail';

export class ClientDunningMail {
  constructor(
    public mail: InvoicingDocumentMail,
    public client: { id: number },
  ) {}

  public static toJson(
    clientDunningMail: ClientDunningMail,
  ): Record<string, unknown> {
    return {
      mail: InvoicingDocumentMail.toJson(clientDunningMail.mail),
      client: clientDunningMail.client,
    };
  }
}
