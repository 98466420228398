export interface CheckApiContract {
  cmc7: string;
  drawer_type: string;
  amount: string;
  rlmc_key: string;
  drawer?: string;
  drawer_firstname?: string;
  drawer_lastname?: string;
}

export class Check {
  constructor(
    public cmc7: string,
    public drawerType: string,
    public amount: string,
    public rlmcKey: string,
    public drawer?: string,
    public drawerFirstname?: string,
    public drawerLastname?: string,
  ) {}

  public static fromJson(json: CheckApiContract): Check {
    return new Check(
      json.cmc7,
      json.drawer_type,
      json.amount,
      json.rlmc_key,
      json.drawer,
      json.drawer_firstname,
      json.drawer_lastname,
    );
  }

  public static toJson(check: Check): CheckApiContract {
    return {
      cmc7: check.cmc7,
      drawer_type: check.drawerType,
      amount: check.amount,
      rlmc_key: check.rlmcKey,
      drawer: check.drawer,
      drawer_firstname: check.drawerFirstname,
      drawer_lastname: check.drawerLastname,
    };
  }
}
