import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  HostListener,
  Inject,
  OnInit,
  QueryList,
  Renderer2,
  ViewChild,
  ViewChildren,
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { NgUtils } from '@manakincubber/tiime-utils';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { finalize, map, startWith, tap } from 'rxjs/operators';
import { TIIME_OVERLAY_DATA, TiimeOverlayRef } from 'tiime-components';

import { LabelTypeEnum } from '@enums';
import { LabelsHelper, StringHelper } from '@helpers';
import { Label } from '@models/labels';
import { LabelsService } from '@services/labels.service';

import {
  LabelSelectionOverlayData,
  LabelSelectionOverlayResponse,
  LabelSelectionResponseEnum,
} from '.';
import { LabelChipComponent } from '../../labels/label-chip/label-chip.component';

@UntilDestroy()
@Component({
  selector: 'app-label-selection-overlay',
  templateUrl: './label-selection-overlay.component.html',
  styleUrls: ['./label-selection-overlay.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LabelSelectionOverlayComponent implements OnInit, AfterViewInit {
  @ViewChild('search') private readonly search: ElementRef<HTMLInputElement>;

  @ViewChild('resultContainer', { read: ElementRef })
  private readonly containerRef: ElementRef;

  @ViewChildren(LabelChipComponent, { read: ElementRef })
  private readonly results: QueryList<ElementRef>;

  @ViewChildren(LabelChipComponent)
  private readonly labelChipResults: QueryList<LabelChipComponent>;

  LabelSelectionResponseEnum = LabelSelectionResponseEnum;
  createdLabels: Label[];
  searchResults$: Observable<Label[]>;
  noMatch = false;

  focusResultIndex = -1;

  labelNameFormControl = new FormControl('');

  readonly loading$ = new BehaviorSubject(false);
  readonly placeholders = Array(5)
    .fill(0)
    .map((_, i) => i);
  readonly searchLabelWording: string;
  readonly labelCreationOptionWording: string;
  readonly trackById = NgUtils.trackById;
  readonly trackByIndex = NgUtils.trackByIndex;

  constructor(
    @Inject(TIIME_OVERLAY_DATA) readonly data: LabelSelectionOverlayData,
    private readonly overlayRef: TiimeOverlayRef<LabelSelectionOverlayResponse>,
    private readonly labelsService: LabelsService,
    private readonly renderer: Renderer2,
  ) {
    this.searchLabelWording = this.getSearchLabelWording();
    this.labelCreationOptionWording =
      data.labelCreationType === LabelTypeEnum.client
        ? 'Créer le label client'
        : 'Créer le label';
  }

  @HostListener('document:keydown', ['$event'])
  handleKeyDown(event: KeyboardEvent): void {
    if (event.key === 'ArrowDown') {
      this.updateResultFocus(1);
    } else if (event.key === 'ArrowUp') {
      this.updateResultFocus(-1);
    } else if (event.key === 'Enter' && this.focusResultIndex !== -1) {
      this.select(
        this.labelChipResults.get(this.focusResultIndex).label,
        null,
        true,
      );
    } else {
      this.search.nativeElement.focus();
      this.focusResultIndex = -1;
    }
  }

  ngOnInit(): void {
    this.loading$.next(true);
    this.initLastUsedLabels()
      .pipe(
        tap(() => {
          this.createdLabels = this.data.labels.filter(
            label => !label.notCreated && !label.disabled,
          );
          this.searchResults$ = this.labelNameFormControl.valueChanges.pipe(
            startWith(''),
            map((value: string) => value.trim()),
            map(searchValue =>
              this.data.labelCreationEnabled
                ? this.searchWithLabelCreationEnabled(searchValue)
                : this.searchWithLabelCreationDisabled(searchValue),
            ),
            untilDestroyed(this),
          );
        }),
        finalize(() => {
          this.loading$.next(false);
        }),
        untilDestroyed(this),
      )
      .subscribe();
  }

  ngAfterViewInit(): void {
    this.search.nativeElement.focus();
  }

  close(action?: LabelSelectionResponseEnum): void {
    this.overlayRef.close({ action });
  }

  tagCreation(tag: string): void {
    this.overlayRef.close({
      action: LabelSelectionResponseEnum.CREATE_TAG,
      tag,
    });
  }

  select(selectedLabel: Label, $event?: Event, focusSelector?: boolean): void {
    if ($event) {
      $event.stopPropagation();
    }

    if (selectedLabel.disabled) {
      return;
    }

    const label$ = selectedLabel.id
      ? of(selectedLabel)
      : this.createLabel$(selectedLabel);

    label$
      .pipe(
        tap(label => {
          this.overlayRef.close({
            action: LabelSelectionResponseEnum.SELECT_LABEL,
            label,
            labelCreated: !selectedLabel.id,
            focusSelector: focusSelector,
          });
        }),
        untilDestroyed(this),
      )
      .subscribe();
  }

  create($event: Event): void {
    if (!this.data.enableVentilation) {
      $event.stopPropagation();
    }
    this.select(
      Label.fromJson({
        label: this.labelNameFormControl.value.trim(),
      }),
      $event,
    );
  }

  private initLastUsedLabels(): Observable<Label[]> {
    if (this.data.labelCreationEnabled || !this.data.transactionId) {
      return of([] as Label[]);
    }
    return this.labelsService
      .getLastUsedTransactionLabels(this.data.transactionId)
      .pipe(
        tap(lastUsedLabels => {
          this.data.labels = LabelsHelper.combineLabels(
            lastUsedLabels,
            this.data.labels,
          );
        }),
      );
  }

  private searchWithLabelCreationEnabled(searchValue: string): Label[] {
    return this.filterLabels(this.data.labels, searchValue);
  }

  private searchWithLabelCreationDisabled(searchValue: string): Label[] {
    const nonStandardFilteredResults = this.filterLabels(
      this.data.labels,
      searchValue,
      { filterLabelNotCreated: true },
    );

    if (searchValue.length && nonStandardFilteredResults.length > 0) {
      this.noMatch = false;
      return nonStandardFilteredResults;
    } else {
      this.noMatch = !!searchValue.length;
      return this.createdLabels;
    }
  }

  private filterLabels(
    labels: Label[],
    searchValue: string,
    options?: { filterLabelNotCreated: boolean },
  ): Label[] {
    return (
      labels?.filter(label => {
        const isInSearchValue =
          StringHelper.includesWithoutAccents(searchValue, label.label) &&
          (!label.disabled ||
            searchValue.toLowerCase() === label.name.toLowerCase());
        return options?.filterLabelNotCreated
          ? isInSearchValue && !label.notCreated
          : isInSearchValue;
      }) || []
    );
  }

  private createLabel$(label: Label): Observable<Label> {
    return this.data.labelCreationType === LabelTypeEnum.client
      ? this.labelsService.createLabel({
          client_name: label.label,
        })
      : this.labelsService.createLabel(label);
  }

  private getSearchLabelWording(): string {
    if (this.data.labelCreationEnabled) {
      return this.data.labelCreationType === LabelTypeEnum.client
        ? 'Rechercher un label client...'
        : 'Rechercher un label...';
    }

    return this.data.labelCreationType === LabelTypeEnum.client
      ? 'Rechercher un label client dans cette liste'
      : 'Rechercher un label dans cette liste';
  }

  private updateResultFocus(incrementValue: number): void {
    const newIndex = Math.min(
      Math.max(this.focusResultIndex + incrementValue, 0),
      this.results.toArray().length - 1,
    );
    if (this.focusResultIndex !== newIndex) {
      this.focusResultIndex = newIndex;
      const focusedLabel = this.renderer.selectRootElement(
        this.results.toArray()[this.focusResultIndex],
      ).nativeElement;

      // Force container to refresh scrollPosition before focus label chip
      this.renderer.setProperty(
        this.containerRef.nativeElement,
        'scrollTop',
        0,
      );
      if (this.focusResultIndex === 0) {
        setTimeout(() => focusedLabel.focus());
      } else {
        focusedLabel.focus();
      }
    }
  }
}
