import { HttpResponse, HttpStatusCode } from '@angular/common/http';
import { OperatorFunction } from 'rxjs';
import { map } from 'rxjs/operators';
import { PaginationData, PaginationRange } from 'tiime-components';

import { HttpHelper } from '@helpers';

import {
  LabelTimelineItem,
  LabelTimelineItemApiContract,
} from './label-timeline-item';

export interface LabelTimelineApiContract {
  balance?: LabelTimelineBalance;
  items?: LabelTimelineItemApiContract[];
}

export interface LabelTimelineBalance {
  credit: string;
  debit: string;
}

export class LabelTimeline {
  constructor(
    public balance?: LabelTimelineBalance,
    public items?: PaginationData<LabelTimelineItem>,
  ) {}

  public static mapToLabelTimeline(
    range: PaginationRange,
  ): OperatorFunction<HttpResponse<LabelTimelineApiContract>, LabelTimeline> {
    return map((response: HttpResponse<LabelTimelineApiContract>) => {
      if (response.status === HttpStatusCode.NoContent) {
        return {
          ...response.body,
          items: new PaginationData([], range),
        };
      }
      const responseRange: PaginationRange =
        HttpHelper.getRangeFromResponseHeaders(
          response.headers,
          range.pageSize,
        );
      const items = HttpHelper.getListBody(range, response.body.items);
      return {
        ...response.body,
        items: new PaginationData(
          items.map(item => LabelTimelineItem.fromJson(item)),
          responseRange,
        ),
      };
    });
  }
}
