<div data-cy="bank-transfer__document-info-dialog">
  <tiime-dialog-close-button></tiime-dialog-close-button>

  <div class="dialog__container">
    <div class="dialog__header">
      <span data-cy="bank-transfer__document-info-dialog__title">
        Justificatifs obligatoires
      </span>
    </div>

    <img
      ngSrc="assets/illus/bank-transfer/illu_bank_transfer_document_info.svg"
      width="135"
      height="135"
      draggable="false"
      alt="Justificatifs obligatoires"
    />

    <h2
      class="dialog__subtitle"
      data-cy="bank-transfer__document-info-dialog__subtitle"
    >
      Pour les virements d'un montant élevé
      <br />
      nous avons l'obligation légale de:
    </h2>
    <ul class="dialog__list">
      <li>
        Comprendre les transactions de nos clients (Directives Européennes)
      </li>
      <li>Lutter contre tout type de fraude à vos dépends</li>
      <li>Vous contacter en cas de doute</li>
    </ul>

    <div
      class="dialog__banner warning"
      data-cy="bank-transfer__document-info-dialog__warning"
    >
      <mat-icon svgIcon="icon-warning-filled"></mat-icon>
      <span>
        Pour tout justificatif non conforme envoyé, le virement sera mis en
        attente. Le service client vous contactera.
      </span>
    </div>

    <div
      class="dialog__helper"
      data-cy="bank-transfer__document-info-dialog__helper"
    >
      <img
        src="assets/illus/bank-transfer/illu_bank_transfer_helper.svg"
        height="90"
        width="100"
        draggable="false"
        alt=""
      />
      <span>
        L'équipe Tiime se tient à votre disposition au
        <br />
        <a href="tel:0176420389">01 76 42 03 89</a>
        de 9h à 18h du lundi au vendredi ou à l'adresse
        <a href="mailto:comptepro@tiime.fr">comptepro&#64;tiime.fr</a>
      </span>
    </div>

    <div class="dialog-actions__container">
      <button
        tiime-button
        neutral
        inverted
        mat-dialog-close
        data-cy="bank-transfer__document-info-dialog__info-btn"
        (click)="onSupportContact()"
      >
        Plus d'info
      </button>
      <button
        tiime-button
        accent
        mat-dialog-close
        data-cy="bank-transfer__document-info-dialog__close-btn"
      >
        J’ai compris
      </button>
    </div>
  </div>
</div>
