<tiime-dialog-close-button></tiime-dialog-close-button>

<div mat-dialog-title>Je suis protégé</div>
<div mat-dialog-content>
  <img
    class="illustration"
    draggable="false"
    src="assets/illu_reassurance.svg"
    alt="Illustration protection"
  />
  <hr />
  <div class="content-detail">
    <div class="row">
      <div class="item">
        <tiime-twocolors-icon
          icon="ic_twocolors_bank"
          size="24"
        ></tiime-twocolors-icon>
        <span class="description">
          Tiime est agréé par L’ACPR (Banque de France) sous le numéro 823811278
        </span>
      </div>
      <div class="item">
        <tiime-twocolors-icon
          icon="ic_twocolors_lock"
          size="24"
        ></tiime-twocolors-icon>
        <span class="description">
          Les données sont échangées via le protocole HTTPS et sont chiffrées
          avec une clef RSA
        </span>
      </div>
    </div>
    <div class="row">
      <div class="item">
        <tiime-twocolors-icon
          icon="ic_twocolors_file_list"
          size="24"
        ></tiime-twocolors-icon>
        <span class="description">
          Nous sommes audités régulièrement selon les standards de sécurités
          bancaires en vigueur en France et en Union Européenne
        </span>
      </div>
      <div class="item">
        <tiime-twocolors-icon
          icon="ic_twocolors_security_shield"
          size="24"
        ></tiime-twocolors-icon>
        <span class="description">
          Nos serveurs sont en Europe et sont surveillés 24h/24 et 7j/7
        </span>
      </div>
    </div>
  </div>
  <hr />
  <div class="column">
    <span class="headline">Nous sommes partenaires avec</span>
    <div class="bi-description">
      <div class="logos">
        <img
          class="powens"
          draggable="false"
          src="assets/illus/billers/illu-powens-logo.svg"
          alt="Powens"
        />
        <img
          class="qard"
          draggable="false"
          src="assets/illus/billers/illu-qard-logo.svg"
          alt="Qard"
        />
      </div>
      <span>
        POWENS et QARD sont agréés en qualité d’établissement de paiement par
        l’Autorité de Contrôle Prudentiel et de Résolution (ACPR), sous le
        numéro CIB 16948
      </span>
    </div>
  </div>
</div>
<div mat-dialog-actions>
  <ng-container
    *ngIf="withoutRedirectOption; then okAction; else otherAction"
  ></ng-container>
  <ng-template #okAction>
    <button tiime-button accent [mat-dialog-close]="">J'ai compris</button>
  </ng-template>
  <ng-template #otherAction>
    <button tiime-button neutral inverted [mat-dialog-close]="">
      Plus tard
    </button>
    <button tiime-button accent (click)="continue()">Relier un compte</button>
  </ng-template>
</div>
