import { ScrollingModule } from '@angular/cdk/scrolling';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { RouterModule } from '@angular/router';

import { TiimeAutoSaveModule } from '../auto-save/auto-save.module';
import { TiimeButtonModule } from '../button/index';
import { TiimeIconModule } from '../icon/index';
import { TiimeTooltipModule } from '../tooltip/index';
import { ActionBarContentPlaceholderComponent } from './action-bar-content-placeholder.component';
import { ActionBarComponent } from './action-bar.component';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    ScrollingModule,
    MatProgressSpinnerModule,
    TiimeAutoSaveModule,
    TiimeButtonModule,
    TiimeIconModule,
    TiimeTooltipModule,
  ],
  declarations: [ActionBarComponent, ActionBarContentPlaceholderComponent],
  exports: [ActionBarComponent, ActionBarContentPlaceholderComponent],
})
export class TiimeActionBarModule {}
