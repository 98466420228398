import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import {
  TiimeButtonModule,
  TiimeCheckboxModule,
  TiimeMenuModule,
  TiimeRadioModule,
} from 'tiime-components';

import { ScrollShadowsModule } from '@directives';

import { IconsModule } from '../../../icons/icons.module';
import { TableUserFilterComponent } from './table-user-filter.component';

@NgModule({
  imports: [
    CommonModule,
    TiimeMenuModule,
    TiimeButtonModule,
    IconsModule,
    TiimeMenuModule,
    TiimeRadioModule,
    ReactiveFormsModule,
    TiimeCheckboxModule,
    ScrollShadowsModule,
  ],
  exports: [TableUserFilterComponent, TiimeMenuModule],
  declarations: [TableUserFilterComponent],
})
export class TableUserFilterModule {}
