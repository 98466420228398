<div
  class="document-picker"
  attr.data-cy="bank-transfer__document-picker__{{ label }}"
>
  <ng-container
    *ngIf="uploadedDocument$ | async as uploadedDocument; else documentPicker"
  >
    <div
      class="document-picker__drop-zone-container document-picker__drop-zone-container--dropped"
      idleState
    >
      <img
        draggable="false"
        alt=""
        src="assets/illus/bank-transfer/illu_bank_transfer_document_proof_picker--dropped.svg"
      />

      <span
        class="document-picker__drop-zone-container__label document-picker__drop-zone-container__label--dropped"
        data-cy="bank-transfer__document-picker__uploaded-document-name"
      >
        {{ uploadedDocument.name }}
      </span>

      <button
        tiime-button
        neutral
        inverted
        icon
        xsmall
        data-cy="bank-transfer__document-picker__remove-document-btn"
        (click)="removeDocument()"
      >
        <mat-icon svgIcon="tc_icon-close"></mat-icon>
      </button>
    </div>
  </ng-container>
  <ng-template #documentPicker>
    <app-drag-and-drop-zone
      data-cy="bank-transfer__document-picker__drop-zone"
      [multiple]="false"
      [acceptedTypes]="acceptedTypes"
      (dropped)="uploadDocument($event)"
    >
      <div class="document-picker__drop-zone-container" idleState>
        <ng-container
          *ngTemplateOutlet="dropZoneContent; context: { hovered: false }"
        ></ng-container>
      </div>
      <div class="document-picker__drop-zone-container" hoverState>
        <ng-container
          *ngTemplateOutlet="dropZoneContent; context: { hovered: true }"
        ></ng-container>
      </div>
    </app-drag-and-drop-zone>
  </ng-template>

  <div
    class="document-picker__description"
    data-cy="bank-transfer__document-picker__description"
  >
    {{ description }}
  </div>
</div>

<ng-template let-hovered="hovered" #dropZoneContent>
  <img
    class="illustration"
    draggable="false"
    alt=""
    [src]="
      hovered
        ? 'assets/illus/bank-transfer/illu_bank_transfer_document_proof_picker--hovered.svg'
        : mandatory
        ? 'assets/illus/bank-transfer/illu_bank_transfer_document_proof_picker--mandatory.svg'
        : 'assets/illus/bank-transfer/illu_bank_transfer_document_proof_picker.svg'
    "
  />

  <span
    class="document-picker__drop-zone-container__label"
    [class.document-picker__drop-zone-container__label--hovered]="hovered"
  >
    {{ label }}
  </span>

  <button
    *ngIf="canMatch"
    tiime-button
    neutral
    inverted
    icon
    xsmall
    data-cy="bank-transfer__document-picker__matching-btn"
    (click)="openMatchingDialog()"
  >
    <mat-icon svgIcon="icon-link-deprecated"></mat-icon>
  </button>

  <app-file-uploader-button
    [multiple]="false"
    [acceptedTypes]="acceptedTypes"
    (fileSelected)="uploadDocument($event)"
  >
    <button
      tiime-button
      neutral
      inverted
      icon
      xsmall
      action
      type="button"
      data-cy="bank-transfer__document-picker__uploading-btn"
    >
      <mat-icon svgIcon="icon-upload"></mat-icon>
    </button>
  </app-file-uploader-button>
</ng-template>
