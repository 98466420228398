import { HttpClient, HttpParams, HttpStatusCode } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { ApiAlertError } from '@decorators/api-alert-error';
import { User, UserApiContract } from '@models/user';

import { CompanySettings } from '../enum';
import { Beneficiary } from '../models';

export interface CompanySettingsItemApiContract<T = unknown> {
  key: string;
  value: T;
}

@Injectable({
  providedIn: 'root',
})
export class UsersService {
  private readonly resource = 'api/v1/users/me';

  constructor(protected readonly httpClient: HttpClient) {}

  @ApiAlertError()
  getUser(): Observable<User> {
    return this.httpClient
      .get<UserApiContract>(this.resource)
      .pipe(map(User.fromJson));
  }

  @ApiAlertError([HttpStatusCode.NotFound])
  update(user: User): Observable<User> {
    return this.httpClient
      .patch<UserApiContract>(this.resource, User.toJson(user))
      .pipe(map(User.fromJson));
  }

  getPersonalWalletBeneficiary(): Observable<Beneficiary | undefined | null> {
    return this.getUserCompanySettings().pipe(
      map(settings => {
        return settings.find(
          settingsItem =>
            settingsItem.key === CompanySettings.personalWalletBeneficiary,
        )?.value as Beneficiary;
      }),
    );
  }

  putPersonalWalletBeneficiarySettings(
    beneficiaryId: number,
  ): Observable<CompanySettingsItemApiContract<number>> {
    return this.putUserCompanySettings<number>({
      key: CompanySettings.personalWalletBeneficiary,
      value: beneficiaryId,
    });
  }

  getAll(hasWalletUser?: boolean): Observable<User[]> {
    const url = 'api/v1/companies/{companyId}/users';

    let params = new HttpParams();

    if (hasWalletUser !== undefined) {
      params = params.append('has_wallet_user', hasWalletUser);
    }

    return this.httpClient
      .get<UserApiContract[]>(url, { params })
      .pipe(map(users => users.map(user => User.fromJson(user))));
  }

  private getUserCompanySettings(): Observable<
    CompanySettingsItemApiContract[]
  > {
    return this.httpClient.get<CompanySettingsItemApiContract[]>(
      `${this.resource}/companies/{companyId}/settings`,
    );
  }

  @ApiAlertError()
  checkUsers(user: Partial<User>): Observable<User[]> {
    const params = new HttpParams({
      fromObject: {
        email: user.email,
      },
    });

    return this.httpClient
      .get<UserApiContract[]>(`api/v1/companies/{companyId}/users`, {
        params,
      })
      .pipe(
        map(users => (users ? users.map(user => User.fromJson(user)) : null)),
      );
  }

  private putUserCompanySettings<T = unknown>(
    setting: CompanySettingsItemApiContract,
  ): Observable<CompanySettingsItemApiContract<T>> {
    return this.httpClient.put<CompanySettingsItemApiContract<T>>(
      `${this.resource}/companies/{companyId}/settings`,
      setting,
    );
  }

  @ApiAlertError()
  updateUser(user: User): Observable<User> {
    const url = `api/v1/companies/{companyId}/users/${user.id}`;
    return this.httpClient
      .put<UserApiContract>(url, User.toJson(user))
      .pipe(map(json => User.fromJson(json)));
  }

  @ApiAlertError()
  create(user: User): Observable<User> {
    return this.httpClient
      .post<UserApiContract>(
        'api/v1/companies/{companyId}/users',
        User.toJson(user),
      )
      .pipe(map(json => User.fromJson(json)));
  }
}
