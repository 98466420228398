import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  Input,
} from '@angular/core';
import { TiimePipesModule } from '@manakincubber/tiime-utils';
import { TiimeTooltipModule } from 'tiime-components';

import { StandardDocumentCategoryIdentifier } from '@enums';
import { Category } from '@models';

import { DocumentPendingComponent } from '../../../company/document/document-shared/components/document-pending/document-pending.component';

@Component({
  standalone: true,
  imports: [
    CommonModule,
    DocumentPendingComponent,
    TiimePipesModule,
    TiimeTooltipModule,
  ],
  selector: 'app-category-icon',
  templateUrl: './category-icon.component.html',
  styleUrls: ['./category-icon.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CategoryIconComponent {
  @Input() withTooltip: boolean;

  @Input() isPending: boolean;

  @Input() set greyed(greyed: boolean) {
    if (!greyed) {
      return;
    }

    this.elementRef.nativeElement.setAttribute('greyed', '');
  }

  @Input() set category(value: Category | StandardDocumentCategoryIdentifier) {
    if (typeof value === 'string') {
      this._category = new Category(null);
      this._category.identifier = value;
    } else {
      this._category = value;
    }

    // category could be null only when uploading a document is faster than
    // fetching document categories, which are needed for app-document-uploader-overlay
    this.categoryIconSrc = this._category?.getCategoryIconSrc();
  }

  get category(): Category {
    return this._category;
  }

  private _category: Category;

  readonly StandardDocumentCategoryIdentifier =
    StandardDocumentCategoryIdentifier;

  categoryIconSrc = '';

  constructor(private readonly elementRef: ElementRef<HTMLElement>) {}
}
