import { Component, OnInit } from '@angular/core';
import { NgUtils } from '@manakincubber/tiime-utils';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { combineLatest, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { TiimeOverlayRef } from 'tiime-components';

import { DownloadFilesService, UploadDocumentsService } from '@core';
import { FileTransferStatus } from '@enums';
import { FileTransfer } from '@models';
import { TIIME_ICON_PREFIX } from '@shared';

@UntilDestroy()
@Component({
  selector: 'app-file-transfer-overlay',
  templateUrl: './file-transfer-overlay.component.html',
  styleUrls: ['./file-transfer-overlay.component.scss'],
})
export class FileTransferOverlayComponent implements OnInit {
  readonly TIIME_ICON_PREFIX = TIIME_ICON_PREFIX;
  readonly trackByIndex = NgUtils.trackByIndex;

  private displayContent = true;

  readonly currentFiles$: Observable<FileTransfer[]> = combineLatest([
    this.uploadDocumentsService.queue$,
    this.downloadFilesService.queue$,
  ]).pipe(
    map(([uploadQueue, downloadQueue]) => [...uploadQueue, ...downloadQueue]),
  );

  readonly hasPendingTransfer$: Observable<boolean> = combineLatest([
    this.uploadDocumentsService.hasPendingTransfer$,
    this.downloadFilesService.hasPendingTransfer$,
  ]).pipe(
    map(
      ([hasPendingUpload, hasPendingDownload]) =>
        hasPendingUpload || hasPendingDownload,
    ),
  );

  readonly hasFilesInQueue$ = this.currentFiles$.pipe(
    map(files => files.length > 0),
  );

  readonly pendingTransferringFilesCount$: Observable<number> =
    this.currentFiles$.pipe(
      map(
        files =>
          files.filter(
            file =>
              file.transferStatus !== FileTransferStatus.COMPLETED &&
              file.transferStatus !== FileTransferStatus.ON_ERROR &&
              file.transferStatus !== FileTransferStatus.NO_DATA,
          ).length,
      ),
    );

  readonly headerDescription$ = combineLatest([
    this.currentFiles$,
    this.hasPendingTransfer$,
    this.pendingTransferringFilesCount$,
  ]).pipe(
    map(
      ([currentFiles, hasPendingTransfer, pendingTransferringFilesCount]: [
        FileTransfer[],
        boolean,
        number,
      ]) => {
        if (!currentFiles) {
          return undefined;
        }
        if (hasPendingTransfer) {
          return `${pendingTransferringFilesCount} tâche${
            pendingTransferringFilesCount > 1 ? 's' : ''
          } en cours...`;
        }
        return `${currentFiles.length} tâche${
          currentFiles.length > 1 ? 's' : ''
        } terminée${currentFiles.length > 1 ? 's' : ''}`;
      },
    ),
  );

  get isExpanded(): boolean {
    return this.displayContent;
  }

  constructor(
    private readonly overlayRef: TiimeOverlayRef,
    private readonly uploadDocumentsService: UploadDocumentsService,
    private readonly downloadFilesService: DownloadFilesService,
  ) {}

  ngOnInit(): void {
    this.observeQueues();
  }

  closeOverlay(): void {
    this.overlayRef.close();
  }

  toggleContentDisplay(): void {
    this.displayContent = !this.displayContent;
  }

  private observeQueues(): void {
    this.uploadDocumentsService
      .observeQueue()
      .pipe(untilDestroyed(this))
      .subscribe();

    this.downloadFilesService
      .observeQueue()
      .pipe(untilDestroyed(this))
      .subscribe();
  }
}
