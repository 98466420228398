import { Pipe, PipeTransform } from '@angular/core';

import { VatTypeCode } from '@enums';
import { VatTypeHelper } from '@helpers';
import { VatType } from '@models';

@Pipe({
  name: 'vatRate',
})
export class VatRatePipe implements PipeTransform {
  transform(
    vatTypeCode: VatTypeCode,
    vatTypes: VatType[],
    date?: string,
  ): number {
    return VatTypeHelper.vatRate(vatTypes, vatTypeCode, date);
  }
}
