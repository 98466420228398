import { PaginationData } from 'tiime-components';

import {
  BankTransaction,
  BankTransactionApiContract,
} from './bank-transaction';

export interface BankTransactionsWithMetadataApiContract {
  transactions: BankTransactionApiContract;
  metadata: BankTransactionsMetadataApiContract;
}

export interface BankTransactionsMetadataApiContract {
  total_amount: number;
}

export interface BankTransactionsMetadata {
  totalAmount: number;
}

export class BankTransactionsWithMetadata {
  constructor(
    public transactions: PaginationData<BankTransaction>,
    public metadata: BankTransactionsMetadata,
  ) {}

  static fromJson(
    json: BankTransactionsMetadataApiContract,
  ): BankTransactionsMetadata {
    return { totalAmount: json.total_amount };
  }
}
