<div class="container">
  <a
    class="homelink"
    tiime-sidenav-item
    routerLinkActive="active"
    data-cy="home-menu-link"
    #home="routerLinkActive"
    [routerLink]="['home']"
  >
    <img
      class="tiime-logo"
      alt="app logo"
      draggable="false"
      src="assets/logo_tiime.svg"
    />
  </a>
  <div class="separator"></div>

  @let activatedModules = activatedModules$ | async;
  @let selectedCompany = selectedCompany$ | async;

  <div class="app-links-container" scrollShadows>
    @if (showTodoMenu$ | async) {
      <tiime-sidenav-menu
        theme="todo-theme"
        iconSrc="assets/icon_todo_page.svg"
        routerLinkActive="active"
        menuTitle="A faire"
        data-cy="todo-menu-link"
      >
        <ng-container *hasAccess="UserPermissionNameEnum.ACCOUNTS_USER_FULL">
          <a
            tiime-sidenav-item
            routerLinkActive="active"
            data-cy="expert-requests-menu-link"
            [routerLink]="['todo', 'transactions-expert-request']"
          >
            Comptabilité
          </a>
        </ng-container>

        <ng-container *hasAccess="UserPermissionNameEnum.BUSINESS_USER_FULL">
          @if (selectedCompany?.walletAccess) {
            <a
              tiime-sidenav-item
              routerLinkActive="active"
              data-cy="payments-request-menu-link"
              [routerLink]="['todo', 'sepa-payments-request']"
            >
              Virements à valider
            </a>
          }
        </ng-container>
      </tiime-sidenav-menu>
    }

    <tiime-sidenav-menu
      *hasAccess="[
        UserPermissionNameEnum.ACCOUNTS_USER_FULL,
        UserPermissionNameEnum.BUSINESS_USER_FULL,
        UserPermissionNameEnum.BUSINESS_USER_PARTIAL,
      ]"
      theme="accounts-theme"
      iconSrc="assets/icon_bank_transactions.svg"
      menuTitle="Comptes"
      routerLinkActive="active"
      data-cy="account-menu-link"
    >
      <a
        tiime-sidenav-item
        routerLinkActive="active"
        data-cy="bank-transactions-menu-link"
        [routerLink]="['account', 'transactions']"
      >
        Transactions
      </a>

      @if (showBankTransfersMenu$ | async) {
        <a
          class="bank-transfer-menu-link"
          tiime-sidenav-item
          routerLinkActive="active"
          data-cy="bank-transfer-menu-link"
          [routerLink]="['account', 'bank-transfers']"
          [state]="{ navigationSource: 'menuEntry' }"
        >
          Virements
          <button
            class="bank-transfer-button"
            tiimeTooltip
            tooltipPosition="right"
            tiime-button
            neutral
            flat
            inverted
            xsmall
            icon
            [content]="bankTransferButtonTooltip"
            (click)="openBankTransferOverlay($event)"
          >
            <mat-icon svgIcon="icon-plus"></mat-icon>
          </button>
          <ng-template #bankTransferButtonTooltip>
            Faire un virement
          </ng-template>
        </a>
      }

      @if (showPayinsMenu$ | async) {
        <a
          tiime-sidenav-item
          routerLinkActive="active"
          data-cy="payins-menu-link"
          [routerLink]="['account', 'payins']"
        >
          Prélèvements
        </a>
      }

      <ng-container *hasAccess="UserPermissionNameEnum.BUSINESS_USER_PARTIAL">
        @if (selectedCompany?.walletAccess) {
          <a
            tiime-sidenav-item
            routerLinkActive="active"
            data-cy="tiime-business-menu-link"
            [routerLink]="['account', 'tiime-business']"
          >
            Tiime Business
          </a>
        }
      </ng-container>

      <ng-container *hasAccess="UserPermissionNameEnum.ACCOUNTS_USER_FULL">
        @if (selectedCompany?.cashRegistersAvailable) {
          <a
            tiime-sidenav-item
            routerLinkActive="active"
            data-cy="cash-tracking-menu-link"
            [routerLink]="['cash-tracking']"
          >
            Suivi de caisse
          </a>
        }
      </ng-container>
    </tiime-sidenav-menu>

    <ng-container *hasAccess="UserPermissionNameEnum.ACCOUNTS_USER_FULL">
      @if (isMicroEntrepreneur$ | async) {
        <div class="cash-tracking-theme">
          <a
            class="cash-tracking"
            tiime-sidenav-item
            routerLinkActive="active"
            data-cy="registry-menu-link"
            #registryLink="routerLinkActive"
            [routerLink]="['registry']"
          >
            <img
              class="link-item-icon"
              alt="Registres"
              draggable="false"
              [src]="
                registryLink.isActive
                  ? 'assets/icon_registers_inverted.svg'
                  : 'assets/icon_registers.svg'
              "
            />
            Registres
          </a>
        </div>
      }
    </ng-container>

    <tiime-sidenav-menu
      *hasAccess="UserPermissionNameEnum.INVOICE_USER_PARTIAL"
      theme="invoice-theme"
      iconSrc="assets/icon_invoice.svg"
      menuTitle="Facturation"
      routerLinkActive="active"
      data-cy="invoice-menu-link"
    >
      <a
        tiime-sidenav-item
        routerLinkActive="active"
        data-cy="invoices-menu-link"
        [routerLink]="['invoice', 'invoices']"
      >
        Factures
      </a>

      <a
        tiime-sidenav-item
        routerLinkActive="active"
        data-cy="quotes-menu-link"
        [routerLink]="['invoice', 'quotes']"
      >
        Devis
      </a>

      <a
        tiime-sidenav-item
        routerLinkActive="active"
        data-cy="clients-menu-link"
        [routerLink]="['invoice', 'clients']"
      >
        Clients
      </a>
    </tiime-sidenav-menu>

    <tiime-sidenav-menu
      *hasAccess="UserPermissionNameEnum.ACCOUNTS_USER_PARTIAL"
      theme="expense-theme"
      iconSrc="assets/category-icons/icon_receipt.svg"
      menuTitle="Dépenses"
      routerLinkActive="active"
      data-cy="expense-menu-link"
    >
      <a
        *hasAccess="UserPermissionNameEnum.ACCOUNTS_USER_PARTIAL"
        tiime-sidenav-item
        routerLinkActive="active"
        data-cy="expense-invoice-menu-link"
        [routerLink]="['expense', 'expense-invoice']"
      >
        Factures
      </a>

      <a
        *hasAccess="UserPermissionNameEnum.ACCOUNTS_USER_FULL"
        tiime-sidenav-item
        routerLinkActive="active"
        data-cy="advanced-expenses-menu-link"
        [routerLink]="['expense', 'advanced-expenses']"
      >
        Frais à rembourser
      </a>

      @if (activatedModules?.hasIkAccess) {
        <a
          tiime-sidenav-item
          external-link
          target="_blank"
          rel="noopener"
          [href]="
            getModuleLink('https://ik.tiime.fr', activatedModules.basegiToken)
          "
        >
          <span>Indemnités kms</span>
        </a>
      } @else {
        <a
          *hasAccess="UserPermissionNameEnum.ACCOUNTS_USER_FULL"
          tiime-sidenav-item
          routerLinkActive="active"
          data-cy="mileage-allowances-menu-link"
          [routerLink]="['expense', 'mileage-allowances']"
        >
          Frais kilométriques
        </a>
      }
    </tiime-sidenav-menu>

    <ng-container *hasAccess="UserPermissionNameEnum.ACCOUNTS_USER_PARTIAL">
      @if (activatedModules?.hasDocAccess) {
        <tiime-sidenav-menu
          theme="receipt-theme"
          iconSrc="assets/icon_folder.svg"
          menuTitle="Documents"
          routerLinkActive="active"
          data-cy="document-menu-link"
        >
          <a
            tiime-sidenav-item
            routerLinkActive="active"
            data-cy="documents-menu-link"
            [routerLink]="['documents']"
          >
            Documents
          </a>

          <a
            *hasAccess="UserPermissionNameEnum.ACCOUNTS_USER_FULL"
            tiime-sidenav-item
            external-link
            target="_blank"
            rel="noopener"
            [href]="
              getModuleLink(
                'https://doc.tiime.fr',
                activatedModules.basegiToken
              )
            "
          >
            <span>Anciens documents</span>
          </a>
        </tiime-sidenav-menu>
      } @else {
        <div class="receipt-theme">
          <a
            tiime-sidenav-item
            routerLinkActive="active"
            data-cy="documents-menu-link"
            #rla="routerLinkActive"
            [routerLink]="['documents']"
          >
            <img
              class="link-item-icon"
              alt="Documents"
              draggable="false"
              [src]="
                rla.isActive
                  ? 'assets/icon_folder_inverted.svg'
                  : 'assets/icon_folder.svg'
              "
            />
            Documents
          </a>
        </div>
      }
    </ng-container>

    <ng-container *hasAccess="UserPermissionNameEnum.ACCOUNTS_USER_FULL">
      @if (activatedModules?.hasSocialAccess) {
        <div class="v1-modules">
          <div class="separator"></div>
          <a
            class="social-link"
            tiime-sidenav-item
            external-link
            target="_blank"
            rel="noopener"
            [href]="
              getModuleLink(
                'https://social.tiime.fr',
                activatedModules.basegiToken
              )
            "
          >
            <img
              class="link-item-icon"
              src="assets/app_icon_white_social.svg"
              draggable="false"
              alt="Illustration social"
            />
            Social
          </a>
        </div>
      }
    </ng-container>
  </div>

  <app-sidenav-document-upload
    *hasAccess="UserPermissionNameEnum.ACCOUNTS_USER_FULL"
  />
</div>
