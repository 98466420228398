import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  inject,
  Input,
  Output,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { TrackingService } from '@manakincubber/tiime-tracking';
import { UntilDestroy } from '@ngneat/until-destroy';
import { BehaviorSubject, Observable } from 'rxjs';
import { filter, take, tap } from 'rxjs/operators';

import { BankTransferService } from '@core';
import { BankTransferReceiptInfoClicked } from '@core/amplitude';
import { StandardDocumentCategoryIdentifier } from '@core/enum';
import { isPickerAction } from '@core/utils/bank-transfer.utils';
import { BankTransferDocumentsProofFormArray } from '@forms';
import {
  Beneficiary,
  DecisionTreeDocumentChoice,
  DecisionTreeDocumentNeeded,
  DecisionTreeDocumentPicker,
  Document,
} from '@models';

import { BankTransferDocumentAcceptanceDialogComponent } from '../bank-transfer-document-acceptance-dialog/bank-transfer-document-acceptance-dialog.component';
import { BankTransferInfoDialogComponent } from '../bank-transfer-document-info-dialog/bank-transfer-document-info-dialog.component';

const TRACK_BY_DOCUMENT_NEEDED_LABEL = (
  _: number,
  documentNeeded: DecisionTreeDocumentNeeded,
): string => documentNeeded.label;

export type DocumentFormValue = { document: Document };

@UntilDestroy()
@Component({
  selector: 'app-bank-transfer-document-proof',
  templateUrl: './bank-transfer-document-proof.component.html',
  styleUrls: ['./bank-transfer-document-proof.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BankTransferDocumentProofComponent {
  @Input() beneficiary: Beneficiary;
  @Input() amount: number;

  @Input() set form(form: BankTransferDocumentsProofFormArray | null) {
    this.form$.next(form);
  }

  @Output() readonly selectedChoice =
    new EventEmitter<DecisionTreeDocumentChoice>();

  readonly trackByDocumentNeededLabel =
    TRACK_BY_DOCUMENT_NEEDED_LABEL.bind(this);
  readonly isPickerAction = isPickerAction.bind(this);

  readonly decisionTreeSample$: Observable<DecisionTreeDocumentChoice> =
    this.bankTransferService.loadDocumentProofDecisionTree();
  readonly form$ =
    new BehaviorSubject<BankTransferDocumentsProofFormArray | null>(null);
  readonly documentsNeeded$ = new BehaviorSubject<DecisionTreeDocumentNeeded[]>(
    undefined,
  );

  private readonly trackingService = inject(TrackingService);

  constructor(
    private readonly dialog: MatDialog,
    private readonly bankTransferService: BankTransferService,
  ) {}

  openDocumentInfoDialog(): void {
    this.trackingService.dispatch(new BankTransferReceiptInfoClicked());
    this.dialog.open(BankTransferInfoDialogComponent, { width: '600px' });
  }

  openDocumentAcceptanceDialog(): void {
    this.dialog.open(BankTransferDocumentAcceptanceDialogComponent, {
      width: '650px',
    });
  }

  updateNeededDocuments(neededDocuments: DecisionTreeDocumentNeeded[]): void {
    const previousDocumentsNeeded = Array.isArray(this.documentsNeeded$.value)
      ? [...this.documentsNeeded$.value]
      : undefined;
    let previousFormValue: DocumentFormValue[];

    this.form$
      .pipe(
        take(1),
        tap(form => {
          previousFormValue = Array.isArray(form.value)
            ? [...(form.value as DocumentFormValue[])]
            : [];

          // small view fix otherwise it will throw the error `cannot find control with name ...`
          // because we're clearing the FormArray we're iterating to
          this.documentsNeeded$.next(undefined);
          form.clear();
        }),
        filter(() => {
          const hasNeededDocuments =
            neededDocuments && neededDocuments.length > 0;

          if (!hasNeededDocuments) {
            this.documentsNeeded$.next(neededDocuments);
          }

          return hasNeededDocuments;
        }),
        tap(form => {
          neededDocuments.forEach(neededDocument =>
            form.addDocumentNeeded(neededDocument.mandatory),
          );

          this.addRIBToFormIfNeeded(
            previousFormValue,
            previousDocumentsNeeded,
            neededDocuments,
            form,
          );

          setTimeout(() => this.documentsNeeded$.next(neededDocuments));
        }),
      )
      .subscribe();
  }

  private addRIBToFormIfNeeded(
    previousFormValue: DocumentFormValue[],
    previousDocumentsNeeded: DecisionTreeDocumentNeeded[],
    neededDocuments: DecisionTreeDocumentNeeded[],
    form: BankTransferDocumentsProofFormArray,
  ): void {
    const ribDocument = previousFormValue
      .map(({ document }) => document)
      .find(doc => !doc?.category && !doc?.type);

    // The `document_upload` action is only in remuneration
    const wasInRemunerationStep =
      previousDocumentsNeeded === undefined ||
      previousDocumentsNeeded?.some(
        documentNeeded => documentNeeded.action === 'document_upload',
      );
    const ribIndex = neededDocuments?.findIndex(
      documentNeeded => documentNeeded.action === 'document_upload',
    );
    const isInRemunerationStep = ribIndex >= 0;

    if (ribDocument && wasInRemunerationStep && isInRemunerationStep) {
      form.at(ribIndex).controls.document.setValue(ribDocument);
    }

    const isInvoiceStep = neededDocuments?.some(
      (documentNeeded: DecisionTreeDocumentPicker) =>
        documentNeeded.document_category?.identifier ===
        StandardDocumentCategoryIdentifier.RECEIPT,
    );

    if (isInvoiceStep && previousFormValue[0]) {
      form.at(0).controls.document.setValue(previousFormValue[0].document);
    }
  }
}
