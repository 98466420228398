<div
  class="inputs-container"
  [class.inputs-container--warn]="color === 'warn'"
  [class.inputs-container--accent]="color === 'accent'"
>
  <input
    #input
    type="text"
    [placeholder]="masked ? '﹡' : ''"
    [class.first-of-group]="i % groupSize === 0 && i !== 0"
    (paste)="onPaste($event, i)"
    (input)="onInput($event, i)"
    (keydown)="onKeydown($event, i)"
    (focus)="onFocus(i)"
    (blur)="onBlur(i)"
    [disabled]="disabled$ | async"
    *ngFor="let holder of placeholders; trackBy: trackByIndex; index as i"
  />

  <mat-icon
    fontIcon="icon-check-1c-r-b-24-fontastic"
    color="accent"
    *ngIf="showCheckmarkOnInputValid && valid"
  ></mat-icon>
</div>

<div class="hidden-inputs-container">
  <input
    #hiddenInput
    type="text"
    [disabled]="disabled$ | async"
    *ngFor="let holder of placeholders; trackBy: trackByIndex; index as i"
  />
</div>
