import { InvoicingTemplate } from '@enums';
import { Tag, TextLine } from '@models';

import { Line } from '../models/line';
import { AbstractInvoicingDocumentBase } from './abstract-invoicing-document-base';

export abstract class AbstractInvoicingDocumentListItem extends AbstractInvoicingDocumentBase {
  protected constructor(
    id?: number,
    title?: string,
    invoicingDocumentNumber?: number,
    emissionDate?: string,
    totalExcludingTaxes?: number,
    totalIncludingTaxes?: number,
    lines?: Line[],
    textLines?: TextLine[],
    color?: string,
    template?: InvoicingTemplate,
    comment?: string,
    tags?: Tag[],
    unitsEnabled?: boolean,
    public clientId?: number,
    public clientName?: string,
  ) {
    super(
      id,
      title,
      invoicingDocumentNumber,
      emissionDate,
      totalExcludingTaxes,
      totalIncludingTaxes,
      lines,
      textLines,
      color,
      template,
      comment,
      tags,
      unitsEnabled,
    );
  }
}
