import { FormControl, FormGroup, Validators } from '@angular/forms';

import { CreateBeneficiaryBody } from '@models';

import {
  ibanPatternValidator,
  ibanValidator,
} from './validators/iban-validator';

export class AddBeneficiaryForm extends FormGroup {
  get name(): FormControl<string> {
    return this.get('name') as FormControl<string>;
  }

  get iban(): FormControl<string> {
    return this.get('iban') as FormControl<string>;
  }

  get ibanErrorMessage(): string {
    return this.iban.touched &&
      (this.iban.hasError('validateIban') || this.iban.hasError('regexIban'))
      ? `Cet IBAN n'est pas valide`
      : '';
  }

  constructor(name: string | null, iban = '') {
    super({
      name: new FormControl<string>(name, [Validators.required]),
      iban: new FormControl<string>(iban, [
        Validators.required,
        ibanValidator,
        ibanPatternValidator,
      ]),
    });
  }

  toCreateBeneficiaryBody(): Omit<CreateBeneficiaryBody, 'bic'> {
    return {
      iban: this.iban.value.replace(/\s/g, ''),
      name: this.name.value,
    };
  }
}
