import { CustomDashboardIndicatorType } from '@enums/dashboards';

import { TypedDashboardIndicator } from '../../../company/home/indicators/dashboards/dashboards-shared/dashboard-customization/types';
import { SimpleBankTransactionLabel } from '../labels';
import { Tag } from '../tag';
import { CustomDashboardDatasetIndicatorsJson } from './custom-dashboard-dataset-indicators';
import { StandardIndicator } from './standard-indicator';

const createTypedIndicatorsArray = (
  json: CustomDashboardDatasetIndicatorsJson,
): TypedDashboardIndicator[] => {
  return [
    ...json.standard_indicators.map(indicator => ({
      key: indicator,
      type: CustomDashboardIndicatorType.StandardIndicator,
    })),
    ...json.tags.map(indicator => ({
      id: indicator,
      type: CustomDashboardIndicatorType.Tag,
    })),
    ...json.labels.map(indicator => ({
      id: indicator,
      type: CustomDashboardIndicatorType.Label,
    })),
  ];
};

const reduceToIndicatorsJson = (
  typedIndicators: TypedDashboardIndicator[],
): CustomDashboardDatasetIndicatorsJson => {
  return typedIndicators.reduce(
    (a: CustomDashboardDatasetIndicatorsJson, b: TypedDashboardIndicator) => {
      if (b.type === CustomDashboardIndicatorType.StandardIndicator) {
        return {
          ...a,
          standard_indicators: [
            ...a.standard_indicators,
            (b as StandardIndicator).key,
          ],
        };
      } else if (b.type === CustomDashboardIndicatorType.Label) {
        return {
          ...a,
          labels: [...a.labels, (b as SimpleBankTransactionLabel).id],
        };
      } else {
        return {
          ...a,
          tags: [...a.tags, (b as Tag).id],
        };
      }
    },
    new CustomDashboardDatasetIndicatorsJson(),
  );
};

export class CustomDataset {
  constructor(
    public color: string,
    public add: TypedDashboardIndicator[],
    public sub?: TypedDashboardIndicator[],
    public title?: string,
    public reversed?: boolean,
    /** Used for tracking dataset when deleting another and for the DatasetIndicatorsSearchService's subjects */
    public trackId?: string,
  ) {}

  static fromJson(json: CustomDatasetApiContract): CustomDataset {
    return new CustomDataset(
      json.color,
      createTypedIndicatorsArray(json.add),
      createTypedIndicatorsArray(json.sub),
      json.title,
      json.reversed,
    );
  }

  static toJson(customDataset: CustomDataset): CustomDatasetApiContract {
    return {
      title: customDataset.title,
      color: customDataset.color,
      reversed: customDataset.reversed,
      add: reduceToIndicatorsJson(customDataset.add),
      sub: reduceToIndicatorsJson(customDataset.sub),
    };
  }
}

export interface CustomDatasetApiContract {
  color: string;
  add: CustomDashboardDatasetIndicatorsJson;
  sub?: CustomDashboardDatasetIndicatorsJson;
  title?: string;
  reversed?: boolean;
}
