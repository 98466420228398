<div
  *tiimeLet="linkedEntity | linkedEntityAmountAndCurrency as amountAndCurrency"
  class="line-content"
>
  <app-bank-logo
    [bankAccount]="linkedEntity.value.bankAccount"
    [isTooltipDisabled]="false"
  ></app-bank-logo>

  <tiime-date-list-cell [date]="linkedEntity.value.date"></tiime-date-list-cell>
  <app-operation-type-logo
    [operationType]="linkedEntity.value.operationType"
    [oneColor]="true"
  ></app-operation-type-logo>
  <div class="linked-transaction-name">{{ linkedEntity.value.wording }}</div>
  <div class="linked-transaction-amount">
    {{ amountAndCurrency.amount | currency: amountAndCurrency.currency }}
  </div>
</div>
