import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ValidatorsUtils } from '@manakincubber/tiime-utils';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { tap } from 'rxjs';

@UntilDestroy()
export class CreatePasswordForm extends FormGroup {
  get differentPasswordsErrorMessage(): string {
    return this.touched && this.hasError('matchingPassword')
      ? 'Les mots de passe ne sont pas identiques'
      : null;
  }

  get password(): FormControl {
    return this.get('password') as FormControl;
  }

  get passwordErrorMessage(): string {
    if (this.password.hasError('required')) {
      return this.password.touched && 'Mot de passe requis';
    } else {
      return this.password.touched && this.password.hasError('validatePassword')
        ? 'Les conditions ne sont pas remplies'
        : null;
    }
  }

  get passwordConfirmation(): FormControl {
    return this.get('passwordConfirmation') as FormControl;
  }

  get passwordConfirmationErrorMessage(): string {
    return this.passwordConfirmation.touched &&
      (this.passwordConfirmation.hasError('required') ||
        this.passwordConfirmation.hasError('matchingPassword'))
      ? 'Confirmation du mot de passe requise'
      : null;
  }

  constructor() {
    super(
      {
        password: new FormControl('', [
          Validators.required,
          ValidatorsUtils.passwordValidator(),
        ]),
        passwordConfirmation: new FormControl('', Validators.required),
      },
      {
        validators: ValidatorsUtils.controlComparisonValidator(
          'password',
          'passwordConfirmation',
          (password: string, passwordConfirmation: string) =>
            password === passwordConfirmation,
          'matchingPassword',
        ),
      },
    );
    this.setConfirmationToErrorIfDifferent();
  }

  setConfirmationToErrorIfDifferent(): void {
    this.valueChanges
      .pipe(
        tap(() => {
          if (this.hasError('matchingPassword')) {
            this.passwordConfirmation.setErrors({ matchingPassword: true });
          } else {
            this.passwordConfirmation.setErrors(null);
          }
        }),
        untilDestroyed(this),
      )
      .subscribe();
  }

  toPassword(): string {
    return btoa(this.password.value);
  }
}
