import { ClientTimelineCashFlowItem } from './client-timeline-cash-flow-item';
import { ClientTimelineExternalInvoiceItem } from './client-timeline-external-invoice-item';
import { ClientTimelineInvoiceItem } from './client-timeline-invoice-item';
import {
  ClientTimelineItem,
  ClientTimelineItemApiContract,
} from './client-timeline-item';
import { ClientTimelineBankTransactionItem } from './client-timeline-transaction-item';
import { ClientTimelineItemType } from './types';

export function deserializeClientTimelineItemContract(
  json: ClientTimelineItemApiContract,
): ClientTimelineItem {
  switch (json.type) {
    case ClientTimelineItemType.bankTransaction:
      return ClientTimelineBankTransactionItem.fromJson(json);
    case ClientTimelineItemType.cashFlow:
      return ClientTimelineCashFlowItem.fromJson(json);
    case ClientTimelineItemType.externalInvoice:
      return ClientTimelineExternalInvoiceItem.fromJson(json);
    default:
      return ClientTimelineInvoiceItem.fromJson(json);
  }
}
