import {
  AbstractControl,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { ValidatorsUtils } from '@manakincubber/tiime-utils';
import moment from 'moment';

import { ApeCode } from '@core/models';
import { MomentHelper } from '@helpers';
import { INTRACOM_VAT_REGEX, SIRET_REGEX } from '@shared';

import { Company } from '../models/company';
import { RncsCompany } from '../models/rncs-company';

export class CompanyForm extends FormGroup {
  get apeCode(): FormControl<ApeCode | string> {
    return this.get('apeCode') as FormControl<ApeCode | string>;
  }
  get capital(): FormControl<number> {
    return this.get('capital') as FormControl<number>;
  }
  get city(): FormControl<string> {
    return this.get('city') as FormControl<string>;
  }
  get legalForm(): FormControl<string> {
    return this.get('legalForm') as FormControl<string>;
  }
  get name(): FormControl<string> {
    return this.get('name') as FormControl<string>;
  }
  get nameErrorMessage(): string {
    return this.name.touched && this.name.hasError('required')
      ? `Nom de la société requis`
      : '';
  }
  get postalCode(): FormControl<string> {
    return this.get('postalCode') as FormControl<string>;
  }
  get rcsCity(): FormControl<string> {
    return this.get('rcsCity') as FormControl<string>;
  }
  get registrationDate(): FormControl<string> {
    return this.get('registrationDate') as FormControl<string>;
  }
  get activityStartDate(): FormControl<string> {
    return this.get('activityStartDate') as FormControl<string>;
  }
  get siret(): FormControl<string> {
    return this.get('siret') as FormControl;
  }
  get siretErrorMessage(): string {
    if (this.siret.touched && this.siret.hasError('pattern')) {
      return 'Format SIRET invalide';
    } else {
      return this.siret.hasError('validateSiret') ? 'SIRET invalide' : '';
    }
  }
  get intracomVatNumber(): FormControl<string> {
    return this.get('intracomVatNumber') as FormControl<string>;
  }
  get vatNumberErrorMessage(): string {
    return this.intracomVatNumber.touched &&
      this.intracomVatNumber.hasError('pattern')
      ? 'Format invalide'
      : '';
  }
  get street(): FormControl<string> {
    return this.get('street') as FormControl<string>;
  }
  get addressComplement(): FormControl<string> {
    return this.get('addressComplement') as FormControl<string>;
  }

  constructor(controls?: { [key: string]: AbstractControl }) {
    let companyControls = {
      name: new FormControl<string>(null, Validators.required),
      postalCode: new FormControl<string>(null),
      city: new FormControl<string>(null),
      street: new FormControl<string>(null),
      addressComplement: new FormControl<string>(null),
      legalForm: new FormControl<string>(null),
      siret: new FormControl<string>(
        null,
        Validators.compose([
          Validators.pattern(SIRET_REGEX),
          ValidatorsUtils.siretValidator(),
        ]),
      ),
      intracomVatNumber: new FormControl<string>(
        null,
        Validators.pattern(INTRACOM_VAT_REGEX),
      ),
      capital: new FormControl<number>(null),
      rcsCity: new FormControl<string>(null),
      apeCode: new FormControl<string>(null),
      registrationDate: new FormControl<string>(null),
      activityStartDate: new FormControl<string>(null),
    };
    if (controls) {
      companyControls = { ...controls, ...companyControls };
    }
    super(companyControls);
  }

  fromCompany(company: Company): void {
    this.patchValue({
      name: company.name,
      postalCode: company.postalCode,
      city: company.city,
      street: company.street,
      addressComplement: company.addressComplement,
      legalForm: company.legalForm,
      siret: company.siret,
      intracomVatNumber: company.intracomVatNumber,
      capital: company.shareCapital || null,
      rcsCity: company.rcsCity,
      apeCode: company.apeCode,
      registrationDate: company.registrationDate,
      activityStartDate: company.activityStartDate,
    });
  }

  reconciliateApiAndRncs(rncsCompany: RncsCompany): void {
    const existingFieldsInApi = {
      name: rncsCompany.name,
      postalCode: rncsCompany.postcode,
      city: rncsCompany.city,
      street: rncsCompany.address,
      legalForm: rncsCompany.legalForm,
      capital: rncsCompany.capital,
    };
    Object.keys(existingFieldsInApi)
      .filter(
        (key: string) =>
          this.get(key).value !== null && this.get(key).value !== '',
      )
      .forEach((key: string) =>
        Reflect.deleteProperty(existingFieldsInApi, key),
      );
    this.patchValue({
      ...existingFieldsInApi,
      siret: rncsCompany.siret,
      rcsCity: rncsCompany.rcs,
      apeCode: rncsCompany.ape,
      registrationDate: rncsCompany.registrationDate,
    });
  }

  toCompany(): Company {
    const company = new Company();
    company.name = this.name.value;
    company.postalCode = this.postalCode.value || null;
    company.city = this.city.value || null;
    company.street = this.street.value || null;
    company.addressComplement = this.addressComplement.value || null;
    company.legalForm = this.legalForm.value || null;
    company.siret = this.siret.value || null;
    company.intracomVatNumber = this.intracomVatNumber.value || null;
    company.shareCapital = this.capital.value || null;
    company.rcsCity = this.rcsCity.value || null;
    company.apeCode =
      (this.apeCode.value as ApeCode)?.code ||
      (this.apeCode.value as string) ||
      null;
    company.registrationDate = this.registrationDate.value
      ? MomentHelper.toCalendarDateString(moment(this.registrationDate.value))
      : null;
    company.activityStartDate = this.activityStartDate.value
      ? MomentHelper.toCalendarDateString(moment(this.activityStartDate.value))
      : null;
    return company;
  }
}
