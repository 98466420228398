import { inject } from '@angular/core';
import { Events } from '@manakincubber/tiime-tracking';
import { Store } from '@ngrx/store';

import { AmplitudeEvents } from '@core/amplitude';
import { AMPLI } from '@core/amplitude/tokens';

export abstract class BaseTrackingGroup {
  protected readonly events$ = inject<Events<AmplitudeEvents>>(Events);
  protected readonly ampli = inject(AMPLI);
  protected readonly store = inject(Store);
}
