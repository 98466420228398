import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'tr[no-data-row]',
  templateUrl: './no-data-row.component.html',
  styleUrls: ['./no-data-row.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NoDataRowComponent {
  // eslint-disable-next-line @angular-eslint/no-input-rename
  @Input('no-data-row') text = '';
}
