import { ChangeDetectionStrategy, Component } from '@angular/core';
import { MatSnackBarRef } from '@angular/material/snack-bar';

@Component({
  selector: 'app-label-created-transaction-snackbar',
  templateUrl: './label-created-transaction-snackbar.component.html',
  styleUrls: ['./label-created-transaction-snackbar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LabelCreatedTransactionSnackbarComponent {
  constructor(
    public readonly snackbarRef: MatSnackBarRef<LabelCreatedTransactionSnackbarComponent>,
  ) {}
}
