<ng-container *tiimeLet="categoryOptions$ | async as categoryOptions">
  <div class="container">
    <tiime-select
      *ngIf="categoryOptions"
      small
      data-cy="select__category"
      #categorySelect
      [selected]="selectedCategory$ | async"
      [compareWith]="categoriesComparator"
      [disabled]="disabled"
      [options]="categoryOptions"
      (valueChanged)="onSelectCategory($event)"
    ></tiime-select>
    <mat-icon *ngIf="disabled" svgIcon="icon-lock"></mat-icon>
  </div>
  <button
    class="hidden-input"
    #menuTrigger="matMenuTrigger"
    [matMenuTriggerFor]="menu"
  ></button>
  <mat-menu
    class="document-category-name-panel"
    #menu="matMenu"
    [overlapTrigger]="true"
    (closed)="onMenuClose()"
  >
    <form
      [formGroup]="createCategoryForm"
      (click)="$event.stopPropagation()"
      (ngSubmit)="onCreateCategory()"
    >
      <tiime-input-container
        label="Nom de l'espace"
        [errorMessage]="createCategoryForm.errorMessage"
      >
        <input
          tiimeInputContainerNativeControl
          formControlName="name"
          data-cy="category-select__input-name"
          (click)="$event.stopPropagation()"
        />
      </tiime-input-container>
      <button tiime-button accent data-cy="category-select__btn-add">
        Créer l'espace
      </button>
    </form>
  </mat-menu>
</ng-container>
