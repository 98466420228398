import { Directive, Input } from '@angular/core';

import {
  LinkedEntityBankTransaction,
  LinkedEntityExpenseReport,
  LinkedEntityImputation,
} from '@models/linked-entities';

@Directive()
export abstract class AbstractLinkedLineDirective<
  T extends
    | LinkedEntityBankTransaction
    | LinkedEntityExpenseReport
    | LinkedEntityImputation,
> {
  @Input() linkedEntity: T;
}
