<div class="hint__container" data-cy="warning-banner">
  <mat-icon class="warning-icon" svgIcon="icon-warning-filled"></mat-icon>
  <div class="hint__text">
    <div class="hint__title">
      {{ title }}
    </div>
    <div class="hint__subtitle">
      {{ subtitle }}
    </div>
  </div>
</div>
