import { inject, Injectable } from '@angular/core';
import { Router, UrlTree } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { filter, map, switchMap, take, tap } from 'rxjs/operators';

import { filterNotNullOrUndefined } from '@helpers';
import {
  isKycValidatedSelector,
  loadWalletAccount,
  walletAccountSelector,
} from '@store/wallet-account';

export function isKycValidated(): Observable<boolean> {
  loadWalletAccountIfNeeded();

  const store = inject(Store);

  return store.select(walletAccountSelector).pipe(
    filterNotNullOrUndefined(),
    take(1),
    switchMap(() => store.select(isKycValidatedSelector)),
  );
}

function loadWalletAccountIfNeeded(): void {
  const store = inject(Store);

  store
    .pipe(
      select(walletAccountSelector),
      take(1),
      filter(walletAccount => !walletAccount),
      tap(() => store.dispatch(loadWalletAccount())),
    )
    .subscribe();
}

@Injectable({
  providedIn: 'root',
})
export class IsKycValidatedGuard {
  constructor(private readonly router: Router) {}

  canMatch(): Observable<boolean | UrlTree> {
    return isKycValidated().pipe(
      map(isKycValidated => {
        if (!isKycValidated) {
          return this.router.createUrlTree(['companies']);
        }
        return isKycValidated;
      }),
    );
  }
}
