import {
  FormArray,
  Validators,
  FormControl,
  ValidatorFn,
} from '@angular/forms';

export class RecipientMailFormArray extends FormArray {
  constructor(validatorFn?: ValidatorFn) {
    super([], validatorFn);
  }

  addRecipient(recipient: string): void {
    this.push(
      new FormControl(
        recipient,
        Validators.compose([Validators.required, Validators.email]),
      ),
    );
    this.markAsTouched();
  }

  removeRecipient(recipientIndex: number): void {
    this.removeAt(recipientIndex);
    this.markAsTouched();
  }

  toRecipients(): string[] {
    return this.getRawValue().filter(mail => !!mail);
  }
}
