<th tiimeTableHeaderCell="selection">
  <mat-checkbox
    large
    [indeterminate]="indeterminate"
    [checked]="areAllSelected"
    (change)="selectAll.emit()"
  ></mat-checkbox>
  <button
    *ngIf="quickSelection"
    class="menu-trigger-button"
    type="button"
    [matMenuTriggerFor]="quickSelectionMenu"
  >
    <mat-icon
      svgIcon="tc_icon-caret-down"
      [matMenuTriggerFor]="quickSelectionMenu"
    ></mat-icon>
  </button>
  <mat-menu #quickSelectionMenu="matMenu">
    <div
      *ngFor="let selection of quickSelection; trackBy: trackByIndex"
      mat-menu-item
      (click)="selection.action(); trackingSelection.emit(selection.label)"
    >
      {{ selection.label }}
    </div>
  </mat-menu>
</th>
