import { NgModule } from '@angular/core';
import { MatIconModule, MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';

@NgModule({
  imports: [MatIconModule],
  exports: [MatIconModule],
  providers: [MatIconRegistry],
})
export class TiimeIconModule {
  constructor(
    private readonly matIconRegistry: MatIconRegistry,
    private readonly domSanitizer: DomSanitizer,
  ) {
    matIconRegistry.setDefaultFontSetClass('tiime-fontastic');
    this.addCustomSvgIcons();
  }

  private addCustomSvgIcons(): void {
    const infoIcons = [{ name: 'icon-warning', path: 'info/icon-warning.svg' }];

    const actionsIcons = [
      { name: 'icon-close', path: 'actions/icon-close.svg' },
      { name: 'icon-button-close', path: 'actions/icon-button-close.svg' },
      { name: 'icon-open-window', path: 'actions/icon-open-window.svg' },
    ];

    const miscIcons = [
      { name: 'icon-arrow-left', path: 'misc/icon-arrow-left.svg' },
      { name: 'icon-arrow-slim', path: 'misc/icon-arrow-slim.svg' },
      { name: 'icon-caret-down', path: 'misc/icon-caret-down.svg' },
      { name: 'icon-check', path: 'misc/icon-check.svg' },
      { name: 'icon-chevron-down', path: 'misc/icon-chevron-down.svg' },
      { name: 'icon-chevron-right', path: 'misc/icon-chevron-right.svg' },
      { name: 'icon-cross-slim', path: 'misc/icon-cross-slim.svg' },
      { name: 'icon-long-arrow-right', path: 'misc/icon-long-arrow-right.svg' },
      { name: 'icon-search', path: 'misc/icon-search.svg' },
    ];

    [...infoIcons, ...actionsIcons, ...miscIcons].forEach(icon => {
      this.matIconRegistry.addSvgIcon(
        'tc_' + icon.name,
        this.domSanitizer.bypassSecurityTrustResourceUrl(
          `/dist/tiime-components/assets/icons/${icon.path}`,
        ),
      );
    });
  }
}
