<tiime-dialog-close-button></tiime-dialog-close-button>

<div class="dialog-title" mat-dialog-title>
  Les justificatifs sélectionnés deviendront des frais à rembourser
</div>

<ng-container *tiimeLet="selection$ | async as selection">
  <mat-dialog-content
    *tiimeLet="receiptsTableState$ | async as receiptsTableState"
    class="dialog-content"
  >
    <div
      class="table-actions"
      [class.table-actions--invisible]="
        receiptsTableState !== TableState.done &&
        receiptsTableState !== TableState.noResult
      "
    >
      <tiime-search-bar
        placeholder="Rechercher dans mes justificatifs..."
        #searchBar
      ></tiime-search-bar>

      <p *ngIf="selection.length > 0">
        {{ selection.length }}
        <ng-container [ngPlural]="selection.length">
          <ng-template ngPluralCase="=1">justificatif sélectionné</ng-template>
          <ng-template ngPluralCase="other">
            justificatifs sélectionnés
          </ng-template>
        </ng-container>
      </p>

      <tiime-paginator
        #paginator
        [range]="documentsTable?.paginationData?.paginationRange"
      ></tiime-paginator>
    </div>
    <div
      class="table-wrapper"
      [class.table-wrapper--hidden]="
        receiptsTableState === TableState.contentPlaceholder
      "
    >
      <app-documents-table
        matchingType="expense_reports"
        #documentsTable
        [categoryId]="data.categoryId"
        [paginator]="paginator"
        [searchBar]="searchBar"
        [flat]="true"
        [disabledMatchedRows]="true"
        [disabledRows]="data.alreadyAddedReceipts"
        [customActions]="customActions"
        [selectedDocuments]="selection"
        [selectedDocumentsCategoryIdentifier]="
          StandardDocumentCategoryIdentifier.ADVANCED_EXPENSE
        "
        (stateChange)="handleStateChange($event)"
        (rowClick)="updateSelection($event)"
      ></app-documents-table>
    </div>

    <tiime-table-content-placeholder
      *ngIf="receiptsTableState === TableState.contentPlaceholder"
      [withoutElevation]="true"
      [withoutBorderRadius]="true"
      [withoutBorders]="true"
      [linesLength]="11"
    ></tiime-table-content-placeholder>
  </mat-dialog-content>

  <mat-dialog-actions class="dialog-actions">
    <button tiime-button accent inverted mat-dialog-close>Annuler</button>
    <button
      tiime-button
      accent
      [disabled]="selection.length === 0"
      (click)="submitMatch(selection)"
    >
      Ajouter à la note de frais
    </button>
  </mat-dialog-actions>
</ng-container>
