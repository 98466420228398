import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { TiimeLetModule, TiimeOverlayModule } from 'tiime-components';

import { IconsModule } from '@shared';

import { LabelChipModule } from '../../labels';
import { CanCreateLabelOrTagPipe } from './can-create-label-or-tag.pipe';
import { LabelSelectionOverlayComponent } from './label-selection-overlay.component';
import { LastUsedLabelsComponent } from './last-used-labels/last-used-labels.component';

@NgModule({
  declarations: [LabelSelectionOverlayComponent],
  imports: [
    LastUsedLabelsComponent,
    CommonModule,
    IconsModule,
    LabelChipModule,
    ReactiveFormsModule,
    TiimeOverlayModule,
    TiimeLetModule,
    CanCreateLabelOrTagPipe,
  ],
  exports: [LabelSelectionOverlayComponent],
})
export class LabelSelectionOverlayModule {}
