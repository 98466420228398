export interface TagApiContract {
  id: number;
  name?: string;
  is_automatic?: boolean;
}

export class Tag {
  constructor(
    public id?: number,
    public name?: string,
    public isAutomatic?: boolean,
  ) {}

  static fromJson(json: TagApiContract): Tag {
    return new Tag(json.id, json.name, json.is_automatic);
  }

  static toJson(tag: Tag): TagApiContract {
    return {
      id: tag.id,
      name: tag.name,
      is_automatic: tag.isAutomatic,
    };
  }
}
