import { DragDropModule } from '@angular/cdk/drag-drop';
import { OverlayModule } from '@angular/cdk/overlay';
import { APP_INITIALIZER, NgModule } from '@angular/core';
import { MomentDateModule } from '@angular/material-moment-adapter';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import {
  MAT_DATE_FORMATS,
  MAT_DATE_LOCALE,
  MAT_RIPPLE_GLOBAL_OPTIONS,
} from '@angular/material/core';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MAT_TABS_CONFIG } from '@angular/material/tabs';
import {
  initializeGooglePlacesModule,
  TIIME_DATE_FORMATS,
  TIIME_RIPPLE_GLOBAL_OPTIONS,
  TiimeActionBarModule,
  TiimeAutoFocusModule,
  TiimeAutoSaveModule,
  TiimeButtonModule,
  TiimeCardModule,
  TiimeCheckboxModule,
  TiimeDateListCellModule,
  TiimeDatepickerModule,
  TiimeDialogModule,
  TiimeEmptyStateModule,
  TiimeFileModule,
  TiimeGooglePlacesModule,
  TiimeIconModule,
  TiimeInputContainerModule,
  TiimeLetModule,
  TiimeListModule,
  TiimeLoaderModule,
  TiimeMailModule,
  TiimeMenuModule,
  TiimeMultilineEllipsisModule,
  TiimeOverlayModule,
  TiimePaginatorModule,
  TiimeProgressBarModule,
  TiimeRadioModule,
  TiimeSearchBarModule,
  TiimeSidenavLayoutModule,
  TiimeSimpleEmptyStateModule,
  TiimeSlideToggleModule,
  TiimeSnackbarModule,
  TiimeSortModule,
  TiimeTableModule,
  TiimeTagModule,
  TiimeTooltipModule,
  TiimeTwocolorsIconModule,
} from 'tiime-components';
import 'moment/locale/fr'; // https://github.com/angular/components/issues/28263

import { AppConfigService } from '@core/services/app-config.service';

@NgModule({
  imports: [MomentDateModule, TiimeGooglePlacesModule],
  providers: [
    {
      provide: APP_INITIALIZER,
      useFactory: (appConfigService: AppConfigService): (() => Promise<void>) =>
        appConfigService.appConfigFactory<void>(() =>
          initializeGooglePlacesModule(
            appConfigService.appConfig.GOOGLE_API_KEY,
          ),
        ),
      deps: [AppConfigService],
      multi: true,
    },
    {
      provide: MAT_RIPPLE_GLOBAL_OPTIONS,
      useValue: TIIME_RIPPLE_GLOBAL_OPTIONS,
    },
    { provide: MAT_DATE_FORMATS, useValue: TIIME_DATE_FORMATS },
    { provide: MAT_DATE_LOCALE, useValue: 'fr-FR' },
    { provide: MAT_TABS_CONFIG, useValue: { animationDuration: '0ms' } },
  ],
  exports: [
    TiimeSlideToggleModule,
    TiimeCheckboxModule,
    TiimeMenuModule,
    TiimeRadioModule,
    TiimeButtonModule,
    TiimePaginatorModule,
    TiimeTableModule,
    TiimeSearchBarModule,
    TiimeIconModule,
    TiimeSnackbarModule,
    TiimeTagModule,
    TiimeActionBarModule,
    TiimeCardModule,
    TiimeTooltipModule,
    TiimeAutoSaveModule,
    TiimeDialogModule,
    TiimeInputContainerModule,
    TiimeGooglePlacesModule,
    TiimeEmptyStateModule,
    TiimeMailModule,
    TiimeListModule,
    TiimeTwocolorsIconModule,
    TiimeFileModule,
    TiimeDatepickerModule,
    TiimeProgressBarModule,
    TiimeLoaderModule,
    TiimeDateListCellModule,
    TiimeSimpleEmptyStateModule,
    MatButtonToggleModule,
    MatProgressSpinnerModule,
    TiimeOverlayModule,
    TiimeSidenavLayoutModule,
    TiimeMultilineEllipsisModule,
    TiimeSortModule,
    OverlayModule,
    DragDropModule,
    TiimeAutoFocusModule,
    TiimeLetModule,
    MatAutocompleteModule,
  ],
})
export class MaterialModule {}
