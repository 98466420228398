import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { iif, Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';

import { ApiAlertError } from '@decorators/api-alert-error';
import {
  Bank,
  BankAccount,
  BIBankConnection,
  BIConnectionApiContract,
} from '@models';

import { ApiService } from './api.service';
import { AppConfigService } from './services/app-config.service';

@Injectable({
  providedIn: 'root',
})
export class BudgetInsightService {
  static BUDGET_INSIGHT_DOMAIN_PREFIX = 'budget-insight-prefix-domain';
  static STORAGE_BI_TOKEN = 'bi_token';

  constructor(
    private readonly http: HttpClient,
    private readonly apiService: ApiService,
    private readonly appConfigService: AppConfigService,
  ) {}

  getUserToken(): string {
    return localStorage.getItem(BudgetInsightService.STORAGE_BI_TOKEN);
  }

  removeUserToken(): void {
    localStorage.removeItem(BudgetInsightService.STORAGE_BI_TOKEN);
  }

  setUserToken(token: string): void {
    localStorage.setItem(BudgetInsightService.STORAGE_BI_TOKEN, token);
  }

  getConnectionWebviewUrl(
    bank: Bank,
    redirectUri: string,
    state: string,
  ): Observable<string> {
    const token = this.getUserToken();
    return iif(() => !!token, of(token), this.apiService.getBudgeaToken()).pipe(
      map((freshToken: string) =>
        this.createOpenConnectionWebviewUrl(
          freshToken,
          bank.providerId,
          redirectUri,
          state,
        ),
      ),
    );
  }

  getReconnectionWebviewUrl(
    connectionId: number,
    redirectUri: string,
    state: string,
  ): Observable<string> {
    const token = this.getUserToken();
    return iif(() => !!token, of(token), this.apiService.getBudgeaToken()).pipe(
      map((freshToken: string) =>
        this.createOpenReconnectionWebviewUrl(
          freshToken,
          connectionId,
          redirectUri,
          state,
        ),
      ),
    );
  }

  @ApiAlertError()
  getConnections(): Observable<BIBankConnection[]> {
    const url = `${BudgetInsightService.BUDGET_INSIGHT_DOMAIN_PREFIX}/users/me/connections?expand=all_accounts,connector`;

    return this.http
      .get(url)
      .pipe(
        map((connectionsJson: { connections: BIConnectionApiContract[] }) =>
          connectionsJson.connections
            .map((connectionJson: BIConnectionApiContract) =>
              BIBankConnection.fromJson(connectionJson),
            )
            .filter((biConnection: BIBankConnection) =>
              biConnection.connector.capabilities.includes('bank'),
            ),
        ),
      );
  }

  @ApiAlertError([])
  getConnectionId(connectionId: number): Observable<BIBankConnection> {
    const url = `${BudgetInsightService.BUDGET_INSIGHT_DOMAIN_PREFIX}/users/me/connections/${connectionId}`;
    const params = new HttpParams({
      fromObject: {
        expand: ['all_accounts', 'connector'].join(),
      },
    });
    return this.http
      .get<BIConnectionApiContract>(url, { params })
      .pipe(
        map((connectionJson: BIConnectionApiContract) =>
          BIBankConnection.fromJson(connectionJson),
        ),
      );
  }

  @ApiAlertError([])
  deleteBankConnection(connectionId: number): Observable<unknown> {
    const url = `${BudgetInsightService.BUDGET_INSIGHT_DOMAIN_PREFIX}/users/me/connections/${connectionId}`;
    return this.http.delete(url);
  }

  @ApiAlertError([])
  enableOrDisableBankAccount(bankAccount: BankAccount): Observable<unknown> {
    const url = `${BudgetInsightService.BUDGET_INSIGHT_DOMAIN_PREFIX}/users/me/accounts/${bankAccount.providerId}?all`;
    return this.http.post(url, { disabled: !bankAccount.enabled });
  }

  private createOpenConnectionWebviewUrl(
    token: string,
    connectorId: number,
    redirectUri: string,
    state: string,
  ): string {
    const appConfig = this.appConfigService.appConfig;
    return (
      `${appConfig.BI_WEBVIEW_URL_BASE}/connect` +
      `?client_id=${appConfig.BI_CLIENT_ID}` +
      `&code=${token}&connector_ids=${connectorId}&state=${state}&redirect_uri=${redirectUri}`
    );
  }

  private createOpenReconnectionWebviewUrl(
    token: string,
    connectionId: number,
    redirectUri: string,
    state: string,
  ): string {
    const appConfig = this.appConfigService.appConfig;
    return (
      `${appConfig.BI_WEBVIEW_URL_BASE}/reconnect` +
      `?client_id=${appConfig.BI_CLIENT_ID}` +
      `&code=${token}&connection_id=${connectionId}&redirect_uri=${redirectUri}&state=${state}`
    );
  }
}
