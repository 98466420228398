/// <reference types="@types/google.maps" />
import { Injectable } from '@angular/core';

import { AutocompleteAddress } from './google-places-autocomplete.interface';

type PlaceResult = google.maps.places.PlaceResult;
type ComponentTemplate = Record<string, 'long_name' | 'short_name'>;

@Injectable({
  providedIn: 'root',
})
export class GooglePlacesAutocompleteService {
  getAutocompleteAddress(place: PlaceResult): AutocompleteAddress {
    return {
      address: this.getAddress(place),
      postalCode: this.getPostalCode(place),
      city: this.getCity(place),
      country: this.getCountry(place),
      countryCode: this.getCountryCode(place),
    };
  }

  private getAddress(place: PlaceResult): string | null {
    const numberComponentTemplate: ComponentTemplate = {
      street_number: 'short_name',
    };
    const routeComponentTemplate: ComponentTemplate = { route: 'long_name' };

    const numberComponent = this.getAddrComponent(
      place,
      numberComponentTemplate,
    );
    const routeComponent = this.getAddrComponent(place, routeComponentTemplate);

    if (numberComponent && routeComponent) {
      return `${numberComponent} ${routeComponent}`;
    } else if (routeComponent) {
      return routeComponent;
    }

    return null;
  }

  private getPostalCode(place: PlaceResult): string | null {
    const postCodeComp: ComponentTemplate = { postal_code: 'long_name' };
    return this.getAddrComponent(place, postCodeComp);
  }

  private getCountry(place: PlaceResult): string | null {
    const countryComp: ComponentTemplate = { country: 'long_name' };
    return this.getAddrComponent(place, countryComp);
  }

  private getCity(place: PlaceResult): string | null {
    const cityComp: ComponentTemplate = { locality: 'long_name' };
    return this.getAddrComponent(place, cityComp);
  }

  private getCountryCode(place: PlaceResult): string | null {
    const countryComp: ComponentTemplate = { country: 'short_name' };
    return this.getAddrComponent(place, countryComp);
  }

  private getAddrComponent(
    place: PlaceResult,
    componentTemplate: ComponentTemplate,
  ): string | null {
    if (!place.address_components) {
      return null;
    }

    for (const addressComponent of place.address_components) {
      const addressEntity = addressComponent.types[0];
      const entityFormat = componentTemplate[addressEntity];
      if (entityFormat) {
        return addressComponent[entityFormat] || null;
      }
    }

    return null;
  }
}
