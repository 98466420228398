import { AutoFocusTarget } from '@angular/cdk/dialog';
import { ScrollStrategy } from '@angular/cdk/overlay';
import { InjectionToken } from '@angular/core';

export const SIDE_PANEL_DATA = new InjectionToken<unknown>('SidePanelData');

export class SidePanelConfig<TData = unknown> {
  panelClass?: string | string[];

  data?: TData | null = null;

  hasBackdrop?: boolean = true;
  backdropClass?: string;

  width?: string = '100%';

  /** Whether the user can use escape or clicking outside to close the side panel. */
  disableClose?: boolean = false;

  closeOnNavigation?: boolean = true;

  autoFocus?: AutoFocusTarget | string | boolean = 'dialog';
  restoreFocus?: boolean = true;

  scrollStrategy?: ScrollStrategy;
}
