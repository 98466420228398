import { HttpProgressEvent } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { TrackingService } from '@manakincubber/tiime-tracking';
import { Store } from '@ngrx/store';
import { Observable, of } from 'rxjs';
import {
  catchError,
  map,
  switchMap,
  take,
  tap,
  withLatestFrom,
} from 'rxjs/operators';
import { PaginationRange } from 'tiime-components';

import { UPLOAD_DOCUMENT_CONFIG } from '@constants';
import {
  StandardDocumentCategory,
  StandardDocumentCategoryIdentifier,
  FileTransferStatus,
} from '@enums';
import { FileHelper } from '@helpers';
import { Document, UploadDocument } from '@models';
import {
  loadDocumentsForCategory,
  loadRecentDocuments,
} from '@store/documents/documents.actions';

import { DocumentUploaded } from '../../amplitude';
import { DocumentUploadOptions } from '../../models/documents/document-upload-options';
import { CategoriesService } from '../categories.service';
import { DocumentsService } from '../documents.service';
import { FileTransferServiceBase } from './file-transfer-service.base';

@Injectable({
  providedIn: 'root',
})
export class UploadDocumentsService extends FileTransferServiceBase<UploadDocument> {
  protected defaultErrorMessage = UPLOAD_DOCUMENT_CONFIG.errorMessage;

  private readonly trackingService = inject(TrackingService);

  constructor(
    private readonly documentsService: DocumentsService,
    private readonly categoriesService: CategoriesService,
    private readonly store: Store,
  ) {
    super();
  }

  addToQueue(
    files: FileList | File[],
    catId?: number,
    documentUploadOptions?: DocumentUploadOptions,
  ): void {
    let categoryObs$ = of(catId);
    if (!catId) {
      // In case the document uploading action does not have a category selection step
      categoryObs$ = this.categoriesService.getAll().pipe(
        switchMap(categories => {
          const category = categories.find(
            category =>
              category.identifier ===
              StandardDocumentCategoryIdentifier.RECEIPT,
          );
          if (category?.id) {
            return of(category);
          } else {
            return this.categoriesService.create(
              StandardDocumentCategory.receipt,
            );
          }
        }),
        map(category => category.id),
      );
    }
    categoryObs$
      .pipe(
        switchMap(categoryId => {
          const uploadDocuments = this.convertToTransferFiles(
            files,
            categoryId,
            documentUploadOptions,
          );
          return this._queue$.pipe(
            take(1),
            tap(queue => {
              this._queue$.next([...uploadDocuments, ...queue]);
            }),
          );
        }),
      )
      .subscribe();
  }

  protected convertToTransferFiles(
    files: FileList | File[],
    categoryId?: number,
    documentUploadOptions?: DocumentUploadOptions,
  ): UploadDocument[] {
    return Array.from(files).map(file => ({
      file,
      filename: file.name,
      transferStatus: FileTransferStatus.NOT_STARTED,
      progress: 0,
      categoryId,
      documentUploadOptions: documentUploadOptions,
      isUpload: true,
    }));
  }

  protected transferFile(
    documentToUpload: UploadDocument,
  ): Observable<HttpProgressEvent | Document | UploadDocument> {
    if (
      !FileHelper.hasAuthorizedType(documentToUpload.file) ||
      FileHelper.hasIncorrectSize(documentToUpload.file)
    ) {
      return this.fileTransferError(documentToUpload);
    }
    return of(documentToUpload).pipe(
      map((uploadDocument: UploadDocument) => {
        uploadDocument.transferStatus = FileTransferStatus.IN_PROGRESS;
        return uploadDocument;
      }),
      switchMap(uploadDocument =>
        this.documentsService.upload(
          uploadDocument.file,
          uploadDocument.categoryId,
          undefined,
          uploadDocument.documentUploadOptions,
        ),
      ),
      tap((result: HttpProgressEvent | Document) => {
        if (result instanceof Document) {
          documentToUpload.transferStatus = FileTransferStatus.COMPLETED;
          this.trackDocumentUpload(result);
        } else {
          documentToUpload.progress = this.calculateTransferProgress(result);
        }
        this.updateFileInQueue(documentToUpload);
      }),
      withLatestFrom(this.hasPendingTransfer$),
      tap(([result, hasPendingUpload]) => {
        if (result instanceof Document && !hasPendingUpload) {
          this.refreshDocuments(result.category.id); //will be moved with https://github.com/ManakinCubber/chronos-web/pull/3236
        }
      }),
      map(([result]) => result),
      catchError(error => this.fileTransferError(documentToUpload, error)),
    );
  }

  private trackDocumentUpload(document: Document): void {
    this.trackingService.dispatch(
      new DocumentUploaded(document.category.identifier),
    );
  }

  private refreshDocuments(categoryId: number): void {
    this.store.dispatch(
      loadDocumentsForCategory({
        categoryId,
        options: { range: new PaginationRange(0, 25) },
      }),
    );
    this.store.dispatch(loadRecentDocuments());
  }

  protected isSameFile(a: UploadDocument, b: UploadDocument): boolean {
    return a.file.name === b.file.name;
  }
}
