import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import { Mapper, NgUtils } from '@manakincubber/tiime-utils';
import moment from 'moment';

import { Boundary, Month } from '@constants';
import { TIIME_ICON_PREFIX } from '@shared';

@Component({
  selector: 'app-year-item',
  templateUrl: './year-item.component.html',
  styleUrls: ['./year-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class YearItemComponent {
  @Input() year: number;
  @Input() months: Month[];
  @Input() boundaries: Boundary[];
  @Input() isRightPanel: boolean;

  @Output() readonly changeYear = new EventEmitter<void>();
  @Output() readonly monthClicked = new EventEmitter<number>();

  readonly TIIME_ICON_PREFIX = TIIME_ICON_PREFIX;
  readonly trackById = NgUtils.trackById;

  readonly mapToIsMonthDisabled: Mapper<number, boolean> = (
    index: number,
    boundary: Boundary,
  ) => index < boundary?.monthIndex || index > boundary?.monthIndex + 11;

  readonly mapToIndex: Mapper<number, number> = (
    index: number,
    isRightPanel: boolean,
  ) => {
    if (isRightPanel) {
      return index + 12;
    }
    return index;
  };

  readonly mapToIsCurrentMonth: Mapper<number, boolean> = (
    monthIndex: number,
    year,
  ) => moment().year() === year && moment().month() === monthIndex;

  onMonthClicked(index: number): void {
    if (!this.mapToIsMonthDisabled(index, this.boundaries[0])) {
      this.monthClicked.emit(index);
    }
  }
}
