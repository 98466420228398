import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { MatMenuTrigger } from '@angular/material/menu';
import { NgUtils } from '@manakincubber/tiime-utils';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { BehaviorSubject, combineLatest, ReplaySubject } from 'rxjs';
import { map, take, tap, withLatestFrom } from 'rxjs/operators';
import { TiimeSelectComponent, TiimeSelectOption } from 'tiime-components';

import { CreateCategoryForm } from '@forms';
import { Category } from '@models';

export type CategorySelectType = 'filter' | 'selector' | 'updater';

export const allCategoriesSelectOption = new Category(null, 'tous les espaces');

const newCategory = new Category(null, 'Créer un espace');
const allCategoriesOption: TiimeSelectOption<Category> = {
  label: 'tous les espaces',
  value: allCategoriesSelectOption,
  image: 'assets/icon_all.svg',
};
const newCategoryOption: TiimeSelectOption<Category> = {
  label: 'Créer un espace',
  value: newCategory,
  image: 'assets/icon_plus.svg',
};

export interface DisabledCategoryWithReason {
  category: Category;
  reason: string;
}

@UntilDestroy()
@Component({
  selector: 'app-category-select',
  templateUrl: './category-select.component.html',
  styleUrls: ['./category-select.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CategorySelectComponent implements OnInit {
  @ViewChild('categorySelect') categorySelect: TiimeSelectComponent<Category>;
  @ViewChild('menuTrigger') menuTrigger: MatMenuTrigger;

  @Input() set categories(categories: Category[]) {
    this.categories$.next(categories || []);
  }

  @Input() set selectedCategory(selectedCategory: Category | null) {
    this.updateSelectedCategory(selectedCategory);
  }

  @Input() set isCategoryCreationEnabled(isCategoryCreationEnabled: boolean) {
    this.isCategoryCreationEnabled$.next(isCategoryCreationEnabled);
  }

  @Input() disabled = false;
  @Input() disabledCategories: DisabledCategoryWithReason[];

  @Output() readonly selectCategory = new EventEmitter<Category>();
  @Output() readonly createCategory = new EventEmitter<Category>();

  createCategoryForm: CreateCategoryForm;

  readonly selectedCategory$ = new ReplaySubject<Category>(1);
  private readonly isCategoryCreationEnabled$ = new BehaviorSubject(true);
  private readonly categories$ = new BehaviorSubject<Category[]>([]);
  readonly categoryOptions$ = combineLatest([
    this.categories$,
    this.isCategoryCreationEnabled$,
  ]).pipe(
    map(([categories, isCategoryCreationEnabled]) => {
      let options;
      if (isCategoryCreationEnabled) {
        options = [
          newCategoryOption,
          ...Category.mapToSelectOptions(categories),
        ];
      } else {
        options = [...Category.mapToSelectOptions(categories)];
      }
      this.disableOptions(options);
      return options;
    }),
  );
  readonly trackById = NgUtils.trackById;
  readonly trackByIndex = NgUtils.trackByIndex;

  private readonly selectedCategoryBackup$ =
    new BehaviorSubject<Category | null>(null);

  readonly categoriesComparator: (a: Category, b?: Category) => boolean = (
    a,
    b,
  ) => {
    if (a.name === allCategoriesOption.label && !b) {
      return true;
    }
    return a.name === b?.name;
  };

  ngOnInit(): void {
    this.initCreateCategoryForm();
  }

  onSelectCategory(category: Category): void {
    if (category === newCategory) {
      this.menuTrigger.openMenu();
      this.selectedCategory$
        .pipe(
          take(1),
          tap(
            selectedCategory =>
              this.selectedCategoryBackup$.next(selectedCategory),
            tap(() => this.selectedCategory$.next(newCategoryOption.value)),
          ),
        )
        .subscribe();
    } else {
      this.selectCategory.emit(category);
    }
  }

  onMenuClose(): void {
    this.selectedCategoryBackup$
      .pipe(
        withLatestFrom(this.categoryOptions$),
        take(1),
        tap(([selectedCategoryBackup, categoryOptions]) => {
          this.selectedCategory$.next({
            ...this.getOptionByCategory(selectedCategoryBackup, categoryOptions)
              .value,
          } as Category);
          this.selectedCategoryBackup$.next(null);
        }),
      )
      .subscribe();
  }

  onCreateCategory(): void {
    if (this.createCategoryForm.valid) {
      this.createCategory.emit(
        new Category(undefined, this.createCategoryForm.name.value as string),
      );
      this.menuTrigger.closeMenu();
    }
  }

  private initCreateCategoryForm(): void {
    this.categories$
      .pipe(
        tap(
          categories =>
            (this.createCategoryForm = new CreateCategoryForm(categories)),
        ),
        untilDestroyed(this),
      )
      .subscribe();
  }

  private getOptionByCategory(
    { name }: Category,
    categoryOptions: TiimeSelectOption<Category>[],
  ): TiimeSelectOption<Category> {
    return categoryOptions.find(option => option.label === name);
  }

  private updateSelectedCategory(selectedCategory: Category | null): void {
    this.categoryOptions$
      .pipe(
        take(1),
        tap(categoryOptions => {
          if (!selectedCategory) {
            if (!categoryOptions[1]) {
              this.selectedCategory$.next({
                ...categoryOptions[0].value,
              } as Category);
            } else {
              this.selectedCategory$.next({
                ...categoryOptions[1].value,
              } as Category);
            }
          } else {
            this.selectedCategory$.next({
              ...this.getOptionByCategory(selectedCategory, categoryOptions)
                ?.value,
            } as Category);
          }
        }),
      )
      .subscribe();
  }

  private disableOptions(options: TiimeSelectOption<Category>[]): void {
    if (this.disabledCategories?.length > 0) {
      options.map(option => {
        const disableCategory = this.disabledCategories.find(
          dc => dc.category.identifier === option.value.identifier,
        );
        if (disableCategory) {
          option.disabled = true;
          option.disabledTooltipContent = disableCategory.reason;
        }
      });
    }
  }
}
