import { NgModule } from '@angular/core';
import { TiimeIconModule, TiimeTooltipModule } from 'tiime-components';

import { CommentTooltipIconComponent } from './comment-tooltip-icon.component';

@NgModule({
  imports: [TiimeIconModule, TiimeTooltipModule],
  exports: [CommentTooltipIconComponent],
  declarations: [CommentTooltipIconComponent],
})
export class CommentTooltipIconModule {}
