<div class="line-content">
  <app-category-icon
    [category]="StandardDocumentCategoryIdentifier.EXPENSE_REPORT"
  ></app-category-icon>

  <div class="linked-expense-report-name">{{ linkedEntity.value.label }}</div>
  <div class="linked-expense-report-amount">
    {{
      linkedEntity.value.amount.value
        | currency: linkedEntity.value.amount.currency
    }}
  </div>
  <tiime-date-list-cell [date]="linkedEntity.value.date"></tiime-date-list-cell>
</div>
