import { Directive, Input, OnInit, Self } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { concat, fromEvent, Observable, race } from 'rxjs';
import { filter, first, map, repeat, tap } from 'rxjs/operators';
import { ButtonBase, LoadingEvent, LoadingService } from 'tiime-components';

export type DisabledRule = { key: string; showLoader?: boolean };
export type DisabledRuleInput = string | DisabledRule;

@UntilDestroy()
@Directive({
  standalone: true,
  selector: `button[tiime-button][disabledOnClick],
             button[tiime-button][disabledOn],
             button[app-button][disabledOnClick],
             button[app-button][disabledOn]`,
})
export class DisabledOnDirective implements OnInit {
  // eslint-disable-next-line @angular-eslint/no-input-rename
  @Input('disabledOnClick')
  set setDisabledOnClick(value: DisabledRuleInput) {
    this.disabledOnClick = coerceDisabledRule(value);
  }
  private disabledOnClick: DisabledRule | null = null;

  // eslint-disable-next-line @angular-eslint/no-input-rename
  @Input('disabledOn')
  set setDisabledOn(value: DisabledRuleInput | DisabledRuleInput[]) {
    if (Array.isArray(value)) {
      this.disabledOn = new Map(
        value.map(keyOrRule => {
          const rule = coerceDisabledRule(keyOrRule);
          return [rule.key, rule];
        }),
      );
    } else {
      const rule = coerceDisabledRule(value);
      this.disabledOn = new Map([[rule.key, rule]]);
    }
  }
  private disabledOn = new Map<string, DisabledRule>();

  constructor(
    @Self() private readonly button: ButtonBase,
    private readonly loadingService: LoadingService,
  ) {}

  ngOnInit(): void {
    const watchedKeys = [
      ...(this.disabledOnClick ? [this.disabledOnClick.key] : []),
      ...this.disabledOn.keys(),
    ];

    concat(
      this.getTriggerEvent().pipe(
        tap(({ showLoader }: DisabledRule) => {
          this.button.hostElement.disabled = true;
          if (showLoader) {
            this.button.setLoading(true);
          }
        }),
      ),
      this.loadingService.asObservable().pipe(
        filter((event: LoadingEvent) => event.isEndEvent),
        first(({ key }: LoadingEvent) => watchedKeys.includes(key)),
        tap(() => {
          this.button.hostElement.disabled = undefined;
          this.button.setLoading(false);
        }),
      ),
    )
      .pipe(repeat(), untilDestroyed(this))
      .subscribe();
  }

  private getTriggerEvent(): Observable<DisabledRule> {
    const loadingEventObservable: Observable<DisabledRule> = this.loadingService
      .asObservable()
      .pipe(
        filter((event: LoadingEvent) => event.isStartEvent),
        first(({ key }: LoadingEvent) => this.disabledOn.has(key)),
        map(({ key }: LoadingEvent) => this.disabledOn.get(key)),
      );

    return this.disabledOnClick
      ? race(
          fromEvent(this.button.hostElement, 'click').pipe(
            first(),
            tap(() => this.loadingService.start(this.disabledOnClick.key)),
            map(() => this.disabledOnClick),
          ),
          loadingEventObservable,
        )
      : loadingEventObservable;
  }
}

function coerceDisabledRule(value: DisabledRuleInput): DisabledRule {
  return typeof value === 'string' ? { key: value } : value;
}
