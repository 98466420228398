/* eslint-disable @typescript-eslint/no-empty-function */
import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  forwardRef,
} from '@angular/core';
import {
  ControlValueAccessor,
  FormsModule,
  NG_VALUE_ACCESSOR,
  ReactiveFormsModule,
} from '@angular/forms';

@Component({
  standalone: true,
  imports: [CommonModule, FormsModule, ReactiveFormsModule],
  selector: 'amount-form-control',
  templateUrl: './amount-form-control.component.html',
  styleUrls: ['./amount-form-control.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => AmountFormControlComponent),
      multi: true,
    },
  ],
})
export class AmountFormControlComponent implements ControlValueAccessor {
  value: number;
  disabled = false;

  constructor(private cdr: ChangeDetectorRef) {}

  writeValue(value: number): void {
    this.value = value;
    this.cdr.markForCheck();
    this.onChange(value);
  }

  registerOnChange(fn: (arg: number) => void): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
    this.cdr.markForCheck();
  }

  onChange: (value?: number) => void = () => {};

  onTouched = (): void => {};
}
