import { Clipboard } from '@angular/cdk/clipboard';
import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { tap } from 'rxjs/operators';
import { SnackbarConfig, TiimeSnackbarService } from 'tiime-components';

import { ApiService } from '@core';
import { BusinessAccessRequestStatus } from '@enums';
import {
  BusinessAccessRequest,
  BusinessAccessRequestPatchUpdates,
} from '@models';

import { BusinessAccessRequestService } from '../../../company-core/business-access-request.service';

export interface AnswerBusinessAccessRequestDialogData {
  businessAccessRequest: BusinessAccessRequest;
  companyName: string;
}

@Component({
  selector: 'app-answer-business-access-request-dialog',
  templateUrl: './answer-business-access-request-dialog.component.html',
  styleUrls: ['./answer-business-access-request-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AnswerBusinessAccessRequestDialogComponent {
  readonly customerCareMailAddress = 'aide@tiime.fr';

  constructor(
    @Inject(MAT_DIALOG_DATA)
    readonly data: AnswerBusinessAccessRequestDialogData,
    readonly dialogRef: MatDialogRef<AnswerBusinessAccessRequestDialogComponent>,
    private readonly clipboard: Clipboard,
    private readonly snackbar: TiimeSnackbarService,
    private readonly apiService: ApiService,
    private readonly businessAccessRequestService: BusinessAccessRequestService,
  ) {}

  acceptBusinessAccessRequest(): void {
    const businessAccessRequestId = this.data.businessAccessRequest.id;
    const updates: BusinessAccessRequestPatchUpdates = {
      status: BusinessAccessRequestStatus.ACCEPTED,
    };
    this.apiService
      .updateBusinessAccessRequest(businessAccessRequestId, updates)
      .pipe(
        tap(() =>
          this.businessAccessRequestService.acceptBusinessAccessRequest(
            this.data.businessAccessRequest,
          ),
        ),
        tap(() => this.dialogRef.close()),
        tap(() =>
          this.snackbar.open(
            'Vous avez bien accepté la demande de rattachement !',
            SnackbarConfig.success,
          ),
        ),
      )
      .subscribe();
  }
}
