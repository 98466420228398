<mat-dialog-actions class="dialog-actions">
  <ng-container [ngSwitch]="theme">
    <button
      *ngSwitchCase="'warn'"
      tiime-button
      warn
      data-cy="confirm-dialog__btn-confirm"
      [style.width.px]="actionsButtonsWidth"
      [disabled]="confirmButtonDisabled"
      (click)="confirm.emit()"
    >
      {{ confirmLabel || 'Confirmer' }}
    </button>
    <button
      *ngSwitchDefault
      tiime-button
      accent
      data-cy="confirm-dialog__btn-confirm"
      [style.width.px]="actionsButtonsWidth"
      [disabled]="confirmButtonDisabled"
      (click)="confirm.emit()"
    >
      {{ confirmLabel || 'Confirmer' }}
    </button>
  </ng-container>

  <button
    tiime-button
    neutral
    inverted
    data-cy="confirm-dialog__btn-cancel"
    [class.warn]="theme === 'accent-with-warn-cancel'"
    [style.width.px]="actionsButtonsWidth"
    (click)="decline.emit()"
  >
    {{ cancelLabel || 'Annuler' }}
  </button>
</mat-dialog-actions>
