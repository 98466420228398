<div class="table__row">
  <div class="table__row__cell"></div>
  <div class="table__row__cell"></div>
  <div class="table__row__cell"></div>
  <div class="table__row__cell"></div>
  <div class="table__row__cell"></div>
</div>
<div class="table__row">
  <div class="table__row__cell"></div>
  <div class="table__row__cell"></div>
  <div class="table__row__cell"></div>
  <div class="table__row__cell"></div>
  <div class="table__row__cell"></div>
</div>
<div class="table__row">
  <div class="table__row__cell"></div>
  <div class="table__row__cell"></div>
  <div class="table__row__cell"></div>
  <div class="table__row__cell"></div>
  <div class="table__row__cell"></div>
</div>
