import { FormControl, Validators } from '@angular/forms';

import { Client } from '@models';

import { AbstractClientForm } from '../bases/abstract-client-form';

export class ClientForm extends AbstractClientForm {
  get email(): FormControl {
    return this.get('email') as FormControl;
  }
  get phone(): FormControl {
    return this.get('phone') as FormControl;
  }
  get note(): FormControl {
    return this.get('note') as FormControl;
  }
  get useEmail(): FormControl {
    return this.get('useEmail') as FormControl;
  }
  get isProfessional(): FormControl {
    return this.get('isProfessional') as FormControl;
  }

  get mailErrorMessage(): string {
    return this.email.touched && this.email.hasError('email')
      ? `Le format de ce champ est invalide`
      : '';
  }

  constructor() {
    super({
      email: new FormControl(null, Validators.email),
      phone: new FormControl(),
      note: new FormControl(),
      useEmail: new FormControl(),
      isProfessional: new FormControl(null, Validators.required),
    });
  }

  toClient(): Client {
    const client = this.getRawValue() as Client;
    client.phone = client.phone || null;
    client.sirenOrSiret = client.sirenOrSiret?.replace(/\s/g, '');
    client.useEmail = client.useEmail === null ? true : client.useEmail;
    return client;
  }
}
