import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TiimePipesModule } from '@manakincubber/tiime-utils';
import { TiimeDateListCellModule, TiimeLetModule } from 'tiime-components';

import { CategoryIconComponent } from '../../../../shared/components/category-icon/category-icon.component';
import { LinkedEntityAmountAndCurrencyPipe } from '../../../document/document-shared/components/linked-entities/linked-entity-amount-and-currency.pipe';
import { BankLogoModule } from '../bank-logo/bank-logo.module';
import { OperationTypeLogoModule } from '../operation-type-logo/operation-type-logo.module';
import { LinkedEntitiesTooltipComponent } from './linked-entities-tooltip.component';
import { LinkedBankTransactionLineComponent } from './linked-item-lines/linked-bank-transaction-line/linked-bank-transaction-line.component';
import { LinkedExpenseReportLineComponent } from './linked-item-lines/linked-expense-report-line/linked-expense-report-line.component';

@NgModule({
  declarations: [
    LinkedEntitiesTooltipComponent,
    LinkedBankTransactionLineComponent,
    LinkedExpenseReportLineComponent,
  ],
  imports: [
    CommonModule,
    TiimeDateListCellModule,
    TiimeLetModule,
    OperationTypeLogoModule,
    BankLogoModule,
    TiimePipesModule,
    CategoryIconComponent,
    LinkedEntityAmountAndCurrencyPipe,
  ],
  exports: [LinkedEntitiesTooltipComponent],
})
export class LinkedEntitiesTooltipModule {}
