import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatIconModule } from '@angular/material/icon';
import { TiimePipesModule } from '@manakincubber/tiime-utils';
import {
  TiimeButtonModule,
  TiimeDateListCellModule,
  TiimeDialogModule,
  TiimeInputContainerModule,
  TiimeLetModule,
  TiimeTooltipModule,
} from 'tiime-components';

import { TagChipModule } from '@shared';

import { LabelChipModule } from '../labels';
import { IsInstanceOfLabelPipe } from './is-instance-of-label.pipe';
import { LabelSelectionOverlayModule } from './label-selection-overlay/label-selection-overlay.module';
import { LabelSelectorComponent } from './label-selector.component';

@NgModule({
  declarations: [LabelSelectorComponent, IsInstanceOfLabelPipe],
  imports: [
    CommonModule,
    MatIconModule,
    ReactiveFormsModule,
    TiimePipesModule,
    TiimeTooltipModule,
    TiimeButtonModule,
    TiimeLetModule,
    TiimeDateListCellModule,
    TiimeInputContainerModule,
    TiimeDialogModule,
    LabelChipModule,
    LabelSelectionOverlayModule,
    TagChipModule,
  ],
  exports: [LabelSelectorComponent],
})
export class LabelSelectorModule {}
