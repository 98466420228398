<mat-menu
  class="transaction-filter-menu third-party-filter"
  [xPosition]="menuPositions?.xPosition || 'before'"
  [yPosition]="menuPositions?.yPosition || 'below'"
>
  <div
    class="container list-container"
    (click)="$event.stopPropagation()"
    (keydown.tab)="$event.stopPropagation()"
  >
    <div class="title">
      <span>Trier</span>
      <mat-icon
        class="reset-filter-btn"
        svgIcon="icon-reset"
        (click)="reset()"
      ></mat-icon>
    </div>

    <ng-content></ng-content>

    <form [formGroup]="form" (ngSubmit)="apply()">
      <ng-container *ngIf="sortKey">
        <mat-radio-group
          class="sort-container"
          color="primary"
          formControlName="sortDirection"
        >
          <mat-radio-button value="asc">
            <span>
              De A
              <mat-icon svgIcon="icon-arrow-right"></mat-icon>
              Z
            </span>
          </mat-radio-button>
          <mat-radio-button
            class="client-name-filter__radio-button"
            value="desc"
          >
            <span>
              De Z
              <mat-icon svgIcon="icon-arrow-right"></mat-icon>
              A
            </span>
          </mat-radio-button>
        </mat-radio-group>
        <div class="separator"></div>
      </ng-container>
      <div class="search">
        <input
          type="text"
          placeholder="Rechercher un label"
          #search
          [formControl]="searchControl"
        />
      </div>
      <div
        *ngIf="(isSearching$ | async) === false"
        class="list__menu-item select-all"
      >
        <ng-container *ngIf="allowFilterWithout">
          <mat-checkbox ngDefaultControl [formControl]="form.without">
            Aucun (vide)
          </mat-checkbox>
          <div class="separator"></div>
        </ng-container>
        <mat-checkbox ngDefaultControl [formControl]="form.all">
          Tous les labels
        </mat-checkbox>
      </div>
      <div class="separator"></div>
      <div class="filter-container" formArrayName="filters" scrollShadows>
        <ng-container
          *ngFor="
            let label of filtersFormArray.controls;
            let i = index;
            trackBy: trackById
          "
        >
          <div *ngIf="filteredLabels.includes(labels[i])" class="filter">
            <mat-checkbox
              ngDefaultControl
              [formControl]="filtersFormArray.controls[i]"
            >
              {{ labels[i].label }}
            </mat-checkbox>
          </div>
        </ng-container>
      </div>
      <button class="apply" tiime-button xsmall accent>Appliquer</button>
    </form>
  </div>
</mat-menu>
