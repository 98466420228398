import { TrackingEvent } from '@manakincubber/tiime-tracking';

import { BankTransferSource } from '../../constants';

export const BANK_TRANSFER_STARTED = 'Bank transfer started';

export class BankTransferStarted implements TrackingEvent {
  readonly name = BANK_TRANSFER_STARTED;

  constructor(readonly source: BankTransferSource) {}
}
