import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TiimePipesModule } from '@manakincubber/tiime-utils';
import { TiimeIconModule, TiimeLetModule } from 'tiime-components';

import { CategoryIconComponent } from '../../../../shared/components/category-icon/category-icon.component';
import { TiimeSpinnerComponent } from '../tiime-spinner/tiime-spinner.component';
import { FileTransferOverlayComponent } from './file-transfer-overlay.component';
import { TransferringFileComponent } from './transferring-file/transferring-file.component';

@NgModule({
  declarations: [FileTransferOverlayComponent, TransferringFileComponent],
  imports: [
    CommonModule,
    CategoryIconComponent,
    TiimeIconModule,
    TiimeLetModule,
    TiimePipesModule,
    TiimeSpinnerComponent,
  ],
  exports: [FileTransferOverlayComponent],
})
export class FileTransferOverlayModule {}
