<video
  controls
  disablepictureinpicture
  type="video/mp4"
  width="100%"
  #video
  [src]="data.source"
>
  Désolé, votre navigateur ne peut pas afficher la vidéo.
</video>
