import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import { Mapper, TiimePipesModule } from '@manakincubber/tiime-utils';
import { TiimeButtonModule } from 'tiime-components';

import { BankAccount, Beneficiary } from '@models';
import { IconsModule } from '@shared';

import { TrustBeneficiaryComponent } from '../../../../account/bank-transfers/beneficiaries/trust-beneficiary/trust-beneficiary.component';
import { OffuscatedIbanPipe } from '../../../pipes/offuscated-iban.pipe';

@Component({
  standalone: true,
  selector: 'app-account-item',
  imports: [
    CommonModule,
    IconsModule,
    TrustBeneficiaryComponent,
    OffuscatedIbanPipe,
    TiimePipesModule,
    TiimeButtonModule,
  ],
  templateUrl: './account-item.component.html',
  styleUrls: ['./account-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AccountItemComponent {
  @Input() account: BankAccount | Beneficiary;
  @Input() isSelected = false;
  @Input() iconColor: 'default' | 'blue' = 'default';
  @Input() isBeneficiary = false;
  @Input() personalWalletBeneficiary?: Beneficiary;

  @Output() readonly addBeneficiary = new EventEmitter<void>();

  readonly mapToIconPath: Mapper<BankAccount | Beneficiary, string> =
    account => {
      if (!this.isBeneficiary) {
        if ((account as BankAccount).isWallet) {
          return 'assets/illus/bank-transfer/icon-tiime.svg';
        }
        return this.iconColor === 'blue'
          ? 'assets/illu_bank_transfer_account_blue.svg'
          : 'assets/illu_bank_transfer_account.svg';
      } else {
        return this.iconColor === 'blue'
          ? 'assets/illu_bank_transfer_beneficiary_blue.svg'
          : 'assets/illus/bank-transfer/illu_bank_transfer_beneficiary.svg';
      }
    };

  addNewBeneficiary(event: MouseEvent): void {
    event.stopPropagation();
    this.addBeneficiary.emit();
  }
}
