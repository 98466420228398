/* tslint:disable */
/* eslint-disable */
// @ts-nocheck
/**
 * Ampli - A strong typed wrapper for your Analytics
 *
 * This file is generated by Amplitude.
 * To update run 'ampli pull web_tiime_apps'
 *
 * Required dependencies: @amplitude/analytics-browser@^1.3.0
 * Tracking Plan Version: 54
 * Build: 1.0.0
 * Runtime: browser:typescript-ampli-v2
 *
 * [View Tracking Plan](https://data.eu.amplitude.com/tiime/Tiime%20Tracking%20Plan/events/main/latest)
 *
 * [Full Setup Instructions](https://data.eu.amplitude.com/tiime/Tiime%20Tracking%20Plan/implementation/web_tiime_apps)
 */

import * as amplitude from '@amplitude/analytics-browser';

export type Environment = 'production' | 'development' | 'test';

export const ApiKey: Record<Environment, string> = {
  production: 'efdfbac267c729681e8d0816a7dd69c0',
  development: '1611e2699f491246892753e4461f6073',
  test: 'a738b44926ddfebf7226305bb61ea69b'
};

/**
 * Default Amplitude configuration options. Contains tracking plan information.
 */
export const DefaultConfiguration: BrowserOptions = {
  plan: {
    version: '54',
    branch: 'main',
    source: 'web_tiime_apps',
    versionId: '4366da87-9b0f-4218-8325-b1f575faa444'
  },
  ...{
    ingestionMetadata: {
      sourceName: 'browser-typescript-ampli',
      sourceVersion: '2.0.0'
    }
  },
  serverZone: amplitude.Types.ServerZone.EU
};

export interface LoadOptionsBase { disabled?: boolean }

export type LoadOptionsWithEnvironment = LoadOptionsBase & { environment: Environment; client?: { configuration?: BrowserOptions; }; };
export type LoadOptionsWithApiKey = LoadOptionsBase & { client: { apiKey: string; configuration?: BrowserOptions; } };
export type LoadOptionsWithClientInstance = LoadOptionsBase & { client: { instance: BrowserClient; } };

export type LoadOptions = LoadOptionsWithEnvironment | LoadOptionsWithApiKey | LoadOptionsWithClientInstance;

export interface IdentifyProperties {
  /**
   * C'est la business unit dans laquelle travaille le collaborateur de cabinet. Si l'utilisateur est côté entrepreneur le champ est vide.
   *
   * | Rule | Value |
   * |---|---|
   * | Item Type | string |
   */
  business_unit?: string[];
  /**
   * User ID avec le format chronos, c'est à dire, un integer.
   *
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  chronos_user_id?: number;
  gclid?: string;
  initial_dclid?: string;
  initial_fbclid?: string;
  initial_gbraid?: string;
  initial_gclid?: string;
  initial_ko_click_id?: string;
  initial_msclkid?: string;
  initial_referrer?: string;
  initial_referring_domain?: string;
  initial_ttclid?: string;
  initial_twclid?: string;
  initial_utm_campaign?: string;
  initial_utm_content?: string;
  initial_utm_id?: string;
  initial_utm_medium?: string;
  initial_utm_source?: string;
  initial_utm_term?: string;
  initial_wbraid?: string;
  /**
   * Cette propriété est réservée aux collaborateurs. Les utilisateurs de type custome ont un champ vide pour cette propriété.
   */
  initiales_collaborateur?: string;
  /**
   * C'est le partner du collaborateur du cabinet. Si l'utilisateur est côté entrepreneur, on laisse le champ vide.
   */
  partner?: string;
  /**
   * Pour distinguer facilement les utilisateurs finaux et les collaborateurs qui interviennent sur le dossier. Doit avoir la valeur collaborateur si l'utilisateur est côté "cabinet"
   */
  type_d_utilisateur?: string;
  /**
   * Type de connexion utilisé pour se connecter aux apps.
   *
   * utilisateur : connexion normale d’un user
   *
   * utilisateur_expert : connexion au travers de l’app expert (voir video de Elize)
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | utilisateur, utilisateur_expert |
   */
  utilise_en_tant_que?: "utilisateur" | "utilisateur_expert";
  utm_campaign?: string;
  utm_content?: string;
  utm_id?: string;
  utm_medium?: string;
  utm_source?: string;
  utm_term?: string;
}

export interface ActionEnMasseCommenceeProperties {
  /**
   * Cette propriété indique si un filtre (excluant les filtres par défaut) est utilisé au moment de l'éxécution de l'action en masse
   */
  a_filtre: boolean;
  /**
   * Cette propriété indique sitous les objects de la page sont sélectionnés au moment de l'éxécution de l'action en masse
   */
  a_tous_elements_page_selectionnes: boolean;
  /**
   * Cette propriété indique le type d'action en masse exécutée par l'utilisateur
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | labeliser, relier, supprimer, deplacer, telecharger, taguer, paye, non_paye |
   */
  type_action: "labeliser" | "relier" | "supprimer" | "deplacer" | "telecharger" | "taguer" | "paye" | "non_paye";
  /**
   * Cette propriété indique si le type d'object actuellement consultés (Documents, Transaction, etc...) - Uniquement sur document actuellement
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | document |
   */
  type_element: "document";
}

export interface ActionEnMasseTooltipDisabledVueProperties {
  /**
   * Cette propriété indique le type d'action en masse exécutée par l'utilisateur
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | labeliser, relier, supprimer, deplacer, telecharger, taguer, paye, non_paye |
   */
  type_action: "labeliser" | "relier" | "supprimer" | "deplacer" | "telecharger" | "taguer" | "paye" | "non_paye";
  /**
   * Cette propriété indique si le type d'object actuellement consultés (Documents, Transaction, etc...) - Uniquement sur document actuellement
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | document |
   */
  type_element: "document";
}

export interface BoutonAjoutTagCliqueProperties {
  /**
   * Cette propriété indique le type d’object sur lequel le tag a été ajouté.
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | transactions, documents, facture, frais_a_remourser, frais_kilometriques, devis |
   */
  applique_sur: "transactions" | "documents" | "facture" | "frais_a_remourser" | "frais_kilometriques" | "devis";
}

export interface BrouillonFactureEnregistreProperties {
  /**
   * Cette propriété indique l’état due la facture ouverte dans l'éditeur de facture.
   *
   * nouveau_brouillon : Le brouillon de facture ouvert est une création
   *
   *
   * mise_a_jour_brouillon : Le brouillon de facture ouvert est brouillon de facture existant que l’on souhaite potentiellement modifier
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | nouveau_brouillon, mise_a_jour_brouillon |
   */
  etat_brouillon_enregistre: "nouveau_brouillon" | "mise_a_jour_brouillon";
  /**
   * Alimenté avec une valeur construite sous le format :
   *
   * facture-<id_chronos>
   */
  identifiant_chronos_de_la_facture?: string;
}

export interface ClientFactureAjouteProperties {
  /**
   * Alimenté avec une valeur construite sous le format :
   *
   * facture-<id_chronos>
   */
  identifiant_chronos_de_la_facture?: string;
  /**
   * Alimenté avec une valeur construite sous le format :
   *
   * client-<id_chronos>
   */
  identifiant_chronos_du_client?: string;
}

export interface CreationFactureEchoueeProperties {
  /**
   * Cette propriété est une liste. Elle prend pour valeur l’ensemble des champs en échec sur la facture.
   *
   * | Rule | Value |
   * |---|---|
   * | Unique Items | false |
   * | Item Type | string |
   */
  erreurs_creation_facture: string[];
  /**
   * Alimenté avec une valeur construite sous le format :
   *
   * facture-<id_chronos>
   */
  identifiant_chronos_de_la_facture?: string;
}

export interface DetailDocumentAccedeProperties {
  /**
   * Cette propriété indique le type de document dont l'utilisateur regarde le détail
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | justificatif, frais_a_rembourser, notes_frais, ik, facture_vente, juridique, social_salarie, social_dirigeant, bulletin_paie, releve_bancaire, compte_annuel, document_permanent, autres, fiscal |
   */
  type_document?:
    | "justificatif"
    | "frais_a_rembourser"
    | "notes_frais"
    | "ik"
    | "facture_vente"
    | "juridique"
    | "social_salarie"
    | "social_dirigeant"
    | "bulletin_paie"
    | "releve_bancaire"
    | "compte_annuel"
    | "document_permanent"
    | "autres"
    | "fiscal";
}

export interface DevisCreeProperties {
  /**
   * Si la facture contient une adresse de livraison
   */
  a_adresse_livraison: boolean;
  /**
   * Si facture contient un champ libre
   */
  a_champ_libre: boolean;
  /**
   * Si le devis créé contient un champ de signature
   */
  a_champ_signature: boolean;
  /**
   * Si le devis créé contient les conditions d'acceptation
   */
  a_conditions_acceptations: boolean;
  /**
   * Si facture contient ligne correspondant à une remise
   */
  a_remise: boolean;
  /**
   * Si facture contient le numero de SIRET ou SIREN du client
   */
  a_siret: boolean;
  /**
   * Si facture contient le numero de TVA Intracommunautaire du client
   */
  a_tva_intra: boolean;
  /**
   * ID chronos du devis au format : devis-​1234...​
   */
  identifiant_chronos_du_devis?: string;
  /**
   * Langue de la facture
   */
  langue_selectionnee: string;
  /**
   * Le format des lignes dans la facture a t'il ou nom des quantités pour les services
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | simple, avec_quantites |
   */
  type_facturation: "simple" | "avec_quantites";
}

export interface DevisEnvoyeProperties {
  /**
   * ID chronos du devis au format : devis-​1234...​
   */
  identifiant_chronos_du_devis?: string;
}

export interface DevisTelechargeProperties {
  /**
   * ID chronos du devis au format : devis-​1234...​
   */
  identifiant_chronos_du_devis?: string;
}

export interface DocumentAjouteProperties {
  /**
   * Cette propriété indique le type de document dont l'utilisateur regarde le détail
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | justificatif, frais_a_rembourser, notes_frais, ik, facture_vente, juridique, social_salarie, social_dirigeant, bulletin_paie, releve_bancaire, compte_annuel, document_permanent, autres, fiscal |
   */
  type_document?:
    | "justificatif"
    | "frais_a_rembourser"
    | "notes_frais"
    | "ik"
    | "facture_vente"
    | "juridique"
    | "social_salarie"
    | "social_dirigeant"
    | "bulletin_paie"
    | "releve_bancaire"
    | "compte_annuel"
    | "document_permanent"
    | "autres"
    | "fiscal";
}

export interface DocumentRelieProperties {
  /**
   * Cette propriété indique si le document ajouté a été ajouté à une transaction liée à une demande manuelle du comptable  (justificatif et message)
   */
  transaction_avec_demande_comptable: boolean;
  /**
   * Cette propriété indique le type de document dont l'utilisateur regarde le détail
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | justificatif, frais_a_rembourser, notes_frais, ik, facture_vente, juridique, social_salarie, social_dirigeant, bulletin_paie, releve_bancaire, compte_annuel, document_permanent, autres, fiscal |
   */
  type_document?:
    | "justificatif"
    | "frais_a_rembourser"
    | "notes_frais"
    | "ik"
    | "facture_vente"
    | "juridique"
    | "social_salarie"
    | "social_dirigeant"
    | "bulletin_paie"
    | "releve_bancaire"
    | "compte_annuel"
    | "document_permanent"
    | "autres"
    | "fiscal";
}

export interface EditeurClientOuvertProperties {
  /**
   * Cette propriété indique l’état du client ouvert dans l'éditeur de client.
   *
   * nouveau_client : La fiche client ouverte est une création
   *
   * modification_client : Le fiche client ouverte est existante ou l’on souhaite potentiellement la modifier
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | nouveau_client, modification_client |
   */
  etat_client_ouvert: "nouveau_client" | "modification_client";
  /**
   * Alimenté avec une valeur construite sous le format :
   *
   * client-<id_chronos>
   */
  identifiant_chronos_du_client?: string;
}

export interface EditeurDevisOuvertProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Enum Values | nouveau_devis, modification_devis |
   */
  etat_devis_ouvert: "nouveau_devis" | "modification_devis";
  /**
   * ID chronos du devis au format : devis-​1234...​
   */
  identifiant_chronos_du_devis?: string;
}

export interface EditeurFactureOuvertProperties {
  /**
   * La facture ouverte est une facture provenant d’un devis transformé en facture
   */
  creee_depuis_devis: boolean;
  /**
   * | Rule | Value |
   * |---|---|
   * | Enum Values | nouvelle_facture, modification_facture |
   */
  etat_facture_ouverte: "nouvelle_facture" | "modification_facture";
  /**
   * Alimenté avec une valeur construite sous le format :
   *
   * facture-<id_chronos>
   */
  identifiant_chronos_de_la_facture?: string;
  /**
   * ID chronos du devis au format : devis-​1234...​
   */
  identifiant_chronos_du_devis?: string;
}

export interface FactureCreeeProperties {
  /**
   * Si la facture contient une adresse de livraison
   */
  a_adresse_livraison: boolean;
  /**
   * Si facture contient un champ libre
   */
  a_champ_libre: boolean;
  /**
   * Si facture contient ligne correspondant à une remise
   */
  a_remise: boolean;
  /**
   * Si facture contient le numero de SIRET ou SIREN du client
   */
  a_siret: boolean;
  /**
   * Si facture contient le numero de TVA Intracommunautaire du client
   */
  a_tva_intra: boolean;
  /**
   * Alimenté avec une valeur construite sous le format :
   *
   * facture-<id_chronos>
   */
  identifiant_chronos_de_la_facture?: string;
  /**
   * ID chronos du devis au format : devis-​1234...​
   */
  identifiant_chronos_du_devis?: string;
  /**
   * Langue de la facture
   */
  langue_selectionnee: string;
  /**
   * Le format des lignes dans la facture a t'il ou nom des quantités pour les services
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | simple, avec_quantites |
   */
  type_facturation: "simple" | "avec_quantites";
}

export interface FactureEnvoyeeProperties {
  /**
   * Alimenté avec une valeur construite sous le format :
   *
   * facture-<id_chronos>
   */
  identifiant_chronos_de_la_facture?: string;
}

export interface FactureTelechargeeProperties {
  /**
   * Alimenté avec une valeur construite sous le format :
   *
   * facture-<id_chronos>
   */
  identifiant_chronos_de_la_facture?: string;
}

export interface FiltreTransactionsAppliqueProperties {
  /**
   * Cette propriété indique la dimension cible sur laquelle le filtre a été appliqué
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | label, date, methode, intitule, banque, tag, commentaire, id, montant, credit_debit, document_relie, demandes_comptables, libre |
   */
  cible_filtre:
    | "label"
    | "date"
    | "methode"
    | "intitule"
    | "banque"
    | "tag"
    | "commentaire"
    | "id"
    | "montant"
    | "credit_debit"
    | "document_relie"
    | "demandes_comptables"
    | "libre";
  /**
   * Cette propriété indique quel element de l'UI a été utilisé pour filtrer
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | list_headers, search_bar, page_header, a_faire, mobile_filter_dropdown |
   */
  methode_filtre: "list_headers" | "search_bar" | "page_header" | "a_faire" | "mobile_filter_dropdown";
  /**
   * | Rule | Value |
   * |---|---|
   * | Enum Values | filtre, ordre |
   */
  type_filtre: "filtre" | "ordre";
}

export interface LabelTransactionAppliqueProperties {
  /**
   * Cette propriété indique si l'utilisateur a utilisé un label existant ou s'il a créé un label
   */
  label_existant: boolean;
}

export interface LectureVideoBesoinAideLanceeProperties {
  /**
   * Propriété permettant d’identifier la video besoin d’aide lancée (hardcoded)
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | edition_facture_devis |
   */
  identifiant_video: "edition_facture_devis";
}

export interface MotifVirement25KChoisiProperties {
  /**
   * Motif du virement supérieur à 10k euros
   */
  motif_virement_25k_choisi: string;
}

export interface MultiplesElementsSelectionnesProperties {
  /**
   * Cette propriété indique le mode de selection utilisé pour selectionner plusieurs élement conjointement
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | tous, aucun, paye, non-paye |
   */
  mode_selection: "tous" | "aucun" | "paye" | "non-paye";
  /**
   * Cette propriété indique si le type d'object actuellement consultés (Documents, Transaction, etc...) - Uniquement sur document actuellement
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | document |
   */
  type_element: "document";
}

export interface NouveauClientEnregistreProperties {
  /**
   * Alimenté avec une valeur construite sous le format :
   *
   * client-<id_chronos>
   */
  identifiant_chronos_du_client?: string;
  /**
   * Indique si le client ajouté est un professionel ou un particulier
   *  professionel: Le client de la facture est professionel
   *  particulier: Le client de la facture est un particulier
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | professionel, particulier |
   */
  type_client: "professionel" | "particulier";
}

export interface RegleLabelCreeProperties {
  /**
   * Cette propriété indique si l'intitulé de matching a été modifié
   */
  intitule_matching_modifie: boolean;
}

export interface TagAjouteProperties {
  /**
   * Cette propriété indique le type d’object sur lequel le tag a été ajouté.
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | transactions, documents, facture, frais_a_remourser, frais_kilometriques, devis |
   */
  applique_sur: "transactions" | "documents" | "facture" | "frais_a_remourser" | "frais_kilometriques" | "devis";
}

export interface TvaEstimationVueProperties {
  /**
   * Indique si la période consulté correspond à la période de la TVA en cours
   */
  tva_period_en_cours: boolean;
}

export interface TvaValideeProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Enum Values | ecran, popup |
   */
  element_de_validation: "ecran" | "popup";
}

export interface ValidationTvaCommenceeProperties {
  /**
   * Cette propriété a pour but d’identifier la source ayant menée les utilisateurs à commencer le processus de validation de la TVA.
   *
   * \---
   *
   * * deeplink_sms: Si l’utilisateur provient du lien envoyé par SMS
   *
   * * email_link: Si l’utilisateur provient du lien envoyé par email
   *
   * * direct: Si l’utilisateur provient a ouvert la webapp/app directement
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | direct, email_link, deeplink_sms |
   */
  validation_tva_acces: "direct" | "email_link" | "deeplink_sms";
}

export interface VirementCommenceProperties {
  /**
   * Cette propriété indique l'élément avec lequel un utilisateur à interagi pour lancer la fatrue de virement
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | me_rembourser, payer, action_rapide, bouton_comptes, profil_beneficiaire, salaire_president, premier_virement, virement_groupe, virement_a_venir |
   */
  source_virement:
    | "me_rembourser"
    | "payer"
    | "action_rapide"
    | "bouton_comptes"
    | "profil_beneficiaire"
    | "salaire_president"
    | "premier_virement"
    | "virement_groupe"
    | "virement_a_venir";
}

export class Identify implements BaseEvent {
  event_type = amplitude.Types.SpecialEventType.IDENTIFY;

  constructor(
    public event_properties?: IdentifyProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class ActionEnMasseCommencee implements BaseEvent {
  event_type = 'action_en_masse_commencee';

  constructor(
    public event_properties: ActionEnMasseCommenceeProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class ActionEnMasseTooltipDisabledVue implements BaseEvent {
  event_type = 'action_en_masse_tooltip_disabled_vue';

  constructor(
    public event_properties: ActionEnMasseTooltipDisabledVueProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class BoutonAjoutTagClique implements BaseEvent {
  event_type = 'bouton_ajout_tag_clique';

  constructor(
    public event_properties: BoutonAjoutTagCliqueProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class BoutonAjouteLabelClique implements BaseEvent {
  event_type = 'bouton_ajoute_label_clique';
}

export class BoutonEnvoyerViaTiimePopinRelanceClientClique implements BaseEvent {
  event_type = 'bouton_envoyer_via_tiime_popin_relance_client_clique';
}

export class BoutonModifierFicheClientClique implements BaseEvent {
  event_type = 'bouton_modifier_fiche_client_clique';
}

export class BoutonNouveauDevisFicheClientClique implements BaseEvent {
  event_type = 'bouton_nouveau_devis_fiche_client_clique';
}

export class BoutonNouvelleFactureFicheClientClique implements BaseEvent {
  event_type = 'bouton_nouvelle_facture_fiche_client_clique';
}

export class BoutonRelanceClientTimelineClique implements BaseEvent {
  event_type = 'bouton_relance_client_timeline_clique';
}

export class BoutonTelechargerPdfPopinRelanceClientClique implements BaseEvent {
  event_type = 'bouton_telecharger_pdf_popin_relance_client_clique';
}

export class BrouillonFactureEnregistre implements BaseEvent {
  event_type = 'brouillon_facture_enregistre';

  constructor(
    public event_properties: BrouillonFactureEnregistreProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class ClientFactureAjoute implements BaseEvent {
  event_type = 'client_facture_ajoute';

  constructor(
    public event_properties?: ClientFactureAjouteProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class ComptabiliteAFaireAccedee implements BaseEvent {
  event_type = 'comptabilité_a_faire_accedee';
}

export class ConnexionWeb implements BaseEvent {
  event_type = 'connexion_web';
}

export class ConseillerEmailContacte implements BaseEvent {
  event_type = 'conseiller_email_contacte';
}

export class CreationFactureEchouee implements BaseEvent {
  event_type = 'creation_facture_echouee';

  constructor(
    public event_properties: CreationFactureEchoueeProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class CreationRegleLabelCommencee implements BaseEvent {
  event_type = 'creation_regle_label_commencee';
}

export class DemandeVirement25KEnvoye implements BaseEvent {
  event_type = 'demande_virement_25k_envoye';
}

export class DetailDocumentAccede implements BaseEvent {
  event_type = 'detail_document_accede';

  constructor(
    public event_properties?: DetailDocumentAccedeProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class DetailsFraisSurNoteFraisVu implements BaseEvent {
  event_type = 'details_frais_sur_note_frais_vu';
}

export class DevisCree implements BaseEvent {
  event_type = 'devis_cree';

  constructor(
    public event_properties: DevisCreeProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class DevisEnvoye implements BaseEvent {
  event_type = 'devis_envoye';

  constructor(
    public event_properties?: DevisEnvoyeProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class DevisTelecharge implements BaseEvent {
  event_type = 'devis_telecharge';

  constructor(
    public event_properties?: DevisTelechargeProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class DocumentAjoute implements BaseEvent {
  event_type = 'document_ajoute';

  constructor(
    public event_properties?: DocumentAjouteProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class DocumentRelie implements BaseEvent {
  event_type = 'document_relie';

  constructor(
    public event_properties: DocumentRelieProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class EditeurClientOuvert implements BaseEvent {
  event_type = 'editeur_client_ouvert';

  constructor(
    public event_properties: EditeurClientOuvertProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class EditeurDevisOuvert implements BaseEvent {
  event_type = 'editeur_devis_ouvert';

  constructor(
    public event_properties: EditeurDevisOuvertProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class EditeurFactureOuvert implements BaseEvent {
  event_type = 'editeur_facture_ouvert';

  constructor(
    public event_properties: EditeurFactureOuvertProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class FactureCreee implements BaseEvent {
  event_type = 'facture_creee';

  constructor(
    public event_properties: FactureCreeeProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class FactureEnvoyee implements BaseEvent {
  event_type = 'facture_envoyee';

  constructor(
    public event_properties?: FactureEnvoyeeProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class FactureTelechargee implements BaseEvent {
  event_type = 'facture_telechargee';

  constructor(
    public event_properties?: FactureTelechargeeProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class FactureTimelineCliquee implements BaseEvent {
  event_type = 'facture_timeline_cliquee';
}

export class FicheClientOuverte implements BaseEvent {
  event_type = 'fiche_client_ouverte';
}

export class FiltreTransactionsApplique implements BaseEvent {
  event_type = 'filtre_transactions_applique';

  constructor(
    public event_properties: FiltreTransactionsAppliqueProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class GedAccedee implements BaseEvent {
  event_type = 'ged_accedee';
}

export class LabelTransactionApplique implements BaseEvent {
  event_type = 'label_transaction_applique';

  constructor(
    public event_properties: LabelTransactionAppliqueProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class LectureVideoBesoinAideLancee implements BaseEvent {
  event_type = 'lecture_video_besoin_aide_lancee';

  constructor(
    public event_properties: LectureVideoBesoinAideLanceeProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class ListeRegleVue implements BaseEvent {
  event_type = 'liste_regle_vue';
}

export class MenuArchiverFicheClientClique implements BaseEvent {
  event_type = 'menu_archiver_fiche_client_clique';
}

export class MenuExporterEncoursFicheClientClique implements BaseEvent {
  event_type = 'menu_exporter_encours_fiche_client_clique';
}

export class MotifVirement25KChoisi implements BaseEvent {
  event_type = 'motif_virement_25k_choisi';

  constructor(
    public event_properties: MotifVirement25KChoisiProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class MultiplesElementsSelectionnes implements BaseEvent {
  event_type = 'multiples_elements_selectionnes';

  constructor(
    public event_properties: MultiplesElementsSelectionnesProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class NouveauClientEnregistre implements BaseEvent {
  event_type = 'nouveau_client_enregistre';

  constructor(
    public event_properties: NouveauClientEnregistreProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class OngletDevisFicheClientClique implements BaseEvent {
  event_type = 'onglet_devis_fiche_client_clique';
}

export class OngletHistoriqueFicheClientClique implements BaseEvent {
  event_type = 'onglet_historique_fiche_client_clique';
}

export class RegleLabelCree implements BaseEvent {
  event_type = 'regle_label_cree';

  constructor(
    public event_properties: RegleLabelCreeProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class TagAjoute implements BaseEvent {
  event_type = 'tag_ajoute';

  constructor(
    public event_properties: TagAjouteProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class TransactionScindee implements BaseEvent {
  event_type = 'transaction_scindee';
}

export class TransactionsComptesAccedee implements BaseEvent {
  event_type = 'transactions_comptes_accedee';
}

export class TvaEstimationVue implements BaseEvent {
  event_type = 'tva_estimation_vue';

  constructor(
    public event_properties: TvaEstimationVueProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class TvaValidee implements BaseEvent {
  event_type = 'tva_validee';

  constructor(
    public event_properties: TvaValideeProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class ValidationTvaAvortee implements BaseEvent {
  event_type = 'validation_tva_avortee';
}

export class ValidationTvaCommencee implements BaseEvent {
  event_type = 'validation_tva_commencee';

  constructor(
    public event_properties: ValidationTvaCommenceeProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class Virement25KCommence implements BaseEvent {
  event_type = 'virement_25k_commence';
}

export class Virement25KEnSavoirPlusConsulte implements BaseEvent {
  event_type = 'virement_25k_en_savoir_plus_consulte';
}

export class VirementCommence implements BaseEvent {
  event_type = 'virement_commence';

  constructor(
    public event_properties: VirementCommenceProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class VirementEnvoye implements BaseEvent {
  event_type = 'virement_envoye';
}

export type PromiseResult<T> = { promise: Promise<T | void> };

const getVoidPromiseResult = () => ({ promise: Promise.resolve() });

// prettier-ignore
export class Ampli {
  private disabled: boolean = false;
  private amplitude?: BrowserClient;

  get client(): BrowserClient {
    this.isInitializedAndEnabled();
    return this.amplitude!;
  }

  get isLoaded(): boolean {
    return this.amplitude != null;
  }

  private isInitializedAndEnabled(): boolean {
    if (!this.amplitude) {
      console.error('ERROR: Ampli is not yet initialized. Have you called ampli.load() on app start?');
      return false;
    }
    return !this.disabled;
  }

  /**
   * Initialize the Ampli SDK. Call once when your application starts.
   *
   * @param options Configuration options to initialize the Ampli SDK with.
   */
  load(options: LoadOptions): PromiseResult<void> {
    this.disabled = options.disabled ?? false;

    if (this.amplitude) {
      console.warn('WARNING: Ampli is already intialized. Ampli.load() should be called once at application startup.');
      return getVoidPromiseResult();
    }

    let apiKey: string | null = null;
    if (options.client && 'apiKey' in options.client) {
      apiKey = options.client.apiKey;
    } else if ('environment' in options) {
      apiKey = ApiKey[options.environment];
    }

    if (options.client && 'instance' in options.client) {
      this.amplitude = options.client.instance;
    } else if (apiKey) {
      this.amplitude = amplitude.createInstance();
      const configuration = (options.client && 'configuration' in options.client) ? options.client.configuration : {};
      return this.amplitude.init(apiKey, undefined, { ...DefaultConfiguration, ...configuration });
    } else {
      console.error("ERROR: ampli.load() requires 'environment', 'client.apiKey', or 'client.instance'");
    }

    return getVoidPromiseResult();
  }

  /**
   * Identify a user and set user properties.
   *
   * @param userId The user's id.
   * @param properties The user properties.
   * @param options Optional event options.
   */
  identify(
    userId: string | undefined,
    properties?: IdentifyProperties,
    options?: EventOptions,
  ): PromiseResult<Result> {
    if (!this.isInitializedAndEnabled()) {
      return getVoidPromiseResult();
    }

    if (userId) {
      options = {...options,  user_id: userId};
    }

    const amplitudeIdentify = new amplitude.Identify();
    const eventProperties = properties;
    if (eventProperties != null) {
      for (const [key, value] of Object.entries(eventProperties)) {
        amplitudeIdentify.set(key, value);
      }
    }
    return this.amplitude!.identify(
      amplitudeIdentify,
      options,
    );
  }

 /**
  * Flush the event.
  */
  flush() : PromiseResult<Result> {
    if (!this.isInitializedAndEnabled()) {
      return getVoidPromiseResult();
    }

    return this.amplitude!.flush();
  }

  /**
   * Track event
   *
   * @param event The event to track.
   * @param options Optional event options.
   */
  track(event: Event, options?: EventOptions): PromiseResult<Result> {
    if (!this.isInitializedAndEnabled()) {
      return getVoidPromiseResult();
    }

    return this.amplitude!.track(event, undefined, options);
  }

  /**
   * action_en_masse_commencee
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/tiime/Tiime%20Tracking%20Plan/events/main/latest/action_en_masse_commencee)
   *
   * L'event est déclenché lorsqu'un utilisateur **clique** sur une action non disabled de la tool bar
   *
   * @param properties The event's properties (e.g. a_filtre)
   * @param options Amplitude event options.
   */
  actionEnMasseCommencee(
    properties: ActionEnMasseCommenceeProperties,
    options?: EventOptions,
  ) {
    return this.track(new ActionEnMasseCommencee(properties), options);
  }

  /**
   * action_en_masse_tooltip_disabled_vue
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/tiime/Tiime%20Tracking%20Plan/events/main/latest/action_en_masse_tooltip_disabled_vue)
   *
   * L'event est déclenché lorsqu'un utilisateur **hoover ou essaye de cliquer** sur une action de la tool bar un bouton de la tooltip lorsque ce dernier est désactivé
   *
   * @param properties The event's properties (e.g. type_action)
   * @param options Amplitude event options.
   */
  actionEnMasseTooltipDisabledVue(
    properties: ActionEnMasseTooltipDisabledVueProperties,
    options?: EventOptions,
  ) {
    return this.track(new ActionEnMasseTooltipDisabledVue(properties), options);
  }

  /**
   * bouton_ajout_tag_clique
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/tiime/Tiime%20Tracking%20Plan/events/main/latest/bouton_ajout_tag_clique)
   *
   * L'event est déclenché lorsqu'un utilisateur clique sur l’icone “#” et que celui-ci ouvre la popup d’ajout de tag (ce n’est pas le cas sur les frais kilométriques et les frais à rembourser) ou sur le bouton “ajouter un tag” dans le dropdown ou depuis la preview d’un document.
   *
   * @param properties The event's properties (e.g. applique_sur)
   * @param options Amplitude event options.
   */
  boutonAjoutTagClique(
    properties: BoutonAjoutTagCliqueProperties,
    options?: EventOptions,
  ) {
    return this.track(new BoutonAjoutTagClique(properties), options);
  }

  /**
   * bouton_ajoute_label_clique
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/tiime/Tiime%20Tracking%20Plan/events/main/latest/bouton_ajoute_label_clique)
   *
   * L'event est déclenché lorsqu'un utilisateur clique sur le bouton "Ajouter un label" sur une ligne liée à une transaction
   *
   * @param options Amplitude event options.
   */
  boutonAjouteLabelClique(
    options?: EventOptions,
  ) {
    return this.track(new BoutonAjouteLabelClique(), options);
  }

  /**
   * bouton_envoyer_via_tiime_popin_relance_client_clique
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/tiime/Tiime%20Tracking%20Plan/events/main/latest/bouton_envoyer_via_tiime_popin_relance_client_clique)
   *
   * On veut tracker l'envoi via Tiime de la relance d'un client.
   *
   * Owner: Nicolas Pavard
   *
   * @param options Amplitude event options.
   */
  boutonEnvoyerViaTiimePopinRelanceClientClique(
    options?: EventOptions,
  ) {
    return this.track(new BoutonEnvoyerViaTiimePopinRelanceClientClique(), options);
  }

  /**
   * bouton_modifier_fiche_client_clique
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/tiime/Tiime%20Tracking%20Plan/events/main/latest/bouton_modifier_fiche_client_clique)
   *
   * **L'event est déclenché à chaque fois que le bouton présent sur la fiche client permettant de modifier le client est cliqué.**
   *
   * Owner: Nicolas Pavard
   *
   * @param options Amplitude event options.
   */
  boutonModifierFicheClientClique(
    options?: EventOptions,
  ) {
    return this.track(new BoutonModifierFicheClientClique(), options);
  }

  /**
   * bouton_nouveau_devis_fiche_client_clique
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/tiime/Tiime%20Tracking%20Plan/events/main/latest/bouton_nouveau_devis_fiche_client_clique)
   *
   * **L'event est déclenché à chaque fois que le bouton "Nouveau devis" de la fiche client est cliqué.**
   *
   * Owner: Nicolas Pavard
   *
   * @param options Amplitude event options.
   */
  boutonNouveauDevisFicheClientClique(
    options?: EventOptions,
  ) {
    return this.track(new BoutonNouveauDevisFicheClientClique(), options);
  }

  /**
   * bouton_nouvelle_facture_fiche_client_clique
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/tiime/Tiime%20Tracking%20Plan/events/main/latest/bouton_nouvelle_facture_fiche_client_clique)
   *
   * **L'event est déclenché à chaque fois que le bouton "Nouvelle facture" de la fiche client est cliqué.**
   *
   * Owner: Nicolas Pavard
   *
   * @param options Amplitude event options.
   */
  boutonNouvelleFactureFicheClientClique(
    options?: EventOptions,
  ) {
    return this.track(new BoutonNouvelleFactureFicheClientClique(), options);
  }

  /**
   * bouton_relance_client_timeline_clique
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/tiime/Tiime%20Tracking%20Plan/events/main/latest/bouton_relance_client_timeline_clique)
   *
   * Ici on souhaite savoir si la fonctionnalité de relance de l'en cours client est utilisée.
   *
   * Owner: Nicolas Pavard
   *
   * @param options Amplitude event options.
   */
  boutonRelanceClientTimelineClique(
    options?: EventOptions,
  ) {
    return this.track(new BoutonRelanceClientTimelineClique(), options);
  }

  /**
   * bouton_telecharger_pdf_popin_relance_client_clique
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/tiime/Tiime%20Tracking%20Plan/events/main/latest/bouton_telecharger_pdf_popin_relance_client_clique)
   *
   * On veut tracker l'envoi via Tiime de la relance d'un client.
   *
   * Owner: Nicolas Pavard
   *
   * @param options Amplitude event options.
   */
  boutonTelechargerPdfPopinRelanceClientClique(
    options?: EventOptions,
  ) {
    return this.track(new BoutonTelechargerPdfPopinRelanceClientClique(), options);
  }

  /**
   * brouillon_facture_enregistre
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/tiime/Tiime%20Tracking%20Plan/events/main/latest/brouillon_facture_enregistre)
   *
   * **L'event est déclenché à chaque fois que le customer enregistre un brouillon de facture avec succès. Si le service renvoie une erreur, on n’envoie pas l’event.**
   *
   * Owner: Matthieu Ramo
   *
   * @param properties The event's properties (e.g. etat_brouillon_enregistre)
   * @param options Amplitude event options.
   */
  brouillonFactureEnregistre(
    properties: BrouillonFactureEnregistreProperties,
    options?: EventOptions,
  ) {
    return this.track(new BrouillonFactureEnregistre(properties), options);
  }

  /**
   * client_facture_ajoute
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/tiime/Tiime%20Tracking%20Plan/events/main/latest/client_facture_ajoute)
   *
   * **L'event est déclenché à chaque fois que le customer ajoute un client sur une de ses factures. Si le customer change un client sur une facture on renvoie l’event.**
   *
   * Owner: Matthieu Ramo
   *
   * @param properties The event's properties (e.g. identifiant_chronos_de_la_facture)
   * @param options Amplitude event options.
   */
  clientFactureAjoute(
    properties?: ClientFactureAjouteProperties,
    options?: EventOptions,
  ) {
    return this.track(new ClientFactureAjoute(properties), options);
  }

  /**
   * comptabilité_a_faire_accedee
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/tiime/Tiime%20Tracking%20Plan/events/main/latest/comptabilit%C3%A9_a_faire_accedee)
   *
   * L'event est déclenché lorsqu'un utilisateur charge la page “A Faire - Comptabilité”
   *
   * Owner: Elise Lagreze
   *
   * @param options Amplitude event options.
   */
  comptabiliteAFaireAccedee(
    options?: EventOptions,
  ) {
    return this.track(new ComptabiliteAFaireAccedee(), options);
  }

  /**
   * connexion_web
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/tiime/Tiime%20Tracking%20Plan/events/main/latest/connexion_web)
   *
   * L'event est déclenché lorsqu'un utilisateur s’authentifie sur l’application web c’est à dire après la redirection d'auth0 vers notre app (donc après chaque authentification)
   *
   * Owner: Matthieu Ramo
   *
   * @param options Amplitude event options.
   */
  connexionWeb(
    options?: EventOptions,
  ) {
    return this.track(new ConnexionWeb(), options);
  }

  /**
   * conseiller_email_contacte
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/tiime/Tiime%20Tracking%20Plan/events/main/latest/conseiller_email_contacte)
   *
   * **Au clic sur l’icone “email” dans l’onglet Business. IOS-ANDROID 
   *  au clic sur l’icone “email” dans le header de la homepage WEB. Un clic déclenche un event**
   *
   * @param options Amplitude event options.
   */
  conseillerEmailContacte(
    options?: EventOptions,
  ) {
    return this.track(new ConseillerEmailContacte(), options);
  }

  /**
   * creation_facture_echouee
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/tiime/Tiime%20Tracking%20Plan/events/main/latest/creation_facture_echouee)
   *
   * Chaque fois que le customer tente d’enregistrer une facture sans succès.
   *
   * Owner: Matthieu Ramo
   *
   * @param properties The event's properties (e.g. erreurs_creation_facture)
   * @param options Amplitude event options.
   */
  creationFactureEchouee(
    properties: CreationFactureEchoueeProperties,
    options?: EventOptions,
  ) {
    return this.track(new CreationFactureEchouee(properties), options);
  }

  /**
   * creation_regle_label_commencee
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/tiime/Tiime%20Tracking%20Plan/events/main/latest/creation_regle_label_commencee)
   *
   * L'event est déclenché lorsqu'un utilisateur clique sur l'élément "Créer le label" lors de l'ajout d'un label à une transaction
   *
   * @param options Amplitude event options.
   */
  creationRegleLabelCommencee(
    options?: EventOptions,
  ) {
    return this.track(new CreationRegleLabelCommencee(), options);
  }

  /**
   * demande_virement_25k_envoye
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/tiime/Tiime%20Tracking%20Plan/events/main/latest/demande_virement_25k_envoye)
   *
   * **Tracking des virements à +25k validés par l’utilisateur et transmis à Treezor. Au clic sur le bouton “Confirmer” à la dernière page du funel de virement**
   *
   * @param options Amplitude event options.
   */
  demandeVirement25KEnvoye(
    options?: EventOptions,
  ) {
    return this.track(new DemandeVirement25KEnvoye(), options);
  }

  /**
   * detail_document_accede
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/tiime/Tiime%20Tracking%20Plan/events/main/latest/detail_document_accede)
   *
   * L'event est déclenché lorsqu'un utilisateur accède a l'écran contenant le détail d'un document
   *
   * Owner: Matthieu Ramo
   *
   * @param properties The event's properties (e.g. type_document)
   * @param options Amplitude event options.
   */
  detailDocumentAccede(
    properties?: DetailDocumentAccedeProperties,
    options?: EventOptions,
  ) {
    return this.track(new DetailDocumentAccede(properties), options);
  }

  /**
   * details_frais_sur_note_frais_vu
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/tiime/Tiime%20Tracking%20Plan/events/main/latest/details_frais_sur_note_frais_vu)
   *
   * L'event est déclenché lorsqu'un utilisateur clique sur la ligne ou sur l’oeil qui apparait au hover de chaque ligne de frais.
   *
   * @param options Amplitude event options.
   */
  detailsFraisSurNoteFraisVu(
    options?: EventOptions,
  ) {
    return this.track(new DetailsFraisSurNoteFraisVu(), options);
  }

  /**
   * devis_cree
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/tiime/Tiime%20Tracking%20Plan/events/main/latest/devis_cree)
   *
   * L'event est déclenché lorsqu'un utilisateur enregistre un devis
   *
   * Owner: Matthieu Ramo
   *
   * @param properties The event's properties (e.g. a_adresse_livraison)
   * @param options Amplitude event options.
   */
  devisCree(
    properties: DevisCreeProperties,
    options?: EventOptions,
  ) {
    return this.track(new DevisCree(properties), options);
  }

  /**
   * devis_envoye
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/tiime/Tiime%20Tracking%20Plan/events/main/latest/devis_envoye)
   *
   * Lorsqu'un utilisateur envoie un devis
   *
   * Owner: Matthieu Ramo
   *
   * @param properties The event's properties (e.g. identifiant_chronos_du_devis)
   * @param options Amplitude event options.
   */
  devisEnvoye(
    properties?: DevisEnvoyeProperties,
    options?: EventOptions,
  ) {
    return this.track(new DevisEnvoye(properties), options);
  }

  /**
   * devis_telecharge
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/tiime/Tiime%20Tracking%20Plan/events/main/latest/devis_telecharge)
   *
   * Lorsqu'un utilisateur télécharge un devis.
   *
   * Owner: Matthieu Ramo
   *
   * @param properties The event's properties (e.g. identifiant_chronos_du_devis)
   * @param options Amplitude event options.
   */
  devisTelecharge(
    properties?: DevisTelechargeProperties,
    options?: EventOptions,
  ) {
    return this.track(new DevisTelecharge(properties), options);
  }

  /**
   * document_ajoute
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/tiime/Tiime%20Tracking%20Plan/events/main/latest/document_ajoute)
   *
   * L'event est déclenché lorsqu'un utilisateur upload un document dans sa GED. Pour cet event, nous attendrons la validation du serveur que le document ait bien été uploadé. (succès only)
   *
   * @param properties The event's properties (e.g. type_document)
   * @param options Amplitude event options.
   */
  documentAjoute(
    properties?: DocumentAjouteProperties,
    options?: EventOptions,
  ) {
    return this.track(new DocumentAjoute(properties), options);
  }

  /**
   * document_relie
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/tiime/Tiime%20Tracking%20Plan/events/main/latest/document_relie)
   *
   * L'event est déclenché lorsqu'un utilisateur relie un document de sa GED (ou lors d’un ajout) à une transaction en cliquant sur le bouton “Matcher” et que le serveur valide l’action avec succès.
   *
   * @param properties The event's properties (e.g. transaction_avec_demande_comptable)
   * @param options Amplitude event options.
   */
  documentRelie(
    properties: DocumentRelieProperties,
    options?: EventOptions,
  ) {
    return this.track(new DocumentRelie(properties), options);
  }

  /**
   * editeur_client_ouvert
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/tiime/Tiime%20Tracking%20Plan/events/main/latest/editeur_client_ouvert)
   *
   * Chaque fois que le customer ouvre l'éditeur de client en mode édition.
   *
   * Owner: Matthieu Ramo
   *
   * @param properties The event's properties (e.g. etat_client_ouvert)
   * @param options Amplitude event options.
   */
  editeurClientOuvert(
    properties: EditeurClientOuvertProperties,
    options?: EventOptions,
  ) {
    return this.track(new EditeurClientOuvert(properties), options);
  }

  /**
   * editeur_devis_ouvert
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/tiime/Tiime%20Tracking%20Plan/events/main/latest/editeur_devis_ouvert)
   *
   * Lorsque l’utilisateur ouvre l'éditeur de devis lors de la création d’un nouveau devis 
   *  Chaque fois que le customer ouvre l'éditeur lors de la modification d’un devis existant ou résultant de la duplication d’un devis
   *
   * Owner: Sébastien Trouillet
   *
   * @param properties The event's properties (e.g. etat_devis_ouvert)
   * @param options Amplitude event options.
   */
  editeurDevisOuvert(
    properties: EditeurDevisOuvertProperties,
    options?: EventOptions,
  ) {
    return this.track(new EditeurDevisOuvert(properties), options);
  }

  /**
   * editeur_facture_ouvert
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/tiime/Tiime%20Tracking%20Plan/events/main/latest/editeur_facture_ouvert)
   *
   * Chaque fois que le customer ouvre avec succès l'éditeur de facture en mode édition.
   *
   * Owner: Elise Lagreze
   *
   * @param properties The event's properties (e.g. creee_depuis_devis)
   * @param options Amplitude event options.
   */
  editeurFactureOuvert(
    properties: EditeurFactureOuvertProperties,
    options?: EventOptions,
  ) {
    return this.track(new EditeurFactureOuvert(properties), options);
  }

  /**
   * facture_creee
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/tiime/Tiime%20Tracking%20Plan/events/main/latest/facture_creee)
   *
   * L'event est déclenché à chaque fois que le customer à enregistré une facture au statut “facturé” avec succès. ça vaut aussi pour les actions de type “créer et envoyer”.
   *
   * Owner: Sébastien Trouillet
   *
   * @param properties The event's properties (e.g. a_adresse_livraison)
   * @param options Amplitude event options.
   */
  factureCreee(
    properties: FactureCreeeProperties,
    options?: EventOptions,
  ) {
    return this.track(new FactureCreee(properties), options);
  }

  /**
   * facture_envoyee
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/tiime/Tiime%20Tracking%20Plan/events/main/latest/facture_envoyee)
   *
   * C'est événement est envoyé chaque fois que le customer envoie une facture a un de ses clients en utilisant la fonctionnalité d'envoi de facture par mail
   *
   * Owner: siegfried.peyre@tiime.fr
   *
   * @param properties The event's properties (e.g. identifiant_chronos_de_la_facture)
   * @param options Amplitude event options.
   */
  factureEnvoyee(
    properties?: FactureEnvoyeeProperties,
    options?: EventOptions,
  ) {
    return this.track(new FactureEnvoyee(properties), options);
  }

  /**
   * facture_telechargee
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/tiime/Tiime%20Tracking%20Plan/events/main/latest/facture_telechargee)
   *
   * Le customer a téléchargé une facture (pas un brouillon de facture, une vraie facture)
   *
   * Owner: siegfried.peyre@tiime.fr
   *
   * @param properties The event's properties (e.g. identifiant_chronos_de_la_facture)
   * @param options Amplitude event options.
   */
  factureTelechargee(
    properties?: FactureTelechargeeProperties,
    options?: EventOptions,
  ) {
    return this.track(new FactureTelechargee(properties), options);
  }

  /**
   * facture_timeline_cliquee
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/tiime/Tiime%20Tracking%20Plan/events/main/latest/facture_timeline_cliquee)
   *
   * On traque l'ouverture des factures depuis la tiime line client
   *
   * Owner: Nicolas Pavard
   *
   * @param options Amplitude event options.
   */
  factureTimelineCliquee(
    options?: EventOptions,
  ) {
    return this.track(new FactureTimelineCliquee(), options);
  }

  /**
   * fiche_client_ouverte
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/tiime/Tiime%20Tracking%20Plan/events/main/latest/fiche_client_ouverte)
   *
   * **L'event est déclenché à chaque fois que la page de détails d'un client (fiche client) est ouverte.**
   *
   * Owner: Nicolas Pavard
   *
   * @param options Amplitude event options.
   */
  ficheClientOuverte(
    options?: EventOptions,
  ) {
    return this.track(new FicheClientOuverte(), options);
  }

  /**
   * filtre_transactions_applique
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/tiime/Tiime%20Tracking%20Plan/events/main/latest/filtre_transactions_applique)
   *
   * * L'event est déclenché lorsqu'un utilisateur applique un filtre sur le tab "Comptes > Transactions". Un event sera déclenché pour chaque cible de filtre modifiée (voir screenshot)
   *
   * * Attendre réponse du serveur : Non
   *
   * Owner: Elise Lagreze
   *
   * @param properties The event's properties (e.g. cible_filtre)
   * @param options Amplitude event options.
   */
  filtreTransactionsApplique(
    properties: FiltreTransactionsAppliqueProperties,
    options?: EventOptions,
  ) {
    return this.track(new FiltreTransactionsApplique(properties), options);
  }

  /**
   * ged_accedee
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/tiime/Tiime%20Tracking%20Plan/events/main/latest/ged_accedee)
   *
   * L'event est déclenché lorsqu'un utilisateur accède a l'écran contenant la liste des documents de l'utilisateur
   *
   * Owner: Matthieu Ramo
   *
   * @param options Amplitude event options.
   */
  gedAccedee(
    options?: EventOptions,
  ) {
    return this.track(new GedAccedee(), options);
  }

  /**
   * label_transaction_applique
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/tiime/Tiime%20Tracking%20Plan/events/main/latest/label_transaction_applique)
   *
   * * L'event est déclenché lorsqu'un utilisateur ajoute un label à une transaction. Cet event sera déclenché pour les labels existants ainsi que les labels créés. 
   *
   * * Attendre la validation du serveur: Oui
   *
   * @param properties The event's properties (e.g. label_existant)
   * @param options Amplitude event options.
   */
  labelTransactionApplique(
    properties: LabelTransactionAppliqueProperties,
    options?: EventOptions,
  ) {
    return this.track(new LabelTransactionApplique(properties), options);
  }

  /**
   * lecture_video_besoin_aide_lancee
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/tiime/Tiime%20Tracking%20Plan/events/main/latest/lecture_video_besoin_aide_lancee)
   *
   * L'event est déclenché lorsqu'un utilisateur clique sur “Lire la vidéo” sur l’éditeur de facture et devis
   *
   * Owner: Sébastien Trouillet
   *
   * @param properties The event's properties (e.g. identifiant_video)
   * @param options Amplitude event options.
   */
  lectureVideoBesoinAideLancee(
    properties: LectureVideoBesoinAideLanceeProperties,
    options?: EventOptions,
  ) {
    return this.track(new LectureVideoBesoinAideLancee(properties), options);
  }

  /**
   * liste_regle_vue
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/tiime/Tiime%20Tracking%20Plan/events/main/latest/liste_regle_vue)
   *
   * L'event est déclenché lorsqu'un utilisateur accède à la liste de ses règles, l’event se déclenche  lors du passage à visible de la popup.
   *
   * @param options Amplitude event options.
   */
  listeRegleVue(
    options?: EventOptions,
  ) {
    return this.track(new ListeRegleVue(), options);
  }

  /**
   * menu_archiver_fiche_client_clique
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/tiime/Tiime%20Tracking%20Plan/events/main/latest/menu_archiver_fiche_client_clique)
   *
   * On veut savoir si la fonctionnalité est utilisée ou non depuis la page client.
   *
   * Owner: Nicolas Pavard
   *
   * @param options Amplitude event options.
   */
  menuArchiverFicheClientClique(
    options?: EventOptions,
  ) {
    return this.track(new MenuArchiverFicheClientClique(), options);
  }

  /**
   * menu_exporter_encours_fiche_client_clique
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/tiime/Tiime%20Tracking%20Plan/events/main/latest/menu_exporter_encours_fiche_client_clique)
   *
   * On veut tracker l'export csv d'un client depuis la page client
   *
   * Owner: Nicolas Pavard
   *
   * @param options Amplitude event options.
   */
  menuExporterEncoursFicheClientClique(
    options?: EventOptions,
  ) {
    return this.track(new MenuExporterEncoursFicheClientClique(), options);
  }

  /**
   * motif_virement_25k_choisi
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/tiime/Tiime%20Tracking%20Plan/events/main/latest/motif_virement_25k_choisi)
   *
   * **Tracking du motif de virement à plus de 25K choisi par l’utilisateur avant l’envoi du virement en banque. Ex properties : salaire, facture...**
   *
   * @param properties The event's properties (e.g. motif_virement_25k_choisi)
   * @param options Amplitude event options.
   */
  motifVirement25KChoisi(
    properties: MotifVirement25KChoisiProperties,
    options?: EventOptions,
  ) {
    return this.track(new MotifVirement25KChoisi(properties), options);
  }

  /**
   * multiples_elements_selectionnes
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/tiime/Tiime%20Tracking%20Plan/events/main/latest/multiples_elements_selectionnes)
   *
   * L'event est déclenché lorsqu'un utilisateur **utilise la selection rapide** d'éléments. Soit en cliquant directement sur la checkbox (tous) soit en sélectionnant une catégorie dans le dropdown
   *
   * @param properties The event's properties (e.g. mode_selection)
   * @param options Amplitude event options.
   */
  multiplesElementsSelectionnes(
    properties: MultiplesElementsSelectionnesProperties,
    options?: EventOptions,
  ) {
    return this.track(new MultiplesElementsSelectionnes(properties), options);
  }

  /**
   * nouveau_client_enregistre
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/tiime/Tiime%20Tracking%20Plan/events/main/latest/nouveau_client_enregistre)
   *
   * L'event est déclenché lorsqu'un utilisateur clique sur le bouton “Créer” en haut à droite de l'éditeur de client pour valider la création d'un client et que le serveur valide la création du client
   *
   * Owner: Matthieu Ramo
   *
   * @param properties The event's properties (e.g. identifiant_chronos_du_client)
   * @param options Amplitude event options.
   */
  nouveauClientEnregistre(
    properties: NouveauClientEnregistreProperties,
    options?: EventOptions,
  ) {
    return this.track(new NouveauClientEnregistre(properties), options);
  }

  /**
   * onglet_devis_fiche_client_clique
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/tiime/Tiime%20Tracking%20Plan/events/main/latest/onglet_devis_fiche_client_clique)
   *
   * **L'event est déclenché à chaque fois que l'onglet "Devis" de la fiche client est cliqué.**
   *
   * Owner: Nicolas Pavard
   *
   * @param options Amplitude event options.
   */
  ongletDevisFicheClientClique(
    options?: EventOptions,
  ) {
    return this.track(new OngletDevisFicheClientClique(), options);
  }

  /**
   * onglet_historique_fiche_client_clique
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/tiime/Tiime%20Tracking%20Plan/events/main/latest/onglet_historique_fiche_client_clique)
   *
   * **L'event est déclenché à chaque fois que l'onglet "Historique" de la fiche client est cliqué.**
   *
   * Owner: Nicolas Pavard
   *
   * @param options Amplitude event options.
   */
  ongletHistoriqueFicheClientClique(
    options?: EventOptions,
  ) {
    return this.track(new OngletHistoriqueFicheClientClique(), options);
  }

  /**
   * regle_label_cree
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/tiime/Tiime%20Tracking%20Plan/events/main/latest/regle_label_cree)
   *
   * L'event est déclenché lorsqu'un utilisateur clique sur le bouton "valider" dans la pop de création d'une règle de label nommée "Créer une règle"
   *
   * @param properties The event's properties (e.g. intitule_matching_modifie)
   * @param options Amplitude event options.
   */
  regleLabelCree(
    properties: RegleLabelCreeProperties,
    options?: EventOptions,
  ) {
    return this.track(new RegleLabelCree(properties), options);
  }

  /**
   * tag_ajoute
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/tiime/Tiime%20Tracking%20Plan/events/main/latest/tag_ajoute)
   *
   * L'event est déclenché lorsqu'un utilisateur clique sur l’icone “#” et que celui-ci ouvre la popup d’ajout de tag (ce n’est pas le cas sur les frais kilométriques et les frais à rembourser) ou sur le bouton “ajouter un tag” dans le dropdown ou depuis la preview d’un document.
   *
   * @param properties The event's properties (e.g. applique_sur)
   * @param options Amplitude event options.
   */
  tagAjoute(
    properties: TagAjouteProperties,
    options?: EventOptions,
  ) {
    return this.track(new TagAjoute(properties), options);
  }

  /**
   * transaction_scindee
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/tiime/Tiime%20Tracking%20Plan/events/main/latest/transaction_scindee)
   *
   * * L'event est déclenché lorsqu'un utilisateur clique sur le bouton valider de la pop up “Diviser la transaction” et l'action est validé par le serveur
   *
   * * Attendre la réponse du serveur : Oui - Succès only
   *
   * @param options Amplitude event options.
   */
  transactionScindee(
    options?: EventOptions,
  ) {
    return this.track(new TransactionScindee(), options);
  }

  /**
   * transactions_comptes_accedee
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/tiime/Tiime%20Tracking%20Plan/events/main/latest/transactions_comptes_accedee)
   *
   * L'event est déclenché à chaque fois qu'un utilisateur accède à l'écran Comptes (App Tiime) et transactions sur le Web et le chargement de la page/écran est terminé.
   *
   * RK: Cet event n’est pas déclenché sur la page “A faire”
   *
   * Owner: Sébastien Trouillet
   *
   * @param options Amplitude event options.
   */
  transactionsComptesAccedee(
    options?: EventOptions,
  ) {
    return this.track(new TransactionsComptesAccedee(), options);
  }

  /**
   * tva_estimation_vue
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/tiime/Tiime%20Tracking%20Plan/events/main/latest/tva_estimation_vue)
   *
   * Cet event est déclenché lorsque la page/écran “Estimation de la TVA” est chargée
   *
   * Owner: Elise Lagreze
   *
   * @param properties The event's properties (e.g. tva_period_en_cours)
   * @param options Amplitude event options.
   */
  tvaEstimationVue(
    properties: TvaEstimationVueProperties,
    options?: EventOptions,
  ) {
    return this.track(new TvaEstimationVue(properties), options);
  }

  /**
   * tva_validee
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/tiime/Tiime%20Tracking%20Plan/events/main/latest/tva_validee)
   *
   * L'event est déclenché lorsque de le server reçoit et prends en compte la validation de la TVA faite par un utilisateur ayant reçu une demande de validation de la TVA.
   *
   * Owner: Matthieu Ramo
   *
   * @param properties The event's properties (e.g. element_de_validation)
   * @param options Amplitude event options.
   */
  tvaValidee(
    properties: TvaValideeProperties,
    options?: EventOptions,
  ) {
    return this.track(new TvaValidee(properties), options);
  }

  /**
   * validation_tva_avortee
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/tiime/Tiime%20Tracking%20Plan/events/main/latest/validation_tva_avortee)
   *
   * L'event est déclenché lorsqu'un utilisateur clique soit sur:
   *
   * * la “X” (ou “<“ sur mobile) en haut à droite de la page l’estimation de TVA et que la TVA est demandée et non validée
   *
   * * Sur le bouton “Revenir plus tard” de la pop de notification web “Valider votre TVA”
   *
   * Owner: Elise Lagreze
   *
   * @param options Amplitude event options.
   */
  validationTvaAvortee(
    options?: EventOptions,
  ) {
    return this.track(new ValidationTvaAvortee(), options);
  }

  /**
   * validation_tva_commencee
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/tiime/Tiime%20Tracking%20Plan/events/main/latest/validation_tva_commencee)
   *
   * L'event est déclenché quand la page de “Estimation de la TVA” est chargé et 
   *
   * * que la validation de TVA est demandée
   *
   * * que la TVA n’a pas déjà été validée et est encore à validée
   *
   * Owner: Elise Lagreze
   *
   * @param properties The event's properties (e.g. validation_tva_acces)
   * @param options Amplitude event options.
   */
  validationTvaCommencee(
    properties: ValidationTvaCommenceeProperties,
    options?: EventOptions,
  ) {
    return this.track(new ValidationTvaCommencee(properties), options);
  }

  /**
   * virement_25k_commence
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/tiime/Tiime%20Tracking%20Plan/events/main/latest/virement_25k_commence)
   *
   * **tracking de tous les virements >=25k démarrés**
   *
   * @param options Amplitude event options.
   */
  virement25KCommence(
    options?: EventOptions,
  ) {
    return this.track(new Virement25KCommence(), options);
  }

  /**
   * virement_25k_en_savoir_plus_consulte
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/tiime/Tiime%20Tracking%20Plan/events/main/latest/virement_25k_en_savoir_plus_consulte)
   *
   * **Tracking des utilisateurs qui cliquent sur le bouton d’info en plus. Au clic sur le bouton “en savoir plus”du funel des 25k.**
   *
   * @param options Amplitude event options.
   */
  virement25KEnSavoirPlusConsulte(
    options?: EventOptions,
  ) {
    return this.track(new Virement25KEnSavoirPlusConsulte(), options);
  }

  /**
   * virement_commence
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/tiime/Tiime%20Tracking%20Plan/events/main/latest/virement_commence)
   *
   * L'event est déclenché lorsqu'un utilisateur attéris sur la page/screen lui permettant d'envoyer un virement.
   *
   * Owner: Solenn ABGRALL
   *
   * @param properties The event's properties (e.g. source_virement)
   * @param options Amplitude event options.
   */
  virementCommence(
    properties: VirementCommenceProperties,
    options?: EventOptions,
  ) {
    return this.track(new VirementCommence(properties), options);
  }

  /**
   * virement_envoye
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/tiime/Tiime%20Tracking%20Plan/events/main/latest/virement_envoye)
   *
   * L'event est déclenché lorsqu'un utilisateur confirme l'envoie d'un virement et que ce dernier est pris en compte par le serveur.
   *
   * Owner: Solenn ABGRALL
   *
   * @param options Amplitude event options.
   */
  virementEnvoye(
    options?: EventOptions,
  ) {
    return this.track(new VirementEnvoye(), options);
  }
}

export const ampli = new Ampli();

// BASE TYPES
type BrowserOptions = amplitude.Types.BrowserOptions;

export type BrowserClient = amplitude.Types.BrowserClient;
export type BaseEvent = amplitude.Types.BaseEvent;
export type IdentifyEvent = amplitude.Types.IdentifyEvent;
export type GroupEvent = amplitude.Types.GroupIdentifyEvent;
export type Event = amplitude.Types.Event;
export type EventOptions = amplitude.Types.EventOptions;
export type Result = amplitude.Types.Result;
