import { TiimeSelectOption } from 'tiime-components';

import { DashboardPeriodType } from '@enums';

export enum Period {
  Custom = DashboardPeriodType.customPeriod,
  ThreeMonths = 3,
  SixMonths = 6,
  TwelveMonths = 12,
  CurrentAccountingPeriod = DashboardPeriodType.currentAccountingPeriod,
}

export enum PeriodComparison {
  None = 0,
  FirstYearBack = 1,
  SecondYearBack = 2,
}

export const PERIOD_OPTIONS: TiimeSelectOption<Period>[] = [
  {
    value: Period.Custom,
    label: 'Personnalisée',
  },
  {
    value: Period.ThreeMonths,
    label: '3 derniers mois',
  },
  {
    value: Period.SixMonths,
    label: '6 derniers mois',
  },
  {
    value: Period.TwelveMonths,
    label: '12 derniers mois',
  },
  {
    value: Period.CurrentAccountingPeriod,
    label: 'Exercice comptable en cours',
  },
];

export const PERIOD_COMPARISON_OPTIONS: TiimeSelectOption<PeriodComparison>[] =
  [
    {
      value: PeriodComparison.None,
      label: 'Aucune',
    },
    {
      value: PeriodComparison.FirstYearBack,
      label: '1 année en arrière',
    },
    {
      value: PeriodComparison.SecondYearBack,
      label: '2 années en arrière',
    },
  ];
