<ng-container *tiimeLet="backButtonConfig$ | async as backButtonConfig">
  <div *ngIf="backButtonConfig" class="back-button-container">
    <a
      [routerLink]="backButtonConfig.routerLink"
      [relativeTo]="backButtonConfig.relativeTo"
      [queryParams]="backButtonConfig.queryParams"
    >
      <button tiime-button icon normal>
        <mat-icon inline="true" fontIcon="icon-ic-back"></mat-icon>
      </button>
      {{ backButtonConfig.label }}
    </a>
  </div>

  <!-- Here we use display none rather than a ngIf because the size of navigation items need this part of the interface to work -->
  <div class="app-navigation" [class.display-none]="backButtonConfig !== null">
    <ng-content select="[app-navigation]"></ng-content>
  </div>

  <ng-container *tiimeLet="contentTitle$ | async as contentTitle">
    <div
      *ngIf="contentTitle.length"
      class="app-title"
      [class.centered]="centeredTitle"
    >
      {{ contentTitle }}
    </div>
  </ng-container>
  <div class="app-content" cdkScrollable>
    <ng-content select="[app-content]"></ng-content>
  </div>
</ng-container>
