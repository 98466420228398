import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ExpensesReportEditorService {
  private _cancelEdit$ = new Subject<void>();

  get cancelEdit$(): Observable<void> {
    return this._cancelEdit$.asObservable();
  }

  cancelEdit(): void {
    this._cancelEdit$.next();
  }
}
