import { CommonModule } from '@angular/common';
import { NgModule, Injector } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import {
  TiimePipesModule,
  TiimeDirectivesModule,
} from '@manakincubber/tiime-utils';
import {
  provideCacheableAnimationLoader,
  provideLottieOptions,
} from 'ngx-lottie';

import * as Components from './components';
import {
  EmptyStateSearchModule,
  ImportDialogContainerModule,
} from './components';
import { DatepickerModule } from './components/datepicker/datepicker.module';
import { MonthPeriodPickerModule } from './components/month-period-picker/month-period-picker.module';
import { IconsModule } from './icons/icons.module';
import { MaterialModule } from './material/material.module';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    IconsModule,
    MaterialModule,
    TiimePipesModule,
    EmptyStateSearchModule,
  ],
  declarations: [Components.PageLoaderComponent],
  exports: [
    CommonModule,
    FormsModule,
    IconsModule,
    ReactiveFormsModule,
    MaterialModule,
    TiimePipesModule,
    TiimeDirectivesModule,
    Components.PageLoaderComponent,
    EmptyStateSearchModule,
    MonthPeriodPickerModule,
    DatepickerModule,
    ImportDialogContainerModule,
  ],
  providers: [
    provideLottieOptions({
      player: () => import('lottie-web/build/player/lottie_svg'),
    }),
    provideCacheableAnimationLoader(),
  ],
})
export class SharedModule {
  static injector: Injector;

  constructor(injector: Injector) {
    SharedModule.injector = injector;
  }
}
