import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { iif, Observable, of } from 'rxjs';
import { map, mapTo, switchMap, switchMapTo } from 'rxjs/operators';
import { DefaultGetOptions, GetOptions } from 'tiime-components';

import { BudgetInsightService } from '@core';
import { TemporaryEncoder } from '@core/temporary-encoder';
import { ApiAlertError } from '@decorators/api-alert-error';
import { BankProvider } from '@enums';
import { BankConnection, BankAccount, BankAccountApiContract } from '@models';

@Injectable({
  providedIn: 'root',
})
export class BankAccountService {
  readonly resource = 'api/v1/companies/{companyId}/bank_accounts';

  constructor(
    private readonly http: HttpClient,
    private readonly budgetInsightService: BudgetInsightService,
  ) {}

  @ApiAlertError()
  getAll(getOptions: DefaultGetOptions = {}): Observable<BankAccount[]> {
    const partialOptions = new GetOptions(getOptions).toHttpGetOptions();
    const options = {
      params: new HttpParams({
        fromObject: partialOptions.params,
        encoder: new TemporaryEncoder(),
      }),
    };

    return this.http
      .get(this.resource, options)
      .pipe(
        map((bankAccountsJson: any) =>
          bankAccountsJson.map((bankAccountJson: any) =>
            BankAccount.fromJson(bankAccountJson),
          ),
        ),
      );
  }

  @ApiAlertError()
  getTiimeBankAccount(): Observable<BankAccount> {
    return this.getAll().pipe(
      map(accounts => (accounts || []).filter(account => account.isWallet)[0]),
    );
  }

  @ApiAlertError()
  create(
    bankAccount: BankAccount,
    bankConnectionId: number,
  ): Observable<BankAccount> {
    const url = `api/v1/companies/{companyId}/bank_connections/${bankConnectionId}/bank_accounts`;
    return this.http
      .post<BankAccountApiContract>(
        url,
        BankAccount.toCreateChronosAccountJson(bankAccount),
      )
      .pipe(
        map((bankAccountJson: BankAccountApiContract) =>
          BankAccount.fromJson(bankAccountJson),
        ),
      );
  }

  disable(
    bankAccount: BankAccount,
    bankConnection: BankConnection,
  ): Observable<BankAccount> {
    return this.enableOrDisable(bankAccount).pipe(
      switchMap((disabledBankAccount: BankAccount) =>
        iif(
          () => bankConnection.bank.provider === BankProvider.BUDGEA,
          this.budgetInsightService
            .enableOrDisableBankAccount(bankAccount)
            .pipe(mapTo(disabledBankAccount)),
          of(disabledBankAccount),
        ),
      ),
    );
  }

  enable(
    bankAccount: BankAccount,
    bankConnection: BankConnection,
  ): Observable<BankAccount> {
    return iif(
      () => bankConnection.bank.provider === BankProvider.BUDGEA,
      this.budgetInsightService
        .enableOrDisableBankAccount(bankAccount)
        .pipe(mapTo(bankAccount)),
      of(bankAccount),
    ).pipe(
      switchMapTo(
        iif(
          () => !bankAccount.id,
          this.create(bankAccount, bankConnection.id),
          this.enableOrDisable(bankAccount),
        ),
      ),
    );
  }

  handleClosure(
    bankAccount: BankAccount,
    bankConnection: BankConnection,
  ): Observable<BankAccount> {
    return iif(
      () => !bankAccount.id,
      this.create(bankAccount, bankConnection.id).pipe(
        map((createdBankAccount: BankAccount) => ({
          ...createdBankAccount,
          closed: bankAccount.closed,
        })),
      ),
      of(bankAccount),
    ).pipe(
      switchMap((existingBankAccount: BankAccount) =>
        this.closure(existingBankAccount),
      ),
    );
  }

  updateIban(bankAccountId: number, iban: string): Observable<BankAccount> {
    return this.patch(bankAccountId, { iban });
  }

  @ApiAlertError()
  updateName(
    bankConnection: BankConnection,
    bankAccount: BankAccount,
    name: string,
  ): Observable<BankAccount> {
    bankAccount.name = name;
    return iif(
      () => !bankAccount.id,
      this.create(bankAccount, bankConnection.id),
      this.patch(bankAccount.id, { name }),
    );
  }

  @ApiAlertError()
  private enableOrDisable(bankAccount: BankAccount): Observable<BankAccount> {
    const url = `${this.resource}/${bankAccount.id}/activations`;
    return this.http
      .post<BankAccountApiContract>(url, {
        enabled: bankAccount.enabled,
      })
      .pipe(
        map((bankAccountJson: BankAccountApiContract) =>
          BankAccount.fromJson(bankAccountJson),
        ),
      );
  }

  @ApiAlertError()
  private closure(bankAccount: BankAccount): Observable<BankAccount> {
    const url = `${this.resource}/${bankAccount.id}/closures`;
    return this.http
      .post<BankAccountApiContract>(url, {
        closed: bankAccount.closed,
      })
      .pipe(
        map((bankAccountJson: BankAccountApiContract) =>
          BankAccount.fromJson(bankAccountJson),
        ),
      );
  }

  @ApiAlertError()
  private patch(
    bankAccountId: number,
    patchedPropertyBody: Partial<Pick<BankAccountApiContract, 'name' | 'iban'>>,
  ): Observable<BankAccount> {
    const url = `${this.resource}/${bankAccountId}`;
    return this.http
      .patch<BankAccountApiContract>(url, patchedPropertyBody)
      .pipe(
        map((bankAccountJson: BankAccountApiContract) =>
          BankAccount.fromJson(bankAccountJson),
        ),
      );
  }
}
