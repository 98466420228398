import { WalletSDDStatusEnum } from '@enums';

export interface CompanyAppConfigApiContract {
  show_automatic_tags: boolean;
  wallet_sdd_status: WalletSDDStatusEnum;
  label_creation_enabled?: boolean;
  enable_redirect_to_todo_page_after_login?: boolean;
  is_feature_payout_from_receipt_enabled?: boolean;
}

export class CompanyAppConfig {
  constructor(
    public showAutomaticTags: boolean,
    public walletSDDStatus: WalletSDDStatusEnum,
    public labelCreationEnabled?: boolean,
    public enableRedirectToTodoPageAfterLogin?: boolean,
    public isFeaturePayoutFromReceiptEnabled?: boolean,
  ) {}

  static fromJson(json: CompanyAppConfigApiContract): CompanyAppConfig {
    return new CompanyAppConfig(
      json.show_automatic_tags,
      json.wallet_sdd_status,
      json.label_creation_enabled,
      json.enable_redirect_to_todo_page_after_login,
      json.is_feature_payout_from_receipt_enabled,
    );
  }
}
