<div class="mat-calendar-header">
  <div class="mat-calendar-controls">
    <div
      class="mat-calendar-period-button"
      role="button"
      (click)="currentPeriodClicked()"
    >
      {{ periodButtonText }}
      <mat-icon
        class="tiime-mat-calendar-arrow"
        svgIcon="tc_icon-caret-down"
        [class.rot-180]="calendar.currentView !== 'month'"
      ></mat-icon>
    </div>

    <div class="mat-calendar-spacer"></div>

    <mat-icon
      class="tiime-mat-calendar-previous-button"
      role="button"
      svgIcon="tc_icon-chevron-right"
      [ngClass]="{ disabled: !previousEnabled() }"
      (click)="previousClicked()"
    ></mat-icon>

    <mat-icon
      class="tiime-mat-calendar-next-button"
      role="button"
      svgIcon="tc_icon-chevron-right"
      [ngClass]="{ disabled: !nextEnabled() }"
      (click)="nextClicked()"
    ></mat-icon>
  </div>
</div>
