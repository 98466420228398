import { DefaultGetOptions } from 'tiime-components';

export interface TotalBilledAmountsApiContract {
  total_excluding_taxes: number;
  total_including_taxes: number;
}

export type TotalBilledAmountsOptions = Pick<
  DefaultGetOptions,
  'filters' | 'search'
>;

export class TotalBilledAmounts {
  constructor(
    public totalExcludingTaxes: number,
    public totalIncludingTaxes: number,
  ) {}

  static fromJson(contract: TotalBilledAmountsApiContract): TotalBilledAmounts {
    return new TotalBilledAmounts(
      contract.total_excluding_taxes,
      contract.total_including_taxes,
    );
  }
}
