export interface DocumentLockedByAccountantApiContract {
  id: number;
  locked_by_accountant: boolean;
}

export class DocumentLockedByAccountant {
  constructor(public id: number, public lockedByAccountant: boolean) {}

  static fromJson(
    json: DocumentLockedByAccountantApiContract,
  ): DocumentLockedByAccountant {
    return new DocumentLockedByAccountant(json.id, json.locked_by_accountant);
  }
  static toJson(
    entity: DocumentLockedByAccountant,
  ): DocumentLockedByAccountantApiContract {
    return { id: entity.id, locked_by_accountant: entity.lockedByAccountant };
  }
}
