import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map, shareReplay } from 'rxjs/operators';
import { TiimeSelectOption } from 'tiime-components';

import { ApiAlertError } from '@decorators/api-alert-error';
import { InvoiceOperationTypeEnum } from '@enums';
import { TIIME_ICON_PREFIX } from '@shared';

export type InvoiceOperationType = {
  value: InvoiceOperationTypeEnum;
  translation: string;
};

@Injectable({
  providedIn: 'root',
})
export class InvoiceOperationTypesService {
  private readonly resourceUri = 'api/v1/operation_types/registries';
  private readonly operationTypesCache$ = this.http
    .get<InvoiceOperationType[]>(this.resourceUri)
    .pipe(shareReplay({ refCount: true, bufferSize: 1 }));

  readonly getAllAsTiimeSelect$ = this.getAll().pipe(
    map((operationTypes: InvoiceOperationType[]) =>
      operationTypes.map<TiimeSelectOption<InvoiceOperationTypeEnum>>(
        (operationType: InvoiceOperationType) => ({
          value: operationType.value,
          label: operationType.translation,
          icon: this.operationTypeLogo(operationType.value),
        }),
      ),
    ),
  );

  private readonly operationTypeLogo = (
    operationType: InvoiceOperationTypeEnum,
  ): string => {
    switch (operationType) {
      case InvoiceOperationTypeEnum.cash:
        return `${TIIME_ICON_PREFIX}ot_cash`;
      case InvoiceOperationTypeEnum.check:
        return `${TIIME_ICON_PREFIX}ot_check`;
      case InvoiceOperationTypeEnum.card:
      case InvoiceOperationTypeEnum.dab:
        return `${TIIME_ICON_PREFIX}ot_card`;
      case InvoiceOperationTypeEnum.transfer:
        return `${TIIME_ICON_PREFIX}ot_transfer-out`;
      case InvoiceOperationTypeEnum.levy:
      default:
        return `${TIIME_ICON_PREFIX}ot_bank`;
    }
  };

  constructor(private readonly http: HttpClient) {}

  @ApiAlertError()
  getAll(): Observable<InvoiceOperationType[]> {
    return this.operationTypesCache$;
  }
}
