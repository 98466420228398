import { Directive } from '@angular/core';
import { Router } from '@angular/router';
import { TiimeAuthService } from '@manakincubber/tiime-auth';

@Directive()
// eslint-disable-next-line @angular-eslint/directive-class-suffix
export abstract class AuthBase {
  constructor(
    protected authService: TiimeAuthService,
    protected router: Router,
  ) {}

  navigateWithEmailAsQueryParam(url: string, email: string): void {
    const queryParams = email ? { email } : undefined;

    void this.router.navigate([url], { queryParams });
  }
}
