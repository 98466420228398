import {
  HttpClient,
  HttpParams,
  HttpProgressEvent,
  HttpResponse,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import {
  GetOptions,
  PaginationData,
  RequiredGetOptions,
} from 'tiime-components';

import { StrongCustomerAuthenticationService } from '@core/auth';
import { SepaPaymentForm } from '@core/forms';
import { HttpHelper } from '@core/helpers';
import {
  SepaPayment,
  SepaPaymentApiContract,
  ScaConfirmSepaPaymentAction,
  SepaPaymentId,
} from '@core/models';
import { TemporaryEncoder } from '@core/temporary-encoder';
import { ApiAlertError } from '@decorators/api-alert-error';

import { HttpFileService } from '../http-file.service';

@Injectable({
  providedIn: 'root',
})
export class SepaPaymentsService {
  readonly resource = `api/v1/companies/{companyId}/bank_accounts/sepa_payment_initiations`;

  constructor(
    private readonly http: HttpClient,
    private readonly httpFileService: HttpFileService,
    private readonly sca: StrongCustomerAuthenticationService,
  ) {}

  @ApiAlertError()
  getImportModel(): Observable<HttpResponse<Blob>> {
    const url = `api/v1/companies/bank_accounts/sepa_payment_initiations/model`;
    return this.httpFileService.download(url);
  }

  import(file: File): Observable<HttpProgressEvent | SepaPayment[]> {
    const url = `${this.resource}`;
    return this.httpFileService.upload(
      url,
      file,
      (list: SepaPaymentApiContract[]) =>
        Array.isArray(list) ? list.map(SepaPayment.fromJson) : null,
    );
  }

  @ApiAlertError()
  getAllAsForm(
    getOptions: RequiredGetOptions<'range'>,
  ): Observable<PaginationData<SepaPaymentForm>> {
    const partialOptions = new GetOptions(getOptions).toHttpGetOptions();
    const options = {
      params: new HttpParams({
        fromObject: partialOptions.params,
        encoder: new TemporaryEncoder(),
      }),
      headers: partialOptions.headers,
    };

    return this.http
      .get<SepaPaymentApiContract[]>(`${this.resource}`, {
        ...options,
        observe: 'response',
      })
      .pipe(
        HttpHelper.mapToPaginationData(
          getOptions.range,
          (sepaPaymentJson: SepaPaymentApiContract) =>
            new SepaPaymentForm(SepaPayment.fromJson(sepaPaymentJson)),
        ),
      );
  }

  @ApiAlertError()
  update(sepaPayment: SepaPayment): Observable<SepaPaymentForm> {
    const url = `${this.resource}/${sepaPayment.id}`;
    return this.http
      .patch<SepaPaymentApiContract>(url, SepaPayment.toJson(sepaPayment))
      .pipe(
        map(
          (sepaPaymentJson: SepaPaymentApiContract) =>
            new SepaPaymentForm(SepaPayment.fromJson(sepaPaymentJson)),
        ),
      );
  }

  @ApiAlertError()
  delete(sepaPaymentInitiationId: number): Observable<void> {
    const url = `${this.resource}/${sepaPaymentInitiationId}`;
    return this.http.delete<void>(url);
  }

  @ApiAlertError()
  confirmPayments(paymentsId: SepaPaymentId[]): Observable<SepaPayment> {
    return this.sca
      .authenticate(new ScaConfirmSepaPaymentAction(paymentsId.length), {
        hasBackdrop: false,
        displayValidationStep: false,
      })
      .pipe(
        switchMap(authenticationSCAHeader =>
          this.http.post(
            `${this.resource}/confirm`,
            {
              sepa_payment_initiations: paymentsId,
            },
            { headers: authenticationSCAHeader },
          ),
        ),
        map((sepaPayments: SepaPaymentApiContract) =>
          SepaPayment.fromJson(sepaPayments),
        ),
      );
  }
}
