import { QuoteStatus } from '@enums';
import { Client, Tag } from '@models';

import { ExportConfigurationBase } from '../../settings/export/export-core/bases/export-configuration.base';

export interface QuotesExportApiContract {
  status: QuoteStatus[] | null;
  options: {
    with_attachments: boolean;
    linked_to_invoice: boolean;
  };
  client_ids?: Client['id'][];
  tag_ids?: Tag['id'][];
}

export class QuotesExportConfiguration extends ExportConfigurationBase {
  constructor(
    public status: QuoteStatus[] | null,
    public attachDocuments: boolean,
    public clients: Client[] | null,
    public tags: Tag[] | null,
  ) {
    super();
  }

  static toJson(configuration: QuotesExportConfiguration): {
    quotations: QuotesExportApiContract;
  } {
    return {
      quotations: {
        ...(this.shouldFilterBy<QuoteStatus>(configuration.status) && {
          status: configuration.status,
        }),
        options: {
          with_attachments: configuration.attachDocuments,
          linked_to_invoice: false,
        },
        client_ids: configuration.clients?.map(({ id }) => id),
        tag_ids: configuration.tags?.map(({ id }) => id),
      },
    };
  }
}
