import {
  Component,
  ContentChild,
  ElementRef,
  Input,
  ViewEncapsulation,
} from '@angular/core';

import { HasAttributesBase } from '../core';
import { InputContainerControl } from './input-container-control';

@Component({
  selector: 'tiime-input-container',
  templateUrl: './input-container.component.html',
  styleUrls: ['input-container.scss'],
  encapsulation: ViewEncapsulation.None,
  // eslint-disable-next-line @angular-eslint/no-host-metadata-property
  host: { class: 'tiime-input-container' },
})
export class InputContainerComponent extends HasAttributesBase {
  @Input() label: string;
  @Input() errorMessage: string;
  @Input() suffix: string;
  @Input() suffixIcon: string;

  @ContentChild(InputContainerControl, { static: true })
  control!: InputContainerControl<unknown>;

  private readonly classList: string[] = ['small', 'italic'];

  constructor(elementRef: ElementRef<HTMLElement>) {
    super(elementRef);
    this.initClasses();
  }

  private initClasses(): void {
    const itemClasses = this.classList.filter((className: string) =>
      this._hasHostAttributes(className),
    );
    this.elementRef.nativeElement.classList.add(...itemClasses);
  }
}
