export interface PostQardConnectionApiContract {
  connection_id: string;
  webview_url: string;
}

export class QardBankConnection {
  constructor(public connection_id: string, public webview_url: string) {}

  public static toChronosConnectionJson(
    qardBankConnection: QardBankConnection,
  ): PostQardConnectionApiContract {
    return {
      connection_id: qardBankConnection.connection_id,
      webview_url: qardBankConnection.webview_url,
    };
  }
}
