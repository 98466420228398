export class RegexHelper {
  static readonly dateRegex =
    /^((\d{2}[/-]\d{2}[/-](\d{2}|\d{4}))|((\d{4})-(\d{2})-(\d{2})))$/; // match dd/mm/yy ou dd/mm/yyyy ou yyyy-mm-dd
  static readonly dateIntervalRegex =
    /^>=?\d{4}-\d{2}-\d{2},<=?\d{4}-\d{2}-\d{2}$/;
  static readonly floatRegex = /^(\d*[.,])?\d+$/;
  static readonly floatWithNegativeSignRegex = /^-?(\d*[.,])?\d+$/;
  static readonly amountIntervalRegex =
    /^([><]-?(\d*[.,])?\d+,[><]-?(\d*[.,])?\d+)$/;
  static readonly emailRegex =
    /^(([^<>()[\].,;:\s@"]+(.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/i;
}
