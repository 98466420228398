import { BankTransferRecurrency } from '@enums';

import { Document, DocumentApiContract, DocumentTypeEnum } from '../document';
import { BaseBeneficiaryApiContract } from './beneficiary';
import { BasePayoutBodyContract, Payout } from './payout';

export interface ScheduledPayoutApiContract {
  amount: number;
  beneficiary: BaseBeneficiaryApiContract;
  currency: string;
  end_date: Date | null;
  id: number;
  label: null;
  next_transaction_date: Date;
  recurrency: BankTransferRecurrency;
  start_date: Date;
  documents: DocumentApiContract[];
}

interface PatchScheduledPayoutBodyContract extends BasePayoutBodyContract {
  id: number;
  documents: { id: number; type: DocumentTypeEnum }[];
}

export class ScheduledPayout extends Payout {
  constructor(
    public id: number,
    amount: number,
    beneficiary: BaseBeneficiaryApiContract,
    startDate: Date,
    recurrency: BankTransferRecurrency,
    public nextTransactionDate: Date,
    public currency: string,
    public documents: Document[],
    endDate?: Date,
    label?: string,
  ) {
    super(amount, beneficiary, startDate, recurrency, endDate, label);
  }

  static fromJson(json: ScheduledPayoutApiContract): ScheduledPayout {
    return new ScheduledPayout(
      json.id,
      json.amount,
      json.beneficiary,
      json.start_date,
      json.recurrency,
      json.next_transaction_date,
      json.currency,
      json.documents
        ? json.documents.map(document => Document.fromJson(document))
        : [],
      json.end_date,
      json.label,
    );
  }

  toJson(): PatchScheduledPayoutBodyContract {
    return {
      id: this.id,
      amount: this.amount,
      start_date: this.formatDate(this.startDate),
      end_date: this.endDate ? this.formatDate(this.endDate) : null,
      recurrency: this.recurrency,
      label: this.label,
      documents: this.documents
        ? this.documents.map(document => ({
            id: document.id,
            type: document.type,
          }))
        : [],
    };
  }
}
