import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { ApiAlertError } from '@decorators/api-alert-error';
import { ExpensesReport, ExpensesReportApiContract } from '@models';

@Injectable({
  providedIn: 'root',
})
export class ExpensesReportService {
  private readonly resource = 'api/v1/companies/{companyId}/expense_reports';

  constructor(private readonly http: HttpClient) {}

  @ApiAlertError()
  get({ id }: Pick<ExpensesReport, 'id'>): Observable<ExpensesReport> {
    return this.http
      .get<ExpensesReportApiContract>(`${this.resource}/${id}`)
      .pipe(map(json => ExpensesReport.fromJson(json)));
  }

  @ApiAlertError()
  create(expensesReport: ExpensesReport): Observable<ExpensesReport> {
    return this.http
      .post<ExpensesReportApiContract>(this.resource, expensesReport.toJson())
      .pipe(map(json => ExpensesReport.fromJson(json)));
  }

  @ApiAlertError()
  update(expensesReport: ExpensesReport): Observable<ExpensesReport> {
    return this.http
      .patch<ExpensesReportApiContract>(
        `${this.resource}/${expensesReport.id}`,
        expensesReport.toJson(),
      )
      .pipe(map(json => ExpensesReport.fromJson(json)));
  }

  @ApiAlertError()
  updateCommentAndTags(
    expensesReport: ExpensesReport,
  ): Observable<ExpensesReport> {
    return this.http
      .patch<ExpensesReportApiContract>(
        `api/v1/companies/{companyId}/documents/${expensesReport.id}`,
        expensesReport.toJson(),
      )
      .pipe(map(json => ExpensesReport.fromJson(json)));
  }

  @ApiAlertError()
  getLastExpenseReportDate(
    userId: number,
    excludedExpenseReportId?: number,
  ): Observable<string> {
    const queryParams = excludedExpenseReportId
      ? { expense_report_id: excludedExpenseReportId }
      : undefined;

    return this.http
      .get<{ last_date: string }>(
        `api/v1/companies/{companyId}/users/${userId}/expense_reports/last_date`,
        {
          params: queryParams,
        },
      )
      .pipe(map(({ last_date }) => last_date));
  }
}
