export type UserIdPrefix = 'utilisateur';
export type ClientIdPrefix = 'client';
export type CompanyIdPrefix = 'societe';
export type InvoiceIdPrefix = 'facture';
export type AccountIdPrefix = 'account';
export type QuoteIdPrefix = 'devis';

type PrefixedId<TPrefix extends string> = `${TPrefix}-${number}`;

export const prefixUserId = (id: number): PrefixedId<UserIdPrefix> =>
  toAmplitudeId('utilisateur', id);

export const prefixClientId = (id: number): PrefixedId<ClientIdPrefix> =>
  toAmplitudeId('client', id);

export const prefixCompanyId = (id: number): PrefixedId<CompanyIdPrefix> =>
  toAmplitudeId('societe', id);

export const prefixInvoiceId = (id: number): PrefixedId<InvoiceIdPrefix> =>
  toAmplitudeId('facture', id);

export const prefixAccountId = (id: number): PrefixedId<AccountIdPrefix> =>
  toAmplitudeId('account', id);

export const prefixQuoteId = (id: number): PrefixedId<QuoteIdPrefix> =>
  toAmplitudeId('devis', id);

function toAmplitudeId<TPrefix extends string>(
  prefix: TPrefix,
  id: number,
): PrefixedId<TPrefix> {
  return `${prefix}-${id}`;
}
