import { Directive, inject } from '@angular/core';
import { Store } from '@ngrx/store';

import { UserPermissionNameEnum } from '@core/enum/users';
import { isMicroEntrepreneur } from '@core/store';

@Directive()
export abstract class NavigationMenuBase {
  protected readonly isMicroEntrepreneur$ =
    inject(Store).select(isMicroEntrepreneur);

  protected readonly UserPermissionNameEnum = UserPermissionNameEnum;
}
