import {
  MAX_INVOICING_LOGO_HEIGHT,
  MAX_INVOICING_LOGO_WIDTH,
  MIN_INVOICING_LOGO_HEIGHT,
  MIN_INVOICING_LOGO_WIDTH,
} from '@core/constants/invoice';
import { clamp } from '@core/utils';
import { InvoicingTemplate, QuoteStatus } from '@enums';
import {
  InvoicingDocumentSeller,
  LineApiContract,
  Tag,
  TagApiContract,
  TextLine,
  TextLineApiContract,
} from '@models';

import { AbstractInvoicingDocument } from '../bases/abstract-invoicing-document';
import { Client } from './client';
import { Country } from './country';
import { DeliveryAddress } from './delivery-address';
import { Discount } from './discount';
import { InvoiceListItem } from './invoice-list-item';
import { Line } from './line';
import { Logo } from './logo';
import { QuoteConfig } from './quote-config';
import { TotalsPerVatType } from './totals-per-vat-type';

export class Quote extends AbstractInvoicingDocument {
  constructor(
    id?: number,
    logo?: Logo,
    seller?: InvoicingDocumentSeller,
    title?: string,
    quoteNumber?: number,
    emissionDate?: string,
    client?: Client,
    totalExcludingTaxes?: number,
    totalIncludingTaxes?: number,
    lines?: Line[],
    textLines?: TextLine[],
    color?: string,
    header?: string,
    footer?: string,
    freeField?: string,
    template?: InvoicingTemplate,
    comment?: string,
    tags?: Tag[],
    unitsEnabled?: boolean,
    totalsPerVatType?: TotalsPerVatType,
    pdfFilename?: string,
    deliveryAddress?: DeliveryAddress,
    discount?: Discount,
    nonApplicableVatReason?: string,
    nonApplicableVatReasonEnabled?: boolean,
    public status?: QuoteStatus,
    public invoiceId?: number,
    public acceptanceConditions?: string,
    public validityPeriod?: string,
    public config?: QuoteConfig,
    public invoices?: InvoiceListItem[],
  ) {
    super(
      id,
      title,
      quoteNumber,
      emissionDate,
      totalExcludingTaxes,
      totalIncludingTaxes,
      lines,
      textLines,
      color,
      template,
      comment,
      tags,
      unitsEnabled,
      logo,
      seller,
      client,
      header,
      footer,
      freeField,
      totalsPerVatType,
      pdfFilename,
      deliveryAddress,
      discount,
      nonApplicableVatReason,
      nonApplicableVatReasonEnabled,
    );
  }

  public static fromJson(json: Record<string, any>): Quote {
    return new Quote(
      json.id,
      json.logo
        ? Logo.fromJson({
            ...json.logo,
            width: json.logo_width,
            height: json.logo_height,
          })
        : null,
      json.seller ? InvoicingDocumentSeller.fromJson(json.seller) : null,
      json.title,
      json.number,
      json.emission_date,
      Client.fromJson({
        id: json.client ? json.client.id : null,
        name: json.client_name,
        address: json.client_address,
        address_complement: json.client_address_complement,
        city: json.client_city,
        country: json.client_country,
        postal_code: json.client_postal_code,
        intracom_vat_number: json.client_intracom_vat_number,
        siren_or_siret: json.client_siren_or_siret,
      }),
      json.total_excluding_taxes,
      json.total_including_taxes,
      json.lines
        ? json.lines.map((lineJson: LineApiContract) => Line.fromJson(lineJson))
        : [],
      json.text_lines
        ? json.text_lines.map((textLineJson: TextLineApiContract) =>
            TextLine.fromJson(textLineJson),
          )
        : [],
      json.color,
      json.header,
      json.footer,
      json.free_field,
      json.template,
      json.comment,
      json.tags
        ? json.tags.map((tagJson: TagApiContract) => Tag.fromJson(tagJson))
        : [],
      json.units_enabled,
      json.totals_per_vat_type
        ? TotalsPerVatType.fromJson(json.totals_per_vat_type)
        : null,
      json.pdf_filename,
      new DeliveryAddress(
        json.client_delivery_address,
        json.client_delivery_postal_code,
        json.client_delivery_city,
        json.client_delivery_country
          ? Country.fromJson(json.client_delivery_country)
          : null,
      ),
      new Discount(
        json.discount_description,
        json.discount_percentage,
        json.discount_amount,
      ),
      json.non_applicable_vat_reason,
      json.non_applicable_vat_reason_enabled,
      json.status,
      json.invoice_id,
      json.acceptance_conditions,
      json.validity_period,
      new QuoteConfig(
        json.client_delivery_enabled,
        json.client_siren_or_siret_enabled,
        json.client_intracom_vat_number_enabled,
        json.free_field_enabled,
        json.country && Country.fromJson(json.country),
        json.discount_enabled,
        json.template,
        json.title_enabled,
        json.acceptance_conditions_enabled,
        json.signature_enabled,
      ),
      (json.invoices || []).map((invoice: unknown) =>
        InvoiceListItem.fromJson(invoice),
      ),
    );
  }

  public static toJson(quote: Quote): Record<string, unknown> {
    return {
      id: quote.id || undefined,
      logo: quote.logo?.id && {
        id: quote.logo.id,
      },
      logo_width:
        quote.logo &&
        clamp(
          Math.round(quote.logo.width),
          MIN_INVOICING_LOGO_WIDTH,
          MAX_INVOICING_LOGO_WIDTH,
        ),
      logo_height:
        quote.logo &&
        clamp(
          Math.round(quote.logo.height),
          MIN_INVOICING_LOGO_HEIGHT,
          MAX_INVOICING_LOGO_HEIGHT,
        ),
      status: quote.status,
      seller: quote.seller?.toJson(),
      title: quote.title,
      number: quote.invoicingDocumentNumber,
      emission_date: quote.emissionDate,
      client: quote.client.id ? { id: quote.client.id } : null,
      client_name: quote.client.name,
      client_address: quote.client.address,
      client_address_complement: quote.client.addressComplement,
      client_city: quote.client.city,
      client_country: quote.client.country.id ? quote.client.country : null,
      client_postal_code: quote.client.postalCode,
      client_intracom_vat_number: quote.client.intracomVatNumber,
      client_siren_or_siret: quote.client.sirenOrSiret
        ? quote.client.sirenOrSiret
        : null,
      total_excluding_taxes: quote.totalExcludingTaxes,
      total_including_taxes: quote.totalIncludingTaxes,
      lines: quote.lines.map((line: Line) => Line.toJson(line)),
      text_lines: quote.textLines?.map((textLine: TextLine) =>
        TextLine.toJson(textLine),
      ),
      color: quote.color,
      footer: quote.footer,
      header: quote.header,
      acceptance_conditions: quote.acceptanceConditions,
      acceptance_conditions_enabled: quote.config.acceptanceConditionsEnabled,
      free_field: quote.freeField,
      free_field_enabled: quote.config.freeFieldEnabled,
      non_applicable_vat_reason: quote.nonApplicableVatReason,
      non_applicable_vat_reason_enabled: quote.nonApplicableVatReasonEnabled,
      validity_period: quote.validityPeriod,
      template: quote.template,
      comment: quote.comment,
      tags: quote.tags?.map((tags: Tag) => Tag.toJson(tags)),
      totals_per_vat_type: TotalsPerVatType.toJson(quote.totalsPerVatType),
      client_delivery_enabled: quote.config.deliveryAddressEnabled,
      client_delivery_address: quote.deliveryAddress.address || null,
      client_delivery_postal_code: quote.deliveryAddress.postalCode || null,
      client_delivery_city: quote.deliveryAddress.city || null,
      client_delivery_country: quote.deliveryAddress.country.id
        ? quote.deliveryAddress.country
        : null,
      client_siren_or_siret_enabled: quote.config.sirenOrSiretEnabled,
      client_intracom_vat_number_enabled: quote.config.intracomVatNumberEnabled,
      country: { id: quote.config.country.id },
      discount_enabled: quote.config.discountEnabled,
      title_enabled: quote.config.titleEnabled,
      discount_description: quote.discount.description,
      discount_amount: quote.discount.amount,
      discount_percentage: quote.discount.percentage,
      signature_enabled: quote.config.signatureEnabled,
    };
  }
}
