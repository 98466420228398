export interface ThreadTransactionApiContract {
  id: number;
  transaction_date: string;
  wording: string;
  amount: number;
  currency: string;
  bank: {
    logo_url: string;
  };
}

export class ThreadTransaction {
  constructor(
    public id: number,
    public transactionDate: string,
    public wording: string,
    public amount: number,
    public currency: string,
    public bankLogo: string,
  ) {}

  static fromJson(json: ThreadTransactionApiContract): ThreadTransaction {
    return new ThreadTransaction(
      json.id,
      json.transaction_date,
      json.wording,
      json.amount,
      json.currency,
      json.bank?.logo_url,
    );
  }
}

export interface EntityApiContract {
  type: string;
  bank_transaction: ThreadTransactionApiContract;
}

export class Entity {
  constructor(public type: string, public bankTransaction: ThreadTransaction) {}

  static fromJson(json: EntityApiContract): Entity {
    return new Entity(
      json.type,
      ThreadTransaction.fromJson(json.bank_transaction),
    );
  }
}
