export const INVOICING_COLOR_LABEL: Record<string, string> = {
  '#FF5E62': 'rouge',
  '#FF7483': 'rose',
  '#FD81B5': 'rose',
  '#C961F7': 'violet',
  '#A475FB': 'violet',
  '#8089FF': 'bleu',
  '#5D9CF1': 'bleu',
  '#54CEFF': 'bleu',
  '#35D4D8': 'turquoise',
  '#19D9B4': 'vert',
  '#53DA85': 'vert',
  '#89D87B': 'vert',
  '#FFD366': 'jaune',
  '#FFBA3F': 'jaune',
  '#FF8765': 'orange',
  '#AB815C': 'marron',
  '#7D6447': 'marron',
  '#476388': 'gris',
  '#6C84A2': 'gris',
  '#88A0BF': 'gris',
};
