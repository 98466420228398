<div
  tiimeTooltip
  tooltipPosition="below-right"
  [content]="bankTooltip"
  [tooltipDisabled]="isTooltipDisabled"
>
  <div
    *tiimeLet="bankAccount?.bank | map: mapToBankLogoInfo as logoInfo"
    class="bank-logo"
  >
    <img
      *ngIf="logoInfo.logo"
      class="logo"
      draggbale="false"
      alt=""
      [src]="logoInfo.logo"
    />
    <div *ngIf="!logoInfo.logo && logoInfo.acronym" class="short-name">
      {{ logoInfo.acronym }}
    </div>
    <img
      *ngIf="!logoInfo.logo && !logoInfo.acronym"
      class="logo"
      src="assets/icon-bank-no-logo.svg"
      alt=""
      draggable="false"
    />
    <div *ngIf="bankAccount?.index > 0" class="count">
      {{ bankAccount.index }}
    </div>
  </div>
</div>
<ng-template #bankTooltip>
  {{ bankAccount | map: mapToBankTooltip }}
</ng-template>
