<mat-dialog-content class="dialog-content" mat-dialog-content>
  <img
    *ngIf="illu"
    class="illustration"
    alt=""
    draggable="false"
    data-cy="confirm-dialog__img-illustration"
    [src]="illu"
  />
  <div class="flex-content">
    <span mat-dialog-title data-cy="confirm-dialog__txt-title">
      <ng-container *ngFor="let titleLine of title; trackBy: trackByIndex">
        {{ titleLine }}
        <br />
      </ng-container>
    </span>
    <div class="description">
      <span class="message" data-cy="confirm-dialog__txt-description">
        <ng-container
          *ngFor="let descriptionLine of description; trackBy: trackByIndex"
        >
          {{ descriptionLine }}
          <br />
        </ng-container>
      </span>
    </div>
  </div>
</mat-dialog-content>
