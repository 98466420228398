export const INVOICING_NUMBER_TEMPLATES = [
  {
    value: 'YYYY-MM-DD-XXXXXX',
    label: 'AAAA-MM-JJ-XXXXXX',
  }, // YEAR_MONTH_DAY_PADDED_NUMBER
  {
    value: 'YYYY-MM-XXXXXX',
    label: 'AAAA-MM-XXXXXX',
  }, // YEAR_MONTH_PADDED_NUMBER
  { value: 'YYYY-XXXXXX', label: 'AAAA-XXXXXX' }, // YEAR_PADDED_NUMBER
  { value: 'XXXXXX', label: 'XXXXXX' }, // PADDED_NUMBER
  { value: 'X', label: 'X' }, // NUMBER
];
