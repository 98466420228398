import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { LineForm } from '@forms';
import { Line, Unit } from '@models';
import { InvoicingUnitsService } from '@services';

@Pipe({
  name: 'translatedInvoicingUnit',
})
export class TranslatedInvoicingUnitPipe implements PipeTransform {
  constructor(
    private readonly translate: TranslateService,
    private readonly invoicingUnitsService: InvoicingUnitsService,
  ) {}

  transform(obj: Line | LineForm): Observable<string | null> {
    const line = this.getLineData(obj);
    return this.getTranslatedUnit(line);
  }

  private getLineData(line: Line | LineForm): {
    invoicingUnitId: number;
    quantity: number;
  } {
    if (line instanceof Line) {
      return {
        invoicingUnitId: line.invoicingUnit?.id,
        quantity: line.quantity,
      };
    }
    if (line instanceof LineForm) {
      return {
        invoicingUnitId: line.invoicingUnit.controls.id.value,
        quantity: line.quantity.value,
      };
    }

    throw new Error(
      'TranslatedInvoicingUnitPipe supports Line or LineForm instance',
    );
  }

  private getTranslatedUnit({
    invoicingUnitId,
    quantity,
  }: {
    invoicingUnitId: number;
    quantity: number;
  }): Observable<string | null> {
    return this.invoicingUnitsService.getUnits({ fromCache: true }).pipe(
      map(units => {
        const language = (
          this.translate.currentLang || this.translate.defaultLang
        ).toLocaleUpperCase();

        if (!units.length || !invoicingUnitId) {
          // unitId can be `undefined` if some lines have a unit and other don't
          return null;
        }
        const { singular, plurals } = units
          .find(({ id }: Unit) => id === invoicingUnitId)
          .translations.find(({ lang }) => lang === language);

        return quantity > 1 ? plurals : singular;
      }),
    );
  }
}
