import { CurrencyPipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';

import {
  Document,
  Invoice,
  InvoiceListItem,
  LinkedBankTransaction,
} from '@models';
import {
  BankTransaction,
  BankTransactionImputation,
} from '@models/bank-transaction';
import { LinkedEntity, LinkedEntityImputation } from '@models/linked-entities';

import { DocumentAmountPipe } from '../../../../../../../document/document-shared/pipes/document-amount.pipe';
import { DocumentMetadataCurrencyPipe } from '../../../../../../../document/document-shared/pipes/document-metadata-currency.pipe';
import { MatchedItem } from '../../../../types/matched-item';

@Pipe({
  name: 'matchedItemAmount',
  standalone: true,
})
export class MatchedItemAmountPipe implements PipeTransform {
  transform(item: MatchedItem): string {
    const currencyPipe = new CurrencyPipe('fr-fr');
    if (
      item instanceof BankTransaction ||
      item instanceof BankTransactionImputation ||
      item instanceof LinkedBankTransaction
    ) {
      return currencyPipe.transform(
        item.amount,
        item instanceof BankTransactionImputation || !item.currency
          ? 'EUR'
          : item.currency /* TODO: bank transaction linked to invoices do not have a currency and use the BankTransaction type,
          which is not correct. They should use the LinkedBankTransaction type (will not fix the currency "issue") */,
      );
    } else if (item instanceof LinkedEntityImputation) {
      return currencyPipe.transform(item.value.amount, item.value.currency);
    } else if (item instanceof LinkedEntity) {
      return currencyPipe.transform(
        item.value.amount.value,
        item.value.amount.currency,
      );
    } else if (item instanceof Document) {
      const documentAmountPipe = new DocumentAmountPipe();
      const documentMetadataCurrencyPipe = new DocumentMetadataCurrencyPipe();
      return currencyPipe.transform(
        documentAmountPipe.transform(item.metadata),
        documentMetadataCurrencyPipe.transform(item.metadata, 'amount'),
      );
    } else if (item instanceof Invoice || item instanceof InvoiceListItem) {
      return currencyPipe.transform(item.totalIncludingTaxes, 'EUR');
    } else {
      throw new Error('Unhandled matched item type.');
    }
  }
}
