import { BooleanInput, coerceBooleanProperty } from '@angular/cdk/coercion';
import {
  AfterViewInit,
  ChangeDetectorRef,
  Directive,
  ElementRef,
  Input,
} from '@angular/core';

@Directive({ selector: '[tiimeAutoFocus]' })
export class AutoFocusDirective implements AfterViewInit {
  @Input('tiimeAutoFocus')
  set setAutoFocus(value: BooleanInput) {
    this.autoFocus = coerceBooleanProperty(value);
  }
  private autoFocus = true;

  constructor(
    private readonly changeDetectorRef: ChangeDetectorRef,
    private readonly elementRef: ElementRef<HTMLElement>,
  ) {}

  ngAfterViewInit(): void {
    if (!this.autoFocus) {
      return;
    }

    const element = this.elementRef.nativeElement;
    if (!(element instanceof HTMLElement)) {
      return;
    }

    setTimeout(() => {
      element.focus();
      this.changeDetectorRef.markForCheck();
    });
  }
}
