import { ApeCode, ApeCodeContract } from '../ape-code';

export interface WalletOnboardingCompanyApiContract {
  last_net_income_range: string;
  ape_code: ApeCodeContract;
}

export class WalletOnboardingCompany {
  constructor(public lastNetIncomeRange?: string, public apeCode?: ApeCode) {}

  public static fromJson(
    json: WalletOnboardingCompanyApiContract,
  ): WalletOnboardingCompany {
    return new WalletOnboardingCompany(
      json.last_net_income_range,
      json.ape_code ? ApeCode.fromJson(json.ape_code) : undefined,
    );
  }
}
