<ng-container *tiimeLet="editorStatus$ | async as editorStatus">
  <app-document-editor-scroll-effect></app-document-editor-scroll-effect>
  <form class="editor-container" [formGroup]="form">
    <div class="wrapper">
      <app-document-editor [loading]="loading$ | async">
        <ng-container document-editor-header>
          <button
            *ngIf="editorStatus === ExpensesReportEditorStatus.Preview"
            class="download-btn"
            tiime-button
            neutral
            inverted
            (click)="download()"
          >
            <mat-icon svgIcon="icon-download-deprecated"></mat-icon>
            Télécharger le PDF
          </button>

          <app-expenses-report-editor-information-card
            *ngIf="
              (isInformationCardClosed$ | async) === false &&
              editorStatus !== ExpensesReportEditorStatus.Preview
            "
            [expenseType]="form.expenseType.value"
            (cardClose)="isInformationCardClosed$.next(true)"
          ></app-expenses-report-editor-information-card>

          <app-document-editor-form-field>
            <input
              class="name-input"
              type="text"
              appDocumentEditorInput
              size="lg"
              formControlName="name"
            />
          </app-document-editor-form-field>

          <div class="expense-report-info">
            <div class="employee">
              <app-document-editor-form-field>
                <label>Propriétaire</label>
                <tiime-select
                  formControlName="owner"
                  [selected]="form.owner.value"
                  [options]="usersSelectOptions$ | async"
                  [compareWith]="compareOptions"
                ></tiime-select>
              </app-document-editor-form-field>
            </div>

            <div
              *ngIf="editorStatus !== ExpensesReportEditorStatus.Preview"
              class="expense-type"
            >
              <app-document-editor-form-field
                [defaultDisabledStyle]="
                  editorStatus === ExpensesReportEditorStatus.Edit
                "
              >
                <label>Type de frais</label>
                <tiime-select
                  formControlName="expenseType"
                  [selected]="form.expenseType.value"
                  [options]="expenseReportTypeSelectOptions"
                ></tiime-select>
              </app-document-editor-form-field>
            </div>

            <div class="date">
              Fait le :
              <div
                *ngIf="
                  (canEditDate$ | async) === true;
                  then datepicker;
                  else dateReadOnly
                "
              ></div>
              <ng-template #datepicker>
                <app-datepicker
                  [formControl]="form.date"
                  [min]="minDate$ | async"
                ></app-datepicker>
              </ng-template>
              <ng-template #dateReadOnly>
                {{ form.date.value | tiimeDatetime : 'dd/MM/yyyy' }}
              </ng-template>
            </div>
          </div>

          <span>{{ form.owner.value?.email }}</span>
        </ng-container>

        <ng-container *ngIf="(loading$ | async) === false" document-editor-body>
          <app-expense-report-editor-advanced-expenses
            *ngIf="form.expenseType.value === ExpenseReportType.AdvancedExpense"
            [metadata]="expenseReport?.metadata"
            [expenseReportForm]="form"
            [currentEditorStatus]="editorStatus"
            [owner]="form.owner.value"
            [advancedExpenses]="expenseReport?.advancedExpenses || []"
            [disableLines]="
              form.disabled ||
              editorStatus === ExpensesReportEditorStatus.Preview
            "
            (reloadExpenseReport)="reloadExpenseReport()"
          ></app-expense-report-editor-advanced-expenses>

          <app-expense-report-editor-travels
            *ngIf="form.expenseType.value === ExpenseReportType.Travel"
            [metadata]="expenseReport?.metadata"
            [expenseReportForm]="form"
            [currentEditorStatus]="editorStatus"
            [owner]="form.owner.value"
            [expenseReportDate]="form.date.value"
            [disableLines]="
              form.disabled ||
              editorStatus === ExpensesReportEditorStatus.Preview
            "
          ></app-expense-report-editor-travels>
        </ng-container>

        <ng-container document-editor-placeholder>
          <app-expenses-report-editor-skeleton
            [class.creation-skeleton]="
              editorStatus === ExpensesReportEditorStatus.Create
            "
          ></app-expenses-report-editor-skeleton>
        </ng-container>
      </app-document-editor>

      <section class="actions">
        <div
          class="wrapper"
          [class.has-match]="document?.linkedEntities?.length > 0"
        >
          <div
            *ngIf="
              expenseReport?.label &&
              editorStatus === ExpensesReportEditorStatus.Preview
            "
            class="acronym-card"
          >
            <app-acronym-illustration
              scale="0.6"
              [acronym]="expenseReport.label.acronym"
              [color]="expenseReport.label.color"
            ></app-acronym-illustration>

            <span
              class="extra-bold name"
              tiimeTooltip
              tooltipPosition="bottom"
              hideWithoutEllipsis
              [content]="nameTooltip"
            >
              <ng-container *ngTemplateOutlet="nameTooltip"></ng-container>
            </span>
            <ng-template #nameTooltip>
              {{ expenseReport.label.name }}
            </ng-template>
          </div>
          <app-linked-entities
            *ngIf="document?.linkedEntities?.length > 0"
            [linkedEntities]="document.linkedEntities"
            (unmatchLinkedEntity)="handleUnmatchExpenseReport($event)"
            (editLink)="openMatchingDialog()"
          ></app-linked-entities>
        </div>

        <ng-container *hasAccess="UserPermissionNameEnum.ACCOUNTS_USER_FULL">
          <app-expenses-report-editor-actions
            *tiimeLet="expensesReportId$ | async as expensesReportId"
            [paymentStatus]="expenseReport?.paymentStatus"
            [editorStatus]="editorStatus"
            [hasCheckedLines]="hasCheckedLines$ | async"
            [document]="document"
            (save)="save()"
            (cancel)="cancel()"
            (edit)="editorStatus$.next(ExpensesReportEditorStatus.Edit)"
            (delete)="delete()"
            (openBankTransferOverlay)="
              openBankTransferOverlay(expensesReportId)
            "
            (matchExpenseReport)="openMatchingDialog()"
          ></app-expenses-report-editor-actions>

          <ng-container
            *ngIf="editorStatus === ExpensesReportEditorStatus.Preview"
          >
            <div class="invoicing-document-info comment">
              <label for="comment">Commentaire</label>
              <textarea
                id="comment"
                formControlName="comment"
                placeholder="Ajouter un commentaire"
                rows="2"
                cdkTextareaAutosize
              ></textarea>
            </div>

            <div class="invoicing-document-info tags">
              <label>Tags</label>
              <app-tag-selector-form-input
                formControlName="tags"
                placeholder="Ajouter un tag"
                [tagSuggestions]="tagSuggestions$ | async"
                (valueChange)="onTagsUpdate($event)"
                (click)="onTagsInputClick()"
              ></app-tag-selector-form-input>
            </div>
          </ng-container>
        </ng-container>
      </section>
    </div>
  </form>
</ng-container>
