import { Pipe, PipeTransform } from '@angular/core';

import { Document, InvoiceListItem, LinkedBankTransaction } from '@models';
import {
  BankTransaction,
  BankTransactionImputation,
} from '@models/bank-transaction';
import { LinkedEntity } from '@models/linked-entities';

import { MatchedItem } from '../../../../types/matched-item';

@Pipe({
  name: 'matchedItemHasPreview',
  standalone: true,
})
export class MatchedItemHasPreviewPipe implements PipeTransform {
  transform(item: MatchedItem): boolean {
    if (
      item instanceof BankTransaction ||
      item instanceof LinkedEntity ||
      item instanceof BankTransactionImputation ||
      item instanceof LinkedBankTransaction
    ) {
      return false;
    } else if (item instanceof InvoiceListItem || item instanceof Document) {
      return true;
    } else {
      throw new Error('Unhandled matched item type.');
    }
  }
}
