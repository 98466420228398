<div
  *tiimeLet="selectedChild$ | async as selectedChild"
  class="document-proof-choice"
  attr.data-cy="document-proof-choice--{{ actionType }}"
  [class.document-proof-choice--selected]="selectedChild"
>
  <label data-cy="document-proof-choice__label">
    {{ label }}
  </label>
  <div
    class="document-proof-choice__container"
    data-cy="document-proof-choice__container"
    #selectorContainer
  >
    <button #openMenuButton (click)="toggleMenu()">
      <ng-container *ngIf="!selectedChild; else selectionChildrenTemplate">
        <div
          class="document-proof-choice__icon-container"
          data-cy="document-proof-choice__placeholder"
        >
          <img
            height="24"
            width="24"
            alt=""
            draggable="false"
            [src]="actionType | map : mapToIconPath"
          />
        </div>
        <div class="document-proof-choice__content">
          <span class="document-proof-choice__label">
            {{ label }}
          </span>
        </div>
      </ng-container>

      <mat-icon
        svgIcon="icon-caret-down"
        [class.rotate]="isMenuOpened"
      ></mat-icon>
    </button>
  </div>

  <ng-template #selectionChildrenTemplate>
    <div
      class="document-proof-choice__icon-container"
      data-cy="document-proof-choice__placeholder"
    >
      <img
        height="24"
        width="24"
        alt=""
        draggable="false"
        [src]="selectedChild.action_type ?? actionType | map : mapToIconPath"
      />
    </div>
    <div class="document-proof-choice__content">
      <span
        class="document-proof-choice__label"
        data-cy="document-proof-choice__selected-label"
      >
        {{ selectedChild.label }}
      </span>
    </div>
  </ng-template>
</div>

<ng-container *ngIf="nextChoice$ | async as recursiveNextChoice">
  <app-bank-transfer-document-proof-choice
    [label]="recursiveNextChoice.label"
    [children]="recursiveNextChoice.children"
    [actionType]="recursiveNextChoice.action_type ?? actionType"
    (documentsNeeded)="documentsNeeded.emit($event)"
    (selectedChoice)="selectedChoice.emit($event)"
  ></app-bank-transfer-document-proof-choice>
</ng-container>
