export interface BeneficiaryApiContract {
  id: number;
  acronym: string;
  color: string;
  internal: false;
  description: string | null;
  name: string;
  email: string | null;
  iban: string;
  bic: string | null;
  notify: boolean;
  rib: {
    id: number;
    file_hash: string;
    mime_type: string;
    comment: string | null;
  } | null;
  bank: {
    brand: string;
    id: number;
    logo_url: string | null;
  } | null;
}

export class Beneficiary {
  constructor(
    public id: number,
    public name: string,
    public acronym: string,
    public color: string,
    public internal: boolean,
    public iban: string,
    public bic: string | null,
    public description?: string,
    public rib?: BeneficiaryRib,
    public bank?: BeneficiaryBank,
    public scaId?: number,
    public notify?: boolean,
    public email?: string,
  ) {}

  static fromJson(json: BeneficiaryApiContract): Beneficiary {
    return new Beneficiary(
      json.id,
      json.name,
      json.acronym,
      json.color,
      json.internal,
      json.iban,
      json.bic,
      json.description,
      json.rib ? BeneficiaryRib.fromJson(json.rib) : null,
      json.bank ? BeneficiaryBank.fromJson(json.bank) : null,
      undefined,
      json.notify,
      json.email,
    );
  }

  static fromBeneficiary(beneficiary: Beneficiary): Beneficiary {
    return new Beneficiary(
      beneficiary.id,
      beneficiary.name,
      beneficiary.acronym,
      beneficiary.color,
      beneficiary.internal,
      beneficiary.iban,
      beneficiary.bic,
      beneficiary.description,
      beneficiary.rib
        ? new BeneficiaryRib(
            beneficiary.rib.id,
            beneficiary.rib.fileHash,
            beneficiary.rib.mimeType,
            beneficiary.rib.comment,
          )
        : null,
      beneficiary.bank
        ? new BeneficiaryBank(
            beneficiary.bank.id,
            beneficiary.bank.brand,
            beneficiary.bank.logoUrl,
          )
        : null,
      beneficiary.scaId,
      beneficiary.notify,
      beneficiary.email,
    );
  }
}

export class BeneficiaryRib {
  constructor(
    public id: number,
    public fileHash?: string,
    public mimeType?: string,
    public comment?: string,
  ) {}

  static fromJson(json: BeneficiaryApiContract['rib']): BeneficiaryRib {
    return new BeneficiaryRib(
      json.id,
      json.file_hash,
      json.mime_type,
      json.comment,
    );
  }
}

export class BeneficiaryBank {
  constructor(
    public id: number,
    public brand: string,
    public logoUrl?: string,
  ) {}

  static fromJson(json: BeneficiaryApiContract['bank']): BeneficiaryBank {
    return new BeneficiaryBank(json.id, json.brand, json.logo_url);
  }
}

export interface BaseBeneficiaryApiContract {
  id: number;
  name: string;
  iban: string;
  bic: string;
}

export interface CreateBeneficiaryBody
  extends Omit<BaseBeneficiaryApiContract, 'id'> {
  internal?: boolean;
}
