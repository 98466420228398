import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnChanges,
} from '@angular/core';
import { Observable } from 'rxjs';

import {
  UpdatedCheckmarkService,
  UpdatedResource,
  UpdatedResourceType,
} from './updated-checkmark.service';

@Component({
  selector: 'app-updated-checkmark',
  standalone: true,
  templateUrl: './updated-checkmark.component.html',
  styleUrls: ['./updated-checkmark.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [CommonModule],
})
export class UpdatedCheckmarkComponent implements OnChanges {
  @Input() resourceType: UpdatedResourceType;
  @Input() resourceId: number;

  obs$: Observable<UpdatedResource>;

  constructor(
    private readonly updatedCheckmarkService: UpdatedCheckmarkService,
  ) {}

  ngOnChanges(): void {
    this.obs$ = undefined;
    if (this.resourceType != null && this.resourceId != null) {
      this.obs$ = this.updatedCheckmarkService.watchUpdatedResource(
        this.resourceType,
        this.resourceId,
      );
    }
  }
}
