import { AbstractControl, ValidatorFn } from '@angular/forms';
import { Mapper } from '@manakincubber/tiime-utils';

/*
 * All of this file could eventually be move to tiime-utils library, which implies that
 * every app should use ngx-mask library instead of angular2-text-mask.
 *
 * Since the upgrade to ng14, the plugin to handle input masks has been changed.
 * Now, the spaces for SIRET/SIREN are only displayed on the input. (e.g. 442 293 775 00031)
 * The actual value never have spaces in it (e.g. 44229377500031)
 */

/** SIRET without spaces */
export const SIRET_REGEX = /^(\d{3}){3}(\d{5})$/;

/** SIRET or SIREN without spaces */
export const SIRET_OR_SIREN_REGEX = /^(\d{3}){3}(\d{5})?$/;

export function sirenOrSiretValidator(): ValidatorFn {
  return (control: AbstractControl): ReturnType<ValidatorFn> => {
    const controlValue = control.value as string;

    if (
      !controlValue ||
      (controlValue.length !== 9 && controlValue.length !== 14)
    ) {
      return null;
    }

    const controlSum = [...controlValue]
      .reverse()
      .reduce(
        (accumulator: number, currentValue: string, currentIndex: number) => {
          const position = currentIndex + 1;
          if (position % 2 === 0) {
            const provisionalValue = +currentValue * 2;
            return (
              accumulator +
              (provisionalValue > 9 ? provisionalValue - 9 : provisionalValue)
            );
          } else {
            return accumulator + +currentValue;
          }
        },
        0,
      );

    return controlSum % 10 === 0
      ? null
      : {
          validSirenOrSiret: {
            valid: false,
          },
        };
  };
}

export const mapToFormattedSiret: Mapper<string, string> = (siret: string) =>
  siret?.replace(/^(\d{3})(\d{3})(\d{3})(\d{5})$/g, '$1 $2 $3 $4');
