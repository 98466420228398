import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import { TiimeButtonModule, TiimeDialogModule } from 'tiime-components';

@Component({
  standalone: true,
  imports: [CommonModule, TiimeDialogModule, TiimeButtonModule],
  selector: 'app-confirmation-dialog-actions',
  templateUrl: './confirmation-dialog-actions.component.html',
  styleUrls: ['./confirmation-dialog-actions.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ConfirmationDialogActionsComponent {
  @Input() theme: 'accent' | 'warn' | 'accent-with-warn-cancel';
  @Input() confirmLabel: string;
  @Input() cancelLabel: string;
  @Input() actionsButtonsWidth: number;
  @Input() confirmButtonDisabled: boolean | undefined;

  @Output() readonly decline = new EventEmitter<void>();
  @Output() readonly confirm = new EventEmitter<void>();
}
