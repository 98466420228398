import {
  HttpClient,
  HttpHeaders,
  HttpParams,
  HttpStatusCode,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import {
  DefaultGetOptions,
  GetOptions,
  PaginationData,
  PaginationRange,
  RequiredGetOptions,
} from 'tiime-components';

import { timelineV2InteropHeader } from '@constants';
import { TemporaryEncoder } from '@core/temporary-encoder';
import { ApiAlertError } from '@decorators/api-alert-error';
import { LabelSuggestionContext } from '@enums';
import { HttpHelper } from '@helpers';
import { LabelTimeline, LabelRule, LabelRuleApiContract } from '@models';
import { Label, LabelApiContract } from '@models/labels';

@Injectable({
  providedIn: 'root',
})
export class LabelsService {
  readonly resource = `api/v1/companies/{companyId}/labels`;

  constructor(protected readonly http: HttpClient) {}

  @ApiAlertError()
  getUnpaginatedLabels(
    getOptions: DefaultGetOptions = {},
  ): Observable<Label[]> {
    const partialOptions = new GetOptions(getOptions).toHttpGetOptions();
    const options = {
      params: new HttpParams({
        fromObject: partialOptions.params,
        encoder: new TemporaryEncoder(),
      }),
      headers: new HttpHeaders('Accept: application/vnd.tiime.labels.v2+json'),
    };
    return this.http
      .get(this.resource, options)
      .pipe(
        map((labelJson: LabelApiContract[]) =>
          labelJson.map(label => Label.fromJson(label)),
        ),
      );
  }

  @ApiAlertError()
  searchLabels(
    searchTerms: string,
    range: PaginationRange,
    expands: string[] = [],
    isClient?: boolean,
  ): Observable<PaginationData<Label>> {
    let params = new HttpParams({
      fromObject: {
        ...(expands && { expand: expands.join(',') }),
        ...(isClient && { client: isClient }),
      },
      encoder: new TemporaryEncoder(),
    });
    params = HttpHelper.setSearchParam(params, searchTerms.toLocaleLowerCase());
    const headers = HttpHelper.setRangeHeader(
      new HttpHeaders('Accept: application/vnd.tiime.labels.v2+json'),
      range,
    );
    const options = { params, headers };
    return this.http
      .get(this.resource, { ...options, observe: 'response' })
      .pipe(
        HttpHelper.mapToPaginationData(range, (labelJson: LabelApiContract) =>
          Label.fromJson(labelJson),
        ),
      );
  }

  @ApiAlertError()
  getLabels(
    getOptions: RequiredGetOptions<'range'>,
  ): Observable<PaginationData<Label>> {
    const params = new HttpParams({
      fromObject: new GetOptions(getOptions).toHttpGetOptions().params,
      encoder: new TemporaryEncoder(),
    });

    const headers = HttpHelper.setRangeHeader(
      new HttpHeaders('Accept: application/vnd.tiime.labels.v2+json'),
      getOptions.range,
    );

    const options = { params, headers };
    return this.http
      .get(this.resource, { ...options, observe: 'response' })
      .pipe(
        HttpHelper.mapToPaginationData(
          getOptions.range,
          (labelsJson: LabelApiContract) => Label.fromJson(labelsJson),
        ),
      );
  }

  @ApiAlertError([
    HttpStatusCode.BadRequest,
    HttpStatusCode.Forbidden,
    HttpStatusCode.NotFound,
  ])
  updateLabel(label: Partial<Label>): Observable<Label> {
    return this.http
      .patch(`${this.resource}/${label.id}`, label)
      .pipe(map((labelsJson: LabelApiContract) => Label.fromJson(labelsJson)));
  }

  @ApiAlertError()
  createLabel(label: Label | { client_name: string }): Observable<Label> {
    const url =
      label instanceof Label ? this.resource : `${this.resource}/client`;
    const options = {
      headers: new HttpHeaders('Accept: application/vnd.tiime.labels.v2+json'),
    };
    return this.http
      .post(url, label, options)
      .pipe(map((labelJson: LabelApiContract) => Label.fromJson(labelJson)));
  }

  @ApiAlertError()
  getLastUsedTransactionLabels(transactionId: number): Observable<Label[]> {
    const headers = {
      Accept:
        'application/vnd.tiime.bank_transactions.label_suggestions.v2+json',
    };
    return this.http
      .get(
        `api/v1/companies/{companyId}/bank_transactions/${transactionId}/label_suggestions`,
        { headers },
      )
      .pipe(
        map((labelJson: LabelApiContract[]) =>
          labelJson.map(label => Label.fromJson(label)),
        ),
      );
  }

  @ApiAlertError()
  getLastUsedDocumentLabels(documentId: number): Observable<Label[]> {
    return this.http
      .get(
        `api/v1/companies/{companyId}/documents/${documentId}/label_suggestions`,
      )
      .pipe(
        map((labelJson: LabelApiContract[]) =>
          labelJson.map(label => Label.fromJson(label)),
        ),
      );
  }

  /** Returns the labels that the user has used recently */
  @ApiAlertError()
  getRecentlyUsedLabels(
    numberOfDataToDisplay = 3,
    labelSuggestionsContext = LabelSuggestionContext.BankTransaction,
  ): Observable<Label[]> {
    return this.http
      .get<LabelApiContract[]>(
        `api/v1/companies/{companyId}/label_suggestions?for=${labelSuggestionsContext}`,
      )
      .pipe(
        map(labels =>
          labels
            .filter((_, index) => index < numberOfDataToDisplay)
            .map(label => Label.fromJson(label)),
        ),
      );
  }

  getStandardLabels(): Observable<Label[]> {
    return this.http
      .get('api/v1/companies/{companyId}/standard_labels')
      .pipe(
        map((labelJson: LabelApiContract[]) =>
          labelJson.map(label => Label.fromJson(label)),
        ),
      );
  }

  @ApiAlertError()
  searchRules(
    range: PaginationRange,
    searchTerms = '',
  ): Observable<PaginationData<LabelRule>> {
    const url = `api/v1/companies/{companyId}/label_rules`;

    let params = new HttpParams({
      encoder: new TemporaryEncoder(),
    });

    params = HttpHelper.setSearchParam(
      params,
      searchTerms?.toLocaleLowerCase(),
    );
    const headers = HttpHelper.setRangeHeader(new HttpHeaders(), range);
    const options = { params, headers };

    return this.http
      .get(url, { ...options, observe: 'response' })
      .pipe(
        HttpHelper.mapToPaginationData(
          range,
          (ruleJson: LabelRuleApiContract) => LabelRule.fromJson(ruleJson),
        ),
      );
  }

  @ApiAlertError()
  deleteRule(rule: LabelRule): Observable<void> {
    const url = `api/v1/companies/{companyId}/label_rules/${rule.id}`;
    return this.http.delete<void>(url);
  }

  createLabelRule(rule: LabelRule): Observable<LabelRule> {
    const url = 'api/v1/companies/{companyId}/label_rules';
    return this.http
      .post(url, {
        label: rule.label,
        wording: rule.wording,
      })
      .pipe(map((json: LabelRuleApiContract) => LabelRule.fromJson(json)));
  }

  @ApiAlertError()
  getTimeline(
    labelId: number,
    getOptions: RequiredGetOptions<'range'>,
  ): Observable<LabelTimeline> {
    const partialOptions = new GetOptions(getOptions).toHttpGetOptions();
    const options = {
      params: new HttpParams({
        fromObject: partialOptions.params,
        encoder: new TemporaryEncoder(),
      }),
      headers: {
        ...partialOptions.headers,
        ...timelineV2InteropHeader,
      },
    };

    return this.http
      .get(`api/v1/companies/{companyId}/labels/${labelId}/timeline`, {
        ...options,
        observe: 'response',
      })
      .pipe(LabelTimeline.mapToLabelTimeline(getOptions.range));
  }
}
