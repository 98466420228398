import { FormControl } from '@angular/forms';
import { FormUtils } from '@manakincubber/tiime-utils';
import moment from 'moment';

import { AbstractInvoicingDocumentForm } from '@bases';
import { InvoicingTemplate } from '@enums';
import { MomentHelper } from '@helpers';
import { Invoice, InvoicingConfig, PaymentLink } from '@models';

export class InvoiceForm extends AbstractInvoicingDocumentForm<Invoice> {
  get compiledNumber(): FormControl {
    return this.get('compiledNumber') as FormControl;
  }

  get iban(): FormControl {
    return this.get('iban') as FormControl;
  }

  get bic(): FormControl {
    return this.get('bic') as FormControl;
  }

  get paymentCondition(): FormControl {
    return this.get('paymentCondition') as FormControl;
  }

  get dueDate(): FormControl {
    return this.get('dueDate') as FormControl;
  }

  get dueDateMode(): FormControl {
    return this.get('dueDateMode') as FormControl;
  }

  get invoiceStatusUpdatedBy(): FormControl {
    return this.get('statusUpdatedBy') as FormControl;
  }

  get type(): FormControl {
    return this.get('type') as FormControl;
  }

  get invoiceSchedule(): FormControl {
    return this.get('invoiceSchedule') as FormControl;
  }

  get advancePaymentInvoicesIds(): FormControl {
    return this.get('advancePaymentInvoicesIds') as FormControl;
  }

  get paymentLink(): FormControl<PaymentLink> {
    return this.get('paymentLink') as FormControl;
  }

  constructor() {
    super({
      statusUpdatedBy: new FormControl(null),
      compiledNumber: new FormControl(null),
      iban: new FormControl(null),
      bic: new FormControl(null),
      paymentCondition: new FormControl(null),
      type: new FormControl(null),
      dueDate: new FormControl(null),
      dueDateMode: new FormControl(null),
      creditNoteLinkedCompiledNumber: new FormControl(null),
      invoiceLinkedCompiledNumber: new FormControl(null),
      invoiceSchedule: new FormControl(null),
      advancePaymentInvoicesIds: new FormControl(null),
      paymentLink: new FormControl(null),
    });
  }

  fromInvoicingConfig(invoicingConfig: InvoicingConfig): void {
    this.patchValue(
      {
        header: invoicingConfig.header,
        footer: invoicingConfig.footer,
        logo: {
          id: invoicingConfig.logoId,
          width: invoicingConfig.logoWidth,
          height: invoicingConfig.logoHeight,
        },
        color: invoicingConfig.color,
        template: invoicingConfig.template,
        freeField: invoicingConfig.invoiceFreeField,
        iban: invoicingConfig.bankAccount
          ? invoicingConfig.bankAccount.iban
          : null,
        bic: invoicingConfig.bankAccount
          ? invoicingConfig.bankAccount.bic
          : null,
        dueDateMode: invoicingConfig.dueDateMode,
        config: {
          freeFieldEnabled: invoicingConfig.invoiceFreeFieldEnabled,
          bankDetailEnabled: invoicingConfig.invoiceBankDetailEnabled,
          paymentConditionEnabled:
            invoicingConfig.invoicePaymentConditionEnabled,
          country: invoicingConfig.country,
          template: invoicingConfig.template,
          titleEnabled: invoicingConfig.template !== InvoicingTemplate.standard,
        },
      },
      FormUtils.shouldNotEmitEvent,
    );
  }

  toInvoice(): Invoice {
    const invoice = this.toInvoicingDocument();
    if (invoice.dueDate) {
      invoice.dueDate = MomentHelper.toCalendarDateString(
        moment(invoice.dueDate),
      );
    }
    return invoice;
  }

  setDraftStatusValidators(): void {
    this.updateValueAndValidity(FormUtils.shouldNotEmitEvent);
  }
}
