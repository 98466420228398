<div class="table-wrapper">
  <div
    class="table"
    [class.without-border-radius]="withoutBorderRadius"
    [class.without-elevation]="withoutElevation"
    [class.without-borders]="withoutBorders"
  >
    <div class="header-row"></div>
    <div class="row" *ngFor="let line of lines; trackBy: trackByIndex">
      <div>
        <div class="cell first"></div>
        <div class="cell"></div>
      </div>
      <div class="cell last"></div>
    </div>
  </div>
</div>
