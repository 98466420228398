import { createAction, props } from '@ngrx/store';

import { Category } from '@models';

export const LOAD_CATEGORIES = '[document] fetch categories';
export const STORE_CATEGORIES = '[document] store categories';

export const ADD_CATEGORY = '[document] add categories';
export const ADD_CATEGORY_SUCCESS = '[document] add categories success';

export const UPDATE_CATEGORY = '[document] update category';
export const UPDATE_CATEGORY_SUCCESS = '[document] update category success';

export const UPDATE_CATEGORY_NAME = '[document] update category name';

export const RESET_CATEGORIES = '[document] reset categories';

export const loadCategories = createAction(LOAD_CATEGORIES);

export const storeCategories = createAction(
  STORE_CATEGORIES,
  props<{ categories: Category[] | null }>(),
);

export const addCategory = createAction(
  ADD_CATEGORY,
  props<{ name: string }>(),
);

export const addCategorySuccess = createAction(
  ADD_CATEGORY_SUCCESS,
  props<{ category: Category }>(),
);

export const updateCategory = createAction(
  UPDATE_CATEGORY,
  props<{ category: Category }>(),
);

export const updateCategorySuccess = createAction(
  UPDATE_CATEGORY_SUCCESS,
  props<{ category: Category }>(),
);

export const updateCategoryName = createAction(
  UPDATE_CATEGORY_NAME,
  props<{ categoryId: number; newName: string }>(),
);

export const resetCategories = createAction(RESET_CATEGORIES);
