import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { Mapper, NgUtils, TiimePipesModule } from '@manakincubber/tiime-utils';
import { TiimeOverlayRef, TIIME_OVERLAY_DATA } from 'tiime-components';

import { DecisionTreeDocumentChoice } from '@core/models';
import { mapToActionTypeIconPath } from '@core/utils/bank-transfer.utils';

@Component({
  selector: 'app-bank-transfer-document-proof-choice-overlay',
  templateUrl: './bank-transfer-document-proof-choice-overlay.component.html',
  standalone: true,
  imports: [CommonModule, TiimePipesModule],
  styleUrls: ['./bank-transfer-document-proof-choice-overlay.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BankTransferDocumentProofChoiceOverlayComponent {
  readonly trackByIndex = NgUtils.trackByIndex.bind(this);
  readonly mapToIconPath: Mapper<string | undefined, string> =
    mapToActionTypeIconPath;

  constructor(
    private overlayRef: TiimeOverlayRef<DecisionTreeDocumentChoice>,
    @Inject(TIIME_OVERLAY_DATA) public choices: DecisionTreeDocumentChoice[],
  ) {}

  select(option: DecisionTreeDocumentChoice): void {
    this.overlayRef.close(option);
  }
}
