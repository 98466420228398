import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatIconModule } from '@angular/material/icon';
import { TranslateModule } from '@ngx-translate/core';

import { TiimeAutoFocusModule } from '../auto-focus';
import { TiimeIconModule } from '../icon';
import { TiimeLetModule } from '../let';
import { TiimeOverlayModule } from '../overlay';
import { TiimeTooltipModule } from '../tooltip';
import { TiimeDropdownComponent } from './select/dropdown/dropdown.component';
import { TiimeSelectComponent } from './select/select.component';
import { SelectedOptionPipe } from './select/selected-option.pipe';

@NgModule({
  declarations: [
    SelectedOptionPipe,
    TiimeDropdownComponent,
    TiimeSelectComponent,
  ],
  imports: [
    CommonModule,
    TiimeAutoFocusModule,
    TiimeIconModule,
    TiimeTooltipModule,
    TranslateModule,
    TiimeOverlayModule,
    TiimeLetModule,
    MatIconModule,
    ReactiveFormsModule,
  ],
  exports: [TiimeSelectComponent],
})
export class TiimeSelectModule {}
