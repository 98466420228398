import { UserPermissionNameEnum } from '@core/enum/users';

export interface PermissionAttributeApiContract {
  product: string;
  module: string;
  name: string;
  permit: boolean;
  translated_name: string;
}

export class PermissionAttribute {
  get fullName(): UserPermissionNameEnum {
    return `${this.product}.${this.module}.${this.name}` as UserPermissionNameEnum;
  }

  constructor(
    public product: string,
    public module: string,
    public name: string,
    public permit: boolean,
    public translatedName: string,
  ) {}

  static fromJson(json: PermissionAttributeApiContract): PermissionAttribute {
    return new PermissionAttribute(
      json.product,
      json.module,
      json.name,
      json.permit,
      json.translated_name,
    );
  }

  static toJson(
    attribute: PermissionAttribute,
  ): PermissionAttributeApiContract {
    return {
      product: attribute.product,
      module: attribute.module,
      name: attribute.name,
      permit: attribute.permit,
      translated_name: attribute.translatedName,
    };
  }
}
