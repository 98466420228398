import { Directive, Input, TemplateRef, ViewContainerRef } from '@angular/core';

import { ReloadService } from '@core/reload.service';
import { AclService } from '@core/services/acl.service';
import { UserPermissionNameEnum } from '@enums/users';

import { AbstractHasAccessDirective } from './abstract-has-access.directive';

@Directive({
  standalone: true,
  selector: '[hasAccess]',
})
export class HasAccessDirective extends AbstractHasAccessDirective {
  @Input() set hasAccess(
    val: UserPermissionNameEnum | UserPermissionNameEnum[],
  ) {
    this.setPermissions(val);
  }

  constructor(
    protected readonly aclService: AclService,
    protected readonly reloadService: ReloadService,
    private readonly templateRef: TemplateRef<unknown>,
    private readonly viewContainer: ViewContainerRef,
  ) {
    super(aclService, reloadService);
  }

  protected refreshContainer(hasAccess: boolean): void {
    this.viewContainer.clear();
    if (hasAccess) {
      this.viewContainer.createEmbeddedView(this.templateRef);
    }
  }
}
