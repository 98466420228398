import { Inject, Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import {
  map,
  pairwise,
  startWith,
  switchMap,
  tap,
  withLatestFrom,
} from 'rxjs/operators';

import { ApiService } from '@core';
import { GTM_DATA_LAYER } from '@core/tokens';
import { Company, User } from '@models';

import { SentryService } from '../../sentry/sentry.service';
import * as UserActions from './user-actions';
import { userSelector } from './user-selector';

@Injectable()
export class UserEffects {
  constructor(
    private readonly store: Store,
    private readonly actions$: Actions,
    private readonly apiService: ApiService,
    private readonly sentryService: SentryService,
    @Inject(GTM_DATA_LAYER) private readonly gtmDataLayer: GtmDataLayer,
  ) {}

  selectCompanyAction$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(UserActions.SELECT_COMPANY),
        switchMap((action: { company: Company }) =>
          this.apiService.changeUserActiveCompany(action.company.id),
        ),
      ),
    { dispatch: false },
  );

  updateAction$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(UserActions.UPDATE),
        withLatestFrom(this.store.select(userSelector)),
        tap(([, user]) => this.sentryService.setUser(user)),
      ),
    { dispatch: false },
  );

  updateIntercomMetadata$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(UserActions.UPDATE),
        map(({ user }: { user: User }) => ({
          userId: user.id,
          userEmail: user.email,
          companyId: user.activeCompany?.id,
          companyName: user.activeCompany?.name,
          businessUnitIds: user.activeCompany?.businessUnits.map(
            ({ id }) => id,
          ),
        })),
        // Trigger initial pairwise value
        startWith({
          userId: undefined,
          userEmail: undefined,
          companyId: undefined,
          companyName: undefined,
          businessUnitIds: undefined,
        }),
        pairwise(),
        tap(([previous, current]) => {
          if (previous.companyId !== current.companyId) {
            this.gtmDataLayer.push({
              event: 'intercom_context_update',
              event_properties: current,
            });
          }
        }),
      ),
    { dispatch: false },
  );
}
