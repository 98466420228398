import { Types } from '@amplitude/analytics-browser';
import { Injectable } from '@angular/core';

export type ExtraWithGroups = {
  extra: Types.Event['extra'] & { groups: { [key: string]: unknown } };
};

/**
 * There is currently a bug with the Amplitude wrapper preventing groups
 * from being added on `track` calls. To go around it, groups can be added
 * to the `extra` option and this plugin moves them in the right place.
 *
 * Example:
 * ```
 * ampli.myEvent(
 *    { eventProperty: 'value' },
 *    { extra: { groups: { groupName: 'group value' } } }
 * )
 * ```
 */
@Injectable({ providedIn: 'root' })
export class GroupsPatchPlugin implements Types.EnrichmentPlugin {
  type = 'enrichment' as const;

  execute(event: Types.Event): Promise<Types.Event> {
    // There is no need to remove the property from extra since
    // extra is not sent to the destination
    if (shouldExtractGroupsFromExtra(event)) {
      event.groups = event.extra.groups;
    }
    return Promise.resolve(event);
  }
}

function shouldExtractGroupsFromExtra(
  event: Types.Event,
): event is Types.Event & ExtraWithGroups {
  return isTrackingEvent(event) && event.extra && 'groups' in event.extra;
}

function isTrackingEvent({ event_type }: Types.Event): boolean {
  return (
    event_type !== Types.SpecialEventType.IDENTIFY &&
    event_type !== Types.SpecialEventType.GROUP_IDENTIFY &&
    event_type !== Types.SpecialEventType.REVENUE
  );
}
