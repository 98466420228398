<ng-content></ng-content>
<div *ngIf="isExternalLink" class="external-link-button">
  <button
    tiime-button
    flat
    icon
    tiimeTooltip
    tooltipClass="dark-tooltip"
    tooltipPosition="bottom"
    [content]="externalLinkTooltip"
  >
    <mat-icon svgIcon="tc_icon-open-window"></mat-icon>
  </button>
  <ng-template #externalLinkTooltip>
    <div class="external-link-tooltip">Ouvrir dans un nouvel onglet</div>
  </ng-template>
</div>
