import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import {
  GetOptions,
  PaginationData,
  RequiredGetOptions,
} from 'tiime-components';

import { TemporaryEncoder } from '@core/temporary-encoder';
import { ApiAlertError } from '@decorators/api-alert-error';
import { HttpHelper } from '@helpers';
import { Company, CompanyApiContract } from '@models';

import { UsersService } from './users.service';

@Injectable({
  providedIn: 'root',
})
export class CompaniesService {
  private readonly resource = 'api/v1/companies';

  constructor(
    private readonly http: HttpClient,
    private readonly userService: UsersService,
  ) {}

  @ApiAlertError()
  getAll(
    getOptions: RequiredGetOptions<'range'>,
  ): Observable<PaginationData<Company>> {
    const partialOptions = new GetOptions(getOptions).toHttpGetOptions();
    const options = {
      params: new HttpParams({
        fromObject: partialOptions.params,
        encoder: new TemporaryEncoder(),
      }),
      headers: {
        ...partialOptions.headers,
        Accept: 'application/vnd.tiime.companies.v2+json',
      },
    };
    return this.http
      .get<CompanyApiContract[]>(this.resource, {
        ...options,
        observe: 'response',
      })
      .pipe(
        HttpHelper.mapToPaginationData(
          getOptions.range,
          (companiesJson: CompanyApiContract) =>
            Company.fromJson(companiesJson),
        ),
      );
  }

  @ApiAlertError()
  get(id: number): Observable<Company> {
    return this.http
      .get<CompanyApiContract>(`${this.resource}/${id}`)
      .pipe(map(companyJson => Company.fromJson(companyJson)));
  }

  @ApiAlertError()
  update(id: number, company: Company): Observable<Company> {
    return this.http
      .patch<CompanyApiContract>(
        `${this.resource}/${id}`,
        Company.toJson(company),
      )
      .pipe(map(companyJson => Company.fromJson(companyJson)));
  }

  @ApiAlertError()
  deleteLogo(id: number): Observable<Company> {
    return this.http
      .patch<CompanyApiContract>(`${this.resource}/${id}`, { logo: null })
      .pipe(map(companyJson => Company.fromJson(companyJson)));
  }
}
