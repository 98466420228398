import { Injectable } from '@angular/core';

import { PaginationRange } from './pagination-range';

@Injectable({
  providedIn: 'root',
})
export class PaginatorService {
  private static readonly LOCAL_DATA_KEY = 'paginator_preferences';

  savePreferences(value: number, componentName: string): void {
    const parsedLocalData = this.getParsedLocalData() || {};
    if (parsedLocalData[componentName] === value) {
      return;
    }
    localStorage.setItem(
      PaginatorService.LOCAL_DATA_KEY,
      JSON.stringify({
        ...parsedLocalData,
        [componentName]: value,
      }),
    );
  }

  getLocalPreferences(componentName: string): PaginationRange | null {
    const parsedLocalData = this.getParsedLocalData();
    const localPageSize = parsedLocalData[componentName];
    return localPageSize
      ? new PaginationRange(undefined, undefined, undefined, localPageSize)
      : null;
  }

  private getParsedLocalData(): Record<string, number> {
    const localData = localStorage.getItem(PaginatorService.LOCAL_DATA_KEY);
    return localData ? (JSON.parse(localData) as Record<string, number>) : {};
  }
}
