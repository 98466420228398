import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  Input,
} from '@angular/core';
import { NgUtils } from '@manakincubber/tiime-utils';
import { BehaviorSubject } from 'rxjs';

import {
  getFree22Columns,
  INVOICING_ADVANCED_TABLE_COLUMNS,
  INVOICING_STANDARD_TABLE_COLUMNS,
} from '@constants';
import { InvoicingTemplate } from '@enums';
import { InvoicingVatType } from '@models';

import { DocumentEditorTableColumn } from '../../../../shared/components/document-editor-table-header/document-editor-table-column';
import { InvoicingDocument } from '../../../company-core/models/invoicing-document.type';

@Component({
  selector: 'app-invoicing-document-lines-preview',
  templateUrl: './invoicing-document-lines-preview.component.html',
  styleUrls: [
    '../../../invoice/invoice-shared/invoicing-lines/styles/invoicing-lines-base.scss',
    '../../../invoice/invoice-shared/invoicing-lines/styles/invoicing-line-base.scss',
    './invoicing-document-lines-preview.component.scss',
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InvoicingDocumentLinesPreviewComponent implements AfterViewInit {
  @Input() invoicingVatTypes: InvoicingVatType[];

  // eslint-disable-next-line @angular-eslint/no-input-rename
  @Input('invoicingDocument')
  set setInvoicingDocument(invoicingDocument: InvoicingDocument) {
    this.invoicingDocument = invoicingDocument;
    this.color$.next(invoicingDocument.color);
    if (invoicingDocument.template === 'advanced') {
      this.columns$.next(
        invoicingDocument.unitsEnabled
          ? INVOICING_ADVANCED_TABLE_COLUMNS
          : getFree22Columns(INVOICING_ADVANCED_TABLE_COLUMNS),
      );
    } else {
      this.columns$.next(INVOICING_STANDARD_TABLE_COLUMNS);
    }
  }

  invoicingDocument: InvoicingDocument;
  readonly columns$ = new BehaviorSubject<DocumentEditorTableColumn[]>([]);
  readonly displayOverflowOverlay = new BehaviorSubject(false);
  readonly color$ = new BehaviorSubject<string | undefined>(undefined);
  readonly InvoicingTemplate = InvoicingTemplate;
  readonly trackById = NgUtils.trackById;

  constructor(private readonly elementRef: ElementRef<HTMLElement>) {}

  ngAfterViewInit(): void {
    const parentElement = this.elementRef.nativeElement.parentElement;
    this.displayOverflowOverlay.next(
      parentElement.scrollHeight - parentElement.offsetHeight > 0,
    );
  }
}
