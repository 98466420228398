export interface CreateMandatePayloadApiContract {
  name: string;
  iban: string;
  bic: string;
  address: string;
  zip_code: string;
  city: string;
  country: string;
  sequence: string;
}

export class CreateMandatePayload {
  constructor(
    public name: string,
    public iban: string,
    public bic: string,
    public address: string,
    public zipCode: string,
    public city: string,
    public country: string,
    public sequence: 'once' | 'recurrent',
  ) {}

  static toJson(
    mandate: CreateMandatePayload,
  ): CreateMandatePayloadApiContract {
    return {
      name: mandate.name,
      iban: mandate.iban.replace(/\s/g, ''),
      bic: mandate.bic,
      address: mandate.address,
      zip_code: mandate.zipCode,
      city: mandate.city,
      country: mandate.country,
      sequence: mandate.sequence,
    };
  }
}
