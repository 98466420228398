export enum BillerConnectionStatus {
  pending = 'pending',
  wrongpass = 'wrongpass',
  unavailable = 'unavailable',
  bug = 'bug',
  broken = 'broken',
  closed = 'closed',
  connected = 'connected',
  additional_information_needed = 'additional_information_needed',
  action_needed = 'action_needed',
  password_expired = 'password_expired',
}

export enum BillerConnectionFilterKeys {
  active = 'active',
  pendingStatus = 'pendingStatus', //pending is a reserved word in formGroup
  outOfSync = 'outOfSync',
  updatedNeeded = 'updatedNeeded',
}

export const ACTIVE_STATUS: string[] = [BillerConnectionStatus.connected];
export const UPDATE_NEEDED_STATUS: string[] = [
  BillerConnectionStatus.action_needed,
  BillerConnectionStatus.additional_information_needed,
  BillerConnectionStatus.wrongpass,
  BillerConnectionStatus.password_expired,
];
export const PENDING_STATUS: string[] = [BillerConnectionStatus.pending];
export const OUT_OF_SYNC_STATUS: string[] = [
  BillerConnectionStatus.broken,
  BillerConnectionStatus.bug,
  BillerConnectionStatus.unavailable,
];
export const BILLER_STATUS_FILTER: Record<string, string[]> = {
  active: ACTIVE_STATUS,
  pendingStatus: PENDING_STATUS,
  outOfSync: OUT_OF_SYNC_STATUS,
  updateNeeded: UPDATE_NEEDED_STATUS,
};
