import { HttpErrorResponse } from '@angular/common/http';

export interface BeneficiaryEditDialogResponseInterface {
  error: HttpErrorResponse;
  listRefreshNeeded: boolean;
  beneficiaryForTransfer: boolean;
}

export class BeneficiaryEditDialogResponse {
  constructor(
    public error?: HttpErrorResponse,
    public listRefreshNeeded?: boolean,
    public beneficiaryForTransfer?: boolean,
  ) {}
}
