import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { UntilDestroy } from '@ngneat/until-destroy';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';

import { BusinessUnit, Company } from '@models';

@UntilDestroy()
@Injectable({
  providedIn: 'root',
})
export class BusinessUnitsService {
  private readonly resource = 'api/v1/companies/{companyId}/business_units';
  private readonly logoResource =
    'api/v1/companies/{companyId}/business_unit/logo/preview';

  private readonly activeBusinessUnit$ = new BehaviorSubject<BusinessUnit>(
    undefined,
  );
  private readonly activeBusinessUnitLogo$ =
    new BehaviorSubject<SafeResourceUrl>(undefined);
  constructor(
    private readonly http: HttpClient,
    private readonly sanitizer: DomSanitizer,
  ) {}

  getActiveBusinessUnit(): Observable<BusinessUnit> {
    return this.activeBusinessUnit$;
  }

  getActiveBusinessUnitLogo(): Observable<SafeResourceUrl | null> {
    return this.activeBusinessUnitLogo$;
  }

  updateActiveBusinessUnit(company: Company, businessUnit: BusinessUnit): void {
    this.activeBusinessUnit$.next(businessUnit);
    if (businessUnit) {
      this.getLogo(company.id).subscribe();
    } else {
      this.activeBusinessUnitLogo$.next(null);
    }
  }

  getBusinessUnit(companyId: number): Observable<BusinessUnit | null> {
    return this.http
      .get(`${this.resource.replace('{companyId}', companyId.toString())}`)
      .pipe(map((businessUnits: BusinessUnit[]) => businessUnits[0]));
  }

  private getLogo(
    companyId: number,
  ): Observable<SafeResourceUrl> | Observable<null> {
    return this.http
      .get(
        `${this.logoResource.replace('{companyId}', companyId.toString())}`,
        {
          responseType: 'blob',
        },
      )
      .pipe(
        map(blob => {
          const objectUrl = URL.createObjectURL(blob);
          return this.sanitizer.bypassSecurityTrustUrl(objectUrl);
        }),
        tap(buLogo => this.activeBusinessUnitLogo$.next(buLogo)),
        catchError(() => {
          this.activeBusinessUnitLogo$.next(null);
          return of(null);
        }),
      );
  }
}
