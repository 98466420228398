import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  BehaviorSubject,
  iif,
  map,
  Observable,
  of,
  switchMap,
  tap,
} from 'rxjs';

import { ApiAlertError } from '@decorators/api-alert-error';

@Injectable({
  providedIn: 'root',
})
export class CurrenciesService {
  private readonly resource = 'api/v1/currencies';

  private readonly currencies$ = new BehaviorSubject<string[]>(undefined);

  constructor(private readonly http: HttpClient) {}

  getCurrencies(): Observable<string[]> {
    return this.currencies$.pipe(
      switchMap(currencies =>
        iif(
          () => typeof currencies !== 'undefined',
          of(currencies),
          this.getAll(),
        ),
      ),
    );
  }

  @ApiAlertError()
  getAll(): Observable<string[]> {
    return this.http.get<Record<number, string>>(`${this.resource}`).pipe(
      map(currencies => Object.values(currencies)),
      tap(currencies => this.currencies$.next(currencies)),
    );
  }
}
