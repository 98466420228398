{
  "name": "t2-web",
  "version": "4.0.0",
  "private": true,
  "license": "UNLICENSED",
  "scripts": {
    "ng": "ng",
    "build": "node --max_old_space_size=5048 ./node_modules/@angular/cli/bin/ng build",
    "test": "ng test",
    "lint": "ng lint",
    "start:from-docker": "ng serve --host 0.0.0.0",
    "cypress:open": "./node_modules/.bin/cypress open",
    "lint-ci:all": "bash ./scripts/lint-ci.sh",
    "test-ci:all": "bash ./scripts/test-ci.sh",
    "build-ci:all": "bash ./scripts/build-ci.sh",
    "build-ci:tiime-components": "bash ./scripts/build-ci.sh tiime-components",
    "build-cd:development": "bash ./scripts/build-cd.sh development",
    "build-cd:staging": "bash ./scripts/build-cd.sh staging",
    "build-cd:production": "bash ./scripts/build-cd.sh production",
    "build-cd:school": "bash ./scripts/build-cd.sh school",
    "build-cd:yellow": "bash ./scripts/build-cd.sh yellow",
    "build-cd:pink": "bash ./scripts/build-cd.sh pink",
    "build-cd:red": "bash ./scripts/build-cd.sh red",
    "build-cd:blue": "bash ./scripts/build-cd.sh blue",
    "build-cd:green": "bash ./scripts/build-cd.sh green",
    "build-cd:orange": "bash ./scripts/build-cd.sh orange",
    "build-cd:testing": "bash ./scripts/build-cd.sh testing",
    "build-cd:local": "bash ./scripts/build-cd.sh local",
    "build-cd:dynamic": "bash ./scripts/build-cd.sh dynamic",
    "build:stats": "yarn build:libs && yarn run build --c=production --stats-json",
    "build:libs": "yarn run build:tiime-components && yarn run copy-fonts:tiime-components && yarn run copy-assets:tiime-components",
    "build:libs:win": "yarn run build:tiime-components && yarn run copy-fonts-win:tiime-components && yarn run copy-assets-win:tiime-components",
    "build:tiime-components": "yarn run build tiime-components",
    "copy-fonts:tiime-components": "mkdir -p ./dist/tiime-components/fonts && cp -R ./projects/tiime-components/src/lib/tiime-fontastic/fonts ./dist/tiime-components",
    "copy-assets:tiime-components": "mkdir -p ./dist/tiime-components/assets && cp -R ./projects/tiime-components/src/assets ./dist/tiime-components",
    "copy-fonts-win:tiime-components": "xcopy /e .\\projects\\tiime-components\\src\\lib\\tiime-fontastic\\fonts .\\dist\\tiime-components\\fonts\\",
    "copy-assets-win:tiime-components": "xcopy /e .\\projects\\tiime-components\\src\\assets .\\dist\\tiime-components\\assets\\",
    "deploy:tiime-components": "yarn build:tiime-components && cd dist/tiime-components && yarn publish --non-interactive --registry http://34.253.172.249:4873 && cd ..",
    "bundle-report": "webpack-bundle-analyzer dist/tiime/stats.json",
    "postinstall": "yarn husky",
    "release": "standard-version",
    "release:minor": "standard-version --release-as minor",
    "release:patch": "standard-version --release-as patch",
    "release:major": "standard-version --release-as major",
    "amplitude:update:main": "ampli pull -b main",
    "storybook": "ng run tiime-components:storybook",
    "build-storybook": "ng run tiime-components:build-storybook"
  },
  "engines": {
    "node": ">=18.0.0 <20.0.0"
  },
  "dependencies": {
    "@amplitude/analytics-browser": "^2.11.2",
    "@amplitude/plugin-session-replay-browser": "^1.6.23",
    "@angular/animations": "~18.2.3",
    "@angular/cdk": "18.2.3",
    "@angular/common": "~18.2.3",
    "@angular/compiler": "~18.2.3",
    "@angular/core": "~18.2.3",
    "@angular/forms": "~18.2.3",
    "@angular/material": "~18.2.3",
    "@angular/material-moment-adapter": "~18.2.3",
    "@angular/platform-browser": "~18.2.3",
    "@angular/platform-browser-dynamic": "~18.2.3",
    "@angular/router": "~18.2.3",
    "@manakincubber/tiime-auth": "^18.0.0",
    "@manakincubber/tiime-tracking": "^18.0.0",
    "@manakincubber/tiime-utils": "^18.0.0",
    "@ngneat/until-destroy": "^10.0.0",
    "@ngrx/effects": "^18.0.2",
    "@ngrx/store": "^18.0.2",
    "@ngrx/store-devtools": "^18.0.2",
    "@ngx-translate/core": "^15.0.0",
    "@ngx-translate/http-loader": "^8.0.0",
    "@sentry/angular-ivy": "^7.114.0",
    "auth0-js": "^9.26.1",
    "big.js": "^6.2.1",
    "chart.js": "^4.4.4",
    "file-saver": "^2.0.5",
    "libphonenumber-js": "^1.11.7",
    "lodash-es": "^4.17.21",
    "lottie-web": "^5.12.2",
    "moment": "2.30.1",
    "ng2-pdf-viewer": "^10.3.0",
    "ngx-color-picker": "^17.0.0",
    "ngx-device-detector": "^8.0.0",
    "ngx-lottie": "^12.0.0",
    "ngx-mask": "^18.0.0",
    "rxjs": "^7.5.7",
    "tslib": "^2.4.0",
    "zone.js": "~0.14.10"
  },
  "devDependencies": {
    "@amplitude/ampli": "^1.35.0",
    "@angular-devkit/build-angular": "^18.2.3",
    "@angular-eslint/builder": "^18.3.0",
    "@angular-eslint/eslint-plugin": "^18.3.0",
    "@angular-eslint/eslint-plugin-template": "^18.3.0",
    "@angular-eslint/schematics": "^18.3.0",
    "@angular-eslint/template-parser": "^18.3.0",
    "@angular/cli": "~18.2.3",
    "@angular/compiler-cli": "~18.2.3",
    "@angular/language-service": "~18.2.3",
    "@ngrx/schematics": "^18.0.2",
    "@storybook/addon-essentials": "^8.2.9",
    "@storybook/addon-interactions": "^8.2.9",
    "@storybook/addon-links": "^8.2.9",
    "@storybook/angular": "^8.2.9",
    "@storybook/blocks": "^8.2.9",
    "@storybook/test": "^8.1.5",
    "@types/auth0-js": "^9.21.6",
    "@types/big.js": "^6.2.2",
    "@types/chart.js": "^2.9.41",
    "@types/file-saver": "^2.0.7",
    "@types/google.maps": "^3.58.0",
    "@types/jasmine": "~5.1.4",
    "@types/jasminewd2": "~2.0.13",
    "@types/lodash-es": "^4.17.12",
    "@types/node": "^18.19.50",
    "@typescript-eslint/eslint-plugin": "^8.4.0",
    "@typescript-eslint/parser": "^8.4.0",
    "angular-http-server": "^1.12.0",
    "axios": "^1.7.7",
    "chromatic": "^11.7.1",
    "cypress": "13.14.2",
    "cypress-file-upload": "^5.0.8",
    "eslint": "^8.57.0",
    "eslint-config-prettier": "^9.1.0",
    "eslint-plugin-import": "2.26.0",
    "eslint-plugin-prettier": "^5.2.1",
    "eslint-plugin-storybook": "^0.8.0",
    "husky": "9.1.5",
    "jasmine-core": "~5.3.0",
    "jasmine-spec-reporter": "~7.0.0",
    "karma": "~6.4.4",
    "karma-chrome-launcher": "^3.2.0",
    "karma-coverage-istanbul-reporter": "~3.0.3",
    "karma-jasmine": "~5.1.0",
    "karma-jasmine-html-reporter": "^2.1.0",
    "lint-staged": "^15.2.10",
    "ng-packagr": "^18.2.1",
    "prettier": "^3.3.3",
    "prettier-plugin-organize-attributes": "^1.0.0",
    "standard-version": "^9.5.0",
    "storybook": "^8.2.9",
    "stylelint": "^16.9.0",
    "stylelint-config-idiomatic-order": "^10.0.0",
    "stylelint-config-recommended-scss": "^14.1.0",
    "ts-node": "~10.9.2",
    "typescript": "~5.4.5",
    "webpack-bundle-analyzer": "^4.10.2"
  }
}
