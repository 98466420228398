import { StandardDocumentCategoryIdentifier } from '@enums';

/**
 * Contains document category identifiers of categories which have a specific
 * UI instead of only relying on the preview dialog
 */
export const DOCUMENT_CATEGORIES_WITH_SPECIFIC_UI =
  new Set<StandardDocumentCategoryIdentifier>([
    StandardDocumentCategoryIdentifier.EXPENSE_REPORT,
  ]);
