import { funcToSource } from './_rollup-plugin-web-worker-loader__helper__funcToSource.js';
function createURL(fn, sourcemapArg) {
  var lines = funcToSource(fn, sourcemapArg);
  var blob = new Blob(lines, {
    type: 'application/javascript'
  });
  return URL.createObjectURL(blob);
}
function createInlineWorkerFactory(fn, sourcemapArg) {
  var url;
  return function WorkerFactory(options) {
    url = url || createURL(fn, sourcemapArg);
    return new Worker(url, options);
  };
}
export { createInlineWorkerFactory };