const maximumSizeMb = 8;
const acceptedTypesDescription = [
  '.jpeg',
  '.jpg',
  '.png',
  '.gif',
  '.pdf',
  '.csv',
  '.txt',
  '.xls',
  '.xlsx',
  '.doc',
  '.docx',
  '.odt',
  '.ppt',
  '.pptx',
  '.rtf',
];

export const unsupportedAcceptedTypes: string[] = [
  'text/csv',
  'text/plain',
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  'application/msword',
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  'application/vnd.oasis.opendocument.text',
  'application/vnd.ms-powerpoint',
  'application/vnd.openxmlformats-officedocument.presentationml.presentation',
  'application/rtf',
  'text/rtf',
  'application/vnd.ms-excel',
];

export const UPLOAD_DOCUMENT_CONFIG = {
  acceptedTypes: acceptedTypesDescription,
  unsupportedAcceptedTypes: unsupportedAcceptedTypes,
  maximumSize: maximumSizeMb,
  errorMessage: `Fichier incorrect,
  la taille maximale doit être de ${maximumSizeMb}Mo et le fichier doit être de type ${acceptedTypesDescription.join(
    ', ',
  )}`,
};
