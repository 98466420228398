import { Document, DocumentPaymentStatusEnum } from '@core/models';
import { Metadata, AmountMetadataValue } from '@models/documents';

export function getVatFromDocumentMetadata(metadata: Metadata[]): number {
  const amountData = metadata?.find(metadata => metadata.key === 'vat_amount');
  if (Array.isArray(amountData?.value)) {
    return (
      amountData.value?.find(m => m.key === 'total')
        ?.value as AmountMetadataValue
    )?.value;
  }
  return amountData?.value
    ? (amountData.value as AmountMetadataValue).value
    : null;
}

export function getAmountFromDocumentMetadata(metadata: Metadata[]): number {
  const amountData = metadata?.find(metadata => metadata.key === 'amount');
  return amountData?.value
    ? (amountData.value as AmountMetadataValue).value
    : null;
}

export function getAmountCurrencyFromDocumentMetadata(
  metadata: Metadata[],
): string {
  const amountData = metadata?.find(metadata => metadata.key === 'amount');
  return amountData?.value
    ? (amountData?.value as AmountMetadataValue).currency
    : 'EUR';
}

export function getDateFromDocumentMetadata(metadata: Metadata[]): string {
  const date = metadata.find(data => data.key === 'date')?.value;
  return date ? String(date) : '';
}

export function getWordingFromDocumentMetadata(metadata: Metadata[]): string {
  const wording = metadata.find(data => data.key === 'wording')
    ?.value as string;
  return wording ? wording : '';
}

export function someDocumentsAreMatchedAndPaid(documents: Document[]): boolean {
  return documents.some(
    ({ paymentStatus, hasBankTransactions }) =>
      paymentStatus === DocumentPaymentStatusEnum.PAID && hasBankTransactions,
  );
}

export function getDocumentIdsEligibleForStatusUpdate(
  documents: Document[],
  status: DocumentPaymentStatusEnum,
): number[] {
  return documents
    .filter(({ paymentStatus, hasBankTransactions }) => {
      if (
        paymentStatus === DocumentPaymentStatusEnum.PAID &&
        hasBankTransactions
      ) {
        return false;
      }
      return paymentStatus !== status;
    })
    .map(({ id }) => id);
}
