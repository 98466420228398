import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Observable } from 'rxjs';
import { filter } from 'rxjs/operators';

import { filterNotNullOrUndefined } from '@helpers';
import { TagDialogData } from '@shared';

import { TagDialogComponent, TagDialogResponse } from './tag-dialog.component';

@Injectable({
  providedIn: 'root',
})
export class TagDialogService {
  constructor(private readonly dialog: MatDialog) {}

  openDialog(dialogData: TagDialogData): Observable<TagDialogResponse> {
    return this.dialog
      .open<TagDialogComponent, TagDialogData, TagDialogResponse>(
        TagDialogComponent,
        {
          data: dialogData,
          width: '600px',
        },
      )
      .afterClosed()
      .pipe(
        filterNotNullOrUndefined(),
        filter(res => !!res),
      );
  }
}
