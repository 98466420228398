import { Injectable } from '@angular/core';
import { Router, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { LegalService } from '@services/legal.service';

@Injectable({
  providedIn: 'root',
})
export class HasUserNotSignedTosAndGdprGuard {
  constructor(
    private readonly legalService: LegalService,
    private readonly router: Router,
  ) {}

  canMatch(): Observable<boolean | UrlTree> {
    return this.legalService.hasUserSignedTosAndGdpr().pipe(
      map(hasUserSigned => {
        return !hasUserSigned || this.router.createUrlTree(['']);
      }),
    );
  }
}
