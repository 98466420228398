<ng-container *ngIf="formValue">
  <section class="recap">
    <header *ngIf="!hideHeader" data-cy="recap__header">
      <ng-container [ngSwitch]="reason">
        <ng-container *ngSwitchCase="undefined">
          <h1>Récapitulatif de mon virement</h1>
        </ng-container>
        <ng-container *ngSwitchCase="'refund'">
          <h1>Récapitulatif de mon remboursement</h1>
        </ng-container>
        <ng-container *ngSwitchCase="'documentPayment'">
          <h1>Récapitulatif de mon paiement</h1>
        </ng-container>
      </ng-container>

      <h2>Vérifiez qu’il n’y ait pas d’erreur</h2>
    </header>

    <div class="recap__amount" data-cy="recap__amount">
      <tiime-twocolors-icon
        icon="ic_twocolors_transfer"
        size="24"
      ></tiime-twocolors-icon>
      <span data-cy="recap__amount--value">
        {{ formValue.initiation.amount | currency }}
      </span>
    </div>

    <div
      *ngIf="formValue.parameters.label?.length > 0"
      class="recap__label"
      data-cy="recap__label"
    >
      <img
        src="assets/icon_bank_transfer_tag.svg"
        alt=""
        height="16"
        width="17"
      />
      <span>{{ formValue.parameters.label }}</span>
    </div>

    <div class="recap__accounts">
      <ng-container
        *ngTemplateOutlet="
          accountTemplate;
          context: { account: formValue.initiation.account }
        "
      ></ng-container>

      <div class="recap__accounts__separator">
        <mat-icon svgIcon="icon-arrow-right"></mat-icon>
      </div>

      <ng-container
        *ngTemplateOutlet="
          accountTemplate;
          context: {
            account: formValue.initiation.beneficiary,
            isBeneficiary: true
          }
        "
      ></ng-container>
      <div *ngIf="reason === 'refund'" class="recap__beneficiary">
        <mat-icon svgIcon="icon-info"></mat-icon>
        <span>
          Il s’agit de votre compte de remboursement par défaut. Vous pouvez le
          modifier. Le nouveau bénéficiaire deviendra votre bénéficiaire par
          défaut.
        </span>
      </div>

      <div class="recap__card periodicity" attr.data-cy="recap__periodicity">
        <div class="recap__card__title">Prévu le</div>
        <div class="recap__card__name" data-cy="recap__date">
          {{ formValue.parameters.date | formattedDate : true }}
        </div>
        <div class="recap__card__bank-details" data-cy="recap__frequency">
          <app-payout-frequency-recap
            [payout]="formValue | map : mapToPayout"
          ></app-payout-frequency-recap>
        </div>
      </div>
    </div>
  </section>

  <ng-template
    let-account="account"
    let-isBeneficiary="isBeneficiary"
    #accountTemplate
  >
    <div
      class="recap__card"
      attr.data-cy="recap__account-{{ isBeneficiary ? 'to' : 'from' }}"
    >
      <div class="recap__card__title">
        {{ isBeneficiary ? 'Vers' : 'Depuis' }} le compte
      </div>
      <div class="recap__card__name">
        {{ account?.name }}
        <ng-container *ngIf="isBeneficiary">
          <app-trust-beneficiary
            [beneficiary]="account"
            [personalWalletBeneficiary]="personalWalletBeneficiary"
          ></app-trust-beneficiary>
        </ng-container>
      </div>
      <div class="recap__card__bank-details">
        <img
          alt=""
          height="20"
          width="20"
          [src]="account | map : mapToIconPath"
        />

        <span>
          <ng-container *ngIf="!isBeneficiary">
            {{ account?.bankName }} -
          </ng-container>
          {{ account?.iban | offuscatedIban }}
        </span>
      </div>
    </div>
  </ng-template>
</ng-container>
