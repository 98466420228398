export class Mail {
  public from?: string;
  public to?: string[];
  public cc?: string[];
  public cci?: string[];
  public subject?: string;
  public body?: string;

  constructor(options?: Mail) {
    Object.assign(this, options);
  }

  public static toJson(mail: Mail): Record<string, unknown> {
    return {
      reply_to: mail.from,
      to: mail.to,
      cc: mail.cc,
      cci: mail.cci,
      subject: mail.subject,
      body: mail.body,
    };
  }
}
