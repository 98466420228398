import moment from 'moment';

import { CashRegisterStatus } from '@enums';

export interface CashRegisterApiContract {
  id?: number;
  initialization_date: string;
  balance?: number;
  last_record_date?: string;
  first_error_record_date?: string;
  has_cashflow?: boolean;
}

export class CashRegister {
  private get isUpToDate(): boolean {
    return moment().isSame(this.lastRecordDate, 'day');
  }

  get status(): CashRegisterStatus {
    // status : error
    if (this.firstErrorRecordDate && !this.isUpToDate) {
      return this.balance < 0
        ? CashRegisterStatus.NEGATIVE_BALANCE
        : CashRegisterStatus.INCOMPLETE;
    }

    // status : incomplete
    if (!this.isUpToDate) {
      return this.balance < 0
        ? CashRegisterStatus.NEGATIVE_BALANCE
        : CashRegisterStatus.INCOMPLETE;
    }

    return CashRegisterStatus.VALID;
  }

  constructor(
    public id: number,
    public initializationDate: string,
    public balance?: number,
    public lastRecordDate?: string,
    public firstErrorRecordDate?: string,
    public hasCashFlow?: boolean,
  ) {}

  static fromJson(contract: CashRegisterApiContract): CashRegister {
    return new CashRegister(
      contract.id,
      contract.initialization_date,
      contract.balance,
      contract.last_record_date,
      contract.first_error_record_date,
      contract.has_cashflow,
    );
  }

  static toJson(cashRegister: CashRegister): CashRegisterApiContract {
    return {
      initialization_date: moment(cashRegister.initializationDate).format(
        'YYYY-MM-DD',
      ),
      balance: cashRegister.balance,
    };
  }
}
