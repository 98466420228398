import { PaginationData, TiimeSelectOption } from 'tiime-components';

import {
  StandardDocumentCategory,
  StandardDocumentCategoryIdentifier,
} from '@enums';

import { Document } from './document';
import { MetadataDef } from './documents';

export interface CategoryApiContract {
  id: number | null;
  name: string;
  pinned_at: string;
  available_metadata: MetadataDef[];
  identifier: StandardDocumentCategoryIdentifier;
}

export class Category {
  constructor(
    public id: number | null,
    public name?: StandardDocumentCategory | string,
    public pinnedAt?: string,
    public documents?: PaginationData<Document>,
    public availableMetadata?: MetadataDef[],
    public identifier?: StandardDocumentCategoryIdentifier,
  ) {}

  static fromJson(json: CategoryApiContract): Category {
    return new Category(
      json.id,
      json.name,
      json.pinned_at,
      undefined,
      json.available_metadata,
      json.identifier,
    );
  }

  static toJson(category: Category): CategoryApiContract {
    return {
      id: category.id,
      name: category.name,
      pinned_at: category.pinnedAt,
      available_metadata: category.availableMetadata,
      identifier: category.identifier,
    };
  }

  static isStandardCategory(categoryIdentifier: string): boolean {
    return Object.values(StandardDocumentCategoryIdentifier).some(
      identifier => `${categoryIdentifier}` === identifier,
    );
  }

  static mapToSelectOptions(
    categories: Category[],
  ): TiimeSelectOption<Category>[] {
    return [
      ...categories.map(category => ({
        value: category,
        label: category.name,
        image: category.getCategoryIconSrc(),
      })),
    ];
  }

  static sortByPin(categories: Category[]): Category[] {
    return categories.slice().sort((a, b) => {
      if (a.pinnedAt && b.pinnedAt) {
        const aDate = new Date(a.pinnedAt);
        const bDate = new Date(b.pinnedAt);
        return aDate.getTime() - bDate.getTime() < 0 ? -1 : 1;
      }
      if (a.pinnedAt || b.pinnedAt) {
        return a.pinnedAt ? -1 : 1;
      }
      return 0;
    });
  }

  getCategoryIconSrc(): string {
    const defaultPath = 'assets/category-icons/icon_';
    const isCustomCategory =
      Object.values(StandardDocumentCategoryIdentifier).findIndex(
        identifier => {
          return identifier === this.identifier;
        },
      ) === -1;

    if (
      this.identifier === StandardDocumentCategoryIdentifier.OTHER_DOCUMENT ||
      isCustomCategory
    ) {
      return `${defaultPath}${StandardDocumentCategoryIdentifier.OTHER_DOCUMENT}.svg`;
    }

    return `${defaultPath}${this.identifier}.svg`;
  }

  getCategoryHoverIllustration(): string {
    const technicalCategory = this.getTechnicalCategory();
    return `assets/illu_upload_${
      technicalCategory != '' ? technicalCategory : 'documents'
    }_hover.svg`;
  }

  getTechnicalIdentifier(): StandardDocumentCategoryIdentifier {
    const technicalCategory = this.getTechnicalCategory();
    const identifier = Object.values(StandardDocumentCategoryIdentifier).find(
      value => value === technicalCategory,
    );
    return identifier;
  }

  private getTechnicalCategory(): string {
    const category = Object.entries(StandardDocumentCategory).find(
      ([, value]) => value === this.name,
    );

    return category ? category[0] : '';
  }
}
