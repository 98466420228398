import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  Input,
  QueryList,
  ViewChildren,
  forwardRef,
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { NgUtils } from '@manakincubber/tiime-utils';

@Component({
  selector: 'app-iban-input',
  templateUrl: './iban-input.component.html',
  styleUrls: ['./iban-input.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => IbanInputComponent),
      multi: true,
    },
  ],
})
export class IbanInputComponent implements ControlValueAccessor {
  @ViewChildren('ibanInput') ibanInputs!: QueryList<ElementRef>;

  @Input() isFormatted = false;

  value: string;
  disabled: boolean;

  parts: string[] = new Array<string>(8).fill('');

  readonly trackByIndex = NgUtils.trackByIndex.bind(this);

  constructor(private readonly cdr: ChangeDetectorRef) {}

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  onChange: (value?: string) => void = () => {};
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  onTouched = (): void => {};

  writeValue(value: string): void {
    this.parts = new Array<string>(8).fill('');
    if (value) {
      const valueParts = this.splitIban(value);
      for (let i = 0; i < valueParts.length; i++) {
        this.parts[i] = valueParts[i];
      }
    }
  }

  registerOnChange(fn: (iban: string) => unknown): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => unknown): void {
    this.onTouched = fn;
  }

  setDisabledState?(disabled: boolean): void {
    this.disabled = disabled;
  }

  onBlur(): void {
    setTimeout(() => {
      if (
        !this.ibanInputs.some(
          input => document.activeElement === input.nativeElement,
        )
      ) {
        this.onTouched();
        this.cdr.markForCheck();
      }
    });
  }

  updateIban(index: number, $event: Event): void {
    const input = $event.target as HTMLInputElement;
    this.parts[index] = input.value;
    this.onChange(this.parts.join(''));
  }

  handleKeyDown(index: number, $event: KeyboardEvent): void {
    if (
      this.parts[index].length === 4 &&
      index < this.parts.length - 1 &&
      !($event.key === 'Backspace')
    ) {
      this.focusNextInput(index);
    } else if (
      this.parts[index].length === 0 &&
      $event.key === 'Backspace' &&
      index > 0
    ) {
      this.focusPreviousInput(index);
    }
  }

  focus(index: number = 0): void {
    const input = this.ibanInputs.toArray()[index];
    if (input) {
      input.nativeElement.focus();
    }
  }

  onPaste(event: ClipboardEvent): void {
    const pasteData = event.clipboardData?.getData('text') || '';
    const cleanData = pasteData.replace(/\s+/g, '');
    const parts = this.splitIban(cleanData);
    parts.forEach((part, i) => {
      if (i < this.parts.length) {
        this.parts[i] = part;
      }
    });
    this.onChange(this.parts.join(''));
  }

  private splitIban(iban: string): string[] {
    return iban.match(/.{1,4}/g) || [];
  }

  private focusNextInput(index: number): void {
    const nextInput = this.ibanInputs.toArray()[index + 1];
    if (nextInput) {
      nextInput.nativeElement.focus();
    }
  }

  private focusPreviousInput(index: number): void {
    const previousInput = this.ibanInputs.toArray()[index - 1];
    if (previousInput) {
      previousInput.nativeElement.focus();
    }
  }
}
