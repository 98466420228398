import { Directive } from '@angular/core';

@Directive()
export abstract class InputContainerControl<T> {
  value: T | null;

  readonly id: string;
  readonly empty: boolean;
  readonly autofilled?: boolean;
}
