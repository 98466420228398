import { SelectionModel } from '@angular/cdk/collections';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  HostBinding,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Sort } from '@angular/material/sort';
import { ActivatedRoute, Router } from '@angular/router';
import { Mapper, NgUtils } from '@manakincubber/tiime-utils';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Actions, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import {
  BehaviorSubject,
  combineLatest,
  EMPTY,
  merge,
  Observable,
  ReplaySubject,
  Subscription,
} from 'rxjs';
import {
  filter,
  startWith,
  switchMap,
  take,
  tap,
  withLatestFrom,
} from 'rxjs/operators';
import {
  ComplexSearchBarComponent,
  extractFiltersFromQueryParams,
  Filter,
  getFiltersQueryParams,
  MonoValueFilter,
  PaginationData,
  PaginationRange,
  PaginatorComponent,
  RequiredGetOptions,
  SearchBarComponent,
  SnackbarConfig,
  TableState,
  TiimeOverlayService,
  TiimeSnackbarService,
} from 'tiime-components';

import { UPLOAD_DOCUMENT_CONFIG } from '@constants';
import { UploadDocumentsService } from '@core';
import { DocumentComplexFilterKey } from '@core/enum/documents';
import { ActionsType } from '@core/enum/mass-actions';
import { filterNotNullOrUndefined } from '@core/helpers';
import {
  CompanyConfigService,
  DocumentsService,
  TransactionsService,
} from '@core/services';
import { MatchingType, StandardDocumentCategoryIdentifier } from '@enums';
import { FileTransferOverlayService } from '@file-transfer';
import { MatchingDialogService } from '@matching/matching-dialog/matching-dialog.service';
import {
  Category,
  Document,
  DocumentsMetadata,
  DocumentsWithMetadata,
  DocumentTypeEnum,
  Tag,
} from '@models';
import { DocumentsUiService } from '@services/documents/documents-ui.service';
import {
  DELETE_DOCUMENT_SUCCESS,
  deleteDocument,
  LOAD_DOCUMENTS_FOR_SEARCH_SUCCESS,
  loadDocumentsForSearch,
  searchedDocumentsSelector,
} from '@store/documents';

import { ConfirmationDialogService } from '../../../../../shared/components/confirmation-dialog/confirmation-dialog.service';
import { DocumentAddedDialogComponent } from '../document-added-dialog/document-added-dialog.component';
import { DocumentsMassActionsToolbarService } from '../documents-mass-actions-toolbar/documents-mass-actions-toolbar.service';
import { AbstractDocumentsTableDirective } from './abstract-documents-table-directive/abstract-documents-table.directive';
import { DocumentsTableAction } from './columns/actions/documents-actions-column-content/documents-actions-column-content.component';
import { AmountTypeEnum } from './columns/amount/documents-amount-column-content/documents-amount-column-content.component';

@UntilDestroy()
@Component({
  selector: 'app-documents-table',
  templateUrl: './documents-table.component.html',
  styleUrls: ['./documents-table.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DocumentsTableComponent
  extends AbstractDocumentsTableDirective
  implements OnInit
{
  @Input() set categoryId(categoryId: number[] | number | null) {
    if (Array.isArray(categoryId)) {
      this.categoryId$.next(categoryId);
    } else if (categoryId && !isNaN(categoryId)) {
      this.categoryId$.next([categoryId]);
    } else {
      this.categoryId$.next(null);
    }
  }

  // eslint-disable-next-line @angular-eslint/no-input-rename
  @Input('paginator') set paginatorRef(paginator: PaginatorComponent) {
    this.setPaginator = paginator;
  }

  // eslint-disable-next-line @angular-eslint/no-input-rename
  @Input('searchBar') set searchbarRef(searchBar: SearchBarComponent) {
    this.setSearchBar = searchBar;
    if (!this.searchByEnterSubscription && this.searchbar.enterToSearch) {
      this.searchByEnterSubscription = merge(
        this.searchbar.enter,
        this.searchbar.clear,
      )
        .pipe(
          tap(
            () =>
              this.searchInTable(
                this.searchbar.searchInput.nativeElement.value,
              ),
            untilDestroyed(this),
          ),
        )
        .subscribe();
    }
  }

  // eslint-disable-next-line @angular-eslint/no-input-rename
  @Input('complexSearchBar') set complexSearchBarRef(
    complexSearchBar: ComplexSearchBarComponent,
  ) {
    complexSearchBar.search
      .pipe(
        tap((filters: Filter<string | number>[]) =>
          this.filterInTable(filters),
        ),
        untilDestroyed(this),
      )
      .subscribe();
  }

  @Input() flat = false;
  @Input() disabledRows: Document[];
  @Input() disabledMatchedRows = false;
  @Input() disableFileDrop = false;
  @Input() matchingType: MatchingType;
  @Input() withMassActions = false;

  @Input() set selectedDocuments(selectedDocuments: Document[]) {
    this.selectedDocumentIds$.next(
      new Set(selectedDocuments.map(({ id }) => id)),
    );
  }

  @Input()
  selectedDocumentsCategoryIdentifier: StandardDocumentCategoryIdentifier;
  @Input() customActions: DocumentsTableAction[];

  @Output() rowClick = new EventEmitter<Document>();
  @Output() initialized = new EventEmitter<boolean>();
  @Output() loading = new EventEmitter<boolean>();
  @Output() stateChange = new EventEmitter<TableState>();
  @Output() metadataChange = new EventEmitter<DocumentsMetadata>();
  @Output() selectionChange = new EventEmitter<SelectionModel<Document>>();
  @Output() readonly tagsUpdated = new EventEmitter<Tag[]>();

  @HostBinding('class.shortened') get classAlternativeStyle(): boolean {
    return this.selectionModel.hasValue();
  }

  hovered = false;
  hasExtraFilters: boolean;

  documentsWithMetadata$: Observable<DocumentsWithMetadata> = this.store.select(
    searchedDocumentsSelector,
  );

  DocumentTypeEnum = DocumentTypeEnum;
  AmountTypeEnum = AmountTypeEnum;

  protected get sortValue(): Sort {
    return super.sortValue?.direction === ''
      ? this.defaultSort
      : super.sortValue;
  }

  readonly config$ = this.companyConfigService.get();
  readonly selectedDocumentIds$ = new BehaviorSubject<Set<number>>(new Set());
  readonly categoryId$ = new ReplaySubject<number[]>(1);
  displayedColumns = [
    'date',
    'name',
    'pre-tax-amount',
    'amount-including-tax',
    'label',
    'actions',
  ];
  readonly acceptedTypes = UPLOAD_DOCUMENT_CONFIG.acceptedTypes;
  readonly maximumSize = UPLOAD_DOCUMENT_CONFIG.maximumSize;
  protected readonly defaultSort: Sort = {
    active: 'created_at',
    direction: 'desc',
  };
  readonly trackByIndex = NgUtils.trackByIndex;
  private searchByEnterSubscription: Subscription;

  private readonly totalFilters = [
    new MonoValueFilter('with_total_amount', true),
    new MonoValueFilter('with_total_amount_excluding_taxes', true),
  ];
  private readonly filterKeys = [
    ...Object.values(DocumentComplexFilterKey),
    ...this.totalFilters.map(filter => filter.key),
  ];

  readonly mapToIsRowDisabled: Mapper<Document, boolean> = (
    document: Document,
  ) => {
    return (
      (this.disabledMatchedRows && document.linkedEntities?.length > 0) ||
      (this.disabledRows &&
        this.disabledRows.findIndex(doc => doc.id === document.id) !== -1)
    );
  };

  readonly mapToCategory: Mapper<
    Document,
    Category | StandardDocumentCategoryIdentifier
  > = (document: Document, selected: boolean) =>
    selected && this.selectedDocumentsCategoryIdentifier
      ? this.selectedDocumentsCategoryIdentifier
      : document.category;

  constructor(
    protected readonly route: ActivatedRoute,
    protected readonly router: Router,
    protected readonly cdr: ChangeDetectorRef,
    protected readonly store: Store,
    protected readonly dialog: MatDialog,
    protected readonly transactionsService: TransactionsService,
    protected readonly snackbarService: TiimeSnackbarService,
    protected readonly documentsService: DocumentsService,
    protected readonly overlayService: TiimeOverlayService,
    private readonly fileTransferOverlayService: FileTransferOverlayService,
    private readonly actions$: Actions,
    private readonly companyConfigService: CompanyConfigService,
    protected readonly confirmationDialogService: ConfirmationDialogService,
    protected readonly matchingDialogService: MatchingDialogService,
    private readonly documentsUiService: DocumentsUiService,
    private readonly uploadDocumentService: UploadDocumentsService,
    private readonly documentsMassActionsToolbarService: DocumentsMassActionsToolbarService,
  ) {
    super(
      router,
      route,
      cdr,
      store,
      dialog,
      transactionsService,
      snackbarService,
      documentsService,
      confirmationDialogService,
      matchingDialogService,
      overlayService,
    );
  }

  ngOnInit(): void {
    this.handleMassActions();
    this.stateChange.emit(this.currentState);
    this.loadCategory();
    this.observeDocumentUpload();
  }

  onHover(hovered: boolean): void {
    this.hovered = hovered;
  }

  paginateInTable(range: PaginationRange): void {
    this.getOptions = { ...this.getOptions, range };
    this.loadDocuments(this.getOptions);
  }

  sortInTable(sort: Sort): void {
    this.getOptions = {
      ...this.getOptions,
      sort: sort.direction ? sort : this.defaultSort,
    };
    this.loadDocuments(this.getOptions);
  }

  searchInTable(search: string): void {
    this.getOptions = {
      ...this.getOptions,
      search,
      range: this.savedPaginationRange,
    };
    this.loadDocuments(this.getOptions);
  }

  filterInTable(filters: Filter<unknown>[]): void {
    this.loadDocuments({
      range: this.savedPaginationRange,
      sort: this.getOptions.sort || this.defaultSort,
      filters: [...filters, ...this.totalFilters],
    });
  }

  onFilesDropped(files: FileList): void {
    if (files.length === 0 || this.disableFileDrop) {
      return;
    }
    this.categoryId$
      .pipe(
        take(1),
        tap(categoryId => {
          if (categoryId?.length === 1) {
            this.fileTransferOverlayService.openOverlayWithFiles(
              files,
              categoryId[0],
            );
          } else {
            this.dialog.open(DocumentAddedDialogComponent, {
              data: { files },
              width: '600px',
              height: '418px',
            });
          }
        }),
        untilDestroyed(this),
      )
      .subscribe();
  }

  backToCategories(search: string = null): void {
    void this.router.navigate(['..'], {
      relativeTo: this.route,
      queryParams: {
        search: search ?? undefined,
      },
      queryParamsHandling: 'merge',
    });
  }

  onRowClick(document: Document): void {
    if (this.matchingType) {
      this.rowClick.emit(document);
    } else {
      this.documentsUiService.openDocumentOrPreview(
        document,
        this.getOptions,
        this.route.snapshot.queryParams,
      );
    }
  }

  reload(): void {
    this.loadDocuments(this.getOptions);
  }

  protected initTableObservable(): Observable<PaginationData<Document>> {
    throw new Error(
      "initTableObservable not be called because we're using Store",
    );
  }

  protected getTableData(): Observable<PaginationData<Document>> {
    throw new Error("getTableData not be called because we're using Store");
  }

  updateLoadingState(isLoading: boolean): void {
    super.updateLoadingState(isLoading);
    this.loading.emit(isLoading);
  }

  deleteDocument(document: Document): void {
    this.store.dispatch(
      deleteDocument({
        document,
        options: this.getOptions,
      }),
    );
    if (this.paginationData.data.length === 1) {
      this.backToCategories();
    }
    this.actions$
      .pipe(
        ofType(DELETE_DOCUMENT_SUCCESS),
        take(1),
        tap(() => {
          this.snackbarService.open(
            'Le document a bien été supprimé',
            SnackbarConfig.success,
          );
          this.reload();
        }),
      )
      .subscribe();
  }

  private handleMassActions(): void {
    if (!this.withMassActions) {
      return;
    }

    this.displayedColumns.unshift('selection');
    this.selectionModel.changed
      .pipe(
        startWith(this.selectionModel),
        tap(() => this.selectionChange.emit(this.selectionModel)),
        untilDestroyed(this),
      )
      .subscribe();
    this.initMassAction();
  }

  private initMassAction(): void {
    this.documentsMassActionsToolbarService.actionSubject$
      .pipe(
        switchMap((action: ActionsType) => {
          const obs = this.documentsMassActionsToolbarService.performMassAction(
            action,
            this.selectionModel.selected,
            this.hasExtraFilters,
            this.areAllSelected,
          );
          if (!obs) {
            return EMPTY;
          }
          return obs.pipe(
            tap(() => this.loadDocuments(this.getOptions)),
            untilDestroyed(this),
          );
        }),
        untilDestroyed(this),
      )
      .subscribe();
  }

  private loadCategory(): void {
    this.categoryId$
      .pipe(
        take(1),
        withLatestFrom(this.route.queryParams),
        tap(([, params]) => {
          this.setState(TableState.contentPlaceholder);
          this.stateChange.emit(TableState.contentPlaceholder);

          const { page, search, sort, ...extraParams } = params;
          this.getOptions.range = this.pageQueryParamsToRange(page);
          this.getOptions.sort = this.sortQueryParamsToSort(sort);
          this.getOptions.search = this.searchQueryParamsToSearch(search);
          let filters = extractFiltersFromQueryParams(
            extraParams,
            this.filterKeys,
          );
          if (this.matchingType === MatchingType.expense_reports) {
            filters = [...filters, new MonoValueFilter('types', 'receipt')];
          }
          this.getOptions.filters = [...filters];

          this.loadDocuments({
            range: this.pageQueryParamsToRange('1'),
            search: this.getOptions.search,
            sort: this.defaultSort,
            filters: this.getOptions.filters,
          });
        }),
        tap(() => this.updateInitializedState(true)),
      )
      .subscribe();
  }

  private loadDocuments(options: RequiredGetOptions<'range'>): void {
    this.updateLoadingState(true);
    this.selectionModel.clear();
    this.loadDocumentsBySearch(options);
    this.saveQueryParams(
      options.range,
      options.search,
      options.sort,
      getFiltersQueryParams(options.filters, this.filterKeys),
    );
  }

  private loadDocumentsBySearch(getOptions: RequiredGetOptions<'range'>): void {
    this.getOptions = getOptions;
    this.store.dispatch(loadDocumentsForSearch({ ...this.getOptions }));

    combineLatest([
      this.documentsWithMetadata$,
      this.actions$.pipe(ofType(LOAD_DOCUMENTS_FOR_SEARCH_SUCCESS)),
    ])
      .pipe(
        filterNotNullOrUndefined(),
        tap(([documentsWithMetadata]) => {
          this.updateState(documentsWithMetadata);
          this.updateLoadingState(false);
        }),
      )
      .subscribe();
  }

  private updateState(documentsWithMetadata: DocumentsWithMetadata): void {
    const { documents, metadata } = documentsWithMetadata;
    this.hasExtraFilters =
      this.getOptions.filters &&
      this.getOptions.filters.filter(f =>
        this.totalFilters.every(filter => filter.key !== f.key),
      ).length > 0;

    this.metadataChange.emit(this.hasExtraFilters ? metadata : null);
    if (documents) {
      this.paginationData = documents;
      const newState =
        documents.data.length > 0 ? TableState.done : TableState.noResult;
      this.setState(newState);
      this.stateChange.emit(newState);
    }
    this.cdr.detectChanges();
  }

  private updateInitializedState(isInitialized: boolean): void {
    this.isInitialized = isInitialized;
    this.initialized.emit(isInitialized);
  }

  private observeDocumentUpload(): void {
    this.uploadDocumentService
      .observeQueue()
      .pipe(
        filter((doc): doc is Document => doc instanceof Document),
        tap(() => this.paginateInTable(new PaginationRange(0, 25))),
        untilDestroyed(this),
      )
      .subscribe();
  }
}
