import { createTracker, withName } from '@manakincubber/tiime-tracking';
import { tap, withLatestFrom } from 'rxjs/operators';

import { selectedCompanyIdSelector } from '@core/store';
import { InvoicingTemplate } from '@enums';

import {
  CLIENT_CREATED,
  CLIENT_EDITOR_OPENED,
  ClientCreated,
  ClientEditorOpened,
  HELP_VIDEO_PLAYED,
  HelpVideoPlayed,
  INVOICE_CLIENT_ADDED,
  INVOICE_DOWNLOADED,
  INVOICE_DRAFT_SAVED,
  INVOICE_EDITOR_OPENED,
  INVOICE_SAVE_ATTEMPT_FAILED,
  INVOICE_SAVED,
  INVOICE_SENT,
  InvoiceClientAdded,
  InvoiceDownloaded,
  InvoiceDraftSaved,
  InvoiceEditorOpened,
  InvoiceSaveAttemptFailed,
  InvoiceSaved,
  InvoiceSent,
  QUOTE_DOWNLOADED,
  QUOTE_EDITOR_OPENED,
  QUOTE_SAVED,
  QUOTE_SENT,
  QuoteDownloaded,
  QuoteEditorOpened,
  QuoteSaved,
  QuoteSent,
} from '../../events';
import {
  prefixClientId,
  prefixInvoiceId,
  prefixQuoteId,
} from '../../id-prefix';
import { optionsWithCompanyGroupExtra } from '../../utils';
import { BaseTrackingGroup } from '../base-tracking-group';

export class InvoiceTrackingGroup extends BaseTrackingGroup {
  handleClientCreated$ = createTracker(
    this.events$.pipe(
      withName(CLIENT_CREATED),
      withLatestFrom(this.store.select(selectedCompanyIdSelector)),
      tap(([{ client }, companyId]: [ClientCreated, number]) => {
        this.ampli.nouveauClientEnregistre(
          {
            identifiant_chronos_du_client: prefixClientId(client.id),
            type_client: client.isProfessional ? 'professionel' : 'particulier',
          },
          optionsWithCompanyGroupExtra(companyId),
        );
      }),
    ),
  );

  handleClientEditorOpened$ = createTracker(
    this.events$.pipe(
      withName(CLIENT_EDITOR_OPENED),
      withLatestFrom(this.store.select(selectedCompanyIdSelector)),
      tap(([event, companyId]: [ClientEditorOpened, number]) => {
        this.ampli.editeurClientOuvert(
          {
            identifiant_chronos_du_client: event.clientId
              ? prefixClientId(event.clientId)
              : null,
            etat_client_ouvert: event.clientId
              ? 'modification_client'
              : 'nouveau_client',
          },
          optionsWithCompanyGroupExtra(companyId),
        );
      }),
    ),
  );

  handleHelpVideoPlayed$ = createTracker(
    this.events$.pipe(
      withName(HELP_VIDEO_PLAYED),
      withLatestFrom(this.store.select(selectedCompanyIdSelector)),
      tap(([, companyId]: [HelpVideoPlayed, number]) => {
        this.ampli.lectureVideoBesoinAideLancee(
          {
            identifiant_video: 'edition_facture_devis',
          },
          optionsWithCompanyGroupExtra(companyId),
        );
      }),
    ),
  );

  handleInvoiceClientAdded$ = createTracker(
    this.events$.pipe(
      withName(INVOICE_CLIENT_ADDED),
      withLatestFrom(this.store.select(selectedCompanyIdSelector)),
      tap(([event, companyId]: [InvoiceClientAdded, number]) => {
        this.ampli.clientFactureAjoute(
          {
            identifiant_chronos_de_la_facture: event.invoiceId
              ? prefixInvoiceId(event.invoiceId)
              : null,
            identifiant_chronos_du_client: event.clientId
              ? prefixClientId(event.clientId)
              : null,
          },
          optionsWithCompanyGroupExtra(companyId),
        );
      }),
    ),
  );

  handleInvoiceDownloaded$ = createTracker(
    this.events$.pipe(
      withName(INVOICE_DOWNLOADED),
      withLatestFrom(this.store.select(selectedCompanyIdSelector)),
      tap(([event, companyId]: [InvoiceDownloaded, number]) => {
        this.ampli.factureTelechargee(
          {
            identifiant_chronos_de_la_facture: prefixInvoiceId(event.invoiceId),
          },
          optionsWithCompanyGroupExtra(companyId),
        );
      }),
    ),
  );

  handleInvoiceDraftSaved$ = createTracker(
    this.events$.pipe(
      withName(INVOICE_DRAFT_SAVED),
      withLatestFrom(this.store.select(selectedCompanyIdSelector)),
      tap(
        ([{ invoiceId, isNewDraft }, companyId]: [
          InvoiceDraftSaved,
          number,
        ]) => {
          this.ampli.brouillonFactureEnregistre(
            {
              identifiant_chronos_de_la_facture: prefixInvoiceId(invoiceId),
              etat_brouillon_enregistre: isNewDraft
                ? 'nouveau_brouillon'
                : 'mise_a_jour_brouillon',
            },
            optionsWithCompanyGroupExtra(companyId),
          );
        },
      ),
    ),
  );

  handleInvoiceEditorOpened$ = createTracker(
    this.events$.pipe(
      withName(INVOICE_EDITOR_OPENED),
      withLatestFrom(this.store.select(selectedCompanyIdSelector)),
      tap(([{ invoice }, companyId]: [InvoiceEditorOpened, number]) => {
        this.ampli.editeurFactureOuvert(
          {
            etat_facture_ouverte: invoice?.id
              ? 'modification_facture'
              : 'nouvelle_facture',
            identifiant_chronos_de_la_facture: invoice?.id
              ? prefixInvoiceId(invoice?.id)
              : null,
            creee_depuis_devis: !!invoice?.quotation,
            identifiant_chronos_du_devis: invoice?.quotation
              ? prefixQuoteId(invoice?.quotation?.id)
              : null,
          },
          optionsWithCompanyGroupExtra(companyId),
        );
      }),
    ),
  );

  handleInvoiceSaveAttemptFailed$ = createTracker(
    this.events$.pipe(
      withName(INVOICE_SAVE_ATTEMPT_FAILED),
      withLatestFrom(this.store.select(selectedCompanyIdSelector)),
      tap(([event, companyId]: [InvoiceSaveAttemptFailed, number]) => {
        this.ampli.creationFactureEchouee(
          {
            identifiant_chronos_de_la_facture: event.invoiceId
              ? prefixInvoiceId(event.invoiceId)
              : null,
            erreurs_creation_facture: event.invalidControls ?? ['refus_back'],
          },
          optionsWithCompanyGroupExtra(companyId),
        );
      }),
    ),
  );

  handleInvoiceSaved$ = createTracker(
    this.events$.pipe(
      withName(INVOICE_SAVED),
      withLatestFrom(this.store.select(selectedCompanyIdSelector)),
      tap(([{ invoice }, companyId]: [InvoiceSaved, number]) => {
        this.ampli.factureCreee(
          {
            identifiant_chronos_de_la_facture: prefixInvoiceId(invoice.id),
            type_facturation:
              invoice.template === InvoicingTemplate.standard
                ? 'simple'
                : 'avec_quantites',
            a_adresse_livraison: invoice.config.deliveryAddressEnabled,
            a_champ_libre: invoice.config.freeFieldEnabled,
            a_remise:
              invoice.config.discountEnabled ||
              invoice.lines.some(line => !!line.discount.amount),
            a_siret: invoice.config.sirenOrSiretEnabled,
            a_tva_intra: invoice.config.intracomVatNumberEnabled,
            langue_selectionnee: invoice.config.country.code,
            identifiant_chronos_du_devis: invoice.quotation
              ? prefixQuoteId(invoice.quotation.id)
              : null,
          },
          optionsWithCompanyGroupExtra(companyId),
        );
      }),
    ),
  );

  handleInvoiceSent$ = createTracker(
    this.events$.pipe(
      withName(INVOICE_SENT),
      withLatestFrom(this.store.select(selectedCompanyIdSelector)),
      tap(([event, companyId]: [InvoiceSent, number]) => {
        this.ampli.factureEnvoyee(
          {
            identifiant_chronos_de_la_facture: prefixInvoiceId(event.invoiceId),
          },
          optionsWithCompanyGroupExtra(companyId),
        );
      }),
    ),
  );

  handleQuoteDownloaded$ = createTracker(
    this.events$.pipe(
      withName(QUOTE_DOWNLOADED),
      withLatestFrom(this.store.select(selectedCompanyIdSelector)),
      tap(([event, companyId]: [QuoteDownloaded, number]) => {
        this.ampli.devisTelecharge(
          {
            identifiant_chronos_du_devis: prefixQuoteId(event.quoteId),
          },
          optionsWithCompanyGroupExtra(companyId),
        );
      }),
    ),
  );

  handleQuoteEditorOpened$ = createTracker(
    this.events$.pipe(
      withName(QUOTE_EDITOR_OPENED),
      withLatestFrom(this.store.select(selectedCompanyIdSelector)),
      tap(([{ quote }, companyId]: [QuoteEditorOpened, number]) => {
        this.ampli.editeurDevisOuvert(
          {
            etat_devis_ouvert: quote?.id
              ? 'modification_devis'
              : 'nouveau_devis',
            identifiant_chronos_du_devis: quote?.id
              ? prefixQuoteId(quote?.id)
              : null,
          },
          optionsWithCompanyGroupExtra(companyId),
        );
      }),
    ),
  );

  handleQuoteSaved$ = createTracker(
    this.events$.pipe(
      withName(QUOTE_SAVED),
      withLatestFrom(this.store.select(selectedCompanyIdSelector)),
      tap(([{ quote }, companyId]: [QuoteSaved, number]) => {
        this.ampli.devisCree(
          {
            identifiant_chronos_du_devis: prefixQuoteId(quote.id),
            type_facturation:
              quote.template === InvoicingTemplate.standard
                ? 'simple'
                : 'avec_quantites',
            a_adresse_livraison: quote.config.deliveryAddressEnabled,
            a_champ_libre: quote.config.freeFieldEnabled,
            a_remise:
              quote.config.discountEnabled ||
              quote.lines.some(line => !!line.discount.amount),
            a_siret: quote.config.sirenOrSiretEnabled,
            a_tva_intra: quote.config.intracomVatNumberEnabled,
            a_conditions_acceptations: quote.config.acceptanceConditionsEnabled,
            a_champ_signature: quote.config.signatureEnabled,
            langue_selectionnee: quote.config.country.code,
          },
          optionsWithCompanyGroupExtra(companyId),
        );
      }),
    ),
  );

  handleQuoteSent$ = createTracker(
    this.events$.pipe(
      withName(QUOTE_SENT),
      withLatestFrom(this.store.select(selectedCompanyIdSelector)),
      tap(([event, companyId]: [QuoteSent, number]) => {
        this.ampli.devisEnvoye(
          {
            identifiant_chronos_du_devis: prefixQuoteId(event.quoteId),
          },
          optionsWithCompanyGroupExtra(companyId),
        );
      }),
    ),
  );
}
