import {
  Component,
  ChangeDetectionStrategy,
  Input,
  ChangeDetectorRef,
  OnChanges,
} from '@angular/core';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { BehaviorSubject } from 'rxjs';
import { map, tap, finalize } from 'rxjs/operators';

import { ApiService } from '@core';

@UntilDestroy()
@Component({
  selector: 'app-logo',
  templateUrl: './logo.component.html',
  styleUrls: ['./logo.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LogoComponent implements OnChanges {
  @Input() width: number;
  @Input() height: number;
  @Input() companyId: number;
  @Input() logoId: number;
  @Input() slug: string;
  @Input() color: string;

  src: SafeUrl;
  loaderSize: number;

  readonly loading$ = new BehaviorSubject(false);

  constructor(
    private readonly domSanitizer: DomSanitizer,
    private readonly apiService: ApiService,
    private readonly cdr: ChangeDetectorRef,
  ) {}

  ngOnChanges(): void {
    this.setLoaderSize();
    if (this.logoId && this.companyId) {
      this.setLogo();
    }
  }

  private setLoaderSize(): void {
    const size = Math.min(this.height, this.width);
    this.loaderSize = Math.round(0.6 * size);
  }

  private setLogo(): void {
    this.loading$.next(true);
    this.apiService
      .getLogo(this.logoId, this.companyId)
      .pipe(
        map((blob: Blob) => {
          if (blob) {
            return this.domSanitizer.bypassSecurityTrustUrl(
              URL.createObjectURL(blob),
            );
          }
          return null;
        }),
        tap((src: SafeUrl) => (this.src = src)),
        finalize(() => {
          this.loading$.next(false);
          this.cdr.markForCheck();
        }),
        untilDestroyed(this),
      )
      .subscribe();
  }
}
