import { FormControl, FormGroup, Validators } from '@angular/forms';

import { CountryForm } from './country-form';

export type DeliveryAddressFormValue = {
  address: string | null;
  city: string | null;
  postalCode: string | null;
  country: { id: number | null };
};

export class DeliveryAddressForm extends FormGroup {
  get address(): FormControl {
    return this.get('address') as FormControl;
  }
  get addressErrorMessage(): string | null {
    if (!this.address.touched) {
      return null;
    }
    return this.address.hasError('required') ? 'Adresse requise' : null;
  }
  get city(): FormControl {
    return this.get('city') as FormControl;
  }
  get cityErrorMessage(): string | null {
    if (!this.city.touched) {
      return null;
    }
    return this.city.hasError('required') ? 'Ville requise' : null;
  }
  get country(): CountryForm {
    return this.get('country') as CountryForm;
  }
  get countryErrorMessage(): string | null {
    if (!this.country.id.touched) {
      return null;
    }
    return this.country.id.hasError('required') ? 'Pays requis' : null;
  }
  get postalCode(): FormControl {
    return this.get('postalCode') as FormControl;
  }
  get postalCodeErrorMessage(): string | null {
    if (!this.postalCode.touched) {
      return null;
    }
    return this.postalCode.hasError('required') ? 'Code postal requis' : null;
  }

  constructor() {
    super({
      address: new FormControl(null),
      postalCode: new FormControl(null),
      city: new FormControl(null),
      country: new CountryForm(),
    });
  }

  setRequired(isRequired: boolean): void {
    this.address.setValidators(isRequired ? Validators.required : null);
    this.postalCode.setValidators(isRequired ? Validators.required : null);
    this.city.setValidators(isRequired ? Validators.required : null);
    this.country.id.setValidators(isRequired ? Validators.required : null);
    this.address.updateValueAndValidity({ emitEvent: false });
    this.postalCode.updateValueAndValidity({ emitEvent: false });
    this.city.updateValueAndValidity({ emitEvent: false });
    this.country.id.updateValueAndValidity({ emitEvent: false });
    this.updateValueAndValidity({ emitEvent: false });
  }
}
