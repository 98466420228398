import {
  Component,
  ChangeDetectionStrategy,
  ViewEncapsulation,
  ElementRef,
} from '@angular/core';

import { HasAttributesBase } from '../core/index';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'a[tiime-sidenav-item]',
  templateUrl: './sidenav-item.component.html',
  styleUrls: ['./sidenav-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class TiimeSidenavItemComponent extends HasAttributesBase {
  private readonly classList: string[] = ['home-header', 'external-link'];

  get isExternalLink(): boolean {
    return this._hasHostAttributes('external-link');
  }

  constructor(elementRef: ElementRef<HTMLElement>) {
    super(elementRef);
    this.initItemClasses();
  }

  private initItemClasses(): void {
    const itemClasses = this.classList.filter((className: string) =>
      this._hasHostAttributes(className),
    );
    this.elementRef.nativeElement.classList.add(...itemClasses);
  }
}
