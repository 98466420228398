import { Injectable, inject } from '@angular/core';
import { UntilDestroy } from '@ngneat/until-destroy';
import { Observable } from 'rxjs';
import {
  AdvancedTableCacheService,
  PaginationData,
  RequiredGetOptions,
} from 'tiime-components';

import { ScheduledPayoutsService } from '@core';
import { ScheduledPayout } from '@core/models';

@UntilDestroy()
@Injectable({ providedIn: 'root' })
export class ScheduledPayoutsComponentService extends AdvancedTableCacheService<ScheduledPayout> {
  private readonly scheduledPayoutsService = inject(ScheduledPayoutsService);

  getTableData(
    options: RequiredGetOptions<'range'>,
  ): Observable<PaginationData<ScheduledPayout>> {
    return this.scheduledPayoutsService.getAll(options);
  }
}
