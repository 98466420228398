import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnInit,
} from '@angular/core';
import { MatMenuTrigger } from '@angular/material/menu';
import { NgUtils } from '@manakincubber/tiime-utils';

import { Client } from '@models';

import { TableColumnSortAndFilterDirective } from '..';
import { TableClientNameFilterForm } from './table-client-name-filter.form';

@Component({
  selector: 'app-table-client-name-filter',
  templateUrl: './table-client-name-filter.component.html',
  styleUrls: [
    '../table-column-filter-base.scss',
    '../table-column-filter-list-base.scss',
    './table-client-name-filter.component.scss',
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TableClientNameFilterComponent
  extends TableColumnSortAndFilterDirective
  implements OnInit
{
  // eslint-disable-next-line @angular-eslint/no-input-rename
  @Input('clients') set setClients(clients: Client[]) {
    if (clients) {
      this.clients = clients;
      if (this.form) {
        this.form.clients = clients;
        this.form.fromParam(this.filterValue ?? '');
      }
    }
  }
  private clients: Client[] = [];

  form: TableClientNameFilterForm;

  readonly trackByFormId = NgUtils.trackByFormId;

  constructor(menuTrigger: MatMenuTrigger) {
    super(menuTrigger);
  }

  protected initForm(): void {
    this.form = new TableClientNameFilterForm(
      this.filterKey,
      this.sortKey,
      this.sort,
      this.clients,
    );
    this.form.fromParam(this.filterValue || '');
  }
}
