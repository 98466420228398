export enum DocumentOpeningMode {
  // The document has a file attached that can be previewed (image, pdf, etc).
  Preview = 'preview',
  // The document can have a file attached but doesn't have one.
  NoFileAvailable = 'no_file_available',
  // The document has been created using Tiime document editing tools (eg an expense report).
  Module = 'module',
  // The document can be opened in the Office Suite (can also be previewed).
  OfficeSuite = 'office_suite',
}
