import { Country } from './country';

export interface InvoicingInputFieldConfigApiContract {
  id: number;
  country: Country;
  header: string;
  footer: string;
  invoice_payment_condition: string;
  invoice_mail_body: string;
  invoice_mail_subject: string;
  quotation_acceptance_conditions: string;
  quotation_mail_body: string;
  quotation_mail_subject: string;
  quotation_validity_period: string;
  dunning_balance_mail_body: string;
  dunning_balance_mail_subject: string;
  dunning_invoice_mail_body: string;
  dunning_invoice_mail_subject: string;
}

export class InvoicingInputFieldConfig {
  constructor(
    public id: number,
    public country: Country,
    public header: string,
    public footer: string,
    public invoicePaymentCondition: string,
    public invoiceMailBody: string,
    public invoiceMailSubject: string,
    public quotationAcceptanceConditions: string,
    public quotationMailBody: string,
    public quotationMailSubject: string,
    public quotationValidityPeriod: string,
    public dunningBalanceMailBody?: string,
    public dunningBalanceMailSubject?: string,
    public dunningInvoiceMailBody?: string,
    public dunningInvoiceMailSubject?: string,
  ) {}

  public static fromJson(
    json: InvoicingInputFieldConfigApiContract,
  ): InvoicingInputFieldConfig {
    return new InvoicingInputFieldConfig(
      json.id,
      json.country,
      json.header,
      json.footer,
      json.invoice_payment_condition,
      json.invoice_mail_body,
      json.invoice_mail_subject,
      json.quotation_acceptance_conditions,
      json.quotation_mail_body,
      json.quotation_mail_subject,
      json.quotation_validity_period,
      json.dunning_balance_mail_body,
      json.dunning_balance_mail_subject,
      json.dunning_invoice_mail_body,
      json.dunning_invoice_mail_subject,
    );
  }

  public static toJson(
    invoicingInputFieldConfig: InvoicingInputFieldConfig,
  ): InvoicingInputFieldConfigApiContract {
    return {
      id: invoicingInputFieldConfig.id,
      country: invoicingInputFieldConfig.country,
      header: invoicingInputFieldConfig.header,
      footer: invoicingInputFieldConfig.footer,
      invoice_payment_condition:
        invoicingInputFieldConfig.invoicePaymentCondition,
      invoice_mail_body: invoicingInputFieldConfig.invoiceMailBody,
      invoice_mail_subject: invoicingInputFieldConfig.invoiceMailSubject,
      quotation_acceptance_conditions:
        invoicingInputFieldConfig.quotationAcceptanceConditions,
      quotation_mail_body: invoicingInputFieldConfig.quotationMailBody,
      quotation_mail_subject: invoicingInputFieldConfig.quotationMailSubject,
      quotation_validity_period:
        invoicingInputFieldConfig.quotationValidityPeriod,
      dunning_balance_mail_body:
        invoicingInputFieldConfig.dunningBalanceMailBody,
      dunning_balance_mail_subject:
        invoicingInputFieldConfig.dunningBalanceMailSubject,
      dunning_invoice_mail_body:
        invoicingInputFieldConfig.dunningInvoiceMailBody,
      dunning_invoice_mail_subject:
        invoicingInputFieldConfig.dunningInvoiceMailSubject,
    };
  }
}
