export enum CardAction {
  lock = 'lock',
  unlock = 'unlock',
  unlockPin = 'unlock_pin',
  lost = 'lost',
  stolen = 'stolen',
  modifyPin = 'modify_pin',
  oppose = 'oppose',
  contactSupport = 'contact_support',
}
