<tiime-dialog-close-button></tiime-dialog-close-button>

<div mat-dialog-content>
  <img class="illustration" src="assets/illu_mail.svg" alt="Ilustration"/>

  <p class="title">Email envoyé</p>

  <p class="description">
    Un email vient de vous être envoyé.
    <br/>
    Il contient un lien vous permettant
    <br/>
    de vous connecter à l'application.
  </p>
</div>
<div mat-dialog-actions>
  <button mat-dialog-close tiime-button accent>J'ai compris</button>
</div>
