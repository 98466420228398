import { ChangeDetectionStrategy, Component } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Store, select } from '@ngrx/store';
import { map } from 'rxjs';

import { UPLOAD_DOCUMENT_CONFIG } from '@constants';
import { selectedCompanySelector } from '@core/store';
import { FileTransferOverlayService } from '@file-transfer';
import { FileHelper } from '@helpers';

import { DocumentAddedDialogComponent } from '../document/document-shared/components';
import { DocumentAddedDialogData } from '../document/document-shared/components/document-added-dialog/document-added-dialog.component';
import { DocumentUploaderDialogComponent } from '../document/document-shared/components/document-uploader-dialog/document-uploader-dialog.component';

@Component({
  selector: 'app-sidenav-document-upload',
  templateUrl: './sidenav-document-upload.component.html',
  styleUrls: ['./sidenav-document-upload.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SidenavDocumentUploadComponent {
  readonly acceptedTypes = UPLOAD_DOCUMENT_CONFIG.acceptedTypes;
  readonly maximumSize = UPLOAD_DOCUMENT_CONFIG.maximumSize;

  readonly receiptEmail$ = this.store.pipe(
    select(selectedCompanySelector),
    map(selectedCompany => selectedCompany.receiptEmail),
  );

  constructor(
    private readonly store: Store,
    private readonly dialog: MatDialog,
    private readonly fileTransferOverlayService: FileTransferOverlayService,
  ) {}

  onFileDropped(files: FileList): void {
    if (files.length === 0) {
      return;
    }

    // open upload overlay to display file errors
    if (
      !FileHelper.hasAuthorizedType(files[0]) ||
      FileHelper.hasIncorrectSize(files[0])
    ) {
      this.fileTransferOverlayService.openOverlayWithFiles(files, null);
      return;
    }

    this.dialog.open<DocumentAddedDialogComponent, DocumentAddedDialogData>(
      DocumentAddedDialogComponent,
      {
        data: { files },
        width: '600px',
        height: '418px',
      },
    );
  }

  openDocumentUploader($event: MouseEvent): void {
    $event.stopPropagation();
    this.dialog.open<DocumentUploaderDialogComponent>(
      DocumentUploaderDialogComponent,
      { width: '550px', height: '556px' },
    );
  }
}
