import { Injectable } from '@angular/core';
import { Router, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { TiimeBusinessOnboardedGuard } from './tiime-business-onboarded.guard';

@Injectable({
  providedIn: 'root',
})
export class TiimeBusinessNotOnboardedGuard {
  constructor(
    private readonly tiimeBusinessOnboarded: TiimeBusinessOnboardedGuard,
    private readonly router: Router,
  ) {}

  canMatch(): Observable<boolean | UrlTree> {
    return this.tiimeBusinessOnboarded.canLoadChild().pipe(
      map(tiimeBusinessOnboarded => {
        if (tiimeBusinessOnboarded === true) {
          return this.router.createUrlTree(['companies']);
        }

        return true;
      }),
    );
  }
}
