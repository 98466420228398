import { Injectable } from '@angular/core';
import { Router, UrlTree } from '@angular/router';
import { Store } from '@ngrx/store';
import { combineLatest, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { isFree22selector, selectedCompanySelector } from '@user-store';

@Injectable({
  providedIn: 'root',
})
export class IsNotFree22Guard {
  constructor(private router: Router, private readonly store: Store) {}

  canActivate(): Observable<boolean | UrlTree> {
    return this.isNotFree22Guard();
  }

  private isNotFree22Guard(): Observable<boolean | UrlTree> {
    return combineLatest([
      this.store.select(isFree22selector),
      this.store.select(selectedCompanySelector).pipe(map(({ id }) => id)),
    ]).pipe(
      map(
        ([isFree22, companyId]) =>
          !isFree22 ||
          this.router.createUrlTree(['companies', companyId, 'upgrade']),
      ),
    );
  }
}
