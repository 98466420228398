<tiime-dialog-close-button></tiime-dialog-close-button>

<div mat-dialog-content>
  <img src="assets/illu_document_added.svg" alt="Documents ajoutés" />
  <form class="upload-file-form" (ngSubmit)="submitForm()">
    <ng-container [ngPlural]="data.files.length">
      <ng-template ngPluralCase="=1">
        <div class="added-document-count">
          1 document prêt
          <br />
          à être ajouté
        </div>
      </ng-template>
      <ng-template ngPluralCase="other">
        <div class="added-document-count">
          {{ data.files.length }} documents prêts
          <br />
          à être ajoutés
        </div>
      </ng-template>
    </ng-container>
    <app-category-select
      [categories]="categories$ | async"
      [selectedCategory]="selectedCategory$ | async"
      (selectCategory)="selectCategory($event)"
      (createCategory)="createCategory($event)"
    ></app-category-select>

    <mat-dialog-actions class="dialog-actions">
      <button
        tiime-button
        accent
        type="submit"
        data-cy="document-added__btn-submit"
      >
        Ajouter
      </button>
    </mat-dialog-actions>
  </form>
</div>
