import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Output,
  ViewEncapsulation,
} from '@angular/core';

@Component({
  selector: 'tiime-overlay',
  templateUrl: './overlay.component.html',
  styleUrls: ['./overlay.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class TiimeOverlayComponent {
  @Output()
  readonly closeOverlay: EventEmitter<void> = new EventEmitter<void>();

  close(): void {
    this.closeOverlay.emit();
  }
}
