<div class="container">
  <div class="background-container">
    <iframe
      src="https://www.tiime.fr/iframe-login"
      loading="eager"
      title="login-integration"
    ></iframe>
  </div>
  <div class="floating-container">
    <ng-content></ng-content>
  </div>
</div>
