import { Pipe, PipeTransform } from '@angular/core';
import { Filter } from 'tiime-components';

import { InvoiceComplexFilterKey } from '@core/enum/invoice';
import { InvoiceFiltersHelper } from '@core/helpers';
import { Client } from '@core/models';

@Pipe({
  name: 'invoiceComplexFilters',
})
export class InvoiceComplexFiltersPipe implements PipeTransform {
  transform(filters: Filter<unknown>[], clients: Client[]): Filter<unknown>[] {
    return InvoiceFiltersHelper.convertFiltersClientToClientName(
      filters,
      clients,
    ).filter(filter =>
      Object.values<string>(InvoiceComplexFilterKey).includes(filter.key),
    );
  }
}
