import { Component, ChangeDetectionStrategy, Input } from '@angular/core';

import { Company } from '@models';

@Component({
  selector: 'app-company-acronym-logo',
  templateUrl: './company-acronym-logo.component.html',
  styleUrls: ['./company-acronym-logo.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CompanyAcronymLogoComponent {
  @Input() company: Company;
  @Input() size: number;
}
