import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import {
  TiimeButtonModule,
  TiimeDialogModule,
  TiimeIconModule,
} from 'tiime-components';

import { CopyToClipboardDirective } from '@directives';

import { AnswerBusinessAccessRequestDialogComponent } from './answer-business-access-request-dialog.component';

@NgModule({
  declarations: [AnswerBusinessAccessRequestDialogComponent],
  imports: [
    CommonModule,
    TiimeDialogModule,
    TiimeButtonModule,
    TiimeIconModule,
    CopyToClipboardDirective,
  ],
  exports: [AnswerBusinessAccessRequestDialogComponent],
})
export class AnswerBusinessAccessRequestDialogModule {}
