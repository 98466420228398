import { HttpClient, HttpParams } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import {
  GetOptions,
  PaginationData,
  RequiredGetOptions,
} from 'tiime-components';

import { TemporaryEncoder } from '@core/temporary-encoder';
import { ApiAlertError } from '@decorators/api-alert-error';
import { HttpHelper } from '@helpers';
import { SddPayin, SddPayinApiContract } from '@models/wallet/payins';

@Injectable({
  providedIn: 'root',
})
export class PayinsService {
  private readonly resource = 'api/v1/wallet/companies/{companyId}/payins';
  private readonly http = inject(HttpClient);

  private readonly _reloadPayinsList$ = new Subject<void>();

  get reloadPayinsList$(): Observable<void> {
    return this._reloadPayinsList$.asObservable();
  }

  reloadPayinsList(): void {
    this._reloadPayinsList$.next();
  }

  getOnboardingPath(): Observable<string> {
    return this.http.get(`${this.resource}/onboarding_path`, {
      responseType: 'text',
    });
  }

  @ApiAlertError()
  getPayins(
    getOptions: RequiredGetOptions<'range'>,
  ): Observable<PaginationData<SddPayin>> {
    const partialOptions = new GetOptions(getOptions).toHttpGetOptions();
    const options = {
      params: new HttpParams({
        fromObject: partialOptions.params,
        encoder: new TemporaryEncoder(),
      }),
      headers: partialOptions.headers,
    };

    return this.http
      .get('api/v1/wallet/companies/{companyId}/sdd_payin_schedules', {
        ...options,
        observe: 'response',
      })
      .pipe(
        HttpHelper.mapToPaginationData(
          getOptions.range,
          (payinsJson: SddPayinApiContract) => SddPayin.fromJson(payinsJson),
        ),
      );
  }

  @ApiAlertError()
  cancelScheduledPayin(scheduledSddPayinId: number): Observable<void> {
    return this.http.delete<void>(
      `api/v1/wallet/companies/{companyId}/sdd_payin_schedules/${scheduledSddPayinId}`,
    );
  }
}
