<tiime-twocolors-icon
  *ngIf="!oneColor; else oneColorTemplate"
  [icon]="operationType | map : mapToOperationTypeLogo"
  [size]="size"
></tiime-twocolors-icon>
<ng-template #oneColorTemplate>
  <ng-container
    *ngIf="isSalaryOperation; then salaryOperation; else oneColor"
  ></ng-container>

  <ng-template #salaryOperation>
    <mat-icon
      class="one-color-icon"
      inline="true"
      tiimeTooltip
      [svgIcon]="'t-ic_ot_' + (operationType | map : mapToOperationTypeSalaryLogo)"
      [content]="tooltip"
      [tooltipDisabled]="!tooltipEnabled"
      [ngStyle]="operationType | map : mapToOneColorIconStyle"
    ></mat-icon>
  </ng-template>

  <ng-template #oneColor>
    <mat-icon
      class="one-color-icon"
      inline="true"
      tiimeTooltip
      [fontIcon]="operationType | map : mapToOperationTypeOneColorLogo"
      [content]="tooltip"
      [tooltipDisabled]="!tooltipEnabled"
      [ngStyle]="operationType | map : mapToOneColorIconStyle"
    ></mat-icon>
  </ng-template>
</ng-template>

<ng-template #tooltip>
  {{ tooltipContent }}
</ng-template>
