import { AsyncPipe } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnInit,
  ViewEncapsulation,
} from '@angular/core';
import { fromEvent, Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';

@Component({
  standalone: true,
  selector: 'app-remaining-char-textarea',
  templateUrl: './remaining-char-textarea.component.html',
  styleUrls: ['./remaining-char-textarea.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [AsyncPipe],
})
export class RemainingCharTextareaComponent implements OnInit {
  @Input() textarea: HTMLTextAreaElement;

  remainingCharText$: Observable<string>;

  private maxLength: number;

  ngOnInit(): void {
    this.maxLength = this.textarea.maxLength;

    this.remainingCharText$ = fromEvent(this.textarea, 'input').pipe(
      startWith(null),
      map(() => {
        const remainingChar = this.maxLength - this.textarea.value.length;
        if (remainingChar < 0) {
          return `${-remainingChar} caractères excédents`;
        }
        return `${remainingChar} ${
          remainingChar > 1 ? 'caractères restants' : 'caractère restant'
        }`;
      }),
    );
  }
}
