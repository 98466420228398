import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  Inject,
  ViewChild,
} from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { delay, fromEvent, map } from 'rxjs';

import { VideoDialogData } from './video-dialog-data';

@UntilDestroy()
@Component({
  selector: 'tiime-video-dialog',
  templateUrl: './video-dialog.component.html',
  styleUrls: ['video-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class VideoDialogComponent implements AfterViewInit {
  @ViewChild('video') private video: ElementRef<HTMLMediaElement>;

  constructor(@Inject(MAT_DIALOG_DATA) readonly data: VideoDialogData) {}

  ngAfterViewInit(): void {
    fromEvent(this.video.nativeElement, 'canplay')
      .pipe(
        delay(500),
        map(() => this.video.nativeElement.play()),
        untilDestroyed(this),
      )
      .subscribe();
  }
}
