import { NgModule } from '@angular/core';
import { MatIconModule, MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';

import { Icon } from './icon';
import { ICONS_TWO_TONE } from './icons-two-tone';

/** Prefix for the recently added / fixed icons */
export const TIIME_ICON_PREFIX = 't-ic_';

/** Every icon should be in the `assets/icons` folder */
const ICONS_FOLDER = 'assets/icons';

interface IconGroup {
  /** The subfolder of `assets/icons` in which the icons are located */
  folder?: string;
  /**
   * A prefix to identify different icons that can be similar.
   * e.g. there are (at least) 2 different bank icons, one with prefix `ot`, for
   * operation type (`t-ic_ot_bank`) and one without (`t-ic_bank`)
   */
  prefix?: string;
  /**
   * A list of icon names, corresponding to the files names without svg extensions
   * e.g. ['bank', 'card', 'cash', 'check', 'transfer-out']
   */
  iconNames: string[];
}

@NgModule({
  exports: [MatIconModule],
})
export class IconsModule {
  constructor(
    public matIconRegistry: MatIconRegistry,
    private domSanitizer: DomSanitizer,
  ) {
    const actionsIcons: Icon[] = [
      { name: 'icon-button-close', path: 'actions/icon-button-close.svg' },
      { name: 'icon-copy', path: 'actions/icon-copy.svg' },
      {
        name: 'icon-copy-deprecated',
        path: 'actions/icon-copy-deprecated.svg',
      },
      { name: 'icon-close', path: 'actions/icon-close.svg' },
      { name: 'icon-close-slim', path: 'actions/icon-close-slim.svg' },
      {
        name: 'icon-close-bold-circled',
        path: 'actions/icon-close-bold-circled.svg',
      },
      { name: 'icon-delete', path: 'actions/icon-delete.svg' },
      { name: 'icon-dropdown', path: 'actions/icon-dropdown.svg' },
      { name: 'icon-plus', path: 'actions/icon-plus.svg' },
      { name: 'icon-send-mail', path: 'actions/icon-send-mail.svg' },
      { name: 'icon-search-zoom-in', path: 'actions/icon-search-zoom-in.svg' },
      { name: 'icon-resize', path: 'actions/icon-resize.svg' },
      { name: 'icon-break-link', path: 'actions/icon-break-link.svg' },
      {
        name: 'icon-break-link-deprecated',
        path: 'actions/icon-break-link-deprecated.svg',
      },
      { name: 'icon-add-filled', path: 'actions/icon-add-filled.svg' },
      { name: 'icon-plus-filled', path: 'icon_plus.svg' },
      {
        name: 'icon-add-filled-active',
        path: 'actions/icon-add-filled-active.svg',
      },
      { name: 'icon-basket-check', path: 'actions/icon-basket-check.svg' },
      { name: 'icon-reset', path: 'actions/icon-reset.svg' },
      { name: 'icon-link-ok', path: 'actions/icon-link-ok.svg' },
      { name: 'icon-pen', path: 'actions/icon-pen.svg' },
      { name: 'icon-pen-deprecated', path: 'actions/icon-pen-deprecated.svg' },
      { name: 'icon-download', path: 'actions/icon-download.svg' },
      {
        name: 'icon-download-deprecated',
        path: 'actions/icon-download-deprecated.svg',
      },
      { name: 'icon-open-window', path: 'actions/icon-open-window.svg' },
      { name: 'icon-comment', path: 'actions/icon-comment.svg' },
      { name: 'icon-tag', path: 'actions/icon-tag.svg' },
      {
        name: 'icon-tag-and-comment',
        path: 'actions/icon-tag-and-comment.svg',
      },
      { name: 'icon-archive', path: 'actions/icon-archive.svg' },
      { name: 'icon-unarchive', path: 'actions/icon-unarchive.svg' },
      { name: 'icon-link', path: 'actions/icon-link.svg' },
      {
        name: 'icon-link-deprecated',
        path: 'actions/icon-link-deprecated.svg',
      },
      { name: 'icon-more-actions', path: 'actions/icon-more-actions.svg' },
      { name: 'icon-export', path: 'actions/icon_export_folder.svg' },
      { name: 'icon-mail', path: 'actions/icon_mail.svg' },
      { name: 'icon-upload', path: 'actions/icon-upload.svg' },
      { name: 'icon-upload-cloud', path: 'actions/icon-upload-cloud.svg' },
      { name: 'icon-upload-small', path: 'actions/icon-upload-small.svg' },
      { name: 'icon-synchro', path: 'actions/icon-synchro.svg' },
      { name: 'icon-arrow-right', path: 'actions/icon-arrow-right.svg' },
      { name: 'icon-arrow-left', path: 'actions/icon-arrow-left.svg' },
      { name: 'icon-receipt-needed', path: 'actions/icon-receipt-needed.svg' },
      { name: 'icon-duplicate', path: 'actions/icon-duplicate.svg' },
      { name: 'icon-eye', path: 'actions/icon-eye.svg' },
      { name: 'icon-send', path: 'actions/icon-send.svg' },
      { name: 'icon-move', path: 'actions/icon-move.svg' },
      { name: 'icon-cross', path: 'actions/icon-cross.svg' },
      { name: 'icon-favorite', path: 'actions/icon-favorite.svg' },
      {
        name: 'icon-favorite-border',
        path: 'actions/icon-favorite-border.svg',
      },
    ];

    const oldMiscIcons: Icon[] = [
      { name: 'icon-stop', path: 'misc/icon-stop.svg' },
      { name: 'icon-clock', path: 'misc/icon-clock.svg' },
      { name: 'icon-clock-future', path: 'misc/icon-clock-future.svg' },
      { name: 'icon-information', path: 'misc/icon-information.svg' },
      { name: 'icon-last', path: 'misc/icon-last.svg' },
      { name: 'icon-ventilated', path: 'misc/icon-ventilated.svg' },
      { name: 'icon-ventilated-line', path: 'misc/icon-ventilated-line.svg' },
      { name: 'icon-settings', path: 'misc/icon-settings.svg' },
      { name: 'icon-stripe-logo', path: 'misc/icon-stripe-logo.svg' },
      { name: 'icon-bank-transfer', path: 'illu_bank_transfer.svg' },
      { name: 'icon-go-cardless-logo', path: 'misc/icon-go-cardless-logo.svg' },
      { name: 'icon-expense-report', path: 'misc/icon-expense-report.svg' },
      { name: 'icon-transfer', path: 'misc/icon-transfer.svg' },
      { name: 'icon-cb', path: 'misc/icon-cb.svg' },
      { name: 'icon-invoice', path: 'misc/icon-invoice.svg' },
      { name: 'icon-invoice-bicolor', path: 'misc/icon-invoice-bicolor.svg' },
      { name: 'icon-z-ticket', path: 'misc/icon-ticket-z.svg' },
      { name: 'icon-cash-tracking', path: 'misc/icon_cash_tracking.svg' },
      { name: 'icon-multiple-files', path: 'misc/icon-multiple-files.svg' },
      { name: 'icon-caret-down', path: 'misc/icon-caret-down.svg' },
      { name: 'icon-green-check', path: 'misc/radio-check.svg' },
      { name: 'icon-check', path: 'misc/icon-check.svg' },
      { name: 'icon-mileage-car', path: 'misc/icon-mileage-car.svg' },
      { name: 'icon-attachments', path: 'icon-attachments.svg' },
      { name: 'icon-cloud-arrows', path: 'misc/icon-cloud-arrows.svg' },
      { name: 'icon-profile', path: 'misc/icon-profile.svg' },
      { name: 'icon-bank', path: 'misc/icon_bank.svg' },
      { name: 'icon-password', path: 'misc/icon_password.svg' },
      { name: 'icon-company', path: 'misc/icon-company.svg' },
      { name: 'icon-settings-invoice', path: 'misc/icon-settings-invoice.svg' },
      { name: 'icon-flag', path: 'misc/icon_flag.svg' },
      { name: 'icon-address', path: 'misc/icon_address.svg' },
      { name: 'icon-lock', path: 'misc/icon-lock.svg' },
      { name: 'icon-lock-info', path: 'misc/icon-lock-info.svg' },
      { name: 'icon-registry', path: 'misc/icon-registry.svg' },
      { name: 'icon-mail-outline', path: 'misc/icon-mail-outline.svg' },
      {
        name: 'icon-mail-read-outline',
        path: 'misc/icon-mail-read-outline.svg',
      },
      { name: 'icon-client', path: 'misc/icon-client.svg' },
      { name: 'icon-archive-lock', path: 'misc/icon-archive-lock.svg' },
      { name: 'icon-lightning', path: 'misc/icon-lightning.svg' },
      { name: 'icon-at', path: 'icon-at.svg' },
      { name: 'icon-phone', path: 'icon-phone.svg' },
      { name: 'icon-stop', path: 'misc/icon-stop.svg' },
      { name: 'icon-cb', path: 'misc/icon-cb.svg' },

      { name: 'icon-warning', path: 'misc/icon-warning.svg' },
      {
        name: 'icon-action-check-round',
        path: 'misc/icon-action-check-round.svg',
      },

      { name: 'icon-scissors', path: 'misc/icon-scissors.svg' },
      { name: 'icon-users', path: 'misc/icon-users.svg' },
      { name: 'icon-tiime-color', path: 'misc/icon-tiime-color.svg' },
      {
        name: 'icon-tiime-invoice-color',
        path: 'misc/icon-tiime-invoice-color.svg',
      },
      {
        name: 'icon-tiime-business-color',
        path: 'misc/icon-tiime-business-color.svg',
      },
      { name: 'icon-user-color', path: 'misc/icon-user-color.svg' },
      { name: 'icon-paper-plane', path: 'misc/icon-paper-plane.svg' },
      { name: 'icon-euro-sign', path: 'misc/icon-euro-sign.svg' },
    ];

    const infoIcons: Icon[] = [
      { name: 'icon-warning-filled', path: 'info/icon-warning-filled.svg' },
      { name: 'icon-info', path: 'info/icon-info.svg' },
      { name: 'icon-info-filled', path: 'icon-info-filled.svg' },
      { name: 'icon-help', path: 'info/icon_help.svg' },
      { name: 'icon-info-home', path: 'info/icon-info-home.svg' },
      {
        name: 'icon-secure-connection',
        path: 'info/icon-secure-connection.svg',
      },
      {
        name: 'icon-security-shield.svg',
        path: 'info/icon-security-shield.svg',
      },
    ];

    [...actionsIcons, ...oldMiscIcons, ...infoIcons].forEach(icon => {
      matIconRegistry.addSvgIcon(
        icon.name,
        domSanitizer.bypassSecurityTrustResourceUrl(`assets/${icon.path}`),
      );
    });

    ICONS_TWO_TONE.forEach(icon => {
      matIconRegistry.addSvgIconInNamespace(
        'two-tone',
        icon.name,
        domSanitizer.bypassSecurityTrustResourceUrl(`assets/${icon.path}`),
      );
    });

    // New icons

    // Action : https://www.figma.com/design/6MDpm9PPmddDuBeBrF9Nhz/Branding---Tiime?node-id=3107-8291&m=dev
    const actionIcons: IconGroup = {
      folder: 'action',
      iconNames: ['close-bold', 'restore-line'],
    };

    // Editor : https://www.figma.com/design/6MDpm9PPmddDuBeBrF9Nhz/Branding---Tiime?node-id=3107-9515&m=dev
    const editorIcons: IconGroup = {
      folder: 'editor',
      iconNames: ['save'],
    };

    // Form : https://www.figma.com/file/6MDpm9PPmddDuBeBrF9Nhz/Branding---Tiime?type=design&node-id=3107-9801&mode=dev
    const formIcons: IconGroup = {
      folder: 'form',
      iconNames: ['calendar-month'],
    };

    // Navigation : https://www.figma.com/file/6MDpm9PPmddDuBeBrF9Nhz/Branding---Tiime?type=design&node-id=3107-9671&mode=dev
    const navigationIcons: IconGroup = {
      folder: 'navigation',
      iconNames: ['arrow-bottom', 'arrow-drop-down-line', 'refresh'],
    };

    const miscIcons = {
      folder: 'misc',
      iconNames: [
        'calendar',
        'chevron',
        'long-arrow',
        'forbidden',
        'label',
        'shield',
        'bolt-filled',
        'cross-filled',
        'tiimi-paw',
        'document-in-company',
        'no-document',
        'personal-fee',
      ],
    };
    const operationTypeIcons = {
      prefix: 'ot',
      folder: 'operation-type',
      iconNames: [
        'bank',
        'card',
        'cash',
        'check',
        'transfer-out',
        'salary-card',
        'salary-cash',
      ],
    };

    const userIcons = {
      folder: 'user',
      iconNames: ['super-admin', 'user-round-grey'],
    };

    const tiimeIcons = {
      folder: 'tiime',
      iconNames: ['tiime'],
    };

    const tiimeBusinessIcons = {
      prefix: 'tb',
      folder: 'tiime-business',
      iconNames: ['payin', 'mandate'],
    };

    this.addPrefixedSvgIcons(
      actionIcons,
      editorIcons,
      formIcons,
      navigationIcons,
      miscIcons,
      operationTypeIcons,
      userIcons,
      tiimeIcons,
      tiimeBusinessIcons,
    );
  }

  private addPrefixedSvgIcons(...iconGroups: IconGroup[]): void {
    iconGroups.forEach(iconGroup => {
      const folder = `${ICONS_FOLDER}/${iconGroup.folder.replace(
        /^\/+|\/+$/g,
        '',
      )}`;

      iconGroup.iconNames.forEach(icon => {
        const groupPrefix = iconGroup.prefix ? `${iconGroup.prefix}_` : '';

        this.matIconRegistry.addSvgIcon(
          `${TIIME_ICON_PREFIX}${groupPrefix}${icon}`,
          this.domSanitizer.bypassSecurityTrustResourceUrl(
            `${folder}/${icon}.svg`,
          ),
        );
      });
    });
  }
}
