import {
  BIBankConnection,
  PostBIConnectionApiContract,
} from './bi-bank-connection';
import {
  PostQardConnectionApiContract,
  QardBankConnection,
} from './qard-bank-connection';
import {
  PostQontoConnectionApiContract,
  QontoBankConnection,
} from './qonto-bank-connection';

export interface ProvidedBankConnectionApiContract {
  bank_uuid: string;
  provider_data:
    | Partial<PostBIConnectionApiContract>
    | PostQontoConnectionApiContract
    | PostQardConnectionApiContract;
}

export class ProvidedBankConnection {
  constructor(
    public bankUUID: string | undefined,
    public providerData:
      | BIBankConnection
      | QontoBankConnection
      | QardBankConnection,
  ) {}

  public static toJson(
    providedBankConnection: ProvidedBankConnection,
  ): Partial<ProvidedBankConnectionApiContract> {
    let providerData;
    if (providedBankConnection.providerData instanceof BIBankConnection) {
      providerData = BIBankConnection.toChronosConnectionJson(
        providedBankConnection.providerData,
      );
    } else if (
      providedBankConnection.providerData instanceof QardBankConnection
    ) {
      providerData = QardBankConnection.toChronosConnectionJson(
        providedBankConnection.providerData,
      );
    } else {
      providerData = QontoBankConnection.toChronosConnectionJson(
        providedBankConnection.providerData,
      );
    }
    return {
      bank_uuid: providedBankConnection.bankUUID,
      provider_data: providerData,
    };
  }
}
