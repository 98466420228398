import { HttpParams } from '@angular/common/http';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { Store } from '@ngrx/store';
import { DeviceDetectorService, DeviceInfo } from 'ngx-device-detector';
import { combineLatest, Observable } from 'rxjs';
import { map, take } from 'rxjs/operators';

import { APP_CODE, APP_PLATFORM } from '@constants';
import { businessUnitSelector } from '@core/store';
import { BusinessUnit, User } from '@models';
import { AppConfigService } from '@services/app-config.service';
import { userSelector } from '@user-store';

import packageInfo from '../../../../../../../../package.json';

interface CareFormFields {
  firstname?: string;
  lastname?: string;
  email?: string;
  company_id?: string;
  device?: string;
  device_version?: string;
  app_used?: string;
  app_version?: string;
  app_platform?: string;
  company_bu?: string;
  partner_id?: number;
  has_wallet_access?: boolean;
}

@Component({
  selector: 'app-care-contact-dialog',
  templateUrl: './care-contact-dialog.component.html',
  styleUrls: ['./care-contact-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CareContactDialogComponent {
  formUrl$: Observable<SafeResourceUrl> = combineLatest([
    this.store.select(userSelector),
    this.store.select(businessUnitSelector),
  ]).pipe(
    take(1),
    map(([user, businessUnit]: [User, BusinessUnit]) =>
      this.initCareFormFields(user, businessUnit),
    ),
    map((careFormFields: CareFormFields) => this.buildFormUrl(careFormFields)),
  );

  constructor(
    private readonly deviceService: DeviceDetectorService,
    private readonly sanitizer: DomSanitizer,
    private readonly store: Store,
    private readonly appConfigService: AppConfigService,
  ) {}

  private buildFormUrl(careFormFields: CareFormFields): SafeResourceUrl {
    const params = new HttpParams({
      fromObject: { ...careFormFields },
    });
    const url = `${
      this.appConfigService.appConfig.CARE_FORM_URL
    }?${params.toString()}`;
    return this.sanitizer.bypassSecurityTrustResourceUrl(url);
  }

  private initCareFormFields(
    user: User,
    businessUnit: BusinessUnit,
  ): CareFormFields {
    const deviceInfo: DeviceInfo = this.deviceService.getDeviceInfo();
    return {
      device: `${deviceInfo.os} ${deviceInfo.os_version}`,
      device_version: `${deviceInfo.browser} ${deviceInfo.browser_version}`,
      app_platform: APP_PLATFORM,
      app_used: APP_CODE,
      app_version: packageInfo.version,
      lastname: user.lastName,
      firstname: user.firstName,
      email: user.email,
      company_id: user.activeCompanyId.toString(),
      company_bu: businessUnit?.brand || '',
      partner_id: businessUnit?.id || null,
      has_wallet_access: user.hasWalletAccess || false,
    };
  }
}
