import { Injectable } from '@angular/core';
import { Router, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { LegalService } from '@services/legal.service';

@Injectable({
  providedIn: 'root',
})
export class HasUserSignedTosAndGdprGuard {
  constructor(
    private readonly legalService: LegalService,
    private readonly router: Router,
  ) {}

  canMatch(): Observable<true | UrlTree> {
    return this.hasUserSignedTosAndGdpr(
      this.router.getCurrentNavigation().initialUrl,
    );
  }

  /**
   * Checks whether the user has signed the legal documents, if not redirects to the legal validation page.
   * @param redirect The url that the user has tried to reach. This will be added to the legal validation url query params.
   * @returns Either true if the user has signed the legal documents or the url to the legal validation page
   */
  private hasUserSignedTosAndGdpr(
    redirect?: UrlTree,
  ): Observable<true | UrlTree> {
    return this.legalService.hasUserSignedTosAndGdpr().pipe(
      map(hasUserSigned => {
        if (hasUserSigned) {
          return true;
        }
        return this.router.createUrlTree(['legal-validation'], {
          queryParams: { redirect },
        });
      }),
    );
  }
}
