import { InvoicingTemplate } from '@enums';
import {
  Client,
  DeliveryAddress,
  Discount,
  InvoicingDocumentSeller,
  Line,
  Logo,
  Tag,
  TextLine,
  TotalsPerVatType,
} from '@models';

import { AbstractInvoicingDocumentBase } from './abstract-invoicing-document-base';

export abstract class AbstractInvoicingDocument extends AbstractInvoicingDocumentBase {
  protected constructor(
    id?: number,
    title?: string,
    invoicingDocumentNumber?: number,
    emissionDate?: string,
    totalExcludingTaxes?: number,
    totalIncludingTaxes?: number,
    lines?: Line[],
    textLines?: TextLine[],
    color?: string,
    template?: InvoicingTemplate,
    comment?: string,
    tags?: Tag[],
    unitsEnabled?: boolean,
    public logo?: Logo,
    public seller?: InvoicingDocumentSeller,
    public client?: Client,
    public header?: string,
    public footer?: string,
    public freeField?: string,
    public totalsPerVatType?: TotalsPerVatType,
    public pdfFilename?: string,
    public deliveryAddress?: DeliveryAddress,
    public discount?: Discount,
    public nonApplicableVatReason?: string,
    public nonApplicableVatReasonEnabled?: boolean,
  ) {
    super(
      id,
      title,
      invoicingDocumentNumber,
      emissionDate,
      totalExcludingTaxes,
      totalIncludingTaxes,
      lines,
      textLines,
      color,
      template,
      comment,
      tags,
      unitsEnabled,
    );
  }
}
