import { HttpErrorResponse, HttpStatusCode } from '@angular/common/http';
import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { BehaviorSubject, throwError } from 'rxjs';
import { catchError, finalize, take, tap } from 'rxjs/operators';

import { AddBeneficiaryForm } from '@forms';
import { Beneficiary } from '@models';
import { BeneficiariesService } from '@services/wallet';

export interface AddBeneficiaryDialogData {
  name?: string;
  iban?: string;
}

@Component({
  templateUrl: './add-beneficiary-dialog.component.html',
  styleUrls: ['./add-beneficiary-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AddBeneficiaryDialogComponent {
  readonly form = new AddBeneficiaryForm(
    this.data.name,
    this.data.iban?.replace(/\s/g, ''),
  );
  readonly ibanErrorMessage$ = new BehaviorSubject<string | undefined>(
    undefined,
  );
  readonly loading$ = new BehaviorSubject(false);

  constructor(
    @Inject(MAT_DIALOG_DATA) readonly data: AddBeneficiaryDialogData,
    private readonly dialogRef: MatDialogRef<
      AddBeneficiaryDialogComponent,
      Beneficiary | null
    >,
    private readonly beneficiaryService: BeneficiariesService,
  ) {}

  addBeneficiary(): void {
    this.loading$.next(true);
    this.dialogRef.disableClose = true;

    const beneficiary = this.form.toCreateBeneficiaryBody();
    this.beneficiaryService
      .create(beneficiary)
      .pipe(
        finalize(() => {
          this.loading$.next(false);
          this.dialogRef.disableClose = false;
        }),
        catchError((err: HttpErrorResponse) => {
          if (err.status === HttpStatusCode.UnprocessableEntity) {
            this.ibanErrorMessage$.next(`Cet IBAN n'est pas valide`);
          } else if (err.status === HttpStatusCode.Conflict) {
            this.ibanErrorMessage$.next(
              (err.error as { error_description: string }).error_description,
            );
          }
          this.observeIbanChanges();
          return throwError(() => err);
        }),
        tap(newBeneficiary => {
          this.dialogRef.close(newBeneficiary);
        }),
      )
      .subscribe();
  }

  private observeIbanChanges(): void {
    this.form.iban.valueChanges
      .pipe(
        take(1),
        tap(() => this.ibanErrorMessage$.next(undefined)),
      )
      .subscribe();
  }
}
