import { Directive } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Subscription, filter, tap } from 'rxjs';

import { ReloadAfterCompanyChanged, ReloadService } from '@core/reload.service';
import { AclService } from '@core/services/acl.service';
import { UserPermissionNameEnum } from '@enums/users';

@UntilDestroy()
@Directive({
  standalone: true,
})
export abstract class AbstractHasAccessDirective
  implements ReloadAfterCompanyChanged
{
  companyChangedSub: Subscription = this.reloadService.companyChanged
    .pipe(
      filter(() => !!this.permissionsNeeded),
      tap(() => this.updateView()),
      untilDestroyed(this),
    )
    .subscribe();

  permissionsNeeded: UserPermissionNameEnum[];

  constructor(
    protected readonly aclService: AclService,
    protected readonly reloadService: ReloadService,
  ) {}

  protected setPermissions(
    val: UserPermissionNameEnum | UserPermissionNameEnum[],
  ): void {
    this.permissionsNeeded = Array.isArray(val) ? val : [val];
    this.updateView();
  }

  protected updateView(): void {
    this.aclService
      .userHasPermission(this.permissionsNeeded)
      .pipe(
        tap(hasAccess => this.refreshContainer(hasAccess)),
        untilDestroyed(this),
      )
      .subscribe();
  }

  protected abstract refreshContainer(hasAccess: boolean): void;
}
