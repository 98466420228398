import { TrackingEvent } from '@manakincubber/tiime-tracking';

export const VAT_VALIDATION_STARTED = 'VAT Validation Started';

export class VatValidationStarted implements TrackingEvent {
  readonly name = VAT_VALIDATION_STARTED;

  constructor(
    readonly validationTvaAcces: 'deeplink_sms' | 'email_link' | 'direct',
  ) {}
}
