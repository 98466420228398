import { FormArray, FormControl } from '@angular/forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { tap } from 'rxjs/operators';
import { OrFilter, ResetFilter } from 'tiime-components';

import { BankAccount } from '@models';
import { TableColumnFilterForm } from '@shared';

@UntilDestroy()
export class TransactionsBankAccountFilterForm extends TableColumnFilterForm {
  get all(): FormControl {
    return this.get('all') as FormControl;
  }

  get filters(): FormArray {
    return this.get('filters') as FormArray;
  }

  constructor(filterKey: string, public bankAccounts: BankAccount[] = []) {
    super(
      {
        all: new FormControl(null),
        filters: new FormArray([]),
      },
      filterKey,
    );
    this.initAllCheckbox();
  }

  resetFilters(): void {
    this.patchValue({
      all: false,
    });
  }

  toOutput(): OrFilter<number> | ResetFilter {
    const selectedBankAccounts = this.filters.controls
      .map((control, i) => (control.value ? this.bankAccounts[i].id : null))
      .filter(v => v !== null);
    return selectedBankAccounts.length
      ? new OrFilter(this.filterKey, selectedBankAccounts)
      : ResetFilter.forKey(this.filterKey);
  }

  fromParam(bankAccountFilterValue: string): void {
    const bankAccountFilterArray = bankAccountFilterValue.split('|');
    const allSelected = bankAccountFilterArray[0] === '';
    const patch: FormControl[] = this.bankAccounts.map(bankAccount => {
      return new FormControl(
        allSelected || bankAccountFilterArray.includes(`${bankAccount.id}`),
      );
    });

    this.setControl('filters', this.setFormArray(patch));
    this.all.setValue(
      allSelected || bankAccountFilterArray.length === this.bankAccounts.length,
      {
        emitEvent: false, // Otherwise observable on 'select all' checkbox will disable all filters
      },
    );
  }

  private setFormArray(controls: FormControl[]): FormArray {
    const formArray = new FormArray(controls);
    formArray.valueChanges
      .pipe(
        tap(() => {
          this.all.setValue(
            this.filters.controls.every(control => control.value === true),
            { emitEvent: false },
          );
        }),
        untilDestroyed(this),
      )
      .subscribe();
    return formArray;
  }

  private initAllCheckbox(): void {
    this.all.valueChanges
      .pipe(
        tap((value: boolean) => {
          Object.keys(this.filters.controls).forEach(key => {
            this.filters.get(key).patchValue(value, { emitEvent: false });
          });
        }),
        untilDestroyed(this),
      )
      .subscribe();
  }
}
