import { OperationType } from '@enums';

import { BankAccount, BankAccountApiContract } from './bank-account';
import { BankTransaction } from './bank-transaction';

export interface LinkedBankTransactionApiContract {
  id: number;
  transaction_date: string;
  operation_type: OperationType;
  wording: string;
  short_bank_name: string;
  amount: number;
  currency?: string;
  realization_date: string;
  bank_account: Pick<BankAccountApiContract, 'short_bank_name'>;
}

export class LinkedBankTransaction {
  constructor(
    public id?: number,
    public transactionDate?: string,
    public operationType?: OperationType,
    public wording?: string,
    public shortBankName?: string,
    public amount?: number,
    public currency?: string,
    public realizationDate?: string,
    public bankAccount?: Pick<BankAccount, 'shortBankName'>,
  ) {}

  static fromJson(
    json: LinkedBankTransactionApiContract,
  ): LinkedBankTransaction {
    return new LinkedBankTransaction(
      json.id,
      json.transaction_date,
      json.operation_type,
      json.wording,
      json.short_bank_name,
      json.amount,
      json.currency,
      json.realization_date,
      json.bank_account
        ? { shortBankName: json.bank_account.short_bank_name }
        : undefined,
    );
  }

  static toJson(
    linkedBankTransaction: LinkedBankTransaction,
  ): LinkedBankTransactionApiContract {
    return {
      id: linkedBankTransaction.id,
      transaction_date: linkedBankTransaction.transactionDate,
      operation_type: linkedBankTransaction.operationType,
      wording: linkedBankTransaction.wording,
      short_bank_name: linkedBankTransaction.shortBankName,
      amount: linkedBankTransaction.amount,
      currency: linkedBankTransaction.currency,
      realization_date: linkedBankTransaction.realizationDate,
      bank_account: linkedBankTransaction.bankAccount
        ? { short_bank_name: linkedBankTransaction.bankAccount.shortBankName }
        : undefined,
    };
  }

  static fromBankTransaction(
    bankTransaction: BankTransaction,
  ): LinkedBankTransaction {
    return new LinkedBankTransaction(
      bankTransaction.id,
      bankTransaction.transactionDate,
      bankTransaction.operationType,
      bankTransaction.wording,
      bankTransaction.shortBankName,
      bankTransaction.amount,
      bankTransaction.currency,
      bankTransaction.realizationDate,
      bankTransaction.bankAccount,
    );
  }
}
