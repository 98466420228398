import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'join',
})
export class JoinPipe implements PipeTransform {
  transform<T>(value: ReadonlyArray<T>, separator?: string): string | null {
    if (value == null) return null;

    if (!this.isSupportedValue(value)) {
      // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
      throw Error(`InvalidPipeArgument: '${value}' for JoinPipe`);
    }

    return value.join(separator);
  }

  private isSupportedValue(value: unknown): boolean {
    return Array.isArray(value);
  }
}
