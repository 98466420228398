import { CommonModule, NgOptimizedImage } from '@angular/common';
import { NgModule } from '@angular/core';
import { TiimePipesModule } from '@manakincubber/tiime-utils';
import { TiimeTooltipModule, TiimeTwocolorsIconModule } from 'tiime-components';

import { InvoicePipesModule } from '../../../invoice/invoice-shared/pipes/invoice-pipes.module';
import { InvoiceNumberComponent } from './invoice-number.component';

@NgModule({
  declarations: [InvoiceNumberComponent],
  imports: [
    CommonModule,
    NgOptimizedImage,
    InvoicePipesModule,
    TiimePipesModule,
    TiimeTwocolorsIconModule,
    TiimeTooltipModule,
  ],
  exports: [InvoiceNumberComponent],
})
export class InvoiceNumberModule {}
