<div *tiimeLet="canSendToMindee$ | async as canSendToMindee" class="header">
  @if (canSendToMindee && (ocerisationAvailable$ | async)) {
    <div class="header__lottie">
      <ng-lottie height="120px" [options]="loaderOptions"></ng-lottie>
    </div>
    <span class="header__title">Depuis une facture</span>
    <span class="header__subtitle">
      Gagnez du temps: en ajoutant une facture, les
      <br />
      informations seront automatiquement saisies
    </span>
  } @else {
    <img
      class="header__img"
      width="118"
      height="118"
      ngSrc="assets/illu_educational_message_receipt.svg"
      draggable="false"
      alt=""
    />
    <span class="header__title">Ajoutez une facture</span>
    @if (canSendToMindee) {
      <app-info-banner
        title="Vous avez saisi des informations manuellement"
        subtitle="Les données de la facture n'écraseront pas celles de votre virement."
      ></app-info-banner>
    }
  }
</div>

<app-drag-and-drop-zone
  class="bank-transfer-drop-zone"
  [acceptedTypes]="acceptedTypes"
  [maximumSize]="maximumSize"
  (dropped)="fileDropped.emit($event)"
>
  <div class="action-container" idleState (click)="$event.stopPropagation()">
    <ng-container [ngTemplateOutlet]="dropZoneContent"></ng-container>
  </div>
  <div class="action-container" hoverState (click)="$event.stopPropagation()">
    <ng-container [ngTemplateOutlet]="dropZoneContent"></ng-container>
  </div>
</app-drag-and-drop-zone>

<ng-template #dropZoneContent>
  <div class="illu-container">
    <img
      class="illu-container__illu"
      ngSrc="assets/illus/bank-transfer/illu_upload_doc.svg"
      height="75"
      width="96"
      draggable="false"
      alt=""
    />
    <app-file-uploader-button
      class="illu-container__button-container"
      [multiple]="false"
      [acceptedTypes]="acceptedTypes"
      (fileSelected)="fileDropped.emit($event)"
    >
      <button
        class="illu-container__button-container__button"
        tiime-button
        accent
        action
      >
        <mat-icon svgIcon="icon-upload-cloud"></mat-icon>
      </button>
    </app-file-uploader-button>
  </div>
  <span class="uploader-title">Glisser / Déposer ici un document</span>
  <span class="uploader-description">
    Nous acceptons les fichiers PDF, JPG et
    <br />
    PNG, d'une taille maximale de 15 Mo.
  </span>
  <button
    class="import-button"
    tiime-button
    accent
    (click)="openMatchingDialog($event)"
  >
    <mat-icon svgIcon="icon-export"></mat-icon>
    Choisir dans Documents
  </button>
</ng-template>
