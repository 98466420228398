import { ChangeDetectorRef, Directive, ElementRef, Input } from '@angular/core';
import { ThemePalette } from '@angular/material/core';

import { HasAttributesBase } from '../core';

@Directive()
// eslint-disable-next-line @angular-eslint/directive-class-suffix
export abstract class ButtonBase extends HasAttributesBase<HTMLButtonElement> {
  @Input() showLoader = false;
  @Input() loaderColor: ThemePalette | 'white' = 'white';

  constructor(
    elementRef: ElementRef<HTMLButtonElement>,
    protected readonly cdr: ChangeDetectorRef,
  ) {
    super(elementRef);
  }

  setLoading(value: boolean): void {
    this.showLoader = value;
    this.cdr.markForCheck();
  }
}
