import { FormControl, FormGroup, Validators } from '@angular/forms';

import { PhoneHelper } from '@core/helpers';
import { RegexHelper } from '@core/helpers/regex.helper';

import { InvoicingConfig } from '../models/invoicing-config';
import { InvoicingDocumentFirstNumberFormControl } from './invoicing-document-first-number-form-control';

export class InvoicingConfigForm extends FormGroup {
  get logoId(): FormControl {
    return this.get('logoId') as FormControl;
  }

  get email(): FormControl<string> {
    return this.get('email') as FormControl<string>;
  }

  get phone(): FormControl<string> {
    return this.get('phone') as FormControl<string>;
  }

  get header(): FormControl {
    return this.get('header') as FormControl;
  }

  get footer(): FormControl {
    return this.get('footer') as FormControl;
  }

  get color(): FormControl {
    return this.get('color') as FormControl;
  }

  get vatTypeCode(): FormControl {
    return this.get('vatType.code') as FormControl;
  }

  get invoiceBankDetailEnabled(): FormControl {
    return this.get('invoiceBankDetailEnabled') as FormControl;
  }

  get invoicePaymentConditionEnabled(): FormControl {
    return this.get('invoicePaymentConditionEnabled') as FormControl;
  }

  get invoiceFreeFieldEnabled(): FormControl {
    return this.get('invoiceFreeFieldEnabled') as FormControl;
  }

  get quotationAcceptanceConditionsEnabled(): FormControl {
    return this.get('quotationAcceptanceConditionsEnabled') as FormControl;
  }

  get quotationFreeFieldEnabled(): FormControl {
    return this.get('quotationFreeFieldEnabled') as FormControl;
  }

  get invoiceFirstNumber(): InvoicingDocumentFirstNumberFormControl {
    return this.get(
      'invoiceFirstNumber',
    ) as InvoicingDocumentFirstNumberFormControl;
  }

  get numberTemplate(): FormControl {
    return this.get('numberTemplate') as FormControl;
  }

  get quotationFirstNumber(): InvoicingDocumentFirstNumberFormControl {
    return this.get(
      'quotationFirstNumber',
    ) as InvoicingDocumentFirstNumberFormControl;
  }

  get dueDateMode(): FormControl {
    return this.get('dueDateMode') as FormControl;
  }

  get stripePaymentsEnabled(): FormControl {
    return this.get('stripePaymentsEnabled') as FormControl;
  }

  get invoicingCategoryType(): FormControl {
    return this.get('invoicingCategoryType') as FormControl;
  }

  get mailErrorMessage(): string {
    return this.email.touched && this.email.hasError('pattern')
      ? `Le format de ce champ est invalide`
      : '';
  }

  get phoneErrorMessage(): string {
    return this.phone.touched && !this.phone.valid
      ? `Le format de ce champ est invalide`
      : '';
  }

  constructor() {
    super({
      id: new FormControl(null),
      email: new FormControl<string>(
        '',
        Validators.pattern(RegexHelper.emailRegex),
      ),
      phone: new FormControl<string>('', [
        Validators.minLength(4),
        PhoneHelper.isValidNumber(),
      ]),
      header: new FormControl(null),
      footer: new FormControl(null),
      logoId: new FormControl(null),
      color: new FormControl(null),
      template: new FormControl(null),
      vatType: new FormGroup({
        code: new FormControl(null),
      }),
      bankAccount: new FormGroup({
        id: new FormControl(null),
        iban: new FormControl(null),
        bic: new FormControl(null),
      }),
      invoiceBankDetailEnabled: new FormControl(null),
      invoicePaymentConditionEnabled: new FormControl(null),
      invoiceFreeField: new FormControl(null),
      invoiceFreeFieldEnabled: new FormControl(null),
      quotationAcceptanceConditionsEnabled: new FormControl(null),
      quotationFreeField: new FormControl(null),
      quotationFreeFieldEnabled: new FormControl(null),
      quotationValidityPeriod: new FormControl(null),
      numberTemplate: new FormControl(null),
      invoiceFirstNumber: new InvoicingDocumentFirstNumberFormControl(),
      quotationFirstNumber: new InvoicingDocumentFirstNumberFormControl(),
      dueDateMode: new FormControl(null),
      stripePaymentsEnabled: new FormControl(null),
      invoicingCategoryType: new FormControl(null),
    });
  }

  fromInvoicingConfig(invoicingConfig: InvoicingConfig): void {
    if (!invoicingConfig.bankAccount) {
      delete invoicingConfig.bankAccount;
    }

    if (!invoicingConfig.vatType) {
      delete invoicingConfig.vatType;
    }

    this.patchValue(invoicingConfig);
  }

  toInvoicingConfig(specificConfig?: InvoicingConfig): InvoicingConfig {
    const invoicingConfig =
      specificConfig || (this.getRawValue() as InvoicingConfig);
    if (invoicingConfig.invoiceFirstNumber) {
      invoicingConfig.invoiceFirstNumber = Number(
        invoicingConfig.invoiceFirstNumber,
      );
    }
    if (invoicingConfig.quotationFirstNumber) {
      invoicingConfig.quotationFirstNumber = Number(
        invoicingConfig.quotationFirstNumber,
      );
    }
    return invoicingConfig;
  }
}
