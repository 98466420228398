export enum LegalInformationsStatus {
  /** User has never sign CGU or GDPR, he has not logged in yet */
  UNSIGNED = 'unsigned',
  /** User has sign the latest CGU or GDPR version */
  UP_TO_DATE = 'up_to_date',
  /** User has sign an old CGU or GDPR version */
  TO_UPDATE = 'to_update',
}

export enum NotApplicableLegalInformationsStatus {
  /** User does not habe to sign this document, mainly the wallet ones for users that only have access to the apps */
  NOT_APPLICABLE = 'not_applicable',
}

export type LegalInformationsApiContract = {
  terms_of_service_status: LegalInformationsStatus;
  gdpr_status: LegalInformationsStatus;
  wallet_terms_of_service_status:
    | LegalInformationsStatus
    | NotApplicableLegalInformationsStatus;
  wallet_gdpr_status:
    | LegalInformationsStatus
    | NotApplicableLegalInformationsStatus;
};

export class LegalInformations {
  constructor(
    public termsOfServiceStatus: LegalInformationsStatus,
    public gdprStatus: LegalInformationsStatus,
    public walletTermsOfServiceStatus:
      | LegalInformationsStatus
      | NotApplicableLegalInformationsStatus,
    public walletGdprStatus:
      | LegalInformationsStatus
      | NotApplicableLegalInformationsStatus,
  ) {}

  public static fromJson(
    json: LegalInformationsApiContract,
  ): LegalInformations {
    return new LegalInformations(
      json.terms_of_service_status,
      json.gdpr_status,
      json.wallet_terms_of_service_status,
      json.wallet_gdpr_status,
    );
  }
}
