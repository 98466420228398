<mat-menu class="transaction-filter-menu number-filter">
  <div
    class="container"
    (click)="$event.stopPropagation()"
    (keydown.tab)="$event.stopPropagation()"
  >
    <div class="title">
      <span>Trier</span>
      <mat-icon
        class="reset-filter-btn"
        svgIcon="icon-reset"
        (click)="reset()"
      ></mat-icon>
    </div>

    <form [formGroup]="form" (ngSubmit)="apply()">
      <mat-radio-group
        class="sort-container"
        color="primary"
        formControlName="sortDirection"
      >
        <mat-radio-button class="number-filter__radio-button" value="asc">
          Du plus petit au plus grand
        </mat-radio-button>
        <mat-radio-button class="number-filter__radio-button" value="desc">
          Du plus grand au plus petit
        </mat-radio-button>
      </mat-radio-group>
      <div class="separator"></div>
      <div class="filter-container">
        <label for="exact-number">{{ inputLabel }}</label>
        <input
          id="exact-number"
          type="text"
          formControlName="exactNumber"
          name="exact-number"
          [pattern]="numberFilterType | numberFilterPattern"
          [placeholder]="placeholder"
        />
        <label>Intervalle</label>
        <div class="interval-group">
          <input
            type="text"
            formControlName="fromNumber"
            name="interval-number"
            [pattern]="numberFilterType | numberFilterPattern"
            [placeholder]="'De : ' + placeholder"
          />
          <input
            type="text"
            formControlName="toNumber"
            name="interval-number"
            [pattern]="numberFilterType | numberFilterPattern"
            [placeholder]="'A : ' + placeholder"
          />
        </div>
      </div>
      <button
        class="apply"
        tiime-button
        xsmall
        accent
        [disabled]="form.invalid"
      >
        Appliquer
      </button>
    </form>
  </div>
</mat-menu>
