export enum CardState {
  lost = 'lost',
  stolen = 'stolen',
  destroyed = 'destroyed',
  locked = 'locked',
  expired = 'expired',
  ordered = 'ordered',
  cvvTryExceeded = 'cvv_try_exceeded',
  pinTryExceeded = 'pin_try_exceeded',
  unactivated = 'unactivated',
  activated = 'activated',
  toOrder = 'to_order',
  managerLocked = 'manager_locked',
  vatValidation = 'vat_validation',
}
