<div
  *tiimeLet="data.items$ | async as accounts"
  class="filter-overlay"
  cdkTrapFocus
  data-cy="account-selector-overlay__container"
>
  <ng-container *ngIf="accounts !== null; else accountItemSkeleton">
    <ng-container
      *ngFor="let account of accounts; let last = last; trackBy: trackByIndex"
    >
      <button
        class="item-button"
        data-cy="account-selector-overlay__item-btn"
        [disabled]="account | map: mapToIsDisabled"
        (click)="selectItem(account)"
      >
        <app-account-item
          [account]="account"
          [iconColor]="data.isBeneficiarySelector ? 'blue' : 'default'"
          [isBeneficiary]="data.isBeneficiarySelector"
          [personalWalletBeneficiary]="data.personalWalletBeneficiary"
          [isSelected]="account.id === data.selectedItem?.id"
        ></app-account-item>
      </button>

      <mat-divider *ngIf="!last"></mat-divider>
    </ng-container>
  </ng-container>
</div>

<ng-container *ngIf="data.canAddAccount">
  <button
    *tiimeLet="data.searchFilterValue$ | async as addAccountName"
    class="item-button add-item-button"
    data-cy="add-account__btn"
    (click)="createItem()"
  >
    <mat-icon svgIcon="icon-plus"></mat-icon>

    <div class="item-button__content">
      Ajouter
      <ng-container *ngIf="addAccountName; else defaultSuffix">
        {{ addAccountName }}
      </ng-container>
      <ng-template #defaultSuffix>un compte</ng-template>
    </div>
  </button>
</ng-container>

<ng-template #accountItemSkeleton>
  <div class="account-item-skeleton">
    <div class="account-item-skeleton__icon"></div>
    <div class="account-item-skeleton__content">
      <div>
        <div class="account-item-skeleton__name"></div>
        <div class="account-item-skeleton__amount"></div>
      </div>
      <div class="account-item-skeleton__bank"></div>
    </div>
  </div>

  <mat-divider></mat-divider>

  <div class="account-item-skeleton">
    <div class="account-item-skeleton__icon"></div>
    <div class="account-item-skeleton__content">
      <div>
        <div class="account-item-skeleton__name"></div>
        <div class="account-item-skeleton__amount"></div>
      </div>
      <div class="account-item-skeleton__bank"></div>
    </div>
  </div>

  <mat-divider></mat-divider>

  <div class="account-item-skeleton">
    <div class="account-item-skeleton__icon"></div>
    <div class="account-item-skeleton__content">
      <div>
        <div class="account-item-skeleton__name"></div>
        <div class="account-item-skeleton__amount"></div>
      </div>
      <div class="account-item-skeleton__bank"></div>
    </div>
  </div>
</ng-template>
