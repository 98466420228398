import { Pipe, PipeTransform } from '@angular/core';

import { TiimeSelectOption } from './select-option';

@Pipe({
  name: 'selectedOption',
})
export class SelectedOptionPipe<T> implements PipeTransform {
  transform(
    options: TiimeSelectOption<T>[],
    selectedOptionValue: T,
    compareWith: (option: T, selected: T) => boolean,
  ): TiimeSelectOption<T> | undefined {
    return options?.find(option =>
      compareWith
        ? compareWith(option.value, selectedOptionValue)
        : option.value === selectedOptionValue,
    );
  }
}
