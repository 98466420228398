import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { filter, tap } from 'rxjs';

import { OverlayPosition } from '../overlay/overlay.service';
import { TiimeSelectOption } from '../select/select/select-option';
import { PaginationRange } from './pagination-range';

@UntilDestroy()
@Component({
  selector: 'tiime-paginator',
  templateUrl: './paginator.component.html',
  styleUrls: ['./paginator.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PaginatorComponent implements OnInit, OnChanges {
  @Input()
  range: PaginationRange;
  @Output()
  readonly rangeEvent: EventEmitter<PaginationRange> =
    new EventEmitter<PaginationRange>();

  rangeForm = new FormControl<number>(PaginationRange.DEFAULT_PAGE_SIZE);
  options: TiimeSelectOption<number>[];

  readonly defaultOptions: TiimeSelectOption<number>[] = [
    { label: '25 lignes', value: 25 },
    { label: '50 lignes', value: 50 },
    { label: '100 lignes', value: 100 },
  ];
  readonly overlayPosition: OverlayPosition = {
    originY: 'top',
    overlayY: 'bottom',
    offsetY: -5,
  };

  get paginatorText(): string {
    return `${this.range.min + 1} à ${this.range.max + 1}`;
  }

  ngOnInit(): void {
    this.updateOptions();
    this.handleRangeUpdate();
  }

  ngOnChanges(): void {
    this.updateOptions();
  }

  private handleRangeUpdate(): void {
    this.rangeForm.valueChanges
      .pipe(
        filter((value: number) => this.range.pageSize !== value),
        tap((value: number) => {
          const min = this.range.min - (this.range.min % value);
          return this.rangeEvent.emit(
            new PaginationRange(min, min + value - 1, undefined, value),
          );
        }),
        untilDestroyed(this),
      )
      .subscribe();
  }

  getOptions(): TiimeSelectOption<number>[] {
    if (this.range.min || this.range.max + 1 === this.range.pageSize) {
      return this.defaultOptions;
    }

    if (this.range.max <= this.defaultOptions[0].value) {
      return [];
    }

    const maxPageSizeIndex = this.defaultOptions.findIndex(
      opt => opt.value >= this.range.max,
    );
    return this.defaultOptions.slice(0, maxPageSizeIndex + 1);
  }

  updateOptions(): void {
    this.options = this.getOptions();
    if (this.options.length) {
      this.rangeForm.enable();
    } else {
      this.rangeForm.disable();
    }

    const selectedOption = this.options
      .slice()
      .reverse()
      .find(opt => opt.value <= this.range.pageSize)?.value;
    this.rangeForm.setValue(selectedOption || this.range.pageSize);
  }

  previousPage(): void {
    if (!this.hasPreviousPage()) {
      return;
    }

    const range = this.range.previous();
    this.rangeEvent.emit(range);
  }

  nextPage(): void {
    if (!this.hasNextPage()) {
      return;
    }

    const range = this.range.next();
    this.rangeEvent.emit(range);
  }

  hasPreviousPage(): boolean {
    return !!this.range.previous();
  }

  hasNextPage(): boolean {
    return !!this.range.next();
  }

  reset(): void {
    this.range = new PaginationRange();
    this.rangeEvent.emit(this.range);
  }
}
