import { CustomDashboardIndicatorType } from '@enums/dashboards';
import { Tag } from '@models';
import { StandardIndicator } from '@models/dashboards';
import { SimpleBankTransactionLabel } from '@models/labels';

export type TypedStandardIndicator = StandardIndicator & {
  type: CustomDashboardIndicatorType;
};
export type TypedLabelIndicator = SimpleBankTransactionLabel & {
  type: CustomDashboardIndicatorType;
};
export type TypedTagIndicator = Tag & {
  type: CustomDashboardIndicatorType;
};

export interface CustomDashboardIndicators {
  standardIndicators?: TypedStandardIndicator[];
  labels?: TypedLabelIndicator[];
  recentlyUsedLabels?: TypedLabelIndicator[];
  tags?: TypedTagIndicator[];
  recentlyUsedTags?: TypedTagIndicator[];
}

export type TypedDashboardIndicator =
  | TypedStandardIndicator
  | TypedLabelIndicator
  | TypedTagIndicator;

export type SelectedDashboardIndicator = {
  datasetTrackId: string;
  indicator: TypedDashboardIndicator;
  operation?: DashboardIndicatorOperation;
};

export enum DashboardIndicatorOperation {
  ADD,
  EXCLUDE,
}
