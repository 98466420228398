import { FormControl, ValidationErrors, ValidatorFn } from '@angular/forms';
import moment from 'moment';

export function periodValidator(
  startControl: FormControl,
  endControl: FormControl,
): ValidatorFn {
  return (): ValidationErrors | null => {
    const start = startControl.value && moment(startControl.value);
    const end = endControl.value && moment(endControl.value);
    return !start || !end || start.isBefore(end)
      ? null
      : { invalidPeriod: true };
  };
}
