import { AbstractControl, FormGroup } from '@angular/forms';

import { TableColumnFilter } from './table-column-sort-and-filter';

export abstract class TableColumnFilterForm<
  T = TableColumnFilter,
> extends FormGroup {
  protected constructor(
    controls: {
      [key: string]: AbstractControl;
    },
    protected readonly filterKey: string,
  ) {
    super(controls);
  }

  abstract fromParam(paramValue: string | boolean): void;
  abstract resetFilters(): void;
  abstract toOutput(): T;
}
