import { AbstractControl, FormControl } from '@angular/forms';
import { UntilDestroy } from '@ngneat/until-destroy';
import { OrFilter, ResetFilter } from 'tiime-components';

import { TableColumnFilterForm } from '@shared';

import { allTransactionsKey } from '../../company/account/transactions/transaction-column-filters/transactions-actions-filter/transactions-actions-filter.component';

@UntilDestroy()
export class TransactionsMatchedFilterForm extends TableColumnFilterForm {
  get matched(): AbstractControl {
    return this.get('matched');
  }

  constructor(filterKey: string) {
    super({ matched: new FormControl(null) }, filterKey);
  }

  fromParam(value?: string): void {
    this.matched.setValue(value ?? allTransactionsKey);
  }

  resetFilters(): void {
    this.patchValue({
      matched: allTransactionsKey,
    });
  }

  toOutput(): OrFilter<string> | ResetFilter {
    return this.matched.value !== allTransactionsKey
      ? new OrFilter(this.filterKey, [this.matched.value])
      : ResetFilter.forKey(this.filterKey);
  }
}
