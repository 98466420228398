import { AsyncPipe } from '@angular/common';
import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { TiimeSpinnerComponent } from 'projects/tiime/src/app/company/company-shared/components/tiime-spinner/tiime-spinner.component';
import {
  Observable,
  distinctUntilChanged,
  iif,
  map,
  switchMap,
  tap,
} from 'rxjs';

import { DocumentsService } from '@core';
import { filterNotNullOrUndefined } from '@core/helpers';

import { BankTransferOverlayService } from '../bank-transfer-overlay.service';

@Component({
  standalone: true,
  imports: [AsyncPipe, PdfViewerModule, TiimeSpinnerComponent],
  selector: 'app-bank-transfer-document-preview',
  templateUrl: './document-preview.component.html',
  styleUrl: './document-preview.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BankTransferDocumentPreviewComponent {
  private readonly bankTransferOverlayService = inject(
    BankTransferOverlayService,
  );
  private readonly documentsService = inject(DocumentsService);
  private readonly domSanitizer = inject(DomSanitizer);

  isPdf = false;

  readonly preview$: Observable<SafeUrl> =
    this.bankTransferOverlayService.document$.pipe(
      filterNotNullOrUndefined(),
      distinctUntilChanged((previous, current) => previous.id === current.id),
      switchMap(document =>
        iif(
          () => document.mimeType === 'application/pdf',
          this.documentsService.getPdfPreview(document.id).pipe(
            tap(() => (this.isPdf = true)),
            map(preview => URL.createObjectURL(preview)),
          ),
          this.documentsService.getPreview(document.id).pipe(
            tap(() => (this.isPdf = false)),
            map(preview =>
              this.domSanitizer.bypassSecurityTrustUrl(
                URL.createObjectURL(preview),
              ),
            ),
          ),
        ),
      ),
    );
}
