import {
  ChangeDetectionStrategy,
  Component,
  ViewEncapsulation,
} from '@angular/core';
import { MatIconModule } from '@angular/material/icon';

@Component({
  standalone: true,
  imports: [MatIconModule],
  selector: 'button[mass-action-toolbar-button]',
  templateUrl: './mass-action-toolbar-button.component.html',
  styleUrls: ['./mass-action-toolbar-button.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class MassActionToolBarButtonComponent {}
