import {
  HorizontalConnectionPos,
  Overlay,
  OverlayConfig,
  OverlayRef,
  VerticalConnectionPos,
} from '@angular/cdk/overlay';
import { ComponentPortal, ComponentType } from '@angular/cdk/portal';
import { Injectable, Injector } from '@angular/core';
import { tap } from 'rxjs/operators';

import { TiimeOverlayConfig } from './overlay.config';
import { TiimeOverlayRef } from './overlay.ref';
import { TIIME_OVERLAY_DATA } from './overlay.token';

const TIIME_OVERLAY_DEFAULT_CONFIG: TiimeOverlayConfig = {
  panelClass: 'tiime-overlay-panel',
  backdropClass: 'cdk-overlay-dark-backdrop',
  hasBackdrop: false,
  backdropClose: false,
  disposeOnNavigation: true,
};

export type OverlayPosition = {
  originX?: HorizontalConnectionPos;
  originY?: VerticalConnectionPos;
  overlayX?: HorizontalConnectionPos;
  overlayY?: VerticalConnectionPos;
  offsetY?: number;
  offsetX?: number;
};

@Injectable()
export class TiimeOverlayService {
  constructor(
    private readonly injector: Injector,
    private readonly overlay: Overlay,
  ) {}

  open<T, D = unknown, R = unknown>(
    componentType: ComponentType<T>,
    data: D | null = null,
    customTiimeOverlayConfig: TiimeOverlayConfig = {},
  ): TiimeOverlayRef<R> {
    const tiimeOverlayConfig = this.getTiimeOverlayConfig(
      customTiimeOverlayConfig,
    );
    const overlayConfig = this.getOverlayConfig(tiimeOverlayConfig);
    const overlayRef = this.overlay.create(overlayConfig);
    const tiimeOverlayRef = new TiimeOverlayRef<R>(overlayRef);

    this.attachModalContainer(overlayRef, tiimeOverlayRef, componentType, data);

    if (tiimeOverlayConfig.backdropClose) {
      this.enableCloseOnBackdropClick(overlayRef, tiimeOverlayRef);
    }

    return tiimeOverlayRef;
  }

  private attachModalContainer<T, D, R>(
    overlayRef: OverlayRef,
    tiimeOverlayRef: TiimeOverlayRef<R>,
    componentType: ComponentType<T>,
    data: D | null,
  ): void {
    const injector = Injector.create({
      providers: [
        { provide: TiimeOverlayRef, useValue: tiimeOverlayRef },
        { provide: TIIME_OVERLAY_DATA, useValue: data },
      ],
      parent: this.injector,
    });
    const containerPortal = new ComponentPortal(componentType, null, injector);
    overlayRef.attach(containerPortal);
  }

  private getTiimeOverlayConfig(
    customTiimeOverlayConfig: TiimeOverlayConfig,
  ): TiimeOverlayConfig {
    const tiimeOverlayConfig = {
      ...TIIME_OVERLAY_DEFAULT_CONFIG,
      ...customTiimeOverlayConfig,
    };

    if (
      !tiimeOverlayConfig.width &&
      !tiimeOverlayConfig.maxWidth &&
      !tiimeOverlayConfig.minWidth &&
      !tiimeOverlayConfig.dynamicWidth
    ) {
      tiimeOverlayConfig.width = '100%';
    }
    if (
      !tiimeOverlayConfig.height &&
      !tiimeOverlayConfig.maxHeight &&
      !tiimeOverlayConfig.minHeight
    ) {
      tiimeOverlayConfig.height = '100%';
    }

    return tiimeOverlayConfig;
  }

  private getOverlayConfig(
    tiimeOverlayConfig: TiimeOverlayConfig,
  ): OverlayConfig {
    return new OverlayConfig({
      ...tiimeOverlayConfig,
      positionStrategy: tiimeOverlayConfig.connectTo
        ? this.overlay
            .position()
            .flexibleConnectedTo(tiimeOverlayConfig.connectTo.origin)
            .withPositions(tiimeOverlayConfig.connectTo.positions)
            .withPush(true)
        : undefined,
      scrollStrategy: tiimeOverlayConfig.repositionOnScroll
        ? this.overlay.scrollStrategies.reposition({ autoClose: true })
        : undefined,
    });
  }

  private enableCloseOnBackdropClick<R>(
    overlayRef: OverlayRef,
    tiimeOverlayRef: TiimeOverlayRef<R>,
  ): void {
    overlayRef
      .backdropClick()
      .pipe(tap(() => tiimeOverlayRef.close()))
      .subscribe();
  }
}
