import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import { BehaviorSubject, combineLatest } from 'rxjs';
import { map } from 'rxjs/operators';

import { UserPermissionNameEnum } from '@enums/users';

import { DocumentUploaderBase } from '../../bases/document-uploader.base';

@Component({
  selector: 'app-document-uploader',
  templateUrl: './document-uploader.component.html',
  styleUrls: ['./document-uploader.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DocumentUploaderComponent extends DocumentUploaderBase {
  @Input() loading: boolean;
  @Input() progress: number;

  @Output() readonly fileReceived = new EventEmitter<FileList>();

  readonly fileHasIncorrectSize$ = new BehaviorSubject(false);
  readonly fileHasUnauthorizedType$ = new BehaviorSubject(false);
  readonly UserPermissionNameEnum = UserPermissionNameEnum;

  readonly fileHasError$ = combineLatest([
    this.fileHasIncorrectSize$,
    this.fileHasUnauthorizedType$,
  ]).pipe(
    map(
      ([fileHasIncorrectSize, fileHasUnauthorizedType]) =>
        fileHasIncorrectSize || fileHasUnauthorizedType,
    ),
  );

  onFileDropped(files: FileList): void {
    if (files.length === 0) {
      return;
    }
    this.fileReceived.emit(files);
  }

  onIncorrectSize(hasIncorrectSize: boolean): void {
    this.fileHasIncorrectSize$.next(hasIncorrectSize);
  }

  onUnauthorizedType(isUnauthorizedType: boolean): void {
    this.fileHasUnauthorizedType$.next(isUnauthorizedType);
  }

  removeError(): void {
    this.fileHasIncorrectSize$.next(false);
    this.fileHasUnauthorizedType$.next(false);
  }
}
