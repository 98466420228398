<ng-container
  *ngIf="company.logoId; then companyLogo; else companyAcronym"
></ng-container>
<ng-template #companyLogo>
  <app-logo
    [width]="size"
    [height]="size"
    [logoId]="company.logoId"
    [companyId]="company.id"
    [slug]="company.slug"
    [color]="company.color"
  ></app-logo>
</ng-template>
<ng-template #companyAcronym>
  <app-acronym
    [color]="company.color"
    [slug]="company.slug"
    [style.width.px]="size"
    [style.height.px]="size"
  ></app-acronym>
</ng-template>
