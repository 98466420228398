import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import {
  TiimeButtonModule,
  TiimeMenuModule,
  TiimeRadioModule,
} from 'tiime-components';

import { IconsModule } from '../../../icons/icons.module';
import { DateAndPeriodFilterModule } from '../date-and-period-filter';
import { TableDateSortAndFilterComponent } from './table-date-sort-and-filter.component';

@NgModule({
  imports: [
    CommonModule,
    TiimeButtonModule,
    IconsModule,
    TiimeMenuModule,
    TiimeRadioModule,
    ReactiveFormsModule,
    DateAndPeriodFilterModule,
  ],
  exports: [TableDateSortAndFilterComponent, TiimeMenuModule],
  declarations: [TableDateSortAndFilterComponent],
})
export class TableDateSortAndFilterModule {}
