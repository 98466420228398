import { InvoicingDocumentMail } from './invoicing-document-mail';

export class InvoiceDunningMail {
  constructor(
    public mail: InvoicingDocumentMail,
    public invoice: { id: number },
  ) {}

  public static toJson(
    invoiceDunningMail: InvoiceDunningMail,
  ): Record<string, unknown> {
    return {
      mail: InvoicingDocumentMail.toJson(invoiceDunningMail.mail),
      invoice: invoiceDunningMail.invoice,
    };
  }
}
