import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

import { NumberFilterTypeEnum } from '@enums/filters';

import { TableColumnSortAndFilterDirective } from '../table-column-sort-and-filter.directive';
import { TableNumberFilterForm } from './table-number-filter.form';

@Component({
  selector: 'app-table-number-filter',
  templateUrl: './table-number-filter.component.html',
  styleUrls: ['../table-column-filter-base.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TableNumberFilterComponent extends TableColumnSortAndFilterDirective {
  @Input() placeholder = 'Montant';
  @Input() inputLabel = 'Montant exact';
  @Input() numberFilterType = NumberFilterTypeEnum.DECIMAL;

  protected initForm(): void {
    this.form = new TableNumberFilterForm(
      this.filterKey,
      this.sortKey,
      this.sort,
    );
    if (this.filterValue) {
      this.form.fromParam(this.filterValue);
    }
  }
}
