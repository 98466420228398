import {
  animate,
  animateChild,
  AnimationTriggerMetadata,
  group,
  query,
  state,
  style,
  transition,
  trigger,
} from '@angular/animations';
import { AnimationCurves, AnimationDurations } from '@angular/material/core';

export const sidePanelAnimations: {
  readonly sidePanelState: AnimationTriggerMetadata;
} = {
  sidePanelState: trigger('state', [
    state('void, hidden', style({ transform: 'translateX(100%)' })),
    state('visible', style({ transform: 'translateX(0%)' })),
    transition(
      'visible => void, visible => hidden',
      group([
        animate(
          `${AnimationDurations.COMPLEX} ${AnimationCurves.ACCELERATION_CURVE}`,
        ),
        query('@*', animateChild(), { optional: true }),
      ]),
    ),
    transition(
      'void => visible',
      group([
        animate(
          `${AnimationDurations.COMPLEX} ${AnimationCurves.DECELERATION_CURVE}`,
        ),
        query('@*', animateChild(), { optional: true }),
      ]),
    ),
  ]),
};
