import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Subject } from 'rxjs';

import { Mail } from '../mail';
import { RecipientMailFormArray } from '../mail-recipient/mail-recipient-form-array';

export class MailForm extends FormGroup {
  /** Subject used to update view in OnPush components which have child components using FormArray as inputs */
  touchedChanges: Subject<boolean> = new Subject<boolean>();

  toRecipientFormArray = new RecipientMailFormArray(Validators.required);
  from = new FormControl('', [Validators.required, Validators.email]);
  ccFormArray = new RecipientMailFormArray();
  cciFormArray = new RecipientMailFormArray();
  subject = new FormControl('', Validators.required);
  body = new FormControl('', Validators.required);

  get fromErrorMessage(): string {
    if (!this.from.touched) {
      return '';
    }

    if (this.from.hasError('required')) {
      return 'Expéditeur requis';
    } else {
      return this.from.hasError('email') ? 'Mail invalide' : '';
    }
  }
  get toErrorMessage(): string {
    return this.toRecipientFormArray.touched &&
      this.toRecipientFormArray.hasError('required')
      ? 'Destinataire requis'
      : '';
  }

  get subjectErrorMessage(): string {
    return this.subject.touched && this.subject.hasError('required')
      ? 'Sujet du mail requis'
      : '';
  }
  get bodyErrorMessage(): string {
    return this.body.touched && this.body.hasError('required')
      ? 'Corps du mail requis'
      : '';
  }

  constructor() {
    super({});
    this.addControl('from', this.from);
    this.addControl('to', this.toRecipientFormArray);
    this.addControl('cc', this.ccFormArray);
    this.addControl('cci', this.cciFormArray);
    this.addControl('subject', this.subject);
    this.addControl('body', this.body);
  }

  markAllAsTouched(): void {
    super.markAllAsTouched();

    this.touchedChanges.next(true);
  }

  toMail(): Mail {
    return new Mail(this.getRawValue());
  }
}
