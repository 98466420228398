import { ChangeDetectionStrategy, Component } from '@angular/core';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { LoginInitiator, TiimeAuthService } from '@manakincubber/tiime-auth';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { catchError, switchMap, take } from 'rxjs/operators';

import { CallbackComponentBase } from '@bases';
import { User } from '@models';
import { UsersService } from '@services/users.service';

@UntilDestroy()
@Component({
  selector: 'app-invitation-callback',
  templateUrl: './invitation-callback.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InvitationCallbackComponent extends CallbackComponentBase {
  private readonly emailParamName = 'email';
  private readonly passwordParamName = 'token';

  constructor(
    store: Store,
    router: Router,
    usersService: UsersService,
    private authService: TiimeAuthService,
    private route: ActivatedRoute,
  ) {
    super(router, store, usersService);
  }

  initAuthentication(): void {
    this.route.queryParamMap
      .pipe(
        take(1),
        switchMap((paramMap: ParamMap) => {
          if (this.authService.isAuthenticated()) {
            return this.redirect(paramMap);
          }

          if (this.canLoginFromQueryParams(paramMap)) {
            const email = paramMap.get(this.emailParamName);
            const password = paramMap.get(this.passwordParamName);

            return this.login(email, password, this.authRedirectUri(paramMap));
          }

          return this.navigateToSignIn();
        }),
        catchError(() => this.navigateToSignIn()),
        untilDestroyed(this),
      )
      .subscribe();
  }

  private canLoginFromQueryParams(paramMap: ParamMap): boolean {
    return (
      paramMap.has(this.emailParamName) && paramMap.has(this.passwordParamName)
    );
  }

  private login(
    email: string,
    password: string,
    redirectUri: string,
  ): Observable<unknown> {
    return this.authService.login(
      email,
      password,
      LoginInitiator.system,
      redirectUri,
    );
  }

  private redirect(queryParams: ParamMap): Observable<boolean> {
    return this.getCurrentUser().pipe(
      switchMap((user: User) =>
        this.handleRedirectionFromQueryParams(
          user.activeCompanyId,
          queryParams,
        ),
      ),
    );
  }
}
