export const MOMENT_RELATIVE_TIME_LABEL = {
  s: `A l'instant !`,
  ss: `A l'instant !`,
  m: `A l'instant !`,
  mm: `%dmin`,
  h: `1h`,
  hh: `%dh`,
  d: `1 jour`,
  dd: `%d jours`,
  M: `1 mois`,
  MM: `%d mois`,
  y: `1 an`,
  yy: `%d ans`,
};
