import { Injectable } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { BehaviorSubject, MonoTypeOperatorFunction, Observable } from 'rxjs';
import { switchMap, tap } from 'rxjs/operators';

import { DocumentsService } from '@core';
import { BankTransferSource } from '@core/amplitude/constants';
import { BankTransferState, Document } from '@models';

import { BankTransferOverlayService } from '../../../../account/bank-transfers/bank-transfers-shared/components/bank-transfert-overlay/bank-transfer-overlay.service';
import {
  DocumentPreviewDialogComponent,
  DocumentPreviewDialogData,
  DocumentPreviewResponseAction,
  DocumentPreviewResponseType,
} from './document-preview-dialog.component';

@Injectable({
  providedIn: 'root',
})
export class DocumentPreviewDialogService {
  // TODO: facilitate imports all over the app
  /**
   * This property should change only when the preview is initializing, when a file is imported or when a file has finished loading,
   * not when a document's property (metadata, comment...) has been updated
   */
  private _isLoading$ = new BehaviorSubject(true);

  get isLoading$(): Observable<boolean> {
    return this._isLoading$.asObservable();
  }

  private _document$ = new BehaviorSubject<Document | undefined>(undefined);

  get document$(): Observable<Document | undefined> {
    return this._document$.asObservable();
  }

  get document(): Document | undefined {
    return this._document$.value;
  }

  constructor(
    private readonly dialog: MatDialog,
    private readonly bankTransferOverlayService: BankTransferOverlayService,
    private readonly documentsService: DocumentsService,
  ) {}

  setLoadingState(isLoading: boolean): void {
    this._isLoading$.next(isLoading);
  }

  setDocument(document?: Document | undefined): void {
    this._document$.next(document);
  }

  openDocumentPreview(
    data: DocumentPreviewDialogData,
  ): MatDialogRef<DocumentPreviewDialogComponent, DocumentPreviewResponseType> {
    return this.dialog.open<
      DocumentPreviewDialogComponent,
      DocumentPreviewDialogData,
      DocumentPreviewResponseType
    >(DocumentPreviewDialogComponent, {
      data,
      panelClass: 'document-preview-panel',
      backdropClass: 'opaque-backdrop',
    });
  }

  openBankTransferOverlayIfAsked(): MonoTypeOperatorFunction<DocumentPreviewResponseType> {
    return tap((dialogResponse: DocumentPreviewResponseType) => {
      if (
        dialogResponse &&
        dialogResponse.action === DocumentPreviewResponseAction.PayDocument &&
        dialogResponse.document
      ) {
        const transferSource: BankTransferSource = dialogResponse.toMyself
          ? 'refundButton'
          : 'payButton';

        const bankTransferState: BankTransferState = {
          document: dialogResponse.document,
          reason: dialogResponse.toMyself ? 'refund' : 'documentPayment',
          transferSource,
        };
        this.bankTransferOverlayService
          .open(bankTransferState)
          .pipe(
            switchMap(() => this.documentsService.getDetail(this.document.id)),
            tap(document => this.setDocument(document)),
          )
          .subscribe();
      }
    });
  }
}
