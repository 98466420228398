import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import {
  TiimeButtonModule,
  TiimeMenuModule,
  TiimeRadioModule,
} from 'tiime-components';

import { IconsModule } from '../../../icons/icons.module';
import { TableColumnFilterPipesModule } from '../pipes/table-column-filter-pipes.module';
import { TableNumberFilterComponent } from './table-number-filter.component';

@NgModule({
  imports: [
    TiimeButtonModule,
    IconsModule,
    TiimeMenuModule,
    TiimeRadioModule,
    ReactiveFormsModule,
    TableColumnFilterPipesModule,
  ],
  exports: [TableNumberFilterComponent, TiimeMenuModule],
  declarations: [TableNumberFilterComponent],
})
export class TableNumberFilterModule {}
