import { BankProvider } from '@enums';

export interface BankApiContract {
  id: number;
  name: string;
  provider_id: number;
  provider: BankProvider;
  sigle: string;
  uuid: string;
  logo_url: string;
  brand: string;
}

export class Bank {
  constructor(
    public id: number,
    public uuid: string,
    public providerId: number,
    public provider: BankProvider,
    public name: string,
    public acronym: string,
    public logoUrl: string,
    public brand: string,
  ) {}

  static fromJson(json: BankApiContract): Bank {
    return new Bank(
      json.id,
      json.uuid,
      json.provider_id,
      json.provider,
      json.name,
      json.sigle,
      json.logo_url,
      json.brand,
    );
  }
}
