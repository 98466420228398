import { TrackingEvent } from '@manakincubber/tiime-tracking';

import { Invoice } from '@models';

export const INVOICE_DRAFT_SAVED = 'Invoice Draft Saved';

export class InvoiceDraftSaved implements TrackingEvent {
  readonly name = INVOICE_DRAFT_SAVED;

  constructor(
    readonly invoiceId: Invoice['id'],
    readonly isNewDraft: boolean,
  ) {}
}
