import { HttpClient, HttpParams, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import {
  DefaultGetOptions,
  GetOptions,
  MonoValueFilter,
  PaginationData,
  RequiredGetOptions,
} from 'tiime-components';

import { HttpFileService } from '@core';
import { TemporaryEncoder } from '@core/temporary-encoder';
import { ApiAlertError } from '@decorators/api-alert-error';
import { InvoicePdfFormat, InvoiceType, PaymentMethod } from '@enums';
import { HttpHelper } from '@helpers';
import {
  Invoice,
  InvoiceListItem,
  InvoiceSchedule,
  InvoiceScheduleApiContract,
  InvoicesCountByStatus,
  InvoicingDocumentMail,
  PaymentLink,
  PaymentLinkApiContract,
  Registry,
  RegistryApiContract,
  TotalBilledAmounts,
  TotalBilledAmountsOptions,
} from '@models';

@Injectable({
  providedIn: 'root',
})
export class InvoiceService {
  private readonly resourceUri = 'api/v1/companies/{companyId}/invoices';
  private readonly paymentLinksResourceUri =
    'api/v1/companies/{companyId}/payment_links';

  constructor(
    private readonly http: HttpClient,
    private readonly httpFileService: HttpFileService,
  ) {}

  @ApiAlertError()
  create(invoice: Invoice): Observable<Invoice> {
    return this.http
      .post(this.resourceUri, Invoice.toJson(invoice))
      .pipe(
        map((invoiceJson: Record<string, unknown>) =>
          Invoice.fromJson(invoiceJson),
        ),
      );
  }

  @ApiAlertError()
  delete(invoiceId: number): Observable<void> {
    return this.http.delete<void>(`${this.resourceUri}/${invoiceId}`);
  }

  @ApiAlertError()
  deleteDraft(invoiceId: number): Observable<void> {
    return this.http.delete<void>(`${this.resourceUri}/${invoiceId}/draft`);
  }

  @ApiAlertError()
  get(invoiceId: number): Observable<Invoice> {
    const url = `${this.resourceUri}/${invoiceId}`;
    const params = new HttpParams({
      fromObject: { expand: 'quotation' },
      encoder: new TemporaryEncoder(),
    });
    return this.http
      .get(url, { params })
      .pipe(
        map((invoiceJson: Record<string, unknown>) =>
          Invoice.fromJson(invoiceJson),
        ),
      );
  }

  getAll(
    getOptions: RequiredGetOptions<'range'>,
  ): Observable<PaginationData<InvoiceListItem>>;
  getAll(getOptions: DefaultGetOptions): Observable<InvoiceListItem[]>;
  getAll(
    getOptions: DefaultGetOptions,
  ): Observable<PaginationData<InvoiceListItem> | InvoiceListItem[]> {
    const partialOptions = new GetOptions(getOptions).toHttpGetOptions();
    const options = {
      params: new HttpParams({
        fromObject: partialOptions.params,
        encoder: new TemporaryEncoder(),
      }),
      headers: partialOptions.headers,
    };

    return getOptions.range
      ? this.http
          .get<Record<string, unknown>[]>(this.resourceUri, {
            ...options,
            observe: 'response',
          })
          .pipe(
            HttpHelper.mapToPaginationData(getOptions.range, invoiceJson =>
              InvoiceListItem.fromJson(invoiceJson),
            ),
          )
      : this.http
          .get<Record<string, unknown>[]>(this.resourceUri, options)
          .pipe(
            map(invoicesJson =>
              invoicesJson.map(invoiceJson =>
                InvoiceListItem.fromJson(invoiceJson),
              ),
            ),
          );
  }

  @ApiAlertError()
  patch(invoiceId: number, invoice: Invoice): Observable<Invoice> {
    return this.http
      .patch(`${this.resourceUri}/${invoiceId}`, Invoice.toJson(invoice))
      .pipe(
        map((invoiceJson: Record<string, unknown>) =>
          Invoice.fromJson(invoiceJson),
        ),
      );
  }

  @ApiAlertError()
  cancel(
    invoice: Invoice | InvoiceListItem,
    emissionDate?: string,
  ): Observable<Invoice> {
    if (invoice.totalIncludingTaxes < 0) {
      return this.http
        .post(
          `api/v1/companies/{companyId}/credit_notes/${invoice.id}/cancel`,
          {},
        )
        .pipe(
          map((invoiceJson: Record<string, unknown>) =>
            Invoice.fromJson(invoiceJson),
          ),
        );
    } else {
      return this.http
        .post(`${this.resourceUri}/${invoice.id}/credit_note`, {
          emission_date: emissionDate,
          cancel_invoice: true,
        })
        .pipe(
          map((invoiceJson: Record<string, unknown>) =>
            Invoice.fromJson(invoiceJson),
          ),
        );
    }
  }

  @ApiAlertError()
  getCountByStatus(): Observable<InvoicesCountByStatus> {
    return this.http.get<InvoicesCountByStatus>(
      `${this.resourceUri}/count_by_status`,
    );
  }

  @ApiAlertError()
  getAdvancePayments(
    getOptions: DefaultGetOptions,
  ): Observable<InvoiceListItem[]> {
    const finalOptions = {
      ...getOptions,
      filters: [
        ...getOptions.filters,
        new MonoValueFilter('type', InvoiceType.advancePayment),
      ],
    };
    return this.getAll(finalOptions);
  }

  @ApiAlertError()
  getPreview(invoiceId: number): Observable<Blob> {
    const url = `${this.resourceUri}/pdf?id=${invoiceId}`;
    return this.http.get(url, { responseType: 'blob' });
  }

  @ApiAlertError()
  send(invoiceId: number, mail: InvoicingDocumentMail): Observable<void> {
    const url = `${this.resourceUri}/${invoiceId}/send`;
    return this.http.post<void>(url, InvoicingDocumentMail.toJson(mail));
  }

  @ApiAlertError()
  duplicate(invoiceId: number): Observable<Invoice> {
    const url = `${this.resourceUri}/${invoiceId}/duplicate`;
    return this.http
      .post(url, null)
      .pipe(
        map((invoiceJson: Record<string, unknown>) =>
          Invoice.fromJson(invoiceJson),
        ),
      );
  }

  @ApiAlertError()
  generateCreditNote(invoiceId: number): Observable<Invoice> {
    const url = `${this.resourceUri}/${invoiceId}/credit_note`;
    return this.http
      .post(url, null)
      .pipe(
        map((invoiceJson: Record<string, unknown>) =>
          Invoice.fromJson(invoiceJson),
        ),
      );
  }

  @ApiAlertError()
  downloadPdf(
    invoiceId: number,
    format: InvoicePdfFormat = InvoicePdfFormat.standard,
  ): Observable<HttpResponse<Blob>> {
    return this.httpFileService.download(
      `${this.resourceUri}/pdf?id=${invoiceId}`,
      {
        headers: { 'Content-Type': 'application/pdf' },
        params:
          format !== InvoicePdfFormat.standard
            ? new HttpParams({
                fromObject: { format },
                encoder: new TemporaryEncoder(),
              })
            : undefined,
      },
    );
  }

  @ApiAlertError()
  createSchedule(
    invoiceId: Invoice['id'],
    invoiceSchedule: InvoiceSchedule,
  ): Observable<InvoiceSchedule> {
    const url = `${this.resourceUri}/${invoiceId}/schedules`;
    return this.http
      .post(url, InvoiceSchedule.toJson(invoiceSchedule))
      .pipe(
        map((invoiceScheduleJson: InvoiceScheduleApiContract) =>
          InvoiceSchedule.fromJson(invoiceScheduleJson),
        ),
      );
  }

  @ApiAlertError()
  updateSchedule(
    invoiceId: Invoice['id'],
    invoiceSchedule: InvoiceSchedule,
  ): Observable<InvoiceSchedule> {
    const url = `${this.resourceUri}/${invoiceId}/schedules/${invoiceSchedule.id}`;
    return this.http
      .put(url, InvoiceSchedule.toJson(invoiceSchedule))
      .pipe(
        map((invoiceScheduleJson: InvoiceScheduleApiContract) =>
          InvoiceSchedule.fromJson(invoiceScheduleJson),
        ),
      );
  }

  @ApiAlertError()
  getTotals(
    getOptions: TotalBilledAmountsOptions,
  ): Observable<TotalBilledAmounts> {
    const partialOptions = new GetOptions(getOptions).toHttpGetOptions();
    const options = {
      params: new HttpParams({
        fromObject: partialOptions.params,
        encoder: new TemporaryEncoder(),
      }),
    };
    return this.http
      .get(`${this.resourceUri}/totals`, options)
      .pipe(map(TotalBilledAmounts.fromJson));
  }

  @ApiAlertError()
  createStripeLink(invoiceId: number): Observable<PaymentLink> {
    return this.http
      .post<PaymentLinkApiContract>(this.paymentLinksResourceUri, {
        type: PaymentMethod.Stripe,
        invoice: { id: invoiceId },
      })
      .pipe(map(PaymentLink.fromJson));
  }

  @ApiAlertError()
  disablePaymentLink(paymentLinkId: number): Observable<void> {
    return this.http.delete<void>(
      `${this.paymentLinksResourceUri}/${paymentLinkId}`,
    );
  }

  @ApiAlertError()
  getRegistriesFromInvoice(invoiceId: number): Observable<Registry[]> {
    const url = `${this.resourceUri}/${invoiceId}/registries`;
    return this.http
      .get(url)
      .pipe(
        map((registriesJson: RegistryApiContract[]) =>
          registriesJson.map(registryJson => Registry.fromJson(registryJson)),
        ),
      );
  }

  @ApiAlertError()
  matchInvoiceWithBankTransactionsAndImputations(
    invoiceId: number,
    bankTransactionsId: number[],
    imputationsId: number[],
  ): Observable<void> {
    const url = `${this.resourceUri}/${invoiceId}/matchings`;

    const bankTransactionIds = bankTransactionsId.map(bankTransactionId => ({
      id: bankTransactionId,
    }));

    const imputationIds = imputationsId.map(imputationId => ({
      id: imputationId,
    }));

    return this.http.put<void>(url, {
      bank_transactions: bankTransactionIds,
      imputations: imputationIds,
    });
  }
}
