import { Directive, EventEmitter, Input, Output } from '@angular/core';

@Directive()
export abstract class AbstractTiimeChipDirective {
  @Input() canRemove = false;

  @Input() basic = false;

  @Input() flat = false;

  @Input() disabled = false;

  @Output() readonly remove = new EventEmitter();
}
