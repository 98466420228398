<ng-container *tiimeLet="userId$ | async as userId">
  <app-operation-type-logo
    *ngIf="item | toOperationType as operationType; else matIconTemplate"
    [operationType]="operationType"
    [oneColor]="true"
    [isSalaryOperation]="
      item.transactionAuthor && item.transactionAuthor?.userId !== userId
    "
  ></app-operation-type-logo>
</ng-container>
<ng-template #matIconTemplate>
  <mat-icon
    class="matched-icon"
    inline="true"
    svgIcon="icon-link-ok"
  ></mat-icon>
</ng-template>

<div
  class="flex-column"
  [class.pointer]="item | matchedItemHasPreview"
  (click)="openPreviewInNewTab()"
>
  <div class="flex-row">
    <span class="amount">
      {{ item | matchedItemAmount }}
    </span>
    <span class="secondary-text">
      {{ item | matchedItemSecondaryText }}
    </span>
  </div>
  <div class="flex-row">
    <span class="main-text">
      {{ item | matchedItemMainText }}
    </span>
    <span *ngIf="item | matchedItemDate as date" class="date">
      - {{ date }}
    </span>
  </div>
</div>

<mat-icon
  *hasAccess="UserPermissionNameEnum.ACCOUNTS_USER_FULL"
  class="remove-item-icon"
  inline="true"
  svgIcon="icon-close-slim"
  data-cy="matched-document__btn-remove"
  (click)="$event.stopPropagation(); remove.emit(item)"
></mat-icon>
