import {
  HttpErrorResponse,
  HttpProgressEvent,
  HttpStatusCode,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, from, Observable, of } from 'rxjs';
import { filter, map, mergeMap, take, tap } from 'rxjs/operators';

import { Document, FileTransfer } from '@core/models';
import { FileTransferStatus } from '@enums';

@Injectable({
  providedIn: 'root',
})
export abstract class FileTransferServiceBase<T extends FileTransfer> {
  protected readonly _queue$ = new BehaviorSubject<T[]>([]);
  get queue$(): Observable<T[]> {
    return this._queue$.asObservable();
  }

  protected abstract defaultErrorMessage: string;

  readonly hasPendingTransfer$: Observable<boolean> = this.hasPendingTransfer();

  clearQueue(): void {
    this._queue$.next([]);
  }

  observeQueue(): Observable<T | HttpProgressEvent | Document | Blob> {
    return this._queue$.pipe(
      filter((files: T[]) => !!files && files.length > 0),
      mergeMap((fileTransfer: T[]) => from(fileTransfer)),
      mergeMap((fileTransfer: T) =>
        fileTransfer.transferStatus === FileTransferStatus.NOT_STARTED
          ? this.transferFile(fileTransfer)
          : of(fileTransfer),
      ),
    );
  }

  abstract addToQueue(files: T['file'][], ...args: unknown[]): void;
  protected abstract convertToTransferFiles(
    files: T['file'][],
    ...args: unknown[]
  ): T[];
  protected abstract transferFile(
    fileToTransfer: T,
  ): Observable<T | HttpProgressEvent | Document | Blob>;
  protected abstract isSameFile(a: T, b: T): boolean;

  protected calculateTransferProgress(
    progressEvent: HttpProgressEvent,
  ): number {
    return Math.round((100 * progressEvent.loaded) / progressEvent.total);
  }

  protected transferErrorMessage(error: unknown): string {
    if (error instanceof HttpErrorResponse) {
      const errorDescription = (error.error as { error_description?: string })
        .error_description;

      if (errorDescription) {
        return errorDescription;
      }
    }

    return this.defaultErrorMessage;
  }

  protected updateFileInQueue(transferedFile: T): void {
    this._queue$
      .pipe(
        take(1),
        tap(queue => {
          const newQueue = queue.map(file =>
            this.isSameFile(file, transferedFile) ? transferedFile : file,
          );
          this._queue$.next(newQueue);
        }),
      )
      .subscribe();
  }

  protected fileTransferError(
    fileToTransfer: T,
    error?: HttpErrorResponse,
  ): Observable<T> {
    fileToTransfer.error = this.transferErrorMessage(error);
    fileToTransfer.progress = 0;
    fileToTransfer.transferStatus =
      error?.status === HttpStatusCode.NotFound
        ? FileTransferStatus.NO_DATA
        : FileTransferStatus.ON_ERROR;
    this.updateFileInQueue(fileToTransfer);
    return of(fileToTransfer);
  }

  private hasPendingTransfer(): Observable<boolean> {
    return this.queue$.pipe(
      map(files => {
        return files.some(
          file =>
            file.transferStatus === FileTransferStatus.NOT_STARTED ||
            file.transferStatus === FileTransferStatus.IN_PROGRESS,
        );
      }),
    );
  }
}
