import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { map, tap } from 'rxjs/operators';

import { FileService } from '@core';
import { ApiAlertError } from '@decorators/api-alert-error';
import { LegalDocumentType } from '@enums';
import {
  LegalInformations,
  LegalInformationsApiContract,
  LegalInformationsStatus,
} from '@models';

@Injectable({
  providedIn: 'root',
})
export class LegalService {
  private legalInformations?: LegalInformations;

  constructor(
    private readonly http: HttpClient,
    private readonly fileService: FileService,
  ) {}

  /**
   * To know if the user must sign TOS or GDPR for apps or for wallet.
   * This is used by the guards.
   */
  hasUserSignedTosAndGdpr(): Observable<boolean> {
    return this.getLegalInformations().pipe(
      map(
        legalInformations =>
          !this.mustUserSignApps(legalInformations) &&
          !this.mustUserSignWallet(legalInformations),
      ),
    );
  }

  /**
   * To know if user is loggin in for the first time and should sign the TOS and GDPR
   */
  areTosOrGdprUnsigned(legalInformations?: LegalInformations): boolean {
    const {
      termsOfServiceStatus,
      gdprStatus,
      walletTermsOfServiceStatus,
      walletGdprStatus,
    } = legalInformations || {};
    return (
      termsOfServiceStatus === LegalInformationsStatus.UNSIGNED ||
      gdprStatus === LegalInformationsStatus.UNSIGNED ||
      walletTermsOfServiceStatus === LegalInformationsStatus.UNSIGNED ||
      walletGdprStatus === LegalInformationsStatus.UNSIGNED
    );
  }

  @ApiAlertError()
  getLegalInformations(): Observable<LegalInformations> {
    return this.legalInformations
      ? of(this.legalInformations)
      : this.http
          .get<LegalInformationsApiContract>(
            'api/v1/users/me/legal_informations',
          )
          .pipe(
            map(legalInformationsJson =>
              LegalInformations.fromJson(legalInformationsJson),
            ),
            tap(
              legalInformations => (this.legalInformations = legalInformations),
            ),
          );
  }

  /**
   * This clears the stored legalInformations so we can fetch new values from api.
   */
  clearLegalInformations(): void {
    this.legalInformations = undefined;
  }

  @ApiAlertError()
  validateTos(): Observable<null> {
    return this.http.post<null>(
      'api/v1/users/me/terms_of_service/validate',
      {},
    );
  }

  @ApiAlertError()
  validateGdpr(): Observable<null> {
    return this.http.post<null>('api/v1/users/me/gdpr/validate', {});
  }

  @ApiAlertError()
  validateWalletTos(): Observable<null> {
    return this.http.post<null>(
      'api/v1/wallet/users/me/terms_of_service/validate',
      {},
    );
  }

  @ApiAlertError()
  validateWalletGdpr(): Observable<null> {
    return this.http.post<null>('api/v1/wallet/users/me/gdpr/validate', {});
  }

  @ApiAlertError()
  openLegalDocumentPdf(doc: LegalDocumentType): Observable<Blob> {
    return this.http
      .get(`api/v1/users/${doc}`, {
        headers: { 'Content-Type': 'application/pdf' },
        responseType: 'blob',
      })
      .pipe(
        map(file => new Blob([file], { type: 'application/pdf' })),
        tap(file => this.fileService.openInNewTab(file)),
      );
  }

  private mustUserSignApps(legalInformations: LegalInformations): boolean {
    const { termsOfServiceStatus, gdprStatus } = legalInformations;
    return (
      termsOfServiceStatus === LegalInformationsStatus.UNSIGNED ||
      termsOfServiceStatus === LegalInformationsStatus.TO_UPDATE ||
      gdprStatus === LegalInformationsStatus.UNSIGNED ||
      gdprStatus === LegalInformationsStatus.TO_UPDATE
    );
  }

  private mustUserSignWallet(legalInformations: LegalInformations): boolean {
    const { walletTermsOfServiceStatus, walletGdprStatus } = legalInformations;
    return (
      walletTermsOfServiceStatus === LegalInformationsStatus.UNSIGNED ||
      walletTermsOfServiceStatus === LegalInformationsStatus.TO_UPDATE ||
      walletGdprStatus === LegalInformationsStatus.UNSIGNED ||
      walletGdprStatus === LegalInformationsStatus.TO_UPDATE
    );
  }
}
