import { Pipe, PipeTransform } from '@angular/core';

import { DocumentTypeEnum } from '@core/models';

@Pipe({
  name: 'canMatchTransaction',
  standalone: true,
})
export class CanMatchTransactionPipe implements PipeTransform {
  transform(
    documentType: DocumentTypeEnum,
    ...allowedDocumentTypes: DocumentTypeEnum[]
  ): boolean {
    return allowedDocumentTypes.includes(documentType);
  }
}
