import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  inject,
} from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { Mapper, NgUtils, TiimePipesModule } from '@manakincubber/tiime-utils';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { tap } from 'rxjs/operators';
import {
  DatetimePipeModule,
  TiimeButtonModule,
  TiimeCheckboxModule,
  TiimeLetModule,
  TiimeTooltipModule,
} from 'tiime-components';

import { ExpenseReportEditorLineForm } from '@forms';
import { IconsModule } from '@shared';

export interface CellDefinition<T> {
  formControlName: keyof T;
  required?: boolean;
  type?: 'date' | 'currency' | 'number';
  valuePath?: string;
  customSuffix?: string;
  containerClass?: string;
  computeValue?: (value: T) => unknown;
}

@UntilDestroy()
@Component({
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    IconsModule,
    TiimeButtonModule,
    TiimeCheckboxModule,
    TiimeLetModule,
    TiimePipesModule,
    TiimeTooltipModule,
    DatetimePipeModule,
  ],
  selector: 'app-expenses-report-editor-line',
  templateUrl: './expenses-report-editor-line.component.html',
  styleUrls: ['./expenses-report-editor-line.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ExpensesReportEditorLineComponent<T> implements OnInit {
  @Input() editorLineForm: ExpenseReportEditorLineForm<T>;

  @Input() disabled: boolean;

  @Input() cellDefinitions: CellDefinition<T>[];

  @Input() disableLineAction: boolean;

  @Output() readonly lineClicked = new EventEmitter<boolean>();
  @Output() readonly lineChecked = new EventEmitter<boolean>();

  isIncomplete: boolean;
  isChecked: boolean;

  readonly trackByIndex = NgUtils.trackByIndex;

  private readonly cdr = inject(ChangeDetectorRef);

  readonly mapToCellValue: Mapper<T, unknown> = (
    data,
    cellDefinition: CellDefinition<T>,
  ) => {
    if (cellDefinition.computeValue) {
      return cellDefinition.computeValue(data);
    } else if (
      cellDefinition.valuePath &&
      data[cellDefinition.formControlName]
    ) {
      const typedObject = data[
        cellDefinition.formControlName
      ] as unknown as Record<string, unknown>;
      return typedObject[cellDefinition.valuePath];
    } else {
      return data[cellDefinition.formControlName];
    }
  };

  readonly mapToIsCellIncomplete: Mapper<unknown, boolean> = (
    cellValue,
    cellDefinition: CellDefinition<T>,
  ) =>
    (cellValue === null || cellValue === undefined) && cellDefinition.required;

  ngOnInit(): void {
    this.editorLineForm.valueChanges
      .pipe(
        untilDestroyed(this),
        tap(({ line, checked }: { checked: boolean; line: T }) => {
          this.isIncomplete = this.checkIsIncomplete(line);
          this.isChecked = checked;
          this.cdr.detectChanges();
        }),
      )
      .subscribe();
  }

  private checkIsIncomplete(lineValue: T): boolean {
    return this.cellDefinitions.some(({ formControlName, required }) => {
      return (
        required &&
        (lineValue[formControlName] === undefined ||
          lineValue[formControlName] === null)
      );
    });
  }
}
