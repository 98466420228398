import {
  LinkedEntityApiContract,
  LinkedEntityBankTransactionValueApiContract,
  LinkedEntityBankTransactionValueBaseApiContract,
  LinkedEntityImputationValueApiContract,
} from './api-contracts';
import { LinkedEntity, LinkedEntityType } from './linked-entity';
import {
  LinkedEntityBankTransaction,
  LinkedEntityBankTransactionValue,
  LinkedEntityBankTransactionValueBase,
} from './linked-entity-bank-transaction';
import { LinkedEntityBase } from './linked-entity-base';
import {
  LinkedEntityExpenseReport,
  LinkedEntityExpenseReportValue,
} from './linked-entity-expense-report';
import {
  LinkedEntityImputation,
  LinkedEntityImputationValue,
} from './linked-entity-imputation';

/**
 * Creates either a `LinkedEntityBankTransaction`, `LinkedEntityExpenseReport` or a `LinkedEntityImputation`.
 * This function is exported instead of being a static member to prevent circular dependencies.
 * @param json Data fetched from the api
 */
export const linkedEntityFromJson = (
  json: LinkedEntityApiContract,
):
  | LinkedEntityBankTransaction
  | LinkedEntityExpenseReport
  | LinkedEntityImputation => {
  const linkedEntityBase: LinkedEntityBase = {
    id: json.value.id,
    date: json.value.date,
  };

  if (json.type === LinkedEntityType.EXPENSE_REPORT) {
    const jsonValue = json.value as LinkedEntityExpenseReportValue;
    const linkedEntityExpenseReportValue: LinkedEntityExpenseReportValue = {
      ...linkedEntityBase,
      label: jsonValue.label,
      amount: jsonValue.amount,
    };

    return new LinkedEntityExpenseReport(linkedEntityExpenseReportValue);
  }

  const jsonValueBankTransactionBase =
    json.value as LinkedEntityBankTransactionValueBaseApiContract;

  const linkedEntityBankTransactionValueBase: LinkedEntityBankTransactionValueBase =
    {
      wording: jsonValueBankTransactionBase.wording,
      operationType: jsonValueBankTransactionBase.operation_type,
      bankAccount: { bank: jsonValueBankTransactionBase.bank_account.bank },
    };

  if (json.type === LinkedEntityType.IMPUTATION) {
    const jsonValue = json.value as LinkedEntityImputationValueApiContract;
    const linkedEntityImputationValue: LinkedEntityImputationValue = {
      ...linkedEntityBase,
      ...linkedEntityBankTransactionValueBase,
      amount: jsonValue.amount,
      currency: jsonValue.currency,
    };

    return new LinkedEntityImputation(linkedEntityImputationValue);
  }

  const jsonValue = json.value as LinkedEntityBankTransactionValueApiContract;
  return new LinkedEntityBankTransaction({
    ...linkedEntityBase,
    ...linkedEntityBankTransactionValueBase,
    countDocuments: jsonValue.count_documents,
    countInvoices: jsonValue.count_invoices,
    amount: jsonValue.amount,
    imputations: jsonValue.imputations
      ? jsonValue.imputations.map(imputation => ({
          id: imputation.id,
          label: { id: imputation.label.id },
          amount: imputation.amount,
        }))
      : [],
    shortBankName: jsonValue.short_bank_name,
  });
};

export const linkedEntityToJson = ({
  type,
  value,
}: LinkedEntity): LinkedEntityApiContract => {
  const linkedEntityBase: LinkedEntityBase = {
    id: value.id,
    date: value.date,
  };

  if (type === LinkedEntityType.EXPENSE_REPORT) {
    const valueJson = value as LinkedEntityExpenseReportValue;
    return {
      type,
      value: {
        ...linkedEntityBase,
        label: valueJson.label,
        amount: valueJson.amount,
      },
    };
  }

  const bankTransactionValueBase =
    value as LinkedEntityBankTransactionValueBase;

  const bankTransactionValueBaseApiContract: LinkedEntityBankTransactionValueBaseApiContract =
    {
      wording: bankTransactionValueBase.wording,
      operation_type: bankTransactionValueBase.operationType,
      bank_account: bankTransactionValueBase.bankAccount,
    };

  if (type === LinkedEntityType.IMPUTATION) {
    const valueJson = value as LinkedEntityImputationValue;
    return {
      type,
      value: {
        ...linkedEntityBase,
        ...bankTransactionValueBaseApiContract,
        amount: valueJson.amount,
        currency: valueJson.currency,
      },
    };
  }

  const valueJson = value as LinkedEntityBankTransactionValue;
  return {
    type,
    value: {
      ...linkedEntityBase,
      ...bankTransactionValueBaseApiContract,
      count_documents: valueJson.countDocuments,
      count_invoices: valueJson.countInvoices,
      imputations: valueJson.imputations,
      short_bank_name: valueJson.shortBankName,
      amount: valueJson.amount,
    },
  };
};
